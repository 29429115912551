<template>
  <div class="kt-feeNotes mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("feeNotes.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'feeNotes', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('accounting/invoices/feeNote/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("feeNotes.addFeeNoteButton") }}
      </b-button>

      <!-- filter LaboratoryId -->
      <div v-if="laboratoriesOptions.length > 2">
        <div class="d-inline-block">
          <b-form-select
            v-model="filterLaboratoryId"
            class="mt-3"
            size="sm"
            :options="laboratoriesOptions"
            value-field="id"
            text-field="formattedName"
            @keydown.enter.exact="onPageInput"
            @input="searchHasChanged = true;"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="feeNotes"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter InvoiceDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="invoiceDateFilter"
              v-model="filterInvoiceDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Invoiced -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- transformed -->
          <b-th
            class="kt-table__th-search px-0"
            style="width: 0;"
          >
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 85px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          <span>{{ data.item.file.accessNumber }}</span>
          <!-- complementary -->
          <span
            v-show="data.item.file.parentFileId"
            class="kt-complementary-icon"
          >
            <b-icon icon="back"></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(feeNoteButtons)="data">
        <div style="width: 85px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'feeNotes', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/invoices/feeNote/view', {feeNoteIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- export pdf  -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'feeNotes', 'toPdf'])"
            :ref="data.index === 0 ? 'exportPdfButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onExport(data.item, 'pdf')"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- cell template : transformed badges -->
      <template v-slot:cell(transformed)="data">
        <div
          v-show="data.item.transformationsCount"
          class="kt-badge kt-badge-icon kt-badge--yellow kt-badge--lg mx-1"
        >
          <b-icon
            icon="arrow-left-right"
            class="kt-badge__icon mr-0"
          ></b-icon>
        </div>
        <div
          v-show="data.item.fromTransformationsCount"
          class="kt-badge kt-badge-icon kt-badge--blue kt-badge--lg mx-1"
        >
          <b-icon
            icon="arrow-left-right"
            class="kt-badge__icon mr-0"
          ></b-icon>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!feeNotesCount && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import invoicesHelpers from "@shared/services/UI/invoicesHelpers";
import price from "@shared/services/UI/price";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
// others
import fileSaver from "file-saver";

export default {
  mixins: [userRights, error, date, price, invoicesHelpers, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      feeNotesCount: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiceDate",
          sortable: true,
          label: this.$t("feeNotes.invoiceDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: false,
          label: this.$t("feeNotes.accessNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiced",
          sortable: false,
          label: this.$t("feeNotes.invoiced"),
          formatter: (value, _key, _item) => {
            return this.$n(value || 0, "currency");
          }
        },
        {
          tdClass: "kt-table__td p-0",
          thClass: "kt-table__th p-0",
          key: "transformed",
          sortable: false,
          label: ""
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "feeNoteButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterLaboratoryId: null,
      filterInvoiceDate: "",
      filterAccessNumber: "",
      // table items
      feeNotes: [],
      // options
      laboratoriesOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterLaboratoryId: "number",
        filterInvoiceDate: "string",
        filterAccessNumber: "string"
      }
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterLaboratoryId ||
        this.filterInvoiceDate ||
        this.filterAccessNumber
      );
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import laboratories
      this.importLaboratoriesOptions();

      // refresh table
      await this.onPageInput();

      // auto-focus
      if (this.$refs.addLineButton) {
        this.$refs.addLineButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.exportPdfButton_0) {
        this.$refs.exportPdfButton_0.focus();
      }
    },

    // import laboratories
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, formattedName: this.$tc("fileInvoiceTransformations.allLaboratories") });
      this.laboratoriesOptions = laboratories;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // searchAndUpdateTable
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (this.filterInvoiceDate !== "") {
          const filterInvoiceDate = new Date(this.filterInvoiceDate).getFullYear();
          if (filterInvoiceDate < 1900 || filterInvoiceDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          laboratoryId: this.filterLaboratoryId,
          invoiceDate: this.filterInvoiceDate,
          accessNumber: this.filterAccessNumber
        };
        const res = await commonServices.getAll("feeNotes", filters);
        this.feeNotes = this.importFormatInvoicesList(res.data.rows, "feeNote");
        this.feeNotesCount = res.data.rows.length;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.feeNotes.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.invoiceDateFilter.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onExport(row, type) {
      try {
        const resFile = await commonServices.export("feeNotes", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.file.accessNumber + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

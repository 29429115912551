const userRightsTable = require("./userRightsTable");

export default {
  methods: {
    isEnabled(keys) {
      if (!this.$userSettings.user || !this.$userSettings.user.userRights) {
        return false;
      }

      if (this.$userSettings.user.userRights.includes("Administrator")) {
        return true;
      }

      let rights = userRightsTable.rights;
      for (const value of keys) {
        if (rights[value]) {
          rights = rights[value];
        } else {
          return false;
        }
      }
      for (const userRight of this.$userSettings.user.userRights) {
        if (rights.includes(userRight)) {
          return true;
        }
      }
      return false;
    },
    isRole(role) {
      if (!this.$userSettings.user || !this.$userSettings.user.userRights) {
        return false;
      }

      if (this.$userSettings.user.userRights.includes("Administrator")) {
        return role === "system";
      } else if (this.$userSettings.user.userRights.includes("UserAdministrator")) {
        return role === "userAdmin";
      } else if (this.$userSettings.user.userRights.includes("ChiefSecretary")) {
        return role === "chiefSecretary";
      } else if (this.$userSettings.user.userRights.includes("Secretary")) {
        return role === "secretary";
      } else if (this.$userSettings.user.userRights.includes("Pathologist")) {
        return role === "pathologist";
      } else if (this.$userSettings.user.userRights.includes("Cytotechnician")) {
        return role === "cytotechnician";
      } else if (this.$userSettings.user.userRights.includes("ChiefAccountant")) {
        return role === "chiefAccountant";
      } else if (this.$userSettings.user.userRights.includes("Accountant")) {
        return role === "accountant";
      } else if (this.$userSettings.user.userRights.includes("ChiefTechnician")) {
        return role === "chiefTechnician";
      } else if (this.$userSettings.user.userRights.includes("Technician")) {
        return role === "technician";
      } else {
        return false;
      }
    }
  }
};

<template>
  <div
    v-if="fileInvoice"
    class="kt-fi-transformation__fileInvoice mt-2 p-2"
    :class="{
      'kt-fi-transformation__fileInvoice--transformed': fileInvoice.fileInvoiceTransformations && fileInvoice.fileInvoiceTransformations.length,
      'kt-fi-transformation__fileInvoice--transformation': fileInvoice.targetFileInvoice_fileInvoiceTransformation,
      'kt-fi-transformation__fileInvoice--transformable': transformable,
      'border-top': !isfirstItemInListProp
    }"
    @click="transformable ? $emit('select', !selected) : false"
  >
    <!-- header -->
    <div class="kt-fi-transformation__fileInvoice-header">
      <!-- checkbox-display-only -->
      <div class="kt-fi-transformation__fileInvoice-checkbox">
        <div
          v-show="transformable"
          class="kt-checkbox-display-only-cont"
        >
          <span
            class="kt-checkbox-display-only"
            :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (selected ? ' kt-checkbox-display-only--checked' : '')"
          >
            <span class="kt-checkbox-display-only__cross"></span>
          </span>
        </div>
      </div>
      <div class="kt-fi-transformation__fileInvoice-content">
        <!-- title : invoice type -->
        <div class="font-weight-semi-bold line-height-md">
          {{ $t("fileInvoiceTransformations." + (fileInvoice.careSheetId ? "careSheet" : fileInvoice.feeNoteId ? "feeNote" : fileInvoice.invoiceReportId ? "invoiceReport" : "")) }}
        </div>
        <!-- text : amount and date -->
        <span class="">{{ fileInvoice.invoiceDate ? $d(new Date(fileInvoice.invoiceDate), "date") : "" }}</span>
        <span class="mx-1">{{ $t("general.emptyWithHyphen") }}</span>
        <span class="">{{ $n(fileInvoice.invoiced, "currency") }}</span>
        <!-- label for transformed -->
        <div
          v-show="fileInvoice.fileInvoiceTransformations && fileInvoice.fileInvoiceTransformations.length"
          class="font-weight-semi-bold text-warning"
        >
          {{ $t("fileInvoiceTransformations.transformed") }}
        </div>
      </div>
    </div>
    <!-- fileInvoice : organisation / patient-->
    <div class="kt-item-list mt-1 pl-4">
      <div
        class="kt-item-list__item-wrapper"
      >
        <div class="kt-item-list__item mb-0">
          <div class="kt-item-list__item-title pr-0">
            <!-- patient name -->
            <PersonName
              v-show="fileInvoice && fileInvoice.organisation === null"
              :sexNameProp="file ? file.patient.sex.name : ''"
              :firstNameProp="file ? file.patient.firstName : ''"
              :lastNameProp="file ? file.patient.lastName : ''"
              :maidenNameProp="file ? file.patient.maidenName : ''"
            />
            <!-- organisation name -->
            {{ file !== null && fileInvoice.organisation ? fileInvoice.organisation.name + (fileInvoice.organisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '' }}
          </div>
          <div class="kt-item-list__item-text">
            <!-- patient birthdate -->
            {{ file !== null && fileInvoice.organisation === null ? getFormattedBirthdateAge(file.patient.birthdate) : '' }}
            <!-- code -->
            {{ file !== null && fileInvoice.organisation ? fileInvoice.organisation.code : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
// helpers
import date from "@shared/services/UI/date";

export default {
  components: { PersonName },
  mixins: [date],
  props: {
    fileProp: {
      type: Object,
      default: null
    },
    fileInvoiceProp: {
      type: Object,
      default: null
    },
    selectedListProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    isfirstItemInListProp: {
      type: Boolean
    }
  },
  data() {
    return {
      file: null,
      fileInvoice: null,
      selected: false,
      transformable: false
    };
  },
  computed: {
  },
  watch: {
    fileProp: {
      handler: function(val) {
        this.file = val;
        if (val === null) {
          this.fileInvoice = null;
          this.transformable = false;
          this.selected = false;
        }
      },
      immediate: true,
      deep: true
    },
    fileInvoiceProp: {
      handler: function(val) {
        this.fileInvoice = val;
        this.transformable = val !== null && (!val.fileInvoiceTransformations || !val.fileInvoiceTransformations.length);
        this.selected = val !== null && this.selectedListProp.includes(val.id);
      },
      immediate: true,
      deep: true
    },
    selectedListProp: {
      handler: function(_val) {
        this.selected = this.fileInvoice && this.selectedListProp.includes(this.fileInvoice.id);
      },
      deep: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
  }
};
</script>

<style scoped src="./FileInvoiceTransformationInvoiceItem.scss" lang="scss"></style>

<template>
  <div class="kt-cytotechniciansReports mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("cytotechniciansReports.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- filter form -->
    <b-form @submit.prevent="searchAndUpdateTable">
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="laboratoryId"
            @input="laboratoryId = $event; searchHasChanged = true;"
            @keydown.enter.exact="searchAndUpdateTable"
            @blur="laboratoryIdValidation = true;"
          />
        </b-col>

        <!-- period -->
        <b-col
          cols="12"
          md="3"
        >
          <label
            for="kt-cytotechniciansReports__period"
            class="d-block"
            style="margin-bottom: 5px;"
          >
            {{ $t('cytotechniciansReports.period') }}
          </label>
          <b-form-group
            :invalid-feedback="startDateInvalidFeedback"
            :state="startDateState"
            style="display: inline-block; width: 50%; padding-right: 4px; vertical-align: top;"
          >
            <b-form-input
              id="kt-cytotechniciansReports__period"
              v-model="startDate"
              type="date"
              :state="startDateState"
              @blur="startDateValidation = true"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :invalid-feedback="endDateInvalidFeedback"
            :state="endDateState"
            style="display: inline-block; width: 50%; vertical-align: top; padding-left: 4px;"
          >
            <b-form-input
              v-model="endDate"
              type="date"
              :state="endDateState"
              @blur="endDateValidation = true"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- filter Cytotechnician -->
        <b-col
          cols="12"
          md="3"
        >
          <InputSearch
            :labelProp="$t('cytotechniciansReports.cytotechnician')"
            searchedTableProp="users"
            searchedFieldProp="lastName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'lastName', 'firstName', 'isActive']"
            suggestionsKeyProp="id"

            :valueProp="filterCytotechnician"
            optionalProp
            :displayedValueProp="filterCytotechnician ? filterCytotechnician.firstName + ' ' + filterCytotechnician.lastName + ' ' + (filterCytotechnician.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.firstName + ' ' + suggestion.lastName + ' ' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
            }"

            @onUpdateParent="filterCytotechnician = $event; searchHasChanged = true;"
            @keydown.enter.exact="searchAndUpdateTable"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <b-col cols="12">
          <!-- submit -->
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
            :disabled="loading || exportLoading"
          >
            <span class="align-middle">{{ $t("general.search") }}</span>
            <b-spinner
              v-show="loading"
              class="ml-2 align-middle spinner-border-xs kt-spinner--bg-transparent"
            ></b-spinner>
          </b-button>
          <!-- export -->
          <b-button
            class="ml-2"
            :variant="$systemSettings.theme"
            :disabled="!cytotechniciansList.length || searchHasChanged || loading || exportLoading"
            @click="onExport"
          >
            <span class="align-middle">{{ $t("general.downloadXlsx") }}</span>
            <b-spinner
              v-show="exportLoading"
              class="ml-2 align-middle spinner-border-xs kt-spinner--bg-transparent"
            ></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- divider -->
    <div class="w-100 border-top mt-4 mb-4"></div>

    <!-- result (totals)
    <div
      v-if="cytotechniciansList.length"
      class="kt-item-list kt-item-list--full-width kt-item-list--shadow mb-4"
    >
      <div class="kt-item-list__item-wrapper">
        <div
          class="kt-item-list__item mb-0"
          style="padding: 20px;"
        >
          <div class="d-flex justify-content-around align-items-center text-center">
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.totalFiles") }}</span>
              <br /><span>{{ totalFiles }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.totalComplementariesShort") }}</span>
              <br /><span>{{ totalComplementaries }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.totalCytology") }}</span>
              <br /><span>{{ totalCytology }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.totalHpv") }}</span>
              <br /><span>{{ totalHpv }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.totalCotesting") }}</span>
              <br /><span>{{ totalCotesting }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.totalEarnings") }}</span>
              <br /><span>{{ $n((totalEarnings || 0) / 100, "currency") }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.positive") }}</span>
              <br /><span>{{ positive }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.negative") }}</span>
              <br /><span>{{ negative }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("cytotechniciansReports.indeterminate") }}</span>
              <br /><span>{{ indeterminate }}</span>
            </h4>
            <div class="ml-3">
              <b-button
                :variant="$systemSettings.theme"
                :disabled="searchHasChanged || loading || exportLoading"
                @click="onExport"
              >
                <span class="align-middle">{{ $t("general.downloadXlsx") }}</span>
                <b-spinner
                  v-show="exportLoading"
                  class="ml-2 align-middle spinner-border-xs kt-spinner--bg-transparent"
                ></b-spinner>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- table -->
    <b-table
      class="mb-0 kt-table"
      style="margin-top: 32px;"
      hover
      :fields="fields"
      :items="cytotechniciansList"
      :busy="loading"
    >
      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="showNoResult && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3 mb-4"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
import cytotechniciansReportsServices from "@/services/API/cytotechniciansReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { SmartSelectInput, InputSearch },
  mixins: [userRights, error, date, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      exportLoading: false,
      searchHasChanged: false,
      maxReached: false,
      showNoResult: false,
      // search filters
      laboratoryId: null,
      startDate: "",
      endDate: "",
      filterCytotechnician: null,
      // validation
      laboratoryIdValidation: false,
      startDateValidation: false,
      endDateValidation: false,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "lastName",
          sortable: false,
          label: this.$t("cytotechniciansReports.cytotechnicianLastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "firstName",
          sortable: false,
          label: this.$t("cytotechniciansReports.cytotechnicianFirstName")
          // formatter: (value, _key, _item) => {
          //   let output = value.firstName + " " + value.lastName;
          //   if (!value.isActive) {
          //     output += " (" + this.$t("general.deletedLabel") + ")";
          //   }
          //   return output;
          // },
          // sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumber",
          sortable: false,
          label: this.$t("cytotechniciansReports.accessNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "timestamp",
          sortable: false,
          label: this.$t("cytotechniciansReports.timestamp"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value) : this.$t("general.emptyWithHyphen");
          }
        }
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "totalFiles",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.totalFiles")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "totalComplementaries",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.totalComplementariesShort")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "cytology",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.totalCytology")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "hpv",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.totalHpv")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "cotesting",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.totalCotesting")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "totalEarnings",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.totalEarnings"),
        //   formatter: (value, _key, _item) => {
        //     return this.$n((value || 0) / 100, "currency");
        //   }
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "positive",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.positive")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "negative",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.negative")
        // },
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th kt-table__th--sm",
        //   key: "indeterminate",
        //   sortable: false,
        //   label: this.$t("cytotechniciansReports.indeterminate")
        // }
      ],
      // table items
      cytotechniciansList: [],
      // totalFiles: 0,
      // totalComplementaries: 0,
      // totalCytology: 0,
      // totalHpv: 0,
      // totalCotesting: 0,
      // totalEarnings: 0,
      // positive: 0,
      // negative: 0,
      // indeterminate: 0,
      // saveParamsConfig
      saveParamsConfig: {
        laboratoryId: "number",
        startDate: "string",
        endDate: "string",
        filterCytotechnician: {
          id: "number",
          firstName: "string",
          lastName: "string",
          isActive: "boolean"
        }
      }
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    isValidGapBetweenStartEnd: function() {
      return this.validateDateBeforeDate(this.getIsoDate(-1, -3, 0, this.endDate), this.startDate);
    },
    startDateState: function() {
      if (!this.startDateValidation) return null;
      if (!this.startDate) return false;
      if (this.endDate && !this.validateDateBeforeDate(this.startDate, this.endDate)) return false;
      if (!this.isValidGapBetweenStartEnd) return false;
      return null;
    },
    startDateInvalidFeedback: function() {
      if (this.startDateState === null) return "";
      if (!this.startDate) return this.$t("validationRules.required");
      if (!this.isValidGapBetweenStartEnd) return this.$tc("reports.reportsSearchForm.validations.oneMonthMaxGap", 3, { authorizesGapInMonths: 3 });
      return this.$t("validationRules.endDateBeforeStartDate");
    },
    endDateState: function() {
      if (!this.endDateValidation) return null;
      if (!this.endDate) return false;
      if (this.startDate && !this.validateDateBeforeDate(this.startDate, this.endDate)) return false;
      if (!this.isValidGapBetweenStartEnd) return false;
      return null;
    },
    endDateInvalidFeedback: function() {
      if (this.endDateState === null) return "";
      if (!this.endDate) return this.$t("validationRules.required");
      if (!this.isValidGapBetweenStartEnd) return "";
      return "";
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // set default period
    if (this.startDate === "") this.startDate = this.getIsoDate(0, -3, 0);
    if (this.endDate === "") this.endDate = this.getIsoDate(0, 0, 0);
  },
  methods: {
    // submit functions
    async validateForm() {
      this.laboratoryIdValidation = true;
      this.startDateValidation = true;
      this.endDateValidation = true;

      return (
        this.laboratoryIdState !== false &&
        this.startDateState !== false &&
        this.endDateState !== false
      );
    },
    // search and update table
    async searchAndUpdateTable() {
      if (this.loading || this.exportLoading) return false;

      try {
        // validation
        if (await this.validateForm() === false) return false;

        this.loading = true;
        this.searchHasChanged = false;
        const filters = {
          laboratoryId: this.laboratoryId,
          startDate: this.startDate,
          endDate: this.endDate,
          cytotechnicianId: this.filterCytotechnician ? this.filterCytotechnician.id : null
        };
        const res = await commonServices.getAll(
          "cytotechniciansReports",
          filters,
          0, // offset
          100 // limit
        );
        // assign result
        let tempLines = [];
        if (res.data.length) {
          for (const cytotechnician of res.data) {
            if (res.data.length) {
              tempLines = tempLines.concat(
                cytotechnician.files.map((v, i) => {
                  v.userId = res.data.userId;
                  if (i === 0) {
                    v.firstName = cytotechnician.firstName;
                    v.lastName = cytotechnician.lastName;
                  } else {
                    v.firstName = "-";
                    v.lastName = "-";
                  }
                  return v;
                })
              );
            }
          }
        }
        this.cytotechniciansList = tempLines;

        // this.totalFiles = res.data.totalFiles;
        // this.totalComplementaries = res.data.totalComplementaries;
        // this.totalCytology = res.data.totalCytology;
        // this.totalHpv = res.data.totalHpv;
        // this.totalCotesting = res.data.totalCotesting;
        // this.totalEarnings = res.data.totalEarnings;
        // this.positive = res.data.positive;
        // this.negative = res.data.negative;
        // this.indeterminate = res.data.indeterminate;
        // this.maxReached = res.data.maxReached;
        // this.showNoResult = res.data.rows.length === 0;

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.handleErrors(err);
      }
    },

    async onExport() {
      if (this.loading || this.exportLoading) return false;

      try {
        // validation
        if (await this.validateForm() === false) return false;

        this.exportLoading = true;
        const filters = {
          laboratoryId: this.laboratoryId,
          startDate: this.startDate,
          endDate: this.endDate,
          cytotechnicianId: this.filterCytotechnician ? this.filterCytotechnician.id : null
        };
        const resFile = await cytotechniciansReportsServices.generate(filters, "xlsx");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          const filterPeriod = "_" + this.startDate + "_" + this.endDate;
          const filterCytotechnician = this.filterCytotechnician ? "_" + this.filterCytotechnician.lastName : "";
          await fileSaver.saveAs(resFile.data, this.$t("cytotechniciansReports.fileName") + filterPeriod + filterCytotechnician + ".xlsx");
        }
        this.exportLoading = false;
      } catch (err) {
        this.exportLoading = false;
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style src="@/views/Accounting/Reports/Reports.scss" lang="scss"></style>

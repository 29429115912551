<template>
  <div class="kt-choose-file">
    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="fileFields"
      :items="files"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onFileSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter creationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="creationDateInput"
              v-model="filterCreationDate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter accessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputAccessNumber
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              :maxlengthProp="50"
              trimProp
              @input="searchHasChanged = true;"
              @keydown.enter.exact="searchAndUpdateTable"
              @submit="searchAndUpdateTable"
            />
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter hospitalisationNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter firstName -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter lastName -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- statuses -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 72px;"
          >
            <b-form-select
              v-model="filterStatusId"
              class="kt-table__th-search-input"
              :options="displayForAccountingProp ? accountingStatusOptions : filingStatusOptions"
              value-field="id"
              text-field="localisedName"
              @input="searchHasChanged = true;"
            ></b-form-select>
          </b-th>
          <!-- search button -->
          <b-th
            class="kt-table__th-search"
            style="width: 151px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="searchAndUpdateTable(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.accessNumber }}
          <!-- is urgent -->
          <span
            v-show="data.item.isUrgent"
            style="font-size: 14px; margin: 0 0 1px 3px;"
          >
            <b-icon
              icon="flag-fill"
              variant="danger"
            ></b-icon>
          </span>
          <!-- complementary -->
          <span
            v-show="data.item.parentFileId"
            class="kt-complementary-icon"
            :class="{'kt-complementary-icon--ml-lg': !!data.item.isUrgent}"
          >
            <b-icon
              icon="back"
            ></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : status badges -->
      <template v-slot:cell(status)="data">
        <StatusBadge
          :typeProp="displayForAccountingProp ? 'accountingStatus' : 'filingStatus'"
          :statusNameProp="displayForAccountingProp ? (data.item.accountingStatus.name || '') : (data.item.filingStatus.name || '')"
          isHiddenEmptyProp
        />
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="(!files || !files.length) && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("fileChoose.notFound") }}
      </h4>
    </div>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3 mb-2"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import StatusBadge from "@shared/views/Helpers/StatusBadge";
import InputAccessNumber from "@shared/views/Helpers/InputAccessNumber";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  components: {
    StatusBadge,
    InputAccessNumber
  },
  mixins: [userRights, error],
  props: {
    displayForAccountingProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      // table fields
      fileFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "creationDate",
          sortable: true,
          label: this.$t("files.creationDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumber")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "patient.firstName",
          sortable: true,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "patient.lastName",
          sortable: true,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "status",
          sortable: false,
          label: this.$t("files.filingStatus")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "fileButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterCreationDate: "",
      filterAccessNumber: "",
      filterHospitalisationNumber: "",
      filterStatusId: null,
      // table items
      files: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterCreationDate ||
        this.filterAccessNumber ||
        this.filterHospitalisationNumber ||
        this.filterStatusId
      );
    }
  },
  filingStatusOptions: function() {
    const filingStatuses = JSON.parse(JSON.stringify(this.$systemSettings.filingStatuses));
    filingStatuses.unshift({ id: null, name: "all", localisedName: this.$tc("general.allMasculine", 2) });
    return filingStatuses;
  },
  accountingStatusOptions: function() {
    const accountingStatuses = JSON.parse(JSON.stringify(this.$systemSettings.accountingStatuses));
    accountingStatuses.unshift({ id: null, name: "all", localisedName: this.$tc("general.allMasculine", 2) });
    return accountingStatuses;
  },
  async mounted() {
    try {
      // import filingStatuses
      await this.importFilingStatuses();

      // import accountingStatuses
      await this.importAccountingStatuses();

      // refresh table
      await this.searchAndUpdateTable();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.creationDateInput.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },

    // update the table
    async searchAndUpdateTable(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // if no filter
        if (this.isSearchActive === false) {
          this.files = [];
          this.searchHasChanged = false;
          this.maxReached = false;
          return false;
        }

        // validation dates
        if (this.filterCreationDate !== "") {
          const filterCreationDate = new Date(this.filterCreationDate).getFullYear();
          if (filterCreationDate < 1900 || filterCreationDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          creationDate: this.filterCreationDate,
          accessNumber: this.filterAccessNumber,
          hospitalisationNumber: this.filterHospitalisationNumber
        };
        if (this.displayForAccountingProp) {
          filters.accountingStatusId = this.filterStatusId;
        } else {
          filters.filingStatusId = this.filterStatusId;
        }

        const res = await commonServices.getAll("files", filters);
        this.files = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.files.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.creationDateInput.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // on select
    onFileSelected(rows) {
      if (rows && rows.length === 1) {
        this.$emit("chosen", rows[0]);
      }
    }
  }
};
</script>

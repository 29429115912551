import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  get(name) {
    return axiosServices.axios.get("/systemSettings/getForFrontend", { params: { name: name } });
  },
  getAll() {
    return axiosServices.axios.get("/systemSettings/getAllForFrontend");
  },
  put(name, value) {
    return axiosServices.axios.put("/systemSettings/putForFrontend", { name: name, value: value });
  },
  async getLogs() {
    return axiosServices.axios.get("/systemSettings/getLogs", { responseType: "blob" });
  }
};

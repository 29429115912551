export default {
  data() {
    return {
      // radio-button options
      //screeningOptions: [
      //  { value: "control", text: this.$t("cytologyReport.screening.control") },
      //  { value: "individual", text: this.$t("cytologyReport.screening.individual") },
      //  { value: "organised", text: this.$t("cytologyReport.screening.organised") }
      //],
      //smearOptions: [
      //  { value: "fcu", text: this.$t("cytologyReport.smear.fcu") },
      //  { value: "fv", text: this.$t("cytologyReport.smear.fv") },
      //  { value: "fem", text: this.$t("cytologyReport.smear.fem") },
      //  { value: "fvu", text: this.$t("cytologyReport.smear.fvu") },
      //  { value: "fan", text: this.$t("cytologyReport.smear.fan") }
      //],
      //layeringOptions: [
      //  { value: "monolayer", text: this.$t("cytologyReport.layering.monolayer") },
      //  { value: "conventional", text: this.$t("cytologyReport.layering.conventional") }
      //],

      hormonalStatusOptions: [
        { value: "oralContraceptive", text: this.$t("cytologyReport.hormonalStatus.oralContraceptive") },
        { value: "menopause", text: this.$t("cytologyReport.hormonalStatus.menopause") },
        { value: "nonTreatedMenopause", text: this.$t("cytologyReport.hormonalStatus.nonTreatedMenopause") },
        { value: "thsMenopause", text: this.$t("cytologyReport.hormonalStatus.thsMenopause") },
        { value: "pregnancy", text: this.$t("cytologyReport.hormonalStatus.pregnancy") },
        { value: "implantContraceptive", text: this.$t("cytologyReport.hormonalStatus.implantContraceptive") },
        { value: "ringContraceptive", text: this.$t("cytologyReport.hormonalStatus.ringContraceptive") },
        { value: "diu", text: this.$t("cytologyReport.hormonalStatus.diu") },
        { value: "progestativeDiu", text: this.$t("cytologyReport.hormonalStatus.progestativeDiu") },
        { value: "amenorrhea", text: this.$t("cytologyReport.hormonalStatus.amenorrhea") },
        { value: "breastFeeding", text: this.$t("cytologyReport.hormonalStatus.breastFeeding") }
      ],
      informationOptions: [
        { text: this.$t("cytologyReport.information.antecedentInSituCarcinome"), value: "antecedentInSituCarcinome" },
        { text: this.$t("cytologyReport.information.antecedentCin"), value: "antecedentCin" },
        { text: this.$t("cytologyReport.information.antecedentCin1"), value: "antecedentCin1" },
        { text: this.$t("cytologyReport.information.antecedentCin2"), value: "antecedentCin2" },
        { text: this.$t("cytologyReport.information.antecedentCin3"), value: "antecedentCin3" },
        { text: this.$t("cytologyReport.information.antecedentCondylome"), value: "antecedentCondylome" },
        { text: this.$t("cytologyReport.information.antecedentConisation"), value: "antecedentConisation" },
        { text: this.$t("cytologyReport.information.antecedentConisationCin1"), value: "antecedentConisationCin1" },
        { text: this.$t("cytologyReport.information.antecedentConisationCin2"), value: "antecedentConisationCin2" },
        { text: this.$t("cytologyReport.information.antecedentConisationCin3"), value: "antecedentConisationCin3" },
        { text: this.$t("cytologyReport.information.antecedentOncogenHpv"), value: "antecedentOncogenHpv" },
        { text: this.$t("cytologyReport.information.antecedentAdenoCarcinome"), value: "antecedentAdenoCarcinome" },
        { text: this.$t("cytologyReport.information.antecedentAscus"), value: "antecedentAscus" },
        { text: this.$t("cytologyReport.information.antecedentCervicalCancer"), value: "antecedentCervicalCancer" },
        { text: this.$t("cytologyReport.information.antecedentMicroInvasiveCarcinome"), value: "antecedentMicroInvasiveCarcinome" },
        { text: this.$t("cytologyReport.information.antecedentCuritherapy"), value: "antecedentCuritherapy" },
        { text: this.$t("cytologyReport.information.antecedentHsil"), value: "antecedentHsil" },
        { text: this.$t("cytologyReport.information.antecedentLsil"), value: "antecedentLsil" },
        { text: this.$t("cytologyReport.information.antecedentPositiveImmunoCytoChemistry"), value: "antecedentPositiveImmunoCytoChemistry" },
        { text: this.$t("cytologyReport.information.antecedentLaser"), value: "antecedentLaser" },
        { text: this.$t("cytologyReport.information.antecedentLaserCin1"), value: "antecedentLaserCin1" },
        { text: this.$t("cytologyReport.information.antecedentLaserCondylome"), value: "antecedentLaserCondylome" },
        { text: this.$t("cytologyReport.information.stenosedCervix"), value: "stenosedCervix" },
        { text: this.$t("cytologyReport.information.partnerCondylome"), value: "partnerCondylome" },
        { text: this.$t("cytologyReport.information.ectropion"), value: "ectropion" },
        { text: this.$t("cytologyReport.information.hysterectomy"), value: "hysterectomy" },
        { text: this.$t("cytologyReport.information.subTotalHysterectomy"), value: "subTotalHysterectomy" },
        { text: this.$t("cytologyReport.information.totalHysterectomy"), value: "totalHysterectomy" },
        { text: this.$t("cytologyReport.information.metrorragies"), value: "metrorragies" },
        { text: this.$t("cytologyReport.information.immunosuppressionNotion"), value: "immunosuppressionNotion" },
        { text: this.$t("cytologyReport.information.cervicalPolype"), value: "cervicalPolype" },
        { text: this.$t("cytologyReport.information.postPartum"), value: "postPartum" },
        { text: this.$t("cytologyReport.information.prolapsus"), value: "prolapsus" },
        { text: this.$t("cytologyReport.information.bleedingSmear"), value: "bleedingSmear" },
        { text: this.$t("cytologyReport.information.monitoringAgc"), value: "monitoringAgc" },
        { text: this.$t("cytologyReport.information.monitoringAsch"), value: "monitoringAsch" },
        { text: this.$t("cytologyReport.information.monitoringAscus"), value: "monitoringAscus" },
        { text: this.$t("cytologyReport.information.monitoringAscusHpv"), value: "monitoringAscusHpv" },
        { text: this.$t("cytologyReport.information.monitoringCondylome"), value: "monitoringCondylome" },
        { text: this.$t("cytologyReport.information.monitoringDistilben"), value: "monitoringDistilben" },
        { text: this.$t("cytologyReport.information.progestativeTreatment"), value: "progestativeTreatment" },
        { text: this.$t("cytologyReport.information.hpvVaccine"), value: "hpvVaccine" },
        { text: this.$t("cytologyReport.information.hpvRecentNegative"), value: "hpvRecentNegative" },
        { text: this.$t("cytologyReport.information.hpvRecentPositive"), value: "hpvRecentPositive" },
        { text: this.$t("cytologyReport.information.spotting"), value: "spotting" }
      ],

      // satisfactory & quality options
      satisfactoryChoices: [
        { id: "satisfactory", name: this.$t("cytologyReport.satisfactoryOptions.satisfactory") },
        { id: "paucicellular", name: this.$t("cytologyReport.satisfactoryOptions.paucicellular") },
        { id: "inflammatory", name: this.$t("cytologyReport.satisfactoryOptions.inflammatory") },
        { id: "hemorrhagic", name: this.$t("cytologyReport.satisfactoryOptions.hemorrhagic") }
      ],
      qualitySatisfactoryChoices: [
        { id: "satisfactory", name: this.$t("cytologyReport.qualityOptions.satisfactory") },
        { id: "satisfactoryWithCells", name: this.$t("cytologyReport.qualityOptions.satisfactoryWithCells") },
        { id: "satisfactoryNoCells", name: this.$t("cytologyReport.qualityOptions.satisfactoryNoCells") }
      ],
      qualityNotSatisfactoryChoices: [
        { id: "notSatisfactory", name: this.$t("cytologyReport.qualityOptions.notSatisfactory") }
      ],
      qualityChoices: [],
      subqualityChoices: [
        { value: "inflammatory", text: this.$t("cytologyReport.subqualityOptions.inflammatory") },
        { value: "hemorrhagic", text: this.$t("cytologyReport.subqualityOptions.hemorrhagic") }
      ],
      // complements options
      pathogensOptions: [
        { text: this.$t("cytologyReport.pathogens.myc"), value: "myc" },
        { text: this.$t("cytologyReport.pathogens.act"), value: "act" },
        { text: this.$t("cytologyReport.pathogens.tri"), value: "tri" },
        { text: this.$t("cytologyReport.pathogens.gard"), value: "gard" },
        { text: this.$t("cytologyReport.pathogens.nomyc"), value: "nomyc" },
        { text: this.$t("cytologyReport.pathogens.noact"), value: "noact" },
        { text: this.$t("cytologyReport.pathogens.mcih"), value: "mcih" }
      ],
      controlOptions: [
        { text: this.$t("cytologyReport.control.arinfl"), value: "arinfl" },
        { text: this.$t("cytologyReport.control.arhor"), value: "arhor" },
        { text: this.$t("cytologyReport.control.arall"), value: "arall" }
      ],
      hormonalOptions: [
        { text: this.$t("cytologyReport.hormonal.mua"), value: "mua" },
        { text: this.$t("cytologyReport.hormonal.mus"), value: "mus" },
        { text: this.$t("cytologyReport.hormonal.mixedMucosa"), value: "mixedMucosa" },
        { text: this.$t("cytologyReport.hormonal.trt"), value: "trt" }
      ],
      reactionOptions: [
        { text: this.$t("cytologyReport.reaction.pak"), value: "pak" },
        { text: this.$t("cytologyReport.reaction.pakPlus"), value: "pakPlus" },
        { text: this.$t("cytologyReport.reaction.mcr"), value: "mcr" },
        { text: this.$t("cytologyReport.reaction.mcd"), value: "mcd" },
        { text: this.$t("cytologyReport.reaction.sevinf"), value: "sevinf" },
        { text: this.$t("cytologyReport.reaction.hemo"), value: "hemo" }
      ],
      reorganisationOptions: [
        { text: this.$t("cytologyReport.reorganisation.cem"), value: "cem" },
        { text: this.$t("cytologyReport.reorganisation.met"), value: "met" },
        { text: this.$t("cytologyReport.reorganisation.hyp"), value: "hyp" }
      ],
      complementaryTechniquesOptions: [
        { text: this.$t("cytologyReport.complementaryTechniques.hpvmed"), value: "hpvmed" },
        { text: this.$t("cytologyReport.complementaryTechniques.cinmed"), value: "cinmed" },
        { text: this.$t("cytologyReport.complementaryTechniques.reflex"), value: "reflex" },
        { text: this.$t("cytologyReport.complementaryTechniques.controlExamination"), value: "controlExamination" }
      ],
      recommendationsOptions: [
        { text: this.$t("cytologyReport.recommendations.reco1"), value: "reco1" },
        { text: this.$t("cytologyReport.recommendations.reco2"), value: "reco2" },
        { text: this.$t("cytologyReport.recommendations.reco3"), value: "reco3" },
        { text: this.$t("cytologyReport.recommendations.reco4"), value: "reco4" },
        { text: this.$t("cytologyReport.recommendations.reco6"), value: "reco6" },
        { text: this.$t("cytologyReport.recommendations.reco18"), value: "reco18" },
        { text: this.$t("cytologyReport.recommendations.reco5"), value: "reco5" },
        { text: this.$t("cytologyReport.recommendations.reco7"), value: "reco7" },
        { text: this.$t("cytologyReport.recommendations.reco9"), value: "reco9" },
        { text: this.$t("cytologyReport.recommendations.reco10"), value: "reco10" },
        { text: this.$t("cytologyReport.recommendations.reco12"), value: "reco12" },
        { text: this.$t("cytologyReport.recommendations.reco13"), value: "reco13" },
        { text: this.$t("cytologyReport.recommendations.reco14"), value: "reco14" },
        { text: this.$t("cytologyReport.recommendations.reco8"), value: "reco8" },
        { text: this.$t("cytologyReport.recommendations.reco16"), value: "reco16" },
        { text: this.$t("cytologyReport.recommendations.reco11"), value: "reco11" },
        { text: this.$t("cytologyReport.recommendations.reco17"), value: "reco17" },
        { text: this.$t("cytologyReport.recommendations.reco15"), value: "reco15" },
        { text: this.$t("cytologyReport.recommendations.reco19"), value: "reco19" }
      ]
    };
  }
};

<template>
  <div
    class="kt-healthCarePayment-edit"
    :class="{'pb-4': !moduleModeProp}"
  >
    <!-- page header -->
    <div
      v-show="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div
      v-show="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="healthCarePaymentData !== null ? healthCarePaymentData.laboratoryId : null"
            @input="laboratoryId = $event"
          />
        </b-col>

        <!-- healthCare organisation -->
        <b-col
          cols="12"
          md="4"
        >
          <InputSearch
            ref="organisationSearchInput"
            class="mb-3"

            searchedTableProp="organisations"
            searchedFieldProp="name"
            :organisationTypeNamesProp="['healthCare']"
            :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
            suggestionsKeyProp="id"
            :labelProp="$t('payments.healthCarePayments.organisation')"
            :valueProp="organisation"
            :displayedValueProp="suggestionFormatOrganisation(organisation)"
            :suggestionFormatProp="suggestionFormatOrganisation"

            chooseComponentPathProp="views/Data/Organisations/OrganisationChoose.vue"
            :chooseOptionsProp="{filterIsPrescribing: false, filterOrganisationTypeName: 'healthCare'}"
            :initialFilterValuesProp="{}"

            :stateProp="organisationState"
            :invalidFeedbackProp="organisationInvalidFeedback"
            @onUpdateParent="organisation = $event"
            @onActivateValidation="organisationValidation = true"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>

        <!-- divider -->
        <b-col cols="12"></b-col>

        <!-- bank -->
        <b-col
          cols="12"
          md="4"
        >
          <InputSearch
            searchedTableProp="banks"
            searchedFieldProp="code"
            :searchedLaboratoriesProp="[]"
            :returnFieldsArrayProp="['id', 'code', 'name']"
            suggestionsKeyProp="id"
            :valueProp="bank"
            :displayedValueProp="bank ? bank.code : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
            }"
            :searchMinLengthProp="1"

            :labelProp="$t('payments.healthCarePayments.bank')"
            :stateProp="bankState"
            :invalidFeedbackProp="bankInvalidFeedback"

            @onUpdateParent="onBankChange($event)"
            @onActivateValidation="bankValidation = true"
            @onDisableValidation="bankValidation = false;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- paymentDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.healthCarePayments.paymentDate')"
            :invalid-feedback="paymentDateInvalidFeedback"
            :state="paymentDateState"
          >
            <b-form-input
              v-model="paymentDate"
              type="date"
              :state="paymentDateState"
              @blur="paymentDateValidation = true"
              @change="refreshReference"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- orderDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.healthCarePayments.orderDate')"
            :invalid-feedback="orderDateInvalidFeedback"
            :state="orderDateState"
          >
            <b-form-input
              v-model="orderDate"
              type="date"
              trim
              :state="orderDateState"
              @blur="orderDateValidation = true;"
              @change="refreshReference"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- paid -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputPrice
            :labelProp="$t('payments.healthCarePayments.paid')"
            :priceProp="paid"
            :stateProp="paidState"
            :invalidFeedbackProp="paidInvalidFeedback"
            @onUpdateParent="paid = $event"
            @onActivateValidation="paidValidation = $event"
            @submit="submitPaid($event)"
          ></InputPrice>
        </b-col>

        <!-- info badge -->
        <b-col
          v-show="reference"
          cols="12"
          class="mb-3"
        >
          <!-- reference -->
          <div class="kt-badge kt-badge--light-gray kt-badge--lg">
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('payments.paymentReports.reference') + ' : ' }}</span>{{ reference }}</span>
          </div>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InputSearch from "@shared/views/Helpers/InputSearch";
import InputPrice from "@shared/views/Helpers/InputPrice";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { SmartSelectInput, InputSearch, InputPrice },
  mixins: [userRights, error, price, date],
  props: {
    healthCarePaymentIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      healthCarePaymentId: this.healthCarePaymentIdProp,
      healthCarePaymentData: null,

      // form variables
      laboratoryId: null,
      organisation: null,
      orderDate: "",
      paymentDate: "",
      bankId: null,
      bank: null,
      paid: 0,
      reference: "",

      // is validation active
      laboratoryIdValidation: false,
      organisationValidation: false,
      orderDateValidation: false,
      paymentDateValidation: false,
      bankValidation: false,
      paidValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    organisationState: function() {
      if (!this.organisationValidation) return null;
      return !this.organisation || !this.organisation.id ? false : null;
    },
    organisationInvalidFeedback: function() {
      if (this.organisationState === null) return "";
      return this.$t("validationRules.required");
    },
    orderDateState: function() {
      return null;
    },
    orderDateInvalidFeedback: function() {
      return "";
    },
    paymentDateState: function() {
      if (!this.paymentDateValidation) return null;
      return this.paymentDate && this.paymentDate.length > 0 ? null : false;
    },
    paymentDateInvalidFeedback: function() {
      return this.paymentDateState === false ? this.$t("validationRules.required") : "";
    },
    bankState: function() {
      if (!this.bankValidation) return null;
      return this.bankId && this.bankId > 0 ? null : false;
    },
    bankInvalidFeedback: function() {
      return this.bankState === false ? this.$t("validationRules.required") : "";
    },
    paidState: function() {
      if (!this.paidValidation) return null;
      if (!this.paid) return false;
      return typeof this.paid === "number" || /^\d+\.\d{2}$/g.test(this.paid) ? null : false;
    },
    paidInvalidFeedback: function() {
      if (this.paidState === null) return "";
      if (!this.paid) return this.$t("validationRules.required");
      return this.$t("validationRules.invalidPrice");
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("payments.healthCarePaymentEdit.pageTitle", { id: this.healthCarePaymentIdProp }) : this.$t("payments.healthCarePaymentNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("payments.healthCarePaymentEdit.submitText") : this.$t("payments.healthCarePaymentNew.submitText");
    }
  },
  watch: {
    organisation: {
      handler: function() {
        this.refreshReference();
      }
    }
  },
  async mounted() {
    // import data
    await this.importData();

    // setup date
    if (this.editModeProp === false) {
      this.paymentDate = this.getIsoDate();
    }

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.laboratoryInput) {
        this.$refs.laboratoryInput.focusFirstElement();
      } else {
        this.$refs.organisationSearchInput.focusFirstElement();
      }
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get healthCarePayment
          const resServices = await commonServices.get("healthCarePayments", this.healthCarePaymentId);
          this.healthCarePaymentData = resServices.data;
          // import data
          this.laboratoryId = resServices.data.laboratoryId;
          this.organisation = resServices.data.organisation;
          this.orderDate = resServices.data.orderDate;
          this.paymentDate = resServices.data.paymentDate;
          this.bank = resServices.data.bank;
          this.bankId = resServices.data.bankId;
          this.paid = this.importFormatPrice(resServices.data.paid);
          this.reference = resServices.data.reference;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // organisation
    suggestionFormatOrganisation(organisation) {
      let output = "";
      if (organisation) {
        output = organisation.name + " (" + organisation.code + ")";
        if (organisation.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },
    // bank
    onBankChange($event) {
      this.bank = $event;
      this.bankId = (this.bank ? this.bank.id : null);
      this.refreshReference();
    },
    // paid
    async submitPaid(event) {
      this.paid = event;
      await this.submitSave();
    },
    // reference
    async refreshReference() {
      try {
        this.reference = this.$t("payments.healthCarePaymentEdit.referencePrefix");
        if (this.organisation) {
          this.reference += "_" + this.organisation.code;
        }
        if (this.bank) {
          this.reference += "_" + this.bank.code.toUpperCase();
        }
        if (this.orderDate) {
          this.reference += "_" + this.orderDate;
        }
        if (this.paymentDate && (!this.orderDate || this.orderDate !== this.paymentDate)) {
          this.reference += "_" + this.paymentDate;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // navigate to the list page
    onGoBack() {
      navigate("accounting/payments/healthCarePayments");
    },

    // submit functions
    validateForm() {
      this.laboratoryIdValidation = true;
      this.organisationValidation = true;
      this.orderDateValidation = true;
      this.paymentDateValidation = true;
      this.bankValidation = true;
      this.paidValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.organisationState === false ||
        this.orderDateState === false ||
        this.paymentDateState === false ||
        this.bankState === false ||
        this.paidState === false
      );
    },
    async saveHealthCarePayment() {
      try {
        const form = {
          laboratoryId: this.laboratoryId,
          organisationId: this.organisation.id,
          orderDate: this.orderDate || null,
          paymentDate: this.paymentDate || null,
          bankId: this.bankId,
          paid: this.exportFormatPrice(this.paid)
        };
        if (this.editMode) {
          const res = await commonServices.put("healthCarePayments", form, this.healthCarePaymentId);
          if (res.data === true) {
            // success
            this.successSave();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.healthCarePaymentEdit.notifications.editionErrorTitle"),
              message: this.$t("payments.healthCarePaymentEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("healthCarePayments", form);
          if (res.data.id) {
            // success
            this.successSave();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.healthCarePaymentNew.notifications.additionErrorTitle"),
              message: this.$t("payments.healthCarePaymentNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave() {
      // success message
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("payments.healthCarePaymentEdit.notifications.editedTitle"),
          message: this.$t("payments.healthCarePaymentEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("payments.healthCarePaymentNew.notifications.addedTitle"),
          message: this.$t("payments.healthCarePaymentNew.notifications.addedText")
        });
      }
      // go back
      if (this.moduleModeProp) {
        this.$emit("healthCarePaymentEdited");
      } else {
        navigate("accounting/payments/healthCarePayments");
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveHealthCarePayment();
    }
  }
};
</script>

<template>
  <span
    v-if="statusNameProp !== '' || (statusNameProp === '' && !isHiddenEmptyProp)"
    class="kt-badge"
    :class="'kt-badge--' + badgeVariant"
  >
    <!-- possible icons: file-person-fill, clipboard-plus, calculator-fill -->
    <span v-if="isDisplayedStatusTitleProp">{{ $t("fileStatus.badgeTitles." + typeProp) + ' : ' }}</span>
    <strong>{{ statusNameProp !== "" ? $t("fileStatus." + typeProp + "." + statusNameProp) : $t("fileStatus.noStatusBadgeText") }}</strong>
  </span>
</template>

<script>
export default {
  props: {
    // can be "filingStatus", "medicalStatus", "accountingStatus"
    typeProp: {
      type: String,
      default: ""
    },
    // ex : "created", "validatedForDiagnosis", etc...
    statusNameProp: {
      type: String,
      default: ""
    },
    // display the status title e.g. "accounting :"
    isDisplayedStatusTitleProp: {
      type: Boolean
    },
    // hide if no status value
    isHiddenEmptyProp: {
      type: Boolean
    }
  },
  data() {
    return {

    };
  },
  computed: {
    badgeVariant: function() {
      let badgeVariant = "light-gray";

      if (this.typeProp === "filingStatus") {
        switch (this.statusNameProp) {
        case "created":
          // default value
          break;
        case "errorInRequest":
          badgeVariant = "red";
          break;
        case "validatedForDiagnosis":
          badgeVariant = "blue";
          break;
        case "validatedForAccounting":
          badgeVariant = "light-green";
          break;
        case "cancelled":
          badgeVariant = "red";
          break;
        }
      } else if (this.typeProp === "medicalStatus") {
        switch (this.statusNameProp) {
        case "created":
          // default value
          break;
        case "validatedTechnically":
          badgeVariant = "blue";
          break;
        case "validatedMedically":
          badgeVariant = "light-green";
          break;
        case "cancelled":
          badgeVariant = "red";
          break;
        }
      } else if (this.typeProp === "accountingStatus") {
        switch (this.statusNameProp) {
        case "created":
          // default value
          break;
        case "invoiced":
          badgeVariant = "yellow";
          break;
        case "paid":
          badgeVariant = "light-green";
          break;
        case "cancelled":
          badgeVariant = "red";
          break;
        }
      }

      return badgeVariant;
    }
  },
  methods: {

  }
};
</script>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  isLoggedIn() {
    return axiosServices.axios.get("/userSessions/isLoggedIn");
  },
  login(credentials) {
    return axiosServices.axios.post("/userSessions/login", credentials);
  },
  sendSmsToken(credentials) {
    return axiosServices.axios.post("/userSessions/sendSmsToken", credentials);
  },
  authenticate(credentials) {
    return axiosServices.axios.post("/userSessions/authenticate", credentials);
  },
  logout() {
    return axiosServices.axios.post("/userSessions/logout");
  },
  forgotPassword(email) {
    return axiosServices.axios.post("/userSessions/forgotPassword", { email: email });
  },
  generateSecret() {
    return axiosServices.axios.post("/userSessions/generateSecret");
  }
};

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  showAllFilePayments(page, filter, sort) {
    return axiosServices.axios.get("/healthCarePayments/showAllFilePayments", {
      params: {
        page: page,
        filter: filter,
        sort: sort
      }
    });
  }
};

<template>
  <div class="kt-sample-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- sample form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- laboratories -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('samples.laboratories')"
            :invalid-feedback="sampleLaboratoriesInvalidFeedback"
            :state="sampleLaboratoriesState"
          >
            <b-form-checkbox
              v-for="sampleLaboratory in sampleLaboratoriesOptions"
              v-show="sampleLaboratory.isVisible"
              :ref="sampleLaboratory.isVisible ? 'laboratoryInputs' : null"
              :key="sampleLaboratory.id"
              v-model="sampleLaboratories"
              :value="sampleLaboratory.id"
              :disabled="sampleLaboratory.disabled"
              :state="sampleLaboratoriesState"
              switch
              class="mr-n2"
            >
              {{ sampleLaboratory.formattedName }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- sectors -->
        <b-col cols="12">
          <b-form-group
            v-if="sectorsOptions.length"
            :label="$t('samples.sectors')"
            :invalid-feedback="sectorsInvalidFeedback"
            :state="sectorsState"
          >
            <b-form-checkbox
              v-for="sector in sectorsOptions"
              v-show="sector.isSubscribed || (sampleData && sampleData.sampleSectors.filter(v => v.sectorId === sector.id).length > 0)"
              :key="sector.id"
              v-model="sectors"
              :value="sector.id"
              :state="sectorsState"
            >
              {{ sector.localisedName }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('samples.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('samples.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              v-model="name"
              trim
              maxlength="256"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error],
  props: {
    sampleIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      sampleId: this.sampleIdProp,
      sampleData: null,
      // form variables
      sampleLaboratories: [],
      sectors: [],
      name: "",
      code: "",
      // is validation active
      sampleLaboratoriesValidation: false,
      sectorsValidation: false,
      nameValidation: false,
      codeValidation: false,
      // options
      sampleLaboratoriesOptions: [],
      sectorsOptions: []
    };
  },
  computed: {
    // form validation
    sampleLaboratoriesState: function() {
      if (!this.sampleLaboratoriesValidation) return null;
      return this.sampleLaboratories && this.sampleLaboratories.length > 0 ? null : false;
    },
    sampleLaboratoriesInvalidFeedback: function() {
      return this.sampleLaboratoriesState === false ? this.$t("validationRules.required") : "";
    },
    sectorsState: function() {
      if (!this.sectorsValidation) return null;
      return this.sectors.length > 0 ? null : false;
    },
    sectorsInvalidFeedback: function() {
      return this.sectorsState === false ? this.$t("validationRules.required") : "";
    },
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("sampleEdit.pageTitle") : this.$t("sampleNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("sampleEdit.submitText") : this.$t("sampleNew.submitText");
    }
  },
  async mounted() {
    // import data
    this.importSectors();
    await this.importData();
    this.importLaboratories();

    // select all active laboratories by default
    if (!this.editModeProp) {
      const filteredLaboratories = this.sampleLaboratoriesOptions.filter((laboratory) => {
        return !laboratory.disabled && laboratory.isActive && laboratory.isVisible;
      });
      this.sampleLaboratories = filteredLaboratories.map((laboratory) => {
        return laboratory.id;
      });
    }

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.sampleLaboratories && this.sampleLaboratories.length) {
        // has selected laboratories
        for (const laboratoryInput of this.$refs.laboratoryInputs) {
          if (this.sampleLaboratories.includes(laboratoryInput.value)) {
            if (laboratoryInput.disabled === false) {
              laboratoryInput.focus();
              break;
            }
          }
        }
      } else {
        // empty laboratories
        if (this.$refs.laboratoryInputs.length) {
          this.$refs.laboratoryInputs[0].focus();
        }
      }
    },

    // import data
    async importData() {
      try {
        if (this.editModeProp) {
          // get sample
          const resServices = await commonServices.get("samples", this.sampleId);
          this.sampleData = resServices.data;
          // import data
          this.sampleLaboratories = resServices.data.sampleLaboratories.map((sampleLaboratory) => {
            return sampleLaboratory.laboratoryId;
          });
          this.sectors = resServices.data.sampleSectors.map((v) => v.sectorId);
          this.name = resServices.data.name;
          this.code = resServices.data.code;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    importLaboratories() {
      this.sampleLaboratoriesOptions = JSON.parse(JSON.stringify(this.$systemSettings.laboratories));
      for (const sampleLaboratory of this.sampleLaboratoriesOptions) {
        sampleLaboratory.isVisible = true;
        if (sampleLaboratory.isActive === false) {
          // if deleted : laboratory not visible
          sampleLaboratory.isVisible = false;
          sampleLaboratory.disabled = true;
          // check is selected : laboratory visible
          for (const selectedSampleLaboratory of this.sampleLaboratories) {
            if (selectedSampleLaboratory === sampleLaboratory.id) {
              sampleLaboratory.isVisible = true;
              sampleLaboratory.name += " (" + this.$t("general.deletedLabel") + ")";
              break;
            }
          }
        }
      }
    },
    importSectors() {
      this.sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
    },
    // navigate to the entry page
    onGoBack() {
      navigate("data/samples");
    },
    // submit
    onValidateSample() {
      this.sampleLaboratoriesValidation = true;
      this.sectorsValidation = true;
      this.nameValidation = true;
      this.codeValidation = true;

      return !(
        this.sampleLaboratoriesState === false ||
        this.sectorsState === false ||
        this.nameState === false ||
        this.codeState === false
      );
    },
    async onSaveSample() {
      try {
        const form = {
          name: this.name,
          code: this.code,
          sampleSectors: this.sectors,
          sampleLaboratories: this.sampleLaboratories
        };
        if (this.editMode) {
          const res = await commonServices.put("samples", form, this.sampleId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("sampleEdit.notifications.editedTitle"),
              message: this.$t("sampleEdit.notifications.editedText")
            });
            navigate("data/samples");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("sampleEdit.notifications.editionErrorTitle"),
              message: this.$t("sampleEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("samples", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("sampleEdit.notifications.addedTitle"),
              message: this.$t("sampleEdit.notifications.addedText")
            });
            navigate("data/samples");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("sampleEdit.notifications.additionErrorTitle"),
              message: this.$t("sampleEdit.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onSubmit() {
      if (this.onValidateSample() !== false) this.onSaveSample();
    }
  }
};
</script>

<template>
  <div
    class="kt-transformation-badge"
    :class="'kt-transformation-badge--' + $systemSettings.theme"
    :style="{display: componentDisplayStyle}"
  >
    <!-- from transformation info -->
    <div v-if="fileInvoice.targetFileInvoice_fileInvoiceTransformation">
      <!-- badge -->
      <div
        class="kt-badge kt-badge--blue kt-badge--lg"
      >
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ hideDetailsProp ? $t('fileInvoiceTransformationBadge.fileInvoiceFromTransformedMessageShort') : $t('fileInvoiceTransformationBadge.fileInvoiceFromTransformedMessage' + (isReportProp ? "Report" : "")) }}</span></span>
      </div>
      <!-- details -->
      <div
        v-if="!hideDetailsProp"
        class="kt-transformation-badge__transformations"
      >
        <!-- origin invoice info -->
        <span class="kt-transformation-badge__transformation-info kt-transformation-badge__transformation-info--from">
          <span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformationBadge.fromTransformationTitle') }}</span>
          <span>{{ ' ' + fileInvoice.targetFileInvoice_fileInvoiceTransformation.invoiceTypeLocalisedName }}</span>
          <span v-if="fileInvoice.targetFileInvoice_fileInvoiceTransformation.originOrganisation">{{ ' '
            + $t("general.emptyWithHyphen") + ' '
            + fileInvoice.targetFileInvoice_fileInvoiceTransformation.originOrganisation.name
            + (fileInvoice.targetFileInvoice_fileInvoiceTransformation.originOrganisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "")
          }}</span>
          <span>{{ ' ' + $t("general.emptyWithHyphen") + ' ' + $d(new Date(fileInvoice.targetFileInvoice_fileInvoiceTransformation.transformationDate), "date") }}</span>
          <!-- view Invoice -->
          <b-button
            size="sm"
            class="kt-btn-transparent p-0 ml-1 align-baseline"
            :variant="$systemSettings.theme"
            @click="goToInvoice(fileInvoice.targetFileInvoice_fileInvoiceTransformation.originFileInvoice)"
          >
            {{ $t('general.view') }}
          </b-button>
        </span>
      </div>
    </div>
    <!-- transformation info -->
    <div v-if="fileInvoice.fileInvoiceTransformations && fileInvoice.fileInvoiceTransformations.length">
      <!-- badge -->
      <div class="kt-badge kt-badge--yellow kt-badge--lg">
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ hideDetailsProp ? $t('fileInvoiceTransformationBadge.fileInvoiceTransformedMessageShort') : $t('fileInvoiceTransformationBadge.fileInvoiceTransformedMessage' + (isReportProp ? "Report" : "")) }}</span></span>
      </div>
      <!-- details -->
      <div
        v-if="!hideDetailsProp"
        class="kt-transformation-badge__transformations"
      >
        <!-- target invoice info -->
        <span
          v-for="fileInvoiceTransformation in fileInvoice.fileInvoiceTransformations"
          :key="fileInvoiceTransformation.id"
          class="kt-transformation-badge__transformation-info kt-transformation-badge__transformation-info--to"
        >
          <span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformationBadge.transformationTitle') }}</span>
          <span>{{ ' ' + fileInvoiceTransformation.invoiceTypeLocalisedName }}</span>
          <span v-if="fileInvoiceTransformation.targetOrganisation">{{ ' '
            + $t("general.emptyWithHyphen") + ' '
            + fileInvoiceTransformation.targetOrganisation.name
            + (fileInvoiceTransformation.targetOrganisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "")
          }}</span>
          <span>{{ ' ' + $t("general.emptyWithHyphen") + ' ' + $d(new Date(fileInvoiceTransformation.transformationDate), "date") }}</span>
          <!-- view Invoice -->
          <b-button
            v-if="!fileInvoiceTransformation.targetFileInvoice.transformationNotInvoicedYet"
            size="sm"
            class="kt-btn-transparent p-0 ml-1 align-baseline"
            :variant="$systemSettings.theme"
            @click="goToInvoice(fileInvoiceTransformation.targetFileInvoice)"
          >
            {{ $t('general.view') }}
          </b-button>
          <!-- tranfromation Not Invoiced Yet -->
          <span
            v-if="fileInvoiceTransformation.targetFileInvoice.transformationNotInvoicedYet"
          >
            {{ $t('fileInvoiceTransformationBadge.transformationNotInvoicedYet') }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  props: {
    fileInvoiceProp: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isReportProp: {
      type: Boolean
    },
    hideDetailsProp: {
      type: Boolean
    },
    cssDisplayProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fileInvoice: this.fileInvoiceProp || {}
    };
  },
  computed: {
    componentDisplayStyle() {
      let componentDisplayStyle = this.cssDisplayProp;

      const isComponentDisplayed = this.fileInvoice.targetFileInvoice_fileInvoiceTransformation ||
        (this.fileInvoice.fileInvoiceTransformations && this.fileInvoice.fileInvoiceTransformations.length);
      if (!isComponentDisplayed) {
        componentDisplayStyle = "none !important";
      }

      return componentDisplayStyle;
    }
  },
  watch: {
    fileInvoiceProp: {
      handler(value) {
        this.fileInvoice = value;
      },
      deep: true
    }
  },
  methods: {
    goToInvoice(targetFileInvoice) {
      if (targetFileInvoice.careSheetId) {
        navigate("accounting/invoices/careSheet/view", { careSheetIdProp: targetFileInvoice.careSheetId });
      } else if (targetFileInvoice.feeNoteId) {
        navigate("accounting/invoices/feeNote/view", { feeNoteIdProp: targetFileInvoice.feeNoteId });
      } else if (targetFileInvoice.invoiceReportId) {
        navigate("accounting/invoices/invoiceReport/view", { invoiceReportIdProp: targetFileInvoice.invoiceReportId });
      }
    }
  }
};
</script>

<style scoped src="./FileInvoiceTransformationBadge.scss" lang="scss"></style>

<template>
  <div class="kt-containerGroupTasks">
    <!-- containerGroupTasks - list -->
    <div class="kt-item-list">
      <div
        v-for="(taskItem, index) in containerGroupTasks"
        :key="taskItem.id"
        class="kt-item-list__item-wrapper"
        style="width: 100%"
      >
        <div
          class="kt-item-list__item"
          style="width: 100%"
        >
          <!-- actions -->
          <div class="kt-item-list__item-actions">
            <!-- delete button -->
            <b-button
              v-if="!viewModeProp"
              v-show="isEnabled(['menu', 'office', 'files', 'containerGroupTasks', 'delete'])"
              :ref="'deleteTaskButton' + index"
              class="btn-icon kt-item-list__item-delete"
              size="sm"
              variant="light"
              squared
              @click="onDeleteTask(taskItem.id)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>

          <!-- view each task -->
          <TaskView
            :taskIdProp="taskItem.taskId"
            :moduleModeProp="true"
            :displayedFieldsProp="[
              'sector',
              'code',
              'description'
            ]"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></TaskView>

          <!-- divider -->
          <div
            v-if="taskItem.number"
            class="w-100 border-top mt-3 mb-3"
          ></div>

          <!-- number -->
          <b-row v-if="taskItem.number">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6 mt-1 mb-0">
                {{ $t("tasks.number") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <div
                class="kt-badge kt-badge--light-gray mt-n1"
              >
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ taskItem.number }}</span></span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- add a task -->
    <b-button
      v-if="!viewModeProp"
      v-show="sectorIdProp !== 1 || containerGroupTasks.length === 0"
      ref="addTaskButton"
      v-b-modal="'chooseTaskModal' + _uid"
      :style="{'margin-top': containerGroupTasks.length ? '2px' : ''}"
      size="sm"
      :variant="'outline-' + $systemSettings.theme"
      pill
    >
      <b-icon icon="plus"></b-icon>
      {{ $t("containerGroupTasks.addButton") }}
    </b-button>

    <!-- Task Choose Modal -->
    <b-modal
      v-if="!viewModeProp"
      :id="'chooseTaskModal' + _uid"
      :title="$t('taskChoose.pageTitle')"
      hide-footer
      size="xl"
      @shown="() => {
        $refs.taskChooseComponent.focusFirstElement();
      }"
      @hidden="focusAddTask"
    >
      <TaskChoose
        ref="taskChooseComponent"
        :sectorIdProp="sectorIdProp"
        :fileCreationDateProp="fileCreationDateProp"
        @chosen="onTaskChosen"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import TaskView from "@/views/Data/Tasks/TaskView";
import TaskChoose from "@/views/Data/Tasks/TaskChoose";
// helpers
import userRights from "@/services/UI/userRights";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    TaskView,
    TaskChoose
  },
  mixins: [userRights],
  props: {
    containerGroupTasksProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    viewModeProp: {
      type: Boolean
    },
    fileCreationDateProp: {
      type: String,
      default: ""
    },
    sectorIdProp: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {
      // displayed list
      containerGroupTasks: []
    };
  },
  watch: {
    containerGroupTasksProp: {
      handler(val) {
        if (val && val.length > 0) {
          this.containerGroupTasks = JSON.parse(JSON.stringify(val));
          // reset the items id
          for (var i in this.containerGroupTasks) {
            this.containerGroupTasks[i].id = Number(uniqueId());
          }
        } else {
          this.containerGroupTasks = [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    // Helpers
    getTaskDisplayedPosition(id) {
      for (let i = 0; i < this.containerGroupTasks.length; i++) {
        if (this.containerGroupTasks[i].id === id) return i;
      }
      return null;
    },
    focusAddTask() {
      this.$nextTick(() => {
        if (this.sectorIdProp !== 1 || this.containerGroupTasks.length === 0) {
          this.$refs.addTaskButton.focus();
        } else {
          this.$refs["deleteTaskButton" + (this.containerGroupTasks.length - 1)][0].focus();
        }
      });
    },
    // Actions
    onDeleteTask(targetId) {
      // get the displayed position
      const displayedPosition = this.getTaskDisplayedPosition(targetId);
      this.containerGroupTasks.splice(displayedPosition, 1);
      this.sendToparent();
      this.focusAddTask();
    },
    onTaskChosen(targetTask, number) {
      // validate
      if (!targetTask) return false;
      const id = Number(uniqueId());
      this.containerGroupTasks.push({
        id: id,
        taskId: targetTask.id,
        task: targetTask,
        number: number
      });
      // close modal
      this.$bvModal.hide("chooseTaskModal" + this._uid);

      this.sendToparent();
    },
    sendToparent() {
      this.$emit("containerGroupTasksUpdate", this.containerGroupTasks);
    }
  }
};
</script>

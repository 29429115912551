<template>
  <div class="kt-healthCarePayment-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("payments.healthCarePaymentLinesEdit.pageTitle") }}
      </h2>
      <!-- reference -->
      <span
        v-show="reference"
        class="kt-badge ml-3"
        :class="'kt-badge--light-gray'"
      >
        <span class="kt-badge__text font-weight-semi-bold">{{ reference }}</span>
      </span>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-3 pb-1"></div>

    <!-- healthCarePayment headers -->
    <div class="kt-view-page__body pb-1">
      <!-- divided inforation -->
      <div class="kt-divided-information">
        <div class="kt-divided-information__wrapper">
          <!-- healthCarePayment info -->
          <div class="kt-divided-information__section">
            <div class="kt-divided-information__section-content font-weight-semi-bold">
              {{ $t("payments.healthCarePaymentLinesEdit.healthCarePaymentSectionTitle") }}
            </div>
            <!-- organisation -->
            <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
              <span class="mr-3">{{ organisation ? organisation.name + " (" + organisation.code + ")" + (organisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : "" }}</span>
            </div>
          </div>
          <!-- paid -->
          <div class="kt-divided-information__section kt-divided-information__section--amount border-left">
            <div class="kt-divided-information__section-title">
              {{ $t('payments.healthCarePayments.paid') }}
            </div>
            <div
              class="kt-divided-information__section-content kt-divided-information__color-secondary"
              :class="{'text-danger': !paid}"
            >
              {{ $n((paid || 0), "currency") }}
            </div>
          </div>
          <!-- reconciled -->
          <div class="kt-divided-information__section kt-divided-information__section--progress border-left">
            <div class="kt-divided-information__section-title">
              {{ $t('payments.healthCarePayments.reconciled') }}
            </div>
            <!-- reconciled progress bar -->
            <ProgressBar
              :valueProp="reconciled"
              :totalProp="paid"
            />
          </div>
          <!-- actions -->
          <div class="kt-divided-information__section kt-divided-information__section--actions border-left">
            <!-- option drop-down -->
            <div
              v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'edit']) ||
                isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'toPdf']) ||
                isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'toXlsx'])"
              class="kt-dropdown-btn kt-dropdown-btn--no-arrow"
            >
              <b-button
                ref="healthCarePaymentActionsDropdown"
                variant="outline-secondary"
                size="sm"
                class="btn-icon kt-dropdown-btn__switcher-btn"
                pill
              >
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
              <ul
                class="kt-dropdown-btn__list"
              >
                <li class="kt-dropdown-btn__shadow"></li>

                <!-- edit healthCarePayment -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'edit'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onEditHealthCarePayment"
                  >
                    <b-icon
                      icon="pen"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.healthCarePayments.editHealthCarePayment") }}
                  </button>
                </li>
                <!-- export healthCarePayment PDF -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'toPdf'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onExportHealthCarePayment('pdf')"
                  >
                    <b-icon
                      icon="download"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.healthCarePayments.downloadPdf") }}
                  </button>
                </li>
                <!-- export healthCarePayment XLS -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'toXlsx'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onExportHealthCarePayment('xlsx')"
                  >
                    <b-icon
                      icon="table"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.healthCarePayments.downloadXlsx") }}
                  </button>
                </li>

                <!-- <li class="kt-dropdown-btn__divider"></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-3 mb-3"></div>
    <!-- table title -->
    <b-row class="mb-1">
      <b-col
        cols="12"
        class="mb-3"
      >
        <!-- title -->
        <h2
          class="kt-page-header__title h4"
          style="margin-bottom: 2px !important;"
        >
          {{ $t("payments.healthCarePaymentLinesEdit.tableTitle") }}
        </h2>
        <!-- View lines Button -->
        <b-button
          ref="viewLinesButton"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === null ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode(null)"
        >
          <b-icon icon="list"></b-icon>
          {{ $t("payments.healthCarePaymentLinesEdit.viewPaymentLineButton") }}
          <span class="kt-count-bubble"><span class="kt-count-bubble__number">{{ filePaymentCount }}</span></span>
        </b-button>
        <!-- Add line Button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'addLine'])"
          ref="addLineButton"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === 'add' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode('add')"
        >
          <b-icon icon="plus"></b-icon>
          {{ $t("payments.healthCarePaymentLinesEdit.addPaymentLineButton") }}
        </b-button>
        <!-- Add group Button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'addGroup'])"
          ref="addGroupButton"
          class="ml-1"
          size="sm"
          :variant="'outline-' + $systemSettings.theme"
          @click="addHealthCarePaymentGroup(0)"
        >
          <b-icon icon="plus"></b-icon>
          {{ $t("payments.healthCarePaymentLinesEdit.addPaymentGroupButton") }}
        </b-button>
      </b-col>
    </b-row>

    <!-- lines table -->
    <b-table
      ref="linesTable"
      class="kt-table"
      tbody-tr-class="kt-paymentLine"
      style="margin-bottom: 2px !important;"
      :fields="tableFields"
      :items="lines"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact="focusFirstLine"
        >
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="searchAccessNumber"
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter RegNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputRegistrationNumber
              class="kt-table__th-search-regNumber"
              :regNumberProp="filterRegNumber"
              @onUpdateParent="filterRegNumber = $event.regNumber"
              @submit="submitSearchInput"
              @input="onFilterChange"
            ></InputRegistrationNumber>
            <b-button
              v-show="filterRegNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterRegNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
              @keydown.tab.exact="(e) => {
                if (currentActiveMode !== 'add') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter CollectionDates -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Acts -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            :style="{'width': (currentActiveMode === null ? '39px' : '85px')}"
            @keydown.tab.exact="(e) => {
              if (currentActiveMode === 'add') {
                e.preventDefault();
                focusFirstLine();
              }
            }"
          >
            <b-form-checkbox
              v-show="currentActiveMode === 'add'"
              v-model="filterPaidFiles"
              v-b-tooltip.hover
              switch
              :title="$t('payments.healthCarePaymentLinesEdit.filterPaidFiles')"
              @input="onPageInput"
            >
              {{ $t("payments.healthCarePaymentLinesEdit.filterPaidFilesShort") }}
            </b-form-checkbox>
          </b-th>
        </b-tr>
      </template>

      <!-- accessNumber / group header -->
      <template v-slot:cell(accessNumber)="data">
        <!-- healthCarePaymentGroup header -->
        <div
          v-if="data.item.healthCarePaymentGroup"
          class="kt-paymentGroup"
          :class="{'kt-paymentGroup--active': currentActiveGroupId === data.item.healthCarePaymentGroup.id}"
          @click="currentActiveGroupId = data.item.healthCarePaymentGroup.id"
        >
          <div class="kt-paymentGroup__middle">
            <div>
              <b-form-radio
                v-model="currentActiveGroupId"
                :value="data.item.healthCarePaymentGroup.id"
                class="kt-paymentGroup__radio"
                :name="$t('payments.healthCarePaymentLinesEdit.activeGroup')"
              ></b-form-radio>
              <span class="font-weight-bold mr-4">{{ $t('payments.healthCarePaymentLinesEdit.groupTitle') }}</span>
            </div>
            <div style="min-width: 86px;">
              <InputPrice
                :ref="'groupPaidInput_' + data.index"
                class="kt-paymentGroup__input mr-3"
                labelProp=""
                :priceProp="data.item.healthCarePaymentGroup.paid"
                :stateProp="null"
                invalidFeedbackProp=""
                inputAutoWidthProp
                @change="editHealthCarePaymentGroup($event, data.item.healthCarePaymentGroup.id)"
                @submit="editHealthCarePaymentGroup($event, data.item.healthCarePaymentGroup.id)"
                @click.stop
              ></InputPrice>
            </div>
            <div>
              <ProgressBar
                class="kt-paymentGroup__progress-bar"
                :valueProp="data.item.healthCarePaymentGroup.reconciled"
                :totalProp="data.item.healthCarePaymentGroup.paid"
              />
            </div>
          </div>
          <div
            class="kt-paymentGroup__end"
          >
            <!-- option drop-down
            :style="{width: healthCarePaymentData && healthCarePaymentData.healthCarePaymentGroups.length > 1 ? '74px' : '32px'}"
            <div
              v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'groupToPdf']) ||
                isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'groupToXlsx'])"
              class="kt-dropdown-btn kt-dropdown-btn--right kt-dropdown-btn--no-arrow"
            >
              variant="kt-dropdown-btn--success"
              <b-button
                variant="outline-secondary"
                size="sm"
                class="btn-icon kt-dropdown-btn__switcher-btn kt-paymentGroup__end__btn mr-1"
                pill
              >
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
              <ul
                class="kt-dropdown-btn__list"
                style="margin-top: 3px;"
              >
                <li class="kt-dropdown-btn__shadow"></li>

                export paymentGroup PDF
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'groupToPdf'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click.stop="onExportPaymentGroup(data.item.healthCarePaymentGroup.id, 'pdf')"
                  >
                    <b-icon
                      icon="download"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReportsPaymentGroupsEdit.downloadPaymentGroupPdf") }}
                  </button>
                </li>
                export paymentGroup XLSX
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'groupToXlsx'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click.stop="onExportPaymentGroup(data.item.healthCarePaymentGroup.id, 'xlsx')"
                  >
                    <b-icon
                      icon="table"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReportsPaymentGroupsEdit.downloadPaymentGroupXlsx") }}
                  </button>
                </li>
              </ul>
            </div> -->
            <!-- delete -->
            <b-button
              v-show="healthCarePaymentData && healthCarePaymentData.healthCarePaymentGroups.length > 1 && isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'deleteGroup'])"
              class="kt-paymentGroup__end__btn"
              size="sm"
              :variant="$systemSettings.theme"
              pill
              @click.stop="onDeleteGroup(data.item.healthCarePaymentGroup.id)"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
        </div>
        <!-- accessNumber -->
        <div
          v-else
          style="display: flex; align-items: center;"
        >
          <b-link
            :variant="$systemSettings.theme"
            class=""
            :href="data.item.fileId ? '/office/file/view/' + data.item.fileId : '#'"
            @click.prevent="navigate('office/file/view', {
              fileIdProp: data.item.fileId,
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            {{ data.item.file.accessNumber }}
            <!-- complementary -->
            <span
              v-show="data.item.file.parentFileId"
              style="font-size: 14px; margin: 0 0 1px 3px; color: rgb(59, 72, 83);"
            >
              <b-icon
                icon="back"
              ></b-icon>
            </span>
          </b-link>
        </div>
      </template>

      <!-- file.patient.regNumber -->
      <template v-slot:cell(regNumber)="data">
        <div v-show="data.item.file.patient.regNumber">
          {{ regNumberFormatter(data.item.file.patient.regNumber) }}
        </div>
        <div v-show="data.item.file.patient.insureeRegNumber">
          {{ regNumberFormatter(data.item.file.patient.insureeRegNumber) + " " + $t("patients.insureeTag") }}
        </div>
      </template>

      <!-- invoiceActs -->
      <template v-slot:cell(fileInvoices)="data">
        <div class="kt-paymentLine-actsTable">
          <div
            v-for="(fileInvoice, fileInvoiceIndex) in data.item.fileInvoices"
            :key="fileInvoice.id"
          >
            <div>
              <!-- transformed badge -->
              <div
                v-show="fileInvoice.fileInvoiceTransformations && fileInvoice.fileInvoiceTransformations.length"
                class="kt-badge kt-badge--yellow"
              >
                <b-icon
                  icon="arrow-left-right"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fullyTransformed') }}</span></span>
              </div>
              <!-- from transformation badge -->
              <div
                v-show="fileInvoice.targetFileInvoice_fileInvoiceTransformation"
                class="kt-badge kt-badge--blue"
              >
                <b-icon
                  icon="arrow-left-right"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fromTransformation') }}</span></span>
              </div>
            </div>
            <!-- invoiceActs table -->
            <div
              v-if="fileInvoice.invoiceActs && fileInvoice.invoiceActs.length"
              class="kt-flex-table"
            >
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('payments.healthCarePayments.filePayments')"
              >
                <!-- header line -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--header"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="columnheader"
                  >
                    {{ $t('payments.healthCarePayments.actCode') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.healthCarePayments.invoiced') }}
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row text-success"
                    role="columnheader"
                  >
                    {{ $t('payments.healthCarePayments.previouslyPaid') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.healthCarePayments.paidShort') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'text-danger': fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0)}"
                    role="columnheader"
                  >
                    {{ $t('payments.healthCarePayments.remaining') }}
                  </div>
                </div>
                <!-- content lines -->
                <div
                  v-for="(invoiceAct, index) in fileInvoice.invoiceActs"
                  :key="invoiceAct.id + '_' + data.item.paid"
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ invoiceAct.act.code }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ $n(invoiceAct.invoiced || 0, "currency") }}
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row text-success"
                    role="cell"
                  >
                    {{ invoiceAct.previouslyPaid ? $n(invoiceAct.previouslyPaid, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                  <div
                    class="kt-flex-table__row py-0"
                    role="cell"
                  >
                    <!-- @onActivateValidation="paidValidation = $event" -->
                    <InputPrice
                      :ref="data.index === 0 && fileInvoiceIndex === 0 && index === 0 ? 'paidInput_' + data.index + '_' + fileInvoiceIndex + '_' + index : null"
                      class="kt-paymentLine-actPriceInput"
                      labelProp=""
                      :priceProp="invoiceAct.paymentAct.paid"
                      :stateProp="invoiceAct.paymentAct.paid ? null : false"
                      invalidFeedbackProp=""
                      @submit="submitPaymentAct(data.index, fileInvoiceIndex, index, $event)"
                      @change="editPaymentAct(data.index, fileInvoiceIndex, index, $event)"
                    ></InputPrice>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'text-danger': (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid) !== 0}"
                    role="cell"
                  >
                    {{ (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid) !== 0 ? $n((invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                </div>
                <!-- Last line (Totals) -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $t('actsTable.total') }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.invoiced, 0), "currency") }}</span>
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row text-success"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.previouslyPaid, "currency") }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.paymentAct.paid, 0), "currency") }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'text-danger': fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0) !== 0}"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0) !== 0 ? $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0), "currency") : $t("general.emptyWithHyphen") }}</span>
                  </div>
                </div>
              </div>
              <!-- save line button -->
              <b-button
                v-if="fileInvoiceIndex === (data.item.fileInvoices.length - 1)"
                :ref="'savePaymentLineButton_' + data.item.id"
                class="d-none"
                :variant="$systemSettings.theme"
                size="sm"
                @click="saveLine(data.index)"
              >
                {{ $t("payments.healthCarePaymentLinesEdit.saveLineButton") }}
              </b-button>
            </div>
          </div>
        </div>
      </template>

      <!-- actions -->
      <template v-slot:cell(actionButtons)="data">
        <div
          v-show="!data.item.healthCarePaymentGroup"
          :style="currentActiveMode === 'add' ? 'width: 85px;' : 'width: 39px;'"
        >
          <!-- delete -->
          <b-button
            v-show="currentActiveMode === null && isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'deleteLine'])"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onDeleteLine(data.item.id)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
          <!-- add -->
          <b-button
            v-show="currentActiveMode === 'add' && isEnabled(['menu', 'accounting', 'payments', 'healthCarePayments', 'addLine'])"
            style="white-space: nowrap;"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onAddLine(data.index)"
          >
            <b-icon icon="plus"></b-icon>
            {{ $t("general.add") }}
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h5
      v-show="showNoResult"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h5>

    <!-- maxReached -->
    <div
      v-show="currentActiveMode === 'add' && maxReached && !loading"
      class="text-center mt-3"
    >
      <b-button
        variant="outline-secondary"
        :disabled="loadingExtendSearch"
        @click="extendSearch"
      >
        <b-icon icon="chevron-down"></b-icon>
        <span>&nbsp;{{ $t('general.extendResults') }}</span>
      </b-button>
      <b-spinner
        v-show="loadingExtendSearch"
        class="ml-1"
        :class="'kt-spinner--' + $systemSettings.theme"
        small
      ></b-spinner>
    </div>

    <!-- editHealthCarePayment Modal -->
    <b-modal
      id="editHealthCarePaymentModal"
      hide-footer
      :title="$t('payments.healthCarePaymentEdit.pageTitle')"
      size="lg"
      @shown="() => {
        $refs.healthCarePaymentEditComponent.focusFirstElement()
      }"
      @hidden="() => {
        if ($refs.healthCarePaymentActionsDropdown) {
          $refs.healthCarePaymentActionsDropdown.click();
          $nextTick(() => {
            $refs.healthCarePaymentActionsDropdown.focus();
          });
        }
      }"
    >
      <HealthCarePaymentEdit
        ref="healthCarePaymentEditComponent"
        :healthCarePaymentIdProp="healthCarePaymentId"
        moduleModeProp
        :editModeProp="true"
        @healthCarePaymentEdited="onHealthCarePaymentEdited"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import HealthCarePaymentEdit from "@/views/Accounting/Payments/HealthCarePayments/HealthCarePaymentEdit";
import InputPrice from "@shared/views/Helpers/InputPrice";
import ProgressBar from "@shared/views/Helpers/ProgressBar";
import InputRegistrationNumber from "@shared/views/Helpers/InputRegistrationNumber";
// services
import commonServices from "@shared/services/API/commonServices";
import healthCarePaymentsServices from "@/services/API/healthCarePaymentsServices";
import healthCarePaymentGroupsServices from "@/services/API/healthCarePaymentGroupsServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import regNumber from "@shared/services/UI/regNumber";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { HealthCarePaymentEdit, InputPrice, ProgressBar, InputRegistrationNumber },
  mixins: [userRights, pagination, error, price, regNumber],
  props: {
    healthCarePaymentIdProp: {
      type: Number,
      default: null
    },
    accessNumberProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general
      healthCarePaymentId: this.healthCarePaymentIdProp,
      healthCarePaymentData: null,
      loading: true,
      page: 1,
      currentActiveMode: null,
      currentActiveGroupId: null,
      maxReached: false,
      loadingExtendSearch: false,
      lastSearchFilters: null,
      lastSearchId: null,

      // healthCarePayment
      filterPaidFiles: true,
      laboratory: null,
      reference: "",
      organisation: null,
      paymentDate: "",
      orderDate: "",
      bank: null,
      bankId: null,
      paid: 0,
      reconciled: 0,
      filePaymentCount: 0,

      // healthCarePaymentGroups
      lines: [],
      tableFields: [
        {
          tdClass: (_value, _key, item) => {
            return "kt-table__td" + (item.healthCarePaymentGroup ? " kt-paymentGroup__container" : "");
          },
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumber",
          sortable: true,
          label: this.$t("payments.healthCarePayments.accessNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "regNumber",
          sortable: false,
          label: this.$t("payments.healthCarePayments.regNumber"),
          formatter: (_value, _key, item) => {
            return item.file.patient.regNumber + item.file.patient.insureeRegNumber;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.patient.lastName",
          sortable: true,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.patient.firstName",
          sortable: true,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.collectionDates",
          sortable: false,
          label: this.$t("files.collectionDateShort"),
          formatter: (value, _key, _item) => {
            return value ? value.join(", ") : "";
          }
        },
        {
          tdClass: (_value, _key, item) => {
            const previouslyPaidTotal = item.fileInvoices.reduce((total, fileInvoice) => { return total + fileInvoice.invoiceActs.previouslyPaid; }, 0);
            return "kt-table__td kt-paymentLine-acts kt-paymentLine-acts--" + (previouslyPaidTotal ? "lg" : "md");
          },
          thClass: "kt-table__th kt-table__th--sm",
          key: "fileInvoices",
          sortable: false,
          label: this.$t("payments.healthCarePayments.acts")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      filterAccessNumber: this.accessNumberProp,
      filterRegNumber: "",
      filterFirstName: "",
      filterLastName: ""
    };
  },
  computed: {
    isSearchActive: function() {
      return this.filterRegNumber !== "" ||
        this.filterAccessNumber !== "" ||
        this.filterFirstName !== "" ||
        this.filterLastName !== "";
    },
    showNoResult: function() {
      return this.currentActiveMode === "add" && !this.lines.length && !this.loading && this.isSearchActive;
    },
    initialValuesPaymentLine: function() {
      return {
        regNumber: this.filterRegNumber,
        accessNumber: this.filterAccessNumber,
        lastName: this.filterFirstName,
        firstName: this.filterLastName
      };
    }
    // formatted variables
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import data
    await this.onPageInput();

    // auto-focus
    if (this.$refs.viewLinesButton) {
      this.$refs.viewLinesButton.focus();
    }

    // if no group, add one
    if (this.lines.length === 0) {
      await this.addHealthCarePaymentGroup(this.paid);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.currentActiveMode === "add") {
        if (this.$refs.paidInput_0_0_0 && this.$refs.paidInput_0_0_0[0]) {
          this.$refs.paidInput_0_0_0[0].focus();
        }
      } else {
        // .$children[1] body .$children[0] first-line
        if (this.$refs.linesTable.$children.length && this.$refs.linesTable.$children[1].$children.length) {
          this.$refs.linesTable.$children[1].$children[0].$children[1].focus();
        }
      }
    },

    // healthCarePayment functions
    async onEditHealthCarePayment() {
      this.$bvModal.show("editHealthCarePaymentModal");
    },
    async onHealthCarePaymentEdited() {
      this.$bvModal.hide("editHealthCarePaymentModal");
      await this.onPageInput();
    },
    async onDeleteHealthCarePayment() {
      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.healthCarePaymentDelete.text"), {
        title: this.$t("payments.healthCarePaymentDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("payments.healthCarePaymentDelete.ok"),
        cancelTitle: this.$t("payments.healthCarePaymentDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("healthCarePayments", this.healthCarePaymentId);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("payments.healthCarePaymentDelete.notifications.deletedTitle"),
            message: this.$t("payments.healthCarePaymentDelete.notifications.deletedText")
          });
          this.onGoBack();
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("payments.healthCarePaymentDelete.notifications.deletedErrorTitle"),
            message: this.$t("payments.healthCarePaymentDelete.notifications.deletedErrorText")
          });
        }
      }
    },
    async onExportHealthCarePayment(type) {
      try {
        const resFile = await commonServices.export("healthCarePayments", this.healthCarePaymentId, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, this.healthCarePaymentId + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateHealthCarePaymentFromData() {
      this.filePaymentCount = this.healthCarePaymentData.filePaymentCount;
      this.laboratory = this.healthCarePaymentData.laboratory;
      this.reference = this.healthCarePaymentData.reference;
      this.organisation = this.healthCarePaymentData.organisation;
      this.bank = this.healthCarePaymentData.bank;
      this.bankId = this.healthCarePaymentData.bankId;
      this.orderDate = this.healthCarePaymentData.orderDate;
      this.paymentDate = this.healthCarePaymentData.paymentDate;
      this.paid = this.importFormatPrice(this.healthCarePaymentData.paid);
      this.reconciled = this.importFormatPrice(this.healthCarePaymentData.reconciled);
    },
    async updateHeader() {
      try {
        const res = await commonServices.get("healthCarePayments", this.healthCarePaymentId);
        this.healthCarePaymentData = res.data;
        this.updateHealthCarePaymentFromData();
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // healthCarePaymentGroup functions
    async addHealthCarePaymentGroup(paid) {
      try {
        if (this.currentActiveMode !== null) {
          await this.onClearAllFilters(false);
          await this.changeCurrentActiveMode(null);
        }
        const form = {
          healthCarePaymentId: this.healthCarePaymentId,
          paid: this.exportFormatPrice(paid),
          reconciled: 0,
          isActive: true
        };
        const res = await commonServices.post("healthCarePaymentGroups", form);
        if (res.data.id) {
          await this.onPageInput();
          this.$nextTick(() => {
            this.currentActiveGroupId = res.data.id;
            this.$refs.groupPaidInput_0.$el.firstElementChild.firstElementChild.firstElementChild.focus();
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async editHealthCarePaymentGroup(paid, healthCarePaymentGroupId) {
      try {
        const form = {
          paid: this.exportFormatPrice(paid)
        };
        const res = await commonServices.put("healthCarePaymentGroups", form, healthCarePaymentGroupId);
        if (res.data) {
          await this.onPageInput();
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onDeleteGroup(healthCarePaymentGroupId) {
      try {
        if (healthCarePaymentGroupId) {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.healthCarePaymentGroupDelete.text"), {
            title: this.$t("payments.healthCarePaymentGroupDelete.title"),
            okVariant: "danger",
            okTitle: this.$t("payments.healthCarePaymentGroupDelete.ok"),
            cancelTitle: this.$t("payments.healthCarePaymentGroupDelete.cancel"),
            centered: true
          });
          if (result) {
            // delete
            this.loading = true;
            const res = await commonServices.delete("healthCarePaymentGroups", healthCarePaymentGroupId);
            if (res.data === true) {
              this.$emit("alert", "success", {
                title: this.$t("payments.healthCarePaymentGroupDelete.notifications.deletedTitle"),
                message: this.$t("payments.healthCarePaymentGroupDelete.notifications.deletedText")
              });
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("payments.healthCarePaymentGroupDelete.notifications.deletedErrorTitle"),
                message: this.$t("payments.healthCarePaymentGroupDelete.notifications.deletedErrorText")
              });
            }
            await this.onPageInput();
            this.loading = false;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onExportPaymentGroup(healthCarePaymentGroupId, type) {
      try {
        const resFile = await commonServices.export("healthCarePaymentGroups", healthCarePaymentGroupId, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, healthCarePaymentGroupId + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // clear filter
    async onClearFilter(fieldName) {
      this[fieldName] = "";
      await this.onPageInput();
    },
    async onClearAllFilters(refresh = true) {
      this.filterRegNumber = "";
      this.filterAccessNumber = "";
      this.filterFirstName = "";
      this.filterLastName = "";
      if (refresh) await this.onPageInput();
    },
    async changeCurrentActiveMode(target) {
      this.maxReached = false;
      this.filterPaidFiles = true;
      this.currentActiveMode = target;
      this.$refs.searchAccessNumber.$el.focus();
      await this.onPageInput();
    },
    updateCurrentActiveGroupId() {
      if (!this.healthCarePaymentData || this.healthCarePaymentData.healthCarePaymentGroups.length === 0) {
        this.currentActiveGroupId = null;
      }
      if (this.currentActiveGroupId === null) {
        // select first
        if (this.healthCarePaymentData.healthCarePaymentGroups.length) {
          this.currentActiveGroupId = this.healthCarePaymentData.healthCarePaymentGroups[0].id;
        }
      } else {
        if (this.healthCarePaymentData.healthCarePaymentGroups.filter((group) => group.id === this.currentActiveGroupId).length === 1) {
          // group still here : do nothing
        } else {
          // group deleted : select first
          this.currentActiveGroupId = this.healthCarePaymentData.healthCarePaymentGroups[0].id;
        }
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.onPageInput();
      }, 450);
    },
    async onPageInput() {
      try {
        this.loading = true;

        let res = [];
        if (this.currentActiveMode === null) {
          // get lines
          if (!this.isSearchActive) {
            // VIEW MODE
            res = await commonServices.get("healthCarePayments", this.healthCarePaymentId);
            // update report and group
            this.healthCarePaymentData = res.data;
            this.updateHealthCarePaymentFromData();
            // update lines
            this.lines = [];
            if (this.healthCarePaymentData.healthCarePaymentGroups.length) {
              this.healthCarePaymentData.healthCarePaymentGroups.sort(function(a, b) { return b.id - a.id; });
              for (const healthCarePaymentGroup of this.healthCarePaymentData.healthCarePaymentGroups) {
                // this.lines : add group
                this.lines.push({
                  file: {
                    accessNumber: "",
                    patient: {
                      regNumber: "",
                      insureeRegNumber: "",
                      firstName: "",
                      lastName: ""
                    },
                    collectionDates: [],
                    fileInvoices: []
                  },
                  fileInvoices: [],
                  healthCarePaymentGroup: {
                    id: healthCarePaymentGroup.id,
                    healthCarePaymentId: healthCarePaymentGroup.healthCarePaymentId,
                    paid: this.importFormatPrice(healthCarePaymentGroup.paid),
                    reconciled: this.importFormatPrice(healthCarePaymentGroup.reconciled)
                  }
                });
                // format filePayments
                healthCarePaymentGroup.filePayments.sort(function(a, b) { return b.id - a.id; });
                // this.lines : add filePayments line
                this.lines = this.lines.concat(this.formatFilePayments(healthCarePaymentGroup.filePayments));
              }
            }
            this.updateCurrentActiveGroupId();
          } else {
            // VIEW MODE (SEARCH)
            res = await healthCarePaymentsServices.showAllFilePayments(
              this.page,
              {
                accessNumber: this.filterAccessNumber,
                regNumber: this.filterRegNumber,
                firstName: this.filterFirstName,
                lastName: this.filterLastName,
                maidenName: "", // filterMaidenName,
                healthCarePaymentId: this.healthCarePaymentId
              },
              {
                by: this.sortBy === "accessNumber" ? "file.accessNumber" : this.sortBy,
                desc: this.sortDesc
              }
            );
            this.lines = this.formatFilePayments(res.data.rows);
            // update report and group
            this.healthCarePaymentData = res.data.healthCarePayment;
            this.updateHealthCarePaymentFromData();
          }
        } else if (this.currentActiveMode === "add") {
          // ADD LIGNE MODE
          if (this.isSearchActive === false) {
            // if no filter
            this.lines = [];
          } else {
            this.lastSearchFilters = {
              filterPaidFiles: this.filterPaidFiles, // filter files with "paid" accounting status
              accessNumber: this.filterAccessNumber,
              regNumber: this.filterRegNumber,
              firstName: this.filterFirstName,
              lastName: this.filterLastName,
              // maidenName: filterMaidenName,
              organisationId: this.organisation.id,
              healthCarePaymentId: this.healthCarePaymentId
            };
            res = await healthCarePaymentGroupsServices.getAllFileInvoices(this.lastSearchFilters);
            this.lines = this.formatFileInvoices(res.data.rows);
            if (res.data && res.data.rows.length) {
              this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
            }
            this.maxReached = res.data.maxReached;
          }
        } else {
          this.lines = [];
        }

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async extendSearch() {
      try {
        if (this.currentActiveMode === "add") {
          this.loadingExtendFiles = true;

          this.lastSearchFilters.minId = this.lastSearchId;

          // search and Update
          const res = await healthCarePaymentGroupsServices.getAllFileInvoices(this.lastSearchFilters);
          if (res.data && res.data.rows.length) {
            this.maxReached = res.data.maxReached;
            this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
            this.lines.push(...this.formatFileInvoices(res.data.rows));
          } else {
            this.maxReached = false;
          }

          this.loadingExtendFiles = false;
        }
      } catch (err) {
        this.loadingExtendFiles = false;
        this.handleErrors(err);
      }
    },
    // FORMAT LINES (fileInvoices)
    formatFileInvoices(fileInvoices) {
      return fileInvoices.map((line) => {
        delete line.careSheetId;
        delete line.feeNoteId;
        delete line.invoiceReportId;

        line.invoiced = this.importFormatPrice(line.invoiced);
        line.paid = 0;
        line.previouslyPaid = 0;

        // invoiceActs
        for (const invoiceAct of line.invoiceActs) {
          delete invoiceAct.fileInvoiceId;
          // invoiced
          invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
          // previouslyPaid
          invoiceAct.previouslyPaid = 0;
          // paid
          for (const paymentAct of invoiceAct.paymentActs) {
            paymentAct.paid = this.importFormatPrice(paymentAct.paid);
            invoiceAct.previouslyPaid += paymentAct.paid;
            line.previouslyPaid += paymentAct.paid;
          }
          // create current paymentAct
          invoiceAct.paymentAct = {
            invoiceActId: invoiceAct.id,
            paid: invoiceAct.invoiced - invoiceAct.previouslyPaid
          };
        }
        // reassign fileInvoices
        line.fileInvoices = [{
          targetFileInvoice_fileInvoiceTransformation: line.targetFileInvoice_fileInvoiceTransformation,
          fileInvoiceTransformations: line.fileInvoiceTransformations,
          invoiced: line.invoiced,
          paid: line.paid,
          previouslyPaid: line.previouslyPaid,
          invoiceActs: line.invoiceActs
        }];
        delete line.targetFileInvoice_fileInvoiceTransformation;
        delete line.fileInvoiceTransformations;
        delete line.invoiceActs;
        // setup collectionDates
        line.file.collectionDates = line.file.containerGroups.map(({ collectionDate }) => {
          return collectionDate ? this.$d(new Date(collectionDate), "date") : "";
        });
        delete line.file.containerGroups;
        return line;
      });
    },
    // FORMAT LINES (filePayments)
    formatFilePayments(filePayments) {
      if (!filePayments.length) return [];

      return filePayments.map((line) => {
        // skip group headers
        if (line.healthCarePaymentGroup) {
          return line;
        }
        delete line.paymentGroupId;
        delete line.patientPaymentId;
        // format prices
        line.paid = this.importFormatPrice(line.paid);
        const invoicedTotal = line.file.fileInvoices.reduce((total, item) => { return total + item.invoiced; }, 0);
        line.invoiced = this.importFormatPrice(invoicedTotal);
        line.previouslyPaid = 0;
        line.fileInvoices = line.file.fileInvoices;
        delete line.file.fileInvoices;

        // fileInvoices
        for (const fileInvoice of line.fileInvoices) {
          fileInvoice.previouslyPaid = 0;
          for (const invoiceAct of fileInvoice.invoiceActs) {
            // format prices
            invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
            for (const paymentAct of invoiceAct.paymentActs) {
              paymentAct.paid = this.importFormatPrice(paymentAct.paid);
            }
            // search if existing current paymentAct
            let currentPaymentActPosition = null;
            for (let l = 0; l < line.paymentActs.length; l++) {
              if (invoiceAct.id === line.paymentActs[l].invoiceActId) {
                currentPaymentActPosition = l;
                break;
              }
            }
            invoiceAct.previouslyPaid = 0;
            // for each paymentActs (old and current)
            for (let l = invoiceAct.paymentActs.length - 1; l >= 0; l--) {
              if (currentPaymentActPosition !== null && invoiceAct.paymentActs[l].id === line.paymentActs[currentPaymentActPosition].id) {
                // if paymentAct is current : save separatly
                invoiceAct.paymentAct = invoiceAct.paymentActs[l];
                invoiceAct.paymentActs.splice(l, 1);
              } else {
                // else : add to previouslyPaid
                invoiceAct.previouslyPaid += invoiceAct.paymentActs[l].paid;
                fileInvoice.previouslyPaid += invoiceAct.paymentActs[l].paid;
                line.previouslyPaid += invoiceAct.paymentActs[l].paid;
              }
            }
            // if no current payment created for the invoiceAct, add a default paymentAct
            if (!invoiceAct.paymentAct) {
              invoiceAct.paymentAct = {
                filePaymentId: line.id,
                id: null,
                invoiceActId: invoiceAct.id,
                paid: 0
              };
            }
          }
        }
        delete line.paymentActs;
        // setup collectionDates
        line.file.collectionDates = line.file.containerGroups.map(({ collectionDate }) => {
          return collectionDate ? this.$d(new Date(collectionDate), "date") : "";
        });
        delete line.file.containerGroups;
        return line;
      });
    },

    // paymentActs
    async editPaymentAct(linePosition, fileInvoicePosition, invoiceActPosition, priceValue = null) {
      // update invoiceActs paid
      this.lines[linePosition].fileInvoices[fileInvoicePosition].invoiceActs[invoiceActPosition].paymentAct.paid = priceValue;
      // update total paid
      let paidTotal = 0;
      for (const fileInvoice of this.lines[linePosition].fileInvoices) {
        for (const invoiceAct of fileInvoice.invoiceActs) {
          paidTotal += invoiceAct.paymentAct.paid;
        }
      }
      this.lines[linePosition].paid = paidTotal;
      // only for view mode
      if (this.currentActiveMode === null) {
        this.$refs["savePaymentLineButton_" + this.lines[linePosition].id][0].classList.remove("d-none");
      }
      await this.$refs.linesTable.refresh();
    },
    async submitPaymentAct(linePosition, fileInvoicePosition, invoiceActPosition, priceValue = null) {
      await this.editPaymentAct(linePosition, fileInvoicePosition, invoiceActPosition, priceValue);
      await this.saveLine(linePosition);
    },
    async submitSearchInput() {
      if (this.currentActiveMode === "add" && this.lines.length === 1) {
        await this.saveLine(0);
      }
    },
    // filePayments
    async saveLine(linePosition) {
      await this.$nextTick();

      if (this.currentActiveMode === null) {
        // EDIT PAYMENT LINE
        await this.onEditLine(linePosition);
      } else {
        // ADD PAYMENT LINE
        await this.onAddLine(linePosition);
      }
    },
    async onEditLine(linePosition) {
      try {
        // update total paid
        let paidTotal = 0;
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paidTotal += invoiceAct.paymentAct.paid;
          }
        }
        this.lines[linePosition].paid = paidTotal;
        // paid amount must be not null
        if (this.lines[linePosition].paid === 0) {
          return false;
        }
        // setup paymentActs
        const paymentActs = [];
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paymentActs.push({
              id: invoiceAct.paymentAct.id,
              filePaymentId: invoiceAct.paymentAct.filePaymentId,
              invoiceActId: invoiceAct.id,
              paid: this.exportFormatPrice(invoiceAct.paymentAct.paid)
            });
          }
        }

        const form = {
          healthCarePaymentGroupId: this.lines[linePosition].healthCarePaymentGroupId,
          fileId: this.lines[linePosition].fileId,
          paid: this.exportFormatPrice(this.lines[linePosition].paid),
          paymentActs: paymentActs,
          isActive: true
        };
        const resEdition = await commonServices.put("filePayments", form, this.lines[linePosition].id);
        if (resEdition.data === true) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("payments.healthCarePaymentLinesEdit.notifications.editedTitle"),
            message: this.$t("payments.healthCarePaymentLinesEdit.notifications.editedText")
          });
          this.$refs.searchAccessNumber.$el.focus();
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("payments.healthCarePaymentLinesEdit.notifications.editionErrorTitle"),
            message: this.$t("payments.healthCarePaymentLinesEdit.notifications.editionErrorText")
          });
        }
        // hide button
        this.$refs["savePaymentLineButton_" + this.lines[linePosition].id][0].classList.add("d-none");
        // update table
        await this.onPageInput();
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onAddLine(linePosition) {
      try {
        // update total paid
        let paidTotal = 0;
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paidTotal += invoiceAct.paymentAct.paid;
          }
        }
        this.lines[linePosition].paid = paidTotal;
        // paid amount must be not null
        if (this.lines[linePosition].paid === 0) {
          return false;
        }
        // setup paymentActs
        const paymentActs = [];
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paymentActs.push({
              invoiceActId: invoiceAct.id,
              paid: this.exportFormatPrice(invoiceAct.paymentAct.paid)
            });
          }
        }

        const form = {
          healthCarePaymentGroupId: this.currentActiveGroupId,
          fileId: this.lines[linePosition].fileId,
          paid: this.exportFormatPrice(this.lines[linePosition].paid),
          paymentActs: paymentActs,
          isActive: true
        };
        // add filePayment
        const resAddition = await commonServices.post("filePayments", form, this.currentActiveGroupId);
        if (resAddition.data.id) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("payments.healthCarePaymentLinesEdit.notifications.addedTitle"),
            message: this.$t("payments.healthCarePaymentLinesEdit.notifications.addedText")
          });
          await this.updateHeader();
          if (this.filterAccessNumber !== "" && this.lines.length > 1) {
            await this.onPageInput();
            this.focusFirstLine();
          } else {
            await this.onClearAllFilters();
            this.$refs.searchAccessNumber.$el.focus();
          }
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("payments.healthCarePaymentLinesEdit.notifications.additionErrorTitle"),
            message: this.$t("payments.healthCarePaymentLinesEdit.notifications.additionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onDeleteLine(filePaymentId) {
      try {
        if (filePaymentId) {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.healthCarePaymentLineDelete.text"), {
            title: this.$t("payments.healthCarePaymentLineDelete.title"),
            okVariant: "danger",
            okTitle: this.$t("payments.healthCarePaymentLineDelete.ok"),
            cancelTitle: this.$t("payments.healthCarePaymentLineDelete.cancel"),
            centered: true
          });
          if (result) {
            // delete
            this.loading = true;
            const res = await commonServices.delete("filePayments", filePaymentId);
            if (res.data === true) {
              // success
              this.$emit("alert", "success", {
                title: this.$t("payments.healthCarePaymentLineDelete.notifications.deletedTitle"),
                message: this.$t("payments.healthCarePaymentLineDelete.notifications.deletedText")
              });
              await this.onPageInput();
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("payments.healthCarePaymentLineDelete.notifications.deletedErrorTitle"),
                message: this.$t("payments.healthCarePaymentLineDelete.notifications.deletedErrorText")
              });
            }
            this.loading = false;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // navigate back to the group
    onGoBack() {
      navigate("accounting/payments/healthCarePayments");
    }
  }
};
</script>

<style src="./HealthCarePaymentLinesEdit-global.scss" lang="scss"></style>

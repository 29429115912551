<template>
  <div class="kt-organisation-c-i">
    <!-- title -->
    <h3
      v-if="!hideLabelProp"
      class="h6"
    >
      {{ $t("organisations.contactInformations") }}
    </h3>

    <!-- contact informations list -->
    <div class="kt-item-list">
      <div
        v-for="organisationContactInformationItem in organisationContactInformations"
        v-show="organisationContactInformationItem.status !== 'deleted'"
        :key="organisationContactInformationItem.tempId"
        class="kt-item-list__item-wrapper"
      >
        <div class="kt-item-list__item">
          <!-- actions -->
          <div
            v-show="organisationContactInformationItem.contactInformation.isActive !== false"
            class="kt-item-list__item-actions"
          >
            <!-- edit button -->
            <b-button
              class="kt-item-list__item-action"
              :variant="'outline-' + $systemSettings.theme"
              size="sm"
              squared
              @click="onOpenEditContactInformation(organisationContactInformationItem.tempId, organisationContactInformationItem.contactInformation)"
            >
              {{ $t("general.edit") }}
            </b-button>
            <!-- delete button -->
            <b-button
              class="btn-icon kt-item-list__item-delete"
              size="sm"
              variant="light"
              squared
              @click="onDeleteOrganisationContactInformation(organisationContactInformationItem.tempId)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>

          <!-- title -->
          <div
            class="kt-item-list__item-title"
            :class="{'kt-item-list__item-title--padding-large': organisationContactInformationItem.contactInformation.isActive === true}"
          >
            {{ organisationContactInformationItem.contactInformation.name }}
          </div>
          <!-- isActive -->
          <div
            v-show="organisationContactInformationItem.contactInformation.isActive === false"
            class="kt-badge kt-badge--red"
          >
            <b-icon
              icon="trash"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
          </div>
          <!-- text -->
          <div
            class="kt-item-list__item-text"
            v-html="formatAddressSub(organisationContactInformationItem.contactInformation)"
          ></div>

          <!-- labels -->
          <div
            v-show="organisationContactInformationItem.contactInformation.isActive !== false"
            class="kt-button-switchers"
          >
            <!-- is Default -->
            <b-button
              class="kt-button-switcher"
              :class="{'kt-button-switcher--active': organisationContactInformationItem.isDefault === true}"
              size="sm"
              variant="light"
              @click="editOrganisationContactInformationProperty(organisationContactInformationItem.tempId, !organisationContactInformationItem.isDefault)"
            >
              <b-icon
                icon="check"
                class="kt-button-switcher__check"
              ></b-icon>
              <b-icon
                icon="plus"
                class="kt-button-switcher__plus"
              ></b-icon>
              {{ $t("contactInformations.isDefault") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- contactInformationInfoBadge -->
    <div>
      <div class="kt-badge kt-badge--light-gray kt-badge--lg mb-2">
        <b-icon
          icon="info-circle"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('organisations.contactInformationInfoBadge') }}</span></span>
      </div>
    </div>
    <!-- add a contactInformation -->
    <b-button
      size="sm"
      :variant="'outline-' + $systemSettings.theme"
      pill
      @click="onOpenAddContactInformation"
    >
      <b-icon icon="plus"></b-icon>
      {{ $t("organisationEdit.addContactInformation") }}
    </b-button>

    <!-- contactInformation add/edit Modal -->
    <b-modal
      id="editContactInformationModal"
      hide-footer
      :title="contactInformationModalData !== null ? $t('organisationEdit.editContactInformation') : $t('organisationEdit.addContactInformation')"
      size="xl"
      @shown="() => {
        $refs['contactInformationEdit' + _uid].focusFirstElement()
      }"
    >
      <ContactInformationEditInpage
        :ref="'contactInformationEdit' + _uid"
        :editModeProp="contactInformationModalData !== null"
        :contactInformationProp="contactInformationModalData"
        @contactInformationAdded="onContactInformationAdded($event)"
        @contactInformationEdited="onContactInformationEdited(organisationContactInformationTempIdModalData, $event)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import ContactInformationEditInpage from "@/views/Data/ContactInformations/ContactInformationEditInpage";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    ContactInformationEditInpage
  },
  mixins: [userRights, error, date, phoneNumber, address],
  props: {
    organisationContactInformationsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    hideLabelProp: {
      type: Boolean
    },
    // true when this component is not editable
    viewModeProp: {
      type: Boolean
    },
    // true when editing an existing file, false when creating a new file
    fileEditModeProp: {
      type: [Boolean, null],
      default: null
    }
  },
  data() {
    return {
      // base list
      organisationContactInformations: [],
      contactInformationModalData: null,
      organisationContactInformationTempIdModalData: null
    };
  },
  watch: {
    organisationContactInformationsProp: {
      handler(val) {
        this.organisationContactInformations = [];
        if (val && val.length > 0) {
          this.organisationContactInformations = JSON.parse(JSON.stringify(val));
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    // contact information
    onOpenAddContactInformation() {
      this.contactInformationModalData = null;
      this.organisationContactInformationTempIdModalData = null;
      this.$bvModal.show("editContactInformationModal");
    },
    onOpenEditContactInformation(tempId, contactInformation) {
      this.contactInformationModalData = contactInformation;
      this.organisationContactInformationTempIdModalData = tempId;
      this.$bvModal.show("editContactInformationModal");
    },
    onContactInformationAdded(contactInformation) {
      this.$bvModal.hide("editContactInformationModal");
      contactInformation.status = "created";
      this.organisationContactInformations.push({
        id: null,
        tempId: "new-" + Number(uniqueId()),
        contactInformation: contactInformation,
        isDefault: false,
        status: "created"
      });
      this.sendToparent();
    },
    onContactInformationEdited(tempId, contactInformation) {
      // find the organisationContactInformation to update
      for (var i in this.organisationContactInformations) {
        if (this.organisationContactInformations[i].tempId === tempId) {
          // update contactInformation
          this.organisationContactInformations[i].contactInformation = contactInformation;
          if (this.organisationContactInformations[i].status === null) {
            this.organisationContactInformations[i].status = "edited";
          }
          if (this.organisationContactInformations[i].contactInformation.id) {
            this.organisationContactInformations[i].contactInformation.status = "edited";
          }

          this.$bvModal.hide("editContactInformationModal");
          this.sendToparent();
          break;
        }
      }
    },

    // organisationContactInformation
    onDeleteOrganisationContactInformation(tempId) {
      // get the position
      const position = this.getOrganisationContactInformationPosition(tempId);
      if (position !== null) {
        // delete
        if (this.organisationContactInformations[position].status === "created") {
          this.organisationContactInformations.splice(position, 1);
        } else {
          this.organisationContactInformations[position].status = "deleted";
          this.organisationContactInformations[position].contactInformation.status = "deleted";
          this.organisationContactInformations[position].isDefault = false;
        }
        // send to parent
        this.sendToparent();
      }
    },
    editOrganisationContactInformationProperty(tempId, value) {
      this.$emit("isDefaultEdited", { target: "organisationContactInformations", tempId: tempId, value: value });
    },

    // helpers
    getOrganisationContactInformationPosition(tempId) {
      for (var i in this.organisationContactInformations) {
        if (this.organisationContactInformations[i].tempId === tempId) return i;
      }
      return null;
    },
    formatAddressSub(obj) {
      let addressLine = this.formatAddress(obj.address1, obj.address2, obj.zip, obj.city, obj.country);
      if (obj.phone) addressLine += (addressLine !== "" ? "<br>" : "") + this.importFormatPhone(obj.phone);
      if (obj.mobile) addressLine += (!obj.phone && addressLine !== "" ? "<br>" : "") + (obj.phone ? " - " : "") + this.importFormatPhone(obj.mobile);
      if (obj.email) addressLine += (addressLine !== "" ? "<br>" : "") + obj.email;

      return addressLine;
    },
    // on update / finished
    sendToparent() {
      this.$emit("organisationContactInformationsUpdated", this.organisationContactInformations);
    }
  }
};
</script>

<template>
  <div
    class="kt-view-page kt-pathologist-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("pathologistView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'administration', 'pathologists', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('administration/pathologist/edit', {pathologistIdProp: pathologistId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("pathologistView.editPathologist") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- isActive -->
      <div
        v-show="!isActive"
        class="kt-badge kt-badge--red kt-badge--lg"
      >
        <b-icon
          icon="trash"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
      </div>

      <!-- SECTION -->
      <div
        v-if="nameDisplay ||
          codeDisplay ||
          registrationNumberDisplay ||
          fullTitleDisplay ||
          sectorsDisplay ||
          additionalFeeGroupsDisplay ||
          signatureFileDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- name -->
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.user") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              <PersonName
                :sexNameProp="user !== null ? user.sex.name : ''"
                :firstNameProp="user !== null ? user.firstName : ''"
                :lastNameProp="user !== null ? user.lastName : ''"
              />
            </p>
          </b-col>
        </b-row>
        <!-- pathologistReplacements -->
        <b-row v-if="pathologistReplacementsDisplay && pathologistReplacements.length">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.pathologistReplacements") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div class="kt-item-list mb-1">
              <div
                v-for="pathologistReplacement in pathologistReplacements"
                :key="pathologistReplacement.tempId"
                class="kt-item-list__item-wrapper"
                style="width: 100%"
              >
                <PathologistReplacement
                  :pathologistReplacementProp="pathologistReplacement"
                  :viewModeProp="true"
                  @alert="(variant, strings) => $emit('alert', variant, strings)"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- sectors -->
        <b-row v-if="sectorsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.sectors") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div v-show="sectors.length === 0">
              {{ $t("general.emptyWithHyphen") }}
            </div>
            <ul
              v-show="sectors.length !== 0"
              class="mb-2"
            >
              <li
                v-for="sector in sectors"
                :key="sector.name"
              >
                {{ sector.localisedName }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- code -->
        <b-row v-if="codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
        <!-- registrationNumber -->
        <b-row v-if="registrationNumberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.registrationNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-2">
              {{ registrationNumber }}
            </p>
          </b-col>
        </b-row>
        <!-- fullTitle -->
        <b-row v-if="fullTitleDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.fullTitle") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-show="!fullTitle"
              class="mb-2"
            >
              {{ $t("general.emptyWithHyphen") }}
            </div>
            <div
              v-show="fullTitle"
              style="display: inline-block; padding: 6px 10px; white-space: pre-line; line-height: 1.15;"
              class="border rounded mb-2"
            >
              {{ fullTitle }}
            </div>
          </b-col>
        </b-row>
        <!-- additionalFeeGroups -->
        <b-row v-if="additionalFeeGroupsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.additionalFeeGroups") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-show="additionalFeeGroups.length === 0"
              class="mb-2"
            >
              {{ $t("general.emptyWithHyphen") }}
            </div>
            <ul
              v-show="additionalFeeGroups.length !== 0"
              class="mb-2"
            >
              <li
                v-for="additionalFeeGroup in additionalFeeGroups"
                :key="additionalFeeGroup.id"
              >
                {{ additionalFeeGroup.name + (additionalFeeGroup.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- signatureFile -->
        <b-row v-if="signatureFileDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.signature") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
            style="max-width: 320px;"
            class="mb-2 pb-1"
          >
            <b-img
              fluid
              thumbnail
              :src="signatureUrl"
              :alt="(signatureFile === null || signatureUrl === null) ? 'No image found' : $t('pathologists.signatureAlt')"
            ></b-img>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
import PathologistReplacement from "./PathologistReplacement";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  components: { PersonName, PathologistReplacement },
  mixins: [userRights, error, viewComponents],
  props: {
    pathologistIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "name",
          "pathologistReplacements",
          "code",
          "registrationNumber",
          "fullTitle",
          "sectors",
          "additionalFeeGroups",
          "signatureFile"
        ];
      }
    }
  },
  data() {
    return {
      // general
      pathologistId: this.pathologistIdProp,
      pathologistData: null,
      // base data
      isActive: true,
      user: null,
      pathologistReplacements: [],
      code: "",
      registrationNumber: "",
      fullTitle: "",
      sectors: [],
      additionalFeeGroups: [],
      signatureFile: null,
      signatureUrl: null,
      // dispayFields
      nameDisplay: false,
      pathologistReplacementsDisplay: false,
      codeDisplay: false,
      registrationNumberDisplay: false,
      fullTitleDisplay: false,
      sectorDisplay: false,
      signatureFileDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get pathologist
      const resServices = await commonServices.get("pathologists", this.pathologistId);
      this.pathologistData = resServices.data;
      // assign the data
      this.isActive = resServices.data.user.isActive;
      this.user = resServices.data.user;
      this.pathologistReplacements = resServices.data.pathologistReplacements || [];
      this.code = resServices.data.code;
      this.registrationNumber = resServices.data.registrationNumber;
      this.fullTitle = resServices.data.fullTitle;
      this.sectors = resServices.data.pathologistSectors.map((pathologistSector) => {
        return {
          name: pathologistSector.sector.name,
          localisedName: this.$t("sectors." + pathologistSector.sector.name) + (!pathologistSector.sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : ""),
          isSubscribed: pathologistSector.sector.isSubscribed
        };
      });
      const additionalFeeGroups = resServices.data.pathologistAdditionalFeeGroups.map((v) => v.additionalFeeGroup);
      additionalFeeGroups.sort((a, b) => {
        if (Math.sign(a.rank) === -1 && Math.sign(b.rank) === 1) {
          return Math.abs(a.rank);
        } else {
          return a.rank - b.rank;
        }
      });
      this.additionalFeeGroups = additionalFeeGroups;
      // import signature
      if (resServices.data.signatureFileName) {
        const resSignature = await commonServices.getFile("pathologists", this.pathologistId);
        this.signatureFile = new File([resSignature.data], resServices.data.signatureFileName);
        this.signatureUrl = window.URL.createObjectURL(this.signatureFile);
      }

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("administration/pathologists");
    }
  }
};
</script>

<template>
  <div class="systemSettings">
    <div class="d-flex justify-content-center">
      <label
        class="m-2"
        style="text-align: center"
      >{{ $t("systemSettings.localization") }}</label>
      <b-dropdown
        class="m-2"
        style="text-align: center"
      >
        <template v-slot:button-content>
          {{ $t("language", locale) }}
        </template>
        <b-dropdown-item-button
          v-for="currentLocale in availableLocales"
          :key="currentLocale"
          class="m-2"
          @click="onLocalization(currentLocale)"
        >
          {{ $t("language", currentLocale) }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import systemSettingsServices from "@shared/services/API/systemSettingsServices";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  mixins: [userRights, error],
  data: function() {
    return {
      availableLocales: [],
      locale: ""
    };
  },
  created: function() {
    this.availableLocales = this.$i18n.availableLocales;
  },
  mounted: async function() {
    try {
      const res = await systemSettingsServices.get("locale");
      this.locale = res.data.value;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    async onLocalization(locale) {
      try {
        await systemSettingsServices.put("locale", locale);
        this.$i18n.locale = locale;
        this.locale = locale;
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

import autocompletionServices from "@shared/services/API/autocompletionServices";

export default {
  data() {
    return {
      suggestions: []
    };
  },
  methods: {
    async onFilterInput(table, field, filter, length, returnFields) {
      try {
        if (filter.string.length > length) {
          const res = await autocompletionServices.autocomplete(table, field, filter, returnFields);
          this.suggestions = res.data;
        } else {
          this.suggestions = [];
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onFilterInputOrganisation(table, field, filter, length, returnFields) {
      try {
        if (filter.string.length > length) {
          const res = await autocompletionServices.autocompleteOrganisations(table, field, filter, returnFields);
          this.suggestions = res.data;
        } else {
          this.suggestions = [];
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};

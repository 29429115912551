<template>
  <div class="kt-task-cytology-content">
    <!-- content form -->
    <b-row>
    </b-row>
  </div>
</template>

<script>
// helpers
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    contentProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    contentValidationProp: {
      type: Boolean
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // form variables
      // is validation active
      // options
    };
  },
  computed: {
    // form validation
    // formatted variables
    contentFormatted: function() {
      return null;
    },
    isValid: function() {
      return true;
    }
  },
  watch: {
    contentProp: {
      handler() {
        this.sendIsValid();
      },
      deep: true,
      immediate: true
    },
    contentValidationProp: {
      handler(val) {
        if (val) {
          this.sendIsValid();
        }
      }
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    onInput(name) {
      this[name + "Validation"] = true;
      this.onSendContent();
    },
    // submit
    onSendContent() {
      this.$emit("updateContent", this.contentFormatted);
      this.sendIsValid();
    },
    sendIsValid() {
      this.$emit("updateIsValid", this.isValid);
    }
  }
};
</script>

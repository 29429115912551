<template>
  <div class="kt-searchTypeSelect">
    <b-button
      size="sm"
      class="mr-1 kt-searchTypeSelect__btn"
      :class="{'kt-searchTypeSelect__btn--active': value === 'exact'}"
      :variant="value === 'exact' ? $systemSettings.theme : 'light'"
      @click="$emit('input', 'exact')"
      @keyup.enter.exact="$emit('submit', true)"
    >
      {{ $t('searchtypeSelect.exact') }}
    </b-button>
    <b-button
      size="sm"
      class="mr-1 kt-searchTypeSelect__btn"
      :class="{'kt-searchTypeSelect__btn--active': value === 'startsWith'}"
      :variant="value === 'startsWith' ? $systemSettings.theme : 'light'"
      @click="$emit('input', 'startsWith')"
      @keyup.enter.exact="$emit('submit', true)"
    >
      {{ $t('searchtypeSelect.startsWith') }}
    </b-button>
    <b-button
      size="sm"
      class="kt-searchTypeSelect__btn"
      :class="{'kt-searchTypeSelect__btn--active': value === 'free'}"
      :variant="value === 'free' ? $systemSettings.theme : 'light'"
      @click="$emit('input', 'free')"
      @keyup.enter.exact="$emit('submit', true)"
    >
      {{ $t('searchtypeSelect.free') }}
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped src="./SearchTypeSelect.scss" lang="scss"></style>

<template>
  <b-form-group
    class="kt-containerGroups"
    :state="containerGroupsState"
    :invalid-feedback="containerGroupsInvalidFeedback"
  >
    <!-- containerGroups - list -->
    <div
      v-show="containerGroups.length"
      class="kt-item-list"
      style="margin-bottom: -10px;"
    >
      <div
        v-for="(containerGroup, index) in containerGroups"
        :key="containerGroup.tempId"
        class="kt-item-list__item-wrapper"
        style="width: 100%;"
      >
        <div
          class="kt-item-list__item"
          style="width: 100%"
        >
          <ContainerGroup
            :ref="index === containerGroups.length - 1 ? 'containerGroupComponents' : null"

            :containerGroupProp="containerGroup"
            :containerGroupRequiredProp="containerGroupsRequiredProp"
            :isValidationActiveProp="containerGroupsValidation"

            :fileCreationDateProp="fileCreationDateProp"
            :laboratoryIdProp="laboratoryIdProp"
            :sectorIdProp="sectorIdProp"
            :viewModeProp="viewModeProp"
            :fileEditModeProp="fileEditModeProp"
            :displayCollectionDateProp="true"
            :displayContainerTypeProp="true"

            @containerGroupUpdated="onUpdateContainerGroup(containerGroup.tempId, $event)"
            @containerGroupDeleted="onDeleteContainerGroup(containerGroup.tempId)"
            @updateIsValid="onIsValidUpdated(containerGroup.tempId, $event)"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </div>
      </div>
    </div>

    <!-- add a containerGroup -->
    <b-button
      v-if="!viewModeProp && isEnabled(['menu', 'office', 'files', 'containerGroups', 'add'])"
      v-show="sectorIdProp !== 1 || containerGroups.length === 0"
      ref="containerGroupAddButton"
      :style="{'margin-top': containerGroups.length ? '12px' : ''}"
      size="sm"
      :variant="'outline-' + $systemSettings.theme"
      pill
      @click="onAddContainerGroup"
    >
      <b-icon icon="plus"></b-icon>
      {{ $t("containerGroups.addButton") }}
    </b-button>
  </b-form-group>
</template>

<script>
// components
import ContainerGroup from "@/views/Data/ContainerGroup";
// helpers
import userRights from "@/services/UI/userRights";
import date from "@shared/services/UI/date";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    ContainerGroup
  },
  mixins: [userRights, date],
  props: {
    containerGroupsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    isValidationActiveProp: {
      type: Boolean
    },
    // true when the component is not editable
    viewModeProp: {
      type: Boolean
    },

    containerGroupsRequiredProp: {
      type: Boolean
    },
    fileCreationDateProp: {
      type: String,
      default: ""
    },
    laboratoryIdProp: {
      type: [Number, null],
      default: null
    },
    sectorIdProp: {
      type: [Number, null],
      default: null
    },
    // true when editing an existing file, false when creating a new file
    fileEditModeProp: {
      type: [Boolean, null],
      default: null
    }
  },
  data() {
    return {
      containerGroups: [],
      // is validation active
      containerGroupsValidation: false,
      // isValid list
      containerGroupsIsValidArray: []
    };
  },
  computed: {
    containerGroupsState: function() {
      // not required
      if (!this.containerGroupsRequiredProp && (!this.containerGroups || !this.containerGroups.length)) return null;
      // required
      if (!this.containerGroups || !this.containerGroups.length) return false;
      // only one allowed in cytology
      if (this.sectorIdProp === 1 && this.containerGroups.length > 1) return false;
      // else
      return null;
    },
    containerGroupsStateDisplay: function() {
      if (!this.containerGroupsValidation) return null;
      return this.containerGroupsState;
    },
    containerGroupsInvalidFeedback: function() {
      // no error
      if (this.containerGroupsStateDisplay === null) return "";
      // required
      if (!this.containerGroups || !this.containerGroups.length) return this.$t("validationRules.required");
      // only one allowed in cytology
      return this.$t("fileEdit.validations.cytologyOnlyOneContainerGroup");
    }
  },
  watch: {
    containerGroupsProp: {
      handler(val) {
        if (val && val.length > 0) {
          // containerGroups imported from parent need a tempId as "new-id"
          const containerGroups = JSON.parse(JSON.stringify(val));
          this.containerGroups = containerGroups.map((containerGroup) => {
            if (containerGroup.tempId === undefined) {
              containerGroup.tempId = "new-" + uniqueId();
            }
            return containerGroup;
          });
        } else {
          this.containerGroups = [];
        }
        // this.sendIsValid();
      },
      deep: true,
      immediate: true
    },
    isValidationActiveProp: {
      handler(val) {
        this.containerGroupsValidation = Boolean(val);
        this.sendIsValid();
      },
      immediate: true
    },
    containerGroupsRequiredProp: {
      handler() {
        this.sendIsValid();
      }
    }
  },
  mounted() {
    // add an empty containergroup on load (if new file creation)
    if (this.viewModeProp === false && this.fileEditModeProp === false && (!this.containerGroupsProp || !this.containerGroupsProp.length)) {
      this.onAddContainerGroup();
    }
  },
  methods: {
    // actions
    onAddContainerGroup() {
      this.containerGroups.push({
        id: null,
        tempId: "new-" + uniqueId(),
        collectionDate: this.getIsoDate(),
        containers: [],
        containerGroupTasks: []
      });
      this.sendToparent();
      // auto-focus
      this.$nextTick(() => {
        this.$refs.containerGroupComponents[0].focusFirstElement();
      });
    },
    onUpdateContainerGroup(tempId, containerGroup) {
      for (let i = this.containerGroups.length - 1; i >= 0; i--) {
        if (this.containerGroups[i].tempId === tempId) {
          this.containerGroups[i] = containerGroup;
          // send to parent
          this.sendToparent();
          break;
        }
      }
    },
    onDeleteContainerGroup(tempId) {
      for (let i = this.containerGroups.length - 1; i >= 0; i--) {
        if (this.containerGroups[i].tempId === tempId) {
          this.containerGroups.splice(i, 1);
          // send to parent
          this.sendToparent();
          break;
        }
      }
      // focus
      this.$nextTick(() => {
        this.$refs.containerGroupAddButton.focus();
      });
    },
    // on update / finished
    sendToparent() {
      this.$emit("containerGroupsUpdated", this.containerGroups);
      this.sendIsValid();
    },
    onIsValidUpdated(tempId, isValid) {
      // search position
      const position = this.containerGroupsIsValidArray.reduce((pos, currentId, index) => {
        if (pos !== null) {
          return pos;
        } else {
          return currentId === tempId ? index : null;
        }
      }, null);
      // edit
      if (isValid !== false) {
        if (position === null) this.containerGroupsIsValidArray.push(tempId);
      } else {
        if (position !== null) this.containerGroupsIsValidArray.splice(position, 1);
      }
      this.sendIsValid();
    },
    sendIsValid() {
      let valid = true;
      if (this.containerGroupsState === false) {
        valid = false;
      } else {
        for (const containerGroup of this.containerGroups) {
          if (!this.containerGroupsIsValidArray.includes(containerGroup.tempId)) {
            valid = false;
            break;
          }
        }
      }
      this.$emit("updateIsValid", valid);
    }
  }
};
</script>

<template>
  <div
    class="kt-contact-account"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-show="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("contactAccounts.pageTitleSingle") }}
      </h2>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
        {{ $t("contactAccounts.pageSubtitle") }}
      </p>
    </div>

    <!-- divider -->
    <div
      v-show="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION general info -->
      <div
        v-if="fullNameDisplay || emailDisplay || phoneDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3
          v-show="moduleModeProp"
          class="kt-view-page__section-title mb-0"
        >
          {{ $t("contactAccounts.pageTitleSingle") }}
        </h3>
        <!-- subtitle -->
        <p style="font-size: 13px; color: #343a40; margin-bottom: 12px; margin-top: 4px;">
          {{ $t("contactAccounts.pageSubtitle") }}
        </p>
        <div v-show="contactData && contactData.email">
          <!-- isLocked -->
          <div
            v-show="isLocked"
            class="kt-badge kt-badge--red mb-2"
          >
            <b-icon
              icon="exclamation-triangle-fill"
              class="kt-badge__icon"
              style="margin-top: -1px"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("contactAccounts.isLocked") }}</span></span>
          </div>
          <!-- email -->
          <b-row v-if="emailDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("contactAccounts.email") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ email }}
              </p>
            </b-col>
          </b-row>
          <!-- phone -->
          <b-row v-if="phoneDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("contactAccounts.phone") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ phone || $t("general.emptyWithHyphen") }}
              </p>
            </b-col>
          </b-row>
        </div>
        <div
          v-show="contactData && !contactData.email"
          class="mb-3"
        >
          <div class="kt-badge kt-badge--red">
            <b-icon
              icon="exclamation-triangle-fill"
              class="kt-badge__icon"
              style="margin-top: -1px"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("contactAccounts.noAccount") }}</span></span>
          </div>
        </div>

        <!-- edit/new button -->
        <b-button
          v-if="!viewModeProp"
          v-b-modal="'contactAccountEditModal' + _uid"
          size="sm"
          class="mt-1 mb-3"
          :variant="'outline-' + $systemSettings.theme"
        >
          <b-icon
            v-show="!contactData || !contactData.email"
            icon="plus"
          ></b-icon>
          {{ contactData && contactData.email ? $t("contactAccountEdit.contactAccountEditButton") : $t("contactAccountNew.contactAccountNewButton") }}
        </b-button>
      </div>
    </div>

    <!-- contactAccount edit modal -->
    <b-modal
      :id="'contactAccountEditModal' + _uid"
      hide-footer
      @shown="() => {
        $refs.contactAccountEditComponent.focusFirstElement()
      }"
    >
      <template #modal-title>
        <b-icon icon="person"></b-icon>
        {{ Boolean(contactData && contactData.email) ? $t("contactAccountEdit.pageTitle") : $t("contactAccountNew.pageTitle") }}
      </template>

      <ContactAccountEdit
        ref="contactAccountEditComponent"
        :contactEditModeProp="contactEditModeProp"
        :editModeProp="Boolean(contactData && contactData.email)"
        :contactIdProp="contactId || null"
        :contactDataProp="{
          isActive: isActive,
          contactContactInformations: contactContactInformations,
          contactOrganisations: contactOrganisations,
          isLocked: isLocked,
          email: email,
          phone: phone
        }"

        moduleModeProp
        @contactAccountEdited="(contactAccountData) => {
          $bvModal.hide('contactAccountEditModal' + _uid);
          isLocked = contactAccountData.isLocked;
          email = contactAccountData.email;
          phone = contactAccountData.phone;
          this.$emit('contactAccountEdited', {
            isLocked: contactAccountData.isLocked,
            email: contactAccountData.email,
            phone: contactAccountData.phone
          });
        }"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import ContactAccountEdit from "@/views/Office/Contacts/ContactAccountEdit";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";
import viewComponents from "@shared/services/UI/viewComponents";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: {
    ContactAccountEdit
  },
  mixins: [userRights, error, phoneNumber, viewComponents],
  props: {
    contactEditModeProp: {
      type: Boolean
    },
    viewModeProp: {
      type: Boolean
    },
    contactIdProp: {
      type: Number,
      default: null
    },
    contactDataProp: {
      type: Object,
      default: null
    },
    moduleModeProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "fullName",
          "email",
          "phone"
        ];
      }
    }
  },
  data() {
    return {
      // general
      contactId: this.contactIdProp,
      // form variables
      isActive: true,
      contactContactInformations: [],
      contactOrganisations: [],
      // contactUser form variables
      isLocked: false,
      email: "",
      phone: "",
      // dispayFields
      fullNameDisplay: false,
      emailDisplay: false,
      phoneDisplay: false
    };
  },
  computed: {
    contactData: function() {
      return this.contactDataProp;
    }
  },
  async mounted() {
    await this.importData();
  },
  methods: {
    // load/refresh data
    async importData() {
      try {
        let contactImportData = null;
        if (this.contactDataProp) {
          contactImportData = this.contactDataProp;
        } else {
          const resServices = await commonServices.get("contacts", this.contactId);
          contactImportData = resServices.data;
        }
        this.updateContactData(contactImportData);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateContactData(contactData) {
      // import data
      this.isActive = contactData.isActive;
      this.contactContactInformations = contactData.contactContactInformations;
      this.contactOrganisations = contactData.contactOrganisations;
      // import account data
      this.isLocked = contactData.isLocked;
      this.email = contactData.email;
      this.phone = this.importFormatPhone(contactData.phone);
    },

    // navigate to the entry page
    onGoBack() {
      navigate("office/contacts");
    }
  }
};
</script>

<template>
  <div class="systemSettings">
    <!-- form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- selectedSectors -->
        <b-col cols="12">
          <b-form-group
            ref="sectorCheckboxesGroup"
            :label="$t('systemSettings.sectors')"
            :invalid-feedback="sectorsInvalidFeedback"
            :state="sectorsState"
          >
            <b-form-checkbox
              v-for="sector in sectorsOptions"
              :key="sector.id"
              v-model="selectedSectors"
              :value="sector.id"
              :disabled="isLoading"
              :state="sectorsState"
              @change="sectorsValidation = true"
            >
              {{ sector.localisedName }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t("general.save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  mixins: [userRights, error],
  props: {},
  data() {
    return {
      isLoading: true,
      selectedSectors: [],
      // is validation active
      sectorsValidation: false,
      // options
      sectorsOptions: []
    };
  },
  computed: {
    // form validation
    sectorsState: function() {
      if (!this.sectorsValidation) return null;
      return this.selectedSectors && this.selectedSectors.length > 0 ? null : false;
    },
    sectorsInvalidFeedback: function() {
      return this.sectorsState === false ? this.$t("validationRules.required") : "";
    }

    // formatted variables
  },
  async mounted() {
    try {
      await this.importData();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    async importData() {
      try {
        this.isLoading = true;
        const res = await commonServices.getAll("sectors", null);
        this.$systemSettings.sectors = res.data.map((sector) => {
          sector.localisedName = this.$t("sectors." + sector.name) + (!sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "");
          return sector;
        });
        this.sectorsOptions = this.$systemSettings.sectors;
        const subscribedSectors = this.$systemSettings.sectors.filter((sector) => sector.isSubscribed === true);
        this.selectedSectors = subscribedSectors.map(v => v.id);
        this.isLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // submit
    onValidateForm() {
      this.sectorsValidation = true;

      return (
        this.sectorsState !== false
      );
    },
    async onSubmit() {
      try {
        // validation
        if (this.onValidateForm() === false) {
          return false;
        }
        this.isLoading = true;
        // save
        for (const sector of this.sectorsOptions) {
          const form = {
            isSubscribed: this.selectedSectors.includes(sector.id)
          };
          const res = await commonServices.put("sectors", form, sector.id);
          if (res.data !== true) {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("systemSettings.editionError.title"),
              message: this.$t("systemSettings.editionError.text")
            });
            await this.importData();
            this.isLoading = false;
            return false;
          }
        }

        // all success
        this.$emit("alert", "success", {
          title: this.$t("systemSettings.edited.title"),
          message: this.$t("systemSettings.edited.text")
        });
        await this.importData();
        this.isLoading = false;
        return true;
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

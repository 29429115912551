<template>
  <div class="kt-invoiceReport-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- progress bar -->
    <b-progress
      v-show="showProgress"
      :value="progressValue"
      :max="progressMax"
      show-progress
      animated
    ></b-progress>

    <!-- invoiceReport form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="invoiceReportData !== null ? invoiceReportData.laboratoryId : null"
            @input="laboratoryId = $event"
          />
        </b-col>

        <!-- organisation -->
        <b-col cols="12">
          <b-form-group
            v-slot="{ariaDescribedby}"
            :label="$t('invoiceReports.organisation')"
            :invalid-feedback="organisationInvalidFeedback"
            :state="organisationState"
          >
            <b-form-radio
              ref="multipleCreationRadio"
              v-model="multipleCreationMode"
              :aria-describedby="ariaDescribedby"
              name="multiple-creation-mode"
              :value="false"
              class="mb-2"
              style="padding-bottom: 1px; z-index: 2;"
            >
              <span>{{ $t("invoiceReportNew.addSingleReportRadioOption") }}</span>
              <!-- choose organisation Button -->
              <b-button
                v-show="!organisation"
                ref="organisationChooseButton"
                v-b-modal="'chooseOrganisationModal'"
                class="my-n1"
                :variant="!multipleCreationMode ? 'outline-' + $systemSettings.theme : 'outline-secondary'"
              >
                {{ $t("general.choose") }}
              </b-button>
              <!-- organisation Value -->
              <b-button
                v-show="organisation"
                ref="organisationClearValueButton"
                class="my-n1"
                variant="outline-secondary"
                @click="organisation = null"
              >
                {{ organisation ? organisation.name + (organisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : "" }}
                <b-icon
                  icon="x"
                  class="mr-0"
                ></b-icon>
              </b-button>
            </b-form-radio>
            <b-form-radio
              v-model="multipleCreationMode"
              :aria-describedby="ariaDescribedby"
              name="multiple-creation-mode"
              :value="true"
              style="margin-bottom: 2px !important;"
            >
              {{ $t("invoiceReportNew.addMultipleReportRadioOption") }}
              <b-form-select
                v-model="organisationTypeName"
                class="my-n1"
                style="width: 133px;"
                :options="[
                  {name: 'clinics', localisedName: $t('accountingSectionOrganisationTypes.clinics')},
                  {name: 'healthCenters', localisedName: $t('accountingSectionOrganisationTypes.healthCenters')},
                  {name: 'hospitals', localisedName: $t('accountingSectionOrganisationTypes.hospitals')}
                ]"
                value-field="name"
                text-field="localisedName"
                @input="multipleCreationMode = true;"
              ></b-form-select>
            </b-form-radio>
          </b-form-group>
        </b-col>

        <!-- endDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('invoiceReports.endDate')"
            :invalid-feedback="endDateInvalidFeedback"
            :state="endDateState"
          >
            <b-form-input
              v-model="endDate"
              type="date"
              :state="endDateState"
              @blur="onDateBlur('endDateValidation')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- invoiceDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('invoiceReports.invoiceDate')"
            :invalid-feedback="invoiceDateInvalidFeedback"
            :state="invoiceDateState"
          >
            <b-form-input
              v-model="invoiceDate"
              type="date"
              @blur="onDateBlur('invoiceDateValidation')"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- info badges -->
        <b-col
          cols="12"
          :class="{'mb-3': reference && !multipleCreationMode}"
        >
          <!-- new line + spacer -->
          <div></div>
          <!-- reference -->
          <div
            v-show="reference && !multipleCreationMode"
            class="kt-badge kt-badge--light-gray kt-badge--lg"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('invoiceReports.reference') + ' : ' }}</span>{{ reference }}</span>
          </div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            ref="submitButton"
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- organisationChoose modal -->
    <b-modal
      id="chooseOrganisationModal"
      hide-footer
      :title="$t('organisationChoose.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.organisationChooseComponent.focusFirstElement()
      }"
      @hidden="() => {
        $nextTick(() => {
          if (organisation) {
            $refs.organisationClearValueButton.focus();
          } else {
            $refs.organisationChooseButton.focus();
          }
        });
      }"
    >
      <OrganisationChoose
        ref="organisationChooseComponent"
        :optionsProp="{
          filterIsPrescribing: true
        }"
        @chosen="onOrganisationChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- invoiceReportAdditionReportModal -->
    <b-modal
      id="invoiceReportAdditionReportModal"
      hide-footer
      :title="$t('invoiceReportAdditionReport.modalTitle')"
      @shown="() => {
        $refs.invoiceReportAdditionReportComponent.focusFirstElement()
      }"
      @hidden="() => {
        if ($refs.submitButton) {
          $refs.submitButton.focus();
        }
      }"
    >
      <InvoiceReportAdditionReport
        ref="invoiceReportAdditionReportComponent"
        :reportProp="invoicesAdditionReport"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import OrganisationChoose from "@/views/Data/Organisations/OrganisationChoose";
import InvoiceReportAdditionReport from "@/views/Accounting/Invoices/InvoiceReports/InvoiceReportAdditionReport";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// services
import commonServices from "@shared/services/API/commonServices";
import invoiceReportsServices from "@/services/API/invoiceReportsServices";
import organisationsServices from "@/services/API/organisationsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { OrganisationChoose, InvoiceReportAdditionReport, SmartSelectInput },
  mixins: [userRights, error, date],
  props: {
    invoiceReportIdProp: {
      type: Number,
      default: null
    },
    organisationTypeNameProp: {
      type: String,
      default: "clinics"
    }
  },
  data() {
    return {
      // general
      multipleCreationMode: false,
      invoiceReportId: this.invoiceReportIdProp,
      invoiceReportData: null,
      // progress
      showProgress: false,
      progressValue: 0,
      progressMax: 0,
      // addition report
      invoicesAdditionReport: null,

      // form variables
      organisationTypeName: this.organisationTypeNameProp || "clinics",
      laboratoryId: null,
      organisation: null,
      endDate: "",
      invoiceDate: "",
      invoiced: "",
      reference: "",
      // is validation active
      laboratoryIdValidation: false,
      organisationValidation: false,
      endDateValidation: false,
      invoiceDateValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    organisationState: function() {
      if (!this.organisationValidation) return null;
      if (this.multipleCreationMode) {
        // organisationTypeName required
        return this.organisationTypeName ? null : false;
      } else {
        if (!this.organisation || !this.organisation.id) {
          // organisation required
          return false;
        } else {
          // no error
          return null;
        }
      }
    },
    organisationInvalidFeedback: function() {
      if (this.organisationState === null) return "";
      if (this.multipleCreationMode) {
        // organisationTypeName required
        return this.$t("invoiceReportNew.validations.organisationTypeNameRequired");
      } else {
        return this.$t("validationRules.required");
      }
    },

    endDateState: function() {
      if (!this.endDateValidation) return null;
      if (!this.endDate) return false;
      if (!this.validatePastDate(this.endDate)) return false;
      return null;
    },
    endDateInvalidFeedback: function() {
      if (this.endDateState === null) return "";
      if (!this.endDate) return this.$t("validationRules.required");
      return this.$t("invoiceReportNew.validations.dateMustBePastOrToday");
    },
    invoiceDateState: function() {
      if (!this.invoiceDateValidation) return null;
      if (!this.invoiceDate) return false;
      return this.validateDateBeforeDate(this.endDate, this.invoiceDate);
    },
    invoiceDateInvalidFeedback: function() {
      if (this.invoiceDateState === null) return "";
      if (!this.invoiceDate) return this.$t("validationRules.required");
      return this.$t("invoiceReportNew.validations.endDateBeforeInvoiceDateError");
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.$t("invoiceReportNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.$t("invoiceReportNew.submitText");
    }
  },
  watch: {
    organisation: function() {
      this.refreshReference();
    }
  },
  async mounted() {
    this.endDate = this.getIsoDate();
    this.invoiceDate = this.getIsoDate();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.laboratoryInput) {
        this.$refs.laboratoryInput.focusFirstElement();
      } else if (this.$refs.multipleCreationRadio) {
        this.$refs.multipleCreationRadio.focus();
      }
    },

    // organisation
    async onOrganisationChosen(targetOrganisation) {
      try {
        if (targetOrganisation) {
          const res = await commonServices.get("organisations", targetOrganisation.id);
          this.organisation = res.data.id ? res.data : null;
          this.multipleCreationMode = false;
        } else {
          this.organisation = null;
        }
        this.organisationValidation = true;
        this.$bvModal.hide("chooseOrganisationModal");
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // input blur
    onDateBlur(targetValidation) {
      this[targetValidation] = true;
      this.refreshReference();
    },
    // reference
    refreshReference() {
      if (this.organisation) {
        this.reference = this.$t("invoiceReports.referencePrefix");
        if (this.organisation.code) {
          this.reference += " " + this.organisation.code;
        }
        if (this.endDate) {
          this.reference += " " + this.endDate;
        }
      } else {
        this.reference = "";
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("accounting/invoices/invoiceReports");
    },

    // submit functions
    validateForm() {
      this.refreshReference();
      this.laboratoryIdValidation = true;
      this.organisationValidation = true;
      this.endDateValidation = true;
      this.invoiceDateValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.organisationState === false ||
        this.endDateState === false ||
        this.invoiceDateState === false
      );
    },
    async saveInvoiceReport() {
      try {
        const form = {
          laboratoryId: this.laboratoryId,
          organisationId: this.organisation ? this.organisation.id : null,
          endDate: this.endDate || null,
          invoiceDate: this.invoiceDate || null,
          reference: this.reference
        };
        // CREATION
        if (this.multipleCreationMode === false) {
          // Single
          const res = await commonServices.post("invoiceReports", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("invoiceReportNew.notifications.addedTitle"),
              message: this.$t("invoiceReportNew.notifications.addedText")
            });
            navigate("accounting/invoices/invoiceReports");
          } else if (res.data.error === "emptyInvoice") {
            this.$emit("alert", "danger", {
              title: this.$t("invoiceReportNew.notifications.emptyInvoiceTitle"),
              message: this.$t("invoiceReportNew.notifications.emptyInvoiceText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("invoiceReportNew.notifications.additionErrorTitle"),
              message: this.$t("invoiceReportNew.notifications.additionErrorText")
            });
          }
        } else {
          // Multiple
          delete form.organisationId;
          form.organisationTypeName = this.organisationTypeName;
          // init progress
          const resOrganisations = await organisationsServices.countOrganisationsOfType(this.organisationTypeName);
          this.progressMax = resOrganisations.data.count;
          this.progressValue = 0;
          this.showProgress = true;
          // post All
          const res = await invoiceReportsServices.postAll(form, this.onPostInvoicesProgress);
          this.invoicesAdditionReport = JSON.parse(res.data.split("|").pop());
          this.$bvModal.show("invoiceReportAdditionReportModal");
          this.showProgress = false;
          this.progressValue = 0;
          this.progressMax = 1;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveInvoiceReport();
    },
    onPostInvoicesProgress(res) {
      const done = res.currentTarget.response.split("|");
      if (done.length > 1) {
        if (isNaN(Number(done[done.length - 1]))) {
          this.progressValue = this.progressMax;
        } else {
          this.progressMax = done[0];
          this.progressValue = done[done.length - 1];
        }
      } else {
        this.progressValue = Math.min(this.progressMax, this.progressValue + 1);
      }
    }
  }
};
</script>

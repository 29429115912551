<template>
  <div class="kt-patientPayments mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("payments.patientPayments.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="() => {
          if (currentActiveMode !== 'awaitingAdd') {
            onAwaitingLineAdd();
          }
        }"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("payments.patientPayments.addAwaitingPaymentLineButton") }}
      </b-button>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <b-row class="mb-1">
      <!-- patientPayments title -->
      <b-col
        cols="6"
        class="mb-3"
      >
        <!-- title -->
        <h2
          class="kt-page-header__title h4"
          style="margin-bottom: 2px !important;"
        >
          {{ $t("payments.patientPayments.tableTitle") }}
        </h2>
        <!-- switch to view button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'patientPayments', 'view'])"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === null ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode(null)"
        >
          <b-icon
            icon="list"
            class="mb-0"
          ></b-icon>
          <span
            class="align-middle ml-1"
            style="line-height: 19px;"
          >{{ $t("payments.patientPayments.viewLinesButton") }}</span>
        </b-button>
      </b-col>
      <!-- awaitingPaymentLines title -->
      <b-col
        cols="6"
        class="mb-3"
      >
        <!-- title -->
        <h2
          class="kt-page-header__title h4"
          style="margin-bottom: 2px !important;"
        >
          {{ $t("payments.patientPayments.awaitingTableTitle") }}
        </h2>
        <!-- switch to view button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'view'])"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === 'awaiting' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode('awaiting')"
        >
          <b-icon
            icon="list"
            class="mb-0"
          ></b-icon>
          <span
            class="align-middle mx-1"
            style="line-height: 19px;"
          >{{ $t("payments.patientPayments.viewAwaitingPaymentLinesButton") }}</span>
          <span class="kt-count-bubble"><span class="kt-count-bubble__number">{{ awaitingPaymentCount }}</span></span>
        </b-button>
      </b-col>
    </b-row>

    <!-- lines table -->
    <b-table
      v-show="(currentActiveMode === null || currentActiveMode === 'awaiting') && editAwaitingId === null && reconcileAwaitingId === null && editPatientPaymentId === null"
      ref="table"
      class="kt-table"
      tbody-tr-class="kt-paymentLine"
      style="margin-bottom: 2px !important;"
      :fields="currentActiveMode === 'awaiting' ? awaitingFields : tableFields"
      :items="lines"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter PaymentTypeId -->
          <b-th class="kt-table__th-search">
            <b-form-select
              ref="paymentTypeFilter"
              v-model="filterPaymentTypeId"
              class="kt-table__th-search-input"
              :options="paymentTypesOptions"
              value-field="id"
              text-field="localisedName"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-select>
          </b-th>
          <!-- filter PaymentDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputDateRange
              ref="filterRangeFilter"
              :valueProp="filterRange"
              class="kt-table__th-search-input"
              fullWidthProp
              @keydown.enter.exact="onPageInput"
              @change="filterRange = $event; searchHasChanged = true;"
              @submit="onPageInput"
            />
          </b-th>
          <!-- filter Paid -->
          <b-th
            v-show="currentActiveMode === null"
            class="kt-table__th-search"
          >
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter PayerName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterPayerName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterPayerName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterPayerName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Bank -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="banks"
              searchedFieldProp="code"
              :searchedLaboratoriesProp="[]"
              :returnFieldsArrayProp="['id', 'code', 'name']"
              suggestionsKeyProp="id"

              :valueProp="filterBank"
              :displayedValueProp="filterBank ? filterBank.code : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
              }"
              noWrapProp
              :searchMinLengthProp="1"

              @onUpdateParent="onBankChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter CheckNumber -->
          <b-th
            v-show="currentActiveMode === 'awaiting'"
            class="kt-table__th-search"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCheckNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterCheckNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCheckNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter TransactionId -->
          <b-th
            v-show="currentActiveMode === 'awaiting'"
            class="kt-table__th-search"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterTransactionId"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterTransactionId !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterTransactionId')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Acts / Paid -->
          <b-th
            v-show="currentActiveMode === 'awaiting'"
            class="kt-table__th-search"
          >
          </b-th>
          <!-- filter comment -->
          <b-th
            v-show="currentActiveMode === 'awaiting'"
            class="kt-table__th-search"
          >
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            :style="{width: (searchHasChanged ? '151px' : currentActiveMode === null ? '85px' : '131px')}"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- accessNumbers -->
      <template v-slot:cell(accessNumbers)="data">
        <div
          v-for="filePayment in data.item.filePayments"
          :key="filePayment.id"
        >
          <span>{{ filePayment.file.accessNumber }}</span>
          <!-- complementary -->
          <span
            v-if="filePayment.file.parentFileId"
            class="kt-complementary-icon"
          >
            <b-icon icon="back"></b-icon>
          </span>
          <span v-if="data.item.filePayments.length > 1 || displayAccessNumberDetails">
            {{ filePayment.paid ? '(' + $n(importFormatPrice(filePayment.paid), "currency") + ')' : '' }}
          </span>
        </div>
      </template>
      <!-- patientLastName -->
      <template v-slot:cell(patientLastName)="data">
        <div
          v-for="filePayment in data.item.filePayments"
          :key="filePayment.id"
        >
          {{ (data.item.filePayments.length > 1 ? '• ' : '') + (filePayment.file.patient ? filePayment.file.patient.lastName : '') }}
        </div>
      </template>
      <!-- patientFirstName -->
      <template v-slot:cell(patientFirstName)="data">
        <div
          v-for="filePayment in data.item.filePayments"
          :key="filePayment.id"
        >
          {{ (data.item.filePayments.length > 1 ? '• ' : '') + filePayment.file.patient ? filePayment.file.patient.firstName : '' }}
        </div>
      </template>
      <!-- comment -->
      <template v-slot:cell(comment)="data">
        <div v-if="data.item.comment">
          <b-button
            :id="'comment_' + data.item.id + '_' + _uid"
            class="btn-icon position-relative"
            variant="light"
            :disabled="!data.item.comment"
          >
            <b-icon icon="chat-left-dots"></b-icon>
            <span class="kt-count-bubble kt-count-bubble--corner"><span class="kt-count-bubble__number">{{ '1' + '' }}</span></span>
          </b-button>
          <!-- popovers -->
          <b-popover
            :target="'comment_' + data.item.id + '_' + _uid"
            triggers="hover focus"
            placement="bottomleft"
          >
            {{ data.item.comment }}
          </b-popover>
        </div>
      </template>

      <!-- actions -->
      <template v-slot:cell(actionButtons)="data">
        <div
          :ref="'actionsContainer_' + data.index"
          :style="currentActiveMode === null ? 'width: 85px;' : 'width: 131px;'"
        >
          <!-- reconcile -->
          <b-button
            v-show="currentActiveMode === 'awaiting' && isEnabled(['menu', 'accounting', 'payments', 'patientPayments', 'reconcile'])"
            :ref="data.index === 0 ? 'reconcileLineButton_' + data.index : null"
            class="mr-1"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onAwaitingPaymentReconcile(data.item.id)"
          >
            <b-icon
              icon="arrows-collapse"
              rotate="90"
            ></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="(currentActiveMode === null && isEnabled(['menu', 'accounting', 'payments', 'patientPayments', 'edit'])) || (currentActiveMode === 'awaiting' && isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'edit']))"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            :class="currentActiveMode === null ? 'mr-1' : 'mx-1'"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="currentActiveMode === null ? onPatientPaymentEdit(data.item.id) : onAwaitingPaymentEdit(data.item.id)"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete -->
          <b-button
            v-show="(currentActiveMode === null && isEnabled(['menu', 'accounting', 'payments', 'patientPayments', 'delete'])) || (currentActiveMode === 'awaiting' && isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'delete']))"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            :class="{'ml-1': currentActiveMode === 'awaiting'}"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="currentActiveMode === null ? onDeletePatientPayment(data.item.id) : onDeleteAwaitingPayment(data.item.id)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h5
      v-show="showNoResult"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h5>

    <!-- maxReached -->
    <div
      v-show="(currentActiveMode === null || currentActiveMode === 'awaiting') && editAwaitingId === null && reconcileAwaitingId === null && editPatientPaymentId === null && maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
      <!-- <b-button
        variant="outline-secondary"
        :disabled="loadingExtendFiles"
        @click="extendSearch"
      >
        <b-icon icon="chevron-down"></b-icon>
        <span>&nbsp;{{ $t('general.extendResults') }}</span>
      </b-button>
      <b-spinner
        v-show="loadingExtendFiles"
        class="ml-1"
        :class="'kt-spinner--' + $systemSettings.theme"
        small
      ></b-spinner> -->
    </div>

    <!-- divider -->
    <div
      v-show="currentActiveMode === 'awaitingAdd' || editAwaitingId !== null"
      class="w-100 border-top mt-1 mb-4"
    ></div>
    <!-- awaitingPaymentEdit component title -->
    <div v-show="currentActiveMode === 'awaitingAdd' || editAwaitingId !== null">
      <h5 class="kt-paymentLine-edit__subtitle mb-3">
        {{ editAwaitingId !== null ? $t("payments.patientPayments.awaitingTableTitleEdit") : $t("payments.patientPayments.awaitingTableTitleAdd") }}
      </h5>
    </div>
    <!-- awaitingPayment Add / Edit -->
    <AwaitingPaymentEdit
      v-if="currentActiveMode === 'awaitingAdd' || editAwaitingId !== null"
      :initialValuesProp="initialValuesAwaitingPayment"
      :awaitingPaymentIdProp="editAwaitingId"
      :editModeProp="!!editAwaitingId"
      moduleModeProp
      @createdAndGoNext="updateCounts"
      @awaitingPaymentSaved="onAwaitingPaymentSaved"
      @goToReconciliation="($event) => {
        currentActiveMode = 'awaiting';
        onPageInput();
        onAwaitingPaymentReconcile($event);
      }"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- divider -->
    <div
      v-show="currentActiveMode === 'add' || reconcileAwaitingId !== null || editPatientPaymentId !== null"
      class="w-100 border-top mt-1 mb-4"
    ></div>
    <!-- reconcile awaiting / edit patientPayment -->
    <div v-show="currentActiveMode === 'add' || reconcileAwaitingId !== null || editPatientPaymentId !== null">
      <h5 class="kt-paymentLine-edit__subtitle mb-3">
        {{ editPatientPaymentId === null ? $t("payments.patientPayments.patientTableTitleReconcile") : $t("payments.patientPayments.patientTableTitleEdit") }}
      </h5>
    </div>
    <!-- awaitingLine Reconcile -->
    <PatientPaymentEdit
      v-if="currentActiveMode === 'add' || reconcileAwaitingId !== null || editPatientPaymentId !== null"
      :awaitingPaymentIdProp="reconcileAwaitingId"
      :patientPaymentIdProp="editPatientPaymentId"
      :editModeProp="!!editPatientPaymentId"
      moduleModeProp
      @patientPaymentSaved="onPatientPaymentSaved"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
import AwaitingPaymentEdit from "@/views/Accounting/Payments/AwaitingPayments/AwaitingPaymentEdit";
import PatientPaymentEdit from "@/views/Accounting/Payments/PatientPayments/PatientPaymentEdit";
import InputDateRange from "@shared/views/Helpers/InputDateRange";
// services
import commonServices from "@shared/services/API/commonServices";
import awaitingPaymentsServices from "@/services/API/awaitingPaymentsServices";
// helpers
import paymentsHelpers from "@shared/services/UI/paymentsHelpers";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
// others
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearch, AwaitingPaymentEdit, PatientPaymentEdit, InputDateRange },
  mixins: [paymentsHelpers, userRights, error, price, saveParamsInQuery],
  props: {
    accessNumberProp: {
      type: String,
      default: ""
    },
    isAwaitingProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      searchHasChanged: false,
      displayAccessNumberDetails: false,
      maxReached: false,
      loading: true,
      loadingExtendFiles: false,
      currentActiveMode: null, // null | "add" | "awaiting" | "awaitingAdd"
      awaitingPaymentCount: 0,
      lastSearchFilters: null,
      lastSearchId: null,
      lastSearchFiltersAwaiting: null,
      lastSearchIdAwaiting: null,

      // patientPayments
      lines: [],
      linesCount: 0,
      tableFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paymentType",
          sortable: true,
          label: this.$t("payments.patientPayments.paymentType"),
          formatter: (_value, _key, item) => {
            return item.paymentType.name ? this.$t("paymentTypes." + item.paymentType.name) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paymentDate",
          sortable: true,
          label: this.$t("payments.patientPayments.paymentDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paid",
          sortable: false,
          label: this.$t("payments.patientPayments.paid"),
          formatter: (value, _key, _item) => {
            return value ? this.$n(value, "currency") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumbers",
          sortable: false,
          label: this.$t("payments.patientPayments.accessNumbers")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "patientLastName",
          sortable: false,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "patientFirstName",
          sortable: false,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "payerName",
          sortable: true,
          label: this.$t("patients.payerName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "bank",
          sortable: true,
          label: this.$t("payments.patientPayments.bank"),
          formatter: (value, _key, _item) => {
            return value ? value.code : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      awaitingFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paymentType",
          sortable: true,
          label: this.$t("payments.patientPayments.paymentType"),
          formatter: (_value, _key, item) => {
            return item.paymentType.name ? this.$t("paymentTypes." + item.paymentType.name) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paymentDate",
          sortable: true,
          label: this.$t("payments.patientPayments.paymentDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumber",
          sortable: true,
          label: this.$t("payments.patientPayments.accessNumber")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "lastName",
          sortable: true,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "firstName",
          sortable: true,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "payerName",
          sortable: true,
          label: this.$t("patients.payerName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "bank",
          sortable: true,
          label: this.$t("payments.awaitingPayments.bank"),
          formatter: (value, _key, _item) => {
            return value ? value.code : "";
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "checkNumber",
          sortable: true,
          label: this.$t("payments.awaitingPayments.checkNumber")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "transactionId",
          sortable: true,
          label: this.$t("payments.awaitingPayments.transactionId")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paid",
          sortable: true,
          label: this.$t("payments.awaitingPayments.paid"),
          formatter: (value, _key, _item) => {
            return this.$n(value || 0, "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "comment",
          sortable: false,
          label: ""
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      filterPaymentTypeId: null,
      filterRange: { start: "", end: "" },
      filterAccessNumber: this.accessNumberProp,
      filterLastName: "",
      filterFirstName: "",
      filterPayerName: "",
      filterBank: null,
      filterCheckNumber: "",
      filterTransactionId: "",
      filterComment: "",

      // awaiting
      editAwaitingId: null,

      // add / edit payment
      reconcileAwaitingId: null,
      editPatientPaymentId: null,

      // saveParamsConfig
      saveParamsConfig: {
        currentActiveMode: "string",
        filterPaymentTypeId: "string",
        filterRange: { start: "string", end: "string" },
        filterAccessNumber: "string",
        filterLastName: "string",
        filterFirstName: "string",
        filterPayerName: "string",
        filterBank: { id: "number", code: "string", name: "string" },
        filterCheckNumber: "string",
        filterTransactionId: "string",
        filterComment: "string"
      }
    };
  },
  computed: {
    // isSearchActive: function() {
    //   if (
    //     this.filterBank !== null ||
    //     Boolean(this.filterRange)
    //   ) {
    //     return true;
    //   }
    //   // awaiting
    //   if (
    //     (this.currentActiveMode === "awaiting") &&
    //     (
    //       this.filterAccessNumber !== "" ||
    //       this.filterLastName !== "" ||
    //       this.filterFirstName !== "" ||
    //       this.filterPayerName !== "" ||
    //       this.filterCheckNumber !== "" ||
    //       this.filterTransactionId !== "" ||
    //       this.filterComment !== ""
    //     )
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
    showNoResult: function() {
      if (this.linesCount || this.loading || this.searchHasChanged) {
        return false;
      }
      return !!(this.currentActiveMode === null || this.currentActiveMode === "awaiting");
    },
    initialValuesAwaitingPayment: function() {
      return {
        paymentTypeId: this.filterPaymentTypeId,
        range: this.filterRange,
        accessNumber: this.filterAccessNumber,
        lastName: this.filterFirstName,
        payerName: this.filterPayerName,
        firstName: this.filterLastName,
        bank: this.filterBank,
        checkNumber: this.filterCheckNumber,
        comment: this.filterComment
      };
    },
    // formatted variables

    // options
    paymentTypesOptions: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.paymentTypes)) || [];
      options.unshift({ id: null, localisedName: this.$tc("general.allMasculine", 2) });
      return options;
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      if (!this.isAwaitingProp) {
        await this.onPageInput();
      } else {
        this.changeCurrentActiveMode("awaiting");
      }

      // auto-focus
      if (this.$refs.addLineButton) {
        this.$refs.addLineButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (
        this.$refs.reconcileLineButton_0 &&
        this.currentActiveMode === "awaiting" && this.isEnabled(["menu", "accounting", "payments", "patientPayments", "edit"])
      ) {
        this.$refs.reconcileLineButton_0.focus();
      } else if (
        this.$refs.editLineButton_0 &&
        ((this.currentActiveMode === null && this.isEnabled(["menu", "accounting", "payments", "patientPayments", "edit"])) || (this.currentActiveMode === "awaiting" && this.isEnabled(["menu", "accounting", "payments", "awaitingPayments", "edit"])))
      ) {
        this.$refs.editLineButton_0.focus();
      } else if (
        this.$refs.deleteLineButton_0 &&
        ((this.currentActiveMode === null && this.isEnabled(["menu", "accounting", "payments", "patientPayments", "delete"])) || (this.currentActiveMode === "awaiting" && this.isEnabled(["menu", "accounting", "payments", "awaitingPayments", "delete"])))
      ) {
        this.$refs.deleteLineButton_0.focus();
      }
    },
    focusFirstFilter() {
      this.$refs.paymentTypeFilter.focus();
    },

    // bank
    onBankChosen(bank) {
      this.filterBank = bank ? { id: bank.id, code: bank.code, name: bank.name } : null;
      this.searchHasChanged = true;
    },

    // table functions
    async onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    async onClearAllFilters() {
      this.filterPaymentTypeId = null;
      this.filterRange = null;
      this.filterAccessNumber = "";
      this.filterLastName = "";
      this.filterFirstName = "";
      this.filterPayerName = "";
      this.filterBank = null;
      this.filterBank = null;
      this.filterCheckNumber = "";
      this.filterTransactionId = "";
      this.filterComment = "";
      await this.onPageInput();
    },
    async changeCurrentActiveMode(target) {
      this.editAwaitingId = null;
      this.editPatientPaymentId = null;
      this.reconcileAwaitingId = null;
      this.currentActiveMode = target;
      this.focusFirstFilter();
      await this.onPageInput();
    },
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (this.filterRange) {
          const filterStartDate = new Date(this.filterRange.start).getFullYear();
          if (filterStartDate < 1900 || filterStartDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;
        const accessNumber = this.filterAccessNumber;

        let res = [];
        if (this.currentActiveMode === null) {
          // VIEW PATIENT PAYMENTS
          this.lastSearchFilters = {
            laboratoryId: null,
            paymentTypeId: this.filterPaymentTypeId,
            bankId: this.filterBank ? this.filterBank.id : null,
            startDate: this.filterRange && this.filterRange.start ? this.filterRange.start : null,
            endDate: this.filterRange && this.filterRange.end ? this.filterRange.end : null,
            accessNumber: this.filterAccessNumber,
            lastName: this.filterLastName,
            firstName: this.filterFirstName,
            payerName: this.filterPayerName
          };
          res = await commonServices.getAll("patientPayments", this.lastSearchFilters);
          this.linesCount = res.data.rows.length;
          this.maxReached = res.data.maxReached;
          if (res.data && res.data.rows.length) {
            this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
          }
          this.lines = res.data.rows;
          for (const line of this.lines) {
            line.paid = this.importFormatPrice(line.paid);
          }
          this.displayAccessNumberDetails = Boolean(accessNumber);
        } else if (this.currentActiveMode === "awaiting") {
          // AWAITING
          this.lastSearchFiltersAwaiting = {
            laboratoryId: null,
            paymentTypeId: this.filterPaymentTypeId,
            accessNumber: this.filterAccessNumber,
            firstName: this.filterFirstName,
            lastName: this.filterLastName,
            payerName: this.filterPayerName,
            startDate: this.filterRange && this.filterRange.start ? this.filterRange.start : null,
            endDate: this.filterRange && this.filterRange.end ? this.filterRange.end : null,
            bankId: this.filterBank ? this.filterBank.id : null,
            checkNumber: this.filterCheckNumber,
            transactionId: this.filterTransactionId
          };
          res = await commonServices.getAll("awaitingPayments", this.lastSearchFiltersAwaiting);
          this.linesCount = res.data.rows.length;
          this.maxReached = res.data.maxReached;
          if (res.data && res.data.rows.length) {
            this.lastSearchIdAwaiting = res.data.rows[res.data.rows.length - 1].id;
          }
          this.lines = res.data.rows;
          for (const line of this.lines) {
            line.paid = this.importFormatPrice(line.paid);
          }
          this.displayAccessNumberDetails = false;
        } else {
          this.lines = [];
          this.linesCount = 0;
          this.maxReached = false;
        }

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.linesCount) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.focusFirstFilter();
          }
        }

        await this.updateCounts();
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async updateCounts() {
      try {
        const availableLaboratoryIds = this.$systemSettings.availableLaboratories.map(v => v.id);
        const resAwaiting = await awaitingPaymentsServices.count(availableLaboratoryIds);
        this.awaitingPaymentCount = resAwaiting.data.count || 0;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async extendSearch() {
      try {
        this.loadingExtendFiles = true;

        if (this.currentActiveMode === null) {
          this.lastSearchFilters.maxId = this.lastSearchId;

          // search and Update
          const res = await commonServices.getAll("patientPayments", this.lastSearchFilters);
          if (res.data && res.data.rows.length) {
            this.maxReached = res.data.maxReached;
            this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
            for (const row of res.data.rows) {
              row.paid = this.importFormatPrice(row.paid);
            }
            this.lines.push(...res.data.rows);
            this.linesCount = this.lines.length;
            this.displayAccessNumberDetails = false;
          } else {
            this.maxReached = false;
          }
        } else {
          this.lastSearchFiltersAwaiting.maxId = this.lastSearchIdAwaiting;

          // search and Update
          const res = await commonServices.getAll("awaitingPayments", this.lastSearchFiltersAwaiting);
          if (res.data && res.data.rows.length) {
            this.maxReached = res.data.maxReached;
            this.lastSearchIdAwaiting = res.data.rows[res.data.rows.length - 1].id;
            for (const row of res.data.rows) {
              row.paid = this.importFormatPrice(row.paid);
            }
            this.lines.push(...res.data.rows);
            this.linesCount = this.lines.length;
            this.displayAccessNumberDetails = false;
          } else {
            this.maxReached = false;
          }
        }

        this.loadingExtendFiles = false;
      } catch (err) {
        this.loadingExtendFiles = false;
        this.handleErrors(err);
      }
    },

    // patientPayment
    async onDeletePatientPayment(patientPaymentId) {
      try {
        if (patientPaymentId) {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.patientPaymentDelete.text"), {
            title: this.$t("payments.patientPaymentDelete.title"),
            okVariant: "danger",
            okTitle: this.$t("payments.patientPaymentDelete.ok"),
            cancelTitle: this.$t("payments.patientPaymentDelete.cancel"),
            centered: true
          });
          if (result) {
            // delete
            this.loading = true;
            const res = await commonServices.delete("patientPayments", patientPaymentId);
            if (res.data === true) {
              this.$emit("alert", "success", {
                title: this.$t("payments.patientPaymentDelete.notifications.deletedTitle"),
                message: this.$t("payments.patientPaymentDelete.notifications.deletedText")
              });
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("payments.patientPaymentDelete.notifications.deletedErrorTitle"),
                message: this.$t("payments.patientPaymentDelete.notifications.deletedErrorText")
              });
            }
            await this.onPageInput(true);
            this.loading = false;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onAwaitingPaymentReconcile(awaitingPaymentId) {
      this.editAwaitingId = null;
      this.editPatientPaymentId = null;
      this.reconcileAwaitingId = awaitingPaymentId;
    },
    onPatientPaymentEdit(patientPaymentId) {
      this.editAwaitingId = null;
      this.reconcileAwaitingId = null;
      this.editPatientPaymentId = patientPaymentId;
    },
    async onPatientPaymentSaved() {
      this.currentActiveMode = this.editPatientPaymentId ? null : "awaiting";
      this.editAwaitingId = null;
      this.reconcileAwaitingId = null;
      this.editPatientPaymentId = null;
      await this.onClearAllFilters();
      this.focusFirstFilter();
    },

    // awaitingPayment
    async onAwaitingLineAdd() {
      if (this.currentActiveMode !== "awaitingAdd") {
        await this.changeCurrentActiveMode("awaitingAdd");
      }
    },
    async onAwaitingPaymentEdit(awaitingPaymentId) {
      this.editAwaitingId = awaitingPaymentId;
    },
    async onAwaitingPaymentSaved() {
      this.editAwaitingId = null;
      this.reconcileAwaitingId = null;
      this.editPatientPaymentId = null;
      this.currentActiveMode = "awaiting";
      await this.onClearAllFilters();
      this.focusFirstFilter();
    },
    async onDeleteAwaitingPayment(awaitingPaymentId) {
      try {
        if (awaitingPaymentId) {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.awaitingPaymentDelete.text"), {
            title: this.$t("payments.awaitingPaymentDelete.title"),
            okVariant: "danger",
            okTitle: this.$t("payments.awaitingPaymentDelete.ok"),
            cancelTitle: this.$t("payments.awaitingPaymentDelete.cancel"),
            centered: true
          });
          // delete
          if (result) {
            this.loading = true;
            const res = await commonServices.delete("awaitingPayments", awaitingPaymentId);
            this.loading = false;
            if (res.data === true) {
              this.$emit("alert", "success", {
                title: this.$t("payments.awaitingPaymentDelete.notifications.deletedTitle"),
                message: this.$t("payments.awaitingPaymentDelete.notifications.deletedText")
              });
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("payments.awaitingPaymentDelete.notifications.deletedErrorTitle"),
                message: this.$t("payments.awaitingPaymentDelete.notifications.deletedErrorText")
              });
            }
            await this.onPageInput();
            this.focusFirstFilter();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style src="./PatientPayments-global.scss" lang="scss"></style>

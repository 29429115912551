<script>
// mixins
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    // "chartData" from mixin
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // chartData from mixin
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>

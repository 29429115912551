<template>
  <div
    class="kt-view-page kt-feeNote-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'general', 'feeNotes'])"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("feeNoteView.pageTitle", {id: feeNoteId}) }}
      </h2>
      <!-- isRetained-->
      <span
        v-if="feeNoteData"
        class="kt-badge kt-badge--light-gray ml-3"
      >
        <b-form-checkbox
          v-model="feeNoteData.isRetained"
          switch
          @change="onIsRetainedChange"
        >
          {{ feeNoteData.isRetained ? $t('feeNoteView.isRetained') : $t('feeNoteView.isNotRetained') }}
        </b-form-checkbox>
        <b-spinner
          v-show="isRetainedLoading"
          class="ml-1"
          :class="`kt-spinner--${$systemSettings.theme}`"
          small
        ></b-spinner>
        <i
          v-show="isRetainedSuccessIconDisplay"
          class="fi fi-rs-check-circle ml-1 text-success"
        ></i>
      </span>
      <!-- remindersBadge -->
      <div
        v-if="feeNoteData && feeNoteData.version && feeNoteData.version > 1"
        class="kt-badge kt-badge--light-gray ml-3"
      >
        <b-icon
          icon="calendar-week"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $tc('feeNoteView.remindersBadge', feeNoteData.version - 1, {remindersCount: feeNoteData.version - 1}) }}</span>
      </div>
      <!-- debtCollectionBadge -->
      <div
        v-if="feeNoteData && feeNoteData.debtCollectionReportDate"
        class="kt-badge kt-badge--red ml-3"
      >
        <b-icon
          icon="calendar-x"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t('feeNoteView.debtCollectionBadge', {date: $d(new Date(feeNoteData.debtCollectionReportDate), "date")}) }}</span>
      </div>
      <!-- transformed badge -->
      <div
        v-show="feeNoteData && feeNoteData.isOriginFullyTransformed"
        class="kt-badge kt-badge--yellow ml-3"
      >
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('feeNoteView.fullyTransformed') }}</span></span>
      </div>
      <!-- from transformation badge -->
      <div
        v-show="feeNoteData && feeNoteData.fromTransformationsCount"
        class="kt-badge kt-badge--blue ml-3"
      >
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('feeNoteView.fromTransformation') }}</span></span>
      </div>
      <!-- documentStatuses -->
      <DocumentStatuses
        :dataProp="feeNoteData"
        class="ml-3"
      />
      <!-- export pdf  -->
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'feeNotes', 'toPdf'])"
        size="sm"
        class="kt-page-header__action ml-3"
        :variant="$systemSettings.theme"
        @click="onExport(feeNoteData, 'pdf')"
      >
        <b-icon icon="download"></b-icon>
        {{ $t("general.downloadPdf") }}
      </b-button>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION - main -->
      <div
        v-if="laboratoryDisplay || invoiceDateDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- laboratory -->
        <b-row v-if="laboratoryDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("feeNotes.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratory ? laboratory.name + (laboratory.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- invoiceDate -->
        <b-row v-if="invoiceDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("feeNotes.invoiceDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ invoiceDate ? this.$d(new Date(invoiceDate), "date") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- patient -->
        <b-row v-if="patientDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.patient") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div class="kt-item-list">
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item mb-0">
                  <div class="kt-item-list__item-title pr-0">
                    <!-- name -->
                    <PersonName
                      :sexNameProp="file ? file.patient.sex.name : ''"
                      :firstNameProp="file ? file.patient.firstName : ''"
                      :maidenNameProp="file ? file.patient.maidenName : ''"
                      :lastNameProp="file ? file.patient.lastName : ''"
                    />
                  </div>
                  <div class="kt-item-list__item-text">
                    <!-- birthdate -->
                    <div v-show="file">
                      {{ file ? getFormattedBirthdateAge(file.patient.birthdate) : "" }}
                    </div>
                    <!-- view patient -->
                    <b-link
                      class=""
                      :variant="$systemSettings.theme"
                      :href="file ? '/office/patient/view/' + file.patient.id : '#'"
                      @click.prevent="() => {
                        if (file) navigate('office/patient/view', {patientIdProp: file.patient.id})
                      }"
                    >
                      {{ $t('general.view') }}
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- SECTION - file -->
      <div
        v-if="fileDetailsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- title -->
        <h3 class="kt-view-page__section-title">
          {{ $t("feeNotes.sectionTitleFileDetails") }}
        </h3>

        <!-- accessNumber -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("feeNotes.accessNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              <b-link
                :variant="$systemSettings.theme"
                class=""
                :href="file ? '/office/file/view/' + file.id : '#'"
                @click.prevent="() => {
                  if (file) navigate('office/file/view', {
                    fileIdProp: file.id,
                    cancelRouteProp: {name: $route.name, props: $route.params}
                  })
                }"
              >
                {{ file ? file.accessNumber : $t("general.emptyWithHyphen") }}
                <!-- complementary -->
                <span
                  v-show="file && file.parentFileId"
                  class="kt-complementary-icon"
                >
                  <b-icon icon="back"></b-icon>
                </span>
              </b-link>
            </p>
          </b-col>
        </b-row>
        <!-- creationDate -->
        <b-row v-show="fileInvoices && fileInvoices.length === 1">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.creationDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file ? this.$d(new Date(file.creationDate), "date") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- pathologist -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.pathologist") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file && file.pathologist ? file.pathologist.user.firstName + " " + file.pathologist.user.lastName + (file.pathologist.user.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- sector -->
        <b-row v-show="fileInvoices && fileInvoices.length === 1">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.sector") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file ? $t('sectors.' + file.sector.name) + (!file.sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- prescribing organisation -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.prescribingOrganisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file && file.prescribingOrganisation ? file.prescribingOrganisation.name + (file.prescribingOrganisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- prescriber -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.prescriber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file && file.fileContacts.length === 1 ? file.fileContacts[0].contact.firstName + " " + file.fileContacts[0].contact.lastName + (file.fileContacts[0].contact.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- collectionDates -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.collectionDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file ? file.collectionDates.join(", ") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
      </div>
      <!-- SECTION - fileInvoices > invoiceActs -->
      <div
        v-if="fileInvoicesDisplay"
        class="kt-view-page__section mb-0"
        :class="sectionMarginsClass"
      >
        <!-- title -->
        <h3 class="kt-view-page__section-title">
          {{ $t("feeNotes.sectionTitleInvoiceActs") }}
        </h3>

        <!-- fileInvoices loop -->
        <div
          v-for="(fileInvoice, fileInvoiceIndex) in fileInvoices"
          :key="fileInvoice.id"
          :style="fileInvoiceIndex !== 0 ? 'margin-top: 5px;' : ''"
        >
          <div
            v-if="fileInvoices && fileInvoices.length > 1"
            class="kt-fileInvoice-header"
          >
            <!-- title -->
            <h4 class="h6 kt-fileInvoice-header__title">
              {{ fileInvoice.file.parentFileId ? $t("fileChooseFromIds.complementaryFile") : $t("fileChooseFromIds.parentFile") }}
            </h4>
            <span class="ml-1">
              {{ $d(new Date(fileInvoice.file.creationDate), "date") + ' - ' + $t('sectors.' + fileInvoice.file.sector.name) }}
            </span>
            <b-button
              size="sm"
              class="kt-btn-transparent p-0 ml-1 align-baseline"
              :variant="$systemSettings.theme"
              @click="navigate('office/file/view', {fileIdProp: fileInvoice.file.id})"
            >
              {{ $t('general.view') }}
            </b-button>
          </div>
          <!-- transformation badges -->
          <FileInvoiceTransformationBadge :fileInvoiceProp="fileInvoice" />
          <!-- acts table -->
          <div
            class="kt-flex-table kt-flex-table--large"
            style="margin-top: 2px"
          >
            <div
              class="kt-flex-table__wrapper"
              role="table"
              :aria-label="$t('invoiceReports.invoiceActs')"
            >
              <div
                class="kt-flex-table__line kt-flex-table__line--header"
                role="rowgroup"
              >
                <div
                  class="kt-flex-table__row first"
                  role="columnheader"
                >
                  {{ $t('feeNotes.invoiceActCode') }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="columnheader"
                >
                  {{ $t('feeNotes.invoiceActPrice') }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="columnheader"
                >
                  {{ $t('feeNotes.invoiceActInvoiced') }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="columnheader"
                >
                  {{ $t('feeNotes.invoiceActRatio') }}
                </div>
              </div>
              <div
                v-for="(invoiceAct, index) in fileInvoice.invoiceActs"
                :key="index"
                class="kt-flex-table__line kt-flex-table__line--regular"
                role="rowgroup"
              >
                <div
                  class="kt-flex-table__row first"
                  role="cell"
                >
                  {{ invoiceAct.act.code }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="cell"
                >
                  {{ $n(invoiceAct.act.price, "currency") }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="cell"
                >
                  {{ $n(invoiceAct.invoiced, "currency") }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="cell"
                >
                  <span :class="(invoiceAct.ratio === 1) ? 'text-success' : 'text-danger'">{{ invoiceAct.ratio ? $n(invoiceAct.ratio, "ratio") : $n(0, "ratio") }}</span>
                </div>
              </div>
            </div>
            <div
              v-show="fileInvoiceIndex === fileInvoices.length - 1"
              class="kt-flex-table__bottom"
            >
              <div class="kt-flex-table__amount-container">
                <span>{{ $t("feeNotes.invoiced") }}</span><span class="kt-flex-table__amount">{{ $n(invoiced || 0, "currency") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// component
import PersonName from "@shared/views/Helpers/PersonName";
import FileInvoiceTransformationBadge from "@shared/views/Helpers/FileInvoiceTransformationBadge";
import DocumentStatuses from "@shared/views/Helpers/DocumentStatuses";
// services
import commonServices from "@shared/services/API/commonServices";
import feeNotesServices from "@/services/API/feeNotesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import invoicesHelpers from "@shared/services/UI/invoicesHelpers";
import viewComponents from "@shared/services/UI/viewComponents";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { FileInvoiceTransformationBadge, PersonName, DocumentStatuses },
  mixins: [userRights, error, date, invoicesHelpers, viewComponents],
  props: {
    feeNoteIdProp: {
      type: Number,
      default: null
    },
    moduleModeProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "laboratory",
          "invoiceDate",
          "patient",
          "fileDetails",
          "fileInvoices"
        ];
      }
    }
  },
  data() {
    return {
      // general
      feeNoteId: this.feeNoteIdProp,
      feeNoteData: null,
      // base data
      laboratory: null,
      invoiceDate: "",
      fileInvoices: [],
      invoiced: null,
      file: null,

      // fee note variables for display
      isRetainedLoading: false,
      isRetainedSuccessIconDisplay: false,

      // dispayFields
      laboratoryDisplay: false,
      invoiceDateDisplay: false,
      patientDisplay: false,
      fileDetailsDisplay: false,
      fileInvoicesDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get feeNote
      const resServices = await commonServices.get("feeNotes", this.feeNoteId);
      this.feeNoteData = this.importFormatInvoice(resServices.data, "feeNote");
      // assign the data
      this.laboratory = this.feeNoteData.laboratory;
      this.invoiceDate = this.feeNoteData.invoiceDate;
      this.fileInvoices = this.feeNoteData.fileInvoices;
      this.invoiced = this.feeNoteData.invoiced;
      this.file = this.feeNoteData.file;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("accounting/invoices/feeNotes");
    },
    async onIsRetainedChange(isRetained) {
      this.feeNoteData.isRetained = !isRetained;
      this.isRetainedLoading = true;

      try {
        // confirmation pop up
        const result = await this.$bvModal.msgBoxConfirm(isRetained ? this.$t("feeNoteSetIsRetained.textRetain") : this.$t("feeNoteSetIsRetained.textUnretain"), {
          title: isRetained ? this.$t("feeNoteSetIsRetained.titleRetain") : this.$t("feeNoteSetIsRetained.titleUnretain"),
          okVariant: "danger",
          okTitle: isRetained ? this.$t("feeNoteSetIsRetained.okRetain") : this.$t("feeNoteSetIsRetained.okUnretain"),
          cancelTitle: this.$t("feeNoteSetIsRetained.cancel"),
          centered: true
        });

        // delete
        if (result) {
          const res = await feeNotesServices.setIsRetained(this.feeNoteId, isRetained);
          if (res.data === true) {
            // success
            this.feeNoteData.isRetained = isRetained;
            this.isRetainedSuccessIconDisplay = true;
            setTimeout(() => { this.isRetainedSuccessIconDisplay = false; }, 1500);
          } else {
            this.$emit("alert", "danger", {
              title: isRetained ? this.$t("feeNoteSetIsRetained.notifications.retainErrorTitle") : this.$t("feeNoteSetIsRetained.notifications.unretainErrorTitle"),
              message: isRetained ? this.$t("feeNoteSetIsRetained.notifications.retainErrorText") : this.$t("feeNoteSetIsRetained.notifications.unretainErrorText")
            });
          }
        }
        this.isRetainedLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // export
    async onExport(row, type) {
      try {
        const resFile = await commonServices.export("feeNotes", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.file.accessNumber + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./FeeNoteView.scss" lang="scss"></style>

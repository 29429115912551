<template>
  <div
    class="kt-view-page kt-organisation-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("organisationView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'data', 'organisations', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/organisation/edit', {organisationIdProp: organisationId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("organisationView.editOrganisation") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- isActive -->
      <div
        v-show="!isActive"
        class="kt-badge kt-badge--red kt-badge--lg"
      >
        <b-icon
          icon="trash"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
      </div>

      <!-- SECTION type / name / code -->
      <div
        v-if="organisationTypeDisplay ||
          nameDisplay ||
          codeDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- organisationType -->
        <b-row v-if="organisationTypeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.organisationType") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ organisationTypeLocalisedName }}
            </p>
          </b-col>
        </b-row>
        <!-- name -->
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.name") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ name }}
            </p>
          </b-col>
        </b-row>
        <!-- code -->
        <b-row v-if="codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="contactInformationsDisplay"
        class="w-100 border-top mt-1 mb-3"
      ></div>

      <!-- SECTION ContactInformations-->
      <div
        v-if="contactInformationsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- contactInformations list -->
        <b-row v-if="contactInformationsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.contactInformations") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="organisationContactInformations.length"
              class="kt-item-list"
            >
              <div
                v-for="organisationContactInformationItem in organisationContactInformations"
                :key="organisationContactInformationItem.id"
                class="kt-item-list__item-wrapper"
              >
                <div class="kt-item-list__item">
                  <!-- title -->
                  <div
                    class="kt-item-list__item-title"
                  >
                    {{ organisationContactInformationItem.contactInformation.name }}
                  </div>
                  <!-- isActive -->
                  <div
                    v-show="organisationContactInformationItem.contactInformation.isActive === false"
                    class="kt-badge kt-badge--red"
                  >
                    <b-icon
                      icon="trash"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
                  </div>
                  <!-- text -->
                  <div
                    class="kt-item-list__item-text"
                    v-html="formatAddressSub(organisationContactInformationItem.contactInformation)"
                  ></div>
                  <!-- labels -->
                  <div
                    v-show="organisationContactInformationItem.contactInformation.isActive !== false"
                    class="kt-button-switchers"
                  >
                    <!-- is Default -->
                    <b-button
                      v-if="organisationContactInformationItem.isDefault"
                      class="kt-button-switcher kt-button-switcher--active kt-button-switcher--no-link"
                      size="sm"
                      variant="light"
                      tabindex="-1"
                    >
                      <b-icon
                        icon="check"
                        class="kt-button-switcher__check"
                      ></b-icon>
                      {{ $t("contactInformations.isDefault") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="mb-2"
            >
              {{ $t("general.emptyWithHyphen") }}
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="organisationSectorsDisplay && organisationType && organisationType.name !== 'healthCare'"
        class="w-100 border-top mt-1 mb-3"
      ></div>

      <!-- SECTION each sector > invoicePatient -->
      <div
        v-if="organisationSectorsDisplay && organisationType && organisationType.name !== 'healthCare'"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- organisationSectors -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.organisationSectors") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
            class="mb-2"
          >
            <p
              v-for="sector in organisationSectors"
              v-show="sector.invoicePatient !== null"
              :key="sector.sectorId"
              class="mb-0"
            >
              {{ sector.localisedName + " : " + (sector.invoicePatient ? $t('organisations.invoicePatientTrue') : $t('organisations.invoicePatientFalse')) }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="invoiceFrequencyDisplay && organisationType && organisationType.name !== 'healthCare'"
        class="w-100 border-top mt-1 mb-3"
      ></div>

      <!-- SECTION frequencies -->
      <div
        v-if="invoiceFrequencyDisplay && organisationType && organisationType.name !== 'healthCare'"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- organisationSectors -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.invoiceFrequency") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ invoiceFrequency ? $t("invoiceFrequencies." + invoiceFrequency.name) : "" }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="invoiceFrequencyDisplay && organisationType && organisationType.name !== 'healthCare'"
        class="w-100 border-top mt-1 mb-3"
      ></div>

      <!-- SECTION sendElectronically -->
      <div
        v-if="sendElectronicallyDisplay && organisationType && organisationType.name !== 'healthCare'"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("organisations.sendElectronicallySection") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ $t("organisations." + (sendElectronically ? "sendElectronicallyEnabled" : "sendElectronicallyDisabled")) }}
            </p>
            <p
              v-show="registrationNumber"
              class="mb-0"
            >
              {{ registrationNumber }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [userRights, error, phoneNumber, address, viewComponents],
  props: {
    organisationIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "organisationType",
          "code",
          "name",
          "contactInformations",
          "organisationSectors",
          "invoiceFrequency",
          "sendElectronically"
        ];
      }
    }
  },
  data() {
    return {
      // general
      organisationId: this.organisationIdProp,
      organisationData: null,
      // base data
      isActive: true,
      organisationTypeId: null,
      organisationType: null,
      code: "",
      name: "",
      organisationContactInformations: "",
      organisationSectors: null,
      invoiceFrequency: null,
      sendElectronically: false,
      registrationNumber: "",
      // extended data
      organisationTypeLocalisedName: "",
      // dispayFields
      organisationTypeDisplay: false,
      codeDisplay: false,
      nameDisplay: false,
      contactInformationsDisplay: false,
      organisationSectorsDisplay: false,
      invoiceFrequencyDisplay: false,
      sendElectronicallyDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get organisation
      const resServices = await commonServices.get("organisations", this.organisationId);
      this.organisationData = resServices.data;
      // assign the data
      this.isActive = resServices.data.isActive;
      this.organisationTypeId = resServices.data.organisationTypeId;
      this.organisationType = resServices.data.organisationType;
      this.code = resServices.data.code;
      this.name = resServices.data.name;
      this.organisationContactInformations = resServices.data.organisationContactInformations;
      this.sendElectronically = resServices.data.sendElectronically;
      this.registrationNumber = resServices.data.registrationNumber;

      // set up organisationSectors
      this.importOrganisationSectors();
      if (resServices.data.organisationSectors && resServices.data.organisationSectors.length) {
        for (const organisationSector of resServices.data.organisationSectors) {
          for (const savedOrganisationSector of this.organisationSectors) {
            if (organisationSector.sectorId === savedOrganisationSector.sectorId) {
              savedOrganisationSector.invoicePatient = organisationSector.invoicePatient;
            }
          }
        }
      }

      // get localised organisationType
      this.organisationTypeLocalisedName = this.$t("organisationType." + resServices.data.organisationType.name);

      this.invoiceFrequency = resServices.data.invoiceFrequency;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("data/organisations");
    },

    // load/refresh sectors
    importOrganisationSectors() {
      const organisationSectors = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.organisationSectors = organisationSectors.map((sector) => {
        return {
          isSubscribed: sector.isSubscribed,
          sectorId: sector.id,
          name: sector.name,
          localisedName: this.$t("sectors." + sector.name) + (!sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : ""),
          invoicePatient: null
        };
      });
    },

    formatAddressSub(obj) {
      let addressLine = this.formatAddress(obj.address1, obj.address2, obj.zip, obj.city, obj.country);
      if (obj.phone) addressLine += (addressLine !== "" ? "<br>" : "") + this.importFormatPhone(obj.phone);
      if (obj.mobile) addressLine += (!obj.phone && addressLine !== "" ? "<br>" : "") + (obj.phone ? " - " : "") + this.importFormatPhone(obj.mobile);
      if (obj.email) addressLine += (addressLine !== "" ? "<br>" : "") + obj.email;

      return addressLine;
    }
  }
};
</script>

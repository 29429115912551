<template>
  <div class="kt-task-cytology-content">
    <!-- content form -->
    <b-row>
      <!-- divider -->
      <b-col cols="12">
        <div class="w-100 border-top mt-1 mb-3"></div>
        <h5 class="kt-section-title">
          {{ $t("tasks.contentTitle") }}
        </h5>
      </b-col>
    </b-row>

    <!-- screening -->
    <b-row v-if="screeningDisplay">
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6">
          {{ $t("tasks.cytology.screening") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <p class="mb-0">
          {{ screening ? $t("cytologyReport.screening." + screening) : $t("general.emptyWithHyphen") }}
        </p>
      </b-col>
    </b-row>
    <!-- smear -->
    <b-row v-if="smearDisplay">
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6">
          {{ $t("tasks.cytology.smear") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <p class="mb-0">
          {{ smear ? $t("cytologyReport.smear." + smear) : $t("general.emptyWithHyphen") }}
        </p>
      </b-col>
    </b-row>
    <!-- layering -->
    <b-row v-if="layeringDisplay">
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6">
          {{ $t("tasks.cytology.layering") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <p class="mb-0">
          {{ layering ? $t("cytologyReport.layering." + layering) : $t("general.emptyWithHyphen") }}
        </p>
      </b-col>
    </b-row>

    <!-- isCytology isHpv -->
    <b-row v-if="layeringDisplay">
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6">
          {{ $t("tasks.cytology.checkboxTitle") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <div
          v-show="isCytology"
          class="kt-badge kt-badge--light-gray mt-n1"
        >
          <span class="kt-badge__text font-weight-semi-bold">{{ $t("tasks.cytology.isCytology") }}</span>
        </div>
        <div
          v-show="isHpv"
          class="kt-badge kt-badge--light-gray mt-n1"
        >
          <span class="kt-badge__text font-weight-semi-bold">{{ $t("tasks.cytology.isHpv") }}</span>
        </div>
        <p
          v-show="!isHpv && !isCytology"
          class="mb-0"
        >
          {{ $t("general.emptyWithHyphen") }}
        </p>
      </b-col>
    </b-row>

    <!-- multiplicity -->
    <b-row v-if="multiplicityDisplay">
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6">
          {{ $t("tasks.cytology.multiplicity") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <p class="mb-0">
          {{ multiplicity ? multiplicity : $t("general.emptyWithHyphen") }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// helpers
import error from "@shared/services/UI/error";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [error, viewComponents],
  props: {
    contentProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "screening",
          "smear",
          "layering",
          "isCytology",
          "isHpv",
          "multiplicity"
        ];
      }
    }
  },
  data() {
    return {
      // form variables
      screening: "",
      smear: "",
      layering: "",
      isCytology: null,
      isHpv: null,
      multiplicity: ""
    };
  },
  watch: {
    contentProp: {
      handler(val) {
        if (val !== null) {
          this.screening = val.screening !== undefined ? val.screening : "";
          this.smear = val.smear !== undefined ? val.smear : "";
          this.layering = val.layering !== undefined ? val.layering : "";
          this.isCytology = val.isCytology !== undefined ? val.isCytology : null;
          this.isHpv = val.isHpv !== undefined ? val.isHpv : null;
          this.multiplicity = val.multiplicity !== undefined ? val.multiplicity : "";
        } else {
          this.screening = "";
          this.smear = "";
          this.layering = "";
          this.isCytology = null;
          this.isHpv = null;
          this.multiplicity = "";
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {}
};
</script>

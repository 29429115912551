<template>
  <div class="kt-404">
    <div class="kt-404__background">
      <svg style="display: none;">
        <defs>
          <path
            id="kt-404__svg-ref-cell-1"
            d="M69.000,323.000 C172.586,76.666 487.000,-147.000 783.000,123.000 C1079.000,393.000 1253.426,88.887 1407.000,
          333.000 C1818.420,986.969 1001.000,1731.000 503.000,1491.000 C5.000,1251.000 -86.055,691.730 69.000,323.000 Z"
          ></path>
        </defs>
      </svg>
      <div class="kt-404__background-wrapper">
        <!-- cell 1 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="2027"
          height="2051"
          viewBox="0 0 1527 1551"
          class="kt-404__svg-cell kt-404__svg-cell--1"
        >
          <use xlink:href="#kt-404__svg-ref-cell-1"></use>
        </svg>
        <!-- cell 2 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="2027"
          height="2051"
          viewBox="0 0 1527 1551"
          class="kt-404__svg-cell kt-404__svg-cell--2"
        >
          <use xlink:href="#kt-404__svg-ref-cell-1"></use>
        </svg>
        <!-- cell 3 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="2027"
          height="2051"
          viewBox="0 0 1527 1551"
          class="kt-404__svg-cell kt-404__svg-cell--3"
        >
          <use xlink:href="#kt-404__svg-ref-cell-1"></use>
        </svg>
        <!-- cell 4 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="2027"
          height="2051"
          viewBox="0 0 1527 1551"
          class="kt-404__svg-cell kt-404__svg-cell--4"
        >
          <use xlink:href="#kt-404__svg-ref-cell-1"></use>
        </svg>
        <!-- cell 5 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="2027"
          height="2051"
          viewBox="0 0 1527 1551"
          class="kt-404__svg-cell kt-404__svg-cell--5"
        >
          <use xlink:href="#kt-404__svg-ref-cell-1"></use>
        </svg>
        <!-- cell 6 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="2027"
          height="2051"
          viewBox="0 0 1527 1551"
          class="kt-404__svg-cell kt-404__svg-cell--6"
        >
          <use xlink:href="#kt-404__svg-ref-cell-1"></use>
        </svg>
      </div>
    </div>

    <div class="kt-404__content">
      <div class="kt-404__centering--table">
        <div class="kt-404__centering-cell">
          <h1 class="kt-404__404">
            <span>{{ $t("notFound.titleAroundIllustration") }}</span>
            <!-- cell -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="2027"
              height="2051"
              viewBox="0 0 1527 1551"
              class="kt-404__404__0"
            >
              <use xlink:href="#kt-404__svg-ref-cell-1"></use>
            </svg>
            <span>{{ $t("notFound.titleAroundIllustration") }}</span>
          </h1>
          <h1 class="kt-404__title">
            {{ $t("notFound.title") }}
          </h1>
          <p class="kt-404__subtitle">
            {{ $t("notFound.subtitle") }}
          </p>
          <b-button
            ref="backButton"
            class="kt-404__back-btn"
            :variant="'outline-' + $systemSettings.theme"
            @click="goBack"
          >
            <b-icon
              style="margin-left: -3px; margin-right: 1px;"
              icon="chevron-left"
            ></b-icon>
            {{ $t("notFound.backButton") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import { navigate } from "@/services/UI/vueRouterServices";
import userRights from "@/services/UI/userRights";

export default {
  mixins: [userRights],
  data() {
    return {
      from: null
    };
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;
    // auto focus
    this.$refs.backButton.focus();
  },
  methods: {
    goBack() {
      if (this.from && this.from.name !== "home" && this.from.name !== null) {
        this.navigate(this.from.name);
      } else {
        this.goToDefaultPageByRight();
      }
    },
    goToDefaultPageByRight() {
      if (this.isEnabled(["roleSpecific", "System"])) {
        navigate("system/statistics");
      } else if (this.isEnabled(["roleSpecific", "userAdministration"])) {
        navigate("administration/users");
      } else if (this.isEnabled(["roleSpecific", "accounting"])) {
        navigate("accounting/invoices/invoiceReports");
      } else if (this.isEnabled(["roleSpecific", "pathologist"])) {
        navigate("diagnosis/reportEntry");
      } else if (this.isEnabled(["roleSpecific", "cytotechnician"])) {
        navigate("diagnosis/reportEntry");
      } else if (this.isEnabled(["roleSpecific", "office"])) {
        navigate("office/files");
      } else if (this.isEnabled(["roleSpecific", "technician"])) {
        navigate("data/instruments");
      } else {
        navigate("home");
      }
    }
  }
};
</script>

<style scoped src="@shared/views/General/NotFound.scss" lang="scss"></style>

<template>
  <div class="kt-user-edit">
    <b-form
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <b-row>
        <!-- user form -->
        <b-col cols="12">
          <!-- laboratories read only -->
          <div
            v-if="!isActiveEditRightsProp && editMode && !isUserRequestProp"
            class="mb-2 pb-1"
          >
            <span
              v-for="userLaboratory in userLaboratoriesOptions"
              v-show="!userLaboratory.disabled"
              :key="userLaboratory.id"
              class="kt-badge kt-badge--lg mb-1 mr-1"
              :class="'kt-badge--light-gray'"
            >
              <b-icon
                icon="building"
                class="kt-badge__icon"
              ></b-icon>
              <span class="kt-badge__text">{{ userLaboratory.name }}</span>
            </span>
          </div>

          <!-- email -->
          <b-form-group
            :label="$t('users.edition.email')"
            :invalid-feedback="emailInvalidFeedback"
            :state="emailState"
          >
            <b-form-input
              ref="emailInput"
              v-model="email"
              type="email"
              trim
              maxlength="320"
              :state="emailState"
              @blur="emailValidation = true"
            ></b-form-input>
          </b-form-group>

          <!-- civility -->
          <b-form-group
            :label="$t('users.edition.civility')"
            :invalid-feedback="sexInvalidFeedback"
            :state="sexState"
          >
            <b-form-radio-group
              v-model="sexId"
              :options="sexOptions"
              value-field="id"
              text-field="localisedName"
              :state="sexState"
            ></b-form-radio-group>
          </b-form-group>

          <!-- firstName -->
          <InputTextFormatted
            v-model="firstName"
            :labelProp="$t('users.edition.firstName')"
            :stateProp="firstNameState"
            :invalidFeedbackProp="firstNameInvalidFeedback"
            @blur="firstNameValidation = true"
            @submit="onSubmit"
          />

          <!-- lastName -->
          <InputTextFormatted
            v-model="lastName"
            :labelProp="$t('users.edition.lastName')"
            :stateProp="lastNameState"
            :invalidFeedbackProp="lastNameInvalidFeedback"
            @blur="lastNameValidation = true"
            @submit="onSubmit"
          />

          <!-- phone -->
          <b-form-group
            :label="$t('users.edition.phone')"
            :invalid-feedback="phoneInvalidFeedback"
            :state="phoneState"
          >
            <!-- phone input -->
            <b-form-input
              v-model="phone"
              class="kt-input--prefixed"
              autocomplete="off"
              trim
              maxlength="50"
              :state="phoneState"
              @blur="phoneValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t('general.phoneCountryCodeDefault') }}
              </div>
            </div>
          </b-form-group>

          <!-- password -->
          <b-form-group
            class="mb-2"
            :label="editMode ? $t('users.edition.password') : $t('users.addition.password')"
            :invalid-feedback="passwordInvalidFeedback"
            :state="passwordState"
          >
            <b-form-input
              ref="passwordField"
              v-model="password"
              class="kt-formField--password"
              autocomplete="off"
              maxlength="64"
              :type="isPasswordVisible ? 'text' : 'password'"
              :state="passwordState"
              @focus="arePasswordLabelsVisible = true"
            ></b-form-input>
            <!-- show password -->
            <div class="kt-show-password">
              <b-button
                class="kt-show-password__button"
                tabindex="-1"
                @click="onShowPassword('passwordField', 'isPasswordVisible')"
              >
                <b-icon
                  v-show="isPasswordVisible"
                  :class="'text-' + $systemSettings.theme"
                  icon="eye-slash-fill"
                ></b-icon>
                <b-icon
                  v-show="!isPasswordVisible"
                  icon="eye-fill"
                ></b-icon>
              </b-button>
            </div>
            <!-- password badges -->
            <div
              v-show="arePasswordLabelsVisible"
              class="kt-password-entry-badges"
            >
              <!-- minLength -->
              <span
                v-if="!password || !(password.length >= 12)"
                class="kt-badge kt-badge--blue"
              >{{
                $t("passwordBadges.minLenghtBadge")
              }}</span>
              <!-- lowercase -->
              <span
                v-if="!/^(?=.*[a-z]).{1,}$/.test(password)"
                class="kt-badge kt-badge--blue"
              >{{
                $t("passwordBadges.lowercaseBadge")
              }}</span>
              <!-- uppercase -->
              <span
                v-if="!/^(?=.*[A-Z]).{1,}$/.test(password)"
                class="kt-badge kt-badge--blue"
              >{{
                $t("passwordBadges.uppercaseBadge")
              }}</span>
              <!-- number -->
              <span
                v-if="!/^(?=.*\d).{1,}$/.test(password)"
                class="kt-badge kt-badge--blue"
              >{{ $t("passwordBadges.numberBadge") }}</span>
            </div>
          </b-form-group>

          <!-- passwordCheck -->
          <b-form-group
            :invalid-feedback="passwordCheckInvalidFeedback"
            :state="passwordCheckState"
          >
            <b-form-input
              ref="passwordCheckField"
              v-model="passwordCheck"
              class="kt-formField--password"
              autocomplete="off"
              maxlength="64"
              :type="isPasswordCheckVisible ? 'text' : 'password'"
              :placeholder="$t('users.edition.repeatPassword')"
              :state="passwordCheckState"
              @blur="passwordCheckValidation = true"
            ></b-form-input>
            <!-- show password -->
            <div class="kt-show-password">
              <b-button
                class="kt-show-password__button"
                tabindex="-1"
                @click="onShowPassword('passwordCheckField', 'isPasswordCheckVisible')"
              >
                <b-icon
                  v-show="isPasswordCheckVisible"
                  :class="'text-' + $systemSettings.theme"
                  icon="eye-slash-fill"
                ></b-icon>
                <b-icon
                  v-show="!isPasswordCheckVisible"
                  icon="eye-fill"
                ></b-icon>
              </b-button>
            </div>
          </b-form-group>

          <!-- laboratories -->
          <b-form-group
            v-if="isActiveEditRightsProp"
            v-show="!userGroups.includes(userAdministratorId)"
            :label="$t('users.edition.laboratories')"
          >
            <b-form-checkbox
              v-for="userLaboratory in userLaboratoriesOptions"
              v-show="userLaboratory.isActive || userLaboratories.includes(userLaboratory.id)"
              :key="userLaboratory.id"
              v-model="userLaboratories"
              :value="userLaboratory.id"
              :disabled="userLaboratory.isActive === false"
              switch
              class="mr-n2"
            >
              {{ userLaboratory.formattedName }}
            </b-form-checkbox>
          </b-form-group>

          <!-- users rights -->
          <b-form-group
            v-if="isActiveEditRightsProp"
            :label="$t('users.edition.rights')"
          >
            <b-form-checkbox
              v-for="option in userGroupsOptions"
              :key="option.id"
              v-model="userGroups"
              :value="option.id"
              switch
              class="mr-n2"
              :disabled="isGroupDisabled(option.name)"
            >
              {{ option.localisedName }}
            </b-form-checkbox>
          </b-form-group>

          <!-- active state -->
          <b-form-group
            v-if="isActiveEditRightsProp"
            :label="$t('users.edition.activity')"
          >
            <b-form-checkbox
              v-model="isActive"
              switch
              class="mr-n2"
            >
              {{ isActive ? $t("users.edition.active") : $t("users.edition.inactive") }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-0 mb-3"></div>
        </b-col>

        <!-- cta -->
        <b-col cols="12">
          <!-- reset -->
          <b-button
            v-if="editMode"
            class="mr-2"
            variant="outline-secondary"
            pill
            type="reset"
          >
            {{ $t("general.reset") }}
          </b-button>

          <!-- submit -->
          <b-button
            :variant="$systemSettings.theme"
            pill
            type="submit"
          >
            {{ editMode ? $t("general.save") : $t("users.addition.submit") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
import commonServices from "@shared/services/API/commonServices";
import usersServices from "@/services/API/usersServices";
// helpers
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";

export default {
  components: { InputTextFormatted },
  mixins: [error, phoneNumber],
  props: {
    userIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    },
    isUserRequestProp: {
      type: Boolean
    },
    isActiveEditRightsProp: {
      type: Boolean
    },
    userDataProp: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },
  data() {
    return {
      // general variables)
      editMode: this.editModeProp,
      userId: this.userIdProp,
      userData: null,
      // form data
      email: "",
      sexId: 2,
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      passwordCheck: "",
      userLaboratories: [],
      userGroups: [],
      isActive: true,
      // extra variables
      arePasswordLabelsVisible: false,
      isPasswordVisible: false,
      isPasswordCheckVisible: false,
      groupsDictionary: null,
      // is validation active
      emailValidation: false,
      sexValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      phoneValidation: false,
      passwordValidation: false,
      passwordCheckValidation: false,
      userRightsValidation: false,
      // options
      userLaboratoriesOptions: [],
      userGroupsOptions: []
    };
  },
  computed: {
    // form validation
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    sexState: function() {
      if (!this.sexValidation) return null;
      return this.sexId && this.sexId > 0 ? null : false;
    },
    sexInvalidFeedback: function() {
      return this.sexState === false ? this.$t("validationRules.required") : "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      return this.firstName && this.firstName.length > 0 ? null : false;
    },
    firstNameInvalidFeedback: function() {
      return this.firstNameState === false ? this.$t("validationRules.required") : "";
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      return this.lastName && this.lastName.length > 0 ? null : false;
    },
    lastNameInvalidFeedback: function() {
      return this.lastNameState === false ? this.$t("validationRules.required") : "";
    },
    phoneState: function() {
      // validation active
      if (!this.phoneValidation) return null;
      // not required
      if (this.phone === "") return null;

      return this.phone && this.phone.length > 0 && /^0?[67]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // if empty
      if (!this.phone) return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidMobilePhone");
    },
    passwordState: function() {
      if (!this.passwordValidation) return null;
      if (this.password === "" && this.passwordCheck === "") return null;
      return this.password && this.password.length >= 12 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password) && this.password === this.passwordCheck ? null : false;
    },
    passwordInvalidFeedback: function() {
      // no error
      if (this.passwordState === null) return "";
      // error but no notification
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password)) return "";
      // else : passwords not equals
      return this.$t("session.register.validations.passwordNotEquals");
    },
    passwordCheckState: function() {
      if (!this.passwordCheckValidation) return null;
      if (this.password === "" && this.passwordCheck === "") return null;
      return this.passwordCheck && this.passwordCheck.length > 0 && this.password === this.passwordCheck ? null : false;
    },
    passwordCheckInvalidFeedback: function() {
      // no error
      if (this.passwordCheckState === null) return "";
      // if empty
      if (!this.passwordCheck) return this.$t("validationRules.required");
      // else : passwords not equals
      return this.$t("session.register.validations.passwordNotEquals");
    },

    userAdministratorId: function() {
      return this.groupsDictionary ? this.groupsDictionary.UserAdministrator : null;
    },
    sexOptions: function() {
      return this.$systemSettings.sexes;
    }
  },
  watch: {
    userGroups: {
      handler: function(newVal) {
        this.$nextTick(() => {
          if (newVal && this.userLaboratoriesOptions.length && this.groupsDictionary) {
            // check all laboratory options when given admin rights
            if (newVal.includes(this.userAdministratorId)) {
              this.userLaboratories = this.userLaboratoriesOptions.map(v => v.id);
            }
            // when selecting a chief/admin right : auto check lesser rights
            if (
              (newVal.includes(this.groupsDictionary.ChiefSecretary) || newVal.includes(this.groupsDictionary.UserAdministrator)) &&
              !newVal.includes(this.groupsDictionary.Secretary)
            ) {
              this.userGroups.push(this.groupsDictionary.Secretary);
            }
            if (
              (newVal.includes(this.groupsDictionary.ChiefAccountant) || newVal.includes(this.groupsDictionary.UserAdministrator)) &&
              !newVal.includes(this.groupsDictionary.Accountant)
            ) {
              this.userGroups.push(this.groupsDictionary.Accountant);
            }
            if (
              (newVal.includes(this.groupsDictionary.Pathologist) || newVal.includes(this.groupsDictionary.UserAdministrator)) &&
              !newVal.includes(this.groupsDictionary.Cytotechnician)
            ) {
              this.userGroups.push(this.groupsDictionary.Cytotechnician);
            }
            if (
              (newVal.includes(this.groupsDictionary.ChiefTechnician) || newVal.includes(this.groupsDictionary.UserAdministrator)) &&
              !newVal.includes(this.groupsDictionary.Technician)
            ) {
              this.userGroups.push(this.groupsDictionary.Technician);
            }
            if (newVal.includes(this.groupsDictionary.UserAdministrator)) {
              if (!newVal.includes(this.groupsDictionary.ChiefSecretary)) {
                this.userGroups.push(this.groupsDictionary.ChiefSecretary);
              }
              if (!newVal.includes(this.groupsDictionary.ChiefAccountant)) {
                this.userGroups.push(this.groupsDictionary.ChiefAccountant);
              }
              if (!newVal.includes(this.groupsDictionary.Pathologist)) {
                this.userGroups.push(this.groupsDictionary.Pathologist);
              }
              if (!newVal.includes(this.groupsDictionary.ChiefTechnician)) {
                this.userGroups.push(this.groupsDictionary.ChiefTechnician);
              }
            }
          }
        });
      },
      deep: true
    }
  },
  async mounted() {
    await this.importUserGroups();
    this.setupGroupsDictionary();
    await this.importData();
    this.importLaboratories();

    // auto-focus
    if (!this.moduleModeProp) {
      this.focusFirstElement();
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.emailInput.focus();
    },

    // load data
    importLaboratories() {
      this.userLaboratoriesOptions = this.$systemSettings.laboratories;
    },
    async importUserGroups() {
      if (this.isActiveEditRightsProp) {
        try {
          const resUserGroups = await commonServices.getAll("userGroups", null);
          this.userGroupsOptions = resUserGroups.data;
          for (const option of this.userGroupsOptions) {
            option.localisedName = this.$t("userGroups." + option.name);
          }
        } catch (err) {
          this.handleErrors(err);
        }
      }
    },
    async importData() {
      if (this.editMode) {
        // import from prop
        if (this.userDataProp !== null) {
          this.email = this.userDataProp.email;
          this.sexId = this.userDataProp.sexId;
          this.firstName = this.userDataProp.firstName;
          this.lastName = this.userDataProp.lastName;
          this.phone = this.userDataProp.phone ? this.importFormatPhone(this.userDataProp.phone) : "";
          this.password = "";
          this.passwordCheck = "";
          // setup laboratories and userGroups (if it's not a user request)
          this.userLaboratories = [];
          this.userGroups = [];
          if (!this.isUserRequestProp && this.userDataProp.userLaboratories && this.userDataProp.userRights) {
            // userLaboratories
            this.userLaboratories = this.userDataProp.userLaboratories.map(v => v.laboratoryId);
            // userGroups
            this.userGroups = this.userDataProp.userRights.map(v => v.userGroupId);
          }
          this.isActive = this.userDataProp.isActive;
        }
      }
    },
    // groupsDictionary
    async setupGroupsDictionary() {
      const dictionary = {};
      this.groupsDictionary = this.userGroupsOptions.forEach((group) => {
        dictionary[group.name] = group.id;
      });
      this.groupsDictionary = dictionary;
    },

    isGroupDisabled(name) {
      if (this.groupsDictionary) {
        if (name === "Secretary") { return this.userGroups.includes(this.groupsDictionary.ChiefSecretary); }
        if (name === "ChiefSecretary") { return this.userGroups.includes(this.groupsDictionary.UserAdministrator); }
        if (name === "Accountant") { return this.userGroups.includes(this.groupsDictionary.ChiefAccountant); }
        if (name === "ChiefAccountant") { return this.userGroups.includes(this.groupsDictionary.UserAdministrator); }
        if (name === "Cytotechnician") { return this.userGroups.includes(this.groupsDictionary.Pathologist); }
        if (name === "Pathologist") { return this.userGroups.includes(this.groupsDictionary.UserAdministrator); }
        if (name === "Technician") { return this.userGroups.includes(this.groupsDictionary.ChiefTechnician); }
        if (name === "ChiefTechnician") { return this.userGroups.includes(this.groupsDictionary.UserAdministrator); }
      } else {
        return false;
      }
    },
    // handle show/hide on the password fields
    onShowPassword(refTarget, showVariableName) {
      this[showVariableName] = !this[showVariableName];
      this.$nextTick(() => this.$refs[refTarget].focus());
    },

    onValidate() {
      this.emailValidation = true;
      this.sexValidation = true;
      this.firstNameValidation = true;
      this.lastNameValidation = true;
      this.phoneValidation = true;
      this.passwordValidation = true;
      this.passwordCheckValidation = true;

      return !(
        this.emailState === false ||
        this.sexState === false ||
        this.firstNameState === false ||
        this.lastNameState === false ||
        this.phoneValidation === false ||
        this.passwordState === false ||
        this.passwordCheckState === false
      );
    },
    onClearValidate() {
      this.emailValidation = false;
      this.sexValidation = false;
      this.firstNameValidation = false;
      this.lastNameValidation = false;
      this.phoneValidation = false;
      this.passwordValidation = false;
      this.passwordCheckValidation = false;
    },
    async onSubmit() {
      try {
        // Validate Form
        if (this.onValidate() === false) return false;

        const form = {
          email: this.email,
          sexId: this.sexId,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.exportFormatPhone(this.phone),
          password: this.password,
          userLaboratories: this.userLaboratories,
          userGroups: this.userGroups,
          isActive: this.isActive
        };

        if (this.isUserRequestProp) {
          // USER REQUEST MODE
          form.userRequestId = this.userId;
          await commonServices.post("users", form);
          this.onClearValidate();
          this.$emit("userAdded", form);
        } else if (this.editMode) {
          // EDIT USER MODE
          form.oldEmail = this.userDataProp.email;
          if (this.password === "") delete form.password;

          const res = await usersServices.putUser(form);
          if (res.data.id) {
            // success
            this.onClearValidate();
            res.data.userRights = res.data.userRights.map((right) => {
              return right.userGroup.name;
            });
            this.$emit("userEdited", res.data);
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("userEdit.notifications.editionErrorTitle"),
              message: this.$t("userEdit.notifications.editionErrorText")
            });
          }
        } else {
          // NEW USER MODE
          const res = await commonServices.post("users", form);
          if (res.data.id) {
            // success
            this.onClearValidate();
            this.$emit("userAdded", res.data);
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("userNew.notifications.additionErrorTitle"),
              message: this.$t("userNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onReset() {
      // Reset each form variables
      this.email = this.userDataProp.email;
      this.sexId = this.userDataProp.sexId;
      this.firstName = this.userDataProp.firstName;
      this.lastName = this.userDataProp.lastName;
      this.phone = this.importFormatPhone(this.userDataProp.phone);
      this.password = "";
      this.passwordCheck = "";

      this.userLaboratories = this.userDataProp.userLaboratories.map((userLaboratory) => {
        return userLaboratory.laboratoryId;
      });

      this.userGroups = this.userDataProp.userRights.map((userRight) => {
        return userRight.userGroupId;
      });
      this.isActive = this.userDataProp.isActive;
    },
    onClear() {
      // Clear each form variables
      this.email = "";
      this.sexId = 2;
      this.firstName = "";
      this.lastName = "";
      this.phone = "";
      this.password = "";
      this.passwordCheck = "";

      this.userLaboratories = [];

      this.userGroups = [];

      this.isActive = true;
    }
  }
};
</script>

<template>
  <div
    class="kt-item-list__item"
    style="width: 100%"
  >
    <!-- view each act -->
    <ActView
      :actIdProp="actId"
      :moduleModeProp="true"
      :displayedFieldsProp="[
        'code',
        'description',
        'price',
        'validityDate'
      ]"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- divider -->
    <div class="w-100 border-top mt-3 mb-2"></div>

    <!-- start/end ValidityDate -->
    <b-row v-if="!viewModeProp">
      <b-col cols="12">
        <h3 class="h6">
          {{ $t("taskActs.validityDateView") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('taskActs.startValidityDate')"
          :invalid-feedback="startValidityDateInvalidFeedback"
          :state="startValidityDateStateDisplay"
          class="mb-0"
        >
          <b-form-input
            v-model="startValidityDate"
            type="date"
            :state="startValidityDateStateDisplay"
            @blur="startValidityDateValidation = true"
            @input="sendToparent"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('taskActs.endValidityDate')"
          :invalid-feedback="endValidityDateInvalidFeedback"
          :state="endValidityDateStateDisplay"
          class="mb-0"
        >
          <b-form-input
            v-model="endValidityDate"
            type="date"
            :state="endValidityDateStateDisplay"
            @blur="endValidityDateValidation = true"
            @input="sendToparent"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-else>
      <!-- ValidityDate -->
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6 mb-1">
          {{ $t("taskActs.validityDateView") }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        {{ (startValidityDate ? $d(new Date(startValidityDate), "date") : "NC") + " - " + (endValidityDate ? $d(new Date(endValidityDate), "date") : "NC") }}
      </b-col>
    </b-row>

    <!-- divider -->
    <div class="w-100 border-top mt-3 mb-2"></div>

    <!-- Additional Fees - edit -->
    <div v-if="!viewModeProp">
      <h3 class="h6">
        {{ $t("taskActs.additionalFees") }}
        <!-- add button -->
        <b-button
          size="sm"
          :variant="'outline-' + $systemSettings.theme"
          class="btn-icon ml-1"
          style="font-size: 19px;"
          @click="onAddAdditionalFee"
        >
          <b-icon icon="plus"></b-icon>
        </b-button>
      </h3>
      <div class="kt-item-list">
        <div
          v-for="additionalFee in additionalFees"
          :key="additionalFee.tempId"
          class="kt-item-list__item-wrapper"
          style="width: 100%"
        >
          <!-- DEBUG :
          {{ additionalFee.tempId }}<br>
          {{ additionalFee.additionalFeeGroupId + '_' + additionalFee.price + '_' + (additionalFee.areDatesOverlapping ? 1 : 0) + '_' + additionalFee.startValidityDate + '_' + additionalFee.endValidityDate }} -->
          <!-- Additional Fees -->
          <AdditionalFee
            :additionalFeeProp="additionalFee"
            :validationActiveProp="validationActiveProp"
            :viewModeProp="viewModeProp"
            :additionalFeeGroupsOptionsProp="additionalFeeGroupsOptionsProp"
            :areDatesOverlappingProp="additionalFee.areDatesOverlapping"
            @deleteAdditionalFee="onDeleteAdditionalFee(additionalFee.tempId)"
            @updateAdditionalFee="onUpdateAdditionalFee(additionalFee.tempId, $event)"
            @updateIsValid="onIsValidUpdated(additionalFee.tempId, $event)"
          />
        </div>
      </div>
    </div>
    <!-- Additional Fees - view -->
    <b-row v-else>
      <!-- title -->
      <b-col
        cols="12"
        md="3"
      >
        <h3 class="h6 mb-1">
          {{ $t("taskActs.additionalFees") }}
        </h3>
      </b-col>
      <!-- list -->
      <b-col
        cols="12"
        md="9"
      >
        <div v-if="!additionalFees || additionalFees.length === 0">
          {{ $t("taskActs.emptyAdditionalFees") }}
        </div>
        <div v-else>
          <b-row
            v-for="(additionalFee, index) in additionalFees"
            :key="additionalFee.id"
          >
            <!-- divider -->
            <b-col
              v-show="index !== 0"
              cols="12"
            >
              <div class="w-100 border-top mt-2 mb-2"></div>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div>{{ additionalFee.additionalFeeGroup.name }}</div>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div>{{ isNaN(additionalFee.price) ? $t("general.emptyWithHyphen") : $n(additionalFee.price, "currency") }}</div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>{{ $t("taskActs.additionalFeeValidityDateView") }}{{ (additionalFee.startValidityDate ? $d(new Date(additionalFee.startValidityDate), "date") : "NC") + " - " + (additionalFee.endValidityDate ? $d(new Date(additionalFee.endValidityDate), "date") : "NC") }}</div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <!-- actions -->
    <div class="kt-item-list__item-actions">
      <!-- delete button -->
      <b-button
        v-if="!viewModeProp"
        class="btn-icon kt-item-list__item-delete"
        size="sm"
        variant="light"
        squared
        @click="$emit('deleteTaskAct', tempId)"
      >
        <b-icon icon="x"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
// components
import ActView from "@/views/Data/Acts/ActView";
import AdditionalFee from "@/views/Data/AdditionalFee";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    ActView,
    AdditionalFee
  },
  mixins: [userRights, error, date],
  props: {
    viewModeProp: {
      type: Boolean
    },
    taskActProp: {
      type: Object,
      default: null,
      deep: true
    },
    validationActiveProp: {
      type: Boolean
    },
    additionalFeeGroupsOptionsProp: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      id: null,
      tempId: "",
      actId: null,
      startValidityDate: "",
      endValidityDate: "",
      additionalFees: [],
      // validation
      additionalFeesIsValidArray: [],
      // is validation active
      startValidityDateValidation: this.validationActiveProp,
      endValidityDateValidation: this.validationActiveProp
    };
  },
  computed: {
    startValidityDateState: function() {
      return this.startValidityDate && this.startValidityDate.length > 0 ? null : false;
    },
    startValidityDateStateDisplay: function() {
      if (!this.startValidityDateValidation) return null;
      return this.startValidityDateState;
    },
    startValidityDateInvalidFeedback: function() {
      return this.startValidityDateStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    endValidityDateState: function() {
      if (!this.endValidityDate) return null;
      return this.validateDateBeforeDate(this.startValidityDate, this.endValidityDate) ? null : false;
    },
    endValidityDateStateDisplay: function() {
      if (!this.endValidityDateValidation) return null;
      return this.endValidityDateState;
    },
    endValidityDateInvalidFeedback: function() {
      if (this.endValidityDateStateDisplay === null) return "";
      return this.$t("validationRules.endDateBeforeStartDate");
    },
    isValid: function() {
      // validity period
      if (this.startValidityDateState === false || this.endValidityDateState === false) {
        return false;
      }
      // each taskAct fields validation
      let state = null;
      for (const additionalFee of this.additionalFees) {
        if (!this.additionalFeesIsValidArray.includes(additionalFee.tempId)) {
          state = false;
          break;
        }
      }
      return state;
    }
  },
  watch: {
    // update data on prop change
    taskActProp: {
      handler: function(newVal) {
        const value = JSON.parse(JSON.stringify(newVal));
        this.id = value.id || null;
        this.tempId = value.tempId || "";
        // update only if different or empty
        if (value.actId) {
          if (value.actId !== this.actId) this.actId = value.actId;
        } else {
          this.actId = "";
        }
        if (value.startValidityDate) {
          if (value.startValidityDate !== this.startValidityDate) this.startValidityDate = value.startValidityDate;
        } else {
          this.startValidityDate = null;
        }
        if (value.endValidityDate) {
          if (value.endValidityDate !== this.endValidityDate) this.endValidityDate = value.endValidityDate;
        } else {
          this.endValidityDate = null;
        }
        if (value.additionalFees && value.additionalFees.length > 0) {
          for (const additionalFee of value.additionalFees) {
            // search existing additionalFee
            let foundPosition = null;
            for (let j = 0; j < this.additionalFees.length; j++) {
              if (additionalFee.tempId === this.additionalFees[j].tempId) {
                foundPosition = j;
              }
            }
            // update data
            if (foundPosition === null) {
              // setup tempId
              if (!additionalFee.tempId) additionalFee.tempId = additionalFee.id || "new-" + uniqueId();
              this.additionalFees.push(additionalFee);
            } else {
              if (additionalFee.additionalFeeGroupId !== this.additionalFees[foundPosition].additionalFeeGroupId) {
                this.additionalFees[foundPosition].additionalFeeGroupId = additionalFee.additionalFeeGroupId;
                this.additionalFees[foundPosition].additionalFeeGroup = additionalFee.additionalFeeGroup;
              }
              if (additionalFee.startValidityDate !== this.additionalFees[foundPosition].startValidityDate) {
                this.additionalFees[foundPosition].startValidityDate = additionalFee.startValidityDate;
              }
              if (additionalFee.endValidityDate !== this.additionalFees[foundPosition].endValidityDate) {
                this.additionalFees[foundPosition].endValidityDate = additionalFee.endValidityDate;
              }
              if (additionalFee.price !== this.additionalFees[foundPosition].price) {
                this.additionalFees[foundPosition].price = additionalFee.price;
              }
              if (additionalFee.areDatesOverlapping !== this.additionalFees[foundPosition].areDatesOverlapping) {
                this.additionalFees[foundPosition].areDatesOverlapping = additionalFee.areDatesOverlapping;
              }
            }
          }
        } else {
          this.additionalFees = [];
        }
      },
      deep: true,
      immediate: true
    },
    // activate validation by prop
    validationActiveProp: function(value, oldValue) {
      if (value !== oldValue) {
        this.startValidityDateValidation = value;
        this.endValidityDateValidation = value;
        // update task
        this.updateOverlappingDatesValidation();
      }
    },
    // sendIsValid
    isValid: function(value, oldValue) {
      if (value !== oldValue) {
        this.sendIsValid();
      }
    }
  },
  mounted() {
    // update task
    this.updateOverlappingDatesValidation();

    this.sendIsValid();
  },
  methods: {
    updateOverlappingDatesValidation() {
      // reset
      for (const additionalFee of this.additionalFees) {
        additionalFee.areDatesOverlapping = false;
      }
      for (let i = 0; i < this.additionalFees.length; i++) {
        for (let j = 0; j < this.additionalFees.length; j++) {
          if (
            i !== j &&
            this.additionalFees[i].additionalFeeGroupId === this.additionalFees[j].additionalFeeGroupId
          ) {
            const validation = this.validateNonOverlappingPeriods(
              this.additionalFees[i].startValidityDate,
              this.additionalFees[i].endValidityDate,
              this.additionalFees[j].startValidityDate,
              this.additionalFees[j].endValidityDate
            );
            if (validation === false) {
              this.additionalFees[i].areDatesOverlapping = true;
              this.additionalFees[j].areDatesOverlapping = true;
              break;
            }
          }
        }
      }
      // force update
      this.additionalFees.push();
      this.sendToparent();
    },
    // Helpers
    getDisplayedPosition(tempId) {
      return this.additionalFees.reduce((pos, value, index) => {
        if (pos !== null) {
          return pos;
        } else {
          return value.tempId === tempId ? index : null;
        }
      }, null);
    },
    // Actions
    onIsValidUpdated(tempId, isValid) {
      // search position
      const position = this.additionalFeesIsValidArray.reduce((pos, currentId, index) => {
        if (pos !== null) {
          return pos;
        } else {
          return currentId === tempId ? index : null;
        }
      }, null);
      // edit
      if (isValid !== false) {
        if (position === null) this.additionalFeesIsValidArray.push(tempId);
      } else {
        if (position !== null) this.additionalFeesIsValidArray.splice(position, 1);
      }
    },
    sendIsValid() {
      this.$emit("updateIsValid", this.isValid);
    },
    // on update / finished
    sendToparent() {
      this.$emit("updateTaskAct", {
        id: this.id,
        tempId: this.tempId,
        actId: this.actId,
        startValidityDate: this.startValidityDate,
        endValidityDate: this.endValidityDate,
        additionalFees: this.additionalFees
      });
    },
    // AdditionalFee Actions
    onAddAdditionalFee() {
      this.additionalFees.push({
        id: null,
        tempId: "new-" + uniqueId(),
        areDatesOverlapping: false,
        additionalFeeGroupId: null,
        additionalFeeGroup: null,
        startValidityDate: this.getIsoDate(),
        endValidityDate: "",
        price: 0
      });

      this.sendToparent();
    },
    onDeleteAdditionalFee(tempId) {
      const position = this.getDisplayedPosition(tempId);
      this.additionalFees.splice(position, 1);
      // update overlapping
      this.updateOverlappingDatesValidation();

      this.sendToparent();
    },
    onUpdateAdditionalFee(tempId, data) {
      const position = this.getDisplayedPosition(tempId);
      if (position !== null) {
        this.additionalFees[position] = data;
        // update overlapping
        this.updateOverlappingDatesValidation();

        this.sendToparent();
      }
    }
  }
};
</script>

<template>
  <div class="kt-histologyValidation">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        v-if="filterProblematicFilesProp"
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="navigate('diagnosis/validation/histologyValidation')"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("histologyValidation.pageTitle") }}
        <span
          v-if="filterProblematicFilesProp"
          class="text-danger"
        >{{ $t("histologyValidation.problematicSubtitle") }}</span>
      </h2>
      <ProblematicFiles
        v-if="!filterProblematicFilesProp"
        typeProp="histologyValidation"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </div>

    <!-- table -->
    <!--
      ref="histologyReportsTable"
      hover
      selected-variant
      select-mode="multi"
      selectable
      @row-selected="tableRows = $event"
    -->
    <b-table
      class="m-0 kt-table"
      responsive
      :fields="fields"
      :items="histologyReports"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- select box
          <b-th
            class="kt-table__th-search"
            style="width: 16px;"
          >
            <button
              class="kt-checkbox-display-only"
              :class="'kt-checkbox-display-only--' + $systemSettings.theme + (tableRows.length ? ' kt-checkbox-display-only--checked' : '')"
              @click="selectAll"
            ></button>
          </b-th> -->
          <!-- filter access Number -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputAccessNumber
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              :maxlengthProp="50"
              trimProp
              @input="onFilterChange"
            />
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter creation date -->
          <b-th
            class="kt-table__th-search"
            style="width: 149px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCreationDate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @input="onFilterChange"
            ></b-form-input>
          </b-th>
          <!-- filter lastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter firstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter birthdate -->
          <b-th
            class="kt-table__th-search"
            style="width: 127px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterBirthdate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @input="onFilterChange"
            ></b-form-input>
          </b-th>
          <!-- filter contact -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterContact"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterContact !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterContact')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter tasks -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterTask"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterTask !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterTask')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- Status -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- actions -->
          <b-th class="kt-table__th-search"></b-th>
        </b-tr>
      </template>

      <!-- cell template : checkbox buttons
      <template v-slot:cell(selectionCheckboxes)="{rowSelected}">
        <span
          class="kt-checkbox-display-only"
          :class="'kt-checkbox-display-only--' + $systemSettings.theme + (rowSelected ? ' kt-checkbox-display-only--checked' : '')"
        ></span>
      </template> -->

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.accessNumber }}
          <!-- is urgent -->
          <span
            v-show="data.item.file.isUrgent"
            style="font-size: 14px; margin: 0 0 1px 3px;"
          >
            <b-icon
              icon="flag-fill"
              variant="danger"
            ></b-icon>
          </span>
          <!-- complementary -->
          <span
            v-show="data.item.file.parentFileId"
            :style="{fontSize: '14px', margin: data.item.file.isUrgent ? '0 0 1px 5px' : '0 0 1px 3px', color: 'rgb(59, 72, 83)'}"
          >
            <b-icon
              icon="back"
            ></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : status badges -->
      <template v-slot:cell(medicalStatus)="data">
        <StatusBadge
          typeProp="medicalStatus"
          :statusNameProp="data.item.medicalStatus"
          isHiddenEmptyProp
        />
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(histologyReportButtons)="data">
        <TableActionButtons
          :elementIdProp="data.item['id']"
          :viewDisplayedProp="!isDownloading && isEnabled(['menu', 'diagnosis', 'histologyReports', 'view']) && data.item['file']['medicalStatus']['name'] === 'validatedMedically'"
          :editDisplayedProp="!isDownloading && isEnabled(['menu', 'diagnosis', 'histologyReports', 'edit']) ? '' : 'kt-table__actions-btn--hidden'"
          :deleteDisplayedProp="false"
          @onViewClick="onView(data.item)"
          @onEditClick="navigate(
            'diagnosis/histologyReportEdit',
            {fileIdProp: data.item.file.id, cancelRouteProp: 'diagnosis/validation/histologyValidation'}
          )"
        />
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!histologyReportsCount && !loading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar>
        <b-navbar-nav>
          <b-pagination
            v-show="histologyReportsCount"
            v-model="page"
            class="mx-2 my-3"
            :class="'kt-pagination--' + $systemSettings.theme"
            :total-rows="histologyReportsCount"
            per-page="50"
            @input="onPageInput"
          ></b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>

    <!-- select box : actions
    <div
      v-show="tableRows.length"
      class="kt-select-action-box"
    >
      <b-container class="text-center">
        <div class="kt-select-action-box__wrapper">
          <div class="kt-select-action-box__title h6 mr-3 mb-0">
            {{ $tc("medicalReports.actions.selectedElements", tableRows.length, {count: tableRows.length}) }}
          </div>
          <div>
            <b-button
              class="kt-select-action-box__cta"
              :variant="$systemSettings.theme"
              @click="onValidateSelected"
            >
              {{ $tc("medicalReports.actions.validateMedically") }}
            </b-button>
          </div>
        </div>
      </b-container>
    </div> -->
  </div>
</template>

<script>
// components
import StatusBadge from "@shared/views/Helpers/StatusBadge";
import TableActionButtons from "@shared/views/Helpers/TableActionButtons";
import ProblematicFiles from "@/views/Helpers/ProblematicFiles";
import InputAccessNumber from "@shared/views/Helpers/InputAccessNumber";
// services
import commonServices from "@shared/services/API/commonServices";
import histologyReportsServices from "@/services/API/histologyReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: {
    StatusBadge,
    TableActionButtons,
    ProblematicFiles,
    InputAccessNumber
  },
  mixins: [userRights, pagination, error, saveParamsInQuery],
  props: {
    filterProblematicFilesProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      loading: false,
      histologyReportsCount: 0,
      page: 1,
      // table fields
      fields: [
        // {
        //   tdClass: "kt-table__td",
        //   thClass: "kt-table__th",
        //   key: "selectionCheckboxes",
        //   sortable: false,
        //   label: ""
        // },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "file.creationDate",
          sortable: true,
          label: this.$t("files.creationDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "file.patient.lastName",
          sortable: true,
          label: this.$t("patients.lastName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "file.patient.firstName",
          sortable: true,
          label: this.$t("patients.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "file.patient.birthdate",
          sortable: true,
          label: this.$t("patients.birthdate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "file.fileContacts",
          sortable: false,
          label: this.$t("files.prescriber"),
          formatter: (value, _key, _item) => {
            // value is already filtered with prescriber
            return value.length === 1 ? value[0].contact.firstName + " " + value[0].contact.lastName : "?";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "file.containerGroups",
          sortable: false,
          label: this.$t("files.containerGroupTasks"),
          formatter: (value, _key, _item) => {
            let formattedString = "";
            value
              .map(containerGroup => {
                formattedString += containerGroup.containerGroupTasks
                  .map(containerGroupTask => {
                    return containerGroupTask.task.code;
                  })
                  .join(", ");
              })
              .join("; ");
            return formattedString;
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "medicalStatus",
          sortable: true,
          label: this.$t("medicalReports.file.medicalStatus")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "histologyReportButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterAccessNumber: "",
      filterLastName: "",
      filterFirstName: "",
      filterBirthdate: "",
      filterCreationDate: "",
      filterContact: "",
      filterTask: "",
      // table items
      histologyReports: [],
      isDownloading: false,
      // table selection
      // tableRows: []
      // saveParamsConfig
      saveParamsConfig: {
        filterAccessNumber: "string",
        filterLastName: "string",
        filterFirstName: "string",
        filterBirthdate: "string",
        filterCreationDate: "string",
        filterContact: "string",
        filterTask: "string"
      }
    };
  },
  computed: {
    sortByFormatted: function() {
      if (this.sortBy === "accessNumber") {
        return "file.accessNumber";
      } else if (this.sortBy === "medicalStatus") {
        return "file.medicalStatus.name";
      } else if (this.sortBy === "cytotechnician") {
        return "cytotechnicianId";
      } else {
        return this.sortBy;
      }
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // load histologyReports
    await this.onPageInput();
  },
  methods: {
    // update table
    async onPageInput() {
      try {
        // validation dates
        if (this.filterCreationDate !== "") {
          const filterCreationDate = new Date(this.filterCreationDate).getFullYear();
          if (filterCreationDate < 1900 || filterCreationDate > 2300) {
            return false;
          }
        }
        if (this.filterBirthdate !== "") {
          const filterBirthdate = new Date(this.filterBirthdate).getFullYear();
          if (filterBirthdate < 1900 || filterBirthdate > 2300) {
            return false;
          }
        }

        this.loading = true;
        // this.$refs.histologyReportsTable.clearSelected();

        // search and Update
        const res = await histologyReportsServices.getAllByPathologist(
          this.page,
          {
            problematicFiles: this.filterProblematicFilesProp,
            accessNumber: this.filterAccessNumber,
            lastName: this.filterLastName,
            firstName: this.filterFirstName,
            birthdate: this.filterBirthdate,
            creationDate: this.filterCreationDate,
            contact: this.filterContact,
            task: this.filterTask
          },
          {
            by: this.sortByFormatted,
            desc: this.sortDesc
          }
        );
        this.histologyReportsCount = res.data.count;
        this.histologyReports = res.data.rows;
        // set up medicalStatus and accessNumber
        this.histologyReports.map(histologyReport => {
          histologyReport.accessNumber = histologyReport.file.accessNumber;
          histologyReport.medicalStatus = "";
          if (typeof histologyReport.file.medicalStatus !== "undefined" && typeof histologyReport.file.medicalStatus.name === "string") {
            histologyReport.medicalStatus = histologyReport.file.medicalStatus.name;
          }
        });

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search with delay
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.onPageInput();
      }, 450);
    },
    // clear one filter
    async onClearFilter(fieldName) {
      this[fieldName] = "";
      await this.onPageInput();
    },

    // View
    async onView(row) {
      this.isDownloading = true;
      try {
        const resFile = await commonServices.export("histologyReports", row.id, "pdf");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.file.accessNumber + ".pdf");
        }
      } catch (err) {
        this.handleErrors(err);
      }
      this.isDownloading = false;
    }

    // validate the selected reports
    // selectAll() {
    //   if (this.tableRows.length === 0) {
    //     this.$refs.histologyReportsTable.selectAllRows();
    //   } else {
    //     this.$refs.histologyReportsTable.clearSelected();
    //   }
    // },
    // async onValidateSelected() {
    //   this.loading = true;
    //   const fileIdsArray = this.tableRows.map(item => item.file.id);
    //   try {
    //     const res = await histologyReportsServices.validateMedically(fileIdsArray);
    //     if (res.data === true) {
    //       // success validation
    //       this.$emit("alert", "success", {
    //         title: this.$t("medicalReports.notifications.selectedFileValidationSuccessTitle")
    //       });
    //       await this.onPageInput();
    //     } else if (res.data.error === "fileNotValidatedForDiagnosis") {
    //       // one or more file are not validated for diagnosis
    //       this.$emit("alert", "danger", {
    //         title: this.$t("medicalReports.notifications.selectedFileNotValidatedForDiagnosisErrorTitle"),
    //         message: this.$t("medicalReports.notifications.selectedFileNotValidatedForDiagnosisErrorText")
    //       });
    //       await this.onPageInput();
    //     } else if (res.data.error === "fileNotSent") {
    //       // one or more file are not validated technically
    //       this.$emit("alert", "danger", {
    //         title: this.$tc("medicalReports.notifications.selectedFileNotSentErrorTitle", fileIdsArray.length),
    //         message: this.$tc("medicalReports.notifications.selectedFileNotSentErrorText", fileIdsArray.length)
    //       });
    //       await this.onPageInput();
    //     } else {
    //       // error
    //       this.$emit("alert", "danger", {
    //         title: this.$t("medicalReports.notifications.selectedFileValidationErrorTitle"),
    //         message: this.$t("medicalReports.notifications.selectedFileValidationErrorText")
    //       });
    //     }
    //   } catch (err) {
    //     this.handleErrors(err);
    //   }
    //   this.loading = false;
    // }
  }
};
</script>

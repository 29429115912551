<template>
  <div
    class="kt-table__actions-container"
    :style="actionContainerStyleComputed"
  >
    <!-- view -->
    <b-button
      :ref="elementIdProp + '_view'"
      :key="elementIdProp + '_view'"
      :class="viewDisplayedProp ? '' : 'kt-table__actions-btn--hidden'"
      class="kt-table__actions-btn"
      size="sm"
      :variant="$systemSettings.theme"
      pill
      @click="$emit('onViewClick')"
    >
      <b-icon icon="eye"></b-icon>
    </b-button>
    <!-- edit -->
    <b-button
      :ref="elementIdProp + '_edit'"
      :key="elementIdProp + '_edit'"
      :class="editDisplayedProp ? '' : 'kt-table__actions-btn--hidden'"
      class="kt-table__actions-btn"
      size="sm"
      :variant="$systemSettings.theme"
      pill
      @click="$emit('onEditClick')"
    >
      <b-icon icon="pen"></b-icon>
    </b-button>
    <!-- delete  -->
    <b-button
      :ref="elementIdProp + '_delete'"
      :key="elementIdProp + '_delete'"
      :class="deleteDisplayedProp ? '' : 'kt-table__actions-btn--hidden'"
      class="kt-table__actions-btn"
      size="sm"
      :variant="$systemSettings.theme"
      pill
      @click="$emit('onDeleteClick')"
    >
      <b-icon icon="trash"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    elementIdProp: {
      type: Number,
      default: 0
    },
    viewDisplayedProp: {
      type: Boolean
    },
    editDisplayedProp: {
      type: Boolean
    },
    deleteDisplayedProp: {
      type: Boolean
    }
  },
  computed: {
    actionContainerStyleComputed: function() {
      return this.actionContainerStyle([
        this.viewDisplayedProp,
        this.editDisplayedProp,
        this.deleteDisplayedProp
      ]);
    }
  },
  methods: {
    actionContainerStyle(arr) {
      // validation
      if (typeof arr !== "object") return {};

      // init
      let containerWidth = 0;
      let visibleCount = 0;
      for (const item of arr) {
        if (item) visibleCount++;
      }

      if (visibleCount) {
        containerWidth = (visibleCount * 37) + ((visibleCount - 1) * 4) + 1;
      }

      return { width: containerWidth + "px" };
    }
  }
};
</script>

<template>
  <div class="kt-smtpServer">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("systemSettings.smtpServerTitle") }}
      </h2>
    </div>
    <!-- input SMTP mail -->
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="$t('systemSettings.smtpServer.smtpSystemSendingAddress')"
          :invalid-feedback="systemSendingAddressInvalidFeedback"
          :state="systemSendingAddressState"
        >
          <b-form-input
            v-model="systemSendingAddress"
            maxlength="256"
            :state="systemSendingAddressState"
            @blur="systemSendingAddressValidation = true"
            @change="onChangeForm('systemSendingAddress', systemSendingAddress)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- input SMTP hourly quota -->
      <b-col cols="6">
        <b-form-group
          :label="$t('systemSettings.smtpServer.smtpHourlyQuota')"
          :invalid-feedback="smtpServerHourlyQuotaInvalidFeedback"
          :state="smtpServerHourlyQuotaState"
        >
          <b-form-input
            v-model="smtpServerHourlyQuota"
            type="number"
            min="0"
            :state="smtpServerHourlyQuotaState"
            @blur="smtpServerHourlyQuotaValidation = true"
            @change="onChangeForm('smtpServerHourlyQuota', smtpServerHourlyQuota)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- input SMTP Server -->
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="$t('systemSettings.smtpServer.smtpServerAddress')"
          :invalid-feedback="smtpServerAddressInvalidFeedback"
          :state="smtpServerAddressState"
        >
          <b-form-input
            v-model="smtpServerAddress"
            maxlength="256"
            :state="smtpServerAddressState"
            @blur="smtpServerAddressValidation = true"
            @change="onChangeForm('smtpServerAddress', smtpServerAddress)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- input SMTP Port -->
      <b-col cols="6">
        <b-form-group
          :label="$t('systemSettings.smtpServer.smtpServerPort')"
          :invalid-feedback="smtpServerPortInvalidFeedback"
          :state="smtpServerPortState"
        >
          <b-form-input
            v-model="smtpServerPort"
            type="number"
            min="1"
            max="65535"
            :state="smtpServerPortState"
            @blur="smtpServerPortValidation = true"
            @change="onChangeForm('smtpServerPort', smtpServerPort)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- input SMTP User -->
    <b-row>
      <b-col cols="6">
        <b-form-group
          :label="$t('systemSettings.smtpServer.smtpServerUser')"
          :invalid-feedback="smtpServerUserInvalidFeedback"
          :state="smtpServerUserState"
        >
          <b-form-input
            v-model="smtpServerUser"
            maxlength="256"
            :state="smtpServerUserState"
            @blur="smtpServerUserValidation = true"
            @change="onChangeForm('smtpServerUser', smtpServerUser)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- input SMTP Password -->
      <b-col cols="6">
        <b-form-group
          :label="$t('systemSettings.smtpServer.smtpServerPassword')"
          :invalid-feedback="smtpServerPasswordInvalidFeedback"
          :state="smtpServerPasswordState"
        >
          <b-form-input
            v-model="smtpServerPassword"
            maxlength="256"
            :state="smtpServerPasswordState"
            @blur="smtpServerPasswordValidation = true"
            @change="onChangeForm('smtpServerPassword', smtpServerPassword)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// services
import systemSettingsServices from "@shared/services/API/systemSettingsServices";
// helpers
import error from "@shared/services/UI/error";
export default {
  mixins: [error],
  data() {
    return {
      systemSendingAddress: "",
      smtpServerHourlyQuota: "",
      smtpServerAddress: "",
      smtpServerPort: "",
      smtpServerUser: "",
      smtpServerPassword: "",
      // is validation active
      systemSendingAddressValidation: false,
      smtpServerHourlyQuotaValidation: false,
      smtpServerAddressValidation: false,
      smtpServerPortValidation: false,
      smtpServerUserValidation: false,
      smtpServerPasswordValidation: false
    };
  },
  computed: {
    // form validation
    systemSendingAddressState: function() {
      if (!this.systemSendingAddressValidation) return null;
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.systemSendingAddress) ? null : false;
    },
    systemSendingAddressInvalidFeedback: function() {
      // no error
      if (this.systemSendingAddressState === null) return "";
      // else if empty
      if (this.systemSendingAddress === "") return this.$t("validationRules.required");
      return this.$t("systemSettings.smtpServer.invalidSmtpSystemSendingAddress");
    },
    smtpServerHourlyQuotaState: function() {
      if (!this.smtpServerHourlyQuotaValidation) return null;
      return /^[0-9]+$/.test(this.smtpServerHourlyQuota) ? null : false;
    },
    smtpServerHourlyQuotaInvalidFeedback: function() {
      // no error
      if (this.smtpServerHourlyQuotaState === null) return "";
      // else if empty
      if (this.smtpServerHourlyQuota === "") return this.$t("validationRules.required");
      return this.$t("systemSettings.smtpServer.invalidSmtpHourlyQuota");
    },
    smtpServerAddressState: function() {
      if (!this.smtpServerAddressValidation) return null;
      return this.smtpServerAddress && this.smtpServerAddress.length > 0 ? null : false;
    },
    smtpServerAddressInvalidFeedback: function() {
      // no error
      if (this.smtpServerAddressState === null) return "";
      // else if empty
      if (this.smtpServerAddress === "") return this.$t("validationRules.required");
      return this.$t("systemSettings.smtpServer.invalidSmtpServerAddress");
    },
    smtpServerPortState: function() {
      if (!this.smtpServerPortValidation) return null;
      return (/^[0-9]+$/.test(this.smtpServerPort) && Number(this.smtpServerPort) >= 1 && Number(this.smtpServerPort) <= 65535) ? null : false;
    },
    smtpServerPortInvalidFeedback: function() {
      // no error
      if (this.smtpServerPortState === null) return "";
      // if empty
      if (this.smtpServerPort === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("systemSettings.smtpServer.invalidSmtpServerPort");
    },
    smtpServerUserState: function() {
      if (!this.smtpServerUserValidation) return null;
      return this.smtpServerUser && this.smtpServerUser.length > 0 ? null : false;
    },
    smtpServerUserInvalidFeedback: function() {
      // no error
      if (this.smtpServerUserState === null) return "";
      // if empty
      if (this.smtpServerUser === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("systemSettings.smtpServer.invalidSmtpServerUser");
    },
    smtpServerPasswordState: function() {
      if (!this.smtpServerPasswordValidation) return null;
      return this.smtpServerPassword && this.smtpServerPassword.length > 0 ? null : false;
    },
    smtpServerPasswordInvalidFeedback: function() {
      // no error
      if (this.smtpServerPasswordState === null) return "";
      // if empty
      if (this.smtpServerPassword === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("systemSettings.smtpServer.invalidSmtpServerPassword");
    }
  },
  mounted: async function() {
    try {
      let res = await systemSettingsServices.get("systemSendingAddress");
      this.systemSendingAddress = res.data.value;
      res = await systemSettingsServices.get("smtpServerHourlyQuota");
      this.smtpServerHourlyQuota = res.data.value;
      res = await systemSettingsServices.get("smtpServerAddress");
      this.smtpServerAddress = res.data.value;
      res = await systemSettingsServices.get("smtpServerPort");
      this.smtpServerPort = res.data.value;
      res = await systemSettingsServices.get("smtpServerUser");
      this.smtpServerUser = res.data.value;
      res = await systemSettingsServices.get("smtpServerPassword");
      this.smtpServerPassword = res.data.value;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    async validateForm() {
      this.systemSendingAddressValidation = true;
      this.smtpServerHourlyQuotaValidation = true;
      this.smtpServerAddressValidation = true;
      this.smtpServerPortValidation = true;
      this.smtpServerUserValidation = true;
      this.smtpServerPasswordValidation = true;

      await this.$nextTick();
      return (
        this.systemSendingAddressState !== false &&
        this.smtpServerHourlyQuotaState !== false &&
        this.smtpServerAddressState !== false &&
        this.smtpServerPortState !== false &&
        this.smtpServerUserState !== false &&
        this.smtpServerPasswordState !== false
      );
    },
    async onChangeForm(smtpServer, value) {
      if (await this.validateForm() === false) return false;
      try {
        const res = await systemSettingsServices.put(smtpServer, value);
        if (res.data) {
          this.$emit("alert", "success", {
            title: this.$t("systemSettings.smtpServer.notifications.addedSmtpTitle"),
            message: this.$t("systemSettings.smtpServer.notifications.addedSmtpText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("systemSettings.smtpServer.notifications.additionErrorTitle"),
            message: this.$t("systemSettings.smtpServer.notifications.additionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

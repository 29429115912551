<template>
  <div
    class="kt-accounting-section"
    :class="'kt-accounting-section--' + $systemSettings.theme"
  >
    <div class="kt-accounting-section__background"></div>
    <div class="kt-accounting-section__container">
      <!-- switcher -->
      <b-button-group
        class="kt-btn-group"
      >
        <b-button
          class="kt-btn-group__btn kt-accounting-section__btn"
          :class="(accountingSectionName === 'organisations') ? 'kt-accounting-section__btn--active' : ''"
          variant="light"
          @click="changeAccountingSectionName('organisations')"
        >
          <b-icon icon="house"></b-icon>
          {{ $t("accountingSections.organisations") }}
        </b-button>
        <b-button
          class="kt-btn-group__btn kt-accounting-section__btn"
          :class="(accountingSectionName === 'patients') ? 'kt-accounting-section__btn--active' : ''"
          variant="light"
          @click="changeAccountingSectionName('patients')"
        >
          <b-icon icon="people"></b-icon>
          {{ $t("accountingSections.patients") }}
        </b-button>
        <b-button
          class="kt-btn-group__btn kt-accounting-section__btn"
          :class="(accountingSectionName === 'healthcares') ? 'kt-accounting-section__btn--active' : ''"
          variant="light"
          @click="changeAccountingSectionName('healthcares')"
        >
          <b-icon icon="building"></b-icon>
          {{ $t("accountingSections.healthcares") }}
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
// helpers
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  //mixins: [error],
  props: {
    currentLocationProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      accountingSectionName: this.$userSettings.savedBehaviors.accounting.activeAccountingSection || "organisations"
    };
  },
  watch: {
    "$userSettings.savedBehaviors.accounting.activeAccountingSection": function(val) {
      this.accountingSectionName = val;
    }
  },
  beforeCreate() {
    if (!this.$userSettings.savedBehaviors.accounting.activeAccountingSection && this.$route.meta.activeAccountingSection) {
      this.$userSettings.savedBehaviors.accounting.activeAccountingSection = this.$route.meta.activeAccountingSection;
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    changeAccountingSectionName(accountingSectionName) {
      if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection && accountingSectionName === this.$userSettings.savedBehaviors.accounting.activeAccountingSection) {
        // same path : do nothing
        return false;
      }

      this.accountingSectionName = accountingSectionName;
      this.$userSettings.savedBehaviors.accounting.activeAccountingSection = accountingSectionName;
      if (this.currentLocationProp === "invoices") {
        // invoices
        if (this.accountingSectionName === "organisations") {
          navigate("accounting/invoices/invoiceReports");
        } else if (this.accountingSectionName === "patients") {
          navigate("accounting/invoices/feeNotes");
        } else if (this.accountingSectionName === "healthcares") {
          navigate("accounting/invoices/careSheets");
        }
      } else if (this.currentLocationProp === "payments") {
        // payments
        if (this.accountingSectionName === "organisations") {
          navigate("accounting/payments/paymentReports");
        } else if (this.accountingSectionName === "patients") {
          navigate("accounting/payments/patientPayments");
        } else if (this.accountingSectionName === "healthcares") {
          navigate("accounting/payments/healthCarePayments");
        }
      }
    }
  }
};
</script>

<style scoped src="./AccountingSectionSelect.scss" lang="scss"></style>

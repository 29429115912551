export default {
  data() {
    return {
      sortBy: "",
      sortDesc: false
    };
  },
  methods: {
    onSortChanged(context) {
      this.sortBy = context.sortBy;
      this.sortDesc = context.sortDesc;
      if (this.sortBy) {
        this.onPageInput();
      }
    }
  }
};

<template>
  <div
    class="kt-view-page kt-task-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("taskView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="areInAreaOfAuthority(taskLaboratories) && isEnabled(['menu', 'data', 'tasks', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/task/edit', {taskIdProp: taskId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("taskView.editTask") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        v-if="!isActive ||
          taskLaboratoriesDisplay ||
          sectorDisplay ||
          codeDisplay ||
          adicapDisplay ||
          (description && descriptionDisplay) ||
          validityDateDisplay ||
          (taskActs.length > 0 && taskActsDisplay) ||
          contentDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- isActive -->
        <div
          v-show="!isActive"
          class="kt-badge kt-badge--red kt-badge--lg"
        >
          <b-icon
            icon="trash"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
        </div>

        <!-- taskLaboratories -->
        <b-row v-if="taskLaboratoriesDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("tasks.laboratories") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratoriesJoinedNames }}
            </p>
          </b-col>
        </b-row>
        <!-- sector -->
        <b-row v-if="sectorDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("tasks.sector") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ sectorLocalisedName }}
            </p>
          </b-col>
        </b-row>
        <!-- code -->
        <b-row v-if="codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("tasks.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
        <!-- adicap -->
        <b-row v-if="adicapDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("tasks.adicap") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ adicap }}
            </p>
          </b-col>
        </b-row>
        <!-- description -->
        <b-row v-if="description && descriptionDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("tasks.description") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ description }}
            </p>
          </b-col>
        </b-row>
        <!-- taskActs -->
        <b-row v-if="taskActs.length > 0 && taskActsDisplay">
          <!-- divider -->
          <b-col cols="12">
            <div class="w-100 border-top mt-1 mb-3"></div>
          </b-col>
        </b-row>
        <b-row v-if="taskActs.length > 0 && taskActsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6 mb-0">
              {{ $t('tasks.taskActs') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <TaskActs
              viewModeProp
              hideLabelProp
              :taskActsProp="taskActs"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-col>
        </b-row>
        <!-- content -->
        <b-row v-if="contentDisplay">
          <b-col
            cols="12"
          >
            <TaskCytologyContentView
              v-if="sectorId === 1"
              :contentProp="content"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
            <TaskHistologyContentView
              v-if="sectorId === 2"
              :contentProp="content"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-col>
        </b-row>

        <b-row v-if="validityDateDisplay">
          <!-- divider -->
          <b-col cols="12">
            <div class="w-100 border-top mt-1 mb-3"></div>
          </b-col>
          <!-- validityDate -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("tasks.validityDateFull") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ (startValidityDate ? $d(new Date(startValidityDate), "date") : "NC") + " - " + (endValidityDate ? $d(new Date(endValidityDate), "date") : "NC") }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// components
import TaskActs from "@/views/Data/TaskActs";
import TaskHistologyContentView from "@/views/Data/Tasks/TaskHistologyContentView";
import TaskCytologyContentView from "@/views/Data/Tasks/TaskCytologyContentView";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  components: { TaskActs, TaskHistologyContentView, TaskCytologyContentView },
  mixins: [userRights, areaOfAuthority, error, price, viewComponents],
  props: {
    taskIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "taskLaboratories",
          "sector",
          "code",
          "adicap",
          "description",
          "validityDate",
          "taskActs",
          "content"
        ];
      }
    }
  },
  data() {
    return {
      // general
      taskId: this.taskIdProp,
      taskData: null,
      // base data
      isActive: true,
      taskLaboratories: [],
      sectorId: null,
      code: "",
      adicap: "",
      description: "",
      startValidityDate: "",
      endValidityDate: "",
      taskActs: [],
      content: null,
      // extended data
      sectorLocalisedName: "",
      laboratoriesJoinedNames: "",
      // dispayFields
      taskLaboratoriesDisplay: false,
      sectorDisplay: false,
      codeDisplay: false,
      adicapDisplay: false,
      descriptionDisplay: false,
      validityDateDisplay: false,
      taskActsDisplay: false,
      contentDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get task
      const resServices = await commonServices.get("tasks", this.taskId);
      this.taskData = resServices.data;
      // assign the data
      this.isActive = resServices.data.isActive;
      this.taskLaboratories = resServices.data.taskLaboratories;
      this.sectorId = resServices.data.sectorId;
      this.laboratoriesJoinedNames = this.taskLaboratories
        .map((taskLaboratoryItem) => { return taskLaboratoryItem.laboratory.isActive === true ? taskLaboratoryItem.laboratory.name : taskLaboratoryItem.laboratory.name + " (" + this.$t("general.deletedLabel") + ")"; })
        .join(", ");
      this.sectorLocalisedName = this.$t("sectors." + resServices.data.sector.name) + (!resServices.data.sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "");
      this.code = resServices.data.code;
      this.adicap = resServices.data.adicap;
      this.description = resServices.data.description;
      this.startValidityDate = resServices.data.startValidityDate;
      this.endValidityDate = resServices.data.endValidityDate;
      this.taskActs = resServices.data.taskActs;
      for (const taskAct of this.taskActs) {
        for (const additionalFee of taskAct.additionalFees) {
          additionalFee.price = this.importFormatPrice(additionalFee.price);
        }
      }
      this.content = resServices.data.content;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("data/tasks");
    }
  }
};
</script>

<template>
  <div
    class="kt-view-page kt-careSheet-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'general', 'careSheets'])"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("careSheetView.pageTitle", {id: careSheetId}) }}
      </h2>
      <!-- transformed badge -->
      <div
        v-show="careSheetData && careSheetData.isOriginFullyTransformed"
        class="kt-badge kt-badge--yellow ml-3"
      >
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fullyTransformed') }}</span></span>
      </div>
      <!-- from transformation badge -->
      <div
        v-show="careSheetData && careSheetData.fromTransformationsCount"
        class="kt-badge kt-badge--blue ml-3"
      >
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fromTransformation') }}</span></span>
      </div>
      <!-- documentStatuses -->
      <DocumentStatuses
        :dataProp="careSheetData"
        class="ml-3"
      />
      <!-- export pdf  -->
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'careSheets', 'toPdf'])"
        size="sm"
        class="kt-page-header__action ml-3"
        :variant="$systemSettings.theme"
        @click="onExport(careSheetData, 'pdf')"
      >
        <b-icon icon="download"></b-icon>
        {{ $t("general.downloadPdf") }}
      </b-button>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION - main -->
      <div
        v-if="laboratoryDisplay || invoiceDateDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- laboratory -->
        <b-row v-if="laboratoryDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("careSheets.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratory ? laboratory.name + (laboratory.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- invoiceDate -->
        <b-row v-if="invoiceDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("careSheets.invoiceDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ invoiceDate ? this.$d(new Date(invoiceDate), "date") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- organisation -->
        <b-row v-if="organisationDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("careSheets.organisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div class="kt-item-list">
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item mb-0">
                  <div class="kt-item-list__item-title pr-0">
                    <!-- name -->
                    {{ organisation ? organisation.name + (organisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
                  </div>
                  <div class="kt-item-list__item-text">
                    <!-- code -->
                    {{ organisation ? organisation.code : $t("general.emptyWithHyphen") }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- SECTION - file -->
      <div
        v-if="fileDetailsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- title -->
        <h3 class="kt-view-page__section-title">
          {{ $t("careSheets.sectionTitleFileDetails") }}
        </h3>

        <!-- accessNumber -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("careSheets.accessNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              <b-link
                :variant="$systemSettings.theme"
                :href="file ? '/office/file/view/' + file.id : '#'"
                @click.prevent="() => {
                  if (file) navigate('office/file/view', {
                    fileIdProp: file.id,
                    cancelRouteProp: {name: $route.name, props: $route.params}
                  })
                }"
              >
                {{ file ? file.accessNumber : $t("general.emptyWithHyphen") }}
                <!-- complementary -->
                <span
                  v-show="file && file.parentFileId"
                  class="kt-complementary-icon"
                >
                  <b-icon icon="back"></b-icon>
                </span>
              </b-link>
            </p>
          </b-col>
        </b-row>
        <!-- creationDate -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.creationDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file ? this.$d(new Date(file.creationDate), "date") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- pathologist -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.pathologist") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file && file.pathologist ? file.pathologist.user.firstName + " " + file.pathologist.user.lastName + (file.pathologist.user.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- sector -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.sector") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file ? $t('sectors.' + file.sector.name) + (!file.sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- patient -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.patient") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div class="kt-item-list">
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item mb-2">
                  <div class="kt-item-list__item-title pr-0">
                    <!-- name -->
                    <PersonName
                      :sexNameProp="file ? file.patient.sex.name : ''"
                      :firstNameProp="file ? file.patient.firstName : ''"
                      :maidenNameProp="file ? file.patient.maidenName : ''"
                      :lastNameProp="file ? file.patient.lastName : ''"
                    />
                  </div>
                  <div class="kt-item-list__item-text">
                    <!-- birthdate -->
                    <div v-show="file">
                      {{ file ? getFormattedBirthdateAge(file.patient.birthdate) : "" }}
                    </div>
                    <!-- view patient -->
                    <b-link
                      :variant="$systemSettings.theme"
                      :href="file ? '/office/patient/view/' + file.patient.id : '#'"
                      @click.prevent="() => {
                        if (file) navigate('office/patient/view', {patientIdProp: file.patient.id})
                      }"
                    >
                      {{ $t('general.view') }}
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- prescribing organisation -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.prescribingOrganisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file && file.prescribingOrganisation ? file.prescribingOrganisation.name + (file.prescribingOrganisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- prescriber -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.prescriber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file && file.fileContacts.length === 1 ? file.fileContacts[0].contact.firstName + " " + file.fileContacts[0].contact.lastName + (file.fileContacts[0].contact.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
        <!-- collectionDates -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.collectionDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ file ? file.collectionDates.join(", ") : $t("general.emptyWithHyphen") }}
            </p>
          </b-col>
        </b-row>
      </div>
      <!-- SECTION - fileInvoices > invoiceActs -->
      <div
        v-if="fileInvoicesDisplay"
        class="kt-view-page__section mb-0"
        :class="sectionMarginsClass"
      >
        <!-- title -->
        <h3 class="kt-view-page__section-title">
          {{ $t("careSheets.sectionTitleInvoiceActs") }}
        </h3>

        <!-- fileInvoices loop -->
        <div
          v-for="(fileInvoice, fileInvoiceIndex) in fileInvoices"
          :key="fileInvoice.id"
        >
          <!-- transformation badges -->
          <FileInvoiceTransformationBadge :fileInvoiceProp="fileInvoice" />
          <!-- acts table -->
          <div
            class="kt-flex-table kt-flex-table--large"
            style="margin-top: 2px"
          >
            <div
              class="kt-flex-table__wrapper"
              role="table"
              :aria-label="$t('invoiceReports.invoiceActs')"
            >
              <div
                class="kt-flex-table__line kt-flex-table__line--header"
                role="rowgroup"
              >
                <div
                  class="kt-flex-table__row first"
                  role="columnheader"
                >
                  {{ $t('careSheets.invoiceActCode') }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="columnheader"
                >
                  {{ $t('careSheets.invoiceActPrice') }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="columnheader"
                >
                  {{ $t('careSheets.invoiceActInvoiced') }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="columnheader"
                >
                  {{ $t('careSheets.invoiceActRatio') }}
                </div>
              </div>
              <div
                v-for="(invoiceAct, index) in fileInvoice.invoiceActs"
                :key="index"
                class="kt-flex-table__line kt-flex-table__line--regular"
                role="rowgroup"
              >
                <div
                  class="kt-flex-table__row first"
                  role="cell"
                >
                  {{ invoiceAct.act.code }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="cell"
                >
                  {{ $n(invoiceAct.act.price, "currency") }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="cell"
                >
                  {{ $n(invoiceAct.invoiced, "currency") }}
                </div>
                <div
                  class="kt-flex-table__row"
                  role="cell"
                >
                  <span :class="(invoiceAct.ratio === 1) ? 'text-success' : 'text-danger'">{{ invoiceAct.ratio ? $n(invoiceAct.ratio, "ratio") : $n(0, "ratio") }}</span>
                </div>
              </div>
            </div>
            <div
              v-show="fileInvoiceIndex === fileInvoices.length - 1"
              class="kt-flex-table__bottom"
            >
              <div class="kt-flex-table__amount-container">
                <span>{{ $t("careSheets.invoiced") }}</span><span class="kt-flex-table__amount">{{ $n(invoiced || 0, "currency") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// component
import PersonName from "@shared/views/Helpers/PersonName";
import FileInvoiceTransformationBadge from "@shared/views/Helpers/FileInvoiceTransformationBadge";
import DocumentStatuses from "@shared/views/Helpers/DocumentStatuses";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import invoicesHelpers from "@shared/services/UI/invoicesHelpers";
import viewComponents from "@shared/services/UI/viewComponents";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { FileInvoiceTransformationBadge, PersonName, DocumentStatuses },
  mixins: [userRights, error, date, invoicesHelpers, viewComponents],
  props: {
    careSheetIdProp: {
      type: Number,
      default: null
    },
    moduleModeProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "laboratory",
          "invoiceDate",
          "organisation",
          "fileDetails",
          "fileInvoices"
        ];
      }
    }
  },
  data() {
    return {
      // general
      careSheetId: this.careSheetIdProp,
      careSheetData: null,
      // base data
      laboratory: null,
      organisation: null,
      invoiceDate: "",
      fileInvoices: [],
      invoiced: null,
      file: null,

      // dispayFields
      laboratoryDisplay: false,
      organisationDisplay: false,
      invoiceDateDisplay: false,
      fileDetailsDisplay: false,
      fileInvoicesDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get careSheet
      const resServices = await commonServices.get("careSheets", this.careSheetId);
      this.careSheetData = this.importFormatInvoice(resServices.data, "careSheet");
      // assign the data
      this.laboratory = this.careSheetData.laboratory;
      this.organisation = this.careSheetData.organisation;
      this.invoiceDate = this.careSheetData.invoiceDate;
      this.fileInvoices = this.careSheetData.fileInvoices;
      this.invoiced = this.careSheetData.invoiced;
      this.file = this.careSheetData.file;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("accounting/invoices/careSheets");
    },
    // export
    async onExport(row, type) {
      try {
        const resFile = await commonServices.export("careSheets", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.file.accessNumber + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./CareSheetView.scss" lang="scss"></style>

<template>
  <div class="kt-payments mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("reports.compensationsReport.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- search form -->
    <ReportsSearchForm
      :laboratoryId="laboratoryId"
      :organisationType="organisationType"
      :organisation="organisation"
      :startDate="startDate"
      :endDate="endDate"
      :displayBalanceDateProp="false"

      displayAllOrganisationsOptionProp

      :isValidationActive="searchFormValidation"

      @inputLaboratoryId="laboratoryId = $event; searchHasChanged = true;"
      @InputOrganisationType="organisationType = $event; searchHasChanged = true;"
      @inputOrganisation="organisation = $event; searchHasChanged = true;"
      @inputStartDate="startDate = $event; searchHasChanged = true;"
      @inputEndDate="endDate = $event; searchHasChanged = true;"

      @updateState="searchFormState = $event"
      @submit="onPageInput"
    />

    <!-- divider -->
    <div class="w-100 border-top mt-4 mb-4"></div>

    <!-- result (totals) -->
    <div
      v-if="filePayments && filePayments.length"
      class="kt-item-list kt-item-list--full-width kt-item-list--shadow"
    >
      <div class="kt-item-list__item-wrapper">
        <div
          class="kt-item-list__item mb-0"
          style="padding: 20px;"
        >
          <div class="d-flex justify-content-around align-items-center text-center">
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.compensationsReport.lossesCount") }}</span>
              <br /><span>{{ lossesCount }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.compensationsReport.lossesTotal") }}</span>
              <br /><span>{{ $n(-(lossesTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.compensationsReport.gainsCount") }}</span>
              <br /><span>{{ gainsCount }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.compensationsReport.gainsTotal") }}</span>
              <br /><span>{{ $n(-(gainsTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.compensationsReport.totalBalance") }}</span>
              <br /><span>{{ $n(-(totalBalance || 0) / 100, "currency") }}</span>
            </h4>

            <div class="ml-3">
              <b-button
                :variant="$systemSettings.theme"
                :disabled="searchHasChanged"
                @click="onExport('xlsx')"
              >
                {{ $t("general.downloadXlsx") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-table
      class="mt-4 mb-0 kt-table"
      hover
      :fields="fieldsFormatted"
      :items="filePayments"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- OrganisationCode -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter CompensationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCompensationDate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter HospitalisationNumber -->
          <b-th
            v-show="organisation || organisationType !== null"
            class="kt-table__th-search"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter CreationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCreationDate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter Patient -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 120px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-button
              ref="filterPatientChoose"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :style="filterPatient === null ? 'padding-left: 34px;' : ''"
              :variant="'outline-' + $systemSettings.theme"
              @click="$bvModal.show('patientChooseModal-' + _uid)"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            >
              <!-- search icon -->
              <b-icon
                v-show="filterPatient === null"
                class="kt-table__th-search-input__search-icon"
                icon="search"
              ></b-icon>
              <!-- value -->
              <span class="kt-table__th-search-input__text-overflow">{{ filterPatient ? filterPatient.firstName + ' ' + filterPatient.lastName : '' }}</span>
            </b-button>
            <b-button
              v-show="filterPatient"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterPatient = null;
                searchHasChanged = true;
                $refs.filterPatientChoose.focus()
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Pathologist -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="pathologists"
              searchedFieldProp="user.lastName"
              :searchedLaboratoriesProp="null"
              :returnFieldsArrayProp="['id', 'user.lastName', 'user.firstName', 'user.isActive', 'code']"
              suggestionsKeyProp="id"

              :valueProp="filterPathologist"
              :displayedValueProp="filterPathologist ? filterPathologist.user.firstName + ' ' + filterPathologist.user.lastName + ' (' + filterPathologist.code + ')' + (filterPathologist.user.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.user.firstName + ' ' + suggestion.user.lastName + ' (' + suggestion.code + ')' + (suggestion.user.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
              }"
              noWrapProp

              @onUpdateParent="onPathologistChosen"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter Contact -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="contacts"
              searchedFieldProp="lastName"
              :searchedLaboratoriesProp="null"
              :returnFieldsArrayProp="['id', 'firstName', 'lastName', 'code', 'isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterContact"
              :displayedValueProp="filterContact ? filterContact.firstName + ' ' + filterContact.lastName + ' (' + filterContact.code + ')' + (filterContact.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.firstName + ' ' + suggestion.lastName + ' (' + suggestion.code + ')' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
              }"
              noWrapProp

              @onUpdateParent="onContactChosen"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- accessNumber -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          <b-link
            :variant="$systemSettings.theme"
            class=""
            :href="data.item.fileId ? '/office/file/view/' + data.item.fileId : '#'"
            @click.prevent="navigate('office/file/view', {
              fileIdProp: data.item.fileId,
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            {{ data.item.file.accessNumber }}
            <!-- complementary -->
            <span
              v-show="data.item.file.parentFileId"
              style="font-size: 14px; margin: 0 0 1px 3px; color: rgb(59, 72, 83);"
            >
              <b-icon icon="back"></b-icon>
            </span>
          </b-link>
        </div>
      </template>

      <!-- patientName -->
      <template v-slot:cell(patientName)="data">
        <PersonName
          :sexNameProp="data.item.file.patient.sex ? data.item.patient.sex.name : ''"
          :firstNameProp="data.item.file.patient.firstName"
          :lastNameProp="data.item.file.patient.lastName"
          :maidenNameProp="data.item.file.patient.maidenName ? data.item.file.patient.maidenName : ''"
        />
      </template>

      <!-- fileContacts -->
      <template v-slot:cell(fileContacts)="data">
        <div
          v-for="fileContact in data.item.file.fileContacts"
          :key="fileContact.id"
        >
          {{ fileContact.contact.firstName + " " + fileContact.contact.lastName }}
          <b-icon
            v-if="fileContact.isPrescriber"
            class="kt-prescriber-tag"
            icon="pencil-square"
          />
        </div>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 39px;">
          <!-- view -->
          <b-button
            class=""
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/file/view', {
              fileIdProp: data.item.fileId,
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="(!filePayments || !filePayments.length) && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import ReportsSearchForm from "@/views/Accounting/Reports/ReportsSearchForm";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import PersonName from "@shared/views/Helpers/PersonName";
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
import compensationsReportsServices from "@/services/API/compensationsReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { ReportsSearchForm, PatientChoose, PersonName, InputSearch },
  mixins: [userRights, error, date, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      exportLoading: false,
      searchHasChanged: false,
      maxReached: false,
      // search filters
      laboratoryId: null,
      organisationType: null,
      organisation: null,
      startDate: "",
      endDate: "",
      // validation
      searchFormState: true,
      searchFormValidation: false,
      // totals
      gainsCount: 0,
      lossesCount: 0,
      gainsTotal: 0,
      lossesTotal: 0,
      totalBalance: 0,
      // table filters
      filterAccessNumber: "",
      filterHospitalisationNumber: "",
      filterCreationDate: "",
      filterPatient: null,
      filterPathologist: null,
      filterContact: null,
      filterCompensationDate: "",
      patientInitialFilterValues: {},
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoicedOrganisation",
          sortable: true,
          label: this.$t("reports.compensationsReport.invoicedOrganisationShort"),
          formatter: (_value, _key, item) => {
            if (item.file.invoicedOrganisation) {
              return this.organisationTypeDictionnary[item.file.invoicedOrganisation.organisationTypeId] + " - " + item.file.invoicedOrganisation.code;
            } else {
              return this.$t("organisationType.patients");
            }
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "compensationDate",
          sortable: true,
          label: this.$t("reports.compensationsReport.compensationDate"),
          formatter: (_value, _key, item) => {
            return this.$d(new Date(item.compensation.date), "date");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort"),
          formatter: (_value, _key, item) => {
            return item.file.accessNumber;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort"),
          formatter: (_value, _key, item) => {
            return item.file.hospitalisationNumber !== "" ? item.file.hospitalisationNumber : this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "creationDate",
          sortable: true,
          label: this.$t("files.creationDateShort"),
          formatter: (_value, _key, item) => {
            return item.file.creationDate ? this.$d(new Date(item.file.creationDate), "date") : "";
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "patientName",
          sortable: true,
          label: this.$t("reports.sales.patientName"),
          formatter: (_value, _key, item) => {
            return item.file.patient.lastName;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "pathologist",
          sortable: true,
          label: this.$t("files.pathologist"),
          formatter: (_value, _key, item) => {
            let output = "";
            if (item.file.pathologist) {
              output = item.file.pathologist.user.firstName + " " + item.file.pathologist.user.lastName + " (" + item.file.pathologist.code + ")";
              if (item.file.pathologist.user.isActive === false) {
                output += " (" + this.$t("general.deletedLabel") + ")";
              }
            }
            return output;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "fileContacts",
          sortable: true,
          label: this.$t("files.prescriberAndRecipients"),
          formatter: (_value, _key, item) => {
            let output = "";
            if (item.file.fileContacts) {
              output = item.file.fileContacts.map((fileContact) => {
                return fileContact.contact.lastName;
              }).join(", ");
            }
            return output;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("reports.compensationsReport.paid"),
          formatter: (_value, _key, item) => {
            return this.$n((-item.compensation.paid || 0) / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      // table items
      filePayments: [],
      // saveParamsConfig
      saveParamsConfig: {
        laboratoryId: "number",
        organisationType: "string",
        organisation: { id: "number", code: "string", name: "string" },
        startDate: "string",
        endDate: "string",
        filterAccessNumber: "string",
        filterHospitalisationNumber: "string",
        filterCreationDate: "string",
        filterPatient: {
          id: "number",
          firstName: "string",
          lastName: "string",
          maidenName: "string"
        },
        filterPathologist: {
          id: "number",
          user: {
            firstName: "string",
            lastName: "string"
          }
        },
        filterContact: {
          id: "number",
          code: "string",
          firstName: "string",
          lastName: "string"
        },
        filterCompensationDate: "string"
      }
    };
  },
  computed: {
    fieldsFormatted: function() {
      return this.fields.filter((v) => {
        if (v.key === "hospitalisationNumber") {
          return this.organisation || this.organisationType !== null;
        } else {
          return true;
        }
      });
    },
    // options
    organisationTypeDictionnary: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.organisationTypes || []));
      return options.reduce((o, key) => ({ ...o, [key.id]: this.$t("organisationType." + key.name) }), {});
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;
  },
  methods: {
    // patient
    async onPatientChosen(patient) {
      this.filterPatient = patient ? { id: patient.id, firstName: patient.firstName, lastName: patient.lastName, maidenName: patient.maidenName } : null;
      this.searchHasChanged = true;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
    },
    // contact
    async onContactChosen(contact) {
      this.filterContact = contact ? { id: contact.id, firstName: contact.firstName, lastName: contact.lastName, code: contact.code } : null;
      this.searchHasChanged = true;
    },
    // pathologist
    onPathologistChosen(pathologist) {
      this.filterPathologist = pathologist ? { id: pathologist.id, user: { firstName: pathologist.user.firstName, lastName: pathologist.user.lastName } } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },
    // submit functions
    validateForm() {
      this.searchFormValidation = true;

      return (
        this.searchFormState !== false
      );
    },
    async onPageInput() {
      try {
        // validation
        if (this.validateForm() === false) return false;
        // validation dates
        if (this.filterFileCreationDate !== "") {
          const filterFileCreationDate = new Date(this.filterFileCreationDate).getFullYear();
          if (filterFileCreationDate < 1900 || filterFileCreationDate > 2300) {
            return false;
          }
        }
        if (this.startDate !== "") {
          const startDate = new Date(this.startDate).getFullYear();
          if (startDate < 1900 || startDate > 2300) {
            return false;
          }
        }
        if (this.endDate !== "") {
          const endDate = new Date(this.endDate).getFullYear();
          if (endDate < 1900 || endDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const res = await commonServices.getAll(
          "compensationsReports",
          {
            laboratoryId: this.laboratoryId,
            organisationType: !this.organisation ? this.organisationType : null,
            organisationId: this.organisation ? this.organisation.id : null,
            startDate: this.startDate,
            endDate: this.endDate,

            accessNumber: this.filterAccessNumber,
            hospitalisationNumber: this.organisation || this.organisationType !== null ? this.filterHospitalisationNumber : "",
            fileCreationDate: this.filterCreationDate || null,
            patientId: this.filterPatient ? this.filterPatient.id : null,
            pathologistId: this.filterPathologist ? this.filterPathologist.id : null,
            contactId: this.filterContact ? this.filterContact.id : null,
            compensationDate: this.filterCompensationDate
          }
        );
        // assign result
        this.filePayments = res.data.rows;
        this.maxReached = res.data.maxReached;
        this.gainsCount = res.data.gainsCount;
        this.lossesCount = res.data.lossesCount;
        this.gainsTotal = res.data.gainsTotal;
        this.lossesTotal = res.data.lossesTotal;
        this.totalBalance = res.data.totalBalance;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    async onExport(type) {
      try {
        // validation
        if (this.validateForm() === false) return false;
        // validation dates
        if (this.filterFileCreationDate !== "") {
          const filterFileCreationDate = new Date(this.filterFileCreationDate).getFullYear();
          if (filterFileCreationDate < 1900 || filterFileCreationDate > 2300) {
            return false;
          }
        }
        if (this.startDate !== "") {
          const startDate = new Date(this.startDate).getFullYear();
          if (startDate < 1900 || startDate > 2300) {
            return false;
          }
        }
        if (this.endDate !== "") {
          const endDate = new Date(this.endDate).getFullYear();
          if (endDate < 1900 || endDate > 2300) {
            return false;
          }
        }

        this.exportLoading = true;
        const organisationTypeFormatted = this.$t("organisationTypePlural." + (this.organisationType || "patients")).toLowerCase();
        let organisationFormatted = this.organisation ? this.organisation.code : organisationTypeFormatted;
        organisationFormatted = organisationFormatted.replace(/ /g, "-");

        const resFile = await compensationsReportsServices.generate(
          {
            laboratoryId: this.laboratoryId,
            organisationType: !this.organisation ? this.organisationType : null,
            organisationId: this.organisation ? this.organisation.id : null,
            startDate: this.startDate,
            endDate: this.endDate,

            accessNumber: this.filterAccessNumber,
            hospitalisationNumber: this.organisation || this.organisationType !== null ? this.filterHospitalisationNumber : "",
            fileCreationDate: this.filterCreationDate || null,
            patientId: this.filterPatient ? this.filterPatient.id : null,
            pathologistId: this.filterPathologist ? this.filterPathologist.id : null,
            contactId: this.filterContact ? this.filterContact.id : null,
            compensationDate: this.filterCompensationDate
          },
          type
        );

        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, this.$t("reports.compensationsReport.fileTitle") + "_" + organisationFormatted + "_" + this.startDate + "_" + this.endDate + "." + type);
        }
        this.exportLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

import { saveParamsInRouteQuery } from "@/services/UI/vueRouterServices";

export default {
  data() {
    return {};
  },
  computed: {
    // common
    paramsValues: function() {
      const filterList = Object.keys(this.saveParamsConfig);
      return Object.assign(...filterList.map((k) => ({ [k]: this[k] })));
    }
  },
  watch: {
    // common
    paramsValues: {
      handler: function(val) {
        // query filters
        saveParamsInRouteQuery(val);
      },
      deep: true
    }
  },
  beforeMount() {
    // query filters
    this.handleQueryFilters();
  },
  methods: {
    // query filters
    handleQueryFilters() {
      const sanitized = this.getValidatedQuery(this.$route.query, this.saveParamsConfig);
      this.importParamsFromObj(sanitized);
    },
    // query filters
    getValidatedQuery(values, rules, allRequired = false) {
      const valuesKeys = values ? Object.keys(values) : [];
      const rulesKeys = Object.keys(rules);
      // validate empty or null
      if (typeof values !== "object" || !rules || !values || !rulesKeys.length || !valuesKeys.length) {
        return {};
      }
      // values must have at least the necessary keys
      const hasKeys = rulesKeys.reduce((res, key) => {
        return res === false ? res : (typeof values[key] !== "undefined");
      }, true);
      if (allRequired && !hasKeys) {
        return {};
      }

      for (const valuesKey of valuesKeys) {
        const value = values[valuesKey];
        const rule = rules[valuesKey];
        if (!rule) {
          delete values[valuesKey];
          continue;
        }
        switch (rule) {
          case "string":
            if (typeof value !== "string" && !(value instanceof String)) {
              delete values[valuesKey];
            }
            break;
          case "number":
            values[valuesKey] = Number(value);
            if (isNaN(values[valuesKey])) {
              delete values[valuesKey];
            }
            break;
          case "boolean":
            if (value === "0") {
              values[valuesKey] = false;
            } else if (value === "1") {
              values[valuesKey] = true;
            } else {
              delete values[valuesKey];
            }
            break;
          case "arrayOfStrings":
            if (typeof value === "string") {
              values[valuesKey] = JSON.parse(value);
            }
            if (!Array.isArray(values[valuesKey]) || values[valuesKey].length === 0) {
              delete values[valuesKey];
            } else {
              const isValid = values[valuesKey].reduce((valid, v) => { return !valid ? valid : (typeof v === "string" || v instanceof String); }, true);
              if (!isValid) {
                delete values[valuesKey];
              }
            }
            break;
          case "arrayOfNumbers":
            if (typeof value === "string") {
              values[valuesKey] = JSON.parse(value);
            }
            if (!Array.isArray(values[valuesKey]) || values[valuesKey].length === 0) {
              delete values[valuesKey];
            } else {
              const isValid = values[valuesKey].reduce((valid, v) => { return !valid ? valid : !isNaN(v); }, true);
              if (!isValid) {
                delete values[valuesKey];
              }
            }
            break;
          default:
            if (typeof value === "string") {
              values[valuesKey] = JSON.parse(value);
            }
            // object
            if (typeof values[valuesKey] === "object") {
              values[valuesKey] = this.getValidatedQuery(values[valuesKey], rule, true);
              if (Object.keys(values[valuesKey]).length === 0) {
                delete values[valuesKey];
              }
            } else {
              // rule has no match
              delete values[valuesKey];
            }
        }
      }

      return values;
    },
    // common
    importParamsFromObj(params) {
      if (!params || Object.keys(params).length === 0) {
        return false;
      }

      const filterList = Object.keys(this.saveParamsConfig);
      for (const name of filterList) {
        if (params[name]) {
          this[name] = params[name];
        }
      }
    }
  }
};

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  showAll(page, filter) {
    return axiosServices.axios.get("/statistics/showAll", {
      params: {
        page: page,
        filter: filter
      }
    });
  }
};

<template>
  <div class="systemSettings">
    <div class="d-flex justify-content-center">
      <label
        class="m-2"
        style="text-align: right"
      >{{ $t("systemSettings.temporaryDirectory") }}</label>
      <b-form-input
        ref="temporaryDirectory"
        v-model="temporaryDirectory"
        class="m-2 w-25"
        @change="onDirectoryChange('temporaryDirectory', temporaryDirectory)"
      ></b-form-input>
      <label
        class="m-2"
        style="text-align: right"
      >{{ $t("systemSettings.persistentDirectory") }}</label>
      <b-form-input
        ref="persistentDirectory"
        v-model="persistentDirectory"
        class="m-2 w-25"
        @change="onDirectoryChange('persistentDirectory', persistentDirectory)"
      ></b-form-input>
    </div>
  </div>
</template>

<script>
import { navigate } from "@/services/UI/vueRouterServices";
import systemSettingsServices from "@shared/services/API/systemSettingsServices";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  mixins: [userRights, error],
  props: {
  },
  data: function() {
    return {
      temporaryDirectory: "",
      persistentDirectory: ""
    };
  },
  created: function() {
    this.availableLocales = this.$i18n.availableLocales;
  },
  mounted: async function() {
    try {
      var res = await systemSettingsServices.get("temporaryDirectory");
      this.temporaryDirectory = res.data.value;
      res = await systemSettingsServices.get("persistentDirectory");
      this.persistentDirectory = res.data.value;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onRoute(routeName) {
      navigate(routeName);
    },
    async onDirectoryChange(directory, value) {
      try {
        const res = await systemSettingsServices.put(directory, value);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("systemSettings.edited.title"),
            message: this.$t("systemSettings.edited.text")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("systemSettings.editionError.title"),
            message: this.$t("systemSettings.editionError.text")
          });
        }
        this.$refs[directory].blur();
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

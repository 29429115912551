<template>
  <div class="kt-automatic-actions">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("systemSettings.automaticActions.pageTitle") }}
      </h2>
    </div>

    <!-- table -->
    <b-table
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="automaticActions"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <!-- isActive -->
      <template v-slot:cell(isActive)="data">
        <span
          v-show="data.item['isActive']"
          class="kt-table__cell-check kt-table__cell-check--success"
        >
          <b-icon icon="check2"></b-icon>
        </span>
        <span
          v-show="!data.item['isActive']"
          class="kt-table__cell-check kt-table__cell-check--danger"
        >
          <b-icon icon="x"></b-icon>
        </span>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(automaticActionButtons)="data">
        <div style="width: 131px;">
          <!-- view -->
          <b-button
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            :disabled="data.item.isLoading"
            @click="navigate('system/systemSettings/automaticAction/view', {automaticActionIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            class="mx-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            :disabled="data.item.isLoading"
            @click="navigate('system/systemSettings/automaticAction/edit', {automaticActionIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete  -->
          <b-button
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            :disabled="data.item.isLoading"
            @click="onDeleteAutomaticAction(data.item)"
          >
            <b-icon
              v-show="!data.item.isLoading"
              icon="trash"
            ></b-icon>
            <b-spinner
              v-show="data.item.isLoading"
              class="align-middle spinner-border-xs kt-spinner--bg-transparent"
            ></b-spinner>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!automaticActionsCount && !loading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar>
        <b-navbar-nav>
          <b-pagination
            v-show="automaticActionsCount"
            v-model="page"
            class="mx-2 my-3"
            :class="'kt-pagination--' + $systemSettings.theme"
            :total-rows="automaticActionsCount"
            per-page="50"
            @input="onPageInput"
          ></b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, pagination, error, date],
  data() {
    return {
      // general
      loading: false,
      page: 1,
      automaticActionsCount: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "name",
          sortable: true,
          label: this.$t("systemSettings.automaticActions.name")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "rule",
          sortable: false,
          label: this.$t("systemSettings.automaticActions.rule")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "parameters",
          sortable: false,
          label: this.$t("systemSettings.automaticActions.parameters")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "isActive",
          sortable: true,
          label: this.$t("systemSettings.automaticActions.isActive")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "lastExecutionTimestamp",
          sortable: false,
          label: this.$t("systemSettings.automaticActions.lastExecutionTimestamp"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true, true) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "nextInvocation",
          sortable: false,
          label: this.$t("systemSettings.automaticActions.nextInvocation"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true, true) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "automaticActionButtons",
          sortable: false,
          label: ""
        }
      ],
      // table items
      automaticActions: []
    };
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.onPageInput();
  },
  methods: {
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },
    // searchAndUpdateTable
    async onPageInput() {
      try {
        this.loading = true;

        const res = await commonServices.showAll(
          "automaticActions",
          this.page,
          {},
          {
            by: this.sortBy,
            desc: this.sortDesc
          }
        );
        this.automaticActionsCount = res.data.count;
        this.automaticActions = res.data.rows.map((v) => {
          v.isLoading = false;
          return v;
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onPageInput();
      }, 450);
    },
    async onDeleteAutomaticAction(row) {
      if (!row || row.isLoading) return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("systemSettings.automaticActionDelete.text"), {
        title: this.$t("systemSettings.automaticActionDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("systemSettings.automaticActionDelete.ok"),
        cancelTitle: this.$t("systemSettings.automaticActionDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        row.isLoading = true;
        try {
          const res = await commonServices.delete("automaticActions", row.id);
          if (res.data === true) {
            this.$emit("alert", "success", {
              title: this.$t("systemSettings.automaticActionDelete.notifications.deletedTitle"),
              message: this.$t("systemSettings.automaticActionDelete.notifications.deletedText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("systemSettings.automaticActionDelete.notifications.deletedErrorTitle"),
              message: this.$t("systemSettings.automaticActionDelete.notifications.deletedErrorText")
            });
          }
        } catch (err) {
          this.handleErrors(err);
        }
        row.isLoading = false;
        await this.onPageInput();
      }
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kt-histologyShortCode-edit pb-4"},[_c('div',{staticClass:"kt-page-header mt-4 pt-1 mb-3"},[_c('b-button',{staticClass:"mr-3 kt-page-header__back-btn",attrs:{"size":"sm","variant":"light"},on:{"click":_vm.onGoBack}},[_c('b-icon',{attrs:{"icon":"chevron-left"}})],1),_c('h2',{staticClass:"kt-page-header__title h4 mb-0"},[_vm._v(" "+_vm._s(_vm.pageTitleStringFormatted)+" ")])],1),_c('div',{staticClass:"w-100 border-top mt-1 mb-4"}),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSave.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('histologyShortCodes.code'),"invalid-feedback":_vm.codeInvalidFeedback,"state":_vm.codeState}},[_c('b-form-input',{ref:"codeInput",attrs:{"trim":"","maxlength":"50","state":_vm.codeState},on:{"blur":function($event){_vm.codeValidation = true}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('histologyShortCodes.name'),"invalid-feedback":_vm.nameInvalidFeedback,"state":_vm.nameState}},[_c('b-form-input',{attrs:{"trim":"","maxlength":"256","state":_vm.nameState},on:{"blur":function($event){_vm.nameValidation = true}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('histologyShortCodes.content'),"invalid-feedback":_vm.contentInvalidFeedback,"state":_vm.contentState}},[_c('quillEditor',{ref:"quillEditor",attrs:{"options":{
              placeholder: _vm.$t('histologyShortCodes.contentPlaceholder'),
              modules: {
                toolbar: [
                  [{'header': [1, 2, 3, false]}],
                  ['bold', 'italic', 'underline'],
                  [{'color': []}, {'background': []}],
                  [{'align': [false, 'right', 'center']}],
                  [{'list': 'ordered'}, {'list': 'bullet'}],
                  [{'script': 'super'}],
                  ['blockquote']
                ]
              },
              formats: ['background', 'bold', 'color', 'italic', 'size', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'formula'],
              readOnly: true,
              theme: 'snow'
            }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"my-2",attrs:{"variant":_vm.$systemSettings.theme,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.finishedButtonStringFormatted)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="kt-fi-transformation-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("fileInvoiceTransformationNew.pageTitle") }}
      </h2>
    </div>

    <!-- transformation form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-show="file === null"
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="fileInvoiceTransformationData !== null ? fileInvoiceTransformationData.laboratoryId : null"
            @input="laboratoryId = $event;"
          />
        </b-col>

        <!-- search file (InputSearchInvoicedFile) -->
        <b-col
          cols="12"
          md="4"
        >
          <InputSearchInvoicedFile
            ref="searchFileInput"
            class="mb-3"

            :laboratoryIdProp="laboratoryId"
            :fileProp="file"

            :labelProp="file === null ? $t('fileInvoiceTransformations.accessNumber') : $t('fileInvoiceTransformations.file')"
            :stateProp="fileState"
            :invalidFeedbackProp="fileInvalidFeedback"
            :disabledProp="false"
            :allowComplementarySelectionProp="false"

            @onUpdateParent="onFileChange($event)"
            @onUpdateValidation="fileValidation = $event"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- file view -->
        <b-col
          v-if="file !== null"
          cols="12"
        >
          <div class="kt-item-list kt-item-list--full-width kt-item-list--shadow">
            <div
              class="kt-item-list__item-wrapper"
            >
              <div
                class="kt-item-list__item"
                style="padding: 20px; margin-bottom: 16px;"
              >
                <FileView
                  moduleModeProp
                  displayForAccountingProp
                  :fileIdProp="file.id"
                  :fileDataProp="file"
                  @alert="(variant, strings) => $emit('alert', variant, strings)"
                />
              </div>
            </div>
          </div>
        </b-col>

        <!-- transformation options -->
        <b-col
          v-if="file !== null"
          cols="12"
        >
          <div class="kt-divided-information text-15 w-100 mb-3 mt-2">
            <div class="kt-divided-information__wrapper">
              <!----------------------- ORIGIN ----------------------->
              <div class="kt-divided-information__section py-3">
                <h5 class="border-bottom mb-2 pb-2">
                  {{ $t("fileInvoiceTransformations.originTitle") }}
                </h5>
                <div>
                  <FileInvoiceTransformationFileInvoiceItem
                    v-for="(fileInvoice, index) in file.fileInvoices"
                    :key="fileInvoice.id"
                    :fileProp="file"
                    :fileInvoiceProp="fileInvoice"
                    :selectedListProp="fileInvoicesSelectedList"
                    :isfirstItemInListProp="index === 0"
                    @select="onSelectFileInvoice(fileInvoice.id, $event)"
                  />
                </div>
                <!-- complementary files -->
                <div
                  v-if="file.childrenFiles && file.childrenFiles.length"
                >
                  <div
                    v-for="childrenFile in file.childrenFiles"
                    :key="childrenFile.id"
                  >
                    <h5 class="border-top mt-2 pt-2 mb-0 text-15 bg-light">
                      {{ $t("fileInvoiceTransformations.childrenFileTitle") }}
                    </h5>
                    <div class="border-bottom mb-2 pb-2 text-13 bg-light">
                      {{ $d(new Date(childrenFile.creationDate), "date") }}
                    </div>
                    <FileInvoiceTransformationFileInvoiceItem
                      v-for="(fileInvoice, index) in childrenFile.fileInvoices"
                      :key="fileInvoice.id"
                      :fileProp="file"
                      :fileInvoiceProp="fileInvoice"
                      :selectedListProp="fileInvoicesSelectedList"
                      :isfirstItemInListProp="index === 0"
                      @select="onSelectFileInvoice(fileInvoice.id, $event)"
                    />
                    <div v-if="!childrenFile.fileInvoices || !childrenFile.fileInvoices.length">
                      <span class="text-dark text-15 pb-3">
                        {{ $t('accountingHistory.emptyInvoice') }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- required error -->
                <div
                  v-show="fileInvoicesState === false"
                  class="text-15 text-danger mt-2"
                >
                  {{ fileInvoicesInvalidFeedback }}
                </div>
              </div>
              <!----------------------- TARGET ----------------------->
              <div class="kt-divided-information__section border-left py-3">
                <h5 class="border-bottom mb-3 pb-2">
                  {{ $t("fileInvoiceTransformations.targetTitle") }}
                </h5>
                <!-- targetOrganisationType -->
                <b-button-group
                  class="kt-btn-group mb-2 pb-1"
                >
                  <b-button
                    class="kt-btn-group__btn"
                    :class="(targetOrganisationType === 'organisation') ? 'kt-btn-group__btn--active' : ''"
                    variant="light"
                    @click="onTargetOrganisationType('organisation')"
                  >
                    <b-icon icon="house"></b-icon>
                    {{ $t("fileInvoiceTransformations.organisation") }}
                  </b-button>
                  <b-button
                    class="kt-btn-group__btn"
                    :class="(targetOrganisationType === 'patient') ? 'kt-btn-group__btn--active' : ''"
                    variant="light"
                    @click="onTargetOrganisationType('patient')"
                  >
                    <b-icon icon="people"></b-icon>
                    {{ $t("fileInvoiceTransformations.patient") }}
                  </b-button>
                  <b-button
                    class="kt-btn-group__btn"
                    :class="(targetOrganisationType === 'healthCare') ? 'kt-btn-group__btn--active' : ''"
                    variant="light"
                    @click="onTargetOrganisationType('healthCare')"
                  >
                    <b-icon icon="building"></b-icon>
                    {{ $t("fileInvoiceTransformations.healthCare") }}
                  </b-button>
                </b-button-group>

                <!-- IF__ targetOrganisationType : Patient -->
                <div
                  v-if="targetOrganisationType === 'patient'"
                  class="mb-3"
                >
                  <!-- patient info -->
                  <div class="kt-item-list">
                    <div
                      class="kt-item-list__item-wrapper"
                    >
                      <div class="kt-item-list__item mb-0">
                        <div class="kt-item-list__item-title pr-0">
                          <!-- patient name -->
                          <PersonName
                            :sexNameProp="file ? file.patient.sex.name : ''"
                            :firstNameProp="file ? file.patient.firstName : ''"
                            :lastNameProp="file ? file.patient.lastName : ''"
                            :maidenNameProp="file ? file.patient.maidenName : ''"
                          />
                        </div>
                        <div class="kt-item-list__item-text">
                          <!-- birthdate -->
                          {{ getFormattedBirthdateAge(file.patient.birthdate) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- edit patient button -->
                  <div class="mt-1">
                    <b-button
                      ref="editPatientButton1"
                      v-b-modal="'patientEditModal'"
                      :variant="$systemSettings.theme"
                      class="kt-btn-transparent pl-0 pt-1 pb-1 mb-n2"
                    >
                      <b-icon icon="pen"></b-icon>
                      {{ $t('fileInvoiceTransformationNew.editPatientButton') }}
                    </b-button>
                  </div>
                </div>
                <!-- IF__ targetOrganisationType : HealthCare -->
                <div
                  v-if="targetOrganisationType === 'healthCare'"
                  class="mb-3"
                >
                  <!-- healthCare Name -->
                  <div
                    v-show="targetOrganisation"
                    class="kt-item-list"
                  >
                    <div class="kt-item-list__item-wrapper">
                      <div class="kt-item-list__item mb-0">
                        <div class="kt-item-list__item-title pr-0">
                          <!-- organisation name -->
                          <div>
                            {{
                              targetOrganisation ?
                                targetOrganisation.name + (targetOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') :
                                ''
                            }}
                          </div>
                        </div>
                        <div class="kt-item-list__item-text">
                          <!-- code -->
                          {{ targetOrganisation ? targetOrganisation.code : '' }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- edit patient button -->
                  <div class="mt-1">
                    <b-button
                      ref="editPatientButton2"
                      v-b-modal="'patientEditModal'"
                      :variant="$systemSettings.theme"
                      class="kt-btn-transparent pl-0 pt-1 pb-1 mb-n2"
                    >
                      <b-icon icon="pen"></b-icon>
                      {{ $t('fileInvoiceTransformationNew.editPatientButton') }}
                    </b-button>
                  </div>
                </div>
                <!-- IF__ targetOrganisationType : Organisation -->
                <div
                  v-if="targetOrganisationType === 'organisation'"
                  class="mb-3"
                >
                  <InputSearch
                    v-show="!targetOrganisation"
                    searchedTableProp="organisations"
                    searchedFieldProp="name"
                    :organisationTypeNamesProp="['clinics', 'healthCenters', 'hospitals']"
                    :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
                    suggestionsKeyProp="id"
                    :labelProp="$t('payments.healthCarePayments.organisation')"
                    :valueProp="targetOrganisation"
                    :displayedValueProp="suggestionFormatOrganisation(targetOrganisation)"
                    :suggestionFormatProp="suggestionFormatOrganisation"

                    chooseComponentPathProp="views/Data/Organisations/OrganisationChoose.vue"
                    :chooseOptionsProp="{filterIsPrescribing: true}"
                    :initialFilterValuesProp="{}"

                    :stateProp="targetOrganisationState"
                    :invalidFeedbackProp="targetOrganisationInvalidFeedback"
                    @onUpdateParent="targetOrganisation = $event"
                    @onActivateValidation="targetOrganisationValidation = true"
                    @alert="(variant, strings) => $emit('alert', variant, strings)"
                  ></InputSearch>
                  <!-- organisation Name -->
                  <div
                    v-show="targetOrganisation"
                    class="kt-item-list"
                  >
                    <div class="kt-item-list__item-wrapper">
                      <div class="kt-item-list__item mb-0">
                        <div class="kt-item-list__item-title">
                          <!-- organisation name -->
                          <div>
                            {{ targetOrganisation ? targetOrganisation.name + (targetOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '' }}
                          </div>
                        </div>
                        <div class="kt-item-list__item-text">
                          <!-- code -->
                          {{ targetOrganisation ? targetOrganisation.code : '' }}
                        </div>
                        <!-- actions -->
                        <div class="kt-item-list__item-actions">
                          <!-- delete organisation Button -->
                          <b-button
                            class="btn-icon kt-item-list__item-delete"
                            size="sm"
                            variant="light"
                            squared
                            @click="targetOrganisation = null;"
                          >
                            <b-icon icon="x"></b-icon>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- error : patient needs contactInformations -->
                <div
                  v-show="errorPatientNeedsContactInformations"
                  class="mb-2"
                >
                  <div class="kt-badge kt-badge--red kt-badge--lg">
                    <b-icon
                      icon="exclamation-circle"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformations.validations.errorPatientNeedsContactInformations') }}</span></span>
                  </div>
                </div>
                <!-- error : patient needs an healthCare coverage -->
                <div
                  v-show="errorPatientNeedsHealthCareCoverage"
                  class="mb-2"
                >
                  <div class="kt-badge kt-badge--red kt-badge--lg">
                    <b-icon
                      icon="exclamation-circle"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformations.validations.errorPatientNeedsHealthCareCoverage') }}</span></span>
                  </div>
                </div>
                <!-- error : patient needs a regNumber -->
                <div
                  v-show="errorPatientNeedsRegNumber"
                  class="mb-2"
                >
                  <div class="kt-badge kt-badge--red kt-badge--lg">
                    <b-icon
                      icon="exclamation-circle"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformations.validations.errorPatientNeedsRegNumber') }}</span></span>
                  </div>
                </div>
                <!-- warning : same target -->
                <div
                  v-show="warningSameTarget"
                  class="mb-2"
                >
                  <div class="kt-badge kt-badge--yellow kt-badge--lg">
                    <b-icon
                      icon="info-circle"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformations.validations.warningSameTarget') }}</span></span>
                  </div>
                </div>
                <!-- warning : patient has healthCare coverage -->
                <div
                  v-show="warningPatientHasHealthCareCoverage"
                  class="mb-2"
                >
                  <div class="kt-badge kt-badge--yellow kt-badge--lg">
                    <b-icon
                      icon="info-circle"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('fileInvoiceTransformations.validations.warningPatientHasHealthCareCoverage') }}</span></span>
                  </div>
                </div>

                <!-- invoiceDate -->
                <b-form-group
                  v-if="targetOrganisationType === 'healthCare'"
                  class="mt-3 mb-2"
                  style="max-width: 163px;"
                  :label="$t('fileInvoiceTransformations.invoiceDate')"
                  :invalid-feedback="invoiceDateInvalidFeedback"
                  :state="invoiceDateState"
                >
                  <b-form-input
                    v-model="invoiceDate"
                    trim
                    type="date"
                    :state="invoiceDateState"
                    @blur="invoiceDateValidation = true"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $tc("fileInvoiceTransformationNew.submitText", fileInvoicesSelectedList.length, {count: fileInvoicesSelectedList.length}) }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- PatientEdit Modal -->
    <b-modal
      id="patientEditModal"
      hide-footer
      :title="$t('patientEdit.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.patientEditComponent.focusFirstElement()
      }"
      @hidden="() => {
        if ($refs.editPatientButton1) {
          $refs.editPatientButton1.focus();
        } else if ($refs.editPatientButton2) {
          $refs.editPatientButton2.focus();
        }
      }"
    >
      <PatientEdit
        ref="patientEditComponent"
        :patientIdProp="file ? file.patient.id : null"
        :editModeProp="true"
        :moduleModeProp="true"
        @onPatientEdited="onPatientEdited"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import FileInvoiceTransformationFileInvoiceItem from "@/views/Accounting/FileInvoiceTransformations/FileInvoiceTransformationFileInvoiceItem";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InputSearchInvoicedFile from "@shared/views/Helpers/InputSearchInvoicedFile";
import InputSearch from "@shared/views/Helpers/InputSearch";
import FileView from "@/views/Office/Files/FileView";
import PatientEdit from "@/views/Office/Patients/PatientEdit";
import PersonName from "@shared/views/Helpers/PersonName";
// services
import commonServices from "@shared/services/API/commonServices";
import filesServices from "@/services/API/filesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import price from "@shared/services/UI/price";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { FileInvoiceTransformationFileInvoiceItem, SmartSelectInput, InputSearchInvoicedFile, InputSearch, FileView, PatientEdit, PersonName },
  mixins: [userRights, error, date, price],
  props: {
  },
  data() {
    return {
      // general
      fileInvoiceTransformationId: null,
      fileInvoiceTransformationData: null,
      // form variables
      laboratoryId: null,
      file: null,
      targetOrganisationType: "healthCare",
      targetOrganisation: null,
      invoiceDate: "",
      fileInvoicesSelectedList: [],
      // is validation active
      laboratoryIdValidation: false,
      fileValidation: false,
      targetOrganisationValidation: false,
      invoiceDateValidation: false,
      fileInvoicesValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    fileState: function() {
      if (!this.fileValidation) return null;
      return this.file !== null ? null : false;
    },
    fileInvalidFeedback: function() {
      return this.fileState === false ? this.$t("validationRules.required") : "";
    },
    targetOrganisationState: function() {
      if (!this.targetOrganisation) return null;
      return this.targetOrganisationType === "patient" || (this.targetOrganisation !== null && this.targetOrganisation.id) ? null : false;
    },
    targetOrganisationInvalidFeedback: function() {
      return this.targetOrganisationState === false ? this.$t("validationRules.required") : "";
    },
    invoiceDateState: function() {
      if (!this.invoiceDateValidation) return null;
      if (this.targetOrganisationType !== "healthCare") return null;
      if (this.invoiceDate === null || this.invoiceDate === "") return false;
      return this.validatePastDate(this.invoiceDate) ? null : false;
    },
    invoiceDateInvalidFeedback: function() {
      if (this.invoiceDateState === null) return "";
      if (!this.invoiceDate) return this.$t("validationRules.required");
      return this.$t("fileInvoiceTransformations.validations.dateMustBePastOrToday");
    },
    fileInvoicesState: function() {
      if (!this.fileInvoicesValidation) return null;
      if (!this.fileInvoicesSelectedList || this.fileInvoicesSelectedList.length < 1) return false;
      return null;
    },
    fileInvoicesInvalidFeedback: function() {
      if (this.fileInvoicesState === null) return "";
      return this.$t("validationRules.required");
    },
    // helpers
    transformableFileInvoiceIds: function() {
      const fileInvoicesIds = [];
      if (this.file) {
        if (this.file.fileInvoices) {
          for (const fileInvoice of this.file.fileInvoices) {
            if (!fileInvoice.fileInvoiceTransformations || !fileInvoice.fileInvoiceTransformations.length) {
              fileInvoicesIds.push(fileInvoice.id);
            }
          }
        }
        if (this.file.childrenFiles) {
          for (const childrenFile of this.file.childrenFiles) {
            if (childrenFile && childrenFile.fileInvoices) {
              for (const fileInvoice of childrenFile.fileInvoices) {
                if (!fileInvoice.fileInvoiceTransformations || !fileInvoice.fileInvoiceTransformations.length) {
                  fileInvoicesIds.push(fileInvoice.id);
                }
              }
            }
          }
        }
      }
      return fileInvoicesIds;
    },
    // transformation errors
    warningSameTarget: function() {
      let ouput = false;
      if (this.file && this.targetOrganisationType) {
        for (const fileInvoice of this.file.fileInvoices) {
          // check file.fileInvoices
          if (this.fileInvoicesSelectedList.includes(fileInvoice.id)) {
            if (fileInvoice.feeNote) {
              ouput = this.targetOrganisationType === "patient";
            } else if (fileInvoice.careSheet) {
              ouput = this.targetOrganisation && fileInvoice.organisation.id === this.targetOrganisation.id;
            } else if (fileInvoice.invoiceReport) {
              ouput = this.targetOrganisation && fileInvoice.invoiceReport.organisation.id === this.targetOrganisation.id;
            } else {
              ouput = true;
            }
            if (ouput === true) return ouput;
          }
        }
        for (const childrenFile of this.file.childrenFiles) {
          if (childrenFile.fileInvoices) {
            for (const fileInvoice of childrenFile.fileInvoices) {
              if (this.fileInvoicesSelectedList.includes(fileInvoice.id)) {
                // check file.childrenFiles.fileInvoices
                if (fileInvoice.feeNote) {
                  ouput = this.targetOrganisationType === "patient";
                } else if (fileInvoice.careSheet) {
                  ouput = this.targetOrganisation && fileInvoice.organisation.id === this.targetOrganisation.id;
                } else if (fileInvoice.invoiceReport) {
                  ouput = this.targetOrganisation && fileInvoice.invoiceReport.organisation.id === this.targetOrganisation.id;
                } else {
                  ouput = true;
                }
                if (ouput === true) return ouput;
              }
            }
          }
        }
      }
      return ouput;
    },
    patientHasHealthCareCoverage: function() {
      // check for patient coverage
      if (!this.file || !this.file.patient || !this.file.patient.insuranceTypeId || !this.file.patient.invoicedOrganisationId) {
        return false;
      }
      return this.file.patient.insuranceType.name === "healthCare" ||
        this.validateDateBeforeDate(this.file.creationDate, this.file.patient.insuranceEndDate);
    },
    patientHasAFullHealthCareCoverage: function() {
      return this.patientHasHealthCareCoverage &&
        this.file.patient.insuranceTypeId !== 1;
    },
    errorPatientNeedsHealthCareCoverage: function() {
      let ouput = false;
      if (this.file && this.targetOrganisationType === "healthCare") {
        ouput = !this.patientHasHealthCareCoverage;
      }
      return ouput;
    },
    errorPatientNeedsRegNumber: function() {
      let ouput = false;
      if (this.file && this.targetOrganisationType === "healthCare") {
        ouput = !this.file.patient.regNumber && !this.file.patient.insureeRegNumber;
      }
      return ouput;
    },
    errorPatientNeedsContactInformations: function() {
      let ouput = false;
      if (this.file && this.targetOrganisationType === "patient") {
        const hasContactInformations = (this.file.patient.address1 && this.file.patient.zip && this.file.patient.city) || (this.file.patient.phone && this.file.patient.email);
        ouput = !hasContactInformations;
      }
      return ouput;
    },
    warningPatientHasHealthCareCoverage: function() {
      let ouput = false;
      if (this.file !== null && this.targetOrganisationType !== "healthCare") {
        ouput = this.patientHasAFullHealthCareCoverage;
      }
      return ouput;
    }
  },
  watch: {
    targetOrganisationType: {
      handler: function(val) {
        if (val === "patient") {
          this.fileInvoicesSelectedList = this.transformableFileInvoiceIds;
        }
      }
    }
  },
  async mounted() {
    // setup date
    this.invoiceDate = this.getIsoDate();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.laboratoryInput) {
        this.$refs.laboratoryInput.focusFirstElement();
      } else if (this.$refs.searchFileInput) {
        this.$refs.searchFileInput.focusFirstElement();
      }
    },

    onSelectFileInvoice(fileInvoiceId, value) {
      if (value) {
        if (!this.fileInvoicesSelectedList.includes(fileInvoiceId)) {
          this.fileInvoicesSelectedList.push(fileInvoiceId);
        }
      } else {
        this.fileInvoicesSelectedList = this.fileInvoicesSelectedList.filter(item => item !== fileInvoiceId);
      }
    },
    suggestionFormatOrganisation(organisation) {
      let output = "";
      if (organisation) {
        output = organisation.name + " (" + organisation.code + ")";
        if (organisation.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },
    async formatParentFile() {
      if (this.file === null) return false;

      this.file = this.formatFile(this.file);
      for (let i = 0; i < this.file.childrenFiles.length; i++) {
        const resFile = await commonServices.get("files", this.file.childrenFiles[i].id);
        const resFileInvoices = await filesServices.getFileInvoicesByFileId(this.file.childrenFiles[i].id);
        resFile.data.fileInvoices = resFileInvoices.data;
        this.file.childrenFiles[i] = this.formatFile(resFile.data);
      }
    },
    formatFile(file) {
      if (file === null) return file;

      for (let i = file.fileInvoices.length - 1; i >= 0; i--) {
        // delete compensation fileInvoice
        if (!file.fileInvoices[i].careSheetId && !file.fileInvoices[i].feeNoteId && !file.fileInvoices[i].invoiceReportId) {
          file.fileInvoices.splice(i, 1);
          continue;
        }
        // select fileInvoice
        if (!file.fileInvoices[i].fileInvoiceTransformations || !file.fileInvoices[i].fileInvoiceTransformations.length) {
          this.fileInvoicesSelectedList.push(file.fileInvoices[i].id);
        }
        // format invoiced
        file.fileInvoices[i].invoiced = this.importFormatPrice(file.fileInvoices[i].invoiced);
        // for each file.fileInvoices, set fileInvoice.organisation & fileInvoice.invoiceDate
        if (file.fileInvoices[i].feeNote) {
          file.fileInvoices[i].organisation = null;
          file.fileInvoices[i].invoiceDate = file.fileInvoices[i].feeNote.invoiceDate;
        } else if (file.fileInvoices[i].careSheet) {
          file.fileInvoices[i].organisation = file.fileInvoices[i].careSheet.organisation;
          file.fileInvoices[i].invoiceDate = file.fileInvoices[i].careSheet.invoiceDate;
        } else if (file.fileInvoices[i].invoiceReport) {
          file.fileInvoices[i].organisation = file.fileInvoices[i].invoiceReport.organisation;
          file.fileInvoices[i].invoiceDate = file.fileInvoices[i].invoiceReport.invoiceDate;
        }
      }
      // sort fileInvoices
      file.fileInvoices.sort(function(a, b) { return a.id - b.id; });

      return file;
    },
    async onFileChange(file) {
      this.fileInvoicesSelectedList = [];
      this.file = file;
      await this.formatParentFile();
      this.setDefaultTargetOrganisationType();
      this.setDefaultTargetOrganisation();
    },
    onTargetOrganisationType(organisationType) {
      this.targetOrganisationType = organisationType;
      this.setDefaultTargetOrganisation();
    },
    setDefaultTargetOrganisationType() {
      this.targetOrganisationType = "healthCare";
      if (this.file === null) {
        this.targetOrganisationType = "healthCare";
      } else if (this.patientHasAFullHealthCareCoverage || (this.patientHasHealthCareCoverage && this.file.forceHealthCare)) {
        this.targetOrganisationType = "healthCare";
      } else if (this.file.invoicedOrganisation === null) {
        // origin invoice target = patient
        this.targetOrganisationType = "healthCare";
      } else {
        // origin invoice target = healthCare or organisation
        this.targetOrganisationType = "patient";
      }
    },
    setDefaultTargetOrganisation() {
      this.targetOrganisation = null;
      if (!this.file) return false;

      if (this.targetOrganisationType === "patient") {
        return false;
      } else if (this.targetOrganisationType === "healthCare") {
        if (this.file.patient.invoicedOrganisation) {
          // take patient health insurance
          this.targetOrganisation = this.file.patient.invoicedOrganisation;
        }
      } else if (this.targetOrganisationType === "organisation") {
        return false;
      }
    },
    async onPatientEdited() {
      const resFile = await commonServices.get("files", this.file.id);
      this.file.patient = resFile.data.patient;
      this.$bvModal.hide("patientEditModal");
      this.setDefaultTargetOrganisation();
    },
    // navigate to the list page
    onGoBack() {
      navigate("accounting/fileInvoiceTransformations");
    },

    // submit functions
    validateForm() {
      this.laboratoryIdValidation = true;
      this.fileValidation = true;
      this.targetOrganisationValidation = true;
      this.invoiceDateValidation = true;
      this.fileInvoicesValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.fileState === false ||
        this.targetOrganisationState === false ||
        this.invoiceDateState === false ||
        this.fileInvoicesState === false ||
        this.errorPatientNeedsHealthCareCoverage === true ||
        this.errorPatientNeedsRegNumber === true ||
        this.errorPatientNeedsContactInformations === true
      );
    },
    async saveFileInvoiceTransformation() {
      try {
        const form = {
          laboratoryId: this.file.laboratoryId,
          originOrganisationId: this.file.invoicedOrganisationId,
          fileInvoiceIds: this.fileInvoicesSelectedList,
          targetOrganisationId: this.targetOrganisation ? this.targetOrganisation.id : null,
          invoiceDate: this.targetOrganisationType === "healthCare" ? this.invoiceDate : null
        };
        const res = await commonServices.post("fileInvoiceTransformations", form);
        if (res.data.result) {
          // success message
          this.$emit("alert", "success", {
            title: this.$t("fileInvoiceTransformationNew.notifications.addedTitle"),
            message: this.$t("fileInvoiceTransformationNew.notifications.addedText")
          });
          navigate("accounting/fileInvoiceTransformations");
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("fileInvoiceTransformationNew.notifications.additionErrorTitle"),
            message: this.$t("fileInvoiceTransformationNew.notifications.additionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveFileInvoiceTransformation();
    }
  }
};
</script>

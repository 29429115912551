<template>
  <div
    class="kt-patientPayment-edit"
    :class="{'pb-4': !moduleModeProp}"
  >
    <!-- page header -->
    <div
      v-show="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("payments.patientPaymentEdit.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div
      v-show="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- awaitingPayment info -->
    <div
      v-if="awaitingPaymentData"
      class="kt-view-page__body pb-1"
    >
      <!-- divided inforation -->
      <div class="kt-divided-information">
        <div class="kt-divided-information__wrapper">
          <!-- title -->
          <div class="kt-divided-information__section">
            <div
              class="kt-divided-information__section-content font-weight-semi-bold"
              style="font-size: 17px; margin-bottom: 4px; line-height: 22.5px;"
            >
              {{ $t("payments.patientPaymentEdit.awaitingSectionTitle") }}
            </div>
          </div>
          <!-- laboratory / accessNumber / patient -->
          <div class="kt-divided-information__section border-left">
            <div class="d-flex">
              <div class="pr-3">
                <!-- <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.laboratory') }}
                </div> -->
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.paymentType') }}
                </div>
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.accessNumber') }}
                </div>
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.patient') }}
                </div>
              </div>
              <div>
                <!-- <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.laboratory ? awaitingPaymentData.laboratory.name : $t("general.emptyWithHyphen") }}
                </div> -->
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.paymentType ? $t("paymentTypes." + awaitingPaymentData.paymentType.name) : $t("general.emptyWithHyphen") }}
                </div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.accessNumber || $t("general.emptyWithHyphen") }}
                </div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.firstName || awaitingPaymentData.lastName ? awaitingPaymentData.firstName + " " + awaitingPaymentData.lastName : $t("general.emptyWithHyphen") }}
                </div>
              </div>
            </div>
          </div>
          <!-- paid / paymentDate / payerName -->
          <div class="kt-divided-information__section border-left">
            <div class="d-flex">
              <div class="pr-3">
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.paid') }}
                </div>
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.paymentDate') }}
                </div>
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.payerName') }}
                </div>
              </div>
              <div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.paid ? $n(importFormatPrice(awaitingPaymentData.paid), "currency") : $n(0, "currency") }}
                </div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.paymentDate ? $d(new Date(awaitingPaymentData.paymentDate), "date") : $t("general.emptyWithHyphen") }}
                </div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.payerName ? awaitingPaymentData.payerName : $t("general.emptyWithHyphen") }}
                </div>
              </div>
            </div>
          </div>
          <!-- bank / check / transaction / comment -->
          <div class="kt-divided-information__section border-left">
            <div class="d-flex">
              <div class="pr-3">
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.bank') }}
                </div>
                <div class="kt-divided-information__section-title">
                  {{ $t('payments.awaitingPayments.checkNumber') }}
                </div>
                <div
                  class="kt-divided-information__section-title"
                  style="white-space: nowrap;"
                >
                  {{ $t('payments.awaitingPayments.transactionId') }}
                </div>
                <div
                  v-show="comment"
                  class="kt-divided-information__section-title"
                >
                  {{ $t('payments.awaitingPayments.comment') }}
                </div>
              </div>
              <div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.bank ? awaitingPaymentData.bank.code : $t("general.emptyWithHyphen") }}
                </div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.checkNumber || $t("general.emptyWithHyphen") }}
                </div>
                <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
                  {{ awaitingPaymentData.transactionId || $t("general.emptyWithHyphen") }}
                </div>
                <div
                  v-show="comment"
                  class="kt-divided-information__section-content kt-divided-information__color-secondary"
                >
                  {{ awaitingPaymentData.comment }}
                </div>
              </div>
            </div>
          </div>
          <!-- actions -->
          <div class="kt-divided-information__section kt-divided-information__section--actions border-left">
            <!-- option drop-down -->
            <div
              v-show="isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'edit'])"
              class="kt-dropdown-btn kt-dropdown-btn--no-arrow"
            >
              <b-button
                ref="awaitingPaymentActionsDropdown"
                variant="outline-secondary"
                size="sm"
                class="btn-icon kt-dropdown-btn__switcher-btn"
                pill
              >
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
              <ul class="kt-dropdown-btn__list">
                <li class="kt-dropdown-btn__shadow"></li>

                <!-- edit -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'edit'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onEditAwaitingPayment"
                  >
                    <b-icon
                      icon="pen"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.patientPaymentEdit.editAwaitingPayment") }}
                  </button>
                </li>

                <!-- <li class="kt-dropdown-btn__divider"></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-3 mb-3"></div>

    <!-- payment form -->
    <b-form
      v-show="editMode"
      @submit.prevent="submitSave"
    >
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="false"
            :initialValueProp="awaitingPaymentData !== null ? awaitingPaymentData.laboratoryId : null"
            @input="laboratoryId = $event;"
            @change="($event) => {
              laboratoryId = $event;
              relatedFiles = [];
              invoicedFilesGroups = [];
            }"
          />
        </b-col>
        <!-- paymentTypeId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="paymentTypesOptions && paymentTypesOptions.length"
            ref="paymentTypeInput"
            :labelProp="$t('payments.awaitingPayments.paymentType')"
            :optionsProp="paymentTypesOptions"
            valueFieldProp="id"
            textFieldProp="localisedName"

            :valueProp="paymentType !== null ? paymentType.id : null"
            :stateProp="paymentTypeState"
            :invalidFeedbackProp="paymentTypeInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="true"
            :initialValueProp="null"
            @input="paymentTypeInput($event)"
          />
        </b-col>
        <!-- paymentDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.patientPayments.paymentDate')"
            :invalid-feedback="paymentDateInvalidFeedback"
            :state="paymentDateState"
          >
            <b-form-input
              v-model="paymentDate"
              type="date"
              :state="paymentDateState"
              @blur="paymentDateValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- bank -->
        <b-col
          v-show="paymentType && paymentType.name !== 'cash'"
          cols="12"
          md="4"
        >
          <InputSearch
            searchedTableProp="banks"
            searchedFieldProp="code"
            :searchedLaboratoriesProp="[]"
            :returnFieldsArrayProp="['id', 'code', 'name']"
            suggestionsKeyProp="id"
            :valueProp="bank"
            :displayedValueProp="bank ? bank.code : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
            }"
            :searchMinLengthProp="1"

            :labelProp="$t('payments.patientPayments.bank')"
            :stateProp="bankState"
            :invalidFeedbackProp="bankInvalidFeedback"

            @onUpdateParent="bank = $event; bankId = (bank ? bank.id : null);"
            @onActivateValidation="bankValidation = true"
            @onDisableValidation="bankValidation = false;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>
        <!-- checkNumber -->
        <b-col
          v-show="paymentType && paymentType.name === 'check'"
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.patientPayments.checkNumber')"
            :invalid-feedback="checkNumberInvalidFeedback"
            :state="checkNumberState"
          >
            <b-form-input
              v-model="checkNumber"
              trim
              maxlength="50"
              :state="checkNumberState"
              @blur="checkNumberValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- transactionId -->
        <b-col
          v-show="paymentType && (paymentType.name === 'creditCard' || paymentType.name === 'transfer')"
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.awaitingPayments.transactionId')"
            :invalid-feedback="transactionIdInvalidFeedback"
            :state="transactionIdState"
          >
            <b-form-input
              v-model="transactionId"
              trim
              maxlength="64"
              :state="transactionIdState"
              @blur="transactionIdValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- paid -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputPrice
            :labelProp="$t('payments.patientPayments.paid')"
            :priceProp="paid"
            :stateProp="paidState"
            :invalidFeedbackProp="paidInvalidFeedback"
            @onUpdateParent="paid = $event"
            @onActivateValidation="paidValidation = $event"
            @submit="submitSave"
          ></InputPrice>
        </b-col>
        <!-- payerName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="payerName"
            :labelProp="$t('payments.awaitingPayments.payerName')"
            :stateProp="payerNameState"
            :invalidFeedbackProp="payerNameInvalidFeedback"
            @blur="payerNameValidation = true;"
            @submit="submitSave"
          />
        </b-col>
        <!-- info badges -->
        <b-col
          v-show="transactionId"
          cols="12"
          class="mb-3"
        >
          <!-- transactionId -->
          <div
            class="kt-badge kt-badge--light-gray kt-badge--lg"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('payments.patientPayments.transactionId') + ' : ' }}</span>{{ transactionId }}</span>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <!-- reconciled progress -->
    <div class="text-center mb-2">
      <div class="kt-divided-information__section-title">
        {{ $t('payments.awaitingPayments.reconciliation') }}
      </div>
      <div class="text-15">
        <span :class="paid ? (reconciled !== paid ? 'text-danger' : 'text-success') : ''">{{ reconciled ? $n(reconciled, "currency") : $n(0, "currency") }}</span>
        <span>{{ ' / ' + (paid ? $n(paid, "currency") : $t("general.emptyWithHyphen")) }}</span>
      </div>
      <!-- reconciled error -->
      <div
        v-show="reconciledState === false"
        class="text-13 text-danger"
      >
        <b-icon
          icon="exclamation-triangle-fill"
          style="margin-right: 2px;"
        ></b-icon>
        {{ $t("payments.patientPaymentEdit.validations.reconciledError") }}
      </div>
    </div>

    <!-- invoicedFilesGroups form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- invoicedFilesGroups -->
        <b-col cols="12">
          <div class="kt-invoiced-files-groups">
            <InvoicedFilesGroup
              v-for="(invoicedFilesGroup, index) in invoicedFilesGroups"
              :key="index"
              class="mb-3"

              :invoicedFilesGroupProp="invoicedFilesGroup"
              :relatedFilesProp="relatedFilesFiltered"
              :currentFilePaymentIdsProp="currentFilePaymentIds"

              :patientPaymentIdProp="patientPaymentIdProp"
              :awaitingPaymentIdProp="awaitingPaymentIdProp"
              :laboratoryIdProp="laboratoryId ? laboratoryId : null"
              :initialSearchAccessNumberProp="invoicedFilesGroup.initialSearchAccessNumber || null"
              :advancedSearchOnLoadProp="Boolean(invoicedFilesGroup.advancedSearchOnLoad)"
              :initialFilterValuesProp="initialFilterValues"
              :unavailableAccessNumbersProp="unavailableAccessNumbers"
              :closeFilesAfterSavingProp="invoicedFilesGroup.closeFilesAfterSaving || null"

              :labelProp="$t('payments.patientPayments.accessNumber')"
              :stateProp="invoicedFilesGroupsState"
              :invalidFeedbackProp="invoicedFilesGroupsInvalidFeedback"
              :disabledProp="false"

              @deleteInvoicedFilesGroup="onDeleteInvoicedFilesGroup(index)"
              @updateGroupAccessNumber="onUpdateGroupAccessNumber(index, $event)"
              @updateGroupInvoicedFiles="onUpdateGroupInvoicedFiles(index, $event)"
              @updateRelatedFiles="addToRelatedFiles($event.accessNumber, $event.relatedFiles)"
              @onCloseFileAfterSaving="onCloseFileAfterSaving(index, $event)"
              @onActivateValidation="invoicedFilesGroupsValidation = true"
              @submit="submitSave"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />

            <!-- add group button -->
            <div class="kt-invoiced-files-groups__add-btn">
              <b-button
                v-show="invoicedFilesGroups.length === 0 || invoicedFilesGroups[invoicedFilesGroups.length - 1].invoicedFiles.length > 0"
                :variant="'outline-' + $systemSettings.theme"
                @click="addInvoicedFilesGroup"
              >
                {{ $t("payments.patientPaymentEdit.addInvoicedFilesGroup") }}
              </b-button>
            </div>
          </div>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-3 mb-1"></div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="mb-2 mt-3"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- editAwaitingPayment Modal -->
    <b-modal
      id="editAwaitingPaymentModal"
      hide-footer
      :title="$t('payments.awaitingPaymentEdit.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.awaitingPaymentEditComponent.focusFirstElement()
      }"
      @hidden="() => {
        if ($refs.awaitingPaymentActionsDropdown) {
          $refs.awaitingPaymentActionsDropdown.click();
          $nextTick(() => {
            $refs.awaitingPaymentActionsDropdown.focus();
          });
        }
      }"
    >
      <AwaitingPaymentEdit
        ref="awaitingPaymentEditComponent"
        :awaitingPaymentIdProp="awaitingPaymentIdProp"
        moduleModeProp
        :editModeProp="true"
        @awaitingPaymentSaved="onAwaitingPaymentEdited"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
import InputPrice from "@shared/views/Helpers/InputPrice";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InvoicedFilesGroup from "@/views/Accounting/Payments/PatientPayments/InvoicedFilesGroup";
import AwaitingPaymentEdit from "@/views/Accounting/Payments/AwaitingPayments/AwaitingPaymentEdit";
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import paymentsHelpers from "@shared/services/UI/paymentsHelpers";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { SmartSelectInput, InvoicedFilesGroup, InputSearch, InputPrice, AwaitingPaymentEdit, InputTextFormatted },
  mixins: [paymentsHelpers, userRights, error, price],
  props: {
    patientPaymentIdProp: {
      type: Number,
      default: null
    },
    awaitingPaymentIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      patientPaymentId: this.patientPaymentIdProp,
      currentFilePaymentIds: [],
      patientPaymentData: null,
      awaitingPaymentId: this.awaitingPaymentIdProp,
      awaitingPaymentData: null,
      // form variables
      awaitingPaymentUserId: null,
      laboratoryId: null,
      paymentType: null,
      paymentDate: "",
      accessNumber: "",
      lastName: "",
      firstName: "",
      bank: null,
      bankId: null,
      checkNumber: "",
      payerName: "",
      transactionId: "",
      paid: 0,
      comment: "",
      invoicedFilesGroups: [],
      relatedFiles: [],
      // is validation active
      reconciledValidation: false,
      laboratoryIdValidation: false,
      paymentTypeValidation: false,
      paymentDateValidation: false,
      bankValidation: false,
      payerNameValidation: false,
      checkNumberValidation: false,
      transactionIdValidation: false,
      paidValidation: false,
      invoicedFilesGroupsValidation: false
    };
  },
  computed: {
    // form validation
    reconciledState: function() {
      if (!this.reconciledValidation) return null;
      return this.reconciled === this.paid ? null : false;
    },
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    paymentTypeState: function() {
      if (!this.paymentTypeIdValidation) return null;
      return this.paymentType !== null ? null : false;
    },
    paymentTypeInvalidFeedback: function() {
      return this.paymentTypeState === false ? this.$t("validationRules.required") : "";
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    paymentDateState: function() {
      if (!this.paymentDateValidation) return null;
      return this.paymentDate !== "" && this.paymentDate !== null ? null : false;
    },
    paymentDateInvalidFeedback: function() {
      return this.paymentDateState === false ? this.$t("validationRules.required") : "";
    },
    bankState: function() {
      if (!this.bankValidation) return null;
      if (this.paymentType && this.paymentType.name === "check" && this.bank === null) return false;
      return null;
    },
    bankInvalidFeedback: function() {
      return this.checkNumberState === false ? this.$t("validationRules.required") : "";
    },
    payerNameState: function() {
      return null;
    },
    payerNameInvalidFeedback: function() {
      return "";
    },
    checkNumberState: function() {
      if (!this.checkNumberValidation) return null;
      if (this.paymentType && this.paymentType.name === "check" && !this.checkNumber) return false;
      return null;
    },
    checkNumberInvalidFeedback: function() {
      return this.checkNumberState === false ? this.$t("validationRules.required") : "";
    },
    transactionIdState: function() {
      return null;
    },
    transactionIdInvalidFeedback: function() {
      return this.transactionIdState === false ? this.$t("validationRules.required") : "";
    },
    paidState: function() {
      if (!this.paidValidation) return null;
      if (!this.paid) return false;
      return typeof this.paid === "number" || /^\d+\.\d{2}$/g.test(this.paid) ? null : false;
    },
    paidInvalidFeedback: function() {
      if (this.paidState === null) return "";
      if (!this.paid) return this.$t("validationRules.required");
      return this.$t("validationRules.invalidPrice");
    },
    invoicedFilesGroupsState: function() {
      if (!this.invoicedFilesGroupsValidation) return null;
      return this.invoicedFilesGroups.length && this.invoicedFilesGroups[0].invoicedFiles.length ? null : false;
    },
    invoicedFilesGroupsInvalidFeedback: function() {
      return this.invoicedFilesGroupsState === false ? this.$t("validationRules.required") : "";
    },

    initialFilterValues: function() {
      return {
        firstName: this.firstName,
        lastName: this.lastName
      };
    },
    reconciled: function() {
      let reconciled = 0;
      for (const invoicedFilesGroup of this.invoicedFilesGroups) {
        for (const invoicedFile of invoicedFilesGroup.invoicedFiles) {
          for (const fileInvoice of invoicedFile.fileInvoices) {
            for (const invoiceAct of fileInvoice.invoiceActs) {
              reconciled += invoiceAct.paymentAct.paid;
            }
          }
        }
      }
      return Math.round((reconciled + Number.EPSILON) * 100) / 100;
    },
    unavailableAccessNumbers: function() {
      const unavailableAccessNumbers = [];
      for (const invoicedFilesGroup of this.invoicedFilesGroups) {
        if (invoicedFilesGroup.invoicedFiles.length) {
          unavailableAccessNumbers.push(invoicedFilesGroup.accessNumber);
        }
      }
      return unavailableAccessNumbers;
    },
    relatedFilesFiltered: function() {
      return this.relatedFiles.filter(item => !this.unavailableAccessNumbers.includes(item.accessNumber));
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.$t("payments.patientPaymentEdit.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.$t("payments.patientPaymentEdit.submitText");
    },

    // options
    paymentTypesOptions: function() {
      return this.$systemSettings.paymentTypes || [];
    }
  },
  async mounted() {
    // import data
    if (this.editModeProp) {
      await this.importPatientPaymentData();
    } else {
      await this.importAwaitingPaymentData();
    }

    // initial search accessNumber
    if (!this.editModeProp) {
      this.invoicedFilesGroups.push({
        accessNumber: this.accessNumber,
        invoicedFiles: [],
        initialSearchAccessNumber: this.accessNumber,
        advancedSearchOnLoad: false,
        closeFilesAfterSaving: null
      });
    }
  },
  methods: {
    // load/refresh data
    async importAwaitingPaymentData() {
      try {
        if (this.awaitingPaymentIdProp) {
          // get patientPayment
          const resServices = await commonServices.get("awaitingPayments", this.awaitingPaymentIdProp);
          this.awaitingPaymentData = resServices.data;
          // import data
          this.awaitingPaymentUserId = resServices.data.awaitingPaymentUserId;
          this.laboratoryId = resServices.data.laboratoryId;
          this.paymentType = resServices.data.paymentType;
          this.paymentDate = resServices.data.paymentDate || "";
          this.accessNumber = resServices.data.accessNumber;
          this.lastName = resServices.data.lastName;
          this.firstName = resServices.data.firstName;
          this.bankId = resServices.data.bankId;
          this.bank = resServices.data.bank;
          this.payerName = resServices.data.payerName;
          this.checkNumber = resServices.data.checkNumber;
          this.transactionId = resServices.data.transactionId;
          this.paid = this.importFormatPrice(resServices.data.paid);
          this.comment = resServices.data.comment || "";
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importPatientPaymentData() {
      try {
        if (this.editModeProp) {
          // get patientPayment
          const resServices = await commonServices.get("patientPayments", this.patientPaymentId);
          // import currentFilePaymentIds
          this.currentFilePaymentIds = resServices.data.filePayments.map(item => item.id);
          // import data
          this.patientPaymentData = this.importFormatPayment(resServices.data);
          this.awaitingPaymentUserId = resServices.data.awaitingPaymentUserId;
          this.laboratoryId = resServices.data.laboratoryId;
          this.paymentType = resServices.data.paymentType;
          this.paymentDate = resServices.data.paymentDate;
          this.bankId = resServices.data.bankId;
          this.bank = resServices.data.bank;
          this.payerName = resServices.data.payerName;
          this.checkNumber = resServices.data.checkNumber;
          this.transactionId = resServices.data.transactionId;
          this.paid = this.patientPaymentData.paid;
          // setup invoicedFilesGroups
          const invoicedFilesGroups = [];
          const initialInvoicedFiles = this.patientPaymentData.invoicedFiles;
          const searchedAccessNumbers = [];
          for (const initialInvoicedFile of initialInvoicedFiles) {
            // for each accessNumber, add a invoicedFilesGroup to search
            if (!searchedAccessNumbers.includes(initialInvoicedFile.accessNumber)) {
              searchedAccessNumbers.push(initialInvoicedFile.accessNumber);
              invoicedFilesGroups.push({
                accessNumber: initialInvoicedFile.accessNumber,
                invoicedFiles: [],
                initialSearchAccessNumber: initialInvoicedFile.accessNumber,
                advancedSearchOnLoad: false,
                closeFilesAfterSaving: null
              });
            }
          }
          this.invoicedFilesGroups = invoicedFilesGroups;
          // const searchedAccessNumbers = [];
          // for (const initialInvoicedFile of initialInvoicedFiles) {
          //   // for each accessNumber in initialInvoicedFiles, search all the initialInvoicedFiles (including the related) and add to the group
          //   if (!searchedAccessNumbers.includes(initialInvoicedFile.accessNumber)) {
          //     searchedAccessNumbers.push(initialInvoicedFile.accessNumber);
          //     const res = await this.mixinSearchInvoicedFiles(this.laboratoryId, initialInvoicedFile.accessNumber, this.currentFilePaymentIds);
          //     if (res.initialInvoicedFiles && res.initialInvoicedFiles.length) {
          //       this.addToRelatedFiles(initialInvoicedFile.accessNumber, res.relatedFiles);
          //       initialInvoicedFilesGroups.push({
          //         accessNumber: initialInvoicedFile.accessNumber,
          //         initialInvoicedFiles: res.initialInvoicedFiles
          //       });
          //     }
          //   }
          // }
          // for (const initialInvoicedFile of initialInvoicedFiles) {
          //   // if the group is searched or not, if the invoicedFile is already added, add its fileInvoices
          //   for (let j = 0; j < initialInvoicedFilesGroups.length; j++) {
          //     if (initialInvoicedFilesGroups[j].accessNumber === initialInvoicedFile.accessNumber) {
          //       for (let k = 0; k < initialInvoicedFilesGroups[j].initialInvoicedFiles.length; k++) {
          //         if (initialInvoicedFilesGroups[j].initialInvoicedFiles[k].id === initialInvoicedFile.id) {
          //           initialInvoicedFilesGroups[j].initialInvoicedFiles[k].fileInvoices = initialInvoicedFile.fileInvoices;
          //         }
          //       }
          //     }
          //   }
          // }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // edit InvoicedFilesGroup properties
    onUpdateGroupAccessNumber(index, accessNumber) {
      this.invoicedFilesGroups[index].accessNumber = accessNumber;
    },
    onUpdateGroupInvoicedFiles(index, invoicedFiles) {
      // update group invoicedFiles
      this.invoicedFilesGroups[index].invoicedFiles = invoicedFiles;
    },
    addToRelatedFiles(accessNumber, relatedFiles) {
      // update related files
      if (relatedFiles && relatedFiles.length) {
        for (const relatedFile of relatedFiles) {
          let found = false;
          for (const savedRelatedFile of this.relatedFiles) {
            if (savedRelatedFile.id === relatedFile.id) {
              found = true;
              savedRelatedFile.originAccessNumbers.push(accessNumber);
              break;
            }
          }
          if (!found) {
            relatedFile.originAccessNumbers = [accessNumber];
            this.relatedFiles.push(relatedFile);
          }
        }
      }
    },
    onCloseFileAfterSaving(index, $event) {
      this.invoicedFilesGroups[index].closeFilesAfterSaving = $event;
    },
    // InvoicedFilesGroup
    addInvoicedFilesGroup() {
      this.invoicedFilesGroups.push({
        accessNumber: "",
        invoicedFiles: [],
        relatedFiles: [],
        advancedSearchOnLoad: true,
        closeFilesAfterSaving: null
      });
    },
    onDeleteInvoicedFilesGroup(index) {
      // delete relatedFiles
      for (let i = this.relatedFiles.length - 1; i >= 0; i--) {
        if (this.relatedFiles[i].originAccessNumbers.includes(this.invoicedFilesGroups[index].accessNumber)) {
          this.relatedFiles[i].originAccessNumbers = this.relatedFiles[i].originAccessNumbers.filter((item) => { return item !== this.invoicedFilesGroups[index].accessNumber; });
          if (this.relatedFiles[i].originAccessNumbers.length === 0) {
            this.relatedFiles.splice(i, 1);
          }
        }
      }
      // delete group
      this.invoicedFilesGroups.splice(index, 1);
      if (!this.invoicedFilesGroups.length) {
        this.$nextTick(() => {
          this.invoicedFilesGroups.push({
            accessNumber: "",
            invoicedFiles: [],
            relatedFiles: [],
            advancedSearchOnLoad: false,
            closeFilesAfterSaving: null
          });
        });
      }
    },

    // awaitingPayment
    onEditAwaitingPayment() {
      this.$bvModal.show("editAwaitingPaymentModal");
    },
    async onAwaitingPaymentEdited() {
      this.$bvModal.hide("editAwaitingPaymentModal");
      // import awaitingPayment
      const initialLaboratoryId = this.laboratoryId;
      await this.importAwaitingPaymentData();
      // if the laboratory as changed, clear the invoicedFiles
      if (this.laboratoryId !== initialLaboratoryId) {
        this.relatedFiles = [];
        this.invoicedFilesGroups = [];
      }
    },

    // payment type
    paymentTypeInput(paymentTypeId) {
      for (const paymentTypesOption of this.paymentTypesOptions) {
        if (paymentTypesOption.id === paymentTypeId) {
          this.paymentType = paymentTypesOption;
          break;
        }
      }
    },

    // submit functions
    validateForm() {
      this.reconciledValidation = true;
      this.laboratoryIdValidation = true;
      this.paymentDateValidation = true;
      this.bankValidation = true;
      this.payerNameValidation = true;
      this.checkNumberValidation = true;
      this.paidValidation = true;
      this.invoicedFilesGroupsValidation = true;

      return !(
        this.reconciledState === false ||
        this.laboratoryIdState === false ||
        this.paymentDateState === false ||
        this.bankState === false ||
        this.payerNameState === false ||
        this.checkNumberState === false ||
        this.paidState === false ||
        this.invoicedFilesGroupsState === false
      );
    },
    async savePatientPayment() {
      try {
        // setup filePayments
        const filePayments = [];
        for (const invoicedFilesGroup of this.invoicedFilesGroups) {
          for (const invoicedFile of invoicedFilesGroup.invoicedFiles) {
            // setup filePayment.paid
            const invoicedFilePaid = invoicedFile.fileInvoices.reduce((total, item) => { return total + item.paid; }, 0);
            if (invoicedFilePaid) {
              // setup filePayment.paymentActs
              const paymentActs = [];
              for (const fileInvoice of invoicedFile.fileInvoices) {
                for (const invoiceAct of fileInvoice.invoiceActs) {
                  paymentActs.push({
                    invoiceActId: invoiceAct.id,
                    paid: this.exportFormatPrice(invoiceAct.paymentAct.paid)
                  });
                }
              }
              // add filePayment
              filePayments.push({
                isActive: true,
                fileId: invoicedFile.id,
                paid: this.exportFormatPrice(invoicedFilePaid),
                paymentActs: paymentActs
              });
            }
          }
        }
        const form = {
          laboratoryId: this.laboratoryId,
          paymentTypeId: this.paymentType ? this.paymentType.id : null,
          paymentDate: this.paymentDate || null,
          filePayments: filePayments,
          bankId: (this.paymentType.name !== "cash" ? this.bankId : null),
          payerName: this.payerName,
          checkNumber: (this.paymentType.name === "check" ? this.checkNumber : ""),
          transactionId: (this.paymentType.name === "creditCard" || this.paymentType.name === "transfer" ? this.transactionId : ""),
          paid: this.exportFormatPrice(this.paid)
        };
        if (this.editMode) {
          const res = await commonServices.put("patientPayments", form, this.patientPaymentId);
          if (res.data === true) {
            this.saveSuccess();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.patientPaymentEdit.notifications.editionErrorTitle"),
              message: this.$t("payments.patientPaymentEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.awaitingPaymentUserId = this.awaitingPaymentUserId;
          form.awaitingPaymentId = this.awaitingPaymentId;
          form.isActive = true;
          const res = await commonServices.post("patientPayments", form);
          if (res.data.id) {
            this.saveSuccess();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.patientPaymentNew.notifications.additionErrorTitle"),
              message: this.$t("payments.patientPaymentNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.savePatientPayment();
    },
    async saveSuccess() {
      // close files
      for (const invoicedFilesGroup of this.invoicedFilesGroups) {
        if (invoicedFilesGroup.closeFilesAfterSaving) {
          for (const fileId of invoicedFilesGroup.closeFilesAfterSaving) {
            if (await this.closeFile(fileId) === false) {
              if (this.editMode) {
                this.$emit("alert", "danger", {
                  title: this.$t("payments.patientPaymentEdit.notifications.editedButNotClosedErrorTitle"),
                  message: this.$t("payments.patientPaymentEdit.notifications.editedButNotClosedErrorText")
                });
              } else {
                this.$emit("alert", "danger", {
                  title: this.$t("payments.patientPaymentNew.notifications.addedButNotClosedErrorTitle"),
                  message: this.$t("payments.patientPaymentNew.notifications.addedButNotClosedErrorText")
                });
              }
              // navigate
              this.onGoBack();
              return false;
            }
          }
        }
      }
      // success message
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("payments.patientPaymentEdit.notifications.editedTitle"),
          message: this.$t("payments.patientPaymentEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("payments.patientPaymentNew.notifications.addedTitle"),
          message: this.$t("payments.patientPaymentNew.notifications.addedText")
        });
      }
      // navigate
      this.onGoBack();
    },

    async closeFile(fileId) {
      try {
        const form = { fileId: fileId };
        const res = await commonServices.post("compensations", form);

        return Boolean(res.data.id || res.data.id === 0);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      if (!this.moduleModeProp) {
        if (this.editMode) {
          navigate("accounting/payments/patientPayments");
        } else {
          navigate("accounting/payments/awaitingPayments", { isAwaitingProp: true });
        }
      } else {
        this.$emit("patientPaymentSaved");
      }
    }
  }
};
</script>

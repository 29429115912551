<template>
  <b-form-group
    :invalid-feedback="remindersInvalidFeedback"
    :state="remindersStateDisplay"
  >
    <div class="kt-reminders">
      <!-- title -->
      <h3
        v-show="!hideLabelProp"
        class="h6"
      >
        {{ $t("reminders.title") }}
      </h3>

      <!-- Reminders - list -->
      <div class="kt-item-list">
        <div
          v-for="(reminderItem, position) in reminders"
          :key="reminderItem.tempId"
          class="kt-item-list__item-wrapper"
          style="width: 100%"
        >
          <div
            class="kt-item-list__item"
            style="width: 100%;"
          >
            <!-- edit -->
            <b-row v-if="!viewModeProp">
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('reminders.delay')"
                  :state="!validationActiveProp || (reminderItem.delay && reminderItem.delay.match(/^\d+$/g) && reminderItem.delay !== '0') ? null : false"
                  class="mb-0"
                >
                  <b-form-input
                    v-model="reminderItem.delay"
                    :state="!validationActiveProp || (reminderItem.delay && reminderItem.delay.match(/^\d+$/g) && reminderItem.delay !== '0') ? null : false"
                    @input="sendToparent"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <InputPrice
                  :labelProp="$t('reminders.amount')"
                  :priceProp="reminderItem.amount"
                  :stateProp="!validationActiveProp || (reminderItem.amount !== null) ? null : false"
                  invalidFeedbackProp=""
                  @onUpdateParent="($event) => {
                    reminderItem.amount = $event;
                    sendToparent();
                  }"
                  @submit="$emit('submit', true)"
                ></InputPrice>
              </b-col>
            </b-row>
            <!-- view -->
            <b-row v-else>
              <b-col
                cols="12"
                md="9"
              >
                {{ $tc('reminders.days', Number(reminderItem.delay ), {number: Number(reminderItem.delay )}) + " : " + $n(reminderItem.amount, "currency") }}
              </b-col>
            </b-row>
            <!-- actions -->
            <div class="kt-item-list__item-actions">
              <!-- delete button -->
              <b-button
                v-if="!viewModeProp"
                class="btn-icon kt-item-list__item-delete"
                size="sm"
                variant="light"
                squared
                @click="onDeleteReminder(position)"
              >
                <b-icon icon="x"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- add reminder button -->
      <b-button
        v-if="!viewModeProp"
        size="sm"
        :variant="'outline-' + $systemSettings.theme"
        pill
        @click="addReminder"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("reminders.addButton") }}
      </b-button>
    </div>
  </b-form-group>
</template>

<script>
// components
import InputPrice from "@shared/views/Helpers/InputPrice";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import uniqueId from "lodash.uniqueid";

export default {
  components: { InputPrice },
  mixins: [userRights, error],
  props: {
    remindersProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    hideLabelProp: {
      type: Boolean
    },
    viewModeProp: {
      type: Boolean
    },
    validationActiveProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // base list
      reminders: []
    };
  },
  computed: {
    remindersState: function() {
      if (this.viewModeProp) {
        // validation not active
        return null;
      } else {
        // each reminder fields validation
        let state = null;
        for (const reminderItem of this.reminders) {
          if (reminderItem.amount === null) {
            state = false;
            break;
          }
          if (!reminderItem.delay || !reminderItem.delay.match(/^\d+$/g) || reminderItem.delay === "0") {
            state = false;
            break;
          }
        }
        return state;
      }
    },
    remindersStateDisplay: function() {
      if (!this.validationActiveProp) return null;
      return this.remindersState;
    },
    remindersInvalidFeedback: function() {
      // no error
      if (this.remindersStateDisplay === null) return "";

      // errors
      for (const reminderItem of this.reminders) {
        if (reminderItem.amount === null) {
          return this.$t("validationRules.required");
        } else if (!reminderItem.delay || reminderItem.delay === "0") {
          return this.$t("validationRules.required");
        } else if (!reminderItem.delay.match(/^\d+$/g)) {
          return "";
        }
      }
      return "";
    }
  },
  watch: {
    // import reminders and setup tempId
    remindersProp: {
      handler(val) {
        if (val && val.length > 0) {
          const reminders = JSON.parse(JSON.stringify(val));
          this.reminders = reminders.map((reminder) => {
            if (!reminder.tempId) {
              reminder.tempId = reminder.id || "new-" + uniqueId();
            }
            return reminder;
          });
        } else {
          this.reminders = [];
        }
      },
      immediate: true
    },
    // activate validation by prop
    validationActiveProp: {
      handler() {
        this.sendIsValid();
      }
    }
  },
  methods: {
    // validation
    sendIsValid() {
      this.$emit("updateIsValid", this.remindersState);
    },

    // Actions
    addReminder() {
      // add in displayed array
      this.reminders.push({
        id: null,
        tempId: "new-" + uniqueId(),
        delay: "",
        amount: 0
      });
      // send to parent
      this.sendToparent();
    },
    onUpdateReminder() {
      this.sendToparent();
    },
    onDeleteReminder(position) {
      this.reminders.splice(position, 1);
      // send to parent
      this.sendToparent();
    },
    // on update / finished
    sendToparent() {
      this.$emit("remindersUpdate", this.reminders);
      this.sendIsValid();
    }
  }
};
</script>

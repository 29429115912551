<template>
  <div class="kt-accounting-history">
    <!-- files List -->
    <h3 class="h4 kt-accounting__h3-title">
      {{ $t('accountingHistory.allFiles') }}
    </h3>
    <b-row class="mb-3">
      <b-col
        v-if="!isLoading && historyDataFiles && historyDataFiles.length"
        cols="12"
        md="8"
      >
        <div class="kt-item-list kt-item-list--full-width kt-item-list--shadow">
          <div
            class="kt-item-list__item-wrapper"
            style="margin-bottom: -12px; margin-top: 2px;"
          >
            <div
              class="kt-item-list__item"
              style="padding: 16px 20px; margin-bottom: 16px;"
            >
              <div
                v-for="(file, index) in historyDataFiles"
                :key="file.id"
                :style="{'margin-bottom': index !== (historyDataFiles.length - 1) ? '6px' : ''}"
              >
                <b-row class="align-items-center">
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <div style="line-height: 1.3;">
                      <div class="font-weight-semi-bold">
                        {{ !file.parentFileId ? $t('fileChooseFromIds.parentFile') : $t('fileChooseFromIds.complementaryFile') }}
                      </div>
                      <div
                        style="font-size: 15px;"
                        class="text-dark"
                      >
                        {{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(file.creationDate, $systemSettings.locale)) }}
                      </div>
                    </div>
                  </b-col>
                  <!-- statuses -->
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <div style="white-space: nowrap;">
                      <StatusBadge
                        v-if="file.filingStatus"
                        typeProp="filingStatus"
                        :statusNameProp="file.filingStatus.name"
                        isHiddenEmptyProp
                      />
                      <StatusBadge
                        v-if="file.medicalStatus"
                        typeProp="medicalStatus"
                        :statusNameProp="file.medicalStatus.name"
                        isHiddenEmptyProp
                        class="ml-1"
                      />
                      <StatusBadge
                        v-if="file.accountingStatus"
                        typeProp="accountingStatus"
                        :statusNameProp="file.accountingStatus.name"
                        isHiddenEmptyProp
                        class="ml-1"
                      />
                    </div>
                  </b-col>
                  <!-- actions -->
                  <b-col
                    v-show="isEnabled(['roleSpecific', 'accounting']) && !hideCloseFileProp"
                    cols="12"
                    md="3"
                    class="text-right"
                  >
                    <b-button
                      v-show="!file.compensationAmount"
                      variant="outline-secondary"
                      size="sm"
                      :disabled="file.accountingStatus.name !== 'invoiced'"
                      :tabindex="noFocusProp ? '-1' : '0'"
                      @click="closeFile(file)"
                    >
                      <span :style="awaitingPayments.length === 0 ? 'margin-right: 2px' : ''">{{ $t('accountingHistory.closeFile') }}</span>
                      <span v-if="file.rest < 0 && awaitingPayments.length === 0">{{ '+' + $n(-file.rest, "currency") }}</span>
                      <span v-if="file.rest > 0 && awaitingPayments.length === 0">{{ $n(-file.rest, "currency") }}</span>
                    </b-button>
                    <b-button
                      v-show="file.compensationAmount"
                      variant="outline-secondary"
                      size="sm"
                      :tabindex="noFocusProp ? '-1' : '0'"
                      @click="uncloseFile(file)"
                    >
                      <b-icon
                        icon="x"
                        :variant="file.compensationAmount < 0 ? 'success' : 'danger'"
                      ></b-icon>
                      <span :class="file.compensationAmount > 0 ? 'text-danger' : 'text-success'">
                        <span
                          class="font-weight-bold"
                          style="margin-right: 2px"
                        >{{ $t('accountingHistory.' + (file.compensationAmount > 0 ? 'losses' : 'gains')) + " " }}</span>
                        <span
                          v-if="file.compensationAmount < 0"
                          class="text-success font-weight-bold"
                        >{{ '+' + $n(-file.compensationAmount, "currency") }}</span>
                        <span
                          v-if="file.compensationAmount > 0"
                          class="text-danger font-weight-bold"
                        >{{ $n(-file.compensationAmount, "currency") }}</span>
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- divider -->
    <div
      v-show="!isLoading"
      class="kt-border-top-strong mb-3"
    ></div>

    <!-- invoices -->
    <div>
      <h3 class="h4 kt-accounting__h3-title">
        {{ $t('accountingHistory.invoices') }}
      </h3>
      <div
        v-for="invoice in invoices"
        :key="invoice.id"
        class="kt-accounting__section"
      >
        <!-- invoice header -->
        <div class="kt-accounting__section-header kt-accounting__section-header--main">
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left">
              <span class="kt-accounting__section-date">{{ $d(new Date(invoice.invoiceDate), "date") }}</span>
            </div>
            <div class="kt-accounting__container-center">
              <!-- title -->
              <h4 class="h6 kt-accounting__section-title">
                {{ $t("fileInvoiceTransformations." + invoice.invoiceType) }}
              </h4>
              <span class="kt-accounting__section-subtitle">
                <span v-if="!invoice.organisation">
                  <PersonName
                    :sexNameProp="fileDataProp.patient.sexName"
                    :firstNameProp="fileDataProp.patient.firstName"
                    :lastNameProp="fileDataProp.patient.lastName"
                    :maidenNameProp="fileDataProp.patient.maidenName"
                  />
                </span>
                <span v-else>
                  {{ invoice.organisation.name + (invoice.organisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') }}
                </span>
              </span>
              <b-button
                size="sm"
                class="kt-btn-transparent p-0 ml-1 align-baseline"
                :variant="$systemSettings.theme"
                :tabindex="noFocusProp ? '-1' : '0'"
                @click="goToInvoice(invoice)"
              >
                {{ $t('general.view') }}
              </b-button>
            </div>
          </div>
        </div>
        <!-- remindersBadge -->
        <div
          v-if="!invoice.organisation"
          class="kt-accounting__container"
        >
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- remindersBadge -->
            <div
              v-if="invoice.version && invoice.version > 1"
              class="kt-badge kt-badge--light-gray mb-1"
            >
              <b-icon
                icon="calendar-week"
                class="kt-badge__icon"
              ></b-icon>
              <span class="kt-badge__text font-weight-semi-bold">{{ $tc('feeNoteView.remindersBadge', invoice.version - 1, {remindersCount: invoice.version - 1}) }}</span>
            </div>
            <!-- debtCollectionBadge -->
            <div
              v-if="invoice.debtCollectionReportDate"
              class="kt-badge kt-badge--red mb-1"
            >
              <b-icon
                icon="calendar-x"
                class="kt-badge__icon"
              ></b-icon>
              <span class="kt-badge__text font-weight-semi-bold">{{ $t('feeNoteView.debtCollectionBadge', {date: $d(new Date(invoice.debtCollectionReportDate), "date")}) }}</span>
            </div>
          </div>
        </div>
        <!-- documentStatuses -->
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- documentStatuses -->
            <DocumentStatuses
              :dataProp="invoice"
              class="mb-2"
              style="margin-top: 1px"
            />
          </div>
        </div>
        <!-- fileInvoices -->
        <div
          v-for="fileInvoice in invoice.fileInvoices"
          :key="fileInvoice.id"
        >
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left"></div>
            <div class="kt-accounting__container-center">
              <!-- fileInvoice header -->
              <div class="kt-accounting__section-header">
                <!-- title -->
                <h4
                  v-if="historyDataFiles.length > 1"
                  class="h6 kt-accounting__section-title"
                >
                  {{ fileInvoice.file.parentFileId ? $t("accountingHistory.complementaryFile") : $t("accountingHistory.parentFile") }}
                </h4>
                <span
                  v-if="historyDataFiles.length > 1 && fileInvoice.file.id === fileIdProp"
                  class="kt-accounting__section-subtitle"
                  :class="'text-' + $systemSettings.theme"
                >{{ $t("accountingHistory.currentFile") }}</span>
                <!-- transformation badges -->
                <FileInvoiceTransformationBadge
                  :fileInvoiceProp="fileInvoice"
                  hideDetailsProp
                  cssDisplayProp="inline-block"
                />
                <!-- download patient careSheet -->
                <div v-if="fileInvoice.patientCareSheetId && (isEnabled(['roleSpecific', 'accounting']) || isEnabled(['roleSpecific', 'office']))">
                  <b-button
                    size="sm"
                    :variant="$systemSettings.theme"
                    :tabindex="noFocusProp ? '-1' : '0'"
                    @click="downloadPatientCareSheet(fileInvoice.patientCareSheetId)"
                  >
                    <b-icon icon="download"></b-icon>
                    {{ $t("accountingHistory.downloadPatientCareSheet") }}
                  </b-button>
                </div>
              </div>
              <!-- invoiceActs table -->
              <div
                class="kt-flex-table kt-accounting-table"
                style="margin-top: 2px"
              >
                <div
                  class="kt-flex-table__wrapper"
                  role="table"
                  :aria-label="$t('files.acts')"
                >
                  <!-- header line -->
                  <div
                    class="kt-flex-table__line kt-flex-table__line--header"
                    role="rowgroup"
                  >
                    <div
                      class="kt-flex-table__row first"
                      role="columnheader"
                    >
                      {{ $t('accountingHistory.invoiceActCode') }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="columnheader"
                    >
                      {{ $t('accountingHistory.invoiceActInvoiced') }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="columnheader"
                    >
                      {{ $t('accountingHistory.invoiceActPaid') }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="columnheader"
                    >
                      {{ $t('accountingHistory.invoiceActRest') }}
                    </div>
                  </div>
                  <!-- content lines -->
                  <div
                    v-for="(invoiceAct, index) in fileInvoice.invoiceActs"
                    :key="index"
                    class="kt-flex-table__line kt-flex-table__line--regular"
                    role="rowgroup"
                  >
                    <div
                      class="kt-flex-table__row first"
                      role="cell"
                    >
                      {{ invoiceAct.act.code }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="cell"
                    >
                      {{ invoiceAct.invoiced ? $n(invoiceAct.invoiced, "currency") : $t("general.emptyWithHyphen") }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="cell"
                    >
                      {{ invoiceAct.paid ? $n(invoiceAct.paid, "currency") : $t("general.emptyWithHyphen") }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="cell"
                    >
                      {{ invoiceAct.rest ? $n(invoiceAct.rest, "currency") : $t("general.emptyWithHyphen") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- invoice footer -->
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- total table -->
            <div
              class="kt-flex-table kt-accounting-table kt-accounting__total-table"
            >
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('files.acts')"
              >
                <!-- content lines -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ $t('accountingHistory.invoiceTotal') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ invoice.invoiced ? $n(invoice.invoiced, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ invoice.paid ? $n(invoice.paid, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ invoice.rest ? $n(invoice.rest, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- empty invoice -->
      <div v-if="!isLoading && invoices.length === 0">
        <div class="kt-accounting__container mb-3">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- title -->
            <span class="text-dark text-15 pb-3">
              {{ $t('accountingHistory.emptyInvoice') }}
            </span>
          </div>
        </div>
      </div>
      <!-- loader -->
      <div v-show="isLoading">
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div
      v-show="!isLoading && awaitingFileInvoices.length"
      class="w-100 border-top mt-1 mb-3"
    ></div>

    <!-- waitingForInvoicing -->
    <div v-show="!isLoading && awaitingFileInvoices.length">
      <h3 class="h4 kt-accounting__h3-title">
        {{ $t('accountingHistory.waitingForInvoicing') }}
      </h3>
      <!-- awaitingFileInvoice tables -->
      <div
        v-for="(file, fileIndex) in historyDataFiles"
        v-show="file.awaitingFileInvoices && file.awaitingFileInvoices.length"
        :key="file.id"
        class="kt-accounting__section"
        :class="{'mb-2': fileIndex !== historyDataFiles.length - 1}"
      >
        <div
          v-for="fileInvoice in file.awaitingFileInvoices"
          :key="fileInvoice.id"
        >
          <!-- awaitingFileInvoice header -->
          <div class="kt-accounting__section-header kt-accounting__section-header--main">
            <div class="kt-accounting__container">
              <div class="kt-accounting__container-left">
              </div>
              <div class="kt-accounting__container-center">
                <!-- title -->
                <h4 class="h6 kt-accounting__section-title">
                  <span v-if="!fileInvoice.file.invoicedOrganisation">{{ $t("fileInvoiceTransformations.feeNote") }}</span>
                  <span v-else-if="fileInvoice.file.invoicedOrganisation.organisationType.name === 'healthCare'">{{ $t("fileInvoiceTransformations.careSheet") }}</span>
                  <span v-else>{{ $t("fileInvoiceTransformations.invoiceReport") }}</span>
                </h4>
                <span
                  v-if="fileInvoice.file.invoicedOrganisation"
                  class="kt-accounting__section-subtitle"
                >
                  <span>
                    {{ fileInvoice.file.invoicedOrganisation.name + (fileInvoice.file.invoicedOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <!-- awaitingFileInvoice container -->
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left"></div>
            <div class="kt-accounting__container-center">
              <!-- fileInvoice header -->
              <div class="kt-accounting__section-header">
                <!-- title -->
                <h4
                  v-if="historyDataFiles.length > 1"
                  class="h6 kt-accounting__section-title"
                >
                  {{ fileInvoice.file.parentFileId ? $t("accountingHistory.complementaryFile") : $t("accountingHistory.parentFile") }}
                </h4>
                <span
                  v-if="historyDataFiles.length > 1 && file.id === fileIdProp"
                  class="kt-accounting__section-subtitle"
                  :class="'text-' + $systemSettings.theme"
                >{{ $t("accountingHistory.currentFile") }}</span>
                <!-- transformation badges -->
                <FileInvoiceTransformationBadge
                  :fileInvoiceProp="fileInvoice"
                  hideDetailsProp
                  cssDisplayProp="inline-block"
                />
              </div>
              <!-- invoiceActs table -->
              <div
                class="kt-flex-table kt-accounting-table"
                style="margin-top: 2px"
              >
                <div
                  class="kt-flex-table__wrapper"
                  role="table"
                  :aria-label="$t('files.acts')"
                >
                  <!-- header line -->
                  <div
                    class="kt-flex-table__line kt-flex-table__line--header"
                    role="rowgroup"
                  >
                    <div
                      class="kt-flex-table__row first"
                      role="columnheader"
                    >
                      {{ $t('careSheets.invoiceActCode') }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="columnheader"
                    >
                      {{ $t('careSheets.invoiceActInvoiced') }}
                    </div>
                  </div>
                  <!-- content lines -->
                  <div
                    v-for="(invoiceAct, index) in fileInvoice.invoiceActs"
                    :key="index"
                    class="kt-flex-table__line kt-flex-table__line--regular"
                    role="rowgroup"
                  >
                    <div
                      class="kt-flex-table__row first"
                      role="cell"
                    >
                      {{ invoiceAct.act.code }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="cell"
                    >
                      {{ invoiceAct.invoiced ? $n(invoiceAct.invoiced, "currency") : $t("general.emptyWithHyphen") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- awaiting total -->
      <div class="kt-flex-table">
        <div class="kt-flex-table__bottom">
          <div class="kt-flex-table__amount-container">
            <span>{{ $t("accountingHistory.amountForInvoicing") }}</span><span class="kt-flex-table__amount">{{ $n(totalAwaitingFileInvoices, "currency") }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-3"></div>

    <!-- payments -->
    <div>
      <h3 class="h4 kt-accounting__h3-title">
        {{ $t('accountingHistory.payments') }}
      </h3>
      <div
        v-for="payment in payments"
        :key="payment.paymentCategory + '_' + payment.id"
        class="kt-accounting__section"
      >
        <!-- payment header -->
        <div class="kt-accounting__section-header kt-accounting__section-header--main">
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left">
              <span class="kt-accounting__section-date">{{ $d(new Date(payment.paymentDate), "date") }}</span>
            </div>
            <div class="kt-accounting__container-center">
              <!-- title -->
              <h4 class="h6 kt-accounting__section-title">
                <span v-if="!payment.organisation">
                  {{ $t("files.patient") }}
                </span>
                <span v-else>
                  {{ payment.organisation.name + (payment.organisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') }}
                </span>
              </h4>
              <span
                v-if="!payment.organisation"
                class="kt-accounting__section-subtitle"
              >
                {{ getPatientPaymentText(payment) }}
              </span>
              <b-button
                v-show="!isRole('secretary') && !isRole('chiefSecretary')"
                size="sm"
                class="kt-btn-transparent p-0 ml-1 align-baseline"
                :variant="$systemSettings.theme"
                :tabindex="noFocusProp ? '-1' : '0'"
                @click="goToPayment(payment)"
              >
                {{ $t('general.view') }}
              </b-button>
            </div>
            <div
              v-show="payment.awaitingPaymentUser"
              class="kt-accounting__container-right"
            >
              <div class="kt-accounting__section-subtitle">
                <span>{{ $t("accountingHistory.awaitingPaymentUser") }}</span>
                <span class="font-weight-semi-bold">{{ payment.awaitingPaymentUser ? payment.awaitingPaymentUser.firstName + " " + payment.awaitingPaymentUser.lastName : "" }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- payment body -->
        <div
          v-for="filePayment in payment.filePayments"
          :key="filePayment.id"
        >
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left"></div>
            <div class="kt-accounting__container-center">
              <!-- fileInvoice header -->
              <div
                v-if="historyDataFiles.length > 1"
                class="kt-accounting__section-header"
              >
                <h4 class="h6 kt-accounting__section-title">
                  {{ filePayment.file.parentFileId ? $t("accountingHistory.complementaryFile") : $t("accountingHistory.parentFile") }}
                </h4>
                <span
                  v-show="filePayment.file.id === fileIdProp"
                  class="kt-accounting__section-subtitle"
                  :class="'text-' + $systemSettings.theme"
                >{{ $t("accountingHistory.currentFile") }}</span>
              </div>
              <!-- invoiceActs table -->
              <div
                class="kt-flex-table kt-accounting-table"
                style="margin-top: 2px"
              >
                <div
                  class="kt-flex-table__wrapper"
                  role="table"
                  :aria-label="$t('files.acts')"
                >
                  <!-- header line -->
                  <div
                    class="kt-flex-table__line kt-flex-table__line--header"
                    role="rowgroup"
                  >
                    <div
                      class="kt-flex-table__row first"
                      role="columnheader"
                    >
                      {{ $t('accountingHistory.invoiceActCode') }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="columnheader"
                    >
                      {{ $t('accountingHistory.invoiceActPaid') }}
                    </div>
                  </div>
                  <!-- content lines -->
                  <div
                    v-for="(paymentAct, index) in filePayment.paymentActs"
                    :key="index"
                    class="kt-flex-table__line kt-flex-table__line--regular"
                    role="rowgroup"
                  >
                    <div
                      class="kt-flex-table__row first"
                      role="cell"
                    >
                      {{ paymentAct.act.code }}
                    </div>
                    <div
                      class="kt-flex-table__row"
                      role="cell"
                    >
                      {{ paymentAct.paid ? $n(paymentAct.paid, "currency") : $t("general.emptyWithHyphen") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- payment footer -->
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- total table -->
            <div
              class="kt-flex-table kt-accounting-table kt-accounting__total-table"
            >
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('files.acts')"
              >
                <!-- content lines -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ $t('accountingHistory.paymentTotal') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ payment.currentFilesPaid ? $n(payment.currentFilesPaid, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                </div>
              </div>
            </div>
            <!-- patient payment contains other files -->
            <div
              v-if="payment.paymentCategory === 'patientPayment' && payment.currentFilesPaid !== payment.paid"
              class="kt-badge kt-badge--blue mb-3"
              style="vertical-align: bottom; margin-top: 4px;"
            >
              <b-icon
                icon="info-circle"
                class="kt-badge__icon"
              ></b-icon>
              <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('accountingHistory.otherFilesPaid') + $n(payment.paid, "currency") }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <!-- empty payment -->
      <div v-if="!isLoading && payments.length === 0">
        <div class="kt-accounting__container mb-3">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- title -->
            <span class="text-dark text-15 pb-3">
              {{ $t('accountingHistory.emptyPayment') }}
            </span>
          </div>
        </div>
      </div>
      <!-- loader -->
      <div v-show="isLoading">
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div
      v-show="!isLoading && awaitingPayments.length > 0"
      class="w-100 border-top mt-1 mb-3"
    ></div>

    <!-- awaiting payments -->
    <div v-show="!isLoading && awaitingPayments.length > 0">
      <h3 class="h4 kt-accounting__h3-title">
        {{ $t('accountingHistory.awaitingPayments') }}
      </h3>
      <div
        v-for="payment in awaitingPayments"
        :key="payment.id"
        class="kt-accounting__section"
      >
        <!-- payment header -->
        <div class="kt-accounting__section-header kt-accounting__section-header--main">
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left">
              <span class="kt-accounting__section-date">{{ $d(new Date(payment.paymentDate), "date") }}</span>
            </div>
            <div class="kt-accounting__container-center">
              <!-- title -->
              <h4 class="h6 kt-accounting__section-title">
                {{ $t("files.patient") }}
              </h4>
              <span class="kt-accounting__section-subtitle">
                {{ getPatientPaymentText(payment) }}
              </span>
              <b-button
                v-show="!isRole('secretary') && !isRole('chiefSecretary')"
                size="sm"
                class="kt-btn-transparent p-0 ml-1 align-baseline"
                :variant="$systemSettings.theme"
                :tabindex="noFocusProp ? '-1' : '0'"
                @click="navigate('accounting/payments/patientPayments', {
                  accessNumberProp: (fileDataProp ? fileDataProp.accessNumber : ''),
                  isAwaitingProp: true
                })"
              >
                {{ $t('general.view') }}
              </b-button>
            </div>
            <div
              v-show="payment.awaitingPaymentUser"
              class="kt-accounting__container-right"
            >
              <div class="kt-accounting__section-subtitle">
                <span>{{ $t("accountingHistory.awaitingPaymentUser") }}</span>
                <span class="font-weight-semi-bold">{{ payment.awaitingPaymentUser ? payment.awaitingPaymentUser.firstName + " " + payment.awaitingPaymentUser.lastName : "" }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- payment footer -->
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- total table -->
            <div class="kt-flex-table kt-accounting-table kt-accounting__total-table">
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('accountingHistory.paymentTotal')"
              >
                <!-- content lines -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ $t('accountingHistory.paymentTotal') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ $n(payment.paid, "currency") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div
      v-show="!isLoading && compensationPayments.length > 0"
      class="w-100 border-top mt-1 mb-3"
    ></div>

    <!-- compensationPayments -->
    <div v-show="!isLoading && compensationPayments.length > 0">
      <h3 class="h4 kt-accounting__h3-title">
        {{ $t('accountingHistory.compensationPayments') }}
      </h3>
      <div
        v-for="compensationPayment in compensationPayments"
        :key="compensationPayment.id"
        class="kt-accounting__section"
      >
        <!-- payment header -->
        <div class="kt-accounting__section-header kt-accounting__section-header--main">
          <div class="kt-accounting__container">
            <div class="kt-accounting__container-left">
              <span class="kt-accounting__section-date">{{ $d(new Date(compensationPayment.compensation.date), "date") }}</span>
            </div>
            <div class="kt-accounting__container-center">
              <!-- title
              <h4 class="h6 kt-accounting__section-title">
                {{ $t('accountingHistory.' + (compensationPayment.paid > 0 ? 'losses' : 'gains')) }}
              </h4> -->
            </div>
            <div
              v-show="compensationPayment.user"
              class="kt-accounting__container-right"
            >
              <div class="kt-accounting__section-subtitle">
                <span>{{ $t("accountingHistory.awaitingPaymentUser") }}</span>
                <span class="font-weight-semi-bold">{{ compensationPayment.user ? compensationPayment.user.firstName + " " + compensationPayment.user.lastName : "" }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- payment body -->
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- fileInvoice header -->
            <div
              v-if="historyDataFiles.length > 1"
              class="kt-accounting__section-header"
            >
              <h4 class="h6 kt-accounting__section-title">
                {{ compensationPayment.file.parentFileId ? $t("accountingHistory.complementaryFile") : $t("accountingHistory.parentFile") }}
              </h4>
              <span
                v-show="compensationPayment.file.id === fileIdProp"
                class="kt-accounting__section-subtitle"
                :class="'text-' + $systemSettings.theme"
              >{{ $t("accountingHistory.currentFile") }}</span>
            </div>
          </div>
        </div>
        <!-- payment footer -->
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- total table -->
            <div class="kt-flex-table kt-accounting-table kt-accounting__total-table">
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('accountingHistory.compensationPayments')"
              >
                <!-- content lines -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ $t('accountingHistory.' + (compensationPayment.paid >= 0 ? 'losses' : 'gains')) }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ $n(-compensationPayment.paid, "currency") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-3"></div>

    <!-- file totals -->
    <div>
      <h3 class="h4 kt-accounting__h3-title">
        {{ $t('accountingHistory.accountingTotals') }}
      </h3>
      <div v-if="!isLoading && (invoices.length || awaitingFileInvoices.length)">
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- notification : has complementary files -->
            <div
              v-if="historyDataFiles && historyDataFiles.length > 1"
              class="kt-badge kt-badge--blue mb-2"
            >
              <b-icon
                icon="info-circle"
                class="kt-badge__icon"
              ></b-icon>
              <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $tc('accountingHistory.fileTotalsContainsComplementaries', historyDataFiles.length - 1, {complementariesCount: historyDataFiles.length - 1}) }}</span></span>
            </div>
            <!-- totals -->
            <div>
              <!-- Total Invoiced -->
              <div
                class="kt-flex-table__amount-container"
                :style="{'margin-left': awaitingFileInvoices.length ? '-2px' : ''}"
              >
                <span>{{ $t('accountingHistory.accountingTotalsInvoiced') }}</span><span class="kt-flex-table__amount">{{ $n(totalInvoiced, "currency") }}</span>
              </div>
              <!-- AwaitingForInvoice -->
              <div
                v-show="awaitingFileInvoices.length"
                class="kt-flex-table__amount-container"
              >
                <span>{{ $t('accountingHistory.accountingTotalsAwaitingForInvoice') }}</span><span class="kt-flex-table__amount">{{ $n(totalAwaitingFileInvoices, "currency") }}</span>
              </div>
              <!-- Total Paid -->
              <div
                class="kt-flex-table__amount-container"
                style="margin-left: -2px;"
              >
                <span>{{ $t('accountingHistory.accountingTotalsPaid') }}</span><span class="kt-flex-table__amount">{{ $n(totalPaid, "currency") }}</span>
              </div>
              <!-- AwaitingPayments -->
              <div
                v-show="awaitingPayments.length > 0"
                class="kt-flex-table__amount-container"
                style="margin-left: -2px;"
              >
                <span>{{ $t('accountingHistory.accountingTotalsAwaitingPayments') }}</span><span class="kt-flex-table__amount">{{ $n(totalAwaitingPayments, "currency") }}</span>
              </div>
              <!-- Compensation -->
              <div
                v-show="compensationPayments.length > 0"
                class="kt-flex-table__amount-container"
                :class="{'text-success': totalCompensations < 0, 'text-danger': totalCompensations > 0}"
                style="margin-left: -2px;"
              >
                <span>{{ $t('accountingHistory.' + (totalCompensations >= 0 ? 'losses' : 'gains')) }}</span><span class="kt-flex-table__amount">{{ $n(-totalCompensations, "currency") }}</span>
              </div>
              <!-- Total Balance -->
              <div
                class="kt-flex-table__amount-container"
                :class="totalBalanceColor"
                style="margin-left: -2px;"
              >
                <span>{{ $t('accountingHistory.accountingTotalsRest') }}</span><span class="kt-flex-table__amount">{{ $n(totalBalance, "currency") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- empty invoice -->
      <div v-if="!isLoading && invoices.length === 0 && awaitingFileInvoices.length === 0">
        <div class="kt-accounting__container">
          <div class="kt-accounting__container-left"></div>
          <div class="kt-accounting__container-center">
            <!-- title -->
            <span class="text-dark text-15 pb-3">
              {{ $t('accountingHistory.emptyInvoice') }}
            </span>
          </div>
        </div>
      </div>
      <!-- loader -->
      <div v-show="isLoading">
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </div>
    </div>

    <!-- spacer -->
    <div class="pt-3"></div>
  </div>
</template>

<script>
// components
import FileInvoiceTransformationBadge from "@shared/views/Helpers/FileInvoiceTransformationBadge";
import PersonName from "@shared/views/Helpers/PersonName";
import DocumentStatuses from "@shared/views/Helpers/DocumentStatuses";
import StatusBadge from "@shared/views/Helpers/StatusBadge";
// services
import commonServices from "@shared/services/API/commonServices";
import compensationsServices from "@/services/API/compensationsServices";
// helpers
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
import userRights from "@/services/UI/userRights";
import accountingHistoryHelpers from "@shared/services/UI/accountingHistoryHelpers";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: {
    FileInvoiceTransformationBadge,
    PersonName,
    DocumentStatuses,
    StatusBadge
  },
  mixins: [error, userRights, date, string, accountingHistoryHelpers],
  props: {
    fileIdProp: {
      type: Number,
      default: null
    },
    fileDataProp: {
      type: Object,
      default: null
    },
    hideCloseFileProp: {
      type: Boolean
    },
    noFocusProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      isLoading: true,
      isDownloading: false,
      historyDataFiles: [],
      awaitingFileInvoices: [],
      invoices: [],
      awaitingPayments: [],
      payments: [],
      totalAwaitingFileInvoices: 0,
      compensationPayments: [],
      totalCompensations: 0,
      totalInvoiced: 0,
      totalAwaitingPayments: 0,
      totalPaid: 0
    };
  },
  computed: {
    totalBalance: function() {
      let balance = (this.totalInvoiced + this.totalAwaitingFileInvoices) - this.totalPaid - this.totalAwaitingPayments - this.totalCompensations;
      balance = Math.round(balance * 100) / 100;
      // remove negative sign when -0,00
      if (Math.abs(balance) === 0) balance = 0;
      return balance;
    },
    totalBalanceColor: function() {
      if (this.totalBalance === 0) {
        return "text-success";
      } else if (this.totalBalance > 0) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.importAccountingHistory();
  },
  methods: {
    async importAccountingHistory() {
      try {
        this.isLoading = true;

        // get accounting history
        const accountingHistory = await this.mixinGetAccountingHistory(
          this.fileIdProp,
          this.fileDataProp.parentFileId,
          this.fileDataProp.childrenFiles && this.fileDataProp.childrenFiles.length ? this.fileDataProp.childrenFiles.map(item => item.id) : []
        );
        this.historyDataFiles = accountingHistory.historyDataFiles;
        this.awaitingFileInvoices = accountingHistory.awaitingFileInvoices;
        this.totalAwaitingFileInvoices = accountingHistory.totalAwaitingFileInvoices;
        this.invoices = accountingHistory.invoices;
        this.totalInvoiced = accountingHistory.totalInvoiced;
        this.awaitingPayments = accountingHistory.awaitingPayments;
        this.totalAwaitingPayments = accountingHistory.totalAwaitingPayments;
        this.payments = accountingHistory.payments;
        this.totalPaid = accountingHistory.totalPaid;
        this.compensationPayments = accountingHistory.compensationPayments;
        this.totalCompensations = accountingHistory.totalCompensations;

        const balance = this.totalInvoiced + this.totalAwaitingFileInvoices - this.totalPaid - this.totalAwaitingPayments;
        this.$emit("loaded", {
          historyDataFiles: this.historyDataFiles,
          totalCompensations: this.totalCompensations,
          balance: Math.round(balance * 100) / 100,
          awaitingPaymentIds: this.awaitingPayments.map(v => v.id)
        });

        this.isLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    goToInvoice(invoice) {
      if (invoice.invoiceType === "careSheet") {
        navigate("accounting/invoices/careSheet/view", { careSheetIdProp: invoice.id });
      } else if (invoice.invoiceType === "feeNote") {
        navigate("accounting/invoices/feeNote/view", { feeNoteIdProp: invoice.id });
      } else if (invoice.invoiceType === "invoiceReport") {
        navigate("accounting/invoices/invoiceReport/view", { invoiceReportIdProp: invoice.id });
      }
    },
    goToPayment(payment) {
      if (payment.paymentCategory === "healthCarePayment") {
        navigate("accounting/payments/healthCarePayment/linesEdit", {
          healthCarePaymentIdProp: payment.id,
          accessNumberProp: (this.fileDataProp ? this.fileDataProp.accessNumber : "")
        });
      } else if (payment.paymentCategory === "patientPayment") {
        navigate("accounting/payments/patientPayments", {
          accessNumberProp: (this.fileDataProp ? this.fileDataProp.accessNumber : "")
        });
      } else if (payment.paymentCategory === "paymentReport") {
        navigate("accounting/payments/paymentReport/paymentLinesEdit", {
          paymentReportIdProp: payment.id,
          paymentGroupIdProp: payment.paymentGroupId,
          accessNumberProp: (this.fileDataProp ? this.fileDataProp.accessNumber : "")
        });
      }
    },
    async downloadPatientCareSheet(patientCareSheetId) {
      try {
        this.isDownloading = true;

        const resFile = await commonServices.export("patientCareSheets", patientCareSheetId, "pdf");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, this.fileDataProp.accessNumber + ".pdf");
        }

        this.isDownloading = false;
      } catch (err) {
        this.handleErrors(err);
        this.isDownloading = false;
      }
    },
    async closeFile(file) {
      try {
        // validation
        if (file.accountingStatus.name === "created") {
          this.$emit("alert", "danger", {
            title: this.$t("compensations.notifications.fileNotInvoicedErrorTitle"),
            message: this.$t("compensations.notifications.fileNotInvoicedErrorText")
          });
          return false;
        }
        if (file.accountingStatus.name === "paid") {
          this.$emit("alert", "danger", {
            title: this.$t("compensations.notifications.fileAlreadyPaidErrorTitle"),
            message: this.$t("compensations.notifications.fileAlreadyPaidErrorText")
          });
          return false;
        }
        if (this.awaitingPayments.length !== 0) {
          this.$emit("alert", "danger", {
            title: this.$t("compensations.notifications.fileHasAwaitingPaymentTitle"),
            message: this.$t("compensations.notifications.fileHasAwaitingPaymentText")
          });
          return false;
        }

        const form = {
          fileId: file.id
        };
        const res = await commonServices.post("compensations", form);

        if (res.data.id || res.data.id === 0) {
          this.$emit("alert", "success", {
            title: this.$t("compensations.notifications.addedTitle"),
            message: this.$t("compensations.notifications.addedText")
          });
          const currentFile = this.historyDataFiles.find(v => v.id === file.id);
          if (res.data.id) {
            const compensationPayment = {
              id: "new" + new Date().valueOf(),
              fileId: file.id,
              file: file,
              paid: file.rest,
              compensationId: res.data.id,
              compensation: {
                id: res.data.id,
                date: this.getIsoDate(),
                paid: file.rest
              }
            };
            currentFile.compensationAmount = file.rest;
            currentFile.compensationPayments.push(compensationPayment);
            this.compensationPayments.push(compensationPayment);
            this.updateCompensationTotal();
          }
          // update accountingHistory status
          const resFile = await commonServices.get("files", file.id);
          currentFile.accountingStatus = resFile.data.accountingStatus;
          // update fileView statuses
          this.$emit("editedCompensation", resFile.data);
          // if closed without compensation, need to refresh the entire accountingHistory
          if (res.data.id === 0) {
            await this.importAccountingHistory();
          }
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("compensations.notifications.additionErrorTitle"),
            message: this.$t("compensations.notifications.additionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async uncloseFile(file) {
      try {
        // validation
        const currentFile = this.historyDataFiles.find(v => v.id === file.id);
        if (currentFile.compensationAmount === 0) return false;

        // confirmation pop up
        const result = await this.$bvModal.msgBoxConfirm(this.$t("compensationDelete.text"), {
          title: this.$t("compensationDelete.title"),
          okVariant: "danger",
          okTitle: this.$t("compensationDelete.ok"),
          cancelTitle: this.$t("compensationDelete.cancel"),
          centered: true
        });
        // delete
        if (result) {
          const res = await compensationsServices.deleteByFileId(file.id);
          if (res.data === true) {
            this.$emit("alert", "success", {
              title: this.$t("compensationDelete.notifications.deletedTitle"),
              message: this.$t("compensationDelete.notifications.deletedText")
            });
            const currentFile = this.historyDataFiles.find(v => v.id === file.id);
            const compensationPayment = {
              id: "new" + new Date().valueOf(),
              file: file,
              fileId: file.id,
              paid: -currentFile.compensationAmount,
              compensationId: res.data.id,
              compensation: {
                id: res.data.id,
                date: this.getIsoDate(),
                paid: -currentFile.compensationAmount
              }
            };
            currentFile.compensationAmount = 0;
            currentFile.compensationPayments.push(compensationPayment);
            this.compensationPayments.push(compensationPayment);
            this.updateCompensationTotal();
            // update accountingHistory status
            const resFile = await commonServices.get("files", file.id);
            currentFile.accountingStatus = resFile.data.accountingStatus;
            // update fileView statuses
            this.$emit("editedCompensation", resFile.data);
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("compensationDelete.notifications.deletedErrorTitle"),
              message: this.$t("compensationDelete.notifications.deletedErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateCompensationTotal() {
      let totalCompensations = this.historyDataFiles.reduce((total, v) => { return total + v.compensationAmount; }, 0);
      totalCompensations = Math.round(totalCompensations * 100) / 100;
      // remove negative sign when -0,00
      if (Math.abs(totalCompensations) === 0) totalCompensations = 0;
      this.totalCompensations = totalCompensations;
    },
    getPatientPaymentText(payment) {
      let output = "";
      if (payment.paymentType) {
        output += this.$t("paymentTypes." + payment.paymentType.name);
      }
      if (payment.checkNumber) {
        if (output) output += " - ";
        output += payment.checkNumber;
      }
      if (payment.payerName) {
        if (output) output += " - ";
        output += this.$t("accountingHistory.payerNamePrefix") + payment.payerName;
      }
      return output;
    }
  }
};
</script>

<style scoped src="./AccountingHistory.scss" lang="scss"></style>

<template>
  <div class="kt-file-choose-from-ids">
    <!-- modal header -->
    <header
      class="modal-header"
      style="margin: -1rem -1rem 0 -1rem;"
    >
      <div>
        <!-- title -->
        <h5
          class="modal-title kt-page-header__title h4 mb-0"
          style="font-size: 22px; line-height: 24px;"
        >
          {{ hasOnlyComplementaries ? $t("fileChooseFromIds.pageTitleComplementaries") : $t("fileChooseFromIds.pageTitle") }}
        </h5>
        <!-- subtitle -->
        <p
          v-if="!hasOnlyComplementaries"
          class="kt-page-header__subtitle mb-0 mt-1 line-height-sm text-secondary"
        >
          {{ $t('fileChooseFromIds.pageSubtitleStart') }}
          <em>{{ $t('fileChooseFromIds.accessNumber', {accessNumber: fileOptions.length ? fileOptions[0].accessNumber : ''}) }}</em>
          {{ $t('fileChooseFromIds.pageSubtitleEnd') }}
        </p>
      </div>
      <!-- close -->
      <button
        ref="closeButton"
        type="button"
        aria-label="Close"
        class="close"
        @click="$emit('chosen', null)"
      >
        {{ $t('general.closeX') }}
      </button>
    </header>

    <!-- file list -->
    <div>
      <!-- empty -->
      <div
        v-if="!fileOptions || !fileOptions.length"
        class="text-center text-15 text-secondary mt-3 mb-1"
      >
        {{ hasOnlyComplementaries ? $t('fileChooseFromIds.emptyComplementaries') : $t('fileChooseFromIds.emptyFiles') }}
      </div>
      <!-- list -->
      <div
        v-for="(file, index) in fileOptions"
        :key="file.id"
        class="kt-file-choose-from-ids__item"
      >
        <b-link
          :ref="index === (fileOptions.length - 1) ? 'firstItemLink' : null"
          class="kt-file-choose-from-ids__link"
          :class="{'kt-file-choose-from-ids__link--current': currentFileIdProp === file.id}"
          @click.prevent="() => {
            if (currentFileIdProp !== file.id) $emit('chosen', file);
          }"
        >
          <span class="kt-file-choose-from-ids__link-wrapper d-flex justify-content-between align-items-center">
            <span class="d-block">
              <span class="font-weight-semi-bold">{{ !file.parentFileId ? $t('fileChooseFromIds.parentFile') : $t('fileChooseFromIds.complementaryFile') }}</span>
              <span class="d-block text-15">
                {{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(file.creationDate, $systemSettings.locale)) }}
              </span>
              <!-- cancel complementary button -->
              <span
                v-show="(file.medicalStatus.name !== 'cancelled') &&
                  (file.filingStatus.name === 'validatedForAccounting' || file.filingStatus.name === 'validatedForDiagnosis') &&
                  displayDeleteFileProp && file.parentFileId && userCanDeleteFile"
                style="display: block;"
              >
                <b-button
                  :variant="$systemSettings.theme"
                  class="kt-btn-transparent p-0 my-1 text-secondary text-13"
                  @click.stop="deleteFile(file)"
                >{{ $t("general.cancel") }}</b-button>
              </span>
            </span>
            <!-- statuses -->
            <span class="d-block">
              <div style="white-space: nowrap;">
                <StatusBadge
                  v-if="displayedStatusesProp.includes('filing') && file.filingStatus"
                  typeProp="filingStatus"
                  :statusNameProp="file.filingStatus.name"
                  isHiddenEmptyProp
                />
                <StatusBadge
                  v-if="displayedStatusesProp.includes('medical') && file.medicalStatus"
                  typeProp="medicalStatus"
                  :statusNameProp="file.medicalStatus.name"
                  isHiddenEmptyProp
                  class="ml-1"
                />
                <StatusBadge
                  v-if="displayedStatusesProp.includes('accounting') && file.accountingStatus"
                  typeProp="accountingStatus"
                  :statusNameProp="file.accountingStatus.name"
                  isHiddenEmptyProp
                  class="ml-1"
                />
              </div>
            </span>
          </span>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
// services
import filesServices from "@/services/API/filesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
// components
import StatusBadge from "@shared/views/Helpers/StatusBadge";

export default {
  components: {
    StatusBadge
  },
  mixins: [userRights, error, date, string],
  props: {
    fileOptionsProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currentFileIdProp: {
      type: Number,
      default: null
    },
    displayedStatusesProp: {
      type: Array,
      default: function() {
        return [
          "filing",
          "medical",
          "accounting"
        ];
      }
    },
    hasOnlyComplementaries: {
      type: Boolean
    },
    displayDeleteFileProp: {
      type: Boolean
    }
  },
  data() {
    return {
      fileOptions: []
    };
  },
  computed: {
    userCanDeleteFile: function() {
      return this.isEnabled(["roleSpecific", "cytotechnician"]);
    }
  },
  watch: {
    fileOptionsProp: {
      handler: function(val) {
        const files = JSON.parse(JSON.stringify(val));
        this.fileOptions = files.sort((a, b) => a.id - b.id);
        this.$nextTick(() => {
          this.focusFirstElement();
        });
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    focusFirstElement() {
      this.$refs.closeButton.focus();
    },
    async deleteFile(file) {
      // can't delete if validatedTechnically
      if (file.medicalStatus.name === "validatedTechnically") {
        this.$emit("alert", "danger", {
          title: this.$t("fileDelete.notifications.validatedTechnicallyTitle"),
          message: this.$t("fileDelete.notifications.validatedTechnicallyText")
        });
        return false;
      }
      // can't delete if validatedMedically
      if (file.medicalStatus.name === "validatedMedically") {
        this.$emit("alert", "danger", {
          title: this.$t("fileDelete.notifications.validatedMedicallyTitle"),
          message: this.$t("fileDelete.notifications.validatedMedicallyText")
        });
        return false;
      }

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("fileDelete.text"), {
        title: this.$t("fileDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("fileDelete.ok"),
        cancelTitle: this.$t("fileDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await filesServices.cancelComplementary(file.id);
        if (res.data === true) {
          // SUCCESS
          this.$emit("alert", "success", {
            title: this.$t("fileDelete.notifications.deletedTitle"),
            message: this.$t("fileDelete.notifications.deletedText")
          });
          // auto-focus
          // this.$refs.addLineButton.focus();
          this.$emit("fileDeleted", file);
          return true;
        } else {
          if (res.data.error === "noParentFile") {
            // pas complementaire
            this.$emit("alert", "danger", {
              title: this.$t("fileDelete.notifications.notComplementaryTitle"),
              message: this.$t("fileDelete.notifications.notComplementaryText")
            });
          } else if (res.data.error === "medicalStatusNotCreated") {
            // medicalStatusNotCreated
            this.$emit("alert", "danger", {
              title: this.$t("fileDelete.notifications.medicalStatusNotCreatedTitle"),
              message: this.$t("fileDelete.notifications.medicalStatusNotCreatedText")
            });
          } else if (res.data.error === "medicalReportAlreadyCreated") {
            // medicalReportAlreadyCreated
            this.$emit("alert", "danger", {
              title: this.$t("fileDelete.notifications.medicalReportAlreadyCreatedTitle"),
              message: this.$t("fileDelete.notifications.medicalReportAlreadyCreatedText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("fileDelete.notifications.deletedErrorTitle"),
              message: this.$t("fileDelete.notifications.deletedErrorText")
            });
          }
          return false;
        }
      }
    }
  }
};
</script>

<style scoped src="./FileChooseFromIds.scss" lang="scss"></style>

<template>
  <div
    class="kt-input-search-invoicedFile"
    :class="'kt-input-search-invoicedFile--' + $systemSettings.theme"
  >
    <b-form @submit.prevent="searchAccessNumber">
      <b-form-group
        class="kt-input-search__input-container mb-0"
        :label="labelProp"
        :state="accessNumberError !== '' ? false : stateProp"
        :invalid-feedback="accessNumberError !== '' ? $t('inputSearchInvoicedFile.validations.' + accessNumberError) : invalidFeedbackProp"
      >
        <!-- input -->
        <b-form-input
          v-show="file === null"
          ref="searchField"
          v-model="accessNumber"
          class="kt-input-search__input"
          autocomplete="off"
          :placeholder="placeholderProp"
          :state="accessNumberError !== '' ? false : stateProp"
          :disabled="disabledProp"
          @input="accessNumberError = ''; $emit('onUpdateValidation', false);"
        ></b-form-input>
        <!-- search icon -->
        <b-icon
          v-show="file === null"
          class="kt-input-search__search-icon"
          icon="search"
        ></b-icon>
        <!-- search button -->
        <b-button
          v-show="file === null && accessNumber"
          class="kt-input-search__submit-icon"
          :variant="'outline-' + $systemSettings.theme"
          type="submit"
        >
          {{ $t("general.search") }}
        </b-button>
      </b-form-group>

      <!-- advanced search button (choose fileInvoice modal) -->
      <b-button
        v-show="file === null"
        v-b-modal="'chooseInvoicedFileModal'"
        class="kt-btn-transparent kt-input-search__advanced-search"
        :variant="$systemSettings.theme"
      >
        {{ $t("general.advancedSearch") }}
      </b-button>
    </b-form>

    <!-- clear file value -->
    <div
      v-if="file !== null"
      class="kt-badge kt-badge--light-gray kt-badge--lg kt-input-search-invoicedFile__value-badge"
    >
      <b-icon
        icon="file-earmark"
        class="kt-badge__icon"
      ></b-icon>
      <span class="kt-badge__text">
        <span>{{ file.accessNumber }}</span>
        <!-- complementary -->
        <span
          v-show="file && file.parentFileId"
          class="kt-complementary-icon"
          :class="{'kt-complementary-icon--ml-lg': file && !!file.isUrgent}"
        >
          <b-icon icon="back"></b-icon>
        </span>
        <!-- clear button -->
        <b-button
          ref="clearButton"
          :variant="'outline-' + $systemSettings.theme"
          class="kt-btn-transparent kt-input-search-invoicedFile__clear-button"
          @click="clearFileValue"
        >
          <b-icon icon="x"></b-icon>
        </b-button>
      </span>
    </div>

    <!-- choose InvoicedFile Modal -->
    <b-modal
      id="chooseInvoicedFileModal"
      hide-footer
      :title="$t('general.advancedSearch')"
      size="xl"
      @shown="() => {
        $refs.chooseInvoicedFileComponent.focusFirstElement()
      }"
      @hidden="() => {
        focusFirstElement()
      }"
    >
      <InvoicedFileChoose
        ref="chooseInvoicedFileComponent"
        :laboratoryIdProp="laboratoryIdProp"
        :initialFilterValuesProp="{}"
        @chosen="onInvoicedFileChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- choose FromIds Modal -->
    <b-modal
      id="fileChooseFromIdsModal"
      size="md"
      hide-header
      hide-footer
      @shown="() => {
        if (fileOptions && fileOptions.length > 0) {
          $refs.chooseFromIdsComponent.focusFirstElement()
        }
      }"
    >
      <FileChooseFromIds
        v-if="fileOptions && fileOptions.length > 0"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="fileOptions"
        @chosen="$event !== null ? validateAndSelectFile($event) : hideChooseFromIdsModal()"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// modals
import InvoicedFileChoose from "@/views/Accounting/Payments/InvoicedFiles/InvoicedFileChoose";
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
// helpers
import error from "@shared/services/UI/error";
import commonServices from "@shared/services/API/commonServices";
import filesServices from "@/services/API/filesServices";

export default {
  components: { InvoicedFileChoose, FileChooseFromIds },
  mixins: [error],
  props: {
    laboratoryIdProp: {
      type: Number,
      default: null
    },
    fileProp: {
      type: Object,
      default: function() {
        return null;
      },
      deep: true
    },
    labelProp: {
      type: String,
      default: ""
    },
    placeholderProp: {
      type: String,
      default: ""
    },
    // validation and error
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    disabledProp: {
      type: Boolean
    },
    allowComplementarySelectionProp: {
      type: Boolean
    }
  },
  data() {
    return {
      accessNumberError: "",
      accessNumber: "",
      file: null,
      fileId: null,
      fileOptions: []
    };
  },
  watch: {
    fileProp: function(val) {
      this.file = val;
      this.fileId = val ? val.id : null;
    },
    laboratoryIdProp: function() {
      this.clearFileValue();
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    focusFirstElement() {
      if (this.file !== null) {
        this.$refs.clearButton.focus();
      } else {
        this.$refs.searchField.focus();
      }
    },

    clearFileValue() {
      this.accessNumber = "";
      this.accessNumberError = "";
      this.$emit("onUpdateParent", null);
    },
    async searchAccessNumber() {
      this.accessNumberError = "";
      const res = await filesServices.getAllByAccessNumber(this.accessNumber);
      if (res.data && res.data.length > 0) {
        if (res.data.length === 1) {
          // this accessNumber has one file
          await this.validateAndSelectFile(res.data[0]);
        } else {
          // this accessNumber has multiple files
          if (this.allowComplementarySelectionProp) {
            // chooseFromIds modal
            this.fileOptions = res.data;
            this.$bvModal.show("fileChooseFromIdsModal");
          } else {
            // choose the parent file
            for (const file of res.data) {
              if (!file.parentFileId) {
                await this.validateAndSelectFile(file);
                break;
              }
            }
          }
        }
      } else {
        this.accessNumberError = "notFoundInLaboratory";
      }
    },
    async onInvoicedFileChosen(invoicedFile) {
      if (invoicedFile) {
        await this.validateAndSelectFile(invoicedFile);
      }
    },
    async validateAndSelectFile(file) {
      if (!file) {
        this.accessNumberError = "notFoundInLaboratory";
        return false;
      }

      const resFile = await commonServices.get("files", file.id);
      const completeFile = resFile.data;
      if (!completeFile) {
        this.accessNumberError = "notFoundInLaboratory";
        return false;
      }
      const resFileInvoices = await filesServices.getFileInvoicesByFileId(completeFile.id);
      completeFile.fileInvoices = resFileInvoices.data;

      this.hideChooseFromIdsModal(false);
      this.$bvModal.hide("chooseInvoicedFileModal");

      this.accessNumber = completeFile.accessNumber;
      // laboratory validation
      if (this.laboratoryIdProp && completeFile.laboratoryId !== this.laboratoryIdProp) {
        this.accessNumberError = "notFoundInLaboratory";
        return false;
      }
      // filingStatus validation
      if (completeFile.filingStatus.name !== "validatedForAccounting") {
        this.accessNumberError = "notValidatedBySecretary";
        return false;
      }
      // cancelled validation
      if (completeFile.accountingStatus.name === "cancelled") {
        this.accessNumberError = "cancelledFile";
        return false;
      }
      // accountingStatus validation
      if (completeFile.accountingStatus.name !== "invoiced" && completeFile.accountingStatus.name !== "paid") {
        this.accessNumberError = "notInvoiced";
        return false;
      }
      // success
      this.accessNumber = "";
      this.accessNumberError = "";
      this.$emit("onUpdateParent", completeFile);
    },
    hideChooseFromIdsModal(clearAccessNumber = true) {
      this.$bvModal.hide("fileChooseFromIdsModal");
      this.fileOptions = [];
      if (clearAccessNumber) this.accessNumber = "";
      this.accessNumberError = "";
    }
  }
};
</script>

<style scoped src="./InputSearchInvoicedFile.scss" lang="scss"></style>

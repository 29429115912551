<template>
  <div>
    <b-sidebar
      id="kt-js-messaging-list"
      class="kt-messaging-list"
      :title="$t('messaging.main.title')"
      shadow
      @shown="importKnownUsers"
    >
      <div class="px-3 pt-1">
        <!-- search -->
        <b-form
          class="kt-messaging-list__search-form"
          :class="searchIsOpened ? 'kt-messaging-list__search-form--open' : ''"
          @submit.prevent
        >
          <!-- back-icon -->
          <div
            class="btn-icon kt-messaging-list__search-back"
          >
            <b-button
              class="btn-icon kt-messaging-list__search-back-btn"
              variant="light"
              @click="searchIsOpened = false; filterField = ''; suggestions = []"
            >
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
          </div>
          <!-- search group -->
          <b-form-group
            class="kt-messaging-list__search-group mb-0"
          >
            <!-- search input -->
            <b-form-input
              ref="searchField"
              v-model="filterField"
              class="kt-messaging-list__search-input"
              autocomplete="off"
              :placeholder="$t('messaging.main.addUserPlaceholder')"
              @input="inputSearchChange"
              @click="searchIsOpened = true"
            ></b-form-input>
            <!-- search icon -->
            <b-icon
              class="kt-messaging-list__search-icon"
              icon="search"
            ></b-icon>
          </b-form-group>
        </b-form>

        <!-- search results -->
        <div
          v-show="searchIsOpened"
          class="kt-messaging-list__users-list"
        >
          <!-- empty -->
          <div v-show="suggestions !== null && suggestions.length === 0">
            <div class="text-center text-15">
              {{ $t('messaging.main.emptySearch') }}
            </div>
          </div>
          <!-- list -->
          <div
            v-for="knownUser in suggestions"
            v-show="knownUser.id !== $userSettings.user.id"
            :key="knownUser.id"
            class="kt-messaging-list__user-item"
          >
            <!-- user link -->
            <button
              class="btn kt-messaging-list__userlink"
              variant="none"
              @click="addKnownUser(knownUser.id)"
            >
              <div
                class="kt-messaging-list__user-name"
                :class="knownUser.loggedIn ? 'kt-messaging-list__user-name--loggedIn' : ''"
              >
                {{ knownUser.firstName }} {{ knownUser.lastName }}
                <span v-show="knownUser.isActive === false">{{ "(" + $t("general.deletedLabel") + ")" }}</span>
                <span
                  v-show="knownUser.unreadReceivedMessages"
                  class="kt-count-bubble kt-count-bubble--danger"
                ><span class="kt-count-bubble__number">{{ knownUser.unreadReceivedMessages }}</span></span>
              </div>
            </button>
          </div>
        </div>

        <!-- known users list -->
        <div
          v-show="!searchIsOpened"
          class="kt-messaging-list__users-list"
        >
          <div class="kt-messaging-list__users-wrapper">
            <div
              v-for="knownUser in knownUsersFormatted"
              :key="knownUser.id"
              class="kt-messaging-list__user-item"
              :style="{order: getKnownUserOrder(knownUser)}"
            >
              <!-- user link -->
              <button
                class="btn kt-messaging-list__userlink"
                variant="none"
                @click="openConversation(knownUser.id)"
              >
                <div
                  class="kt-messaging-list__user-name kt-messaging-list__user-name--hasLoggedBubble"
                  :class="knownUser.loggedIn ? 'kt-messaging-list__user-name--loggedIn' : ''"
                >
                  {{ knownUser.firstName }} {{ knownUser.lastName }}
                  <span v-show="knownUser.isActive === false">{{ "(" + $t("general.deletedLabel") + ")" }}</span>
                  <span
                    v-show="knownUser.unreadReceivedMessages"
                    class="kt-count-bubble kt-count-bubble--danger"
                  ><span class="kt-count-bubble__number">{{ knownUser.unreadReceivedMessages }}</span></span>
                </div>
              </button>
              <!-- remove user button -->
              <button
                class="close kt-messaging-list__user-remove"
                @click="removeKnownUser(knownUser.id)"
              >
                <b-icon icon="x"></b-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- empty -->
        <div
          v-show="!searchIsOpened && (knownUsersFormatted === null || knownUsersFormatted.length === 0)"
          class="kt-messaging-list__empty__container"
        >
          <div
            class="kt-messaging-list__empty__wrapper"
          >
            <b-icon
              class="kt-messaging-list__empty__icon mt-3"
              icon="person-lines-fill"
            ></b-icon>
            <p class="kt-messaging-list__empty__text mb-0 mt-1">
              {{ $t("messaging.main.emptyKnownUsers") }}
            </p>
          </div>
        </div>
      </div>

      <template #footer>
        <!-- support contact -->
        <div
          v-if="supportUser"
          class="kt-messaging-list__support-container"
        >
          <div class="kt-messaging-list__user-item">
            <!-- user link -->
            <button
              class="btn kt-messaging-list__userlink"
              variant="none"
              @click="openConversation(supportUser.id)"
            >
              <div
                class="kt-messaging-list__user-name kt-messaging-list__user-name--hasLoggedBubble"
                :class="supportUser.loggedIn ? 'kt-messaging-list__user-name--loggedIn' : ''"
              >
                {{ $t("messaging.main.supportUserName") }}
                <span
                  v-show="supportUser.unreadReceivedMessages"
                  class="kt-count-bubble kt-count-bubble--danger"
                ><span class="kt-count-bubble__number">{{ supportUser.unreadReceivedMessages }}</span></span>
              </div>
            </button>
          </div>
        </div>
      </template>
    </b-sidebar>

    <!-- open sidebar button -->
    <div class="kt-messaging-list__open-sidebar-container">
      <div class="kt-messaging-list__open-sidebar-wrapper">
        <b-button
          v-b-toggle.kt-js-messaging-list
          class="btn-icon m-3 shadow kt-messaging-list__open-sidebar"
          variant="light"
        >
          <b-icon icon="chat-left-dots"></b-icon><!--
          --><span
            v-show="newMessagesTotal"
            class="kt-count-bubble kt-count-bubble--corner kt-count-bubble--danger"
          ><span class="kt-count-bubble__number">{{ newMessagesTotal }}</span></span>
        </b-button>
      </div>
    </div>

    <!-- Conversation -->
    <Conversation
      v-if="currentKnownUserId"
      :knownUserIdProp="currentKnownUserId"
      @onCloseConversation="currentKnownUserId = null"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import messagingServices from "@shared/services/API/messagingServices";
// components
import Conversation from "@shared/views/Messaging/Conversation";
// helpers
import autocompletion from "@shared/services/UI/autocompletion";
import audioMessage from "@shared/views/Messaging/sounds/juntos-607.mp3";

export default {
  components: { Conversation },
  mixins: [autocompletion],
  data() {
    return {
      debugMode: false,
      knownUsers: [],
      currentKnownUserId: null,
      newMessagesTotal: 0,
      newMessageAudio: new Audio(audioMessage),
      // subscriptions variables
      privateMessageSubscriptionId: null,
      markAsReadSubscriptionId: null,
      onTypingSubscriptionId: null,
      loggedInSubscriptionId: null,
      newConversationSubscriptionId: null,
      // search
      searchIsOpened: false,
      filterField: ""
    };
  },
  computed: {
    knownUsersFormatted: function() {
      // filter out supportUser
      const knownUsersFormatted = this.knownUsers.filter((user) => { return user.id !== 2; });
      // order list
      return knownUsersFormatted.sort((a, b) => {
        const aDate = (a.messages && a.messages.length > 0) ? new Date(a.messages[a.messages.length - 1].timestamp) : 0;
        const bDate = (b.messages && b.messages.length > 0) ? new Date(b.messages[b.messages.length - 1].timestamp) : 0;
        return bDate - aDate;
      });
    },
    supportUser: function() {
      let supportUser = null;
      for (const knownUser of this.knownUsers) {
        if (knownUser.id === 2) {
          supportUser = knownUser;
          break;
        }
      }
      return supportUser;
    }
  },
  watch: {},
  async mounted() {
    await this.importKnownUsers();
    this.privateMessageSubscriptionId = messagingServices.subscribe("privateMessage", this.onMessage);
    this.markAsReadSubscriptionId = messagingServices.subscribe("markAsRead", this.onMarkAsRead);
    this.typingSubscriptionId = messagingServices.subscribe("typing", this.onTyping);
    this.loggedInSubscriptionId = messagingServices.subscribe("loggedIn", this.onLoggedIn);
    this.newConversationSubscriptionId = messagingServices.subscribe("newConversation", this.onNewConversation);
  },
  beforeDestroy() {
    messagingServices.unsubscribe("privateMessage", this.privateMessageSubscriptionId);
    messagingServices.unsubscribe("markAsRead", this.markAsReadSubscriptionId);
    messagingServices.unsubscribe("typing", this.typingSubscriptionId);
    messagingServices.unsubscribe("loggedIn", this.loggedInSubscriptionId);
    messagingServices.unsubscribe("newConversation", this.newConversationSubscriptionId);
  },
  methods: {
    // search user (with delay)
    inputSearchChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onFilterInput("users", "lastName", { string: this.filterField, laboratoryIds: [] }, 1, ["id", "lastName", "firstName", "email", "isActive"]);
      }, 450);
    },

    playSoundNewMessage() {
      this.newMessageAudio.play();
    },

    getKnownUserOrder(knownUser) {
      if (knownUser.unreadReceivedMessages && knownUser.loggedIn) {
        return 0;
      } else if (knownUser.unreadReceivedMessages) {
        return 1;
      } else if (knownUser.loggedIn) {
        return 2;
      } else {
        return 3;
      }
    },
    async addKnownUser(userId) {
      if (!userId) return false;

      // check if already added
      let isAlreadyAdded = false;
      for (const knownUser of this.knownUsers) {
        if (knownUser.id === userId) {
          isAlreadyAdded = true;
          break;
        }
      }

      if (!isAlreadyAdded) {
        // add knownUser
        const resKnownUser = await commonServices.post("knownUsers", {
          userId: this.$userSettings.user.id,
          knownUserId: userId
        });
        if (resKnownUser.data.id) {
          // update knownUsers list
          const resUser = await commonServices.get("knownUsers", userId);
          if (resUser.data.id) {
            this.knownUsers.push(resUser.data);
            this.refreshNewMessagesTotal();
          }
        }
      }

      this.openConversation(userId);

      this.filterField = "";
      this.suggestions = [];
      this.searchIsOpened = false;
    },
    async removeKnownUser(id) {
      const res = await commonServices.delete("knownUsers", id);
      if (res.data === true) {
        for (let i = this.knownUsers.length - 1; i >= 0; i--) {
          if (this.knownUsers[i].id === id) {
            this.knownUsers.splice(i, 1);
            this.refreshNewMessagesTotal();
            break;
          }
        }
      }
    },
    openConversation(knownUserId) {
      this.currentKnownUserId = null;
      this.$nextTick(() => {
        this.currentKnownUserId = knownUserId;
      });
    },
    async importKnownUsers() {
      try {
        this.loading = true;
        const res = await commonServices.getAll("knownUsers");
        // import knownUser
        this.knownUsers = res.data;
        this.refreshNewMessagesTotal();
        this.loading = false;
      } catch (err) {
        this.onAlert("warning", {
          title: this.$t(err.response.data.title),
          stack: this.$t(err.response.data.stack)
        });
        this.loading = false;
      }
    },
    refreshNewMessagesTotal() {
      this.newMessagesTotal = this.knownUsers.reduce((total, v) => { return total + v.unreadReceivedMessages; }, 0);
    },

    onMessage(message) {
      if (this.debugMode) console.log("SIDEBAR received onMessage: ", message);
      // setup fromKnownUser
      message.fromKnownUser = (message.senderId !== this.$userSettings.user.id);
      // add to knownUser messages
      const messageKnownUserId = message.fromKnownUser ? message.senderId : message.recipientId;
      for (const knownUser of this.knownUsers) {
        if (knownUser.id === messageKnownUserId) {
          knownUser.messages.push(message);
          if (message.fromKnownUser) {
            knownUser.unreadReceivedMessages++;
            this.refreshNewMessagesTotal();
          }
          break;
        }
      }
      // notification sound
      if (message.fromKnownUser && this.currentKnownUserId !== messageKnownUserId) {
        this.playSoundNewMessage();
      }
    },
    onMarkAsRead(data) {
      if (this.debugMode) console.log("SIDEBAR received onMarkAsRead: ", data);
      if (
        data.recipientId === this.$userSettings.user.id // knownUser (data.recipientId) has read current user messages
      ) {
        for (const knownUser of this.knownUsers) {
          if (knownUser.id === data.userId) {
            knownUser.unreadSentMessages = 0;
            break;
          }
        }
      } else if (
        data.userId === this.$userSettings.user.id // current user has read knownUser (data.userId) conversation
      ) {
        for (const knownUser of this.knownUsers) {
          if (knownUser.id === data.recipientId) {
            knownUser.unreadReceivedMessages = 0;
            this.refreshNewMessagesTotal();
            break;
          }
        }
      }
    },
    onTyping(data) {
      if (this.debugMode) console.log("SIDEBAR received onTyping: ", data);
      // do nothing when the user itself is typing
      if (data.userId === this.$userSettings.user.id) return true;

      // otherwise
      for (const knownUser of this.knownUsers) {
        if (knownUser.id === data.userId) {
          knownUser.isTyping = data.isTyping;
          break;
        }
      }
    },
    onLoggedIn(data) {
      if (this.debugMode) console.log("SIDEBAR received onLoggedIn: ", data);
      // do nothing when the user itself is logging in
      if (data.userId === this.$userSettings.user.id) return true;

      for (const knownUser of this.knownUsers) {
        if (knownUser.id === data.userId) {
          knownUser.loggedIn = data.isLoggedIn;
          break;
        }
      }
    },
    async onNewConversation() {
      if (this.debugMode) console.log("SIDEBAR received onNewConversation: ");
      await this.importKnownUsers();
      this.playSoundNewMessage();
    }
  }
};
</script>

<style scoped src="./SideBar.scss" lang="scss"></style>

<template>
  <div
    class="kt-view-page kt-file-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      class="kt-page-header mb-3"
      :class="moduleModeProp ? '' : 'mt-4 pt-1'"
    >
      <!-- page header top -->
      <div v-if="!moduleModeProp">
        <!-- back button -->
        <b-button
          ref="backButton"
          size="sm"
          class="kt-page-header__back-btn btn-icon mr-3"
          variant="light"
          @click="onGoBack"
        >
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
        <!-- title -->
        <h2 class="h4 mb-0 kt-page-header__title">
          {{ parentFileId ? $t("fileView.pageTitleComplementary") : $t("fileView.pageTitle") }}
        </h2>
        <!-- edit button -->
        <b-button
          v-if="isEnabled(['menu', 'office', 'files', 'edit'])"
          size="sm"
          class="kt-page-header__action"
          :variant="$systemSettings.theme"
          @click="navigate('office/file/edit', {fileIdProp: fileId})"
        >
          <b-icon icon="pen"></b-icon>
          {{ $t("fileView.editFile") }}
        </b-button>
      </div>
      <!-- subtitle -->
      <div
        class="kt-page-header__subtitle"
        :class="moduleModeProp ? 'mt-0' : ''"
      >
        <!-- accessNumber -->
        <span>{{ $t("files.subtitleAccessNumber", {accessNumber: accessNumber}) }}</span>
        <!-- complementary icon -->
        <span
          v-show="parentFileId"
          class="kt-complementary-icon"
        >
          <b-icon
            icon="back"
          ></b-icon>
        </span>
        <!-- isUrgent icon -->
        <span
          v-show="isUrgent"
          class="kt-badge kt-badge--red ml-1"
        >
          <b-icon
            icon="flag-fill"
            class="kt-badge__icon"
          ></b-icon>
          {{ $t("files.urgentBadgeText") }}
        </span>
        <!-- creationDate -->
        <div>
          {{ $t("fileView.created", {date: creationDate}) }}
        </div>
      </div>
      <!-- page header - bottom -->
      <div class="kt-page-header__bottom mt-1">
        <!-- status history -->
        <span style="white-space: nowrap;">
          <StatusBadge
            class="mr-1"
            typeProp="filingStatus"
            :statusNameProp="filingStatus"
            isDisplayedStatusTitleProp
          />
          <StatusBadge
            class="mr-1"
            typeProp="medicalStatus"
            :statusNameProp="medicalStatus"
            isDisplayedStatusTitleProp
          />
          <StatusBadge
            typeProp="accountingStatus"
            :statusNameProp="accountingStatus"
            isDisplayedStatusTitleProp
          />
        </span>
        <!-- history button -->
        <b-button
          v-b-modal="'fileStatusesHistoryModal-' + fileId"
          variant="outline-secondary"
          size="sm"
          class="ml-2"
        >
          {{ $t('files.historyButton') }}
        </b-button>
        <!-- archives button -->
        <b-button
          v-b-modal="'archivesModal-' + _uid"
          variant="outline-secondary"
          size="sm"
          class="ml-2"
        >
          {{ $t('files.archives') }}
          <span
            class="kt-count-bubble"
            :class="archives.length ? 'kt-count-bubble--danger' : ''"
          ><span class="kt-count-bubble__number">{{ archives.length }}</span></span>
          <b-icon
            v-show="hasMissingArchives"
            icon="exclamation-triangle-fill"
            style="color: rgba(199, 51, 51, 0.9) !important; margin-left: 4px; font-size: 16px;"
          />
        </b-button>
        <!-- comments button -->
        <b-button
          v-b-modal="'fileCommentsModal-' + fileId"
          variant="outline-secondary"
          size="sm"
          class="ml-2"
        >
          {{ $t('files.commentsButton') }} <span
            class="kt-count-bubble"
            :class="commentsCount ? 'kt-count-bubble--danger' : ''"
          ><span class="kt-count-bubble__number">{{ commentsCount }}</span></span>
        </b-button>
        <!-- group of files button -->
        <b-button
          v-b-modal="'complementaryFilesModal-' + fileId"
          variant="outline-secondary"
          size="sm"
          class="ml-2"
        >
          {{ $t('files.groupOfFilesButton') }} <span
            class="kt-count-bubble"
            :class="groupOfFiles && groupOfFiles.length > 1 ? 'kt-count-bubble--danger' : ''"
          ><span class="kt-count-bubble__number">{{ groupOfFiles ? groupOfFiles.length : 0 }}</span></span>
        </b-button>
        <!-- option drop-down -->
        <div
          v-show="(medicalStatus === 'validatedMedically' || isReadyMedicalReport) && (isEnabled(['roleSpecific', 'office']) || isEnabled(['roleSpecific', 'diagnosis']))"
          class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-2"
        >
          <b-button
            variant="outline-secondary"
            size="sm"
            class="btn-icon kt-dropdown-btn__switcher-btn"
          >
            <b-icon icon="three-dots-vertical"></b-icon>
          </b-button>
          <ul class="kt-dropdown-btn__list">
            <li class="kt-dropdown-btn__shadow"></li>

            <!-- download medical report -->
            <li
              v-show="(medicalStatus === 'validatedMedically' || isReadyMedicalReport)"
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button"
                @click="downloadMedicalReport"
              >
                <b-icon
                  icon="download"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("files.downloadMedicalReport") }}
              </button>
            </li>
          </ul>
        </div>
        <b-spinner
          v-show="isDownloading"
          class="ml-1"
          :class="'kt-spinner--' + $systemSettings.theme"
          small
        ></b-spinner>
      </div>
      <!-- message sent warnings -->
      <div
        v-show="sentMessageWarningText !== ''"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="chat-left-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ sentMessageWarningText }}</span><br />{{ lastSentMessage ? (lastSentMessage.email || "") + (lastSentMessage.phone && lastSentMessage.email ? ", " : "") + (lastSentMessage.phone || "") : "" }}</span>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- PARENT FILE VIEW (for complementary) -->
    <b-button
      v-show="parentFileId"
      :variant="$systemSettings.theme"
      class="mb-2"
      @click="($event) => {
        navigate('office/file/view', {fileIdProp: parentFileId, cancelRouteProp: cancelRouteProp})
      }"
    >
      {{ $t('files.parentFileViewButton') }}
    </b-button>

    <!-- BODY -->
    <div class="kt-view-page__body">
      <!-- SECTION - laboratory & sector & pathologist -->
      <div
        v-if="laboratoryDisplay || sectorDisplay || pathologistDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- laboratory -->
        <b-row v-if="laboratoryDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratory ? laboratory.name + (laboratory.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.empty") }}
            </p>
          </b-col>
        </b-row>
        <!-- sector -->
        <b-row v-if="sectorDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.sector") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ sectorLocalisedName }}
            </p>
          </b-col>
        </b-row>
        <!-- pathologist -->
        <b-row v-if="pathologistDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.pathologist") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ pathologist ? (pathologist.user.firstName + " " + pathologist.user.lastName + " (" + pathologist.code + ")" + (pathologist.user.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "")) : $t("general.empty") }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div class="w-100 border-top mt-1 mb-3"></div>

      <!-- SECTION - patient -->
      <div
        v-if="patientDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- patient -->
        <b-row v-if="patientDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.patient") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="patientId"
              class="kt-frame mb-3"
            >
              <PatientView
                :patientIdProp="patientId"
                :referenceCreationDateProp="referenceCreationDate"
                moduleModeProp
                @alert="(variant, strings) => $emit('alert', variant, strings)"
              />
            </div>
            <!-- forceHealthCare -->
            <div v-show="forceHealthCare">
              <!-- checkbox-display-only -->
              <div
                class="kt-checkbox-display-only-cont"
                style="margin-bottom: 1px;"
              >
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + ' kt-checkbox-display-only--checked'"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text">{{ $t("files.forceHealthCare") }}</span>
              </div>
              <!-- forceHealthCareOptions -->
              <div class="mb-3">
                <span class="font-weight-semi-bold">
                  {{ $t('files.forceHealthCareType') }}
                </span>
                <span>
                  {{ $t('files.forceHealthCareTypes.' + forceHealthCareOptions.type) }}
                </span>
                <span
                  v-show="forceHealthCareOptions.type === 'accident'"
                  class="font-weight-semi-bold ml-2"
                >
                  {{ $t('files.forceHealthCareReference') }}
                </span>
                <span v-show="forceHealthCareOptions.type === 'accident'">
                  {{ forceHealthCareOptions.reference || $t("general.emptyWithHyphen") }}
                </span>
                <span
                  v-show="forceHealthCareOptions.type === 'accident' || forceHealthCareOptions.type === 'pregnancy'"
                  class="font-weight-semi-bold ml-2"
                >
                  {{ $t('files.forceHealthCareDate') }}
                </span>
                <span v-show="forceHealthCareOptions.type === 'accident' || forceHealthCareOptions.type === 'pregnancy'">
                  {{ forceHealthCareOptions.date ? $d(new Date(forceHealthCareOptions.date), "date") : $t("general.emptyWithHyphen") }}
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div class="w-100 border-top mt-1 mb-3"></div>

      <!-- SECTION - organisation / prescriber / file-contacts -->
      <div
        v-if="prescribingOrganisationDisplay || prescriberDisplay || fileContactsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- prescribingOrganisation -->
        <b-row v-if="prescribingOrganisationDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.prescribingOrganisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="prescribingOrganisation"
              class="kt-item-list"
            >
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item">
                  <!-- name -->
                  <div class="kt-item-list__item-title">
                    {{ prescribingOrganisation ? prescribingOrganisation.name + (prescribingOrganisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.empty") }}
                  </div>
                  <!-- code -->
                  <div class="kt-item-list__item-text">
                    {{ prescribingOrganisation ? prescribingOrganisation.code : $t("general.emptyWithHyphen") }}
                  </div>
                  <!-- invoice patient -->
                  <div class="mb-2 mt-3">
                    <p class="mb-0 font-weight-semi-bold">
                      {{ $t("files.invoicePatient") }}
                    </p>
                    <p class="mb-0">
                      {{ forceInvoicePatient !== null ? (forceInvoicePatient ? $t('organisations.invoicePatientTrue') : $t('organisations.invoicePatientFalse')) : $t("general.emptyWithHyphen") }}
                    </p>
                  </div>
                  <!-- hospitalisationNumber -->
                  <div>
                    <p class="mb-0 font-weight-semi-bold">
                      {{ $t("files.hospitalisationNumber") }}
                    </p>
                    <p class="mb-0">
                      {{ hospitalisationNumber || $t("general.emptyWithHyphen") }}
                    </p>
                  </div>
                  <!-- ipp -->
                  <div v-show="ipp">
                    <p
                      class="mb-0 mt-2 font-weight-semi-bold"
                      style="letter-spacing: 0.5px;"
                    >
                      {{ $t("files.ipp") }}
                    </p>
                    <p class="mb-0">
                      {{ ipp }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="mb-2"
            >
              {{ $t("general.emptyWithHyphen") }}
            </div>
          </b-col>
        </b-row>
        <!-- prescriber -->
        <b-row v-if="prescriberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.prescriber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="prescriber"
              class="kt-item-list"
            >
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item">
                  <!-- name -->
                  <a
                    v-b-modal="'contactViewModal-' + _uid"
                    class="kt-item-list__item-title"
                    @click="displayedContactId = prescriber.id"
                  >
                    {{ prescriber.firstName + " " + prescriber.lastName + (prescriber.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") }}
                  </a>
                  <!-- code -->
                  <div class="kt-item-list__item-text">
                    {{ prescriber.code }}
                  </div>
                  <!-- contactInformation -->
                  <div
                    v-if="contactInformation"
                    class="mb-2 mt-3"
                  >
                    <p class="mb-0 font-weight-semi-bold">
                      <!-- {{ $t("files.contactInformation") }} -->
                      {{ contactInformation ? contactInformation.name + (contactInformation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : $t("general.empty") }}
                    </p>
                    <p class="mb-0">
                      {{ contactInformation ? formatAddress(contactInformation.address1, contactInformation.address2, contactInformation.zip, contactInformation.city, contactInformation.country) : $t("general.empty") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="mb-2"
            >
              {{ $t("general.emptyWithHyphen") }}
            </div>
          </b-col>
        </b-row>
        <!-- fileContacts -->
        <b-row v-if="fileContactsDisplay && fileContacts.length">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.otherRecipients") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="fileContacts.length"
              class="kt-item-list"
            >
              <div
                v-for="contactItem in fileContacts"
                :key="contactItem.key"
                class="kt-item-list__item-wrapper"
              >
                <div class="kt-item-list__item">
                  <!-- name -->
                  <a
                    v-b-modal="'contactViewModal-' + _uid"
                    class="kt-item-list__item-title"
                    @click="displayedContactId = contactItem.contact.id"
                  >
                    {{ contactItem.contact.firstName + " " + contactItem.contact.lastName + (contactItem.contact.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") }}
                  </a>
                  <!-- code -->
                  <div class="kt-item-list__item-text">
                    {{ contactItem.contact.code }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="mb-2"
            >
              {{ $t("general.emptyWithHyphen") }}
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div class="w-100 border-top mt-1 mb-3"></div>

      <!-- SECTION - invoiced organisation -->
      <div
        v-if="invoicedOrganisationDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- invoicedOrganisation -->
        <b-row v-if="invoicedOrganisationDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("files.invoicedOrganisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-2">
              {{ invoicedOrganisationName }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div class="w-100 border-top mt-1 mb-3"></div>

      <!-- SECTION - containerGroups & additionalFeeGroup -->
      <div
        v-if="containerGroupsDisplay || additionalFeeGroupDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- additionalFeeGroup -->
        <b-row v-if="additionalFeeGroupDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('files.additionalFeeGroup') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-2">
              {{ additionalFeeGroup ? (additionalFeeGroup.name + (additionalFeeGroup.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '')) : $t("additionalFeeGroups.defaultGroupName") }}
            </p>
          </b-col>
        </b-row>
        <!-- containerGroups -->
        <b-row v-if="containerGroupsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('files.containerGroups') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <ContainerGroups
              :viewModeProp="true"
              :containerGroupsProp="containerGroups"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-col>
        </b-row>
      </div>

      <!-- SECTION - accountingHistory -->
      <div
        v-if="accountingHistoryDisplay"
        class="kt-view-page__section mt-4 pt-1 mb-3"
        :class="sectionMarginsClass"
      >
        <!-- divider -->
        <div class="kt-border-top-strong mb-3"></div>

        <AccountingHistory
          v-if="fileData !== null && accountingHistoryContentDisplay"
          :fileIdProp="fileIdProp"
          :fileDataProp="fileData"
          @editedCompensation="onEditedCompensation"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
        <!-- display button -->
        <div class="text-center">
          <b-button
            v-show="!accountingHistoryContentDisplay"
            :variant="$systemSettings.theme"
            @click="accountingHistoryContentDisplay = true"
          >
            <b-icon icon="chevron-down"></b-icon>
            {{ $t("fileView.accountingHistoryDisplayButton") }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- fileStatusesHistory Modal  -->
    <b-modal
      :id="'fileStatusesHistoryModal-' + fileId"
      size="xl"
      hide-footer
      :title="$t('files.fileStatusesHistoryModalTitle')"
    >
      <FileStatusesHistory
        :fileStatusesHistoryProp="fileStatusesHistory"
        :sentMessagesProp="sentMessages"
        :fileModificationsProp="fileModifications"
        hideHeaderProp
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- archives Modal  -->
    <b-modal
      :id="'archivesModal-' + _uid"
      size="m"
      hide-header
      hide-footer
    >
      <FileArchives
        :archivesProp="archives"
        :hasMissingArchivesProp="hasMissingArchives"
        :readyProp="!archivesIsLoading"
        @close="$bvModal.hide('archivesModal-' + _uid)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- fileComments Modal  -->
    <b-modal
      :id="'fileCommentsModal-' + fileId"
      size="xl"
      hide-footer
      :title="$t('files.fileCommentsModalTitle')"
      @shown="() => {
        $refs.fileCommentsComponent.focusFirstElement()
      }"
    >
      <FileComments
        ref="fileCommentsComponent"
        :fileIdProp="fileId"
        @updateCommentsCount="commentsCount = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- ContactView Modal  -->
    <b-modal
      :id="'contactViewModal-' + _uid"
      size="xl"
      hide-footer
      :title="$t('files.contactViewModalTitle')"
      @hidden="() => {
        displayedContactId = null;
      }"
    >
      <ContactView
        v-if="displayedContactId"
        moduleModeProp
        :contactIdProp="displayedContactId"
        :displayedFieldsProp="[
          'fullName',
          'code',
          'registrationNumber',
          'contactInformations',
          'contactOrganisations',
          'sendOptions'
        ]"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- groupOfFiles Modal -->
    <b-modal
      :id="'complementaryFilesModal-' + fileId"
      size="m"
      hide-header
      hide-footer
      @shown="() => {
        if (groupOfFiles && groupOfFiles.length > 1) {
          $refs.chooseFromIdsComponent.focusFirstElement();
        } else if ($refs.addComplementaryButton && isEnabled(['roleSpecific', 'cytotechnician'])) {
          $refs.addComplementaryButton.focus();
        }
      }"
    >
      <!-- no complementary -->
      <div v-show="!groupOfFiles || groupOfFiles.length < 2">
        <!-- modal header -->
        <header
          class="modal-header"
          style="margin: -1rem -1rem 0 -1rem;"
        >
          <div>
            <!-- title -->
            <h5
              class="modal-title kt-page-header__title h4 mb-0"
              style="font-size: 22px; line-height: 24px;"
            >
              {{ $t("fileChooseFromIds.pageTitle") }}
            </h5>
          </div>
          <!-- close -->
          <button
            ref="closeButton"
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('complementaryFilesModal-' + fileId)"
          >
            {{ $t('general.closeX') }}
          </button>
        </header>
        <!-- modal content -->
        <p class="text-dark text-15 mb-1 mt-3">
          {{ $t("files.noComplementaryFile") }}
        </p>
      </div>
      <FileChooseFromIds
        v-if="groupOfFiles && groupOfFiles.length > 1"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="groupOfFiles"
        :currentFileIdProp="fileId"
        @chosen="($event) => {
          if ($event !== null) {
            // if (groupNavigatesToReportProp) {
            //   if ($event.sector && $event.sector.name) {
            //     navigate('diagnosis/' + $event.sector.name + 'ReportEdit', {fileIdProp: $event.id})
            //   }
            // } else {
            // }
            if ($event.id !== fileId) {
              navigate('office/file/view', {fileIdProp: $event.id, cancelRouteProp: cancelRouteProp})
            } else {
              $bvModal.hide('complementaryFilesModal-' + fileId)
            }
          } else {
            $bvModal.hide('complementaryFilesModal-' + fileId)
          }
        }"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
      <!-- new complementary file -->
      <b-button
        v-if="isEnabled(['roleSpecific', 'cytotechnician'])"
        v-show="fileRequestErrors.length === 0 && !parentFileId"
        ref="addComplementaryButton"
        class="mb-2 mt-3"
        :variant="$systemSettings.theme"
        type="button"
        @click="addComplementaryFile"
      >
        {{ $t('fileEdit.addComplementaryFile') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
// components
import ContainerGroups from "@/views/Data/ContainerGroups";
import PatientView from "@/views/Office/Patients/PatientView";
import StatusBadge from "@shared/views/Helpers/StatusBadge";
import FileStatusesHistory from "@/views/Office/Files/FileStatusesHistory";
import FileArchives from "@shared/views/Office/Files/FileArchives";
import FileComments from "@/views/Office/Files/FileComments";
import AccountingHistory from "@/views/Office/Files/AccountingHistory";
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
import ContactView from "@/views/Office/Contacts/ContactView";
// services
import commonServices from "@shared/services/API/commonServices";
import filesServices from "@/services/API/filesServices";
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
import address from "@shared/services/UI/address";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";
import fileSaver from "file-saver";

export default {
  components: {
    ContainerGroups,
    PatientView,
    StatusBadge,
    FileStatusesHistory,
    FileArchives,
    FileComments,
    AccountingHistory,
    FileChooseFromIds,
    ContactView
  },
  mixins: [userRights, error, address, date, string, viewComponents],
  props: {
    fileIdProp: {
      type: Number,
      default: null
    },
    fileDataProp: {
      type: Object,
      default: null
    },
    groupNavigatesToReportProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "laboratory",
          "sector",
          "pathologist",

          "patient",

          "prescribingOrganisation",
          "hospitalisationNumber",
          "prescriber",
          "fileContacts",

          "invoicedOrganisation",

          "additionalFeeGroup",
          "containerGroups",
          "accountingHistory"
        ];
      }
    },
    cancelRouteProp: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // general
      accessNumber: "",
      fileId: this.fileIdProp,
      fileData: null,
      isDownloading: false,
      displayedContactId: null,
      accountingHistoryContentDisplay: this.isEnabled(["roleSpecific", "office"]) || this.isEnabled(["roleSpecific", "accounting"]),
      // base data
      parentFileId: null,
      referenceCreationDate: "",
      creationDate: "",
      isUrgent: false,
      laboratory: null,
      sector: null,
      pathologist: null,

      archives: [],
      archivesIsLoading: false,
      hasMissingArchives: false,

      commentsCount: 0,

      fileStatusesHistory: null,
      sentMessages: [],
      fileModifications: [],
      filingStatus: "",
      medicalStatus: "",
      accountingStatus: "",

      complementaryFiles: [],
      groupOfFiles: [],

      fileRequestErrors: [],

      patient: null,
      patientId: null,
      forceHealthCare: false,
      forceHealthCareOptions: {
        type: "screeningCampaign",
        reference: "",
        date: ""
      },
      ipp: "",

      prescribingOrganisation: null,
      hospitalisationNumber: "",
      forceInvoicePatient: null,

      prescriber: null,
      prescriberHasDownloaded: false,
      fileContacts: [],
      contactInformationId: null,
      contactInformation: null,

      invoicedOrganisation: null,

      additionalFeeGroup: null,
      containerGroups: [],
      collectionDates: [],

      // dispayFields
      laboratoryDisplay: false,
      pathologistDisplay: false,
      prescribingOrganisationDisplay: false,
      invoicedOrganisationDisplay: false,
      fileContactsDisplay: false,
      patientDisplay: false,
      additionalFeeGroupDisplay: false,
      containerGroupsDisplay: false,
      accountingHistoryDisplay: false
    };
  },
  computed: {
    sectorLocalisedName: function() {
      if (!this.sector) {
        return "";
      }
      return this.$t("sectors." + this.sector.name) + (!this.sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "");
    },
    lastSentMessage: function() {
      if (this.sentMessages && this.sentMessages.length) {
        return {
          email: this.sentMessages[this.sentMessages.length - 1].content.email,
          phone: this.sentMessages[this.sentMessages.length - 1].content.phone,
          recipient: this.sentMessages[this.sentMessages.length - 1].content.recipient,
          timestamp: this.sentMessages[this.sentMessages.length - 1].timestamp
        };
      } else {
        return null;
      }
    },
    sentMessageWarningText: function() {
      let output = "";
      if (this.filingStatus === "errorInRequest" && this.lastSentMessage) {
        if (this.lastSentMessage.recipient === "prescriber") {
          output = this.$t("files.notifications.sentMessageWarningPrescriber");
        } else if (this.lastSentMessage.recipient === "patient") {
          output = this.$t("files.notifications.sentMessageWarningPatient");
        }
      }
      return output;
    },
    invoicedOrganisationName: function() {
      let name = "";
      if (this.invoicedOrganisation === null) {
        name = this.$t("files.patient");
        if (this.patient) {
          name += " : " + this.patient.firstName + (this.patient.firstName && this.patient.lastName ? " " : "") + this.patient.lastName;
        }
      } else {
        name = this.invoicedOrganisation.organisationType.name === "healthCare" ? this.$t("files.healthCare") : this.$t("files.organisation");
        name += " : " + this.invoicedOrganisation.name;
        if (this.invoicedOrganisation.isActive === false) {
          name += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return name;
    },
    isReadyMedicalReport: function() {
      if (this.groupOfFiles.length === 0) {
        return false;
      }
      const notValidated = this.groupOfFiles.find((file) => {
        if (!file.parentFileId) {
          return file.medicalStatus.name !== "validatedMedically";
        } else {
          return file.medicalStatus.name !== "validatedMedically" && file.medicalStatus.name !== "cancelled";
        }
      });
      return !notValidated;
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.importData();
    this.getArchives();

    // auto focus
    if (!this.moduleModeProp && this.$refs.backButton) {
      this.$refs.backButton.focus();
    }
  },
  methods: {
    async getArchives() {
      try {
        this.archivesIsLoading = true;
        const filters = [
          {
            documentType: "admission",
            accessNumber: this.accessNumber,
            patientId: null
          },
          {
            documentType: "medicalReport",
            accessNumber: this.accessNumber,
            patientId: null
          }
        ];
        const resArchives = await commonServices.getAll("archives", filters);
        const archives = [];
        this.hasMissingArchives = false;
        for (const documentTypeObj of resArchives.data) {
          for (const interfaceObj of documentTypeObj.results) {
            for (const document of interfaceObj.result) {
              if (document.error && document.error === "unreachable") {
                this.hasMissingArchives = true;
              } else {
                archives.push({
                  documentType: documentTypeObj.documentType,
                  interface: interfaceObj.interface,
                  localName: interfaceObj.localName,
                  ...document
                });
              }
            }
          }
        }
        this.archives = archives;
        this.archivesIsLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importData() {
      try {
        if (this.fileDataProp) {
          this.fileData = this.fileDataProp;
        } else {
          const resServices = await commonServices.get("files", this.fileId);
          this.fileData = resServices.data;
        }

        // assign the data
        this.fileId = this.fileData.id;
        // groupOfFiles & groupOfFiles & referenceCreationDate
        this.parentFileId = this.fileData.parentFileId;
        this.complementaryFiles = this.fileData.childrenFiles.map((item) => {
          item.parentFileId = this.fileId;
          return item;
        });
        this.complementaryFiles.map((item) => {
          item.parentFileId = this.fileId;
          return item;
        });
        let groupOfFiles = [];
        if (this.parentFileId) {
          // current file is a complementary
          const resLinkedFiles = await filesServices.getLinkedFiles(this.parentFileId);
          if (resLinkedFiles.data) {
            groupOfFiles = resLinkedFiles.data;
          }
          groupOfFiles.unshift(this.fileData.parentFile);
          this.referenceCreationDate = this.fileData.parentFile.creationDate;
        } else {
          // current file is the parent
          if (this.complementaryFiles && this.complementaryFiles.length) {
            groupOfFiles = this.complementaryFiles;
          }
          groupOfFiles.unshift({
            id: this.fileId,
            accessNumber: this.fileData.accessNumber,
            parentFileId: null,
            creationDate: this.fileData.creationDate,
            filingStatus: this.fileData.filingStatus || null,
            medicalStatus: this.fileData.medicalStatus,
            accountingStatus: this.fileData.accountingStatus || null
          });
          this.referenceCreationDate = this.fileData.creationDate;
        }
        this.groupOfFiles = groupOfFiles.map((file) => {
          // add sector to all files
          file.sector = this.fileData.sector;
          return file;
        });

        // file statuses
        this.fileStatusesHistory = this.fileData.fileStatusesHistory;
        this.sentMessages = this.fileData.sentMessages || [];
        this.fileModifications = this.fileData.fileModifications || [];
        this.filingStatus = this.fileData.filingStatus.name;
        this.medicalStatus = this.fileData.medicalStatus.name;
        this.accountingStatus = this.fileData.accountingStatus.name;

        this.commentsCount = this.fileData.fileCommentCount || 0;

        // fileRequestErrors
        this.fileRequestErrors = this.fileData.fileRequestErrors || [];
        this.fileRequestErrors = this.fileRequestErrors.map((item) => {
          return {
            requestErrorId: item.requestErrorId,
            localisedName: this.$t("requestErrors." + item.requestError.name)
          };
        });

        this.accessNumber = this.fileData.accessNumber;
        this.isUrgent = this.fileData.isUrgent;
        this.creationDate = this.fileData.creationDate ? this.$d(new Date(this.fileData.creationDate), "date") : "-";
        this.laboratory = this.fileData.laboratory;
        this.sector = this.fileData.sector;

        this.pathologist = this.fileData.pathologist;

        // patient
        this.patient = this.fileData.patient;
        this.patientId = this.fileData.patient.id;
        // forceHealthCare
        this.forceHealthCareOptions = {
          type: this.fileData.forceHealthCare ? (this.fileData.forceHealthCare.type || "screeningCampaign") : "screeningCampaign",
          reference: this.fileData.forceHealthCare ? (this.fileData.forceHealthCare.reference || "") : "",
          date: this.fileData.forceHealthCare ? (this.fileData.forceHealthCare.date || "") : ""
        };
        this.forceHealthCare = Boolean(this.fileData.forceHealthCare);
        this.ipp = this.fileData.ipp;
        // prescribingOrganisation
        this.prescribingOrganisation = this.fileData.prescribingOrganisation;
        this.forceInvoicePatient = this.fileData.forceInvoicePatient;
        this.hospitalisationNumber = this.fileData.hospitalisationNumber;
        // fileContacts
        const fileContactsPrescriber = this.fileData.fileContacts ? this.fileData.fileContacts.filter(v => v.isPrescriber) : [];
        this.prescriber = fileContactsPrescriber[0] ? fileContactsPrescriber[0].contact : null;
        this.prescriberHasDownloaded = fileContactsPrescriber[0] ? fileContactsPrescriber[0].downloaded : false;
        this.fileContacts = this.fileData.fileContacts ? this.fileData.fileContacts.filter(v => !v.isPrescriber) : [];

        this.contactInformationId = this.fileData.contactInformationId;
        this.contactInformation = this.fileData.contactInformation || null;
        // invoicedOrganisation
        this.invoicedOrganisation = this.fileData.invoicedOrganisation;
        // containerGroups
        if (this.fileData.additionalFeeGroup) {
          this.additionalFeeGroup = this.fileData.additionalFeeGroup;
        } else {
          this.additionalFeeGroup = {
            id: null,
            name: this.$t("additionalFeeGroups.defaultGroupName"),
            rank: 0
          };
        }
        this.containerGroups = this.fileData.containerGroups || null;

        // setup file collectionDates
        const collectionDatesArr = [];
        if (this.fileData.containerGroups !== null) {
          for (const containerGroup of this.fileData.containerGroups) {
            collectionDatesArr.push(containerGroup.collectionDate ? this.$d(new Date(containerGroup.collectionDate), "date") : "");
          }
        }
        this.collectionDates = collectionDatesArr;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    onGoBack() {
      if (this.cancelRouteProp) {
        navigate(this.cancelRouteProp.name, this.cancelRouteProp.props);
      } else if (this.isEnabled(["roleSpecific", "secretary"])) { // + admin + system admin
        navigate("office/files");
      } else if (this.isEnabled(["roleSpecific", "accountant"])) {
        navigate("accounting/files");
      } else if (this.isEnabled(["roleSpecific", "cytotechnician"]) || this.isEnabled(["roleSpecific", "technician"])) {
        navigate("diagnosis/files");
      } else {
        navigate("office/files");
      }
    },
    // ----- COMPLEMENTARY FILE ----- //
    addComplementaryFile() {
      if (this.fileRequestErrors && this.fileRequestErrors.length) {
        // error
        this.$emit("alert", "danger", {
          title: this.$t("fileEdit.notifications.cantAddComplementaryWithCurrentErrorTitle")
        });
      } else if (this.moduleModeProp) {
        this.$emit("onOpenAddComplementaryFileModule", true);
      } else {
        this.navigate("office/file/new", { parentFileIdProp: this.fileIdProp, accessNumberProp: this.accessNumber });
      }
    },
    // MedicalReport
    async downloadMedicalReport() {
      try {
        // all files must be validated
        if (!this.isReadyMedicalReport) {
          this.$emit("alert", "warning", {
            title: this.$t("files.notifications.validateAllFilesBeforeDownloadTitle"),
            message: this.$t("files.notifications.validateAllFilesBeforeDownloadText")
          });
          return false;
        }

        this.isDownloading = true;

        const res = await cytologyReportsServices.getAllByFileId(this.parentFileId || this.fileId);
        const reports = res.data.cytologyReports || [];

        for (const report of reports) {
          if (report.isSuperseded === false) {
            const resFile = await commonServices.export("cytologyReports", report.id, "pdf");
            if (resFile.data.type === "application/json") {
              this.$emit("alert", "danger", {
                title: this.$t("general.downloadFileErrorTitle"),
                message: this.$t("general.downloadFileErrorText")
              });
            } else {
              fileSaver.saveAs(resFile.data, this.accessNumber + ".pdf");
            }
            this.isDownloading = false;
            return true;
          }
        }

        // no report found
        this.$emit("alert", "danger", {
          title: this.$t("files.notifications.medicalReportNotFound")
        });
        this.isDownloading = false;
      } catch (err) {
        this.isDownloading = false;
        this.handleErrors(err);
      }
    },
    // Compensation
    async onEditedCompensation(file) {
      // groupOfFiles
      for (const groupFile of this.groupOfFiles) {
        if (groupFile.id === file.id) {
          groupFile.accountingStatus = file.accountingStatus;
        }
      }

      if (this.fileData.id === file.id) {
        this.fileData.accountingStatus = file.accountingStatus;
        this.fileData.accountingStatusId = file.accountingStatus.id;
        // file statuses
        this.fileStatusesHistory = file.fileStatusesHistory;
        this.accountingStatus = file.accountingStatus.name;
      }
    }
  }
};
</script>

<template>
  <div
    class="kt-smart-select"
    :class="'kt-smart-select--' + $systemSettings.theme"
  >
    <!-- 1 (if displayUniqueProp), 2 or 3 options -->
    <b-form-group
      v-if="!forceSelectInputProp && (forceRadioInputProp || (optionsFiltered.length > 0 && optionsFiltered.length <= 3))"
      v-show="isInputDisplayed"
      :label="labelProp"
      :invalid-feedback="invalidFeedbackProp"
      :state="stateProp"
      :class="formGroupClassProp"
    >
      <b-form-radio-group
        ref="radioInput"
        v-model="selectedValue"
        :options="optionsFiltered"
        :value-field="valueFieldProp"
        :text-field="textFieldProp"
        :state="stateProp"
        :disabled="disabledProp"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
      ></b-form-radio-group>
    </b-form-group>

    <!-- at least 4 options -->
    <b-row v-if="!forceRadioInputProp && (forceSelectInputProp || optionsFiltered.length >= 4)">
      <b-col
        :cols="responsiveOptionsProp.cols"
        :md="responsiveOptionsProp.md"
      >
        <b-form-group
          v-show="isInputDisplayed"
          :label="labelProp"
          :invalid-feedback="invalidFeedbackProp"
          :state="stateProp"
          :class="formGroupClassProp"
        >
          <b-form-select
            ref="selectInput"
            v-model="selectedValue"
            :options="optionsFiltered"
            :value-field="valueFieldProp"
            :text-field="textFieldProp"
            :state="stateProp"
            :disabled="disabledProp"
            @input="$emit('input', $event)"
            @change="$emit('change', $event)"
            @blur="$emit('blur', $event)"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// helpers
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    labelProp: {
      type: String,
      default: ""
    },
    optionsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    valueFieldProp: {
      type: String,
      default: "value"
    },
    textFieldProp: {
      type: String,
      default: "text"
    },

    valueProp: {
      type: [String, Number],
      default: null
    },
    stateProp: {
      type: Boolean
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },

    disabledProp: {
      type: Boolean
    },
    showDisabledItemsProp: {
      type: Boolean
    },
    displayUniqueProp: {
      type: Boolean
    },
    initialValueProp: {
      type: [String, Number],
      default: null
    },
    showInactiveItemsProp: {
      type: Boolean
    },
    selectFirstOnloadProp: {
      type: Boolean
    },
    responsiveOptionsProp: {
      type: Object,
      default: function() {
        return {
          cols: 12,
          md: 4
        };
      }
    },
    formGroupClassProp: {
      type: String,
      default: ""
    },
    forceSelectInputProp: {
      type: Boolean
    },
    forceRadioInputProp: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedValue: this.valueProp
    };
  },
  computed: {
    optionsFilteredSelectables: function() {
      const arr = [];

      // setup options (filter out disabled and inactive, except if it is the selected value)
      for (const option of this.optionsProp) {
        if (
          (typeof option.disabled === "undefined" || option.disabled === false) &&
          (typeof option.isActive === "undefined" || option.isActive || option[this.valueFieldProp] === this.initialValueProp)
        ) arr.push(JSON.parse(JSON.stringify(option)));
      }
      // add "deleted" label in inactive name
      arr.forEach(item => {
        if (typeof item.isActive !== "undefined" && item.isActive === false) {
          item[this.textFieldProp] += " (" + this.$t("general.deletedLabel") + ")";
          item.disabled = true;
        }
      });

      return arr;
    },
    isInputDisplayed: function() {
      const isDisplayed = this.displayUniqueProp || this.optionsFiltered.length !== 1;
      this.$emit("updateIsDisplayed", isDisplayed);
      return isDisplayed;
    },
    optionsFiltered: function() {
      let options = JSON.parse(JSON.stringify(this.optionsProp));
      options = options
        .filter(v => {
          // setup options (filter out disabled and inactive, except if it is the selected value)
          return (
            (this.showDisabledItemsProp || typeof v.disabled === "undefined" || v.disabled === false || v[this.valueFieldProp] === this.initialValueProp) &&
            (this.showInactiveItemsProp || typeof v.isActive === "undefined" || v.isActive || v[this.valueFieldProp] === this.initialValueProp)
          );
        })
        .map(v => {
          // add "deleted" label in inactive name
          if (typeof v.isActive !== "undefined" && v.isActive === false) {
            v[this.textFieldProp] += " (" + this.$t("general.deletedLabel") + ")";
            v.disabled = true;
          }
          return v;
        });

      return options;
    }
  },
  watch: {
    valueProp: function(val) {
      this.selectedValue = val;
    }
  },
  mounted() {
    // auto select
    if (!this.disabledProp && this.initialValueProp === null) {
      if (this.optionsFilteredSelectables.length) {
        if (
          this.optionsFiltered.length === 1 ||
          (this.selectFirstOnloadProp && this.optionsFiltered.length > 1)
        ) {
          this.$emit("input", this.optionsFilteredSelectables[0][this.valueFieldProp]);
          this.$emit("change", this.optionsFilteredSelectables[0][this.valueFieldProp]);
        }
      }
    }
  },
  methods: {
    focusFirstElement() {
      if (this.optionsFiltered.length > 0 && this.optionsFiltered.length <= 3) {
        let focused = false;
        for (const child of this.$refs.radioInput.$children) {
          if (child.value === this.selectedValue) {
            child.focus();
            focused = true;
            break;
          }
        }
        if (!focused) {
          this.$refs.radioInput.$children[0].focus();
        }
      } else if (this.optionsFiltered.length >= 4) {
        this.$refs.selectInput.focus();
      }
    }
  }
};
</script>

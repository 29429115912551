<template>
  <div class="kt-report-edit">
    <!-- file details -->
    <ReportFileDetails
      v-if="showFileDetailsProp"
      :fileDataProp="fileData"
      :hpvResultsProp="hpvResults"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- statuses warnings -->
    <div class="kt-report-edit__warnings">
      <!-- cancelled -->
      <div
        v-show="cancelled"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="x"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.cancelledWarning") }}</span>
      </div>

      <!-- technically validated -->
      <div
        v-show="!isSuperseded && validatedTechnically && !validatedMedically"
        class="kt-validated-warning kt-badge kt-badge--blue kt-badge--lg mr-1"
      >
        <b-icon
          icon="check-circle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.validatedWarningTechnically") }}</span>
      </div>

      <!-- medically validated -->
      <div
        v-show="validatedMedically && isSuperseded === false"
        class="kt-validated-warning kt-badge kt-badge--light-green kt-badge--lg mt-1 mr-1"
      >
        <b-icon
          icon="check-circle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.validatedWarningMedically") }}</span>
        <!-- View -->
        <b-button
          v-show="!validateAllFilesBeforeDownload"
          class="kt-badge__btn my-n1"
          size="sm"
          variant="success"
          @click="onView"
        >
          <b-icon icon="eye"></b-icon> {{ $t("general.view") }}
        </b-button>
        <b-spinner
          v-show="isDownloading"
          style="margin-left: 6px;"
          :class="'kt-spinner--' + $systemSettings.theme"
          small
        ></b-spinner>
        <!-- option drop-down (new version) -->
        <div
          v-if="validatedMedically && isSuperseded === false && isRole('pathologist')"
          class="kt-dropdown-btn kt-dropdown-btn--success kt-dropdown-btn--no-arrow ml-2 my-n1"
        >
          <b-button
            variant="outline-secondary"
            size="sm"
            class="btn-icon kt-dropdown-btn__switcher-btn"
            pill
          >
            <b-icon icon="three-dots-vertical"></b-icon>
          </b-button>
          <ul
            class="kt-dropdown-btn__list"
          >
            <li class="kt-dropdown-btn__shadow"></li>

            <!-- new version button -->
            <li
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button text-danger"
                @click="createNewVersion"
              >
                <b-icon
                  icon="trash"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("medicalReportEdit.versioning.createNewVersion") }}
              </button>
            </li>

            <!-- <li class="kt-dropdown-btn__divider"></li> -->
          </ul>
        </div>
      </div>

      <!-- info badge : validateAllFilesBeforeDownload -->
      <div
        v-show="validateAllFilesBeforeDownload"
        class="kt-validated-warning kt-badge kt-badge--blue kt-badge--lg mt-1 mr-1"
      >
        <b-icon
          icon="info-circle"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.validateAllFilesBeforeDownload") }}</span>
      </div>

      <!-- overwritten warnings -->
      <span
        v-show="isSuperseded"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="x-circle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.versioning.reportCancelled") }}</span>
      </span>

      <!-- documentStatuses -->
      <DocumentStatuses
        :dataProp="cytologyReportData"
        class="mt-1 ml-2"
      />
      <!-- option drop-down -->
      <div
        v-if="validatedMedically && !validateAllFilesBeforeDownload && isSuperseded === false && cytologyReportData"
        class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-2 my-n1"
      >
        <b-button
          variant="outline-secondary"
          size="sm"
          class="btn-icon kt-dropdown-btn__switcher-btn"
          pill
        >
          <b-icon icon="three-dots-vertical"></b-icon>
        </b-button>
        <ul
          class="kt-dropdown-btn__list"
        >
          <li class="kt-dropdown-btn__shadow"></li>

          <!-- resetSendElectronically -->
          <li
            class="kt-dropdown-btn__item"
          >
            <button
              class="kt-dropdown-btn__button"
              @click="resetSendElectronically"
            >
              <b-icon
                icon="cloud"
                class="kt-dropdown-btn__icon"
              ></b-icon>
              {{ $t("medicalReports.actions.resetSendElectronically") }}
            </button>
          </li>

          <!-- <li class="kt-dropdown-btn__divider"></li> -->
        </ul>
      </div>
    </div>

    <b-row class="kt-row__padding-sm mt-2 pb-4">
      <!-- LEFT COLUMN : report context -->
      <b-col class="kt-report-edit__fixed-col">
        <div class="kt-report-context">
          <!-- cytotechnician name -->
          <!-- <div class="text-15 mb-2">
            <span
              class="font-weight-bold"
              style="color: #373b3f;"
            >{{ "Cytotech. :" + "" }}
            </span>{{ (cytotechnician ? cytotechnician.firstName + " " + cytotechnician.lastName : $t("general.emptyWithHyphen")) }}
          </div> -->
          <!-- pathologist name -->
          <h2 class="kt-report-edit__title">
            {{ $t("medicalReportEdit.pathologist") }}
          </h2>
          <InputSearch
            :valueProp="pathologist"
            :displayedValueProp="pathologistName"
            disabledProp
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>

          <!-- divider and title -->
          <div class="kt-report-context__divider"></div>
          <h2 class="kt-report-edit__title">
            {{ $t("medicalReportEdit.clinicalInformation") }}
          </h2>

          <!-- screening -->
          <span
            class="kt-badge kt-badge--light-gray"
          >
            {{ $t("cytologyReport.screening.screening") + ' :' }}
            <strong>{{ screening ? $t("cytologyReport.screening." + screening) : $t("cytologyReport.screening.empty") }}</strong>
          </span>

          <!-- hormonal status -->
          <div class="kt-listed-input">
            <label
              class="mr-2 kt-report-edit__label"
            >{{ $t("cytologyReport.hormonalStatus.hormonalStatus") }}</label>
            <ul
              v-show="form.hormonalStatus"
              class="kt-listed-input__list"
            >
              <li
                v-show="form.hormonalStatus"
                class="kt-listed-input__item"
              >
                {{ form.hormonalStatus ? $t("cytologyReport.hormonalStatus." + form.hormonalStatus) : '' }}
              </li>
            </ul>
          </div>

          <!-- information -->
          <div class="kt-listed-input">
            <label
              class="mr-2 kt-report-edit__label"
            >{{ $t("cytologyReport.information.information") }}</label>
            <ul
              v-show="form.information.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="info in form.information"
                :key="info.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.information." + info) }}
              </li>
            </ul>
          </div>

          <div class="kt-report-context__divider"></div>

          <!-- Send the report to the patient -->
          <div>
            <div
              class="kt-checkbox-display-only-cont pt-0"
            >
              <span
                class="kt-checkbox-display-only"
                :class="'kt-checkbox-display-only--' + $systemSettings.theme + (form.sendReportToPatient ? ' kt-checkbox-display-only--checked' : ' kt-checkbox-display-only--crossed')"
              >
                <span class="kt-checkbox-display-only__cross"></span>
              </span>
              <span class="kt-checkbox-display-only__text">{{ form.sendReportToPatient ? $t("medicalReportEdit.sendReportToPatient") : $t("medicalReportEdit.sendReportToPatientInactive") }}</span>
            </div>
          </div>
        </div>
      </b-col>

      <!-- MIDDLE COLUMN : results -->
      <b-col>
        <div class="kt-report-results">
          <!-- smear & layering -->
          <div class="kt-report-results__radio-group mb-3">
            <span class="kt-badge kt-badge--light-gray">
              {{ $t("cytologyReport.smear.smear") + ' :' }}
              <strong>{{ smear ? $t("cytologyReport.smear." + smear) : $t("cytologyReport.smear.empty") }}</strong>
            </span>
            <span style="display: inline-block; border-left: 1px solid #dee0e2; height: 1rem; vertical-align: middle; margin: 0 4px"></span>
            <span class="kt-badge kt-badge--light-gray">
              {{ $t("cytologyReport.layering.layering") + ' :' }}
              <strong>{{ layering ? $t("cytologyReport.layering." + layering) : $t("cytologyReport.layering.empty") }}</strong>
            </span>
          </div>

          <div class="pb-2">
            <!-- unsatisfactory basge  -->
            <div
              v-show="form.quality === 'notSatisfactory'"
              class="mb-1"
            >
              <span class="kt-badge kt-badge--red">
                <span class="kt-badge__text font-weight-semi-bold">{{ $t("cytologyReport.qualityOptions.notSatisfactory") }}</span>
              </span>
            </div>
            <!-- satisfactory -->
            <div class="mb-1">
              <span
                class="kt-badge kt-badge--light-gray kt-badge--lg"
              >
                <!-- {{ $t("cytologyReport.satisfactoryViewTitle") + ' :' }} -->
                <span class="kt-badge__text font-weight-semi-bold">{{ form.satisfactory ? $t("cytologyReport.satisfactoryOptions." + form.satisfactory) : $t("general.emptyWithHyphen") }}</span>
              </span>
            </div>
            <!-- quality -->
            <div
              v-show="form.satisfactory === 'satisfactory'"
              class="mb-1"
            >
              <span
                class="kt-badge kt-badge--light-gray kt-badge--lg"
              >
                <!-- {{ $t("cytologyReport.qualityViewTitle") + ' :' }} -->
                <span class="kt-badge__text font-weight-semi-bold">{{ form.quality ? $t("cytologyReport.qualityOptions." + form.quality) : $t("general.emptyWithHyphen") }}</span>
              </span>
            </div>
            <!-- sub qualities  -->
            <div
              v-show="form.quality === 'notSatisfactory'"
              class="mb-1"
            >
              <!-- {{ $t("cytologyReport.subqualityViewTitle") + ' :' }} -->
              <span
                v-for="(subqualityItem, index) in subqualitiesDisplay"
                :key="index"
                class="kt-badge kt-badge--light-gray kt-badge--lg mr-1"
              >
                <span class="kt-badge__text font-weight-semi-bold">{{ $t("cytologyReport.subqualityOptions." + subqualityItem) }}</span>
              </span>
            </div>
          </div>

          <!-- radio - result -->
          <b-form-group
            disabled
            class="kt-report-evaluation mb-0"
            :class="form.result !== '' ? 'kt-report-evaluation--checked' : 'kt-report-evaluation--unchecked'"
          >
            <div class="kt-report-evaluation__wrapper">
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--1">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'nilm' ? 'kt-report-evaluation__active' : ''"
                  value="nilm"
                >
                  {{ $t("cytologyReport.result.nilm") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--2">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'ascus' ? 'kt-report-evaluation__active' : ''"
                  value="ascus"
                >
                  {{ $t("cytologyReport.result.ascus") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--2">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'asch' ? 'kt-report-evaluation__active' : ''"
                  value="asch"
                >
                  {{ $t("cytologyReport.result.asch") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--3">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'lsil' ? 'kt-report-evaluation__active' : ''"
                  value="lsil"
                >
                  {{ $t("cytologyReport.result.lsil") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--3">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'agc' ? 'kt-report-evaluation__active' : ''"
                  value="agc"
                >
                  {{ $t("cytologyReport.result.agc") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--3">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'hsil' ? 'kt-report-evaluation__active' : ''"
                  value="hsil"
                >
                  {{ $t("cytologyReport.result.hsil") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--4">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'ais' ? 'kt-report-evaluation__active' : ''"
                  value="ais"
                >
                  {{ $t("cytologyReport.result.ais") }}
                </b-button>
              </div>
              <div class="kt-report-evaluation__btn-wrapper kt-report-evaluation__btn-wrapper--4">
                <b-button
                  v-model="form.result"
                  :class="form.result === 'scc' ? 'kt-report-evaluation__active' : ''"
                  value="scc"
                >
                  {{ $t("cytologyReport.result.scc") }}
                </b-button>
              </div>
            </div>
          </b-form-group>
        </div>
      </b-col>

      <!-- RIGHT COLUMN -->
      <b-col class="kt-report-edit__fixed-col">
        <div class="kt-report-right">
          <!-- COMPLEMENTS  -->
          <h2 class="kt-report-edit__title">
            {{ $t("medicalReportEdit.complements") }}
          </h2>

          <!-- pathogens -->
          <div class="kt-listed-input mt-0">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.pathogens.pathogens") }}</label>
            <ul
              v-show="form.pathogens.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="pathogen in form.pathogens"
                :key="pathogen.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.pathogens." + pathogen) }}
              </li>
            </ul>
          </div>

          <!-- hormonal -->
          <div class="kt-listed-input">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.hormonal.hormonal") }}</label>
            <ul
              v-show="form.hormonal.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="hormonalItem in form.hormonal"
                :key="hormonalItem.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.hormonal." + hormonalItem) }}
              </li>
            </ul>
          </div>

          <!-- reaction -->
          <div class="kt-listed-input">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.reaction.reaction") }}</label>
            <ul
              v-show="form.reaction.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="reactionItem in form.reaction"
                :key="reactionItem.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.reaction." + reactionItem) }}
              </li>
            </ul>
          </div>

          <!-- reorganisation -->
          <div class="kt-listed-input">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.reorganisation.reorganisation") }}</label>
            <ul
              v-show="form.reorganisation.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="reorganisationItem in form.reorganisation"
                :key="reorganisationItem.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.reorganisation." + reorganisationItem) }}
              </li>
            </ul>
          </div>

          <!-- complementaryTechniques -->
          <div class="kt-listed-input">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.complementaryTechniques.complementaryTechniques") }}</label>
            <ul
              v-show="form.complementaryTechniques.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="complementaryTechItem in form.complementaryTechniques"
                :key="complementaryTechItem.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.complementaryTechniques." + complementaryTechItem) }}
              </li>
            </ul>
          </div>

          <!-- control -->
          <div class="kt-listed-input">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.control.control") }}</label>
            <ul
              v-show="form.control.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="controlItem in form.control"
                :key="controlItem.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.control." + controlItem) }}
              </li>
            </ul>
          </div>

          <!-- recommendations -->
          <div class="kt-listed-input">
            <label
              class="kt-report-edit__label"
            >{{ $t("cytologyReport.recommendations.recommendations") }}</label>
            <ul
              v-show="form.recommendations.length !== 0"
              class="kt-listed-input__list"
            >
              <li
                v-for="recommendationsItem in form.recommendations"
                :key="recommendationsItem.id"
                class="kt-listed-input__item"
              >
                {{ $t("cytologyReport.recommendations." + recommendationsItem) }}
              </li>
            </ul>
            <!-- otherRecommendation -->
            <div
              v-show="form.otherRecommendation"
              class="text-15"
              :style="form.recommendations.length !== 0 ? 'margin-top: -11px !important;' : ''"
            >
              <div class="font-weight-bold">
                {{ $t("cytologyReport.recommendations.otherRecommendation") }}
              </div>
              <div style="line-height: 1.42; white-space: pre-line; word-break: break-word;">
                {{ form.otherRecommendation }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// components
import ReportFileDetails from "@/views/Diagnosis/ReportFileDetails";
import InputSearch from "@shared/views/Helpers/InputSearch";
import DocumentStatuses from "@shared/views/Helpers/DocumentStatuses";
// services
import commonServices from "@shared/services/API/commonServices";
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
import filesServices from "@/services/API/filesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import fileSaver from "file-saver";

export default {
  components: {
    ReportFileDetails,
    InputSearch,
    DocumentStatuses
  },
  mixins: [userRights, error, date],
  props: {
    cytologyReportDataProp: {
      type: Object,
      default: null
    },
    reportIdProp: {
      type: Number,
      default: null
    },
    fileIdProp: {
      type: Number,
      default: null
    },
    showFileDetailsProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general variables
      cytologyReportData: null,
      cytologyReportId: null,
      previousCytologyReports: [],
      isSuperseded: true,
      isDownloading: false,

      // file info
      fileData: null,
      fileId: null,
      parentFileId: null,
      complementaryFiles: [],
      groupOfFiles: [],

      validatedTechnically: false,
      validatedMedically: false,
      cancelled: false,

      laboratoryId: null,
      age: 0,
      isHpv: false,
      isCytology: false,
      hpvResults: [],
      // radio-button values
      screening: "",
      smear: "",
      layering: "",

      // base variables
      form: {
        hormonalStatus: "",
        information: [],
        sendReportToPatient: false,
        satisfactory: "satisfactory",
        result: "",
        quality: "satisfactoryWithCells",
        pathogens: [],
        hormonal: [],
        reaction: [],
        reorganisation: [],
        complementaryTechniques: [],
        control: [],
        recommendations: [],
        otherRecommendation: ""
      },
      subqualitiesDisplay: [],
      pathologist: null,
      cytotechnician: null
    };
  },
  computed: {
    validateAllFilesBeforeDownload: function() {
      let output = false;
      if (this.validatedMedically && !this.isSuperseded) {
        for (const file of this.groupOfFiles) {
          if (file.medicalStatus.name !== "validatedMedically" && file.medicalStatus.name !== "cancelled") {
            output = true;
            break;
          }
        }
      }
      return output;
    },
    pathologistName: function() {
      let name = "";
      if (this.pathologist) {
        name = this.pathologist.user.firstName + " " + this.pathologist.user.lastName + " (" + this.pathologist.code + ")";
        if (this.pathologist.user.isActive === false) name += " (" + this.$t("general.deletedLabel") + ")";
      }
      return name;
    }
  },
  watch: {
    // update subqualities for display
    form: {
      handler: function() {
        if (this.form.subquality === "notSatisfactory") {
          this.subqualitiesDisplay = this.form.subquality;
        } else {
          this.subqualitiesDisplay = [];
        }
      },
      deep: true
    }
  },
  async mounted() {
    try {
      if (this.cytologyReportDataProp) {
        await this.refreshReportByProp(this.cytologyReportDataProp);
      } else if (this.fileIdProp) {
        await this.refreshReportByFileId(this.fileIdProp);
      } else {
        // no report found
        this.$emit("alert", "danger", {
          title: this.$t("cytologyReport.reportNotFound.title"),
          message: this.$t("cytologyReport.reportNotFound.text")
        });
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    // unused for now
    async refreshReport(id) {
      const resReport = await commonServices.get("cytologyReports", id);
      if (resReport.data.id) {
        // file
        const resFile = await commonServices.get("files", resReport.data.fileId);
        await this.updateFileInfo(resFile.data);
        // report
        this.cytologyReportData = resReport.data;
        this.cytologyReportId = resReport.data.id;
        this.isSuperseded = resReport.data.isSuperseded;
        this.hpvResults = this.isHpv ? resReport.hpvResults : [];
        this.pathologist = resReport.data.pathologist;
        this.cytotechnician = resReport.data.cytotechnician;
        // report
        if (this.cytologyReportData.content) {
          this.updateReportInfo(this.cytologyReportData.content);
        }
      } else {
        this.$emit("alert", "danger", {
          title: this.$t("diagnosis.unknownCytologyReport.title"),
          message: this.$t("diagnosis.unknownCytologyReport.text")
        });
      }
    },
    async refreshReportByProp(data) {
      if (data) {
        this.cytologyReportData = JSON.parse(JSON.stringify(data));
        this.cytologyReportId = data.id;
        this.isSuperseded = data.isSuperseded;
        this.pathologist = this.cytologyReportData.pathologist;
        this.cytotechnician = this.cytologyReportData.cytotechnician;
        // file
        await this.updateFileInfo(this.cytologyReportData.file);
        // report
        if (this.cytologyReportData.content) {
          this.updateReportInfo(this.cytologyReportData.content);
        }
      } else {
        this.$emit("alert", "danger", {
          title: this.$t("diagnosis.unknownCytologyReport.title"),
          message: this.$t("diagnosis.unknownCytologyReport.text")
        });
      }
    },
    async refreshReportByFileId(fileId) {
      const resReports = await cytologyReportsServices.getAllByFileId(fileId); // available : cytologyReports file
      if (resReports.data.cytologyReports && resReports.data.cytologyReports.length > 0) {
        // file
        await this.updateFileInfo(resReports.data.file);
        // save latest report
        this.cytologyReportData = resReports.data.cytologyReports[resReports.data.cytologyReports.length - 1];
        this.cytologyReportId = this.cytologyReportData.id;
        this.hpvResults = this.isHpv ? resReports.data.cytologyReports[resReports.data.cytologyReports.length - 1].hpvResults : [];
        this.isSuperseded = this.cytologyReportData.isSuperseded;
        this.pathologist = this.cytologyReportData.pathologist;
        this.cytotechnician = this.cytologyReportData.cytotechnician;
        // report
        if (this.cytologyReportData.content) {
          this.updateReportInfo(this.cytologyReportData.content);
        }
        // save previous reports
        this.previousCytologyReports = [];
        for (let i = 0; i < resReports.data.cytologyReports.length - 1; i++) {
          resReports.data.cytologyReports[i].hpvResults = this.hpvResults;
          this.previousCytologyReports.push(resReports.cytologyReports.data[i]);
        }
      } else {
        this.cytologyReportData = null;
        this.cytologyReportId = null;
        this.hpvResults = [];
        this.isSuperseded = false;
        // no report found
        this.$emit("alert", "danger", {
          title: this.$t("cytologyReport.reportNotFound.title"),
          message: this.$t("cytologyReport.reportNotFound.text")
        });
      }
    },
    async updateFileInfo(file) {
      this.fileId = file.id;
      this.parentFileId = file.parentFileId;

      // groupOfFiles
      if (!file.groupOfFiles) {
        this.complementaryFiles = file.childrenFiles || [];
        file.complementaryFiles = file.childrenFiles || [];
        this.complementaryFiles = this.complementaryFiles.map((item) => {
          item.parentFileId = file.id;
          return item;
        });
        let groupOfFiles = [];
        if (this.parentFileId) {
          // current file is a complementary
          const resLinkedFiles = await filesServices.getLinkedFiles(this.parentFileId);
          if (resLinkedFiles.data) groupOfFiles = resLinkedFiles.data;
          file.parentFile.accessNumber = file.accessNumber;
          groupOfFiles.unshift(file.parentFile);
        } else {
          // current file is the parent
          if (this.complementaryFiles && this.complementaryFiles.length) {
            groupOfFiles = this.complementaryFiles;
          }
          groupOfFiles.unshift({
            id: this.fileId,
            parentFileId: null,
            accessNumber: file.accessNumber,
            creationDate: file.creationDate,
            filingStatus: file.filingStatus || null,
            medicalStatus: file.medicalStatus,
            accountingStatus: file.accountingStatus || null
          });
        }
        file.groupOfFiles = groupOfFiles;
      }
      this.groupOfFiles = file.groupOfFiles;

      this.fileData = file;

      this.validatedTechnically = file.medicalStatus.name === "validatedTechnically";
      this.validatedMedically = file.medicalStatus.name === "validatedMedically";
      this.cancelled = file.medicalStatus.name === "cancelled";
      this.laboratoryId = file.laboratoryId;
      this.age = this.getAgeYearFromBirthdate(file.patient.birthdate);

      if (file.containerGroups.length === 1 && file.containerGroups[0].containerGroupTasks.length === 1) {
        this.isHpv = file.containerGroups[0].containerGroupTasks[0].task.content.isHpv;
        this.isCytology = file.containerGroups[0].containerGroupTasks[0].task.content.isCytology;
        // radio-button values
        this.screening = file.containerGroups[0].containerGroupTasks[0].task.content.screening;
        this.smear = file.containerGroups[0].containerGroupTasks[0].task.content.smear;
        this.layering = file.containerGroups[0].containerGroupTasks[0].task.content.layering;
      } else {
        this.isHpv = false;
        this.isCytology = false;
        // radio-button values
        this.screening = "";
        this.smear = "";
        this.layering = "";
      }
    },
    updateReportInfo(content) {
      this.form.hormonalStatus = content.hormonalStatus;
      this.form.information = content.information;
      this.form.sendReportToPatient = content.sendReportToPatient;
      this.form.satisfactory = content.satisfactory;
      this.form.result = content.result;
      this.form.quality = content.quality;
      this.$nextTick(() => {
        this.form.subquality = content.subquality;
        this.subqualitiesDisplay = content.subquality;
      });
      this.form.cellModifications = content.cellModifications;
      this.form.pathogens = content.pathogens;
      this.form.hormonal = content.hormonal;
      this.form.reaction = content.reaction;
      this.form.reorganisation = content.reorganisation;
      this.form.complementaryTechniques = content.complementaryTechniques;
      this.form.control = content.control;
      this.form.recommendations = content.recommendations;
      this.form.otherRecommendation = content.otherRecommendation;
    },

    // View actions
    async onView() {
      if (!this.isDownloading) {
        this.isDownloading = true;
        try {
          const resFile = await commonServices.export("cytologyReports", this.cytologyReportData.id, "pdf");
          if (resFile.data.type === "application/json") {
            this.$emit("alert", "danger", {
              title: this.$t("general.downloadFileErrorTitle"),
              message: this.$t("general.downloadFileErrorText")
            });
          } else {
            fileSaver.saveAs(resFile.data, this.fileData.accessNumber + ".pdf");
          }
        } catch (err) {
          this.handleErrors(err);
        }
        this.isDownloading = false;
      }
    },

    // Versions
    async createNewVersion() {
      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("medicalReportEdit.versioning.newVersionConfirmationText"), {
        title: this.$t("medicalReportEdit.versioning.newVersionConfirmationTitle"),
        okVariant: "danger",
        okTitle: this.$t("medicalReportEdit.versioning.newVersionConfirmationOk"),
        cancelTitle: this.$t("medicalReportEdit.versioning.newVersionConfirmationCancel"),
        centered: true
      });
      // create new version
      if (result) {
        try {
          const res = await cytologyReportsServices.newVersion(this.cytologyReportId);
          if (res.data.id) {
            this.$emit("newVersionCreated", true);
            return true;
          } else if (res.data.error && res.data.error === "fileNotValidatedMedically") {
            this.$emit("alert", "danger", {
              title: this.$t("medicalReportEdit.notifications.currentVersionNotValidatedMedicallyTitle"),
              message: this.$t("medicalReportEdit.notifications.currentVersionNotValidatedMedicallyText")
            });
            this.isLoading = false;
            this.$emit("refreshCytologyReport", true);
            return false;
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("medicalReportEdit.notifications.newVersionErrorTitle"),
              message: this.$t("medicalReportEdit.notifications.newVersionErrorText")
            });
            return false;
          }
        } catch (err) {
          this.handleErrors(err);
        }
      }
    },
    async resetSendElectronically() {
      try {
        this.isLoading = true;
        const res = await cytologyReportsServices.resetSentElectronically(this.cytologyReportId);
        if (res.data === true) {
          this.cytologyReportData.isSentElectronically = null;
          this.$emit("alert", "success", {
            title: this.$t("medicalReports.notifications.sentElectronicallyTitle"),
            message: this.$t("medicalReports.notifications.sentElectronicallyText")
          });
          return true;
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReports.notifications.sendElectronicallyErrorTitle"),
            message: this.$t("medicalReports.notifications.sendElectronicallyErrorText")
          });
        }
        this.isLoading = false;
      } catch (err) {
        this.handleErrors(err);
        this.isLoading = false;
      }
    }
  }
};
</script>

<style src="./CytologyReportEdit-global.scss" lang="scss"></style>
<style scoped src="./CytologyReportEdit.scss" lang="scss"></style>

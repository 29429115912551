import Vue from "vue";
import VueI18n from "vue-i18n";

import enDateTimeFormats from "@shared/locales/enDateTimeFormats.json";
import frDateTimeFormats from "@shared/locales/frDateTimeFormats.json";
import enNumberFormats from "@shared/locales/enNumberFormats.json";
import frNumberFormats from "@shared/locales/frNumberFormats.json";
import enMessages from "./enMessages.json";
import sharedEnMessages from "@shared/locales/enMessages.json";
import frMessages from "./frMessages.json";
import sharedFrMessages from "@shared/locales/frMessages.json";

Vue.use(VueI18n);

const messages = {
  en: { ...sharedEnMessages.en, ...enMessages.en },
  fr: { ...sharedFrMessages.fr, ...frMessages.fr }
};

const dateTimeFormats = {
  en: enDateTimeFormats.en,
  fr: frDateTimeFormats.fr
};

const numberFormats = {
  en: enNumberFormats.en,
  fr: frNumberFormats.fr
};

export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
  dateTimeFormats,
  numberFormats
});

<template>
  <div
    class="kt-pathologistReplacement kt-item-list__item"
    style="width: 100%"
  >
    <div v-if="!viewModeProp">
      <b-row>
        <!-- replacedPathologist -->
        <b-col
          cols="12"
          md="4"
        >
          <InputSearch
            searchedTableProp="pathologists"
            searchedFieldProp="user.lastName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'user.lastName', 'user.firstName', 'code', 'user.isActive']"
            suggestionsKeyProp="id"
            :labelProp="$t('pathologistReplacement.replacedPathologist')"
            :valueProp="replacedPathologist"
            :displayedValueProp="suggestionFormatPathologists(replacedPathologist)"
            :suggestionFormatProp="suggestionFormatPathologists"
            :stateProp="replacedPathologistStateDisplay"
            :invalidFeedbackProp="replacedPathologistInvalidFeedback"
            @onUpdateParent="($event) => {
              replacedPathologist = $event;
              replacedPathologistId = $event ? $event.id : null;
              sendToparent();
            }"
            @onActivateValidation="replacedPathologistValidation = true"
            @onDisableValidation="replacedPathologistValidation = false;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>
        <!-- ValidityDates -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('pathologistReplacement.startValidityDate')"
            :invalid-feedback="startValidityDateInvalidFeedback"
            :state="startValidityDateStateDisplay"
            class="mb-2"
          >
            <b-form-input
              v-model="startValidityDate"
              type="date"
              :state="startValidityDateStateDisplay"
              @blur="startValidityDateValidation = true"
              @input="sendToparent"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('pathologistReplacement.endValidityDate')"
            :invalid-feedback="endValidityDateInvalidFeedback"
            :state="endValidityDateStateDisplay"
            class="mb-2"
          >
            <b-form-input
              v-model="endValidityDate"
              type="date"
              :state="endValidityDateStateDisplay"
              @blur="endValidityDateValidation = true"
              @input="sendToparent"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <!-- viewMode -->
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <div>{{ replacedPathologist ? replacedPathologist.user.firstName + ' ' + replacedPathologist.user.lastName : '' }}</div>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <div>{{ $t("pathologistReplacement.validityDate") }}{{ (startValidityDate ? " " + $d(new Date(startValidityDate), "date") : "NC") + " - " + (endValidityDate ? $d(new Date(endValidityDate), "date") : "NC") }}</div>
        </b-col>
      </b-row>
    </div>

    <!-- actions -->
    <div class="kt-item-list__item-actions">
      <!-- delete button -->
      <b-button
        v-if="!viewModeProp"
        class="btn-icon kt-item-list__item-delete"
        size="sm"
        variant="light"
        squared
        @click="$emit('deletePathologistReplacement', tempId)"
      >
        <b-icon icon="x"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
// helpers
import userRights from "@/services/UI/userRights";
import date from "@shared/services/UI/date";

export default {
  components: {
    InputSearch
  },
  mixins: [userRights, date],
  props: {
    viewModeProp: {
      type: Boolean
    },
    pathologistReplacementProp: {
      type: Object,
      default: () => {
        return {
          id: null,
          tempId: "",
          replacedPathologist: null,
          replacedPathologistId: null,
          startValidityDate: "",
          endValidityDate: "",
          areDatesOverlapping: false
        };
      },
      deep: true
    },
    validationActiveProp: {
      type: Boolean
    },
    areDatesOverlappingProp: {
      type: Boolean
    }
  },
  data() {
    return {
      id: null,
      tempId: "",
      replacedPathologistId: null,
      replacedPathologist: null,
      startValidityDate: "",
      endValidityDate: "",
      // is validation active
      replacedPathologistValidation: this.validationActiveProp,
      startValidityDateValidation: this.validationActiveProp,
      endValidityDateValidation: this.validationActiveProp
    };
  },
  computed: {
    replacedPathologistState: function() {
      return this.replacedPathologist ? null : false;
    },
    replacedPathologistStateDisplay: function() {
      if (!this.replacedPathologistValidation) return null;
      return this.replacedPathologistState;
    },
    replacedPathologistInvalidFeedback: function() {
      return this.replacedPathologistStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    startValidityDateState_withoutOverlapping: function() {
      return this.startValidityDate && this.startValidityDate.length > 0 ? null : false;
    },
    startValidityDateState: function() {
      if (this.startValidityDateState_withoutOverlapping === false) return false;
      if (this.areDatesOverlappingProp) return false;
      return null;
    },
    startValidityDateStateDisplay: function() {
      if (!this.startValidityDateValidation) return null;
      return this.startValidityDateState;
    },
    startValidityDateInvalidFeedback: function() {
      if (this.startValidityDateStateDisplay === null) return "";
      if (!this.areDatesOverlappingProp) {
        return this.$t("validationRules.required");
      } else {
        return this.$t("pathologistReplacement.validations.overlappingDates");
      }
    },
    endValidityDateState_withoutOverlapping: function() {
      if (!this.endValidityDate) return null;
      return this.validateDateBeforeDate(this.startValidityDate, this.endValidityDate) ? null : false;
    },
    endValidityDateState: function() {
      if (this.endValidityDateState_withoutOverlapping === false) return false;
      if (this.areDatesOverlappingProp) return false;
      return null;
    },
    endValidityDateStateDisplay: function() {
      if (!this.endValidityDateValidation) return null;
      return this.endValidityDateState;
    },
    endValidityDateInvalidFeedback: function() {
      if (this.endValidityDateStateDisplay === null) return "";
      if (!this.areDatesOverlappingProp) {
        return this.$t("validationRules.endDateBeforeStartDate");
      } else {
        return this.$t("pathologistReplacement.validations.overlappingDates");
      }
    },
    isValid: function() {
      return (
        this.replacedPathologistState !== false &&
        this.startValidityDateState !== false &&
        this.endValidityDateState !== false
      ) ? null : false;
    }
  },
  watch: {
    pathologistReplacementProp: {
      handler: function(newVal) {
        const value = JSON.parse(JSON.stringify(newVal));
        this.id = value.id || null;
        this.tempId = value.tempId || "";
        // update only if different or empty
        if (value.replacedPathologistId !== this.replacedPathologistId) {
          this.replacedPathologistId = value.replacedPathologistId;
          this.replacedPathologist = value.replacedPathologist;
        }
        if (value.startValidityDate !== this.startValidityDate) this.startValidityDate = value.startValidityDate;
        if (value.endValidityDate !== this.endValidityDate) this.endValidityDate = value.endValidityDate;
      },
      deep: true,
      immediate: true
    },
    // activate validation by prop
    validationActiveProp: function(value, oldValue) {
      if (value !== oldValue) {
        this.replacedPathologistValidation = value;
        this.startValidityDateValidation = value;
        this.endValidityDateValidation = value;
        this.sendIsValid();
      }
    },
    areDatesOverlappingProp: function(value, oldValue) {
      if (value !== oldValue) {
        this.sendIsValid();
      }
    }
  },
  methods: {
    // format pathologist name
    suggestionFormatPathologists(suggestion) {
      if (!suggestion) return "";
      let output = suggestion.user.firstName + " " + suggestion.user.lastName + " (" + suggestion.code + ")";
      if (suggestion.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },

    // validation
    sendIsValid() {
      this.$emit("updateIsValid", this.isValid);
    },
    // on update / finished
    sendToparent() {
      this.$emit("onUpdateParent", {
        id: this.id,
        tempId: this.tempId,
        areDatesOverlapping: this.areDatesOverlappingProp,
        replacedPathologistId: this.replacedPathologistId,
        replacedPathologist: this.replacedPathologist,
        startValidityDate: this.startValidityDate,
        endValidityDate: this.endValidityDate
      });
      this.sendIsValid();
    }
  }
};
</script>

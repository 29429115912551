<template>
  <div class="kt-task-cytology-content">
    <!-- content form -->
    <b-row>
      <!-- divider -->
      <b-col cols="12">
        <div class="w-100 border-top mt-1 mb-3"></div>
        <h5 class="kt-section-title">
          {{ $t("tasks.contentTitle") }}
        </h5>
      </b-col>

      <!-- screening -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('tasks.cytology.screening')"
          :invalid-feedback="screeningInvalidFeedback"
          :state="screeningStateDisplay"
        >
          <b-form-select
            v-model="screening"
            :options="screeningOptions"
            value-field="value"
            text-field="text"
            :state="screeningStateDisplay"
            @input="onInput('screening')"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <!-- smear -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('tasks.cytology.smear')"
          :invalid-feedback="smearInvalidFeedback"
          :state="smearStateDisplay"
        >
          <b-form-select
            v-model="smear"
            :options="smearOptions"
            value-field="value"
            text-field="text"
            :state="smearStateDisplay"
            @input="onInput('smear')"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <!-- layering -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('tasks.cytology.layering')"
          :invalid-feedback="layeringInvalidFeedback"
          :state="layeringStateDisplay"
        >
          <b-form-select
            v-model="layering"
            :options="layeringOptions"
            value-field="value"
            text-field="text"
            :state="layeringStateDisplay"
            @input="onInput('layering')"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <!-- isCytology -->
      <b-col cols="12">
        <b-form-group
          :label="$t('tasks.cytology.checkboxTitle')"
          :state="isCytologyStateDisplay"
          class="mb-0"
        >
          <b-form-checkbox
            v-model="isCytology"
            :state="isCytologyStateDisplay"
            @input="onInput('isCytology')"
          >
            {{ $t('tasks.cytology.isCytology') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <!-- isHpv -->
      <b-col cols="12">
        <b-form-group
          :invalid-feedback="isHpvInvalidFeedback"
          :state="isHpvStateDisplay"
        >
          <b-form-checkbox
            v-model="isHpv"
            :state="isHpvStateDisplay"
            @input="onInput('isHpv')"
          >
            {{ $t('tasks.cytology.isHpv') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <!-- multiplicity -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('tasks.cytology.multiplicity')"
          :invalid-feedback="multiplicityInvalidFeedback"
          :state="multiplicityStateDisplay"
        >
          <b-form-input
            v-model="multiplicity"
            trim
            maxlength="10"
            :state="multiplicityStateDisplay"
            @blur="multiplicityValidation = true"
            @input="onSendContent"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// helpers
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    contentProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    contentValidationProp: {
      type: Boolean
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // form variables
      screening: "",
      smear: "",
      layering: "",
      isCytology: true,
      isHpv: true,
      multiplicity: 1,
      // is validation active
      screeningValidation: false,
      smearValidation: false,
      layeringValidation: false,
      isCytologyValidation: false,
      isHpvValidation: false,
      multiplicityValidation: false,
      // options
      screeningOptions: [
        //{ value: "empty", text: this.$t("cytologyReport.screening.empty") },
        { value: "control", text: this.$t("cytologyReport.screening.control") },
        { value: "individual", text: this.$t("cytologyReport.screening.individual") },
        { value: "organised", text: this.$t("cytologyReport.screening.organised") }
      ],
      smearOptions: [
        //{ value: "empty", text: this.$t("cytologyReport.smear.empty") },
        { value: "fcu", text: this.$t("cytologyReport.smear.fcu") },
        { value: "fv", text: this.$t("cytologyReport.smear.fv") },
        { value: "fem", text: this.$t("cytologyReport.smear.fem") },
        { value: "fvu", text: this.$t("cytologyReport.smear.fvu") },
        { value: "fan", text: this.$t("cytologyReport.smear.fan") }
      ],
      layeringOptions: [
        //{ value: "empty", text: this.$t("cytologyReport.layering.empty") },
        { value: "monolayer", text: this.$t("cytologyReport.layering.monolayer") },
        { value: "conventional", text: this.$t("cytologyReport.layering.conventional") }
      ]
    };
  },
  computed: {
    // form validation
    screeningState: function() {
      return this.screening && this.screening.length > 0 ? null : false;
    },
    screeningStateDisplay: function() {
      if (!this.screeningValidation) return null;
      return this.screeningState;
    },
    screeningInvalidFeedback: function() {
      return this.screeningStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    smearState: function() {
      return this.smear && this.smear.length > 0 ? null : false;
    },
    smearStateDisplay: function() {
      if (!this.smearValidation) return null;
      return this.smearState;
    },
    smearInvalidFeedback: function() {
      return this.smearStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    layeringState: function() {
      return this.layering && this.layering.length > 0 ? null : false;
    },
    layeringStateDisplay: function() {
      if (!this.layeringValidation) return null;
      return this.layeringState;
    },
    layeringInvalidFeedback: function() {
      return this.layeringStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    isCytologyState: function() {
      return typeof this.isCytology === "boolean" && (this.isCytology || this.isHpv) ? null : false;
    },
    isCytologyStateDisplay: function() {
      if (!this.isCytologyValidation) return null;
      return this.isCytologyState;
    },
    isCytologyInvalidFeedback: function() {
      return this.isCytologyStateDisplay === false ? this.$t("tasks.cytology.validations.isCytologyisHpvRequired") : "";
    },
    isHpvState: function() {
      return typeof this.isHpv === "boolean" && (this.isCytology || this.isHpv) ? null : false;
    },
    isHpvStateDisplay: function() {
      if (!this.isHpvValidation) return null;
      return this.isHpvState;
    },
    isHpvInvalidFeedback: function() {
      return this.isHpvStateDisplay === false ? this.$t("tasks.cytology.validations.isCytologyisHpvRequired") : "";
    },
    multiplicityState: function() {
      return this.multiplicity && /^[1-9]\d*$/.test(this.multiplicity) ? null : false;
    },
    multiplicityStateDisplay: function() {
      if (!this.multiplicityValidation) return null;
      return this.multiplicityState;
    },
    multiplicityInvalidFeedback: function() {
      if (this.multiplicityStateDisplay === null) return "";
      return !this.multiplicity ? this.$t("validationRules.required") : this.$t("tasks.cytology.validations.invalidMultiplicity");
    },
    // formatted variables
    contentFormatted: function() {
      return {
        screening: this.screening,
        smear: this.smear,
        layering: this.layering,
        isCytology: this.isCytology,
        isHpv: this.isHpv,
        multiplicity: this.multiplicity
      };
    },
    isValid: function() {
      return this.screeningState !== false &&
        this.smearState !== false &&
        this.layeringState !== false &&
        this.isCytologyState !== false &&
        this.isHpvState !== false &&
        this.multiplicityState !== false;
    }
  },
  watch: {
    contentProp: {
      handler(val) {
        if (val !== null) {
          const tempValue = JSON.parse(JSON.stringify(val));
          this.screening = tempValue.screening !== undefined ? tempValue.screening : "";
          this.smear = tempValue.smear !== undefined ? tempValue.smear : "";
          this.layering = tempValue.layering !== undefined ? tempValue.layering : "";
          this.isCytology = tempValue.isCytology !== undefined ? tempValue.isCytology : true;
          this.isHpv = tempValue.isHpv !== undefined ? tempValue.isHpv : true;
          this.multiplicity = tempValue.multiplicity !== undefined ? tempValue.multiplicity : 1;
        } else {
          this.screening = "";
          this.smear = "";
          this.layering = "";
          this.isCytology = true;
          this.isHpv = true;
          this.multiplicity = 1;
        }
        this.sendIsValid();
      },
      deep: true,
      immediate: true
    },
    contentValidationProp: {
      handler(val) {
        if (val) {
          this.activateValidation();
          this.sendIsValid();
        }
      }
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    onInput(name) {
      this[name + "Validation"] = true;
      this.onSendContent();
    },
    // submit
    activateValidation() {
      this.screeningValidation = true;
      this.smearValidation = true;
      this.layeringValidation = true;
      this.isCytologyValidation = true;
      this.isHpvValidation = true;
      this.multiplicityValidation = true;
    },
    onSendContent() {
      this.$emit("updateContent", this.contentFormatted);
      this.sendIsValid();
    },
    sendIsValid() {
      this.$emit("updateIsValid", this.isValid);
    }
  }
};
</script>

<template>
  <div class="kt-contact-organisations">
    <!-- title -->
    <h3
      v-show="!hideLabelProp"
      class="h6"
    >
      {{ $t("contacts.organisations") }}
    </h3>

    <!-- contactOrganisations list -->
    <div class="kt-item-list">
      <div
        v-for="(contactOrganisationItem, index) in contactOrganisations"
        v-show="contactOrganisationItem.status !== 'deleted'"
        :key="contactOrganisationItem.tempId"
        class="kt-item-list__item-wrapper"
      >
        <div class="kt-item-list__item">
          <!-- actions -->
          <div
            v-show="contactOrganisationItem.isActive !== false && contactOrganisationItem.organisation.isActive !== false"
            class="kt-item-list__item-actions"
          >
            <!-- delete button -->
            <b-button
              class="btn-icon kt-item-list__item-delete"
              size="sm"
              variant="light"
              squared
              @click="onDeleteContactOrganisation(contactOrganisationItem.tempId)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>

          <!-- title -->
          <div
            class="kt-item-list__item-title"
            :class="{'kt-item-list__item-title--padding-large': contactOrganisationItem.isActive === true && contactOrganisationItem.organisation.isActive === true}"
          >
            {{ contactOrganisationItem.organisation.name }}
          </div>
          <!-- isActive -->
          <div
            v-show="contactOrganisationItem.isActive === false || contactOrganisationItem.organisation.isActive === false"
            class="kt-badge kt-badge--red"
          >
            <b-icon
              icon="trash"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
          </div>
          <!-- text -->
          <div class="kt-item-list__item-text">
            {{ contactOrganisationItem.organisation.code }}
          </div>

          <!-- organisationContactInformation -->
          <b-form-group
            v-show="contactOrganisationItem.isActive === true && contactOrganisationItem.organisation.isActive === true"
            class="mb-2 mt-3"
            :label="$t('contacts.organisationContactInformation')"
          >
            <b-form-select
              v-model="contactOrganisations[index].contactInformationId"
              :options="contactOrganisationItem.organisation.organisationContactInformations"
              value-field="contactInformationId"
              text-field="contactInformation.name"
              @change="editOrganisationContactInformation(contactOrganisations[index])"
            />
          </b-form-group>
          <!-- read only ContactInformation -->
          <div
            v-show="contactOrganisationItem.isActive === false || contactOrganisationItem.organisation.isActive === false"
            class="kt-item-list__item-text mb-2 mt-3"
            v-html="formatAddressSub(contactOrganisationItem.contactInformation || null)"
          ></div>
          <!-- deleted ContactInformation -->
          <div
            v-show="contactOrganisationItem.contactInformation.isActive === false"
            class="kt-item-list__item-text text-danger mb-2 mt-1"
          >
            {{ $t("contacts.notifications.deletedOrganisationContactInformation") }}
          </div>

          <!-- labels -->
          <div
            v-show="contactOrganisationItem.isActive !== false && contactOrganisationItem.organisation.isActive !== false"
            class="kt-button-switchers"
          >
            <!-- is Default -->
            <b-button
              class="kt-button-switcher"
              :class="{'kt-button-switcher--active': contactOrganisationItem.isDefault === true}"
              size="sm"
              variant="light"
              @click="editContactOrganisationProperty(contactOrganisationItem.tempId, !contactOrganisationItem.isDefault)"
            >
              <b-icon
                icon="check"
                class="kt-button-switcher__check"
              ></b-icon>
              <b-icon
                icon="plus"
                class="kt-button-switcher__plus"
              ></b-icon>
              {{ $t("contactInformations.isDefault") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- add a contactOrganisation -->
    <b-button
      ref="addOrganisationButton"
      v-b-modal="'chooseContactOrganisationModal'"
      size="sm"
      :variant="'outline-' + $systemSettings.theme"
      pill
    >
      <b-icon icon="plus"></b-icon>
      {{ $t("contactEdit.addOrganisation") }}
    </b-button>

    <!-- choose Organisation Modal -->
    <b-modal
      id="chooseContactOrganisationModal"
      hide-footer
      :title="$t('organisationChoose.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.organisationChooseComponent.focusFirstElement()
      }"
    >
      <OrganisationChoose
        ref="organisationChooseComponent"
        :optionsProp="{
          filterIsPrescribing: true
        }"
        @chosen="onContactOrganisationChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import OrganisationChoose from "@/views/Data/Organisations/OrganisationChoose";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    OrganisationChoose
  },
  mixins: [userRights, error, date, phoneNumber, address],
  props: {
    contactOrganisationsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    hideLabelProp: {
      type: Boolean
    },
    // true when this component is not editable
    viewModeProp: {
      type: Boolean
    },
    // true when editing an existing file, false when creating a new file
    fileEditModeProp: {
      type: [Boolean, null],
      default: null
    }
  },
  data() {
    return {
      // base list
      contactOrganisations: []
    };
  },
  watch: {
    contactOrganisationsProp: {
      handler(val) {
        this.contactOrganisations = [];
        if (val && val.length > 0) {
          this.contactOrganisations = JSON.parse(JSON.stringify(val));
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    // organisation
    async onContactOrganisationChosen(targetOrganisation) {
      try {
        const res = await commonServices.get("organisations", targetOrganisation.id);
        const organisation = res.data.id ? res.data : null;

        if (organisation) {
          // search if the organisation is already added
          const position = this.contactOrganisations.reduce((pos, value, index) => {
            if (pos !== null) {
              return pos;
            } else {
              return value.organisationId === organisation.id ? index : null;
            }
          }, null);

          const defaultContactInformation = this.getOrganisationDefaultContactInformation(organisation);
          if (position !== null) {
            // if the organisation is known : update the object
            if (this.contactOrganisations[position].status === "deleted") {
              this.contactOrganisations[position].status = "edited";
              this.contactOrganisations[position].contactInformationId = defaultContactInformation.id || null;
              this.contactOrganisations[position].contactInformation = defaultContactInformation;
            }
            this.contactOrganisations[position].organisation = organisation;
          } else {
            // if the organisation is new : add the object
            this.contactOrganisations.push({
              isActive: true,
              id: null,
              tempId: "new-" + Number(uniqueId()),
              organisationId: organisation.id,
              organisation: organisation,
              contactInformationId: defaultContactInformation.id || null,
              contactInformation: defaultContactInformation,
              isDefault: false,
              status: "created"
            });
          }

          this.sendToparent();

          // auto-focus
          this.$refs.addOrganisationButton.focus();

          // close the modals
          this.$bvModal.hide("chooseContactOrganisationModal");
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    editOrganisationContactInformation(contactOrganisationItem) {
      if (contactOrganisationItem.status === null) {
        contactOrganisationItem.status = "edited";
      }

      const contactInformationId = contactOrganisationItem.contactInformationId;
      for (const organisationContactInformation of contactOrganisationItem.organisation.organisationContactInformations) {
        if (organisationContactInformation.contactInformationId === contactInformationId) {
          contactOrganisationItem.contactInformation = organisationContactInformation.contactInformation;
          break;
        }
      }
      this.sendToparent();
    },
    getOrganisationDefaultContactInformation(organisation) {
      let defaultContactInformation = null;
      if (organisation && organisation.organisationContactInformations) {
        for (const organisationContactInformation of organisation.organisationContactInformations) {
          if (organisationContactInformation.isDefault) {
            defaultContactInformation = organisationContactInformation.contactInformation;
            break;
          }
        }
      }
      return defaultContactInformation;
    },
    onDeleteContactOrganisation(tempId) {
      // get the position
      const position = this.getContactOrganisationPosition(tempId);
      if (position !== null) {
        // delete
        if (this.contactOrganisations[position].status === "created") {
          this.contactOrganisations.splice(position, 1);
        } else {
          this.contactOrganisations[position].status = "deleted";
          this.contactOrganisations[position].isDefault = false;
        }
        // send to parent
        this.sendToparent();

        // auto-focus
        this.$refs.addOrganisationButton.focus();
      }
    },
    editContactOrganisationProperty(tempId, value) {
      this.$emit("isDefaultEdited", { target: "contactOrganisations", tempId: tempId, value: value });
    },

    // helpers
    getContactOrganisationPosition(tempId) {
      for (var i in this.contactOrganisations) {
        if (this.contactOrganisations[i].tempId === tempId) return i;
      }
      return null;
    },
    formatAddressSub(obj) {
      if (!obj) return "";

      let addressLine = this.formatAddress(obj.address1, obj.address2, obj.zip, obj.city, obj.country);
      if (obj.phone) addressLine += (addressLine !== "" ? "<br>" : "") + this.importFormatPhone(obj.phone);
      if (obj.mobile) addressLine += (!obj.phone && addressLine !== "" ? "<br>" : "") + (obj.phone ? " - " : "") + this.importFormatPhone(obj.mobile);
      if (obj.email) addressLine += (addressLine !== "" ? "<br>" : "") + obj.email;

      return addressLine;
    },
    // on update / finished
    sendToparent() {
      this.$emit("contactOrganisationsUpdated", this.contactOrganisations);
    }
  }
};
</script>

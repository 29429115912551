<template>
  <div
    class="kt-view-page kt-patient-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("patientView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'office', 'patients', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('office/patient/edit', {patientIdProp: patientId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("patientView.editPatient") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION name info -->
      <div
        v-if="nameDisplay || birthdateDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- fullName -->
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.fullName") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              <PersonName
                :sexNameProp="sexName"
                :firstNameProp="firstName"
                :lastNameProp="lastName"
                :maidenNameProp="maidenName"
              />
            </p>
          </b-col>
        </b-row>
        <!-- birthdate -->
        <b-row v-if="birthdateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.birthdateFull") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ getFormattedBirthdateAge(birthdate) }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION ID's -->
      <div
        v-if="regNumberDisplay || insDisplay || invoicedOrganisationDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("patientView.identifiers") }}
        </h3>
        <!-- regNumber -->
        <b-row v-if="regNumberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.regNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ regNumberFormatter(regNumber) }}
              <b-button
                :id="'copyRegNumberBtn' + _uid"
                size="sm"
                class="btn-icon align-baseline"
                style="margin: -6px 0 0 1px;"
                variant="light"
                @click="toClipboard('regNumber')"
              >
                <b-icon icon="clipboard"></b-icon>
              </b-button>
              <b-tooltip
                :show.sync="showRegNumberTooltip"
                :target="'copyRegNumberBtn' + _uid"
                placement="bottom"
                triggers=""
              >
                {{ isCopiedRegNumber ? $t("general.copied") : $t("general.notCopied") }}
              </b-tooltip>
            </p>
          </b-col>
        </b-row>
        <!-- ins -->
        <b-row v-if="insDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.ins") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ ins }}
            </p>
          </b-col>
        </b-row>
        <!-- invoicedOrganisation -->
        <b-row
          v-if="invoicedOrganisationDisplay"
          v-show="invoicedOrganisation !== null"
        >
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.invoicedOrganisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p
              class="mb-0"
              :class="invoicedOrganisationColor"
            >
              <!-- organisation name -->
              {{ invoicedOrganisation ? invoicedOrganisation.name + (invoicedOrganisation.isActive === true ? "" : " (" + this.$t("general.deletedLabel") + ")") : $t("general.emptyWithHyphen") }}
              <!-- coverage type -->
              <span v-show="insuranceType"><br />{{ $t("general.emptyWithHyphen") }} {{ insuranceType ? $t("insuranceTypes." + insuranceType.name) : '' }}</span>
              <!-- validity date -->
              <span v-show="insuranceEndDate"><br />{{ $t("general.emptyWithHyphen") }} {{ insuranceEndDateIsValid ? $t("patients.insuranceEndDateIsValid") : $t("patients.insuranceEndDateNotValid") }} {{ insuranceEndDate ? $d(new Date(insuranceEndDate), "date") : '' }}</span>
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION insuree -->
      <div
        v-if="(insureeFirstName || insureeLastName || insureeMaidenName || insureeRegNumber) && (nameInsureeDisplay || insureeRegNumberDisplay)"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("patients.insureeSectionTitle") }}
        </h3>
        <!-- fullNameInsuree -->
        <b-row v-if="nameInsureeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.fullName") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              <PersonName
                :firstNameProp="insureeFirstName"
                :lastNameProp="insureeLastName"
                :maidenNameProp="insureeMaidenName"
              />
            </p>
          </b-col>
        </b-row>
        <!-- insureeRegNumber -->
        <b-row v-if="insureeRegNumberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.regNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ regNumberFormatter(insureeRegNumber) }}
              <b-button
                :id="'copyInsureeRegNumberBtn' + _uid"
                size="sm"
                class="btn-icon align-baseline"
                style="margin: -6px 0 0 1px;"
                variant="light"
                @click="toClipboard('insureeRegNumber')"
              >
                <b-icon icon="clipboard"></b-icon>
              </b-button>
              <b-tooltip
                :show.sync="showInsureeRegNumberTooltip"
                :target="'copyInsureeRegNumberBtn' + _uid"
                placement="bottom"
                triggers=""
              >
                {{ isCopiedInsureeRegNumber ? $t("general.copied") : $t("general.notCopied") }}
              </b-tooltip>
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION contactInformation -->
      <div
        v-if="addressDisplay || phoneDisplay || emailDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("patientView.contactInformation") }}
        </h3>
        <!-- address -->
        <b-row v-if="addressDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.addressFull") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ formatAddress(address1, address2, zip, city, country) }}
            </p>
          </b-col>
        </b-row>
        <!-- phone -->
        <b-row v-if="phoneDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.phone") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ phone }}
            </p>
          </b-col>
        </b-row>
        <!-- email -->
        <b-row v-if="emailDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("patients.email") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ email }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import regNumber from "@shared/services/UI/regNumber";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import date from "@shared/services/UI/date";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  components: { PersonName },
  mixins: [userRights, error, regNumber, phoneNumber, address, viewComponents, date],
  props: {
    patientIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "name",
          "birthdate",

          "regNumber",
          "ins",
          "invoicedOrganisation",

          "nameInsuree",
          "insureeRegNumber",

          "address",
          "phone",
          "email"
        ];
      }
    },
    referenceCreationDateProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general
      patientId: this.patientIdProp,
      patientData: null,
      showRegNumberTooltip: false,
      isCopiedRegNumber: false,
      showInsureeRegNumberTooltip: false,
      isCopiedInsureeRegNumber: false,
      // base data
      sexName: "",
      firstName: "",
      lastName: "",
      maidenName: "",
      birthdate: "",
      regNumber: "",
      ins: "",
      invoicedOrganisation: null,
      insuranceType: null,
      insuranceEndDate: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      country: "",
      phone: "",
      email: "",
      insureeFirstName: "",
      insureeLastName: "",
      insureeMaidenName: "",
      insureeRegNumber: "",
      // dispayFields
      nameDisplay: false,
      birthdateDisplay: false,
      regNumberDisplay: false,
      insDisplay: false,
      invoicedOrganisationDisplay: false,
      addressDisplay: false,
      phoneDisplay: false,
      emailDisplay: false,
      nameInsureeDisplay: false,
      insureeRegNumberDisplay: false
    };
  },
  computed: {
    insuranceEndDateIsValid: function() {
      if (!this.invoicedOrganisation) {
        return true;
      }
      // no date
      if (!this.insuranceEndDate) {
        if (!this.insuranceType || this.insuranceType.name === "healthCare") {
          return null;
        } else {
          return false;
        }
      }
      const dateForInsuranceValidy = this.referenceCreationDateProp || this.getIsoDate();
      const isValid = this.validateDateBeforeDate(dateForInsuranceValidy, this.insuranceEndDate);
      // date + healthCare
      if (this.insuranceType.name === "healthCare") {
        return isValid ? null : false;
      }
      // date + others
      return isValid;
    },
    invoicedOrganisationColor: function() {
      if (this.insuranceEndDateIsValid === true) {
        return "text-success";
      } else if (this.insuranceEndDateIsValid === false) {
        return "text-danger";
      } else {
        return "";
      }
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get patient
      const resServices = await commonServices.get("patients", this.patientId);
      this.patientData = resServices.data;
      // assign the data
      this.sexName = resServices.data.sex.name || "";
      this.firstName = resServices.data.firstName;
      this.lastName = resServices.data.lastName;
      this.maidenName = resServices.data.maidenName;
      this.birthdate = resServices.data.birthdate;
      this.regNumber = resServices.data.regNumber;
      this.ins = resServices.data.ins;
      this.invoicedOrganisation = resServices.data.invoicedOrganisation;
      this.insuranceType = resServices.data.insuranceType;
      this.insuranceEndDate = resServices.data.insuranceEndDate;
      this.address1 = resServices.data.address1;
      this.address2 = resServices.data.address2;
      this.zip = resServices.data.zip;
      this.city = resServices.data.city;
      this.country = resServices.data.country;
      this.phone = this.importFormatPhone(resServices.data.phone);
      this.email = resServices.data.email;

      this.insureeFirstName = resServices.data.insureeFirstName;
      this.insureeLastName = resServices.data.insureeLastName;
      this.insureeMaidenName = resServices.data.insureeMaidenName;
      this.insureeRegNumber = resServices.data.insureeRegNumber;

      // return data to parent
      this.$emit("onPatientLoaded", this.patientData);

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("office/patients");
    },
    async toClipboard(target) {
      try {
        await navigator.clipboard.writeText(this[target]);
        this.showTooltip(target, true);
      } catch ($e) {
        this.showTooltip(target, false);
      }
    },
    showTooltip(target, isSuccess) {
      if (target === "insureeRegNumber") {
        this.isCopiedInsureeRegNumber = isSuccess;
        this.showInsureeRegNumberTooltip = true;
      } else {
        this.isCopiedRegNumber = isSuccess;
        this.showRegNumberTooltip = true;
      }
      setTimeout(() => {
        if (target === "insureeRegNumber") {
          this.showInsureeRegNumberTooltip = false;
        } else {
          this.showRegNumberTooltip = false;
        }
      }, 3000);
    }
  }
};
</script>

<template>
  <div class="kt-contact-account-edit">
    <!-- contact form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- isLocked -->
        <b-col
          v-show="contactEditModeProp && editMode"
          cols="12"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="isLocked"
              switch
              class="mr-n2"
            >
              {{ $t('contactAccounts.isLocked') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="12">
          <b-form-group
            class="kt-form-group--mb-sm"
            :label="$t('contactAccounts.email')"
            :invalid-feedback="emailInvalidFeedback"
            :state="emailState"
          >
            <b-form-input
              ref="emailInput"
              v-model="email"
              type="email"
              trim
              :state="emailState"
              @blur="emailValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- phone -->
        <b-col cols="12">
          <b-form-group
            class="kt-form-group--mb-sm"
            :label="$t('contactAccounts.phone')"
            :invalid-feedback="phoneInvalidFeedback"
            :state="phoneState"
          >
            <b-form-input
              v-model="phone"
              class="kt-input--prefixed"
              trim
              :state="phoneState"
              @blur="phoneValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t('general.phoneCountryCodeDefault') }}
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";

export default {
  components: {},
  mixins: [userRights, error, phoneNumber],
  props: {
    contactEditModeProp: {
      type: Boolean
    },
    editModeProp: {
      type: Boolean
    },
    contactIdProp: {
      type: Number,
      default: null
    },
    contactDataProp: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      contactId: this.contactIdProp,
      // form variables
      isActive: true,
      contactContactInformations: [],
      contactOrganisations: [],
      // contactUser form variables
      isLocked: false,
      email: "",
      phone: "",
      // is validation active
      emailValidation: false,
      phoneValidation: false
    };
  },
  computed: {
    // contactUser form validation
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    phoneState: function() {
      // validation active
      if (!this.phoneValidation) return null;
      // not required
      if (this.phone === "") return null;

      return this.phone && this.phone.length > 0 && /^0?[67]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // if empty
      if (!this.phone) return this.$t("validationRules.required");
      // else : passwords not equals
      return this.$t("validationRules.invalidMobilePhone");
    },

    // formatted variables
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("contactAccountEdit.submitText") : this.$t("contactAccountNew.submitText");
    },
    contactData: function() {
      return this.contactDataProp;
    }
  },
  async mounted() {
    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.emailInput) {
        this.$refs.emailInput.focus();
      }
    },

    // load/refresh data
    async importData() {
      try {
        let contactImportData = null;
        if (this.contactDataProp) {
          contactImportData = this.contactDataProp;
        } else {
          const resServices = await commonServices.get("contacts", this.contactId);
          contactImportData = resServices.data;
        }
        this.updateContactData(contactImportData);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateContactData(contactData) {
      // import data
      this.isActive = contactData.isActive;
      // contactInformations
      this.contactContactInformations = contactData.contactContactInformations;
      this.contactContactInformations = contactData.contactContactInformations;
      this.contactOrganisations = contactData.contactOrganisations;
      // import account data
      this.isLocked = contactData.isLocked;
      this.email = contactData.email;
      this.phone = this.importFormatPhone(contactData.phone);
    },

    // helpers
    getDefaultEmail() {
      if (this.contactContactInformations.length) {
        for (const contactContactInformation of this.contactContactInformations) {
          if (contactContactInformation.isDefault) {
            if (contactContactInformation.contactInformation && contactContactInformation.contactInformation.email) {
              return contactContactInformation.contactInformation.email;
            } else {
              return "";
            }
          }
        }
      }
      if (this.contactOrganisations.length) {
        for (const contactOrganisation of this.contactOrganisations) {
          if (contactOrganisation.isDefault) {
            if (contactOrganisation.contactInformation && contactOrganisation.contactInformation.email) {
              return contactOrganisation.contactInformation.email;
            } else {
              return "";
            }
          }
        }
      }
      return null;
    },

    // submit
    onSubmit() {
      if (this.onValidateForm() !== false) this.onSaveForm();
    },
    onValidateForm() {
      this.emailValidation = true;
      this.phoneValidation = true;

      return !(
        this.emailState === false ||
        this.phoneState === false
      );
    },
    async onSaveForm() {
      try {
        const form = {
          isLocked: this.isLocked,
          email: this.email,
          phone: this.exportFormatPhone(this.phone)
        };

        if (this.contactEditModeProp) {
          this.$emit("contactAccountEdited", form);
        } else {
          form.isLocked = false;
          this.$emit("contactAccountEdited", form);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave(form) {
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("contactAccountEdit.notifications.editedTitle"),
          message: this.$t("contactAccountEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("contactAccountNew.notifications.addedTitle"),
          message: this.$t("contactAccountNew.notifications.addedText")
        });
      }
      this.$emit("contactAccountEdited", form);
    }
  }
};
</script>

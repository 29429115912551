import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@shared/views/General/Home.vue";
import NotFound from "@shared/views/General/NotFound.vue";

import SystemSettings from "@/views/System/SystemSettings/SystemSettings.vue";
import SystemSettingsTheme from "@/views/System/SystemSettings/SystemSettingsTheme.vue";
import SystemSettingsLocalization from "@/views/System/SystemSettings/SystemSettingsLocalization.vue";
import SystemSettingsSessions from "@/views/System/SystemSettings/SystemSettingsSessions.vue";
import SystemSettingsSectors from "@/views/System/SystemSettings/SystemSettingsSectors.vue";
import SystemSettingsDirectories from "@/views/System/SystemSettings/SystemSettingsDirectories.vue";
import SystemSettingsLogging from "@/views/System/SystemSettings/SystemSettingsLogging.vue";
import SystemSettingsAutomaticActions from "@/views/System/SystemSettings/SystemSettingsAutomaticActions.vue";
import SystemSettingsSmtpServer from "@/views/System/SystemSettings/SystemSettingsSmtpServer.vue";
import SystemSettingsAutomaticActionView from "@/views/System/SystemSettings/SystemSettingsAutomaticActionView.vue";
import SystemSettingsAutomaticActionEdit from "@/views/System/SystemSettings/SystemSettingsAutomaticActionEdit.vue";
import AdditionalFeeGroups from "@/views/System/SystemSettings/AdditionalFeeGroups.vue";
import SystemStatistics from "@/views/System/Statistics.vue";

import Users from "@/views/Administration/Users/Users.vue";
import Pathologists from "@/views/Administration/Pathologists/Pathologists.vue";
import PathologistView from "@/views/Administration/Pathologists/PathologistView.vue";
import PathologistEdit from "@/views/Administration/Pathologists/PathologistEdit.vue";
import Laboratories from "@/views/Administration/Laboratories/Laboratories.vue";
import LaboratoryView from "@/views/Administration/Laboratories/LaboratoryView.vue";
import LaboratoryEdit from "@/views/Administration/Laboratories/LaboratoryEdit.vue";

import Organisations from "@/views/Data/Organisations/Organisations.vue";
import OrganisationView from "@/views/Data/Organisations/OrganisationView.vue";
import OrganisationEdit from "@/views/Data/Organisations/OrganisationEdit.vue";
import Samples from "@/views/Data/Samples/Samples.vue";
import SampleView from "@/views/Data/Samples/SampleView.vue";
import SampleEdit from "@/views/Data/Samples/SampleEdit.vue";
import ContainerTypes from "@/views/Data/ContainerTypes/ContainerTypes.vue";
import ContainerTypeView from "@/views/Data/ContainerTypes/ContainerTypeView.vue";
import ContainerTypeEdit from "@/views/Data/ContainerTypes/ContainerTypeEdit.vue";
import Tasks from "@/views/Data/Tasks/Tasks.vue";
import TaskView from "@/views/Data/Tasks/TaskView.vue";
import TaskEdit from "@/views/Data/Tasks/TaskEdit.vue";
import Acts from "@/views/Data/Acts/Acts.vue";
import ActView from "@/views/Data/Acts/ActView.vue";
import ActEdit from "@/views/Data/Acts/ActEdit.vue";
import Instruments from "@/views/Data/Instruments/Instruments.vue";
import InstrumentView from "@/views/Data/Instruments/InstrumentView.vue";
import InstrumentEdit from "@/views/Data/Instruments/InstrumentEdit.vue";
import Banks from "@/views/Data/Banks/Banks.vue";
import BankView from "@/views/Data/Banks/BankView.vue";
import BankEdit from "@/views/Data/Banks/BankEdit.vue";

import Statistics from "@/views/Reports/Statistics/Statistics.vue";
import LegalLog from "@/views/Reports/LegalLog/LegalLog.vue";
import ContactReports from "@/views/Reports/ContactReports/ContactReports.vue";
import PathologistsReports from "@/views/Reports/PathologistsReports/PathologistsReports.vue";
import CytotechniciansReports from "@/views/Reports/CytotechniciansReports/CytotechniciansReports.vue";
import ResultsReports from "@/views/Reports/ResultsReports/ResultsReports.vue";
import FilingReports from "@/views/Reports/FilingReports/FilingReports.vue";
import FilesReports from "@/views/Reports/FilesReports/FilesReports.vue";

import Patients from "@shared/views/Office/Patients/Patients.vue";
import PatientView from "@/views/Office/Patients/PatientView.vue";
import PatientEdit from "@/views/Office/Patients/PatientEdit.vue";
import Files from "@/views/Office/Files/Files.vue";
import FileEntry from "@/views/Office/Files/FileEntry.vue";
import FileView from "@/views/Office/Files/FileView.vue";
import FileEdit from "@/views/Office/Files/FileEdit.vue";
import Contacts from "@/views/Office/Contacts/Contacts.vue";
import ContactView from "@/views/Office/Contacts/ContactView.vue";
import ContactEdit from "@/views/Office/Contacts/ContactEdit.vue";

import ReportEntry from "@/views/Diagnosis/ReportEntry.vue";
import CytologyReportEdit from "@/views/Diagnosis/CytologyReports/CytologyReportEdit.vue";
import HistologyReportEdit from "@/views/Diagnosis/HistologyReports/HistologyReportEdit.vue";
import MedicalReports from "@/views/Diagnosis/MedicalReports/MedicalReports.vue";
import CytologyReports from "@/views/Diagnosis/MedicalReports/CytologyReports.vue";
import HistologyReports from "@/views/Diagnosis/MedicalReports/HistologyReports.vue";
import Validation from "@/views/Diagnosis/Validation/Validation.vue";
import CytologyValidation from "@/views/Diagnosis/Validation/CytologyValidation.vue";
import HistologyValidation from "@/views/Diagnosis/Validation/HistologyValidation.vue";
import AwaitingHpvResults from "@/views/Diagnosis/AwaitingHpvResults/AwaitingHpvResults.vue";
import AwaitingHpvResultEdit from "@/views/Diagnosis/AwaitingHpvResults/AwaitingHpvResultEdit.vue";
import AwaitingHpvResultsUpload from "@/views/Diagnosis/AwaitingHpvResults/AwaitingHpvResultsUpload.vue";
import UnrecognizedHpvResults from "@/views/Diagnosis/UnrecognizedHpvResults/UnrecognizedHpvResults.vue";
import HistologyShortCodes from "@/views/Diagnosis/HistologyShortCodes/HistologyShortCodes.vue";
import HistologyShortCodeView from "@/views/Diagnosis/HistologyShortCodes/HistologyShortCodeView.vue";
import HistologyShortCodeEdit from "@/views/Diagnosis/HistologyShortCodes/HistologyShortCodeEdit.vue";
import ContainerGroupShortCodes from "@/views/Diagnosis/ContainerGroupShortCodes/ContainerGroupShortCodes.vue";
import ContainerGroupShortCodeView from "@/views/Diagnosis/ContainerGroupShortCodes/ContainerGroupShortCodeView.vue";
import ContainerGroupShortCodeEdit from "@/views/Diagnosis/ContainerGroupShortCodes/ContainerGroupShortCodeEdit.vue";

import Invoices from "@/views/Accounting/Invoices/Invoices.vue";
import InvoiceReports from "@/views/Accounting/Invoices/InvoiceReports/InvoiceReports.vue";
import InvoiceReportView from "@/views/Accounting/Invoices/InvoiceReports/InvoiceReportView.vue";
import InvoiceReportEdit from "@/views/Accounting/Invoices/InvoiceReports/InvoiceReportEdit.vue";
import FeeNotes from "@/views/Accounting/Invoices/FeeNotes/FeeNotes.vue";
import FeeNoteView from "@/views/Accounting/Invoices/FeeNotes/FeeNoteView.vue";
import FeeNoteEdit from "@/views/Accounting/Invoices/FeeNotes/FeeNoteEdit.vue";
import CareSheets from "@/views/Accounting/Invoices/CareSheets/CareSheets.vue";
import CareSheetView from "@/views/Accounting/Invoices/CareSheets/CareSheetView.vue";
import CareSheetEdit from "@/views/Accounting/Invoices/CareSheets/CareSheetEdit.vue";

import Payments from "@/views/Accounting/Payments/Payments.vue";
import PaymentReports from "@/views/Accounting/Payments/PaymentReports/PaymentReports.vue";
import PaymentReportEdit from "@/views/Accounting/Payments/PaymentReports/PaymentReportEdit.vue";
import PaymentReportPaymentGroupsEdit from "@/views/Accounting/Payments/PaymentReports/PaymentReportPaymentGroupsEdit.vue";
import PaymentReportPaymentLinesEdit from "@/views/Accounting/Payments/PaymentReports/PaymentReportPaymentLinesEdit.vue";
import PatientPayments from "@/views/Accounting/Payments/PatientPayments/PatientPayments.vue";
import HealthCarePayments from "@/views/Accounting/Payments/HealthCarePayments/HealthCarePayments.vue";
import HealthCarePaymentLinesEdit from "@/views/Accounting/Payments/HealthCarePayments/HealthCarePaymentLinesEdit.vue";
import HealthCarePaymentEdit from "@/views/Accounting/Payments/HealthCarePayments/HealthCarePaymentEdit.vue";

import FileInvoiceTransformations from "@/views/Accounting/FileInvoiceTransformations/FileInvoiceTransformations.vue";
import FileInvoiceTransformationEdit from "@/views/Accounting/FileInvoiceTransformations/FileInvoiceTransformationEdit.vue";

import Reports from "@/views/Accounting/Reports/Reports.vue";
import BalanceReport from "@/views/Accounting/Reports/BalanceReport.vue";
import FilesNotInvoiced from "@/views/Accounting/Reports/FilesNotInvoiced.vue";
import Sales from "@/views/Accounting/Reports/Sales.vue";
import FileInvoicesNotPaid from "@/views/Accounting/Reports/FileInvoicesNotPaid.vue";
import AllPaymentsReport from "@/views/Accounting/Reports/AllPaymentsReport.vue";
import IncorrectPayments from "@/views/Accounting/Reports/IncorrectPayments.vue";
import ReceivedPayments from "@/views/Accounting/Reports/ReceivedPayments.vue";
import CompensationsReport from "@/views/Accounting/Reports/CompensationsReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { activeTopMenuName: "" }
  },

  //
  // SYSTEM ROUTES
  //
  // systemSettings
  {
    path: "/system/systemSettings",
    component: SystemSettings,
    meta: { activeTopMenuName: "system" },
    // Children route "tabs"
    children: [
      {
        path: "theme",
        name: "system/systemSettings/theme",
        component: SystemSettingsTheme,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "localization",
        name: "system/systemSettings/localization",
        component: SystemSettingsLocalization,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "sessions",
        name: "system/systemSettings/sessions",
        component: SystemSettingsSessions,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "sectors",
        name: "system/systemSettings/sectors",
        component: SystemSettingsSectors,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "/system/systemSettings/additionalFeeGroups",
        name: "system/systemSettings/additionalFeeGroups",
        component: AdditionalFeeGroups,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "directories",
        name: "system/systemSettings/directories",
        component: SystemSettingsDirectories,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "logging",
        name: "system/systemSettings/logging",
        component: SystemSettingsLogging,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "automaticActions",
        name: "system/systemSettings/automaticActions",
        component: SystemSettingsAutomaticActions,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "smtpServer",
        name: "system/systemSettings/smtpServer",
        component: SystemSettingsSmtpServer,
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "automaticAction/view/:automaticActionIdProp",
        name: "system/systemSettings/automaticAction/view",
        component: SystemSettingsAutomaticActionView,
        props: (route) => ({
          automaticActionIdProp: Number(route.params.automaticActionIdProp) || null
        }),
        meta: { activeTopMenuName: "system" }
      },
      {
        path: "automaticAction/edit/:automaticActionIdProp",
        name: "system/systemSettings/automaticAction/edit",
        component: SystemSettingsAutomaticActionEdit,
        props: (route) => ({
          editModeProp: true,
          automaticActionIdProp: Number(route.params.automaticActionIdProp) || null
        }),
        meta: { activeTopMenuName: "system" }
      }
    ]
  },
  // SystemStatistics
  {
    path: "/system/statistics",
    name: "system/statistics",
    component: SystemStatistics,
    meta: { activeTopMenuName: "system" }
  },

  //
  // ADMINISTRATION ROUTES
  //
  // Users
  {
    path: "/administration/users",
    name: "administration/users",
    component: Users,
    meta: { activeTopMenuName: "administration" }
  },
  // Pathologists
  {
    path: "/administration/pathologists",
    name: "administration/pathologists",
    component: Pathologists,
    meta: { activeTopMenuName: "administration" }
  },
  {
    path: "/administration/pathologist/view/:pathologistIdProp",
    name: "administration/pathologist/view",
    component: PathologistView,
    props: (route) => ({
      pathologistIdProp: Number(route.params.pathologistIdProp) || null
    }),
    meta: { activeTopMenuName: "administration" }
  },
  {
    path: "/administration/pathologist/edit/:pathologistIdProp",
    name: "administration/pathologist/edit",
    component: PathologistEdit,
    props: (route) => ({
      editModeProp: true,
      pathologistIdProp: Number(route.params.pathologistIdProp) || null
    }),
    meta: { activeTopMenuName: "administration" }
  },
  {
    path: "/administration/pathologist/new",
    name: "administration/pathologist/new",
    component: PathologistEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "administration" }
  },
  // Laboratories
  {
    path: "/administration/laboratories",
    name: "administration/laboratories",
    component: Laboratories,
    meta: { activeTopMenuName: "administration" }
  },
  {
    path: "/administration/laboratory/view/:laboratoryIdProp",
    name: "administration/laboratory/view",
    component: LaboratoryView,
    props: (route) => ({
      laboratoryIdProp: Number(route.params.laboratoryIdProp) || null
    }),
    meta: { activeTopMenuName: "administration" }
  },
  {
    path: "/administration/laboratory/edit/:laboratoryIdProp",
    name: "administration/laboratory/edit",
    component: LaboratoryEdit,
    props: (route) => ({
      editModeProp: true,
      laboratoryIdProp: Number(route.params.laboratoryIdProp) || null
    }),
    meta: { activeTopMenuName: "administration" }
  },
  {
    path: "/administration/laboratory/new",
    name: "administration/laboratory/new",
    component: LaboratoryEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "administration" }
  },

  //
  // REPORTS ROUTES
  //
  {
    path: "/reports/statistics",
    name: "reports/statistics",
    component: Statistics,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/legalLog",
    name: "reports/legalLog",
    component: LegalLog,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/contactReports",
    name: "reports/contactReports",
    component: ContactReports,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/pathologistsReports",
    name: "reports/pathologistsReports",
    component: PathologistsReports,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/cytotechniciansReports",
    name: "reports/cytotechniciansReports",
    component: CytotechniciansReports,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/resultsReports",
    name: "reports/resultsReports",
    component: ResultsReports,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/filingReports",
    name: "reports/filingReports",
    component: FilingReports,
    meta: { activeTopMenuName: "reports" }
  },
  {
    path: "/reports/filesReports",
    name: "reports/filesReports",
    component: FilesReports,
    meta: { activeTopMenuName: "reports" }
  },

  //
  // DATA ROUTES
  //
  // Organisations
  {
    path: "/data/organisations",
    name: "data/organisations",
    component: Organisations,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/organisation/view/:organisationIdProp",
    name: "data/organisation/view",
    component: OrganisationView,
    props: (route) => ({
      organisationIdProp: Number(route.params.organisationIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/organisation/edit/:organisationIdProp",
    name: "data/organisation/edit",
    component: OrganisationEdit,
    props: (route) => ({
      editModeProp: true,
      organisationIdProp: Number(route.params.organisationIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/organisation/new",
    name: "data/organisation/new",
    component: OrganisationEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },
  // Samples
  {
    path: "/data/samples",
    name: "data/samples",
    component: Samples,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/sample/view/:sampleIdProp",
    name: "data/sample/view",
    component: SampleView,
    props: (route) => ({
      sampleIdProp: Number(route.params.sampleIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/sample/edit/:sampleIdProp",
    name: "data/sample/edit",
    component: SampleEdit,
    props: (route) => ({
      editModeProp: true,
      sampleIdProp: Number(route.params.sampleIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/sample/new",
    name: "data/sample/new",
    component: SampleEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },
  // ContainerTypes
  {
    path: "/data/containerTypes",
    name: "data/containerTypes",
    component: ContainerTypes,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/containerType/view/:containerTypeIdProp",
    name: "data/containerType/view",
    component: ContainerTypeView,
    props: (route) => ({
      containerTypeIdProp: Number(route.params.containerTypeIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/containerType/edit/:containerTypeIdProp",
    name: "data/containerType/edit",
    component: ContainerTypeEdit,
    props: (route) => ({
      editModeProp: true,
      containerTypeIdProp: Number(route.params.containerTypeIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/containerType/new",
    name: "data/containerType/new",
    component: ContainerTypeEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },
  // Tasks
  {
    path: "/data/tasks",
    name: "data/tasks",
    component: Tasks,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/task/view/:taskIdProp",
    name: "data/task/view",
    component: TaskView,
    props: (route) => ({
      taskIdProp: Number(route.params.taskIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/task/edit/:taskIdProp",
    name: "data/task/edit",
    component: TaskEdit,
    props: (route) => ({
      editModeProp: true,
      taskIdProp: Number(route.params.taskIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/task/new",
    name: "data/task/new",
    component: TaskEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },
  // Acts
  {
    path: "/data/acts",
    name: "data/acts",
    component: Acts,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/act/view/:actIdProp",
    name: "data/act/view",
    component: ActView,
    props: (route) => ({
      actIdProp: Number(route.params.actIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/act/edit/:actIdProp",
    name: "data/act/edit",
    component: ActEdit,
    props: (route) => ({
      editModeProp: true,
      actIdProp: Number(route.params.actIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/act/new",
    name: "data/act/new",
    component: ActEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },
  // Instruments
  {
    path: "/data/instruments",
    name: "data/instruments",
    component: Instruments,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/instrument/view/:instrumentIdProp",
    name: "data/instrument/view",
    component: InstrumentView,
    props: (route) => ({
      instrumentIdProp: Number(route.params.instrumentIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/instrument/edit/:instrumentIdProp",
    name: "data/instrument/edit",
    component: InstrumentEdit,
    props: (route) => ({
      editModeProp: true,
      instrumentIdProp: Number(route.params.instrumentIdProp) || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/instrument/new",
    name: "data/instrument/new",
    component: InstrumentEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },
  // Banks
  {
    path: "/data/banks",
    name: "data/banks",
    component: Banks,
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/bank/view/:bankIdProp",
    name: "data/bank/view",
    component: BankView,
    props: (route) => ({
      bankIdProp: route.params.bankIdProp || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/bank/edit/:bankIdProp",
    name: "data/bank/edit",
    component: BankEdit,
    props: (route) => ({
      editModeProp: true,
      bankIdProp: route.params.bankIdProp || null
    }),
    meta: { activeTopMenuName: "data" }
  },
  {
    path: "/data/bank/new",
    name: "data/bank/new",
    component: BankEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "data" }
  },

  //
  // OFFICE ROUTES
  //
  // Patients
  {
    path: "/office/patients",
    name: "office/patients",
    component: Patients,
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/patient/view/:patientIdProp",
    name: "office/patient/view",
    component: PatientView,
    props: (route) => ({
      patientIdProp: Number(route.params.patientIdProp) || null
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/patient/edit/:patientIdProp",
    name: "office/patient/edit",
    component: PatientEdit,
    props: (route) => ({
      editModeProp: true,
      patientIdProp: Number(route.params.patientIdProp) || null
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/patient/new",
    name: "office/patient/new",
    component: PatientEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "office" }
  },
  // Files
  {
    path: "/office/fileEntry",
    name: "office/fileEntry",
    component: FileEntry,
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/files",
    name: "office/files",
    component: Files,
    props: (route) => ({
      keepFiltersProp: route.params.keepFiltersProp
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/file/view/:fileIdProp",
    name: "office/file/view",
    component: FileView,
    props: (route) => ({
      fileIdProp: Number(route.params.fileIdProp) || null,
      cancelRouteProp: route.params.cancelRouteProp || null,
      groupNavigatesToReportProp: route.params.groupNavigatesToReportProp || false
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/file/edit/:fileIdProp",
    name: "office/file/edit",
    component: FileEdit,
    props: (route) => ({
      editModeProp: true,
      fileIdProp: Number(route.params.fileIdProp) || null,
      cancelRouteProp: route.params.cancelRouteProp
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/file/new/:accessNumberProp?",
    name: "office/file/new",
    component: FileEdit,
    props: (route) => ({
      editModeProp: false,
      parentFileIdProp: Number(route.params.parentFileIdProp) || null,
      accessNumberProp: route.params.accessNumberProp || null
    }),
    meta: { activeTopMenuName: "office" }
  },
  // Contacts
  {
    path: "/office/contacts",
    name: "office/contacts",
    component: Contacts,
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/contact/view/:contactIdProp",
    name: "office/contact/view",
    component: ContactView,
    props: (route) => ({
      contactIdProp: Number(route.params.contactIdProp) || null
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/contact/edit/:contactIdProp",
    name: "office/contact/edit",
    component: ContactEdit,
    props: (route) => ({
      editModeProp: true,
      contactIdProp: Number(route.params.contactIdProp) || null
    }),
    meta: { activeTopMenuName: "office" }
  },
  {
    path: "/office/contact/new",
    name: "office/contact/new",
    component: ContactEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "office" }
  },

  //
  // DIAGNOSIS ROUTES
  //
  // Files
  {
    path: "/diagnosis/files",
    name: "diagnosis/files",
    component: Files,
    props: (route) => ({
      displayForDiagnosisProp: true,
      diagnosisFilterProp: "",
      keepFiltersProp: route.params.keepFiltersProp
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/files/technicalValidation",
    name: "diagnosis/files/technicalValidation",
    component: Files,
    props: (route) => ({
      displayForDiagnosisProp: true,
      diagnosisFilterProp: "technicalValidation",
      keepFiltersProp: route.params.keepFiltersProp
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/files/medicalValidation",
    name: "diagnosis/files/medicalValidation",
    component: Files,
    props: (route) => ({
      displayForDiagnosisProp: true,
      diagnosisFilterProp: "medicalValidation",
      keepFiltersProp: route.params.keepFiltersProp
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  // Report entry
  {
    path: "/diagnosis/reportEntry",
    name: "diagnosis/reportEntry",
    component: ReportEntry,
    meta: { activeTopMenuName: "diagnosis" }
  },
  // Cytology Report Edit
  {
    path: "/diagnosis/cytologyReport/edit/:fileIdProp",
    name: "diagnosis/cytologyReportEdit",
    component: CytologyReportEdit,
    props: (route) => ({
      fileIdProp: Number(route.params.fileIdProp) || null,
      cancelRouteProp: route.params.cancelRouteProp || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  // Histology Report Edit
  {
    path: "/diagnosis/histologyReport/edit/:fileIdProp",
    name: "diagnosis/histologyReportEdit",
    component: HistologyReportEdit,
    props: (route) => ({
      fileIdProp: Number(route.params.fileIdProp) || null,
      cancelRouteProp: route.params.cancelRouteProp || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  // Medical Reports
  {
    path: "/diagnosis/medicalReports",
    component: MedicalReports,
    meta: { activeTopMenuName: "diagnosis" },
    // Children route "tabs"
    children: [
      {
        path: "", // default
        name: "diagnosis/medicalReports",
        component: null,
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "undefined"
        }
      },
      {
        path: "cytologyReports",
        name: "diagnosis/medicalReports/cytologyReports",
        component: CytologyReports,
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "cytology"
        }
      },
      {
        path: "cytologyReports/problematicFiles",
        name: "diagnosis/medicalReports/cytologyReports/problematicFiles",
        component: CytologyReports,
        props: {
          filterProblematicFilesProp: true
        },
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "cytology"
        }
      },
      {
        path: "histologyReports",
        name: "diagnosis/medicalReports/histologyReports",
        component: HistologyReports,
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "histology"
        }
      },
      {
        path: "histologyReports/problematicFiles",
        name: "diagnosis/medicalReports/histologyReports/problematicFiles",
        component: HistologyReports,
        props: {
          filterProblematicFilesProp: true
        },
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "histology"
        }
      }
    ]
  },
  // Reports Validation
  {
    path: "/diagnosis/validation",
    component: Validation,
    meta: { activeTopMenuName: "diagnosis" },
    // Children route "tabs"
    children: [
      {
        path: "", // default
        name: "diagnosis/validation",
        component: null,
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "undefined"
        }
      },
      {
        path: "cytologyValidation",
        name: "diagnosis/validation/cytologyValidation",
        component: CytologyValidation,
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "cytology"
        }
      },
      {
        path: "cytologyValidation/problematicFiles",
        name: "diagnosis/validation/cytologyValidation/problematicFiles",
        component: CytologyValidation,
        props: {
          filterProblematicFilesProp: true
        },
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "cytology"
        }
      },
      {
        path: "histologyValidation",
        name: "diagnosis/validation/histologyValidation",
        component: HistologyValidation,
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "histology"
        }
      },
      {
        path: "histologyValidation/problematicFiles",
        name: "diagnosis/validation/histologyValidation/problematicFiles",
        component: HistologyValidation,
        props: {
          filterProblematicFilesProp: true
        },
        meta: {
          activeTopMenuName: "diagnosis",
          activeSector: "histology"
        }
      }
    ]
  },
  // AwaitingHpvResults
  {
    path: "/diagnosis/awaitingHpvResults",
    name: "diagnosis/awaitingHpvResults",
    component: AwaitingHpvResults,
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/awaitingHpvResult/edit/:awaitingHpvResultIdProp",
    name: "diagnosis/awaitingHpvResult/edit",
    component: AwaitingHpvResultEdit,
    props: (route) => ({
      editModeProp: true,
      awaitingHpvResultIdProp: Number(route.params.awaitingHpvResultIdProp) || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/awaitingHpvResult/new",
    name: "diagnosis/awaitingHpvResult/new",
    component: AwaitingHpvResultEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/awaitingHpvResults/upload",
    name: "diagnosis/awaitingHpvResults/upload",
    component: AwaitingHpvResultsUpload,
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/unrecognizedHpvResults",
    name: "diagnosis/unrecognizedHpvResults",
    component: UnrecognizedHpvResults,
    meta: { activeTopMenuName: "diagnosis" }
  },
  // HistologyShortCodes
  {
    path: "/diagnosis/histologyShortCodes",
    name: "diagnosis/histologyShortCodes",
    component: HistologyShortCodes,
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/histologyShortCode/view/:histologyShortCodeIdProp",
    name: "diagnosis/histologyShortCode/view",
    component: HistologyShortCodeView,
    props: (route) => ({
      histologyShortCodeIdProp: route.params.histologyShortCodeIdProp || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/histologyShortCode/edit/:histologyShortCodeIdProp",
    name: "diagnosis/histologyShortCode/edit",
    component: HistologyShortCodeEdit,
    props: (route) => ({
      editModeProp: true,
      histologyShortCodeIdProp: route.params.histologyShortCodeIdProp || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/histologyShortCode/new",
    name: "diagnosis/histologyShortCode/new",
    component: HistologyShortCodeEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "diagnosis" }
  },
  // ContainerGroupShortCodes
  {
    path: "/diagnosis/containerGroupShortCodes",
    name: "diagnosis/containerGroupShortCodes",
    component: ContainerGroupShortCodes,
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/containerGroupShortCode/view/:containerGroupShortCodeIdProp",
    name: "diagnosis/containerGroupShortCode/view",
    component: ContainerGroupShortCodeView,
    props: (route) => ({
      containerGroupShortCodeIdProp: route.params.containerGroupShortCodeIdProp || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/containerGroupShortCode/edit/:containerGroupShortCodeIdProp",
    name: "diagnosis/containerGroupShortCode/edit",
    component: ContainerGroupShortCodeEdit,
    props: (route) => ({
      editModeProp: true,
      containerGroupShortCodeIdProp: route.params.containerGroupShortCodeIdProp || null
    }),
    meta: { activeTopMenuName: "diagnosis" }
  },
  {
    path: "/diagnosis/containerGroupShortCode/new",
    name: "diagnosis/containerGroupShortCode/new",
    component: ContainerGroupShortCodeEdit,
    props: {
      editModeProp: false
    },
    meta: { activeTopMenuName: "diagnosis" }
  },

  //
  // ACCOUNTING ROUTES
  //
  // Files
  {
    path: "/accounting/files",
    name: "accounting/files",
    component: Files,
    props: (route) => ({
      displayForAccountingProp: true,
      keepFiltersProp: route.params.keepFiltersProp
    }),
    meta: { activeTopMenuName: "accounting" }
  },

  // Invoices
  {
    path: "/accounting/invoices",
    component: Invoices,
    meta: { activeTopMenuName: "accounting" },
    // Children route "tabs"
    children: [
      {
        path: "", // default
        name: "accounting/invoices",
        component: InvoiceReports,
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      {
        path: "invoiceReports",
        name: "accounting/invoices/invoiceReports",
        component: InvoiceReports,
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      {
        path: "feeNotes",
        name: "accounting/invoices/feeNotes",
        component: FeeNotes,
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "patients"
        }
      },
      {
        path: "careSheets",
        name: "accounting/invoices/careSheets",
        component: CareSheets,
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "healthcares"
        }
      }
    ]
  },
  // InvoiceReports
  {
    path: "/accounting/invoices/invoiceReport/view/:invoiceReportIdProp",
    name: "accounting/invoices/invoiceReport/view",
    component: InvoiceReportView,
    props: (route) => ({
      invoiceReportIdProp: Number(route.params.invoiceReportIdProp) || null
    }),
    meta: {
      activeTopMenuName: "accounting",
      activeAccountingSection: "organisations"
    }
  },
  {
    path: "/accounting/invoices/invoiceReport/new",
    name: "accounting/invoices/invoiceReport/new",
    component: InvoiceReportEdit,
    props: (route) => ({
      organisationTypeNameProp: route.params.organisationTypeNameProp || ""
    }),
    meta: {
      activeTopMenuName: "accounting",
      activeAccountingSection: "organisations"
    }
  },
  // FeeNotes
  {
    path: "/accounting/invoices/feeNote/view/:feeNoteIdProp",
    name: "accounting/invoices/feeNote/view",
    component: FeeNoteView,
    props: (route) => ({
      feeNoteIdProp: Number(route.params.feeNoteIdProp) || null
    }),
    meta: {
      activeTopMenuName: "accounting",
      activeAccountingSection: "patients"
    }
  },
  {
    path: "/accounting/invoices/feeNote/new",
    name: "accounting/invoices/feeNote/new",
    component: FeeNoteEdit,
    meta: {
      activeTopMenuName: "accounting",
      activeAccountingSection: "patients"
    }
  },
  // CareSheets
  {
    path: "/accounting/invoices/careSheet/view/:careSheetIdProp",
    name: "accounting/invoices/careSheet/view",
    component: CareSheetView,
    props: (route) => ({
      careSheetIdProp: Number(route.params.careSheetIdProp) || null
    }),
    meta: {
      activeTopMenuName: "accounting",
      activeAccountingSection: "healthcares"
    }
  },
  {
    path: "/accounting/invoices/careSheet/new",
    name: "accounting/invoices/careSheet/new",
    component: CareSheetEdit,
    meta: {
      activeTopMenuName: "accounting",
      activeAccountingSection: "healthcares"
    }
  },

  // Payments
  {
    path: "/accounting/payments",
    component: Payments,
    meta: { activeTopMenuName: "accounting" },
    // Children route "tabs"
    children: [
      {
        path: "",
        name: "accounting/payments",
        component: PaymentReports,
        meta: { activeTopMenuName: "accounting" }
      },
      // PAYMENT REPORTS
      {
        path: "paymentReports",
        name: "accounting/payments/paymentReports",
        component: PaymentReports,
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      {
        path: "paymentReport/edit/:paymentReportIdProp",
        name: "accounting/payments/paymentReport/edit",
        component: PaymentReportEdit,
        props: (route) => ({
          editModeProp: true,
          paymentReportIdProp: Number(route.params.paymentReportIdProp) || null
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      {
        path: "paymentReport/new",
        name: "accounting/payments/paymentReport/new",
        component: PaymentReportEdit,
        props: (route) => ({
          editModeProp: false,
          organisationTypeNameProp: route.params.organisationTypeNameProp || ""
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      // paymentReports Groups
      {
        path: "paymentReport/paymentGroupsEdit/:paymentReportIdProp",
        name: "accounting/payments/paymentReport/paymentGroupsEdit",
        component: PaymentReportPaymentGroupsEdit,
        props: (route) => ({
          editModeProp: true,
          paymentReportIdProp: Number(route.params.paymentReportIdProp) || null
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      // paymentReports Lines
      {
        path: "paymentReport/paymentReportPaymentLinesEdit/:paymentReportIdProp/:paymentGroupIdProp",
        name: "accounting/payments/paymentReport/paymentLinesEdit",
        component: PaymentReportPaymentLinesEdit,
        props: (route) => ({
          paymentReportIdProp: Number(route.params.paymentReportIdProp) || null,
          paymentGroupIdProp: Number(route.params.paymentGroupIdProp) || null,
          accessNumberProp: route.params.accessNumberProp || ""
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "organisations"
        }
      },
      // PATIENT PAYMENTS
      {
        path: "patientPayments",
        name: "accounting/payments/patientPayments",
        component: PatientPayments,
        props: (route) => ({
          accessNumberProp: route.params.accessNumberProp || "",
          isAwaitingProp: route.params.isAwaitingProp || false
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "patients"
        }
      },
      // HEALTHCARE PAYMENTS
      {
        path: "healthCarePayments",
        name: "accounting/payments/healthCarePayments",
        component: HealthCarePayments,
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "healthcares"
        }
      },
      {
        path: "healthCarePayment/linesEdit/:healthCarePaymentIdProp",
        name: "accounting/payments/healthCarePayment/linesEdit",
        component: HealthCarePaymentLinesEdit,
        props: (route) => ({
          healthCarePaymentIdProp: Number(route.params.healthCarePaymentIdProp) || null,
          accessNumberProp: route.params.accessNumberProp || ""
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "healthcares"
        }
      },
      {
        path: "healthCarePayment/edit/:healthCarePaymentIdProp",
        name: "accounting/payments/healthCarePayment/edit",
        component: HealthCarePaymentEdit,
        props: (route) => ({
          editModeProp: true,
          healthCarePaymentIdProp: Number(route.params.healthCarePaymentIdProp) || null
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "healthcares"
        }
      },
      {
        path: "healthCarePayment/new",
        name: "accounting/payments/healthCarePayment/new",
        component: HealthCarePaymentEdit,
        props: (_route) => ({
          editModeProp: false
        }),
        meta: {
          activeTopMenuName: "accounting",
          activeAccountingSection: "healthcares"
        }
      }
    ]
  },

  // transformations
  {
    path: "/accounting/fileInvoiceTransformations",
    name: "accounting/fileInvoiceTransformations",
    component: FileInvoiceTransformations,
    meta: { activeTopMenuName: "accounting" }
  },
  {
    path: "/accounting/fileInvoiceTransformation/new",
    name: "accounting/fileInvoiceTransformation/new",
    component: FileInvoiceTransformationEdit,
    meta: { activeTopMenuName: "accounting" }
  },

  // reports
  {
    path: "/accounting/reports",
    component: Reports,
    meta: { activeTopMenuName: "accounting" },
    // Children route "tabs"
    children: [
      {
        path: "", // default
        name: "accounting/reports",
        component: BalanceReport,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "balanceReport",
        name: "accounting/reports/balanceReport",
        component: BalanceReport,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "filesNotInvoiced",
        name: "accounting/reports/filesNotInvoiced",
        component: FilesNotInvoiced,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "sales",
        name: "accounting/reports/sales",
        component: Sales,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "fileInvoicesNotPaid",
        name: "accounting/reports/fileInvoicesNotPaid",
        component: FileInvoicesNotPaid,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "allPaymentsReport",
        name: "accounting/reports/allPaymentsReport",
        component: AllPaymentsReport,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "incorrectPayments",
        name: "accounting/reports/incorrectPayments",
        component: IncorrectPayments,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "receivedPayments",
        name: "accounting/reports/receivedPayments",
        component: ReceivedPayments,
        meta: { activeTopMenuName: "accounting" }
      },
      {
        path: "compensationsReport",
        name: "accounting/reports/compensationsReport",
        component: CompensationsReport,
        meta: { activeTopMenuName: "accounting" }
      }
    ]
  },

  // 404 page
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFound,
    meta: {
      layout: "fullWidth",
      activeTopMenuName: ""
    }
  }
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export function navigate(routeName, props, query) {
  const route = { name: routeName };
  if (props) { route.params = props; }
  if (query) { route.query = query; }
  if (
    (router.currentRoute.name !== route.name) ||
    (route.params && JSON.stringify(router.currentRoute.params) !== JSON.stringify(route.params))
  ) {
    router.push(route);
  }
}
// history manipulations
export function saveParamsInRouteQuery(params) {
  const formattedQuery = createQueryFromParams(params);

  const newRoute = {
    name: router.currentRoute.name,
    params: router.currentRoute.params,
    query: formattedQuery.queryObject
  };
  const { href } = router.resolve(newRoute);
  // router.currentRoute.path + (query.length ? "?" : "") + query.join("&")
  window.history.replaceState(window.history.state, null, href);
}
export function createQueryFromParams(params) {
  if (!params) params = {};
  const queryArray = [];
  const keys = Object.keys(params);
  let totalLength = router.currentRoute.path.length + 1;
  for (const key of keys) {
    if (params[key] !== "" && params[key] !== null) {
      // stringify
      if (typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key]);
      } else if (typeof params[key] === "boolean") {
        params[key] = params[key] ? "1" : "0";
      }
      params[key] = String(params[key]);
      // encode
      const encoded = encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      // get total length
      totalLength += (encoded.length + 1);
      // add to query
      if (totalLength < 2048) {
        queryArray.push(encoded);
      }
    } else {
      delete params[key];
    }
  }
  return {
    queryArray: queryArray,
    queryObject: params
  };
}
export function getQueryFromUrl() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  autocomplete(object, field, filter, returnFields) {
    return axiosServices.axios.get("/autocompletion/autocomplete", {
      params: {
        object: object,
        field: field,
        filter: filter,
        returnFields: returnFields
      }
    });
  },
  autocompleteOrganisations(object, field, filter, returnFields) {
    return axiosServices.axios.get("/autocompletion/autocompleteOrganisations", {
      params: {
        object: object,
        field: field,
        filter: filter,
        returnFields: returnFields
      }
    });
  }
};

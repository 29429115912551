<template>
  <div
    class="kt-patient-edit"
    :class="{'pb-4': !moduleModeProp}"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- patient form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- sexId -->
        <b-col cols="12">
          <b-form-group
            :invalid-feedback="sexIdInvalidFeedback"
            :state="sexIdState"
          >
            <b-form-radio-group
              ref="patientSexRadio"
              v-model="sexId"
              :options="sexOptions"
              value-field="id"
              text-field="localisedName"
              :state="sexIdState"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <!-- firstName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="firstName"
            :labelProp="$t('patients.firstName')"
            :stateProp="firstNameState"
            :invalidFeedbackProp="firstNameInvalidFeedback"
            @blur="firstNameValidation = true"
            @submit="onSubmit"
          />
        </b-col>
        <!-- lastName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="lastName"
            :labelProp="$t('patients.lastName')"
            :stateProp="lastNameState"
            :invalidFeedbackProp="lastNameInvalidFeedback"
            @blur="lastNameValidation = true"
            @submit="onSubmit"
          />
        </b-col>
        <!-- maidenName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-show="sexId === 2"
            v-model="maidenName"
            :labelProp="$t('patients.maidenNameFull')"
            :stateProp="maidenNameState"
            :invalidFeedbackProp="maidenNameInvalidFeedback"
            optionalProp
            @blur="maidenNameValidation = true"
            @submit="onSubmit"
          />
        </b-col>
        <!-- birthdate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.birthdateFull')"
            :invalid-feedback="birthdateInvalidFeedback"
            :state="birthdateState"
          >
            <b-form-input
              v-model="birthdate"
              type="date"
              :state="birthdateState"
              @blur="birthdateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- new line -->
        <div class="w-100"></div>
        <!-- regNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <InputRegistrationNumber
            class="mb-3"
            :labelProp="$t('patients.regNumber')"
            :regNumberProp="regNumber"
            :stateProp="regNumberState"
            :invalidFeedbackProp="regNumberInvalidFeedback"
            @onUpdateParent="regNumber = $event.regNumber"
            @onActivateValidation="regNumberValidation = $event"
          ></InputRegistrationNumber>
          <!-- warning badges -->
          <div
            v-show="regNumberSexWarning || regNumberBirthdateWarning"
            class="mt-n2 mb-2"
          >
            <!-- regNumberSexWarning -->
            <div v-show="regNumberSexWarning">
              <div class="kt-badge kt-badge--yellow">
                <b-icon
                  icon="info-circle"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("patientEdit.validations.warningRegnumberSex") }}</span></span>
              </div>
            </div>
            <!-- regNumberBirthdateWarning -->
            <div v-show="regNumberBirthdateWarning">
              <div
                class="kt-badge kt-badge--yellow"
                :class="{'mt-1': regNumberSexWarning}"
              >
                <b-icon
                  icon="info-circle"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("patientEdit.validations.warningRegnumberBirthdate") }}</span></span>
              </div>
            </div>
          </div>
        </b-col>
        <!-- ins -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.ins')"
            :invalid-feedback="insInvalidFeedback"
            :state="insState"
            class="form-group--optional"
          >
            <div style="position: relative">
              <div style="position: absolute; top: 5px; left: 4px; opacity: 0.95;">
                <svg
                  height="25px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 99.21 99.21"
                >
                  <g id="Pastille">
                    <circle
                      fill="#1e3253"
                      cx="49.38"
                      cy="49.61"
                      r="42.52"
                    />
                  </g>
                  <g id="INS">
                    <path
                      fill="#fff"
                      d="M19.64,29.37a5.25,5.25,0,0,1,1.61-3.93q1.62-1.56,5-1.56a7.54,7.54,0,0,1,5.12,1.56,5,5,0,0,1,1.77,3.93,5.23,5.23,0,0,1-1.64,3.95,7,7,0,0,1-5,1.59,7.33,7.33,0,0,1-5.06-1.59,5,5,0,0,1-1.78-3.95M22.12,65.6c-2.3-.13-4.09-.53-5.36-1.58a5.64,5.64,0,0,1-1.91-4.61,11.24,11.24,0,0,1,.37-2.8l2.1-7.86c0-.14.07-.26.09-.35a2.64,2.64,0,0,0,0-.29c0-.69-.3-1-.91-1a2.86,2.86,0,0,0-1,.19c-.36.13-.74.28-1.13.46l-1.89-6.84a39.57,39.57,0,0,1,4.79-2.21,17,17,0,0,1,6-1,7.32,7.32,0,0,1,5.23,1.7,5.94,5.94,0,0,1,1.77,4.5A10.17,10.17,0,0,1,30,46.65l-4.8,18.68a25.65,25.65,0,0,1-3.06.27"
                    /><path
                      fill="#fff"
                      d="M79.51,49.85,77.14,49a8.44,8.44,0,0,1-1.83-.75.83.83,0,0,1-.38-.7.72.72,0,0,1,.43-.7,3.94,3.94,0,0,1,1.56-.22,10.59,10.59,0,0,1,3.53.62A23.55,23.55,0,0,1,84,48.94l3.12-7.54a25.83,25.83,0,0,0-5.25-2,24.43,24.43,0,0,0-6.38-.76,28.88,28.88,0,0,0-5.22.46,14.46,14.46,0,0,0-4.47,1.53,8,8,0,0,0-4.25,7.54,7.4,7.4,0,0,0,.29,2.1,5.51,5.51,0,0,0,1.05,1.94,8.59,8.59,0,0,0,2,1.72,14.23,14.23,0,0,0,3.21,1.46l1.61.54a7.24,7.24,0,0,1,1.78.78.88.88,0,0,1,.37.72c0,.51-.2.8-.61.89a5.78,5.78,0,0,1-1.22.14,12.56,12.56,0,0,1-2.58-.27c-.86-.18-1.69-.4-2.48-.65a21.91,21.91,0,0,1-2.12-.78l-.14-.06-2,8.16a20.69,20.69,0,0,0,4.46,1.27,39.32,39.32,0,0,0,7,.56A26,26,0,0,0,77,66.25a13.33,13.33,0,0,0,4.23-1.56,8.4,8.4,0,0,0,3-3,9.47,9.47,0,0,0,1.1-4.8,7,7,0,0,0-1.32-4,8.5,8.5,0,0,0-4.49-3"
                    /><path
                      fill="#fff"
                      d="M60.57,56c-.3.16-.65.29-1,.46a2.76,2.76,0,0,1-1.13.3c-.43,0-.65-.27-.65-.81a3,3,0,0,1,.11-.59l1.61-5.76c.18-.69.35-1.4.49-2.16a11.21,11.21,0,0,0,.21-2,6.93,6.93,0,0,0-2.07-5.23,7.66,7.66,0,0,0-5.52-2A13.39,13.39,0,0,0,48,39a11.06,11.06,0,0,0-4.07,2.64,5,5,0,0,0-2.1-2.62A7.49,7.49,0,0,0,38,38.22a17.57,17.57,0,0,0-6.19,1l-.31.13A6,6,0,0,1,33,43.46a10.2,10.2,0,0,1-.38,2.75l-1.13,4.41L27.66,65.47H40.1l3.12-12.33A9.91,9.91,0,0,1,44.46,50,1.87,1.87,0,0,1,46,49.15a.63.63,0,0,1,.59.27,1.8,1.8,0,0,1,.22.7,4.12,4.12,0,0,1-.08.81,4.57,4.57,0,0,1-.19.64L45,57.07a13.23,13.23,0,0,0-.33,1.4,8.37,8.37,0,0,0-.1,1.34,5.77,5.77,0,0,0,2,4.61,8,8,0,0,0,5.41,1.69,16.85,16.85,0,0,0,5.33-.86c.39-.13.78-.27,1.16-.43Z"
                    />
                  </g>
                  <g id="Coche">
                    <path
                      fill="#1e3253"
                      d="M64.47,45a8.32,8.32,0,0,1-8.3-8.26,8.31,8.31,0,0,1,8.25-8.35,8.33,8.33,0,0,1,8.35,8.26A8.31,8.31,0,0,1,64.52,45Z"
                    /><path
                      fill="#fff"
                      d="M64.47,29.07a7.62,7.62,0,0,1,0,15.23h0a7.62,7.62,0,0,1,0-15.23h0m0-1.37h0a9,9,0,0,0,0,18h0a9,9,0,0,0,0-18Z"
                    /><polyline
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.37px"
                      points="59.63 37.33 62.22 39.81 68.73 33.36"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <b-form-input
              v-model="ins"
              style="padding-left: 32px;"
              trim
              maxlength="50"
              :state="insState"
              @blur="insValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- invoicedOrganisation -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.invoicedOrganisation')"
            :invalid-feedback="invoicedOrganisationInvalidFeedback"
            :state="invoicedOrganisationState"
          >
            <b-button
              v-show="invoicedOrganisation === null"
              ref="organisationChooseButton"
              v-b-modal="'choosePatientOrganisationModal'"
              :variant="'outline-' + $systemSettings.theme"
            >
              {{ $t("fileEdit.choose") }}
            </b-button>
            <div
              v-show="invoicedOrganisation !== null"
              class="kt-listed-input__text"
            >
              <b-button
                ref="organisationDeleteButton"
                :variant="$systemSettings.theme"
                block
                class="kt-listed-input__btn"
                @click="() => {
                  invoicedOrganisation = null;
                  invoicedOrganisationId = null;
                  insuranceTypeId = 1;
                  $nextTick(() => $refs.organisationChooseButton.focus());
                }"
              >
                <span class="kt-listed-input__btn-text">{{ invoicedOrganisationName }}</span>
                <b-icon
                  icon="x"
                  class="kt-listed-input__btn-icon"
                ></b-icon>
              </b-button>
            </div>
          </b-form-group>
          <div
            v-show="invoicedOrganisation !== null"
          >
            <!-- insuranceType -->
            <b-form-group
              :invalid-feedback="insuranceTypeInvalidFeedback"
              :state="insuranceTypeState"
            >
              <b-form-select
                v-model="insuranceTypeId"
                :options="insuranceTypeOptions"
                value-field="id"
                text-field="localisedName"
              />
            </b-form-group>
            <!-- insuranceEndDate -->
            <b-form-group
              class="mt-n1"
              :label="$t('patients.insuranceEndDate')"
              :invalid-feedback="insuranceEndDateInvalidFeedback"
              :state="insuranceEndDateState"
            >
              <b-form-input
                v-model="insuranceEndDate"
                type="date"
                :state="insuranceEndDateState"
                @blur="insuranceEndDateValidation = true"
              />
            </b-form-group>
          </div>
        </b-col>
        <!-- divider -->
        <b-col
          v-show="isInsureeDisplayed"
          cols="12"
        >
          <div
            class="w-100 border-top mt-1"
            style="margin-bottom: 10px;"
          ></div>
        </b-col>
        <!-- insuree header -->
        <b-col cols="12">
          <b-button
            :variant="isInsureeDisplayed ? 'light' : 'outline-' + $systemSettings.theme"
            class="kt-patient-edit__insuree-btn mb-1"
            @click="isInsureeDisplayed = !isInsureeDisplayed"
          >
            <b-icon
              v-show="!isInsureeDisplayed"
              icon="plus"
              class="kt-patient-edit__insuree-btn-icon"
            ></b-icon>
            <b-icon
              v-show="isInsureeDisplayed"
              icon="backspace"
              class="kt-patient-edit__insuree-btn-icon"
            ></b-icon>
            {{ isInsureeDisplayed ? $t("general.emptyString") : $t("patientEdit.insureeButtonOpen") }}
          </b-button>
          <h5
            v-show="isInsureeDisplayed"
            class="kt-section-title kt-section-title--inlined kt-insuree-title"
          >
            {{ $t("patients.insureeSectionTitle") }}
          </h5>
        </b-col>
        <!-- insuree section -->
        <b-col cols="12">
          <div v-show="isInsureeDisplayed">
            <b-row>
              <!-- insureeFirstName -->
              <b-col
                cols="12"
                md="4"
              >
                <InputTextFormatted
                  v-model="insureeFirstName"
                  :labelProp="$t('patients.firstName')"
                  :stateProp="insureeFirstNameState"
                  :invalidFeedbackProp="insureeFirstNameInvalidFeedback"
                  @blur="insureeFirstNameValidation = true"
                  @submit="onSubmit"
                />
              </b-col>
              <!-- insureeLastName -->
              <b-col
                cols="12"
                md="4"
              >
                <InputTextFormatted
                  v-model="insureeLastName"
                  :labelProp="$t('patients.lastName')"
                  :stateProp="insureeLastNameState"
                  :invalidFeedbackProp="insureeLastNameInvalidFeedback"
                  @blur="insureeLastNameValidation = true"
                  @submit="onSubmit"
                />
              </b-col>
              <!-- insureeMaidenName -->
              <b-col
                cols="12"
                md="4"
              >
                <InputTextFormatted
                  v-model="insureeMaidenName"
                  :labelProp="$t('patients.maidenNameFull')"
                  :stateProp="insureeMaidenNameState"
                  :invalidFeedbackProp="insureeMaidenNameInvalidFeedback"
                  optionalProp
                  @blur="insureeMaidenNameValidation = true"
                  @submit="onSubmit"
                />
              </b-col>
              <!-- insureeRegNumber -->
              <b-col
                cols="12"
                md="4"
              >
                <InputRegistrationNumber
                  :labelProp="$t('patients.regNumber')"
                  :regNumberProp="insureeRegNumber"
                  :stateProp="insureeRegNumberState"
                  :invalidFeedbackProp="insureeRegNumberInvalidFeedback"
                  @onUpdateParent="insureeRegNumber = $event.regNumber"
                  @onActivateValidation="insureeRegNumberValidation = $event"
                ></InputRegistrationNumber>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-3 mb-2"></div>
        </b-col>
        <!-- SECTION address -->
        <b-col cols="12">
          <h5 class="kt-section-title kt-section-title--inlined">
            {{ $t("patients.addressSectionTitle") }}
          </h5>
          <span class="kt-required-note">
            {{ $t("patientEdit.phoneAndEmailOrAddressRequired") }}
          </span>
        </b-col>
        <!-- address1 -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('patients.address1')"
            :invalid-feedback="address1InvalidFeedback"
            :state="address1State"
          >
            <b-form-input
              v-model="address1"
              trim
              maxlength="50"
              :state="address1State"
              @blur="address1Validation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- address2 -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.address2')"
            :invalid-feedback="address2InvalidFeedback"
            :state="address2State"
            class="form-group--optional"
          >
            <b-form-input
              v-model="address2"
              trim
              maxlength="50"
              :state="address2State"
              @blur="address2Validation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- zip -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.zip')"
            :invalid-feedback="zipInvalidFeedback"
            :state="zipState"
          >
            <b-form-input
              v-model="zip"
              trim
              maxlength="50"
              :state="zipState"
              @blur="zipValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- city -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.city')"
            :invalid-feedback="cityInvalidFeedback"
            :state="cityState"
          >
            <b-form-input
              v-model="city"
              trim
              maxlength="50"
              :state="cityState"
              @blur="cityValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- country -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.country')"
            :invalid-feedback="countryInvalidFeedback"
            :state="countryState"
          >
            <b-form-input
              v-model="country"
              trim
              maxlength="50"
              :state="countryState"
              @blur="countryValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- SECTION contactInformation -->
        <b-col cols="12">
          <h5 class="kt-section-title">
            {{ $t("patients.contactInformationSectionTitle") }}
          </h5>
        </b-col>
        <!-- phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.phone')"
            :invalid-feedback="phoneInvalidFeedback"
            :state="phoneState"
          >
            <b-form-input
              v-model="phone"
              class="kt-input--prefixed"
              trim
              maxlength="50"
              :state="phoneState"
              @blur="phoneValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t("general.phoneCountryCodeDefault") }}
              </div>
            </div>
          </b-form-group>
        </b-col>
        <!-- email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('patients.email')"
            :invalid-feedback="emailInvalidFeedback"
            :state="emailState"
          >
            <b-form-input
              v-model="email"
              trim
              maxlength="256"
              :state="emailState"
              @blur="emailValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- choose Organisation Modal -->
    <b-modal
      id="choosePatientOrganisationModal"
      hide-footer
      :title="$t('organisationChoose.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.organisationChooseComponent.focusFirstElement();
      }"
      @hidden="() => {
        if (invoicedOrganisation === null) {
          $refs.organisationChooseButton.focus();
        } else {
          $refs.organisationDeleteButton.focus();
        }
      }"
    >
      <OrganisationChoose
        ref="organisationChooseComponent"
        :optionsProp="{
          filterOrganisationTypeName: 'healthCare',
          filterIsPrescribing: false
        }"
        @chosen="onOrganisationChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import InputRegistrationNumber from "@shared/views/Helpers/InputRegistrationNumber";
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
// modals
import OrganisationChoose from "@/views/Data/Organisations/OrganisationChoose";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import regNumber from "@shared/services/UI/regNumber";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import date from "@shared/services/UI/date";

export default {
  components: { InputRegistrationNumber, InputTextFormatted, OrganisationChoose },
  mixins: [userRights, error, regNumber, phoneNumber, address, date],
  props: {
    patientIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    },
    initialValuesProp: {
      type: Object,
      default: function() {
        return {
          birthdate: "",
          firstName: "",
          lastName: "",
          maidenName: "",
          regNumber: "",
          zip: "",
          address1: "",
          address2: "",
          city: "",
          country: "",
          phone: "",
          email: ""
        };
      }
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      patientId: this.patientIdProp,
      patientData: null,
      onefieldRequiredValidation: false,
      // form variables
      sexId: 2,
      birthdate: !this.editModeProp ? (this.initialValuesProp.birthdate || "") : "",
      firstName: !this.editModeProp ? (this.initialValuesProp.firstName || "") : "",
      lastName: !this.editModeProp ? (this.initialValuesProp.lastName || "") : "",
      maidenName: !this.editModeProp ? (this.initialValuesProp.maidenName || "") : "",
      regNumber: !this.editModeProp ? (this.initialValuesProp.regNumber || "") : "",
      ins: "",
      invoicedOrganisation: null,
      invoicedOrganisationId: null,
      insuranceTypeId: 1,
      insuranceEndDate: "",
      zip: !this.editModeProp ? (this.initialValuesProp.zip || "") : "",
      address1: !this.editModeProp ? (this.initialValuesProp.address1 || "") : "",
      address2: !this.editModeProp ? (this.initialValuesProp.address2 || "") : "",
      city: !this.editModeProp ? (this.initialValuesProp.city || "") : "",
      country: !this.editModeProp ? (this.initialValuesProp.country || this.getLocalisedDefaultCountry()) : "",
      phone: !this.editModeProp ? (this.initialValuesProp.phone || "") : "",
      email: !this.editModeProp ? (this.initialValuesProp.email || "") : "",
      isInsureeDisplayed: false,
      insureeFirstName: "",
      insureeLastName: "",
      insureeMaidenName: "",
      insureeRegNumber: "",
      // is validation active
      sexIdValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      maidenNameValidation: false,
      birthdateValidation: false,
      regNumberValidation: false,
      insValidation: false,
      invoicedOrganisationValidation: false,
      insuranceTypeValidation: false,
      insuranceEndDateValidation: false,
      address1Validation: false,
      address2Validation: false,
      zipValidation: false,
      cityValidation: false,
      countryValidation: false,
      phoneValidation: false,
      emailValidation: false,
      insureeFirstNameValidation: "",
      insureeLastNameValidation: "",
      insureeMaidenNameValidation: "",
      insureeRegNumberValidation: ""
    };
  },
  computed: {
    // form validation
    sexIdState: function() {
      return null;
    },
    sexIdInvalidFeedback: function() {
      return "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;
      return null;
    },
    firstNameInvalidFeedback: function() {
      // no error
      if (this.firstNameState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");
      // else : required
      return this.$t("validationRules.required");
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;
      return null;
    },
    lastNameInvalidFeedback: function() {
      // no error
      if (this.lastNameState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");
      // else : required
      return this.$t("validationRules.required");
    },
    maidenNameState: function() {
      return null;
    },
    maidenNameInvalidFeedback: function() {
      return "";
    },
    birthdateState: function() {
      if (!this.birthdateValidation) return null;
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;
      // not required
      if (!this.birthdate) return null;

      // invalid date
      let birthYear = this.birthdate || "";
      birthYear = Number(birthYear.slice(0, 4)) || 0;
      if (birthYear < 1890 || birthYear > 2300) {
        return false;
      }
      // futur date
      if (!this.validatePastDate(this.birthdate)) {
        return false;
      }
      return null;
    },
    birthdateInvalidFeedback: function() {
      // no error
      if (this.birthdateState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");

      // invalid date
      const birthYear = Number(this.birthdate.slice(0, 4)) || 0;
      if (birthYear < 1890 || birthYear > 2300) return this.$t("validationRules.invalidDate");
      // futur date
      return this.$t("patientEdit.validations.birthdateInFutur");
    },
    regNumberState: function() {
      if (!this.regNumberValidation) return null;
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;
      // empty not required
      if (this.regNumber === "") return null;

      // different than issuree
      if (this.regNumber === this.insureeRegNumber) return false;
      // 15 caracters
      if (this.regNumber.length !== 15) return false;
      // only digits or A-B
      if (!/^\d+(2A|2B)?\d+$/.test(this.regNumber)) return false;
      // no error
      return null;
    },
    regNumberInvalidFeedback: function() {
      // no error
      if (this.regNumberState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");

      // different than issuree
      if (this.regNumber === this.insureeRegNumber) return this.$t("patientEdit.validations.regnumbersMustBeDifferent");
      // 15 characters
      if (this.regNumber.length !== 15) this.$t("patientEdit.validations.regnumber15Characters");
      // only digits or A-B
      return this.$t("patientEdit.validations.invalidRegnumber");
    },
    regNumberSexWarning: function() {
      // empty or 15 caracters
      if (this.regNumber.length !== 15) return false;
      // empty sexId
      if (!this.sexId) return false;
      // sex validation
      return this.regNumber.slice(0, 1) !== String(this.sexId);
    },
    regNumberBirthdateWarning: function() {
      // empty or 15 caracters
      if (this.regNumber.length !== 15) return false;
      // empty birthdate
      if (this.birthdate.length !== 10) return false;
      // yyyy-mm-dd 0 12 34 ....
      return (
        // validate year of birth
        this.birthdate.slice(2, 4) !== this.regNumber.slice(1, 3) ||
        // validate month of birth
        this.birthdate.slice(5, 7) !== this.regNumber.slice(3, 5)
      );
    },
    insState: function() {
      if (!this.insValidation) return null;
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;
      return null;
    },
    insInvalidFeedback: function() {
      // no error
      if (this.insState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");
      // else : required
      return this.$t("validationRules.required");
    },
    invoicedOrganisationState: function() {
      return null;
    },
    invoicedOrganisationInvalidFeedback: function() {
      return "";
    },
    insuranceTypeState: function() {
      if (!this.insuranceTypeNameValidation) return null;
      return this.invoicedOrganisationId !== null && this.insuranceTypeId === null ? false : null;
    },
    insuranceTypeInvalidFeedback: function() {
      if (this.insuranceTypeState === null) return "";
      return this.$t("validationRules.required");
    },
    insuranceEndDateState: function() {
      if (!this.insuranceEndDateValidation) return null;
      if (this.invoicedOrganisation) {
        const insuranceType = this.insuranceTypeOptions.find(v => v.id === this.insuranceTypeId);
        if (!insuranceType || insuranceType.name === "healthCare") {
          return null;
        } else {
          return !this.insuranceEndDate ? false : null;
        }
      } else {
        return null;
      }
    },
    insuranceEndDateInvalidFeedback: function() {
      if (this.insuranceEndDateState === null) return "";
      return this.$t("validationRules.required");
    },
    address1State: function() {
      return null;
    },
    address1InvalidFeedback: function() {
      return "";
    },
    address2State: function() {
      return null;
    },
    address2InvalidFeedback: function() {
      return "";
    },
    zipState: function() {
      return null;
    },
    zipInvalidFeedback: function() {
      return "";
    },
    cityState: function() {
      return null;
    },
    cityInvalidFeedback: function() {
      return "";
    },
    countryState: function() {
      return null;
    },
    countryInvalidFeedback: function() {
      return "";
    },
    phoneState: function() {
      if (!this.phoneValidation) return null;
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;

      // not required
      if (this.phone.length === 0) return null;
      // test if invalid
      return this.phone && this.phone.length > 0 && /^0?[123456789]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");
      // else : invalid phone
      return this.$t("validationRules.invalidPhone");
    },
    emailState: function() {
      if (!this.emailValidation) return null;
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;

      // not required
      if (this.email.length === 0) return null;
      // test if invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");
      // else : invalid email
      return this.$t("validationRules.invalidEmail");
    },
    // insuree
    insureeFirstNameState: function() {
      return null;
    },
    insureeFirstNameInvalidFeedback: function() {
      return "";
    },
    insureeLastNameState: function() {
      return null;
    },
    insureeLastNameInvalidFeedback: function() {
      return "";
    },
    insureeMaidenNameState: function() {
      return null;
    },
    insureeMaidenNameInvalidFeedback: function() {
      return "";
    },
    insureeRegNumberState: function() {
      if (!this.insureeRegNumberValidation) return null;
      if (!this.isInsureeDisplayed) return null;
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return false;
      // empty not required
      if (this.insureeRegNumber === "") return null;

      // different than issuree
      if (this.regNumber === this.insureeRegNumber) return false;
      // 15 caracters
      if (this.insureeRegNumber.length !== 15) return false;
      // only digits or A-B
      if (!/^\d+(2A|2B)?\d+$/.test(this.insureeRegNumber)) return false;
      // no error
      return null;
    },
    insureeRegNumberInvalidFeedback: function() {
      // no error
      if (this.insureeRegNumberState === null) return "";
      // one field required validation
      if (this.onefieldRequiredValidation && !this.hasOneFieldNotEmpty) return this.$t("patientEdit.validations.atLeastOneFieldRequired");

      // different than issuree
      if (this.regNumber === this.insureeRegNumber) return this.$t("patientEdit.validations.regnumbersMustBeDifferent");
      // 15 characters
      if (this.insureeRegNumber.length !== 15) this.$t("patientEdit.validations.regnumber15Characters");
      // only digits or A-B
      return this.$t("patientEdit.validations.invalidRegnumber");
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("patientEdit.pageTitle") : this.$t("patientNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("patientEdit.submitText") : this.$t("patientNew.submitText");
    },
    invoicedOrganisationName: function() {
      if (!this.invoicedOrganisation) {
        return "";
      }
      return this.invoicedOrganisation.name + (this.invoicedOrganisation.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
    },
    hasOneFieldNotEmpty: function() {
      return (
        this.firstName.length > 0 ||
        this.lastName.length > 0 ||
        (this.birthdate && this.birthdate.length > 0) ||
        this.ins.length > 0 ||
        this.regNumber.length > 0 ||
        (this.isInsureeDisplayed && this.insureeRegNumber.length > 0) ||
        this.phone.length > 0 ||
        this.email.length > 0
      );
    },

    // options
    sexOptions: function() {
      return this.$systemSettings.sexes || [];
    },
    insuranceTypeOptions: function() {
      return this.$systemSettings.insuranceTypes || [];
    }
  },
  async mounted() {
    try {
      // import data
      if (this.editModeProp) {
        await this.importData();
      }

      // auto focus
      if (!this.moduleModeProp) {
        this.$nextTick(() => {
          this.focusFirstElement();
        });
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstElement() {
      if (this.sexId) {
        for (const child of this.$refs.patientSexRadio.$children) {
          if (child.value === this.sexId) {
            child.focus();
            break;
          }
        }
      } else {
        this.$refs.patientSexRadio.$children[0].focus();
      }
    },
    // load/refresh data
    async importData() {
      try {
        // get patient
        const resServices = await commonServices.get("patients", this.patientId);
        this.patientData = resServices.data;
        // import data
        this.sexId = resServices.data.sexId;
        this.firstName = resServices.data.firstName;
        this.lastName = resServices.data.lastName;
        this.maidenName = resServices.data.maidenName;
        this.birthdate = (resServices.data.birthdate === "" ? null : resServices.data.birthdate);
        this.regNumber = resServices.data.regNumber;
        this.ins = resServices.data.ins;
        this.invoicedOrganisation = resServices.data.invoicedOrganisation;
        this.invoicedOrganisationId = resServices.data.invoicedOrganisationId;
        this.insuranceTypeId = !resServices.data.insuranceTypeId ? 1 : resServices.data.insuranceTypeId;
        this.insuranceEndDate = resServices.data.insuranceEndDate;
        this.address1 = resServices.data.address1;
        this.address2 = resServices.data.address2;
        this.zip = resServices.data.zip;
        this.city = resServices.data.city;
        this.country = resServices.data.country;
        this.phone = this.importFormatPhone(resServices.data.phone);
        this.email = resServices.data.email;
        this.insureeFirstName = resServices.data.insureeFirstName;
        this.insureeLastName = resServices.data.insureeLastName;
        this.insureeMaidenName = resServices.data.insureeMaidenName;
        this.insureeRegNumber = resServices.data.insureeRegNumber;

        if (this.insureeFirstName || this.insureeLastName || this.insureeMaidenName || this.insureeRegNumber) {
          this.isInsureeDisplayed = true;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // navigate to the entry page
    onGoBack() {
      navigate("office/patients");
    },

    onOrganisationChosen(organisation) {
      this.invoicedOrganisation = organisation;
      this.invoicedOrganisationId = organisation ? organisation.id : null;
      this.$bvModal.hide("choosePatientOrganisationModal");
    },

    // submit
    onValidatePatient() {
      // activate "one field" validations
      this.onefieldRequiredValidation = true;
      // activate validations
      this.sexIdValidation = true;
      this.firstNameValidation = true;
      this.lastNameValidation = true;
      this.maidenNameValidation = true;
      this.birthdateValidation = true;
      this.regNumberValidation = true;
      this.insValidation = true;
      this.invoicedOrganisationValidation = true;
      this.insuranceTypeValidation = true;
      this.insuranceEndDateValidation = true;

      this.insureeFirstNameValidation = true;
      this.insureeLastNameValidation = true;
      this.insureeMaidenNameValidation = true;
      this.insureeRegNumberValidation = true;

      this.address1Validation = true;
      this.address2Validation = true;
      this.zipValidation = true;
      this.cityValidation = true;
      this.countryValidation = true;
      this.phoneValidation = true;
      this.emailValidation = true;

      // validate
      return !(
        this.hasOneFieldNotEmpty === false ||
        this.sexIdState === false ||
        this.firstNameState === false ||
        this.lastNameState === false ||
        this.maidenNameState === false ||
        this.birthdateState === false ||
        this.regNumberState === false ||
        this.insState === false ||
        this.invoicedOrganisationState === false ||
        this.insuranceTypeState === false ||
        this.insuranceEndDateState === false ||

        this.insureeFirstNameState === false ||
        this.insureeLastNameState === false ||
        this.insureeMaidenNameState === false ||
        this.insureeRegNumberState === false ||

        this.address1State === false ||
        this.address2State === false ||
        this.zipState === false ||
        this.cityState === false ||
        this.countryState === false ||
        this.phoneState === false ||
        this.emailState === false
      );
    },
    async onSavePatient() {
      try {
        const form = {
          sexId: this.sexId,
          firstName: this.firstName,
          lastName: this.lastName,
          maidenName: this.sexId === 1 ? "" : this.maidenName,
          birthdate: this.birthdate || null,
          regNumber: this.regNumber,
          ins: this.ins,
          invoicedOrganisationId: this.invoicedOrganisationId,
          insuranceTypeId: this.invoicedOrganisationId !== null ? this.insuranceTypeId : null,
          insuranceEndDate: this.invoicedOrganisationId !== null && this.insuranceEndDate ? this.insuranceEndDate : null,

          insureeFirstName: this.isInsureeDisplayed ? this.insureeFirstName : "",
          insureeLastName: this.isInsureeDisplayed ? this.insureeLastName : "",
          insureeMaidenName: this.isInsureeDisplayed ? this.insureeMaidenName : "",
          insureeRegNumber: this.isInsureeDisplayed ? this.insureeRegNumber : "",

          address1: this.address1,
          address2: this.address2,
          zip: this.zip,
          city: this.city,
          country: this.country,
          phone: this.exportFormatPhone(this.phone),
          email: this.email
        };
        if (this.editMode) {
          const res = await commonServices.put("patients", form, this.patientId);
          if (res.data === true) {
            this.successSave(this.patientId);
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("patientEdit.notifications.editionErrorTitle"),
              message: this.$t("patientEdit.notifications.editionErrorText")
            });
          }
        } else {
          const res = await commonServices.post("patients", form);
          if (res.data.id) {
            this.patientId = res.data.id;
            this.successSave(this.patientId);
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("patientEdit.notifications.additionErrorTitle"),
              message: this.$t("patientEdit.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave(patientId) {
      // success message
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("patientEdit.notifications.editedTitle"),
          message: this.$t("patientEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("patientEdit.notifications.addedTitle"),
          message: this.$t("patientEdit.notifications.addedText")
        });
      }
      // go back to main component
      if (this.moduleModeProp) {
        // CHOOSE PATIENT MODE
        this.$emit(this.editMode ? "onPatientEdited" : "onPatientCreated", patientId);
      } else {
        // REGULAR PATIENT PAGE
        navigate("office/patients");
      }
    },
    onSubmit() {
      if (this.onValidatePatient() !== false) { this.onSavePatient(); }
    }
  }
};
</script>

<style scoped src="./PatientEdit.scss" lang="scss"></style>

<template>
  <div class="kt-automaticAction-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("systemSettings.automaticActionEdit.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- automaticAction form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- name -->
        <b-col
          cols="12"
          md="3"
        >
          <h3 class="h6">
            {{ $t("systemSettings.automaticActions.name") }}
          </h3>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p class="mb-0">
            {{ name }}
          </p>
        </b-col>
        <!-- parameters -->
        <b-col
          cols="12"
          md="3"
        >
          <h3 class="h6">
            {{ $t("systemSettings.automaticActions.parameters") }}
          </h3>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p class="mb-0">
            {{ parameters }}
          </p>
        </b-col>
        <!-- rule -->
        <b-col
          cols="12"
          md="3"
        >
          <h3 class="h6">
            {{ $t("systemSettings.automaticActionEdit.rule") }}
          </h3>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <p class="mb-0">
            {{ $t("systemSettings.automaticActionEdit.ruleInformation") }}
          </p>
        </b-col>
      </b-row>
      <!-- rule input -->
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :invalid-feedback="ruleInvalidFeedback"
            :state="ruleState"
          >
            <b-form-input
              v-model="rule"
              trim
              :state="ruleState"
              @blur="ruleValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- isActive checkbox -->
      <b-row class="mt-n2">
        <b-col
          cols="12"
          md="3"
        >
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-checkbox
            v-model="isActive"
            switch
            class="mr-n2"
          >
            {{ isActive ? $t("systemSettings.automaticActionEdit.active") : $t("systemSettings.automaticActionEdit.inactive") }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!-- divider -->
      <div class="w-100 border-top mt-3 mb-2"></div>

      <b-row>
        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t("systemSettings.automaticActionEdit.submitText") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error],
  props: {
    automaticActionIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      // general
      automaticActionId: this.automaticActionIdProp,
      automaticActionData: null,
      // form variables
      name: "",
      rule: "",
      parameters: "",
      isActive: false,
      // is validation active
      rulealidation: false
    };
  },
  computed: {
    // form validation
    ruleState: function() {
      if (!this.ruleValidation) return null;
      return this.rule && this.rule.length > 0 ? null : false;
    },
    ruleInvalidFeedback: function() {
      return this.ruleState === false ? this.$t("validationRules.required") : "";
    }
  },
  async mounted() {
    // import data
    await this.importData();
  },
  methods: {
    // load/refresh data
    async importData() {
      try {
        // get automaticAction
        const resServices = await commonServices.get("automaticActions", this.automaticActionId);
        this.automaticActionData = resServices.data;
        // import data
        this.name = resServices.data.name;
        this.rule = resServices.data.rule;
        this.parameters = resServices.data.parameters;
        this.isActive = resServices.data.isActive;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("system/systemSettings/automaticActions");
    },

    // submit functions
    validateForm() {
      this.ruleValidation = true;

      return !(
        this.ruleState === false
      );
    },
    async saveAutomaticAction() {
      try {
        const form = {
          rule: this.rule,
          isActive: this.isActive
        };
        const res = await commonServices.put("automaticActions", form, this.automaticActionId);
        if (res.data === true) {
          // success message
          this.$emit("alert", "success", {
            title: this.$t("systemSettings.automaticActionEdit.notifications.editedTitle"),
            message: this.$t("systemSettings.automaticActionEdit.notifications.editedText")
          });
          navigate("system/systemSettings/automaticActions");
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("systemSettings.automaticActionEdit.notifications.editionErrorTitle"),
            message: this.$t("systemSettings.automaticActionEdit.notifications.editionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveAutomaticAction();
    }
  }
};
</script>

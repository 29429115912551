<template>
  <div class="kt-forgot-password-success">
    <!-- back to login -->
    <div class="kt-login__subtitle">
      <b-link
        class="kt-link kt-link--gray"
        @click="$emit('goToLogin')"
      >
        <b-icon icon="chevron-left"></b-icon>
        {{ $t("session.forgotPassword.backButton") }}
      </b-link>
    </div>

    <!-- illustration -->
    <div class="text-center">
      <img
        :src="'/img/Session/RegisterSuccess/email-link-' + $systemSettings.theme + '.png'"
        alt="email-confirmation"
      />
    </div>

    <!-- title -->
    <h2 class="kt-login__action-title h5 mt-4 mb-3">
      {{ $t('session.forgotPasswordSuccess.title') }}
    </h2>
    <div
      class="kt-login__subtitle"
    >
      {{ $t("session.forgotPasswordSuccess.subtitle") }}
    </div>
  </div>
</template>

<script>
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    forgotPasswordReturnProp: {
      type: String,
      default: ""
    }
  }
};
</script>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  getAllByAccessNumber(accessNumber) {
    return axiosServices.axios.get("/cytologyReports/getAllByAccessNumber", { params: { accessNumber: accessNumber } });
  },
  getIdByFileId(fileId) {
    return axiosServices.axios.get("/cytologyReports/getIdByFileId", { params: { fileId: fileId } });
  },
  getAllByFileId(fileId) {
    return axiosServices.axios.get("/cytologyReports/getAllByFileId", { params: { fileId: fileId } });
  },
  getAllByCytotechnician(page, filter, sort) {
    return axiosServices.axios.get(
      "/cytologyReports/getAllByCytotechnician", {
        params: {
          page: page,
          filter: filter,
          sort: sort
        }
      });
  },
  getAllByPathologist(page, filter, sort) {
    return axiosServices.axios.get(
      "/cytologyReports/getAllByPathologist", {
        params: {
          page: page,
          filter: filter,
          sort: sort
        }
      });
  },
  getClinicalInformation(parentFileId) {
    return axiosServices.axios.get("/cytologyReports/getClinicalInformation", { params: { parentFileId: parentFileId } });
  },
  countAllNegatives(range, cotestingHpvPos, targetUserIds) {
    return axiosServices.axios.get("/cytologyReports/countAllNegatives", { params: { range: range, cotestingHpvPos, targetUserIds: targetUserIds } });
  },
  newVersion(id) {
    return axiosServices.axios.post("/cytologyReports/newVersion", { id: id });
  },
  validateTechnically(fileId) {
    return axiosServices.axios.put("/cytologyReports/validateTechnically", { fileId: fileId });
  },
  validateMedically(fileId) {
    return axiosServices.axios.put("/cytologyReports/validateMedically", { fileId: fileId });
  },
  validateAllNegatives(range, cotestingHpvPos, targetUserId, callback) {
    return axiosServices.axios.put("/cytologyReports/validateAllNegatives", { range: range, cotestingHpvPos, targetUserId: targetUserId }, { onDownloadProgress: callback });
  },
  resetSentElectronically(id) {
    return axiosServices.axios.put("/cytologyReports/resetSentElectronically", { id: id });
  }
};

<template>
  <div class="systemSettings mb-3 mb-lg-5">
    <!-- systemSettings navbar -->
    <b-nav
      pills
      class="kt-sub-nav mt-1 mt-lg-2"
      :class="'kt-sub-nav--' + $systemSettings.theme"
    >
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/theme'"
        @click="navigate('system/systemSettings/theme')"
      >
        <b-icon icon="gem"></b-icon>
        {{ $t("systemSettings.themeNavigationTitle") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/localization'"
        @click="navigate('system/systemSettings/localization')"
      >
        <b-icon icon="globe"></b-icon>
        {{ $t("systemSettings.localization") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/sessions'"
        @click="navigate('system/systemSettings/sessions')"
      >
        <b-icon icon="stopwatch"></b-icon>
        {{ $t("systemSettings.sessions") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/sectors'"
        @click="navigate('system/systemSettings/sectors')"
      >
        <b-icon icon="cart3"></b-icon>
        {{ $t("systemSettings.sectors") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/additionalFeeGroups'"
        @click="navigate('system/systemSettings/additionalFeeGroups')"
      >
        <b-icon icon="plus-circle"></b-icon>
        {{ $t("additionalFeeGroups.pageTitle") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/directories'"
        @click="navigate('system/systemSettings/directories')"
      >
        <b-icon icon="folder"></b-icon>
        {{ $t("systemSettings.directories") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/logging'"
        @click="navigate('system/systemSettings/logging')"
      >
        <b-icon icon="file-text"></b-icon>
        {{ $t("systemSettings.logging") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/automaticActions'"
        @click="navigate('system/systemSettings/automaticActions')"
      >
        <b-icon icon="calendar-check"></b-icon>
        {{ $t("systemSettings.automaticActions.pageTitle") }}
      </b-nav-item>
      <b-nav-item
        class="kt-sub-nav__link"
        :active="$route.name === 'system/systemSettings/smtpServer'"
        @click="navigate('system/systemSettings/smtpServer')"
      >
        <b-icon icon="server"></b-icon>
        {{ $t("laboratory.smtpServer") }}
      </b-nav-item>
    </b-nav>

    <!-- systemSettings content -->
    <div class="d-flex justify-content-center mt-2 mt-lg-3">
      <keep-alive>
        <router-view
          :key="$route.fullPath"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
// others
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  // mixins: [userRights]
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;
  }
};
</script>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  getLinkedFiles(parentFileId) {
    return axiosServices.axios.get("/files/getLinkedFiles", { params: { parentFileId: parentFileId } });
  },
  getAllByPatientId(patientId) {
    return axiosServices.axios.get("/files/getAllByPatientId", { params: { patientId: patientId } });
  },
  getAccountingHistory(filter) {
    return axiosServices.axios.get("/files/getAccountingHistory", { params: { filter: filter } });
  },
  getAllByAccessNumber(accessNumber) {
    return axiosServices.axios.get("/files/getAllByAccessNumber", { params: { accessNumber: accessNumber } });
  },
  getFileInvoicesByFileId(fileId) {
    return axiosServices.axios.get("/files/getFileInvoicesByFileId", { params: { fileId: fileId } });
  },
  getInvoicedOrganisation(form) {
    return axiosServices.axios.get("/files/getInvoicedOrganisation", { params: form });
  },
  getAllInvoicedFiles(filter) {
    return axiosServices.axios.get("/files/getAllInvoicedFiles", { params: { filter: filter } });
  },
  getInvoicedFile(filter) {
    return axiosServices.axios.get("/files/getInvoicedFile", { params: { filter: filter } });
  },
  setIsUrgent(id, isUrgent) {
    return axiosServices.axios.put("/files/setIsUrgent", { id: id, isUrgent: isUrgent });
  },
  invalidate(id, filingStatus) {
    return axiosServices.axios.put("/files/invalidate", { id: id, filingStatus: filingStatus });
  },
  validateForDiagnosis(id) {
    return axiosServices.axios.put("/files/validateForDiagnosis", { id: id });
  },
  validateForAccounting(id) {
    return axiosServices.axios.put("/files/validateForAccounting", { id: id });
  },
  cancelComplementary(id) {
    return axiosServices.axios.put("/files/cancelComplementary", { id: id });
  }
};

<template>
  <div
    class="kt-unknownPayment-edit"
    :class="{'pb-4': !moduleModeProp}"
  >
    <!-- page header -->
    <div
      v-show="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div
      v-show="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- unknownPayment form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- hospitalisationNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.unknownPayments.hospitalisationNumber')"
            :invalid-feedback="hospitalisationNumberInvalidFeedback"
            :state="hospitalisationNumberState"
          >
            <b-form-input
              ref="hospitalisationNumberInput"
              v-model="hospitalisationNumber"
              trim
              maxlength="50"
              :state="hospitalisationNumberState"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- lastName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="lastName"
            :labelProp="$t('payments.unknownPayments.lastName')"
            :stateProp="lastNameState"
            :invalidFeedbackProp="lastNameInvalidFeedback"
            @submit="submitSave"
          />
        </b-col>
        <!-- firstName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="firstName"
            :labelProp="$t('payments.unknownPayments.firstName')"
            :stateProp="firstNameState"
            :invalidFeedbackProp="firstNameInvalidFeedback"
            @blur="hospitalisationNumberValidation = true; lastNameValidation = true; firstNameValidation = true"
            @submit="submitSave"
          />
        </b-col>
        <!-- paid -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputPrice
            :labelProp="$t('payments.unknownPayments.paid')"
            :priceProp="paid"
            :stateProp="paidState"
            :invalidFeedbackProp="paidInvalidFeedback"
            @onUpdateParent="paid = $event"
            @onActivateValidation="paidValidation = $event"
            @submit="submitSave"
          ></InputPrice>
        </b-col>
        <!-- comment -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('payments.unknownPayments.comment')"
            :invalid-feedback="commentInvalidFeedback"
            :state="commentState"
          >
            <b-form-input
              v-model="comment"
              trim
              maxlength="256"
              :state="commentState"
              @blur="commentValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
import InputPrice from "@shared/views/Helpers/InputPrice";
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputTextFormatted, InputPrice },
  mixins: [userRights, error, price],
  props: {
    paymentReportIdProp: {
      type: Number,
      default: null
    },
    paymentGroupIdProp: {
      type: Number,
      default: null
    },
    initialValuesProp: {
      type: Object,
      default: function() {
        return {};
      }
    },
    unknownPaymentIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      unknownPaymentId: this.unknownPaymentIdProp,
      unknownPaymentData: null,
      // form variables
      hospitalisationNumber: this.initialValuesProp.hospitalisationNumber || "",
      lastName: this.initialValuesProp.lastName || "",
      firstName: this.initialValuesProp.firstName || "",
      paid: 0,
      comment: this.initialValuesProp.comment || "",
      // is validation active
      hospitalisationNumberValidation: false,
      lastNameValidation: false,
      firstNameValidation: false,
      paidValidation: false,
      commentValidation: false
    };
  },
  computed: {
    // form validation
    hospitalisationNumberState: function() {
      if (!this.hospitalisationNumberValidation) return null;
      return this.atLeastOneIsFilled ? null : false;
    },
    hospitalisationNumberInvalidFeedback: function() {
      return this.hospitalisationNumberState === false ? this.$t("payments.unknownPaymentEdit.validations.atLeastOneRequired") : "";
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      return this.atLeastOneIsFilled ? null : false;
    },
    lastNameInvalidFeedback: function() {
      return this.lastNameState === false ? this.$t("payments.unknownPaymentEdit.validations.atLeastOneRequired") : "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      return this.atLeastOneIsFilled ? null : false;
    },
    firstNameInvalidFeedback: function() {
      return this.firstNameState === false ? this.$t("payments.unknownPaymentEdit.validations.atLeastOneRequired") : "";
    },
    paidState: function() {
      if (!this.paidValidation) return null;
      if (!this.paid) return false;
      return typeof this.paid === "number" || /^\d+\.\d{2}$/g.test(this.paid) ? null : false;
    },
    paidInvalidFeedback: function() {
      if (this.paidState === null) return "";
      if (!this.paid) return this.$t("validationRules.required");
      return this.$t("validationRules.invalidPrice");
    },
    commentState: function() {
      return null;
    },
    commentInvalidFeedback: function() {
      return "";
    },
    // helpers
    atLeastOneIsFilled: function() {
      return this.hospitalisationNumber !== "" || this.lastName !== "" || this.firstName !== "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("payments.unknownPaymentEdit.pageTitle") : this.$t("payments.unknownPaymentNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("payments.unknownPaymentEdit.submitText") : this.$t("payments.unknownPaymentNew.submitText");
    }
  },
  async mounted() {
    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.hospitalisationNumberInput.focus();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get unknownPayment
          const resServices = await commonServices.get("unknownPayments", this.unknownPaymentId);
          this.unknownPaymentData = resServices.data;
          // import data
          this.hospitalisationNumber = resServices.data.hospitalisationNumber;
          this.lastName = resServices.data.lastName;
          this.firstName = resServices.data.firstName;
          this.paid = this.importFormatPrice(resServices.data.paid);
          this.comment = resServices.data.comment;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("accounting/payments/paymentReport/paymentGroupsEdit", { paymentReportIdProp: this.paymentReportIdProp });
    },

    // submit functions
    validateForm() {
      this.hospitalisationNumberValidation = true;
      this.lastNameValidation = true;
      this.firstNameValidation = true;
      this.paidValidation = true;
      this.commentValidation = true;

      return !(
        this.hospitalisationNumberState === false ||
        this.lastNameState === false ||
        this.firstNameState === false ||
        this.paidState === false ||
        this.commentState === false
      );
    },
    async saveUnknownPayment() {
      try {
        const form = {
          paymentGroupId: this.paymentGroupIdProp,
          hospitalisationNumber: this.hospitalisationNumber,
          firstName: this.firstName,
          lastName: this.lastName,
          paid: this.exportFormatPrice(this.paid),
          comment: this.comment
        };
        if (this.editMode) {
          const res = await commonServices.put("unknownPayments", form, this.unknownPaymentId);
          if (res.data === true) {
            this.saveSuccess();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.unknownPaymentEdit.notifications.editionErrorTitle"),
              message: this.$t("payments.unknownPaymentEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("unknownPayments", form);
          if (res.data.id) {
            this.saveSuccess();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.unknownPaymentNew.notifications.additionErrorTitle"),
              message: this.$t("payments.unknownPaymentNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveUnknownPayment();
    },
    saveSuccess() {
      // success message
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("payments.unknownPaymentEdit.notifications.editedTitle"),
          message: this.$t("payments.unknownPaymentEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("payments.unknownPaymentNew.notifications.addedTitle"),
          message: this.$t("payments.unknownPaymentNew.notifications.addedText")
        });
      }
      // navigate
      if (!this.moduleModeProp) {
        navigate("accounting/payments/paymentReport/paymentGroupsEdit", { paymentReportIdProp: this.paymentReportIdProp });
      } else {
        this.$emit("unknownPaymentSaved");
      }
    }
  }
};
</script>

<template>
  <div
    v-show="userRequestsCountWithoutFilters"
    class="kt-user-requests"
  >
    <!-- header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("users.userRequestsTableTitle") }}
      </h2>
      <!-- new request badge -->
      <div
        class="kt-badge kt-badge--blue ml-3"
      >
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $tc("users.userRequestsNewRequestBadge", userRequestsCountWithoutFilters, {count: userRequestsCountWithoutFilters}) }}</span></span>
      </div>
    </div>

    <!-- userRequest table -->
    <b-table
      ref="userRequestsTable"
      class="m-0 kt-table"
      :hover="isEnabled(['menu', 'administration', 'userRequests', 'import'])"
      responsive
      :fields="userRequestsFields"
      :items="userRequests"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      :selectable="isEnabled(['menu', 'administration', 'userRequests', 'import'])"
      select-mode="single"
      @row-selected="onRowSelected"
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <!-- <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search"> -->
      <!-- filter UserRequests FirstName
          <b-th
            class="kt-table__th-search"
            style="width: 28.5%"
          >
            <b-form-input
              v-model="filterUserRequestsFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterUserRequestsFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterUserRequestsFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th> -->
      <!-- filter UserRequests LastName
          <b-th
            class="kt-table__th-search"
            style="width: 28.5%"
          >
            <b-form-input
              v-model="filterUserRequestsLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterUserRequestsLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterUserRequestsLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th> -->
      <!-- filter UserRequests Email
          <b-th
            class="kt-table__th-search"
            style="width: 38.5%"
          >
            <b-form-input
              v-model="filterUserRequestsEmail"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterUserRequestsEmail !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterUserRequestsEmail')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th> -->
      <!-- actions
          <b-th
            class="kt-table__th-search"
            style="width: 39px"
          ></b-th> -->
      <!-- </b-tr>
      </template> -->
      <!-- loader -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
      <!-- table actions -->
      <template v-slot:cell(userRequestButtons)="data">
        <b-button
          v-show="isEnabled(['menu', 'administration', 'userRequests', 'delete'])"
          :ref="data.item['id'] + '_delete'"
          :key="data.item['id']"
          class="my-0"
          size="sm"
          :variant="$systemSettings.theme"
          pill
          @click="onDeleteUserRequest(data.item)"
        >
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>
    <!-- no result -->
    <h4
      v-show="!userRequestsCount && !loading && isSearchActive"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>
    <!-- pagination -->
    <div
      v-if="userRequestsCount > 50"
      class="d-flex justify-content-around"
    >
      <b-navbar-nav>
        <b-pagination
          v-model="page"
          class="mx-2 my-3"
          :class="'kt-pagination--' + $systemSettings.theme"
          :total-rows="userRequestsCount"
          per-page="50"
          @input="onPageInput"
        ></b-pagination>
      </b-navbar-nav>
    </div>

    <!-- ADD / EDIT MODAL -->
    <b-modal
      ref="userEditModal"
      hide-footer
      @shown="() => {
        $refs.userEditComponent.focusFirstElement()
      }"
      @hidden="onUserEditHidden"
    >
      <template #modal-title>
        <b-icon icon="person-fill"></b-icon>
        {{ $t("userEdit.filePageTitle") }}
      </template>

      <UserEdit
        ref="userEditComponent"
        :userIdProp="selectedUserResquestData ? selectedUserResquestData.id : null"
        :userDataProp="selectedUserResquestData"

        :editModeProp="true"
        :isActiveEditRightsProp="true"
        :isUserRequestProp="true"
        moduleModeProp
        @userAdded="onUserAdded($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import UserEdit from "@/views/Administration/Users/UserEdit.vue";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";

export default {
  components: {
    UserEdit
  },
  mixins: [userRights, pagination, error],
  data() {
    return {
      // general
      loading: false,
      userRequestsCount: 0,
      userRequestsCountWithoutFilters: 0,
      page: 1,
      // table fields
      userRequestsFields: [
        {
          thStyle: { width: "28.5%" },
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: true,
          label: this.$t("users.firstName")
        },
        {
          thStyle: { width: "28.5%" },
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: true,
          label: this.$t("users.lastName")
        },
        {
          thStyle: { width: "38.5%" },
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "email",
          sortable: true,
          label: this.$t("users.email")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "userRequestButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      sortBy: "",
      sortDesc: false,
      filterUserRequestsFirstName: "",
      filterUserRequestsLastName: "",
      filterUserRequestsEmail: "",
      // table items
      userRequests: [],
      // edit user data
      selectedUserResquestData: null
    };
  },
  computed: {
    isSearchActive() {
      return this.filterUserRequestsFirstName || this.filterUserRequestsLastName || this.filterUserRequestsEmail;
    }
  },
  async mounted() {
    await this.onPageInput();
  },
  methods: {
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },
    // search update Table
    async onPageInput() {
      try {
        this.loading = true;

        // users
        const filters = {
          firstName: this.filterUserRequestsFirstName,
          lastName: this.filterUserRequestsLastName,
          email: this.filterUserRequestsEmail
        };
        const resUserRequests = await commonServices.showAll(
          "userRequests",
          this.page,
          filters,
          {
            by: this.sortBy,
            desc: this.sortDesc
          }
        );
        if (!this.isSearchActive) this.userRequestsCountWithoutFilters = resUserRequests.data.count;
        this.userRequestsCount = resUserRequests.data.count;
        this.userRequests = resUserRequests.data.rows;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onPageInput();
      }, 450);
    },

    // EDIT functions
    async onRowSelected(row) {
      try {
        if (row && row.length > 0 && this.isEnabled(["menu", "administration", "userRequests", "import"])) {
          const res = await commonServices.get("userRequests", row[0].id);
          // setup props
          this.selectedUserResquestData = res.data;
          this.selectedUserResquestData.isActive = true;
          // show modal
          this.$refs.userEditModal.show();
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onUserAdded(userData) {
      // hide modal
      this.$refs.userEditModal.hide();
      // success alert
      this.$emit("userAdded", userData);
      // reset modal
      this.selectedUserResquestData = null;
      this.$refs.userRequestsTable.clearSelected();
      // reload table
      if (this.userRequestsCountWithoutFilters > 0) this.userRequestsCountWithoutFilters--;
      this.onPageInput();
    },
    // clear table selection after closing userEdit
    onUserEditHidden() {
      // hide modal
      this.$refs.userEditModal.hide();
      // reset modal
      this.selectedUserResquestData = null;
      this.$refs.userRequestsTable.clearSelected();
    },

    // DELETE
    async onDeleteUserRequest(row) {
      try {
        if (row && this.isEnabled(["menu", "administration", "userRequests", "delete"])) {
          const result = await this.$bvModal.msgBoxConfirm(this.$t("userRequests.deletion.text"), {
            title: this.$t("userRequests.deletion.title"),
            okVariant: "danger",
            okTitle: this.$t("userRequests.deletion.ok"),
            cancelTitle: this.$t("userRequests.deletion.cancel"),
            centered: true
          });
          if (result) {
            const res = await commonServices.delete("userRequests", row.id);
            if (res.data === true) {
              this.$emit("alert", "success", {
                title: this.$t("userRequests.deleted.title"),
                message: this.$t("userRequests.deleted.text")
              });
              if (this.userRequestsCountWithoutFilters > 0) this.userRequestsCountWithoutFilters--;
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("userRequests.deletionError.title"),
                message: this.$t("userRequests.deletionError.text")
              });
            }
            this.onFilterChange();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<template>
  <div class="kt-report-entry pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("reportEntry.pageTitle") }}
      </h2>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0 mt-1 text-secondary">
        {{ $t('reportEntry.pageSubtitle') }}
      </p>
    </div>

    <!-- access number input -->
    <b-form @submit.prevent="searchAccessNumber">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('reportEntry.accessNumber')"
            :state="accessNumberState"
            :invalid-feedback="accessNumberInvalidFeedback"
          >
            <InputAccessNumber
              ref="accessNumberInput"
              v-model="accessNumber"
              :stateProp="accessNumberState"
              :maxlengthProp="50"
              trimProp
              @updateValidation="accessNumberValidatation = $event"
              @submit="searchAccessNumber"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- divider -->
    <div class="w-100 border-top mt-2 mb-2"></div>

    <!-- choose from Ids Modal -->
    <b-modal
      id="fileChooseFromIdsModal"
      size="m"
      hide-header
      hide-footer
      @shown="() => {
        if (fileOptions && fileOptions.length > 0) {
          $refs.chooseFromIdsComponent.focusFirstElement();
        }
      }"
    >
      <FileChooseFromIds
        v-if="fileOptions && fileOptions.length > 0"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="fileOptions"
        @chosen="($event !== null) ? navigateIfValidatedFile($event) : hideChooseModal()"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- file not ready for diagnosis  -->
    <b-modal
      :id="'notReadyForDiagnosisModal-' + _uid"
      size="m"
      hide-footer
      hide-header
      @shown="() => {
        $refs.notReadyForDiagnosisButton.focus()
      }"
    >
      <!-- modal header -->
      <header
        class="modal-header"
        style="margin: -1rem -1rem 0 -1rem;"
      >
        <div>
          <!-- title -->
          <h5
            class="modal-title kt-page-header__title h4 mb-0"
            style="font-size: 18px;
            letter-spacing: -0.03px;
            line-height: 20px;
            color: inherit;"
          >
            {{ $t('files.notifications.notReadyForDiagnosisTitle') }}
          </h5>
          <!-- style="font-size: 22px; line-height: 24px;" -->
        </div>
        <!-- close -->
        <button
          ref="closeButton"
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('notReadyForDiagnosisModal-' + _uid)"
        >
          {{ $t('general.closeX') }}
        </button>
      </header>
      <!-- modal body -->
      <div class="pb-1 pt-3">
        <!-- <h4
          style="font-size: 18px;
          letter-spacing: -0.03px;
          margin-bottom: 12px;"
        >
          {{ $t('files.notifications.notReadyForDiagnosisTitle') }}
        </h4> -->

        <b-button
          ref="notReadyForDiagnosisButton"
          :variant="$systemSettings.theme"
          size="sm"
          @click="navigate('office/file/view', {
            fileIdProp: notReadyForDiagnosisFileId,
            cancelRouteProp: {name: $route.name, props: $route.params}
          })"
        >
          <b-icon icon="eye"></b-icon>
          {{ $t('files.notifications.notReadyForDiagnosisButton') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// helpers
import { navigate } from "@/services/UI/vueRouterServices";
import filesServices from "@/services/API/filesServices";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
// components
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
import InputAccessNumber from "@shared/views/Helpers/InputAccessNumber";

export default {
  components: { FileChooseFromIds, InputAccessNumber },
  mixins: [userRights, error],
  data() {
    return {
      accessNumber: "",
      accessNumberValidatation: false,
      fileOptions: [],
      notReadyForDiagnosisFileId: null
    };
  },
  computed: {
    // form validation
    accessNumberValidate: function() {
      if (!this.accessNumber) return "valid";
      for (const laboratory of this.$systemSettings.laboratories) {
        if (this.accessNumber.match(laboratory.accessNumberRegex)) {
          if (laboratory.disabled) {
            return "unallowedLaboratory";
          } else {
            return "valid";
          }
        }
      }
      return "invalid";
    },
    accessNumberState: function() {
      if (!this.accessNumber || !this.accessNumberValidatation) return null;
      if (this.accessNumberValidate === "valid") return null;
      return false;
    },
    accessNumberInvalidFeedback: function() {
      if (this.accessNumberState === null) return "";
      return this.accessNumberValidate === "unallowedLaboratory" ? this.$t("fileEntry.validations.unallowedLaboratory") : this.$t("fileEntry.validations.invalidAccessNumber");
    }
  },
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // auto focus
    this.$nextTick(() => {
      if (this.$refs.accessNumberInput) {
        this.$refs.accessNumberInput.focusFirstElement();
      }
    });
  },
  methods: {
    async searchAccessNumber() {
      try {
        this.accessNumberValidatation = true;
        if (this.accessNumber && this.accessNumberValidate === "valid") {
          const res = await filesServices.getAllByAccessNumber(this.accessNumber);
          if (res.data && res.data.length > 0) {
            if (res.data.length === 1) {
              this.navigateIfValidatedFile(res.data[0]);
            } else {
              this.fileOptions = res.data;
              this.$bvModal.show("fileChooseFromIdsModal");
            }
          } else if (res.data.error && res.data.error === "fileNotInLaboratory") {
            this.$emit("alert", "danger", {
              title: this.$t("reportEntry.notifications.fileNotInLaboratoryTitle"),
              message: this.$t("reportEntry.notifications.fileNotInLaboratoryText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("reportEntry.notifications.fileNotExistTitle"),
              message: this.$t("reportEntry.notifications.fileNotExistText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    navigateIfValidatedFile(file) {
      if (file.filingStatus && (file.filingStatus.name === "validatedForDiagnosis" || file.filingStatus.name === "validatedForAccounting")) {
        navigate("diagnosis/" + file.sector.name + "ReportEdit", {
          fileIdProp: file.id,
          cancelRouteProp: "diagnosis/reportEntry"
        });
        return true;
      } else if (file) {
        // this.$emit("alert", "danger", {
        //   title: this.$t("reportEntry.notifications.fileNotValidatedTitle"),
        //   message: this.$t("reportEntry.notifications.fileNotValidatedText")
        // });
        this.notReadyForDiagnosisFileId = file.id;
        this.$bvModal.show("notReadyForDiagnosisModal-" + this._uid);
        return true;
      }
    },
    hideChooseModal() {
      this.$bvModal.hide("fileChooseFromIdsModal");
      this.fileOptions = [];
    }
  }
};
</script>

<template>
  <div class="editPathogens">
    <b-form @submit.prevent="onSubmit">
      <!-- checkbox -->
      <b-form-group v-if="!isRadioProp">
        <b-form-checkbox-group
          v-model="values"
          :options="optionsProp"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
      <!-- radio -->
      <b-form-group v-if="isRadioProp">
        <b-form-radio-group
          v-model="values"
          :options="optionsProp"
          stacked
        ></b-form-radio-group>
      </b-form-group>

      <!-- submit -->
      <div class="text-right">
        <b-button
          v-if="values.length"
          class="mr-2"
          variant="outline-secondary"
          pill
          @click="values = isRadioProp ? '' : []"
        >
          {{ isRadioProp ? $t("general.clear") : $t("general.clearAll") }}
        </b-button>
        <b-button
          :variant="'outline-' + $systemSettings.theme"
          pill
          type="submit"
        >
          {{ $t("general.save") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    isRadioProp: {
      type: Boolean
    },
    saveEventNameProp: {
      type: String,
      default: ""
    },
    optionsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    editedValuesProp: {
      type: [Array, String],
      default: function() {
        return this.isRadioProp ? "" : [];
      },
      deep: true
    }
  },
  data() {
    return {
      values: null
    };
  },
  watch: {
    editedValuesProp: {
      handler: function(value) {
        if (this.values !== value) {
          if ((this.isRadioProp && typeof value === "string") || (!this.isRadioProp && typeof value !== "string")) {
            this.values = value;
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onSubmit() {
      this.$emit(this.saveEventNameProp, this.values);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-input',{ref:"textInput",attrs:{"value":_vm.value,"maxlength":_vm.maxlengthProp,"state":_vm.stateProp,"disabled":_vm.disabledProp,"trim":_vm.trimProp,"autocomplete":"off"},on:{"input":function ($event) {
    _vm.$emit('input', $event);
    _vm.$emit('updateValidation', false);
  },"change":function ($event) {
    _vm.formatted = _vm.formatValue($event);
    _vm.$emit('input', _vm.formatted);
    _vm.$emit('updateValidation', true);
  },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function () {
    _vm.formatted = _vm.formatValue(_vm.value);
    _vm.$emit('input', _vm.formatted);
    _vm.$emit('updateValidation', true);
    _vm.$emit('submit', true)
  }).apply(null, arguments)},"blur":function () {
    _vm.$emit('updateValidation', true);
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="kt-view-page kt-act-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("actView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'data', 'acts', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/act/edit', {actIdProp: actId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("actView.editAct") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        v-if="codeDisplay ||
          (description && descriptionDisplay) ||
          priceDisplay ||
          validityDateDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- isActive -->
        <div
          v-show="!isActive"
          class="kt-badge kt-badge--red kt-badge--lg"
        >
          <b-icon
            icon="trash"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
        </div>
        <!-- code -->
        <b-row v-if="codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("acts.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
        <!-- description -->
        <b-row v-if="description && descriptionDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("acts.description") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-2">
              {{ description }}
            </p>
          </b-col>
        </b-row>
        <!-- price -->
        <b-row v-if="priceDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("acts.price") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ isNaN(price) ? $t("general.emptyWithHyphen") : $n(price, "currency") }}
            </p>
          </b-col>
        </b-row>
        <!-- validityDate -->
        <b-row v-if="validityDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("acts.validityDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            {{ (startValidityDate ? $d(new Date(startValidityDate), "date") : "NC") + " - " + (endValidityDate ? $d(new Date(endValidityDate), "date") : "NC") }}
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import price from "@shared/services/UI/price";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [userRights, error, price, viewComponents],
  props: {
    actIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "code",
          "description",
          "price",
          "validityDate"
        ];
      }
    }
  },
  data() {
    return {
      // general
      actId: this.actIdProp,
      actData: null,
      // base data
      isActive: true,
      code: "",
      description: "",
      price: null,
      startValidityDate: "",
      endValidityDate: "",
      // dispayFields
      codeDisplay: false,
      descriptionDisplay: false,
      priceDisplay: false,
      validityDateDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get act
      const resServices = await commonServices.get("acts", this.actId);
      this.actData = resServices.data;
      // assign the data
      this.isActive = resServices.data.isActive;
      this.code = resServices.data.code;
      this.description = resServices.data.description;
      this.price = this.importFormatPrice(resServices.data.price);
      this.startValidityDate = resServices.data.startValidityDate;
      this.endValidityDate = resServices.data.endValidityDate;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("data/acts");
    }
  }
};
</script>

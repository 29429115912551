import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  getByEmail(email) {
    return axiosServices.axios.get("/users/getByEmail", { params: { email: email } });
  },
  // unused :
  getAllPathologists() {
    return axiosServices.axios.get("/users/getAllPathologists");
  },
  putUser(form) {
    return axiosServices.axios.put("/users/putUser", { form: form });
  }
};

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  async generate(filter, type) {
    return axiosServices.axios.get("/filesNotInvoiced/generate", {
      params: {
        filter: filter,
        type: type
      },
      responseType: "blob"
    });
  }
};

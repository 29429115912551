<template>
  <div
    class="kt-view-page kt-options-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("containerTypeView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'data', 'containerTypes', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/containerType/edit', {containerTypeIdProp: containerTypeId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("containerTypeView.editContainerType") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        v-if="laboratoryDisplay ||
          sectorDisplay ||
          nameDisplay ||
          techniqueDisplay ||
          mediumDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- isActive -->
        <div
          v-show="!isActive"
          class="kt-badge kt-badge--red kt-badge--lg"
        >
          <b-icon
            icon="trash"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
        </div>

        <!-- laboratory -->
        <b-row v-if="laboratory && laboratoryDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerTypes.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratory.name + (laboratory.isActive === true ? "" : " (" + this.$t("general.deletedLabel") + ")") }}
            </p>
          </b-col>
        </b-row>

        <!-- sector -->
        <b-row v-if="sectorDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerTypes.sector") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ sector ? ($t("sectors." + sector.name) + (!sector.isSubscribed ? " (" + $t("general.deletedLabel") + ")" : "")) : '' }}
            </p>
          </b-col>
        </b-row>
        <!-- name -->
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerTypes.name") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ name }}
            </p>
          </b-col>
        </b-row>
        <!-- technique -->
        <b-row v-if="technique && techniqueDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerTypes.technique") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ technique }}
            </p>
          </b-col>
        </b-row>
        <!-- medium -->
        <b-row v-if="medium && mediumDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerTypes.medium") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ medium }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [userRights, areaOfAuthority, error, viewComponents],
  props: {
    containerTypeIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "laboratory",
          "sector",
          "name",
          "technique",
          "medium"
        ];
      }
    }
  },
  data() {
    return {
      // general
      containerTypeId: this.containerTypeIdProp,
      containerTypeData: null,
      // base data
      isActive: true,
      laboratory: null,
      sector: null,
      name: "",
      technique: "",
      medium: "",
      // dispayFields
      laboratoryDisplay: false,
      sectorDisplay: false,
      nameDisplay: false,
      techniqueDisplay: false,
      mediumDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get containerType
      const resServices = await commonServices.get("containerTypes", this.containerTypeId);
      this.containerTypeData = resServices.data;
      // assign the data
      this.isActive = resServices.data.isActive;
      this.laboratory = resServices.data.laboratory;
      this.sector = resServices.data.sector;
      this.name = resServices.data.name;
      this.technique = resServices.data.technique;
      this.medium = resServices.data.medium;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("data/containerTypes");
    }
  }
};
</script>

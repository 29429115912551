import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  upload(instrumentId, file) {
    const form = new FormData();
    form.append("instrumentId", instrumentId);
    if (file) {
      form.append("file", file);
    }
    return axiosServices.axios.post("/hpvResults/upload",
      form,
      { headers: { "Content-Type": "multipart/form-data" } });
  }
};

<template>
  <div
    class="kt-input-search"
    :class="'kt-input-search--' + $systemSettings.theme"
  >
    <!-- search -->
    <b-form-group
      class="kt-input-search__input-container mb-0"
      :class="{'form-group--optional': !!optionalProp}"
      :label="labelProp"
      :invalid-feedback="invalidFeedbackProp"
      :state="stateProp"
    >
      <!-- input -->
      <b-form-input
        v-show="valueProp === null"
        ref="searchField"
        v-model="filterField"
        class="kt-input-search__input"
        autocomplete="off"
        :placeholder="placeholderProp"
        :state="stateProp"
        :disabled="disabledProp"
        @input="onInputSearchChange"
        @keydown.enter.prevent
        @keyup.enter.prevent="onInputSearchChange"
        @click.stop
      ></b-form-input>
      <!-- search icon -->
      <b-icon
        v-show="!loading && valueProp === null"
        class="kt-input-search__search-icon"
        icon="search"
      ></b-icon>
      <!-- loading spinner -->
      <b-spinner
        v-show="loading"
        class="kt-input-search__spinner"
        :class="'kt-spinner--' + $systemSettings.theme"
        small
      ></b-spinner>
      <!-- value -->
      <div
        v-show="valueProp !== null"
        class="kt-input-search__value"
      >
        <b-button
          ref="valueButton"
          :variant="$systemSettings.theme"
          block
          class="kt-input-search__value-btn"
          :disabled="disabledProp"
          @click="onClearField"
        >
          <span
            class="kt-input-search__value-btn-text"
            :class="{'kt-input-search__value-btn-text--nowrap': noWrapProp}"
          >{{ displayedValueProp }}</span>
          <b-icon
            v-show="!disabledProp"
            icon="x"
            class="kt-input-search__value-btn-icon"
          ></b-icon>
        </b-button>
      </div>
    </b-form-group>

    <!-- advanced search button (choose fileInvoice modal) -->
    <b-button
      v-show="chooseComponentPathProp && valueProp === null"
      v-b-modal="'chooseItemModal'"
      class="kt-btn-transparent kt-input-search__advanced-search"
      :variant="$systemSettings.theme"
    >
      {{ $t("general.advancedSearch") }}
    </b-button>

    <!-- search feedback -->
    <div v-show="valueProp === null && filterField.length > 0 && isActiveValidation && suggestions.length === 0">
      <div class="kt-input-search__suggestion kt-input-search__suggestion--empty">
        {{ filterField.length >= searchMinLengthProp ? $t('inputSearch.noResult') : $t('inputSearch.tooShort', {minLength: searchMinLengthProp}) }}
      </div>
    </div>

    <!-- suggestions -->
    <div
      v-show="valueProp === null && suggestions.length > 0"
      class="kt-input-search__suggestions-container"
    >
      <div
        class="kt-input-search__suggestions"
        @click.stop
      >
        <div v-show="suggestions.length">
          <div
            v-for="suggestion in suggestions"
            :key="suggestion[suggestionsKeyProp]"
          >
            <b-button
              squared
              block
              class="kt-input-search__suggestion"
              @click="onItemChosen(suggestion)"
            >
              {{ suggestionFormatProp(suggestion) }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- choose Item Modal -->
    <b-modal
      v-if="chooseComponentPathProp"
      id="chooseItemModal"
      hide-footer
      :title="$t('general.advancedSearch')"
      size="xl"
      @shown="() => {
        if (typeof $refs.chooseComponent.focusFirstElement === 'function') {
          $refs.chooseComponent.focusFirstElement();
        }
      }"
    >
      <component
        :is="chooseComponentName"
        ref="chooseComponent"
        :optionsProp="chooseOptionsProp"
        :initialFilterValuesProp="initialFilterValuesProp"
        @chosen="onItemChosen($event)"
      />
    </b-modal>
  </div>
</template>

<script>
// helpers
import error from "@shared/services/UI/error";
import autocompletion from "@shared/services/UI/autocompletion";

export default {
  mixins: [error, autocompletion],
  props: {
    initialSearchedFieldProp: {
      type: String,
      default: ""
    },
    valueProp: {
      type: Object,
      default: function() {
        return null;
      },
      deep: true
    },
    optionalProp: {
      type: Boolean
    },
    searchedTableProp: {
      type: String,
      default: ""
    },
    searchedFieldProp: {
      type: String,
      default: ""
    },
    searchedLaboratoriesProp: {
      type: Array,
      default: function() {
        return null;
      },
      deep: true
    },
    organisationTypeNamesProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    returnFieldsArrayProp: {
      type: Array,
      default: null
    },
    searchMinLengthProp: {
      type: Number,
      default: 2
    },
    suggestionsKeyProp: {
      type: String,
      default: "id"
    },
    displayedValueProp: {
      type: String,
      default: ""
    },
    // other options
    labelProp: {
      type: String,
      default: ""
    },
    placeholderProp: {
      type: String,
      default: ""
    },
    suggestionFormatProp: {
      type: Function,
      default: function(_suggestion) {
        return "";
      }
    },
    noWrapProp: {
      type: Boolean
    },
    // choose modal
    chooseComponentPathProp: {
      type: String,
      default: ""
    },
    chooseOptionsProp: {
      type: Object,
      default: function() {
        return {};
      },
      deep: true
    },
    initialFilterValuesProp: {
      type: Object,
      default: function() {
        return {};
      },
      deep: true
    },
    // validation and error
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    disabledProp: {
      type: Boolean
    }
  },
  data() {
    return {
      filterField: "",
      laboratoriesAllIds: [],
      suggestions: [],
      isActiveValidation: false,
      loading: false
    };
  },
  computed: {
    chooseComponentName() {
      let chooseComponentName = "";
      if (this.chooseComponentPathProp) {
        chooseComponentName = this.chooseComponentPathProp.split("/").slice(-1)[0];
        chooseComponentName = chooseComponentName.slice(0, chooseComponentName.length - 4);
      }
      return chooseComponentName;
    }
  },
  created() {
    // import chooseComponent
    if (this.chooseComponentPathProp) {
      this.$options.components[this.chooseComponentName] = () => import("@/" + this.chooseComponentPathProp);
    }
  },
  mounted() {
    // add click events
    this.$nextTick(() => this.addClickEvents());

    // import laboratoriesAllIds
    if (this.searchedTableProp !== "organisations" && this.searchedLaboratoriesProp === null && this.$systemSettings.application === "main") {
      this.importLaboratoriesAllIds();
    }

    // initialSearchedFieldProp
    if (this.initialSearchedFieldProp) {
      this.filterField = this.initialSearchedFieldProp;
      this.search();
    }
  },
  beforeDestroy() {
    // click events
    this.removeClickEvents();
  },
  methods: {
    focusFirstElement() {
      if (this.valueProp !== null) {
        this.$refs.valueButton.focus();
      } else {
        this.$refs.searchField.focus();
      }
    },

    // import laboratories IDs
    importLaboratoriesAllIds() {
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      this.laboratoriesAllIds = laboratories.map((laboratory) => { return laboratory.id; });
    },

    // click events
    addClickEvents() {
      // close the suggestions when leaving the input
      document.addEventListener("click", this.closeDropdownOnClick);
    },
    removeClickEvents() {
      // remove the click event on document
      document.removeEventListener("click", this.closeDropdownOnClick);
    },
    // close suggestion's dropdown on click
    closeDropdownOnClick() {
      if (!this.valueProp) {
        this.filterField = "";
        this.suggestions = [];
      }
    },

    // search (with delay)
    onInputSearchChange() {
      this.suggestions = [];
      this.delayedSearch();
    },
    delayedSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 450);
    },
    async search() {
      this.loading = true;
      if (this.searchedTableProp !== "organisations") {
        const filters = {
          string: this.filterField,
          laboratoryIds: null
        };
        if (this.$systemSettings.application === "main") {
          filters.laboratoryIds = this.searchedLaboratoriesProp !== null ? this.searchedLaboratoriesProp : this.laboratoriesAllIds;
        }
        await this.onFilterInput(
          this.searchedTableProp,
          this.searchedFieldProp,
          filters,
          this.searchMinLengthProp - 1,
          this.returnFieldsArrayProp
        );
      } else {
        await this.onFilterInputOrganisation(
          this.searchedTableProp,
          this.searchedFieldProp,
          {
            string: this.filterField,
            organisationTypeNames: this.organisationTypeNamesProp
          },
          this.searchMinLengthProp - 1,
          this.returnFieldsArrayProp
        );
      }
      this.loading = false;
      this.isActiveValidation = true;
    },
    async onItemChosen(item) {
      this.$bvModal.hide("chooseItemModal");
      this.filterField = "";
      this.suggestions = [];
      this.$emit("onUpdateParent", item);
      this.$nextTick(() => {
        this.$emit("onActivateValidation");
        this.focusFirstElement();
      });
      this.isActiveValidation = false;
    },

    // clear
    onClearField() {
      this.$emit("onUpdateParent", null);
      this.$emit("onDisableValidation");
      this.$nextTick(() => this.$refs.searchField.focus());
    }
  }
};
</script>

<style scoped src="./InputSearch.scss" lang="scss"></style>

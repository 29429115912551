<template>
  <div class="kt-choose-act">
    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="actFields"
      :items="acts"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onActSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="filterCodeInput"
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Description -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterDescription"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterDescription !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterDescription')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Price -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter StartValidityDate -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterStartValidityDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
          </b-th>
          <!-- filter EndValidityDate -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterEndValidityDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="!actsCount && !loading && isSearchActive"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("actChoose.notFound") }}
      </h4>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";

export default {
  mixins: [error, price],
  data() {
    return {
      // general
      loading: false,
      actsCount: 0,
      page: 1,
      // table fields
      actFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: true,
          label: this.$t("acts.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "description",
          sortable: true,
          label: this.$t("acts.description")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "price",
          sortable: true,
          label: this.$t("acts.price"),
          formatter: (value, _key, _item) => {
            return value ? this.$n(this.importFormatPrice(value), "currency") : this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "startValidityDate",
          sortable: true,
          label: this.$t("acts.startValidityDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "endValidityDate",
          sortable: true,
          label: this.$t("acts.endValidityDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        }
      ],
      // table filters
      filterCode: "",
      filterDescription: "",
      filterPrice: "",
      filterStartValidityDate: "",
      filterEndValidityDate: "",
      // table items
      acts: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterCode ||
        this.filterDescription ||
        this.filterPrice !== "" ||
        this.filterStartValidityDate ||
        this.filterEndValidityDate
      );
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.filterCodeInput.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchAndUpdateTable();
    },
    // update the table
    async searchAndUpdateTable() {
      try {
        // if no filter
        if (this.isSearchActive === false) {
          this.acts = [];
          return false;
        }

        // validation dates
        if (this.filterStartValidityDate !== "") {
          const filterStartValidityDate = new Date(this.filterStartValidityDate).getFullYear();
          if (filterStartValidityDate < 1900 || filterStartValidityDate > 2300) {
            return false;
          }
        }
        if (this.filterEndValidityDate !== "") {
          const filterEndValidityDate = new Date(this.filterEndValidityDate).getFullYear();
          if (filterEndValidityDate < 1900 || filterEndValidityDate > 3000) {
            return false;
          }
        }

        // get the table items and update
        this.loading = true;

        const res = await commonServices.getAll(
          "acts",
          {
            code: this.filterCode,
            description: this.filterDescription,
            startValidityDate: this.filterStartValidityDate,
            endValidityDate: this.filterEndValidityDate
          }
        );
        this.acts = res.data;
        this.actsCount = res.data.length;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchAndUpdateTable();
      }, 450);
    },
    // on select
    onActSelected(rows) {
      if (rows && rows.length === 1) {
        this.$emit("chosen", rows[0]);
      }
    }
  }
};
</script>

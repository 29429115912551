import socketIoClient from "socket.io-client";
import uniqueId from "lodash.uniqueid";

var newConversationCallbacks = [];
var privateMessageCallbacks = [];
var markAsReadCallbacks = [];
var typingCallbacks = [];
var loggedInCallbacks = [];

const onNewConversation = function() {
  for (const newConversationCallback of newConversationCallbacks) {
    newConversationCallback.callback();
  }
};

const onPrivateMessage = function(message) {
  for (const privateMessageCallback of privateMessageCallbacks) {
    privateMessageCallback.callback(message);
  }
};

const onMarkAsRead = function(message) {
  for (const markAsReadCallback of markAsReadCallbacks) {
    markAsReadCallback.callback(message);
  }
};

const onTyping = function(message) {
  for (const typingCallback of typingCallbacks) {
    typingCallback.callback(message);
  }
};

const onLoggedIn = function(user) {
  for (const loggedInCallback of loggedInCallbacks) {
    loggedInCallback.callback(user);
  }
};

let socket = null;

export default {
  initialize(url) {
    socket = socketIoClient.io(url);
    socket.on("newConversation", onNewConversation);
    socket.on("privateMessage", onPrivateMessage);
    socket.on("markAsRead", onMarkAsRead);
    socket.on("typing", onTyping);
    socket.on("loggedIn", onLoggedIn);
  },
  subscribe(type, callback) {
    const callbackUniqueId = Number(uniqueId());
    if (type === "newConversation") {
      newConversationCallbacks.push({ id: callbackUniqueId, callback: callback });
    } else if (type === "privateMessage") {
      privateMessageCallbacks.push({ id: callbackUniqueId, callback: callback });
    } else if (type === "markAsRead") {
      markAsReadCallbacks.push({ id: callbackUniqueId, callback: callback });
    } else if (type === "typing") {
      typingCallbacks.push({ id: callbackUniqueId, callback: callback });
    } else if (type === "loggedIn") {
      loggedInCallbacks.push({ id: callbackUniqueId, callback: callback });
    }
    return callbackUniqueId;
  },
  unsubscribe(type, id) {
    if (type === "newConversation") {
      newConversationCallbacks = newConversationCallbacks.filter((newConversationCallback) => { return newConversationCallback.id !== id; });
    } else if (type === "privateMessage") {
      privateMessageCallbacks = privateMessageCallbacks.filter((privateMessageCallback) => { return privateMessageCallback.id !== id; });
    } else if (type === "markAsRead") {
      markAsReadCallbacks = markAsReadCallbacks.filter((markAsReadCallback) => { return markAsReadCallback.id !== id; });
    } else if (type === "typing") {
      typingCallbacks = typingCallbacks.filter((typingCallback) => { return typingCallback.id !== id; });
    } else if (type === "loggedIn") {
      loggedInCallbacks = loggedInCallbacks.filter((loggedInCallback) => { return loggedInCallback.id !== id; });
    }
  },
  sendUserId(userId) {
    socket.emit("userId", { userId: userId });
  },
  sendMessage(message) {
    socket.emit("privateMessage", message);
  },
  sendMarkAsRead(message) {
    socket.emit("markAsRead", message);
  },
  sendTyping(message) {
    socket.emit("typing", message);
  }
};

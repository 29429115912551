<template>
  <div class="viewInvoiceReportsAdditionReport">
    <div class="mb-1"></div>

    <!-- invoicesCreated -->
    <h5 class="text-center pb-1">
      <span class="align-middle">{{ $t("invoiceReportAdditionReport.invoicesCreated") }}</span>
      <span
        class="kt-count-bubble"
        :class="reportProp.invoicesCreated.length === 0 ? 'kt-count-bubble--danger' : 'kt-count-bubble--success'"
      ><span class="kt-count-bubble__number">{{ reportProp.invoicesCreated.length }}</span></span>
    </h5>
    <b-table
      v-show="reportProp.invoicesCreated.length"
      class="kt-table mb-4"
      responsive
      :fields="fields"
      :items="reportProp.invoicesCreated"
    ></b-table>
    <div
      v-show="!reportProp.invoicesCreated.length"
      class="border-top mb-4"
    ></div>

    <!-- emptyInvoices -->
    <h5 class="text-center pb-1">
      <span class="align-middle">{{ $t("invoiceReportAdditionReport.emptyInvoices") }}</span>
      <span
        class="kt-count-bubble"
        :class="reportProp.emptyInvoices.length > 0 ? 'kt-count-bubble--success' : ''"
      ><span class="kt-count-bubble__number">{{ reportProp.emptyInvoices.length }}</span></span>
    </h5>
    <b-table
      v-show="reportProp.emptyInvoices.length"
      class="kt-table mb-4"
      responsive
      :fields="fields"
      :items="reportProp.emptyInvoices"
    ></b-table>
    <div
      v-show="!reportProp.emptyInvoices.length"
      class="border-top mb-4"
    ></div>

    <!-- invoicesNotCreated -->
    <h5 class="text-center pb-1">
      <span class="align-middle">{{ $t("invoiceReportAdditionReport.invoicesNotCreated") }}</span>
      <span
        class="kt-count-bubble"
        :class="reportProp.invoicesNotCreated.length > 0 ? 'kt-count-bubble--danger' : 'kt-count-bubble--success'"
      ><span class="kt-count-bubble__number">{{ reportProp.invoicesNotCreated.length }}</span></span>
    </h5>
    <b-table
      v-show="reportProp.invoicesNotCreated.length"
      class="kt-table mb-4"
      responsive
      :fields="fields"
      :items="reportProp.invoicesNotCreated"
    ></b-table>
    <div
      v-show="!reportProp.invoicesNotCreated.length"
      class="border-top mb-4"
    ></div>

    <!-- back button -->
    <div class="pt-2 mt-3">
      <b-button
        ref="backButton"
        variant="outline-secondary"
        @click="navigate('accounting/invoices/invoiceReports')"
      >
        {{ $t("invoiceReportAdditionReport.backButton") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  props: {
    reportProp: {
      type: Object,
      default: function() {
        return undefined;
      },
      deep: true
    }
  },
  data() {
    return {
      fields: [
        {
          tdClass: "kt-table__td py-2 text-15",
          thClass: "kt-table__th kt-table__th--sm w-25",
          key: "organisationCode",
          sortable: false,
          label: this.$t("invoiceReportAdditionReport.organisationCode")
        },
        {
          tdClass: "kt-table__td py-2 text-15",
          thClass: "kt-table__th kt-table__th--sm w-75",
          key: "organisationName",
          sortable: false,
          label: this.$t("invoiceReportAdditionReport.organisationName")
        }
      ]
    };
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;
  },
  methods: {
    focusFirstElement() {
      this.$refs.backButton.focus();
    }
  }
};
</script>

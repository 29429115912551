<template>
  <div class="systemSettings">
    <div class="d-flex justify-content-center">
      <!-- label -->
      <label
        class="m-2"
        style="text-align: center"
      >{{ $t("systemSettings.themeLabelText") }}</label>

      <!-- dropdown -->
      <b-dropdown
        class="m-2"
        style="text-align: center"
      >
        <template v-slot:button-content>
          {{ chosenTheme ? $t("systemSettings.themes." + chosenTheme) : "" }}
        </template>
        <b-dropdown-item-button
          v-for="currentTheme in availableThemes"
          :key="currentTheme"
          class="m-2"
          @click="onSelectTheme(currentTheme)"
        >
          {{ $t("systemSettings.themes." + currentTheme) }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import systemSettingsServices from "@shared/services/API/systemSettingsServices";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  mixins: [userRights, error],
  data: function() {
    return {
      availableThemes: [],
      chosenTheme: null
    };
  },
  async created() {
    try {
      const resAvailableThemes = await systemSettingsServices.get("availableThemes");
      this.availableThemes = resAvailableThemes.data.value;
      const resCurrentTheme = await systemSettingsServices.get("theme");
      this.$systemSettings.theme = resCurrentTheme.data.value;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  mounted() {
    this.chosenTheme = this.$systemSettings.theme;
  },
  methods: {
    async onSelectTheme(theme) {
      try {
        const res = await systemSettingsServices.put("theme", theme);
        if (res.data === true) {
          this.$systemSettings.theme = theme;
          this.chosenTheme = theme;
          this.updateBodyVariantClass();
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateBodyVariantClass() {
      const appBody = document.body;
      appBody.className = "";
      appBody.classList.add("kt-app-body--" + this.$systemSettings.theme);
    }
  }
};
</script>

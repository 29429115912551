export default {
  methods: {
    isInAreaOfAuthority(userLaboratory) {
      return userLaboratory.laboratory.disabled === false;
    },
    areInAreaOfAuthority(userLaboratories) {
      let returnValue = true;
      for (const userLaboratory of userLaboratories) {
        if (userLaboratory.laboratory.disabled === true) {
          returnValue = false;
          break;
        }
      }
      return returnValue;
    }
  }
};

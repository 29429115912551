<template>
  <div class="kt-containerType-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- containerType form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- laboratory -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            :labelProp="$t('containerTypes.laboratory')"
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="true"
            :initialValueProp="containerTypeData !== null ? containerTypeData.laboratoryId : null"
            @input="laboratoryId = $event"
          />
        </b-col>

        <!-- sector -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="sectorsOptions.length"
            :labelProp="$t('containerTypes.sector')"
            :optionsProp="sectorsOptions"
            valueFieldProp="id"
            textFieldProp="localisedName"

            :valueProp="sectorId"
            :stateProp="sectorState"
            :invalidFeedbackProp="sectorInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="true"
            :initialValueProp="containerTypeData !== null ? containerTypeData.sectorId : null"
            @input="sectorId = $event"
          />
        </b-col>
        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('containerTypes.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              ref="nameInput"
              v-model="name"
              trim
              maxlength="50"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- technique -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('containerTypes.technique')"
            :invalid-feedback="techniqueInvalidFeedback"
            :state="techniqueState"
          >
            <b-form-input
              v-model="technique"
              trim
              maxlength="256"
              :state="techniqueState"
              @blur="techniqueValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- medium -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('containerTypes.medium')"
            :invalid-feedback="mediumInvalidFeedback"
            :state="mediumState"
          >
            <b-form-input
              v-model="medium"
              trim
              maxlength="256"
              :state="mediumState"
              @blur="mediumValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { SmartSelectInput },
  mixins: [userRights, error],
  props: {
    containerTypeIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      containerTypeId: this.containerTypeIdProp,
      containerTypeData: null,
      // form variables
      laboratoryId: null,
      sectorId: null,
      name: "",
      technique: "",
      medium: "",
      // is validation active
      laboratoryIdValidation: false,
      sectorValidation: false,
      nameValidation: false,
      techniqueValidation: false,
      mediumValidation: false,
      // options
      sectorsOptions: []
    };
  },
  computed: {
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    // form validation
    sectorState: function() {
      if (!this.sectorValidation) return null;
      return this.sectorId ? null : false;
    },
    sectorInvalidFeedback: function() {
      return this.sectorState === false ? this.$t("validationRules.required") : "";
    },
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    techniqueState: function() {
      if (!this.techniqueValidation) return null;
      return this.technique && this.technique.length > 0 ? null : false;
    },
    techniqueInvalidFeedback: function() {
      return this.techniqueState === false ? this.$t("validationRules.required") : "";
    },
    mediumState: function() {
      if (!this.mediumValidation) return null;
      return this.medium && this.medium.length > 0 ? null : false;
    },
    mediumInvalidFeedback: function() {
      return this.mediumState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("containerTypeEdit.pageTitle") : this.$t("containerTypeNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("containerTypeEdit.submitText") : this.$t("containerTypeNew.submitText");
    }
  },
  async mounted() {
    // import data
    this.importSectors();
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.laboratoryInput) {
        this.$refs.laboratoryInput.focusFirstElement();
      }
    },

    // import data
    async importData() {
      try {
        if (this.editModeProp) {
          // get containerType
          const resServices = await commonServices.get("containerTypes", this.containerTypeId);
          this.containerTypeData = resServices.data;
          // import data
          this.laboratoryId = resServices.data.laboratoryId;
          this.sectorId = resServices.data.sectorId;
          this.name = resServices.data.name;
          this.technique = resServices.data.technique;
          this.medium = resServices.data.medium;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    importSectors() {
      const sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.sectorsOptions = sectorsOptions.map((sector) => {
        sector.isActive = sector.isSubscribed;
        sector.disabled = !sector.isSubscribed;
        return sector;
      });
    },
    // navigate to the entry page
    onGoBack() {
      navigate("data/containerTypes");
    },
    // submit
    onValidateContainerType() {
      this.laboratoryIdValidation = true;
      this.sectorValidation = true;
      this.nameValidation = true;
      this.techniqueValidation = true;
      this.mediumValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.sectorState === false ||
        this.nameState === false ||
        this.techniqueState === false ||
        this.mediumState === false
      );
    },
    async onSaveContainerType() {
      try {
        const form = {
          laboratoryId: this.laboratoryId,
          sectorId: this.sectorId,
          name: this.name,
          technique: this.technique,
          medium: this.medium
        };
        if (this.editMode) {
          const res = await commonServices.put("containerTypes", form, this.containerTypeId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("containerTypeEdit.notifications.editedTitle"),
              message: this.$t("containerTypeEdit.notifications.editedText")
            });
            navigate("data/containerTypes");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("containerTypeEdit.notifications.editionErrorTitle"),
              message: this.$t("containerTypeEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("containerTypes", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("containerTypeEdit.notifications.addedTitle"),
              message: this.$t("containerTypeEdit.notifications.addedText")
            });
            navigate("data/containerTypes");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("containerTypeEdit.notifications.additionErrorTitle"),
              message: this.$t("containerTypeEdit.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onSubmit() {
      if (this.onValidateContainerType() !== false) this.onSaveContainerType();
    }
  }
};
</script>

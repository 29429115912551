<template>
  <div
    class="kt-view-page kt-instrument-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("instrumentView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="instrumentData && isInAreaOfAuthority(instrumentData) && isEnabled(['menu', 'data', 'instruments', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/instrument/edit', {instrumentIdProp: instrumentId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("instrumentView.editInstrument") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION Laboratory-->
      <div
        v-if="laboratoryName && laboratoryNameDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- laboratoryName -->
        <b-row v-if="laboratoryName && laboratoryNameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratoryName }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION Description-->
      <div
        v-if="networkNameDisplay ||
          modelDisplay ||
          brandDisplay ||
          serialNumberDisplay ||
          techniqueDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("instrumentView.sectionTitleDescription") }}
        </h3>
        <!-- networkName -->
        <b-row v-if="networkNameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.networkName") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ networkName }}
            </p>
          </b-col>
        </b-row>
        <!-- model -->
        <b-row v-if="modelDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.model") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ model }}
            </p>
          </b-col>
        </b-row>
        <!-- brand -->
        <b-row v-if="brandDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.brand") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ brand }}
            </p>
          </b-col>
        </b-row>
        <!-- serialNumber -->
        <b-row v-if="serialNumberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.serialNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ serialNumber }}
            </p>
          </b-col>
        </b-row>
        <!-- technique -->
        <b-row v-if="techniqueDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.technique") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ technique }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION Maintenance -->
      <div
        v-if="lastInspectionDateDisplay ||
          lastInspectionExpiryDateDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("instrumentView.sectionTitleMaintenance") }}
        </h3>
        <!-- lastInspectionDate -->
        <b-row v-if="lastInspectionDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.lastInspectionDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ lastInspectionDate }}
            </p>
          </b-col>
        </b-row>
        <!-- lastInspectionExpiryDate -->
        <b-row v-if="lastInspectionExpiryDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("instruments.lastInspectionExpiryDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ lastInspectionExpiryDate }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [userRights, areaOfAuthority, error, viewComponents],
  props: {
    instrumentIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "laboratoryName",
          "networkName",
          "model",
          "brand",
          "serialNumber",
          "technique",
          "lastInspectionDate",
          "lastInspectionExpiryDate"
        ];
      }
    }
  },
  data() {
    return {
      // general
      instrumentId: this.instrumentIdProp,
      instrumentData: null,
      // base data
      laboratoryId: null,
      networkName: "",
      model: "",
      brand: "",
      serialNumber: "",
      technique: "",
      lastInspectionDate: "",
      lastInspectionExpiryDate: "",
      // extended data
      laboratoryName: "",
      // dispayFields
      laboratoryNameDisplay: false,
      networkNameDisplay: false,
      modelDisplay: false,
      brandDisplay: false,
      serialNumberDisplay: false,
      techniqueDisplay: false,
      lastInspectionDateDisplay: false,
      lastInspectionExpiryDateDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get instrument
      const resServices = await commonServices.get("instruments", this.instrumentId);
      this.instrumentData = resServices.data;
      // assign the data
      this.laboratoryId = resServices.data.laboratoryId;
      this.networkName = resServices.data.networkName;
      this.model = resServices.data.model;
      this.brand = resServices.data.brand;
      this.serialNumber = resServices.data.serialNumber;
      this.technique = resServices.data.technique;
      this.lastInspectionDate = resServices.data.lastInspectionDate ? this.$d(new Date(resServices.data.lastInspectionDate), "date") : "-";
      this.lastInspectionExpiryDate = resServices.data.lastInspectionExpiryDate ? this.$d(new Date(resServices.data.lastInspectionExpiryDate), "date") : "-";
      this.laboratoryName = resServices.data.laboratory.name + (resServices.data.laboratory.isActive === true ? "" : " (" + this.$t("general.deletedLabel") + ")");

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("data/instruments");
    }
  }
};
</script>

<template>
  <div class="kt-fileInvoiceTransformations mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("fileInvoiceTransformations.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'fileInvoiceTransformations', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('accounting/fileInvoiceTransformation/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("fileInvoiceTransformations.addFileInvoiceTransformationButton") }}
      </b-button>

      <!-- filter LaboratoryId -->
      <div v-if="laboratoriesOptions.length > 2">
        <div class="d-inline-block">
          <b-form-select
            v-model="filterLaboratoryId"
            class="mt-3"
            size="sm"
            :options="laboratoriesOptions"
            value-field="id"
            text-field="formattedName"
            @keydown.enter.exact="onPageInput"
            @input="searchHasChanged = true;"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="fileInvoiceTransformations"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="accessNumberFilter"
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter CreationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCreationDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter HospitalisationNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Patient -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 120px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-button
              ref="filterPatientChoose"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :style="filterPatient === null ? 'padding-left: 34px;' : ''"
              :variant="'outline-' + $systemSettings.theme"
              @click="$bvModal.show('patientChooseModal-' + _uid)"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            >
              <!-- search icon -->
              <b-icon
                v-show="filterPatient === null"
                class="kt-table__th-search-input__search-icon"
                icon="search"
              ></b-icon>
              <!-- value -->
              <span class="kt-table__th-search-input__text-overflow">{{ filterPatient ? filterPatient.firstName + ' ' + filterPatient.lastName : '' }}</span>
            </b-button>
            <b-button
              v-show="filterPatient"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterPatient = null;
                searchHasChanged = true;
                $refs.filterPatientChoose.focus()
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- OriginOrganisation -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="organisations"
              searchedFieldProp="name"
              :organisationTypeNamesProp="['clinics', 'healthCare', 'healthCenters', 'hospitals']"
              :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterOriginOrganisation"
              :displayedValueProp="filterOriginOrganisation ? filterOriginOrganisation.name + ' (' + filterOriginOrganisation.code + ')' + (filterOriginOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.name + ' (' + suggestion.code + ')' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
              }"
              noWrapProp

              @onUpdateParent="onOriginOrganisationChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter TargetOrganisation -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="organisations"
              searchedFieldProp="name"
              :organisationTypeNamesProp="['clinics', 'healthCare', 'healthCenters', 'hospitals']"
              :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterTargetOrganisation"
              :displayedValueProp="filterTargetOrganisation ? filterTargetOrganisation.name + ' (' + filterTargetOrganisation.code + ')' + (filterTargetOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.name + ' (' + suggestion.code + ')' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
              }"
              noWrapProp

              @onUpdateParent="onTargetOrganisationChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter Invoiced -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter TransformationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterTransformationDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 151px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap;"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.originFileInvoice.file.accessNumber }}
          <!-- complementary -->
          <span
            v-show="data.item.originFileInvoice.file.parentFileId"
            class="kt-complementary-icon"
          >
            <b-icon
              icon="back"
            ></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : patient -->
      <template v-slot:cell(patient)="data">
        <!-- patient name -->
        <PersonName
          :sexNameProp="data.item.originFileInvoice.file.patient.sex.name || ''"
          :firstNameProp="data.item.originFileInvoice.file.patient.firstName || ''"
          :lastNameProp="data.item.originFileInvoice.file.patient.lastName || ''"
          :maidenNameProp="data.item.originFileInvoice.file.patient.maidenName || ''"
        />
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(fileInvoiceTransformationButtons)="data">
        <div style="width: 39px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'files', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/file/view', {
              fileIdProp: data.item.originFileInvoice.file['id'],
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!fileInvoiceTransformationsCount && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import PersonName from "@shared/views/Helpers/PersonName";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearch, PatientChoose, PersonName },
  mixins: [userRights, error, price, areaOfAuthority, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      fileInvoiceTransformationsCount: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: false,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "creationDate",
          sortable: false,
          label: this.$t("files.creationDate"),
          formatter: (_value, _key, item) => {
            return item.originFileInvoice.file.creationDate ? this.$d(new Date(item.originFileInvoice.file.creationDate), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: false,
          label: this.$t("files.hospitalisationNumberShort"),
          formatter: (_value, _key, item) => {
            return item.originFileInvoice.file.hospitalisationNumber;
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "patient",
          sortable: false,
          label: this.$t("files.patient"),
          formatter: (_value, _key, item) => {
            return item.originFileInvoice.file.patient;
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "originOrganisation",
          sortable: false,
          label: this.$t("fileInvoiceTransformations.originOrganisationType"),
          formatter: (_value, _key, item) => {
            if (item.originOrganisation !== null) {
              return item.originOrganisation.name + (item.originOrganisation.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
            } else {
              return this.$t("fileInvoiceTransformations.patient");
            }
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "targetOrganisation",
          sortable: false,
          label: this.$t("fileInvoiceTransformations.targetOrganisationType"),
          formatter: (_value, _key, item) => {
            if (item.targetOrganisation !== null) {
              return item.targetOrganisation.name + (item.targetOrganisation.isActive === false ? "(" + this.$t("general.deletedLabel") + ")" : "");
            } else {
              return this.$t("fileInvoiceTransformations.patient");
            }
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiced",
          sortable: false,
          label: this.$t("fileInvoiceTransformations.invoiced"),
          formatter: (_value, _key, item) => {
            return this.$n((this.importFormatPrice(item.targetFileInvoice.invoiced) || 0), "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "transformationDate",
          sortable: false,
          label: this.$t("fileInvoiceTransformations.transformationDateShort"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "fileInvoiceTransformationButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterLaboratoryId: null,
      filterAccessNumber: "",
      filterHospitalisationNumber: "",
      filterPatient: null,
      filterCreationDate: "",
      filterOriginOrganisation: null,
      filterTargetOrganisation: null,
      filterTransformationDate: "",
      patientInitialFilterValues: {},
      // table items
      fileInvoiceTransformations: [],
      // options
      laboratoriesOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterLaboratoryId: "number",
        filterAccessNumber: "string",
        filterHospitalisationNumber: "string",
        filterPatient: {
          id: "number",
          firstName: "string",
          lastName: "string",
          maidenName: "string"
        },
        filterCreationDate: "string",
        filterOriginOrganisation: { id: "number", code: "string", name: "string" },
        filterTargetOrganisation: { id: "number", code: "string", name: "string" },
        filterTransformationDate: "string"
      }
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterLaboratoryId ||
        this.filterAccessNumber ||
        this.filterHospitalisationNumber ||
        this.filterPatient ||
        this.filterCreationDate ||
        this.filterOriginOrganisation ||
        this.filterTargetOrganisation ||
        this.filterTransformationDate
      );
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import laboratories
    this.importLaboratoriesOptions();

    // refresh table
    await this.onPageInput();

    // auto-focus
    if (this.$refs.addLineButton) {
      this.$refs.addLineButton.focus();
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      }
    },

    // import laboratories
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, formattedName: this.$tc("fileInvoiceTransformations.allLaboratories") });
      this.laboratoriesOptions = laboratories;
    },

    // patient
    async onPatientChosen(patient) {
      this.filterPatient = patient ? { id: patient.id, firstName: patient.firstName, lastName: patient.lastName, maidenName: patient.maidenName } : null;
      this.searchHasChanged = true;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
    },
    // originOrganisation
    onOriginOrganisationChosen(organisation) {
      this.filterOriginOrganisation = organisation ? { id: organisation.id, code: organisation.code, name: organisation.name } : null;
      this.searchHasChanged = true;
    },
    // targetOrganisation
    onTargetOrganisationChosen(organisation) {
      this.filterTargetOrganisation = organisation ? { id: organisation.id, code: organisation.code, name: organisation.name } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // search and update Table
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // dates validation
        if (this.filterCreationDate !== "") {
          const filterCreationDate = new Date(this.filterCreationDate).getFullYear();
          if (filterCreationDate < 1900 || filterCreationDate > 2300) return false;
        }
        if (this.filterTransformationDate !== "") {
          const filterTransformationDate = new Date(this.filterTransformationDate).getFullYear();
          if (filterTransformationDate < 1900 || filterTransformationDate > 2300) return false;
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          laboratoryId: this.filterLaboratoryId,
          accessNumber: this.filterAccessNumber,
          hospitalisationNumber: this.filterHospitalisationNumber,
          patientId: this.filterPatient ? this.filterPatient.id : null,
          creationDate: this.filterCreationDate,
          originOrganisationId: this.filterOriginOrganisation ? this.filterOriginOrganisation.id : null,
          targetOrganisationId: this.filterTargetOrganisation ? this.filterTargetOrganisation.id : null,
          transformationDate: this.filterTransformationDate
        };
        const res = await commonServices.getAll("fileInvoiceTransformations", filters);
        this.fileInvoiceTransformations = res.data.rows;
        this.fileInvoiceTransformationsCount = res.data.rows.length;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.fileInvoiceTransformationsCount) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.accessNumberFilter.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

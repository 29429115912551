<template>
  <div
    class="kt-view-page kt-sample-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("sampleView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="areInAreaOfAuthority(sampleLaboratories) && isEnabled(['menu', 'data', 'samples', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/sample/edit', {sampleIdProp: sampleId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("sampleView.editSample") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        v-if="!isActive ||
          sampleLaboratoriesDisplay ||
          sectorsDisplay ||
          nameDisplay ||
          (code && codeDisplay)"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- isActive -->
        <div
          v-show="!isActive"
          class="kt-badge kt-badge--red kt-badge--lg"
        >
          <b-icon
            icon="trash"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
        </div>

        <!-- sampleLaboratories -->
        <b-row v-if="sampleLaboratoriesDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("samples.laboratories") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratoriesJoinedNames }}
            </p>
          </b-col>
        </b-row>
        <!-- sectors -->
        <b-row v-if="sectorsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("pathologists.sectors") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div v-show="sectors.length === 0">
              {{ $t("general.emptyWithHyphen") }}
            </div>
            <ul
              v-show="sectors.length !== 0"
              class="mb-2"
            >
              <li
                v-for="sector in sectors"
                :key="sector.name"
              >
                {{ sector.localisedName }}
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- code -->
        <b-row v-if="code && codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("samples.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
        <!-- name -->
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("samples.name") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ name }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [userRights, areaOfAuthority, error, viewComponents],
  props: {
    sampleIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "sampleLaboratories",
          "sectors",
          "name",
          "code"
        ];
      }
    }
  },
  data() {
    return {
      // general
      sampleId: this.sampleIdProp,
      sampleData: null,
      // base data
      isActive: true,
      sectors: [],
      name: "",
      code: "",
      sampleLaboratories: [],
      // extended data
      laboratoriesJoinedNames: "",
      // dispayFields
      sampleLaboratoriesDisplay: false,
      sectorsDisplay: false,
      nameDisplay: false,
      codeDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get sample
      const resServices = await commonServices.get("samples", this.sampleId);
      this.sampleData = resServices.data;
      // assign the data
      this.isActive = resServices.data.isActive;
      this.sampleLaboratories = resServices.data.sampleLaboratories;
      this.laboratoriesJoinedNames = this.sampleLaboratories
        .map((sampleLaboratoryItem) => {
          if (sampleLaboratoryItem.laboratory.isActive === false) sampleLaboratoryItem.laboratory.name += " (" + this.$t("general.deletedLabel") + ")";
          return sampleLaboratoryItem.laboratory.name;
        })
        .join(", ");
      this.sectors = resServices.data.sampleSectors.map((sampleSector) => {
        return {
          name: sampleSector.sector.name,
          localisedName: this.$t("sectors." + sampleSector.sector.name) + (!sampleSector.sector.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : ""),
          isSubscribed: sampleSector.sector.isSubscribed
        };
      });
      this.name = resServices.data.name;
      this.code = resServices.data.code;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("data/samples");
    }
  }
};
</script>

export default {
  methods: {
    // format price from 6050 to "60.50"
    importFormatPrice(price) {
      var formattedPrice = "";
      price = String(price);
      if (price === "0") {
        formattedPrice = "0.00";
      } else if (price !== "" && price !== null) {
        if (price.length === 2) {
          price = "0" + price;
        } else if (price.length === 1) {
          price = "00" + price;
        }
        formattedPrice = price.slice(0, price.length - 2) + "." + price.slice(price.length - 2, price.length);
      }
      return Number(formattedPrice);
    },
    // format price from "60.50" or 60.50 to 6050
    exportFormatPrice(price) {
      var formattedPrice = "";
      if (typeof price === "number") {
        formattedPrice = price * 100;
      } else if (price !== "" && price !== null && /^\d+\.\d{2}$/g.test(price)) {
        formattedPrice = Number(price.replace(/\./g, ""));
      }

      return formattedPrice;
    }
  }
};

<template>
  <div class="kt-awaitingHpvResult-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("awaitingHpvUpload.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- upload form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- instrument -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputSearch
            searchedTableProp="instruments"
            searchedFieldProp="networkName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'networkName', 'model', 'brand', 'serialNumber', 'isActive']"
            suggestionsKeyProp="id"
            :labelProp="$t('awaitingHpvUpload.instrument')"
            :placeholderProp="$t('awaitingHpvUpload.instrumentPlaceholder')"
            :valueProp="instrument"
            :displayedValueProp="suggestionFormatInstruments(instrument)"
            :suggestionFormatProp="suggestionFormatInstruments"

            chooseComponentPathProp="views/Data/Instruments/InstrumentChoose.vue"
            :chooseOptionsProp="{}"
            :initialFilterValuesProp="{}"

            :stateProp="instrumentState"
            :invalidFeedbackProp="instrumentInvalidFeedback"
            @onUpdateParent="
              instrument = $event;
              instrumentId = instrument ? instrument.id : 0;
            "
            @onActivateValidation="instrumentValidation = true;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>

        <!-- divider -->
        <b-col cols="12"></b-col>

        <!-- HPV results csv file -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('awaitingHpvUpload.hpvResultsFile')"
            :invalid-feedback="hpvResultsFileInvalidFeedback"
            :state="hpvResultsFileState"
          >
            <b-form-file
              v-model="hpvResultsFile"
              accept=".csv"
              class=""
              :placeholder="$t('general.fileInputPlaceholder')"
              :drop-placeholder="$t('general.fileInputDropPlaceholder')"
              :state="hpvResultsFileState"
            ></b-form-file>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t("awaitingHpvUpload.submitText") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import hpvResultsServices from "@/services/API/hpvResultsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearch },
  mixins: [userRights, error],
  data() {
    return {
      // form variables
      instrument: null,
      instrumentId: null,
      hpvResultsFile: null,
      // is validation active
      instrumentValidation: false,
      hpvResultsFileValidation: false
    };
  },
  computed: {
    // form validation
    instrumentState: function() {
      if (!this.instrumentValidation) return null;
      return this.instrument && this.instrument.id ? null : false;
    },
    instrumentInvalidFeedback: function() {
      return this.instrumentState === false ? this.$t("validationRules.required") : "";
    },
    hpvResultsFileState: function() {
      if (!this.hpvResultsFileValidation) return null;
      return this.hpvResultsFile ? null : false;
    },
    hpvResultsFileInvalidFeedback: function() {
      return this.hpvResultsFileState === false ? this.$t("validationRules.required") : "";
    }
  },
  methods: {
    // format instrument suggestion
    suggestionFormatInstruments(suggestion) {
      let output = "";
      if (suggestion) {
        output = suggestion.networkName + " " + suggestion.model + " " + suggestion.brand + " (" + suggestion.serialNumber + ")";
        if (suggestion.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },
    // navigate back
    onGoBack() {
      navigate("diagnosis/awaitingHpvResults");
    },

    // submit functions
    validateForm() {
      this.instrumentValidation = true;
      this.hpvResultsFileValidation = true;

      return !(
        this.instrumentState === false ||
        this.hpvResultsFileState === false
      );
    },
    async saveInstrument() {
      try {
        const res = await hpvResultsServices.upload(this.instrumentId, this.hpvResultsFile);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("awaitingHpvUpload.notifications.uploadedTitle"),
            message: this.$t("awaitingHpvUpload.notifications.uploadedText")
          });
          navigate("diagnosis/awaitingHpvResults");
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("awaitingHpvUpload.notifications.uploadErrorTitle"),
            message: this.$t("awaitingHpvUpload.notifications.uploadErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveInstrument();
    }
  }
};
</script>

<template>
  <div class="kt-organisation-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- organisation form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- organisationTypeId -->
        <b-col cols="12">
          <b-form-group
            :label="$t('organisations.organisationType')"
            :invalid-feedback="organisationTypeIdInvalidFeedback"
            :state="organisationTypeIdState"
          >
            <b-form-radio-group
              ref="organisationTypeInput"
              v-model="organisationTypeId"
              :options="organisationTypeOptions"
              value-field="id"
              text-field="localisedNamePlural"
              :state="organisationTypeIdState"
              @input="onOrganisationTypeInput"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('organisations.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              v-model="name"
              trim
              maxlength="256"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('organisations.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- contact informations -->
        <b-col cols="12">
          <b-form-group
            :label="$t('organisations.contactInformations')"
            :invalid-feedback="contactInformationsInvalidFeedback"
            :state="contactInformationsState"
          >
            <OrganisationContactInformations
              :organisationContactInformationsProp="organisationContactInformations"
              :editModeProp="editModeProp"
              hideLabelProp
              @organisationContactInformationsUpdated="onUpdateOrganisationContactInformations($event)"
              @isDefaultEdited="onIsDefaultEdited($event.tempId, $event.value)"
            />
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col
          v-show="showInvoiceOptions"
          cols="12"
        >
          <div class="w-100 border-top mt-1 mb-3"></div>
        </b-col>
        <!-- for each sector : default invoicePatient -->
        <b-col
          v-show="showInvoiceOptions"
          cols="12"
        >
          <h6 style="max-width: 475px;">
            {{ $t('organisations.organisationSectors') }}
          </h6>
          <b-form-group
            v-for="sector in organisationSectors"
            v-show="sector.isSubscribed || sector.invoicePatient !== null"
            :key="sector.sectorId"
            :label="sector.localisedName"
            :invalid-feedback="organisationSectorsInvalidFeedback"
            :state="organisationSectorsState"
          >
            <b-form-radio-group
              v-model="sector.invoicePatient"
              :options="[
                {value: true, text: $t('organisations.invoicePatientTrue')},
                {value: false, text: $t('organisations.invoicePatientFalse')}
              ]"
              value-field="value"
              text-field="text"
              :state="organisationSectorsState"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col
          v-show="showInvoiceOptions"
          cols="12"
        >
          <div class="w-100 border-top mt-1 mb-3"></div>
        </b-col>
        <!-- invoiceFrequencyId -->
        <b-col
          v-show="showInvoiceOptions"
          cols="12"
        >
          <h6 style="max-width: 475px;">
            {{ $t('organisations.invoiceFrequency') }}
          </h6>
          <b-form-group
            :invalid-feedback="invoiceFrequencyIdInvalidFeedback"
            :state="invoiceFrequencyIdState"
          >
            <b-form-radio-group
              v-model="invoiceFrequencyId"
              :options="invoiceFrequencyOptions"
              value-field="id"
              text-field="localisedName"
              :state="invoiceFrequencyIdState"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col
          v-show="showInvoiceOptions"
          cols="12"
        >
          <div class="w-100 border-top mt-1 mb-3"></div>
        </b-col>
        <!-- sendElectronically -->
        <b-col
          v-show="showInvoiceOptions"
          cols="12"
          md="4"
        >
          <h6>
            {{ $t('organisations.sendElectronicallySection') }}
          </h6>
          <b-form-group class="mb-2">
            <b-form-checkbox
              v-model="sendElectronically"
              switch
            >
              {{ $t('organisations.sendElectronically') }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            :label="$t('organisations.registrationNumber')"
            :invalid-feedback="registrationNumberInvalidFeedback"
            :state="registrationNumberState"
          >
            <b-form-input
              v-model="registrationNumber"
              trim
              maxlength="50"
              :state="registrationNumberState"
              @blur="registrationNumberValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import OrganisationContactInformations from "@/views/Data/Organisations/OrganisationContactInformations";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: {
    OrganisationContactInformations
  },
  mixins: [userRights, error, phoneNumber, address],
  props: {
    organisationIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      organisationId: this.organisationIdProp,
      organisationData: null,
      // form variables
      organisationTypeId: null,
      code: "",
      name: "",
      organisationContactInformations: [],
      organisationSectors: [],
      invoiceFrequencyId: null,
      sendElectronically: false,
      registrationNumber: "",
      // is validation active
      organisationTypeIdValidation: false,
      codeValidation: false,
      nameValidation: false,
      contactInformationsValidation: false,
      organisationSectorsValidation: false,
      invoiceFrequencyIdValidation: false,
      registrationNumberValidation: false,
      // options
      showInvoiceOptions: true,
      invoiceFrequencyOptions: []
    };
  },
  computed: {
    // form validation
    organisationTypeIdState: function() {
      if (!this.organisationTypeIdValidation) return null;
      return this.organisationTypeId && this.organisationTypeId > 0 ? null : false;
    },
    organisationTypeIdInvalidFeedback: function() {
      return this.organisationTypeIdState === false ? this.$t("validationRules.required") : "";
    },
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    contactInformationsState: function() {
      // is validation active
      if (!this.contactInformationsValidation) return null;
      // at least one contact information
      if (this.organisationContactInformations.filter(item => item.status !== "deleted" && item.contactInformation.isActive).length === 0) return false;
      // at least one default
      if (!this.getContactInformationsDefaultId()) return false;
      // else
      return null;
    },
    contactInformationsInvalidFeedback: function() {
      // no error
      if (this.contactInformationsState === null) return "";

      // no contact information
      if (this.organisationContactInformations.filter(item => item.status !== "deleted" && item.contactInformation.isActive).length === 0) return this.$t("validationRules.required");

      // no default
      return this.$t("organisationEdit.validations.isDefaultRequired");
    },
    organisationSectorsState: function() {
      // validation active
      if (!this.organisationSectorsValidation) return null;
      // all invoicePatient fields required
      if (this.selectedOrganisationTypeName !== "healthCare") {
        for (const organisationSector of this.organisationSectors) {
          if (organisationSector.isSubscribed && typeof organisationSector.invoicePatient !== "boolean") {
            return false;
          }
        }
      }
      return null;
    },
    organisationSectorsInvalidFeedback: function() {
      // no error
      if (this.organisationSectorsState === null) return "";
      // else : required
      return this.$t("validationRules.required");
    },
    invoiceFrequencyIdState: function() {
      if (!this.invoiceFrequencyIdValidation) return null;
      return this.invoiceFrequencyId && this.invoiceFrequencyId > 0 ? null : false;
    },
    invoiceFrequencyIdInvalidFeedback: function() {
      return this.invoiceFrequencyIdState === false ? this.$t("validationRules.required") : "";
    },
    registrationNumberState: function() {
      if (!this.registrationNumberValidation) return null;
      if (!this.showInvoiceOptions || !this.sendElectronically) return null;
      return this.registrationNumber && this.registrationNumber.length > 0 ? null : false;
    },
    registrationNumberInvalidFeedback: function() {
      return this.registrationNumberState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("organisationEdit.pageTitle") : this.$t("organisationNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("organisationEdit.submitText") : this.$t("organisationNew.submitText");
    },
    selectedOrganisationTypeName: function() {
      let selectedOrganisationTypeName = "";
      for (const organisationType of this.organisationTypeOptions) {
        if (this.organisationTypeId === organisationType.id) {
          selectedOrganisationTypeName = organisationType.name;
          break;
        }
      }
      return selectedOrganisationTypeName;
    },

    // options
    organisationTypeOptions: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.organisationTypes || []));
      // sort order
      options.sort(function(a, _b) {
        return a.name === "healthCare" ? 1 : -1;
      });
      return options;
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import data
    await this.importInvoiceFrequencies();
    this.setupOrganisationSectors();
    await this.importData();

    // auto-focus
    this.focusFirstElement();

    if (!this.editModeProp) {
      // select by default first OrganisationType
      this.organisationTypeId = this.organisationTypeOptions[0].id;
      // cf. onOrganisationTypeInput() for default organisationSectors.invoicePatient
    }
  },
  methods: {
    focusFirstElement() {
      if (this.organisationTypeId && this.$refs.organisationTypeInput) {
        for (const child of this.$refs.organisationTypeInput.$children) {
          if (child.value === this.organisationTypeId) {
            child.focus();
            break;
          }
        }
      } else {
        this.$refs.organisationTypeInput.$children[0].focus();
      }
    },

    // import data
    async importInvoiceFrequencies() {
      try {
        const res = await commonServices.getAll("invoiceFrequencies", null);
        this.invoiceFrequencyOptions = res.data;
        for (const invoiceFrequency of this.invoiceFrequencyOptions) {
          invoiceFrequency.localisedName = this.$t("invoiceFrequencies." + invoiceFrequency.name);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    setupOrganisationSectors() {
      const organisationSectors = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.organisationSectors = organisationSectors.map((sector) => {
        let localisedName = this.$t("organisations.invoiceTargetSectorTitle", { sector: this.$t("sectors." + sector.name) });
        if (!sector.isSubscribed) localisedName += " (" + this.$t("general.deletedLabel") + ")";
        return {
          isSubscribed: sector.isSubscribed,
          sectorId: sector.id,
          name: sector.name,
          localisedName: localisedName,
          invoicePatient: null
        };
      });
    },
    async importData() {
      try {
        if (this.editModeProp) {
          // get organisation
          const resServices = await commonServices.get("organisations", this.organisationId);
          this.organisationData = JSON.parse(JSON.stringify(resServices.data));
          // import data
          this.organisationTypeId = resServices.data.organisationTypeId;
          this.code = resServices.data.code;
          this.name = resServices.data.name;
          this.organisationContactInformations = resServices.data.organisationContactInformations;
          for (const organisationContactInformation of this.organisationContactInformations) {
            organisationContactInformation.tempId = organisationContactInformation.id;
            organisationContactInformation.status = null;
          }
          this.$nextTick(() => {
            this.invoiceFrequencyId = resServices.data.invoiceFrequencyId;
            // assign each organisationSector value
            if (typeof resServices.data.organisationSectors === "object") {
              for (const organisationSector of resServices.data.organisationSectors) {
                for (const savedOrganisationSector of this.organisationSectors) {
                  if (organisationSector.sectorId === savedOrganisationSector.sectorId) {
                    savedOrganisationSector.invoicePatient = organisationSector.invoicePatient;
                    break;
                  }
                }
              }
            }
            this.sendElectronically = resServices.data.sendElectronically;
            this.registrationNumber = resServices.data.registrationNumber;
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // navigate to the list page
    onGoBack() {
      navigate("data/organisations");
    },
    onOrganisationTypeInput() {
      if (this.selectedOrganisationTypeName === "healthCare") {
        // select "manual invoicing"
        for (const invoiceFrequency of this.invoiceFrequencyOptions) {
          if (invoiceFrequency.name === "none") {
            this.invoiceFrequencyId = invoiceFrequency.id;
            break;
          }
        }
        // assign invoicePatient = null
        for (const organisationSector of this.organisationSectors) {
          if (organisationSector.isSubscribed || organisationSector.invoicePatient !== null) {
            organisationSector.invoicePatient = null;
          }
        }
        // hide options
        this.showInvoiceOptions = false;
      } else {
        // select by default manual invoicing
        this.invoiceFrequencyId = this.invoiceFrequencyOptions[0].id;
        // select by default patient invoicing
        for (const organisationSector of this.organisationSectors) {
          if (organisationSector.isSubscribed || organisationSector.invoicePatient !== null) {
            organisationSector.invoicePatient = true;
          }
        }
        // show options
        this.showInvoiceOptions = true;
      }
    },

    // CONTACT INFORMATIONS
    onUpdateOrganisationContactInformations(data) {
      this.organisationContactInformations = data;
      this.autoSelectDefaultContactInformation();
      this.setEditedStatus();
      this.contactInformationsValidation = true;
    },

    // helpers
    getContactInformationsDefaultId() {
      let defaultId = null;
      if (this.organisationContactInformations.length) {
        for (const organisationContactInformation of this.organisationContactInformations) {
          if (organisationContactInformation.isDefault) {
            defaultId = organisationContactInformation.tempId;
            break;
          }
        }
      }
      return defaultId;
    },
    onIsDefaultEdited(tempId, value) {
      // clear all isDefault
      if (value) {
        this.clearDefaultContactInformation();
      }
      // set isDefault
      for (const organisationContactInformation of this.organisationContactInformations) {
        if (organisationContactInformation.tempId === tempId) {
          // update the target value
          organisationContactInformation.isDefault = value;
          break;
        }
      }

      // handle default contact information
      this.autoSelectDefaultContactInformation();
      this.setEditedStatus();
      // activate the field validation
      this.contactInformationsValidation = true;
    },
    clearDefaultContactInformation() {
      if (this.organisationContactInformations.length) {
        for (const organisationContactInformation of this.organisationContactInformations) {
          organisationContactInformation.isDefault = false;
        }
      }
    },
    autoSelectDefaultContactInformation() {
      const contactInformationsActive = this.organisationContactInformations.filter(item => item.status !== "deleted" && item.contactInformation.isActive === true);
      const contactInformationLength = contactInformationsActive.length;
      if (
        contactInformationLength === 1 &&
        !this.getContactInformationsDefaultId()
      ) {
        contactInformationsActive[0].isDefault = true;
      }
    },
    setEditedStatus() {
      for (const organisationContactInformation of this.organisationContactInformations) {
        if (this.organisationData !== null && organisationContactInformation.status === null) {
          // for each original
          for (const originalItem of this.organisationData.organisationContactInformations) {
            // find the same
            if (organisationContactInformation.id === originalItem.id) {
              // if different isDefault
              if (organisationContactInformation.isDefault !== originalItem.isDefault) {
                // status = "edited"
                organisationContactInformation.status = "edited";
              }
              break;
            }
          }
        }
      }
    },

    // submit
    onSubmit() {
      if (this.onValidateOrganisation() !== false) this.onSaveOrganisation();
    },
    onValidateOrganisation() {
      this.organisationTypeIdValidation = true;
      this.codeValidation = true;
      this.nameValidation = true;
      this.contactInformationsValidation = true;
      this.organisationSectorsValidation = true;
      this.invoiceFrequencyIdValidation = true;
      this.registrationNumberValidation = true;

      return !(
        this.organisationTypeIdState === false ||
        this.codeState === false ||
        this.nameState === false ||
        this.contactInformationsState === false ||
        this.organisationSectorsState === false ||
        this.invoiceFrequencyIdState === false ||
        this.registrationNumberState === false
      );
    },
    async onSaveOrganisation() {
      try {
        // setup contactInformation
        const organisationContactInformations = this.organisationContactInformations.map((organisationContactInformation) => {
          return {
            id: organisationContactInformation.id,
            contactInformation: organisationContactInformation.contactInformation,
            isDefault: organisationContactInformation.isDefault,
            status: organisationContactInformation.status
          };
        });
        // setup sectors > invoicePatient

        let organisationSectorsExport = [];
        if (this.selectedOrganisationTypeName !== "healthCare") {
          organisationSectorsExport = this.organisationSectors.map((sector) => {
            return {
              sectorId: sector.sectorId,
              invoicePatient: sector.invoicePatient
            };
          });
          organisationSectorsExport = organisationSectorsExport.filter((organisationSector) => {
            return typeof organisationSector.invoicePatient === "boolean";
          });
        }
        // setup form
        const form = {
          organisationTypeId: this.organisationTypeId,
          name: this.name,
          code: this.code,
          organisationContactInformations: organisationContactInformations,
          invoiceFrequencyId: this.invoiceFrequencyId,
          organisationSectors: organisationSectorsExport,
          sendElectronically: this.showInvoiceOptions ? this.sendElectronically : false,
          registrationNumber: this.showInvoiceOptions ? this.registrationNumber : ""
        };

        if (this.editMode) {
          const res = await commonServices.put("organisations", form, this.organisationId);
          if (res.data === true) {
            // success message
            this.successSave();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("organisationEdit.notifications.editionErrorTitle"),
              message: this.$t("organisationEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("organisations", form);
          if (res.data.id) {
            // success message
            this.successSave();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("organisationNew.notifications.additionErrorTitle"),
              message: this.$t("organisationNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave() {
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("organisationEdit.notifications.editedTitle"),
          message: this.$t("organisationEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("organisationNew.notifications.addedTitle"),
          message: this.$t("organisationNew.notifications.addedText")
        });
      }
      navigate("data/organisations");
    }
  }
};
</script>

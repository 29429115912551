<template>
  <div class="kt-legalLog mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("legalLog.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- filter form -->
    <b-form @submit.prevent="onExport('pdf')">
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="null"
            @input="laboratoryId = $event;"
            @blur="laboratoryIdValidation = true;"
          />
        </b-col>

        <!-- date -->
        <b-col
          cols="12"
          md="4"
          lg="3"
        >
          <b-form-group
            :label="$t('legalLog.creationDate')"
            :invalid-feedback="creationDateInvalidFeedback"
            :state="creationDateState"
          >
            <b-form-input
              v-model="creationDate"
              type="date"
              :state="creationDateState"
              @blur="creationDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <!-- <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t('general.search') }}
          </b-button> -->
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t('general.downloadPdf') }}
          </b-button>
          <b-button
            class="my-2 ml-3"
            :variant="$systemSettings.theme"
            @click="onExport('xlsx')"
          >
            {{ $t('general.downloadXlsx') }}
          </b-button>
          <!-- on loading -->
          <b-spinner
            v-show="loading"
            class="align-middle ml-3"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
        </b-col>
      </b-row>
    </b-form>

    <!-- divider
    <div class="w-100 border-top mt-4 mb-4"></div> -->

    <!-- table
    <b-table
      class="mt-4 mb-0 kt-table"
      hover
      :fields="fields"
      :items="files"
      :busy="loading"
    > -->
    <!-- search header -->
    <!-- <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search"> -->
    <!-- filter AccessNumber
          <b-th class="kt-table__th-search">
          </b-th> -->
    <!-- filter CreationDate
          <b-th class="kt-table__th-search">
          </b-th> -->
    <!-- filter Patient
          <b-th class="kt-table__th-search">
          </b-th> -->
    <!-- filter Pathologist
          <b-th class="kt-table__th-search">
          </b-th> -->
    <!-- filter Contact
          <b-th class="kt-table__th-search">
          </b-th> -->
    <!-- OrganisationCode
          <b-th class="kt-table__th-search">
          </b-th> -->
    <!-- actions
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
          </b-th> -->
    <!-- </b-tr>
      </template> -->

    <!-- accessNumber
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.file.accessNumber }}
          <span
            v-show="data.item.file.parentFileId"
            class="kt-complementary-icon"
          >
            <b-icon icon="back"></b-icon>
          </span>
        </div>
      </template> -->

    <!-- patientName
      <template v-slot:cell(patientName)="data">
        <PersonName
          :sexNameProp="data.item.file.patient.sex ? data.item.patient.sex.name : ''"
          :firstNameProp="data.item.file.patient.firstName"
          :lastNameProp="data.item.file.patient.lastName"
          :maidenNameProp="data.item.file.patient.maidenName ? data.item.file.patient.maidenName : ''"
        />
      </template> -->

    <!-- fileContacts
      <template v-slot:cell(fileContacts)="data">
        <div
          v-for="fileContact in data.item.file.fileContacts"
          :key="fileContact.id"
        >
          {{ fileContact.contact.firstName + " " + fileContact.contact.lastName + (fileContact.isPrescriber ? " (" + $t("fileContacts.isPrescriber") + ")" : "") }}
        </div>
      </template> -->

    <!-- cell template : action buttons
      <template v-slot:cell(actionButtons)="data">
        <div :style="{'width': (files && files.length ? '39px' : '')}">
          <b-button
            class=""
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/file/view', {
              fileIdProp: data.item['id'],
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template> -->

    <!-- on loading
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table> -->

    <!-- no result
    <h4
      v-show="(!files || !files.length) && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4> -->

    <!-- maxReached
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3 mb-4"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div> -->
  </div>
</template>

<script>
// components
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// services
import legalLogServices from "@/services/API/legalLogServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { SmartSelectInput },
  mixins: [userRights, error, date],
  data() {
    return {
      // general
      loading: false,
      // searchHasChanged: false,
      // maxReached: false,
      // search filters
      laboratoryId: null,
      creationDate: "",
      // validation
      laboratoryIdValidation: false,
      creationDateValidation: false,
      // table fields
      // fields: [
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "pathologist",
      //     sortable: true,
      //     label: this.$t("files.pathologist"),
      //     formatter: (value, key, item) => {
      //       let output = "";
      //       if (item.file.pathologist) {
      //         output = item.file.pathologist.user.firstName + " " + item.file.pathologist.user.lastName + " (" + item.file.pathologist.code + ")";
      //         if (item.file.pathologist.user.isActive === false) {
      //           output += " (" + this.$t("general.deletedLabel") + ")";
      //         }
      //       }
      //       return output;
      //     },
      //     sortByFormatted: true
      //   },
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "accessNumber",
      //     sortable: true,
      //     label: this.$t("files.accessNumberShort"),
      //     formatter: (value, key, item) => {
      //       return item.file.accessNumber;
      //     },
      //     sortByFormatted: true
      //   },
      //   // {
      //   //   tdClass: "kt-table__td",
      //   //   thClass: "kt-table__th",
      //   //   key: "creationDate",
      //   //   sortable: true,
      //   //   label: this.$t("files.creationDate"),
      //   //   formatter: (value, key, item) => {
      //   //     return item.file.creationDate ? this.$d(new Date(item.file.creationDate), "date") : "";
      //   //   },
      //   //   sortByFormatted: true
      //   // },
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "patientName",
      //     sortable: true,
      //     label: this.$t("reports.sales.patientName"),
      //     formatter: (value, key, item) => {
      //       return item.file.patient.lastName;
      //     },
      //     sortByFormatted: true
      //   },
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "fileContacts",
      //     sortable: true,
      //     label: this.$t("files.prescriberAndRecipients"),
      //     formatter: (value, key, item) => {
      //       let output = "";
      //       if (item.file.fileContacts) {
      //         output = item.file.fileContacts.map((fileContact) => {
      //           return fileContact.contact.lastName;
      //         }).join(", ");
      //       }
      //       return output;
      //     },
      //     sortByFormatted: true
      //   },
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "organisationCode",
      //     sortable: true,
      //     label: this.$t("files.prescribingOrganisation"),
      //     formatter: (value, key, item) => {
      //       let output = "";
      //       if (item.prescribingOrganisation) {
      //         output = item.prescribingOrganisation.code;
      //         if (item.prescribingOrganisation.isActive === false) {
      //           output += " (" + this.$t("general.deletedLabel") + ")";
      //         }
      //       }
      //       return output;
      //     },
      //     sortByFormatted: true
      //   },
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "acts",
      //     sortable: true,
      //     label: this.$t("files.acts"),
      //     formatter: (value, key, item) => {
      //       let output = "";
      //       for (let i = 0; i < item.containerGroups.length; i++) {
      //         for (let j = 0; j < item.containerGroups[i].containerGroupTasks.length; j++) {
      //           for (let k = 0; k < item.containerGroups[i].containerGroupTasks[j].task.taskActs.length; k++) {
      //             if (output !== "") output += ", ";
      //             if (item.containerGroups[i].containerGroupTasks[j].task.taskActs[k].act.isActive === false) output += " (" + this.$t("general.deletedLabel") + ")";
      //           }
      //         }
      //       }
      //       return output;
      //     },
      //     sortByFormatted: true
      //   },
      //   {
      //     tdClass: "kt-table__td",
      //     thClass: "kt-table__th",
      //     key: "actionButtons",
      //     sortable: false,
      //     label: ""
      //   }
      // ],
      // table items
      files: []
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    creationDateState: function() {
      if (!this.creationDateValidation) return null;
      // required
      if (this.creationDate === "") return false;
      // invalid date
      const creationYear = Number(this.creationDate.slice(0, 4)) || 0;
      if (creationYear < 1890 || creationYear > 2300) return false;
      // today is not over yet
      if (this.creationDate === this.getIsoDate()) return false;
      // futur date
      if (!this.validatePastDate(this.creationDate)) return false;
      return this.creationDate ? null : false;
    },
    creationDateInvalidFeedback: function() {
      // no error
      if (this.creationDateState !== false) return "";
      // required
      if (this.creationDate === "") return this.$t("validationRules.required");
      // invalid date
      const creationYear = Number(this.creationDate.slice(0, 4)) || 0;
      if (creationYear < 1890 || creationYear > 2300) return this.$t("validationRules.invalidDate");
      // today is not over yet
      if (this.creationDate === this.getIsoDate()) return this.$t("legalLog.validations.todayNotOverYet");
      // futur date
      return this.$t("validationRules.required");
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // set default date to yesterday
    this.creationDate = this.getIsoDate(-1, 0, 0);
  },
  methods: {
    // submit functions
    async validateForm() {
      this.laboratoryIdValidation = true;
      this.creationDateValidation = true;
      await this.$nextTick();

      return (
        this.laboratoryIdState !== false &&
        this.creationDateState !== false
      );
    },
    // search and update table
    // async submitForm() {
    //   try {
    //     // validation
    //     if (await this.validateForm() === false) return false;

    //     this.loading = true;
    //     this.searchHasChanged = false;
    //     const filters = {
    //       laboratoryId: this.laboratoryId,
    //       creationDate: this.creationDate
    //     };
    //     const res = await legalLogServices.get(filters);
    //     // assign result
    //     // this.files = res.data.rows;
    //     // this.maxReached = res.data.maxReached;
    //     // TEMP
    //     this.files = res.data;

    //     this.loading = false;
    //   } catch (err) {
    //     this.handleErrors(err);
    //   }
    // },

    async onExport(type) {
      try {
        // validation
        if (await this.validateForm() === false) return false;

        this.loading = true;
        const filters = {
          laboratoryId: this.laboratoryId,
          creationDate: this.creationDate
        };
        const resFile = await legalLogServices.generate(filters, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          await fileSaver.saveAs(resFile.data, "legalLog." + type);
        }
        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

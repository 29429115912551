<template>
  <div
    class="kt-invoiced-files-group"
    :class="'kt-invoiced-files-group--' + $systemSettings.theme"
  >
    <!-- delete button -->
    <b-button
      variant="light"
      class="btn-icon kt-invoiced-files-group__delete-button"
      squared
      size="sm"
      @click="$emit('deleteInvoicedFilesGroup')"
    >
      <b-icon icon="x"></b-icon>
    </b-button>

    <!-- file search -->
    <b-form @submit.prevent="onAccessNumberChange">
      <!-- input search -->
      <b-form-group
        v-show="!invoicedFiles.length"
        class="kt-invoiced-files-group__input-container mb-0"
        :label="labelProp"
        :state="accessNumberError !== '' ? false : stateProp"
        :invalid-feedback="accessNumberError ? $t('invoicedFilesGroup.validations.' + accessNumberError) : invalidFeedbackProp"
      >
        <!-- search accessNumber -->
        <b-form-input
          ref="searchField"
          v-model="accessNumber"
          class="kt-invoiced-files-group__input"
          autocomplete="off"
          trim
          :state="stateProp"
          :disabled="disabledProp"
          @input="onAccessNumberChange"
          @blur="$emit('onActivateValidation', true)"
        ></b-form-input>
        <!-- search icon -->
        <b-icon
          class="kt-invoiced-files-group__search-icon"
          icon="search"
        ></b-icon>
        <!-- loading -->
        <b-spinner
          v-show="loading"
          class="ml-1"
          :class="'kt-spinner--' + $systemSettings.theme"
          small
        ></b-spinner>
        <!-- clear button
        <b-button
          v-show="accessNumber"
          variant="light"
          squared
          class="kt-invoiced-files-group__clear-value-btn"
          :disabled="disabledProp"
          @click="clearAccessNumber"
        >
          <b-icon
            icon="x"
            class="kt-input-search__value-btn-icon"
          ></b-icon>
        </b-button> -->
      </b-form-group>

      <!-- advanced search button (choose invoicedFile modal) -->
      <b-button
        v-show="!invoicedFiles.length"
        ref="advancedSearchButton"
        class="mt-1"
        size="sm"
        :variant="'outline-' + $systemSettings.theme"
        @click="showChooseModal"
      >
        {{ $t("general.advancedSearch") }}
      </b-button>
    </b-form>

    <!-- invoicedFiles -->
    <div class="kt-invoiced-files-group__invoicedFiles">
      <div
        v-for="(invoicedFile, fileIndex) in invoicedFiles"
        :key="invoicedFile.id"
        class="kt-invoiced-files-group__invoicedFile"
        :class="{'mb-2': fileIndex !== invoicedFiles.length - 1}"
      >
        <!-- left col - group info -->
        <div class="kt-invoiced-files-group__left-col">
          <div v-if="!invoicedFile.parentFileId && fileIndex === 0">
            <div class="kt-invoiced-files-group__file-title">
              {{ $t("invoicedFilesGroup.fileAccessNumber", {accessNumber: invoicedFile.accessNumber}) }}
            </div>
          </div>
          <div v-if="!invoicedFile.parentFileId">
            <div class="text-15">
              <div>
                {{ invoicedFile.creationDate ? $d(new Date(invoicedFile.creationDate), "date") : "" }}
              </div>
              <PersonName
                :sexNameProp="invoicedFile.patient.sex ? invoicedFile.patient.sex.name : ''"
                :firstNameProp="invoicedFile.patient.firstName"
                :lastNameProp="invoicedFile.patient.lastName"
                :maidenNameProp="invoicedFile.patient.maidenName || ''"
              />
              <div>
                {{ invoicedFile.patient.regNumber ? regNumberFormatter(invoicedFile.patient.regNumber) : '' }}
              </div>
              <div>
                {{ invoicedFile.patient.insureeRegNumber ? regNumberFormatter(invoicedFile.patient.insureeRegNumber) + ' ' + $t("patients.insureeTag") : '' }}
              </div>
              <b-link
                :variant="$systemSettings.theme"
                :href="invoicedFile.id ? '/office/file/view/' + invoicedFile.id : '#'"
                @click.prevent="navigate('office/file/view', {
                  fileIdProp: invoicedFile.id,
                  cancelRouteProp: {name: $route.name, props: $route.params}
                })"
              >
                {{ $t("general.view") }}
              </b-link>
            </div>
          </div>
          <div v-else>
            <div class="font-weight-semi-bold">
              {{ $t("invoicedFilesGroup.complementaryFile") }}
              <!-- complementary -->
              <span class="kt-complementary-icon">
                <b-icon icon="back"></b-icon>
              </span>
            </div>
            <div class="text-15">
              <div>
                {{ invoicedFile.creationDate ? $d(new Date(invoicedFile.creationDate), "date") : "" }}
              </div>
              <b-link
                :variant="$systemSettings.theme"
                :href="invoicedFile.id ? '/office/file/view/' + invoicedFile.id : '#'"
                @click.prevent="navigate('office/file/view', {
                  fileIdProp: invoicedFile.id,
                  cancelRouteProp: {name: $route.name, props: $route.params}
                })"
              >
                {{ $t("general.view") }}
              </b-link>
            </div>
          </div>
        </div>
        <!-- right col - paymentActs table -->
        <div class="kt-invoiced-files-group__right-col">
          <div
            v-for="(fileInvoice, fileInvoiceIndex) in invoicedFile.fileInvoices"
            :key="fileInvoice.id"
          >
            <div>
              <!-- transformed badge -->
              <div
                v-show="fileInvoice.fileInvoiceTransformations && fileInvoice.fileInvoiceTransformations.length"
                class="kt-badge kt-badge--yellow"
              >
                <b-icon
                  icon="arrow-left-right"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fullyTransformed') }}</span></span>
              </div>
              <!-- from transformation badge -->
              <div
                v-show="fileInvoice.targetFileInvoice_fileInvoiceTransformation"
                class="kt-badge kt-badge--blue"
              >
                <b-icon
                  icon="arrow-left-right"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fromTransformation') }}</span></span>
              </div>
            </div>
            <!-- invoiceActs table -->
            <div
              v-if="fileInvoice.invoiceActs && fileInvoice.invoiceActs.length"
              class="kt-flex-table"
            >
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('payments.patientPayments.patientPayments')"
              >
                <!-- table header -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--header"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="columnheader"
                  >
                    {{ $t('payments.patientPayments.actCode') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.patientPayments.invoiced') }}
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row kt-previsouly-paid-cell"
                    role="columnheader"
                  >
                    {{ $t('payments.patientPayments.previouslyPaid') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.patientPayments.paid') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.patientPayments.remaining') }}
                  </div>
                </div>
                <!-- table lines -->
                <div
                  v-for="(invoiceAct, invoiceActIndex) in fileInvoice.invoiceActs"
                  :key="invoiceAct.id"
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ invoiceAct.act.code }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ $n(invoiceAct.invoiced || 0, "currency") }}
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row kt-previsouly-paid-cell"
                    role="cell"
                  >
                    {{ invoiceAct.previouslyPaid ? $n(invoiceAct.previouslyPaid, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                  <div
                    class="kt-flex-table__row py-0"
                    role="cell"
                  >
                    <InputPrice
                      :ref="'paidInput_' + fileIndex + '_' + fileInvoiceIndex + '_' + invoiceActIndex"
                      class="kt-paymentLine-actPriceInput"
                      labelProp=""
                      :priceProp="invoiceAct.paymentAct.paid"
                      :stateProp="null"
                      invalidFeedbackProp=""
                      @submit="submitPaymentAct(fileIndex, fileInvoiceIndex, invoiceActIndex, $event)"
                      @onUpdateParent="editPaymentAct(fileIndex, fileInvoiceIndex, invoiceActIndex, $event)"
                    ></InputPrice>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'kt-remaining-cell': (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid) !== 0}"
                    role="cell"
                  >
                    {{ (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid) !== 0 ? $n((invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                </div>
                <!-- Last line (Totals) -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $t('actsTable.total') }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.invoiced, 0), "currency") }}</span>
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row kt-previsouly-paid-cell"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.previouslyPaid, "currency") }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.paymentAct.paid, 0), "currency") }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'kt-remaining-cell': fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0) !== 0}"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0) !== 0 ? $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0), "currency") : $t("general.emptyWithHyphen") }}</span>
                  </div>
                </div>
              </div>
              <!-- save line button
              <b-button
                :ref="'saveFilePaymentButton_' + fileInvoice.id"
                class="d-none"
                :variant="$systemSettings.theme"
                size="sm"
                @click="saveLine(fileIndex)"
              >
                {{ $t("payments.patientPayments.saveLineButton") }}
              </b-button> -->
            </div>
          </div>
        </div>
      </div>
      <!-- accounting history -->
      <div
        v-show="invoicedFiles.length"
        class="kt-invoiced-files-group__balance border-top"
      >
        <!-- all totals -->
        <div class="kt-recap">
          <!-- Total Invoiced -->
          <div
            class="kt-recap__item"
          >
            <div class="kt-recap__label">
              {{ $t('accountingHistory.accountingTotalsInvoiced') }}
            </div>
            <div class="kt-recap__amount">
              {{ $n(totalInvoiced, "currency") }}
            </div>
          </div>
          <!-- AwaitingForInvoice -->
          <div
            v-show="countAwaitingFileInvoices"
            class="kt-recap__item"
            style="margin-left: -2px;"
          >
            <div class="kt-recap__label">
              {{ $t('accountingHistory.accountingTotalsAwaitingForInvoice') }}
            </div>
            <div class="kt-recap__amount">
              {{ $n(totalAwaitingFileInvoices, "currency") }}
            </div>
          </div>
          <!-- Total Paid -->
          <div
            class="kt-recap__item"
            style="margin-left: -2px;"
          >
            <div class="kt-recap__label">
              {{ $t('accountingHistory.accountingTotalsPaid') }}
            </div>
            <div class="kt-recap__amount">
              {{ $n(totalPaid, "currency") }}
            </div>
          </div>
          <!-- AwaitingPayments -->
          <div
            v-show="awaitingPayments.length"
            class="kt-recap__item"
            style="margin-left: -2px;"
          >
            <div class="kt-recap__label">
              {{ $t('accountingHistory.accountingTotalsAwaitingPayments') }}
            </div>
            <div class="kt-recap__amount">
              {{ $n(totalAwaitingPayments, "currency") }}
            </div>
          </div>
          <!-- Compensation
          <div
            v-show="totalCompensations !== 0"
            class="kt-recap__item"
            :class="totalCompensations < 0 ? 'text-success' : 'text-danger'"
            style="margin-left: -2px;"
          >
            <div class="kt-recap__label">
              {{ $t('accountingHistory.' + (totalCompensations > 0 ? 'losses' : 'gains')) }}
            </div>
            <div class="kt-recap__amount">
              {{ $n(-totalCompensations, "currency") }}
            </div>
          </div> -->
          <!-- Total Balance -->
          <div
            class="kt-recap__item"
            :class="totalBalanceColor"
            style="margin-left: -2px;"
          >
            <div class="kt-recap__label">
              {{ $t('accountingHistory.accountingTotalsRest') }}
            </div>
            <div class="kt-recap__amount">
              {{ $n(filesBalance, "currency") }}
            </div>
          </div>
        </div>

        <!-- contextual : close file -->
        <div v-show="invoicedFiles.length && (compensationAmountAfterSaving !== 0 || totalCompensations) && !areAllFilesInvoiced">
          <div class="kt-badge kt-badge--lg kt-badge--light-gray">
            <b-icon
              icon="info-circle"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text">{{ $t('payments.awaitingPaymentEdit.hasUninvoicedFile') }}</span>
          </div>
        </div>
        <div v-show="invoicedFiles.length && (compensationAmountAfterSaving !== 0 || totalCompensations) && hasOtherAwaitingPayments">
          <div class="kt-badge kt-badge--lg kt-badge--light-gray">
            <b-icon
              icon="info-circle"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text">{{ $t('payments.awaitingPaymentEdit.hasAwaitingPayments') }}</span>
          </div>
        </div>
        <div v-show="invoicedFiles.length && compensationAmountAfterSaving !== 0 && areAllFilesInvoiced && !hasOtherAwaitingPayments && !totalCompensations">
          <b-button
            class="mt-1"
            style="margin-bottom: 6px;"
            variant="outline-secondary"
            @click="$emit('onCloseFileAfterSaving', closeFilesAfterSavingProp ? null : allFileIds)"
          >
            <!-- checkbox-display-only -->
            <div class="kt-checkbox-display-only-cont d-inline-block align-middle">
              <span
                class="kt-checkbox-display-only"
                :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (closeFilesAfterSavingProp ? ' kt-checkbox-display-only--checked' : '')"
              >
                <span class="kt-checkbox-display-only__cross"></span>
              </span>
            </div>
            <span
              class="font-weight-semi-bold align-middle"
              style="margin-left: 12px;"
            >
              <span class="align-middle">{{ $tc('payments.awaitingPaymentEdit.closeFileAfterSaving', (searchedFile ? searchedFile.children.length : 0), {complementaries: (searchedFile ? searchedFile.children.length : 0)}) + ' : ' }}</span>
              <span
                v-show="compensationAmountAfterSaving > 0"
                class="text-success align-middle"
              >{{ $t('payments.awaitingPaymentEdit.gainAfterSaving', {amount: $n(compensationAmountAfterSaving, "currency")}) }}</span>
              <span
                v-show="compensationAmountAfterSaving < 0"
                class="text-danger align-middle"
              >{{ $t('payments.awaitingPaymentEdit.lossAfterSaving', {amount: $n(compensationAmountAfterSaving, "currency")}) }}</span>
            </span>
          </b-button>
        </div>
        <div v-show="invoicedFiles.length && areAllFilesInvoiced && !hasOtherAwaitingPayments && totalCompensations">
          <div class="kt-badge kt-badge--lg kt-badge--light-gray">
            <b-icon
              icon="info-circle"
              class="kt-badge__icon"
            ></b-icon>
            <div class="kt-badge__text">
              <div>
                {{ $t('payments.awaitingPaymentEdit.hasCompensation') }}
                <span
                  v-show="totalCompensations < 0"
                  class="font-weight-semi-bold text-success"
                >{{ $t('payments.awaitingPaymentEdit.gainAfterSaving', {amount: $n(-totalCompensations, "currency")}) }}</span>
                <span
                  v-show="totalCompensations > 0"
                  class="font-weight-semi-bold text-danger"
                >{{ $t('payments.awaitingPaymentEdit.lossAfterSaving', {amount: $n(-totalCompensations, "currency")}) }}</span>
              </div>
              <div v-show="totalCompensations !== filesBalance">
                {{ $t('payments.awaitingPaymentEdit.updateCompensation') }}
                <span
                  v-show="filesBalance <= 0"
                  class="font-weight-semi-bold text-success"
                >{{ filesBalance === 0 ? $n(0, "currency") : $t('payments.awaitingPaymentEdit.gainAfterSaving', {amount: $n(-filesBalance, "currency")}) }}</span>
                <span
                  v-show="filesBalance > 0"
                  class="font-weight-semi-bold text-danger"
                >{{ $t('payments.awaitingPaymentEdit.lossAfterSaving', {amount: $n(-filesBalance, "currency")}) }}</span>
              </div>
              <div>
                <b-link
                  :variant="$systemSettings.theme"
                  @click.prevent="uncloseFiles"
                >
                  {{ $t("payments.awaitingPaymentEdit.deleteCompensation") }}
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- choose invoicedFile Modal -->
    <b-modal
      ref="chooseInvoicedFileModal"
      hide-footer
      :title="$t('invoicedFileChoose.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.invoicedFileChooseComponent.focusFirstElement()
      }"
      @hidden="focusLastInvoicedFile"
    >
      <InvoicedFileChoose
        ref="invoicedFileChooseComponent"
        :laboratoryIdProp="laboratoryIdProp"
        :initialFilterValuesProp="initialFilterValues"
        :relatedFilesProp="relatedFilesProp"
        @chosen="onInvoicedFileChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
import InputPrice from "@shared/views/Helpers/InputPrice";
// modals
import InvoicedFileChoose from "@/views/Accounting/Payments/InvoicedFiles/InvoicedFileChoose";
// services
import compensationsServices from "@/services/API/compensationsServices";
import filesServices from "@/services/API/filesServices";
// helpers
import paymentsHelpers from "@shared/services/UI/paymentsHelpers";
import accountingHistoryHelpers from "@shared/services/UI/accountingHistoryHelpers";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import regNumber from "@shared/services/UI/regNumber";
// others
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { PersonName, InputPrice, InvoicedFileChoose },
  mixins: [paymentsHelpers, accountingHistoryHelpers, error, price, regNumber],
  props: {
    patientPaymentIdProp: {
      type: Number,
      default: null
    },
    awaitingPaymentIdProp: {
      type: Number,
      default: null
    },
    laboratoryIdProp: {
      type: Number,
      default: null
    },
    invoicedFilesGroupProp: {
      type: Object,
      default: function() {
        return {
          accessNumber: "",
          invoicedFiles: []
        };
      },
      deep: true
    },
    relatedFilesProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    currentFilePaymentIdsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    initialSearchAccessNumberProp: {
      type: String,
      default: null
    },
    advancedSearchOnLoadProp: {
      type: Boolean
    },
    initialFilterValuesProp: {
      type: Object,
      default: function() {
        return {
          firstName: "",
          lastName: ""
        };
      },
      deep: true
    },
    unavailableAccessNumbersProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    closeFilesAfterSavingProp: {
      type: Array,
      default: function() {
        return null;
      }
    },

    labelProp: {
      type: String,
      default: ""
    },
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    disabledProp: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      accessNumber: this.invoicedFilesGroupProp.accessNumber,
      accessNumberError: "",
      invoicedFiles: this.invoicedFilesGroupProp.invoicedFiles,
      invoiced: 0,
      paid: 0,
      previouslyPaid: 0,
      // accounting history
      searchedFile: null,
      historyDataFiles: [],
      areAllFilesInvoiced: true,
      countAwaitingFileInvoices: 0,
      totalAwaitingFileInvoices: 0,
      totalInvoiced: 0,
      awaitingPayments: [],
      totalAwaitingPayments: 0,
      previousPayments: [],
      totalPreviouslyPaid: 0,
      totalCompensations: 0
    };
  },
  computed: {
    initialFilterValues: function() {
      return {
        accessNumber: this.accessNumber,
        firstName: this.initialFilterValuesProp.firstName,
        lastName: this.initialFilterValuesProp.lastName
      };
    },
    totalBalanceColor: function() {
      const totalBalance = this.filesBalance;
      if (totalBalance === 0) {
        return "text-success";
      } else if (totalBalance > 0) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
    compensationAmountAfterSaving: function() {
      if (!this.searchedFile) return 0;
      return -this.filesBalance;
    },
    hasOtherAwaitingPayments: function() {
      return this.awaitingPayments.filter(v => v.id !== this.awaitingPaymentIdProp).length > 0;
    },
    allFileIds: function() {
      if (!this.searchedFile) return [];
      return [
        this.searchedFile.id,
        ...this.searchedFile.children.map(v => v.id)
      ];
    },
    totalPaid: function() {
      return this.totalPreviouslyPaid + this.paid;
    },
    filesBalance: function() {
      if (!this.searchedFile) return 0;
      const balance = this.totalAwaitingFileInvoices + this.totalInvoiced - this.totalAwaitingPayments - this.totalPaid;
      return Math.round((balance + Number.EPSILON) * 100) / 100;
    }
  },
  watch: {
    "invoicedFilesGroupProp.invoicedFiles": {
      handler: async function(val) {
        this.invoicedFiles = JSON.parse(JSON.stringify(val));

        if (this.invoicedFiles.length) {
          if (!this.searchedFile || this.searchedFile.accessNumber !== this.invoicedFiles[0].accessNumber) {
            await this.importAccountingHistory();
          } else {
            this.updatePaidAmount();
          }
        } else {
          this.clearAccountingHistory();
        }
      },
      deep: true
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    if (this.initialSearchAccessNumberProp) {
      this.accessNumber = this.initialSearchAccessNumberProp;
      await this.searchInvoicedFiles();
    }
    // open advanced search on load
    if (this.advancedSearchOnLoadProp) {
      this.$refs.chooseInvoicedFileModal.show();
    }
  },
  methods: {
    async importAccountingHistory() {
      const res = await filesServices.getAllByAccessNumber(this.invoicedFiles[0].accessNumber);
      this.searchedFile = res.data.find(v => !v.parentFileId);
      this.searchedFile.children = res.data.filter(v => !!v.parentFileId) || [];
      this.areAllFilesInvoiced = !res.data.some(v => v.accountingStatus.name === "created" || v.accountingStatus.name === "canceled");

      // get accounting history
      const accountingHistory = await this.mixinGetAccountingHistory(
        this.searchedFile.id,
        null,
        this.searchedFile.children.map(v => v.id)
      );
      this.historyDataFiles = accountingHistory.historyDataFiles;
      this.countAwaitingFileInvoices = accountingHistory.awaitingFileInvoices.length;
      this.totalAwaitingFileInvoices = accountingHistory.totalAwaitingFileInvoices;
      this.totalInvoiced = accountingHistory.totalInvoiced;
      this.awaitingPayments = accountingHistory.awaitingPayments.filter(v => v.id !== this.awaitingPaymentIdProp);
      this.totalAwaitingPayments = this.awaitingPayments.reduce((total, current) => { return total + (current.paid || 0); }, 0);
      this.previousPayments = accountingHistory.payments.filter(v => v.paymentCategory !== "patientPayment" || v.id !== this.patientPaymentIdProp);
      this.totalPreviouslyPaid = this.previousPayments.reduce((total, current) => { return total + (current.currentFilesPaid || 0); }, 0);
      this.totalCompensations = accountingHistory.totalCompensations;
      this.$emit("onCloseFileAfterSaving", (this.totalCompensations ? this.allFileIds : null));
      this.updatePaidAmount();
    },
    clearAccountingHistory() {
      this.searchedFile = null;
      this.historyDataFiles = [];
      this.areAllFilesInvoiced = true;
      this.countAwaitingFileInvoices = 0;
      this.totalAwaitingFileInvoices = 0;
      this.totalInvoiced = 0;
      this.awaitingPayments = [];
      this.totalAwaitingPayments = 0;
      this.previousPayments = [];
      this.totalPreviouslyPaid = 0;
      this.totalCompensations = 0;
      this.paid = 0;
      this.$emit("onCloseFileAfterSaving", null);
    },

    async focusLastInvoicedFile() {
      await this.$nextTick();

      if (this.invoicedFiles && this.invoicedFiles.length > 0 && this.$refs["paidInput_" + (this.invoicedFiles.length - 1) + "_" + 0 + "_" + 0]) {
        this.$refs["paidInput_" + (this.invoicedFiles.length - 1) + "_" + 0 + "_" + 0][0].focus();
      } else {
        if (this.$refs.advancedSearchButton) this.$refs.advancedSearchButton.focus();
      }
    },

    showChooseModal() {
      this.$refs.chooseInvoicedFileModal.show();
    },
    clearAccessNumber() {
      this.accessNumber = "";
      this.$emit("updateGroupAccessNumber", "");
      this.$refs.searchField.$el.focus();
    },
    // search accessNumber (with a delay)
    onAccessNumberChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.searchInvoicedFiles();
      }, 450);
    },
    async onInvoicedFileChosen(invoicedFile) {
      if (invoicedFile && invoicedFile.accessNumber) {
        this.accessNumber = invoicedFile.accessNumber;
        await this.searchInvoicedFiles();
        // close modal
        this.$refs.chooseInvoicedFileModal.hide();
      }
    },

    async searchInvoicedFiles() {
      try {
        this.$emit("updateGroupInvoicedFiles", []);

        // empty accessNumber
        if (this.accessNumber === "") {
          this.$emit("updateGroupAccessNumber", "");
          this.$refs.searchField.$el.focus();
          return false;
        }
        // validate : unavailableAccessNumbers
        if (this.unavailableAccessNumbersProp.includes(this.accessNumber)) {
          this.accessNumberError = "alreadyAddedAccessNumber";
          this.$emit("updateGroupAccessNumber", "");
          this.$refs.searchField.$el.focus();
          return false;
        }

        this.loading = true;

        const accessNumber = this.accessNumber;
        this.accessNumberError = "";

        const res = await this.mixinSearchInvoicedFiles(this.laboratoryIdProp, accessNumber, this.currentFilePaymentIdsProp);

        if (res.invoicedFiles && res.invoicedFiles.length) {
          this.$emit("updateGroupAccessNumber", accessNumber);
          this.$emit("updateGroupInvoicedFiles", JSON.parse(JSON.stringify(res.invoicedFiles)));
          this.$emit("updateRelatedFiles", { accessNumber: accessNumber, relatedFiles: res.relatedFiles });
        } else {
          this.accessNumberError = "notFoundInLaboratory";
          this.$emit("updateGroupAccessNumber", this.accessNumber);
        }

        await this.focusLastInvoicedFile();

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // invoicedFiles > fileInvoice > invoiceActs > paymentAct save/edit
    editPaymentAct(invoicedFilePosition, fileInvoicePosition, invoiceActPosition, priceValue = null) {
      // update invoiceActs paid
      this.invoicedFiles[invoicedFilePosition].fileInvoices[fileInvoicePosition].invoiceActs[invoiceActPosition].paymentAct.paid = priceValue;
      // update total paid
      this.invoicedFiles[invoicedFilePosition].fileInvoices[fileInvoicePosition].paid = this.invoicedFiles[invoicedFilePosition].fileInvoices[fileInvoicePosition].invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.paymentAct.paid, 0);

      this.updatePaidAmount();

      this.$emit("updateGroupInvoicedFiles", this.invoicedFiles);
    },
    submitPaymentAct(invoicedFilePosition, fileInvoicePosition, invoiceActPosition, priceValue = null) {
      this.editPaymentAct(invoicedFilePosition, fileInvoicePosition, invoiceActPosition, priceValue);
      this.$emit("submit");
    },

    updatePaidAmount() {
      // update paid amount
      this.paid = this.invoicedFiles.reduce((total, invoicedFile) => {
        return total + invoicedFile.fileInvoices.reduce((total2, fileInvoice) => {
          return total2 + fileInvoice.paid;
        }, 0);
      }, 0);
    },
    async uncloseFiles() {
      try {
        // confirmation pop up
        const result = await this.$bvModal.msgBoxConfirm(this.$t("compensationDelete.text"), {
          title: this.$t("compensationDelete.title"),
          okVariant: "danger",
          okTitle: this.$t("compensationDelete.ok"),
          cancelTitle: this.$t("compensationDelete.cancel"),
          centered: true
        });
        // delete
        if (result) {
          for (const file of this.historyDataFiles) {
            if (file.compensationAmount === 0) continue;

            const res = await compensationsServices.deleteByFileId(file.id);
            if (res.data !== true) {
              this.$emit("alert", "danger", {
                title: this.$t("compensationDelete.notifications.deletedErrorTitle"),
                message: this.$t("compensationDelete.notifications.deletedErrorText")
              });
              await this.importAccountingHistory();
              return false;
            }
          }
          // success
          this.$emit("alert", "success", {
            title: this.$t("compensationDelete.notifications.deletedTitle"),
            message: this.$t("compensationDelete.notifications.deletedText")
          });
          this.historyDataFiles.map((v) => {
            v.compensationPayments = [];
            v.compensationAmount = 0;
          });
          this.totalCompensations = 0;
          this.$emit("onCloseFileAfterSaving", null);
          return true;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./InvoicedFilesGroup.scss" lang="scss"></style>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  showAll(object, page, filter, sort) {
    return axiosServices.axios.get("/" + object + "/showAll", {
      params:
      {
        page: page,
        filter: filter,
        sort: sort
      }
    });
  },
  get(object, id) {
    return axiosServices.axios.get("/" + object + `/get/${id}`);
  },
  getAll(object, filter) {
    return axiosServices.axios.get("/" + object + "/getAll", { params: { filter: filter } });
  },
  async getFile(object, id) {
    return axiosServices.axios.get("/" + object + `/getFile/${id}`, {
      responseType: "blob"
    });
  },
  async export(object, id, type) {
    return axiosServices.axios.get("/" + object + `/export/${id}`, {
      params: { type: type },
      responseType: "blob"
    });
  },
  post(object, form) {
    return axiosServices.axios.post("/" + object + "/post", { form: form });
  },
  put(object, form, id) {
    return axiosServices.axios.put("/" + object + `/put/${id}`, { form: form });
  },
  putFile(object, id, file) {
    const form = new FormData();
    if (file) {
      form.append("file", file);
    }
    return axiosServices.axios.put("/" + object + `/putFile/${id}`,
      form,
      { headers: { "Content-Type": "multipart/form-data" } });
  },
  delete(object, id) {
    return axiosServices.axios.delete("/" + object + `/delete/${id}`);
  }
};

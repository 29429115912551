// helpers
import price from "@shared/services/UI/price";

export default {
  mixins: [price],
  methods: {
    importFormatInvoicesList(invoices, type) {
      if (!invoices) return [];
      return invoices.map((invoice) => {
        return this.importFormatInvoice(invoice, type);
      });
    },
    importFormatInvoice(invoice, type) {
      if (type === "feeNote" || type === "careSheet") {
        invoice.file = null;
        for (let i = 0; i < invoice.fileInvoices.length; i++) {
          // setup file
          if (i === 0) invoice.file = JSON.parse(JSON.stringify(invoice.fileInvoices[0].file));
          invoice.fileInvoices[i].file = {
            id: invoice.fileInvoices[i].file.id,
            parentFileId: invoice.fileInvoices[i].file.parentFileId,
            creationDate: invoice.fileInvoices[i].file.creationDate,
            sector: invoice.fileInvoices[i].file.sector
          };
        }
        // setup file collectionDates
        const collectionDatesArr = [];
        if (invoice.file.containerGroups) {
          for (const containerGroup of invoice.file.containerGroups) {
            collectionDatesArr.push(containerGroup.collectionDate ? this.$d(new Date(containerGroup.collectionDate), "date") : "");
          }
        }
        invoice.file.collectionDates = collectionDatesArr;
      }

      invoice.invoiced = this.importFormatPrice(invoice.invoiced);
      // format fileInvoices
      let invoicePaid = 0;
      if (!invoice.fileInvoices) invoice.fileInvoices = [];
      for (const fileInvoice of invoice.fileInvoices) {
        fileInvoice.invoiced = this.importFormatPrice(fileInvoice.invoiced);
        // invoiceActs
        if (!fileInvoice.invoiceActs) fileInvoice.invoiceActs = [];
        for (const invoiceAct of fileInvoice.invoiceActs) {
          invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
          invoiceAct.act.price = this.importFormatPrice(invoiceAct.act.price);
          // paymentActs
          let invoiceActPaid = 0;
          invoiceAct.paymentActs = invoiceAct.paymentActs.map((paymentAct) => {
            paymentAct.paid = this.importFormatPrice(paymentAct.paid);
            paymentAct.filePayment.paid = this.importFormatPrice(paymentAct.filePayment.paid);
            invoiceActPaid += paymentAct.paid;
            invoicePaid += paymentAct.paid;
            return paymentAct;
          });
          invoiceAct.paid = invoiceActPaid;
          invoiceAct.rest = invoiceAct.invoiced - invoiceAct.paid;
        }
      }

      // invoice totals payments
      invoice.paid = invoicePaid;
      invoice.rest = invoice.invoiced - invoice.paid;

      if (invoice.fileInvoices) {
        // general formatting for all invoices
        invoice.transformationsAmount = 0;
        invoice.transformationsCount = 0;
        invoice.fromTransformationsAmount = 0;
        invoice.fromTransformationsCount = 0;
        for (const fileInvoice of invoice.fileInvoices) {
          // setup fileInvoice.invoiceActs ratio
          if (fileInvoice.invoiceActs) {
            for (const invoiceAct of fileInvoice.invoiceActs) {
              invoiceAct.ratio = invoiceAct.invoiced / invoiceAct.act.price;
            }
          }
          // setup fileInvoice > fileInvoiceTransformations (target)
          if (fileInvoice.fileInvoiceTransformations) {
            for (const fileInvoiceTransformation of fileInvoice.fileInvoiceTransformations) {
              // transformations count
              invoice.transformationsCount++;
              // invoiced format
              fileInvoiceTransformation.targetFileInvoice.invoiced = this.importFormatPrice(fileInvoiceTransformation.targetFileInvoice.invoiced);
              invoice.transformationsAmount += fileInvoiceTransformation.targetFileInvoice.invoiced;
              // organisation localised name
              if (fileInvoiceTransformation.targetOrganisation !== undefined) {
                if (fileInvoiceTransformation.targetOrganisation === null) {
                  fileInvoiceTransformation.invoiceTypeLocalisedName = this.$t("fileInvoiceTransformations.feeNote");
                } else if (fileInvoiceTransformation.targetOrganisation.organisationType.name === "healthCare") {
                  fileInvoiceTransformation.invoiceTypeLocalisedName = this.$t("fileInvoiceTransformations.careSheet");
                } else {
                  fileInvoiceTransformation.invoiceTypeLocalisedName = this.$t("fileInvoiceTransformations.invoiceReport");
                  // transformation not yet invoiced
                  if (fileInvoiceTransformation.targetFileInvoice.invoiceReportId === null) {
                    fileInvoiceTransformation.targetFileInvoice.transformationNotInvoicedYet = true;
                  }
                }
              }
            }
          }
          if (fileInvoice.targetFileInvoice_fileInvoiceTransformation) {
            // transformations count
            invoice.fromTransformationsCount++;
            // invoiced format
            fileInvoice.targetFileInvoice_fileInvoiceTransformation.originFileInvoice.invoiced = this.importFormatPrice(fileInvoice.targetFileInvoice_fileInvoiceTransformation.originFileInvoice.invoiced);
            invoice.fromTransformationsAmount += fileInvoice.targetFileInvoice_fileInvoiceTransformation.originFileInvoice.invoiced;
            // organisation localised name
            if (fileInvoice.targetFileInvoice_fileInvoiceTransformation.originOrganisation !== undefined) {
              if (fileInvoice.targetFileInvoice_fileInvoiceTransformation.originOrganisation === null) {
                fileInvoice.targetFileInvoice_fileInvoiceTransformation.invoiceTypeLocalisedName = this.$t("fileInvoiceTransformations.feeNote");
              } else if (fileInvoice.targetFileInvoice_fileInvoiceTransformation.originOrganisation.organisationType.name === "healthCare") {
                fileInvoice.targetFileInvoice_fileInvoiceTransformation.invoiceTypeLocalisedName = this.$t("fileInvoiceTransformations.careSheet");
              } else {
                fileInvoice.targetFileInvoice_fileInvoiceTransformation.invoiceTypeLocalisedName = this.$t("fileInvoiceTransformations.invoiceReport");
              }
            }
          }
        }
        // isOriginFullyTransformed
        if (invoice.transformationsCount && invoice.invoiced === invoice.transformationsAmount) {
          invoice.isOriginFullyTransformed = true;
        }
      }

      return invoice;
    }
  }
};

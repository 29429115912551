<template>
  <div
    v-show="isLoggedIn"
    class="kt-conversation"
  >
    <!-- header -->
    <div
      class="kt-conversation__header"
      :class="knownUser === null || loading ? 'kt-conversation__header--loading' : ''"
    >
      <h5
        v-if="knownUser !== null"
        class="kt-conversation__header-title"
        :class="knownUser.loggedIn ? 'kt-conversation__header-title--loggedIn' : ''"
      >
        {{ knownUser.id !== 2 ? knownUser.firstName + ' ' + knownUser.lastName : $t("messaging.main.supportUserName") }}
        <span v-show="knownUser.isActive === false">{{ "(" + $t("general.deletedLabel") + ")" }}</span>
        <span
          v-show="knownUser.unreadReceivedMessages"
          class="kt-count-bubble kt-count-bubble--danger"
        ><span class="kt-count-bubble__number">{{ knownUser.unreadReceivedMessages }}</span></span>
      </h5>
      <!-- remove button -->
      <button
        class="close kt-conversation__header-close"
        @click="$emit('onCloseConversation', true)"
      >
        <b-icon icon="x"></b-icon>
      </button>
    </div>

    <!-- body -->
    <div class="kt-conversation__body">
      <!-- messages list -->
      <div
        class="kt-conversation__messages"
        :class="{'kt-conversation__messages--loading': knownUser === null || loading}"
      >
        <!-- empty -->
        <div
          v-if="knownUser !== null && knownUser.messages && knownUser.messages.length === 0"
          class="kt-conversation__empty-container"
        >
          <div
            class="kt-conversation__empty-wrapper"
          >
            <b-icon
              class="kt-conversation__empty-icon mt-3"
              icon="chat-left-dots-fill"
            ></b-icon>
            <p class="kt-conversation__empty-text mb-0 mt-1">
              {{ $t("messaging.conversation.empty") + (knownUser.id !== 2 ? knownUser.firstName + ' ' + knownUser.lastName : $t("messaging.conversation.supportUserNameEmpty")) }}
            </p>
          </div>
        </div>
        <!-- messages -->
        <div
          ref="kt-conversation__messages-wrapper"
          class="kt-conversation__messages-wrapper"
        >
          <div
            v-if="knownUser !== null && knownUser.messages && knownUser.messages.length > 0"
            class="kt-conversation__messages-inner"
          >
            <div
              v-for="(message, index) in filteredMessages"
              :key="message.key"
              class="kt-conversation__message"
              :class="message.fromKnownUser ? 'kt-conversation__message--fromKnownUser' : 'kt-conversation__message--fromSelf'"
            >
              <div class="kt-conversation__message-top">
                {{ getMessageTop(index) }}
              </div>
              <div class="kt-conversation__message-wrapper">
                <div
                  v-if="!message.fromKnownUser"
                  class="kt-conversation__message-side mr-2"
                >
                  <span :class="!message.read ? 'text-secondary' : 'text-success'"><b-icon icon="check-circle-fill"></b-icon></span> {{ getMessageSide(index) }}
                </div>
                <div class="kt-conversation__message-content">
                  {{ message.content }}
                </div>
                <div
                  v-if="message.fromKnownUser"
                  class="kt-conversation__message-side ml-2"
                >
                  {{ message.timestampObject.toLocaleTimeString(navigatorLanguage, {hour: "2-digit", minute: "2-digit"}) }}
                </div>
              </div>
            </div>
            <!-- is typing -->
            <div
              v-show="knownUserIsTyping"
              class="kt-conversation__message kt-conversation__message--fromKnownUser kt-conversation__message--isTyping"
            >
              <div class="kt-conversation__message-wrapper">
                <div class="kt-conversation__message-content">
                  <div class="kt-icon-animation">
                    <div class="kt-icon-animation__stage">
                      <div class="kt-icon-animation__dot-falling"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- form -->
      <b-form
        v-if="knownUser !== null"
        class="kt-conversation__form"
        @submit.prevent="sendMessage"
      >
        <!-- textarea -->
        <TextareaAutosize
          ref="kt-conversation__textarea"
          v-model="messageInput"
          class="kt-conversation__textarea"
          autocomplete="off"
          :maxHeight="126"
          :submitFunction="sendMessage"
        ></TextareaAutosize>
        <!-- button -->
        <button
          class="btn kt-conversation__submit"
          :class="messageInput !== '' && messageInput !== null ? 'kt-conversation__submit--active' : ''"
          type="submit"
        >
          <b-icon icon="arrow-right-circle-fill"></b-icon>
        </button>
      </b-form>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import messagingServices from "@shared/services/API/messagingServices";
// components
import TextareaAutosize from "@shared/views/Helpers/TextareaAutosize";
// helpers
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";

export default {
  components: { TextareaAutosize },
  mixins: [error, date],
  props: {
    knownUserIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      debugMode: false,
      navigatorLanguage: null,
      knownUser: null,
      knownUserIsTyping: false,
      messageInput: "",
      loading: true,
      // subscriptions variables
      privateMessageSubscriptionId: null,
      markAsReadSubscriptionId: null,
      onTypingSubscriptionId: null,
      loggedInSubscriptionId: null
    };
  },
  computed: {
    isLoggedIn: function() {
      return Boolean(this.$userSettings.user);
    },
    filteredMessages: function() {
      const output = [];
      let item = {};
      if (this.knownUser === null || !this.knownUser.messages || this.knownUser.messages.length === 0) return output;

      let countUnreadMessages = this.knownUser.unreadSentMessages;
      for (let j = this.knownUser.messages.length - 1; j >= 0; j--) {
        item = this.knownUser.messages[j];
        // add "read" tag
        item.read = true;
        if (item.senderId === this.$userSettings.user.id) {
          item.read = countUnreadMessages <= 0;
          countUnreadMessages--;
        }
        // add "key" tag
        item.key = (item.read ? "0" : "1") + item.id;
        // push
        output.unshift(item);
      }
      return output;
    }
  },
  watch: {
    knownUser: {
      handler: function(val) {
        if (val !== null) {
          this.$nextTick(() => {
            this.$refs["kt-conversation__textarea"].$el.focus();
          });
        }
      },
      immediate: true
    },
    messageInput: {
      handler: function(newVal, oldVal) {
        if (newVal === "" && oldVal !== "") {
          this.sendTyping(false);
        } else {
          if (!this.timer) {
            this.refreshTyping();
          }
        }
      }
    }
  },
  created() {
    this.navigatorLanguage = navigator.language;
    this.privateMessageSubscriptionId = messagingServices.subscribe("privateMessage", this.onMessage);
    this.markAsReadSubscriptionId = messagingServices.subscribe("markAsRead", this.onMarkAsRead);
    this.typingSubscriptionId = messagingServices.subscribe("typing", this.onTyping);
    this.loggedInSubscriptionId = messagingServices.subscribe("loggedIn", this.onLoggedIn);
  },
  beforeDestroy() {
    messagingServices.unsubscribe("privateMessage", this.privateMessageSubscriptionId);
    messagingServices.unsubscribe("markAsRead", this.markAsReadSubscriptionId);
    messagingServices.unsubscribe("typing", this.typingSubscriptionId);
    messagingServices.unsubscribe("loggedIn", this.loggedInSubscriptionId);
  },
  async mounted() {
    await this.importKnownUser();
    this.messagesScroll("bottom");
  },
  methods: {
    getMessageTop(index) {
      if (index === 0) {
        // first message : always display the date
        return this.getFormattedAlphabeticalDateTimeFromTimestamp(this.knownUser.messages[index].timestampObject, this.$systemSettings.locale);
      } else if (
        // others : only when the date is changing
        this.knownUser.messages[index].timestampObject.getDate() !== this.knownUser.messages[index - 1].timestampObject.getDate() ||
        this.knownUser.messages[index].timestampObject.getMonth() !== this.knownUser.messages[index - 1].timestampObject.getMonth() ||
        this.knownUser.messages[index].timestampObject.getFullYear() !== this.knownUser.messages[index - 1].timestampObject.getFullYear()
      ) {
        return this.getFormattedAlphabeticalDateTimeFromTimestamp(this.knownUser.messages[index].timestampObject, this.$systemSettings.locale);
      } else {
        // others : only when the date is changing
        return "";
      }
    },
    getMessageSide(index) {
      if (index === 0) {
        return this.knownUser.messages[index].timestampObject.toLocaleTimeString(this.navigatorLanguage, { hour: "2-digit", minute: "2-digit" });
      } else if (
        this.knownUser.messages[index].timestampObject.getMinutes() !== this.knownUser.messages[index - 1].timestampObject.getMinutes() ||
        this.knownUser.messages[index].timestampObject.getHours() !== this.knownUser.messages[index - 1].timestampObject.getHours() ||
        this.knownUser.messages[index].timestampObject.getDate() !== this.knownUser.messages[index - 1].timestampObject.getDate() ||
        this.knownUser.messages[index].timestampObject.getMonth() !== this.knownUser.messages[index - 1].timestampObject.getMonth() ||
        this.knownUser.messages[index].timestampObject.getFullYear() !== this.knownUser.messages[index - 1].timestampObject.getFullYear()
      ) {
        return this.knownUser.messages[index].timestampObject.toLocaleTimeString(this.navigatorLanguage, { hour: "2-digit", minute: "2-digit" });
      } else {
        return "";
      }
    },
    messagesScroll(position) {
      const height = this.$refs["kt-conversation__messages-wrapper"].scrollHeight;
      if (position === "top") {
        this.$refs["kt-conversation__messages-wrapper"].scrollTop = 0;
      } else if (position === "bottom") {
        this.$refs["kt-conversation__messages-wrapper"].scrollTop = height;
      }
    },
    async importKnownUser() {
      try {
        this.loading = true;
        const res = await commonServices.get("knownUsers", this.knownUserIdProp);
        if (res.data.id) {
          // create date object
          if (res.data.messages.length > 0) {
            for (const message of res.data.messages) {
              message.timestampObject = new Date(message.timestamp);
            }
          }
          // import knownUser
          this.knownUser = res.data;
          this.knownUserIsTyping = res.data.isTyping || false;
          // mark as read
          this.sendMarkAsRead();

          this.loading = false;
        } else {
          // error
          this.onAlert("warning", {
            title: this.$t("messaging.conversation.importKnownUserFailureTitle"),
            stack: this.$t("messaging.conversation.importKnownUserFailureText")
          });
          this.loading = false;
        }
      } catch (err) {
        this.onAlert("warning", {
          title: this.$t(err.response.data.title),
          stack: this.$t(err.response.data.stack)
        });
        this.loading = false;
      }
    },

    sendMessage() {
      if (this.debugMode) console.log("CONVERSATION sendMessage");
      if (this.messageInput === "") return false;
      messagingServices.sendMessage({
        userId: this.$userSettings.user.id,
        recipientId: this.knownUser.id,
        message: this.messageInput
      });
      this.messageInput = "";
      return true;
    },
    sendMarkAsRead() {
      if (this.debugMode) console.log("CONVERSATION sendMarkAsRead");
      messagingServices.sendMarkAsRead({
        userId: this.$userSettings.user.id,
        recipientId: this.knownUser.id
      });
      return true;
    },
    refreshTyping() {
      if (this.messageInput !== "") {
        this.sendTyping(true);
        this.timer = setTimeout(() => {
          this.refreshTyping();
        }, 9000);
      } else {
        this.timer = null;
      }
    },
    sendTyping(value) {
      if (this.debugMode) console.log("CONVERSATION sendTyping", value);
      messagingServices.sendTyping({
        userId: this.$userSettings.user.id,
        recipientId: this.knownUser.id,
        isTyping: value
      });
      return true;
    },

    onMessage(message) {
      if (this.debugMode) console.log("CONVERSATION received onMessage: ", message);
      // setup scroll position
      const isScrolledToBottom = Math.abs(this.$refs["kt-conversation__messages-wrapper"].scrollHeight - this.$refs["kt-conversation__messages-wrapper"].scrollTop - this.$refs["kt-conversation__messages-wrapper"].clientHeight) <= 3.0;
      // setup fromKnownUser
      message.fromKnownUser = (message.senderId !== this.$userSettings.user.id);

      // add to knownUser messages
      const messageKnownUserId = message.fromKnownUser ? message.senderId : message.recipientId;
      if (this.knownUser.id === messageKnownUserId) {
        message.timestampObject = new Date(message.timestamp);
        this.knownUser.messages.push(message);
        // mark as read
        if (message.fromKnownUser) {
          this.sendMarkAsRead();
        } else {
          this.knownUser.unreadSentMessages++;
        }
      }

      // handle scroll
      if (isScrolledToBottom) {
        this.$nextTick(() => {
          this.messagesScroll("bottom");
        });
      }
    },
    onMarkAsRead(data) {
      if (this.debugMode) console.log("CONVERSATION received onMarkAsRead: ", data);
      if (this.knownUser === null) return false;

      if (
        data.recipientId === this.$userSettings.user.id && // knownUser (data.recipientId) has read the current user messages
        data.userId === this.knownUser.id
      ) {
        this.knownUser.unreadSentMessages = 0;
      } else if (
        data.recipientId === this.knownUser.id && // the current user has read the knownUser (data.userId) messages
        data.userId === this.$userSettings.user.id
      ) {
        this.knownUser.unreadReceivedMessages = 0;
      }
    },
    onTyping(data) {
      if (this.debugMode) console.log("CONVERSATION received onTyping: ", data);
      // setup scroll position
      const isScrolledToBottom = Math.abs(this.$refs["kt-conversation__messages-wrapper"].scrollHeight - this.$refs["kt-conversation__messages-wrapper"].scrollTop - this.$refs["kt-conversation__messages-wrapper"].clientHeight) <= 3.0;
      // isTyping
      if (this.knownUser !== null && this.knownUser.id === data.userId) {
        this.knownUserIsTyping = data.isTyping;
      }
      // handle scroll
      if (isScrolledToBottom) {
        this.$nextTick(() => {
          this.messagesScroll("bottom");
        });
      }
    },
    onLoggedIn(data) {
      if (this.debugMode) console.log("CONVERSATION received onLoggedIn: ", data);
      if (this.knownUser !== null && this.knownUser.id === data.userId) {
        this.knownUser.loggedIn = data.isLoggedIn;
      }
    }
  }
};
</script>

<style scoped src="./Conversation.scss" lang="scss"></style>

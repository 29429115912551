<template>
  <div class="forgotPassword">
    <!-- title -->
    <h2 class="kt-login__action-title h5 mb-4">
      {{ $t('session.login.forgotPassword') }}
    </h2>

    <!-- forgot password form -->
    <b-form @submit.prevent="onSubmit">
      <!-- Unknown User message
      <p
        v-show="isUnknownUserErrorDisplayed"
        class="kt-login__invalidMessage text-danger"
      >
        {{ $t("session.forgotPassword.validations.unknownUnser") }}
      </p> -->

      <!-- email -->
      <b-form-group
        :label="$t('session.forgotPassword.email')"
        :invalid-feedback="emailInvalidFeedback"
        :state="emailState"
      >
        <b-form-input
          ref="emailInput"
          v-model="email"
          type="email"
          trim
          maxlength="320"
          :state="emailState"
          autocomplete="username"
          @blur="emailValidation = true"
        ></b-form-input>
      </b-form-group>

      <!-- footer -->
      <div class="kt-login__footer">
        <!-- back to login -->
        <div>
          <b-link
            class="kt-link kt-link--gray"
            @click="$emit('goToLogin')"
          >
            <b-icon icon="chevron-left"></b-icon>
            {{ $t("session.forgotPassword.backButton") }}
          </b-link>
        </div>
        <!-- submit -->
        <div>
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            pill
            type="submit"
          >
            {{ $t('session.forgotPassword.submit') }}
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import sessionsServices from "@/services/API/sessionsServices";
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  data() {
    return {
      email: "",
      // is validation active
      emailValidation: false
      // error display
      // isUnknownUserErrorDisplayed: false
    };
  },
  computed: {
    // form validation
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    }
  },
  mounted() {
    // auto focus
    this.$refs.emailInput.focus();
  },
  methods: {
    // submit functions
    onValidate() {
      this.emailValidation = true;

      return this.emailState !== false;
    },
    async onSubmit() {
      try {
        // Validate Login Form
        if (this.onValidate() === false) return false;

        // Register file
        const res = await sessionsServices.forgotPassword(this.email);
        if (res.data === true) {
          // Success
          this.$emit("passwordForgotten", this.email);
        } else if (res.data.error === "unknownUser") {
          // Invalid email
          this.$emit("passwordForgotten", this.email);
        } else if (res.data.error === "userNotActivated") {
          // user Not Activated
          this.$emit("alert", "danger", {
            title: this.$t("session.forgotPassword.notifications.userNotActivatedTitle"),
            message: this.$t("session.forgotPassword.notifications.userNotActivatedText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("session.forgotPassword.notifications.onForgotPasswordErrorTitle"),
            message: this.$t("session.forgotPassword.notifications.onForgotPasswordErrorText")
          });
        }
      } catch (err) {
        // Other errors
        this.$emit("alert", "danger", {
          title: this.$t("session.forgotPassword.notifications.onForgotPasswordErrorTitle"),
          message: this.$t("session.forgotPassword.notifications.onForgotPasswordErrorText")
        });
      }
    }
  }
};
</script>

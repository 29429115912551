<template>
  <div class="kt-choose-task">
    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="taskFields"
      :items="tasks"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onTaskSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="codeInput"
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Description -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterDescription"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.tab.exact="(e) => {
                if (filterDescription === '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterDescription !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterDescription')"
              @keydown.tab.exact="(e) => {
                if (filterDescription !== '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="!tasksCount && !loading && isSearchActive"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("taskChoose.notFound") }}
      </h4>
    </div>

    <div v-show="chooseNumber">
      <h4>
        {{ $t("taskChoose.number") }}
      </h4>
      <b-form-spinbutton
        v-model="number"
        min="1"
        :max="multiplicity"
      ></b-form-spinbutton>
      <b-button
        class="my-2"
        :variant="$systemSettings.theme"
        @click="onChoose"
      >
        {{ $t("taskChoose.choose") }}
      </b-button>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    fileCreationDateProp: {
      type: String,
      default: ""
    },
    sectorIdProp: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {
      // general
      loading: false,
      tasksCount: 0,
      // table fields
      taskFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: false,
          label: this.$t("tasks.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "description",
          sortable: false,
          label: this.$t("tasks.description")
        }
      ],
      // table filters
      filterCode: "",
      filterDescription: "",
      // table items
      tasks: [],
      currentTask: null,
      chooseNumber: false,
      number: 1,
      multiplicity: 0
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterCode ||
        this.filterDescription
      );
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.codeInput.$el.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchAndUpdateTable();
    },
    // update the table
    async searchAndUpdateTable() {
      try {
        // if no filter
        if (this.isSearchActive === false) {
          this.tasks = [];
          return false;
        }

        // get the table items and update
        this.loading = true;

        const filters = {
          fileCreationDate: this.fileCreationDateProp,
          sectorId: this.sectorIdProp,
          code: this.filterCode,
          description: this.filterDescription
        };
        const res = await commonServices.getAll("tasks", filters);
        this.tasks = res.data;
        this.tasksCount = res.data.length;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchAndUpdateTable();
      }, 450);
    },
    // on select
    async onTaskSelected(rows) {
      if (rows && rows.length === 1) {
        const res = await commonServices.get("tasks", rows[0].id);
        if (res.data.content && res.data.content.multiplicity && res.data.content.multiplicity > 1) {
          this.currentTask = res.data;
          this.multiplicity = res.data.content.multiplicity;
          this.chooseNumber = true;
        } else {
          this.$emit("chosen", res.data, this.number);
        }
      }
    },
    onChoose() {
      this.$emit("chosen", this.currentTask, this.number);
    }
  }
};
</script>

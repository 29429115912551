<template>
  <div class="kt-email-validation-success">
    <!-- illustration -->
    <div class="text-center">
      <img
        :src="'/img/Session/RegisterSuccess/email-confirmation-' + $systemSettings.theme + '.png'"
        alt="email-confirmation"
      />
    </div>

    <!-- title -->
    <h2
      class="kt-login__action-title h5 mt-4 mb-3"
    >
      {{ $t('session.emailValidationSuccess.successMessage1') }}
    </h2>
    <!-- already have an account ? -->
    <div class="kt-login__subtitle">
      {{ $t("session.emailValidationSuccess.successMessage2") }}
    </div>
  </div>
</template>

<script>
import error from "@shared/services/UI/error";

export default {
  mixins: [error]
};
</script>

<template>
  <div class="kt-unrecognizedHpvResults mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="navigate('diagnosis/awaitingHpvResults')"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("unrecognizedHpvResults.pageTitle") }}
      </h2>
      <!-- exportResults button  -->
      <b-button
        v-show="isEnabled(['menu', 'diagnosis', 'unrecognizedHpvResults', 'export'])"
        ref="exportResultsButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="exportResults"
      >
        <b-icon icon="download"></b-icon>
        {{ $t("unrecognizedHpvResults.exportResultsButton") }}
      </b-button>

      <!-- filter LaboratoryId -->
      <div v-if="laboratoriesOptions.length > 2">
        <div class="d-inline-block">
          <b-form-select
            v-model="filterLaboratoryId"
            class="mt-3"
            size="sm"
            :options="laboratoriesOptions"
            value-field="id"
            text-field="formattedName"
            @keydown.enter.exact="onPageInput"
            @input="searchHasChanged = true;"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-table
      ref="unrecognizedHpvTable"
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="unrecognizedHpvResults"
      :busy="loading"
      selected-variant
      select-mode="multi"
      selectable
      @row-selected="tableRows = $event"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- select box -->
          <b-th
            class="kt-table__th-search"
            style="width: 16px;"
          >
            <button
              class="kt-checkbox-display-only"
              :class="'kt-checkbox-display-only--' + $systemSettings.theme + (tableRows.length ? ' kt-checkbox-display-only--checked' : '')"
              @click="toggleSelectAll"
            ></button>
          </b-th>
          <!-- filter Timestamp -->
          <b-th
            class="kt-table__th-search"
            style="width: 200px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
          </b-th>
          <!-- filter InstrumentId -->
          <b-th class="kt-table__th-search">
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="instruments"
              searchedFieldProp="networkName"
              :searchedLaboratoriesProp="null"
              :returnFieldsArrayProp="['id', 'networkName', 'model', 'brand', 'serialNumber', 'isActive']"
              suggestionsKeyProp="id"

              :placeholderProp="$t('awaitingHpvUpload.instrumentPlaceholder')"
              :valueProp="filterInstrument"
              :displayedValueProp="filterInstrument ? filterInstrument.networkName + ' ' + filterInstrument.model + (filterInstrument.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="suggestionFormatInstruments"
              noWrapProp

              @onUpdateParent="($event) => {
                filterInstrument = $event;
                searchHasChanged = true;
              }"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            ></InputSearch>
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              maxlength="50"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            />
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Content -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            :style="{width: searchHasChanged ? '151px' : '38px'}"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : checkbox buttons -->
      <template v-slot:cell(selectionCheckboxes)="{rowSelected}">
        <span
          class="kt-checkbox-display-only"
          :class="'kt-checkbox-display-only--' + $systemSettings.theme + (rowSelected ? ' kt-checkbox-display-only--checked' : '')"
        ></span>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(unrecognizedHpvResultButtons)="data">
        <div style="width: 38px;">
          <!-- delete  -->
          <b-button
            v-show="isEnabled(['menu', 'diagnosis', 'unrecognizedHpvResults', 'delete'])"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onDeleteUnrecognizedHpvResult(data.item)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!unrecognizedHpvResultsCount && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- select box : actions -->
    <div
      v-show="tableRows.length"
      class="kt-select-action-box"
    >
      <b-container class="text-center">
        <div class="kt-select-action-box__wrapper">
          <div class="kt-select-action-box__title h6 mr-3 mb-0">
            {{ $tc("unrecognizedHpvResults.selectedElements", tableRows.length, {count: tableRows.length}) }}
          </div>
          <div>
            <b-button
              class="kt-select-action-box__cta"
              :variant="$systemSettings.theme"
              @click="deleteSelected"
            >
              {{ $tc("unrecognizedHpvResults.deleteMultipleResults", tableRows.length, {count: tableRows.length}) }}
            </b-button>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
import unrecognizedHpvResultsServices from "@/services/API/unrecognizedHpvResultsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { InputSearch },
  mixins: [userRights, error, date],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      unrecognizedHpvResultsCount: 0,
      // table selection
      tableRows: [],
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "selectionCheckboxes",
          sortable: false,
          label: ""
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "timestamp",
          sortable: true,
          label: this.$t("unrecognizedHpvResults.timestamp"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "instrument",
          sortable: true,
          label: this.$t("unrecognizedHpvResults.instrument"),
          formatter: (value, _key, _item) => {
            return value.networkName + (value.isActive === true ? "" : " (" + this.$t("general.deletedLabel") + ")");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("unrecognizedHpvResults.accessNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "content",
          sortable: true,
          label: this.$t("unrecognizedHpvResults.content")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "unrecognizedHpvResultButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterDate: "",
      filterLaboratoryId: null,
      filterInstrument: null,
      filterAccessNumber: "",
      // table items
      unrecognizedHpvResults: [],
      // options
      laboratoriesOptions: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterDate ||
        this.filterLaboratoryId ||
        this.filterInstrument ||
        this.filterAccessNumber
      );
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import laboratories
      this.importLaboratoriesOptions();

      // refresh table
      await this.onPageInput();

      // auto-focus
      if (this.$refs.exportResultsButton) {
        this.$refs.exportResultsButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.deleteLineButton_0) {
        this.$refs.deleteLineButton_0.focus();
      }
    },

    // selection
    toggleSelectAll() {
      if (this.tableRows.length === 0) {
        this.$refs.unrecognizedHpvTable.selectAllRows();
      } else {
        this.$refs.unrecognizedHpvTable.clearSelected();
      }
    },

    // import laboratories
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, formattedName: this.$tc("fileInvoiceTransformations.allLaboratories") });
      this.laboratoriesOptions = laboratories;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // search and update the table
    async onPageInput() {
      try {
        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          date: this.filterDate,
          laboratoryId: this.filterLaboratoryId,
          instrumentId: this.filterInstrument ? this.filterInstrument.id : null,
          accessNumber: this.filterAccessNumber
        };
        const res = await commonServices.getAll("unrecognizedHpvResults", filters);
        this.unrecognizedHpvResults = res.data.rows;
        this.unrecognizedHpvResultsCount = res.data.rows.length;
        this.maxReached = res.data.maxReached;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // format instrument suggestion
    suggestionFormatInstruments(suggestion) {
      let output = "";
      if (suggestion) {
        output = suggestion.networkName + " " + suggestion.model + " " + suggestion.brand + " (" + suggestion.serialNumber + ")";
        if (suggestion.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },
    // delete
    async onDeleteUnrecognizedHpvResult(row) {
      if (typeof row === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("unrecognizedHpvResultDelete.text"), {
        title: this.$t("unrecognizedHpvResultDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("unrecognizedHpvResultDelete.ok"),
        cancelTitle: this.$t("unrecognizedHpvResultDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("unrecognizedHpvResults", row.id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("unrecognizedHpvResultDelete.notifications.deletedTitle"),
            message: this.$t("unrecognizedHpvResultDelete.notifications.deletedText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("unrecognizedHpvResultDelete.notifications.deletedErrorTitle"),
            message: this.$t("unrecognizedHpvResultDelete.notifications.deletedErrorText")
          });
        }
        this.onPageInput();
      }
    },
    // validate the selected reports
    async deleteSelected() {
      this.loading = true;
      const hpvResultIds = this.tableRows.map(item => item.id);
      try {
        const res = await unrecognizedHpvResultsServices.deleteSelected(hpvResultIds);
        if (res.data === true) {
          // success delete
          this.$emit("alert", "success", {
            title: this.$t("unrecognizedHpvResults.notifications.selectedDeleteSuccessTitle")
          });
          await this.onPageInput();
        } else if (res.data.error === "oneOrMoreNotDeleted") {
          // one or more file are not deleted
          this.$emit("alert", "danger", {
            title: this.$t("unrecognizedHpvResults.notifications.oneOrMoreNotDeletedErrorTitle"),
            message: this.$t("unrecognizedHpvResults.notifications.oneOrMoreNotDeletedErrorText")
          });
          await this.onPageInput();
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("unrecognizedHpvResults.notifications.selectedDeleteErrorTitle"),
            message: this.$t("unrecognizedHpvResults.notifications.selectedDeleteErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
      this.loading = false;
    },
    // export
    async exportResults() {
      try {
        const resFile = await unrecognizedHpvResultsServices.generate({}, "csv");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, "unrecognizedHpvResults_" + this.getIsoDate() + "." + "csv");
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<template>
  <div class="kt-choose-invoicedFile">
    <!-- relatedFiles switcher -->
    <div
      v-show="relatedFilesProp.length"
      class="mb-1"
    >
      <!-- related files -->
      <b-button
        class="mb-1"
        size="sm"
        :variant="displayRelatedFiles ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
        @click="displayRelatedFiles = true;"
      >
        <b-icon icon="link"></b-icon>
        {{ $t("invoicedFileChoose.relatedFiles") }}
        <span class="kt-count-bubble"><span class="kt-count-bubble__number">{{ relatedFilesProp.length }}</span></span>
      </b-button>
      <!-- all files -->
      <b-button
        class="ml-1 mb-1"
        size="sm"
        :variant="!displayRelatedFiles ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
        @click="displayRelatedFiles = false;"
      >
        <b-icon icon="search"></b-icon>
        {{ $t("invoicedFileChoose.search") }}
      </b-button>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="invoicedFilesFields"
      :items="invoicedFilesFormatted"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onInvoicedFileSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          v-show="!displayRelatedFiles"
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter accessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="filterAccessNumberInput"
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Patient -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 120px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-button
              ref="filterPatientChoose"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :style="filterPatient === null ? 'padding-left: 34px;' : ''"
              :variant="'outline-' + $systemSettings.theme"
              @click="$bvModal.show('patientChooseModal-' + _uid)"
              @keydown.enter.exact="searchAndUpdateTable"
              @keydown.tab.exact="(e) => {
                if (filterPatient === null) {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <!-- search icon -->
              <b-icon
                v-show="filterPatient === null"
                class="kt-table__th-search-input__search-icon"
                icon="search"
              ></b-icon>
              <!-- value -->
              <span class="kt-table__th-search-input__text-overflow">{{ filterPatient ? filterPatient.firstName + ' ' + filterPatient.lastName : '' }}</span>
            </b-button>
            <b-button
              v-show="filterPatient"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterPatient = null;
                searchAndUpdateTable();
                $refs.filterPatientChoose.focus()
              }"
              @keydown.tab.exact="focusFirstLine"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- laboratory.name -->
          <b-th class="kt-table__th-search">
            <b-form-input
              :value="laboratory ? laboratory.name : ''"
              class="kt-table__th-search-input"
              disabled
            ></b-form-input>
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.accessNumber }}
          <!-- complementary -->
          <span
            v-show="data.item.parentFileId"
            style="font-size: 14px; margin: 0 0 1px 3px; color: rgb(59, 72, 83);"
          >
            <b-icon
              icon="back"
            ></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : patient -->
      <template v-slot:cell(patient)="data">
        <!-- patient name -->
        <PersonName
          :sexNameProp="data.value.sex.name || ''"
          :firstNameProp="data.value.firstName || ''"
          :lastNameProp="data.value.lastName || ''"
          :maidenNameProp="data.value.maidenName || ''"
        />
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="!invoicedFiles.length && !loading && isSearchActive"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("invoicedFileChoose.notFound") }}
      </h4>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import PersonName from "@shared/views/Helpers/PersonName";
// services
import filesServices from "@/services/API/filesServices";
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  components: { PatientChoose, PersonName },
  mixins: [userRights, error],
  props: {
    laboratoryIdProp: {
      type: Number,
      default: null
    },
    relatedFilesProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    initialFilterValuesProp: {
      type: Object,
      default: function() {
        return {
          accessNumber: "",
          firstName: "",
          lastName: ""
        };
      }
    }
  },
  data() {
    return {
      // general
      loading: false,
      laboratory: null,
      displayRelatedFiles: Boolean(this.relatedFilesProp.length),
      // table fields
      invoicedFilesFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "patient",
          sortable: true,
          label: this.$t("files.patient")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "laboratory.name",
          sortable: true,
          label: this.$t("files.laboratory")
        }
      ],
      // table filters
      filterLaboratoryId: this.laboratoryIdProp,
      filterAccessNumber: this.initialFilterValuesProp.accessNumber || "",
      filterPatient: this.initialFilterValuesProp.patient || null,
      patientInitialFilterValues: {},
      // table items
      invoicedFiles: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterAccessNumber ||
        this.filterPatient
      );
    },
    invoicedFilesFormatted: function() {
      if (this.displayRelatedFiles && this.relatedFilesProp.length) {
        return this.relatedFilesProp;
      } else {
        return this.invoicedFiles;
      }
    }
  },
  watch: {
    laboratoryIdProp: {
      handler: async function(val) {
        this.filterLaboratoryId = val;
        await this.importLaboratory();
      }
    }
  },
  async mounted() {
    try {
      // import laboratory
      await this.importLaboratory();
      // import table data
      await this.searchAndUpdateTable();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.filterAccessNumberInput.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },

    async importLaboratory() {
      const res = await commonServices.get("laboratories", this.laboratoryIdProp);
      this.laboratory = res.data;
    },

    async onPatientChosen(patient) {
      this.filterPatient = patient;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
      this.searchAndUpdateTable();
    },

    // clear one filter
    async onClearFilter(fieldName) {
      this[fieldName] = "";
      await this.searchAndUpdateTable();
    },

    // search update (with a delay)
    onFilterChange() {
      this.displayRelatedFiles = false;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchAndUpdateTable();
      }, 450);
    },
    // update the table
    async searchAndUpdateTable() {
      try {
        // if no filter
        if (this.isSearchActive === false) {
          this.invoicedFiles = [];
          return false;
        }

        // get the table items and update
        this.loading = true;

        const res = await filesServices.getAllInvoicedFiles({
          laboratoryId: this.laboratoryIdProp,
          accessNumber: this.filterAccessNumber,
          patientId: this.filterPatient ? this.filterPatient.id : null,
          regNumber: "" // this.filterRegNumber
        });
        this.invoicedFiles = res.data;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // on select
    onInvoicedFileSelected(rows) {
      if (rows && rows.length === 1) {
        this.$emit("chosen", rows[0]);
      }
    }
  }
};
</script>

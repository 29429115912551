<template>
  <div class="kt-histologyShortCodes">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("histologyShortCodes.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'diagnosis', 'histologyShortCodes', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('diagnosis/histologyShortCode/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("histologyShortCodes.addButton") }}
      </b-button>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="histologyShortCodes"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Name -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.tab.exact="(e) => {
                if (filterName === '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterName')"
              @keydown.tab.exact="(e) => {
                if (filterName !== '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 131px;"
          ></b-th>
        </b-tr>
      </template>
      <!-- cell template : action buttons -->
      <template v-slot:cell(histologyShortCodeButtons)="data">
        <div style="width: 131px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'diagnosis', 'histologyShortCodes', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('diagnosis/histologyShortCode/view', {histologyShortCodeIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="isEnabled(['menu', 'diagnosis', 'histologyShortCodes', 'edit'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="mx-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('diagnosis/histologyShortCode/edit', {histologyShortCodeIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete  -->
          <b-button
            v-show="isEnabled(['menu', 'diagnosis', 'histologyShortCodes', 'delete'])"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onDelete(data.item)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>
      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!histologyShortCodesCount && !loading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar>
        <b-navbar-nav>
          <b-pagination
            v-show="histologyShortCodesCount"
            v-model="page"
            class="mx-2 my-3"
            :class="'kt-pagination--' + $systemSettings.theme"
            :total-rows="histologyShortCodesCount"
            per-page="50"
            @input="onPageInput"
          ></b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, pagination, error, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      page: 1,
      histologyShortCodesCount: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: true,
          label: this.$t("histologyShortCodes.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "name",
          sortable: true,
          label: this.$t("histologyShortCodes.name")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "histologyShortCodeButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterCode: "",
      filterName: "",
      // table items
      histologyShortCodes: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterCode: "string",
        filterName: "string"
      }
    };
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.onPageInput();

    // auto-focus
    this.$refs.addLineButton.focus();
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.editLineButton_0) {
        this.$refs.editLineButton_0.focus();
      } else if (this.$refs.deleteLineButton_0) {
        this.$refs.deleteLineButton_0.focus();
      }
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },
    // searchAndUpdateTable
    async onPageInput() {
      try {
        this.loading = true;

        const res = await commonServices.showAll(
          "histologyShortCodes",
          this.page,
          {
            code: this.filterCode,
            name: this.filterName
          },
          {
            by: this.sortBy,
            desc: this.sortDesc
          }
        );
        this.histologyShortCodesCount = res.data.count;
        this.histologyShortCodes = res.data.rows;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onPageInput();
      }, 450);
    },
    async onDelete(row) {
      if (typeof row === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("histologyShortCodeDelete.text"), {
        title: this.$t("histologyShortCodeDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("histologyShortCodeDelete.ok"),
        cancelTitle: this.$t("histologyShortCodeDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("histologyShortCodes", row.id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("histologyShortCodeDelete.notifications.deletedTitle"),
            message: this.$t("histologyShortCodeDelete.notifications.deletedText")
          });
          // auto-focus
          this.$refs.addLineButton.focus();
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("histologyShortCodeDelete.notifications.deletedErrorTitle"),
            message: this.$t("histologyShortCodeDelete.notifications.deletedErrorText")
          });
        }
        this.onPageInput();
      }
    }
  }
};
</script>

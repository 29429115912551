<template>
  <b-form-group
    :label="labelProp"
    :invalid-feedback="invalidFeedbackProp"
    :state="stateProp"
    :class="{'form-group--optional': optionalProp}"
  >
    <b-form-input
      ref="textInput"
      v-model="displayValue"
      :maxlength="maxlengthProp"
      :state="stateProp"
      :formatter="formatValue"
      :disabled="disabledProp"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="$emit('submit', true)"
      @blur="$emit('blur', true)"
    ></b-form-input>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    labelProp: {
      type: String,
      default: ""
    },
    stateProp: {
      type: Boolean
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    disabledProp: {
      type: Boolean
    },
    maxlengthProp: {
      type: Number,
      default: 50
    },
    optionalProp: {
      type: Boolean
    }
  },
  computed: {
    displayValue: {
      get: function() {
        return this.formatValue(this.value);
      },
      set: function(modifiedValue) {
        this.$emit("input", this.formatValue(modifiedValue));
      }
    }
  },
  methods: {
    focus() {
      this.$refs.textInput.focus();
    },

    formatValue(value = "") {
      if (value === "") {
        return "";
      } else {
        return value
          .replace(/[\^0-9:,;!?<>~=+*/\\²%[\](){}@#"`°_&|µ$€£]+/g, "") // remove special characters
          .replace(/^[\s|-]+/, "") // trim starting spaces
          .replace(/((\s+|-+)?\s-(\s+|-+)?|(\s+|-+)?-\s(\s+|-+)?)+/g, "-") // remove blended spaces and hyphens
          .replace(/\s{2,}/g, " ") // remove multiple spaces
          .replace(/-{2,}/g, "-") // remove multiple hyphens
          .toLowerCase() // all to lowercase
          .replace(/(^[^\s-]{1})|(\s+[^\s-]{1})|(-+[^\s-]{1})/g, letter => letter.toUpperCase()); // first letter uppercase
        // using unicode : /(^[a-z\u00E0-\u00FC]{1})|(\s+[a-z\u00E0-\u00FC]{1})|(-+[a-z\u00E0-\u00FC]{1})/gu
        // conversion table backup : àáâãäåæçèéêëìíîïðñòóôõöùúûüýÿ ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖÙÚÛÜÝŸ
      }
    }
  }
};
</script>

<template>
  <div
    class="kt-input-price"
    :class="'kt-input-price--' + $systemSettings.theme"
    :style="{width: inputWidth}"
  >
    <b-form-group
      class="mb-0"
      :label="labelProp"
      :invalid-feedback="invalidFeedbackProp"
      :state="stateProp"
    >
      <b-form-input
        ref="priceField"
        v-model="priceFormatted"
        :state="stateProp"
        autocomplete="off"
        :maxlength="maxlengthProp"
        @focus="(event) => {
          event.target.select();
        }"
        @blur="$emit('onActivateValidation', true)"
        @input="onPriceInput"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="$emit('submit', formatPrice(priceFormatted))"
        @change="$emit('change', formatPrice(priceFormatted))"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    labelProp: {
      type: String,
      default: ""
    },
    priceProp: {
      type: [Number, null],
      default: function() {
        return this.nullAllowedProp ? "" : "0.00";
      }
    },
    stateProp: {
      type: Boolean
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    nullAllowedProp: {
      type: Boolean
    },
    inputAutoWidthProp: {
      type: Boolean
    },
    maxlengthProp: {
      type: Number,
      default: 11
    }
  },
  data() {
    return {
      price: this.nullAllowedProp ? "" : "0.00",
      priceFormatted: this.nullAllowedProp ? "" : "0.00",
      inputWidth: "",
      // debug
      debugMode: false
    };
  },
  watch: {
    priceProp: {
      handler(value) {
        let v = value;
        if (v === null) {
          v = this.nullAllowedProp ? "" : "0.00";
        } else if (typeof v === "number") {
          if (1 / v === -Infinity) {
            // correction to preserve the sign when -0
            v = "-.00";
          } else {
            v = String(v.toFixed(2));
          }
        }
        v = this.formatPriceToString(v);
        if (v !== this.price) {
          this.priceFormatted = v;
          if (this.$refs.priceField) this.onPriceInput();
        }
        if (this.inputAutoWidthProp) this.inputAutoWidth();
      },
      immediate: true
    }
  },
  methods: {
    focus() {
      this.$refs.priceField.focus();
    },

    inputAutoWidth() {
      this.inputWidth = (((this.priceFormatted.length + 1) * 8) + 14) + "px";
    },
    onPriceInput() {
      // if (this.debugMode) console.log("1 ///////////// original value " + this.priceFormatted);
      // if (this.debugMode) console.log("1 ///////////// original caret " + this.$refs.priceField.selectionStart);

      // reset price if empty
      if (this.priceFormatted === "") {
        this.price = this.nullAllowedProp ? "" : "0.00";
        this.$emit("onUpdateParent", this.nullAllowedProp ? null : 0);
        this.$nextTick(() => {
          // update formatted value
          this.priceFormatted = this.nullAllowedProp ? "" : "0.00";

          // update the carret
          this.$nextTick(() => {
            this.$refs.priceField.selectionStart = 0;
            this.$refs.priceField.selectionEnd = 0;
          });
        });

        if (this.inputAutoWidthProp) this.inputAutoWidth();

        return true;
      }

      let formatted = this.priceFormatted;
      let integerNumbers = "0";
      let decimalNumbers = "00";
      const caretStart = this.$refs.priceField.selectionStart;
      const caretEnd = this.$refs.priceField.selectionEnd;

      // if no decimals, add decimals
      if (!/[.,]/g.test(formatted)) {
        formatted += ".";
      }
      // formatting
      for (let i = 0; i < formatted.length; i++) {
        if (formatted.charAt(i) === "." || formatted.charAt(i) === ",") {
          integerNumbers = formatted.slice(0, i).replace(/[^\d-]/g, "").replace(/^0*/, "");
          integerNumbers = integerNumbers || "0";
          decimalNumbers = formatted.slice(i + 1, formatted.length).replace(/\D/g, "");
          if (decimalNumbers.length === 0) {
            decimalNumbers = "00";
          } else if (decimalNumbers.length === 1) {
            decimalNumbers = decimalNumbers + "0";
          } else if (decimalNumbers.length > 2) {
            decimalNumbers = decimalNumbers.slice(0, 2);
          }
          break;
        }
      }
      formatted = integerNumbers + "." + decimalNumbers;

      // update unformatted value
      this.price = integerNumbers + "." + decimalNumbers;
      this.$emit("onUpdateParent", Number(formatted.replace(/,/g, ".")));

      // if (this.debugMode) console.log("2 ///////////// formatted " + formatted);
      // if (this.debugMode) console.log("/");

      // if (this.debugMode) console.log("///////////// before caret " + caretStart);
      // if (this.debugMode) console.log("///////////// formatted.length " + formatted.length);
      this.$nextTick(() => {
        // update formatted value
        this.priceFormatted = formatted;

        if (this.inputAutoWidthProp) this.inputAutoWidth();

        // update the carret
        this.$nextTick(() => {
          this.$refs.priceField.selectionStart = caretStart;
          this.$refs.priceField.selectionEnd = caretEnd;
        });
      });
      // if (this.debugMode) console.log("///////////// after caret " + this.$refs.priceField.selectionStart);
      // if (this.debugMode) console.log("//////////////////////////////////////////////////////////////");
      // if (this.debugMode) console.log("/");
    },
    formatPrice(price = "") {
      // if empty
      if (price === "0.00") {
        return 0;
      } else if (price === null || price === "") {
        return this.nullAllowedProp ? null : 0;
      }
      const formatted = this.formatPriceToString(price);
      return Number(formatted.replace(/,/g, "."));
    },
    formatPriceToString(price = "") {
      // if empty
      if (price === "0.00") {
        return "0.00";
      } else if (price === null || price === "") {
        return this.nullAllowedProp ? "" : "0.00";
      }

      let formatted = String(price);
      let integerNumbers = "0";
      let decimalNumbers = "00";

      // if no decimals, add decimals
      if (!/[.,]/g.test(formatted)) {
        formatted += ".";
      }
      // formatting
      for (let i = 0; i < formatted.length; i++) {
        if (formatted.charAt(i) === "." || formatted.charAt(i) === ",") {
          integerNumbers = formatted.slice(0, i).replace(/[^\d-]/g, "").replace(/^0*/, "");
          integerNumbers = integerNumbers || "0";
          decimalNumbers = formatted.slice(i + 1, formatted.length).replace(/\D/g, "");
          if (decimalNumbers.length === 0) {
            decimalNumbers = "00";
          } else if (decimalNumbers.length === 1) {
            decimalNumbers = decimalNumbers + "0";
          } else if (decimalNumbers.length > 2) {
            decimalNumbers = decimalNumbers.slice(0, 2);
          }
          break;
        }
      }
      return integerNumbers + "." + decimalNumbers;
    }
  }
};
</script>

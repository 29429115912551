<template>
  <div class="kt-attached-files">
    <!-- modal header -->
    <header
      class="modal-header"
      style="margin: -1rem -1rem 0 -1rem;"
    >
      <div>
        <!-- title -->
        <h5
          class="modal-title kt-page-header__title h4 mb-0"
          style="font-size: 22px; line-height: 24px;"
        >
          {{ $t("fileArchives.pageTitle") }}
        </h5>
        <!-- subtitle -->
        <p
          v-show="hasMissingArchivesProp"
          class="kt-page-header__subtitle mb-0 line-height-sm text-14"
          style="color: rgba(199, 51, 51, 0.9) !important; margin-top: 6px;"
        >
          <b-icon
            icon="exclamation-triangle-fill"
            style="margin-right: 3px;"
          />
          {{ $t('fileArchives.hasMissingArchives') }}
        </p>
      </div>
      <!-- close -->
      <button
        ref="closeButton"
        type="button"
        aria-label="Close"
        class="close"
        @click="$emit('close', true)"
      >
        {{ $t('general.closeX') }}
      </button>
    </header>

    <!-- loader -->
    <div
      v-show="!readyProp"
      :class="'mt-3 text-center text-' + $systemSettings.theme"
    >
      <b-spinner
        class="align-middle"
        :class="'kt-spinner--' + $systemSettings.theme"
      ></b-spinner>
      <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
    </div>

    <!-- empty -->
    <div
      v-show="!archivesProp.length && readyProp"
      class="text-center text-15 text-secondary mt-3 mb-1"
    >
      {{ $t("fileArchives.emptyFiles") }}
    </div>

    <!-- list -->
    <div v-show="archivesProp.length && readyProp">
      <div
        v-for="(attachedFile, index) in archivesProp"
        :key="attachedFile.id"
        class="kt-file-choose-from-ids__item"
      >
        <b-link
          :ref="index === (archivesProp.length - 1) ? 'firstItemLink' : null"
          class="kt-file-choose-from-ids__link"
          @click.prevent="getFile(attachedFile)"
        >
          <span class="kt-file-choose-from-ids__link-wrapper d-flex justify-content-between align-items-center">
            <span class="d-block pr-3">
              <b-icon icon="cloud-arrow-down" />
            </span>
            <span class="d-block w-100">
              <span class="d-block text-15 font-weight-semi-bold">{{ attachedFile.subtype ? $t("archivesDocumentSubtypes." + attachedFile.subtype) : $t("archivesDocumentTypes." + attachedFile.documentType) }}</span>
              <span class="d-block text-13 text-secondary">
                {{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(attachedFile.date, $systemSettings.locale, {
                  displayTime: true,
                  displayYear: null,
                  shortToday: false,
                  shortYesterday: false
                })) }}
              </span>
            </span>
          </span>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
// services
import archivesServices from "@/services/API/archivesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
// others
import fileSaver from "file-saver";

export default {
  mixins: [userRights, error, date, string],
  props: {
    archivesProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    hasMissingArchivesProp: {
      type: Boolean
    },
    readyProp: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  watch: {
    archivesProp: {
      handler: function(val) {
        const archives = JSON.parse(JSON.stringify(val));
        this.archives = archives.sort((a, b) => a.id - b.id);
      },
      deep: true,
      immediate: true
    },
    readyProp: {
      handler: function(val) {
        if (val) {
          this.$nextTick(() => {
            this.focusFirstElement();
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.closeButton.focus();
    },
    async getFile(archive) {
      try {
        const resFile = await archivesServices.getDocument(archive.interface, archive.documentType, archive.id, archive.size);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, (archive.name || (this.$t("archivesDocumentTypes." + archive.documentType) + "-" + archive.id)) + ".pdf");
        }
        this.$emit("close", true);
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./FileChooseFromIds.scss" lang="scss"></style>

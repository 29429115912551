<template>
  <div
    class="kt-view-page kt-laboratory-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("laboratoryView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="laboratoryData && isInAreaOfAuthority({laboratory: laboratoryData}) && isEnabled(['menu', 'administration', 'laboratories', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('administration/laboratory/edit', {laboratoryIdProp: laboratoryId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("laboratoryView.editLaboratory") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION name and general info -->
      <div
        v-if="nameDisplay ||
          registrationNumberDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- name -->
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.name") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ name }}
            </p>
          </b-col>
        </b-row>
        <!-- registrationNumber -->
        <b-row v-if="registrationNumberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.registrationNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ registrationNumber }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION contact information -->
      <div
        v-if="addressDisplay ||
          phoneDisplay ||
          emailDisplay ||
          accountingDepartmentEmailDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("laboratory.sectionTitleContactInformation") }}
        </h3>

        <!-- address -->
        <b-row v-if="addressDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.address1") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ formatAddress(address1, address2, zip, city, country) }}
            </p>
          </b-col>
        </b-row>
        <!-- phone -->
        <b-row v-if="phoneDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.phone") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ phone }}
            </p>
          </b-col>
        </b-row>
        <!-- email -->
        <b-row v-if="emailDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.email") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ email }}
            </p>
          </b-col>
        </b-row>
        <!-- accounting department email -->
        <b-row v-if="accountingDepartmentEmailDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.accountingDepartmentEmail") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ accountingDepartmentEmail }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION bank details -->
      <div
        v-if="bankDetailsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("laboratory.sectionTitlePayments") }}
        </h3>

        <!-- bankDetails -->
        <b-row v-if="bankDetailsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratories.bankDetails") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ bankDetails }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- SECTION reminders -->
      <div
        v-if="remindersDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h3 class="kt-view-page__section-title">
          {{ $t("laboratory.sectionTitleReminders") }}
        </h3>

        <!-- reminders -->
        <b-row v-if="remindersDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("laboratory.sectionTitleReminders") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <Reminders
              viewModeProp
              hideLabelProp
              :remindersProp="reminders"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-col>
        </b-row>
      </div>

      <div v-if="isSystemUser">
        <!-- SECTION laboratory links -->
        <div
          v-if="websiteDisplay ||
            gprdWebsiteDisplay ||
            paymentLinkDisplay ||
            paymentShortLinkDisplay"
          class="kt-view-page__section"
          :class="sectionMarginsClass"
        >
          <h3 class="kt-view-page__section-title">
            {{ $t("laboratory.sectionTitleLinks") }}
          </h3>

          <!-- website -->
          <b-row v-if="websiteDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.website") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ website }}
              </p>
            </b-col>
          </b-row>
          <!-- gprdWebsite -->
          <b-row v-if="gprdWebsiteDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.gprdWebsite") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ gprdWebsite }}
              </p>
            </b-col>
          </b-row>
          <!-- paymentLink -->
          <b-row v-if="paymentLinkDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.paymentLink") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ paymentLink }}
              </p>
            </b-col>
          </b-row>
          <!-- paymentShortLink -->
          <b-row v-if="paymentShortLinkDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.paymentShortLink") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ paymentShortLink }}
              </p>
            </b-col>
          </b-row>
        </div>

        <!-- SECTION laboratory visuals -->
        <div
          v-if="logoFileDisplay || baseColorDisplay"
          class="kt-view-page__section"
          :class="sectionMarginsClass"
        >
          <h3 class="kt-view-page__section-title">
            {{ $t("laboratory.sectionTitleLogo") }}
          </h3>

          <!-- logoFile -->
          <b-row v-if="logoFileDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.logo") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
              style="max-width: 320px;"
              class="mb-2 pb-1"
            >
              <b-img
                fluid
                thumbnail
                :src="logoUrl"
                :alt="(logoFile === null || logoUrl === null) ? 'No image found' : $t('laboratories.logoAlt')"
              ></b-img>
            </b-col>
          </b-row>
          <!-- baseColor -->
          <b-row v-if="baseColorDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.baseColor") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
              style="max-width: 320px;"
            >
              <b-form-input
                v-model="baseColor"
                style="padding-left: 40px"
                disabled
                :placeholder="$t('general.emptyWithHyphen')"
              ></b-form-input>
              <!-- color preview -->
              <div style="position: relative;">
                <div
                  style="position: absolute; bottom: 0; left: 0; width: 34px; height: 36.5px; z-index: 1; border-radius: 0.25rem 0 0 0.2rem; pointer-events: none;"
                  :style="{
                    backgroundColor: (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(baseColor)) ? baseColor : '#4c505c'
                  }"
                ></div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- SECTION AdvancedParameters -->
        <div
          v-if="accessNumberRegexDisplay || slideNumberRegexDisplay || slideToAccessNumberRegexDisplay"
          class="kt-view-page__section"
          :class="sectionMarginsClass"
        >
          <h3 class="kt-view-page__section-title">
            {{ $t("laboratory.sectionTitleAdvancedParameters") }}
          </h3>

          <!-- accessNumberRegex -->
          <b-row v-if="accessNumberRegexDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.accessNumberRegex") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ accessNumberRegex }}
              </p>
            </b-col>
          </b-row>
          <!-- slideNumberRegex -->
          <b-row v-if="slideNumberRegexDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.slideNumberRegex") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ slideNumberRegex }}
              </p>
            </b-col>
          </b-row>
          <!-- slideToAccessNumberRegex -->
          <b-row v-if="slideToAccessNumberRegexDisplay">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("laboratories.slideToAccessNumberRegex") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ slideToAccessNumberRegex }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import Reminders from "@/views/Administration/Laboratories/Reminders";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import viewComponents from "@shared/services/UI/viewComponents";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { Reminders },
  mixins: [userRights, areaOfAuthority, error, price, phoneNumber, address, viewComponents],
  props: {
    laboratoryIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "name",
          "registrationNumber",
          "address",
          "phone",
          "email",
          "accountingDepartmentEmail",
          "website",
          "gprdWebsite",
          "paymentLink",
          "paymentShortLink",
          "bankDetails",
          "logoFile",
          "baseColor",
          "accessNumberRegex",
          "slideNumberRegex",
          "slideToAccessNumberRegex",
          "reminders"
        ];
      }
    }
  },
  data() {
    return {
      // general
      laboratoryId: this.laboratoryIdProp,
      laboratoryData: null,
      isSystemUser: this.isRole("system"),
      // base data
      name: "",
      registrationNumber: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      country: "",
      phone: "",
      email: "",
      accountingDepartmentEmail: "",
      website: "",
      gprdWebsite: "",
      paymentLink: "",
      paymentShortLink: "",
      bankDetails: "",
      logoFile: null,
      logoUrl: null,
      baseColor: "",
      accessNumberRegex: "",
      slideNumberRegex: "",
      slideToAccessNumberRegex: "",
      reminders: [],
      // dispayFields
      nameDisplay: false,
      registrationNumberDisplay: false,
      addressDisplay: false,
      phoneDisplay: false,
      emailDisplay: false,
      accountingDepartmentEmailDisplay: false,
      websiteDisplay: false,
      gprdWebsiteDisplay: false,
      paymentLinkDisplay: false,
      paymentShortLinkDisplay: false,
      bankDetailsDisplay: false,
      logoFileDisplay: false,
      baseColorDisplay: false,
      accessNumberRegexDisplay: false,
      slideNumberRegexDisplay: false,
      slideToAccessNumberRegexDisplay: false,
      remindersDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get laboratory
      const resServices = await commonServices.get("laboratories", this.laboratoryId);
      this.laboratoryData = resServices.data;
      // assign the data
      this.name = resServices.data.name;
      this.registrationNumber = resServices.data.registrationNumber;
      this.address1 = resServices.data.address1;
      this.address2 = resServices.data.address2;
      this.zip = resServices.data.zip;
      this.city = resServices.data.city;
      this.country = resServices.data.country;
      this.phone = this.importFormatPhone(resServices.data.phone);
      this.email = resServices.data.email;
      this.accountingDepartmentEmail = resServices.data.accountingDepartmentEmail;
      this.website = resServices.data.website;
      this.gprdWebsite = resServices.data.gprdWebsite;
      this.paymentLink = resServices.data.paymentLink;
      this.paymentShortLink = resServices.data.paymentShortLink;
      this.bankDetails = resServices.data.bankDetails;
      this.baseColor = resServices.data.baseColor;
      this.accessNumberRegex = resServices.data.accessNumberRegex;
      this.slideNumberRegex = resServices.data.slideNumberRegex;
      this.slideToAccessNumberRegex = resServices.data.slideToAccessNumberRegex;
      // import logo
      if (resServices.data.logoFileName) {
        const resLogo = await commonServices.getFile("laboratories", this.laboratoryId);
        this.logoFile = new File([resLogo.data], resServices.data.logoFileName);
        this.logoUrl = window.URL.createObjectURL(this.logoFile);
      }
      // reminders
      this.reminders = resServices.data.reminders.map((reminder) => {
        reminder.delay = String(reminder.delay);
        reminder.amount = this.importFormatPrice(reminder.amount);
        return reminder;
      });

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("administration/laboratories");
    }
  }
};
</script>

<template>
  <div class="systemSettings">
    <div class="d-flex justify-content-center">
      <!-- download button -->
      <b-button
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="onDownloadFile"
      >
        {{ $t("systemSettings.downloadFile") }}
      </b-button>
    </div>
    <div
      v-show="isDownloading"
      :class="'text-center text-' + $systemSettings.theme"
    >
      <b-spinner
        class="align-middle"
        :class="'kt-spinner--' + $systemSettings.theme"
      ></b-spinner>
      <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
    </div>
  </div>
</template>

<script>
import { navigate } from "@/services/UI/vueRouterServices";
import systemSettingsServices from "@shared/services/API/systemSettingsServices";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import fileSaver from "file-saver";

export default {
  mixins: [userRights, error],
  props: {
  },
  data: function() {
    return {
      isDownloading: false
    };
  },
  methods: {
    onRoute(routeName) {
      navigate(routeName);
    },
    async onDownloadFile() {
      this.isDownloading = true;
      try {
        const resFile = await systemSettingsServices.getLogs();
        fileSaver.saveAs(resFile.data, "kitaro_log.zip");
      } catch (err) {
        this.handleErrors(err);
      }
      this.isDownloading = false;
    }
  }
};
</script>

<template>
  <div class="kt-paymentReport-paymentGroups-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("payments.paymentReportsPaymentGroupsEdit.pageTitle") }}
      </h2>
      <!-- reference -->
      <span
        v-show="reference"
        class="kt-badge ml-3"
        :class="'kt-badge--light-gray'"
      >
        <span class="kt-badge__text font-weight-semi-bold">{{ reference }}</span>
      </span>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- paymentReport info -->
    <div class="kt-view-page__body">
      <div class="kt-view-page__section">
        <!-- laboratory -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("payments.paymentReports.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratory ? laboratory.name + (laboratory.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : '' }}
            </p>
          </b-col>
        </b-row>
        <!-- organisation -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('payments.paymentReports.organisation') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-show="organisation"
              class="kt-item-list mt-1"
            >
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item">
                  <div class="kt-item-list__item-title pr-0">
                    <!-- name -->
                    {{ organisation ? organisation.name + (organisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : "" }}
                  </div>
                  <div class="kt-item-list__item-text">
                    <!-- code -->
                    {{ organisation ? organisation.code : '' }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- orderDate -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('payments.paymentReports.orderDate') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ orderDate ? $d(new Date(orderDate), "date") : "" }}
            </p>
          </b-col>
        </b-row>
        <!-- paymentDate -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('payments.paymentReports.paymentDate') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ paymentDate ? $d(new Date(paymentDate), "date") : "" }}
            </p>
          </b-col>
        </b-row>
        <!-- bank -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('payments.paymentReports.bank') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ bank ? bank.code : "" }}
            </p>
          </b-col>
        </b-row>
        <!-- paid -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('payments.paymentReports.paid') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ $n((paid || 0), "currency") }}
            </p>
          </b-col>
        </b-row>
        <!-- reconciled -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t('payments.paymentReports.reconciled') }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <ProgressBar
              style="max-width: 33.3333%"
              :valueProp="reconciled"
              :totalProp="paid"
            />
          </b-col>
        </b-row>
        <!-- edit button -->
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <b-button
              v-b-modal="'editPaymentReportModal'"
              size="sm"
              class="mb-2"
              :variant="'outline-' + $systemSettings.theme"
            >
              <b-icon icon="pen" />
              {{ $t("general.edit") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-3 mb-3"></div>

    <!-- table title -->
    <div class="mb-3">
      <!-- title -->
      <h2 class="kt-page-header__title h4">
        {{ $t("payments.paymentReportsPaymentGroupsEdit.paymentGroupsTitle") }}
      </h2>
      <!-- add payment group button -->
      <b-button
        ref="addGroupButton"
        size="sm"
        class="ml-3"
        :variant="'outline-' + $systemSettings.theme"
        @click="onAddPaymentGroup"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("payments.paymentReportsPaymentGroupsEdit.addPaymentGroup") }}
      </b-button>
    </div>

    <!-- table -->
    <b-table
      class="m-0 kt-table"
      table-class="m-0"
      :fields="paymentGroupsFields"
      :items="paymentGroups"
      :busy="loading"
    >
      <!-- pathologist -->
      <template v-slot:cell(pathologist)="data">
        <span class="align-middle">{{ data.value }}</span>
      </template>
      <!-- filePayments -->
      <template v-slot:cell(filePayments)="data">
        <span class="align-middle">{{ data.value ? data.value.length : 0 }}</span>
      </template>
      <!-- reconciled -->
      <template v-slot:cell(reconciled)="data">
        <ProgressBar
          :valueProp="data.item.reconciled"
          :totalProp="data.item.paid"
        />
      </template>
      <!-- cell template : action buttons -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 78px;">
          <!-- view
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'viewGroup'])"
            class="mr-1"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="viewPaymentGroup(data.item['id'])"
          >
            <b-icon icon="eye"></b-icon>
          </b-button> -->
          <!-- edit lines -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'viewLines'])"
            class="mr-1"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/paymentReport/paymentLinesEdit', {paymentReportIdProp: paymentReportId, paymentGroupIdProp: data.item.id})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- option drop-down -->
          <div
            v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'GroupToPdf']) ||
              isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'GroupToXlsx']) ||
              isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'deleteGroup'])"
            class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-1"
          >
            <!-- variant="kt-dropdown-btn--success" -->
            <b-button
              variant="outline-secondary"
              size="sm"
              class="btn-icon kt-dropdown-btn__switcher-btn"
              pill
            >
              <b-icon icon="three-dots-vertical"></b-icon>
            </b-button>
            <ul
              class="kt-dropdown-btn__list"
            >
              <li class="kt-dropdown-btn__shadow"></li>

              <!-- edit paymentGroup -->
              <li
                v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'editGroup'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button"
                  @click="onEditPaymentGroup(data.item.id)"
                >
                  <b-icon
                    icon="pen"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReportsPaymentGroupsEdit.editPaymentGroup") }}
                </button>
              </li>
              <!-- export paymentGroup PDF -->
              <li
                v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'groupToPdf'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button"
                  @click="onExportPaymentGroup(data.item, 'pdf')"
                >
                  <b-icon
                    icon="download"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReportsPaymentGroupsEdit.downloadPaymentGroupPdf") }}
                </button>
              </li>
              <!-- export paymentGroup XLS -->
              <li
                v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'groupToXlsx'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button"
                  @click="onExportPaymentGroup(data.item, 'xlsx')"
                >
                  <b-icon
                    icon="table"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReportsPaymentGroupsEdit.downloadPaymentGroupXlsx") }}
                </button>
              </li>
              <!-- delete paymentGroup -->
              <li
                v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'deleteGroup'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button text-danger"
                  @click="onDeletePaymentGroup(data.item.id)"
                >
                  <b-icon
                    icon="trash"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReportsPaymentGroupsEdit.deletePaymentGroup") }}
                </button>
              </li>

              <!-- <li class="kt-dropdown-btn__divider"></li> -->
            </ul>
          </div>
        </div>
      </template>
      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- editPaymentReport Modal -->
    <b-modal
      id="editPaymentReportModal"
      hide-footer
      :title="$t('payments.paymentReportEdit.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.paymentReportEditComponent.focusFirstElement()
      }"
    >
      <PaymentReportEdit
        ref="paymentReportEditComponent"
        :paymentReportIdProp="paymentReportIdProp"
        moduleModeProp
        :editModeProp="editModeProp"
        @paymentReportEdited="onPaymentReportEdited"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- editPaymentGroup Modal -->
    <b-modal
      id="editPaymentGroupModal"
      hide-footer
      :title="currentPaymentGroupId ? $t('payments.paymentGroupEdit.pageTitle') : $t('payments.paymentGroupNew.pageTitle')"
      size="md"
      @shown="() => {
        $refs.paymentGroupEditComponent.focusFirstElement()
      }"
      @hidden="() => {
        $nextTick(() => {
          $refs.addGroupButton.focus();
        });
      }"
    >
      <PaymentGroupEdit
        ref="paymentGroupEditComponent"
        :paymentReportIdProp="paymentReportId"
        :unvavailablePathologistIdsProp="unvavailablePathologistIds"
        :paymentGroupIdProp="currentPaymentGroupId"
        moduleModeProp
        :editModeProp="currentPaymentGroupId !== null"
        @paymentGroupEdited="onPaymentGroupEdited"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import PaymentReportEdit from "@/views/Accounting/Payments/PaymentReports/PaymentReportEdit";
import PaymentGroupEdit from "@/views/Accounting/Payments/PaymentGroups/PaymentGroupEdit";
import ProgressBar from "@shared/views/Helpers/ProgressBar";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { PaymentReportEdit, PaymentGroupEdit, ProgressBar },
  mixins: [userRights, error, price],
  props: {
    editModeProp: {
      type: Boolean
    },
    paymentReportIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      paymentReportId: this.paymentReportIdProp,
      paymentReportData: null,
      loading: true,

      // paymentReport infos
      laboratory: null,
      organisation: null,
      orderDate: "",
      paymentDate: "",
      bankId: null,
      bank: null,
      paid: 0,
      reconciled: 0,
      reference: "",

      // paymentGroups
      paymentGroups: [],
      paymentGroupsFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "pathologist",
          sortable: false,
          label: this.$t("payments.paymentReportsPaymentGroupsEdit.pathologist"),
          formatter: (value, _key, _item) => {
            let output = "";
            if (value) {
              output = value.user.firstName + " " + value.user.lastName + " (" + value.code + ")";
              if (value.user.isActive === false) {
                output += " (" + this.$t("general.deletedLabel") + ")";
              }
            }
            return output;
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          thStyle: { width: "14%" },
          key: "paid",
          sortable: false,
          label: this.$t("payments.paymentReports.paid"),
          formatter: (value, _key, _item) => {
            return this.$n((value || 0), "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "filePayments",
          thStyle: { width: "14%" },
          sortable: false,
          label: this.$t("payments.paymentReportsPaymentGroupsEdit.files")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          thStyle: { width: "21%" },
          key: "reconciled",
          sortable: false,
          label: this.$t("payments.paymentReports.reconciled"),
          formatter: (value, _key, _item) => {
            return this.$n((value || 0), "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          thStyle: { width: "78px" },
          key: "actionButtons",
          sortable: false,
          label: this.$t("payments.paymentReportsPaymentGroupsEdit.actionButtonsTitle")
        }
      ],
      // paymentGroupEdit Modal
      currentPaymentGroupId: null
    };
  },
  computed: {
    unvavailablePathologistIds: function() {
      return this.paymentGroups.map(({ pathologist }) => {
        return pathologist.id;
      });
    }
    // form validation

    // formatted variables
  },
  watch: {},
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import data
    if (this.editModeProp) {
      this.importData();
    }

    // auto-focus
    this.$refs.addGroupButton.focus();
  },
  methods: {
    // import paymentReport data
    async importData(importPaymentGroups = true) {
      try {
        this.loading = true;
        // get paymentReport
        const resServices = await commonServices.get("paymentReports", this.paymentReportId);
        this.paymentReportData = resServices.data;
        // import data
        this.laboratory = resServices.data.laboratory;
        this.organisation = resServices.data.organisation;
        this.orderDate = resServices.data.orderDate;
        this.paymentDate = resServices.data.paymentDate;
        this.bank = resServices.data.bank;
        this.bankId = resServices.data.bankId;
        this.paid = this.importFormatPrice(resServices.data.paid);
        this.reconciled = this.importFormatPrice(resServices.data.reconciled);
        this.reference = resServices.data.reference;
        if (importPaymentGroups) {
          this.paymentGroups = resServices.data.paymentGroups;
          for (const paymentGroup of this.paymentGroups) {
            // setup paymentGroups
            paymentGroup.tempId = paymentGroup.id;
            paymentGroup.paid = this.importFormatPrice(paymentGroup.paid);
            paymentGroup.reconciled = this.importFormatPrice(paymentGroup.reconciled);
            // setup filePayments
            for (const filePayment of paymentGroup.filePayments) {
              filePayment.paid = this.importFormatPrice(filePayment.paid);
              for (const paymentAct of filePayment.paymentActs) {
                paymentAct.paid = this.importFormatPrice(paymentAct.paid);
                paymentAct.invoiceAct.invoiced = this.importFormatPrice(paymentAct.invoiceAct.invoiced);
              }
              // setup collectionDates
              const collectionDatesArr = [];
              for (const containerGroup of filePayment.file.containerGroups) {
                collectionDatesArr.push(containerGroup.collectionDate ? this.$d(new Date(containerGroup.collectionDate), "date") : "");
              }
              filePayment.file.collectionDates = collectionDatesArr;
            }
          }
        }
        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // PaymentReport functions
    async onPaymentReportEdited() {
      this.$bvModal.hide("editPaymentReportModal");
      await this.importData(false);
    },

    // PaymentGroups functions
    onAddPaymentGroup() {
      this.currentPaymentGroupId = null;
      this.$bvModal.show("editPaymentGroupModal");
    },
    onEditPaymentGroup(id) {
      this.currentPaymentGroupId = id;
      this.$bvModal.show("editPaymentGroupModal");
    },
    onPaymentGroupEdited() {
      this.$bvModal.hide("editPaymentGroupModal");
      this.currentPaymentGroupId = null;
      this.importData();
    },
    async onDeletePaymentGroup(id) {
      if (typeof id === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.paymentGroupDelete.text"), {
        title: this.$t("payments.paymentGroupDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("payments.paymentGroupDelete.ok"),
        cancelTitle: this.$t("payments.paymentGroupDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("paymentGroups", id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("payments.paymentGroupDelete.notifications.deletedTitle"),
            message: this.$t("payments.paymentGroupDelete.notifications.deletedText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("payments.paymentGroupDelete.notifications.deletedErrorTitle"),
            message: this.$t("payments.paymentGroupDelete.notifications.deletedErrorText")
          });
        }
        await this.importData();
      }
    },

    // navigate back to the list page
    onGoBack() {
      navigate("accounting/payments/paymentReports");
    },

    async onExportPaymentGroup(row, type) {
      try {
        const resFile = await commonServices.export("paymentGroups", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.id + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

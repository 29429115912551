<template>
  <div class="kt-pathologist-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- pathologist form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- pathologist name -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputSearch
            ref="pathologistNameInput"
            searchedTableProp="usersOfPathologists"
            searchedFieldProp="lastName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'lastName', 'firstName', 'email', 'isActive']"
            suggestionsKeyProp="id"
            :labelProp="$t('pathologists.userId')"
            :valueProp="user"
            :displayedValueProp="suggestionFormatUsers(user)"
            :suggestionFormatProp="suggestionFormatUsers"
            :stateProp="userIdState"
            :invalidFeedbackProp="userIdInvalidFeedback"
            @onUpdateParent="
              user = $event;
              userId = user ? user.id : 0;
            "
            @onActivateValidation="userIdValidation = true"
            @onDisableValidation="
              userIdValidation = false;
              userIsAvailable = true;
            "
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>

        <b-col cols="12"></b-col>

        <!-- pathologistReplacements -->
        <b-col cols="12">
          <div class="mb-2">
            {{ $t("pathologists.pathologistReplacements") }}
            <!-- add button -->
            <b-button
              size="sm"
              :variant="'outline-' + $systemSettings.theme"
              class="btn-icon ml-1"
              style="font-size: 19px;"
              @click="onAddPathologistReplacement"
            >
              <b-icon icon="plus"></b-icon>
            </b-button>
          </div>
          <div class="kt-item-list mb-1">
            <div
              v-for="(pathologistReplacement, index) in pathologistReplacements"
              :key="pathologistReplacement.tempId"
              class="kt-item-list__item-wrapper"
              style="width: 100%"
            >
              <PathologistReplacement
                :pathologistReplacementProp="pathologistReplacement"
                :validationActiveProp="pathologistReplacementsValidation"
                :viewModeProp="false"
                :areDatesOverlappingProp="pathologistReplacement.areDatesOverlapping"
                @deletePathologistReplacement="onDeletePathologistReplacement(index)"
                @onUpdateParent="onUpdatePathologistReplacement(index, $event)"
                @updateIsValid="($event) => {
                  // search position
                  const validArrayPosition = pathologistReplacementsValidArray.reduce((pos, current, index) => {
                    if (pos !== null) {
                      return pos;
                    } else {
                      return current === pathologistReplacement.tempId ? index : null;
                    }
                  }, null);
                  // edit
                  if ($event !== false) {
                    if (validArrayPosition === null) pathologistReplacementsValidArray.push(pathologistReplacement.tempId);
                  } else {
                    if ($event !== null) pathologistReplacementsValidArray.splice(validArrayPosition, 1);
                  }
                }"
                @alert="(variant, strings) => $emit('alert', variant, strings)"
              />
            </div>
          </div>
        </b-col>

        <!-- sectors -->
        <b-col cols="12">
          <b-form-group
            v-if="sectorsOptions.length"
            :label="$t('pathologists.sectors')"
            :invalid-feedback="sectorsInvalidFeedback"
            :state="sectorsState"
          >
            <b-form-checkbox
              v-for="sector in sectorsOptions"
              v-show="sector.isSubscribed || (pathologistData && pathologistData.pathologistSectors.filter(v => v.sectorId === sector.id).length > 0)"
              :key="sector.id"
              v-model="sectors"
              :value="sector.id"
              :state="sectorsState"
            >
              {{ sector.localisedName }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('pathologists.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- registrationNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('pathologists.registrationNumber')"
            :invalid-feedback="registrationNumberInvalidFeedback"
            :state="registrationNumberState"
            :class="{'form-group--optional': pathologistReplacements.length}"
          >
            <b-form-input
              v-model="registrationNumber"
              trim
              maxlength="50"
              :state="registrationNumberState"
              @blur="registrationNumberValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- fullTitle -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('pathologists.fullTitle')"
            :invalid-feedback="fullTitleInvalidFeedback"
            :state="fullTitleState"
            class="form-group--optional"
          >
            <b-form-input
              v-model="fullTitle1"
              trim
              maxlength="50"
              :state="fullTitleState"
              @blur="fullTitleValidation = true"
            ></b-form-input>
            <b-form-input
              v-model="fullTitle2"
              trim
              maxlength="50"
              :state="fullTitleState"
              @blur="fullTitleValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- additionalFeeGroups -->
        <b-col cols="12">
          <b-form-group
            :label="$t('pathologists.additionalFeeGroups')"
            :invalid-feedback="additionalFeeGroupsInvalidFeedback"
            :state="additionalFeeGroupsState"
          >
            <!-- <div>
              <b-form-checkbox
                :value="true"
                disabled
              >
                {{ $t("additionalFeeGroups.defaultGroupName") }}
              </b-form-checkbox>
            </div> -->
            <div
              v-for="additionalFeeGroup in positiveAdditionalFeeGroupsOptions"
              :key="additionalFeeGroup.id"
            >
              <!-- checkbox-display-only -->
              <button
                v-show="additionalFeeGroup.isActive || positiveAdditionalFeeGroupId === additionalFeeGroup.id"
                class="kt-checkbox-display-only-cont"
                type="button"
                @click.prevent="() => {
                  if (positiveAdditionalFeeGroupId === additionalFeeGroup.id) {
                    positiveAdditionalFeeGroupId = null;
                  } else {
                    positiveAdditionalFeeGroupId = additionalFeeGroup.id;
                  }
                  if (pathologistData) pathologistData.pathologistAdditionalFeeGroups = [];
                }"
              >
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (positiveAdditionalFeeGroupId === additionalFeeGroup.id ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text font-weight-normal">{{ additionalFeeGroup.name }}</span>
              </button>
            </div>
            <div
              v-for="additionalFeeGroup in negativeAdditionalFeeGroupsOptions"
              :key="additionalFeeGroup.id"
            >
              <!-- checkbox-display-only -->
              <div
                v-show="additionalFeeGroup.isActive"
                class="kt-checkbox-display-only-cont"
              >
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + ' kt-checkbox-display-only--checked disabled'"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text font-weight-normal">{{ additionalFeeGroup.name }}</span>
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- signature image -->
        <b-col
          cols="12"
          md="4"
        >
          <InputImage
            class="mb-3"
            :labelProp="$t('pathologists.signature')"
            :altProp="$t('pathologists.signatureAlt')"
            :imageFileProp="signatureFile"
            :imageUrlProp="signatureUrl"
            :stateProp="signatureState"
            :invalidFeedbackProp="signatureInvalidFeedback"
            @updateImage="signatureFile = $event; signatureValidation = true;"
            @updateUrl="signatureUrl = $event; signatureValidation = true;"
          />
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputImage from "@shared/views/Helpers/InputImage";
import InputSearch from "@shared/views/Helpers/InputSearch";
import PathologistReplacement from "./PathologistReplacement";
// services
import pathologistsServices from "@/services/API/pathologistsServices";
import commonServices from "@shared/services/API/commonServices";
// helpers
import uniqueId from "lodash.uniqueid";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputImage, InputSearch, PathologistReplacement },
  mixins: [userRights, error, date],
  props: {
    pathologistIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      pathologistData: null,
      // general
      editMode: this.editModeProp,
      pathologistId: this.pathologistIdProp,
      // form variables
      pathologistReplacements: [],
      user: null,
      userId: null,
      userIsAvailable: true,
      code: "",
      registrationNumber: "",
      fullTitle1: "",
      fullTitle2: "",
      sectors: [],
      positiveAdditionalFeeGroupId: null,
      signatureUrl: null,
      signatureFile: null,
      // self validation
      pathologistReplacementsValidArray: [],
      // is validation active
      pathologistReplacementsValidation: false,
      userIdValidation: false,
      codeValidation: false,
      registrationNumberValidation: false,
      fullTitleValidation: false,
      sectorsValidation: false,
      additionalFeeGroupsValidation: false,
      signatureValidation: false,
      // options
      sectorsOptions: [],
      positiveAdditionalFeeGroupsOptions: [],
      negativeAdditionalFeeGroupsOptions: []
    };
  },
  computed: {
    // form validation
    userIdState: function() {
      if (!this.userIdValidation) return null;
      return this.userId && this.userId > 0 && this.userIsAvailable !== false ? null : false;
    },
    userIdInvalidFeedback: function() {
      if (this.userIsAvailable === false) return this.$t("pathologist.validations.userIsAlreadyUsed");
      return this.userIdState === false ? this.$t("validationRules.required") : "";
    },
    sectorsState: function() {
      if (!this.sectorsValidation) return null;
      return this.sectors.length > 0 ? null : false;
    },
    sectorsInvalidFeedback: function() {
      return this.sectorsState === false ? this.$t("validationRules.required") : "";
    },
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    registrationNumberState: function() {
      if (!this.registrationNumberValidation) return null;
      if (this.pathologistReplacements.length) return null;
      return this.registrationNumber && this.registrationNumber.length > 0 ? null : false;
    },
    registrationNumberInvalidFeedback: function() {
      return this.registrationNumberState === false ? this.$t("validationRules.required") : "";
    },
    fullTitleState: function() {
      return null;
    },
    fullTitleInvalidFeedback: function() {
      return "";
    },
    additionalFeeGroupsState: function() {
      if (!this.pathologistData) return null;
      const positiveAditionalFeeGroupIds = this.pathologistData.pathologistAdditionalFeeGroups.filter(v => v.additionalFeeGroup.rank > 0);
      return positiveAditionalFeeGroupIds.length > 1 ? false : null;
    },
    additionalFeeGroupsInvalidFeedback: function() {
      return this.$t("pathologist.validations.pathologistAdditionalFeesError");
    },
    signatureState: function() {
      return null;
    },
    signatureInvalidFeedback: function() {
      return "";
    },
    pathologistReplacementsState: function() {
      let state = null;
      for (const pathologistReplacement of this.pathologistReplacements) {
        if (!this.pathologistReplacementsValidArray.includes(pathologistReplacement.tempId)) {
          state = false;
          break;
        }
      }
      return state;
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("pathologistEdit.pageTitle") : this.$t("pathologistNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("pathologistEdit.submitText") : this.$t("pathologistNew.submitText");
    }
  },
  watch: {
    // check if the user is available
    userId: {
      handler: async function() {
        try {
          if (this.userId) {
            const res = await pathologistsServices.existsWithUser(this.pathologistIdProp, this.userId);
            this.userIsAvailable = !res.data;
          }
        } catch (err) {
          this.handleErrors(err);
        }
      }
    }
  },
  async mounted() {
    // import data
    this.importAdditionalFeeGroupsOptions();
    await this.importData();
    this.importSectors();

    // auto focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.pathologistNameInput.focusFirstElement();
    },
    // format user name
    suggestionFormatUsers(suggestion) {
      let output = "";
      if (suggestion) {
        output = suggestion.firstName + " " + suggestion.lastName + " (" + suggestion.email + ")";
        if (suggestion.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },

    // pathologistReplacements
    updateOverlappingDatesValidation() {
      // reset
      for (const pathologistReplacement of this.pathologistReplacements) {
        pathologistReplacement.areDatesOverlapping = false;
      }
      for (let i = 0; i < this.pathologistReplacements.length; i++) {
        if (!this.pathologistReplacements[i].replacedPathologistId) continue;
        for (let j = 0; j < this.pathologistReplacements.length; j++) {
          if (i !== j) {
            const validation = this.validateNonOverlappingPeriods(
              this.pathologistReplacements[i].startValidityDate,
              this.pathologistReplacements[i].endValidityDate,
              this.pathologistReplacements[j].startValidityDate,
              this.pathologistReplacements[j].endValidityDate
            );
            if (validation === false) {
              this.pathologistReplacements[i].areDatesOverlapping = true;
              this.pathologistReplacements[j].areDatesOverlapping = true;
              break;
            }
          }
        }
      }
      // force update
      this.pathologistReplacements.push();
    },
    onAddPathologistReplacement() {
      this.pathologistReplacements.push({
        id: null,
        tempId: "new-" + uniqueId(),
        areDatesOverlapping: false,
        replacedPathologistId: null,
        replacedPathologist: null,
        startValidityDate: this.getIsoDate(),
        endValidityDate: ""
      });
    },
    onDeletePathologistReplacement(position) {
      this.pathologistReplacements.splice(position, 1);
      this.updateOverlappingDatesValidation();
    },
    onUpdatePathologistReplacement(position, data) {
      this.pathologistReplacements[position] = data;
      this.updateOverlappingDatesValidation();
    },

    // import data
    importAdditionalFeeGroupsOptions() {
      let additionalFeeGroupsOptions = JSON.parse(JSON.stringify(this.$systemSettings.additionalFeeGroups));
      additionalFeeGroupsOptions = additionalFeeGroupsOptions.map((v) => {
        if (v.isActive === false) v.name += " (" + this.$t("general.deletedLabel") + ")";
        return v;
      });

      this.positiveAdditionalFeeGroupsOptions = additionalFeeGroupsOptions.filter(v => v.rank > 0);

      const negativeAdditionalFeeGroupsOptions = additionalFeeGroupsOptions.filter(v => v.rank < 0);
      negativeAdditionalFeeGroupsOptions.unshift({ isActive: true, id: null, name: this.$t("additionalFeeGroups.defaultGroupName"), rank: 0 });
      this.negativeAdditionalFeeGroupsOptions = negativeAdditionalFeeGroupsOptions;
    },
    async importData() {
      try {
        if (this.editModeProp) {
          // get pathologist
          const resServices = await commonServices.get("pathologists", this.pathologistId);
          this.pathologistData = resServices.data;
          // import data
          this.pathologistReplacements = (resServices.data.pathologistReplacements || []).map(v => {
            v.tempId = v.id;
            v.areDatesOverlapping = false;
            return v;
          });
          this.updateOverlappingDatesValidation();
          this.user = resServices.data.user;
          this.userId = resServices.data.userId;
          this.sectors = resServices.data.pathologistSectors.map((v) => v.sectorId);
          this.code = resServices.data.code;
          this.registrationNumber = resServices.data.registrationNumber;
          this.fullTitle1 = resServices.data.fullTitle.match(/\n/) ? resServices.data.fullTitle.split("\n")[0] : resServices.data.fullTitle;
          this.fullTitle2 = resServices.data.fullTitle.match(/\n.+/) ? resServices.data.fullTitle.split("\n")[1] : "";
          const positiveAdditionalFeeGroup = resServices.data.pathologistAdditionalFeeGroups.find((v) => v.additionalFeeGroup.rank > 0);
          this.positiveAdditionalFeeGroupId = positiveAdditionalFeeGroup ? positiveAdditionalFeeGroup.additionalFeeGroupId : null;
          // import signature
          if (resServices.data.signatureFileName) {
            const resSignature = await commonServices.getFile("pathologists", this.pathologistId);
            this.signatureFile = new File([resSignature.data], resServices.data.signatureFileName);
            this.signatureUrl = window.URL.createObjectURL(this.signatureFile);
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    importSectors() {
      this.sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
    },

    // format pathologist name
    suggestionFormatPathologists(suggestion) {
      if (!suggestion) return "";
      let output = suggestion.user.firstName + " " + suggestion.user.lastName + " (" + suggestion.code + ")";
      if (suggestion.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },

    // navigate to the entry page
    onGoBack() {
      navigate("administration/pathologists");
    },
    // submit
    async  onValidatePathologist() {
      this.pathologistReplacementsValidation = true;
      this.userIdValidation = true;
      this.codeValidation = true;
      this.registrationNumberValidation = true;
      this.fullTitleValidation = true;
      this.sectorsValidation = true;
      this.additionalFeeGroupsValidation = true;
      this.signatureValidation = true;
      await this.$nextTick();

      return !(
        this.pathologistReplacementsState === false ||
        this.userIdState === false ||
        this.codeState === false ||
        this.registrationNumberState === false ||
        this.fullTitleState === false ||
        this.sectorsState === false ||
        this.signatureState === false
      );
    },
    async onSavePathologist() {
      try {
        const additionalFeeGroupIds = this.negativeAdditionalFeeGroupsOptions.filter(v => v.rank !== 0).map(v => v.id);
        if (this.positiveAdditionalFeeGroupId) {
          additionalFeeGroupIds.push(this.positiveAdditionalFeeGroupId);
        }
        const form = {
          pathologistReplacements: this.pathologistReplacements.map(v => {
            return {
              replacedPathologistId: v.replacedPathologistId,
              startValidityDate: v.startValidityDate || null,
              endValidityDate: v.endValidityDate || null
            };
          }),
          userId: this.userId,
          sectorIds: this.sectors,
          code: this.code,
          registrationNumber: this.registrationNumber,
          fullTitle: this.fullTitle1 + (this.fullTitle2 ? "\n" + this.fullTitle2 : ""),
          additionalFeeGroupIds: additionalFeeGroupIds
        };
        if (this.editMode) {
          const res = await commonServices.put("pathologists", form, this.pathologistId);
          if (res.data === true) {
            if (await this.onSaveSignature()) this.successSave();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("pathologistEdit.notifications.editionErrorTitle"),
              message: this.$t("pathologistEdit.notifications.editionErrorText")
            });
          }
        } else {
          const res = await commonServices.post("pathologists", form);
          if (res.data.id) {
            this.pathologistId = res.data.id;
            if (await this.onSaveSignature()) this.successSave();
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("pathologistNew.notifications.additionErrorTitle"),
              message: this.$t("pathologistNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onSaveSignature() {
      try {
        const res = await commonServices.putFile("pathologists", this.pathologistId, this.signatureFile);
        if (res.data === true) {
          return true;
        } else {
          if (res.data.error === "fileExceedsMaximumSize") {
            this.$emit("alert", "danger", {
              title: this.$t("pathologistNew.notifications.fileExceedsMaximumSizeTitle"),
              message: this.$t("pathologistNew.notifications.fileExceedsMaximumSizeText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("pathologistNew.notifications.editionErrorTitle"),
              message: this.$t("pathologistNew.notifications.editionErrorText")
            });
          }
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave() {
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("pathologistEdit.notifications.editedTitle"),
          message: this.$t("pathologistEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("pathologistNew.notifications.addedTitle"),
          message: this.$t("pathologistNew.notifications.addedText")
        });
      }

      navigate("administration/pathologists");
    },
    async onSubmit() {
      if (await this.onValidatePathologist() !== false) await this.onSavePathologist();
    }
  }
};
</script>

export default {
  methods: {
    regNumberFormatter(value) {
      // format: X XX XX XX XXX XXX XX
      // format: 1 23 45 67 890 123 45
      // format: 012345678901234567890

      if (typeof value === "undefined" || value === null || value === "") {
        return "";
      }

      const trimmed = value.replace(/ /g, "");
      let formatted = "";

      if (trimmed) {
        // 1 length
        formatted = trimmed;
        // 2-3 length
        if (trimmed.length > 1) {
          formatted = formatted.slice(0, 1) + " " + trimmed.slice(1, trimmed.length);
        }
        // 4-5 length
        if (trimmed.length > 3) {
          formatted = formatted.slice(0, 4) + " " + trimmed.slice(3, trimmed.length);
        }
        // 6-7 length
        if (trimmed.length > 5) {
          formatted = formatted.slice(0, 7) + " " + trimmed.slice(5, trimmed.length);
        }
        // 8-10 length
        if (trimmed.length > 7) {
          formatted = formatted.slice(0, 10) + " " + trimmed.slice(7, trimmed.length);
        }
        // 11-13 length
        if (trimmed.length > 10) {
          formatted = formatted.slice(0, 14) + " " + trimmed.slice(10, trimmed.length);
        }
        // 14-15 length
        if (trimmed.length > 13) {
          formatted = formatted.slice(0, 18) + " " + trimmed.slice(13, trimmed.length);
        }
      }

      return formatted;
    }
  }
};

<template>
  <div class="kt-paymentReports mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("payments.paymentReports.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('accounting/payments/paymentReport/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("payments.paymentReports.addPaymentReportButton") }}
      </b-button>

      <!-- filter LaboratoryId -->
      <div v-if="laboratoriesOptions.length > 2">
        <div class="d-inline-block">
          <b-form-select
            v-model="filterLaboratoryId"
            class="mt-3"
            size="sm"
            :options="laboratoriesOptions"
            value-field="id"
            text-field="formattedName"
            @keydown.enter.exact="onPageInput"
            @input="searchHasChanged = true;"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- switcher organisation type -->
    <b-button-group
      class="kt-btn-group mb-3"
    >
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'clinics'])"
        class="kt-btn-group__btn"
        :class="(organisationTypeName === 'clinics') ? 'kt-btn-group__btn--active' : ''"
        variant="light"
        @click="changeOrganisationType('clinics')"
      >
        <b-icon icon="house"></b-icon>
        {{ $t("accountingSectionOrganisationTypes.clinics") }}
      </b-button>
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'healthCenters'])"
        class="kt-btn-group__btn"
        :class="(organisationTypeName === 'healthCenters') ? 'kt-btn-group__btn--active' : ''"
        variant="light"
        @click="changeOrganisationType('healthCenters')"
      >
        <b-icon icon="people"></b-icon>
        {{ $t("accountingSectionOrganisationTypes.healthCenters") }}
      </b-button>
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'hospitals'])"
        class="kt-btn-group__btn"
        :class="(organisationTypeName === 'hospitals') ? 'kt-btn-group__btn--active' : ''"
        variant="light"
        @click="changeOrganisationType('hospitals')"
      >
        <b-icon icon="building"></b-icon>
        {{ $t("accountingSectionOrganisationTypes.hospitals") }}
      </b-button>
    </b-button-group>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      table-class="m-0"
      hover
      :fields="fields"
      :items="paymentReports"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Reference -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="referenceFilter"
              v-model="filterReference"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterReference !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterReference')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filterOrganisation -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="organisations"
              searchedFieldProp="name"
              :organisationTypeNamesProp="['clinics', 'healthCenters', 'hospitals']"
              :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterOrganisation"
              :displayedValueProp="filterOrganisation ? filterOrganisation.name + ' (' + filterOrganisation.code + ')' + (filterOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.name + ' (' + suggestion.code + ')' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
              }"
              noWrapProp

              @onUpdateParent="onOrganisationChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter Bank -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="banks"
              searchedFieldProp="code"
              :searchedLaboratoriesProp="[]"
              :returnFieldsArrayProp="['id', 'code', 'name']"
              suggestionsKeyProp="id"
              :searchMinLengthProp="1"

              :valueProp="filterBank"
              :displayedValueProp="filterBank ? filterBank.code : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
              }"
              noWrapProp

              @onUpdateParent="onBankChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter OrderDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterOrderDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter PaymentDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputDateRange
              ref="filterRangeFilter"
              :valueProp="filterRange"
              class="kt-table__th-search-input"
              fullWidthProp
              @keydown.enter.exact="onPageInput"
              @change="filterRange = $event; searchHasChanged = true;"
              @submit="onPageInput"
            />
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- filter Reconciled -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 151px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- template paymentGroups
      <template v-slot:cell(paymentGroups)="dataGroups">
        <div
          class="kt-flex-table"
        >
          <div
            class="kt-flex-table__wrapper"
            role="table"
            :aria-label="$t('payments.paymentReports.paymentGroups')"
          >
            <div
              class="kt-flex-table__line kt-flex-table__line--header"
              role="rowgroup"
            >
              <div
                class="kt-flex-table__row first"
                role="columnheader"
              >
                {{ $t("payments.paymentReports.paidShort") }}
              </div>
              <div
                class="kt-flex-table__row"
                role="columnheader"
              >
                {{ $t("payments.paymentReports.reconciled") }}
              </div>
              <div
                class="kt-flex-table__row"
                role="columnheader"
              >
                {{ $t("payments.paymentReports.remaining") }}
              </div>
            </div>
            <div
              class="kt-flex-table__line kt-flex-table__line--regular"
              role="rowgroup"
            >
              <div
                class="kt-flex-table__row first"
                role="cell"
              >
                {{ dataGroups.item['paid'] ? $n(dataGroups.item['paid'] / 100, "currency") : $n(0, "currency") }}
              </div>
              <div
                class="kt-flex-table__row"
                role="cell"
              >
                {{ dataGroups.item['reconciled'] ? $n(dataGroups.item['reconciled'] / 100, "currency") : $n(0, "currency") }}
              </div>
              <div
                class="kt-flex-table__row"
                role="cell"
              >
                <span
                  :class="dataGroups.item['paid'] && dataGroups.item['reconciled'] && (dataGroups.item['paid'] - dataGroups.item['reconciled'] === 0) ? 'text-success' : 'text-danger'"
                >{{ $n((dataGroups.item['paid'] - dataGroups.item['reconciled']) / 100, "currency") }}</span>
              </div>
            </div>
          </div>
        </div>
      </template> -->
      <!-- template reconciled -->
      <template v-slot:cell(reconciled)="data">
        <ProgressBar
          :valueProp="importFormatPrice(data.item.reconciled)"
          :totalProp="importFormatPrice(data.item.paid)"
        />
      </template>
      <!-- cell template : action buttons -->
      <template v-slot:cell(paymentReportButtons)="data">
        <div style="width: 78px;">
          <!-- edit -->
          <b-button
            v-show="isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'edit'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="mr-1"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/paymentReport/paymentGroupsEdit', {paymentReportIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- option drop-down -->
          <div
            v-show="(isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toPdf'])) ||
              (isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toXlsx'])) ||
              (isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'delete']))"
            class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-1"
          >
            <b-button
              :ref="data.index === 0 ? 'lineDropdownButton_' + data.index : null"
              variant="outline-secondary"
              size="sm"
              class="btn-icon kt-dropdown-btn__switcher-btn"
              pill
            >
              <b-icon icon="three-dots-vertical"></b-icon>
            </b-button>
            <ul
              class="kt-dropdown-btn__list"
            >
              <li class="kt-dropdown-btn__shadow"></li>

              <!-- export paymentGroup PDF -->
              <li
                v-show="isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toPdf'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button"
                  @click="onExportPaymentReport(data.item, 'pdf')"
                >
                  <b-icon
                    icon="download"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReports.downloadPaymentReportPdf") }}
                </button>
              </li>
              <!-- export paymentGroup XLS -->
              <li
                v-show="isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toXlsx'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button"
                  @click="onExportPaymentReport(data.item, 'xlsx')"
                >
                  <b-icon
                    icon="table"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReports.downloadPaymentReportXlsx") }}
                </button>
              </li>
              <!-- delete paymentGroup -->
              <li
                v-show="isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'delete'])"
                class="kt-dropdown-btn__item"
              >
                <button
                  class="kt-dropdown-btn__button text-danger"
                  @click="onDeletePaymentReport(data.item)"
                >
                  <b-icon
                    icon="trash"
                    class="kt-dropdown-btn__icon"
                  ></b-icon>
                  {{ $t("payments.paymentReports.deletePaymentReport") }}
                </button>
              </li>

              <!-- <li class="kt-dropdown-btn__divider"></li> -->
            </ul>
          </div>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!paymentReports.length && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <b-button
        variant="outline-secondary"
        :disabled="loadingExtendSearch"
        @click="extendSearch"
      >
        <b-icon icon="chevron-down"></b-icon>
        <span>&nbsp;{{ $t('general.extendResults') }}</span>
      </b-button>
      <b-spinner
        v-show="loadingExtendSearch"
        class="ml-1"
        :class="'kt-spinner--' + $systemSettings.theme"
        small
      ></b-spinner>
    </div>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
import ProgressBar from "@shared/views/Helpers/ProgressBar";
import InputDateRange from "@shared/views/Helpers/InputDateRange";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { InputSearch, ProgressBar, InputDateRange },
  mixins: [userRights, error, price, areaOfAuthority, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      loadingExtendSearch: false,
      lastSearchFilters: null,
      lastSearchId: null,
      // organisationType
      organisationTypeName: "clinics",
      // table fields
      fields: [
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "reference",
          sortable: true,
          label: this.$t("payments.paymentReports.reference")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "organisation",
          sortable: true,
          label: this.$t("payments.paymentReports.organisationName"),
          formatter: (value, _key, _item) => {
            return value.name + " (" + value.code + ")" + (value.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "bank",
          sortable: true,
          label: this.$t("payments.paymentReports.bank"),
          formatter: (value, _key, _item) => {
            return value ? value.code : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "orderDate",
          sortable: true,
          label: this.$t("payments.paymentReports.orderDateShort"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentDate",
          sortable: true,
          label: this.$t("payments.paymentReports.paymentDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("payments.paymentReports.paid"),
          formatter: (value, _key, _item) => {
            return this.$n((this.importFormatPrice(value) || 0), "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thStyle: { width: "200px" },
          thClass: "kt-table__th",
          key: "reconciled",
          sortable: false,
          label: this.$t("payments.paymentReports.reconciled")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentReportButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterLaboratoryId: null,
      filterReference: "",
      filterOrganisation: null,
      filterBank: null,
      filterOrderDate: "",
      filterRange: { start: "", end: "" },
      // table items
      paymentReports: [],
      // options
      laboratoriesOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        organisationTypeName: "string",
        filterLaboratoryId: "number",
        filterReference: "string",
        filterOrganisation: { id: "number", code: "string", name: "string" },
        filterBank: { id: "number", code: "string", name: "string" },
        filterOrderDate: "string",
        filterRange: { start: "string", end: "string" }
      }
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterLaboratoryId ||
        this.filterReference ||
        this.filterOrganisation ||
        this.filterBank ||
        this.filterOrderDate ||
        this.filterRange
      );
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import laboratories
      this.importLaboratoriesOptions();

      // refresh table
      await this.onPageInput();

      // auto-focus
      if (this.$refs.addLineButton) {
        this.$refs.addLineButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.editLineButton_0) {
        this.$refs.editLineButton_0.focus();
      } else if (this.$refs.lineDropdownButton_0) {
        this.$refs.lineDropdownButton_0.focus();
      }
    },

    // import laboratories
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, formattedName: this.$tc("fileInvoiceTransformations.allLaboratories") });
      this.laboratoriesOptions = laboratories;
    },

    // organisationTypeName
    changeOrganisationType(organisationTypeName) {
      this.organisationTypeName = organisationTypeName;
      this.onPageInput();
    },

    // organisation
    onOrganisationChosen(organisation) {
      this.filterOrganisation = organisation ? { id: organisation.id, code: organisation.code, name: organisation.name } : null;
      this.searchHasChanged = true;
    },
    // bank
    onBankChosen(bank) {
      this.filterBank = bank ? { id: bank.id, code: bank.code, name: bank.name } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // update table
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (this.filterOrderDate !== "") {
          const filterOrderDate = new Date(this.filterOrderDate).getFullYear();
          if (filterOrderDate < 1900 || filterOrderDate > 2300) {
            return false;
          }
        }
        if (this.filterRange) {
          const filterStartDate = new Date(this.filterRange.start).getFullYear();
          if (filterStartDate < 1900 || filterStartDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        this.lastSearchFilters = {
          organisationType: this.organisationTypeName,
          laboratoryId: this.filterLaboratoryId,
          organisationId: this.filterOrganisation ? this.filterOrganisation.id : null,
          bankId: this.filterBank ? this.filterBank.id : null,
          orderDate: this.filterOrderDate,
          startDate: this.filterRange && this.filterRange.start ? this.filterRange.start : null,
          endDate: this.filterRange && this.filterRange.end ? this.filterRange.end : null,
          reference: this.filterReference
        };
        const res = await commonServices.getAll("paymentReports", this.lastSearchFilters);
        if (res.data && res.data.rows.length) {
          this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
        }
        this.paymentReports = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.paymentReports.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.referenceFilter.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async extendSearch() {
      try {
        this.loadingExtendFiles = true;

        this.lastSearchFilters.maxId = this.lastSearchId;

        // search and Update
        const res = await commonServices.getAll("paymentReports", this.lastSearchFilters);
        if (res.data && res.data.rows.length) {
          this.maxReached = res.data.maxReached;
          this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
          this.paymentReports.push(...res.data.rows);
        } else {
          this.maxReached = false;
        }

        this.loadingExtendFiles = false;
      } catch (err) {
        this.loadingExtendFiles = false;
        this.handleErrors(err);
      }
    },

    // delete
    async onDeletePaymentReport(row) {
      if (typeof row === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.paymentReportDelete.text"), {
        title: this.$t("payments.paymentReportDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("payments.paymentReportDelete.ok"),
        cancelTitle: this.$t("payments.paymentReportDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("paymentReports", row.id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("payments.paymentReportDelete.notifications.deletedTitle"),
            message: this.$t("payments.paymentReportDelete.notifications.deletedText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("payments.paymentReportDelete.notifications.deletedErrorTitle"),
            message: this.$t("payments.paymentReportDelete.notifications.deletedErrorText")
          });
        }
        this.onPageInput();
        this.$refs.referenceFilter.focus();
      }
    },
    // export
    async onExportPaymentReport(row, type) {
      try {
        const resFile = await commonServices.export("paymentReports", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.reference + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

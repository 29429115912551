<template>
  <div class="kt-file-entry pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("fileEntry.pageTitle") }}
      </h2>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0 mt-1 text-secondary">
        {{ $t('fileEntry.pageSubtitle') }}
      </p>
    </div>

    <!-- access number input -->
    <b-form @submit.prevent="searchAccessNumber">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('fileEntry.accessNumber')"
            :state="accessNumberState"
            :invalid-feedback="accessNumberInvalidFeedback"
          >
            <InputAccessNumber
              ref="accessNumberInput"
              v-model="accessNumber"
              :stateProp="accessNumberState"
              :maxlengthProp="50"
              trimProp
              @updateValidation="accessNumberValidatation = $event"
              @submit="searchAccessNumber"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- divider -->
    <div class="w-100 border-top mt-2 mb-2"></div>

    <!-- choose from Ids Modal -->
    <b-modal
      id="fileChooseFromIdsModal"
      size="m"
      hide-header
      hide-footer
      @shown="() => {
        if (fileOptions && fileOptions.length > 0) {
          $refs.chooseFromIdsComponent.focusFirstElement();
        }
      }"
    >
      <FileChooseFromIds
        v-if="fileOptions && fileOptions.length > 0"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="fileOptions"
        @chosen="($event !== null) ? navigate('office/file/edit', {fileIdProp: $event.id, accessNumberProp: $event.accessNumber}) : hideChooseModal()"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
import { navigate } from "@/services/UI/vueRouterServices";
import filesServices from "@/services/API/filesServices";
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
// components
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
import InputAccessNumber from "@shared/views/Helpers/InputAccessNumber";

export default {
  components: { FileChooseFromIds, InputAccessNumber },
  mixins: [userRights, error],
  data() {
    return {
      accessNumber: "",
      accessNumberValidatation: false,
      fileOptions: []
    };
  },
  computed: {
    // form validation
    accessNumberValidate: function() {
      if (!this.accessNumber) return "valid";
      for (const laboratory of this.$systemSettings.laboratories) {
        if (this.accessNumber.match(laboratory.accessNumberRegex)) {
          if (laboratory.disabled) {
            return "unallowedLaboratory";
          } else {
            return "valid";
          }
        }
      }
      return "invalid";
    },
    accessNumberState: function() {
      if (!this.accessNumber || !this.accessNumberValidatation) return null;
      if (this.accessNumberValidate === "valid") return null;
      return false;
    },
    accessNumberInvalidFeedback: function() {
      if (this.accessNumberState === null) return "";
      return this.accessNumberValidate === "unallowedLaboratory" ? this.$t("fileEntry.validations.unallowedLaboratory") : this.$t("fileEntry.validations.invalidAccessNumber");
    }
  },
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // auto focus
    if (this.$refs.accessNumberInput) {
      this.$refs.accessNumberInput.focusFirstElement();
    }
  },
  methods: {
    async searchAccessNumber() {
      try {
        this.accessNumberValidatation = true;
        if (this.accessNumber && this.accessNumberValidate === "valid") {
          const res = await filesServices.getAllByAccessNumber(this.accessNumber);
          if (res.data && res.data.length > 0) {
            if (res.data.length === 1) {
              navigate("office/file/edit", { fileIdProp: res.data[0].id, accessNumberProp: res.data[0].accessNumber });
            } else {
              this.fileOptions = res.data;
              this.$bvModal.show("fileChooseFromIdsModal");
            }
          } else if (res.data.error && res.data.error === "fileNotInLaboratory") {
            this.$emit("alert", "danger", {
              title: this.$t("fileEntry.notifications.fileNotInLaboratoryTitle"),
              message: this.$t("fileEntry.notifications.fileNotInLaboratoryText")
            });
          } else {
            navigate("office/file/new", { accessNumberProp: this.accessNumber });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    hideChooseModal() {
      this.$bvModal.hide("fileChooseFromIdsModal");
      this.fileOptions = [];
    }
  }
};
</script>

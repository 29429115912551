<template>
  <div class="kt-view-page kt-file-comments pb-4">
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        class="kt-view-page__section kt-view-page__section--smallMargins"
      >
        <!-- comments list -->
        <b-row
          v-for="commentItem in commentsList"
          :key="commentItem.id"
          class="kt-file-comments__item"
        >
          <!-- user -->
          <b-col
            cols="12"
            md="3"
          >
            <p class="mb-0">
              {{ commentItem.user ? commentItem.user.firstName + ' ' + commentItem.user.lastName + (commentItem.user.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") : '' }}
            </p>
          </b-col>
          <!-- date -->
          <b-col
            cols="12"
            md="3"
          >
            <p class="mb-0">
              {{ commentItem.timestamp ? getFormattedDateTimeFromTimestamp(commentItem.timestamp) : '' }}
            </p>
          </b-col>
          <!-- comment -->
          <b-col
            cols="12"
            md="6"
          >
            <p
              class="mb-0"
              style="white-space: pre-line; word-break: break-word;"
            >
              {{ commentItem.content }}
            </p>
            <span
              v-if="commentItem.user && commentItem.user.id === $userSettings.user.id"
            ><b-link
              class="kt-link py-1 pr-1 mr-1"
              :class="'kt-link--' + $systemSettings.theme"
              @click.prevent="openEditComment(commentItem)"
            >{{ $t("fileComments.editButton") }}</b-link><b-link
              class="kt-link p-1"
              :class="'kt-link--' + $systemSettings.theme"
              @click.prevent="deleteComment(commentItem.id)"
            >{{ $t("general.delete") }}</b-link></span>
          </b-col>
        </b-row>
        <!-- no comment -->
        <div
          v-show="!commentsList || !commentsList.length"
          class="text-dark text-15 mb-2 pb-1"
        >
          {{ $t("fileComments.noComment") }}
        </div>

        <div class="w-100 border-top mt-1 mb-3"></div>

        <!-- edit section -->
        <div>
          <!-- save title -->
          <div v-show="currentEditedComment === null">
            <h5>{{ $t("fileComments.saveTitle") }}</h5>
          </div>
          <!-- edit title -->
          <div v-show="currentEditedComment !== null">
            <h5 :class="currentEditedComment && currentEditedComment.timestamp ? 'mb-1' : ''">
              {{ $t("fileComments.editionTitle") }} <b-icon
                icon="pen"
                class="kt-file-comments__edit-icon"
              ></b-icon>
            </h5>
            <div>{{ currentEditedComment && currentEditedComment.timestamp ? getFormattedDateTimeFromTimestamp(currentEditedComment.timestamp) : '' }}</div>
          </div>
          <!-- content -->
          <b-textarea
            ref="textareaInput"
            v-model="content"
            class="mt-1"
            trim
            maxlength="256"
          ></b-textarea>
          <!-- cancel button -->
          <b-button
            v-show="currentEditedComment !== null"
            variant="outline-secondary"
            class="mt-1 mr-1"
            @click="currentEditedComment = null; content = '';"
          >
            {{ $t("general.cancel") }}
          </b-button>
          <!-- save button -->
          <b-button
            :variant="$systemSettings.theme"
            class="mt-1"
            @click="currentEditedComment !== null ? editComment() : saveComment()"
          >
            {{ currentEditedComment !== null ? $t("fileComments.editSubmitButton") : $t("fileComments.saveSubmitButton") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import fileCommentsServices from "@/services/API/fileCommentsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import uniqueId from "lodash.uniqueid";

export default {
  components: {},
  mixins: [userRights, error, date],
  props: {
    commentsBeforeCreationProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    fileIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      importedComments: null, // null for new file (no fileId), empty for edit file but no comment
      content: "",
      currentEditedComment: null
    };
  },
  computed: {
    commentsList: function() {
      return this.importedComments !== null ? this.importedComments : this.commentsBeforeCreationProp;
    }
  },
  watch: {
    // when fileId changes : import comments
    fileIdProp: {
      async handler() {
        try {
          await this.importComments();
        } catch (err) {
          this.handleErrors(err);
        }
      },
      immediate: true
    },
    // updateCommentsCount
    commentsBeforeCreationProp: {
      async handler() {
        if (!this.fileIdProp) {
          this.$emit("updateCommentsCount", this.commentsList.length);
        }
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.textareaInput.focus();
    },
    // import comments
    async importComments() {
      try {
        if (this.fileIdProp) {
          const res = await fileCommentsServices.getAllByFileId(this.fileIdProp);
          this.importedComments = res.data;
          // clear commentsBeforeCreation
          this.$emit("onExportCommentsBeforeCreation", []);
          this.$emit("updateCommentsCount", this.importedComments.length);
        } else {
          this.importedComments = null;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    openEditComment(comment) {
      this.currentEditedComment = comment;
      this.content = comment.content;
      this.focusFirstElement();
    },
    async editComment() {
      try {
        if (this.fileIdProp) {
          // EDIT FILE MODE
          const res = await commonServices.put(
            "fileComments",
            {
              fileId: this.fileIdProp,
              userId: this.$userSettings.user.id,
              content: this.content
            },
            this.currentEditedComment.id
          );
          if (res.data === true) {
            // success
            this.$emit("alert", "success", {
              title: this.$t("fileComments.notifications.editCommentSuccessTitle"),
              message: this.$t("fileComments.notifications.editCommentSuccessTitle")
            });
            // refresh comments
            this.currentEditedComment = null;
            this.content = "";
            await this.importComments();
            this.focusFirstElement();
            return true;
          } else {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("fileComments.notifications.editCommentErrorTitle"),
              message: this.$t("fileComments.notifications.editCommentErrorText")
            });
            return false;
          }
        } else {
          // NEW FILE MODE
          const exportArr = JSON.parse(JSON.stringify(this.commentsBeforeCreationProp));
          for (const item of exportArr) {
            if (item.id === this.currentEditedComment.id) {
              item.content = this.content;
              // success
              this.$emit("alert", "success", {
                title: this.$t("fileComments.notifications.editCommentSuccessTitle"),
                message: this.$t("fileComments.notifications.editCommentSuccessTitle")
              });
              // refresh comments
              this.currentEditedComment = null;
              this.content = "";
              this.$emit("onExportCommentsBeforeCreation", exportArr);
              this.focusFirstElement();
              return true;
            }
          }
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileComments.notifications.editCommentErrorTitle"),
            message: this.$t("fileComments.notifications.editCommentErrorText")
          });
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async saveComment() {
      try {
        if (this.fileIdProp) {
          // EDIT FILE MODE
          const res = await commonServices.post(
            "fileComments",
            {
              fileId: this.fileIdProp,
              userId: this.$userSettings.user.id,
              content: this.content
            }
          );
          if (res.data.id) {
            // success
            this.$emit("alert", "success", {
              title: this.$t("fileComments.notifications.saveCommentSuccessTitle"),
              message: this.$t("fileComments.notifications.saveCommentSuccessTitle")
            });
            // refresh comments
            this.content = "";
            await this.importComments();
            this.focusFirstElement();
            return true;
          } else {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("fileComments.notifications.saveCommentsErrorTitle"),
              message: this.$t("fileComments.notifications.saveCommentsErrorText")
            });
            return false;
          }
        } else {
          // NEW FILE MODE
          const exportArr = JSON.parse(JSON.stringify(this.commentsBeforeCreationProp));
          exportArr.push({
            id: Number(uniqueId()),
            userId: this.$userSettings.user.id,
            user: this.$userSettings.user,
            content: this.content
          });
          this.$emit("updateCommentsCount", exportArr.length);
          this.$emit("onExportCommentsBeforeCreation", exportArr);
          // success
          this.$emit("alert", "success", {
            title: this.$t("fileComments.notifications.saveCommentSuccessTitle"),
            message: this.$t("fileComments.notifications.saveCommentSuccessTitle")
          });
          // refresh comments
          this.content = "";
          await this.importComments();
          this.focusFirstElement();
          return true;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async deleteComment(id) {
      try {
        if (this.fileIdProp) {
          // EDIT FILE MODE
          const res = await commonServices.delete("fileComments", id);
          if (res.data === true) {
            // success
            this.$emit("alert", "success", {
              title: this.$t("fileComments.notifications.deleteCommentSuccessTitle"),
              message: this.$t("fileComments.notifications.deleteCommentSuccessTitle")
            });
            // refresh comments
            this.currentEditedComment = null;
            this.content = "";
            await this.importComments();
            this.focusFirstElement();
            return true;
          } else {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("fileComments.notifications.deleteCommentErrorTitle"),
              message: this.$t("fileComments.notifications.deleteCommentErrorText")
            });
            return false;
          }
        } else {
          // NEW FILE MODE
          const exportArr = JSON.parse(JSON.stringify(this.commentsBeforeCreationProp));
          for (let i = exportArr.length - 1; i >= 0; i--) {
            if (exportArr[i].id === id) {
              exportArr.splice(i, 1);
              // success
              this.$emit("alert", "success", {
                title: this.$t("fileComments.notifications.deleteCommentSuccessTitle"),
                message: this.$t("fileComments.notifications.deleteCommentSuccessTitle")
              });
              // refresh comments
              this.currentEditedComment = null;
              this.content = "";
              this.$emit("onExportCommentsBeforeCreation", exportArr);
              this.$emit("updateCommentsCount", this.commentsList.length);
              this.focusFirstElement();
              return true;
            }
          }
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileComments.notifications.deleteCommentErrorTitle"),
            message: this.$t("fileComments.notifications.deleteCommentErrorText")
          });
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    }
  }
};
</script>

<style scoped src="./FileComments.scss" lang="scss"></style>

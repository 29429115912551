<template>
  <div
    class="kt-view-page kt-contact-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("contactView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'office', 'contacts', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('office/contact/edit', {contactIdProp: contactId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("contactView.editContact") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0"></p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- isActive -->
      <div
        v-show="!isActive"
        class="kt-badge kt-badge--red kt-badge--lg"
      >
        <b-icon
          icon="trash"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
      </div>

      <!-- SECTION ID's-->
      <div
        v-if="fullNameDisplay ||
          codeDisplay ||
          registrationNumberDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- fullName -->
        <b-row v-if="fullNameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.fullName") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ sexLocalisedName + " " + firstName + " " + lastName }}
            </p>
          </b-col>
        </b-row>
        <!-- code -->
        <b-row v-if="codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
        <!-- registrationNumber -->
        <b-row v-if="registrationNumberDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.registrationNumber") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ registrationNumber }}
            </p>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="contactOrganisationsDisplay"
        class="w-100 border-top mt-1 mb-4"
      ></div>

      <!-- SECTION contactOrganisations -->
      <div
        v-if="contactOrganisationsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- contactOrganisations list -->
        <b-row v-if="contactOrganisationsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.organisations") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="contactOrganisations.length"
              class="kt-item-list"
            >
              <div
                v-for="contactOrganisationItem in contactOrganisations"
                :key="contactOrganisationItem.id"
                class="kt-item-list__item-wrapper"
              >
                <div class="kt-item-list__item">
                  <!-- title -->
                  <div class="kt-item-list__item-title">
                    {{ contactOrganisationItem.organisation.name }}
                  </div>
                  <!-- isActive -->
                  <div
                    v-show="contactOrganisationItem.isActive === false || contactOrganisationItem.organisation.isActive === false"
                    class="kt-badge kt-badge--red"
                  >
                    <b-icon
                      icon="trash"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
                  </div>
                  <!-- text -->
                  <div class="kt-item-list__item-text">
                    {{ contactOrganisationItem.organisation.code }}
                  </div>
                  <!-- organisationContactInformation -->
                  <div class="mt-2">
                    <span style="font-weight: 500;">{{ contactOrganisationItem.contactInformation.name }}</span>
                  </div>
                  <div
                    class="kt-item-list__item-text"
                    v-html="formatAddressSub(contactOrganisationItem.contactInformation)"
                  ></div>
                  <!-- deleted ContactInformation -->
                  <div
                    v-show="contactOrganisationItem.contactInformation.isActive === false"
                    class="kt-item-list__item-text text-danger mb-2 mt-1"
                  >
                    {{ $t("contacts.notifications.deletedOrganisationContactInformation") }}
                  </div>

                  <!-- labels -->
                  <div
                    v-show="contactOrganisationItem.isDefault && contactOrganisationItem.isActive !== false && contactOrganisationItem.organisation.isActive !== false"
                    class="kt-button-switchers"
                  >
                    <!-- is Default -->
                    <b-button
                      class="kt-button-switcher kt-button-switcher--active kt-button-switcher--no-link"
                      size="sm"
                      variant="light"
                      tabindex="-1"
                    >
                      <b-icon
                        icon="check"
                        class="kt-button-switcher__check"
                      ></b-icon>
                      {{ $t("contactInformations.isDefault") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="contactInformationsDisplay"
        class="w-100 border-top mt-1 mb-4"
      ></div>

      <!-- SECTION contactInformations-->
      <div
        v-if="contactInformationsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- contactInformations list -->
        <b-row v-if="contactInformationsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.contactInformations") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div
              v-if="contactContactInformations.length"
              class="kt-item-list"
            >
              <div
                v-for="contactContactInformationItem in contactContactInformations"
                :key="contactContactInformationItem.id"
                class="kt-item-list__item-wrapper"
              >
                <div class="kt-item-list__item">
                  <!-- title -->
                  <div class="kt-item-list__item-title">
                    {{ contactContactInformationItem.contactInformation.name }}
                  </div>
                  <!-- isActive -->
                  <div
                    v-show="contactContactInformationItem.contactInformation.isActive === false"
                    class="kt-badge kt-badge--red"
                  >
                    <b-icon
                      icon="trash"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
                  </div>
                  <!-- text -->
                  <div
                    class="kt-item-list__item-text"
                    v-html="formatAddressSub(contactContactInformationItem.contactInformation)"
                  ></div>
                  <!-- labels -->
                  <div
                    v-show="contactContactInformationItem.isDefault && contactContactInformationItem.contactInformation.isActive !== false"
                    class="kt-button-switchers"
                  >
                    <!-- is Default -->
                    <b-button
                      class="kt-button-switcher kt-button-switcher--active kt-button-switcher--no-link"
                      size="sm"
                      variant="light"
                      tabindex="-1"
                    >
                      <b-icon
                        icon="check"
                        class="kt-button-switcher__check"
                      ></b-icon>
                      {{ $t("contactInformations.isDefault") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="sendOptionsDisplay"
        class="w-100 border-top mt-1 mb-4"
      ></div>

      <!-- SECTION sendOptions-->
      <div
        v-if="sendOptionsDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- sendOptions -->
        <b-row v-if="sendOptionsDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.sendOptions") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <div v-if="sendByPost">
              {{ $t("contacts.sendByPost") }}
            </div>
            <div v-if="sendElectronically">
              {{ $t("contacts.sendElectronically") }}
            </div>
          </b-col>
        </b-row>
        <!-- securedEmails -->
        <b-row v-if="sendElectronically && securedEmails.length">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("contacts.securedEmails") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
            class="mb-3"
          >
            <div
              v-for="emailItem in securedEmails"
              :key="emailItem"
            >
              {{ emailItem }}
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- divider -->
      <div
        v-if="sendOptionsDisplay"
        class="w-100 border-top mt-1 mb-4"
      ></div>

      <!-- SECTION account -->
      <div
        v-if="accountDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- account -->
        <b-row v-if="accountDisplay">
          <b-col cols="12">
            <ContactAccount
              v-if="contactData"
              class="mb-2"
              :contactEditModeProp="true"
              :viewModeProp="true"
              :contactIdProp="contactId || null"
              :contactDataProp="{
                isActive: isActive,
                contactContactInformations: contactContactInformations,
                contactOrganisations: contactOrganisations,
                isLocked: isLocked,
                email: email,
                phone: phone
              }"
              :displayedFieldsProp="[
                'email',
                'phone'
              ]"
              moduleModeProp
              @contactAccountEdited="(awaitingAccountData) => {
                isLocked = awaitingAccountData.isLocked,
                email = awaitingAccountData.email,
                phone = awaitingAccountData.phone
              }"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import ContactAccount from "@/views/Office/Contacts/ContactAccount";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  components: {
    ContactAccount
  },
  mixins: [userRights, error, viewComponents, phoneNumber, address],
  props: {
    contactIdProp: {
      type: Number,
      default: null
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "fullName",
          "code",
          "registrationNumber",
          "contactInformations",
          "contactOrganisations",
          "sendOptions",
          "account"
        ];
      }
    }
  },
  data() {
    return {
      // general
      contactId: this.contactIdProp,
      contactData: null,
      // form account variables
      isLocked: false,
      email: "",
      phone: "",
      // base data
      isActive: true,
      sexLocalisedName: "",
      firstName: "",
      lastName: "",
      code: "",
      registrationNumber: "",
      contactContactInformations: [],
      contactOrganisations: [],
      sendByPost: false,
      sendElectronically: true,
      securedEmails: [],
      // dispayFields
      fullNameDisplay: false,
      codeDisplay: false,
      registrationNumberDisplay: false,
      contactInformationsDisplay: false,
      contactOrganisationsDisplay: false,
      sendOptionsDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get contact
      const resServices = await commonServices.get("contacts", this.contactId);
      this.contactData = resServices.data;
      // assign the data
      this.isActive = resServices.data.isActive;
      this.sexLocalisedName = this.$t("sexes." + resServices.data.sex.name);
      this.firstName = resServices.data.firstName;
      this.lastName = resServices.data.lastName;
      this.code = resServices.data.code;
      this.registrationNumber = resServices.data.registrationNumber;
      this.contactContactInformations = resServices.data.contactContactInformations;
      this.contactOrganisations = resServices.data.contactOrganisations || [];
      this.sendByPost = resServices.data.sendByPost;
      this.sendElectronically = resServices.data.sendElectronically;
      this.securedEmails = resServices.data.securedEmails;
      this.isLocked = resServices.data.isLocked;
      this.email = resServices.data.email;
      this.phone = resServices.data.phone;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("office/contacts");
    },

    formatAddressSub(obj) {
      let addressLine = this.formatAddress(obj.address1, obj.address2, obj.zip, obj.city, obj.country);
      if (obj.phone) addressLine += (addressLine !== "" ? "<br>" : "") + this.importFormatPhone(obj.phone);
      if (obj.mobile) addressLine += (!obj.phone && addressLine !== "" ? "<br>" : "") + (obj.phone ? " - " : "") + this.importFormatPhone(obj.mobile);
      if (obj.email) addressLine += (addressLine !== "" ? "<br>" : "") + obj.email;

      return addressLine;
    }
  }
};
</script>

export default {
  methods: {
    /**/
    /*// Birth Date
    /**/

    // @return Number 51
    getAgeYearFromBirthdate(birthdateInput) {
      const today = new Date();
      const birthDate = new Date(birthdateInput);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 0) {
        age = 0;
      }
      return age;
    },
    // @return "(51 ans)"
    getFormattedAgeFromBirthdate(birthdateInput) {
      var now = new Date();
      var nowYear = now.getYear();
      var nowMonth = now.getMonth();
      var nowDay = now.getDate();

      var birthdateObj = new Date(birthdateInput);
      var birthdateYear = birthdateObj.getYear();
      var birthdateMonth = birthdateObj.getMonth();
      var birthdateDate = birthdateObj.getDate();

      var yearAge = 0;
      var monthAge = 0;
      var dayAge = 0;

      var ageString = "";

      // ages calculation
      yearAge = nowYear - birthdateYear;

      if (nowMonth >= birthdateMonth) {
        monthAge = nowMonth - birthdateMonth;
      } else {
        yearAge--;
        monthAge = 12 + nowMonth - birthdateMonth;
      }

      if (nowDay >= birthdateDate) {
        dayAge = nowDay - birthdateDate;
      } else {
        monthAge--;
        dayAge = 31 + nowDay - birthdateDate;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      // output string set up
      if (yearAge === 0 && monthAge === 0 && dayAge >= 0) {
        // few days old / today
        ageString = this.$tc("birthdayAge.days", dayAge, { age: dayAge });
      } else if (yearAge === 0 && monthAge > 0) {
        // few months old
        ageString = this.$tc("birthdayAge.months", monthAge, { age: monthAge });
      } else if (yearAge > 0) {
        // few years old
        ageString = this.$tc("birthdayAge.years", yearAge, { age: yearAge });
      } else {
        ageString = "invalid date of birth";
      }

      return ageString;
    },

    // Unused for now, improve this function and replace the no-UTC function
    // today's date minus 10 years is not the exact same result as minus 5 years because of UTC calculation
    getAgeFromBirthdateUtc(birthdateInput) {
      const birthdateDateObj = new Date(birthdateInput);
      let diff = Date.now() - birthdateDateObj.getTime();
      diff = diff - 86400000; // minus one day in ms

      let ageString = "";
      if (diff >= 0) {
        const ageDateObj = new Date(diff);

        const ageYears = ageDateObj.getUTCFullYear() - 1970;
        const ageMonths = ageDateObj.getUTCMonth();
        const ageDays = ageDateObj.getUTCDate() - 1;

        // output string set up
        if (ageYears === 0 && ageMonths === 0 && ageDays) {
          // few days old
          ageString = ageDays + " jours";
        } else if (ageYears === 0 && ageMonths > 0) {
          // few months old
          ageString = ageMonths + " mois";
        } else if (ageYears > 0) {
          // few years old
          ageString = ageYears + " ans";
        } else {
          // error
          ageString = "invalid date of birth";
        }
      } else {
        ageString = "invalid date of birth";
      }

      return ageString;
    },

    // @return "30/12/1970 (51 ans)"
    getFormattedBirthdateAge(birthdateInput) {
      let birthdateAge = "";
      if (birthdateInput) {
        birthdateAge = this.$d(new Date(birthdateInput), "date") + " " + this.getFormattedAgeFromBirthdate(birthdateInput);
      } else {
        birthdateAge = "-";
      }
      return birthdateAge;
    },

    /**/
    /*// DateTime
    /**/

    // @return "30/12" or "30/12/1970" or "30/12/1970 15:20"
    getFormattedDateTimeFromTimestamp(timestamp, displayTime = true, displayYear = true, displaySeconds = false) {
      const date = this.dateStringIntoObject(timestamp);
      if (!date) return "";

      let day = date.getDate();
      day = (day < 10 ? "0" : "") + day;
      let month = date.getMonth() + 1;
      month = (month < 10 ? "0" : "") + month;

      let hour = "";
      let min = "";
      let sec = "";
      if (displayTime) {
        hour = date.getHours();
        hour = (hour < 10 ? "0" : "") + hour;
        min = date.getMinutes();
        min = (min < 10 ? "0" : "") + min;
        if (displaySeconds) {
          sec = date.getSeconds();
          sec = (sec < 10 ? "0" : "") + sec;
        }
      }

      let year = "";
      if (displayYear) {
        year = date.getFullYear();
      }

      return day + "/" + month + (displayYear ? "/" + year : "") + (displayTime ? " " + hour + ":" + min : "") + (displayTime && displaySeconds ? ":" + sec : "");
    },

    // @return "today" or "yesterday" or "30 décembre" or "30 décembre 1970" or "30 décembre 1970 15:20"
    getFormattedAlphabeticalDateTimeFromTimestamp(timestamp, lang = "en", options = {}) {
      const defaults = {
        displayTime: false,
        displayYear: null, // null for display when different from current year, true to force display, false to force hide
        shortToday: true,
        shortYesterday: true
      };
      const filteredOptions = Object.assign({}, defaults, options);

      const dateObject = this.dateStringIntoObject(timestamp);
      if (!dateObject) return "";

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const dateDay = dateObject.getDate();
      const dateMonth = dateObject.getMonth();
      const dateYear = dateObject.getFullYear();
      let output = "";

      if (
        // today
        filteredOptions.shortToday === true &&
        dateDay === today.getDate() &&
        dateMonth === today.getMonth() &&
        dateYear === today.getFullYear()
      ) {
        output = this.$t("date.today");
      } else if (
        // yesterday
        filteredOptions.shortYesterday === true &&
        dateDay === yesterday.getDate() &&
        dateMonth === yesterday.getMonth() &&
        dateYear === yesterday.getFullYear()
      ) {
        output = this.$t("date.yesterday");
      } else {
        // setup dateStringOptions
        const dateStringOptions = {
          month: "long",
          day: "numeric"
        };
        // setup displayYear
        if (
          filteredOptions.displayYear === true ||
          (filteredOptions.displayYear === null && dateYear !== today.getFullYear())
        ) {
          dateStringOptions.year = "numeric";
        }
        // string date
        output = dateObject.toLocaleString(lang, dateStringOptions);
      }

      if (filteredOptions.displayTime) {
        let hour = "";
        let min = "";
        hour = dateObject.getHours();
        hour = (hour < 10 ? "0" : "") + hour;
        min = dateObject.getMinutes();
        min = (min < 10 ? "0" : "") + min;
        output += " " + hour + ":" + min;
      }

      return output;
    },

    /* getFormattedAlphabeticalDateTimeFromTimestamp(timestamp, lang = "en", displayTime = true, displayYear = true) {
      const date = this.dateStringIntoObject(timestamp);
      if (date === false) return "";

      const dayWeekNumber = date.getDay();
      const dayWeek = this.$t("date.days." + dayWeekNumber);
      const dayNumber = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const month = this.$t("date.months." + monthNumber);

      let hour = "";
      let min = "";
      if (displayTime) {
        hour = date.getHours();
        hour = (hour < 10 ? "0" : "") + hour;
        min = date.getMinutes();
        min = (min < 10 ? "0" : "") + min;
      }

      let year = "";
      if (displayYear) {
        year = date.getFullYear();
      }

      return dayWeek + " " + dayNumber + " " + month + (displayYear ? " " + year : "") + (displayTime ? " " + hour + ":" + min : "");
    },*/

    /**/
    /*// Helpers
    /**/

    // Convert string into Date object
    // @input:date (String) : date to convert (required)
    // @return converted date (Date Object) || false (error)
    dateStringIntoObject(date) {
      let output = null;

      if (!date) {
        // Do nothing
      } else if (typeof date === "string") {
        // format the ISO date "yyyy-mm-dd..."" into "yyyy/mm/dd..."" to prevent Date object to parse as UTC
        //if (date.match(/^\d{4}-\d{2}-\d{2}/)) {
        //  date = date.slice(0, 4) + "/" + date.slice(5, 7) + "/" + date.slice(8, date.length);
        //}
        output = new Date(date);
      } else {
        output = date;
      }

      // impossible to build
      if (!(output instanceof Date) || isNaN(output)) {
        output = null;
      }

      return output;
    },

    getIsoDate(shiftDays = 0, shiftMonth = 0, shiftYear = 0, fromIsoDate = "") {
      const date = fromIsoDate ? new Date(fromIsoDate) : new Date();
      if (shiftDays) date.setDate(date.getDate() + shiftDays);
      if (shiftMonth) date.setMonth(date.getMonth() + shiftMonth);
      if (shiftYear) date.setFullYear(date.getFullYear() + shiftYear);
      const dateMonth = date.getMonth() + 1;
      const dateDay = date.getDate();
      return date.getFullYear() + "-" + (dateMonth < 10 ? "0" : "") + dateMonth + "-" + (dateDay < 10 ? "0" : "") + dateDay;
    },
    getIsoDateFromDate(date) {
      const dateMonth = date.getMonth() + 1;
      const dateDay = date.getDate();
      return date.getFullYear() + "-" + (dateMonth < 10 ? "0" : "") + dateMonth + "-" + (dateDay < 10 ? "0" : "") + dateDay;
    },

    /**/
    /*// date validations
    /**/

    // works only for dates (without time)
    // info : 200 years = 73049 days, 100 years = 36525 days, 10 years = 3653 days
    validatePastDate(date = "", todayAccepted = true, limitInDays = 73049) {
      let output = true;

      // setup now date
      const nowDate = new Date();
      const now = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth(),
        day: nowDate.getDate()
      };

      // setup date object
      const inputDate = this.dateStringIntoObject(date);
      // no valid date
      if (!inputDate) return false;
      date = {
        year: inputDate.getFullYear(),
        month: inputDate.getMonth(),
        day: inputDate.getDate()
      };

      if ( // check today
        date.year === now.year &&
        date.month === now.month &&
        date.day === now.day
      ) {
        output = todayAccepted;
      } else if (
        // check future year
        (date.year > now.year) ||
        // check future month
        (date.year === now.year &&
        date.month > now.month) ||
        // check future day
        (date.year === now.year &&
        date.month === now.month &&
        date.day > now.day)
      ) {
        output = false;
      }

      // check limit
      if (output && limitInDays) {
        const limitDate = new Date(new Date().getTime() - (limitInDays * 24 * 60 * 60 * 1000));
        const limit = {
          year: limitDate.getFullYear(),
          month: limitDate.getMonth(),
          day: limitDate.getDate()
        };
        if (
          (date.year < limit.year) || // check past year
          (date.year === limit.year && date.month < limit.month) || // check past month
          (date.year === limit.year && date.month === limit.month && date.day < limit.day) // check past day
        ) {
          output = false;
        }
      }

      return output;
    },
    // works only for dates (without time)
    // info : 200 years = 73048 days, 100 years = 36524 days, 10 years = 3652 days
    validateFutureDate(date = "", todayAccepted = true, limitInDays = 73049) {
      let output = true;

      // setup now date
      const nowDate = new Date();
      const now = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth(),
        day: nowDate.getDate()
      };

      // setup date object
      const inputDate = this.dateStringIntoObject(date);
      // no valid date
      if (!inputDate) return false;
      date = {
        year: inputDate.getFullYear(),
        month: inputDate.getMonth(),
        day: inputDate.getDate()
      };

      if ( // check today
        date.year === now.year &&
        date.month === now.month &&
        date.day === now.day
      ) {
        output = todayAccepted;
      } else if ( // check past year
        date.year < now.year
      ) {
        output = false;
      } else if ( // check past month
        date.year === now.year &&
        date.month < now.month
      ) {
        output = false;
      } else if ( // check past day
        date.year === now.year &&
        date.month === now.month &&
        date.day < now.day
      ) {
        output = false;
      }

      // check limit
      if (output && limitInDays) {
        const limitDate = new Date(new Date().getTime() - (limitInDays * 24 * 60 * 60 * 1000));
        const limit = {
          year: limitDate.getFullYear(),
          month: limitDate.getMonth(),
          day: limitDate.getDate()
        };
        if (
          (date.year > limit.year) || // check future year
          (date.year === limit.year && date.month > limit.month) || // check future month
          (date.year === limit.year && date.month === limit.month && date.day > limit.day) // check future day
        ) {
          output = false;
        }
      }

      return output;
    },
    // works only for dates (without time)
    validateDateBeforeDate(date1 = "", date2 = "", allowEqualDates = true) {
      // validate dates format
      if (
        !date1 ||
        /^[12]\d{3}-\d{2}-\d{2}$/.test(date1) !== true ||
        !date2 ||
        /^[12]\d{3}-\d{2}-\d{2}$/.test(date2) !== true
      ) {
        return false;
      }

      const diff = date1.replace(/-/g, "") - date2.replace(/-/g, "");
      if (allowEqualDates) {
        return diff <= 0;
      } else {
        return diff < 0;
      }
    },
    // works only for Timestamps
    validateTimestampBeforeTimestamp(timestamp1 = "", timestamp2 = "", allowEqualDates = true) {
      // validate dates format
      if (!timestamp1 || !timestamp2) {
        return null;
      }

      const diff = new Date(timestamp1) - new Date(timestamp2);
      if (allowEqualDates) {
        return diff <= 0;
      } else {
        return diff < 0;
      }
    },
    // works only for periods with dates (without time)
    // @return true if non-overlapping | null if error | false if overlapping
    validateNonOverlappingPeriods(start1 = "", end1 = "", start2 = "", end2 = "") {
      // validate dates format
      if (!start1 || !start2) return null;
      // format dates
      start1 = (start1 || "").replace(/-/g, "");
      start2 = (start2 || "").replace(/-/g, "");
      end1 = (end1 || "").replace(/-/g, "");
      end2 = (end2 || "").replace(/-/g, "");
      // validate same start
      if (start1 === start2) return false;
      // firstDate lastDate
      let firstDate;
      let lastDate;
      if (start1 < start2) {
        firstDate = { start: start1, end: end1 };
        lastDate = { start: start2, end: end2 };
      } else {
        firstDate = { start: start2, end: end2 };
        lastDate = { start: start1, end: end1 };
      }
      // validate no endings
      if (!firstDate.end) return false;
      if (!lastDate.end) return firstDate.end < lastDate.start;

      // validate others
      return lastDate.start > firstDate.end;
    }
  }
};

<template>
  <b-button
    class="kt-pin"
    :class="{'kt-pin--active': pined}"
    variant="light"
    @click="() => {
      if (pined) {
        unpin();
      } else if (valueProp !== null) {
        pin();
      }
    }"
  >
    <svg
      viewBox="0 0 151 253"
      class="kt-pin__icon"
      fill="currentColor"
      width="0.6em"
      height="1em"
      focusable="false"
      role="img"
      aria-label="pin"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          d="M124.776,51.000 C124.776,52.119 124.778,82.884 124.776,84.000 C124.772,88.898 150.680,97.371 150.658,102.000 C150.602,113.826 150.658,125.676 150.658,132.000 C150.658,144.640 108.615,146.000 88.941,146.000 C88.941,172.218 88.941,199.963 88.941,222.000 C80.261,243.640 76.000,253.000 76.000,253.000 C76.000,253.000 71.719,243.640 63.000,222.000 C63.000,199.963 63.000,172.218 63.000,146.000 C43.235,146.000 1.000,144.640 1.000,132.000 C1.000,125.676 1.056,113.826 1.000,102.000 C0.978,97.371 27.005,88.898 27.000,84.000 C26.999,82.884 27.000,52.119 27.000,51.000 C26.999,45.682 0.633,50.051 0.652,44.896 C0.701,31.491 1.000,24.331 1.000,17.000 C1.000,5.481 34.500,0.000 76.000,0.000 C117.311,0.000 150.658,5.481 150.658,17.000 C150.658,24.331 150.956,31.491 151.004,44.896 C151.023,50.051 124.777,45.682 124.776,51.000 Z"
        />
      </g>
    </svg>
  </b-button>
</template>

<script>
export default {
  props: {
    // savedPathProp: {
    //   type: String,
    //   default: ""
    // },
    savedValueProp: {
      type: [Boolean, String, Number, Object, Array],
      default: null
    },
    valueProp: {
      type: [Boolean, String, Number, Object, Array],
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    pined: function() {
      return this.savedValueProp !== null;
    }
  },
  watch: {
    valueProp: {
      handler(value) {
        // update saved value
        if (this.pined) {
          this.$emit("saveValue", value);
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.pined) {
      this.$emit("importValue", this.savedValueProp);
    }
  },
  methods: {
    pin() {
      this.$emit("saveValue", this.valueProp);
    },
    unpin() {
      this.$emit("unsaveValue", true);
    }

    // resolve(obj, path) {
    //   return path.split(".").reduce(function(prev, curr) {
    //     return prev ? prev[curr] : null;
    //   }, obj || self);
    // }
  }
};
</script>

<style scoped src="./Pin.scss" lang="scss"></style>

export default {
  methods: {
    getLocalisedHpvResult(content = "") {
      let translated = "";
      if (content === "") {
        translated = this.$t("medicalReports.file.hpvResultWaiting");
      } else if (content === "-") {
        translated = this.$t("medicalReports.file.hpvResultNegative");
      } else if (content === "INDETERMINATE") {
        translated = this.$t("medicalReports.file.hpvResultIndeterminate");
      } else {
        translated = this.hpvResultsProp[this.hpvResultsProp.length - 1].content;
      }
      return translated;
    }
  }
};

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  /**
   * @param {number} id Fee note ID
   * @param {boolean} isRetained True if fee note reminders should be blocked, true otherwise
   */
  setIsRetained(id, isRetained) {
    return axiosServices.axios.put("/feeNotes/setIsRetained", { id: id, isRetained: isRetained });
  }
};

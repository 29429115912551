<template>
  <b-form-textarea
    v-model="val"
    :style="computedStyles"
    :disabled="disabled"
    :autofocus="autofocus"
    :trim="trim"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :rows="rows"
    :state="state"
    :maxlength="maxlength"
    @focus="resize"
    @keydown.enter.exact.prevent
    @keyup.enter.exact="submitFunction"
    @keydown.enter.shift.exact="newline"
  ></b-form-textarea>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    minHeight: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 216
    },
    submitFunction: {
      type: Function,
      default: function() {
        return false;
      }
    },
    // Force !important for style properties
    important: {
      type: [Boolean, Array],
      default: false
    },
    // b-form-textarea properties
    disabled: {
      type: Boolean
    },
    autofocus: {
      type: Boolean
    },
    trim: {
      type: Boolean
    },
    autocomplete: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    rows: {
      type: [String, Number],
      default: 1
    },
    state: {
      type: [Boolean],
      default: null
    },
    maxlength: {
      type: Number,
      default: 512
    }
  },
  data() {
    return {
      val: "",
      // works when content height becomes more then value of the maxHeight property
      maxHeightScroll: false,
      height: "auto"
    };
  },
  computed: {
    computedStyles() {
      let overflow = "auto";
      if (!this.maxHeightScroll) {
        overflow = !this.isOverflowImportant ? "hidden" : "hidden !important";
      }
      return {
        resize: !this.isResizeImportant ? "none" : "none !important",
        height: this.height,
        overflow: overflow
      };
    },
    isResizeImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes("resize"));
    },
    isOverflowImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes("overflow"));
    },
    isHeightImportant() {
      const imp = this.important;
      return imp === true || (Array.isArray(imp) && imp.includes("height"));
    }
  },
  watch: {
    value(val) {
      this.val = val;
    },
    val(val) {
      this.$nextTick(this.resize);
      this.$emit("input", val);
    },
    minHeight() {
      this.$nextTick(this.resize);
    },
    maxHeight() {
      this.$nextTick(this.resize);
    }
  },
  created() {
    this.val = this.value;
  },
  mounted() {
    // force rows to 1
    if (this.rows === 1 || this.rows === "1") {
      this.$el.setAttribute("rows", "1");
    }
    // init resize
    this.resize();
  },
  methods: {
    resize() {
      const important = this.isHeightImportant ? "important" : "";
      this.height = "auto" + (important ? " !important" : "");
      this.$nextTick(() => {
        let contentHeight = this.$el.scrollHeight + 1;
        if (this.minHeight) {
          contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight;
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        contentHeight = contentHeight < 36 ? 36 : contentHeight;
        const heightVal = contentHeight + "px";
        this.height = heightVal + (important ? " !important" : "");
      });
      return this;
    },
    newline() {
      this.val = `${this.val}`;
    }
  }
};
</script>

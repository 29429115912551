const recommendationsTable = {
  lessThan30: {
    organised: {
      noHpv: {
        fns: ["reco1"],
        nilm: ["reco10"],
        asch: ["reco8"],
        lsil: ["reco8"],
        hsil: ["reco8"],
        ais: ["reco8"],
        scc: ["reco8"]
      },
      hpv: {
        hpvPositive: {
          ascus: ["reco8"],
          agc: ["reco11"]
        },
        hpvNegative: {
          ascus: ["reco10"],
          agc: ["reco10"]
        }
      }
    },
    individual: {
      hpv: {
        hpvPositive: {
          fns: ["reco1"],
          nilm: ["reco6"],
          ascus: ["reco8"],
          agc: ["reco11"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        },
        hpvNegative: {
          fns: ["reco1"],
          nilm: ["reco10"],
          ascus: ["reco10"],
          agc: ["reco10"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        }
      }
    },
    control: {
      hpv: {
        hpvPositive: {
          fns: ["reco1"],
          nilm: ["reco6"],
          ascus: ["reco8"],
          agc: ["reco11"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        },
        hpvNegative: {
          fns: ["reco1"],
          nilm: ["reco10"],
          ascus: ["reco10"],
          agc: ["reco10"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        }
      }
    }
  },
  over30: {
    organised: {
      hpv: {
        hpvPositive: {
          fns: ["reco1"],
          nilm: ["reco6"],
          ascus: ["reco8"],
          agc: ["reco8"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        },
        hpvNegative: {
          noCytology: ["reco5"]
        }
      }
    },
    individual: {
      hpv: {
        hpvPositive: {
          fns: ["reco1"],
          nilm: ["reco6"],
          ascus: ["reco8"],
          agc: ["reco8"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        },
        hpvNegative: {
          fns: ["reco1"],
          nilm: ["reco5"],
          ascus: ["reco10"],
          agc: ["reco12"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        }
      }
    },
    control: {
      hpv: {
        hpvPositive: {
          fns: ["reco1"],
          nilm: ["reco6"],
          ascus: ["reco8"],
          agc: ["reco8"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        },
        hpvNegative: {
          fns: ["reco1"],
          nilm: ["reco5"],
          ascus: ["reco10"],
          agc: ["reco12"],
          asch: ["reco8"],
          lsil: ["reco8"],
          hsil: ["reco8"],
          ais: ["reco8"],
          scc: ["reco8"]
        }
      }
    }
  }
};

module.exports.getRecommendations = function(lessThan30, screening, isHpv, hpvResult, cytologyResult) {
  let returnValue = [];
  let filterChoice = recommendationsTable;
  if (lessThan30) { filterChoice = filterChoice.lessThan30; } else { filterChoice = filterChoice.over30; }
  if (filterChoice) { filterChoice = filterChoice[screening]; }
  if (filterChoice) { if (isHpv) { filterChoice = filterChoice.hpv[hpvResult]; } else { filterChoice = filterChoice.noHpv; } }
  if (filterChoice) { returnValue = filterChoice[cytologyResult] ? filterChoice[cytologyResult] : []; }
  return returnValue;
};

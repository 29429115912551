<template>
  <div
    :class="[
      'kt-progress-bar',
      'kt-progress-bar--' + $systemSettings.theme,
      progressClass
    ]"
  >
    <div class="kt-progress-bar__amounts">
      <div class="kt-progress-bar__amount mr-3">
        {{ value !== null ? $n((value || 0), "currency") : '' }}
      </div>
      <div class="kt-progress-bar__amount">
        {{ remaining !== null ? $n((remaining), "currency") : $t("general.emptyWithHyphen") }}
      </div>
    </div>
    <div class="kt-progress-bar__bg">
      <div
        class="kt-progress-bar__fill"
        :style="{width: progress > 100 ? '100%' : progress + '%'}"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalProp: {
      type: Number,
      default: null
    },
    valueProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      total: this.totalProp,
      value: this.valueProp
    };
  },
  computed: {
    remaining() {
      if (this.total && this.value) {
        return this.total - this.value;
      } else {
        return null;
      }
    },
    progressClass() {
      if (!this.total) {
        return "kt-progress-bar--neutral";
      } else if (this.progress <= 0) {
        return "kt-progress-bar--neutral";
      } else if (this.progress === 100) {
        return "kt-progress-bar--success";
      } else if (this.progress > 100) {
        return "kt-progress-bar--error";
      } else {
        return "kt-progress-bar--neutral";
      }
    },
    progress() {
      if (!this.total) {
        return 0;
      } else if (!this.value || (this.value / this.total < 0)) {
        return 0;
      } else {
        return (this.value / this.total) * 100;
      }
    }
  },
  watch: {
    totalProp: {
      handler(value) {
        this.total = value;
      }
    },
    valueProp: {
      handler(value) {
        this.value = value;
      }
    }
  },
  methods: {
  }
};
</script>

<style scoped src="./ProgressBar.scss" lang="scss"></style>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  postAll(filter, callback) {
    return axiosServices.axios.post("/balanceReports/postAll", { filter: filter }, { onDownloadProgress: callback });
  },
  async getBalanceReport(fileName) {
    return axiosServices.axios.get("/balanceReports/getBalanceReport", {
      params: {
        fileName: fileName
      },
      responseType: "blob"
    });
  }
};

<template>
  <div class="kt-users">
    <!-- UserRequests -->
    <UserRequests
      @userAdded="onUserAdded($event)"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("users.usersTableTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'administration', 'users', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="onAddUser"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("users.addUserButton") }}
      </b-button>
    </div>
    <!-- users table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      :hover="isEnabled(['menu', 'administration', 'users', 'edit'])"
      responsive
      :fields="formattedFields"
      :items="users"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      :selectable="isEnabled(['menu', 'administration', 'users', 'edit'])"
      select-mode="single"
      @row-selected="onUserRowSelected"
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter laboratories -->
          <b-th
            v-show="$systemSettings.availableLaboratories.length > 1"
            class="kt-table__th-search"
            style="width: 12%"
          >
            <b-form-input
              v-model="filterLaboratories"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterLaboratories !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLaboratories')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>

          <!-- filter First Name -->
          <b-th
            class="kt-table__th-search"
            style="width: 23.5%"
          >
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Last Name -->
          <b-th
            class="kt-table__th-search"
            style="width: 23.5%"
          >
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Email -->
          <b-th
            class="kt-table__th-search"
            style="width: 33.5%"
          >
            <b-form-input
              v-model="filterEmail"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterEmail !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterEmail')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>

          <!-- active -->
          <b-th
            class="kt-table__th-search"
            style="width: 108px"
          >
            <button
              class="kt-checkbox-display-only-cont"
              @click="onClickUsersActiveCheckbox"
              @keydown.space.prevent="onClickUsersActiveCheckbox"
              @keydown.tab.exact.prevent="focusFirstLine"
            >
              <span
                class="kt-checkbox-display-only"
                :class="'kt-checkbox-display-only--' + $systemSettings.theme + isActiveFilterCheckboxClass"
              >
                <span class="kt-checkbox-display-only__cross"></span>
              </span>
              <span class="kt-checkbox-display-only__text">{{ !filterActive && filterNotActive ? $t("users.notActiveUsers") : $t("users.activeUsers") }}</span>
            </button>
          </b-th>
        </b-tr>
      </template>

      <!-- isActive -->
      <template v-slot:cell(isActive)="data">
        <span
          v-show="data.item['isActive']"
          class="kt-table__cell-check kt-table__cell-check--success"
        >
          <b-icon icon="check2"></b-icon>
        </span>
        <span
          v-show="!data.item['isActive']"
          class="kt-table__cell-check kt-table__cell-check--danger"
        >
          <b-icon icon="x"></b-icon>
        </span>
      </template>

      <!-- loader -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>
    <!-- no result -->
    <h4
      v-show="!usersCount && !loading && (filterFirstName || filterLastName || filterEmail || filterLaboratories || !filterActive || !filterNotActive)"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>
    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar-nav>
        <b-pagination
          v-show="usersCount"
          v-model="page"
          class="mx-2 my-3"
          :class="'kt-pagination--' + $systemSettings.theme"
          :total-rows="usersCount"
          per-page="50"
          @input="onPageInput"
        ></b-pagination>
      </b-navbar-nav>
    </div>

    <!-- ADD / EDIT MODAL -->
    <b-modal
      ref="userEditModal"
      hide-footer
      @shown="() => {
        $refs.userEditComponent.focusFirstElement()
      }"
      @hidden="onUserEditHidden"
    >
      <template #modal-title>
        <b-icon icon="person-fill"></b-icon>
        {{ !selectedUserData ? $t("userNew.pageTitle") : (selectedUserData ? selectedUserData.firstName + " " + selectedUserData.lastName : "") }}
      </template>

      <UserEdit
        ref="userEditComponent"
        :userIdProp="selectedUserData ? selectedUserData.id : null"
        :userDataProp="selectedUserData"

        :editModeProp="!!selectedUserData"
        :isActiveEditRightsProp="!selectedUserData || $userSettings.user.email !== selectedUserData.email"
        :isUserRequestProp="false"
        moduleModeProp
        @userEdited="onUserEdited($event)"
        @userAdded="onUserAdded"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import UserRequests from "@/views/Administration/Users/UserRequests.vue";
import UserEdit from "@/views/Administration/Users/UserEdit.vue";
// services
import usersServices from "@/services/API/usersServices";
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";

export default {
  components: { UserEdit, UserRequests },
  mixins: [userRights, pagination, error, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      usersCount: 0,
      page: 1,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "userLaboratories",
          sortable: false,
          label: this.$t("users.laboratories"),
          formatter: (value, _key, _item) => {
            return value
              .map((userLaboratory) => {
                return userLaboratory.laboratory.name + (userLaboratory.laboratory.isActive ? "" : " (" + this.$t("general.deletedLabel") + ")");
              })
              .join(", ");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: true,
          label: this.$t("users.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: true,
          label: this.$t("users.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "email",
          sortable: true,
          label: this.$t("users.email")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "isActive",
          sortable: true,
          label: this.$t("users.isActive")
        }
      ],
      // table filters
      filterLaboratories: "",
      filterFirstName: "",
      filterLastName: "",
      filterEmail: "",
      filterActive: true,
      filterNotActive: true,
      sortBy: "",
      sortDesc: false,
      // table items
      users: [],
      // edit user data
      selectedUserData: null,
      // saveParamsConfig
      saveParamsConfig: {
        filterLaboratories: "string",
        filterFirstName: "string",
        filterLastName: "string",
        filterEmail: "string",
        filterActive: "boolean",
        filterNotActive: "boolean"
      }
    };
  },
  computed: {
    isActiveFilterCheckboxClass: function() {
      if (this.filterActive && this.filterNotActive) {
        return "";
      } else if (this.filterActive && !this.filterNotActive) {
        return " kt-checkbox-display-only--checked";
      } else if (!this.filterActive && this.filterNotActive) {
        return " kt-checkbox-display-only--crossed";
      }
      return "";
    },
    formattedFields: function() {
      if (this.$systemSettings.availableLaboratories.length > 1) {
        return this.fields;
      } else {
        return this.fields.filter(v => v.key !== "userLaboratories");
      }
    }
  },
  watch: {
    filterActive: {
      handler() {
        this.onPageInput();
      }
    },
    filterNotActive: {
      handler() {
        this.onPageInput();
      }
    }
  },
  async mounted() {
    await this.onPageInput();

    // auto-focus
    this.$refs.addLineButton.focus();
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },

    // active filter
    onClickUsersActiveCheckbox() {
      if (this.filterActive && this.filterNotActive) {
        this.filterActive = true;
        this.filterNotActive = false;
      } else if (this.filterActive && !this.filterNotActive) {
        this.filterActive = false;
        this.filterNotActive = true;
      } else if (!this.filterActive && this.filterNotActive) {
        this.filterActive = true;
        this.filterNotActive = true;
      }
    },

    // search update Table
    async onPageInput() {
      try {
        this.loading = true;

        const filters = {
          firstName: this.filterFirstName,
          lastName: this.filterLastName,
          email: this.filterEmail,
          laboratories: this.$systemSettings.availableLaboratories.length > 1 ? this.filterLaboratories : "",
          active: this.filterActive,
          notActive: this.filterNotActive
        };
        const res = await commonServices.showAll(
          "users",
          this.page,
          filters,
          {
            by: this.sortBy,
            desc: this.sortDesc
          }
        );
        this.usersCount = res.data.count;
        this.users = res.data.rows;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onPageInput();
      }, 450);
    },

    onAddUser() {
      // setup props
      this.selectedUserData = null;
      // show modal
      this.$refs.userEditModal.show();
    },
    async onUserRowSelected(row) {
      try {
        if (row && row.length > 0 && this.isEnabled(["menu", "administration", "users", "edit"])) {
          // get user data
          const res = await usersServices.getByEmail(row[0].email);
          // setup props
          this.selectedUserData = res.data;
          // show modal
          this.$refs.userEditModal.show();
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // EDITED - ADDED functions
    async onUserAdded(userData) {
      // hide modal
      this.$refs.userEditModal.hide();
      // success alert
      this.$emit("alert", "success", {
        title: this.$t("users.added.title"),
        message: this.$t("users.added.text", {
          firstName: userData.firstName,
          lastName: userData.lastName
        })
      });
      // reset modal
      this.selectedUserData = null;
      this.$refs.table.clearSelected();
      // reload table
      await this.onPageInput();
      // auto-focus
      this.$nextTick(() => {
        this.$refs.addLineButton.focus();
      });
    },
    async onUserEdited(user) {
      // hide modal
      this.$refs.userEditModal.hide();
      // success alert
      this.$emit("alert", "success", {
        title: this.$t("users.edited.title"),
        message: this.$t("users.edited.text", {
          firstName: user.firstName,
          lastName: user.lastName
        })
      });
      // update currentUser
      if (user.id === this.$userSettings.user.id) {
        this.$userSettings.user = user;
      }
      // reset modal
      this.selectedUserData = null;
      this.$refs.table.clearSelected();
      // reload table
      await this.onPageInput();
      // auto-focus
      this.$nextTick(() => {
        this.$refs.addLineButton.focus();
      });
    },
    // clear table selection after closing userEdit
    onUserEditHidden() {
      // hide modal
      this.$refs.userEditModal.hide();
      // reset modal
      this.selectedUserData = null;
      this.$refs.table.clearSelected();
    }
  }
};
</script>

<template>
  <div>
    <!-- actions -->
    <div
      v-if="!viewModeProp"
      class="kt-item-list__item-actions"
    >
      <b-button
        v-show="isEnabled(['menu', 'office', 'files', 'containerGroups', 'delete'])"
        class="btn-icon kt-item-list__item-delete"
        size="sm"
        variant="light"
        squared
        @click="onDeleteContainerGroup"
      >
        <b-icon icon="x"></b-icon>
      </b-button>
    </div>

    <b-row>
      <!-- collectionDate - edit -->
      <b-col
        v-if="displayCollectionDateProp"
        v-show="!viewModeProp"
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('files.collectionDate')"
          :state="collectionDateStateDisplay"
          :invalid-feedback="collectionDateInvalidFeedback"
        >
          <b-form-input
            ref="collectionDateInput"
            v-model="collectionDate"
            type="date"
            :state="collectionDateState"
            @input="onInput('collectionDate')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- collectionDate - view -->
      <b-col
        v-if="displayCollectionDateProp"
        v-show="viewModeProp"
        cols="12"
        md="3"
      >
        <label>{{ $t("files.collectionDate") }}</label>
      </b-col>
      <b-col
        v-if="displayCollectionDateProp"
        v-show="viewModeProp"
        cols="12"
        md="9"
      >
        <p class="mb-0">
          {{ collectionDate ? $d(new Date(collectionDate), 'date') : $t("general.emptyWithHyphen") }}
        </p>
      </b-col>

      <!-- containers - list -->
      <b-col cols="12">
        <b-form-group
          :label="$t('files.containers')"
          :state="containersStateDisplay"
          :invalid-feedback="containersInvalidFeedback"
        >
          <Containers
            :containersProp="containers || []"

            :fileCreationDateProp="fileCreationDateProp"
            :laboratoryIdProp="laboratoryIdProp"
            :sectorIdProp="sectorIdProp"
            :viewModeProp="viewModeProp"
            :displayContainerTypeProp="displayContainerTypeProp"

            @containersUpdate="onContainersUpdate($event)"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-form-group>
      </b-col>

      <!-- containerGroupTasks - list -->
      <b-col cols="12">
        <b-form-group
          :label="$t('files.containerGroupTasks')"
          :state="containerGroupTasksStateDisplay"
          :invalid-feedback="containerGroupTasksInvalidFeedback"
          class="mb-0"
        >
          <ContainerGroupTasks
            :viewModeProp="viewModeProp"
            :containerGroupTasksProp="containerGroupTasks"
            :fileCreationDateProp="fileCreationDateProp"
            :sectorIdProp="sectorIdProp"
            @containerGroupTasksUpdate="onContainerGroupTasksUpdate($event)"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></ContainerGroupTasks>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// components
import Containers from "@/views/Data/Containers";
import ContainerGroupTasks from "@/views/Data/ContainerGroupTasks";
// helpers
import userRights from "@/services/UI/userRights";
import date from "@shared/services/UI/date";

export default {
  components: {
    Containers,
    ContainerGroupTasks
  },
  mixins: [userRights, date],
  props: {
    containerGroupProp: {
      type: Object,
      default: null,
      deep: true
    },
    isValidationActiveProp: {
      type: Boolean
    },
    containerGroupRequiredProp: {
      type: Boolean
    },

    fileCreationDateProp: {
      type: String,
      default: ""
    },
    laboratoryIdProp: {
      type: [Number, null],
      default: null
    },
    sectorIdProp: {
      type: [Number, null],
      default: null
    },
    displayCollectionDateProp: {
      type: Boolean
    },
    displayContainerTypeProp: {
      type: Boolean
    },
    // true when the component is not editable
    viewModeProp: {
      type: Boolean
    },
    // true when editing an existing file, false when creating a new file
    fileEditModeProp: {
      type: [Boolean, null],
      default: null
    }
  },
  data() {
    return {
      // form variables
      containerGroupId: null,
      containerGroupTempId: null,
      collectionDate: "",
      containers: [],
      containerGroupTasks: [],
      // is validation active
      collectionDateValidation: false,
      containersValidation: false,
      containerGroupTasksValidation: false
    };
  },
  computed: {
    collectionDateState: function() {
      // not required
      if (!this.displayCollectionDateProp && !this.containerGroupRequiredProp && !this.collectionDate) return null;
      // required
      if (!this.collectionDate) return false;
      // is past date
      if (!this.validatePastDate(this.collectionDate)) return false;
      // else
      return null;
    },
    collectionDateStateDisplay: function() {
      if (!this.collectionDateValidation) return null;
      return this.collectionDateState;
    },
    collectionDateInvalidFeedback: function() {
      // no error
      if (this.collectionDateStateDisplay === null) return "";
      // required
      if (!this.collectionDate) return this.$t("validationRules.required");
      // not past date
      return this.$t("fileEdit.validations.collectionDateInvalid");
    },
    isValidContainersSample: function() {
      let isValid = true;
      for (const container of this.containers) {
        if (!container.sampleId) isValid = false;
      }
      return isValid;
    },
    isValidContainersContainerType: function() {
      let isValid = true;
      if (this.displayContainerTypeProp) {
        for (const container of this.containers) {
          if (!container.containerTypeId) isValid = false;
        }
      }
      return isValid;
    },
    containersState: function() {
      // not required
      if (!this.containerGroupRequiredProp && (!this.containers || !this.containers.length)) return null;
      // required
      if (!this.containers || !this.containers.length) return false;
      // too much containers for cytology
      if (this.sectorIdProp === 1 && this.containers.length > 1) return false;
      // a sample is required for each containers
      if (!this.isValidContainersSample) return false;
      // a containerType is required for each containers
      if (!this.isValidContainersContainerType) return false;
      return null;
    },
    containersStateDisplay: function() {
      if (!this.containersValidation) return null;
      return this.containersState;
    },
    containersInvalidFeedback: function() {
      // no error
      if (this.containersStateDisplay === null) return "";
      // required
      if (!this.containers || !this.containers.length) return this.$t("fileEdit.validations.containersRequired");
      // too much containers for cytology
      if (this.sectorIdProp === 1 && this.containers.length > 1) return this.$t("fileEdit.validations.cytologyOnlyOneContainer");
      // a sample is required for each containers
      if (!this.isValidContainersSample) return this.$t("fileEdit.validations.containerSampleRequired");
      // a containerType is required for each containers
      return this.$t("fileEdit.validations.containerTypeRequired");
    },
    containerGroupTasksState: function() {
      // not required
      if (!this.containerGroupRequiredProp && (!this.containerGroupTasks || !this.containerGroupTasks.length)) return null;
      // required
      if (!this.containerGroupTasks || !this.containerGroupTasks.length) return false;
      // too much tasks for cytology
      if (this.sectorIdProp === 1 && this.containerGroupTasks.length > 1) return false;
      // else
      return null;
    },
    containerGroupTasksStateDisplay: function() {
      if (!this.containerGroupTasksValidation) return null;
      return this.containerGroupTasksState;
    },
    containerGroupTasksInvalidFeedback: function() {
      // no error
      if (this.containerGroupTasksStateDisplay === null) return "";
      // required
      if (!this.containerGroupTasks || !this.containerGroupTasks.length) {
        return this.$t("fileEdit.validations.containerGroupTasksRequired");
      }
      // too much tasks for cytology
      return this.$t("fileEdit.validations.cytologyOnlyOneContainerGroupTasks");
    },

    // formatted variables
    contentFormatted: function() {
      return {
        id: this.containerGroupId,
        tempId: this.containerGroupTempId,
        collectionDate: this.collectionDate,
        containers: this.containers,
        containerGroupTasks: this.containerGroupTasks
      };
    },
    isValid: function() {
      return this.collectionDateState !== false &&
        this.containersState !== false &&
        this.containerGroupTasksState !== false;
    }
  },
  watch: {
    // update value
    containerGroupProp: {
      handler(val) {
        if (val !== null) {
          const tempValue = JSON.parse(JSON.stringify(val)) || {};
          this.containerGroupId = tempValue.id || null;
          this.containerGroupTempId = tempValue.tempId || null;
          this.collectionDate = tempValue.collectionDate || "";
          this.containers = tempValue.containers || [];
          this.containerGroupTasks = tempValue.containerGroupTasks || [];
        } else {
          this.containerGroupId = null;
          this.containerGroupTempId = null;
          this.collectionDate = "";
          this.containers = [];
          this.containerGroupTasks = [];
        }
        this.sendIsValid();
      },
      deep: true,
      immediate: true
    },
    // update validation
    isValidationActiveProp: {
      handler(val) {
        this.changeAllValidations(Boolean(val));
        this.sendIsValid();
      },
      immediate: true
    },
    containerGroupRequiredProp: {
      handler() {
        this.sendIsValid();
      }
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    focusFirstElement() {
      this.$refs.collectionDateInput.focus();
    },
    onInput(name) {
      this[name + "Validation"] = true;
      this.sendToparent();
    },
    // edit (containers)
    onContainersUpdate(containers) {
      // don't activate the validation (onInput) because of the default container onload
      this.containers = containers;
      this.sendToparent();
    },
    // edit (tasks)
    onContainerGroupTasksUpdate(containerGroupTasks) {
      this.containerGroupTasks = containerGroupTasks;
      this.onInput("containerGroupTasks");
    },
    // delete
    onDeleteContainerGroup() {
      this.$emit("containerGroupDeleted", true);
    },
    // submit
    changeAllValidations(value) {
      this.collectionDateValidation = value;
      this.containersValidation = value;
      this.containerGroupTasksValidation = value;
    },
    sendToparent() {
      this.$emit("containerGroupUpdated", this.contentFormatted);
      this.sendIsValid();
    },
    sendIsValid() {
      this.$emit("updateIsValid", this.isValid);
    }
  }
};
</script>

<template>
  <div class="kt-choose-patient">
    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="patientFields"
      :items="patients"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onPatientSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter birthdate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="filterBirthdateInput"
              v-model="filterBirthdate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="inputChange"
            ></b-form-input>
          </b-th>
          <!-- filter lastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="inputChange"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter firstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="inputChange"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter maidenName -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- filter regNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputRegistrationNumber
              class="kt-table__th-search-regNumber"
              :regNumberProp="filterRegNumber"
              @onUpdateParent="filterRegNumber = $event.regNumber"
              @submit="searchAndUpdateTable"
              @input="inputChange"
            ></InputRegistrationNumber>
            <b-button
              v-show="filterRegNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterRegNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter zip -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterZip"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="inputChange"
            ></b-form-input>
            <b-button
              v-show="filterZip !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterZip')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            :class="{'p-0': !searchHasChanged}"
            style="width: 0;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap"
              @click="searchAndUpdateTable(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- regNumber -->
      <template v-slot:cell(regNumber)="data">
        <div v-show="data.item.regNumber">
          <span style="white-space: nowrap;">{{ regNumberFormatter(data.item.regNumber) }}</span>
        </div>
        <div v-show="data.item.insureeRegNumber">
          <span style="white-space: nowrap;">{{ regNumberFormatter(data.item.insureeRegNumber) }}</span>
          <span>{{ " " + $t("patients.insureeTag") }}</span>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="(!patients || !patients.length) && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      <h4>{{ $t("patientChoose.notFound") }}</h4>
      <!-- add patient button -->
      <b-button
        v-if="$systemSettings.application === 'main' && isEnabled(['roleSpecific', 'office'])"
        ref="addPatientButton"
        :variant="$systemSettings.theme"
        @click="onOpenAddPatient"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("patientChoose.createNewPatient") }}
      </b-button>
    </div>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3 mb-2"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import InputRegistrationNumber from "@shared/views/Helpers/InputRegistrationNumber";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import regNumber from "@shared/services/UI/regNumber";

export default {
  components: { InputRegistrationNumber },
  mixins: [userRights, error, regNumber],
  props: {
    initialFilterValuesProp: {
      type: Object,
      default: function() {
        return {
          birthdate: "",
          firstName: "",
          lastName: "",
          regNumber: "",
          zip: ""
        };
      }
    }
  },
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      // table fields
      patientFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "birthdate",
          sortable: false,
          label: this.$t("patients.birthdate"),
          formatter: (value, _key, _item) => {
            return (value ? this.$d(new Date(value), "date") : "");
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: false,
          label: this.$t("patients.lastName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: false,
          label: this.$t("patients.firstName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "maidenName",
          sortable: false,
          label: this.$t("patients.maidenName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "regNumber",
          sortable: true,
          label: this.$t("patients.regNumber"),
          formatter: (_value, _key, item) => {
            return item.regNumber + item.insureeRegNumber;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "zip",
          sortable: true,
          label: this.$t("patients.zip")
        },
        {
          tdClass: "kt-table__td pl-0",
          thClass: "kt-table__th pl-0",
          key: "patientButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterBirthdate: this.initialFilterValuesProp.birthdate || "",
      filterFirstName: this.initialFilterValuesProp.firstName || "",
      filterLastName: this.initialFilterValuesProp.lastName || "",
      filterRegNumber: this.initialFilterValuesProp.regNumber || "",
      filterZip: this.initialFilterValuesProp.zip || "",
      // table items
      patients: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterBirthdate || this.filterFirstName || this.filterLastName || this.filterRegNumber || this.filterZip
      );
    }
  },
  async mounted() {
    await this.searchAndUpdateTable();
  },
  methods: {
    focusFirstElement() {
      this.$refs.filterBirthdateInput.$el.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      } else if (this.$refs.addPatientButton) {
        this.$refs.addPatientButton.focus();
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // update the table
    async searchAndUpdateTable(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        if (this.isSearchActive) {
          this.$emit("searched", true);
        }

        // if no filter
        if (this.isSearchActive === false) {
          this.patients = [];
          this.searchHasChanged = false;
          this.maxReached = false;
          return false;
        }

        // validation dates
        if (this.filterBirthdate !== "") {
          const filterBirthdate = new Date(this.filterBirthdate).getFullYear();
          if (filterBirthdate < 1900 || filterBirthdate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          birthdate: this.filterBirthdate,
          firstName: this.filterFirstName,
          lastName: this.filterLastName,
          regNumber: this.filterRegNumber,
          zip: this.filterZip
        };
        if (this.$systemSettings.application === "prescribers") {
          filters.contactId = this.$userSettings.user.id;
        }
        const res = await commonServices.getAll("patients", filters);
        this.patients = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.patients.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.filterBirthdateInput.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onPatientSelected(rows) {
      if (rows && rows.length === 1) {
        this.$emit("chosen", rows[0]);
      }
    },
    onOpenAddPatient() {
      this.$emit("openAddItem");
    },
    inputChange() {
      this.searchHasChanged = true;
      this.$emit("updateInitialFilterValues", {
        birthdate: this.filterBirthdate,
        firstName: this.filterFirstName,
        lastName: this.filterLastName,
        regNumber: this.filterRegNumber,
        zip: this.filterZip
      });
    }
  }
};
</script>

<template>
  <div class="kt-main-nav">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <!-- MENU FOR ADMIN ONLY -->
          <div
            v-if="isEnabled(['roleSpecific', 'userAdministration'])"
            class="kt-main-nav__wrapper"
          >
            <nav :aria-label="$t('menu.general.mainMenu')">
              <!-- system -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'system'])"
                class="my-2"
                :variant="showSystemSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('system')"
              >
                <b-icon icon="bar-chart"></b-icon>
                {{ $t("menu.general.system") }}
              </b-button>
              <!-- reports -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'reports'])"
                class="my-2"
                :class="isEnabled(['menu', 'general', 'system']) ? 'ml-2' : ''"
                :variant="showReportsSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('reports')"
              >
                <b-icon icon="bar-chart-fill"></b-icon>
                {{ $t("menu.general.reports") }}
              </b-button>
              <!-- administration -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'administration'])"
                class="my-2 ml-2"
                :variant="showAdministrationSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('administration')"
              >
                <b-icon icon="tools"></b-icon>
                {{ $t("menu.general.administration") }}
              </b-button>
              <!-- data -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'data'])"
                class="my-2 ml-2"
                :variant="showDataSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('data')"
              >
                <b-icon icon="archive"></b-icon>
                {{ $t("menu.general.data") }}
              </b-button>
              <!-- office -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'office'])"
                class="my-2 ml-2"
                :variant="showOfficeSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('office')"
              >
                <b-icon icon="laptop"></b-icon>
                {{ $t("menu.general.office") }}
              </b-button>
              <!-- diagnosis -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'diagnosis'])"
                class="my-2 ml-2"
                :variant="showDiagnosisSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('diagnosis')"
              >
                <b-icon icon="bullseye"></b-icon>
                {{ $t("menu.general.diagnosis") }}
              </b-button>
              <!-- accounting -->
              <b-button
                v-show="isEnabled(['menu', 'general', 'accounting'])"
                class="my-2 ml-2"
                :variant="showAccountingSubmenu ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="onDisplaySubmenu('accounting')"
              >
                <b-icon icon="wallet"></b-icon>
                {{ $t("menu.general.accounting") }}
              </b-button>
            </nav>
          </div>

          <!-- MENU FOR OFFICE ONLY -->
          <div
            v-else-if="isEnabled(['roleSpecific', 'office'])"
            class="kt-main-nav__wrapper py-2"
          >
            <nav :aria-label="$t('menu.general.mainMenu')">
              <!-- fileEntry -->
              <b-button
                v-show="isEnabled(['menu', 'office', 'general', 'files'])"
                :variant="$route.name === 'office/fileEntry' ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('office/fileEntry')"
              >
                <b-icon icon="upc"></b-icon>
                {{ $t("menu.office.fileEntry") }}
              </b-button>
              <!-- files -->
              <b-button
                v-show="isEnabled(['menu', 'office', 'general', 'files'])"
                class="ml-2"
                :variant="$route.name.startsWith('office/file') && $route.name !== 'office/fileEntry' ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('office/files')"
              >
                <b-icon icon="folder"></b-icon>
                {{ $t("menu.office.files") }}
              </b-button>
              <!-- contacts -->
              <b-button
                v-show="isEnabled(['menu', 'office', 'general', 'contacts'])"
                class="ml-2"
                :variant="$route.name.startsWith('office/contact') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('office/contacts')"
              >
                <b-icon icon="person-fill"></b-icon>
                {{ $t("menu.office.contacts") }}
              </b-button>
              <!-- patients -->
              <b-button
                v-show="isEnabled(['menu', 'office', 'general', 'patients'])"
                class="ml-2"
                :variant="$route.name.startsWith('office/patient') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('office/patients')"
              >
                <b-icon icon="person"></b-icon>
                {{ $t("menu.office.patients") }}
              </b-button>

              <!-- dropdown secretary -->
              <div
                v-if="isEnabled(['roleSpecific', 'secretary']) && !isEnabled(['roleSpecific', 'chiefSecretary'])"
                class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-3"
              >
                <b-button
                  :variant="'outline-' + $systemSettings.theme"
                  class="kt-dropdown-btn__switcher-btn px-1"
                  style="width: 37px; text-align: center;"
                  pill
                >
                  <b-icon
                    icon="three-dots-vertical"
                    class="mr-0"
                  ></b-icon>
                </b-button>
                <ul class="kt-dropdown-btn__list">
                  <li class="kt-dropdown-btn__shadow"></li>
                  <!-- statistics -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'statistics'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name === 'reports/statistics') ? 'active' : ''"
                      @click="navigate('reports/statistics')"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.statistics") }}
                    </button>
                  </li>
                  <!-- <li class="kt-dropdown-btn__divider"></li> -->
                </ul>
              </div>

              <!-- dropdown chiefSecretary -->
              <div
                v-else-if="isEnabled(['roleSpecific', 'chiefSecretary'])"
                class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-3"
              >
                <b-button
                  :variant="'outline-' + $systemSettings.theme"
                  class="kt-dropdown-btn__switcher-btn px-1"
                  style="width: 37px; text-align: center;"
                  pill
                >
                  <b-icon
                    icon="three-dots-vertical"
                    class="mr-0"
                  ></b-icon>
                </b-button>
                <ul class="kt-dropdown-btn__list">
                  <li class="kt-dropdown-btn__shadow"></li>

                  <!-- statistics -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'statistics'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name === 'reports/statistics') ? 'active' : ''"
                      @click="navigate('reports/statistics')"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.statistics") }}
                    </button>
                  </li>
                  <li class="kt-dropdown-btn__divider"></li>
                  <!-- organisations -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'organisations'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/organisation') ? 'active' : ''"
                      @click="navigate('data/organisations')"
                    >
                      <b-icon
                        icon="building"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.organisations") }}
                    </button>
                  </li>
                  <li class="kt-dropdown-btn__divider"></li>
                  <!-- containerTypes -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'containerTypes'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/containerType') ? 'active' : ''"
                      @click="navigate('data/containerTypes')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.containerTypes") }}
                    </button>
                  </li>
                  <!-- samples -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'samples'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/sample') ? 'active' : ''"
                      @click="navigate('data/samples')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.samples") }}
                    </button>
                  </li>
                  <!-- tasks -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'tasks'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/task') ? 'active' : ''"
                      @click="navigate('data/tasks')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.tasks") }}
                    </button>
                  </li>
                  <!-- acts -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'acts'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/act') ? 'active' : ''"
                      @click="navigate('data/acts')"
                    >
                      <b-icon
                        icon="book-half"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.acts") }}
                    </button>
                  </li>
                  <li
                    v-show="isEnabled(['menu', 'administration', 'general', 'pathologists'])"
                    class="kt-dropdown-btn__divider"
                  ></li>
                  <!-- pathologists -->
                  <li
                    v-show="isEnabled(['menu', 'administration', 'general', 'pathologists'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('administration/pathologist') ? 'active' : ''"
                      @click="navigate('administration/pathologists')"
                    >
                      <b-icon
                        icon="eye"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.administration.pathologists") }}
                    </button>
                  </li>
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'filesReports']) || isEnabled(['menu', 'reports', 'general', 'contactReports']) || isEnabled(['menu', 'reports', 'general', 'legalLog'])"
                    class="kt-dropdown-btn__divider"
                  ></li>
                  <!-- filesReports -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'filesReports'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/filesReports' ? 'active' : ''"
                      @click="navigate('reports/filesReports')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.filesReport") }}
                    </button>
                  </li>
                  <!-- contactReports -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'contactReports'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/contactReports' ? 'active' : ''"
                      @click="navigate('reports/contactReports')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.contactReport") }}
                    </button>
                  </li>
                  <!-- legalLog -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'legalLog'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/legalLog' ? 'active' : ''"
                      @click="navigate('reports/legalLog')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.legalLog") }}
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <!-- MENU FOR DIAGNOSIS ONLY -->
          <div
            v-else-if="isEnabled(['roleSpecific', 'diagnosis'])"
            class="kt-main-nav__wrapper py-2"
          >
            <nav :aria-label="$t('menu.general.mainMenu')">
              <!-- reportEntry -->
              <b-button
                v-show="isEnabled(['menu', 'diagnosis', 'general', 'reports'])"
                :variant="($route.name === 'diagnosis/reportEntry') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('diagnosis/reportEntry')"
              >
                <b-icon icon="upc"></b-icon>
                {{ $t("menu.diagnosis.reportEntry") }}
              </b-button>
              <!-- files -->
              <b-button
                class="ml-2"
                :variant="($route.name === 'diagnosis/files' || $route.name.startsWith('office/file/')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('diagnosis/files')"
              >
                <b-icon icon="folder"></b-icon>
                {{ $t("menu.office.files") }}
              </b-button>
              <!-- reports Cytotechnician -->
              <b-button
                v-show="isEnabled(['menu', 'diagnosis', 'general', 'medicalReports'])"
                class="ml-2"
                :variant="($route.name.startsWith('diagnosis/medicalReports')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigateIntoDiagnosis('medicalReports')"
              >
                <b-icon icon="file-earmark-text"></b-icon>
                {{ $t("menu.diagnosis.medicalReports") }}
              </b-button>
              <!-- reports Validation -->
              <b-button
                v-show="isEnabled(['menu', 'diagnosis', 'general', 'validation'])"
                class="ml-2"
                :variant="($route.name.startsWith('diagnosis/validation')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigateIntoDiagnosis('validation')"
              >
                <b-icon icon="check2-all"></b-icon>
                {{ $t("menu.diagnosis.validation") }}
              </b-button>

              <!-- dropdown data -->
              <div class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-3">
                <b-button
                  :variant="'outline-' + $systemSettings.theme"
                  class="kt-dropdown-btn__switcher-btn px-1"
                  style="width: 37px; text-align: center;"
                  pill
                >
                  <b-icon
                    icon="three-dots-vertical"
                    class="mr-0"
                  ></b-icon>
                </b-button>
                <ul class="kt-dropdown-btn__list">
                  <li class="kt-dropdown-btn__shadow"></li>

                  <!-- statistics -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'statistics'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name === 'reports/statistics') ? 'active' : ''"
                      @click="navigate('reports/statistics')"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.statistics") }}
                    </button>
                  </li>
                  <!-- awaitingHpvResults -->
                  <li
                    v-show="isEnabled(['menu', 'diagnosis', 'general', 'awaitingHpvResults'])"
                    class="kt-dropdown-btn__divider"
                  ></li>
                  <li
                    v-show="isEnabled(['menu', 'diagnosis', 'general', 'awaitingHpvResults'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name === 'diagnosis/awaitingHpvResults') ? 'active' : ''"
                      @click="navigate('diagnosis/awaitingHpvResults')"
                    >
                      <b-icon
                        icon="clock"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.diagnosis.awaitingHpvResults") }}
                    </button>
                  </li>

                  <li
                    v-show="isEnabled(['menu', 'diagnosis', 'general', 'histologyShortCodes']) && isEnabled(['menu', 'diagnosis', 'general', 'containerGroupShortCodes'])"
                    class="kt-dropdown-btn__divider"
                  ></li>
                  <!-- containerGroupShortCodes -->
                  <li
                    v-show="isEnabled(['menu', 'diagnosis', 'general', 'containerGroupShortCodes'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name.startsWith('diagnosis/containerGroupShortCode')) ? 'active' : ''"
                      @click="navigate('diagnosis/containerGroupShortCodes')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.diagnosis.containerGroupShortCodes") }}
                    </button>
                  </li>
                  <!-- histologyShortCodes -->
                  <li
                    v-show="isEnabled(['menu', 'diagnosis', 'general', 'histologyShortCodes'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name.startsWith('diagnosis/histologyShortCode')) ? 'active' : ''"
                      @click="navigate('diagnosis/histologyShortCodes')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.diagnosis.histologyShortCodes") }}
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <!-- MENU FOR ACCOUNTING ONLY -->
          <div
            v-else-if="isEnabled(['roleSpecific', 'accounting'])"
            class="kt-main-nav__wrapper py-2"
          >
            <nav :aria-label="$t('menu.general.mainMenu')">
              <!-- files -->
              <b-button
                v-show="isEnabled(['menu', 'accounting', 'general', 'files'])"
                :variant="($route.name === 'accounting/files' || $route.name.startsWith('office/file/')) && !$route.name.startsWith('accounting/fileInvoice') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigateIntoAccouting('files')"
              >
                <b-icon icon="folder"></b-icon>
                {{ $t("menu.office.files") }}
              </b-button>
              <!-- invoices -->
              <b-button
                v-show="isEnabled(['menu', 'accounting', 'general', 'invoices'])"
                class="ml-2"
                :variant="$route.name.startsWith('accounting/invoices') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigateIntoAccouting('invoices')"
              >
                <b-icon icon="file-earmark-text"></b-icon>
                {{ $t("menu.accounting.invoiceReports") }}
              </b-button>
              <!-- payments -->
              <b-button
                v-show="isEnabled(['menu', 'accounting', 'general', 'payments'])"
                class="ml-2"
                :variant="(($route.name).startsWith('accounting/payments')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigateIntoAccouting('payments')"
              >
                <b-icon icon="check-circle"></b-icon>
                {{ $t("menu.accounting.payments") }}
              </b-button>
              <!-- transformations -->
              <b-button
                v-show="isEnabled(['menu', 'accounting', 'general', 'fileInvoiceTransformations'])"
                class="ml-2"
                :variant="$route.name.startsWith('accounting/fileInvoiceTransformation') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('accounting/fileInvoiceTransformations')"
              >
                <b-icon icon="arrow-left-right"></b-icon>
                {{ $t("menu.accounting.fileInvoiceTransformations") }}
              </b-button>
              <!-- reports/balanceReport -->
              <b-button
                v-show="isEnabled(['menu', 'accounting', 'general', 'reports'])"
                class="ml-2"
                :variant="$route.name.startsWith('accounting/reports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('accounting/reports/balanceReport')"
              >
                <b-icon icon="box-arrow-right"></b-icon>
                {{ $t("menu.accounting.reports") }}
              </b-button>

              <!-- dropdown data -->
              <div class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-3">
                <b-button
                  :variant="'outline-' + $systemSettings.theme"
                  class="kt-dropdown-btn__switcher-btn px-1"
                  style="width: 37px; text-align: center;"
                  pill
                >
                  <b-icon
                    icon="three-dots-vertical"
                    class="mr-0"
                  ></b-icon>
                </b-button>
                <ul class="kt-dropdown-btn__list">
                  <li class="kt-dropdown-btn__shadow"></li>

                  <!-- statistics -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'statistics'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name === 'reports/statistics') ? 'active' : ''"
                      @click="navigate('reports/statistics')"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.statistics") }}
                    </button>
                  </li>
                  <!-- filesReports -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'filesReports'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/filesReports' ? 'active' : ''"
                      @click="navigate('reports/filesReports')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.filesReport") }}
                    </button>
                  </li>
                  <!-- contactReports -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'contactReports'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/contactReports' ? 'active' : ''"
                      @click="navigate('reports/contactReports')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.contactReport") }}
                    </button>
                  </li>
                  <li class="kt-dropdown-btn__divider"></li>
                  <!-- patient -->
                  <li
                    v-show="isEnabled(['menu', 'office', 'general', 'patients'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('office/patient') ? 'active' : ''"
                      @click="navigate('office/patients')"
                    >
                      <b-icon
                        icon="person"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.office.patients") }}
                    </button>
                  </li>
                  <!-- organisations -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'organisations'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/organisation') ? 'active' : ''"
                      @click="navigate('data/organisations')"
                    >
                      <b-icon
                        icon="building"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.organisations") }}
                    </button>
                  </li>
                  <!-- banks -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'banks'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/bank') ? 'active' : ''"
                      @click="navigate('data/banks')"
                    >
                      <b-icon
                        icon="cash-stack"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.banks") }}
                    </button>
                  </li>
                  <!-- divider -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'samples']) ||
                      isEnabled(['menu', 'data', 'general', 'tasks']) ||
                      isEnabled(['menu', 'data', 'general', 'acts'])"
                    class="kt-dropdown-btn__divider"
                  ></li>
                  <!-- samples -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'samples'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/sample') ? 'active' : ''"
                      @click="navigate('data/samples')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.samples") }}
                    </button>
                  </li>
                  <!-- tasks -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'tasks'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/task') ? 'active' : ''"
                      @click="navigate('data/tasks')"
                    >
                      <b-icon
                        icon="book"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.tasks") }}
                    </button>
                  </li>
                  <!-- acts -->
                  <li
                    v-show="isEnabled(['menu', 'data', 'general', 'acts'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('data/act') ? 'active' : ''"
                      @click="navigate('data/acts')"
                    >
                      <b-icon
                        icon="book-half"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.data.acts") }}
                    </button>
                  </li>
                  <!-- divider -->
                  <li
                    v-show="isEnabled(['menu', 'administration', 'general', 'pathologists'])"
                    class="kt-dropdown-btn__divider"
                  ></li>
                  <!-- pathologists -->
                  <li
                    v-show="isEnabled(['menu', 'administration', 'general', 'pathologists'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name.startsWith('administration/pathologist') ? 'active' : ''"
                      @click="navigate('administration/pathologists')"
                    >
                      <b-icon
                        icon="eye"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.administration.pathologists") }}
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <!-- MENU FOR TECHNICIAN ONLY -->
          <div
            v-else-if="isEnabled(['roleSpecific', 'technician'])"
            class="kt-main-nav__wrapper py-2"
          >
            <nav :aria-label="$t('menu.general.mainMenu')">
              <!-- files -->
              <b-button
                class=""
                :variant="$route.name.startsWith('diagnosis/file') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('diagnosis/files')"
              >
                <b-icon icon="folder"></b-icon>
                {{ $t("menu.office.files") }}
              </b-button>
              <!-- instruments -->
              <b-button
                v-show="isEnabled(['menu', 'data', 'general', 'instruments'])"
                class="ml-2"
                :variant="$route.name.startsWith('data/instrument') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                pill
                @click="navigate('data/instruments')"
              >
                <b-icon icon="eye"></b-icon>
                {{ $t("menu.data.instruments") }}
              </b-button>

              <!-- dropdown data -->
              <div class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-3">
                <b-button
                  :variant="'outline-' + $systemSettings.theme"
                  class="kt-dropdown-btn__switcher-btn px-1"
                  style="width: 37px; text-align: center;"
                  pill
                >
                  <b-icon
                    icon="three-dots-vertical"
                    class="mr-0"
                  ></b-icon>
                </b-button>
                <ul class="kt-dropdown-btn__list">
                  <li class="kt-dropdown-btn__shadow"></li>
                  <!-- statistics -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'statistics'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="($route.name === 'reports/statistics') ? 'active' : ''"
                      @click="navigate('reports/statistics')"
                    >
                      <b-icon
                        icon="bar-chart-fill"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.statistics") }}
                    </button>
                  </li>
                  <!-- <li class="kt-dropdown-btn__divider"></li> -->
                  <!-- filesReports -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'filesReports'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/filesReports' ? 'active' : ''"
                      @click="navigate('reports/filesReports')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.filesReport") }}
                    </button>
                  </li>
                  <!-- contactReports -->
                  <li
                    v-show="isEnabled(['menu', 'reports', 'general', 'contactReports'])"
                    class="kt-dropdown-btn__item"
                  >
                    <button
                      class="kt-dropdown-btn__button"
                      :class="$route.name === 'reports/contactReports' ? 'active' : ''"
                      @click="navigate('reports/contactReports')"
                    >
                      <b-icon
                        icon="box-arrow-right"
                        class="kt-dropdown-btn__icon"
                      ></b-icon>
                      {{ $t("menu.reports.contactReport") }}
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <!-- SUB-MENUS -->
          <div
            v-if="isEnabled(['roleSpecific', 'userAdministration'])"
            class="kt-main-nav__submenus"
          >
            <!-- system submenu -->
            <b-collapse v-model="showSystemSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.system')})">
                <!-- systemSettings -->
                <b-button
                  v-show="isEnabled(['menu', 'system', 'general', 'systemSettings'])"
                  class="mb-2"
                  :variant="($route.name.startsWith('system/systemSettings')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('system/systemSettings/theme')"
                >
                  <b-icon icon="gear"></b-icon>
                  {{ $t("menu.system.systemSettings") }}
                </b-button>
                <!-- statistics -->
                <b-button
                  v-show="isEnabled(['menu', 'system', 'general', 'statistics'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('system/statistics')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('system/statistics')"
                >
                  <b-icon icon="bar-chart-fill"></b-icon>
                  {{ $t("menu.system.statistics") }}
                </b-button>
              </nav>
            </b-collapse>
            <!-- reports submenu -->
            <b-collapse v-model="showReportsSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.reports')})">
                <!-- statistics -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'statistics'])"
                  class="mb-2"
                  :variant="($route.name === 'reports/statistics') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/statistics')"
                >
                  <b-icon icon="bar-chart-fill"></b-icon>
                  {{ $t("menu.reports.statistics") }}
                </b-button>
                <!-- filesReports -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'filesReports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/filesReports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/filesReports')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.filesReport") }}
                </b-button>
                <!-- contactReports -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'contactReports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/contactReports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/contactReports')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.contactReport") }}
                </b-button>
                <!-- pathologistsReports -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'pathologistsReports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/pathologistsReports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/pathologistsReports')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.pathologistsReport") }}
                </b-button>
                <!-- cytotechniciansReports -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'cytotechniciansReports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/cytotechniciansReports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/cytotechniciansReports')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.cytotechniciansReport") }}
                </b-button>
                <!-- resultsReports -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'resultsReports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/resultsReports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/resultsReports')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.resultsReport") }}
                </b-button>
                <!-- filingReports -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'filingReports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/filingReports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/filingReports')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.filingReport") }}
                </b-button>
                <!-- legalLog -->
                <b-button
                  v-show="isEnabled(['menu', 'reports', 'general', 'legalLog'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('reports/legalLog') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('reports/legalLog')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.reports.legalLog") }}
                </b-button>
              </nav>
            </b-collapse>
            <!-- administration submenu -->
            <b-collapse v-model="showAdministrationSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.administration')})">
                <!-- users -->
                <b-button
                  v-show="isEnabled(['menu', 'administration', 'general', 'users'])"
                  class="mb-2"
                  :variant="($route.name === 'administration/users') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('administration/users')"
                >
                  <b-icon icon="people"></b-icon>
                  {{ $t("menu.administration.users") }}
                </b-button>
                <!-- pathologists -->
                <b-button
                  v-show="isEnabled(['menu', 'administration', 'general', 'pathologists'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('administration/pathologist') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('administration/pathologists')"
                >
                  <b-icon icon="eye"></b-icon>
                  {{ $t("menu.administration.pathologists") }}
                </b-button>
                <!-- laboratories -->
                <b-button
                  v-show="isEnabled(['menu', 'administration', 'general', 'laboratories'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('administration/laborator') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('administration/laboratories')"
                >
                  <b-icon icon="building"></b-icon>
                  {{ $t("menu.administration.laboratories") }}
                </b-button>
              </nav>
            </b-collapse>
            <!-- data submenu -->
            <b-collapse v-model="showDataSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.data')})">
                <!-- organisations -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'organisations'])"
                  class="mb-2"
                  :variant="$route.name.startsWith('data/organisation') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/organisations')"
                >
                  <b-icon icon="building"></b-icon>
                  {{ $t("menu.data.organisations") }}
                </b-button>
                <!-- samples -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'samples'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('data/sample') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/samples')"
                >
                  <b-icon icon="book"></b-icon>
                  {{ $t("menu.data.samples") }}
                </b-button>
                <!-- containerTypes -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'containerTypes'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('data/containerType') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/containerTypes')"
                >
                  <b-icon icon="book"></b-icon>
                  {{ $t("menu.data.containerTypes") }}
                </b-button>
                <!-- tasks -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'tasks'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('data/task') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/tasks')"
                >
                  <b-icon icon="book"></b-icon>
                  {{ $t("menu.data.tasks") }}
                </b-button>
                <!-- acts -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'acts'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('data/act') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/acts')"
                >
                  <b-icon icon="book-half"></b-icon>
                  {{ $t("menu.data.acts") }}
                </b-button>
                <!-- instruments -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'instruments'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('data/instrument') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/instruments')"
                >
                  <b-icon icon="eye"></b-icon>
                  {{ $t("menu.data.instruments") }}
                </b-button>
                <!-- banks -->
                <b-button
                  v-show="isEnabled(['menu', 'data', 'general', 'banks'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('data/bank') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('data/banks')"
                >
                  <b-icon icon="cash-stack"></b-icon>
                  {{ $t("menu.data.banks") }}
                </b-button>
              </nav>
            </b-collapse>
            <!-- office submenu -->
            <b-collapse v-model="showOfficeSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.office')})">
                <!-- fileEntry -->
                <b-button
                  v-show="isEnabled(['menu', 'office', 'general', 'files'])"
                  class="mb-2"
                  :variant="$route.name === 'office/fileEntry' ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('office/fileEntry')"
                >
                  <b-icon icon="upc"></b-icon>
                  {{ $t("menu.office.fileEntry") }}
                </b-button>
                <!-- files -->
                <b-button
                  v-show="isEnabled(['menu', 'office', 'general', 'files'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('office/file') && $route.name !== 'office/fileEntry') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('office/files')"
                >
                  <b-icon icon="folder"></b-icon>
                  {{ $t("menu.office.files") }}
                </b-button>
                <!-- contacts -->
                <b-button
                  v-show="isEnabled(['menu', 'office', 'general', 'contacts'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('office/contact') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('office/contacts')"
                >
                  <b-icon icon="person-fill"></b-icon>
                  {{ $t("menu.office.contacts") }}
                </b-button>
                <!-- patients -->
                <b-button
                  v-show="isEnabled(['menu', 'office', 'general', 'patients'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('office/patient') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('office/patients')"
                >
                  <b-icon icon="person"></b-icon>
                  {{ $t("menu.office.patients") }}
                </b-button>
              </nav>
            </b-collapse>
            <!-- diagnosis submenu -->
            <b-collapse v-model="showDiagnosisSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.diagnosis')})">
                <!-- reportEntry -->
                <b-button
                  v-show="isEnabled(['menu', 'diagnosis', 'general', 'reports'])"
                  class="mb-2"
                  :variant="($route.name === 'diagnosis/reportEntry') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('diagnosis/reportEntry')"
                >
                  <b-icon icon="upc"></b-icon>
                  {{ $t("menu.diagnosis.reportEntry") }}
                </b-button>
                <!-- files -->
                <b-button
                  class="mb-2 ml-2"
                  :variant="($route.name === 'diagnosis/files' || $route.name.startsWith('office/file/')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('diagnosis/files')"
                >
                  <b-icon icon="folder"></b-icon>
                  {{ $t("menu.office.files") }}
                </b-button>
                <!-- cytologyReportsCytotechnician -->
                <b-button
                  v-show="isEnabled(['menu', 'diagnosis', 'general', 'medicalReports'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('diagnosis/medicalReports')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigateIntoDiagnosis('medicalReports')"
                >
                  <b-icon icon="file-earmark-text"></b-icon>
                  {{ $t("menu.diagnosis.medicalReports") }}
                </b-button>
                <!-- cytologyReportsPathologist -->
                <b-button
                  v-show="isEnabled(['menu', 'diagnosis', 'general', 'validation'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('diagnosis/validation')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigateIntoDiagnosis('validation')"
                >
                  <b-icon icon="check2-all"></b-icon>
                  {{ $t("menu.diagnosis.validation") }}
                </b-button>
                <!-- awaitingHpvResults -->
                <b-button
                  v-show="isEnabled(['menu', 'diagnosis', 'general', 'awaitingHpvResults'])"
                  class="mb-2 ml-2"
                  :variant="($route.name === 'diagnosis/awaitingHpvResults') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('diagnosis/awaitingHpvResults')"
                >
                  <b-icon icon="clock"></b-icon>
                  {{ $t("menu.diagnosis.awaitingHpvResults") }}
                </b-button>
                <!-- histologyShortCodes -->
                <b-button
                  v-show="isEnabled(['menu', 'diagnosis', 'general', 'histologyShortCodes'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('diagnosis/histologyShortCode')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('diagnosis/histologyShortCodes')"
                >
                  <b-icon icon="clock"></b-icon>
                  {{ $t("menu.diagnosis.histologyShortCodes") }}
                </b-button>
                <!-- containerGroupShortCodes -->
                <b-button
                  v-show="isEnabled(['menu', 'diagnosis', 'general', 'containerGroupShortCodes'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('diagnosis/containerGroupShortCode')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('diagnosis/containerGroupShortCodes')"
                >
                  <b-icon icon="clock"></b-icon>
                  {{ $t("menu.diagnosis.containerGroupShortCodes") }}
                </b-button>
              </nav>
            </b-collapse>
            <!-- accounting submenu -->
            <b-collapse v-model="showAccountingSubmenu">
              <nav :aria-label="$t('menu.general.subMenu', {title: $t('menu.general.accounting')})">
                <!-- files -->
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'general', 'files'])"
                  class="mb-2"
                  :variant="($route.name === 'accounting/files' || $route.name.startsWith('office/file/')) && !$route.name.startsWith('accounting/fileInvoice') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigateIntoAccouting('files')"
                >
                  <b-icon icon="folder"></b-icon>
                  {{ $t("menu.office.files") }}
                </b-button>
                <!-- invoices -->
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'general', 'invoices'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('accounting/invoices') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigateIntoAccouting('invoices')"
                >
                  <b-icon icon="file-earmark-text"></b-icon>
                  {{ $t("menu.accounting.invoiceReports") }}
                </b-button>
                <!-- payment -->
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'general', 'payments'])"
                  class="mb-2 ml-2"
                  :variant="($route.name.startsWith('accounting/payments')) ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigateIntoAccouting('payments')"
                >
                  <b-icon icon="check-circle"></b-icon>
                  {{ $t("menu.accounting.payments") }}
                </b-button>
                <!-- transformations -->
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'general', 'fileInvoiceTransformations'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('accounting/fileInvoiceTransformation') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('accounting/fileInvoiceTransformations')"
                >
                  <b-icon icon="arrow-left-right"></b-icon>
                  {{ $t("menu.accounting.fileInvoiceTransformations") }}
                </b-button>
                <!-- reports/balanceReport -->
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'general', 'reports'])"
                  class="mb-2 ml-2"
                  :variant="$route.name.startsWith('accounting/reports') ? $systemSettings.theme : ('outline-' + $systemSettings.theme)"
                  pill
                  @click="navigate('accounting/reports/balanceReport')"
                >
                  <b-icon icon="box-arrow-right"></b-icon>
                  {{ $t("menu.accounting.reports") }}
                </b-button>
              </nav>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error],
  props: {
    isLoggedInProp: Boolean
  },
  data() {
    return {
      subscribedSectors: [],

      // sub-menus
      showDataSubmenu: false,
      showOfficeSubmenu: false,
      showAccountingSubmenu: false,
      showDiagnosisSubmenu: false,
      showAdministrationSubmenu: false,
      showSystemSubmenu: false,
      showReportsSubmenu: false
    };
  },
  watch: {
    // close sub-menus on log out
    isLoggedInProp: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.showDataSubmenu = false;
          this.showOfficeSubmenu = false;
          this.showAccountingSubmenu = false;
          this.showDiagnosisSubmenu = false;
          this.showAdministrationSubmenu = false;
          this.showSystemSubmenu = false;
          this.showReportsSubmenu = false;
        }
      }
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // open the administration menu according to the current page
    if (this.isEnabled(["roleSpecific", "userAdministration"])) {
      this.onDisplaySubmenu(this.$route.meta.activeTopMenuName);
    }

    // import data
    this.importSectors();
  },
  methods: {
    importSectors() {
      const sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.subscribedSectors = sectorsOptions.filter(v => v.isSubscribed);
    },

    // toggle sub-menus
    onDisplaySubmenu(target) {
      this.showDataSubmenu = (target === "data") ? !this.showDataSubmenu : false;
      this.showOfficeSubmenu = (target === "office") ? !this.showOfficeSubmenu : false;
      this.showAccountingSubmenu = (target === "accounting") ? !this.showAccountingSubmenu : false;
      this.showDiagnosisSubmenu = (target === "diagnosis") ? !this.showDiagnosisSubmenu : false;
      this.showAdministrationSubmenu = (target === "administration") ? !this.showAdministrationSubmenu : false;
      this.showSystemSubmenu = (target === "system") ? !this.showSystemSubmenu : false;
      this.showReportsSubmenu = (target === "reports") ? !this.showReportsSubmenu : false;
    },
    // accounting navigation (with $userSettings accounting sections)
    navigateIntoAccouting(target) {
      if (target === "files") {
        // files
        this.navigate("accounting/files");
      } else if (target === "invoices") {
        // invoices
        if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection === "organisations") {
          this.navigate("accounting/invoices/invoiceReports");
        } else if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection === "patients") {
          this.navigate("accounting/invoices/feeNotes");
        } else if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection === "healthcares") {
          this.navigate("accounting/invoices/careSheets");
        } else {
          // default
          this.navigate("accounting/invoices/invoiceReports");
        }
      } else if (target === "payments") {
        // payments
        if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection === "organisations") {
          this.navigate("accounting/payments/paymentReports");
        } else if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection === "patients") {
          this.navigate("accounting/payments/patientPayments");
        } else if (this.$userSettings.savedBehaviors.accounting.activeAccountingSection === "healthcares") {
          this.navigate("accounting/payments/healthCarePayments");
        } else {
          // default
          this.navigate("accounting/payments/paymentReports");
        }
      } else {
        this.navigate(target);
      }
    },
    // diagnosis navigation (with $userSettings)
    async navigateIntoDiagnosis(target) {
      if (target === "medicalReports") {
        // medicalReports
        if (this.$userSettings.savedBehaviors.diagnosis.activeSector === "cytology") {
          this.navigate("diagnosis/medicalReports/cytologyReports");
        } else if (this.$userSettings.savedBehaviors.diagnosis.activeSector === "histology") {
          this.navigate("diagnosis/medicalReports/histologyReports");
        } else {
          // default
          await this.navigateToDiagnosisFirstSubscribedSector(target);
        }
      } else if (target === "validation") {
        // validation
        if (this.$userSettings.savedBehaviors.diagnosis.activeSector === "cytology") {
          this.navigate("diagnosis/validation/cytologyValidation");
        } else if (this.$userSettings.savedBehaviors.diagnosis.activeSector === "histology") {
          this.navigate("diagnosis/validation/histologyValidation");
        } else {
          // default
          await this.navigateToDiagnosisFirstSubscribedSector(target);
        }
      } else {
        this.navigate(target);
      }
    },
    async navigateToDiagnosisFirstSubscribedSector(target) {
      if (!this.subscribedSectors.length) return false;

      this.$userSettings.savedBehaviors.diagnosis.activeSector = this.subscribedSectors[0].name;

      if (target === "medicalReports") {
        // medicalReports
        navigate("diagnosis/medicalReports/" + this.subscribedSectors[0].name + "Reports");
      } else if (target === "validation") {
        // validation
        navigate("diagnosis/validation/" + this.subscribedSectors[0].name + "Validation");
      }

      return true;
    }
  }
};
</script>

<style scoped src="@shared/views/General/Menu/Menu.scss" lang="scss"></style>

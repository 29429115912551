<template>
  <div class="kt-report-view">
    <!-- file details -->
    <ReportFileDetails
      v-if="showFileDetailsProp"
      :fileDataProp="fileData"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- validated warnings -->
    <div class="kt-report-edit__warnings">
      <!-- medically validated -->
      <div
        v-show="validatedMedically && isSuperseded === false"
        class="kt-validated-warning kt-badge kt-badge--light-green kt-badge--lg mt-1 mr-1"
      >
        <b-icon
          icon="check-circle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.validatedWarningMedically") }}</span>

        <!-- View -->
        <b-button
          v-show="!validateAllFilesBeforeDownload"
          class="kt-badge__btn my-n1"
          size="sm"
          variant="success"
          @click="onView"
        >
          <b-icon icon="eye"></b-icon> {{ $t("general.view") }}
        </b-button>
        <b-spinner
          v-show="isDownloading"
          style="margin-left: 6px;"
          :class="'kt-spinner--' + $systemSettings.theme"
          small
        ></b-spinner>
        <!-- option drop-down (new version) -->
        <div
          v-if="validatedMedically && isSuperseded === false"
          class="kt-dropdown-btn kt-dropdown-btn--success kt-dropdown-btn--no-arrow ml-2 my-n1"
        >
          <b-button
            variant="outline-secondary"
            size="sm"
            class="btn-icon kt-dropdown-btn__switcher-btn"
            pill
          >
            <b-icon icon="three-dots-vertical"></b-icon>
          </b-button>
          <ul
            class="kt-dropdown-btn__list"
          >
            <li class="kt-dropdown-btn__shadow"></li>

            <!-- new version button -->
            <li
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button text-danger"
                @click="createNewVersion"
              >
                <b-icon
                  icon="trash"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("medicalReportEdit.versioning.createNewVersion") }}
              </button>
            </li>

            <!-- <li class="kt-dropdown-btn__divider"></li> -->
          </ul>
        </div>
      </div>

      <!-- info badge : validateAllFilesBeforeDownload -->
      <div
        v-show="validateAllFilesBeforeDownload"
        class="kt-validated-warning kt-badge kt-badge--blue kt-badge--lg mt-1 mr-1"
      >
        <b-icon
          icon="info-circle"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.validateAllFilesBeforeDownload") }}</span>
      </div>

      <!-- overwritten warnings -->
      <span
        v-show="isSuperseded"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="x-circle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.versioning.reportCancelled") }}</span>
      </span>

      <!-- documentStatuses -->
      <DocumentStatuses
        :dataProp="histologyReportData"
        class="mt-1 ml-2"
      />
    </div>

    <b-row class="kt-row__padding-sm mt-2 pb-4">
      <!-- LEFT COLUMN : report context -->
      <b-col class="kt-report-edit__fixed-col">
        <div class="kt-report-context">
          <!-- pathologist name -->
          <h2 class="kt-report-edit__title">
            {{ $t("medicalReportEdit.pathologist") }}
          </h2>
          <InputSearch
            :valueProp="pathologist"
            :displayedValueProp="pathologistName"
            disabledProp
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </div>
      </b-col>

      <!-- MIDDLE COLUMN : body -->
      <b-col>
        <div class="kt-report-body">
          <!-- analysis -->
          <div class="kt-report-results__radio-group mb-3">
            <span class="kt-badge kt-badge--light-gray">
              {{ $t("histologyReport.analysis.title") + ' :' }}
              <strong>{{ analysis ? $t("histologyReport.analysis." + analysis) : $t("histologyReport.analysis.empty") }}</strong>
            </span>
          </div>

          <!-- body -->
          <div class="ql-container ql-snow">
            <div
              class="ql-editor"
              v-html="form.body"
            ></div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// modules
import "quill/dist/quill.snow.css";
import "@/views/Diagnosis/HistologyReports/quill.scss";
// components
import ReportFileDetails from "@/views/Diagnosis/ReportFileDetails";
import InputSearch from "@shared/views/Helpers/InputSearch";
import DocumentStatuses from "@shared/views/Helpers/DocumentStatuses";
// services
import commonServices from "@shared/services/API/commonServices";
import histologyReportsServices from "@/services/API/histologyReportsServices";
import filesServices from "@/services/API/filesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import fileSaver from "file-saver";

export default {
  components: {
    ReportFileDetails,
    InputSearch,
    DocumentStatuses
  },
  mixins: [userRights, error, date],
  props: {
    histologyReportDataProp: {
      type: Object,
      default: null
    },
    reportIdProp: {
      type: Number,
      default: null
    },
    fileIdProp: {
      type: Number,
      default: null
    },
    showFileDetailsProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general variables
      histologyReportData: this.histologyReportDataProp || null,
      histologyReportId: null,
      previousHistologyReports: [],
      isSuperseded: true,
      isDownloading: false,

      // file info
      fileData: null,
      fileId: null,
      parentFileId: null,
      complementaryFiles: [],
      groupOfFiles: [],

      validatedMedically: false,

      // analysis
      analysis: "",

      // base variables
      form: {
        body: "",
        medicalValidationTimestamp: ""
      },
      pathologist: null
    };
  },
  computed: {
    validateAllFilesBeforeDownload: function() {
      let output = false;
      if (this.validatedMedically) {
        for (const file of this.groupOfFiles) {
          if (file.medicalStatus.name !== "validatedMedically" && file.medicalStatus.name !== "cancelled") {
            output = true;
            break;
          }
        }
      }
      return output;
    },
    pathologistName: function() {
      let name = "";
      if (this.pathologist) {
        name = this.pathologist.user.firstName + " " + this.pathologist.user.lastName + " (" + this.pathologist.code + ")";
        if (this.pathologist.user.isActive === false) name += " (" + this.$t("general.deletedLabel") + ")";
      }
      return name;
    }
  },
  watch: {},
  async mounted() {
    try {
      if (this.histologyReportDataProp) {
        await this.refreshReportByProp(this.histologyReportDataProp);
      } else if (this.fileIdProp) {
        await this.refreshReportByFileId(this.fileIdProp);
      } else {
        // no report found
        this.$emit("alert", "danger", {
          title: this.$t("histologyReport.reportNotFound.title"),
          message: this.$t("histologyReport.reportNotFound.text")
        });
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    async refreshReportByProp(data) {
      if (data.id) {
        this.histologyReportData = data;
        this.histologyReportId = data.id;
        this.isSuperseded = data.isSuperseded;
        this.pathologist = this.histologyReportData.pathologist;
        // file
        await this.updateFileInfo(this.histologyReportData.file);
        // report
        if (this.histologyReportData.content) {
          this.updateReportInfo(this.histologyReportData.content);
        }
      } else {
        this.$emit("alert", "danger", {
          title: this.$t("diagnosis.unknownHistologyReport.title"),
          message: this.$t("diagnosis.unknownHistologyReport.text")
        });
      }
    },
    async refreshReportByFileId(fileId) {
      const resReports = await histologyReportsServices.getAllByFileId(fileId); // available : histologyReports file
      if (resReports.data.histologyReports && resReports.data.histologyReports.length > 0) {
        // file
        await this.updateFileInfo(resReports.data.file);
        // save latest report
        this.histologyReportData = resReports.data.histologyReports[resReports.data.histologyReports.length - 1];
        this.histologyReportId = this.histologyReportData.id;
        this.isSuperseded = this.histologyReportData.isSuperseded;
        this.pathologist = this.histologyReportData.pathologist;
        // report
        if (this.histologyReportData.content) {
          this.updateReportInfo(this.histologyReportData.content);
        }
        // save previous reports
        this.previousHistologyReports = [];
        for (let i = 0; i < resReports.data.histologyReports.length - 1; i++) {
          this.previousHistologyReports.push(resReports.histologyReports.data[i]);
        }
      } else {
        this.histologyReportData = null;
        this.histologyReportId = null;
        this.isSuperseded = false;
        // no report found
        this.$emit("alert", "danger", {
          title: this.$t("histologyReport.reportNotFound.title"),
          message: this.$t("histologyReport.reportNotFound.text")
        });
      }
    },
    async updateFileInfo(file) {
      this.fileId = file.id;
      this.parentFileId = file.parentFileId;

      // groupOfFiles
      if (!file.groupOfFiles) {
        this.complementaryFiles = file.childrenFiles || [];
        file.complementaryFiles = file.childrenFiles || [];
        this.complementaryFiles = this.complementaryFiles.map((item) => {
          item.parentFileId = file.id;
          return item;
        });
        let groupOfFiles = [];
        if (this.parentFileId) {
          // current file is a complementary
          const resLinkedFiles = await filesServices.getLinkedFiles(this.parentFileId);
          if (resLinkedFiles.data) groupOfFiles = resLinkedFiles.data;
          file.parentFile.accessNumber = file.accessNumber;
          groupOfFiles.unshift(file.parentFile);
        } else {
          // current file is the parent
          if (this.complementaryFiles && this.complementaryFiles.length) {
            groupOfFiles = this.complementaryFiles;
          }
          groupOfFiles.unshift({
            id: this.fileId,
            parentFileId: null,
            accessNumber: file.accessNumber,
            creationDate: file.creationDate,
            filingStatus: file.filingStatus || null,
            medicalStatus: file.medicalStatus,
            accountingStatus: file.accountingStatus || null
          });
        }
        file.groupOfFiles = groupOfFiles;
      }
      this.groupOfFiles = file.groupOfFiles;

      this.fileData = file;
      this.validatedMedically = file.medicalStatus.name === "validatedMedically";

      this.analysis = file.containerGroups.reduce((result, item) => {
        const containerGroupTasksContent = item.containerGroupTasks.reduce((resultGroupTask, item1) => {
          if (item1.task.content && typeof item1.task.content.analysis !== "undefined") {
            resultGroupTask += (resultGroupTask !== "" ? ", " : "") + item1.task.content.analysis;
          }
          return resultGroupTask;
        }, "");

        if (containerGroupTasksContent !== "") {
          result += (result !== "" ? " ; " : "") + containerGroupTasksContent;
        }
        return result;
      }, "");
    },
    updateReportInfo(content) {
      this.form.body = content.body;
      this.form.medicalValidationTimestamp = content.medicalValidationTimestamp;
    },

    // View actions
    async onView() {
      if (!this.isDownloading) {
        this.isDownloading = true;
        try {
          const resFile = await commonServices.export("histologyReports", this.histologyReportData.id, "pdf");
          if (resFile.data.type === "application/json") {
            this.$emit("alert", "danger", {
              title: this.$t("general.downloadFileErrorTitle"),
              message: this.$t("general.downloadFileErrorText")
            });
          } else {
            fileSaver.saveAs(resFile.data, this.fileData.accessNumber + ".pdf");
          }
        } catch (err) {
          this.handleErrors(err);
        }
        this.isDownloading = false;
      }
    },

    // Versions
    async createNewVersion() {
      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("medicalReportEdit.versioning.newVersionConfirmationText"), {
        title: this.$t("medicalReportEdit.versioning.newVersionConfirmationTitle"),
        okVariant: "danger",
        okTitle: this.$t("medicalReportEdit.versioning.newVersionConfirmationOk"),
        cancelTitle: this.$t("medicalReportEdit.versioning.newVersionConfirmationCancel"),
        centered: true
      });
      // create new version
      if (result) {
        try {
          const res = await histologyReportsServices.newVersion(this.histologyReportId);
          if (res.data.id) {
            this.$emit("newVersionCreated", true);
            return true;
          } else if (res.data.error && res.data.error === "fileNotValidatedMedically") {
            this.$emit("alert", "danger", {
              title: this.$t("medicalReportEdit.notifications.currentVersionNotValidatedMedicallyTitle"),
              message: this.$t("medicalReportEdit.notifications.currentVersionNotValidatedMedicallyText")
            });
            this.isLoading = false;
            this.$emit("refreshHistologyReport", true);
            return false;
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("medicalReportEdit.notifications.newVersionErrorTitle"),
              message: this.$t("medicalReportEdit.notifications.newVersionErrorText")
            });
            return false;
          }
        } catch (err) {
          this.handleErrors(err);
        }
      }
    }
  }
};
</script>

<style scoped src="./HistologyReportEdit.scss" lang="scss"></style>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  async generate(filter, type) {
    return axiosServices.axios.get("/unrecognizedHpvResults/generate", {
      params: {
        filter: filter,
        type: type
      },
      responseType: "blob"
    });
  },
  deleteSelected(ids) {
    return axiosServices.axios({
      method: "delete",
      url: "/unrecognizedHpvResults/deleteSelected",
      data: { ids: ids }
    });
  }
};

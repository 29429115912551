<template>
  <div class="kt-bank-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- bank form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('banks.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              ref="codeInput"
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('banks.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              v-model="name"
              trim
              maxlength="256"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error],
  props: {
    bankIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      bankId: this.bankIdProp,
      bankData: null,
      // form variables
      code: "",
      name: "",
      // is validation active
      codeValidation: false,
      nameValidation: false
    };
  },
  computed: {
    // form validation
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("bankEdit.pageTitle") : this.$t("bankNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("bankEdit.submitText") : this.$t("bankNew.submitText");
    }
  },
  async mounted() {
    // validate ID
    //if (this.editModeProp) {
    //  if (this.bankId === null) {
    //    this.$emit("alert", "danger", {
    //      title: this.$t("bank.notifications.invalidIdTitle"),
    //      message: this.$t("bank.notifications.invalidIdText")
    //    });
    //    navigate("data/banks");
    //    return;
    //  }
    //}

    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.codeInput.focus();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get bank
          const resServices = await commonServices.get("banks", this.bankId);
          this.bankData = resServices.data;
          // import data
          this.code = resServices.data.code;
          this.name = resServices.data.name;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("data/banks");
    },

    // submit functions
    validateForm() {
      this.codeValidation = true;
      this.nameValidation = true;

      return !(
        this.codeState === false ||
        this.nameState === false
      );
    },
    async saveBank() {
      try {
        const form = {
          code: this.code,
          name: this.name
        };
        if (this.editMode) {
          const res = await commonServices.put("banks", form, this.bankId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("bankEdit.notifications.editedTitle"),
              message: this.$t("bankEdit.notifications.editedText")
            });
            navigate("data/banks");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("bankEdit.notifications.editionErrorTitle"),
              message: this.$t("bankEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("banks", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("bankNew.notifications.addedTitle"),
              message: this.$t("bankNew.notifications.addedText")
            });
            navigate("data/banks");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("bankNew.notifications.additionErrorTitle"),
              message: this.$t("bankNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveBank();
    }
  }
};
</script>

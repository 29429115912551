<template>
  <div
    class="kt-input-search"
    :class="'kt-input-search--' + $systemSettings.theme"
  >
    <b-row :class="filterField !== searchedCode || exactMatch === null ? 'mb-2' : 'mb-1'">
      <b-col
        cols="12"
        md="8"
      >
        <!-- code search form -->
        <b-form @submit.prevent="onItemChosen(exactMatch)">
          <b-form-group
            class="kt-input-search__input-container mb-0"
            :invalid-feedback="invalidFeedbackProp"
            :state="stateProp"
          >
            <!-- input -->
            <b-form-input
              ref="searchField"
              v-model="filterField"
              class="kt-input-search__input"
              style="padding-right: 80px;"
              autocomplete="off"
              :placeholder="$t('codexPopup.placeholder')"
              :state="stateProp"
              :disabled="disabledProp"
              @input="onInputSearchChange"
            ></b-form-input>
            <!-- search icon -->
            <b-icon
              v-show="filterField === searchedCode && !loading"
              class="kt-input-search__search-icon"
              icon="search"
            ></b-icon>
            <!-- loading spinner -->
            <b-spinner
              v-show="filterField !== searchedCode || loading"
              class="kt-input-search__spinner"
              :class="'kt-spinner--' + $systemSettings.theme"
              small
            ></b-spinner>
            <!-- search button -->
            <b-button
              class="kt-input-search__submit-icon"
              :disabled="loading || filterField !== searchedCode || exactMatch === null"
              :variant="'outline-' + $systemSettings.theme"
              type="submit"
            >
              {{ $t("general." + (isSearchProp ? "search" : "add")) }}
            </b-button>
          </b-form-group>
        </b-form>

        <!-- search feedback -->
        <div v-show="isActiveValidation && filterField === searchedCode && filterField.length > 0">
          <div
            v-show="(!isSearchProp && exactMatch === null) || (isSearchProp && suggestions.length === 0)"
            class="kt-input-search__suggestion kt-input-search__suggestion--empty"
          >
            {{ filterField.length >= searchMinLengthProp ? $t('inputSearch.noResult') : $t('inputSearch.tooShort', {minLength: searchMinLengthProp}) }}
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- exact match -->
    <div
      v-show="filterField === searchedCode && exactMatch !== null"
      class="kt-input-search__value mb-3"
    >
      <b-button
        squared
        class="kt-btn-transparent pl-0 pb-1 font-weight-semi-bold"
        :variant="$systemSettings.theme"
        @click="onItemChosen(exactMatch)"
      >
        {{ exactMatch ? exactMatch.code : "" }}
      </b-button>
      <div
        class="kt-diagnosisShortcode__preview"
        v-html="getHtml(exactMatch ? exactMatch.html : '')"
      ></div>
    </div>

    <!-- suggestions -->
    <div
      v-show="filterField === searchedCode && suggestions.length > 0"
      class="mb-3"
    >
      <!-- divider -->
      <div
        v-if="!isSearchProp"
        class="mb-3 border-top"
      ></div>
      <!-- title -->
      <div
        v-if="!isSearchProp"
        class="mb-1"
        style="font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-weight: 600;
        color: #283547;"
      >
        {{ $t("inputSearch.suggestions") }}
      </div>
      <!-- suggestions -->
      <div>
        <div
          v-for="suggestion in suggestions"
          :key="suggestion.code"
          class="mb-1"
        >
          <!-- preview -->
          <div class="kt-input-search__value">
            <b-button
              squared
              class="kt-btn-transparent pl-0 pb-1 font-weight-semi-bold"
              :variant="$systemSettings.theme"
              @click="onItemChosen(suggestion)"
            >
              {{ suggestion.code }}
            </b-button>
            <div
              class="kt-diagnosisShortcode__preview"
              v-html="getHtml(suggestion.html)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// services
// import commonServices from "@shared/services/API/commonServices";
// helpers
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    initialSearchedFieldProp: {
      type: String,
      default: ""
    },
    searchMinLengthProp: {
      type: Number,
      default: 2
    },
    // validation and error
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    disabledProp: {
      type: Boolean
    },
    isSearchProp: {
      type: Boolean
    }
  },
  data() {
    return {
      filterField: "",
      searchedCode: "",
      exactMatch: null,
      suggestions: [],
      isActiveValidation: false,
      loading: false
    };
  },
  async mounted() {
    // initialSearchedFieldProp
    if (this.initialSearchedFieldProp) {
      this.filterField = this.initialSearchedFieldProp;
      this.search();
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.searchField.focus();
    },

    // search (with delay)
    onInputSearchChange() {
      this.exactMatch = null;
      this.suggestions = [];
      this.delayedSearch();
    },
    delayedSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 450);
    },
    async sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
      return true;
    },
    async search() {
      try {
        this.loading = true;
        this.searchedCode = this.filterField;
        // const filters = {
        //   string: this.searchedCode
        // };
        await this.$nextTick();
        if (this.searchedCode.length > this.searchMinLengthProp) {
          const res = await this.sendRequest();
          if (res.data) {
            this.exactMatch = res.data.exactMatch || null;
            this.suggestions = res.data.suggestions || [];
          } else {
            this.exactMatch = null;
            this.suggestions = [];
          }
        } else {
          this.suggestions = [];
          this.exactMatch = null;
        }
        this.isActiveValidation = true;
        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async sendRequest() {
      await this.sleep(700);
      // START REQUEST
      const res = {
        data: {
          exactMatch: null,
          // suggestions:[],
          suggestions: [
            {
              code: "EF96",
              html: "<h2>Hello Suggestion 1 !</h2><p><br /></p><p><em>Ceci est un <strong>exemple</strong></em></p>"
            },
            {
              code: "EF98",
              html: "<h2>Hello Suggestion 2 !</h2><p><br /></p><p><em>Ceci est un <strong>exemple</strong></em></p>"
            },
            {
              code: "ED97",
              html: "<h2>Hello Suggestion 3 !</h2><p><br /></p><p><em>Ceci est un <strong>exemple</strong></em></p>"
            }
          ]
        }
      };
      if (!this.isSearchProp) {
        res.data.exactMatch = {
          code: "EF97",
          html: "<h2>Hello World !</h2><p><br /></p><p><em>Ceci est un <strong>exemple</strong></em></p>"
        };
      }
      // END REQUEST
      return res;
    },
    async onItemChosen(item) {
      if (item) {
        this.$emit("onUpdateParent", item);
      }
    },
    getHtml(html) {
      return html
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
        .replace(/javascript:[^"].*(")/gi, "\"")
        .replace(/javascript:[^'].*(')/gi, "'")
        .replace(/(onclick|onmouseover)="[^"].*"/gi, "")
        .replace(/(onclick|onmouseover)=\([^)].*\)/gi, "")
        .replace(/(onclick|onmouseover)='[^'].*'/gi, "");
    }
  }
};
</script>

<style scoped src="@shared/views/Helpers/InputSearch.scss" lang="scss"></style>

<template>
  <b-button
    v-show="problematicCount > 0"
    size="sm"
    class="pr-3 kt-page-header__action text-danger"
    variant="outline-secondary"
    @click="navigateToProblematicPage"
  >
    <span class="font-weight-semi-bold">{{ $tc("problematicFiles." + typeProp, problematicCount, {count: (problematicCount + (problematicCount === 50 ? '+' : ''))}) }}</span>
  </b-button>
</template>

<script>
// services
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
import histologyReportsServices from "@/services/API/histologyReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error],
  props: {
    typeProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general
      problematicCount: 0
    };
  },
  computed: {},
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import data
      await this.getProblematicCount();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    // import problematic count
    async getProblematicCount() {
      try {
        let res = null;
        if (this.typeProp === "cytologyReports") {
          res = await cytologyReportsServices.getAllByCytotechnician(
            1,
            { problematicFiles: true },
            { by: "", desc: false }
          );
          this.problematicCount = res.data.count;
        } else if (this.typeProp === "histologyReports") {
          res = await histologyReportsServices.getAllByCytotechnician(
            1,
            { problematicFiles: true },
            { by: "", desc: false }
          );
          this.problematicCount = res.data.count;
        } else if (this.typeProp === "cytologyValidation") {
          res = await cytologyReportsServices.getAllByPathologist(
            1,
            { problematicFiles: true },
            { by: "", desc: false }
          );
          this.problematicCount = res.data.count;
        } else if (this.typeProp === "histologyValidation") {
          res = await histologyReportsServices.getAllByPathologist(
            1,
            { problematicFiles: true },
            { by: "", desc: false }
          );
          this.problematicCount = res.data.count;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate
    navigateToProblematicPage() {
      if (this.typeProp === "cytologyReports") {
        this.navigate("diagnosis/medicalReports/cytologyReports/problematicFiles");
      } else if (this.typeProp === "histologyReports") {
        this.navigate("diagnosis/medicalReports/histologyReports/problematicFiles");
      } else if (this.typeProp === "cytologyValidation") {
        this.navigate("diagnosis/validation/cytologyValidation/problematicFiles");
      } else if (this.typeProp === "histologyValidation") {
        this.navigate("diagnosis/validation/histologyValidation/problematicFiles");
      }
    }
  }
};
</script>

<template>
  <div class="kt-balanceReport mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("reports.balanceReport.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- search form -->
    <ReportsSearchForm
      :laboratoryId="laboratoryId"
      :organisationType="organisationType"
      :organisation="organisation"
      :startDate="startDate"
      :endDate="endDate"
      :balanceDate="balanceDate"
      displayBalanceDateProp
      hideBalanceStartDateProp
      balanceEndDateMustBeAfterEndDateProp
      alwaysDisplayBalanceDatesProp

      :isValidationActive="searchFormValidation"

      @inputLaboratoryId="laboratoryId = $event; searchHasChanged = true;"
      @InputOrganisationType="organisationType = $event; searchHasChanged = true;"
      @inputOrganisation="organisation = $event; searchHasChanged = true;"
      @inputStartDate="startDate = $event; searchHasChanged = true;"
      @inputEndDate="endDate = $event; searchHasChanged = true;"
      @inputBalanceDate="balanceDate = $event; searchHasChanged = true;"

      @updateState="searchFormState = $event"
      @submit="onPageInput"
    />

    <!-- divider -->
    <div class="w-100 border-top mt-4 mb-4"></div>

    <!-- sub-nav -->
    <b-nav
      pills
      class="kt-sub-nav mt-n3 mb-4"
      :class="'kt-sub-nav--' + $systemSettings.theme"
    >
      <!-- files -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="reportDisplayedTable === 'files'"
        @click="reportDisplayedTable = 'files'"
      >
        {{ $t("reports.balanceReport.files") }}
      </b-nav-item>
      <!-- unknown -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="reportDisplayedTable === 'unknown'"
        :disabled="lastSearchOrganisationType === null || lastSearchOrganisationType === 'healthCare'"
        @click="reportDisplayedTable = 'unknown'"
      >
        {{ $t("reports.balanceReport.unknown") }}
      </b-nav-item>
      <!-- unreconciled -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="reportDisplayedTable === 'unreconciled'"
        @click="reportDisplayedTable = 'unreconciled'"
      >
        {{ $t("reports.balanceReport.unreconciled") }}
      </b-nav-item>
    </b-nav>

    <!-- result (totals) -->
    <div
      v-if="files && files.length"
      class="kt-item-list kt-item-list--full-width kt-item-list--shadow mb-4"
    >
      <div class="kt-item-list__item-wrapper">
        <div
          class="kt-item-list__item mb-0"
          style="padding: 20px;"
        >
          <div class="d-flex justify-content-around align-items-center text-center">
            <h4
              v-show="reportDisplayedTable === 'files'"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.invoicedTotal") }}</span>
              <br /><span>{{ $n((invoicedTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'files'"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.transformedTotal") }}</span>
              <br /><span>{{ transformedTotal ? $n(transformedTotal / 100, "currency") : $t("general.emptyWithHyphen") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'files'"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.paidTotal") }}</span>
              <br /><span>{{ $n((paidTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'files'"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.compensatedTotal") }}</span>
              <br /><span>{{ compensatedTotal ? $n(-compensatedTotal / 100, "currency") : $t("general.emptyWithHyphen") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'files'"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.balanceTotal") }}</span>
              <br /><span>{{ $n((balanceTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'unknown' && lastSearchOrganisationType !== null && lastSearchOrganisationType !== 'healthCare'"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.unknownPaymentsTotal") }}</span>
              <br /><span>{{ unknownPaymentsTotal ? $n(unknownPaymentsTotal / 100, "currency") : $t("general.emptyWithHyphen") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType === null"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.unreconciledPaymentsTotal") }}</span>
              <br /><span>{{ awaitingPaymentsTotal ? $n(awaitingPaymentsTotal / 100, "currency") : $t("general.emptyWithHyphen") }}</span>
            </h4>
            <h4
              v-show="reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType !== null"
              class="kt-reports__total-title border-right"
            >
              <span class="kt-reports__total-text">{{ $t("reports.balanceReport.unreconciledPaymentsTotal") }}</span>
              <br /><span>{{ unreconciledPaymentsTotal ? $n(unreconciledPaymentsTotal / 100, "currency") : $t("general.emptyWithHyphen") }}</span>
            </h4>
            <div class="ml-3">
              <b-button
                :variant="$systemSettings.theme"
                :disabled="searchHasChanged"
                @click="onExport('xlsx')"
              >
                {{ $t("general.downloadXlsx") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- progress bar -->
    <b-progress
      v-show="showProgress"
      :value="progressValue"
      :max="progressMax"
      show-progress
      animated
    ></b-progress>

    <!-- files table -->
    <b-table
      v-show="reportDisplayedTable === 'files'"
      class="mt-4 mb-0 kt-table"
      hover
      :fields="fieldsFormatted"
      :items="files"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- OrganisationCode -->
          <b-th
            v-show="organisation || organisationType !== null"
            class="kt-table__th-search"
          >
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter HospitalisationNumber -->
          <b-th
            v-show="organisation || organisationType !== null"
            class="kt-table__th-search"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter CreationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterCreationDate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter Patient -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 120px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-button
              ref="filterPatientChoose"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :style="filterPatient === null ? 'padding-left: 34px;' : ''"
              :variant="'outline-' + $systemSettings.theme"
              @click="$bvModal.show('patientChooseModal-' + _uid)"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            >
              <!-- search icon -->
              <b-icon
                v-show="filterPatient === null"
                class="kt-table__th-search-input__search-icon"
                icon="search"
              ></b-icon>
              <!-- value -->
              <span class="kt-table__th-search-input__text-overflow">{{ filterPatient ? filterPatient.firstName + ' ' + filterPatient.lastName : '' }}</span>
            </b-button>
            <b-button
              v-show="filterPatient"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterPatient = null;
                searchHasChanged = true;
                $refs.filterPatientChoose.focus()
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Invoiced -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Transformed -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter AwaitingPayment -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Gain/Loss -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Balance -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- accessNumber -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.accessNumber }}
          <!-- complementary -->
          <span
            v-show="data.item.parentFileId"
            class="kt-complementary-icon"
          >
            <b-icon icon="back"></b-icon>
          </span>
        </div>
      </template>

      <!-- patientName -->
      <template v-slot:cell(patientName)="data">
        <PersonName
          :sexNameProp="data.item.patient.sex ? data.item.patient.sex.name : ''"
          :firstNameProp="data.item.patient.firstName"
          :lastNameProp="data.item.patient.lastName"
          :maidenNameProp="data.item.patient.maidenName ? data.item.patient.maidenName : ''"
        />
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 39px;">
          <!-- view -->
          <b-button
            class=""
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/file/view', {
              fileIdProp: data.item.id,
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- unknown table -->
    <b-table
      v-show="reportDisplayedTable === 'unknown'"
      class="mt-4 mb-0 kt-table"
      hover
      :fields="fieldsUnknown"
      :items="unknownPayments"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- OrganisationCode -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- PaymentDate -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter HospitalisationNumber -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 39px;">
          <!-- view -->
          <b-button
            class=""
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/paymentReport/paymentLinesEdit', {paymentReportIdProp: data.item.paymentGroup.paymentReportId, paymentGroupIdProp: data.item.paymentGroupId})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- unreconciled table (organisation) -->
    <b-table
      v-show="reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType !== null"
      class="mt-4 mb-0 kt-table"
      hover
      :fields="fieldsUnreconciled"
      :items="unreconciledPayments"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- OrganisationCode -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- PaymentDate -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Reconciled -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Unreconciled -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 39px;">
          <!-- view -->
          <b-button
            class=""
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="() => {
              if (data.item.paymentTypeId) {
                navigate('accounting/payments/patientPayments', {accessNumberProp: data.item.accessNumber, isAwaitingProp: true})
              } else if (data.item.paymentGroups) {
                navigate('accounting/payments/paymentReport/paymentGroupsEdit', {paymentReportIdProp: data.item.id})
              } else {
                navigate('accounting/payments/healthCarePayment/linesEdit', {healthCarePaymentIdProp: data.item.id})
              }
            }"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- unreconciled table (patient) -->
    <b-table
      v-show="reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType === null"
      class="mt-4 mb-0 kt-table"
      hover
      :fields="fieldsAwaiting"
      :items="awaitingPayments"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- filter paymentTypeId -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- PaymentDate -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 39px;">
          <!-- view -->
          <b-button
            class=""
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="() => {
              navigate('accounting/payments/patientPayments', {accessNumberProp: data.item.accessNumber, isAwaitingProp: true})
            }"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!loading && !searchHasChanged &&
        (
          (reportDisplayedTable === 'files' && (!files || !files.length)) ||
          (reportDisplayedTable === 'unknown' && (!unknownPayments || !unknownPayments.length)) ||
          (reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType === null && (awaitingPayments.length === 0)) ||
          (reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType !== null && (unreconciledPayments.length === 0))
        )"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show=" !loading &&
        (reportDisplayedTable === 'files' && filesMaxReached) ||
        (reportDisplayedTable === 'unknown' && unknownMaxReached) ||
        (reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType === null && awaitingMaxReached) ||
        (reportDisplayedTable === 'unreconciled' && lastSearchOrganisationType !== null && unreconciledMaxReached)
      "
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import ReportsSearchForm from "@/views/Accounting/Reports/ReportsSearchForm";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import PersonName from "@shared/views/Helpers/PersonName";
// services
import balanceReportsServices from "@/services/API/balanceReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { ReportsSearchForm, PatientChoose, PersonName },
  mixins: [userRights, error, date, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      exportLoading: false,
      searchHasChanged: false,
      lastSearchOrganisationType: null,
      reportDisplayedTable: "files", // "files", "unknown", "unreconciled"
      filesMaxReached: false,
      unknownMaxReached: false,
      awaitingMaxReached: false,
      unreconciledMaxReached: false,
      // progress
      showProgress: false,
      progressMax: 1,
      progressValue: 0,
      // search filters
      laboratoryId: null,
      organisationType: null,
      organisation: null,
      startDate: "",
      endDate: "",
      balanceDate: "",
      // validation
      searchFormState: true,
      searchFormValidation: false,
      // totals
      invoicedTotal: 0,
      transformedTotal: 0,
      paidTotal: 0,
      compensatedTotal: 0,
      unknownPaymentsTotal: 0,
      unreconciledPaymentsTotal: 0,
      awaitingPaymentsTotal: 0,
      balanceTotal: 0,
      // table filters
      filterAccessNumber: "",
      filterHospitalisationNumber: "",
      filterCreationDate: "",
      filterPatient: null,
      patientInitialFilterValues: {},
      // files fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoicedOrganisation",
          sortable: true,
          label: this.$t("reports.balanceReport.invoicedOrganisationShort"),
          formatter: (_value, _key, item) => {
            if (item.invoicedOrganisation) {
              return item.invoicedOrganisation.code;
            } else {
              return this.$t("organisationType.patients");
            }
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "creationDate",
          sortable: true,
          label: this.$t("files.creationDateShort"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "patientName",
          sortable: true,
          label: this.$t("reports.sales.patientName"),
          formatter: (_value, _key, item) => {
            return item.patient.lastName;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiced",
          sortable: false,
          label: this.$t("reports.balanceReport.invoiced"),
          formatter: (_value, _key, item) => {
            return this.$n((item.invoiced || 0) / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "transformed",
          sortable: false,
          label: this.$t("reports.balanceReport.transformed"),
          formatter: (_value, _key, item) => {
            return item.transformed ? this.$n(item.transformed / 100, "currency") : this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("reports.balanceReport.paid"),
          formatter: (_value, _key, item) => {
            return this.$n((item.paid || 0) / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "awaitingPayment",
          sortable: false,
          label: this.$t("reports.balanceReport.awaitingPayment"),
          formatter: (_value, _key, item) => {
            return item.awaitingPaymentsPaid ? this.$n((item.awaitingPaymentsPaid) / 100, "currency") : this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "compensated",
          sortable: false,
          label: this.$t("reports.balanceReport.compensated"),
          formatter: (_value, _key, item) => {
            return item.compensated ? this.$n((-item.compensated) / 100, "currency") : this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "balance",
          sortable: false,
          label: this.$t("reports.balanceReport.balance"),
          formatter: (_value, _key, item) => {
            return this.$n((item.balance || 0) / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      // unknown fields
      fieldsUnknown: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "organisation",
          sortable: true,
          label: this.$t("reports.balanceReport.organisationCode"),
          formatter: (_value, _key, item) => {
            return item.organisationCode;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentDate",
          sortable: true,
          label: this.$t("reports.balanceReport.paymentDate"),
          formatter: (_value, _key, item) => {
            return this.$d(new Date(item.paymentDate), "date");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: true,
          label: this.$t("payments.unknownPayments.firstName"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: true,
          label: this.$t("payments.unknownPayments.lastName"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("reports.balanceReport.paid"),
          formatter: (_value, _key, item) => {
            return this.$n((item.paid || 0) / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      // unreconciled fields
      fieldsUnreconciled: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "organisationCode",
          sortable: true,
          label: this.$t("reports.balanceReport.organisationCode"),
          formatter: (_value, _key, item) => {
            return item.organisation ? item.organisation.code : this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentDate",
          sortable: true,
          label: this.$t("reports.balanceReport.paymentDate"),
          formatter: (_value, _key, item) => {
            return this.$d(new Date(item.paymentDate), "date");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("reports.balanceReport.paid"),
          formatter: (_value, _key, item) => {
            return this.$n(item.paid / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "reconciled",
          sortable: false,
          label: this.$t("reports.balanceReport.reconciled"),
          formatter: (_value, _key, item) => {
            return this.$n(item.reconciled / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "unreconciled",
          sortable: false,
          label: this.$t("reports.balanceReport.unreconciled"),
          formatter: (_value, _key, item) => {
            return this.$n((item.paid - item.reconciled) / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      // awaiting fields
      fieldsAwaiting: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentType",
          sortable: true,
          label: this.$t("reports.balanceReport.paymentType"),
          formatter: (_value, _key, item) => {
            return item.paymentTypeId ? this.paymentTypesIdToName[item.paymentTypeId] : this.$t("general.emptyWithHyphen");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentDate",
          sortable: true,
          label: this.$t("reports.balanceReport.paymentDate"),
          formatter: (_value, _key, item) => {
            return this.$d(new Date(item.paymentDate), "date");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("reports.balanceReport.paid"),
          formatter: (_value, _key, item) => {
            return this.$n(item.paid / 100, "currency");
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      // file export
      fileName: "",
      // table items
      files: [],
      unknownPayments: [],
      unreconciledPayments: [],
      awaitingPayments: [],
      // saveParamsConfig
      saveParamsConfig: {
        laboratoryId: "number",
        organisationType: "string",
        organisation: { id: "number", code: "string", name: "string" },
        startDate: "string",
        endDate: "string",
        balanceDate: "string",
        filterAccessNumber: "string",
        filterHospitalisationNumber: "string",
        filterCreationDate: "string",
        filterPatient: {
          id: "number",
          firstName: "string",
          lastName: "string",
          maidenName: "string"
        }
      }
    };
  },
  computed: {
    fieldsFormatted: function() {
      return this.fields.filter((v) => {
        if (v.key === "hospitalisationNumber" || v.key === "invoicedOrganisation") {
          return this.organisation || this.organisationType !== null;
        } else {
          return true;
        }
      });
    },
    // options
    paymentTypesIdToName: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.paymentTypes)) || [];
      options.unshift({ id: null, localisedName: this.$tc("general.allMasculine", 2) });
      return options.reduce((o, key) => ({ ...o, [key.id]: key.localisedName }), {});
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;
  },
  methods: {
    // patient
    async onPatientChosen(patient) {
      this.filterPatient = patient ? { id: patient.id, firstName: patient.firstName, lastName: patient.lastName, maidenName: patient.maidenName } : null;
      this.searchHasChanged = true;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },

    // progress bar
    onGenerateProgress(res) {
      const done = res.currentTarget.response.split("|");
      if (done.length > 1) {
        if (isNaN(Number(done[done.length - 1]))) {
          this.progressValue = this.progressMax;
        } else {
          this.progressMax = done[0];
          this.progressValue = done[done.length - 1];
        }
      } else {
        this.progressValue = Math.min(this.progressMax, this.progressValue + 1);
      }
    },

    // submit functions
    validateForm() {
      this.searchFormValidation = true;

      return (
        this.searchFormState !== false
      );
    },
    async onPageInput() {
      try {
        // validation
        if (this.validateForm() === false) return false;
        // validation dates
        if (this.filterFileCreationDate !== "") {
          const filterFileCreationDate = new Date(this.filterFileCreationDate).getFullYear();
          if (filterFileCreationDate < 1900 || filterFileCreationDate > 2300) {
            return false;
          }
        }
        if (this.startDate !== "") {
          const startDate = new Date(this.startDate).getFullYear();
          if (startDate < 1900 || startDate > 2300) {
            return false;
          }
        }
        if (this.endDate !== "") {
          const endDate = new Date(this.endDate).getFullYear();
          if (endDate < 1900 || endDate > 2300) {
            return false;
          }
        }
        if (this.balanceDate !== "") {
          const balanceDate = new Date(this.balanceDate).getFullYear();
          if (balanceDate < 1900 || balanceDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        this.showProgress = true;
        this.progressValue = 0;
        this.progressMax = 1;
        const res = await balanceReportsServices.postAll(
          {
            laboratoryId: this.laboratoryId,
            organisationType: !this.organisation ? this.organisationType : null,
            organisationId: this.organisation ? this.organisation.id : null,
            startDate: this.startDate,
            endDate: this.endDate,
            balanceDate: this.balanceDate,

            accessNumber: this.filterAccessNumber,
            hospitalisationNumber: this.organisation || this.organisationType !== null ? this.filterHospitalisationNumber : "",
            creationDate: this.filterCreationDate || null,
            patientId: this.filterPatient ? this.filterPatient.id : null
          }, this.onGenerateProgress
        );
        this.showProgress = false;
        this.progressValue = 0;
        this.progressMax = 1;

        const balanceReport = JSON.parse(res.data.split("|").pop());
        if (balanceReport.fileName) {
          // assign result
          this.fileName = balanceReport.fileName;

          this.files = balanceReport.files;
          this.filesMaxReached = balanceReport.filesMaxReached;

          this.unknownPayments = balanceReport.unknownPayments;
          this.unknownMaxReached = balanceReport.unknownMaxReached;
          this.unknownPaymentsTotal = balanceReport.unknownPaymentsTotal;

          this.unreconciledPayments = balanceReport.unreconciledPayments;
          this.unreconciledMaxReached = balanceReport.unreconciledMaxReached;
          this.unreconciledPaymentsTotal = balanceReport.unreconciledPaymentsTotal;

          this.awaitingPayments = balanceReport.awaitingPayments;
          this.awaitingMaxReached = balanceReport.awaitingMaxReached;
          this.awaitingPaymentsTotal = balanceReport.awaitingPaymentsTotal;

          this.invoicedTotal = balanceReport.invoicedTotal;
          this.transformedTotal = balanceReport.transformedTotal;
          this.paidTotal = balanceReport.paidTotal;
          this.compensatedTotal = balanceReport.compensatedTotal;
          this.balanceTotal = balanceReport.balanceTotal;
          this.lastSearchOrganisationType = balanceReport.searchedOrganisationType;
          // handle active tab
          if ((this.lastSearchOrganisationType === null || this.lastSearchOrganisationType === "healthCare") && this.reportDisplayedTable === "unknown") {
            this.reportDisplayedTable = "files";
          }
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("reports.balanceReport.reportNotCreatedTitle"),
            message: this.$t("reports.balanceReport.reportNotCreatedText")
          });
        }

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onExport(type) {
      try {
        // validation
        if (this.validateForm() === false) return false;
        // validation dates
        if (this.filterFileCreationDate !== "") {
          const filterFileCreationDate = new Date(this.filterFileCreationDate).getFullYear();
          if (filterFileCreationDate < 1900 || filterFileCreationDate > 2300) {
            return false;
          }
        }
        if (this.startDate !== "") {
          const startDate = new Date(this.startDate).getFullYear();
          if (startDate < 1900 || startDate > 2300) {
            return false;
          }
        }
        if (this.endDate !== "") {
          const endDate = new Date(this.endDate).getFullYear();
          if (endDate < 1900 || endDate > 2300) {
            return false;
          }
        }
        if (this.balanceDate !== "") {
          const balanceDate = new Date(this.balanceDate).getFullYear();
          if (balanceDate < 1900 || balanceDate > 2300) {
            return false;
          }
        }

        this.exportLoading = true;
        const organisationTypeFormatted = this.$t("organisationTypePlural." + (this.organisationType || "patients")).toLowerCase();
        let organisationFormatted = this.organisation ? this.organisation.code : organisationTypeFormatted;
        organisationFormatted = organisationFormatted.replace(/ /g, "-");

        const resFile = await balanceReportsServices.getBalanceReport(this.fileName);

        if (resFile.data.type === "application/json") {
          const errorString = await resFile.data.text();
          const errorObject = JSON.parse(errorString);
          if (errorObject && errorObject.error === "fileNotExists") {
            this.$emit("alert", "info", {
              title: this.$t("reports.balanceReport.fileNotExistsTitle"),
              message: this.$t("reports.balanceReport.fileNotExistsText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("general.downloadFileErrorTitle"),
              message: this.$t("general.downloadFileErrorText")
            });
          }
        } else {
          fileSaver.saveAs(resFile.data, this.$t("reports.balanceReport.fileTitle") + "_" + organisationFormatted + "_" + this.startDate + "_" + this.endDate + "." + type);
        }
        this.exportLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<template>
  <div class="systemSettings">
    <div class="d-flex justify-content-center">
      <label class="m-2">{{ $t("systemSettings.automaticLogout") }}</label>
      <b-form-input
        v-model="automaticLogout"
        type="range"
        min="1"
        max="120"
        class="m-2 w-25"
        @change="onAutomaticLogoutChange"
      ></b-form-input>
      <label class="m-2">{{ automaticLogout }}</label>
    </div>
    <div class="d-flex justify-content-center">
      <label class="m-2">{{ $t("systemSettings.maxFailedLoginAttempts") }}</label>
      <b-form-input
        v-model="maxFailedLoginAttempts"
        type="range"
        min="1"
        max="10"
        class="m-2 w-25"
        @change="onMaxFailedLoginAttemptsChange"
      ></b-form-input>
      <label class="m-2">{{ maxFailedLoginAttempts }}</label>
    </div>
    <div class="d-flex justify-content-center">
      <label class="m-2">{{ $t("systemSettings.allowedDomains") }}</label>
      <b-input-group class="m-2 w-50">
        <b-form-input v-model="domain"></b-form-input>
        <b-input-group-append>
          <b-button @click="onAddDomain">
            {{ $t("systemSettings.plus") }}
          </b-button>
          <b-button @click="onRemoveDomain">
            {{ $t("systemSettings.minus") }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div
      v-for="allowedDomain in allowedDomains"
      :key="allowedDomain"
    >
      <div class="d-flex justify-content-center">
        <label class="m-2">{{ allowedDomain }}</label>
      </div>
    </div>
    <!-- table -->
    <b-table
      class="m-0 kt-table"
      hover
      :fields="userSessionsFields"
      :items="userSessions"
      :busy="userSessionsLoading"
      :sort-by="userSessionsSortBy"
      :sort-desc="userSessionsSortDesc"
      no-local-sorting
      @sort-changed="loadUserSessions"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- filter email -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterUserSessionsEmail"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onUserSessionsFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterUserSessionsEmail !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onUserSessionsClearFilter('filterUserSessionsEmail')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter firstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterUserSessionsFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onUserSessionsFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterUserSessionsFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onUserSessionsClearFilter('filterUserSessionsFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter lastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterUserSessionsLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onUserSessionsFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterUserSessionsLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onUserSessionsClearFilter('filterUserSessionsLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <b-th class="kt-table__th-search"></b-th>
          <b-th class="kt-table__th-search"></b-th>
          <b-th class="kt-table__th-search"></b-th>
          <b-th class="kt-table__th-search"></b-th>
        </b-tr>
      </template>
      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!userSessionsCount && !userSessionsLoading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar>
        <b-navbar-nav>
          <b-pagination
            v-show="userSessionsCount"
            v-model="userSessionsPage"
            class="mx-2 my-3"
            :class="'kt-pagination--' + $systemSettings.theme"
            :total-rows="userSessionsCount"
            per-page="50"
            @input="loadUserSessions"
          ></b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>

    <!-- table -->
    <b-table
      class="m-0 kt-table"
      hover
      :fields="contactSessionsFields"
      :items="contactSessions"
      :busy="contactSessionsLoading"
      :sort-by="contactSessionsSortBy"
      :sort-desc="contactSessionsSortDesc"
      no-local-sorting
      @sort-changed="loadContactSessions"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr class="kt-table__tr-search">
          <!-- filter email -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterContactSessionsEmail"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onContactSessionsFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterContactSessionsEmail !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onContactSessionsClearFilter('filterContactSessionsEmail')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter firstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterContactSessionsFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onContactSessionsFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterContactSessionsFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onContactSessionsClearFilter('filterContactSessionsFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter lastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterContactSessionsLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onContactSessionsFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterContactSessionsLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onContactSessionsClearFilter('filterContactSessionsLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <b-th class="kt-table__th-search"></b-th>
          <b-th class="kt-table__th-search"></b-th>
          <b-th class="kt-table__th-search"></b-th>
          <b-th class="kt-table__th-search"></b-th>
        </b-tr>
      </template>
      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!contactSessionsCount && !contactSessionsLoading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar>
        <b-navbar-nav>
          <b-pagination
            v-show="contactSessionsCount"
            v-model="contactSessionsPage"
            class="mx-2 my-3"
            :class="'kt-pagination--' + $systemSettings.theme"
            :total-rows="contactSessionsCount"
            per-page="50"
            @input="loadContactSessions"
          ></b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import commonServices from "@shared/services/API/commonServices";
import systemSettingsServices from "@shared/services/API/systemSettingsServices";
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";

import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, pagination, error, date],
  data: function() {
    return {
      userSessionsLoading: false,
      contactSessionsLoading: false,
      userSessionsPage: 1,
      contactSessionsPage: 1,
      userSessionsSortBy: "",
      contactSessionsSortBy: "",
      userSessionsSortDesc: false,
      contactSessionsSortDesc: false,
      // table fields
      userSessionsFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "user.email",
          sortable: true,
          label: this.$t("systemSettings.email")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "user.firstName",
          sortable: true,
          label: this.$t("systemSettings.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "user.lastName",
          sortable: true,
          label: this.$t("systemSettings.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "user.phone",
          sortable: false,
          label: this.$t("systemSettings.phone")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "createdAt",
          sortable: false,
          label: this.$t("systemSettings.createdAt"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "updatedAt",
          sortable: false,
          label: this.$t("systemSettings.updatedAt"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "expires",
          sortable: false,
          label: this.$t("systemSettings.expiresAt"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true) : "";
          }
        }
      ],
      // table fields
      contactSessionsFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "contact.email",
          sortable: true,
          label: this.$t("systemSettings.email")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "contact.firstName",
          sortable: true,
          label: this.$t("systemSettings.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "contact.lastName",
          sortable: true,
          label: this.$t("systemSettings.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "contact.phone",
          sortable: false,
          label: this.$t("systemSettings.phone")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "createdAt",
          sortable: false,
          label: this.$t("systemSettings.createdAt"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "updatedAt",
          sortable: false,
          label: this.$t("systemSettings.updatedAt"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "expires",
          sortable: false,
          label: this.$t("systemSettings.expiresAt"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value, true, true) : "";
          }
        }
      ],
      automaticLogout: 1,
      maxFailedLoginAttempts: 1,
      allowedDomains: [],
      domain: "",
      filterUserSessionsFirstName: "",
      filterUserSessionsLastName: "",
      filterUserSessionsEmail: "",
      userSessionsCount: 0,
      userSessions: [],
      filterContactSessionsFirstName: "",
      filterContactSessionsLastName: "",
      filterContactSessionsEmail: "",
      contactSessionsCount: 0,
      contactSessions: []
    };
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.onPageInput();
  },
  methods: {
    async onUserSessionsClearFilter(fieldName) {
      this.userSessionsLoading = true;
      this[fieldName] = "";
      await this.loadUserSessions();
      this.userSessionsLoading = false;
    },
    async onContactSessionsClearFilter(fieldName) {
      this.contactSessionsLoading = true;
      this[fieldName] = "";
      await this.loadContactSessions();
      this.contactSessionsLoading = false;
    },
    async onPageInput() {
      try {
        let res = await systemSettingsServices.get("automaticLogout");
        this.automaticLogout = res.data.value;
        res = await systemSettingsServices.get("maxFailedLoginAttempts");
        this.maxFailedLoginAttempts = res.data.value;
        res = await systemSettingsServices.get("allowedDomains");
        this.allowedDomains = res.data.value;
        this.$watch("allowedDomains", this.onAllowedDomainsChange, { deep: true });
        this.loadUserSessions();
        this.loadContactSessions();
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async loadUserSessions() {
      this.userSessionsLoading = true;
      const res = await commonServices.showAll(
        "userSessions",
        this.userSessionsPage,
        {
          firstName: this.filterUserSessionsFirstName,
          lastName: this.filterUserSessionsLastName,
          email: this.filterUserSessionsEmail
        },
        {
          by: this.userSessionsSortBy,
          desc: this.userSessionsSortDesc
        }
      );
      this.userSessionsCount = res.data.count;
      this.userSessions = res.data.rows;
      this.userSessionsLoading = false;
    },
    async loadContactSessions() {
      this.contactSessionsLoading = true;
      const res = await commonServices.showAll(
        "contactSessions",
        this.contactSessionsPage,
        {
          firstName: this.filterContactSessionsFirstName,
          lastName: this.filterContactSessionsLastName,
          email: this.filterContactSessionsEmail
        },
        {
          by: this.contactSessionsSortBy,
          desc: this.contactSessionsSortDesc
        }
      );
      this.contactSessionsCount = res.data.count;
      this.contactSessions = res.data.rows;
      this.contactSessionsLoading = false;
    },
    // search update (with a delay)
    onUserSessionsFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadUserSessions();
      }, 450);
    },
    // search update (with a delay)
    onContactSessionsFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadContactSessions();
      }, 450);
    },
    async onAutomaticLogoutChange() {
      try {
        await systemSettingsServices.put("automaticLogout", this.automaticLogout);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onMaxFailedLoginAttemptsChange() {
      try {
        await systemSettingsServices.put("maxFailedLoginAttempts", this.maxFailedLoginAttempts);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onAllowedDomainsChange() {
      try {
        await systemSettingsServices.put("allowedDomains", this.allowedDomains);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onAddDomain() {
      if (!this.allowedDomains.includes(this.domain)) {
        this.allowedDomains.push(this.domain);
        this.domain = "";
      }
    },
    onRemoveDomain() {
      const index = this.allowedDomains.indexOf(this.domain);
      if (index > -1) {
        this.allowedDomains.splice(index, 1);
        this.domain = "";
      }
    }
  }
};
</script>

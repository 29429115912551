<template>
  <div class="kt-hpvResultsView">
    <!-- modal header -->
    <header
      class="modal-header"
      style="margin: -1rem -1rem 0 -1rem;"
    >
      <div>
        <!-- title -->
        <h5
          class="modal-title kt-page-header__title h4 mb-0"
          style="font-size: 22px; line-height: 24px;"
        >
          {{ $t("hpvResultsView.pageTitle") }}
        </h5>
      </div>
      <!-- close -->
      <button
        ref="closeButton"
        type="button"
        aria-label="Close"
        class="close"
        @click="$emit('close', true)"
      >
        {{ $t('general.closeX') }}
      </button>
    </header>

    <!-- hpvResults list -->
    <div>
      <div
        v-for="hpvResult in hpvResultsProp"
        :key="hpvResult.id"
        class="kt-file-choose-from-ids__item"
      >
        <div
          class="kt-file-choose-from-ids__link"
          @click.prevent="$emit('chosen', file)"
        >
          <div class="kt-file-choose-from-ids__link-wrapper">
            <!-- left side -->
            <div>
              <div class="font-weight-semi-bold">
                {{ getLocalisedHpvResult(hpvResult.content) }}
              </div>
              <div
                class="kt-file-choose-from-ids__link-subtext"
                style="color: #676d72;"
              >
                {{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(hpvResult.timestamp, $systemSettings.locale, {
                  displayTime: true,
                  displayYear: null,
                  shortToday: false,
                  shortYesterday: false
                })) }}
                <br />{{ capitalizeFirstLetter(hpvResult.instrument.brand) + " " + capitalizeFirstLetter(hpvResult.instrument.model) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
import cytologyReportHelpers from "@/services/UI/cytologyReportHelpers";

export default {
  mixins: [userRights, error, date, string, cytologyReportHelpers],
  props: {
    hpvResultsProp: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
  }
};
</script>

<style scoped src="./FileChooseFromIds.scss" lang="scss"></style>

<template>
  <div class="kt-validation">
    <!-- ReportSectorSelect -->
    <ReportSectorSelect
      currentLocationProp="validation"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- systemSettings content -->
    <div class="mt-2 mt-lg-3">
      <router-view
        :key="$route.fullPath"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </div>
  </div>
</template>

<script>
// components
import ReportSectorSelect from "@/views/Diagnosis/ReportSectorSelect";
// helpers

export default {
  components: { ReportSectorSelect },
  mixins: [],
  methods: {}
};
</script>

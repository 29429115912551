<template>
  <div class="kt-file-choose-from-previous">
    <!-- modal header -->
    <header
      class="modal-header"
      style="margin: -1rem -1rem 0 -1rem;"
    >
      <div>
        <!-- title -->
        <h5
          class="modal-title kt-page-header__title h4 mb-0"
          style="font-size: 22px; line-height: 24px;"
        >
          {{ $t("fileChooseFromPrevious.pageTitle") }}
        </h5>
        <!-- subtitle -->
        <p class="kt-page-header__subtitle mb-0 mt-1 line-height-sm text-secondary">
          {{ $t('fileChooseFromPrevious.pageSubtitle') }}
        </p>
        <!-- subtitle -->
        <p
          v-show="hasMissingPreviousFilesProp"
          class="kt-page-header__subtitle mb-0 line-height-sm text-14"
          style="color: rgba(199, 51, 51, 0.9) !important; margin-top: 6px;"
        >
          <b-icon
            icon="exclamation-triangle-fill"
            style="margin-right: 3px;"
          />
          {{ $t('fileArchives.hasMissingPreviousFiles') }}
        </p>
      </div>
      <!-- close -->
      <button
        ref="closeButton"
        type="button"
        aria-label="Close"
        class="close"
        @click="$emit('chosen', null)"
      >
        {{ $t('general.closeX') }}
      </button>
    </header>

    <!-- file list -->
    <div>
      <!-- empty -->
      <div
        v-if="!fileOptions || !fileOptions.length"
        class="text-center text-15 text-secondary mt-3 mb-1"
      >
        {{ $t('fileChooseFromPrevious.emptyFiles') }}
      </div>
      <!-- list -->
      <div
        v-for="(file, index) in fileOptions"
        :key="file.id"
        class="kt-file-choose-from-ids__item"
      >
        <b-link
          :ref="index === (fileOptions.length - 1) ? 'firstItemLink' : null"
          class="kt-file-choose-from-ids__link"
          @click.prevent="$emit('chosen', file)"
        >
          <span class="kt-file-choose-from-ids__link-wrapper d-flex justify-content-between align-items-center">
            <!-- left side -->
            <span
              v-if="!file.isArchive"
              class="d-block"
            >
              <span class="font-weight-semi-bold">{{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(file.creationDate, $systemSettings.locale)) }}</span>
              <span class="kt-file-choose-from-ids__link-subtext">
                <span class="d-block">{{ file.accessNumber }}</span>
                <!-- complementariesCount -->
                <span
                  v-show="file.complementariesCount"
                  style="display: block;"
                >
                  {{ $tc("fileChooseFromPrevious.complementariesCount", file.complementariesCount, {count: file.complementariesCount}) }}
                </span>
              </span>
            </span>
            <span
              v-else
              class="d-block"
            >
              <span class="font-weight-semi-bold">{{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(file.date, $systemSettings.locale, {
                displayTime: true,
                displayYear: null,
                shortToday: false,
                shortYesterday: false
              })) }}</span>
            </span>
            <!-- statuses -->
            <span class="d-block">
              <div
                v-if="!file.isArchive"
                style="white-space: nowrap;"
              >
                <StatusBadge
                  v-if="displayedStatusesProp.includes('filing') && file.filingStatus"
                  typeProp="filingStatus"
                  :statusNameProp="file.filingStatus.name"
                  isHiddenEmptyProp
                />
                <StatusBadge
                  v-if="displayedStatusesProp.includes('medical') && file.medicalStatus"
                  typeProp="medicalStatus"
                  :statusNameProp="file.medicalStatus.name"
                  isHiddenEmptyProp
                  class="ml-1"
                />
                <StatusBadge
                  v-if="displayedStatusesProp.includes('accounting') && file.accountingStatus"
                  typeProp="accountingStatus"
                  :statusNameProp="file.accountingStatus.name"
                  isHiddenEmptyProp
                  class="ml-1"
                />
              </div>
              <div v-else>
                <span class="kt-badge kt-badge--light-gray kt-file-choose-from-ids__isArchive">
                  <strong>{{ $tc("fileChooseFromPrevious.isArchive") }}</strong>
                </span>
              </div>
            </span>
          </span>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
// components
import StatusBadge from "@shared/views/Helpers/StatusBadge";

export default {
  components: {
    StatusBadge
  },
  mixins: [userRights, error, date, string],
  props: {
    fileOptionsProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    displayedStatusesProp: {
      type: Array,
      default: function() {
        return [
          "filing",
          "medical",
          "accounting"
        ];
      }
    },
    hasMissingPreviousFilesProp: {
      type: Boolean
    }
  },
  data() {
    return {
      fileOptions: []
    };
  },
  watch: {
    fileOptionsProp: {
      handler: function(val) {
        this.fileOptions = val;
        this.$nextTick(() => {
          this.focusFirstElement();
        });
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    focusFirstElement() {
      this.$refs.closeButton.focus();
    }
  }
};
</script>

<style scoped src="./FileChooseFromIds.scss" lang="scss"></style>

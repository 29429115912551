<template>
  <div class="kt-careSheet-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- careSheet form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-show="file === null"
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="null"
            @input="onLaboratoryIdChange($event)"
          />
        </b-col>

        <!-- invoicedOrganisation (healthcare) -->
        <b-col
          v-show="file !== null && file.invoicedOrganisation"
          cols="12"
          class="mb-3"
        >
          <!-- invoicedOrganisation -->
          <div class="kt-item-list">
            <div
              class="kt-item-list__item-wrapper"
            >
              <div class="kt-item-list__item mb-0">
                <div class="kt-item-list__item-title pr-0">
                  <!-- name -->
                  {{ file !== null && file.invoicedOrganisation ? file.invoicedOrganisation.name : '' }}
                </div>
                <div class="kt-item-list__item-text">
                  <!-- code -->
                  {{ file !== null && file.invoicedOrganisation ? file.invoicedOrganisation.code : '' }}
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <!-- fileId -->
        <b-col
          cols="12"
          md="4"
        >
          <InputSearchFile
            ref="searchFileInput"
            class="mb-3"

            :laboratoryIdProp="laboratoryId"
            :currentFileIdProp="null"
            :fileProp="file"

            :labelProp="file === null ? $t('careSheets.accessNumber') : $t('careSheets.file')"
            validationTypeProp="careSheet"
            :stateProp="fileIdState"
            :invalidFeedbackProp="fileIdInvalidFeedback"
            :disabledProp="false"

            :displayForAccountingProp="true"

            @onUpdateParent="file = $event; fileId = file ? file.id : null;"
            @onUpdateValidation="fileIdValidation = $event"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- file -->
        <b-col
          v-if="fileId !== null"
          cols="12"
        >
          <div class="kt-item-list kt-item-list--full-width kt-item-list--shadow">
            <div
              class="kt-item-list__item-wrapper"
            >
              <div
                class="kt-item-list__item"
                style="padding: 20px; margin-bottom: 16px;"
              >
                <FileView
                  moduleModeProp
                  displayForAccountingProp
                  :fileIdProp="fileId"
                  @alert="(variant, strings) => $emit('alert', variant, strings)"
                />
              </div>
            </div>
          </div>
        </b-col>

        <!-- divider -->
        <b-col cols="12"></b-col>

        <!-- invoiceDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('careSheets.invoiceDate')"
            :invalid-feedback="invoiceDateInvalidFeedback"
            :state="invoiceDateState"
          >
            <b-form-input
              v-model="invoiceDate"
              type="date"
              :state="invoiceDateState"
              @blur="invoiceDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InputSearchFile from "@shared/views/Helpers/InputSearchFile";
import FileView from "@/views/Office/Files/FileView";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearchFile, SmartSelectInput, FileView },
  mixins: [userRights, error, date],
  props: {
  },
  data() {
    return {
      // general
      // form variables
      laboratory: null,
      laboratoryId: null,
      invoiceDate: "",
      file: null,
      fileId: null,
      // extra variables
      fileInvoice: null,
      // is validation active
      laboratoryIdValidation: false,
      fileIdValidation: false,
      invoiceDateValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    fileIdState: function() {
      if (!this.fileIdValidation) return null;
      if (!this.fileId || !this.file) return false;
      return null;
    },
    fileIdInvalidFeedback: function() {
      if (this.fileIdState === null) return "";
      return this.$t("validationRules.required");
    },
    invoiceDateState: function() {
      if (!this.invoiceDateValidation) return null;
      return this.invoiceDate && this.invoiceDate.length > 0 ? null : false;
    },
    invoiceDateInvalidFeedback: function() {
      return this.invoiceDateState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.$t("careSheetNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.$t("careSheetNew.submitText");
    }
  },
  async mounted() {
    // setup default invoiceDate
    this.invoiceDate = this.getIsoDate();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.laboratoryInput) {
        this.$refs.laboratoryInput.focusFirstElement();
      } else if (this.$refs.searchFileInput) {
        this.$refs.searchFileInput.focusFirstElement();
      }
    },

    // laboratoryId
    onLaboratoryIdChange(value) {
      this.laboratoryId = value;
      this.fileInvoice = null;
      this.file = null;
      this.fileId = null;
    },
    // navigate to the list page
    onGoBack() {
      navigate("accounting/invoices/careSheets");
    },

    // submit functions
    validateForm() {
      this.laboratoryIdValidation = true;
      this.fileIdValidation = true;
      this.invoiceDateValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.fileIdState === false ||
        this.invoiceDateState === false
      );
    },
    async saveCareSheet() {
      try {
        const form = {
          laboratoryId: this.laboratoryId,
          fileId: this.fileId,
          invoiceDate: this.invoiceDate || null
        };
        const res = await commonServices.post("careSheets", form);
        if (res.data.id) {
          // success message
          this.$emit("alert", "success", {
            title: this.$t("careSheetNew.notifications.addedTitle"),
            message: this.$t("careSheetNew.notifications.addedText")
          });
          navigate("accounting/invoices/careSheets");
        } else if (res.data.error === "fileNotExists") {
          this.$emit("alert", "danger", {
            title: this.$t("careSheets.notifications.fileNotExistsTitle"),
            message: this.$t("careSheets.notifications.fileNotExistsText")
          });
        } else if (res.data.error === "fileNotInLaboratory") {
          this.$emit("alert", "danger", {
            title: this.$t("careSheets.notifications.fileNotInLaboratoryTitle"),
            message: this.$t("careSheets.notifications.fileNotInLaboratoryText")
          });
        } else if (res.data.error === "fileNotHealthCare") {
          this.$emit("alert", "danger", {
            title: this.$t("careSheets.notifications.fileNotHealthCareTitle"),
            message: this.$t("careSheets.notifications.fileNotHealthCareText")
          });
        } else if (res.data.error === "fileNotValidatedForAccounting") {
          this.$emit("alert", "danger", {
            title: this.$t("careSheets.notifications.fileNotValidatedForAccountingTitle"),
            message: this.$t("careSheets.notifications.fileNotValidatedForAccountingText")
          });
        } else if (res.data.error === "fileAlreadyInvoiced") {
          this.$emit("alert", "danger", {
            title: this.$t("careSheets.notifications.fileAlreadyInvoicedTitle"),
            message: this.$t("careSheets.notifications.fileAlreadyInvoicedText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("careSheetNew.notifications.additionErrorTitle"),
            message: this.$t("careSheetNew.notifications.additionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveCareSheet();
    }
  }
};
</script>

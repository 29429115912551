<template>
  <span>{{ formattedNameStart }} <span v-if="formattedNameStart && formattedMaidenName"><span class="kt-maiden-prefix">{{ $t("patients.maidenNamePrefix") }}</span> {{ formattedMaidenName }}</span></span>
</template>

<script>
export default {
  props: {
    sexNameProp: {
      type: String,
      default: ""
    },
    firstNameProp: {
      type: String,
      default: ""
    },
    lastNameProp: {
      type: String,
      default: ""
    },
    maidenNameProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    };
  },
  computed: {
    formattedNameStart: function() {
      const formattedNameArr = [];
      if (this.sexNameProp) {
        formattedNameArr.push(this.$t("sexes." + this.sexNameProp));
      }
      if (this.firstNameProp) {
        formattedNameArr.push(this.capitalize(this.firstNameProp));
      }
      if (this.lastNameProp) {
        formattedNameArr.push(this.capitalize(this.lastNameProp));
      }

      return formattedNameArr.join(" ");
    },
    formattedMaidenName: function() {
      return this.capitalize(this.maidenNameProp);
    }
  },
  methods: {
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
  }
};
</script>

<style scoped src="./PersonName.scss" lang="scss"></style>

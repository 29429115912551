<template>
  <div
    v-if="dataProp && (dataProp.isArchived || dataProp.isTransferred || hasSentDetail)"
    class="kt-document-statuses"
  >
    <!-- sent -->
    <div
      v-show="dataProp && (dataProp.isTransferred || hasSentDetail)"
      class="kt-badge kt-badge--light-gray pt-0 pb-0"
      :class="{'mr-2': dataProp && dataProp.isArchived}"
    >
      <span class="kt-badge__text">
        <span
          class="font-weight-semi-bold"
          :class="{'mr-1': hasSentDetail}"
          style="line-height: 27px;"
        >{{ $t('documentStatuses.isTransferred') }}</span>

        <span
          v-show="dataProp && dataProp.isSentByPost"
          :id="'isSentByPost' + _uid"
          class="kt-document-statuses__icon-container"
        >
          <!-- mailbox -->
          <b-icon
            icon="envelope"
            class="kt-document-statuses__icon"
          ></b-icon>
        </span>
        <span
          v-show="dataProp && dataProp.isSentByEmail"
          :id="'isSentByEmail' + _uid"
          class="kt-document-statuses__icon-container"
        >
          <b-icon
            icon="at"
            class="kt-document-statuses__icon"
          ></b-icon>
        </span>
        <span
          v-show="dataProp && dataProp.isSentElectronically"
          :id="'isSentElectronically' + _uid"
          class="kt-document-statuses__icon-container"
        >
          <!-- file-earmark-arrow-up -->
          <!-- file-lock2 -->
          <!-- wifi -->
          <b-icon
            icon="cloud"
            class="kt-document-statuses__icon"
          ></b-icon>
        </span>
      </span>
    </div>
    <!-- archived -->
    <div
      v-show="dataProp && dataProp.isArchived"
      class="kt-badge kt-badge--light-gray pt-0 pb-0"
    >
      <span class="kt-badge__text">
        <span
          class="font-weight-semi-bold mr-1"
          style="line-height: 27px;"
        >{{ $t('documentStatuses.isArchived') }}</span>

        <span
          :id="'isArchived' + _uid"
          class="kt-document-statuses__icon-container"
        >
          <!--  cloud-upload -->
          <!-- inbox -->
          <b-icon
            icon="archive"
            class="kt-document-statuses__icon"
          ></b-icon>
        </span>
      </span>
    </div>

    <!-- popovers -->
    <b-popover
      :target="'isArchived' + _uid"
      triggers="hover"
      placement="bottom"
    >
      {{ $t('documentStatuses.isArchived') }}
    </b-popover>
    <b-popover
      :target="'isSentByPost' + _uid"
      triggers="hover"
      placement="bottom"
    >
      {{ $t('documentStatuses.isSentByPost') }}
    </b-popover>
    <b-popover
      :target="'isSentByEmail' + _uid"
      triggers="hover"
      placement="bottom"
    >
      {{ $t('documentStatuses.isSentByEmail') }}
    </b-popover>
    <b-popover
      :target="'isSentElectronically' + _uid"
      triggers="hover"
      placement="bottom"
    >
      {{ $t('documentStatuses.isSentElectronically') }}
    </b-popover>
  </div>
</template>

<script>
export default {
  props: {
    dataProp: {
      type: Object,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    hasSentDetail() {
      return this.dataProp && (this.dataProp.isSentByPost || this.dataProp.isSentByEmail || this.dataProp.isSentElectronically);
    }
  },
  methods: {}
};
</script>

<style scoped src="./DocumentStatuses.scss" lang="scss"></style>

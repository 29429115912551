<template>
  <div class="kt-report-edit">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="navigateBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("histologyReport.pageTitle") }}
      </h2>
      <!-- page loader -->
      <b-spinner
        v-show="isLoading"
        class="align-middle"
        :class="'kt-spinner--' + $systemSettings.theme"
        style="margin-left: 10px;"
      ></b-spinner>
    </div>

    <!-- page body -->
    <div
      class="kt-page-body"
      :class="{'kt-page-body--loading': isLoading}"
    >
      <ReportFileDetails
        :fileDataProp="fileData"
        :openAddComplementaryProp="openAddComplementary"
        @openedAddComplementary="openAddComplementary = false;"
        @updateGroupOfFiles="fileData.groupOfFiles = $event.groupOfFiles; groupOfFiles = $event.groupOfFiles; complementaryFiles = $event.complementaryFiles;"
        @refreshReport="refreshReportByFileId(fileId)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />

      <!-- badges/warnings -->
      <div class="kt-report-edit__warnings">
        <!-- isAssignedPathologist -->
        <div
          v-show="!isAssignedPathologist"
          class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
        >
          <b-icon
            icon="pen"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text font-weight-semi-bold">{{ $t("medicalReportEdit.statusInformation.currentUserNotAssignedPathologist") }}</span>
        </div>
      </div>

      <!-- versions navigation -->
      <div
        v-show="previousHistologyReports.length"
        class="kt-validated-warning kt-badge kt-badge--light-gray kt-badge--lg mt-2"
      >
        <b-icon
          icon="clock-history"
          class="kt-badge__icon"
        ></b-icon>
        <span
          class="kt-badge__text font-weight-semi-bold"
          style="margin-right: 11px;"
        >{{ $t("medicalReportEdit.versioning.versionsSectionTitle") }}</span>
        <!-- versions buttons -->
        <b-button-group
          size="sm"
          class="kt-btn-group kt-btn-group--white-blue"
        >
          <b-button
            v-for="histologyReport in previousHistologyReports"
            :key="histologyReport.id"
            class="kt-btn-group__btn"
            :class="{'kt-btn-group__btn--active': displayedVersionId === histologyReport.id}"
            :style="'color: #a8001b !important; border-color: #a8001b !important;'"
            variant="light"
            @click="displayedVersionId = histologyReport.id"
          >
            <!-- arrow-counterclockwise rotate="270"-->
            <b-icon icon="file-earmark"></b-icon> {{ histologyReport.content.medicalValidationTimestamp ? $d(new Date(histologyReport.content.medicalValidationTimestamp), "date") : '' }}
          </b-button>
          <!-- current version button -->
          <b-button
            class="kt-btn-group__btn"
            :class="{'kt-btn-group__btn--active': displayedVersionId === null}"
            :style="
              !fileData ? 'color: #484848 !important; border-color: #5f5f5f !important;'
              : fileData.medicalStatus.name === 'validatedMedically' ? 'color: rgb(17, 84, 28) !important; border-color: rgb(27, 111, 41) !important;'
                : 'color: #484848 !important; border-color: #5f5f5f !important;'
            "
            variant="light"
            @click="displayedVersionId = null"
          >
            <b-icon icon="file-earmark"></b-icon> {{ $t("medicalReportEdit.versioning.currentVersionBackButton") }}
          </b-button>
        </b-button-group>
      </div>

      <!-- REPORT 2 COLOMNS -->
      <b-row
        v-show="displayedVersionId === null && !validatedMedically"
        class="kt-row__padding-sm mt-2"
      >
        <!-- LEFT COLUMN : report context -->
        <b-col class="kt-report-edit__fixed-col">
          <div class="kt-report-context">
            <!-- pathologist -->
            <h2 class="kt-report-edit__title">
              {{ $t("medicalReportEdit.pathologist") }}
            </h2>
            <InputSearch
              :valueProp="pathologist"
              :displayedValueProp="suggestionFormatPathologists(pathologist)"
              disabledProp
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </div>
        </b-col>

        <!-- MIDDLE COLUMN : results -->
        <b-col>
          <div class="kt-report-body">
            <!-- analysis -->
            <div class="kt-report-results__radio-group mb-3">
              <span class="kt-badge kt-badge--light-gray">
                {{ $t("histologyReport.analysis.title") + ' :' }}
                <strong>{{ analysis ? $t("histologyReport.analysis." + analysis) : $t("histologyReport.analysis.empty") }}</strong>
              </span>
            </div>

            <!-- body -->
            <b-form-group
              class="mb-0"
              :disabled="validatedMedically"
              :invalid-feedback="bodyInvalidFeedback"
              :state="bodyStateDisplay"
            >
              <quillEditor
                ref="quillEditor"
                v-model="form.body"
                :disabled="!isAssignedPathologist"
                :options="{
                  placeholder: $t('histologyReport.bodyPlaceholder'),
                  modules: {
                    toolbar: [
                      [{'header': [1, 2, 3, false]}],
                      ['bold', 'italic', 'underline'],
                      [{'color': []}, {'background': []}],
                      [{'align': [false, 'right', 'center']}],
                      [{'list': 'ordered'}, {'list': 'bullet'}],
                      [{'script': 'super'}],
                      ['blockquote']
                    ]
                  },
                  formats: ['background', 'bold', 'color', 'italic', 'size', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'formula'],
                  readOnly: true,
                  theme: 'snow'
                }"
              />
              <!--
                  debug: 'info',
                  modules: {
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'],
                      [{'list': 'ordered'}, {'list': 'bullet'}],
                      [{'header': [1, 2, 3, 4, 5, 6, false]}],
                      [{'color': []}, {'background': []}],
                      [{'align': []}],
                      [{'script': 'sub'}, {'script': 'super'}],
                      [{'font': []}],
                      [{'align': []}],
                      ['link', 'image'],
                      ['clean']
                    ]
                  },
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)" -->
            </b-form-group>
            <b-button
              @click.stop="openCodex('lcl')"
            >
              {{ "" + "Codex" }} <span style="font-size: 13px; opacity: 0.66; word-spacing: -0.06em;">{{ "" + "ctrl + F" }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- REPORT VIEW (WHEN VALIDATED) -->
      <div
        v-if="displayedVersionId === null && validatedMedically"
      >
        <HistologyReportView
          v-if="histologyReportData"
          :histologyReportDataProp="histologyReportData"
          :showFileDetailsProp="false"
          @newVersionCreated="refreshReportByFileId(fileId, true)"
          @refreshHistologyReport="refreshHistologyReports"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </div>

      <!-- PREVIOUS REPORTS VIEW -->
      <div v-show="previousHistologyReports.length > 0">
        <div
          v-for="histologyReport in previousHistologyReports"
          :key="histologyReport.id"
        >
          <HistologyReportView
            v-show="displayedVersionId === histologyReport.id"
            :histologyReportDataProp="histologyReport"
            :showFileDetailsProp="false"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </div>
      </div>

      <!-- report bottom action bar -->
      <div
        v-show="displayedVersionId === null"
        class="kt-report-actions"
      >
        <div class="kt-report-actions__wrapper">
          <!-- Save -->
          <b-button
            v-show="isAssignedPathologist && !validatedMedically"
            class="kt-report-actions__btn"
            variant="outline-secondary"
            @click="onSave"
          >
            {{ $t("medicalReportEdit.actions.save") }}
          </b-button>
          <!-- Validate -->
          <b-button
            v-show="isAssignedPathologist && !validatedMedically"
            class="kt-report-actions__btn"
            :variant="$systemSettings.theme"
            @click="onValidate(true)"
          >
            {{ $t("medicalReportEdit.actions.validateMedically") }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- Modal - validation confirmation -->
    <b-modal
      id="validationConfirmationModal"
      :title="$t('medicalReportEdit.validationConfirmation.modalTitle')"
      hide-footer
    >
      <div class="line-height-md">
        {{ $t('medicalReportEdit.validationConfirmation.modalText') }}
      </div>
      <div
        v-show="!!complementaryCount"
        class="line-height-md mt-2 text-14"
        :class="'text-' + $systemSettings.theme"
        v-html="validationConfirmationText"
      ></div>

      <div class="mt-2">
        <b-button
          class="mb-2 mt-2"
          variant="outline-secondary"
          @click="() => {
            openAddComplementary = true;
            $bvModal.hide('validationConfirmationModal');
          }"
        >
          {{ $t('medicalReportEdit.validationConfirmation.addComplementary') }}
        </b-button>
        <b-button
          class="mb-2 mt-2 ml-3"
          :variant="$systemSettings.theme"
          @click="onValidate(false)"
        >
          {{ $t('medicalReportEdit.validationConfirmation.validate') }}
        </b-button>
      </div>
    </b-modal>
    <!-- Modal - report alreadyCreated Modal  -->
    <b-modal
      :id="'alreadyCreatedModal-' + _uid"
      size="sm-larger"
      hide-footer
      hide-header
      @shown="() => {
        $refs.alreadyCreatedButton.focus()
      }"
    >
      <div class="text-center py-1">
        <h4
          style="font-size: 18px;
          letter-spacing: -0.03px;
          margin-bottom: 12px;"
        >
          <b-icon
            icon="exclamation-triangle"
            style="margin-right: 6px; color: #a8001b !important;"
          ></b-icon>
          {{ $t('medicalReportEdit.notifications.alreadyCreatedTitle') }}
        </h4>
        <b-button
          ref="alreadyCreatedButton"
          :variant="$systemSettings.theme"
          size="sm"
          @click="refreshAndCloseWarning"
        >
          <b-icon icon="eye"></b-icon>
          {{ $t('medicalReportEdit.notifications.alreadyCreatedBtn') }}
        </b-button>
      </div>
    </b-modal>

    <!-- Modal - codexPopup -->
    <!-- :return-focus="$refs.quillEditor.quill" -->
    <b-modal
      id="codexPopup"
      :title="$t('codexPopup.modalTitle')"
      hide-footer
      @shown="() => {
        $refs.codexPopupChoose.focusFirstElement()
      }"
      @hidden="() => {
        diagnosisShortcodeMode = 'choose';
        savedSelectionRange = null;
        // focus back to the editor
        if (savedSelectionRange) {
          $refs.quillEditor.quill.setSelection(savedSelectionRange, 'user');
        }
      }"
    >
      <!-- <div class="line-height-md">
        {{ $t('codexPopup.modalText') }}
      </div> -->

      <b-nav
        pills
        class="kt-sub-nav mt-n3 mb-4"
        :class="'kt-sub-nav--' + $systemSettings.theme"
      >
        <!-- choose -->
        <b-nav-item
          class="kt-sub-nav__link"
          :active="diagnosisShortcodeMode === 'choose'"
          @click="diagnosisShortcodeMode = 'choose'"
        >
          {{ $t("general.add") }}
        </b-nav-item>
        <!-- search -->
        <b-nav-item
          class="kt-sub-nav__link"
          :active="diagnosisShortcodeMode === 'search'"
          @click="diagnosisShortcodeMode = 'search'"
        >
          {{ $t("general.search") }}
        </b-nav-item>
      </b-nav>

      <!-- choose -->
      <div v-show="diagnosisShortcodeMode === 'choose'">
        <InputHistologyShortCode
          ref="codexPopupChoose"
          :initialSearchedFieldProp="searchedCode"

          labelProp=""
          :stateProp="null"
          invalidFeedbackProp=""

          @onUpdateParent="importSearchedCode"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </div>
      <!-- search -->
      <div v-show="diagnosisShortcodeMode === 'search'">
        <InputHistologyShortCode
          ref="codexPopupSearch"
          :initialSearchedFieldProp="searchedCode"

          labelProp=""
          :stateProp="null"
          invalidFeedbackProp=""
          isSearchProp

          @onUpdateParent="importSearchedCode"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </div>

      <!-- <div
        v-show="resultCode"
        class="mb-2"
      >
        back button
        <b-button
          size="sm"
          class="mr-3"
          variant="light"
          @click="deleteCode"
        >
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
        title
        <h2 class="kt-report-edit__title d-inline-block mb-0">
          {{ resultCode ? resultCode.code : "" }}
        </h2>
      </div> -->

      <!-- <div
        v-if="resultCode"
        class="mb-3"
        style="padding-left: 52px"
        v-html="resultCode.name"
      ></div> -->

      <!-- <div class="mt-2">
        <b-button
          class="mb-2 mt-2 mr-2"
          variant="outline-secondary"
          @click="() => {
            $bvModal.hide('validationConfirmationModal');
          }"
        >
          {{ $t('codexPopup.cancel') }}
        </b-button>
        <b-button
          ref="importCodeButton"
          class="mb-2 mt-2"
          :variant="$systemSettings.theme"
          :disabled="resultCode === null"
          @click="importSearchedCode"
        >
          {{ $t('codexPopup.import') }}
        </b-button>
      </div> -->
    </b-modal>
  </div>
</template>

<script>
// modules
import "quill/dist/quill.snow.css";
import "@/views/Diagnosis/HistologyReports/quill.scss";
import { quillEditor } from "vue-quill-editor";
// components
import ReportFileDetails from "@/views/Diagnosis/ReportFileDetails";
import HistologyReportView from "@/views/Diagnosis/HistologyReports/HistologyReportView";
import InputSearch from "@shared/views/Helpers/InputSearch";
import InputHistologyShortCode from "@/views/Helpers/InputHistologyShortCode";
// services
import commonServices from "@shared/services/API/commonServices";
import histologyReportsServices from "@/services/API/histologyReportsServices";
import filesServices from "@/services/API/filesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: {
    quillEditor,
    ReportFileDetails,
    HistologyReportView,
    InputSearch,
    InputHistologyShortCode
  },
  mixins: [userRights, error, date],
  props: {
    fileIdProp: {
      type: Number,
      default: null
    },
    cancelRouteProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general variables
      isLoading: true,
      histologyReportData: null,
      histologyReportId: null,
      // versioning variables
      previousHistologyReports: [],
      displayedVersionId: null,

      edited: false,
      isDownloading: false,
      openAddComplementary: false,

      // codexPopup
      diagnosisShortcodeMode: "choose", // "choose" | "search"
      searchedCode: "",
      savedSelectionRange: null,

      // file info
      fileData: null,
      fileId: null,
      parentFileId: null,
      complementaryFiles: [],
      groupOfFiles: [],

      validatedMedically: false,

      // analysis
      analysis: "",

      // base variables
      form: {
        body: ""
      },
      pathologist: null,

      // validation variables
      bodyValidation: false
    };
  },
  computed: {
    isAssignedPathologist: function() {
      if (!this.$userSettings || !this.$userSettings.user || !this.pathologist) return false;
      return this.$userSettings.user.id === this.pathologist.userId;
    },

    // form validation
    bodyState: function() {
      return this.form.body.length > 0 ? null : false;
    },
    bodyStateDisplay: function() {
      if (!this.bodyValidation) {
        return null;
      } else {
        return this.bodyState;
      }
    },
    bodyInvalidFeedback: function() {
      if (this.bodyStateDisplay === false) {
        return this.$t("validationRules.required");
      } else {
        return "";
      }
    },

    // formatted variables
    validationConfirmationText: function() {
      return this.$tc("medicalReportEdit.validationConfirmation.complementaryCount", this.complementaryCount, { count: this.complementaryCount });
    },
    complementaryCount: function() {
      return this.groupOfFiles.filter((file) => { return Boolean(file.parentFileId); }).length;
    }
  },
  watch: {
    "form.body": {
      handler: function(val) {
        this.edited = !this.histologyReportData || this.histologyReportData.content.body !== val;
      }
    }
  },
  async mounted() {
    await this.importHistologyReport();

    // quill shortcuts
    this.$refs.quillEditor.quill.keyboard.addBinding({
      key: "F",
      shortKey: true
    }, (_range, _context) => {
      this.openCodex();
    });
  },
  methods: {
    async refreshAndCloseWarning() {
      await this.refreshHistologyReports();
      this.$bvModal.hide("alreadyCreatedModal-" + this._uid);
    },
    async refreshHistologyReports() {
      this.clearHistologyReportData();
      await this.importHistologyReport();
    },

    clearHistologyReportData() {
      this.histologyReportData = null;
      this.histologyReportId = null;
      // versioning variables
      this.previousHistologyReports = [];
      this.displayedVersionId = null;

      this.edited = false;
      this.isDownloading = false;
      this.openAddComplementary = false;

      // codexPopup
      this.diagnosisShortcodeMode = "choose"; // "choose" | "search"
      this.searchedCode = "";
      this.savedSelectionRange = null;

      // file info
      this.fileData = null;
      this.fileId = null;
      this.parentFileId = null;
      this.complementaryFiles = [];
      this.groupOfFiles = [];

      this.validatedMedically = false;

      // analysis
      this.analysis = "";

      // base variables
      this.form = {
        body: ""
      };
      this.pathologist = null;

      // validation variables
      this.bodyValidation = false;
    },
    async importHistologyReport() {
      try {
        // import report
        const resReportImport = await this.refreshReportByFileId(this.fileIdProp, false);

        // if no report, create new from file
        if (!resReportImport.imported) {
          await this.createNewReportFromFile(resReportImport.file);
        }

        this.isLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    openCodex(code = "") {
      this.savedSelectionRange = this.$refs.quillEditor.quill.getSelection();
      this.searchedCode = code;
      this.$bvModal.show("codexPopup");
    },
    importSearchedCode(histologyShortCode) {
      this.$bvModal.hide("codexPopup");
      if (!histologyShortCode || !histologyShortCode.html) return false;

      const quill = this.$refs.quillEditor.quill;
      const currentLength = quill.getLength();
      const currentSelectionRange = quill.getSelection();
      const selectionRange = this.savedSelectionRange || currentSelectionRange;
      const targetIndex = selectionRange ? selectionRange.index : currentLength;
      if (currentLength > 1) {
        // content is not empty
        if (targetIndex === (currentLength - 1)) {
          // at the end
          quill.clipboard.dangerouslyPasteHTML(targetIndex, ("<p><br /></p>" + histologyShortCode.html));
        } else if (targetIndex === 0) {
          // at the start
          quill.clipboard.dangerouslyPasteHTML(targetIndex, (histologyShortCode.html + "<p><br /></p>"));
        } else {
          // in the middle
          quill.clipboard.dangerouslyPasteHTML(targetIndex, ("<p><br /></p>" + histologyShortCode.html + "<p><br /></p>"));
        }
      } else {
        // content is empty
        quill.clipboard.dangerouslyPasteHTML(0, histologyShortCode.html);
      }
      // const delta = quill.clipboard.convert(html);
      // console.log("delta", delta);

      // if (selectionRange) {
      // const indexEnd = selectionRange.index + selectionRange.length;
      // quill.insertText(indexEnd, delta, null, null, "user");

      // quill.updateContents(new Delta()
      //   .retain(selectionRange.index)
      //   .delete(selectionRange.length)
      //   .insert(delta)
      // );
      // }
      // quill.setContents(delta, "silent");
    },

    async createNewReportFromFile(file) {
      this.isLoading = true;

      if (file && file.id) {
        await this.updateFileInfo(file);
        this.validatedMedically = false;
        this.pathologist = file.pathologist;
        this.edited = true;

        this.isLoading = false;
      } else {
        // no file found
        this.$emit("alert", "danger", {
          title: this.$t("medicalReportEdit.notifications.unknownAccessNumberTitle"),
          message: this.$t("medicalReportEdit.notifications.unknownAccessNumberText")
        });
      }
    },
    async refreshReportByFileId(fileId, displayError = true) {
      this.isLoading = true;

      const resReports = await histologyReportsServices.getAllByFileId(fileId); // available : histologyReports / file

      if (resReports.data.histologyReports && resReports.data.histologyReports.length > 0) {
        // force refresh
        if (this.histologyReportData !== null) {
          this.histologyReportData = null;
          await this.$nextTick();
        }
        // file
        await this.updateFileInfo(resReports.data.file);
        // save latest report
        const lastHistologyReport = resReports.data.histologyReports[resReports.data.histologyReports.length - 1];
        lastHistologyReport.file = this.fileData;
        this.histologyReportData = lastHistologyReport;
        this.histologyReportId = this.histologyReportData.id;
        this.validatedMedically = this.fileData.medicalStatus.name === "validatedMedically";
        // report
        if (this.histologyReportData.content) {
          this.updateReportInfo(this.histologyReportData.content);
        }
        // save previous reports
        this.previousHistologyReports = [];
        for (let i = 0; i < resReports.data.histologyReports.length - 1; i++) {
          this.previousHistologyReports.push(resReports.data.histologyReports[i]);
        }
        this.displayedVersionId = null;
        // reset edited
        this.$nextTick(() => { this.edited = false; });
      } else {
        this.histologyReportData = null;
        this.histologyReportId = null;
        if (displayError) {
          // no report found
          this.$emit("alert", "danger", {
            title: this.$t("histologyReport.reportNotFound.title"),
            message: this.$t("histologyReport.reportNotFound.text")
          });
        }
      }

      this.isLoading = false;

      return {
        imported: Boolean(resReports.data && resReports.data.histologyReports && resReports.data.histologyReports.length > 0), // boolean
        file: (resReports.data && resReports.data.file) ? resReports.data.file : null // object|null
      };
    },
    async updateFileInfo(file) {
      try {
        this.fileId = file.id;
        this.parentFileId = file.parentFileId;
        this.pathologist = file.pathologist;

        // groupOfFiles
        file.complementaryFiles = file.childrenFiles || [];
        this.complementaryFiles = file.childrenFiles;
        this.complementaryFiles = this.complementaryFiles.map((item) => {
          item.parentFileId = file.id;
          return item;
        });
        let groupOfFiles = [];
        if (this.parentFileId) {
          // current file is a complementary
          const resLinkedFiles = await filesServices.getLinkedFiles(this.parentFileId);
          if (resLinkedFiles.data) groupOfFiles = resLinkedFiles.data;
          file.parentFile.accessNumber = file.accessNumber;
          groupOfFiles.unshift(file.parentFile);
        } else {
          // current file is the parent
          if (this.complementaryFiles && this.complementaryFiles.length) {
            groupOfFiles = this.complementaryFiles;
          }
          groupOfFiles.unshift({
            id: this.fileId,
            parentFileId: null,
            accessNumber: file.accessNumber,
            creationDate: file.creationDate,
            filingStatus: file.filingStatus || null,
            medicalStatus: file.medicalStatus,
            accountingStatus: file.accountingStatus || null
          });
        }
        this.groupOfFiles = groupOfFiles;
        file.groupOfFiles = groupOfFiles;

        this.fileData = file;

        this.analysis = file.containerGroups.reduce((result, item) => {
          const containerGroupTasksContent = item.containerGroupTasks.reduce((resultGroupTask, item1) => {
            if (item1.task.content && typeof item1.task.content.analysis !== "undefined") {
              resultGroupTask += (resultGroupTask !== "" ? ", " : "") + item1.task.content.analysis;
            }
            return resultGroupTask;
          }, "");

          if (containerGroupTasksContent !== "") {
            result += (result !== "" ? " ; " : "") + containerGroupTasksContent;
          }
          return result;
        }, "");
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateReportInfo(content) {
      this.form.body = content.body;
    },

    // format pathologist name
    suggestionFormatPathologists(suggestion) {
      if (!suggestion) return "";
      let output = suggestion.user.firstName + " " + suggestion.user.lastName + " (" + suggestion.code + ")";
      if (suggestion.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },

    // validate form
    validateForm() {
      this.bodyValidation = true;
      return !(
        this.bodyState === false
      );
    },

    // Actions
    async onSave(handleSuccess = true, handleErrors = true) {
      try {
        // no change : do nothing
        if (!this.edited) {
          if (handleSuccess) {
            this.$emit("alert", "success", {
              title: this.$t("medicalReportEdit.notifications.editionSuccessTitle"),
              message: this.$t("medicalReportEdit.notifications.editionSuccessText")
            });
            const refresh = await this.refreshReportByFileId(this.fileId);
            return refresh.imported;
          }
          return true;
        }

        this.isLoading = true;

        // validate before saving
        if (!this.validateForm()) {
          this.isLoading = false;
          return false;
        }

        const form = this.form;
        form.pathologistId = this.pathologist.id;
        form.fileId = this.fileId;
        form.sendReportToPatient = false;

        if (this.histologyReportData) {
          // edition save
          const res = await commonServices.put("histologyReports", form, this.histologyReportData.id);
          if (res.data === true) {
            if (handleSuccess) {
              this.$emit("alert", "success", {
                title: this.$t("medicalReportEdit.notifications.editionSuccessTitle"),
                message: this.$t("medicalReportEdit.notifications.editionSuccessText")
              });
              const refresh = await this.refreshReportByFileId(this.fileId);
              return refresh.imported;
            } else if (res.data.error && res.data.error === "alreadyValidatedMedicallyOrCancelled") {
              this.$emit("alert", "danger", {
                title: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledTitle"),
                message: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledText")
              });
              this.isLoading = false;
              await this.refreshHistologyReports();
              return false;
            } else {
              this.isLoading = false;
              return true;
            }
          } else {
            if (handleErrors) {
              this.$emit("alert", "danger", {
                title: this.$t("medicalReportEdit.notifications.editionErrorTitle"),
                message: this.$t("medicalReportEdit.notifications.editionErrorText")
              });
            }
            this.isLoading = false;
            return false;
          }
        } else {
          // addition save
          const res = await commonServices.post("histologyReports", form);
          if (res.data.id) {
            if (handleSuccess) {
              this.$emit("alert", "success", {
                title: this.$t("medicalReportEdit.notifications.additionSuccessTitle"),
                message: this.$t("medicalReportEdit.notifications.additionSuccessText")
              });
              const refresh = await this.refreshReportByFileId(this.fileId);
              this.isLoading = false;
              return refresh.imported;
            } else if (res.data.error && res.data.error === "alreadyCreated") {
              this.$bvModal.show("alreadyCreatedModal-" + this._uid);
              this.isLoading = false;
              return false;
            } else {
              this.histologyReportId = res.data.id;
              this.isLoading = false;
              return true;
            }
          } else {
            if (handleErrors) {
              this.$emit("alert", "danger", {
                title: this.$t("medicalReportEdit.notifications.additionErrorTitle"),
                message: this.$t("medicalReportEdit.notifications.additionErrorText")
              });
            }
            this.isLoading = false;
            return false;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onValidate(validationConfirmation = true) {
      // validate rights
      if (!this.isAssignedPathologist) {
        this.$emit("alert", "danger", {
          title: this.$t("medicalReportEdit.validations.validationAssignedPathologistErrorTitle"),
          message: this.$t("medicalReportEdit.validations.validationAssignedPathologistErrorText")
        });
        return false;
      }

      // already validated = success
      if (this.validatedMedically) {
        this.$emit("alert", "success", {
          title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
          message: this.$t("medicalReportEdit.notifications.validationSuccessText")
        });
        return true;
      }

      this.isLoading = true;

      // validate fields
      if (!this.validateForm()) {
        this.isLoading = false;
        return false;
      }

      let successSave = false;
      let successMedValidation = false;

      // save
      if (this.edited) {
        // save changes
        successSave = await this.onSave(false, true);
        if (!successSave) {
          this.isLoading = false;
          return false;
        }
      }

      // validationConfirmation
      if (validationConfirmation && !this.parentFileId) {
        this.$bvModal.show("validationConfirmationModal");
        if (successSave) {
          const refresh = await this.refreshReportByFileId(this.fileId);
          this.isLoading = false;
          return refresh.imported;
        } else {
          this.isLoading = false;
          return true;
        }
      } else {
        this.$bvModal.hide("validationConfirmationModal");
      }

      // send request
      successMedValidation = await this.onValidateMedically(true);
      if (successSave || successMedValidation) {
        await this.refreshReportByFileId(this.fileId);
      }
      this.isLoading = false;
      return successMedValidation;
    },
    async onValidateMedically(displaySuccess = true) {
      try {
        const res = await histologyReportsServices.validateMedically(this.fileId);
        if (res.data === true) {
          if (displaySuccess) {
            this.$emit("alert", "success", {
              title: this.$t("medicalReportEdit.notifications.validationSuccessTitle"),
              message: this.$t("medicalReportEdit.notifications.validationSuccessText")
            });
          }
          this.isLoading = false;
          return true;
        } else if (res.data.error === "reportNotCreated") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.reportNotCreated"),
            message: this.$t("medicalReportEdit.notifications.reportNotCreated")
          });
        } else if (res.data.error === "fileNotValidatedForDiagnosis") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.fileNotValidatedForDiagnosisTitle"),
            message: this.$t("medicalReportEdit.notifications.fileNotValidatedForDiagnosisText")
          });
        } else if (res.data.error === "fileNotSent") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.fileNotSentTitle"),
            message: this.$t("medicalReportEdit.notifications.fileNotSentText")
          });
        } else if (res.data.error && res.data.error === "alreadyValidatedMedicallyOrCancelled") {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledTitle"),
            message: this.$t("medicalReportEdit.notifications.alreadyValidatedMedicallyOrCancelledText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("medicalReportEdit.notifications.validationErrorTitle"),
            message: this.$t("medicalReportEdit.notifications.validationErrorText")
          });
        }
        this.isLoading = false;
        await this.refreshHistologyReports();
        return false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onView() {
      if (!this.isDownloading) {
        this.isDownloading = true;
        try {
          const resFile = await commonServices.export("histologyReports", this.histologyReportData.id, "pdf");
          if (resFile.data.type === "application/json") {
            this.$emit("alert", "danger", {
              title: this.$t("general.downloadFileErrorTitle"),
              message: this.$t("general.downloadFileErrorText")
            });
          } else {
            fileSaver.saveAs(resFile.data, this.fileData.accessNumber + ".pdf");
          }
        } catch (err) {
          this.handleErrors(err);
        }
        this.isDownloading = false;
      }
    },

    // cancel and go back
    navigateBack() {
      if (this.cancelRouteProp) {
        navigate(this.cancelRouteProp);
      } else if (this.isEnabled(["roleSpecific", "userAdministration"]) || this.isEnabled(["roleSpecific", "system"])) {
        // default for admin
        navigate("diagnosis/validation/histologyValidation");
      } else if (this.isEnabled(["roleSpecific", "pathologist"])) {
        navigate("diagnosis/validation/histologyValidation");
      } else if (this.isEnabled(["roleSpecific", "cytotechnician"])) {
        navigate("diagnosis/medicalReports/histologyReports");
      }
    }
  }
};
</script>

<style scoped src="./HistologyReportEdit.scss" lang="scss"></style>
<style src="./HistologyReportEdit-global.scss" lang="scss"></style>

<template>
  <div class="kt-payments">
    <!-- AccountingSectionSelect -->
    <AccountingSectionSelect
      currentLocationProp="payments"
    />

    <!-- router -->
    <div class="mt-2 mt-lg-3">
      <router-view
        :key="$route.fullPath"
        @alert="(variant, strings) => {
          $emit('alert', variant, strings)
        }"
      />
    </div>
  </div>
</template>

<script>
// components
import AccountingSectionSelect from "@/views/Accounting/AccountingSectionSelect";

export default {
  components: { AccountingSectionSelect },
  mixins: [],
  methods: {}
};
</script>

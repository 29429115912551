// helpers
import filesServices from "@/services/API/filesServices";
import price from "@shared/services/UI/price";

export default {
  mixins: [price],
  methods: {
    importFormatPayment(payment) {
      payment.paid = this.importFormatPrice(payment.paid);
      payment.previouslyPaid = 0;
      // filePayments -> invoicedFiles
      payment.invoicedFiles = payment.filePayments;
      delete payment.filePayments;
      for (const invoicedFile of payment.invoicedFiles) {
        delete invoicedFile.paymentGroupId;
        delete invoicedFile.patientPaymentId;
        delete invoicedFile.healthCarePaymentId;
        // reassign fileInvoices
        invoicedFile.fileInvoices = invoicedFile.file.fileInvoices;
        for (const fileInvoice of invoicedFile.fileInvoices) {
          // format fileInvoices
          fileInvoice.paid = this.importFormatPrice(invoicedFile.paid);
          fileInvoice.invoiced = this.importFormatPrice(fileInvoice.invoiced);
          fileInvoice.previouslyPaid = 0;
        }
        delete invoicedFile.file.fileInvoices;
        delete invoicedFile.paid;
        // file
        invoicedFile.patient = invoicedFile.file.patient;
        invoicedFile.accessNumber = invoicedFile.file.accessNumber;
        invoicedFile.accountingStatusId = invoicedFile.file.accountingStatusId;
        invoicedFile.creationDate = invoicedFile.file.creationDate;
        invoicedFile.parentFileId = invoicedFile.file.parentFileId;
        invoicedFile.id = invoicedFile.fileId;
        delete invoicedFile.file;
        delete invoicedFile.fileId;

        // invoiceActs
        for (const fileInvoice of invoicedFile.fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            // format prices
            invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
            for (const paymentAct of invoiceAct.paymentActs) {
              paymentAct.paid = this.importFormatPrice(paymentAct.paid);
            }
            // search if existing current paymentAct
            let currentPaymentActPosition = null;
            for (let l = 0; l < invoicedFile.paymentActs.length; l++) {
              if (invoiceAct.id === invoicedFile.paymentActs[l].invoiceActId) {
                currentPaymentActPosition = l;
                break;
              }
            }
            invoiceAct.previouslyPaid = 0;
            // for each paymentActs (old and current)
            for (let l = invoiceAct.paymentActs.length - 1; l >= 0; l--) {
              if (currentPaymentActPosition !== null && invoiceAct.paymentActs[l].id === invoicedFile.paymentActs[currentPaymentActPosition].id) {
                // if paymentAct is current : save separatly
                invoiceAct.paymentAct = invoiceAct.paymentActs[l];
                invoiceAct.paymentActs.splice(l, 1);
              } else {
                // else : add to previouslyPaid
                invoiceAct.previouslyPaid += invoiceAct.paymentActs[l].paid;
                invoicedFile.previouslyPaid += invoiceAct.paymentActs[l].paid;
                payment.previouslyPaid += invoiceAct.paymentActs[l].paid;
              }
            }
            // if no current payment created for the invoiceAct, add a default paymentAct
            if (!invoiceAct.paymentAct) {
              invoiceAct.paymentAct = {
                filePaymentId: payment.id,
                id: null,
                invoiceActId: invoiceAct.id,
                paid: 0
              };
            }
          }
        }
        delete invoicedFile.paymentActs;
        // setup collectionDates
        //invoicedFile.file.collectionDates = invoicedFile.file.containerGroups.map(({ collectionDate }) => {
        //  return collectionDate ? this.$d(new Date(collectionDate), "date") : "";
        //});
        //delete invoicedFile.file.containerGroups;
      }

      return payment;
    },

    async mixinSearchInvoicedFiles(laboratoryId, accessNumber, currentFilePaymentIds = []) {
      const res = await filesServices.getInvoicedFile({
        laboratoryId: laboratoryId,
        accessNumber: accessNumber
      });
      let formattedList = [];
      const relatedFiles = [];
      if (res.data && res.data.length) {
        formattedList = this.sortInvoicedFiles(this.moveFirstSearchedInvoicedFiles(res.data, accessNumber));

        // format each invoicedFile > fileInvoices (filePayment)
        for (const formatted of formattedList) {
          for (const fileInvoice of formatted.fileInvoices) {
            fileInvoice.invoiced = this.importFormatPrice(fileInvoice.invoiced);
            fileInvoice.paid = 0;
            fileInvoice.previouslyPaid = 0;
            // invoiceActs
            for (const invoiceAct of fileInvoice.invoiceActs) {
              // invoiced
              invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
              // previouslyPaid
              invoiceAct.previouslyPaid = 0;
              // create current paymentAct
              invoiceAct.paymentAct = {
                invoiceActId: invoiceAct.id,
                paid: 0
              };
              // paid / previouslyPaid
              for (const paymentAct of invoiceAct.paymentActs) {
                paymentAct.paid = this.importFormatPrice(paymentAct.paid);
                if (currentFilePaymentIds.includes(paymentAct.filePaymentId)) {
                  invoiceAct.paymentAct.paid += paymentAct.paid;
                  fileInvoice.paid += paymentAct.paid;
                } else {
                  invoiceAct.previouslyPaid += paymentAct.paid;
                  fileInvoice.previouslyPaid += paymentAct.paid;
                }
              }
            }
          }
          // setup collectionDates
          formatted.collectionDates = formatted.containerGroups.map(({ collectionDate }) => {
            return collectionDate ? this.$d(new Date(collectionDate), "date") : "";
          });
          delete formatted.containerGroups;
        }
        // move related files
        for (let i = formattedList.length - 1; i >= 0; i--) {
          if (formattedList[i].accessNumber !== accessNumber) {
            relatedFiles.push(formattedList[i]);
            formattedList.splice(i, 1);
          }
        }
      }
      return {
        invoicedFiles: formattedList,
        relatedFiles: relatedFiles
      };
    },

    moveFirstSearchedInvoicedFiles(invoicedFiles, accessNumber) {
      for (let i = 0; i < invoicedFiles.length; i++) {
        // if complementary file and misplaced
        if (!invoicedFiles[i].parentFileId && invoicedFiles[i].accessNumber === accessNumber) {
          this.array_move(invoicedFiles, i, 0);
          break;
        }
      }
      return invoicedFiles;
    },
    sortInvoicedFiles(invoicedFiles) {
      for (let i = 0; i < invoicedFiles.length; i++) {
        // if complementary file and misplaced
        if (invoicedFiles[i].parentFileId && (i === 0 || invoicedFiles[i].accessNumber !== invoicedFiles[i - 1].accessNumber)) {
          // search for parent
          for (let j = 0; j < invoicedFiles.length; j++) {
            if (invoicedFiles[j].accessNumber === invoicedFiles[i].accessNumber && !invoicedFiles[j].parentFileId) {
              this.array_move(invoicedFiles, i, j + 1);
              invoicedFiles = this.sortInvoicedFiles(invoicedFiles);
              break;
            }
          }
          break;
        }
      }
      return invoicedFiles;
    },
    array_move(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    }
  }
};

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  existsWithUser(pathologistId, userId) {
    return axiosServices.axios.get("/pathologists/existsWithUser", {
      params: {
        pathologistId: pathologistId,
        userId: userId
      }
    });
  }
};

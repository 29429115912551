<template>
  <div class="kt-instrument-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- instrument form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- laboratory -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            :labelProp="$t('instruments.laboratory')"
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="true"
            :initialValueProp="instrumentData !== null ? instrumentData.laboratoryId : null"
            @input="laboratoryId = $event"
          />
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- title -->
        <b-col cols="12">
          <h5>{{ $t("instrumentEdit.sectionTitleDescription") }}</h5>
        </b-col>

        <!-- networkName -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('instruments.networkName')"
            :invalid-feedback="networkNameInvalidFeedback"
            :state="networkNameState"
          >
            <b-form-input
              v-model="networkName"
              trim
              maxlength="50"
              :state="networkNameState"
              @blur="networkNameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- model -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('instruments.model')"
            :invalid-feedback="modelInvalidFeedback"
            :state="modelState"
          >
            <b-form-input
              v-model="model"
              trim
              maxlength="50"
              :state="modelState"
              @blur="modelValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- brand -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('instruments.brand')"
            :invalid-feedback="brandInvalidFeedback"
            :state="brandState"
          >
            <b-form-input
              v-model="brand"
              trim
              maxlength="50"
              :state="brandState"
              @blur="brandValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- serialNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('instruments.serialNumber')"
            :invalid-feedback="serialNumberInvalidFeedback"
            :state="serialNumberState"
          >
            <b-form-input
              v-model="serialNumber"
              trim
              maxlength="50"
              :state="serialNumberState"
              @blur="serialNumberValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- technique -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('instruments.technique')"
            :invalid-feedback="techniqueInvalidFeedback"
            :state="techniqueState"
          >
            <b-form-input
              v-model="technique"
              trim
              maxlength="256"
              :state="techniqueState"
              @blur="techniqueValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- title -->
        <b-col cols="12">
          <h5>{{ $t("instrumentEdit.sectionTitleMaintenance") }}</h5>
        </b-col>

        <!-- lastInspectionDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('instruments.lastInspectionDate')"
            :invalid-feedback="lastInspectionDateInvalidFeedback"
            :state="lastInspectionDateState"
          >
            <b-form-input
              v-model="lastInspectionDate"
              type="date"
              :state="lastInspectionDateState"
              @blur="lastInspectionDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- lastInspectionExpiryDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('instruments.lastInspectionExpiryDate')"
            :invalid-feedback="lastInspectionExpiryDateInvalidFeedback"
            :state="lastInspectionExpiryDateState"
          >
            <b-form-input
              v-model="lastInspectionExpiryDate"
              type="date"
              :state="lastInspectionExpiryDateState"
              @blur="lastInspectionExpiryDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: {
    SmartSelectInput
  },
  mixins: [userRights, error],
  props: {
    instrumentIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      instrumentId: this.instrumentIdProp,
      instrumentData: null,
      // form variables
      laboratoryId: null,
      networkName: "",
      model: "",
      brand: "",
      serialNumber: "",
      technique: "",
      lastInspectionDate: "",
      lastInspectionExpiryDate: "",
      // is validation active
      laboratoryIdValidation: false,
      networkNameValidation: false,
      modelValidation: false,
      brandValidation: false,
      serialNumberValidation: false,
      techniqueValidation: false,
      lastInspectionDateValidation: false,
      lastInspectionExpiryDateValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    networkNameState: function() {
      if (!this.networkNameValidation) return null;
      return this.networkName && this.networkName.length > 0 ? null : false;
    },
    networkNameInvalidFeedback: function() {
      return this.networkNameState === false ? this.$t("validationRules.required") : "";
    },
    modelState: function() {
      if (!this.modelValidation) return null;
      return this.model && this.model.length > 0 ? null : false;
    },
    modelInvalidFeedback: function() {
      return this.modelState === false ? this.$t("validationRules.required") : "";
    },
    brandState: function() {
      if (!this.brandValidation) return null;
      return this.brand && this.brand.length > 0 ? null : false;
    },
    brandInvalidFeedback: function() {
      return this.brandState === false ? this.$t("validationRules.required") : "";
    },
    serialNumberState: function() {
      if (!this.serialNumberValidation) return null;
      return this.serialNumber && this.serialNumber.length > 0 ? null : false;
    },
    serialNumberInvalidFeedback: function() {
      return this.serialNumberState === false ? this.$t("validationRules.required") : "";
    },
    techniqueState: function() {
      if (!this.techniqueValidation) return null;
      return this.technique && this.technique.length > 0 ? null : false;
    },
    techniqueInvalidFeedback: function() {
      return this.techniqueState === false ? this.$t("validationRules.required") : "";
    },
    lastInspectionDateState: function() {
      if (!this.lastInspectionDateValidation) return null;
      return this.lastInspectionDate && this.lastInspectionDate.length > 0 ? null : false;
    },
    lastInspectionDateInvalidFeedback: function() {
      return this.lastInspectionDateState === false ? this.$t("validationRules.required") : "";
    },
    lastInspectionExpiryDateState: function() {
      if (!this.lastInspectionExpiryDateValidation) return null;
      return this.lastInspectionExpiryDate && this.lastInspectionExpiryDate.length > 0 ? null : false;
    },
    lastInspectionExpiryDateInvalidFeedback: function() {
      return this.lastInspectionExpiryDateState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("instrumentEdit.pageTitle") : this.$t("instrumentNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("instrumentEdit.submitText") : this.$t("instrumentNew.submitText");
    }
  },
  async mounted() {
    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.laboratoryInput.focusFirstElement();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get instrument
          const resServices = await commonServices.get("instruments", this.instrumentId);
          this.instrumentData = resServices.data;
          // import data
          this.laboratoryId = resServices.data.laboratoryId;
          this.networkName = resServices.data.networkName;
          this.model = resServices.data.model;
          this.brand = resServices.data.brand;
          this.serialNumber = resServices.data.serialNumber;
          this.technique = resServices.data.technique;
          this.lastInspectionDate = resServices.data.lastInspectionDate;
          this.lastInspectionExpiryDate = resServices.data.lastInspectionExpiryDate;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("data/instruments");
    },

    // submit functions
    onValidateInstrument() {
      this.laboratoryIdValidation = true;
      this.networkNameValidation = true;
      this.modelValidation = true;
      this.brandValidation = true;
      this.serialNumberValidation = true;
      this.techniqueValidation = true;
      this.lastInspectionDateValidation = true;
      this.lastInspectionExpiryDateValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.networkNameState === false ||
        this.modelState === false ||
        this.brandState === false ||
        this.serialNumberState === false ||
        this.techniqueState === false ||
        this.lastInspectionDateState === false ||
        this.lastInspectionExpiryDateState === false
      );
    },
    async onSaveInstrument() {
      try {
        const form = {
          laboratoryId: this.laboratoryId,
          networkName: this.networkName,
          model: this.model,
          brand: this.brand,
          serialNumber: this.serialNumber,
          technique: this.technique,
          lastInspectionDate: this.lastInspectionDate || null,
          lastInspectionExpiryDate: this.lastInspectionExpiryDate || null
        };
        if (this.editMode) {
          const res = await commonServices.put("instruments", form, this.instrumentId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("instrumentEdit.notifications.editedTitle"),
              message: this.$t("instrumentEdit.notifications.editedText")
            });
            navigate("data/instruments");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("instrumentEdit.notifications.editionErrorTitle"),
              message: this.$t("instrumentEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("instruments", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("instrumentNew.notifications.addedTitle"),
              message: this.$t("instrumentNew.notifications.addedText")
            });
            navigate("data/instruments");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("instrumentNew.notifications.additionErrorTitle"),
              message: this.$t("instrumentNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onSubmit() {
      if (this.onValidateInstrument() !== false) this.onSaveInstrument();
    }
  }
};
</script>

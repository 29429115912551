<template>
  <div class="kt-laboratory-edit pb-4">
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- laboratory form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              ref="laboratoryNameInput"
              v-model="name"
              trim
              maxlength="256"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- registrationNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.registrationNumber')"
            :invalid-feedback="registrationNumberInvalidFeedback"
            :state="registrationNumberState"
          >
            <b-form-input
              v-model="registrationNumber"
              trim
              maxlength="50"
              :state="registrationNumberState"
              @blur="registrationNumberValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- section Address -->
        <b-col cols="12">
          <h5>{{ $t("laboratory.sectionTitleAddress") }}</h5>
        </b-col>

        <!-- address1 -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('laboratories.address1')"
            :invalid-feedback="address1InvalidFeedback"
            :state="address1State"
          >
            <b-form-input
              v-model="address1"
              trim
              maxlength="50"
              :state="address1State"
              @blur="address1Validation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- address2 -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.address2')"
            :invalid-feedback="address2InvalidFeedback"
            :state="address2State"
          >
            <b-form-input
              v-model="address2"
              trim
              maxlength="50"
              :state="address2State"
              @blur="address2Validation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- zip -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.zip')"
            :invalid-feedback="zipInvalidFeedback"
            :state="zipState"
          >
            <b-form-input
              v-model="zip"
              trim
              maxlength="50"
              :state="zipState"
              @blur="zipValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- city -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.city')"
            :invalid-feedback="cityInvalidFeedback"
            :state="cityState"
          >
            <b-form-input
              v-model="city"
              trim
              maxlength="50"
              :state="cityState"
              @blur="cityValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- country -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.country')"
            :invalid-feedback="countryInvalidFeedback"
            :state="countryState"
          >
            <b-form-input
              v-model="country"
              trim
              maxlength="50"
              :state="countryState"
              @blur="countryValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- section title -->
        <b-col cols="12">
          <h5>{{ $t("laboratory.sectionTitleContactInformation") }}</h5>
        </b-col>

        <!-- phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.phone')"
            :invalid-feedback="phoneInvalidFeedback"
            :state="phoneState"
          >
            <b-form-input
              v-model="phone"
              class="kt-input--prefixed"
              trim
              maxlength="50"
              :state="phoneState"
              @blur="phoneValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t('general.phoneCountryCodeDefault') }}
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.email')"
            :invalid-feedback="emailInvalidFeedback"
            :state="emailState"
          >
            <b-form-input
              v-model="email"
              trim
              maxlength="256"
              :state="emailState"
              @blur="emailValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- accounting department email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.accountingDepartmentEmail')"
            :invalid-feedback="accountingDepartmentEmailInvalidFeedback"
            :state="accountingDepartmentEmailState"
          >
            <b-form-input
              v-model="accountingDepartmentEmail"
              trim
              maxlength="256"
              :state="accountingDepartmentEmailState"
              @blur="accountingDepartmentEmailValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-show="isSystemUser">
        <!-- SMTP Server -->
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- section title -->
        <b-col cols="12">
          <h5>{{ $t("laboratory.smtpServer") }}</h5>
        </b-col>
        <!-- input SMTP Server -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.smtpServerAddress')"
            :invalid-feedback="smtpServerAddressInvalidFeedback"
            :state="smtpServerAddressState"
          >
            <b-form-input
              v-model="smtpServerAddress"
              maxlength="256"
              :state="smtpServerAddressState"
              @blur="smtpServerAddressValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- input SMTP Port -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.smtpServerPort')"
            :invalid-feedback="smtpServerPortInvalidFeedback"
            :state="smtpServerPortState"
          >
            <b-form-input
              v-model="smtpServerPort"
              type="number"
              min="1"
              max="65535"
              :state="smtpServerPortState"
              @blur="smtpServerPortValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- input SMTP User -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.smtpServerUser')"
            :invalid-feedback="smtpServerUserInvalidFeedback"
            :state="smtpServerUserState"
          >
            <b-form-input
              v-model="smtpServerUser"
              maxlength="256"
              :state="smtpServerUserState"
              @blur="smtpServerUserValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- input SMTP Password -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.smtpServerPassword')"
            :invalid-feedback="smtpServerPasswordInvalidFeedback"
            :state="smtpServerPasswordState"
          >
            <b-form-input
              v-model="smtpServerPassword"
              maxlength="256"
              :state="smtpServerPasswordState"
              @blur="smtpServerPasswordValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- input SMTP hourly quota -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.smtpHourlyQuota')"
            :invalid-feedback="smtpServerHourlyQuotaInvalidFeedback"
            :state="smtpServerHourlyQuotaState"
          >
            <b-form-input
              v-model="smtpServerHourlyQuota"
              type="number"
              min="0"
              :state="smtpServerHourlyQuotaState"
              @blur="smtpServerHourlyQuotaValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- section title -->
        <b-col cols="12">
          <h5>{{ $t("laboratory.sectionTitlePayments") }}</h5>
        </b-col>
        <!-- bankDetails -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.bankDetails')"
            :invalid-feedback="bankDetailsInvalidFeedback"
            :state="bankDetailsState"
          >
            <b-form-input
              v-model="bankDetails"
              trim
              maxlength="256"
              :state="bankDetailsState"
              @blur="bankDetailsValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>
        <!-- section title -->
        <b-col cols="12">
          <h5>{{ $t("laboratory.sectionTitleReminders") }}</h5>
        </b-col>
        <!-- Reminders - list -->
        <b-col cols="12">
          <Reminders
            hideLabelProp
            :remindersProp="reminders"
            :validationActiveProp="remindersValidation"
            @remindersUpdate="reminders = $event"
            @updateIsValid="remindersState = $event"
            @submit="onSubmit"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>
        <!-- debtCollectionDelay in days -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.debtCollectionDelay')"
            :invalid-feedback="debtCollectionDelayInvalidFeedback"
            :state="debtCollectionDelayState"
          >
            <b-form-input
              v-model="debtCollectionDelay"
              trim
              maxlength="10"
              :state="debtCollectionDelayState"
              @blur="debtCollectionDelayValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- debtCollectionDepartment -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('laboratories.debtCollectionDepartment')"
            :invalid-feedback="debtCollectionDepartmentInvalidFeedback"
            :state="debtCollectionDepartmentState"
          >
            <b-form-input
              v-model="debtCollectionDepartment"
              trim
              maxlength="50"
              :state="debtCollectionDepartmentState"
              @blur="debtCollectionDepartmentValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col
          v-show="isSystemUser"
          cols="12"
        >
          <b-row>
            <!-- divider -->
            <b-col cols="12">
              <div class="w-100 border-top mt-1 mb-2"></div>
            </b-col>
            <!-- section Links -->
            <b-col cols="12">
              <h5>{{ $t("laboratory.sectionTitleLinks") }}</h5>
            </b-col>
            <!-- website -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.website')"
                :invalid-feedback="websiteInvalidFeedback"
                :state="websiteState"
              >
                <b-form-input
                  v-model="website"
                  trim
                  maxlength="256"
                  :state="websiteState"
                  @blur="websiteValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- gprdWebsite -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.gprdWebsite')"
                :invalid-feedback="gprdWebsiteInvalidFeedback"
                :state="gprdWebsiteState"
              >
                <b-form-input
                  v-model="gprdWebsite"
                  trim
                  maxlength="256"
                  :state="gprdWebsiteState"
                  @blur="gprdWebsiteValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- paymentLink -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.paymentLink')"
                :invalid-feedback="paymentLinkInvalidFeedback"
                :state="paymentLinkState"
              >
                <b-form-input
                  v-model="paymentLink"
                  trim
                  maxlength="512"
                  :state="paymentLinkState"
                  @blur="paymentLinkValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- paymentShortLink -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.paymentShortLink')"
                :invalid-feedback="paymentShortLinkInvalidFeedback"
                :state="paymentShortLinkState"
              >
                <b-form-input
                  v-model="paymentShortLink"
                  trim
                  maxlength="50"
                  :state="paymentShortLinkState"
                  @blur="paymentShortLinkValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- divider -->
            <b-col cols="12">
              <div class="w-100 border-top mt-1 mb-2"></div>
            </b-col>
            <!-- section TitleLogo -->
            <b-col cols="12">
              <h5>{{ $t("laboratory.sectionTitleLogo") }}</h5>
            </b-col>
            <!-- logo image -->
            <b-col
              cols="12"
              md="4"
            >
              <InputImage
                class="mb-3"
                :labelProp="$t('laboratories.logo')"
                :altProp="$t('laboratories.logoAlt')"
                :imageFileProp="logoFile"
                :imageUrlProp="logoUrl"
                :stateProp="logoState"
                :invalidFeedbackProp="logoInvalidFeedback"
                @updateImage="logoFile = $event; logoValidation = true;"
                @updateUrl="logoUrl = $event; logoValidation = true;"
              />
            </b-col>
            <!-- baseColor -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.baseColor')"
                :invalid-feedback="baseColorInvalidFeedback"
                :state="baseColorState"
              >
                <b-form-input
                  v-model="baseColor"
                  style="padding-left: 40px"
                  trim
                  maxlength="7"
                  :state="baseColorState"
                  :placeholder="$t('laboratories.baseColorPlaceholder')"
                  @blur="baseColorValidation = true"
                ></b-form-input>
                <!-- color preview -->
                <div style="position: relative;">
                  <div
                    style="position: absolute; bottom: 0; left: 0; width: 34px; height: 36.5px; z-index: 1; border-radius: 0.25rem 0 0 0.2rem; pointer-events: none;"
                    :style="{
                      backgroundColor: (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(baseColor)) ? baseColor : '#4c505c'
                    }"
                  ></div>
                </div>
              </b-form-group>
            </b-col>

            <!-- divider -->
            <b-col cols="12">
              <div class="w-100 border-top mt-1 mb-2"></div>
            </b-col>
            <!-- section AdvancedParameters -->
            <b-col cols="12">
              <h5>{{ $t("laboratory.sectionTitleAdvancedParameters") }}</h5>
            </b-col>
            <!-- accessNumberRegex -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.accessNumberRegex')"
                :invalid-feedback="accessNumberRegexInvalidFeedback"
                :state="accessNumberRegexState"
              >
                <b-form-input
                  v-model="accessNumberRegex"
                  :placeholder="$t('laboratories.accessNumberRegexPlaceholder')"
                  trim
                  maxlength="50"
                  :state="accessNumberRegexState"
                  @blur="accessNumberRegexValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- slideNumberRegex -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.slideNumberRegex')"
                :invalid-feedback="slideNumberRegexInvalidFeedback"
                :state="slideNumberRegexState"
              >
                <b-form-input
                  v-model="slideNumberRegex"
                  :placeholder="$t('laboratories.slideNumberRegexPlaceholder')"
                  trim
                  maxlength="50"
                  :state="slideNumberRegexState"
                  @blur="slideNumberRegexValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- slideToAccessNumberRegex -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('laboratories.slideToAccessNumberRegex')"
                :invalid-feedback="slideToAccessNumberRegexInvalidFeedback"
                :state="slideToAccessNumberRegexState"
              >
                <b-form-input
                  v-model="slideToAccessNumberRegex"
                  :placeholder="$t('laboratories.slideToAccessNumberRegexPlaceholder')"
                  trim
                  maxlength="50"
                  :state="slideToAccessNumberRegexState"
                  @blur="slideToAccessNumberRegexValidation = true"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputImage from "@shared/views/Helpers/InputImage";
import Reminders from "@/views/Administration/Laboratories/Reminders";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import address from "@shared/services/UI/address";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import { navigate } from "@/services/UI/vueRouterServices";
import phoneNumber from "@shared/services/UI/phoneNumber";

export default {
  components: { InputImage, Reminders },
  mixins: [userRights, error, address, phoneNumber, price],
  props: {
    laboratoryIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      laboratoryId: this.laboratoryIdProp,
      laboratoryData: null,
      isSystemUser: this.isRole("system"),
      // form variables
      name: "",
      registrationNumber: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      country: this.getLocalisedDefaultCountry(),
      phone: "",
      email: "",
      accountingDepartmentEmail: "",
      smtpServerAddress: "",
      smtpServerPort: "",
      smtpServerUser: "",
      smtpServerPassword: "",
      smtpServerHourlyQuota: "",
      website: "",
      gprdWebsite: "",
      paymentLink: "",
      paymentShortLink: "",
      bankDetails: "",
      baseColor: "",
      logoUrl: null,
      logoFile: null,
      accessNumberRegex: "",
      slideNumberRegex: "",
      slideToAccessNumberRegex: "",
      reminders: [],
      remindersState: true,
      debtCollectionDelay: "",
      debtCollectionDepartment: "",
      // is validation active
      nameValidation: false,
      registrationNumberValidation: false,
      address1Validation: false,
      address2Validation: false,
      zipValidation: false,
      cityValidation: false,
      countryValidation: false,
      phoneValidation: false,
      emailValidation: false,
      accountingDepartmentEmailValidation: false,
      smtpServerAddressValidation: false,
      smtpServerPortValidation: false,
      smtpServerUserValidation: false,
      smtpServerPasswordValidation: false,
      smtpServerHourlyQuotaValidation: false,
      websiteValidation: false,
      gprdWebsiteValidation: false,
      paymentLinkValidation: false,
      paymentShortLinkValidation: false,
      bankDetailsValidation: false,
      baseColorValidation: false,
      logoValidation: false,
      accessNumberRegexValidation: false,
      slideNumberRegexValidation: false,
      slideToAccessNumberRegexValidation: false,
      remindersValidation: false,
      debtCollectionDelayValidation: false,
      debtCollectionDepartmentValidation: false
    };
  },
  computed: {
    // form validation
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    registrationNumberState: function() {
      if (!this.registrationNumberValidation) return null;
      return this.registrationNumber && this.registrationNumber.length === 14 ? null : false;
    },
    registrationNumberInvalidFeedback: function() {
      // no error
      if (this.registrationNumberState === null) return "";
      // required
      if (this.registrationNumber === "") return this.$t("validationRules.required");
      // other : length
      return this.$t("laboratoryEdit.validations.regNumberLength");
    },
    address1State: function() {
      if (!this.address1Validation) return null;
      return this.address1 && this.address1.length > 0 ? null : false;
    },
    address1InvalidFeedback: function() {
      return this.address1State === false ? this.$t("validationRules.required") : "";
    },
    address2State: function() {
      return null;
    },
    address2InvalidFeedback: function() {
      return "";
    },
    zipState: function() {
      if (!this.zipValidation) return null;
      return this.zip && this.zip.length > 0 ? null : false;
    },
    zipInvalidFeedback: function() {
      return this.zipState === false ? this.$t("validationRules.required") : "";
    },
    cityState: function() {
      if (!this.cityValidation) return null;
      return this.city && this.city.length > 0 ? null : false;
    },
    cityInvalidFeedback: function() {
      return this.cityState === false ? this.$t("validationRules.required") : "";
    },
    countryState: function() {
      if (!this.countryValidation) return null;
      return this.country && this.country.length > 0 ? null : false;
    },
    countryInvalidFeedback: function() {
      return this.countryState === false ? this.$t("validationRules.required") : "";
    },
    phoneState: function() {
      // validation active
      if (!this.phoneValidation) return null;
      return this.phone && this.phone.length > 0 && /^0?[1234589]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // if empty
      if (!this.phone) return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidLandlinePhone");
    },
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    accountingDepartmentEmailState: function() {
      if (!this.accountingDepartmentEmailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.accountingDepartmentEmail) ? null : false;
    },
    accountingDepartmentEmailInvalidFeedback: function() {
      // no error
      if (this.accountingDepartmentEmailState === null) return "";
      // if empty
      if (this.accountingDepartmentEmail === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    smtpServerAddressState: function() {
      if (!this.smtpServerAddressValidation) return null;
      return !this.isSystemUser || (this.smtpServerAddress && this.smtpServerAddress.length > 0) ? null : false;
    },
    smtpServerAddressInvalidFeedback: function() {
      // no error
      if (this.smtpServerAddressState === null) return "";
      // else if empty
      if (this.smtpServerAddress === "") return this.$t("validationRules.required");
      return this.$t("laboratoryEdit.validations.invalidSmtpServerAddress");
    },
    smtpServerPortState: function() {
      if (!this.smtpServerPortValidation) return null;
      return !this.isSystemUser || (/^[0-9]+$/.test(this.smtpServerPort) && Number(this.smtpServerPort) >= 1 && Number(this.smtpServerPort) <= 65535) ? null : false;
    },
    smtpServerPortInvalidFeedback: function() {
      // no error
      if (this.smtpServerPortState === null) return "";
      // if empty
      if (this.smtpServerPort === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("laboratoryEdit.validations.invalidSmtpServerPort");
    },
    smtpServerUserState: function() {
      if (!this.smtpServerUserValidation) return null;
      return !this.isSystemUser || (this.smtpServerUser && this.smtpServerUser.length > 0) ? null : false;
    },
    smtpServerUserInvalidFeedback: function() {
      // no error
      if (this.smtpServerUserState === null) return "";
      // if empty
      if (this.smtpServerUser === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("laboratoryEdit.validations.invalidSmtpServerUser");
    },
    smtpServerPasswordState: function() {
      if (!this.smtpServerPasswordValidation) return null;
      return !this.isSystemUser || (this.smtpServerPassword && this.smtpServerPassword.length > 0) ? null : false;
    },
    smtpServerPasswordInvalidFeedback: function() {
      // no error
      if (this.smtpServerPasswordState === null) return "";
      // if empty
      if (this.smtpServerPassword === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("laboratoryEdit.validations.invalidSmtpServerPassword");
    },
    smtpServerHourlyQuotaState: function() {
      if (!this.smtpServerHourlyQuotaValidation) return null;
      return !this.isSystemUser || (/^[0-9]+$/.test(this.smtpServerHourlyQuota)) ? null : false;
    },
    smtpServerHourlyQuotaInvalidFeedback: function() {
      // no error
      if (this.smtpServerHourlyQuotaState === null) return "";
      // else if empty
      if (this.smtpServerHourlyQuota === "") return this.$t("validationRules.required");
      return this.$t("laboratoryEdit.validations.invalidSmtpHourlyQuota");
    },
    websiteState: function() {
      return null;
    },
    websiteInvalidFeedback: function() {
      return "";
    },
    gprdWebsiteState: function() {
      return null;
    },
    gprdWebsiteInvalidFeedback: function() {
      return "";
    },
    paymentLinkState: function() {
      return null;
    },
    paymentLinkInvalidFeedback: function() {
      return "";
    },
    paymentShortLinkState: function() {
      return null;
    },
    paymentShortLinkInvalidFeedback: function() {
      return "";
    },
    bankDetailsState: function() {
      return null;
    },
    bankDetailsInvalidFeedback: function() {
      return "";
    },
    baseColorState: function() {
      if (!this.baseColorValidation || !this.isSystemUser) return null;
      // test if empty or invalid
      return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(this.baseColor) ? null : false;
    },
    baseColorInvalidFeedback: function() {
      // no error
      if (this.baseColorState === null) return "";
      // if empty
      if (this.baseColor === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidBaseColor");
    },
    logoState: function() {
      return null;
    },
    logoInvalidFeedback: function() {
      return "";
    },
    accessNumberRegexState: function() {
      return null;
    },
    accessNumberRegexInvalidFeedback: function() {
      return "";
    },
    slideNumberRegexState: function() {
      return null;
    },
    slideNumberRegexInvalidFeedback: function() {
      return "";
    },
    slideToAccessNumberRegexState: function() {
      return null;
    },
    slideToAccessNumberRegexInvalidFeedback: function() {
      return "";
    },
    higherRemindersDelay: function() {
      return Math.max(...this.reminders.map(o => Number(o.delay)));
    },
    debtCollectionDelayState: function() {
      if (!this.debtCollectionDelayValidation) return null;
      if (this.debtCollectionDelay === "") return null;
      // invalid
      if (!/^[0-9]+$/.test(this.debtCollectionDelay)) return false;
      // higher than reminders
      if (Number(this.debtCollectionDelay) && this.higherRemindersDelay > Number(this.debtCollectionDelay)) return false;
      // else
      return null;
    },
    debtCollectionDelayInvalidFeedback: function() {
      // no error
      if (this.debtCollectionDelayState === null) return "";
      // if empty
      if (this.debtCollectionDelay === "") return this.$t("validationRules.required");
      // if invalid
      if (!/^[0-9]+$/.test(this.debtCollectionDelay)) return this.$t("laboratoryEdit.validations.invalidDelay");
      // if higher than reminders
      return this.$t("laboratoryEdit.validations.delayTooLow");
    },
    debtCollectionDepartmentState: function() {
      return null;
    },
    debtCollectionDepartmentInvalidFeedback: function() {
      return "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("laboratoryEdit.pageTitle") : this.$t("laboratoryNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("laboratoryEdit.submitText") : this.$t("laboratoryNew.submitText");
    }
  },
  async mounted() {
    // import data
    await this.importData();

    // auto focus
    if (!this.moduleModeProp) {
      this.focusFirstElement();
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.laboratoryNameInput.focus();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get laboratory
          const resServices = await commonServices.get("laboratories", this.laboratoryId);
          this.laboratoryData = resServices.data;
          // import data
          this.name = resServices.data.name;
          this.registrationNumber = resServices.data.registrationNumber;
          this.address1 = resServices.data.address1;
          this.address2 = resServices.data.address2;
          this.zip = resServices.data.zip;
          this.city = resServices.data.city;
          this.country = resServices.data.country;
          this.phone = this.importFormatPhone(resServices.data.phone);
          this.email = resServices.data.email;
          this.accountingDepartmentEmail = resServices.data.accountingDepartmentEmail;
          this.smtpServerAddress = resServices.data.smtpServerAddress;
          this.smtpServerPort = resServices.data.smtpServerPort;
          this.smtpServerUser = resServices.data.smtpServerUser;
          this.smtpServerPassword = resServices.data.smtpServerPassword;
          this.smtpServerHourlyQuota = resServices.data.smtpServerHourlyQuota;
          this.website = resServices.data.website;
          this.gprdWebsite = resServices.data.gprdWebsite;
          this.paymentLink = resServices.data.paymentLink;
          this.paymentShortLink = resServices.data.paymentShortLink;
          this.bankDetails = resServices.data.bankDetails;
          this.baseColor = resServices.data.baseColor;
          this.accessNumberRegex = resServices.data.accessNumberRegex;
          this.slideNumberRegex = resServices.data.slideNumberRegex;
          this.slideToAccessNumberRegex = resServices.data.slideToAccessNumberRegex;
          // import logo
          if (resServices.data.logoFileName) {
            const resLogo = await commonServices.getFile("laboratories", this.laboratoryId);
            this.logoFile = new File([resLogo.data], resServices.data.logoFileName);
            this.logoUrl = window.URL.createObjectURL(this.logoFile);
          }
          this.reminders = resServices.data.reminders.map((reminder) => {
            reminder.delay = String(reminder.delay);
            reminder.amount = this.importFormatPrice(reminder.amount);
            return reminder;
          });
          this.debtCollectionDelay = resServices.data.debtCollectionDelay || "";
          this.debtCollectionDepartment = resServices.data.debtCollectionDepartment || "";
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("administration/laboratories");
    },

    // submit functions
    onValidateLaboratory() {
      this.nameValidation = true;
      this.registrationNumberValidation = true;
      this.address1Validation = true;
      this.address2Validation = true;
      this.zipValidation = true;
      this.cityValidation = true;
      this.countryValidation = true;
      this.phoneValidation = true;
      this.emailValidation = true;
      this.accountingDepartmentEmailValidation = true;
      this.smtpServerAddressValidation = true;
      this.smtpServerPortValidation = true;
      this.smtpServerUserValidation = true;
      this.smtpServerPasswordValidation = true;
      this.smtpServerHourlyQuotaValidation = true;
      this.websiteValidation = true;
      this.gprdWebsiteValidation = true;
      this.paymentLinkValidation = true;
      this.paymentShortLinkValidation = true;
      this.bankDetailsValidation = true;
      this.baseColorValidation = true;
      this.logoValidation = true;
      this.accessNumberRegexValidation = true;
      this.slideNumberRegexValidation = true;
      this.slideToAccessNumberRegexValidation = true;
      this.remindersValidation = true;
      this.debtCollectionDelayValidation = true;
      this.debtCollectionDepartmentValidation = true;

      return !(
        this.nameState === false ||
        this.registrationNumberState === false ||
        this.address1State === false ||
        this.address2State === false ||
        this.zipState === false ||
        this.cityState === false ||
        this.countryState === false ||
        this.phoneState === false ||
        this.emailState === false ||
        this.accountingDepartmentEmailState === false ||
        this.smtpServerAddressState === false ||
        this.smtpServerPortState === false ||
        this.smtpServerUserState === false ||
        this.smtpServerPasswordState === false ||
        this.smtpServerHourlyQuota === false ||
        this.websiteState === false ||
        this.gprdWebsiteState === false ||
        this.paymentLinkState === false ||
        this.paymentShortLinkState === false ||
        this.bankDetailsState === false ||
        this.baseColorState === false ||
        this.logoState === false ||
        this.accessNumberRegexState === false ||
        this.slideNumberRegexState === false ||
        this.slideToAccessNumberRegexState === false ||
        this.remindersState === false ||
        this.debtCollectionDelayState === false ||
        this.debtCollectionDepartmentState === false
      );
    },
    async onSaveLaboratory() {
      try {
        let form = {
          name: this.name,
          registrationNumber: this.registrationNumber,
          address1: this.address1,
          address2: this.address2,
          zip: this.zip,
          city: this.city,
          country: this.country,
          email: this.email,
          accountingDepartmentEmail: this.accountingDepartmentEmail,
          smtpServerAddress: this.smtpServerAddress,
          smtpServerPort: this.smtpServerPort,
          smtpServerUser: this.smtpServerUser,
          smtpServerPassword: this.smtpServerPassword,
          smtpServerHourlyQuota: this.smtpServerHourlyQuota,
          phone: this.exportFormatPhone(this.phone),
          bankDetails: this.bankDetails,
          reminders: this.reminders.map((reminder) => {
            return {
              delay: Number(reminder.delay),
              amount: this.exportFormatPrice(reminder.amount)
            };
          }).sort(function(a, b) { return a.delay - b.delay; }),
          debtCollectionDelay: Number(this.debtCollectionDelay) || null,
          debtCollectionDepartment: this.debtCollectionDepartment || ""
        };
        if (this.isSystemUser) {
          form = {
            ...form,
            baseColor: this.baseColor,
            accessNumberRegex: this.accessNumberRegex || "^.*$",
            slideNumberRegex: this.slideNumberRegex || "^.*$",
            slideToAccessNumberRegex: this.slideToAccessNumberRegex || "",
            website: this.website,
            gprdWebsite: this.gprdWebsite,
            paymentLink: this.paymentLink,
            paymentShortLink: this.paymentShortLink
          };
        }
        if (this.editMode) {
          const res = await commonServices.put("laboratories", form, this.laboratoryId);
          if (res.data === true) {
            if (this.isSystemUser) {
              if (await this.onSaveLogo()) {
                this.updateGlobalLaboratoriesList(form);
                this.successSave();
              }
            } else {
              this.updateGlobalLaboratoriesList(form);
              this.successSave();
            }
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("laboratoryEdit.notifications.editionErrorTitle"),
              message: this.$t("laboratoryEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("laboratories", form);
          if (res.data.id) {
            this.laboratoryId = res.data.id;
            if (this.isSystemUser) {
              if (await this.onSaveLogo()) {
                this.updateGlobalLaboratoriesList(form);
                this.successSave();
              }
            } else {
              this.updateGlobalLaboratoriesList(form);
              this.successSave();
            }
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("laboratoryNew.notifications.additionErrorTitle"),
              message: this.$t("laboratoryNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateGlobalLaboratoriesList(form) {
      const laboratory = {
        id: this.laboratoryId,
        isActive: true,
        disabled: false,
        name: form.name,
        formattedName: form.name
      };
      if (this.editMode) {
        for (let i = 0; i < this.$systemSettings.laboratories.length; i++) {
          if (this.$systemSettings.laboratories[i].id === laboratory.id) {
            this.$systemSettings.laboratories[i] = laboratory;
          }
        }
        for (let i = 0; i < this.$systemSettings.availableLaboratories.length; i++) {
          if (this.$systemSettings.availableLaboratories[i].id === laboratory.id) {
            this.$systemSettings.availableLaboratories[i] = laboratory;
          }
        }
      } else {
        this.$systemSettings.laboratories.push(laboratory);
        this.$systemSettings.availableLaboratories.push(laboratory);
      }
    },
    async onSaveLogo() {
      try {
        const res = await commonServices.putFile("laboratories", this.laboratoryId, this.logoFile);
        if (res.data === true) {
          return true;
        } else {
          if (res.data.error === "fileExceedsMaximumSize") {
            this.$emit("alert", "danger", {
              title: this.$t("laboratoryEdit.notifications.fileExceedsMaximumSizeTitle"),
              message: this.$t("laboratoryEdit.notifications.fileExceedsMaximumSizeText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("laboratoryEdit.notifications.editionErrorTitle"),
              message: this.$t("laboratoryEdit.notifications.editionErrorText")
            });
          }
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave() {
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("laboratoryEdit.notifications.editedTitle"),
          message: this.$t("laboratoryEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("laboratoryNew.notifications.addedTitle"),
          message: this.$t("laboratoryNew.notifications.addedText")
        });
      }

      if (this.moduleModeProp) {
        // MODULE MODE : go back to main component
        const laboratoryNewData = {
          id: this.laboratoryId,
          name: this.name,
          address1: this.address1,
          address2: this.address2,
          zip: this.zip,
          city: this.city,
          country: this.country,
          registrationNumber: this.registrationNumber,
          email: this.email,
          accountingDepartmentEmail: this.accountingDepartmentEmail,
          smtpServerAddress: this.smtpServerAddress,
          smtpServerPort: this.smtpServerPort,
          smtpServerUser: this.smtpServerUser,
          smtpServerPassword: this.smtpServerPassword,
          smtpServerHourlyQuota: this.smtpServerHourlyQuota,
          website: this.website,
          gprdWebsite: this.gprdWebsite,
          paymentLink: this.paymentLink,
          paymentShortLink: this.paymentShortLink,
          bankDetails: this.bankDetails,
          phone: this.exportFormatPhone(this.phone),
          baseColor: this.baseColor,
          accessNumberRegex: this.accessNumberRegex || "^.*$",
          slideNumberRegex: this.slideNumberRegex || "^.*$",
          slideToAccessNumberRegex: this.slideToAccessNumberRegex || ""
        };
        this.$emit("chosen", laboratoryNewData);
      } else {
        // REGULAR PAGE : go back to the list page
        navigate("administration/laboratories");
      }
    },
    onSubmit() {
      if (this.onValidateLaboratory() !== false) this.onSaveLaboratory();
    }
  }
};
</script>

<template>
  <div class="kt-register">
    <!-- title -->
    <h2 class="kt-login__action-title h5">
      {{ $t('session.login.register') }}
    </h2>
    <!-- already have an account ? -->
    <div class="kt-login__subtitle">
      {{ $t("session.register.loginText") }}
      <b-link
        :class="'kt-link kt-link--' + $systemSettings.theme"
        @click="$emit('goToLogin')"
      >
        {{ $t("session.register.loginCta") }}
      </b-link>
    </div>

    <!-- register form -->
    <b-form @submit.prevent="onSubmit">
      <!-- email -->
      <b-form-group
        class="kt-form-group--mb-sm"
        :label="$t('session.register.email')"
        :invalid-feedback="emailInvalidFeedback"
        :state="emailState"
      >
        <b-form-input
          ref="emailInput"
          v-model="email"
          type="email"
          trim
          :state="emailState"
          @blur="emailValidation = true"
        ></b-form-input>
      </b-form-group>

      <!-- civility -->
      <b-form-group
        class="kt-form-group--mb-sm"
        :invalid-feedback="sexInvalidFeedback"
        :state="sexState"
      >
        <b-form-radio-group
          v-model="sexId"
          :options="sexOptions"
          value-field="id"
          text-field="localisedName"
          :state="sexState"
        ></b-form-radio-group>
      </b-form-group>

      <!-- first name -->
      <InputTextFormatted
        v-model="firstName"
        :labelProp="$t('session.register.firstName')"
        :stateProp="firstNameState"
        :invalidFeedbackProp="firstNameInvalidFeedback"
        class="kt-form-group--mb-sm"
        @blur="firstNameValidation = true"
        @submit="onSubmit"
      />

      <!-- last name -->
      <InputTextFormatted
        v-model="lastName"
        :labelProp="$t('session.register.lastName')"
        :stateProp="lastNameState"
        :invalidFeedbackProp="lastNameInvalidFeedback"
        class="kt-form-group--mb-sm"
        @blur="lastNameValidation = true"
        @submit="onSubmit"
      />

      <!-- phone -->
      <b-form-group
        class="kt-form-group--mb-sm"
        :label="$t('session.register.phone')"
        :invalid-feedback="phoneInvalidFeedback"
        :state="phoneState"
      >
        <b-form-input
          v-model="phone"
          class="kt-input--prefixed"
          trim
          :state="phoneState"
          @blur="phoneValidation = true"
        ></b-form-input>
        <!-- prefix -->
        <div class="kt-input-prefix">
          <div class="kt-input-prefix__content">
            {{ $t('general.phoneCountryCodeDefault') }}
          </div>
        </div>
      </b-form-group>

      <!-- password -->
      <b-form-group
        class="kt-form-group--mb-sm"
        :label="$t('session.register.password')"
        :invalid-feedback="passwordInvalidFeedback"
        :state="passwordState"
      >
        <b-form-input
          ref="passwordField"
          v-model="password"
          class="kt-formField--password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :state="passwordState"
        ></b-form-input>
        <!-- show password -->
        <div class="kt-show-password">
          <b-button
            class="kt-show-password__button"
            tabindex="-1"
            @click="onShowPassword('passwordField', 'isPasswordVisible')"
          >
            <b-icon
              v-show="isPasswordVisible"
              :class="'text-' + $systemSettings.theme"
              icon="eye-slash-fill"
            ></b-icon>
            <b-icon
              v-show="!isPasswordVisible"
              icon="eye-fill"
            ></b-icon>
          </b-button>
        </div>
        <!-- password badges -->
        <div class="kt-password-entry-badges">
          <!-- minLenght -->
          <span
            v-if="!password || !(password.length >= 12)"
            class="kt-badge kt-badge--blue"
          >{{ $t("passwordBadges.minLenghtBadge") }}</span>
          <!-- lowercase -->
          <span
            v-if="!(/^(?=.*[a-z]).{1,}$/.test(password))"
            class="kt-badge kt-badge--blue"
          >{{ $t("passwordBadges.lowercaseBadge") }}</span>
          <!-- uppercase -->
          <span
            v-if="!(/^(?=.*[A-Z]).{1,}$/.test(password))"
            class="kt-badge kt-badge--blue"
          >{{ $t("passwordBadges.uppercaseBadge") }}</span>
          <!-- number -->
          <span
            v-if="!(/^(?=.*\d).{1,}$/.test(password))"
            class="kt-badge kt-badge--blue"
          >{{ $t("passwordBadges.numberBadge") }}</span>
        </div>
      </b-form-group>

      <!-- passwordCheck -->
      <b-form-group
        :invalid-feedback="passwordCheckInvalidFeedback"
        :state="passwordCheckState"
      >
        <b-form-input
          ref="passwordCheckField"
          v-model="passwordCheck"
          class="kt-formField--password"
          :type="isPasswordCheckVisible ? 'text' : 'password'"
          :placeholder="$t('session.register.repeatPassword')"
          :state="passwordCheckState"
          @blur="passwordCheckValidation = true"
        ></b-form-input>
        <!-- show password -->
        <div class="kt-show-password">
          <b-button
            class="kt-show-password__button"
            tabindex="-1"
            @click="onShowPassword('passwordCheckField', 'isPasswordCheckVisible')"
          >
            <b-icon
              v-show="isPasswordCheckVisible"
              :class="'text-' + $systemSettings.theme"
              icon="eye-slash-fill"
            ></b-icon>
            <b-icon
              v-show="!isPasswordCheckVisible"
              icon="eye-fill"
            ></b-icon>
          </b-button>
        </div>
      </b-form-group>

      <!-- footer -->
      <div class="kt-login__footer">
        <div></div>
        <!-- submit -->
        <div>
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            pill
            type="submit"
          >
            {{ $t("session.register.submit") }}
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
// components
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import error from "@shared/services/UI/error";

export default {
  components: { InputTextFormatted },
  mixins: [error],
  data() {
    return {
      email: "",
      sexId: 2,
      firstName: "",
      lastName: "",
      phone: "",
      password: "",
      isPasswordVisible: false,
      passwordCheck: "",
      isPasswordCheckVisible: false,
      // is validation active
      emailValidation: false,
      sexValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      phoneValidation: false,
      passwordValidation: false,
      passwordCheckValidation: false
    };
  },
  computed: {
    // form validation
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    sexState: function() {
      if (!this.sexValidation) return null;
      return this.sexId && this.sexId > 0 ? null : false;
    },
    sexInvalidFeedback: function() {
      return this.sexState === false ? this.$t("validationRules.required") : "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      return this.firstName && this.firstName.length > 0 ? null : false;
    },
    firstNameInvalidFeedback: function() {
      return this.firstNameState === false ? this.$t("validationRules.required") : "";
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      return this.lastName && this.lastName.length > 0 ? null : false;
    },
    lastNameInvalidFeedback: function() {
      return this.lastNameState === false ? this.$t("validationRules.required") : "";
    },
    phoneState: function() {
      // validation active
      if (!this.phoneValidation) return null;
      // not required
      if (this.phone === "") return null;

      return this.phone && this.phone.length > 0 && /^0?[67]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // if empty
      if (!this.phone) return this.$t("validationRules.required");
      // else : passwords not equals
      return this.$t("validationRules.invalidMobilePhone");
    },
    passwordState: function() {
      if (!this.passwordValidation) return null;
      return this.password && this.password.length >= 12 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password) && this.password === this.passwordCheck ? null : false;
    },
    passwordInvalidFeedback: function() {
      // no error
      if (this.passwordState === null) return "";
      // if empty
      //if (!this.password && !(this.password.length > 0)) return this.$t("validationRules.required");
      // too short
      //if (this.password.length < 12) return this.$t("session.register.validations.passwordTooShort");
      // lowercase
      //if (!(/^(?=.*[a-z]).{12,}$/.test(this.password))) return this.$t("session.register.validations.passwordNoLowercase");
      // uppercase
      //if (!(/^(?=.*[A-Z]).{12,}$/.test(this.password))) return this.$t("session.register.validations.passwordNoUppercase");
      // number
      //if (!(/^(?=.*\d).{12,}$/.test(this.password))) return this.$t("session.register.validations.passwordNoNumber");
      // error but no notification
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(this.password)) return "";
      // else : passwords not equals
      return this.$t("session.register.validations.passwordNotEquals");
    },
    passwordCheckState: function() {
      if (!this.passwordCheckValidation) return null;
      return this.passwordCheck && this.passwordCheck.length > 0 && this.password === this.passwordCheck ? null : false;
    },
    passwordCheckInvalidFeedback: function() {
      // no error
      if (this.passwordCheckState === null) return "";
      // if empty
      if (!this.passwordCheck) return this.$t("validationRules.required");
      // else : passwords not equals
      return this.$t("session.register.validations.passwordNotEquals");
    },
    // formatting variables
    // format phone to +33 for sending the file
    formattedPhone: function() {
      var formattedPhone = "";
      if (/^0\d{9}$/.test(this.phone)) {
        formattedPhone = "+33" + this.phone.substring(1);
      } else if (/^\d{9}$/.test(this.phone)) {
        formattedPhone = "+33" + this.phone;
      }
      return formattedPhone;
    },

    sexOptions: function() {
      return this.$systemSettings.sexes;
    }
  },
  async mounted() {
    // auto focus
    this.$refs.emailInput.focus();
  },
  methods: {
    onShowPassword(refTarget, showVariableName) {
      this[showVariableName] = !this[showVariableName];
      this.$nextTick(() => this.$refs[refTarget].focus());
    },

    // submit functions
    onValidate() {
      this.emailValidation = true;
      this.sexValidation = true;
      this.firstNameValidation = true;
      this.lastNameValidation = true;
      this.phoneValidation = true;
      this.passwordValidation = true;
      this.passwordCheckValidation = true;

      return !(
        this.emailState === false ||
        this.sexState === false ||
        this.firstNameState === false ||
        this.lastNameState === false ||
        this.phoneValidation === false ||
        this.passwordState === false ||
        this.passwordCheckState === false
      );
    },
    async onSubmit() {
      try {
        // Validate Form
        if (this.onValidate() === false) return false;

        // Register file
        const form = {
          email: this.email,
          sexId: this.sexId,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.formattedPhone,
          password: this.password,
          passwordCheck: this.passwordCheck
        };
        const res = await commonServices.post("userRequests", form);
        if (res.data.id) {
          form.id = res.data.id;
          this.$emit("registered", form);
        } else if (res.data.error === "unknownDomain") {
          // Unknown domain
          this.$emit("alert", "danger", {
            title: this.$t("session.register.notifications.onUnknownDomainTitle"),
            message: this.$t("session.register.notifications.onUnknownDomainText")
          });
        } else if (res.data.error === "userAlreadyExists") {
          // User already exists
          this.$emit("alert", "danger", {
            title: this.$t("session.register.notifications.userAlreadyExistsTitle"),
            message: this.$t("session.register.notifications.userAlreadyExistsText")
          });
        } else {
          // Other errors
          this.$emit("alert", "danger", {
            title: this.$t("session.register.notifications.onRegisterErrorTitle"),
            message: this.$t("session.register.notifications.onRegisterErrorText")
          });
        }
      } catch (err) {
        // Other errors
        this.$emit("alert", "danger", {
          title: this.$t("session.register.notifications.onRegisterErrorTitle"),
          message: this.$t("session.register.notifications.onRegisterErrorText")
        });
      }
    }
  }
};
</script>

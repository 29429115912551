<template>
  <div class="kt-resultsReports mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("resultsReports.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- filter form -->
    <b-form @submit.prevent="searchAndUpdateTable">
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="laboratoryId"
            @input="laboratoryId = $event; searchHasChanged = true;"
            @keydown.enter.exact="searchAndUpdateTable"
            @blur="laboratoryIdValidation = true;"
          />
        </b-col>

        <!-- period -->
        <b-col
          cols="12"
          md="3"
        >
          <label
            for="kt-resultsReports__period"
            class="d-block"
            style="margin-bottom: 5px;"
          >
            {{ $t('resultsReports.period') }}
          </label>
          <b-form-group
            :invalid-feedback="startDateInvalidFeedback"
            :state="startDateState"
            style="display: inline-block; width: 50%; padding-right: 4px; vertical-align: top;"
          >
            <b-form-input
              id="kt-resultsReports__period"
              v-model="startDate"
              type="date"
              :state="startDateState"
              @blur="startDateValidation = true"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :invalid-feedback="endDateInvalidFeedback"
            :state="endDateState"
            style="display: inline-block; width: 50%; vertical-align: top; padding-left: 4px;"
          >
            <b-form-input
              v-model="endDate"
              type="date"
              :state="endDateState"
              @blur="endDateValidation = true"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- filterContact -->
        <b-col
          cols="12"
          md="3"
        >
          <InputSearch
            :labelProp="$t('files.prescriber')"
            searchedTableProp="contacts"
            searchedFieldProp="lastName"
            :searchedLaboratoriesProp="null"
            :returnFieldsArrayProp="['id', 'firstName', 'lastName', 'code', 'isActive']"
            suggestionsKeyProp="id"

            :valueProp="filterContact"
            optionalProp
            :displayedValueProp="filterContact ? filterContact.firstName + ' ' + filterContact.lastName + ' (' + filterContact.code + ')' + (filterContact.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.firstName + ' ' + suggestion.lastName + ' (' + suggestion.code + ')' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
            }"

            @onUpdateParent="filterContact = $event; searchHasChanged = true;"
            @keydown.enter.exact="searchAndUpdateTable"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- filter Cytotechnician -->
        <b-col
          cols="12"
          md="3"
        >
          <InputSearch
            :labelProp="$t('cytotechniciansReports.cytotechnician')"
            searchedTableProp="users"
            searchedFieldProp="lastName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'lastName', 'firstName', 'isActive']"
            suggestionsKeyProp="id"

            :valueProp="filterCytotechnician"
            optionalProp
            :displayedValueProp="filterCytotechnician ? filterCytotechnician.firstName + ' ' + filterCytotechnician.lastName + ' ' + (filterCytotechnician.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.firstName + ' ' + suggestion.lastName + ' ' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
            }"

            @onUpdateParent="filterCytotechnician = $event; searchHasChanged = true;"
            @keydown.enter.exact="searchAndUpdateTable"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- filter Pathologist -->
        <b-col
          cols="12"
          md="3"
        >
          <InputSearch
            :labelProp="$t('files.pathologist')"
            searchedTableProp="pathologists"
            searchedFieldProp="user.lastName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'code', 'user.lastName', 'user.firstName', 'user.isActive']"
            suggestionsKeyProp="id"

            :valueProp="filterPathologist"
            optionalProp
            :displayedValueProp="filterPathologist ? filterPathologist.user.firstName + ' ' + filterPathologist.user.lastName + ' (' + filterPathologist.code + ')' + (filterPathologist.user.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.user.firstName + ' ' + suggestion.user.lastName + ' (' + suggestion.code + ')' + (suggestion.user.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
            }"

            @onUpdateParent="filterPathologist = $event; searchHasChanged = true;"
            @keydown.enter.exact="searchAndUpdateTable"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <b-col cols="12">
          <!-- submit -->
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
            :disabled="loading || exportLoading"
          >
            <span class="align-middle">{{ $t("general.search") }}</span>
            <b-spinner
              v-show="loading"
              class="ml-2 align-middle spinner-border-xs kt-spinner--bg-transparent"
            ></b-spinner>
          </b-button>
          <!-- export -->
          <b-button
            class="ml-2"
            :variant="$systemSettings.theme"
            :disabled="!cytologyReportsList.length || searchHasChanged || loading || exportLoading"
            @click="onExport"
          >
            <span class="align-middle">{{ $t("general.downloadXlsx") }}</span>
            <b-spinner
              v-show="exportLoading"
              class="ml-2 align-middle spinner-border-xs kt-spinner--bg-transparent"
            ></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- divider -->
    <div class="w-100 border-top mt-4 mb-4"></div>

    <!-- table -->
    <b-table
      class="mb-0 kt-table"
      style="margin-top: 32px;"
      hover
      :fields="fields"
      :items="cytologyReportsList"
      :busy="loading"
    >
      <!-- cell template : patient -->
      <template v-slot:cell(patient)="data">
        <PersonName
          v-if="data.item.file && data.item.file.patient"
          class="kt-break-word"
          :firstNameProp="data.item.file.patient.firstName || ''"
          :lastNameProp="data.item.file.patient.lastName || ''"
          :maidenNameProp="data.item.file.patient.maidenName || ''"
        />
      </template>
      <!-- cell template : parentCytologyReportId -->
      <template v-slot:cell(parentCytologyReportId)="data">
        <div style="white-space: nowrap;">
          <span
            v-show="data.value"
            class="kt-table__cell-check kt-table__cell-check--success"
          >
            <b-icon icon="check2"></b-icon>
          </span>
        </div>
      </template>
      <!-- cell template : downloaded -->
      <template v-slot:cell(downloaded)="data">
        <div style="white-space: nowrap;">
          <span
            v-show="data.value"
            class="kt-table__cell-check kt-table__cell-check--success"
          >
            <b-icon icon="check2"></b-icon>
          </span>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="showNoResult && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3 mb-4"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
import resultsReportsServices from "@/services/API/resultsReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import HpvResultHelpers from "@/services/UI/HpvResultHelpers";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { PersonName, SmartSelectInput, InputSearch },
  mixins: [userRights, error, date, saveParamsInQuery, HpvResultHelpers],
  data() {
    return {
      // general
      loading: false,
      exportLoading: false,
      searchHasChanged: false,
      maxReached: false,
      showNoResult: false,
      // search filters
      laboratoryId: null,
      startDate: "",
      endDate: "",
      filterContact: null,
      filterCytotechnician: null,
      filterPathologist: null,
      // validation
      laboratoryIdValidation: false,
      startDateValidation: false,
      endDateValidation: false,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "prescriber",
          sortable: false,
          label: this.$t("files.prescriber"),
          formatter: (_value, _key, item) => {
            if (!item.contact) return "";
            return item.contact.code;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumber",
          sortable: false,
          label: this.$t("files.accessNumber"),
          formatter: (_value, _key, item) => {
            return item.file ? item.file.accessNumber : "";
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "patient",
          sortable: true,
          label: this.$t("files.patient")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "collectionDate",
          sortable: false,
          label: this.$t("files.collectionDateShort"),
          formatter: (_value, _key, item) => {
            if (!item.file || !item.file.collectionDate) return "";
            return this.$d(new Date(item.file.collectionDate), "date");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "containerType",
          sortable: false,
          label: this.$t("files.containerType"),
          formatter: (_value, _key, item) => {
            return item.file ? item.file.containerType : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "task",
          sortable: false,
          label: this.$t("files.containerGroupTasks"),
          formatter: (_value, _key, item) => {
            if (!item.task) return "";
            return item.task.code;
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "cytologyReportResult",
          sortable: false,
          label: this.$t("files.advancedSearchCytologyResult"),
          formatter: (_value, _key, item) => {
            if (!item.content || !item.content.result) return "";
            return this.$t("cytologyReport.result." + item.content.result);
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "hpvResult",
          sortable: false,
          label: this.$t("files.advancedSearchHpvResult"),
          formatter: (_value, _key, item) => {
            if (!item.hpvResult) return "";
            return this.getLocalisedHpvResult(item.hpvResult.content);
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "cytotechnician",
          sortable: false,
          label: this.$t("medicalReports.file.cytotechnician"),
          formatter: (value, _key, _item) => {
            return value ? value.firstName + " " + value.lastName : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "pathologist",
          sortable: false,
          label: this.$t("files.pathologist"),
          formatter: (value, _key, _item) => {
            return value ? value.user.firstName + " " + value.user.lastName : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "timestamp",
          sortable: false,
          label: this.$t("resultsReports.medicalValidationTimestamp"),
          formatter: (_value, _key, item) => {
            if (!item.content || !item.content.medicalValidationTimestamp) return this.$t("general.emptyWithHyphen");
            return this.getFormattedDateTimeFromTimestamp(item.content.medicalValidationTimestamp);
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "parentCytologyReportId",
          sortable: false,
          label: this.$t("resultsReports.hasParent"),
          formatter: (value, _key, _item) => {
            return !!value;
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "downloaded",
          sortable: false,
          label: this.$t("resultsReports.downloaded"),
          formatter: (value, _key, _item) => {
            return !!value;
          }
        }
      ],
      // table items
      cytologyReportsList: [],
      // saveParamsConfig
      saveParamsConfig: {
        laboratoryId: "number",
        startDate: "string",
        endDate: "string",
        filterContact: {
          id: "number",
          firstName: "string",
          lastName: "string",
          isActive: "boolean"
        },
        filterCytotechnician: {
          id: "number",
          firstName: "string",
          lastName: "string",
          isActive: "boolean"
        },
        filterPathologist: {
          id: "number",
          code: "string",
          user: {
            firstName: "string",
            lastName: "string",
            isActive: "boolean"
          }
        }
      }
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    isValidGapBetweenStartEnd: function() {
      return this.validateDateBeforeDate(this.getIsoDate(-1, -3, 0, this.endDate), this.startDate);
    },
    startDateState: function() {
      if (!this.startDateValidation) return null;
      if (!this.startDate) return false;
      if (this.endDate && !this.validateDateBeforeDate(this.startDate, this.endDate)) return false;
      if (!this.isValidGapBetweenStartEnd) return false;
      return null;
    },
    startDateInvalidFeedback: function() {
      if (this.startDateState === null) return "";
      if (!this.startDate) return this.$t("validationRules.required");
      if (!this.isValidGapBetweenStartEnd) return this.$tc("reports.reportsSearchForm.validations.oneMonthMaxGap", 3, { authorizesGapInMonths: 3 });
      return this.$t("validationRules.endDateBeforeStartDate");
    },
    endDateState: function() {
      if (!this.endDateValidation) return null;
      if (!this.endDate) return false;
      if (this.startDate && !this.validateDateBeforeDate(this.startDate, this.endDate)) return false;
      if (!this.isValidGapBetweenStartEnd) return false;
      return null;
    },
    endDateInvalidFeedback: function() {
      if (this.endDateState === null) return "";
      if (!this.endDate) return this.$t("validationRules.required");
      if (!this.isValidGapBetweenStartEnd) return "";
      return "";
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // set default period
    if (this.startDate === "") this.startDate = this.getIsoDate(0, -3, 0);
    if (this.endDate === "") this.endDate = this.getIsoDate(0, 0, 0);
  },
  methods: {
    // submit functions
    async validateForm() {
      this.laboratoryIdValidation = true;
      this.startDateValidation = true;
      this.endDateValidation = true;

      return (
        this.laboratoryIdState !== false &&
        this.startDateState !== false &&
        this.endDateState !== false
      );
    },
    // search and update table
    async searchAndUpdateTable() {
      if (this.loading || this.exportLoading) return false;

      try {
        // validation
        if (await this.validateForm() === false) return false;

        this.loading = true;
        this.searchHasChanged = false;
        const filters = {
          laboratoryId: this.laboratoryId,
          startDate: this.startDate,
          endDate: this.endDate,
          contactId: this.filterContact ? this.filterContact.id : null,
          cytotechnicianId: this.filterCytotechnician ? this.filterCytotechnician.id : null,
          pathologistId: this.filterPathologist ? this.filterPathologist.id : null
        };
        const res = await commonServices.getAll(
          "resultsReports",
          filters,
          0, // offset
          100 // limit
        );
        // assign result
        let tempLines = [];
        if (res.data.length) {
          for (const contact of res.data) {
            if (res.data.length) {
              tempLines = tempLines.concat(
                contact.cytologyReports.map((v, i) => {
                  if (i === 0) {
                    v.contact = {
                      firstName: contact.firstName,
                      lastName: contact.lastName,
                      code: contact.code
                    };
                  }
                  return v;
                })
              );
            }
          }
        }
        this.cytologyReportsList = tempLines;

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.handleErrors(err);
      }
    },

    async onExport() {
      if (this.loading || this.exportLoading) return false;

      try {
        // validation
        if (await this.validateForm() === false) return false;

        this.exportLoading = true;
        const filters = {
          laboratoryId: this.laboratoryId,
          startDate: this.startDate,
          endDate: this.endDate,
          contactId: this.filterContact ? this.filterContact.id : null,
          cytotechnicianId: this.filterCytotechnician ? this.filterCytotechnician.id : null,
          pathologistId: this.filterPathologist ? this.filterPathologist.id : null
        };
        const resFile = await resultsReportsServices.generate(filters, "xlsx");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          const filterPeriod = "_" + this.startDate + "_" + this.endDate;
          const filterContact = this.filterContact ? "_" + this.filterContact.lastName : "";
          await fileSaver.saveAs(resFile.data, this.$t("resultsReports.fileName") + filterPeriod + filterContact + ".xlsx");
        }
        this.exportLoading = false;
      } catch (err) {
        this.exportLoading = false;
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style src="@/views/Accounting/Reports/Reports.scss" lang="scss"></style>

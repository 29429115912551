<template>
  <div
    ref="mainContainer"
    class="kt-awaitingPayment-edit"
    :class="{'pb-4': !moduleModeProp}"
  >
    <!-- page header -->
    <div
      v-show="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div
      v-show="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- awaitingPayment form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            ref="laboratoryInput"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdState"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="awaitingPaymentData !== null ? awaitingPaymentData.laboratoryId : null"
            @input="laboratoryId = $event"
          />
        </b-col>
        <!-- paymentTypeId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="paymentTypesOptions && paymentTypesOptions.length"
            ref="paymentTypeInput"
            :labelProp="$t('payments.awaitingPayments.paymentType')"
            :optionsProp="paymentTypesOptions"
            valueFieldProp="id"
            textFieldProp="localisedName"

            :valueProp="paymentType !== null ? paymentType.id : null"
            :stateProp="paymentTypeState"
            :invalidFeedbackProp="paymentTypeInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="false"
            :initialValueProp="null"
            @input="paymentTypeInput($event)"
            @change="paymentTypeSave($event)"
          />
        </b-col>
      </b-row>

      <b-row>
        <!-- paymentDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.awaitingPayments.paymentDate')"
            :invalid-feedback="paymentDateInvalidFeedback"
            :state="paymentDateState"
          >
            <b-form-input
              v-model="paymentDate"
              type="date"
              trim
              :state="paymentDateState"
              @blur="paymentDateValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- accessNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.awaitingPayments.accessNumber')"
            :invalid-feedback="accessNumberInvalidFeedback"
            :state="accessNumberState"
            class="kt-accessNumberSearch__form-group"
          >
            <!-- right icons -->
            <div class="kt-accessNumberSearch__icon-wrapper">
              <b-icon
                v-show="accessNumberFound === true"
                class="kt-accessNumberSearch__icon"
                icon="check"
              ></b-icon>
            </div>
            <!-- input -->
            <b-form-input
              v-model="accessNumber"
              class="kt-accessNumberSearch__input"
              trim
              maxlength="50"
              :state="accessNumberState"
              @input="onAccessNumberInput"
              @blur="accessNumberValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- lastName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="lastName"
            :labelProp="$t('payments.awaitingPayments.lastName')"
            :stateProp="lastNameState"
            :invalidFeedbackProp="lastNameInvalidFeedback"
            :disabledProp="accessNumberFound === true"
            @blur="lastNameValidation = true;"
            @submit="submitSave"
          />
        </b-col>
        <!-- firstName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="firstName"
            :labelProp="$t('payments.awaitingPayments.firstName')"
            :stateProp="firstNameState"
            :invalidFeedbackProp="firstNameInvalidFeedback"
            :disabledProp="accessNumberFound === true"
            @blur="firstNameValidation = true"
            @submit="submitSave"
          />
        </b-col>
      </b-row>

      <b-row class="justify-content-center">
        <!-- warning : notInvoicedToPatient -->
        <b-col
          v-show="accessNumberFound && (!filesData || filesData.filter((v) => v.invoicedOrganisationId === null).length === 0)"
          cols="12"
          class="text-center"
        >
          <div class="kt-badge kt-badge--red kt-badge--lg mb-2">
            <b-icon
              icon="exclamation-triangle"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('payments.awaitingPaymentEdit.notInvoicedToPatient') }}</span></span>
          </div>
        </b-col>

        <!-- warning : accessNumberNotFound -->
        <b-col
          v-show="accessNumberFound === false"
          cols="12"
          class="text-center"
        >
          <div class="kt-badge kt-badge--yellow kt-badge--lg">
            <b-icon
              icon="exclamation-circle"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('payments.awaitingPaymentEdit.accessNumberNotFound') }}</span></span>
          </div>
        </b-col>
      </b-row>

      <!-- divider -->
      <div class="w-100 border-top mt-2 mb-2 pb-1"></div>

      <b-row>
        <!-- payerName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            ref="payerNameInput"
            v-model="payerName"
            :labelProp="$t('payments.awaitingPayments.payerName')"
            :stateProp="payerNameState"
            :invalidFeedbackProp="payerNameInvalidFeedback"
            @blur="payerNameValidation = true;"
            @submit="submitSave"
          />
        </b-col>
        <!-- bank -->
        <b-col
          v-show="paymentType && paymentType.name !== 'cash'"
          cols="12"
          md="4"
        >
          <InputSearch
            searchedTableProp="banks"
            searchedFieldProp="code"
            :searchedLaboratoriesProp="[]"
            :returnFieldsArrayProp="['id', 'code', 'name']"
            suggestionsKeyProp="id"
            :valueProp="bank"
            :displayedValueProp="bank ? bank.code : ''"
            :suggestionFormatProp="(suggestion) => {
              return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
            }"
            :searchMinLengthProp="1"

            :labelProp="$t('payments.awaitingPayments.bank')"
            :stateProp="bankState"
            :invalidFeedbackProp="bankInvalidFeedback"

            @onUpdateParent="bank = $event; bankId = (bank ? bank.id : null);"
            @onActivateValidation="bankValidation = true"
            @onDisableValidation="bankValidation = false;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>
        <!-- checkNumber -->
        <b-col
          v-show="paymentType && paymentType.name === 'check'"
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.awaitingPayments.checkNumber')"
            :invalid-feedback="checkNumberInvalidFeedback"
            :state="checkNumberState"
          >
            <b-form-input
              v-model="checkNumber"
              trim
              maxlength="50"
              :state="checkNumberState"
              @blur="checkNumberValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- transactionId -->
        <b-col
          v-show="paymentType && (paymentType.name === 'creditCard' || paymentType.name === 'transfer')"
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('payments.awaitingPayments.transactionId')"
            :invalid-feedback="transactionIdInvalidFeedback"
            :state="transactionIdState"
          >
            <b-form-input
              v-model="transactionId"
              trim
              maxlength="64"
              :state="transactionIdState"
              @blur="transactionIdValidation = true;"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- paid -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputPrice
            :labelProp="$t('payments.awaitingPayments.paid')"
            :priceProp="paid"
            :stateProp="paidState"
            :invalidFeedbackProp="paidInvalidFeedback"
            @onUpdateParent="paid = $event"
            @onActivateValidation="paidValidation = $event"
            @submit="submitSave"
          ></InputPrice>
        </b-col>
      </b-row>

      <!-- contextual : close file -->
      <div v-show="!editMode && filesData && filesData.length && (compensationAmountAfterSaving !== 0 || totalCompensations) && !areAllFilesInvoiced">
        <div class="kt-badge kt-badge--lg kt-badge--light-gray">
          <b-icon
            icon="info-circle"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text">{{ $t('payments.awaitingPaymentEdit.hasUninvoicedFile') }}</span>
        </div>
      </div>
      <div v-show="!editMode && filesData && filesData.length && (compensationAmountAfterSaving !== 0 || totalCompensations) && hasAwaitingPayments">
        <div class="kt-badge kt-badge--lg kt-badge--light-gray">
          <b-icon
            icon="info-circle"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text">{{ $t('payments.awaitingPaymentEdit.hasAwaitingPayments') }}</span>
        </div>
      </div>
      <div v-show="!editMode && filesData && filesData.length && compensationAmountAfterSaving !== 0 && areAllFilesInvoiced && !hasAwaitingPayments && !totalCompensations">
        <b-button
          class="mb-3"
          variant="outline-secondary"
          @click="closeFileAfterSaving = !closeFileAfterSaving"
        >
          <!-- checkbox-display-only -->
          <div class="kt-checkbox-display-only-cont d-inline-block align-middle">
            <span
              class="kt-checkbox-display-only"
              :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (closeFileAfterSaving ? ' kt-checkbox-display-only--checked' : '')"
            >
              <span class="kt-checkbox-display-only__cross"></span>
            </span>
          </div>
          <span
            class="font-weight-semi-bold"
            style="margin-left: 12px;"
          >
            <span class="align-middle">{{ $tc('payments.awaitingPaymentEdit.closeFileAfterSaving', Math.max(filesData.length - 1, 0), {complementaries: Math.max(filesData.length - 1, 0)}) + ' : ' }}</span>
            <span
              v-show="compensationAmountAfterSaving > 0"
              class="text-success align-middle"
            >{{ $t('payments.awaitingPaymentEdit.gainAfterSaving', {amount: $n(compensationAmountAfterSaving, "currency")}) }}</span>
            <span
              v-show="compensationAmountAfterSaving < 0"
              class="text-danger align-middle"
            >{{ $t('payments.awaitingPaymentEdit.lossAfterSaving', {amount: $n(compensationAmountAfterSaving, "currency")}) }}</span>
          </span>
        </b-button>
      </div>
      <div v-show="!editMode && filesData && filesData.length && areAllFilesInvoiced && !hasAwaitingPayments && totalCompensations">
        <div class="kt-badge kt-badge--lg kt-badge--light-gray">
          <b-icon
            icon="info-circle"
            class="kt-badge__icon"
          ></b-icon>
          <div class="kt-badge__text">
            <div>
              {{ $t('payments.awaitingPaymentEdit.hasCompensation') }}
              <span
                v-show="totalCompensations < 0"
                class="font-weight-semi-bold text-success"
              >{{ $t('payments.awaitingPaymentEdit.gainAfterSaving', {amount: $n(-totalCompensations, "currency")}) }}</span>
              <span
                v-show="totalCompensations > 0"
                class="font-weight-semi-bold text-danger"
              >{{ $t('payments.awaitingPaymentEdit.lossAfterSaving', {amount: $n(-totalCompensations, "currency")}) }}</span>
            </div>
            <div v-show="totalCompensations !== compensationAmountAfterSaving">
              {{ $t('payments.awaitingPaymentEdit.updateCompensation') }}
              <span
                v-show="-compensationAmountAfterSaving <= 0"
                class="font-weight-semi-bold text-success"
              >{{ compensationAmountAfterSaving === 0 ? $n(0, "currency") : $t('payments.awaitingPaymentEdit.gainAfterSaving', {amount: $n(compensationAmountAfterSaving, "currency")}) }}</span>
              <span
                v-show="-compensationAmountAfterSaving > 0"
                class="font-weight-semi-bold text-danger"
              >{{ $t('payments.awaitingPaymentEdit.lossAfterSaving', {amount: $n(compensationAmountAfterSaving, "currency")}) }}</span>
            </div>
            <div>
              <b-link
                :variant="$systemSettings.theme"
                @click.prevent="uncloseFiles"
              >
                {{ $t("payments.awaitingPaymentEdit.deleteCompensation") }}
              </b-link>
            </div>
          </div>
        </div>
      </div>

      <b-row class="justify-content-center">
        <!-- filesData List -->
        <!-- <b-col
          v-if="filesData && filesData.length"
          cols="12"
          md="7"
        >
          <div class="kt-item-list kt-item-list--full-width kt-item-list--shadow">
            <div
              class="kt-item-list__item-wrapper"
              style="margin-bottom: -12px; margin-top: 2px;"
            >
              <div
                class="kt-item-list__item"
                style="padding: 16px 20px; margin-bottom: 16px;"
              >
                <div
                  v-for="(file, index) in filesData"
                  :key="file.id"
                  :style="{'margin-bottom': index !== (filesData.length - 1) ? '6px' : ''}"
                >
                  <b-row class="align-items-center">
                    <b-col
                      cols="12"
                      md="5"
                    >
                      <div style="line-height: 1.3;">
                        <div class="font-weight-semi-bold">
                          {{ !file.parentFileId ? $t('fileChooseFromIds.parentFile') : $t('fileChooseFromIds.complementaryFile') }}
                        </div>
                        <div
                          style="font-size: 15px;"
                          class="text-dark"
                        >
                          {{ capitalizeFirstLetter(getFormattedAlphabeticalDateTimeFromTimestamp(file.creationDate, $systemSettings.locale)) }}
                        </div>
                      </div>
                    </b-col> -->
        <!-- statuses -->
        <!-- <b-col
                      cols="10"
                      md="6"
                    >
                      <StatusBadge
                        v-if="file.filingStatus"
                        typeProp="filingStatus"
                        :statusNameProp="file.filingStatus.name"
                        isHiddenEmptyProp
                      />
                      <StatusBadge
                        v-if="file.medicalStatus"
                        typeProp="medicalStatus"
                        :statusNameProp="file.medicalStatus.name"
                        isHiddenEmptyProp
                        class="ml-1"
                      />
                      <StatusBadge
                        v-if="file.accountingStatus"
                        typeProp="accountingStatus"
                        :statusNameProp="file.accountingStatus.name"
                        isHiddenEmptyProp
                        class="ml-1"
                      />
                    </b-col> -->
        <!-- view button -->
        <!-- <b-col
                      cols="2"
                      md="1"
                      class="text-right"
                    >
                      <b-button
                        :variant="$systemSettings.theme"
                        size="sm"
                        @click="() => {
                          displayedFile = file;
                          $bvModal.show('fileViewModal' + _uid);
                        }"
                      >
                        <b-icon
                          icon="eye"
                          class="mr-0"
                        ></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col> -->

        <!-- accountingHistory -->
        <b-col
          v-if="filesData && filesData.length"
          cols="12"
          class="mt-3 pt-1"
        >
          <!-- divider -->
          <div class="kt-border-top-strong mb-3"></div>

          <AccountingHistory
            v-if="parentFileData !== null"
            :fileIdProp="parentFileData.id"
            :fileDataProp="parentFileData"
            hideCloseFileProp
            noFocusProp
            @loaded="($event) => {
              historyDataFiles = $event.historyDataFiles;
              filesBalance = $event.balance;
              totalCompensations = $event.totalCompensations;
              const filteredIds = $event.awaitingPaymentIds.filter(v => v !== awaitingPaymentId);
              hasAwaitingPayments = filteredIds.length > 0;
              if (!editModeProp) {
                paid = $event.balance;
                $nextTick(() => {
                  $nextTick(() => {
                    $nextTick(() => {
                      $refs.payerNameInput.focus();
                    });
                  });
                });
              }
            }"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>
      </b-row>

      <!-- divider -->
      <div
        class="mb-2"
        :class="filesData && filesData.length ? 'mt-2 kt-border-top-strong' : 'mt-3 border-top'"
      ></div>

      <b-row>
        <!-- comment -->
        <b-col
          cols="12"
        >
          <b-form-group
            :label="$t('payments.awaitingPayments.comment')"
            :invalid-feedback="commentInvalidFeedback"
            :state="commentState"
          >
            <b-form-input
              ref="commentInput"
              v-model="comment"
              trim
              maxlength="256"
              :state="commentState"
              @blur="commentValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- info badges -->
        <b-col
          v-if="transactionId"
          cols="12"
          class="mb-3"
        >
          <!-- transactionId -->
          <div
            class="kt-badge kt-badge--light-gray kt-badge--lg"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('payments.awaitingPayments.transactionId') + ' : ' }}</span>{{ transactionId }}</span>
          </div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
import InputPrice from "@shared/views/Helpers/InputPrice";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import AccountingHistory from "@/views/Office/Files/AccountingHistory";
// services
import compensationsServices from "@/services/API/compensationsServices";
import commonServices from "@shared/services/API/commonServices";
import filesServices from "@/services/API/filesServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import date from "@shared/services/UI/date";
import string from "@shared/services/UI/string";
import window from "@shared/services/UI/window";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearch, InputTextFormatted, InputPrice, SmartSelectInput, AccountingHistory },
  mixins: [userRights, error, price, date, string, window],
  props: {
    initialValuesProp: {
      type: Object,
      default: function() {
        return {};
      }
    },
    awaitingPaymentIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      awaitingPaymentId: this.awaitingPaymentIdProp,
      awaitingPaymentData: null,
      // form variables
      awaitingPaymentUserId: null,
      laboratoryId: this.initialValuesProp.laboratoryId || null,
      paymentType: this.initialValuesProp.paymentType || null,
      paymentDate: this.initialValuesProp.paymentDate || "",
      accessNumber: this.initialValuesProp.accessNumber || "",
      lastName: this.initialValuesProp.lastName || "",
      firstName: this.initialValuesProp.firstName || "",
      bank: this.initialValuesProp.bank || null,
      bankId: this.initialValuesProp.bank ? this.initialValuesProp.bank.Id : null,
      checkNumber: this.initialValuesProp.checkNumber || "",
      transactionId: this.initialValuesProp.transactionId || "",
      paid: 0,
      payerName: this.initialValuesProp.payerName || "",
      comment: this.initialValuesProp.comment || "",
      closeFileAfterSaving: false,
      // file search
      loading: false,
      accessNumberFound: null,
      filesData: [],
      historyDataFiles: [],
      filesBalance: 0,
      totalCompensations: 0,
      hasAwaitingPayments: false,
      parentFileData: null,
      displayedFile: null,
      // is validation active
      laboratoryIdValidation: false,
      paymentTypeValidation: false,
      paymentDateValidation: false,
      accessNumberValidation: false,
      lastNameValidation: false,
      firstNameValidation: false,
      bankValidation: false,
      checkNumberValidation: false,
      transactionIdValidation: false,
      paidValidation: false,
      payerNameValidation: false,
      commentValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    paymentTypeState: function() {
      if (!this.paymentTypeValidation) return null;
      return this.paymentType !== null ? null : false;
    },
    paymentTypeInvalidFeedback: function() {
      return this.paymentTypeState === false ? this.$t("validationRules.required") : "";
    },
    paymentDateState: function() {
      if (!this.paymentDateValidation) return null;
      return this.paymentDate !== "" && this.paymentDate !== null ? null : false;
    },
    paymentDateInvalidFeedback: function() {
      return this.paymentDateState === false ? this.$t("validationRules.required") : "";
    },
    accessNumberState: function() {
      if (!this.accessNumberValidation) return null;
      return this.accessNumber !== "" ? null : false;
    },
    accessNumberInvalidFeedback: function() {
      return this.accessNumberState === false ? this.$t("validationRules.required") : "";
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      return this.lastName !== "" ? null : false;
    },
    lastNameInvalidFeedback: function() {
      return this.lastNameState === false ? this.$t("validationRules.required") : "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      return this.firstName !== "" ? null : false;
    },
    firstNameInvalidFeedback: function() {
      return this.firstNameState === false ? this.$t("validationRules.required") : "";
    },
    bankState: function() {
      if (!this.bankValidation) return null;
      if (this.paymentType && this.paymentType.name === "check" && this.bank === null) return false;
      return null;
    },
    bankInvalidFeedback: function() {
      return this.checkNumberState === false ? this.$t("validationRules.required") : "";
    },
    checkNumberState: function() {
      if (!this.checkNumberValidation) return null;
      if (this.paymentType && this.paymentType.name === "check" && !this.checkNumber) return false;
      return null;
    },
    checkNumberInvalidFeedback: function() {
      return this.checkNumberState === false ? this.$t("validationRules.required") : "";
    },
    transactionIdState: function() {
      return null;
    },
    transactionIdInvalidFeedback: function() {
      return this.transactionIdState === false ? this.$t("validationRules.required") : "";
    },
    paidState: function() {
      if (!this.paidValidation) return null;
      if (!this.paid) return false;
      return typeof this.paid === "number" || /^\d+\.\d{2}$/g.test(this.paid) ? null : false;
    },
    paidInvalidFeedback: function() {
      if (this.paidState === null) return "";
      if (!this.paid) return this.$t("validationRules.required");
      return this.$t("validationRules.invalidPrice");
    },
    payerNameState: function() {
      return null;
    },
    payerNameInvalidFeedback: function() {
      return "";
    },
    commentState: function() {
      return null;
    },
    commentInvalidFeedback: function() {
      return "";
    },

    // helpers
    areAllFilesInvoiced: function() {
      return !!this.filesData && !this.filesData.some(v => v.accountingStatus.name === "created" || v.accountingStatus.name === "canceled");
    },
    compensationAmountAfterSaving: function() {
      return this.parentFileData ? -(this.filesBalance - this.paid) : 0;
    },
    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("payments.awaitingPaymentEdit.pageTitle") : this.$t("payments.awaitingPaymentNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("payments.awaitingPaymentEdit.submitText") : this.$t("payments.awaitingPaymentNew.submitText");
    },

    // options
    paymentTypesOptions: function() {
      return this.$systemSettings.paymentTypes || [];
    }
  },
  watch: {
    editModeProp: async function(newVal, oldVal) {
      if (newVal !== oldVal) {
        // import data
        if (this.editModeProp === false) {
          this.clearData();
        } else {
          await this.importData();
        }
      }
    }
  },
  async mounted() {
    // import data
    await this.importData();

    if (this.editModeProp === false) {
      // setup date
      this.paymentDate = this.getIsoDate();
      // setup paymentType
      if (this.$userSettings.savedBehaviors.accounting.paymentType) {
        this.paymentType = this.$userSettings.savedBehaviors.accounting.paymentType;
      } else {
        this.paymentType = this.paymentTypesOptions.find(v => v.name === "check") || null;
      }
    } else {
      this.importFileByAccessNumber();
    }

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.laboratoryInput) {
        this.$refs.laboratoryInput.focusFirstElement();
      } else if (this.$refs.paymentTypeInput) {
        this.$refs.paymentTypeInput.focusFirstElement();
      }
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get awaitingPayment
          const resServices = await commonServices.get("awaitingPayments", this.awaitingPaymentId);
          this.awaitingPaymentData = resServices.data;
          // import data
          this.awaitingPaymentUserId = resServices.data.awaitingPaymentUserId;
          this.laboratoryId = resServices.data.laboratoryId;
          this.paymentType = resServices.data.paymentType;
          this.paymentDate = resServices.data.paymentDate;
          this.accessNumber = resServices.data.accessNumber;
          this.lastName = resServices.data.lastName;
          this.firstName = resServices.data.firstName;
          this.bank = resServices.data.bank;
          this.bankId = resServices.data.bankId;
          this.checkNumber = resServices.data.checkNumber;
          this.transactionId = resServices.data.transactionId;
          this.paid = this.importFormatPrice(resServices.data.paid);
          this.payerName = resServices.data.payerName;
          this.comment = resServices.data.comment;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    clearData() {
      if (!this.editModeProp) {
        //await this.$nextTick();
        this.resetValidation();
        this.awaitingPaymentData = null;
        // this.laboratoryId = null;
        this.paymentDate = this.getIsoDate();
        this.accessNumber = "";
        this.accessNumberFound = null;
        this.filesData = [];
        this.historyDataFiles = [];
        this.parentFileData = null;
        this.filesBalance = 0;
        this.totalCompensations = 0;
        this.hasAwaitingPayments = false;
        this.closeFileAfterSaving = false;
        this.lastName = "";
        this.firstName = "";
        this.bank = null;
        this.bankId = null;
        this.checkNumber = "";
        this.transactionId = "";
        this.paid = 0;
        this.payerName = "";
        this.comment = "";
      }
    },
    resetValidation() {
      this.laboratoryIdValidation = false;
      this.paymentTypeIdValidation = false;
      this.paymentDateValidation = false;
      this.accessNumberValidation = false;
      this.lastNameValidation = false;
      this.firstNameValidation = false;
      this.bankValidation = false;
      this.checkNumberValidation = false;
      this.transactionIdValidation = false;
      this.paidValidation = false;
      this.payerNameValidation = false;
      this.commentValidation = false;
    },

    // paymentType
    paymentTypeInput(paymentTypeId) {
      for (const paymentTypesOption of this.paymentTypesOptions) {
        if (paymentTypesOption.id === paymentTypeId) {
          this.paymentType = paymentTypesOption;
          break;
        }
      }
    },
    paymentTypeSave(paymentTypeId) {
      for (const paymentTypesOption of this.paymentTypesOptions) {
        if (paymentTypesOption.id === paymentTypeId) {
          this.$userSettings.savedBehaviors.accounting.paymentType = paymentTypesOption;
          break;
        }
      }
    },

    // import file by accessNumber
    async importFileByAccessNumber() {
      try {
        if (this.accessNumber.length) {
          this.loading = true;
          this.closeFileAfterSaving = false;

          const res = await filesServices.getAllByAccessNumber(this.accessNumber);
          if (res.data && res.data.length > 0) {
            this.accessNumberFound = true;

            this.filesData = res.data;
            // auto-fill firstName / lastName
            for (const fileData of this.filesData) {
              if (!fileData.parentFileId) {
                this.firstName = fileData.patient.firstName;
                this.lastName = fileData.patient.lastName + (fileData.patient.maidenName ? " " + fileData.patient.maidenName : "");
                break;
              }
            }

            // setup parentFileData
            const parentFile = this.filesData.find((v) => { return !v.parentFileId; });
            parentFile.childrenFiles = this.filesData.filter((v) => { return v.parentFileId; });
            this.parentFileData = parentFile;
          } else {
            this.accessNumberFound = false;
            this.filesData = [];
            this.historyDataFiles = [];
            this.parentFileData = null;
            this.filesBalance = 0;
            this.totalCompensations = 0;
            this.hasAwaitingPayments = false;
          }

          this.loading = false;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // import file by accessNumber (with a delay)
    onAccessNumberInput() {
      this.accessNumberFound = null;
      this.filesData = [];
      this.historyDataFiles = [];
      this.parentFileData = null;
      this.filesBalance = 0;
      this.totalCompensations = 0;
      this.hasAwaitingPayments = false;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.importFileByAccessNumber();
      }, 450);
    },

    // navigate to the list page
    onGoBack() {
      navigate("accounting/payments/patientPayments");
    },

    // submit functions
    validateForm() {
      this.laboratoryIdValidation = true;
      this.paymentTypeIdValidation = true;
      this.paymentDateValidation = true;
      this.accessNumberValidation = true;
      this.lastNameValidation = true;
      this.firstNameValidation = true;
      this.bankValidation = true;
      this.checkNumberValidation = true;
      this.transactionIdValidation = true;
      this.paidValidation = true;
      this.payerNameValidation = true;
      this.commentValidation = true;

      return !(
        this.laboratoryIdState === false ||
        this.paymentTypeIdState === false ||
        this.paymentDateState === false ||
        this.accessNumberState === false ||
        this.lastNameState === false ||
        this.firstNameState === false ||
        this.bankState === false ||
        this.checkNumberState === false ||
        this.transactionIdState === false ||
        this.paidState === false ||
        this.payerNameState === false ||
        this.commentState === false
      );
    },
    async saveAwaitingPayment() {
      try {
        const form = {
          laboratoryId: this.laboratoryId,
          paymentTypeId: this.paymentType.id,
          paymentDate: this.paymentDate || null,
          accessNumber: this.accessNumber,
          firstName: this.firstName,
          lastName: this.lastName,
          bankId: (this.paymentType.name !== "cash" ? this.bankId : null),
          checkNumber: (this.paymentType.name === "check" ? this.checkNumber : ""),
          transactionId: (this.paymentType.name === "creditCard" || this.paymentType.name === "transfer" ? this.transactionId : ""),
          paid: this.exportFormatPrice(this.paid),
          payerName: this.payerName,
          comment: this.comment
        };
        if (this.editMode) {
          const res = await commonServices.put("awaitingPayments", form, this.awaitingPaymentId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("payments.awaitingPaymentEdit.notifications.editedTitle"),
              message: this.$t("payments.awaitingPaymentEdit.notifications.editedText")
            });
            // go back
            if (!this.moduleModeProp) {
              navigate("accounting/payments/patientPayments");
            } else {
              this.$emit("awaitingPaymentSaved");
            }
          } else {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("payments.awaitingPaymentEdit.notifications.editionErrorTitle"),
              message: this.$t("payments.awaitingPaymentEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const resAwaiting = await commonServices.post("awaitingPayments", form);
          if (resAwaiting.data.id) {
            // close files
            if ((this.totalCompensations || this.closeFileAfterSaving) && this.filesData && this.areAllFilesInvoiced && !this.hasAwaitingPayments) {
              for (const file of this.filesData) {
                if (await this.closeFile(file) === false) {
                  this.$emit("alert", "danger", {
                    title: this.$t("payments.awaitingPaymentNew.notifications.createdButNotClosedErrorTitle"),
                    message: this.$t("payments.awaitingPaymentNew.notifications.createdButNotClosedErrorText")
                  });
                  this.$emit("createdAndGoNext", true);
                  this.focusFirstElement();
                  this.clearData();
                  this.scrollToElement(this.$refs.mainContainer, { behavior: "smooth" });
                  return false;
                }
              }
            }
            // success
            if (resAwaiting.data.reconciliationResult) {
              this.$emit("alert", "success", {
                title: this.$t("payments.awaitingPaymentNew.notifications.addedAndReconciledTitle"),
                message: this.$t("payments.awaitingPaymentNew.notifications.addedAndReconciledText")
              });
            } else if (this.filesData.length === 0) {
              this.$emit("alert", "warning", {
                title: this.$t("payments.awaitingPaymentNew.notifications.addedAndFileNotFoundTitle"),
                message: this.$t("payments.awaitingPaymentNew.notifications.addedAndFileNotFoundText")
              });
            } else {
              this.$emit("alert", "warning", {
                title: this.$t("payments.awaitingPaymentNew.notifications.addedAndFileNotInvoicedTitle"),
                message: this.$t("payments.awaitingPaymentNew.notifications.addedAndFileNotInvoicedText")
              });
            }
            this.$emit("createdAndGoNext", true);
            this.focusFirstElement();
            this.clearData();
            this.scrollToElement(this.$refs.mainContainer, { behavior: "smooth" });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("payments.awaitingPaymentNew.notifications.additionErrorTitle"),
              message: this.$t("payments.awaitingPaymentNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveAwaitingPayment();
    },

    async closeFile(file) {
      try {
        const form = { fileId: file.id };
        const res = await commonServices.post("compensations", form);

        return Boolean(res.data.id || res.data.id === 0);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async uncloseFiles() {
      try {
        // confirmation pop up
        const result = await this.$bvModal.msgBoxConfirm(this.$t("compensationDelete.text"), {
          title: this.$t("compensationDelete.title"),
          okVariant: "danger",
          okTitle: this.$t("compensationDelete.ok"),
          cancelTitle: this.$t("compensationDelete.cancel"),
          centered: true
        });
        // delete
        if (result) {
          for (const file of this.historyDataFiles) {
            if (file.compensationAmount === 0) continue;

            const res = await compensationsServices.deleteByFileId(file.id);
            if (res.data !== true) {
              this.$emit("alert", "danger", {
                title: this.$t("compensationDelete.notifications.deletedErrorTitle"),
                message: this.$t("compensationDelete.notifications.deletedErrorText")
              });
              this.refreshAccountingHistory();
              return false;
            }
          }
          // success
          this.$emit("alert", "success", {
            title: this.$t("compensationDelete.notifications.deletedTitle"),
            message: this.$t("compensationDelete.notifications.deletedText")
          });
          this.refreshAccountingHistory();
          return true;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    refreshAccountingHistory() {
      const parentFileData = this.parentFileData;
      const paid = this.paid;
      this.parentFileData = null;
      this.$nextTick(() => {
        this.parentFileData = parentFileData;
        this.paid = paid;
      });
    }
  }
};
</script>

<style scoped src="./AwaitingPaymentEdit.scss" lang="scss"></style>

<template>
  <div
    class="kt-input-image"
    :class="'kt-input-image--' + $systemSettings.theme"
  >
    <b-form-group
      class="mb-0"
      :label="labelProp"
      :invalid-feedback="imageError !== '' ? imageError : invalidFeedbackProp"
      :state="imageError !== '' ? false : stateProp"
    >
      <div
        v-show="imageFile !== null"
        class="kt-input-image__img-container"
      >
        <b-img
          class="kt-input-image__img"
          fluid
          :src="imageUrl"
          :alt="(imageFile === null || imageUrl === null) ? 'No image found' : altProp"
        ></b-img>
      </div>
      <b-button
        v-show="imageFile !== null"
        class="kt-btn-transparent kt-input-image__clear-btn"
        size="sm"
        :variant="$systemSettings.theme"
        @click="onFileClear"
      >
        {{ $t('general.delete') }}
      </b-button>
      <b-form-file
        v-show="imageFile === null"
        v-model="imageFile"
        :accept="acceptProp"
        :placeholder="$t('general.fileInputPlaceholder')"
        :drop-placeholder="$t('general.fileInputDropPlaceholder')"
        :state="imageError !== '' ? false : stateProp"
        @input="onFileInput"
      ></b-form-file>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    labelProp: {
      type: String,
      default: ""
    },
    altProp: {
      type: String,
      default: ""
    },
    imageFileProp: {
      type: File,
      default: null
    },
    imageUrlProp: {
      type: String,
      default: null
    },
    // in byte
    maxSizeProp: {
      type: Number,
      default: function() {
        return 1024 * 1024;
      }
    },
    acceptProp: {
      type: String,
      default: ".jpg, .png"
    },
    stateProp: {
      type: Boolean
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imageFile: this.imageFileProp || null,
      imageUrl: this.imageUrlProp || null,
      imageError: ""
    };
  },
  watch: {
    imageFileProp: {
      handler: function(value) {
        this.imageFile = value;
      },
      deep: true
    },
    imageUrlProp: {
      handler: function(value) {
        this.imageUrl = value;
      },
      deep: true
    }
  },
  methods: {
    onFileInput() {
      if (this.imageFile) {
        const acceptedFormats = this.acceptProp.replace(/\s+/g, "").split(",");
        let fileFormat = this.imageFile.name.split(".");
        fileFormat = "." + fileFormat[fileFormat.length - 1].toLowerCase();

        if (!acceptedFormats.includes(fileFormat)) {
          this.$emit("updateImage", null);
          this.$emit("updateUrl", null);
          this.imageError = this.$t("validationRules.imageFileFormatNotAccepted", { acceptedFormats: this.acceptProp });
        } else if (this.imageFile.size >= this.maxSizeProp) {
          this.$emit("updateImage", null);
          this.$emit("updateUrl", null);
          this.imageError = this.$t("validationRules.imageFileTooLarge", { maxSizeInMo: this.maxSizeProp / 1024 / 1024 });
        } else {
          this.$emit("updateImage", this.imageFile);
          this.$emit("updateUrl", window.URL.createObjectURL(this.imageFile));
          this.imageError = "";
        }
      } else {
        this.$emit("updateImage", null);
        this.$emit("updateUrl", null);
        this.imageError = "";
      }
    },
    onFileClear() {
      this.$emit("updateImage", null);
      this.$emit("updateUrl", null);
      this.imageError = "";
    }
  }
};
</script>

<style scoped src="./InputImage.scss" lang="scss"></style>

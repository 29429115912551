<template>
  <div class="kt-receivedPayments mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("reports.receivedPayments.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- search form -->
    <ReportsSearchForm
      :laboratoryId="laboratoryId"
      :organisationType="organisationType"
      :organisation="organisation"
      :startDate="startDate"
      :endDate="endDate"
      :displayBalanceDateProp="false"

      :paymentTypesOptionsProp="paymentTypesOptions"
      displayPaymentTypeProp
      :paymentTypeId="paymentTypeId"
      displayAwaitingPaymentUserProp
      :awaitingPaymentUser="awaitingPaymentUser"
      displayAllOrganisationsOptionProp

      :isValidationActive="searchFormValidation"

      @inputLaboratoryId="laboratoryId = $event; searchHasChanged = true;"
      @InputOrganisationType="organisationType = $event; searchHasChanged = true;"
      @inputOrganisation="organisation = $event; searchHasChanged = true;"
      @inputStartDate="startDate = $event; searchHasChanged = true;"
      @inputEndDate="endDate = $event; searchHasChanged = true;"
      @paymentTypeId="paymentTypeId = $event; searchHasChanged = true;"
      @awaitingPaymentUser="awaitingPaymentUser = $event; searchHasChanged = true;"

      @updateState="searchFormState = $event"
      @submit="onPageInput"
    />

    <!-- divider -->
    <div class="w-100 border-top mt-4 mb-4"></div>

    <!-- result (totals) -->
    <div
      v-if="searchedLaunched"
      class="kt-item-list kt-item-list--full-width kt-item-list--shadow"
    >
      <div class="kt-item-list__item-wrapper">
        <div
          class="kt-item-list__item mb-0"
          style="padding: 20px;"
        >
          <div class="d-flex justify-content-around align-items-center text-center">
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.receivedPayments.paidTotal") }}</span>
              <br /><span>{{ $n((paidTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.receivedPayments.reconciliation") }}</span>
              <span class="kt-reports__total-progress">
                <ProgressBar
                  :valueProp="importFormatPrice(reconciledPaidTotal)"
                  :totalProp="importFormatPrice(paidTotal)"
                />
              </span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.receivedPayments.refundsTotal") }}</span>
              <br /><span>{{ $n((refundsTotal || 0) / 100, "currency") }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.receivedPayments.reconciliation") }}</span>
              <span class="kt-reports__total-progress">
                <ProgressBar
                  :valueProp="importFormatPrice(reconciledRefundsTotal)"
                  :totalProp="importFormatPrice(refundsTotal)"
                />
              </span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("reports.receivedPayments.receivedTotal") }}</span>
              <br /><span>{{ $n(totalPaymentBalance / 100, "currency") }}</span>
            </h4>

            <div class="ml-3">
              <b-button
                :variant="$systemSettings.theme"
                :disabled="searchHasChanged"
                @click="onExport('xlsx')"
              >
                {{ $t("general.downloadXlsx") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub-nav -->
    <b-nav
      v-show="lastSearchOrganisationType === null || lastSearchOrganisationType === 'all'"
      pills
      class="kt-sub-nav mt-2 mb-4"
      :class="'kt-sub-nav--' + $systemSettings.theme"
    >
      <!-- patient -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="currentActiveMode === null"
        @click="currentActiveMode = null"
      >
        {{ $t("reports.receivedPayments.subnavPatientPayment") }}
      </b-nav-item>
      <!-- unreconciled -->
      <b-nav-item
        class="kt-sub-nav__link"
        :active="currentActiveMode === 'unreconciled'"
        @click="currentActiveMode = 'unreconciled'"
      >
        {{ $t("reports.receivedPayments.subnavUnreconciled") }}
      </b-nav-item>
      <!-- healthCare -->
      <b-nav-item
        v-show="lastSearchOrganisationType === 'all'"
        class="kt-sub-nav__link"
        :active="currentActiveMode === 'healthCare'"
        @click="currentActiveMode = 'healthCare'"
      >
        {{ $t("reports.receivedPayments.subnavHealthCare") }}
      </b-nav-item>
      <!-- organisation -->
      <b-nav-item
        v-show="lastSearchOrganisationType === 'all'"
        class="kt-sub-nav__link"
        :active="currentActiveMode === 'organisation'"
        @click="currentActiveMode = 'organisation'"
      >
        {{ $t("reports.receivedPayments.subnavOrganisation") }}
      </b-nav-item>
    </b-nav>

    <!-- patient table -->
    <b-table
      v-show="currentActiveMode === null || currentActiveMode === 'unreconciled'"
      class="mt-4 mb-0 kt-table"
      hover
      :fields="patientPaymentsFieldsFormatted"
      :items="currentActiveMode === null ? patientPayments : unreconciledPayments"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter PaymentTypeId -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter PaymentDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterPaymentDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterPaymentDate !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterPaymentDate')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter PayerName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterPayerName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterPayerName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterPayerName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Bank -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="banks"
              searchedFieldProp="code"
              :searchedLaboratoriesProp="[]"
              :returnFieldsArrayProp="['id', 'code', 'name']"
              suggestionsKeyProp="id"
              :searchMinLengthProp="1"

              :valueProp="filterBank"
              :displayedValueProp="filterBank ? filterBank.code : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
              }"
              noWrapProp

              @onUpdateParent="onBankChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter CheckNumber -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterCheckNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterCheckNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCheckNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter TransactionId -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterTransactionId"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterTransactionId !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterTransactionId')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter comment -->
          <b-th
            v-show="currentActiveMode === 'unreconciled'"
            class="kt-table__th-search"
          >
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- accessNumbers -->
      <template v-slot:cell(accessNumbers)="data">
        <div v-if="data.item.filePayments">
          <div
            v-for="filePayment in data.item.filePayments"
            :key="filePayment.id"
          >
            <span>{{ (data.item.filePayments.length > 1 ? '• ' : '') + filePayment.file.accessNumber }}</span>
            <!-- complementary -->
            <span
              v-if="filePayment.file.parentFileId"
              class="kt-complementary-icon"
            >
              <b-icon icon="back"></b-icon>
            </span>
            <span v-if="data.item.filePayments.filter((v) => v.paid !== data.item.paid).length">
              {{ filePayment.paid ? '(' + $n(importFormatPrice(filePayment.paid), "currency") + ')' : '' }}
            </span>
          </div>
        </div>
        <div v-else>
          {{ data.item.accessNumber }}
        </div>
      </template>
      <!-- lastName -->
      <template v-slot:cell(lastName)="data">
        <div v-if="data.item.filePayments">
          <div
            v-for="filePayment in data.item.filePayments"
            :key="filePayment.id"
          >
            {{ (data.item.filePayments.length > 1 ? '• ' : '') + (filePayment.file.patient ? filePayment.file.patient.lastName : '') }}
          </div>
        </div>
        <div v-else>
          {{ data.item.lastName }}
        </div>
      </template>
      <!-- firstName -->
      <template v-slot:cell(firstName)="data">
        <div v-if="data.item.filePayments">
          <div
            v-for="filePayment in data.item.filePayments"
            :key="filePayment.id"
          >
            {{ (data.item.filePayments.length > 1 ? '• ' : '') + (filePayment.file.patient ? filePayment.file.patient.firstName : '') }}
          </div>
        </div>
        <div v-else>
          {{ data.item.firstName }}
        </div>
      </template>
      <!-- comment -->
      <template v-slot:cell(comment)="data">
        <div v-if="data.item.comment">
          <b-button
            :id="'comment_' + data.item.id + '_' + _uid"
            class="btn-icon position-relative"
            variant="light"
            :disabled="!data.item.comment"
          >
            <b-icon icon="chat-left-dots"></b-icon>
            <span class="kt-count-bubble kt-count-bubble--corner"><span class="kt-count-bubble__number">{{ '1' + '' }}</span></span>
          </b-button>
          <!-- popovers -->
          <b-popover
            :target="'comment_' + data.item.id + '_' + _uid"
            triggers="hover focus"
            placement="bottomleft"
          >
            {{ data.item.comment }}
          </b-popover>
        </div>
      </template>

      <!-- actions -->
      <template v-slot:cell(actionButtons)="data">
        <div style="width: 39px;">
          <!-- edit -->
          <b-button
            v-show="currentActiveMode === null && isEnabled(['menu', 'accounting', 'payments', 'patientPayments', 'edit'])"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/patientPayments', {accessNumberProp: (data.item.filePayments[0].file.accessNumber), isAwaitingProp: false})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- edit awaiting -->
          <b-button
            v-show="currentActiveMode === 'unreconciled' && isEnabled(['menu', 'accounting', 'payments', 'awaitingPayments', 'edit'])"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/patientPayments', {accessNumberProp: (data.item.filePayments[0].file.accessNumber), isAwaitingProp: true})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- paymentReports / healthCare table -->
    <b-table
      v-show="currentActiveMode === 'organisation' || currentActiveMode === 'healthCare'"
      ref="table"
      class="mt-4 m-0 kt-table"
      table-class="m-0"
      hover
      :fields="organisationsFields"
      :items="currentActiveMode === 'organisation' ? paymentReports : healthCarePayments"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filterOrganisation -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- filter PaymentDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterPaymentDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter Reference -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterReference"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterReference !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterReference')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Bank -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="banks"
              searchedFieldProp="code"
              :searchedLaboratoriesProp="[]"
              :returnFieldsArrayProp="['id', 'code', 'name']"
              suggestionsKeyProp="id"
              :searchMinLengthProp="1"

              :valueProp="filterBank"
              :displayedValueProp="filterBank ? filterBank.code : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.code + ' (' + suggestion.name + ')' : '';
              }"
              noWrapProp

              @onUpdateParent="onBankChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter Paid -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- filter Reconciled -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 39px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap; padding-left: 12px; padding-right: 12px;"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>
      <!-- template reconciled -->
      <template v-slot:cell(reconciled)="data">
        <ProgressBar
          :valueProp="importFormatPrice(data.item.reconciled)"
          :totalProp="importFormatPrice(data.item.paid)"
        />
      </template>
      <!-- cell template : action buttons -->
      <template v-slot:cell(paymentReportButtons)="data">
        <div style="width: 39px;">
          <!-- edit -->
          <b-button
            v-show="lastSearchOrganisationType !== 'healthCare' && isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'edit'])"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/paymentReport/paymentGroupsEdit', {paymentReportIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="lastSearchOrganisationType === 'healthCare' && isInAreaOfAuthority(data.item) && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'edit'])"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/payments/healthCarePayment/linesEdit', {healthCarePaymentIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="
        (
          (currentActiveMode === 'organisation' && !paymentReports.length) ||
          (currentActiveMode === 'healthCare' && !healthCarePayments.length) ||
          (currentActiveMode === 'unreconciled' && !unreconciledPayments.length) ||
          (currentActiveMode === null && !patientPayments.length)
        )
          && searchedLaunched
          && !loading
          && !searchHasChanged
      "
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="!loading
        && (
          (currentActiveMode === 'organisation' && organisationMaxReached) ||
          (currentActiveMode === 'healthCare' && healthCareMaxReached) ||
          (currentActiveMode === 'unreconciled' && unreconciledMaxReached) ||
          (currentActiveMode === null && patientMaxReached)
        )
      "
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import ReportsSearchForm from "@/views/Accounting/Reports/ReportsSearchForm";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import InputSearch from "@shared/views/Helpers/InputSearch";
import ProgressBar from "@shared/views/Helpers/ProgressBar";
// services
import commonServices from "@shared/services/API/commonServices";
import receivedPaymentsServices from "@/services/API/receivedPaymentsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import price from "@shared/services/UI/price";
import areaOfAuthority from "@shared/services/UI/areaOfAuthority";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { ReportsSearchForm, PatientChoose, InputSearch, ProgressBar },
  mixins: [userRights, error, date, price, areaOfAuthority, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      exportLoading: false,
      searchHasChanged: false,
      searchedLaunched: false,
      currentActiveMode: null,
      patientMaxReached: false,
      unreconciledMaxReached: false,
      organisationMaxReached: false,
      healthCareMaxReached: false,
      lastSearchOrganisationType: null,
      patientInitialFilterValues: {},
      // search filters
      laboratoryId: null,
      organisationType: null,
      organisation: null,
      startDate: "",
      endDate: "",
      paymentTypeId: null,
      awaitingPaymentUser: null,
      // validation
      searchFormState: true,
      searchFormValidation: false,
      // totals
      paidTotal: 0,
      reconciledPaidTotal: 0,
      unreconciledPaidTotal: 0,
      refundsTotal: 0,
      reconciledRefundsTotal: 0,
      unreconciledRefundsTotal: 0,
      totalPaymentBalance: 0,
      // table filters
      filterPaymentTypeId: null,
      filterPaymentDate: "",
      filterAccessNumber: "",
      filterLastName: "",
      filterFirstName: "",
      filterPayerName: "",
      filterBank: null,
      filterCheckNumber: "",
      filterTransactionId: "",
      filterComment: "",
      filterReference: "",
      // table fields
      patientPaymentsFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paymentType",
          sortable: true,
          label: this.$t("payments.patientPayments.paymentType"),
          formatter: (_value, _key, item) => {
            return item.paymentType.name ? this.$t("paymentTypes." + item.paymentType.name) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paymentDate",
          sortable: true,
          label: this.$t("payments.patientPayments.paymentDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paid",
          sortable: false,
          label: this.$t("payments.patientPayments.paid"),
          formatter: (value, _key, _item) => {
            return this.$n(this.importFormatPrice(value), "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumbers",
          sortable: false,
          label: this.$t("payments.patientPayments.accessNumbers")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "lastName",
          sortable: false,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "firstName",
          sortable: false,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "payerName",
          sortable: true,
          label: this.$t("patients.payerName"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "bank",
          sortable: true,
          label: this.$t("payments.patientPayments.bank"),
          formatter: (value, _key, _item) => {
            return value ? value.code : this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "checkNumber",
          sortable: true,
          label: this.$t("payments.awaitingPayments.checkNumber"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th kt-table__th--sm",
          key: "transactionId",
          sortable: true,
          label: this.$t("payments.awaitingPayments.transactionId"),
          formatter: (value, _key, _item) => {
            return value || this.$t("general.emptyWithHyphen");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "comment",
          sortable: false,
          label: ""
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      organisationsFields: [
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "organisation",
          sortable: true,
          label: this.$t("payments.paymentReports.organisationName"),
          formatter: (value, _key, _item) => {
            return value.code + (value.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentDate",
          sortable: true,
          label: this.$t("payments.paymentReports.paymentDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "reference",
          sortable: true,
          label: this.$t("payments.paymentReports.reference")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "bank",
          sortable: true,
          label: this.$t("payments.paymentReports.bank"),
          formatter: (value, _key, _item) => {
            return value ? value.code : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paid",
          sortable: false,
          label: this.$t("payments.paymentReports.paid"),
          formatter: (value, _key, _item) => {
            return this.$n((this.importFormatPrice(value) || 0), "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thStyle: { width: "200px" },
          thClass: "kt-table__th",
          key: "reconciled",
          sortable: false,
          label: this.$t("payments.paymentReports.reconciled")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "paymentReportButtons",
          sortable: false,
          label: ""
        }
      ],
      // table items
      patientPayments: [],
      healthCarePayments: [],
      paymentReports: [],
      unreconciledPayments: [],
      // saveParamsConfig
      saveParamsConfig: {
        laboratoryId: "number",
        organisationType: "string",
        organisation: { id: "number", code: "string", name: "string" },
        paymentTypeId: "number",
        awaitingPaymentUser: { id: "number", firstName: "string", lastName: "string" },
        startDate: "string",
        endDate: "string",

        filterPaymentDate: "string",
        filterAccessNumber: "string",
        filterLastName: "string",
        filterFirstName: "string",
        filterPayerName: "string",
        filterBank: { id: "number", code: "string", name: "string" },
        filterCheckNumber: "string",
        filterTransactionId: "string"
      }
    };
  },
  computed: {
    patientPaymentsFieldsFormatted: function() {
      return this.patientPaymentsFields.filter((v) => {
        if (v.key === "comment") {
          return this.currentActiveMode === "unreconciled";
        } else {
          return true;
        }
      });
    },
    // options
    paymentTypesOptions: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.paymentTypes)) || [];
      options.unshift({ id: null, localisedName: this.$tc("general.allMasculine", 2) });
      return options;
    },
    paymentTypesIdToName: function() {
      return this.paymentTypesOptions.reduce((o, key) => ({ ...o, [key.id]: key.localisedName }), {});
    },
    paymentTypesNameToId: function() {
      return this.paymentTypesOptions.reduce((o, key) => ({ ...o, [key.name]: key.id }), {});
    }
  },
  watch: {
    organisationType: {
      handler: function(val) {
        if ((val === null || val === "all") && this.organisation === null) {
          this.filterReference = "";
        }
      }
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    if (this.organisationType === "healthCare") {
      this.lastSearchOrganisationType = "healthCare";
    } else if (this.organisationType !== null) {
      this.lastSearchOrganisationType = "organisation";
    }
  },
  methods: {
    focusFirstLine() {
      // if (this.$refs.editLineButton_0) {
      //   this.$refs.editLineButton_0.focus();
      // } else if (this.$refs.lineDropdownButton_0) {
      //   this.$refs.lineDropdownButton_0.focus();
      // }
    },

    // bank
    onBankChosen(bank) {
      this.filterBank = bank ? { id: bank.id, code: bank.code, name: bank.name } : null;
      this.searchHasChanged = true;
    },
    // patient
    async onPatientChosen(patient) {
      this.filterPatient = patient ? { id: patient.id, firstName: patient.firstName, lastName: patient.lastName, maidenName: patient.maidenName } : null;
      this.searchHasChanged = true;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
    },
    // contact
    async onContactChosen(contact) {
      this.filterContact = contact ? { id: contact.id, firstName: contact.firstName, lastName: contact.lastName, code: contact.code } : null;
      this.searchHasChanged = true;
    },
    // pathologist
    onPathologistChosen(pathologist) {
      this.filterPathologist = pathologist ? { id: pathologist.id, user: { firstName: pathologist.user.firstName, lastName: pathologist.user.lastName } } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },
    // submit functions
    validateForm() {
      this.searchFormValidation = true;

      return (
        this.searchFormState !== false
      );
    },
    async onPageInput() {
      try {
        // validation
        if (this.validateForm() === false) return false;
        // validation dates
        if (this.filterPaymentDate !== "") {
          const filterPaymentDate = new Date(this.filterPaymentDate).getFullYear();
          if (filterPaymentDate < 1900 || filterPaymentDate > 2300) {
            return false;
          }
        }
        if (this.startDate !== "") {
          const startDate = new Date(this.startDate).getFullYear();
          if (startDate < 1900 || startDate > 2300) {
            return false;
          }
        }
        if (this.endDate !== "") {
          const endDate = new Date(this.endDate).getFullYear();
          if (endDate < 1900 || endDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        let paymentTypeIdFilter = null;
        if (!this.organisation && this.organisationType === null) {
          paymentTypeIdFilter = this.paymentTypeId;
        }
        let awaitingPaymentUserFilter = null;
        if (!this.organisation && this.organisationType === null) {
          awaitingPaymentUserFilter = this.awaitingPaymentUser;
        }
        // let checkNumberFilter = "";
        // if (!this.organisation && this.organisationType === null && (this.paymentTypeId === null || this.paymentTypeId === this.paymentTypesNameToId.check)) {
        //   checkNumberFilter = this.filterCheckNumber;
        // }

        const lastSearchOrganisationType = !this.organisation ? this.organisationType : "organisation";
        if (this.organisation) {
          this.currentActiveMode = "organisation";
        } else if (this.organisationType === null || this.organisationType === "all") {
          this.currentActiveMode = null;
        } else if (this.organisationType === "healthCare") {
          this.currentActiveMode = "healthCare";
        } else {
          this.currentActiveMode = "organisation";
        }
        const showPatientFilters = !this.organisation && (this.organisationType === null || this.organisationType === "all");
        const res = await commonServices.getAll(
          "receivedPayments",
          {
            laboratoryId: this.laboratoryId,
            organisationType: !this.organisation ? this.organisationType : null,
            organisationId: this.organisation ? this.organisation.id : null,
            paymentTypeId: paymentTypeIdFilter,
            awaitingPaymentUserId: awaitingPaymentUserFilter ? awaitingPaymentUserFilter.id : null,
            startDate: this.startDate,
            endDate: this.endDate,

            paymentDate: this.filterPaymentDate,
            accessNumber: showPatientFilters ? this.filterAccessNumber : "",
            lastName: showPatientFilters ? this.filterLastName : "",
            firstName: showPatientFilters ? this.filterFirstName : "",
            payerName: showPatientFilters ? this.filterPayerName : "",
            bankId: this.filterBank ? this.filterBank.id : null,
            checkNumber: showPatientFilters ? this.filterCheckNumber : "",
            transactionId: showPatientFilters ? this.filterTransactionId : "",
            reference: (this.organisation || (this.organisationType !== null || this.organisationType === "all")) ? this.filterReference : ""
            // hospitalisationNumber: this.organisation || this.organisationType !== null ? this.filterHospitalisationNumber : "",
            // patientId: this.filterPatient ? this.filterPatient.id : null,
            // pathologistId: this.filterPathologist ? this.filterPathologist.id : null,
            // contactId: this.filterContact ? this.filterContact.id : null
          }
        );
        // assign result
        this.patientPayments = [];
        this.unreconciledPayments = [];
        this.healthCarePayments = [];
        this.paymentReports = [];

        this.lastSearchOrganisationType = lastSearchOrganisationType;

        this.patientMaxReached = res.data.patientPayments && res.data.patientPayments.maxReached;
        this.unreconciledMaxReached = res.data.unreconciledPayments && res.data.unreconciledPayments.maxReached;
        this.organisationMaxReached = res.data.healthCarePayments && res.data.healthCarePayments.maxReached;
        this.healthCareMaxReached = res.data.paymentReports && res.data.paymentReports.maxReached;

        this.paidTotal = res.data.paidTotal;
        this.reconciledPaidTotal = res.data.reconciledPaidTotal;
        this.unreconciledPaidTotal = res.data.unreconciledPaidTotal;
        this.refundsTotal = res.data.refundsTotal;
        this.reconciledRefundsTotal = res.data.reconciledRefundsTotal;
        this.unreconciledRefundsTotal = res.data.unreconciledRefundsTotal;
        this.totalPaymentBalance = res.data.totalPaymentBalance;

        if (res.data.patientPayments) {
          this.patientPayments = res.data.patientPayments.rows;
          this.unreconciledPayments = res.data.unreconciledPayments.rows;

          // this.paidTotal += res.data.patientPayments.paidTotal + res.data.unreconciledPayments.paidTotal;
          // this.reconciledPaidTotal += res.data.patientPayments.paidTotal;
          // this.unreconciledPaidTotal += res.data.unreconciledPayments.paidTotal;
          // this.refundsTotal += res.data.patientPayments.refundsTotal + res.data.unreconciledPayments.refundsTotal;
          // this.reconciledRefundsTotal += res.data.patientPayments.refundsTotal;
          // this.unreconciledRefundsTotal += res.data.unreconciledPayments.refundsTotal;
        }
        if (res.data.healthCarePayments) {
          this.healthCarePayments = res.data.healthCarePayments.rows;

          // this.paidTotal += res.data.healthCarePayments.paidTotal;
          // this.reconciledPaidTotal += res.data.healthCarePayments.paidReconciled;
          // this.unreconciledPaidTotal += res.data.healthCarePayments.paidTotal - res.data.healthCarePayments.paidReconciled;
          // this.refundsTotal += res.data.healthCarePayments.refundsTotal;
          // this.reconciledRefundsTotal += res.data.healthCarePayments.refundsReconciled;
          // this.unreconciledRefundsTotal += res.data.healthCarePayments.refundsTotal - res.data.healthCarePayments.refundsReconciled;
        }
        if (res.data.paymentReports) {
          this.paymentReports = res.data.paymentReports.rows;

          // this.paidTotal += res.data.paymentReports.paidTotal;
          // this.reconciledPaidTotal += res.data.paymentReports.paidReconciled;
          // this.unreconciledPaidTotal += res.data.paymentReports.paidTotal - res.data.paymentReports.paidReconciled;
          // this.refundsTotal += res.data.paymentReports.refundsTotal;
          // this.reconciledRefundsTotal += res.data.paymentReports.refundsReconciled;
          // this.unreconciledRefundsTotal += res.data.paymentReports.refundsTotal - res.data.paymentReports.refundsReconciled;
        }

        this.searchedLaunched = true;
        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    async onExport(type) {
      try {
        // validation
        if (this.validateForm() === false) return false;
        // validation dates
        if (this.filterPaymentDate !== "") {
          const filterPaymentDate = new Date(this.filterPaymentDate).getFullYear();
          if (filterPaymentDate < 1900 || filterPaymentDate > 2300) {
            return false;
          }
        }
        if (this.startDate !== "") {
          const startDate = new Date(this.startDate).getFullYear();
          if (startDate < 1900 || startDate > 2300) {
            return false;
          }
        }
        if (this.endDate !== "") {
          const endDate = new Date(this.endDate).getFullYear();
          if (endDate < 1900 || endDate > 2300) {
            return false;
          }
        }

        this.exportLoading = true;

        let paymentTypeIdFilter = null;
        if (!this.organisation && this.organisationType === null) {
          paymentTypeIdFilter = this.paymentTypeId;
        }
        let awaitingPaymentUserFilter = null;
        if (!this.organisation && this.organisationType === null) {
          awaitingPaymentUserFilter = this.awaitingPaymentUser;
        }

        const organisationTypeFormatted = this.$t("organisationTypePlural." + (this.organisationType || "patients")).toLowerCase();
        let organisationFormatted = this.organisation ? this.organisation.code : organisationTypeFormatted;
        organisationFormatted = organisationFormatted.replace(/ /g, "-");

        const showPatientFilters = !this.organisation && (this.organisationType === null || this.organisationType === "all");
        const resFile = await receivedPaymentsServices.generate(
          {
            laboratoryId: this.laboratoryId,
            organisationType: !this.organisation ? this.organisationType : null,
            organisationId: this.organisation ? this.organisation.id : null,
            paymentTypeId: paymentTypeIdFilter,
            awaitingPaymentUserId: awaitingPaymentUserFilter ? awaitingPaymentUserFilter.id : null,
            startDate: this.startDate,
            endDate: this.endDate,

            paymentDate: this.filterPaymentDate,
            accessNumber: showPatientFilters ? this.filterAccessNumber : "",
            lastName: showPatientFilters ? this.filterLastName : "",
            firstName: showPatientFilters ? this.filterFirstName : "",
            payerName: showPatientFilters ? this.filterPayerName : "",
            bankId: this.filterBank ? this.filterBank.id : null,
            checkNumber: showPatientFilters ? this.filterCheckNumber : "",
            transactionId: showPatientFilters ? this.filterTransactionId : "",
            reference: (this.organisation || this.organisationType !== null) ? this.filterReference : ""
          },
          type
        );

        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, this.$t("reports.receivedPayments.fileTitle") + "_" + organisationFormatted + "_" + this.startDate + "_" + this.endDate + "." + type);
        }
        this.exportLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    goToPayment(payment) {
      if (payment.healthCarePaymentGroups) {
        navigate("accounting/payments/healthCarePayment/linesEdit", { healthCarePaymentIdProp: payment.id });
      } else if (payment.paymentGroups) {
        navigate("accounting/payments/paymentReport/paymentGroupsEdit", { paymentReportIdProp: payment.id });
      } else {
        navigate("accounting/payments/patientPayments");
      }
    }
  }
};
</script>

<template>
  <div class="kt-validateAll">
    <!-- Modal : validateAll -->
    <b-modal
      id="validateAllModal"
      hide-footer
      :title="$t('validateAllModal.modalTitle')"
      :size="showPathologistsList ? 'lg' : 'sm-larger'"
      @shown="onShownValidateAllModal"
      @hidden="() => {
        if ($refs.ValidateAllButton) {
          $refs.ValidateAllButton.focus();
        }
      }"
    >
      <b-form @submit.prevent="validateAllSubmit">
        <!-- range -->
        <b-form-checkbox-group
          v-model="range"
          :state="range.length === 0 ? false : null"
          :disabled="loading"
          class="mb-3"
        >
          <b-form-checkbox
            ref="validateAllModal_hpv"
            value="hpv"
            @change="onRangeChange('hpv')"
          >
            {{ $t("validateAllModal.hpv") }}
          </b-form-checkbox>
          <b-form-checkbox
            ref="validateAllModal_cytology"
            value="cytology"
            @change="onRangeChange('cytology')"
          >
            {{ $t("validateAllModal.cytology") }}
          </b-form-checkbox>
          <b-form-checkbox
            ref="validateAllModal_cotesting"
            value="cotesting"
            @change="onRangeChange('cotesting')"
          >
            {{ $t("validateAllModal.cotesting") }}
          </b-form-checkbox>
        </b-form-checkbox-group>
        <!-- cotestingHpvPos -->
        <b-form-checkbox
          v-show="range.includes('cotesting')"
          ref="validateAllModal_cotestingHpvPos"
          v-model="cotestingHpvPos"
          :disabled="loading"
          class="mb-3"
          @change="onRangeChange('cotestingHpvPos')"
        >
          {{ $t("validateAllModal.cotestingHpvPos") }}
        </b-form-checkbox>

        <b-row v-if="currentPathologist">
          <!-- pathologist -->
          <b-col
            cols="12"
            class="mb-3"
          >
            <!-- pathologist (self) -->
            <div v-show="!showPathologistsList">
              <span class="font-weight-semi-bold">{{ $t("validateAllModal.samePathologist") }}</span>
              <!-- negativeCount -->
              <div
                class="font-weight-semi-bold"
                :class="currentPathologist.negativeCount ? 'text-' + $systemSettings.theme : ''"
              >
                <div
                  class="px-2 py-1 mt-1"
                  style="background-color: rgb(246, 247, 248);"
                >
                  {{ $tc("validateAllModal.negativeCount", currentPathologist.negativeCount, {count: currentPathologist.negativeCount}) }}
                </div>
                <!-- progress bar -->
                <b-progress
                  v-show="currentPathologist.loading"
                  :value="progressValue"
                  :max="progressMax"
                  show-progress
                  animated
                ></b-progress>
              </div>
              <!-- validated -->
              <div
                v-show="currentPathologist.validated && currentPathologist.validatedCount"
                class="font-weight-semi-bold text-success"
              >
                {{ $tc("validateAllModal.validatedCount", currentPathologist.validatedCount, {count: currentPathologist.validatedCount}) }}
              </div>
              <div
                v-show="currentPathologist.validated && currentPathologist.notValidatedCount"
                class="font-weight-semi-bold text-success"
              >
                {{ $tc("validateAllModal.notValidatedCount", currentPathologist.notValidatedCount, {count: currentPathologist.notValidatedCount}) }}
              </div>
              <!-- switcher -->
              <b-button
                class="kt-btn-transparent pl-0 ml-n1 mt-2"
                :class="'text-' + $systemSettings.theme"
                :disabled="loading"
                variant="light"
                pill
                @click="() => {
                  showPathologistsList = true;
                  updatePathologistsList();
                }"
              >
                <b-icon
                  icon="arrow-left-right"
                  :class="'text-' + $systemSettings.theme"
                ></b-icon>
                {{ $t("validateAllModal.switchToDifferentPathologist") }}
              </b-button>
            </div>
            <!-- pathologists (all) -->
            <div v-show="showPathologistsList">
              <span
                class="font-weight-semi-bold d-inline-block"
                style="margin-bottom: 2px;"
              >{{ $t("validateAllModal.differentPathologist") }}</span>
              <!-- select all -->
              <b-button
                class="kt-btn-transparent pl-0 ml-2"
                style="margin-bottom: 2px;"
                :class="'text-' + $systemSettings.theme"
                :disabled="loading || (!selectables.length && !targetUserIds.length)"
                variant="light"
                pill
                @click="() => {
                  if (!targetUserIds.length || targetUserIds.length !== selectables.length) {
                    targetUserIds = selectables;
                  } else {
                    targetUserIds = [];
                  }
                }"
              >
                <b-icon
                  icon="check2-all"
                  :class="'text-' + $systemSettings.theme"
                ></b-icon>
                {{ (!targetUserIds.length || targetUserIds.length !== selectables.length) ? $t("general.selectAll") : $t("general.clearAll") }}
              </b-button>

              <!-- pathologists -->
              <div class="kt-validateAll__pathologists">
                <div
                  v-for="(pathologist) in pathologists"
                  :key="pathologist.id"
                  class="kt-validateAll__pathologist mt-2 p-2"
                  :class="{'kt-validateAll__pathologist--disabled': !pathologist.negativeCount, 'kt-validateAll__pathologist--selectable': !!pathologist.negativeCount}"
                  @click="() => {
                    if (!loading && pathologist.negativeCount > 0) {
                      const pos = targetUserIds.indexOf(pathologist.userId);
                      if (pos !== -1) {
                        targetUserIds.splice(pos, 1);
                      } else {
                        targetUserIds.push(pathologist.userId);
                      }
                    }
                  }"
                >
                  <!-- header -->
                  <div class="kt-validateAll__pathologist__header">
                    <!-- checkbox -->
                    <div class="kt-validateAll__pathologist__checkbox">
                      <div
                        v-show="pathologist.negativeCount > 0"
                        class="kt-checkbox-display-only-cont"
                      >
                        <span
                          class="kt-checkbox-display-only"
                          :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (targetUserIds.includes(pathologist.userId) ? ' kt-checkbox-display-only--checked' : '')"
                        >
                          <span class="kt-checkbox-display-only__cross"></span>
                        </span>
                      </div>
                    </div>
                    <div class="kt-validateAll__pathologist__content">
                      <!-- samePathologist name -->
                      <div
                        v-show="pathologist.userId === $userSettings.user.id"
                        class="font-weight-semi-bold line-height-md"
                      >
                        {{ $t("validateAllModal.samePathologist") }}
                      </div>
                      <!-- pathologist name -->
                      <div
                        v-show="pathologist.userId !== $userSettings.user.id"
                        class="font-weight-semi-bold line-height-md"
                      >
                        {{ pathologist.user.firstName + " " + pathologist.user.lastName + (pathologist.user.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') }}
                      </div>
                      <!-- pathologist code -->
                      <div>{{ pathologist.code }}</div>
                      <!-- negativeCount -->
                      <div
                        class="font-weight-semi-bold"
                        :class="pathologist.negativeCount ? 'text-' + $systemSettings.theme : ''"
                      >
                        {{ $tc("validateAllModal.negativeCount", pathologist.negativeCount, {count: pathologist.negativeCount}) }}
                        <b-progress
                          v-show="pathologist.loading"
                          :value="progressValue"
                          :max="progressMax"
                          show-progress
                          animated
                        ></b-progress>
                      </div>
                      <!-- validated -->
                      <div
                        v-show="pathologist.validated && pathologist.validatedCount"
                        class="font-weight-semi-bold text-success"
                      >
                        {{ $tc("validateAllModal.validatedCount", pathologist.validatedCount, {count: pathologist.validatedCount}) }}
                      </div>
                      <div
                        v-show="pathologist.validated && pathologist.notValidatedCount"
                        class="font-weight-semi-bold text-success"
                      >
                        {{ $tc("validateAllModal.notValidatedCount", pathologist.notValidatedCount, {count: pathologist.notValidatedCount}) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- switcher -->
              <b-button
                class="kt-btn-transparent text-dark pl-0 mt-1"
                :class="'text-' + $systemSettings.theme"
                :disabled="loading"
                variant="light"
                pill
                @click="() => {
                  showPathologistsList = false;
                  updatePathologistsList();
                }"
              >
                <b-icon
                  icon="arrow-left-right"
                  :class="'text-' + $systemSettings.theme"
                ></b-icon>
                {{ $t("validateAllModal.switchToSamePathologist") }}
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- submit -->
        <b-button
          class="my-2"
          :variant="$systemSettings.theme"
          type="submit"
          :disabled="(!showPathologistsList && (!currentPathologist || !currentPathologist.negativeCount)) || (showPathologistsList && !targetUserIds.length)"
        >
          {{ $tc('medicalReports.actions.validate') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";

export default {
  mixins: [userRights, error],
  props: {},
  data() {
    return {
      // general
      loading: false,
      // progress
      showProgress: false,
      progressMax: 1,
      progressValue: 0,
      // validateAll variables
      range: ["hpv"],
      cotestingHpvPos: true,
      targetUserIds: [],
      showPathologistsList: false,
      pathologists: []
    };
  },
  computed: {
    filteredPathologistsList: function() {
      return this.pathologists.filter(v => v.userId === this.$userSettings.user.id);
    },
    currentPathologist: function() {
      return this.pathologists.find(v => v.userId === this.$userSettings.user.id) || null;
    },
    selectables: function() {
      return this.pathologists
        .filter((v) => { return !!v.negativeCount; })
        .map((v) => { return v.userId; });
    }
  },
  async mounted() {
  },
  methods: {
    async onRangeChange(type) {
      await this.updatePathologistsList();
      await this.$nextTick();
      this.$refs["validateAllModal_" + type].focus();
    },

    async importPathologistsOptions() {
      try {
        const res = await commonServices.getAll("pathologists", null);
        this.pathologists = res.data.map((v) => {
          return {
            ...v,
            negativeCount: 0,
            loading: false,
            validated: false,
            validatedCount: null,
            notValidatedCount: null
          };
        });
      } catch (err) {
        this.handleErrors(err);
      }
    },

    async onShownValidateAllModal() {
      if (!this.pathologists.length) {
        await this.importPathologistsOptions();
      }
      await this.updatePathologistsList();
      if (this.$refs.validateAllModal_hpv) {
        this.$nextTick(() => {
          this.$refs.validateAllModal_hpv.focus();
        });
      }
    },
    async updatePathologistsList() {
      this.loading = true;
      if (this.range.length) {
        const userIds = this.showPathologistsList ? null : [this.$userSettings.user.id];
        const res = await cytologyReportsServices.countAllNegatives(
          this.range,
          this.range.includes("cotesting") ? this.cotestingHpvPos : false,
          userIds
        );
        for (const pathologist of this.pathologists) {
          pathologist.negativeCount = 0;
          pathologist.validatedCount = 0;
          pathologist.notValidatedCount = 0;
          for (const pathologistItem of res.data) {
            if (pathologistItem.pathologist.id === pathologist.id) {
              pathologist.negativeCount = pathologistItem.count;
            }
          }
          // unselect if no negative files
          if (!pathologist.negativeCount) {
            const pos = this.targetUserIds.indexOf(pathologist.userId);
            if (pos !== -1) {
              this.targetUserIds.splice(pos, 1);
            }
          }
        }
      } else {
        this.targetUserIds = [];
        for (const pathologist of this.pathologists) {
          pathologist.negativeCount = 0;
          pathologist.validatedCount = 0;
          pathologist.notValidatedCount = 0;
        }
      }
      this.loading = false;
    },

    // validate all
    async validateAllSubmit() {
      try {
        this.loading = true;
        const userIds = this.showPathologistsList ? this.targetUserIds : [this.$userSettings.user.id];
        const res = await cytologyReportsServices.countAllNegatives(
          this.range,
          this.range.includes("cotesting") ? this.cotestingHpvPos : false,
          userIds
        );
        const pathologistsNegativeList = res.data;
        const validationCount = pathologistsNegativeList.reduce((total, v) => { return total + v.count; }, 0);
        this.loading = false;
        if (validationCount === 0) {
          // success nothing to validate
          this.$emit("alert", "success", {
            title: this.$t("medicalReports.notifications.validateAllNegativesNoNegativesTitle")
          });
        } else {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("medicalReports.notifications.validateAllNegativesConfirmationText"), {
            title: this.$t("medicalReports.notifications.validateAllNegativesConfirmationTitle"),
            okVariant: this.$systemSettings.theme,
            okTitle: this.$t("medicalReports.notifications.validateAllNegativesConfirmationOkBtn"),
            cancelTitle: this.$t("medicalReports.notifications.validateAllNegativesConfirmationCancelBtn"),
            centered: true
          });
          // validate
          if (result) {
            for (const userId of userIds) {
              for (const pathologist of this.pathologists) {
                if (userId === pathologist.userId) {
                  pathologist.loading = true;
                  this.progressMax = userIds.leng;
                  this.progressValue = 0;
                  this.showProgress = true;
                  const resValidateAll = await cytologyReportsServices.validateAllNegatives(
                    this.range,
                    this.range.includes("cotesting") ? this.cotestingHpvPos : false,
                    userId,
                    this.onValidateProgress
                  );
                  const reportData = JSON.parse(resValidateAll.data.split("|").pop());
                  pathologist.negativeCount = reportData.cytologyReportsNotValidated;
                  pathologist.loading = false;
                  pathologist.validated = true;
                  pathologist.validatedCount = reportData.cytologyReportsValidated;
                  pathologist.notValidatedCount = reportData.cytologyReportsNotValidated;
                  this.showProgress = false;
                  this.progressValue = 0;
                  this.progressMax = 1;
                }
              }
            }
          }
          this.$emit("refreshParent", true);
        }
      } catch (err) {
        this.handleErrors(err);
        this.loading = false;
      }
    },

    onValidateProgress(res) {
      const done = res.currentTarget.response.split("|");
      if (done.length > 1) {
        if (isNaN(Number(done[done.length - 1]))) {
          this.progressValue = this.progressMax;
        } else {
          this.progressMax = done[0];
          this.progressValue = done[done.length - 1];
        }
      } else {
        this.progressValue = Math.min(this.progressMax, this.progressValue + 1);
      }
    }
  }
};
</script>

<style scoped src="./ValidateAll.scss" lang="scss"></style>

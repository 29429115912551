module.exports.rights = {
  roleSpecific: {
    system: ["Administrator"],
    userAdministration: ["UserAdministrator"],
    chiefSecretary: ["ChiefSecretary"],
    secretary: ["Secretary"],
    pathologist: ["Pathologist"],
    cytotechnician: ["Cytotechnician"],
    chiefAccountant: ["ChiefAccountant"],
    accountant: ["Accountant"],
    technician: ["Technician"],
    chiefTechnician: ["ChiefTechnician"],

    office: ["ChiefSecretary", "Secretary"],
    diagnosis: ["Cytotechnician", "Pathologist"],
    accounting: ["ChiefAccountant", "Accountant"]
  },
  menu: {
    general: {
      data: ["Secretary", "Technician"],
      office: ["Secretary"],
      accounting: ["Accountant"],
      diagnosis: ["Cytotechnician"],
      administration: ["UserAdministrator"],
      reports: ["Secretary", "Cytotechnician", "Technician", "Accountant"]
    },
    data: {
      general: {
        organisations: ["Secretary", "Accountant"],
        samples: ["Secretary", "ChiefAccountant"],
        containerTypes: ["Secretary"],
        tasks: ["Secretary", "Accountant"],
        acts: ["Secretary", "Accountant"],
        instruments: ["Technician"],
        contactInformations: ["Secretary", "ChiefAccountant"],
        banks: ["Secretary", "Accountant"]
      },
      organisations: {
        add: ["ChiefAccountant"],
        view: ["Secretary", "Accountant", "Cytotechnician"],
        edit: ["ChiefAccountant"],
        delete: ["ChiefAccountant"]
      },
      samples: {
        add: ["ChiefSecretary", "ChiefAccountant"],
        view: ["Secretary", "Accountant"],
        edit: ["ChiefSecretary", "ChiefAccountant"],
        delete: ["ChiefSecretary", "ChiefAccountant"]
      },
      containerTypes: {
        add: ["ChiefSecretary"],
        view: ["Secretary", "Accountant"],
        edit: ["ChiefSecretary"],
        delete: ["ChiefSecretary"]
      },
      tasks: {
        add: ["ChiefSecretary", "ChiefAccountant"],
        view: ["Secretary", "Accountant"],
        edit: ["ChiefSecretary", "ChiefAccountant"],
        delete: ["ChiefSecretary", "ChiefAccountant"],
        taskActs: {
          add: ["ChiefSecretary", "ChiefAccountant"],
          view: ["Secretary", "Accountant"],
          edit: ["ChiefSecretary", "ChiefAccountant"],
          delete: ["ChiefSecretary", "ChiefAccountant"]
        }
      },
      acts: {
        add: ["ChiefSecretary", "ChiefAccountant"],
        view: ["ChiefSecretary", "Accountant"],
        edit: ["ChiefSecretary", "ChiefAccountant"],
        delete: ["ChiefSecretary", "ChiefAccountant"]
      },
      contactInformations: {
        add: ["Secretary", "ChiefAccountant"],
        view: ["Secretary", "ChiefAccountant", "Accountant"],
        edit: ["Secretary", "ChiefAccountant"],
        delete: ["Secretary", "ChiefAccountant"]
      },
      instruments: {
        add: ["ChiefTechnician"],
        view: ["Technician", "Secretary", "Accountant"],
        edit: ["ChiefTechnician"],
        delete: ["ChiefTechnician"]
      },
      banks: {
        add: ["ChiefAccountant"],
        view: ["Secretary", "Accountant"],
        edit: ["ChiefAccountant"],
        delete: ["ChiefAccountant"]
      }
    },
    office: {
      general: {
        patients: ["Secretary", "Accountant"],
        files: ["Secretary"],
        contacts: ["Secretary"]
      },
      patients: {
        view: ["Secretary", "Cytotechnician", "Accountant"],
        add: ["Secretary"],
        edit: ["Secretary", "Cytotechnician", "Accountant"],
        delete: ["Secretary"]
      },
      files: {
        view: ["Secretary", "Cytotechnician", "Accountant"],
        add: ["Secretary"],
        edit: ["Secretary", "Cytotechnician"],
        fileContacts: {
          add: ["Secretary", "Cytotechnician"],
          view: ["Secretary", "Cytotechnician"],
          edit: ["Secretary", "Cytotechnician"],
          delete: ["Secretary", "Cytotechnician"]
        },
        containerGroups: {
          add: ["Secretary", "Cytotechnician"],
          view: ["Secretary", "Cytotechnician"],
          edit: ["Secretary", "Cytotechnician"],
          delete: ["Secretary", "Cytotechnician"]
        },
        containers: {
          add: ["Secretary", "Cytotechnician"],
          view: ["Secretary", "Cytotechnician"],
          edit: ["Secretary", "Cytotechnician"],
          delete: ["Secretary", "Cytotechnician"]
        },
        containerGroupTasks: {
          add: ["Secretary", "Cytotechnician"],
          view: ["Secretary", "Cytotechnician"],
          edit: ["Secretary", "Cytotechnician"],
          delete: ["Secretary", "Cytotechnician"]
        }
      },
      contacts: {
        view: ["Secretary"],
        add: ["ChiefSecretary"],
        edit: ["ChiefSecretary"],
        delete: ["ChiefSecretary"]
      }
    },
    accounting: {
      general: {
        files: ["Accountant"],
        invoices: ["Accountant"],
        invoiceReports: ["Accountant"],
        careSheets: ["Accountant"],
        feeNotes: ["Accountant"],
        fileInvoiceTransformations: ["Accountant"],
        payments: ["Accountant"],
        reports: ["Accountant"]
      },
      files: {
        view: ["Accountant"]
      },
      invoiceReports: {
        clinics: ["Accountant"],
        healthCenters: ["Accountant"],
        hospitals: ["Accountant"],
        add: ["Accountant"],
        view: ["Accountant", "Secretary"],
        toPdf: ["Accountant", "Secretary"],
        toXlsx: ["Accountant", "Secretary"]
      },
      careSheets: {
        add: ["Accountant"],
        view: ["Accountant", "Secretary"],
        toPdf: ["Accountant", "Secretary"]
      },
      feeNotes: {
        add: ["Accountant"],
        view: ["Accountant", "Secretary"],
        toPdf: ["Accountant", "Secretary"]
      },
      fileInvoiceTransformations: {
        add: ["Accountant"],
        view: ["Accountant"],
        edit: ["Accountant"],
        delete: ["ChiefAccountant"]
      },
      payments: {
        general: {
          paymentReports: ["Accountant"],
          healthCarePayments: ["Accountant"],
          patientPayments: ["Accountant"],
          awaitingPayments: ["Accountant"]
        },
        paymentReports: {
          view: ["Accountant", "Secretary"],
          add: ["Accountant"],
          edit: ["Accountant"],
          delete: ["ChiefAccountant"],
          viewGroup: ["Accountant", "Secretary"],
          addGroup: ["Accountant"],
          editGroup: ["Accountant"],
          deleteGroup: ["Accountant"],
          viewLines: ["Accountant", "Secretary"],
          editLines: ["Accountant"],
          postLine: ["Accountant"],
          addLine: ["Accountant"],
          editLine: ["Accountant"],
          deleteLine: ["Accountant"],
          toPdf: ["Accountant", "Secretary"],
          groupToPdf: ["Accountant", "Secretary"],
          toXlsx: ["Accountant", "Secretary"],
          groupToXlsx: ["Accountant", "Secretary"]
        },
        patientPayments: {
          view: ["Accountant", "Secretary"],
          add: ["Accountant"],
          edit: ["Accountant"],
          reconcile: ["Accountant"],
          delete: ["ChiefAccountant"]
        },
        awaitingPayments: {
          view: ["Accountant"],
          add: ["Accountant"],
          edit: ["Accountant"],
          delete: ["ChiefAccountant"]
        },
        healthCarePayments: {
          add: ["Accountant"],
          view: ["Accountant", "Secretary"],
          edit: ["Accountant"],
          delete: ["Accountant"],
          toPdf: ["Accountant"],
          toXlsx: ["Accountant"],
          addGroup: ["Accountant"],
          viewGroup: ["Accountant"],
          editGroup: ["Accountant"],
          deleteGroup: ["Accountant"],
          groupToPdf: ["Accountant"],
          groupToXlsx: ["Accountant"],
          addLine: ["Accountant"],
          editLine: ["Accountant"],
          deleteLine: ["Accountant"]
        }
      },
      reports: {
        general: {
          sales: ["Accountant"],
          filesNotInvoiced: ["Accountant"],
          fileInvoicesNotPaid: ["Accountant"],
          allPaymentsReport: ["Accountant"],
          incorrectPayments: ["Accountant"],
          receivedPayments: ["Accountant"],
          compensationsReport: ["Accountant"],
          balanceReport: ["Accountant"]
        },
        sales: {
          all: ["Accountant"],
          clinics: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          healthCare: ["Accountant"],
          patients: ["Accountant"]
        },
        filesNotInvoiced: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        },
        fileInvoicesNotPaid: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        },
        allPaymentsReport: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        },
        incorrectPayments: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        },
        receivedPayments: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        },
        compensationsReports: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        },
        balanceReports: {
          clinics: ["Accountant"],
          healthCare: ["Accountant"],
          healthCenters: ["Accountant"],
          hospitals: ["Accountant"],
          others: ["Accountant"]
        }
      }
    },
    diagnosis: {
      general: {
        reports: ["Cytotechnician"],
        medicalReports: ["Cytotechnician"],
        validation: ["Pathologist"],
        cytologyReports: ["Cytotechnician"],
        cytologyValidation: ["Pathologist"],
        histologyReports: ["Cytotechnician"],
        histologyValidation: ["Pathologist"],
        awaitingHpvResults: ["Cytotechnician", "Technician"],
        histologyShortCodes: ["Pathologist"],
        containerGroupShortCodes: ["Cytotechnician"]
      },
      sectorSubmenu: {
        medicalReports: {
          cytology: ["Cytotechnician"],
          histology: ["Pathologist"]
        },
        validation: {
          cytology: ["Pathologist"],
          histology: ["Pathologist"]
        }
      },
      cytologyReports: {
        add: ["Cytotechnician"],
        view: ["Cytotechnician"],
        edit: ["Cytotechnician"],
        validateTechnically: ["Cytotechnician"],
        validateMedically: ["Pathologist"]
      },
      histologyReports: {
        add: ["Cytotechnician"],
        view: ["Cytotechnician"],
        edit: ["Cytotechnician"],
        validateTechnically: ["Cytotechnician"],
        validateMedically: ["Pathologist"]
      },
      awaitingHpvResults: {
        add: ["Cytotechnician", "Technician"],
        upload: ["Cytotechnician", "Technician"],
        edit: ["Cytotechnician", "Technician"],
        delete: ["Cytotechnician", "Technician"]
      },
      unrecognizedHpvResults: {
        export: ["Cytotechnician", "Technician"],
        delete: ["Cytotechnician", "Technician"]
      },
      histologyShortCodes: {
        add: ["Pathologist"],
        view: ["Pathologist"],
        edit: ["Pathologist"],
        delete: ["Pathologist"]
      },
      containerGroupShortCodes: {
        add: ["Cytotechnician"],
        view: ["Cytotechnician"],
        edit: ["Cytotechnician"],
        delete: ["Cytotechnician"]
      }
    },
    administration: {
      general: {
        pathologists: ["UserAdministrator", "ChiefSecretary", "ChiefAccountant"],
        laboratories: ["UserAdministrator"],
        users: ["UserAdministrator"]
      },
      laboratories: {
        add: ["UserAdministrator"],
        view: ["UserAdministrator"],
        edit: ["UserAdministrator"],
        delete: ["UserAdministrator"]
      },
      users: {
        add: ["UserAdministrator"],
        edit: ["UserAdministrator"]
      },
      userRequests: {
        import: ["UserAdministrator"],
        delete: ["UserAdministrator"]
      },
      pathologists: {
        add: ["UserAdministrator", "ChiefAccountant"],
        view: ["UserAdministrator", "Secretary", "ChiefAccountant"],
        edit: ["UserAdministrator", "ChiefAccountant"],
        delete: ["UserAdministrator", "ChiefAccountant"]
      }
    },
    reports: {
      general: {
        legalLog: ["ChiefSecretary"],
        contactReports: ["ChiefSecretary", "ChiefAccountant", "ChiefTechnician"],
        pathologistsReports: ["ChiefSecretary"],
        cytotechniciansReports: ["UserAdministrator"],
        resultsReports: ["UserAdministrator"],
        filingReports: ["UserAdministrator"],
        filesReports: ["ChiefSecretary", "ChiefAccountant", "ChiefTechnician"],
        statistics: ["Secretary", "Technician", "Cytotechnician", "Accountant"]
      }
    }
  }
};

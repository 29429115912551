// services
import filesServices from "@/services/API/filesServices";
// helpers
import price from "@shared/services/UI/price";
import invoicesHelpers from "@shared/services/UI/invoicesHelpers";

export default {
  mixins: [price, invoicesHelpers],
  methods: {
    async mixinGetAccountingHistory(fileId, parentFileId, complementaryFileIds) {
      const res = await filesServices.getAccountingHistory({
        id: fileId,
        parentFileId: parentFileId,
        complementaryFileIds: complementaryFileIds
      });
      const accountingHistory = res.data; // returns files [], awaitingPayments [], invoices []

      const result = {
        historyDataFiles: [],
        awaitingFileInvoices: [],
        totalAwaitingFileInvoices: 0,
        invoices: [],
        totalInvoiced: 0,
        awaitingPayments: [],
        totalAwaitingPayments: 0,
        payments: [],
        totalPaid: 0,
        compensationPayments: [],
        totalCompensations: 0
      };

      // sorting chronologically
      accountingHistory.files.sort((a, b) => a.id - b.id);

      // format files
      accountingHistory.files.map((file) => {
        // awaitingFileInvoices
        file.awaitingFileInvoices = file.awaitingFileInvoices.map((awaitingFileInvoice) => {
          awaitingFileInvoice.invoiced = this.importFormatPrice(awaitingFileInvoice.invoiced);
          awaitingFileInvoice.invoiceActs.map((invoiceAct) => {
            invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
            invoiceAct.act.price = this.importFormatPrice(invoiceAct.act.price);
            return invoiceAct;
          });
          return awaitingFileInvoice;
        });
        // compensationPayments
        if (!file.compensationAmount) file.compensationAmount = 0;
        for (const compensationPayment of file.compensationPayments) {
          compensationPayment.paid = this.importFormatPrice(compensationPayment.paid);
          compensationPayment.compensation.paid = this.importFormatPrice(compensationPayment.compensation.paid);
          compensationPayment.file = file;
          file.compensationAmount += compensationPayment.paid;
          result.compensationPayments.push(compensationPayment);
        }
        return file;
      });

      // historyDataFiles
      result.historyDataFiles = accountingHistory.files;

      // setup awaitingFileInvoices && totalCompensations
      for (const historyDataFile of result.historyDataFiles) {
        for (const awaitingFileInvoice of historyDataFile.awaitingFileInvoices) {
          result.awaitingFileInvoices.push(awaitingFileInvoice);
          result.totalAwaitingFileInvoices += awaitingFileInvoice.invoiced;
        }
        result.totalCompensations += historyDataFile.compensationAmount;
      }

      // setup invoices
      result.invoices = accountingHistory.invoices.map((invoice) => {
        return this.importFormatInvoice(invoice, invoice.invoiceType);
      });
      // setup totalInvoiced
      for (const invoice of result.invoices) {
        for (const fileInvoice of invoice.fileInvoices) {
          if (!fileInvoice.fileInvoiceTransformations || !fileInvoice.fileInvoiceTransformations.length) {
            result.totalInvoiced += fileInvoice.invoiced;
            // setup the invoiced amount per file
            for (const historyDataFile of result.historyDataFiles) {
              if (fileInvoice.fileId === historyDataFile.id) {
                historyDataFile.invoiced = (historyDataFile.invoiced || 0) + fileInvoice.invoiced;
              }
            }
          }
        }
      }

      // setup payments
      const payments = [];
      for (const invoice of result.invoices) {
        for (const fileInvoice of invoice.fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            for (const paymentAct of invoiceAct.paymentActs) {
              // format paymentAct
              const formattedPaymentAct = {
                id: paymentAct.id,
                act: invoiceAct.act,
                paid: paymentAct.paid
              };
              // filePayment
              const formattedFilePayment = {
                id: paymentAct.filePayment.id,
                paid: paymentAct.filePayment.paid,
                file: fileInvoice.file,
                paymentActs: [formattedPaymentAct]
              };
              // filePaymentPayment
              let filePaymentPayment = null;
              if (paymentAct.filePayment.healthCarePaymentGroup) {
                filePaymentPayment = paymentAct.filePayment.healthCarePaymentGroup.healthCarePayment;
                filePaymentPayment.paymentCategory = "healthCarePayment";
                filePaymentPayment.paymentGroupId = paymentAct.filePayment.healthCarePaymentGroup.id;
              } else if (paymentAct.filePayment.paymentGroup) {
                filePaymentPayment = paymentAct.filePayment.paymentGroup.paymentReport;
                filePaymentPayment.paymentCategory = "paymentReport";
                filePaymentPayment.paymentGroupId = paymentAct.filePayment.paymentGroup.id;
              } else if (paymentAct.filePayment.patientPayment) {
                filePaymentPayment = paymentAct.filePayment.patientPayment;
                filePaymentPayment.paymentCategory = "patientPayment";
                filePaymentPayment.paymentGroupId = null;
              }
              filePaymentPayment.paid = this.importFormatPrice(filePaymentPayment.paid);
              filePaymentPayment.filePayments = [formattedFilePayment];

              // payment
              const paymentPosition = payments.findIndex(v => v.paymentCategory === filePaymentPayment.paymentCategory && v.id === filePaymentPayment.id);
              if (paymentPosition === -1) {
                payments.push(filePaymentPayment);
              } else {
                // filePayment
                const filePaymentPosition = payments[paymentPosition].filePayments.findIndex(v => v.id === formattedFilePayment.id);
                if (filePaymentPosition === -1) {
                  payments[paymentPosition].filePayments.push(formattedFilePayment);
                } else {
                  // paymentAct
                  const paymentActPosition = payments[paymentPosition].filePayments[filePaymentPosition].paymentActs.findIndex(v => v.id === formattedPaymentAct.id);
                  if (paymentActPosition === -1) {
                    payments[paymentPosition].filePayments[filePaymentPosition].paymentActs.push(formattedPaymentAct);
                  }
                }
              }
            }
          }
        }
      }
      // setup currentFilesPaid (the part paid for the currently displayed fileIds)
      for (const payment of payments) {
        payment.currentFilesPaid = payment.filePayments.reduce((total, item) => { return total + item.paid; }, 0);
        result.totalPaid += payment.currentFilesPaid;
      }
      result.payments = payments;

      // setup awaitingPayments
      result.awaitingPayments = accountingHistory.awaitingPayments.map((payment) => {
        payment.paid = this.importFormatPrice(payment.paid);
        return payment;
      });
      result.totalAwaitingPayments = result.awaitingPayments.reduce((total, current) => { return total + (current.paid || 0); }, 0);

      // setup the paid amount per file
      for (const payment of payments) {
        for (const filePayment of payment.filePayments) {
          for (const historyDataFile of result.historyDataFiles) {
            if (filePayment.file.id === historyDataFile.id) {
              historyDataFile.paid = (historyDataFile.paid || 0) + filePayment.paid;
            }
          }
        }
      }
      // setup the rest amount per file
      for (const historyDataFile of result.historyDataFiles) {
        historyDataFile.rest = (historyDataFile.invoiced || 0) - (historyDataFile.paid || 0);
      }

      return result;
    }
  }
};

<template>
  <div class="kt-task-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <b-button
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- task form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- edit Warning -->
        <b-col
          v-show="editModeProp"
          cols="12"
        >
          <div
            class="kt-badge kt-badge--yellow kt-badge--lg mt-n1 mb-2"
          >
            <b-icon
              icon="exclamation-triangle-fill"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text font-weight-semi-bold">{{ $t("tasks.editWarning") }}</span>
          </div>
        </b-col>

        <!-- laboratories -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('tasks.laboratories')"
            :invalid-feedback="taskLaboratoriesInvalidFeedback"
            :state="taskLaboratoriesState"
          >
            <b-form-checkbox
              v-for="taskLaboratory in taskLaboratoriesOptions"
              v-show="taskLaboratory.isVisible"
              :ref="taskLaboratory.isVisible ? 'laboratoryInputs' : null"
              :key="taskLaboratory.id"
              v-model="taskLaboratories"
              :value="taskLaboratory.id"
              :disabled="taskLaboratory.disabled"
              :state="taskLaboratoriesState"
            >
              {{ taskLaboratory.formattedName }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- sector -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="sectorsOptions.length"
            :labelProp="$t('files.sector')"
            :optionsProp="sectorsOptions"
            valueFieldProp="id"
            textFieldProp="localisedName"

            :valueProp="sectorId"
            :stateProp="sectorIdState"
            :invalidFeedbackProp="sectorIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="taskData !== null ? taskData.sectorId : null"
            @input="sectorId = $event; content = null; contentValidation = false; contentState = false;"
          />
        </b-col>
        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('tasks.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- adicap -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('tasks.adicap')"
            :invalid-feedback="adicapInvalidFeedback"
            :state="adicapState"
          >
            <b-form-input
              v-model="adicap"
              trim
              maxlength="50"
              :state="adicapState"
              @blur="adicapValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- description -->
        <b-col
          cols="12"
        >
          <b-form-group
            :label="$t('tasks.description')"
            :invalid-feedback="descriptionInvalidFeedback"
            :state="descriptionState"
          >
            <b-form-input
              v-model="description"
              trim
              maxlength="256"
              :state="descriptionState"
              @blur="descriptionValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-3"></div>
          <h5 class="kt-section-title">
            {{ $t("tasks.taskActs") }}
          </h5>
        </b-col>
        <!-- TaskActs - list -->
        <b-col cols="12">
          <TaskActs
            hideLabelProp
            :taskActsProp="taskActs"
            :validationActiveProp="taskActsValidation"
            @taskActsUpdate="taskActs = $event"
            @updateIsValid="taskActsState = $event"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- Task Content (advanced parameters) -->
        <b-col cols="12">
          <TaskCytologyContent
            v-if="sectorId === 1"
            :editModeProp="editModeProp"
            :contentProp="content"
            :contentValidationProp="contentValidation"
            @updateContent="content = $event"
            @updateIsValid="contentState = $event"
          />
          <TaskHistologyContent
            v-if="sectorId === 2"
            :editModeProp="editModeProp"
            :contentProp="content"
            :contentValidationProp="contentValidation"
            @updateContent="content = $event"
            @updateIsValid="contentState = $event"
          />
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-3"></div>
          <h5 class="kt-section-title">
            {{ $t("tasks.validityDateFull") }}
          </h5>
        </b-col>
        <!-- startValidityDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('tasks.startValidityDate')"
            :invalid-feedback="startValidityDateInvalidFeedback"
            :state="startValidityDateState"
          >
            <b-form-input
              v-model="startValidityDate"
              type="date"
              :state="startValidityDateState"
              @blur="startValidityDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- endValidityDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('tasks.endValidityDate')"
            :invalid-feedback="endValidityDateInvalidFeedback"
            :state="endValidityDateState"
          >
            <b-form-input
              v-model="endValidityDate"
              type="date"
              :state="endValidityDateState"
              @blur="endValidityDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-2"></div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import TaskActs from "@/views/Data/TaskActs";
import TaskCytologyContent from "@/views/Data/Tasks/TaskCytologyContent";
import TaskHistologyContent from "@/views/Data/Tasks/TaskHistologyContent";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { TaskActs, TaskCytologyContent, TaskHistologyContent, SmartSelectInput },
  mixins: [userRights, error, price, date],
  props: {
    taskIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      taskId: this.taskIdProp,
      taskData: null,
      // form variables
      taskLaboratories: [],
      sectorId: null,
      code: "",
      adicap: "",
      description: "",
      startValidityDate: "",
      endValidityDate: "",
      taskActs: [],
      content: null,
      // is validation active
      taskLaboratoriesValidation: false,
      sectorIdValidation: false,
      codeValidation: false,
      adicapValidation: false,
      descriptionValidation: false,
      startValidityDateValidation: false,
      endValidityDateValidation: false,
      taskActsValidation: false,
      contentValidation: false,
      // objects validation
      taskActsState: null,
      taskActsInvalidFeedback: "",
      contentState: false,
      // options
      taskLaboratoriesOptions: [],
      sectorsOptions: []
    };
  },
  computed: {
    // form validation
    taskLaboratoriesState: function() {
      if (!this.taskLaboratoriesValidation) return null;
      return this.taskLaboratories && this.taskLaboratories.length > 0 ? null : false;
    },
    taskLaboratoriesInvalidFeedback: function() {
      return this.taskLaboratoriesState === false ? this.$t("validationRules.required") : "";
    },
    sectorIdState: function() {
      if (!this.sectorIdValidation) return null;
      return this.sectorId && this.sectorId > 0 ? null : false;
    },
    sectorIdInvalidFeedback: function() {
      return this.sectorIdState === false ? this.$t("validationRules.required") : "";
    },
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    adicapState: function() {
      if (!this.adicapValidation) return null;
      return this.adicap && this.adicap.length > 0 && this.adicap.length <= 50 ? null : false;
    },
    adicapInvalidFeedback: function() {
      // no error
      if (this.adicapState === null) return "";
      // if empty
      if (!this.adicap || this.adicap.length === 0) return this.$t("validationRules.required");
      // else : too long
      return this.$t("taskEdit.validations.adicapTooLong");
    },
    descriptionState: function() {
      if (!this.descriptionValidation) return null;
      return this.description && this.description.length > 0 ? null : false;
    },
    descriptionInvalidFeedback: function() {
      return this.descriptionState === false ? this.$t("validationRules.required") : "";
    },
    startValidityDateState: function() {
      if (!this.startValidityDateValidation) return null;
      return this.startValidityDate && this.startValidityDate.length > 0 ? null : false;
    },
    startValidityDateInvalidFeedback: function() {
      return this.startValidityDateState === false ? this.$t("validationRules.required") : "";
    },
    endValidityDateState: function() {
      return null;
    },
    endValidityDateInvalidFeedback: function() {
      return "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("taskEdit.pageTitle") : this.$t("taskNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("taskEdit.submitText") : this.$t("taskNew.submitText");
    }
  },
  async mounted() {
    // import data
    this.importSectors();
    await this.importData();
    this.importLaboratories();

    if (!this.editModeProp) {
      // select all active laboratories by default
      const filteredLaboratories = this.taskLaboratoriesOptions.filter((laboratory) => {
        return !laboratory.disabled && laboratory.isActive && laboratory.isVisible;
      });
      this.taskLaboratories = filteredLaboratories.map((laboratory) => {
        return laboratory.id;
      });
      // by default, startValidityDate is today
      this.startValidityDate = this.getIsoDate();
    }

    // auto-focus
    await this.$nextTick();
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.taskLaboratories && this.taskLaboratories.length) {
        // has selected laboratories
        for (const laboratoryInput of this.$refs.laboratoryInputs) {
          if (this.taskLaboratories.includes(laboratoryInput.value)) {
            if (laboratoryInput.disabled === false) {
              laboratoryInput.focus();
              break;
            }
          }
        }
      } else {
        // empty laboratories
        if (this.$refs.laboratoryInputs.length) {
          this.$refs.laboratoryInputs[0].focus();
        }
      }
    },

    // import data
    importLaboratories() {
      this.taskLaboratoriesOptions = JSON.parse(JSON.stringify(this.$systemSettings.laboratories));
      for (const taskLaboratory of this.taskLaboratoriesOptions) {
        taskLaboratory.isVisible = true;
        if (taskLaboratory.isActive === false) {
          // if deleted : laboratory not visible
          taskLaboratory.isVisible = false;
          taskLaboratory.disabled = true;
          // check is selected : laboratory visible
          for (const selectedTaskLaboratory of this.taskLaboratories) {
            if (selectedTaskLaboratory === taskLaboratory.id) {
              taskLaboratory.isVisible = true;
              taskLaboratory.name += " (" + this.$t("general.deletedLabel") + ")";
              break;
            }
          }
        }
      }
    },
    importSectors() {
      const sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.sectorsOptions = sectorsOptions.map((sector) => {
        sector.isActive = sector.isSubscribed;
        sector.disabled = !sector.isSubscribed;
        return sector;
      });
    },
    async importData() {
      try {
        if (this.editModeProp) {
          // get task
          const resServices = await commonServices.get("tasks", this.taskId);
          this.taskData = resServices.data;
          // import data
          this.taskLaboratories = resServices.data.taskLaboratories.map((taskLaboratory) => {
            return taskLaboratory.laboratoryId;
          });
          this.sectorId = resServices.data.sectorId;
          this.code = resServices.data.code;
          this.adicap = resServices.data.adicap;
          this.description = resServices.data.description;
          this.startValidityDate = resServices.data.startValidityDate;
          this.endValidityDate = resServices.data.endValidityDate;
          const taskActs = resServices.data.taskActs;
          for (const taskAct of taskActs) {
            for (const additionalFee of taskAct.additionalFees) {
              additionalFee.price = this.importFormatPrice(additionalFee.price);
              additionalFee.areDatesOverlapping = false;
            }
          }
          this.taskActs = taskActs;
          this.content = resServices.data.content;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // submit
    async onValidateTask() {
      this.taskLaboratoriesValidation = true;
      this.sectorIdValidation = true;
      this.codeValidation = true;
      this.adicapValidation = true;
      this.descriptionValidation = true;
      this.startValidityDateValidation = true;
      this.endValidityDateValidation = true;
      this.taskActsValidation = true;
      this.contentValidation = true;
      await this.$nextTick();

      return !(
        this.taskLaboratoriesState === false ||
        this.sectorIdState === false ||
        this.codeState === false ||
        this.adicapState === false ||
        this.descriptionState === false ||
        this.startValidityDateState === false ||
        this.endValidityDateState === false ||
        this.taskActsState === false ||
        this.contentState === false
      );
    },
    async onSaveTask() {
      try {
        const taskActs = this.taskActs.map((taskAct) => {
          const additionalFees = taskAct.additionalFees.map((additionalFee) => {
            return {
              additionalFeeGroupId: additionalFee.additionalFeeGroupId,
              price: this.exportFormatPrice(additionalFee.price),
              endValidityDate: additionalFee.endValidityDate || null,
              startValidityDate: additionalFee.startValidityDate || null
            };
          });
          return {
            actId: taskAct.actId,
            additionalFees: additionalFees,
            endValidityDate: taskAct.endValidityDate || null,
            startValidityDate: taskAct.startValidityDate || null
          };
        });
        const form = {
          code: this.code,
          adicap: this.adicap,
          description: this.description,
          startValidityDate: this.startValidityDate || null,
          endValidityDate: this.endValidityDate || null,
          sectorId: this.sectorId,
          taskLaboratories: this.taskLaboratories,
          taskActs: taskActs,
          content: this.content
        };

        if (this.editMode) {
          const res = await commonServices.put("tasks", form, this.taskId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("taskEdit.notifications.editedTitle"),
              message: this.$t("taskEdit.notifications.editedText")
            });
            navigate("data/tasks");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("taskEdit.notifications.editionErrorTitle"),
              message: this.$t("taskEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("tasks", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("taskNew.notifications.addedTitle"),
              message: this.$t("taskNew.notifications.addedText")
            });
            navigate("data/tasks");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("taskNew.notifications.additionErrorTitle"),
              message: this.$t("taskNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onSubmit() {
      const validation = await this.onValidateTask();
      if (validation !== false) {
        await this.onSaveTask();
      }
    },
    // go back to tasks
    onGoBack() {
      navigate("data/tasks");
    }
  }
};
</script>

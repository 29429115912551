<template>
  <div class="kt-choose-contact">
    <!-- title for filtering organisation -->
    <div
      v-if="organisationName"
      class="mb-2"
    >
      <!-- isActiveFilterOrganisation -->
      <b-form-group>
        <b-form-checkbox
          v-model="isActiveFilterOrganisation"
          switch
          @input="searchAndUpdateTable"
        >
          {{ $t("contactChoose.filteringOrganisationTitle") }} <strong>{{ organisationName }}</strong>
        </b-form-checkbox>
      </b-form-group>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="contactFields"
      :items="contacts"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onDoctorSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="filterCodeInput"
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="searchAndUpdateTable"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 85px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap"
              @click="searchAndUpdateTable(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="(!contacts || !contacts.length) && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("contactChoose.notFound") }}
      </h4>
    </div>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3 mb-2"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    organisationIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      organisationName: "",
      isActiveFilterOrganisation: true,
      // table fields
      contactFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: true,
          label: this.$t("contacts.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: true,
          label: this.$t("contacts.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: true,
          label: this.$t("contacts.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "actions",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterCode: "",
      filterFirstName: "",
      filterLastName: "",
      // table items
      contacts: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterCode ||
        this.filterFirstName ||
        this.filterLastName
      );
    }
  },
  async mounted() {
    await this.importOrganisation();
  },
  methods: {
    focusFirstElement() {
      this.$refs.filterCodeInput.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },

    async importOrganisation() {
      try {
        if (this.organisationIdProp) {
          const res = await commonServices.get("organisations", this.organisationIdProp);
          if (res.data) {
            this.organisationName = res.data.name + (res.data.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
          } else {
            this.organisationName = "";
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // update the table
    async searchAndUpdateTable(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // if no filter
        if (this.isSearchActive === false) {
          this.contacts = [];
          this.searchHasChanged = false;
          this.maxReached = false;
          return false;
        }

        // get the table items and update
        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          code: this.filterCode,
          firstName: this.filterFirstName,
          lastName: this.filterLastName,
          organisationId: this.isActiveFilterOrganisation ? this.organisationIdProp : null
        };

        const res = await commonServices.getAll("contacts", filters);
        this.contacts = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.contacts.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.filterCodeInput.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // on select
    onDoctorSelected(rows) {
      if (rows && rows.length === 1) {
        this.$emit("chosen", rows[0]);
      }
    }
  }
};
</script>

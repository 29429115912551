export default {
  methods: {
    errorModal(strings) {
      return this.$bvModal.msgBoxOk(strings.stack, {
        title: "Error: " + strings.title,
        noCloseOnBackdrop: true,
        size: "xl"
      });
    },
    async handleErrors(error) {
      if (error === "userNotLoggedIn" || error === "appReloadRequired") {
        console.log("handleErrors: " + error);
      } else if (error.response && error.response.data) {
        if (Object.prototype.toString.call(error.response.data) === "[object Blob]") {
          error.response.data = JSON.parse(await error.response.data.text());
        }
        this.errorModal({
          title: this.$t(error.response.data.title),
          stack: this.$t(JSON.stringify(error.response.data.stack))
        });
      } else {
        console.log(error);
      }
    }
  }
};

<template>
  <div
    class="kt-item-list__item"
    style="width: 100%"
  >
    <div v-if="!viewModeProp">
      <b-row>
        <!-- additionalFeeGroup -->
        <b-col
          cols="12"
          md="3"
        >
          <SmartSelectInput
            v-if="additionalFeeGroupsOptionsProp && additionalFeeGroupsOptionsProp.length > 0"
            :labelProp="$t('additionalFees.additionalFeeGroup')"
            :optionsProp="additionalFeeGroupsOptionsProp"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="additionalFeeGroupId"
            :stateProp="additionalFeeGroupStateDisplay"
            :invalidFeedbackProp="additionalFeeGroupInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="true"
            :showInactiveItemsProp="true"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="additionalFeeGroupId === null"
            :initialValueProp="additionalFeeGroupId"
            :responsiveOptionsProp="{cols: 12, md: 12}"
            formGroupClassProp="mb-2"
            forceSelectInputProp
            @input="($event) => {
              additionalFeeGroupId = $event;
              const filteredOptions = additionalFeeGroupsOptionsProp.filter((v) => v.id === $event);
              additionalFeeGroup = filteredOptions.length === 1 ? filteredOptions[0] : null;
              sendToparent();
              additionalFeeGroupValidation = true;
            }"
          />
        </b-col>
        <!-- price -->
        <b-col
          cols="12"
          md="3"
        >
          <InputPrice
            class="mb-2"
            :labelProp="$t('additionalFees.price')"
            :priceProp="price"
            :stateProp="priceStateDisplay"
            :invalidFeedbackProp="priceInvalidFeedback"
            @onUpdateParent="($event) => {
              price = $event;
              sendToparent();
            }"
            @onActivateValidation="priceValidation = $event"
          />
        </b-col>
        <!-- ValidityDates -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('taskActs.startValidityDate')"
            :invalid-feedback="startValidityDateInvalidFeedback"
            :state="startValidityDateStateDisplay"
            class="mb-2"
          >
            <b-form-input
              v-model="startValidityDate"
              type="date"
              :state="startValidityDateStateDisplay"
              @blur="startValidityDateValidation = true"
              @input="sendToparent"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('taskActs.endValidityDate')"
            :invalid-feedback="endValidityDateInvalidFeedback"
            :state="endValidityDateStateDisplay"
            class="mb-2"
          >
            <b-form-input
              v-model="endValidityDate"
              type="date"
              :state="endValidityDateStateDisplay"
              @blur="endValidityDateValidation = true"
              @input="sendToparent"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <!-- viewMode -->
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <div>{{ additionalFeeGroup.name }}</div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div>{{ isNaN(price) ? $t("general.emptyWithHyphen") : $n(price, "currency") }}</div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div>{{ $t("taskActs.additionalFeeValidityDateView") }}{{ (startValidityDate ? $d(new Date(startValidityDate), "date") : "NC") + " - " + (endValidityDate ? $d(new Date(endValidityDate), "date") : "NC") }}</div>
        </b-col>
      </b-row>
    </div>

    <!-- actions -->
    <div class="kt-item-list__item-actions">
      <!-- delete button -->
      <b-button
        v-if="!viewModeProp"
        class="btn-icon kt-item-list__item-delete"
        size="sm"
        variant="light"
        squared
        @click="$emit('deleteAdditionalFee', tempId)"
      >
        <b-icon icon="x"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
// components
import InputPrice from "@shared/views/Helpers/InputPrice";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// helpers
import userRights from "@/services/UI/userRights";
import date from "@shared/services/UI/date";

export default {
  components: {
    InputPrice,
    SmartSelectInput
  },
  mixins: [userRights, date],
  props: {
    viewModeProp: {
      type: Boolean
    },
    additionalFeeProp: {
      type: Object,
      default: () => {
        return {
          id: null,
          tempId: "",
          additionalFeeGroupId: null,
          additionalFeeGroup: null,
          price: 0,
          startValidityDate: "",
          endValidityDate: "",
          areDatesOverlapping: false
        };
      },
      deep: true
    },
    validationActiveProp: {
      type: Boolean
    },
    additionalFeeGroupsOptionsProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    areDatesOverlappingProp: {
      type: Boolean
    }
  },
  data() {
    return {
      id: null,
      tempId: "",
      additionalFeeGroupId: null,
      additionalFeeGroup: null,
      price: 0,
      startValidityDate: "",
      endValidityDate: "",
      // is validation active
      additionalFeeGroupValidation: this.validationActiveProp,
      priceValidation: this.validationActiveProp,
      startValidityDateValidation: this.validationActiveProp,
      endValidityDateValidation: this.validationActiveProp
    };
  },
  computed: {
    additionalFeeGroupState: function() {
      return this.additionalFeeGroup ? null : false;
    },
    additionalFeeGroupStateDisplay: function() {
      if (!this.additionalFeeGroupValidation) return null;
      return this.additionalFeeGroupState;
    },
    additionalFeeGroupInvalidFeedback: function() {
      return this.additionalFeeGroupStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    priceState: function() {
      return this.price !== "" && this.price !== 0 ? null : false;
    },
    priceStateDisplay: function() {
      if (!this.priceValidation) return null;
      return this.priceState;
    },
    priceInvalidFeedback: function() {
      return this.priceStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    startValidityDateState_withoutOverlapping: function() {
      return this.startValidityDate && this.startValidityDate.length > 0 ? null : false;
    },
    startValidityDateState: function() {
      if (this.startValidityDateState_withoutOverlapping === false) return false;
      if (this.areDatesOverlappingProp) return false;
      return null;
    },
    startValidityDateStateDisplay: function() {
      if (!this.startValidityDateValidation) return null;
      return this.startValidityDateState;
    },
    startValidityDateInvalidFeedback: function() {
      if (this.startValidityDateStateDisplay === null) return "";
      if (!this.areDatesOverlappingProp) {
        return this.$t("validationRules.required");
      } else {
        return this.$t("additionalFees.validations.overlappingDates");
      }
    },
    endValidityDateState_withoutOverlapping: function() {
      if (!this.endValidityDate) return null;
      return this.validateDateBeforeDate(this.startValidityDate, this.endValidityDate) ? null : false;
    },
    endValidityDateState: function() {
      if (this.endValidityDateState_withoutOverlapping === false) return false;
      if (this.areDatesOverlappingProp) return false;
      return null;
    },
    endValidityDateStateDisplay: function() {
      if (!this.endValidityDateValidation) return null;
      return this.endValidityDateState;
    },
    endValidityDateInvalidFeedback: function() {
      if (this.endValidityDateStateDisplay === null) return "";
      if (!this.areDatesOverlappingProp) {
        return this.$t("validationRules.endDateBeforeStartDate");
      } else {
        return this.$t("additionalFees.validations.overlappingDates");
      }
    },
    isValid: function() {
      return (
        this.additionalFeeGroupState !== false &&
        this.priceState !== false &&
        this.startValidityDateState !== false &&
        this.endValidityDateState !== false
      ) ? null : false;
    }
  },
  watch: {
    additionalFeeProp: {
      handler: function(newVal) {
        const value = JSON.parse(JSON.stringify(newVal));
        this.id = value.id || null;
        this.tempId = value.tempId || "";
        // update only if different or empty
        if (value.additionalFeeGroupId !== this.additionalFeeGroupId) {
          this.additionalFeeGroupId = value.additionalFeeGroupId;
          this.additionalFeeGroup = value.additionalFeeGroup;
        }
        if (value.price !== this.price) this.price = value.price;
        if (value.startValidityDate !== this.startValidityDate) this.startValidityDate = value.startValidityDate;
        if (value.endValidityDate !== this.endValidityDate) this.endValidityDate = value.endValidityDate;
      },
      deep: true,
      immediate: true
    },
    // activate validation by prop
    validationActiveProp: function(value, oldValue) {
      if (value !== oldValue) {
        this.additionalFeeGroupValidation = value;
        this.priceValidation = value;
        this.startValidityDateValidation = value;
        this.endValidityDateValidation = value;
        this.sendIsValid();
      }
    },
    areDatesOverlappingProp: function(value, oldValue) {
      if (value !== oldValue) {
        this.sendIsValid();
      }
    }
  },
  methods: {
    // validation
    sendIsValid() {
      this.$emit("updateIsValid", this.isValid);
    },
    // on update / finished
    sendToparent() {
      this.$emit("updateAdditionalFee", {
        id: this.id,
        tempId: this.tempId,
        areDatesOverlapping: this.areDatesOverlappingProp,
        additionalFeeGroupId: this.additionalFeeGroupId,
        additionalFeeGroup: this.additionalFeeGroup,
        price: this.price,
        startValidityDate: this.startValidityDate,
        endValidityDate: this.endValidityDate
      });
      this.sendIsValid();
    }
  }
};
</script>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  getAllByAccessNumber(accessNumber) {
    return axiosServices.axios.get("/histologyReports/getAllByAccessNumber", { params: { accessNumber: accessNumber } });
  },
  getIdByFileId(fileId) {
    return axiosServices.axios.get("/histologyReports/getIdByFileId", { params: { fileId: fileId } });
  },
  getAllByFileId(fileId) {
    return axiosServices.axios.get("/histologyReports/getAllByFileId", { params: { fileId: fileId } });
  },
  getAllByCytotechnician(page, filter, sort) {
    return axiosServices.axios.get(
      "/histologyReports/getAllByCytotechnician", {
        params: {
          page: page,
          filter: filter,
          sort: sort
        }
      });
  },
  getAllByPathologist(page, filter, sort) {
    return axiosServices.axios.get(
      "/histologyReports/getAllByPathologist", {
        params: {
          page: page,
          filter: filter,
          sort: sort
        }
      });
  },
  newVersion(id) {
    return axiosServices.axios.post("/histologyReports/newVersion", { id: id });
  },
  validateMedically(fileId) {
    return axiosServices.axios.put("/histologyReports/validateMedically", { fileId: fileId });
  }
};

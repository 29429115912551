<template>
  <div class="kt-awaitingHpvResult-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- awaitingHpvResult form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- timestamp -->
        <b-col
          v-show="editMode"
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('awaitingHpvResults.timestamp')"
          >
            <b-form-input
              v-model="timestamp"
              trim
              maxlength="50"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- divider -->
        <b-col cols="12"></b-col>

        <!-- instrument -->
        <b-col
          cols="12"
          md="4"
          class="mb-3"
        >
          <InputSearch
            searchedTableProp="instruments"
            searchedFieldProp="networkName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'networkName', 'model', 'brand', 'serialNumber', 'isActive']"
            suggestionsKeyProp="id"
            :labelProp="$t('awaitingHpvUpload.instrument')"
            :placeholderProp="$t('awaitingHpvUpload.instrumentPlaceholder')"
            :valueProp="instrument"
            :displayedValueProp="suggestionFormatInstruments(instrument)"
            :suggestionFormatProp="suggestionFormatInstruments"

            chooseComponentPathProp="views/Data/Instruments/InstrumentChoose.vue"
            :chooseOptionsProp="{}"
            :initialFilterValuesProp="{}"

            :stateProp="instrumentState"
            :invalidFeedbackProp="instrumentInvalidFeedback"
            @onUpdateParent="
              instrument = $event;
              instrumentId = instrument ? instrument.id : 0;
            "
            @onActivateValidation="instrumentValidation = true;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- accessNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('awaitingHpvResults.accessNumber')"
            :invalid-feedback="accessNumberInvalidFeedback"
            :state="accessNumberState"
          >
            <b-form-input
              v-model="accessNumber"
              trim
              maxlength="50"
              :state="accessNumberState"
              @blur="accessNumberValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- content -->
        <b-col cols="12">
          <b-form-group
            :label="$t('awaitingHpvResults.content')"
            :invalid-feedback="contentSelectedInvalidFeedback"
            :state="contentSelectedState"
          >
            <b-form-checkbox-group
              v-model="contentSelected"
              :options="contentOptions"
              value-field="value"
              text-field="text"
              :state="contentSelectedState"
              :disabled="isIndeterminate"
            ></b-form-checkbox-group>
            <b-form-checkbox
              v-model="isIndeterminate"
              :state="contentSelectedState"
              @input="contentSelected = []"
            >
              {{ $t('awaitingHpvResults.isIndeterminate') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearch },
  mixins: [userRights, error, date],
  props: {
    awaitingHpvResultIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      awaitingHpvResultData: null,
      // general
      editMode: this.editModeProp,
      awaitingHpvResultId: this.awaitingHpvResultIdProp,
      // form variables
      instrument: null,
      instrumentId: null,
      timestamp: "",
      accessNumber: "",
      contentSelected: [],
      isIndeterminate: false,
      // is validation active
      instrumentValidation: false,
      accessNumberValidation: false,
      contentSelectedValidation: false,
      // options
      contentOptions: [
        { text: "16", value: "16" },
        { text: "18", value: "18" },
        { text: "45", value: "45" },
        { text: "31", value: "31" },
        { text: "51", value: "51" },
        { text: "52", value: "52" },
        { text: "33/58", value: "33/58" },
        { text: "56/59/66", value: "56/59/66" },
        { text: "35/39/68", value: "35/39/68" }
      ]
    };
  },
  computed: {
    // form validation
    instrumentState: function() {
      if (!this.instrumentValidation) return null;
      return this.instrument && this.instrument.id ? null : false;
    },
    instrumentInvalidFeedback: function() {
      return this.instrumentState === false ? this.$t("validationRules.required") : "";
    },
    accessNumberState: function() {
      if (!this.accessNumberValidation) return null;
      return this.accessNumber && this.accessNumber.length > 0 ? null : false;
    },
    accessNumberInvalidFeedback: function() {
      return this.accessNumberState === false ? this.$t("validationRules.required") : "";
    },
    contentSelectedState: function() {
      if (!this.contentSelectedValidation) return null;
      if (this.isIndeterminate && this.contentSelected.length === 0) return null;
      if (!this.isIndeterminate && this.contentSelected.length >= 0) return null;
      return false;
    },
    contentSelectedInvalidFeedback: function() {
      if (this.contentSelectedState === null) return "";
      if (this.isIndeterminate && this.contentSelected.length > 0) return this.$t("awaitingHpvResults.validations.resultIndeterminateNotEmpty");
      return this.$t("validationRules.required");
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("awaitingHpvResultEdit.pageTitle", { id: this.awaitingHpvResultIdProp }) : this.$t("awaitingHpvResultNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("awaitingHpvResultEdit.submitText") : this.$t("awaitingHpvResultNew.submitText");
    }
  },
  async mounted() {
    // import data
    await this.importData();
  },
  methods: {
    // format instrument suggestion
    suggestionFormatInstruments(suggestion) {
      let output = "";
      if (suggestion) {
        output = suggestion.networkName + " " + suggestion.model + " " + suggestion.brand + " (" + suggestion.serialNumber + ")";
        if (suggestion.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get awaitingHpvResult
          const resServices = await commonServices.get("hpvResults", this.awaitingHpvResultId);
          this.awaitingHpvResultData = resServices.data;
          // import data
          this.instrument = resServices.data.instrument;
          this.instrumentId = resServices.data.instrumentId;
          this.timestamp = this.getFormattedDateTimeFromTimestamp(resServices.data.timestamp);
          this.accessNumber = resServices.data.accessNumber;
          this.contentSelected = resServices.data.content === "-" ? [] : resServices.data.content.split(";");
          if (this.contentSelected.includes("INDETERMINATE")) {
            this.isIndeterminate = true;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("diagnosis/awaitingHpvResults");
    },
    // submit
    onValidateAwaitingHpvResult() {
      this.instrumentValidation = true;
      this.accessNumberValidation = true;
      this.contentSelectedValidation = true;

      return !(
        this.instrumentState === false ||
        this.accessNumberState === false ||
        this.contentSelectedState === false
      );
    },
    async onSaveAwaitingHpvResult() {
      try {
        // format content for export
        let contentSelectedExport = "";
        if (this.isIndeterminate) {
          contentSelectedExport = "INDETERMINATE";
        } else {
          contentSelectedExport = (this.contentSelected.length === 0) ? "-" : this.contentSelected.join(";");
        }
        const form = {
          instrumentId: this.instrumentId,
          accessNumber: this.accessNumber,
          content: contentSelectedExport
        };
        if (this.editMode) {
          const res = await commonServices.put("hpvResults", form, this.awaitingHpvResultId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("awaitingHpvResultEdit.notifications.editedTitle"),
              message: this.$t("awaitingHpvResultEdit.notifications.editedText")
            });
            navigate("diagnosis/awaitingHpvResults");
          } else if (res.data.error === "existingHpvResult") {
            this.$emit("alert", "danger", {
              title: this.$t("awaitingHpvResults.notifications.existingHpvResultTitle"),
              message: this.$t("awaitingHpvResults.notifications.existingHpvResultText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("awaitingHpvResultEdit.notifications.editionErrorTitle"),
              message: this.$t("awaitingHpvResultEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("hpvResults", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("awaitingHpvResultNew.notifications.addedTitle"),
              message: this.$t("awaitingHpvResultNew.notifications.addedText")
            });
            navigate("diagnosis/awaitingHpvResults");
          } else if (res.data.error === "existingHpvResult") {
            this.$emit("alert", "danger", {
              title: this.$t("awaitingHpvResults.notifications.existingHpvResultTitle"),
              message: this.$t("awaitingHpvResults.notifications.existingHpvResultText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("awaitingHpvResultNew.notifications.additionErrorTitle"),
              message: this.$t("awaitingHpvResultNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onSubmit() {
      if (this.onValidateAwaitingHpvResult() !== false) this.onSaveAwaitingHpvResult();
    }
  }
};
</script>

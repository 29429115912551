<template>
  <div class="systemSettings w-100">
    <!-- form -->
    <b-form @submit.prevent="onSubmit">
      <!-- list -->
      <div class="kt-item-list mt-3">
        <div
          v-for="(additionalFeeGroup, index) in additionalFeeGroups"
          :key="additionalFeeGroup.tempId"
          class="kt-item-list__item-wrapper w-100"
        >
          <div
            class="kt-item-list__item w-100"
          >
            <b-row class="align-items-center">
              <!-- rank -->
              <b-col
                cols="12"
                md="2"
              >
                <div class="d-flex align-items-center">
                  <!-- rank number -->
                  <div class="addFeeGroup__rank-number">
                    <b-input
                      class="addFeeGroup__rank-input"
                      :value="additionalFeeGroup.rank"
                      size="sm"
                      variant="light"
                      disabled
                    />
                  </div>
                  <!-- rank up/down buttons -->
                  <div>
                    <div>
                      <b-button
                        class="btn-icon addFeeGroup__rank-btn addFeeGroup__rank-btn--left"
                        size="sm"
                        variant="light"
                        squared
                        @click="rankMove('down', index)"
                      >
                        <b-icon icon="chevron-up" />
                      </b-button>
                    </div>
                    <div>
                      <b-button
                        class="btn-icon addFeeGroup__rank-btn"
                        size="sm"
                        variant="light"
                        squared
                        @click="rankMove('up', index)"
                      >
                        <b-icon icon="chevron-down" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>
              <!-- name -->
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  :label="$t('additionalFeeGroups.name')"
                  class="mb-0"
                  :state="additionalFeeGroupsValidation && (!additionalFeeGroup.name || hasDoubleNames) ? false : null"
                  :invalid-feedback="!additionalFeeGroup.name ? $t('validationRules.required') : ''"
                >
                  <b-form-input
                    v-model="additionalFeeGroup.name"
                    trim
                    maxlength="50"
                    :disabled="additionalFeeGroup.isActive === false"
                    :state="additionalFeeGroupsValidation && (!additionalFeeGroup.name || hasDoubleNames) ? false : null"
                  />
                </b-form-group>
              </b-col>
              <!-- isActive -->
              <b-col v-show="additionalFeeGroup.isActive === false">
                <div class="text-right">
                  <div class="kt-badge kt-badge--red kt-badge--lg">
                    <b-icon
                      icon="trash"
                      class="kt-badge__icon"
                    ></b-icon>
                    <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- actions -->
            <div class="kt-item-list__item-actions">
              <!-- delete button -->
              <b-button
                v-show="additionalFeeGroup.isActive !== false"
                class="btn-icon kt-item-list__item-delete"
                size="sm"
                variant="light"
                squared
                @click="deleteAdditionalFeeGroup(index, additionalFeeGroup)"
              >
                <b-icon icon="x"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- error : namesMustBeUnique -->
      <div
        v-show="additionalFeeGroupsValidation && hasDoubleNames"
        class="text-14 mb-2"
        style="color: #dc3545;"
      >
        <b-icon
          icon="exclamation-circle-fill"
          class="text-16 mr-1"
        ></b-icon>
        {{ $t("additionalFeeGroups.validations.namesMustBeUnique") }}
      </div>
      <!-- new additionalFeeGroup -->
      <b-button
        class="mb-2"
        :variant="'outline-' + $systemSettings.theme"
        pill
        type="button"
        @click="addAdditionalFeeGroup"
      >
        {{ $t('additionalFeeGroups.addAdditionalFeeGroupButton') }}
      </b-button>

      <!-- divider -->
      <div class="w-100 border-top mt-3 mb-3"></div>

      <!-- submit -->
      <b-row>
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t("general.save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// services
import additionalFeeGroupsServices from "@/services/API/additionalFeeGroupsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import uniqueId from "lodash.uniqueid";

export default {
  mixins: [userRights, error],
  props: {},
  data() {
    return {
      isLoading: true,
      additionalFeeGroups: [],
      // is validation active
      additionalFeeGroupsValidation: false
    };
  },
  computed: {
    // form validation
    hasDoubleNames: function() {
      for (let i = 0; i < this.additionalFeeGroups.length; i++) {
        for (let j = 0; j < this.additionalFeeGroups.length; j++) {
          if (i !== j && this.additionalFeeGroups[i].name === this.additionalFeeGroups[j].name) {
            return true;
          }
        }
      }
      return false;
    },
    hasEmptyNames: function() {
      for (const additionalFeeGroup of this.additionalFeeGroups) {
        if (!additionalFeeGroup.name) {
          return true;
        }
      }
      return false;
    },
    namesState: function() {
      if (!this.additionalFeeGroupsValidation) return null;
      return this.hasDoubleNames || this.hasEmptyNames ? false : null;
    }

    // formatted variables
  },
  mounted() {
    this.importData();
  },
  methods: {
    importData() {
      this.additionalFeeGroups = JSON.parse(JSON.stringify(this.$systemSettings.additionalFeeGroups))
        .sort((a, b) => a.rank - b.rank)
        .map((additionalFeeGroup) => {
          additionalFeeGroup.tempId = uniqueId();
          return additionalFeeGroup;
        });
    },
    // submit
    onValidateForm() {
      this.additionalFeeGroupsValidation = true;

      return (
        this.namesState !== false
      );
    },
    async onSubmit() {
      try {
        // validation
        if (this.onValidateForm() === false) {
          return false;
        }
        this.isLoading = true;

        const form = this.additionalFeeGroups.map((v) => {
          return {
            id: v.id,
            name: v.name,
            rank: v.rank,
            isActive: v.isActive
          };
        });

        // save
        const res = await additionalFeeGroupsServices.updateAdditionalFeeGroups(form);
        if (res.data !== true) {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("systemSettings.editionError.title"),
            message: this.$t("systemSettings.editionError.text")
          });
          await this.importData();
          this.isLoading = false;
          return false;
        }

        // all success
        this.$emit("alert", "success", {
          title: this.$t("systemSettings.edited.title"),
          message: this.$t("systemSettings.edited.text")
        });
        await this.importData();
        this.isLoading = false;
        return true;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // edit group
    rankMove(direction, index) {
      const oldRank = this.additionalFeeGroups[index].rank;
      let newRank = this.additionalFeeGroups[index].rank + (1 * (direction === "up" ? 1 : -1));
      if (newRank === 0) newRank = (direction === "up" ? 1 : -1);

      if (index === 0 && direction === "down") {
        let currentRank = newRank;
        for (const additionalFeeGroup of this.additionalFeeGroups) {
          additionalFeeGroup.rank = currentRank;
          currentRank = (currentRank + 1) || 1;
        }
      } else if (index === this.additionalFeeGroups.length - 1 && direction === "up") {
        let hasNegativeRank = false;
        for (const additionalFeeGroup of this.additionalFeeGroups) {
          if (additionalFeeGroup.rank < 0) {
            hasNegativeRank = true;
            break;
          }
        }
        if (hasNegativeRank) {
          let currentRank = (this.additionalFeeGroups[0].rank + 1) || 1;
          for (const additionalFeeGroup of this.additionalFeeGroups) {
            additionalFeeGroup.rank = currentRank;
            currentRank = (currentRank + 1) || 1;
          }
        }
      } else {
        for (const additionalFeeGroup of this.additionalFeeGroups) {
          if (additionalFeeGroup.rank === oldRank) {
            additionalFeeGroup.rank = newRank;
          } else if (additionalFeeGroup.rank === newRank) {
            additionalFeeGroup.rank = oldRank;
          }
        }
      }

      this.additionalFeeGroups.sort((a, b) => a.rank - b.rank);
    },
    addAdditionalFeeGroup() {
      this.additionalFeeGroups.push({
        id: null,
        tempId: uniqueId(),
        rank: this.additionalFeeGroups.length ? (this.additionalFeeGroups[this.additionalFeeGroups.length - 1].rank + 1) : 1,
        name: "",
        isActive: true
      });
    },
    deleteAdditionalFeeGroup(index, additionalFeeGroup) {
      if (additionalFeeGroup.id) {
        this.additionalFeeGroups[index].isActive = false;
      } else {
        this.additionalFeeGroups.splice(index, 1);
        if (typeof this.additionalFeeGroups[index] !== "undefined") {
          for (let i = index; i < this.additionalFeeGroups.length; i++) {
            this.additionalFeeGroups[i].rank = (this.additionalFeeGroups[i].rank - 1) || -1;
          }
        }
      }
    }
  }
};
</script>

<style scoped src="./AdditionalFeeGroups.scss" lang="scss"></style>

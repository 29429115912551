<template>
  <div class="kt-contacts mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("contacts.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'office', 'contacts', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('office/contact/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("contacts.addContactButton") }}
      </b-button>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="fields"
      :items="contacts"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="codeFilter"
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>

          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>

          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>

          <!-- filter registrationNumber -->
          <b-th class="kt-table__th-search"></b-th>

          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 131px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(contactButtons)="data">
        <div style="width: 131px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'office', 'contacts', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/contact/view', {contactIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="isEnabled(['menu', 'office', 'contacts', 'edit'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="mx-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/contact/edit', {contactIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete  -->
          <b-button
            v-show="isEnabled(['menu', 'office', 'contacts', 'delete'])"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onDeleteDoctor(data.item)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="(!contacts || !contacts.length) && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: true,
          label: this.$t("contacts.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: true,
          label: this.$t("contacts.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: true,
          label: this.$t("contacts.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "registrationNumber",
          sortable: true,
          label: this.$t("contacts.registrationNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "contactButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterCode: "",
      filterFirstName: "",
      filterLastName: "",
      // table items
      contacts: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterCode: "string",
        filterFirstName: "string",
        filterLastName: "string"
      }
    };
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.onPageInput();

    // auto-focus
    if (this.$refs.addLineButton) {
      this.$refs.addLineButton.focus();
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.editLineButton_0) {
        this.$refs.editLineButton_0.focus();
      } else if (this.$refs.deleteLineButton_0) {
        this.$refs.deleteLineButton_0.focus();
      }
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // searchAndUpdateTable
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          code: this.filterCode,
          firstName: this.filterFirstName,
          lastName: this.filterLastName
        };

        const res = await commonServices.getAll("contacts", filters);
        this.contacts = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.contacts.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.codeFilter.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    async onDeleteDoctor(row) {
      if (typeof row === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("contacts.deletion.text"), {
        title: this.$t("contacts.deletion.title"),
        okVariant: "danger",
        okTitle: this.$t("contacts.deletion.ok"),
        cancelTitle: this.$t("contacts.deletion.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("contacts", row.id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("contacts.deleted.title"),
            message: this.$t("contacts.deleted.text")
          });
          // auto-focus
          this.$refs.addLineButton.focus();
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("contacts.deletionError.title"),
            message: this.$t("contacts.deletionError.text")
          });
        }
        this.onPageInput();
      }
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kt-choose-sample"},[_c('b-table',{ref:"table",staticClass:"m-0 kt-table",attrs:{"hover":"","responsive":"","fields":_vm.sampleFields,"items":_vm.samples,"busy":_vm.loading,"select-mode":"single","selectable":""},on:{"row-selected":_vm.onSampleSelected},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',{staticClass:"kt-table__tr-search",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.focusFirstLine.apply(null, arguments)}}},[_c('b-th',{staticClass:"kt-table__th-search"},[_c('b-form-input',{staticClass:"kt-table__th-search-input",attrs:{"trim":"","autocomplete":"off"},on:{"input":_vm.onFilterChange,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function (e) {
              if (_vm.filterCode === '') {
                e.preventDefault();
                _vm.focusFirstLine();
              }
            }).apply(null, arguments)}},model:{value:(_vm.filterCode),callback:function ($$v) {_vm.filterCode=$$v},expression:"filterCode"}}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterCode !== ''),expression:"filterCode !== ''"}],staticClass:"kt-table__clear-btn",attrs:{"squared":"","size":"sm"},on:{"click":function($event){return _vm.onClearFilter('filterCode')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function (e) {
              if (_vm.filterCode !== '') {
                e.preventDefault();
                _vm.focusFirstLine();
              }
            }).apply(null, arguments)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1)],1),_c('b-th',{staticClass:"kt-table__th-search"},[_c('b-form-input',{ref:"nameInput",staticClass:"kt-table__th-search-input",attrs:{"trim":"","autocomplete":"off"},on:{"input":_vm.onFilterChange},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterName !== ''),expression:"filterName !== ''"}],staticClass:"kt-table__clear-btn",attrs:{"squared":"","size":"sm"},on:{"click":function($event){return _vm.onClearFilter('filterName')}}},[_c('b-icon',{attrs:{"icon":"x"}})],1)],1)],1)]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{class:'text-center text-' + _vm.$systemSettings.theme},[_c('b-spinner',{staticClass:"align-middle",class:'kt-spinner--' + _vm.$systemSettings.theme}),_c('span',{staticClass:"kt-spinner-label"},[_vm._v(_vm._s(_vm.$t("query.loading")))])],1)]},proxy:true}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.samplesCount && !_vm.loading && _vm.isSearchActive),expression:"!samplesCount && !loading && isSearchActive"}],staticClass:"text-center mt-3"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("sampleChoose.notFound"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="kt-statistics mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("statistics.pageTitle") }}
      </h2>
    </div>

    <!-- period -->
    <b-row class="mt-2">
      <b-col
        cols="12"
        md="4"
      >
        <div class="mt-1">
          <div
            style="color: #495057; display: inline-block; vertical-align: top;
            padding: 0.375rem 0.9375rem; font-size: 0.9375rem; height: calc(1.5em + 0.75rem + 2px);
            background: white; border: 1px solid #ced4da; border-radius: 0.25rem; user-select: none;"
          >
            {{ $t('statistics.live') }}
          </div>
          <div
            class="d-inline-block align-top ml-3"
            style="width: 144px;"
          >
            <SmartSelectInput
              v-if="$systemSettings.laboratories.length"

              labelProp=""
              :optionsProp="$systemSettings.laboratories"
              valueFieldProp="id"
              textFieldProp="name"

              :valueProp="laboratoryId"
              :stateProp="null"
              invalidFeedbackProp=""

              :disabledProp="false"
              :showDisabledItemsProp="false"
              :showInactiveItemsProp="true"
              :displayUniqueProp="false"
              :selectFirstOnloadProp="laboratoryId === null"
              :initialValueProp="laboratoryId"
              forceSelectInputProp
              :responsiveOptionsProp="{cols: 12, md: 12}"
              formGroupClassProp="mb-0"
              @input="laboratoryId = $event"
            />
          </div>
        </div>
        <!-- divider -->
        <div
          class="w-100 border-top mb-4"
          style="margin-top: 12px"
        ></div>
      </b-col>
      <b-col
        cols="12"
        md="8"
        style="padding-left: 30px;"
      >
        <div class="d-flex mt-1">
          <div style="flex: 0 0 120px;">
            <b-form-select
              v-model="periodType"
              :options="periodTypeOptions"
              value-field="name"
              text-field="localisedName"
              @input="importData"
            ></b-form-select>
          </div>
          <div
            v-show="periodType === 'monthly'"
            style="flex: 0 0 179px;"
            class="ml-3"
          >
            <b-button
              class="kt-btn__statistics btn-icon"
              style="border: 1px solid #ced4da; vertical-align: top;"
              variant="light"
              @click="() => {
                targetMonth = (targetMonth === 1 ? 12 : targetMonth - 1);
                importData();
              }"
            >
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <b-form-input
              style="width: 106px; display: inline-block; vertical-align: top; background: white; border-color: #ced4da; user-select: none;"
              :value="$t('statistics.months.' + targetMonth)"
              disabled
            ></b-form-input>
            <b-button
              class="kt-btn__statistics btn-icon"
              style="border: 1px solid #ced4da; vertical-align: top;"
              variant="light"
              @click="() => {
                targetMonth = (targetMonth === 12 ? 1 : targetMonth + 1);
                importData();
              }"
            >
              <b-icon icon="chevron-right"></b-icon>
            </b-button>
          </div>
          <div
            style="flex: 0 0 179px;"
            class="ml-3"
          >
            <b-button
              class="kt-btn__statistics btn-icon"
              style="border: 1px solid #ced4da; vertical-align: top;"
              variant="light"
              @click="() => {
                targetYear--;
                importData();
              }"
            >
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <b-form-input
              style="width: 106px; display: inline-block; vertical-align: top; background: white; border-color: #ced4da; user-select: none;"
              :value="targetYear"
              disabled
            ></b-form-input>
            <b-button
              class="kt-btn__statistics btn-icon"
              style="border: 1px solid #ced4da; vertical-align: top;"
              variant="light"
              @click="() => {
                targetYear++;
                importData();
              }"
            >
              <b-icon icon="chevron-right"></b-icon>
            </b-button>
          </div>
          <div
            v-show="periodType === 'monthly'"
            style="flex: 0 0 179px;"
            class="ml-3"
          >
            <b-form-checkbox
              v-model="isWeighted"
              switch
              style="margin-top: 7px; color: rgb(73, 80, 87);"
              class="text-15"
            >
              {{ $t("statistics.isWeightedButton") }}
            </b-form-checkbox>
          </div>
        </div>

        <!-- divider -->
        <div
          class="w-100 border-top mb-4"
          style="margin-top: 12px"
        ></div>
      </b-col>
    </b-row>

    <b-row>
      <!-- ongoing -->
      <b-col
        cols="12"
        md="4"
      >
        <!-- header -->
        <div style="margin-bottom: 12px">
          <h3 class="h5 mb-0 d-inline-block align-top">
            {{ $t("statistics.sections.ongoingFiles") }}
          </h3>
          <div
            class="kt-badge kt-badge--light-gray ml-2"
            style="margin-top: -1px; margin-bottom: -4px;"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{
              ongoingLiveTotal !== null ? ($n(ongoingLiveTotal) + " " + $tc("statistics.files", Math.round(ongoingLiveData.all))) : $t("general.emptyWithHyphen")
            }}</span></span>
          </div>
          <div
            class="kt-badge ml-1"
            :class="ongoingLiveEvolution === null ? 'kt-badge--light-gray' : ongoingLiveEvolution && ongoingLiveEvolution >= 0 ? 'kt-badge--light-green' : 'kt-badge kt-badge--red'"
            style="margin-top: -1px; margin-bottom: -4px;"
          >
            <b-icon
              v-show="ongoingLiveEvolution !== null"
              :icon="ongoingLiveEvolution && ongoingLiveEvolution >= 0 ? 'arrow-up-right' : 'arrow-down-right'"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ ongoingLiveEvolution !== null ? $n(ongoingLiveEvolution) + "%" : $t("general.emptyWithHyphen") }}</span></span>
          </div>
        </div>
        <AnalysisTypesSelect
          v-model="analysisTypes"
          :analysisColorsProp="analysisColors"
        />
        <BarChart
          ref="ongoingLiveChart"
          :chartData="ongoingLiveChartData"
          :options="ongoingLiveChartOptions"
        ></BarChart>
      </b-col>
      <!-- registered -->
      <b-col
        cols="12"
        md="4"
        style="margin-left: 15px; margin-right: -15px;"
      >
        <!-- header -->
        <div style="margin-bottom: 12px">
          <h3 class="h5 mb-0 d-inline-block align-top">
            {{ $t("statistics.sections.registredFiles") }}
          </h3>
          <div
            class="kt-badge kt-badge--light-gray ml-2"
            style="margin-top: -1px; margin-bottom: -4px;"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{
              $n(registeredTotal) + " " + $tc("statistics.files", Math.round(registeredTotal))
            }}</span></span>
          </div>
          <div
            class="kt-badge kt-badge--light-gray ml-1"
            style="margin-top: -1px; margin-bottom: -4px;"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{
              registeredAverage !== null ?
                ($n(registeredAverage) + " " + $t("statistics.perDay")) :
                $t("general.emptyWithHyphen")
            }}</span></span>
          </div>
        </div>
        <AnalysisTypesSelect
          v-model="analysisTypes"
          :analysisColorsProp="analysisColors"
        />
        <LineChart
          ref="registeredChart"
          :chartData="registeredChartData"
          :options="registeredChartOptions"
        ></LineChart>
      </b-col>
      <!-- completion delay -->
      <b-col
        cols="12"
        md="4"
      >
        <!-- header -->
        <div style="margin-bottom: 12px">
          <h3 class="h5 mb-0 d-inline-block align-top">
            {{ $t("statistics.sections.completionDelay") }}
          </h3>
          <div
            class="kt-badge kt-badge--light-gray ml-2"
            style="margin-top: -1px; margin-bottom: -4px;"
          >
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{
              averageCompletionDurationTotal !== null ?
                $n(averageCompletionDurationTotal) + " " + $tc("statistics.day", Math.round(averageCompletionDurationTotal)) :
                $t("general.emptyWithHyphen")
            }}</span></span>
          </div>
        </div>
        <AnalysisTypesSelect
          v-model="analysisTypes"
          :analysisColorsProp="analysisColors"
        />
        <LineChart
          ref="delayChart"
          :chartData="delayChartData"
          :options="delayChartOptions"
        ></LineChart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// services
import dashboardServices from "@/services/API/dashboardServices";
// components
import AnalysisTypesSelect from "./AnalysisTypesSelect.vue";
import LineChart from "./LineChart.vue";
import BarChart from "./BarChart.vue";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
// mixins
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
// others
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { AnalysisTypesSelect, LineChart, BarChart, SmartSelectInput },
  mixins: [error, date, saveParamsInQuery],
  data() {
    return {
      // general
      loading: true,
      analysisColors: {
        cytology: "#f87979", //#d25953 #d25a39 #EA6A47 // pastel-red #f87979, pastel-blue #43b1dc
        cotesting: "#43b1dc", //#43b1dc #33a7d4 // deep-red #f56070, light-blue #88e5ff, ocean-blue #156497, yellow #f4b856, soft-yellow #ffd480, green #75ca75, purple #7f73e2;
        hpv: "#156497" //#156497 #186891
      },
      // analysisType: null,
      laboratoryId: null,
      periodType: "monthly",
      targetMonth: new Date().getMonth() + 1,
      targetYear: new Date().getFullYear(),
      analysisTypes: ["cytology", "cotesting", "hpv"],
      isWeighted: false,
      // data
      data: [],
      weightedData: [],
      cachedYears: this.$systemSettings.laboratories.reduce((o, key) => ({ ...o, [key.id]: [] }), {}),
      cachedMonths: this.$systemSettings.laboratories.reduce((o, key) => ({ ...o, [key.id]: [] }), {}),
      cachedData: this.$systemSettings.laboratories.reduce((o, key) => ({ ...o, [key.id]: {} }), {}),
      ongoingLiveData: null,
      yesterdayData: null,
      // options
      analysisTypesOptions: ["cytology", "cotesting", "hpv"],
      periodTypeOptions: [
        {
          localisedName: this.$t("statistics.periodTypes.annual"),
          name: "annual"
        },
        {
          localisedName: this.$t("statistics.periodTypes.monthly"),
          name: "monthly"
        }
        // {
        //   localisedName: this.$t("statistics.periodTypes.weekly"),
        //   name: "weekly"
        // }
      ],
      // saveParamsConfig
      saveParamsConfig: {
        laboratoryId: "number",
        periodType: "string",
        targetMonth: "number",
        targetYear: "number",
        analysisTypes: "arrayOfStrings",
        isWeighted: "boolean"
      }
    };
  },
  computed: {
    // chart data
    chartLabels: function() {
      if (this.periodType === "monthly") {
        return this.chartLabelsFull.map(v => v.slice(0, 2));
      } else if (this.periodType === "annual") {
        return this.chartLabelsFull.map(v => v.slice(0, 5));
      } else {
        return [];
      }
    },
    chartLabelsFull: function() {
      if (this.periodType === "monthly") {
        const dayCount = this.getMonthNumberOfDays(this.targetMonth, this.targetYear);
        const monthLabels = [];
        for (let i = 1; i <= dayCount; i++) {
          monthLabels.push((i < 10 ? "0" : "") + i + "/" + (this.targetMonth < 10 ? "0" : "") + this.targetMonth);
        }
        return monthLabels;
      } else if (this.periodType === "annual") {
        return this.data.map((v) => {
          let day = Number(v.creationDate.slice(8, 10)) + 6;
          let month = Number(v.creationDate.slice(5, 7));
          let year = this.targetYear;
          const dayCount = this.getMonthNumberOfDays(month, year);
          if (day > dayCount) {
            day = day - dayCount;
            month++;
            if (month > 12) {
              month = 1;
              year++;
            }
          }
          return (day < 10 ? "0" : "") + day + "/" + (month < 10 ? "0" : "") + month + "/" + String(year).slice(2, 4);
        });
      } else {
        return [];
      }
    },
    // register
    registeredChartOptions: function() {
      return {
        interactions: {
          mode: "index",
          intersect: false
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          mode: "index",
          intersect: false,
          custom: (tooltipModel) => {
            this.createCustomToolTip(tooltipModel, "registeredChart", "registeredChartData", { isAverage: false, isCumulative: true });
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      };
    },
    registeredChartData: function() {
      const dataName = this.isWeighted && this.periodType === "monthly" ? "weightedData" : "data";
      const datasets = [];
      const additiveArrays = [];
      for (const option of this.analysisTypesOptions) {
        let currentData = null;
        if (this.analysisTypes.includes(option)) {
          additiveArrays.push(this[dataName].map((v) => {
            return v ? v.registered[option] : null;
          }));
          currentData = additiveArrays.length > 1 ? this.sumArrays(additiveArrays) : additiveArrays[0];
        }
        datasets.push({
          label: this.$t("statistics.analysisTypes." + option),
          data: currentData,
          backgroundColor: this.analysisColors[option],
          cubicInterpolationMode: "monotone",
          lineTension: 0.4
        });
      }
      return {
        labels: this.chartLabels,
        datasets: datasets,
        labelsFull: this.chartLabelsFull
      };
    },
    registeredTotalNotRounded: function() {
      if (this.analysisTypes.length === this.analysisTypesOptions.length) {
        // all analysisTypes
        return this.data.reduce((total, v) => { return total + (v ? v.registered.all : 0); }, 0);
      } else {
        // missing analysisTypes
        let total = 0;
        for (const analysisType of this.analysisTypes) {
          total += this.data.reduce((sum, v) => { return sum + (v ? v.registered[analysisType] : 0); }, 0);
        }
        return total;
      }
    },
    registeredTotal: function() {
      return this.round(this.registeredTotalNotRounded, 0);
    },
    registeredAverage: function() {
      if (this.data.length === 0) return null;
      const availableData = this.data.filter(v => v !== null);
      if (availableData.length === 0) return null;
      let average = this.registeredTotalNotRounded / availableData.length;
      if (this.periodType === "annual") {
        average = average / 7; // convert "by week" to "by day" average
      }
      return this.round(average);
    },
    // averageCompletionDuration
    delayChartOptions: function() {
      return {
        interactions: {
          mode: "index",
          intersect: false
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          mode: "index",
          intersect: false,
          custom: (tooltipModel) => {
            this.createCustomToolTip(tooltipModel, "delayChart", "delayChartData", { isAverage: true, isCumulative: false });
          }
        },
        responsive: true,
        maintainAspectRatio: false
      };
    },
    delayChartData: function() {
      const dataName = this.isWeighted && this.periodType === "monthly" ? "weightedData" : "data";
      const datasets = [];
      for (const option of this.analysisTypesOptions) {
        let currentData = null;
        if (this.analysisTypes.includes(option)) {
          currentData = this[dataName].map((v) => {
            return v ? v.averageCompletionDuration[option] : null;
          });
        }
        datasets.push({
          label: this.$t("statistics.analysisTypes." + option),
          data: currentData,
          borderColor: this.analysisColors[option],
          backgroundColor: this.analysisColors[option],
          fill: false,
          cubicInterpolationMode: "monotone",
          lineTension: 0.4
        });
      }
      return {
        labels: this.chartLabels,
        datasets: datasets,
        labelsFull: this.chartLabelsFull
      };
    },
    averageCompletionDurationTotal: function() {
      if (this.data.length === 0 || this.analysisTypes.length === 0) return null;
      const availableData = this.data.filter(v => v !== null && v.averageCompletionDuration.all !== null);
      if (availableData.length === 0) return null;

      if (this.analysisTypes.length === this.analysisTypesOptions.length) {
        // all analysisTypes
        const totalDelay = availableData.reduce((total, v) => { return total + (v ? v.averageCompletionDuration.all : 0); }, 0);
        return this.round(totalDelay / availableData.length, 100);
      } else {
        // missing analysisTypes
        const averages = [];
        for (const analysisType of this.analysisTypes) {
          const currentAvailableData = availableData.filter(v => v.averageCompletionDuration[analysisType] !== null);
          if (currentAvailableData.length === 0) continue;
          const currentTotalDelay = currentAvailableData.reduce((total, v) => {
            return total + (v ? v.averageCompletionDuration[analysisType] : 0);
          }, 0);
          averages.push(currentTotalDelay / currentAvailableData.length);
        }
        if (averages.length === 0) return null;
        return this.round(averages.reduce((total, v) => total + v, 0) / averages.length, 100);
      }
    },
    // ongoing
    ongoingLiveChartOptions: function() {
      return {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        interactions: {
          mode: "index",
          intersect: false
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          mode: "index",
          intersect: false,
          custom: (tooltipModel) => {
            this.createCustomToolTip(tooltipModel, "ongoingLiveChart", "ongoingLiveChartData", { isAverage: false, isCumulative: false });
          }
        },
        responsive: true,
        maintainAspectRatio: false
      };
    },
    ongoingLiveChartData: function() {
      const datasets = [];
      for (const option of this.analysisTypesOptions) {
        let currentData = null;
        if (this.analysisTypes.includes(option) && this.ongoingLiveData && this.ongoingLiveData[option]) {
          currentData = [
            this.ongoingLiveData[option].office,
            this.ongoingLiveData[option].technique,
            this.ongoingLiveData[option].medical
          ];
        }
        datasets.push({
          label: this.$t("statistics.analysisTypes." + option),
          data: currentData,
          backgroundColor: this.analysisColors[option],
          barPercentage: 0.8
        });
      }
      return {
        labels: [this.$t("statistics.ongoing.office"), this.$t("statistics.ongoing.diagnosis"), this.$t("statistics.ongoing.pathologist")],
        labelsFull: [this.$t("statistics.ongoing.office"), this.$t("statistics.ongoing.diagnosis"), this.$t("statistics.ongoing.pathologist")],
        datasets: datasets
      };
    },
    // ongoingChartData: function() {
    //   return {
    //     labels: [this.$t("statistics.ongoing.office"), this.$t("statistics.ongoing.diagnosis"), this.$t("statistics.ongoing.pathologist")],
    //     datasets: [
    //       {
    //         label: this.$t("statistics.analysisTypes.cytology"),
    //         backgroundColor: "#88e5ff",
    //         data: this.data.length === 0 ? [] : [
    //           this.data[this.data.length - 1].ongoing.cytology.office,
    //           this.data[this.data.length - 1].ongoing.cytology.technique,
    //           this.data[this.data.length - 1].ongoing.cytology.medical
    //         ]
    //       },
    //       {
    //         label: this.$t("statistics.analysisTypes.cotesting"),
    //         backgroundColor: "#f87979",
    //         data: this.data.length === 0 ? [] : [
    //           this.data[this.data.length - 1].ongoing.cotesting.office,
    //           this.data[this.data.length - 1].ongoing.cotesting.technique,
    //           this.data[this.data.length - 1].ongoing.cotesting.medical
    //         ]
    //       },
    //       {
    //         label: this.$t("statistics.analysisTypes.hpv"),
    //         backgroundColor: "#156497",
    //         data: this.data.length === 0 ? [] : [
    //           this.data[this.data.length - 1].ongoing.hpv.office,
    //           this.data[this.data.length - 1].ongoing.hpv.technique,
    //           this.data[this.data.length - 1].ongoing.hpv.medical
    //         ]
    //       }
    //     ]
    //   };
    // },
    ongoingLiveTotal: function() {
      if (!this.ongoingLiveData) return null;

      if (this.analysisTypes.length === this.analysisTypesOptions.length) {
        // all analysisTypes
        return this.ongoingLiveData.all;
      } else {
        // missing analysisTypes
        let total = 0;
        for (const analysisType of this.analysisTypes) {
          total += this.ongoingLiveData[analysisType].all;
        }
        return total;
      }
    },
    ongoingLiveEvolution: function() {
      if (!this.yesterdayData || !this.ongoingLiveTotal) return null;

      let yesterdayTotal = 0;
      if (this.analysisTypes.length === this.analysisTypesOptions.length) {
        // all analysisTypes
        yesterdayTotal = this.yesterdayData.ongoing.all;
      } else {
        // missing analysisTypes
        for (const analysisType of this.analysisTypes) {
          yesterdayTotal += this.yesterdayData.ongoing[analysisType].all;
        }
      }

      const evolution = ((this.ongoingLiveTotal - yesterdayTotal) / yesterdayTotal) * 100;
      return this.round(evolution);
    }
  },
  watch: {
    // update data on laboratory change
    laboratoryId: {
      handler: async function(_newVal, oldVal) {
        if (oldVal !== null) {
          await this.importData();
          await this.importLiveData();

          if (!this.yesterdayData) {
            const yesterdayIsoDate = this.getIsoDate(-1);
            const filters = {
              laboratoryId: this.laboratoryId,
              startDate: yesterdayIsoDate,
              endDate: yesterdayIsoDate
            };
            const res = await dashboardServices.getAll(filters);
            // update cache
            this.cacheData(res.data);
          }
        }
      }
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    await this.importData();
    await this.importLiveData();
  },
  methods: {
    updateYesterdayData() {
      if (!this.yesterdayData) {
        if (this.laboratoryId && this.cachedData && this.cachedData[this.laboratoryId]) {
          this.yesterdayData = this.cachedData[this.laboratoryId][this.getIsoDate(-1)] || null;
        }
      }
    },
    async importData() {
      try {
        if (this.periodType === "monthly") {
          if (!this.cachedMonths[this.laboratoryId].includes(this.targetYear + "-" + this.targetMonth)) {
            const startIsoDate = this.targetYear + "-" + (this.targetMonth < 10 ? "0" : "") + this.targetMonth + "-01";
            const dayCount = this.getMonthNumberOfDays(this.targetMonth, this.targetYear);
            const endIsoDate = this.targetYear + "-" + (this.targetMonth < 10 ? "0" : "") + this.targetMonth + "-" + dayCount;
            const startIsoDateWithMargins = this.getIsoDate(-7, 0, 0, startIsoDate);
            const endIsoDateWithMargins = this.getIsoDate(7, 0, 0, endIsoDate);
            const filters = {
              laboratoryId: this.laboratoryId,
              startDate: startIsoDateWithMargins,
              endDate: endIsoDateWithMargins
            };
            const res = await dashboardServices.getAll(filters);
            res.data.map((v) => {
              v.averageCompletionDuration.all = Number(v.averageCompletionDuration.all);
              v.averageCompletionDuration.cytology = Number(v.averageCompletionDuration.cytology);
              v.averageCompletionDuration.hpv = Number(v.averageCompletionDuration.hpv);
              v.averageCompletionDuration.cotesting = Number(v.averageCompletionDuration.cotesting);
              v.averageCompletionDuration.other = Number(v.averageCompletionDuration.other);
            });
            // update cache
            this.cacheData(res.data);
            // update cache index
            this.cachedMonths[this.laboratoryId].push(this.targetYear + "-" + this.targetMonth);
          }
        } else if (this.periodType === "annual") {
          if (!this.cachedYears[this.laboratoryId].includes(this.targetYear)) {
            let startMonth = 1;
            for (let i = 1; i <= 12; i++) {
              if (!this.cachedMonths[this.laboratoryId].includes(this.targetYear + "-" + i)) {
                startMonth = i;
                break;
              }
            }
            let endMonth = 12;
            for (let i = 12; i >= 1; i--) {
              if (!this.cachedMonths[this.laboratoryId].includes(this.targetYear + "-" + i)) {
                endMonth = i;
                break;
              }
            }
            const startIsoDate = this.targetYear + "-" + (startMonth < 10 ? "0" : "") + startMonth + "-01";
            const endIsoDate = this.targetYear + "-" + (endMonth < 10 ? "0" : "") + endMonth + "-01";
            const startIsoDateWithMargins = this.getIsoDate(-7, 0, 0, startIsoDate);
            const endIsoDateWithMargins = this.getIsoDate(7, 0, 0, endIsoDate);
            const filters = {
              laboratoryId: this.laboratoryId,
              startDate: startIsoDateWithMargins,
              endDate: endIsoDateWithMargins
            };
            const res = await dashboardServices.getAll(filters);
            // update cache
            this.cacheData(res.data);
            // update cache index
            for (let i = 1; i <= 12; i++) {
              if (!this.cachedMonths[this.laboratoryId].includes(this.targetYear + "-" + i)) {
                this.cachedMonths[this.laboratoryId].push(this.targetYear + "-" + i);
              }
            }
            this.cachedYears[this.laboratoryId].push(this.targetYear);
          }
        }
        // update dataset
        this.updateData();
        this.updateWeightedData();
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importLiveData() {
      try {
        const filters = { laboratoryId: this.laboratoryId };
        const res = await dashboardServices.getOngoingLive(filters);
        this.ongoingLiveData = res.data;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    updateData() {
      this.data = [];
      let dayCount = 0;
      if (this.periodType === "monthly") {
        // MONTHLY
        dayCount = this.getMonthNumberOfDays(this.targetMonth, this.targetYear);
        for (let i = 0; i < dayCount; i++) {
          const targetIsoDate = this.targetYear + "-" + (this.targetMonth < 10 ? "0" : "") + this.targetMonth + "-" + (i < 9 ? "0" : "") + (i + 1);
          const cachedData = this.cachedData[this.laboratoryId][targetIsoDate];
          this.data.push(cachedData || null);
        }
      } else if (this.periodType === "annual") {
        // ANNUAL

        // setup dayCount
        for (let month = 1; month <= 12; month++) {
          dayCount += this.getMonthNumberOfDays(month, this.targetYear);
        }

        let weekData = [];
        const dayDate = new Date(this.targetYear + "-01-01");
        let dayDateISO = "";
        let weekDateISO = "";
        let totalWeekData = null;
        // loop weeks
        for (let i = 1; i <= dayCount; i += 7) {
          // setup weekDateISO
          weekDateISO = this.getIsoDateFromDate(dayDate);
          // setup weekData
          weekData = [];
          for (let j = 1; j <= 7; j++) {
            dayDateISO = (j === 1 ? weekDateISO : this.getIsoDateFromDate(dayDate));
            const cachedData = this.cachedData[this.laboratoryId][dayDateISO];
            if (cachedData) weekData.push(cachedData);
            dayDate.setDate(dayDate.getDate() + 1);
          }
          if (weekData.length === 0) continue;
          // setup totalWeekData
          totalWeekData = {
            creationDate: weekDateISO,
            registered: {
              all: weekData.reduce((total, v) => { return total + v.registered.all; }, 0),
              hpv: weekData.reduce((total, v) => { return total + v.registered.hpv; }, 0),
              cytology: weekData.reduce((total, v) => { return total + v.registered.cytology; }, 0),
              cotesting: weekData.reduce((total, v) => { return total + v.registered.cotesting; }, 0)
            },
            averageCompletionDuration: {
              all: this.round(weekData.reduce((total, v) => { return total + v.averageCompletionDuration.all; }, 0) / weekData.length, 100),
              hpv: this.round(weekData.reduce((total, v) => { return total + v.averageCompletionDuration.hpv; }, 0) / weekData.length, 100),
              cytology: this.round(weekData.reduce((total, v) => { return total + v.averageCompletionDuration.cytology; }, 0) / weekData.length, 100),
              cotesting: this.round(weekData.reduce((total, v) => { return total + v.averageCompletionDuration.cotesting; }, 0) / weekData.length, 100)
            },
            ongoing: {
              all: weekData.reduce((total, v) => { return total + v.ongoing.all; }, 0),
              hpv: {
                all: weekData.reduce((total, v) => { return total + v.ongoing.hpv.all; }, 0),
                office: weekData.reduce((total, v) => { return total + v.ongoing.hpv.office; }, 0),
                technique: weekData.reduce((total, v) => { return total + v.ongoing.hpv.technique; }, 0),
                medical: weekData.reduce((total, v) => { return total + v.ongoing.hpv.medical; }, 0)
              },
              cytology: {
                all: weekData.reduce((total, v) => { return total + v.ongoing.cytology.all; }, 0),
                office: weekData.reduce((total, v) => { return total + v.ongoing.cytology.office; }, 0),
                technique: weekData.reduce((total, v) => { return total + v.ongoing.cytology.technique; }, 0),
                medical: weekData.reduce((total, v) => { return total + v.ongoing.cytology.medical; }, 0)
              },
              cotesting: {
                all: weekData.reduce((total, v) => { return total + v.ongoing.cotesting.all; }, 0),
                office: weekData.reduce((total, v) => { return total + v.ongoing.cotesting.office; }, 0),
                technique: weekData.reduce((total, v) => { return total + v.ongoing.cotesting.technique; }, 0),
                medical: weekData.reduce((total, v) => { return total + v.ongoing.cotesting.medical; }, 0)
              }
            }
          };
          this.data.push(totalWeekData);
        }
      }
    },
    updateWeightedData() {
      this.weightedData = [];
      if (this.periodType === "annual") return true;

      for (const value of this.data) {
        if (value === null) {
          this.weightedData.push(null);
          continue;
        }

        // setup weekData
        const currentDate = new Date(value.creationDate);
        const weekData = [];
        for (let j = 0; j < 7; j++) {
          const currentIsoDate = this.getIsoDateFromDate(currentDate);
          const cachedData = this.cachedData[this.laboratoryId][currentIsoDate];
          if (cachedData) {
            weekData.push(cachedData);
          }
          if (j < 6) currentDate.setDate(currentDate.getDate() - 1);
        }

        // setup averageCompletionDuration
        const averageCompletionDuration = {
          all: null,
          hpv: null,
          cytology: null,
          cotesting: null
        };
        const availableData = weekData.filter(v => v !== null && v.averageCompletionDuration.all !== null);
        if (availableData.length > 0) {
          const totalDelay = availableData.reduce((total, v) => { return total + (v ? v.averageCompletionDuration.all : 0); }, 0);
          averageCompletionDuration.all = this.round(totalDelay / availableData.length, 100);
        }
        for (const option of this.analysisTypesOptions) {
          const currentAvailableData = availableData.filter(v => v.averageCompletionDuration[option] !== null);
          if (currentAvailableData.length === 0) continue;
          const currentTotalDelay = currentAvailableData.reduce((total, v) => {
            return total + (v ? v.averageCompletionDuration[option] : 0);
          }, 0);
          averageCompletionDuration[option] = this.round(currentTotalDelay / currentAvailableData.length, 100);
        }

        // average weekData
        const averageWeekData = {
          creationDate: value.creationDate,
          registered: {
            all: this.round(weekData.reduce((total, v) => { return total + v.registered.all; }, 0) / weekData.length, 0),
            hpv: this.round(weekData.reduce((total, v) => { return total + v.registered.hpv; }, 0) / weekData.length, 0),
            cytology: this.round(weekData.reduce((total, v) => { return total + v.registered.cytology; }, 0) / weekData.length, 0),
            cotesting: this.round(weekData.reduce((total, v) => { return total + v.registered.cotesting; }, 0) / weekData.length, 0)
          },
          averageCompletionDuration: averageCompletionDuration,
          ongoing: {
            all: this.round(weekData.reduce((total, v) => { return total + v.ongoing.all; }, 0) / weekData.length, 0),
            hpv: {
              all: this.round(weekData.reduce((total, v) => { return total + v.ongoing.hpv.all; }, 0) / weekData.length, 0),
              office: this.round(weekData.reduce((total, v) => { return total + v.ongoing.hpv.office; }, 0) / weekData.length, 0),
              technique: this.round(weekData.reduce((total, v) => { return total + v.ongoing.hpv.technique; }, 0) / weekData.length, 0),
              medical: this.round(weekData.reduce((total, v) => { return total + v.ongoing.hpv.medical; }, 0) / weekData.length, 0)
            },
            cytology: {
              all: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cytology.all; }, 0) / weekData.length, 0),
              office: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cytology.office; }, 0) / weekData.length, 0),
              technique: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cytology.technique; }, 0) / weekData.length, 0),
              medical: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cytology.medical; }, 0) / weekData.length, 0)
            },
            cotesting: {
              all: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cotesting.all; }, 0) / weekData.length, 0),
              office: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cotesting.office; }, 0) / weekData.length, 0),
              technique: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cotesting.technique; }, 0) / weekData.length, 0),
              medical: this.round(weekData.reduce((total, v) => { return total + v.ongoing.cotesting.medical; }, 0) / weekData.length, 0)
            }
          }
        };
        this.weightedData.push(averageWeekData);
      }
    },
    // helpers
    cacheData(data) {
      for (const value of data) {
        this.cachedData[this.laboratoryId][value.creationDate] = value;
      }
      this.updateYesterdayData();
    },
    getMonthNumberOfDays(month, year) {
      let dayCount = 30;
      const longMonthsList = [1, 3, 5, 7, 8, 10, 12];
      if (longMonthsList.includes(month)) {
        dayCount = 31;
      } else if (month === 2) {
        // works only between 1904 and 2196
        if (year % 4 === 0 && year !== 2100) {
          dayCount = 29;
        } else {
          dayCount = 28;
        }
      }
      return dayCount;
    },
    round(number, limitForDigits = 10) {
      const hideDigits = number < -limitForDigits || number > limitForDigits;
      return Math.round((number + Number.EPSILON) * (hideDigits ? 1 : 10)) / (hideDigits ? 1 : 10);
    },
    sumArrays(arrays) {
      const n = arrays.reduce((max, v) => Math.max(max, v.length), 0);
      const result = Array.from({ length: n });
      return result.map((_, i) => arrays.map(arr => arr[i]).reduce((sum, v) => {
        return (v === null ? null : sum + v);
      }, 0));
    },
    // tooltip
    createCustomToolTip(tooltipModel, componentRefName, chartDataName, options = null) {
      // get tooltip element
      let parent = this;
      let tooltipEl = null;
      for (let i = 0; i < 5; i++) {
        parent = parent.$parent;
        if (parent.$refs["kt-tooltip"]) {
          tooltipEl = parent.$refs["kt-tooltip"];
          break;
        }
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add("no-transform");
      }

      // Set Text
      if (tooltipModel.body && tooltipModel.dataPoints && tooltipModel.dataPoints.length) {
        var indexData = this.getChartIndexData(chartDataName, tooltipModel.dataPoints[0].index, options);

        const headerLabel = "<th class=\"kt-tooltip__header-label\">" + indexData.label + "</th>";
        const headerValue = "<th class=\"kt-tooltip__header-value\">" + indexData.totalValue + "</th>";
        var innerHtml = "<table aria-label=\"" + this.$t("statistics.tooltipLabel") + "\"><thead><tr>" + headerLabel + headerValue + "</tr></thead>";
        innerHtml += "<tbody>";
        indexData.lines.forEach(function(line, _i) {
          const colorBox = "<span class=\"kt-tooltip__line-color\" style=\"background: " + line.backgroundColor + ";\"></span>";
          const lineLabel = "<span class=\"kt-tooltip__line-label\">" + line.label + "</span>";
          const lineValue = "<span class=\"kt-tooltip__line-value\">" + line.value + "</span>";
          innerHtml += "<tr><td>" + colorBox + lineLabel + "</td><td>" + lineValue + "</td></tr>";
        });
        innerHtml += "</tbody></table>";

        var contentRoot = tooltipEl.querySelector(".kt-tooltip__wrapper");
        contentRoot.innerHTML = innerHtml;
      }

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      var position = this.$refs[componentRefName].$refs.canvas.getBoundingClientRect();
      tooltipEl.style.left = position.left + tooltipModel.caretX + "px";
      tooltipEl.style.top = position.top + tooltipModel.caretY + "px";
    },
    getChartIndexData(chartDataName, index, options = null) {
      if (!options) options = {};
      if (!options.isAverage) options.isAverage = false;
      if (!options.isCumulative) options.isCumulative = false;
      const dataSets = this[chartDataName].datasets.filter(v => v.data);
      const lines = [];
      for (let i = 0; i < dataSets.length; i++) {
        let value = dataSets[i].data[index];
        if (options.isCumulative) value -= (i > 0 ? dataSets[i - 1].data[index] : 0);
        lines.push({
          backgroundColor: dataSets[i].backgroundColor,
          label: dataSets[i].label,
          value: value
        });
      }
      let totalValue = lines.reduce((total, v) => { return total + v.value; }, 0);
      if (options.isAverage) totalValue = this.round(totalValue / lines.length, 100);
      return {
        label: this[chartDataName].labelsFull[index],
        lines: lines,
        totalValue: totalValue
      };
    },
    // UNUSED
    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
        tooltipEl.style.borderRadius = "3px";
        tooltipEl.style.color = "white";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    },
    externalTooltipHandler(context) {
      // UNUSED
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = this.getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement("thead");

        titleLines.forEach(title => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = 0;

          const th = document.createElement("th");
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement("tbody");
        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];

          const span = document.createElement("span");
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = "2px";
          span.style.marginRight = "10px";
          span.style.height = "10px";
          span.style.width = "10px";
          span.style.display = "inline-block";

          const tr = document.createElement("tr");
          tr.style.backgroundColor = "inherit";
          tr.style.borderWidth = 0;

          const td = document.createElement("td");
          td.style.borderWidth = 0;

          const text = document.createTextNode(body);

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
    }
  }
};
</script>

<template>
  <div class="kt-contactInformation-edit">
    <!-- contactInformation form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- isActive badge -->
        <div
          v-show="!isActive"
          class="kt-badge kt-badge--red kt-badge--lg"
        >
          <b-icon
            icon="trash"
            class="kt-badge__icon"
          ></b-icon>
          <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("general.deletedLabel") }}</span></span>
        </div>

        <b-col cols="12"></b-col>

        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.name')"
            :state="nameState"
            :invalid-feedback="nameInvalidFeedback"
          >
            <b-form-input
              ref="nameInput"
              v-model="name"
              trim
              maxlength="50"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12"></b-col>

        <!-- address1 -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('contactInformations.address1')"
            :state="address1State"
            :invalid-feedback="address1InvalidFeedback"
          >
            <b-form-input
              v-model="address1"
              trim
              maxlength="50"
              :state="address1State"
              @blur="address1Validation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- address2 -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.address2')"
            :state="address2State"
            :invalid-feedback="address2InvalidFeedback"
            class="form-group--optional"
          >
            <b-form-input
              v-model="address2"
              trim
              maxlength="50"
              :state="address2State"
              @blur="address2Validation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- zip -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.zip')"
            :state="zipState"
            :invalid-feedback="zipInvalidFeedback"
          >
            <b-form-input
              v-model="zip"
              trim
              maxlength="50"
              :state="zipState"
              @blur="zipValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- city -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.city')"
            :state="cityState"
            :invalid-feedback="cityInvalidFeedback"
          >
            <b-form-input
              v-model="city"
              trim
              maxlength="50"
              :state="cityState"
              @blur="cityValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- country -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.country')"
            :state="countryState"
            :invalid-feedback="countryInvalidFeedback"
          >
            <b-form-input
              v-model="country"
              trim
              maxlength="50"
              :state="countryState"
              @blur="countryValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- phone -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.phone')"
            :state="phoneState"
            :invalid-feedback="phoneInvalidFeedback"
          >
            <b-form-input
              v-model="phone"
              class="kt-input--prefixed"
              trim
              maxlength="50"
              :state="phoneState"
              @blur="phoneValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t('general.phoneCountryCodeDefault') }}
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- mobile -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.mobile')"
            :state="mobileState"
            :invalid-feedback="mobileInvalidFeedback"
            class="form-group--optional"
          >
            <b-form-input
              v-model="mobile"
              class="kt-input--prefixed"
              trim
              maxlength="50"
              :state="mobileState"
              @blur="mobileValidation = true"
            ></b-form-input>
            <!-- prefix -->
            <div class="kt-input-prefix">
              <div class="kt-input-prefix__content">
                {{ $t('general.phoneCountryCodeDefault') }}
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contactInformations.email')"
            :state="emailState"
            :invalid-feedback="emailInvalidFeedback"
            :class="{'form-group--optional': emailOptionalProp}"
          >
            <b-form-input
              v-model="email"
              trim
              maxlength="50"
              :state="emailState"
              @blur="emailValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import address from "@shared/services/UI/address";
import phoneNumber from "@shared/services/UI/phoneNumber";

export default {
  mixins: [userRights, error, address, phoneNumber],
  props: {
    contactInformationProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    editModeProp: {
      type: Boolean
    },
    emailOptionalProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      contactInformation: null,
      // form variables
      contactInformationId: null,
      isActive: true,
      name: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      country: this.getLocalisedDefaultCountry(),
      phone: "",
      mobile: "",
      email: "",
      // is validation active
      nameValidation: false,
      address1Validation: false,
      address2Validation: false,
      zipValidation: false,
      cityValidation: false,
      countryValidation: false,
      phoneValidation: false,
      mobileValidation: false,
      emailValidation: false
    };
  },
  computed: {
    // form validation
    nameState: function() {
      if (!this.address1Validation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    address1State: function() {
      if (!this.address1Validation) return null;
      return this.address1 && this.address1.length > 0 ? null : false;
    },
    address1InvalidFeedback: function() {
      return this.address1State === false ? this.$t("validationRules.required") : "";
    },
    address2State: function() {
      return null;
    },
    address2InvalidFeedback: function() {
      return "";
    },
    zipState: function() {
      if (!this.zipValidation) return null;
      return this.zip && this.zip.length > 0 ? null : false;
    },
    zipInvalidFeedback: function() {
      return this.zipState === false ? this.$t("validationRules.required") : "";
    },
    cityState: function() {
      if (!this.cityValidation) return null;
      return this.city && this.city.length > 0 ? null : false;
    },
    cityInvalidFeedback: function() {
      return this.cityState === false ? this.$t("validationRules.required") : "";
    },
    countryState: function() {
      if (!this.countryValidation) return null;
      return this.country && this.country.length > 0 ? null : false;
    },
    countryInvalidFeedback: function() {
      return this.countryState === false ? this.$t("validationRules.required") : "";
    },
    phoneState: function() {
      // validation active
      if (!this.phoneValidation) return null;
      return this.phone && this.phone.length > 0 && /^0?[1234589]\d{8}$/.test(this.phone) ? null : false;
    },
    phoneInvalidFeedback: function() {
      // no error
      if (this.phoneState === null) return "";
      // if empty
      if (!this.phone) return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidLandlinePhone");
    },
    mobileState: function() {
      // validation active
      if (!this.mobileValidation) return null;
      if (this.mobile === "") return null;
      return this.mobile && this.mobile.length > 0 && /^0?[67]\d{8}$/.test(this.mobile) ? null : false;
    },
    mobileInvalidFeedback: function() {
      // no error
      if (this.mobileState === null) return "";
      // else : invalid
      return this.$t("validationRules.invalidMobilePhone");
    },
    emailState: function() {
      if (!this.emailValidation) return null;
      if (this.emailOptionalProp && !this.email) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (!this.email) return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },

    // formatted variables
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("contactInformationEdit.submitText") : this.$t("contactInformationNew.submitText");
    }
  },
  async mounted() {
    // import data
    if (this.editModeProp) {
      await this.importData();
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.nameInput.focus();
    },

    // load/refresh data
    importData() {
      // import data
      this.isActive = this.contactInformationProp.isActive;
      this.contactInformationId = this.contactInformationProp.id;
      this.name = this.contactInformationProp.name;
      this.address1 = this.contactInformationProp.address1;
      this.address2 = this.contactInformationProp.address2;
      this.zip = this.contactInformationProp.zip;
      this.city = this.contactInformationProp.city;
      this.country = this.contactInformationProp.country;
      this.phone = this.importFormatPhone(this.contactInformationProp.phone);
      this.mobile = this.importFormatPhone(this.contactInformationProp.mobile);
      this.email = this.contactInformationProp.email;
    },

    // submit functions
    onSubmit() {
      if (this.onValidateContactInformation() !== false) this.sendContactInformation();
    },
    onValidateContactInformation() {
      this.nameValidation = true;
      this.address1Validation = true;
      this.address2Validation = true;
      this.zipValidation = true;
      this.cityValidation = true;
      this.countryValidation = true;
      this.phoneValidation = true;
      this.mobileValidation = true;
      this.emailValidation = true;

      return !(
        this.nameState === false ||
        this.address1State === false ||
        this.address2State === false ||
        this.zipState === false ||
        this.cityState === false ||
        this.countryState === false ||
        this.phoneState === false ||
        this.mobileState === false ||
        this.emailState === false
      );
    },
    sendContactInformation() {
      // Go back to main component
      const contactInformationNewData = {
        id: this.contactInformationId,
        isActive: this.isActive,
        name: this.name,
        address1: this.address1,
        address2: this.address2,
        zip: this.zip,
        city: this.city,
        country: this.country,
        phone: this.exportFormatPhone(this.phone),
        mobile: this.exportFormatPhone(this.mobile),
        email: this.email
      };
      if (this.editModeProp) {
        this.$emit("contactInformationEdited", contactInformationNewData);
      } else {
        this.$emit("contactInformationAdded", contactInformationNewData);
      }
    }
  }
};
</script>

<template>
  <div
    class="kt-view-page kt-automaticAction-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("systemSettings.automaticActionView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('system/systemSettings/automaticAction/edit', {automaticActionIdProp: automaticActionId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("systemSettings.automaticActionView.editAutomaticAction") }}
      </b-button>
      <!-- subtitle
      <p class="kt-page-header__subtitle mb-0">
      </p> -->
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <b-row>
          <!-- name -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("systemSettings.automaticActions.name") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ name }}
            </p>
          </b-col>
          <!-- parameters -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("systemSettings.automaticActions.parameters") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ parameters }}
            </p>
          </b-col>
          <!-- rule -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6 mb-0">
              {{ $t("systemSettings.automaticActionView.rule") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0 text-secondary text-13">
              {{ $t("systemSettings.automaticActionView.ruleInformation") }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-1">
              {{ rule }}
            </p>
          </b-col>
          <!-- isActive -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("systemSettings.automaticActions.isActive") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <b-form-checkbox
              v-model="isActive"
              disabled
              switch
              class="mr-n2"
            >
              {{ isActive ? $t("users.edition.active") : $t("users.edition.inactive") }}
            </b-form-checkbox>
          </b-col>
          <!-- lastExecutionTimestamp -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("systemSettings.automaticActions.lastExecutionTimestamp") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ lastExecutionTimestamp ? getFormattedDateTimeFromTimestamp(lastExecutionTimestamp, true, true, true) : "" }}
            </p>
          </b-col>
          <!-- nextInvocation -->
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("systemSettings.automaticActions.nextInvocation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ nextInvocation ? getFormattedDateTimeFromTimestamp(nextInvocation, true, true, true) : "" }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [userRights, error, date, viewComponents],
  props: {
    automaticActionIdProp: {
      type: Number,
      default: null
    },
    moduleModeProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "name",
          "rule",
          "parameters",
          "isActive",
          "lastExecutionTimestamp",
          "nextInvocation"
        ];
      }
    }
  },
  data() {
    return {
      // general
      automaticActionId: this.automaticActionIdProp,
      automaticActionData: null,
      // base data
      name: "",
      rule: "",
      parameters: "",
      isActive: false,
      lastExecutionTimestamp: "",
      nextInvocation: ""
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get automaticAction
      const resServices = await commonServices.get("automaticActions", this.automaticActionId);
      this.automaticActionData = resServices.data;
      // assign the data
      this.name = resServices.data.name;
      this.rule = resServices.data.rule;
      this.parameters = resServices.data.parameters;
      this.isActive = resServices.data.isActive;
      this.lastExecutionTimestamp = resServices.data.lastExecutionTimestamp;
      this.nextInvocation = resServices.data.nextInvocation;
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("system/systemSettings/automaticActions");
    }
  }
};
</script>

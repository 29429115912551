<template>
  <div class="kt-choose-organisation">
    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="organisationFields"
      :items="organisations"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onOrganisationSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter OrganisationType -->
          <b-th class="kt-table__th-search">
            <b-form-select
              ref="filterTypeInput"
              v-model="filterOrganisationTypeName"
              :disabled="isfilterOrganisationTypeDisabled"
              class="kt-table__th-search-input"
              :options="organisationTypeOptions"
              value-field="name"
              text-field="localisedNamePlural"
              @input="onFilterChange"
            ></b-form-select>
          </b-th>
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="filterCodeInput"
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Name -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.tab.exact="(e) => {
                if (filterName === '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterName')"
              @keydown.tab.exact="(e) => {
                if (filterName !== '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter City -->
          <b-th class="kt-table__th-search">
          </b-th>
        </b-tr>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="!organisations.length && !loading && isSearchActive"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("organisationChoose.notFound") }}
      </h4>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    optionsProp: {
      type: Object,
      default: function() {
        return {
          filterIsPrescribing: null,
          filterOrganisationTypeName: ""
        };
      },
      deep: true
    },
    initialFilterValuesProp: {
      type: Object,
      default: function() {
        return {
          organisationType: "",
          code: "",
          name: ""
        };
      },
      deep: true
    }
  },
  data() {
    return {
      // general
      loading: false,
      page: 1,
      // table fields
      organisationFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "organisationType",
          formatter: (value, _key, _item) => {
            let returnStr = "";
            if (value.name) {
              returnStr = this.$t("organisationType." + value.name);
            }
            return returnStr;
          },
          sortable: true,
          label: this.$t("organisations.organisationType")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: true,
          label: this.$t("organisations.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "name",
          sortable: true,
          label: this.$t("organisations.name")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "city",
          sortable: true,
          label: this.$t("organisations.city")
        }
      ],
      // table filters
      filterOrganisationTypeName: this.initialFilterValuesProp.organisationType || "all",
      filterCode: this.initialFilterValuesProp.code || "",
      filterName: this.initialFilterValuesProp.name || "",
      // disabling filters
      isfilterOrganisationTypeDisabled: false,
      // table items
      organisations: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterCode ||
        this.filterName
      );
    },
    // options
    organisationTypeOptions: function() {
      let options = JSON.parse(JSON.stringify(this.$systemSettings.organisationTypes || []));
      if (this.optionsProp && this.optionsProp.filterIsPrescribing !== undefined && this.optionsProp.filterIsPrescribing !== null) {
        options = options.filter(v => v.isPrescribing === this.optionsProp.filterIsPrescribing);
      }
      options.unshift({ id: 0, name: "all", localisedName: this.$tc("general.allFeminine", 1), localisedNamePlural: this.$tc("general.allFeminine", 2) });
      return options;
    },
    organisationTypeAllNames: function() {
      let options = JSON.parse(JSON.stringify(this.$systemSettings.organisationTypes || []));
      if (this.optionsProp && this.optionsProp.filterIsPrescribing !== undefined && this.optionsProp.filterIsPrescribing !== null) {
        options = options.filter(v => v.isPrescribing === this.optionsProp.filterIsPrescribing);
      }
      return options.map(v => v.name);
    }
  },
  async mounted() {
    try {
      this.loading = true;

      // force organisationType and disable filter
      if (this.optionsProp.filterOrganisationTypeName) {
        this.filterOrganisationTypeName = this.optionsProp.filterOrganisationTypeName;
        this.isfilterOrganisationTypeDisabled = true;
      } else {
        this.isfilterOrganisationTypeDisabled = false;
      }

      // load table data
      this.searchAndUpdateTable();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstElement() {
      if (!this.isfilterOrganisationTypeDisabled) {
        this.$refs.filterTypeInput.focus();
      } else {
        this.$refs.filterCodeInput.focus();
      }
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchAndUpdateTable();
    },

    // update the table
    async searchAndUpdateTable() {
      try {
        // if no filter
        if (this.isSearchActive === false) {
          this.organisations = [];
          this.loading = false;
          return false;
        }

        this.loading = true;

        let filterOrganisationTypeNames = [];
        if (this.filterOrganisationTypeName === "all" || this.filterOrganisationTypeName === "") {
          filterOrganisationTypeNames = this.organisationTypeAllNames;
        } else {
          filterOrganisationTypeNames = [this.filterOrganisationTypeName];
        }

        // get the table items and update
        const filters = {
          organisationTypes: filterOrganisationTypeNames,
          code: this.filterCode,
          name: this.filterName
        };
        const res = await commonServices.getAll("organisations", filters);
        this.organisations = res.data;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchAndUpdateTable();
      }, 450);
    },

    // on select
    onOrganisationSelected(rows) {
      if (rows && rows.length === 1) {
        this.$emit("chosen", rows[0]);
      }
    }
  }
};
</script>

export default {
  methods: {
    // format to : address1, address2 - zip city, country
    formatAddress(address1 = null, address2 = null, zip = null, city = null, country = null) {
      var address = (address1 || "") + (address1 && address2 ? ", " : "") + (address2 || "");

      address += (address && (zip || city)) ? " - " : "";
      address += (zip || "") + (zip && city ? " " : "") + (city || "");

      address += (address && country ? ", " : "") + (country || "");

      return address;
    },
    getLocalisedDefaultCountry() {
      let country = "";
      if (this.$systemSettings.locale === "fr") {
        country = "France";
      }
      return country;
    }
  }
};

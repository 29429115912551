var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kt-report-entry pb-4"},[_c('div',{staticClass:"kt-page-header mt-4 pt-1 mb-3"},[_c('h2',{staticClass:"h4 mb-0 kt-page-header__title"},[_vm._v(" "+_vm._s(_vm.$t("reportEntry.pageTitle"))+" ")]),_c('p',{staticClass:"kt-page-header__subtitle mb-0 mt-1 text-secondary"},[_vm._v(" "+_vm._s(_vm.$t('reportEntry.pageSubtitle'))+" ")])]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchAccessNumber.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportEntry.accessNumber'),"state":_vm.accessNumberState,"invalid-feedback":_vm.accessNumberInvalidFeedback}},[_c('InputAccessNumber',{ref:"accessNumberInput",attrs:{"stateProp":_vm.accessNumberState,"maxlengthProp":50,"trimProp":""},on:{"updateValidation":function($event){_vm.accessNumberValidatation = $event},"submit":_vm.searchAccessNumber},model:{value:(_vm.accessNumber),callback:function ($$v) {_vm.accessNumber=$$v},expression:"accessNumber"}})],1)],1)],1)],1),_c('div',{staticClass:"w-100 border-top mt-2 mb-2"}),_c('b-modal',{attrs:{"id":"fileChooseFromIdsModal","size":"m","hide-header":"","hide-footer":""},on:{"shown":function () {
      if (_vm.fileOptions && _vm.fileOptions.length > 0) {
        _vm.$refs.chooseFromIdsComponent.focusFirstElement();
      }
    }}},[(_vm.fileOptions && _vm.fileOptions.length > 0)?_c('FileChooseFromIds',{ref:"chooseFromIdsComponent",attrs:{"fileOptionsProp":_vm.fileOptions},on:{"chosen":function($event){($event !== null) ? _vm.navigateIfValidatedFile($event) : _vm.hideChooseModal()},"alert":function (variant, strings) { return _vm.$emit('alert', variant, strings); }}}):_vm._e()],1),_c('b-modal',{attrs:{"id":'notReadyForDiagnosisModal-' + _vm._uid,"size":"m","hide-footer":"","hide-header":""},on:{"shown":function () {
      _vm.$refs.notReadyForDiagnosisButton.focus()
    }}},[_c('header',{staticClass:"modal-header",staticStyle:{"margin":"-1rem -1rem 0 -1rem"}},[_c('div',[_c('h5',{staticClass:"modal-title kt-page-header__title h4 mb-0",staticStyle:{"font-size":"18px","letter-spacing":"-0.03px","line-height":"20px","color":"inherit"}},[_vm._v(" "+_vm._s(_vm.$t('files.notifications.notReadyForDiagnosisTitle'))+" ")])]),_c('button',{ref:"closeButton",staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.$bvModal.hide('notReadyForDiagnosisModal-' + _vm._uid)}}},[_vm._v(" "+_vm._s(_vm.$t('general.closeX'))+" ")])]),_c('div',{staticClass:"pb-1 pt-3"},[_c('b-button',{ref:"notReadyForDiagnosisButton",attrs:{"variant":_vm.$systemSettings.theme,"size":"sm"},on:{"click":function($event){return _vm.navigate('office/file/view', {
          fileIdProp: _vm.notReadyForDiagnosisFileId,
          cancelRouteProp: {name: _vm.$route.name, props: _vm.$route.params}
        })}}},[_c('b-icon',{attrs:{"icon":"eye"}}),_vm._v(" "+_vm._s(_vm.$t('files.notifications.notReadyForDiagnosisButton'))+" ")],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
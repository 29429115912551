<template>
  <div class="statistics">
    <b-form-group
      :label="$t('systemStatistics.startDate')"
      label-for="startDate"
    >
      <b-form-input
        id="startDate"
        v-model="startDate"
        autocomplete="off"
        type="date"
        required
        @change="onPageInput"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      :label="$t('systemStatistics.endDate')"
      label-for="endDate"
    >
      <b-form-input
        ref="endDate"
        v-model="endDate"
        autocomplete="off"
        type="date"
        required
        @change="onPageInput"
      ></b-form-input>
    </b-form-group>
    <div class="d-flex justify-content-around">
      <b-navbar toggleable="lg">
        <b-navbar-toggle target="menu-collapse"></b-navbar-toggle>
        <b-collapse
          id="menu-collapse"
          is-nav
        >
          <b-navbar-nav>
            <b-pagination
              v-show="statisticsNumber"
              v-model="page"
              class="mx-2 my-3"
              :class="'kt-pagination--' + $systemSettings.theme"
              :total-rows="statisticsNumber"
              per-page="12"
              @input="onPageInput"
            ></b-pagination>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <div
      v-for="sector in sectors"
      :key="sector.name"
    >
      <h4
        v-show="statisticsNumber && !loading"
        class="text-center mt-3"
      >
        {{ $t("systemStatistics." + sector.name) }}
      </h4>
      <b-table
        class="shadow p-0 m-0"
        bordered
        outlined
        small
        head-variant="dark"
        foot-clone
        :table-variant="$systemSettings.theme"
        :fields="fields"
        :items="sector.periods"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div :class="'text-center text-' + $systemSettings.theme">
            <b-spinner
              class="align-middle"
              :class="'kt-spinner--' + $systemSettings.theme"
            ></b-spinner>
            <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
          </div>
        </template>
      </b-table>
    </div>
    <h4
      v-show="!statisticsNumber && !loading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>
  </div>
</template>

<script>
import statisticsServices from "@/services/API/statisticsServices";
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";

export default {
  mixins: [userRights, pagination, error],
  data() {
    return {
      loading: false,
      fields: [
        {
          key: "startDate",
          sortable: false,
          label: this.$t("systemStatistics.startDate")
        },
        {
          key: "endDate",
          sortable: false,
          label: this.$t("systemStatistics.endDate")
        },
        {
          key: "count",
          sortable: false,
          label: this.$t("systemStatistics.count")
        }
      ],
      statisticsNumber: 0,
      sectors: [],
      startDate: "",
      endDate: "",
      page: 1
    };
  },
  async mounted() {
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1, startDate.getMonth() + 1, 1);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1, 0);
    this.startDate = startDate.toISOString().split(/[T.]/)[0];
    this.endDate = endDate.toISOString().split(/[T.]/)[0];
    await this.onPageInput();
  },
  methods: {
    async onPageInput() {
      try {
        let startDate = "";
        if (this.startDate !== "") {
          startDate = new Date(this.startDate);
          if (startDate.getFullYear() < 2000) {
            return;
          }
        }
        let endDate = "";
        if (this.endDate !== "") {
          endDate = new Date(this.endDate);
          if (endDate.getFullYear() < 2000) {
            return;
          }
        }
        this.loading = true;
        const filters = {
          startDate: this.startDate,
          endDate: this.endDate
        };
        const res = await statisticsServices.showAll(
          this.page,
          filters,
          {
            by: this.sortBy,
            desc: this.sortDesc
          }
        );
        this.statisticsNumber = res.data.count;
        this.sectors = res.data.sectors;
        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<template>
  <div
    v-if="authorizedSectors.length >= 2"
    class="kt-report-sector"
    :class="'kt-report-sector--' + $systemSettings.theme"
  >
    <div class="kt-report-sector__background"></div>
    <div class="kt-report-sector__container">
      <!-- switcher -->
      <b-button-group
        class="kt-btn-group"
      >
        <b-button
          v-for="sector in authorizedSectors"
          :key="sector.id"
          class="kt-btn-group__btn kt-report-sector__btn"
          :class="(activeSector === sector.name) ? 'kt-report-sector__btn--active' : ''"
          variant="light"
          @click="changeActiveSector(sector.name)"
        >
          <!-- <b-icon icon="house"></b-icon> -->
          {{ $t("sectors." + sector.name) }}
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
// services
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
// helpers
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, error],
  props: {
    currentLocationProp: {
      type: String, // "medicalReports" || "validation"
      default: ""
    }
  },
  data() {
    return {
      activeSector: this.$userSettings.savedBehaviors.diagnosis.activeSector || "undefined",
      sectors: [],
      authorizedSectors: []
    };
  },
  watch: {
    "$userSettings.savedBehaviors.diagnosis.activeSector": function(val) {
      this.activeSector = val;
    }
  },
  beforeCreate() {
    if (!this.$userSettings.savedBehaviors.diagnosis.activeSector && this.$route.meta.activeSector && this.$route.meta.activeSector !== "undefined") {
      this.$userSettings.savedBehaviors.diagnosis.activeSector = this.$route.meta.activeSector;
    }
  },
  async mounted() {
    this.importSectors();

    // if no activeSector, navigate to first subscribed
    if (this.$route.meta.activeSector === "undefined") {
      if (!this.$userSettings.savedBehaviors.diagnosis.activeSector && this.$route.meta.activeSector) {
        this.navigateToFirstAuthorizedSector();
      } else {
        this.changeActiveSector(this.$userSettings.savedBehaviors.diagnosis.activeSector);
      }
    }
  },
  methods: {
    importSectors() {
      this.sectors = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.authorizedSectors = this.sectors.filter((sector) => {
        return sector.isSubscribed && this.isEnabled(["menu", "diagnosis", "sectorSubmenu", this.currentLocationProp, sector.name]);
      });
    },
    changeActiveSector(targetSector) {
      if (targetSector === this.$userSettings.savedBehaviors.diagnosis.activeSector) {
        // same path : do nothing
        return false;
      }

      this.activeSector = targetSector;
      this.$userSettings.savedBehaviors.diagnosis.activeSector = targetSector;

      if (this.currentLocationProp === "medicalReports") {
        // medicalReports
        if (targetSector === "cytology") {
          navigate("diagnosis/medicalReports/cytologyReports");
        } else if (targetSector === "histology") {
          navigate("diagnosis/medicalReports/histologyReports");
        }
      } else if (this.currentLocationProp === "validation") {
        // validation
        if (targetSector === "cytology") {
          navigate("diagnosis/validation/cytologyValidation");
        } else if (targetSector === "histology") {
          navigate("diagnosis/validation/histologyValidation");
        }
      }
    },
    navigateToFirstAuthorizedSector() {
      if (!this.authorizedSectors.length) return false;

      this.$userSettings.savedBehaviors.diagnosis.activeSector = this.authorizedSectors[0].name;

      if (this.currentLocationProp === "medicalReports") {
        // medicalReports
        navigate("diagnosis/medicalReports/" + this.authorizedSectors[0].name + "Reports");
      } else if (this.currentLocationProp === "validation") {
        // validation
        navigate("diagnosis/validation/" + this.authorizedSectors[0].name + "Validation");
      }

      return true;
    }
  }
};
</script>

<style scoped src="./ReportSectorSelect.scss" lang="scss"></style>

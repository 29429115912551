<template>
  <div class="kt-reports-search-form">
    <!-- search form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- laboratoryId -->
        <b-col cols="12">
          <SmartSelectInput
            v-if="$systemSettings.laboratories.length"
            labelProp=""
            :optionsProp="$systemSettings.laboratories"
            valueFieldProp="id"
            textFieldProp="name"

            :valueProp="laboratoryId"
            :stateProp="laboratoryIdStateDisplay"
            :invalidFeedbackProp="laboratoryIdInvalidFeedback"

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="false"
            :selectFirstOnloadProp="true"
            :initialValueProp="laboratoryId"
            @input="$emit('inputLaboratoryId', $event)"
            @keydown.enter.exact="onSubmit"
          />
        </b-col>

        <!-- organisationType -->
        <b-col cols="12">
          <b-form-group
            v-show="showOrganisationTypes && organisation === null"
            :invalid-feedback="organisationInvalidFeedback"
            :state="organisationStateDisplay"
          >
            <b-row>
              <b-col
                cols="12"
                md="2"
                style="flex: 0 0 12.5%; max-width: 12.5%; padding-right: 4px;"
              >
                <b-form-select
                  ref="selectInput"
                  :value="organisationType"
                  :options="organisationTypeOptions"
                  :state="organisationStateDisplay"
                  @input="$emit('InputOrganisationType', $event)"
                />
              </b-col>
              <b-col>
                <!-- <b-button
                  v-show="isEnabled(['menu', 'accounting', 'reports', 'sales', 'clinics'])"
                  class="mr-2"
                  :variant="organisationType === 'clinics' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
                  pill
                  @click="$emit('InputOrganisationType', 'clinics')"
                >
                  <b-icon icon="house"></b-icon>
                  {{ $t("organisationTypePlural.clinics") }}
                </b-button>
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'reports', 'sales', 'healthCenters'])"
                  class="mr-2"
                  :variant="organisationType === 'healthCenters' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
                  pill
                  @click="$emit('InputOrganisationType', 'healthCenters')"
                >
                  <b-icon icon="people"></b-icon>
                  {{ $t("organisationTypePlural.healthCenters") }}
                </b-button>
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'reports', 'sales', 'hospitals'])"
                  class="mr-2"
                  :variant="organisationType === 'hospitals' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
                  pill
                  @click="$emit('InputOrganisationType', 'hospitals')"
                >
                  <b-icon icon="building"></b-icon>
                  {{ $t("organisationTypePlural.hospitals") }}
                </b-button>
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'reports', 'sales', 'healthCare'])"
                  class="mr-2"
                  :variant="organisationType === 'healthCare' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
                  pill
                  @click="$emit('InputOrganisationType', 'healthCare')"
                >
                  <b-icon icon="house-fill"></b-icon>
                  {{ $t("organisationTypePlural.healthCare") }}
                </b-button>
                <b-button
                  v-show="isEnabled(['menu', 'accounting', 'reports', 'sales', 'patients'])"
                  class="mr-2"
                  :variant="organisationType === null ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
                  pill
                  @click="$emit('InputOrganisationType', null)"
                >
                  <b-icon icon="person"></b-icon>
                  {{ $t("organisationTypePlural.patients") }}
                </b-button> -->
                <b-button
                  class="ml-n1 kt-btn-transparent text-dark pl-0"
                  variant="light"
                  pill
                  @click="showOrganisationTypes = false; organisationValidation = false;"
                >
                  <b-icon
                    icon="arrow-left-right"
                    :class="'text-' + $systemSettings.theme"
                  ></b-icon>
                  {{ $t("reports.reportsSearchForm.searchByOrganisation") }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <!-- specific organisation -->
        <b-col
          v-show="!showOrganisationTypes"
          cols="12"
          md="3"
          class="mb-3"
        >
          <InputSearch
            searchedTableProp="organisations"
            searchedFieldProp="name"
            :organisationTypeNamesProp="['clinics', 'healthCare', 'healthCenters', 'hospitals']"
            :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
            suggestionsKeyProp="id"
            :labelProp="$t('reports.reportsSearchForm.organisation')"
            :valueProp="organisation"
            :displayedValueProp="suggestionFormatOrganisation(organisation)"
            :suggestionFormatProp="suggestionFormatOrganisation"

            chooseComponentPathProp="views/Data/Organisations/OrganisationChoose.vue"
            :chooseOptionsProp="{}"
            :initialFilterValuesProp="{}"

            :stateProp="organisationStateDisplay"
            :invalidFeedbackProp="organisationInvalidFeedback"
            @onUpdateParent="onOrganisationChosen($event)"
            @onActivateValidation="organisationValidation = true"
            @keydown.enter.exact="onSubmit"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>
        <b-col
          v-show="!showOrganisationTypes"
          class="mb-3 pl-0"
        >
          <b-button
            class="kt-btn-transparent text-dark pl-0"
            style="margin-top: 30px;"
            variant="light"
            pill
            @click="showOrganisationTypes = true; $emit('inputOrganisation', null)"
          >
            <b-icon
              icon="arrow-left-right"
              :class="'text-' + $systemSettings.theme"
            ></b-icon>
            {{ $t("reports.reportsSearchForm.searchByOrganisationType") }}
          </b-button>
        </b-col>

        <!-- divider -->
        <b-col cols="12"></b-col>

        <!-- paymentType -->
        <b-col
          v-show="displayPaymentTypeProp && organisationType === null && showOrganisationTypes"
          cols="12"
          md="3"
        >
          <SmartSelectInput
            v-if="displayPaymentTypeProp && paymentTypesOptionsProp.length"
            ref="paymentTypeInput"
            :labelProp="$t('payments.awaitingPayments.paymentType')"
            :optionsProp="paymentTypesOptionsProp"
            valueFieldProp="id"
            textFieldProp="localisedName"

            :valueProp="paymentTypeId"
            :stateProp="null"
            invalidFeedbackProp=""

            :disabledProp="false"
            :showDisabledItemsProp="false"
            :showInactiveItemsProp="false"
            :displayUniqueProp="true"
            :selectFirstOnloadProp="false"
            :initialValueProp="null"
            :responsiveOptionsProp="{cols: 12, md: 12}"
            @input="$emit('paymentTypeId', $event)"
          />
        </b-col>

        <!-- awaitingPaymentUser -->
        <b-col
          v-show="displayAwaitingPaymentUserProp && organisationType === null && showOrganisationTypes"
          cols="12"
          md="3"
        >
          <InputSearch
            searchedTableProp="users"
            searchedFieldProp="lastName"
            :searchedLaboratoriesProp="$systemSettings.availableLaboratories.map(v => v.id)"
            :returnFieldsArrayProp="['id', 'lastName', 'firstName']"
            suggestionsKeyProp="id"

            :labelProp="$t('pathologists.userId')"
            :valueProp="awaitingPaymentUser"
            :displayedValueProp="suggestionFormatUsers(awaitingPaymentUser)"
            :suggestionFormatProp="suggestionFormatUsers"
            :stateProp="null"
            invalidFeedbackProp=""

            @onUpdateParent="$emit('awaitingPaymentUser', $event)"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>

        <!-- divider -->
        <b-col cols="12"></b-col>

        <!-- period -->
        <b-col
          cols="12"
          md="3"
        >
          <label
            for="kt-sales__first-date-input"
            class="d-block"
            style="margin-bottom: 5px;"
          >
            {{ periodLabel }}
          </label>
          <b-form-group
            v-show="!hideStartDateProp"
            :invalid-feedback="startDateInvalidFeedback"
            :state="startDateStateDisplay"
            style="display: inline-block; width: 50%; padding-right: 4px; vertical-align: top;"
          >
            <b-form-input
              id="kt-sales__first-date-input"
              :value="startDate"
              type="date"
              :state="startDateStateDisplay"
              @input="$emit('inputStartDate', $event)"
              @blur="startDateValidation = true"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :invalid-feedback="endDateInvalidFeedback"
            :state="endDateStateDisplay"
            style="display: inline-block; width: 50%; vertical-align: top;"
            :style="!hideStartDateProp ? 'padding-left: 4px;' : ''"
          >
            <b-form-input
              :value="endDate"
              type="date"
              :state="endDateStateDisplay"
              @input="$emit('inputEndDate', $event)"
              @blur="endDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- balance date -->
        <b-col
          v-show="displayBalanceDateProp"
          cols="12"
          md="3"
          class="pl-0"
        >
          <label
            for="kt-sales__balance-input"
            class="d-block"
            style="margin-bottom: 5px;"
          >
            {{ balanceLabel }}
          </label>
          <div v-show="sameBalanceDate">
            <b-button
              class="kt-btn-transparent text-dark pl-0"
              variant="light"
              @click="sameBalanceDate = false;"
            >
              <b-icon
                icon="arrow-left-right"
                :class="'text-' + $systemSettings.theme"
              ></b-icon>
              {{ $t("reports.reportsSearchForm.differentBalanceDate") }}
            </b-button>
          </div>
          <div v-show="!sameBalanceDate">
            <b-form-group
              v-show="!hideBalanceStartDateProp"
              style="display: inline-block; width: 50%; padding-right: 4px; vertical-align: top;"
            >
              <b-form-input
                :value="startDate"
                type="date"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :invalid-feedback="balanceDateInvalidFeedback"
              :state="balanceDateState"
              style="display: inline-block; width: 50%; padding-right: 4px; vertical-align: top;"
            >
              <b-form-input
                for="kt-sales__balance-input"
                :value="balanceDate"
                type="date"
                :state="balanceDateStateDisplay"
                @input="$emit('inputBalanceDate', $event)"
                @blur="balanceDateValidation = true"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class=""
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ $t("general.search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import InputSearch from "@shared/views/Helpers/InputSearch";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";

export default {
  components: { SmartSelectInput, InputSearch },
  mixins: [userRights, error, date],
  props: {
    laboratoryId: {
      type: Number,
      default: null
    },
    organisationType: {
      type: String,
      default: null
    },
    organisation: {
      type: Object,
      default: function() {
        return null;
      }
    },
    startDate: {
      type: String,
      default: ""
    },
    hideStartDateProp: {
      type: Boolean
    },
    endDate: {
      type: String,
      default: ""
    },
    balanceDate: {
      type: String,
      default: ""
    },
    authorizesGapInMonthsProp: {
      type: Number,
      default: 12
    },
    displayBalanceDateProp: {
      type: Boolean
    },
    hideBalanceStartDateProp: {
      type: Boolean
    },
    balanceEndDateMustBeAfterEndDateProp: {
      type: Boolean
    },
    paymentTypeId: {
      type: Number,
      default: null
    },
    paymentTypesOptionsProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    displayPaymentTypeProp: {
      type: Boolean
    },
    awaitingPaymentUser: {
      type: Object,
      default: function() {
        return null;
      }
    },
    displayAwaitingPaymentUserProp: {
      type: Boolean
    },
    displayAllOrganisationsOptionProp: {
      type: Boolean
    },
    isValidationActive: {
      type: Boolean
    },
    alwaysDisplayBalanceDatesProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      showOrganisationTypes: true,
      sameBalanceDate: !this.alwaysDisplayBalanceDatesProp && (this.endDate === this.balanceDate || !this.displayBalanceDateProp),
      // is validation active
      laboratoryIdValidation: false,
      organisationValidation: false,
      startDateValidation: false,
      endDateValidation: false,
      balanceDateValidation: false
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      return this.laboratoryId && this.laboratoryId > 0 ? null : false;
    },
    laboratoryIdStateDisplay: function() {
      if (!this.laboratoryIdValidation) return null;
      return this.laboratoryIdState;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdStateDisplay === false ? this.$t("validationRules.required") : "";
    },
    organisationState: function() {
      if (this.showOrganisationTypes) {
        // organisationType required
        return this.organisationType || this.organisationType === null ? null : false;
      } else {
        // organisation required
        return !this.organisation || !this.organisation.id ? false : null;
      }
    },
    organisationStateDisplay: function() {
      if (!this.organisationValidation) return null;
      return this.organisationState;
    },
    organisationInvalidFeedback: function() {
      if (this.organisationStateDisplay === null) return "";
      // organisationTypeName or organisation required
      return this.$t("validationRules.required");
    },
    isValidGapBetweenStartEnd: function() {
      if (this.hideStartDateProp) return true;
      return this.validateDateBeforeDate(this.getIsoDate(-1, -this.authorizesGapInMonthsProp, 0, this.endDate), this.startDate);
    },
    startDateState: function() {
      if (!this.startDate) return false;
      if (!this.validateDateBeforeDate(this.startDate, this.endDate)) return false;
      if (!this.isValidGapBetweenStartEnd) return false;
      return null;
    },
    startDateStateDisplay: function() {
      if (!this.startDateValidation) return null;
      return this.startDateState;
    },
    startDateInvalidFeedback: function() {
      if (this.startDateStateDisplay === null) return "";
      if (!this.startDate) return this.$t("validationRules.required");
      if (!this.isValidGapBetweenStartEnd) return this.$tc("reports.reportsSearchForm.validations.oneMonthMaxGap", this.authorizesGapInMonthsProp, { authorizesGapInMonths: this.authorizesGapInMonthsProp });
      return this.$t("validationRules.endDateBeforeStartDate");
    },
    endDateState: function() {
      if (!this.endDate) return false;
      if (!this.validateDateBeforeDate(this.startDate, this.endDate)) return false;
      if (!this.isValidGapBetweenStartEnd) return false;
      return null;
    },
    endDateStateDisplay: function() {
      if (!this.endDateValidation) return null;
      return this.endDateState;
    },
    endDateInvalidFeedback: function() {
      if (this.endDateStateDisplay === null) return "";
      if (!this.endDate) return this.$t("validationRules.required");
      if (!this.isValidGapBetweenStartEnd) return "";
      return this.$t("validationRules.endDateBeforeStartDate");
    },
    isValidBalanceEndDateAfterEndDate: function() {
      if (!this.balanceEndDateMustBeAfterEndDateProp) return true;
      return this.validateDateBeforeDate(this.endDate, this.balanceDate);
    },
    balanceDateState: function() {
      if (!this.displayBalanceDateProp) return null;
      if (!this.balanceDate) return false;
      if (!this.isValidBalanceEndDateAfterEndDate) return false;
      return this.validateDateBeforeDate(this.startDate, this.balanceDate) ? null : false;
    },
    balanceDateStateDisplay: function() {
      if (!this.balanceDateValidation) return null;
      return this.balanceDateState;
    },
    balanceDateInvalidFeedback: function() {
      if (this.balanceDateStateDisplay === null) return "";
      if (!this.balanceDate) return this.$t("validationRules.required");
      if (!this.isValidBalanceEndDateAfterEndDate) return this.$t("reports.reportsSearchForm.validations.balanceEndDateAfterEndDateError");
      return this.$t("reports.reportsSearchForm.validations.balanceDateBeforeStartDateError");
    },
    areAllFieldsValid: function() {
      const result = (
        this.laboratoryIdState !== false &&
        this.organisationState !== false &&
        this.startDateState !== false &&
        this.endDateState !== false &&
        this.balanceDateState !== false
      );
      this.$emit("updateState", result);
      return result;
    },
    periodLabel: function() {
      switch (this.$route.name) {
      case "accounting/reports/balanceReport":
        return this.$t("reports.reportsSearchForm.periodLabels.balanceReport");
      case "accounting/reports/filesNotInvoiced":
        return this.$t("reports.reportsSearchForm.periodLabels.filesNotInvoiced");
      case "accounting/reports/sales":
        return this.$t("reports.reportsSearchForm.periodLabels.sales");
      case "accounting/reports/fileInvoicesNotPaid":
        return this.$t("reports.reportsSearchForm.periodLabels.fileInvoicesNotPaid");
      case "accounting/reports/allPaymentsReport":
        return this.$t("reports.reportsSearchForm.periodLabels.allPaymentsReport");
      case "accounting/reports/receivedPayments":
        return this.$t("reports.reportsSearchForm.periodLabels.receivedPayments");
      case "accounting/reports/incorrectPayments":
        return this.$t("reports.reportsSearchForm.periodLabels.incorrectPayments");
      case "accounting/reports/compensationsReport":
        return this.$t("reports.reportsSearchForm.periodLabels.compensationsReport");
      default:
        return this.$t("reports.reportsSearchForm.period");
      }
    },
    balanceLabel: function() {
      switch (this.$route.name) {
      case "accounting/reports/balanceReport":
        return this.$t("reports.reportsSearchForm.balanceLabels.balanceReport");
      case "accounting/reports/filesNotInvoiced":
        return this.$t("reports.reportsSearchForm.balanceLabels.filesNotInvoiced");
      case "accounting/reports/sales":
        return this.$t("reports.reportsSearchForm.balanceLabels.sales");
      case "accounting/reports/fileInvoicesNotPaid":
        return this.$t("reports.reportsSearchForm.balanceLabels.fileInvoicesNotPaid");
      case "accounting/reports/allPaymentsReport":
        return this.$t("reports.reportsSearchForm.balanceLabels.allPaymentsReport");
      case "accounting/reports/incorrectPayments":
        return this.$t("reports.reportsSearchForm.balanceLabels.incorrectPayments");
      case "accounting/reports/compensationsReport":
        return this.$t("reports.reportsSearchForm.balanceLabels.compensationsReport");
      default:
        return this.$t("reports.reportsSearchForm.balanceDate");
      }
    },
    organisationTypeOptions: function() {
      const options = [
        { value: null, text: this.$t("organisationTypePlural.patients"), disabled: !this.isEnabled(["menu", "accounting", "reports", "sales", "patients"]) },
        { value: "healthCare", text: this.$t("organisationTypePlural.healthCare"), disabled: !this.isEnabled(["menu", "accounting", "reports", "sales", "healthCare"]) },
        { value: "hospitals", text: this.$t("organisationTypePlural.hospitals"), disabled: !this.isEnabled(["menu", "accounting", "reports", "sales", "hospitals"]) },
        { value: "clinics", text: this.$t("organisationTypePlural.clinics"), disabled: !this.isEnabled(["menu", "accounting", "reports", "sales", "clinics"]) },
        { value: "healthCenters", text: this.$t("organisationTypePlural.healthCenters"), disabled: !this.isEnabled(["menu", "accounting", "reports", "sales", "healthCenters"]) }
      ];
      if (this.displayAllOrganisationsOptionProp) {
        options.unshift(
          { value: "all", text: this.$tc("general.allMasculine", 2), disabled: !this.isEnabled(["menu", "accounting", "reports", "sales", "all"]) }
        );
      }
      return options;
    }
  },
  watch: {
    isValidationActive: {
      handler: function(val) {
        if (val) this.validateForm();
      }
    },
    organisation: {
      handler: function(val) {
        if (val) {
          this.showOrganisationTypes = false;
        }
      },
      immediate: true
    }
  },
  async mounted() {
    // set default dates
    const nowDate = this.getIsoDate();
    if (this.startDate === "") this.$emit("inputStartDate", this.getIsoDate(0, -3));
    if (this.endDate === "") this.$emit("inputEndDate", nowDate);
    if (this.balanceDate === "" && this.displayBalanceDateProp) this.$emit("inputBalanceDate", nowDate);
  },
  methods: {
    async onOrganisationChosen($event) {
      if ($event) {
        // get organisation for organisationType
        // const res = await commonServices.get("organisations", $event.id);
        // const organisation = res.data.id ? res.data : null;
        this.$emit("inputOrganisation", { id: $event.id, code: $event.code, name: $event.name });
      } else {
        this.$emit("inputOrganisation", null);
      }
    },

    suggestionFormatOrganisation(organisation) {
      let output = "";
      if (organisation) {
        output = organisation.name + " (" + organisation.code + ")";
        if (organisation.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },
    suggestionFormatUsers(suggestion) {
      let output = "";
      if (suggestion) {
        output = suggestion.firstName + " " + suggestion.lastName;
      }
      return output;
    },

    // submit functions
    validateForm() {
      this.laboratoryIdValidation = true;
      this.organisationValidation = true;
      this.startDateValidation = true;
      this.endDateValidation = true;
      this.balanceDateValidation = true;

      return (
        this.laboratoryIdState !== false &&
        this.organisationState !== false &&
        this.startDateState !== false &&
        this.endDateState !== false &&
        this.balanceDateState !== false
      );
    },
    onSubmit() {
      if (this.validateForm()) {
        this.$emit("submit", true);
      }
    }
  }
};
</script>

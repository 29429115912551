<template>
  <div class="kt-login">
    <!-- title -->
    <h2 class="kt-login__action-title h5">
      {{ $t('session.login.login') }}
    </h2>
    <!-- register button -->
    <div
      v-if="!enableAuthentication && $systemSettings.application === 'main'"
      class="kt-login__subtitle"
    >
      {{ $t("session.login.registerText") }}
      <b-link
        :class="'kt-link kt-link--' + $systemSettings.theme"
        @click="$emit('goToRegister')"
      >
        {{ $t("session.login.registerCta") }}
      </b-link>
    </div>

    <!-- login form -->
    <b-form @submit.prevent="onSubmit">
      <!-- invalid message -->
      <p
        v-if="isLoginLineErrorDisplayed"
        class="kt-login__invalidMessage text-danger"
      >
        {{ $t("session.login.validations.invalid") }}
      </p>
      <!-- token invalid message -->
      <p
        v-if="isAuthenticatedLineErrorDisplayed"
        class="kt-login__invalidMessage text-danger"
      >
        {{ $t("session.login.validations.invalidToken") }}
      </p>

      <!-- email -->
      <b-form-group
        v-show="!enableAuthentication"
        class="kt-form-group--mb-sm"
        :label="$t('session.login.email')"
        :invalid-feedback="emailInvalidFeedback"
        :state="emailState"
      >
        <b-form-input
          ref="emailInput"
          v-model="email"
          type="email"
          trim
          maxlength="256"
          :state="emailState"
          autocomplete="username"
        ></b-form-input>
      </b-form-group>

      <!-- password -->
      <b-form-group
        v-show="!enableAuthentication"
        :label="$t('session.login.password')"
        :invalid-feedback="passwordInvalidFeedback"
        :state="passwordState"
      >
        <b-form-input
          ref="passwordInput"
          v-model="password"
          class="kt-formField--password"
          :type="isPasswordVisible ? 'text' : 'password'"
          :state="passwordState"
          maxlength="64"
          autocomplete="current-password"
        ></b-form-input>
        <div class="kt-show-password">
          <b-button
            class="kt-show-password__button"
            tabindex="-1"
            @click="onShowPassword"
          >
            <b-icon
              v-show="isPasswordVisible"
              :class="'text-' + $systemSettings.theme"
              icon="eye-slash-fill"
            ></b-icon>
            <b-icon
              v-show="!isPasswordVisible"
              icon="eye-fill"
            ></b-icon>
          </b-button>
        </div>
      </b-form-group>

      <!-- email text for token -->
      <p
        v-show="enableAuthentication"
        class="font-weight-semi-bold"
        style="position: relative; padding: 0 0 0 8px; margin: 16px 0 8px 0;"
      >
        <span
          :class="'bg-' + $systemSettings.theme"
          style="position: absolute; top: 0; left: 0; height: 100%; width: 3px;"
        ></span>
        {{ email }}
      </p>
      <!-- token -->
      <b-form-group
        v-show="enableAuthentication"
        :label="$t('session.login.tokenText')"
        :invalid-feedback="tokenInvalidFeedback"
        :state="tokenState"
        class="kt-form-group--mb-sm"
      >
        <b-form-input
          ref="tokenInput"
          v-model="token"
          class="mb-1"
          maxlength="50"
          :state="passwordState"
        ></b-form-input>
        <b-link
          :class="'kt-link--' + $systemSettings.theme"
          style="font-size: 15px;"
          @click.prevent="onSendSmsToken"
        >
          {{ $t('session.login.tokenSmsLink') }}
        </b-link>
      </b-form-group>

      <!-- footer -->
      <div class="kt-login__footer">
        <div>
          <!-- forgot password -->
          <b-link
            v-show="!enableAuthentication"
            class="kt-link kt-link--gray kt-link--underlined mr-4"
            @click="$emit('forgotPassword')"
          >
            {{ $t("session.login.forgotPassword") }}
          </b-link>
        </div>
        <!-- submit -->
        <div>
          <b-button
            :class="!enableAuthentication ? 'my-2' : 'mb-1 mt-0'"
            :variant="$systemSettings.theme"
            pill
            type="submit"
          >
            {{ $t("session.login.login") }}
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import sessionsServices from "@/services/API/sessionsServices";
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  data() {
    return {
      email: "",
      password: "",
      isPasswordVisible: false,
      // authentication
      enableAuthentication: false,
      token: "",
      // invalid login
      isLoginLineErrorDisplayed: false,
      isAuthenticatedLineErrorDisplayed: false,
      // is validation active
      emailValidation: false,
      passwordValidation: false,
      tokenValidation: false
    };
  },
  computed: {
    // form validation
    emailState: function() {
      if (!this.emailValidation) return null;
      // test if empty or invalid
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email) ? null : false;
    },
    emailInvalidFeedback: function() {
      // no error
      if (this.emailState === null) return "";
      // if empty
      if (this.email === "") return this.$t("validationRules.required");
      // else : invalid
      return this.$t("validationRules.invalidEmail");
    },
    passwordState: function() {
      if (!this.passwordValidation) return null;
      return this.password && this.password.length > 0 ? null : false;
    },
    passwordInvalidFeedback: function() {
      return this.passwordState === false ? this.$t("validationRules.required") : "";
    },
    tokenState: function() {
      if (!this.tokenValidation) return null;
      return this.token && this.token.length > 0 ? null : false;
    },
    tokenInvalidFeedback: function() {
      return this.tokenState === false ? this.$t("validationRules.required") : "";
    }
  },
  mounted() {
    // auto focus
    this.$refs.emailInput.focus();
  },
  methods: {
    onShowPassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.$refs.passwordInput.focus();
    },

    async onSendSmsToken() {
      const res = await sessionsServices.sendSmsToken({
        email: this.email,
        password: this.password
      });
      if (res.data === true) {
        this.$emit("alert", "success", {
          title: this.$t("session.login.notifications.onSmsTokenSuccessTitle")
        });
      } else {
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.onSmsTokenErrorTitle"),
          message: this.$t("session.login.notifications.onSmsTokenErrorText")
        });
      }
    },

    // submit functions
    onValidate() {
      if (this.enableAuthentication) {
        this.passwordValidation = false;
        this.emailValidation = false;
        this.tokenValidation = true;

        return this.tokenState !== false;
      } else {
        this.passwordValidation = true;
        this.emailValidation = true;
        this.tokenValidation = false;

        return this.passwordState !== false && this.emailState !== false;
      }
    },
    async onSubmit() {
      try {
        // Validate Login Form
        if (this.onValidate() === false) return false;

        if (this.enableAuthentication) {
          // Token for double authentication
          const resAuth = await sessionsServices.authenticate({ email: this.email, token: this.token });
          if (resAuth.data.id) {
            this.$userSettings.user = {
              id: resAuth.data.id,
              email: resAuth.data.email,
              sex: resAuth.data.sex,
              firstName: resAuth.data.firstName,
              lastName: resAuth.data.lastName,
              phone: resAuth.data.phone || ""
            };
            this.$emit("loggedIn", resAuth.data);
          } else {
            // errors
            this.handleLoginErrors(resAuth.data.error);
          }
        } else {
          // Regular login
          const res = await sessionsServices.login({ email: this.email, password: this.password });
          if (res.data.id) {
            // success
            this.$userSettings.user = {
              id: res.data.id,
              email: res.data.email,
              sex: res.data.sex,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phone: res.data.phone || ""
            };
            this.$emit("loggedIn", res.data);
          } else if (res.data === true) {
            // enableAuthentication
            this.enableAuthentication = true;
            this.isLoginLineErrorDisplayed = false;
            this.isAuthenticatedLineErrorDisplayed = false;
            this.$nextTick(() => this.$refs.tokenInput.focus());
          } else {
            // errors
            this.handleLoginErrors(res.data.error || null);
          }
        }
      } catch (err) {
        // Other errors
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.onLoginErrorTitle"),
          message: this.$t("session.login.notifications.onLoginErrorText")
        });
      }
    },
    handleLoginErrors(err) {
      this.isLoginLineErrorDisplayed = false;
      this.isAuthenticatedLineErrorDisplayed = false;

      if (err === "unknownUser" || err === "unknownContact" || err === "incorrectPassword") {
        // Invalid Login
        this.isLoginLineErrorDisplayed = true;
      } else if (err === "userNotAuthenticated" || err === "contactNotAuthenticated") {
        // user Not Authenticated (invalidToken)
        this.isAuthenticatedLineErrorDisplayed = true;
      } else if (err === "userEmailNotValidated") {
        // user Email Not Validated
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.userEmailNotValidatedTitle"),
          message: this.$t("session.login.notifications.userEmailNotValidatedText")
        });
      } else if (err === "userRequestNotProcessed") {
        // user Not Activated
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.userRequestNotProcessedTitle"),
          message: this.$t("session.login.notifications.userRequestNotProcessedText")
        });
      } else if (err === "userNotActivated") {
        // user Not Activated
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.userNotActivatedTitle"),
          message: this.$t("session.login.notifications.userNotActivatedText")
        });
      } else if (err === "contactLocked") {
        // contactLocked
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.contactLockedTitle"),
          message: this.$t("session.login.notifications.contactLockedText")
        });
      } else if (err === "maxFailedAttemptsReached") {
        // maxFailedAttemptsReached
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.maxFailedAttemptsReachedTitle"),
          message: this.$t("session.login.notifications.maxFailedAttemptsReachedText")
        });
      } else {
        // Other errors
        this.$emit("alert", "danger", {
          title: this.$t("session.login.notifications.onLoginErrorTitle"),
          message: this.$t("session.login.notifications.onLoginErrorText")
        });
      }
    }
  }
};
</script>

<template>
  <div class="kt-organisations">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("organisations.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'data', 'organisations', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('data/organisation/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("organisations.addOrganisationButton") }}
      </b-button>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="fields"
      :items="organisations"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter OrganisationType -->
          <b-th class="kt-table__th-search">
            <b-form-select
              v-model="filterOrganisationType"
              class="kt-table__th-search-input"
              :options="organisationTypeOptions"
              value-field="name"
              text-field="localisedNamePlural"
              @input="onFilterChange"
            ></b-form-select>
          </b-th>
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Name -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Address1 -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterAddress1"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterAddress1 !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAddress1')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Address2 -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterAddress2"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterAddress2 !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAddress2')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Zip -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterZip"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterZip !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterZip')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter City -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterCity"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.tab.exact="(e) => {
                if (filterCity === '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterCity !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCity')"
              @keydown.tab.exact="(e) => {
                if (filterCity !== '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Phone -->
          <b-th class="kt-table__th-search">
          </b-th>

          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 131px;"
          ></b-th>
        </b-tr>
      </template>
      <!-- cell template : action buttons -->
      <template v-slot:cell(organisationButtons)="data">
        <div style="width: 131px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'data', 'organisations', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('data/organisation/view', {organisationIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="isEnabled(['menu', 'data', 'organisations', 'edit'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="mx-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('data/organisation/edit', {organisationIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete  -->
          <b-button
            v-show="isEnabled(['menu', 'data', 'organisations', 'delete'])"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onDeleteOrganisation(data.item)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!organisationsCount && !loading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- pagination -->
    <div class="d-flex justify-content-around">
      <b-navbar>
        <b-navbar-nav>
          <b-pagination
            v-show="organisationsCount"
            v-model="page"
            class="mx-2 my-3"
            :class="'kt-pagination--' + $systemSettings.theme"
            :total-rows="organisationsCount"
            per-page="50"
            @input="onPageInput"
          ></b-pagination>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights, pagination, error, phoneNumber, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      page: 1,
      organisationsCount: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "organisationType",
          formatter: (value, _key, _item) => {
            let returnStr = "";
            if (value.name) {
              returnStr = this.$t("organisationType." + value.name);
            }
            return returnStr;
          },
          sortable: false,
          label: this.$t("organisations.organisationType")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: true,
          label: this.$t("organisations.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "name",
          sortable: true,
          label: this.$t("organisations.name")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "address1",
          sortable: false,
          label: this.$t("organisations.address1")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "address2",
          sortable: false,
          label: this.$t("organisations.address2")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "zip",
          sortable: false,
          label: this.$t("organisations.zip")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "city",
          sortable: false,
          label: this.$t("organisations.city")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "phone",
          formatter: (value, _key, _item) => { return this.importFormatPhone(value); },
          sortable: false,
          label: this.$t("organisations.phone")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "organisationButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterOrganisationType: "all",
      filterCode: "",
      filterName: "",
      filterAddress1: "",
      filterAddress2: "",
      filterZip: "",
      filterCity: "",
      // table items
      organisations: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterOrganisationType: "string",
        filterCode: "string",
        filterName: "string",
        filterAddress1: "string",
        filterAddress2: "string",
        filterZip: "string",
        filterCity: "string"
      }
    };
  },
  computed: {
    // options
    organisationTypeOptions: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.organisationTypes || []));
      options.unshift({ id: 0, name: "all", localisedName: this.$tc("general.allFeminine", 1), localisedNamePlural: this.$tc("general.allFeminine", 2) });
      return options;
    },
    organisationTypeAllNames: function() {
      const options = JSON.parse(JSON.stringify(this.$systemSettings.organisationTypes || []));
      return options.map(v => v.name);
    }
  },
  async mounted() {
    try {
      this.loading = true;

      // pseudo-mixins
      this.navigate = navigate;

      // load table data
      this.onPageInput();

      // auto-focus
      this.$refs.addLineButton.focus();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.editLineButton_0) {
        this.$refs.editLineButton_0.focus();
      } else if (this.$refs.deleteLineButton_0) {
        this.$refs.deleteLineButton_0.focus();
      }
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.onPageInput();
    },

    // searchAndUpdateTable
    async onPageInput() {
      try {
        this.loading = true;

        let filterOrganisationTypeNames = [];
        if (this.filterOrganisationType === "all" || this.filterOrganisationType === "") {
          filterOrganisationTypeNames = this.organisationTypeAllNames;
        } else {
          filterOrganisationTypeNames = [this.filterOrganisationType];
        }

        const res = await commonServices.showAll(
          "organisations",
          this.page,
          {
            organisationTypes: filterOrganisationTypeNames,
            code: this.filterCode,
            name: this.filterName,
            address1: this.filterAddress1,
            address2: this.filterAddress2,
            zip: this.filterZip,
            city: this.filterCity
          },
          {
            by: this.sortBy,
            desc: this.sortDesc
          }
        );
        this.organisationsCount = res.data.count;
        this.organisations = res.data.rows;
        this.organisations.map(organisation => {
          organisation.address1 = organisation.organisationContactInformations[0].contactInformation.address1;
          organisation.address2 = organisation.organisationContactInformations[0].contactInformation.address2;
          organisation.zip = organisation.organisationContactInformations[0].contactInformation.zip;
          organisation.city = organisation.organisationContactInformations[0].contactInformation.city;
          organisation.phone = organisation.organisationContactInformations[0].contactInformation.phone;
          delete organisation.organisationContactInformations;
        });

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onPageInput();
      }, 450);
    },
    // delete
    async onDeleteOrganisation(row) {
      if (typeof row === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("organisations.deletion.text"), {
        title: this.$t("organisations.deletion.title"),
        okVariant: "danger",
        okTitle: this.$t("organisations.deletion.ok"),
        cancelTitle: this.$t("organisations.deletion.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("organisations", row.id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("organisations.deleted.title"),
            message: this.$t("organisations.deleted.text")
          });
          // auto-focus
          this.$refs.addLineButton.focus();
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("organisations.deletionError.title"),
            message: this.$t("organisations.deletionError.text")
          });
        }
        this.onPageInput();
      }
    }
  }
};
</script>

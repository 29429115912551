import { render, staticRenderFns } from "./FileEdit.vue?vue&type=template&id=3474f755&scoped=true&"
import script from "./FileEdit.vue?vue&type=script&lang=js&"
export * from "./FileEdit.vue?vue&type=script&lang=js&"
import style0 from "./FileEdit.scss?vue&type=style&index=0&id=3474f755&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3474f755",
  null
  
)

export default component.exports
<template>
  <div
    class="kt-view-page kt-invoiceReport-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'general', 'invoiceReports'])"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("invoiceReportView.pageTitle", {id: invoiceReportId}) }}
      </h2>
      <!-- transformed badge -->
      <div
        v-show="invoiceReportData && invoiceReportData.isOriginFullyTransformed"
        class="kt-badge kt-badge--yellow ml-3"
      >
        <b-icon
          icon="arrow-left-right"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('invoiceReportView.fullyTransformed') }}</span></span>
      </div>
      <!-- documentStatuses -->
      <DocumentStatuses
        :dataProp="invoiceReportData"
        class="ml-3"
      />
      <!-- export pdf  -->
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'toPdf'])"
        size="sm"
        class="kt-page-header__action ml-3"
        :variant="$systemSettings.theme"
        @click="onExport(invoiceReportData, 'pdf')"
      >
        <b-icon icon="download"></b-icon>
        {{ $t("general.downloadPdf") }}
      </b-button>
      <!-- export xlsx  -->
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'toXlsx'])"
        size="sm"
        class="kt-page-header__action ml-2"
        :variant="$systemSettings.theme"
        @click="onExport(invoiceReportData, 'xlsx')"
      >
        <b-icon icon="table"></b-icon>
        {{ $t("general.downloadXlsx") }}
      </b-button>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION - general -->
      <div
        v-if="laboratoryDisplay || organisationDisplay || referenceDisplay || endDateDisplay || invoiceDateDisplay || invoicedDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- laboratory -->
        <b-row v-if="laboratoryDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("invoiceReports.laboratory") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ laboratory ? laboratory.name + (laboratory.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "") : "" }}
            </p>
          </b-col>
        </b-row>
        <!-- organisation -->
        <b-row v-if="organisationDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("invoiceReports.organisation") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ organisation ? organisation.name + (organisation.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "") : "" }}
            </p>
          </b-col>
        </b-row>
        <!-- reference -->
        <b-row v-if="referenceDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("invoiceReports.reference") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ reference }}
            </p>
          </b-col>
        </b-row>
        <!-- endDate -->
        <b-row v-if="endDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("invoiceReports.endDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ endDate }}
            </p>
          </b-col>
        </b-row>
        <!-- invoiceDate -->
        <b-row v-if="invoiceDateDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("invoiceReports.invoiceDate") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ invoiceDate ? $d(new Date(invoiceDate), "date") : "" }}
            </p>
          </b-col>
        </b-row>
        <!-- invoiced -->
        <b-row v-if="invoicedDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("invoiceReports.invoiced") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ invoiced ? $n(invoiced, "currency") : $n(0, "currency") }}
            </p>
          </b-col>
        </b-row>
      </div>
      <!-- SECTION - invoiceReportLines -->
      <div
        v-if="invoiceReportLinesDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <!-- title -->
        <h3 class="kt-view-page__section-title">
          {{ $t("invoiceReportView.sectionTitleinvoiceReportLines") }}
        </h3>

        <!-- invoiceReportLines (fileInvoices) -->
        <b-row v-if="invoiceReportLinesDisplay">
          <b-col cols="12">
            <b-table
              class="kt-table"
              responsive
              :fields="invoiceReportLinesFields"
              :items="fileInvoices"
              :sort-compare="sortCompare"
            >
              <!-- head hospitalisation  -->
              <template #head(hospitalisationNumber)>
                <div style="white-space: nowrap;">
                  {{ $t("files.hospitalisationNumberShort") }}
                </div>
              </template>
              <!-- head invoiced  -->
              <template #head(invoiced)>
                <div style="max-width: 74px; padding-right: 0.75rem">
                  {{ $t("invoiceReports.invoiced") }}
                </div>
              </template>

              <!-- cell template : access number -->
              <template v-slot:cell(accessNumber)="data">
                <div style="display: flex; align-items: center;">
                  <b-link
                    :variant="$systemSettings.theme"
                    class=""
                    :href="data.item.file.id ? '/office/file/view/' + data.item.file.id : '#'"
                    @click.prevent="navigate('office/file/view', {
                      fileIdProp: data.item.file.id,
                      cancelRouteProp: {name: $route.name, props: $route.params}
                    })"
                  >
                    {{ data.item.file.accessNumber }}
                    <!-- complementary -->
                    <span
                      v-show="data.item.file.parentFileId"
                      class="kt-complementary-icon"
                    >
                      <b-icon icon="back"></b-icon>
                    </span>
                  </b-link>
                </div>
              </template>
              <!-- cell collectionDate  -->
              <template v-slot:cell(collectionDates)="data">
                <p
                  v-for="(collectionDate, index) in data.value"
                  :key="index"
                  class="mb-0"
                >
                  {{ collectionDate ? $d(new Date(collectionDate), "date") : "" }}
                </p>
              </template>
              <!-- cell patient  -->
              <template v-slot:cell(patient)="data">
                <PersonName
                  :sexNameProp="data.item && data.item.file.patient.sex ? data.item.file.patient.sex.name : ''"
                  :firstNameProp="data.item ? data.item.file.patient.firstName : ''"
                  :lastNameProp="data.item ? data.item.file.patient.lastName : ''"
                />
              </template>
              <!-- cell pathologist  -->
              <template v-slot:cell(pathologist)="data">
                <div class="line-height-xs">
                  {{ data.item ? data.item.file.pathologist.user.firstName + ' ' + data.item.file.pathologist.user.lastName : '' }}
                  <br /><span class="text-13 text-secondary">{{ data.item ? data.item.file.pathologist.code : '' }}</span>
                </div>
              </template>
              <!-- cell invoiceActs  -->
              <template v-slot:cell(invoiceActs)="dataAct">
                <div
                  v-if="dataAct.value && dataAct.value.length"
                  class="kt-flex-table"
                >
                  <div
                    class="kt-flex-table__wrapper"
                    role="table"
                    :aria-label="$t('invoiceReports.invoiceActs')"
                  >
                    <div
                      class="kt-flex-table__line kt-flex-table__line--header"
                      role="rowgroup"
                    >
                      <div
                        class="kt-flex-table__row first"
                        role="columnheader"
                      >
                        {{ $t('invoiceReports.invoiceActCode') }}
                      </div>
                      <div
                        class="kt-flex-table__row"
                        role="columnheader"
                      >
                        {{ $t('invoiceReports.invoiceActPrice') }}
                      </div>
                      <div
                        class="kt-flex-table__row"
                        role="columnheader"
                      >
                        {{ $t('invoiceReports.invoiceActInvoiced') }}
                      </div>
                    </div>
                    <div
                      v-for="(invoiceAct, index) in dataAct.value"
                      :key="index"
                      class="kt-flex-table__line kt-flex-table__line--regular"
                      role="rowgroup"
                    >
                      <div
                        class="kt-flex-table__row first"
                        role="cell"
                      >
                        {{ invoiceAct.act.code }}
                      </div>
                      <div
                        class="kt-flex-table__row"
                        role="cell"
                      >
                        {{ invoiceAct.act.price ? $n(invoiceAct.act.price, "currency") : $n(0, "currency") }}
                      </div>
                      <div
                        class="kt-flex-table__row"
                        role="cell"
                      >
                        {{ invoiceAct.invoiced ? $n(invoiceAct.invoiced, "currency") : $n(0, "currency") }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- cell invoiced  -->
              <template v-slot:cell(invoiced)="data">
                <div style="padding-right: 0.75rem;">
                  {{ data.value }}
                </div>
              </template>
              <!-- cell template : transformed badges -->
              <template v-slot:cell(transformed)="data">
                <div class="d-flex">
                  <div
                    v-if="data.item.fileInvoiceTransformations && data.item.fileInvoiceTransformations.length"
                    class="kt-badge kt-badge-icon kt-badge--yellow kt-badge--lg mx-1"
                    style="align-self: center; cursor: pointer;"
                    :style="data.detailsShowing ? 'box-shadow: 0 0 0px 1px #efd25d, 0 0 6px #fee880;' : ''"
                    @click="data.toggleDetails"
                  >
                    <b-icon
                      icon="arrow-left-right"
                      class="kt-badge__icon mr-0"
                    ></b-icon>
                  </div>
                  <div
                    v-if="data.item.targetFileInvoice_fileInvoiceTransformation"
                    class="kt-badge kt-badge-icon kt-badge--blue kt-badge--lg mx-1"
                    style="align-self: center; cursor: pointer;"
                    :style="data.detailsShowing ? 'box-shadow: 0 0 0px 1px #709abf, 0 0 6px #8bfaff;' : ''"
                    @click="data.toggleDetails"
                  >
                    <b-icon
                      icon="arrow-left-right"
                      class="kt-badge__icon mr-0"
                    ></b-icon>
                  </div>
                  <b-button
                    v-if="(data.item.fileInvoiceTransformations && data.item.fileInvoiceTransformations.length) || data.item.targetFileInvoice_fileInvoiceTransformation"
                    size="sm"
                    class="kt-btn-transparent py-0 pl-1 pr-2"
                    style="color: currentColor !important;"
                    :variant="$systemSettings.theme"
                    @click="data.toggleDetails"
                  >
                    <b-icon :icon="data.detailsShowing ? 'chevron-up' : 'chevron-down'"></b-icon><!-- <br>{{ data.detailsShowing ? $t('general.hide') : $t('general.show') }} -->
                  </b-button>
                </div>
              </template>

              <template #row-details="data">
                <div
                  class="border-top mb-2"
                  style="margin-top: -12px;"
                ></div>
                <!-- transformation badges -->
                <FileInvoiceTransformationBadge
                  :fileInvoiceProp="data.item"
                  isReportProp
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// component
import PersonName from "@shared/views/Helpers/PersonName";
import FileInvoiceTransformationBadge from "@shared/views/Helpers/FileInvoiceTransformationBadge";
import DocumentStatuses from "@shared/views/Helpers/DocumentStatuses";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import viewComponents from "@shared/services/UI/viewComponents";
import invoicesHelpers from "@shared/services/UI/invoicesHelpers";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { FileInvoiceTransformationBadge, PersonName, DocumentStatuses },
  mixins: [userRights, error, viewComponents, invoicesHelpers],
  props: {
    invoiceReportIdProp: {
      type: Number,
      default: null
    },
    moduleModeProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "laboratory",
          "organisation",
          "reference",
          "endDate",
          "invoiceDate",
          "invoiced",
          "invoiceReportLines"
        ];
      }
    }
  },
  data() {
    return {
      // general
      invoiceReportId: this.invoiceReportIdProp,
      invoiceReportData: null,
      // base data
      laboratory: null,
      organisation: null,
      reference: "",
      endDate: "",
      invoiceDate: "",
      invoiced: null,
      fileInvoices: null,
      // table fields
      invoiceReportLinesFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort"),
          formatter: (_value, _key, item) => {
            return item.file.hospitalisationNumber;
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "file.creationDate",
          sortable: true,
          label: this.$t("files.creationDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "file.sector",
          sortable: true,
          label: this.$t("files.sector"),
          formatter: (value, _key, _item) => {
            if (value) {
              return this.$t("sectors." + value.name) + (!value.isSubscribed ? " (" + this.$t("general.deletedLabel") + ")" : "");
            } else {
              return "";
            }
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "collectionDates",
          sortable: true,
          label: this.$t("files.collectionDateShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "patient",
          sortable: true,
          label: this.$t("files.patient")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "pathologist",
          sortable: true,
          label: this.$t("files.pathologist")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "file.fileContacts",
          sortable: true,
          label: this.$t("files.prescriber"),
          formatter: (value, _key, _item) => {
            // value is already filtered with only prescriber
            // no value means that there is no prescriber
            return value.length === 1 ? value[0].contact.firstName + " " + value[0].contact.lastName : "?";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiceActs",
          sortable: true,
          label: this.$t("invoiceReports.invoiceActs")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiced",
          sortable: true,
          label: this.$t("invoiceReports.invoiced"),
          formatter: (_value, _key, item) => {
            let returnValue = 0;
            item.invoiceActs.forEach(function(act) {
              returnValue += act.invoiced;
            });
            return returnValue ? this.$n(returnValue, "currency") : this.$n(0, "currency");
          }
        },
        {
          tdClass: "kt-table__td p-0",
          thClass: "kt-table__th p-0",
          key: "transformed",
          sortable: false,
          label: ""
        }
      ],
      // dispayFields
      laboratoryDisplay: false,
      organisationDisplay: false,
      referenceDisplay: false,
      endDateDisplay: false,
      invoiceDateDisplay: false,
      invoicedDisplay: false,
      invoiceReportLinesDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // get invoiceReport
      const resServices = await commonServices.get("invoiceReports", this.invoiceReportId);
      this.invoiceReportData = this.importFormatInvoice(resServices.data, "invoiceReport");
      // assign the data
      this.laboratory = resServices.data.laboratory;
      this.organisation = resServices.data.organisation;
      this.reference = resServices.data.reference;
      this.endDate = resServices.data.endDate ? this.$d(new Date(resServices.data.endDate), "date") : "";
      this.invoiceDate = resServices.data.invoiceDate;
      this.invoiced = resServices.data.invoiced;
      this.fileInvoices = resServices.data.fileInvoices;
      // setup collection dates
      for (const fileInvoice of this.fileInvoices) {
        const collectionDatesArr = [];
        for (const containerGroup of fileInvoice.file.containerGroups) {
          collectionDatesArr.push(containerGroup.collectionDate);
        }
        fileInvoice.collectionDates = collectionDatesArr;
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("accounting/invoices/invoiceReports");
    },
    sortCompare(aRow, bRow, key, _sortDesc, _formatter, compareOptions, compareLocale) {
      let valA;
      let valB;
      let isNumber = false;
      if (key === "accessNumber") {
        valA = aRow.file.accessNumber;
        valB = bRow.file.accessNumber;
      } else if (key === "hospitalisationNumber") {
        valA = aRow.file.hospitalisationNumber;
        valB = bRow.file.hospitalisationNumber;
      } else if (key === "file.creationDate") {
        valA = aRow.file.creationDate;
        valB = bRow.file.creationDate;
        isNumber = true;
      } else if (key === "file.sector") {
        valA = aRow.file.sector.name;
        valB = bRow.file.sector.name;
      } else if (key === "collectionDates") {
        valA = aRow.collectionDates.length ? aRow.collectionDates[0] : "";
        valB = bRow.collectionDates.length ? bRow.collectionDates[0] : "";
        isNumber = true;
      } else if (key === "patient") {
        valA = aRow.file.patient.lastName;
        valB = bRow.file.patient.lastName;
      } else if (key === "pathologist") {
        valA = aRow.file.pathologist.user.lastName;
        valB = bRow.file.pathologist.user.lastName;
      } else if (key === "file.fileContacts") {
        valA = aRow.file.fileContacts[0].contact.lastName;
        valB = bRow.file.fileContacts[0].contact.lastName;
      } else if (key === "invoiceActs") {
        valA = aRow.invoiceActs.length ? aRow.invoiceActs[0].act.code : "";
        valB = bRow.invoiceActs.length ? bRow.invoiceActs[0].act.code : "";
      } else if (key === "invoiced") {
        valA = aRow.invoiceActs.length ? aRow.invoiceActs[0].invoiced : "";
        valB = bRow.invoiceActs.length ? bRow.invoiceActs[0].invoiced : "";
        isNumber = true;
      } else {
        valA = aRow[key];
        valB = bRow[key];
      }
      if (
        isNumber ||
        (typeof valA === "number" && typeof valB === "number") ||
        (valA instanceof Date && valB instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        if (valA < valB) {
          return -1;
        } else if (valA > valB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.toString(valA).localeCompare(this.toString(valB), compareLocale, compareOptions);
      }
    },
    toString(value) {
      if (value === null || typeof value === "undefined") {
        return "";
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(" ");
      } else {
        return String(value);
      }
    },
    // export
    async onExport(row, type) {
      try {
        const resFile = await commonServices.export("invoiceReports", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.reference + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./InvoiceReportView.scss" lang="scss"></style>

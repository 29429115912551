<template>
  <div
    class="kt-input-search-file"
    :class="'kt-input-search-file--' + $systemSettings.theme"
  >
    <b-form
      @submit.prevent="searchAccessNumber"
    >
      <b-form-group
        class="kt-input-search__input-container mb-0"
        :label="labelProp"
        :state="accessNumberError !== '' ? false : stateProp"
        :invalid-feedback="accessNumberError !== '' ? $t('inputSearchFile.validations.' + accessNumberError) : invalidFeedbackProp"
      >
        <!-- input -->
        <b-form-input
          v-show="file === null"
          ref="searchField"
          v-model="accessNumber"
          class="kt-input-search__input"
          autocomplete="off"
          :placeholder="placeholderProp"
          :state="accessNumberError !== '' ? false : stateProp"
          :disabled="disabledProp"
          @input="searchHasChanged = true; accessNumberError = ''; $emit('onUpdateValidation', false);"
        ></b-form-input>

        <b-icon
          v-show="file === null"
          class="kt-input-search__search-icon"
          icon="search"
        ></b-icon>

        <b-button
          v-show="file === null && accessNumber"
          class="kt-input-search__submit-icon"
          :variant="'outline-' + $systemSettings.theme"
          type="submit"
        >
          {{ $t("general.search") }}
        </b-button>
      </b-form-group>

      <!-- advanced search button (choose file modal) -->
      <b-button
        v-show="file === null"
        v-b-modal="'chooseFileModal'"
        class="kt-btn-transparent kt-input-search__advanced-search"
        :variant="$systemSettings.theme"
      >
        {{ $t("general.advancedSearch") }}
      </b-button>
    </b-form>

    <!-- clear file value -->
    <div
      v-if="file !== null"
      class="kt-badge kt-badge--light-gray kt-badge--lg kt-input-search-file__value-badge"
    >
      <b-icon
        icon="file-earmark"
        class="kt-badge__icon"
      ></b-icon>
      <span class="kt-badge__text">
        <!-- accessNumber -->
        <span>{{ file.accessNumber }}</span>
        <!-- complementary -->
        <span
          v-show="file && file.parentFileId"
          class="kt-complementary-icon"
          :class="{'kt-complementary-icon--ml-lg': file && !!file.isUrgent}"
        >
          <b-icon icon="back"></b-icon>
        </span>
        <!-- clear button -->
        <b-button
          ref="clearButton"
          :variant="'outline-' + $systemSettings.theme"
          class="kt-btn-transparent"
          @click="clearFileValue"
        >
          <b-icon icon="x"></b-icon>
        </b-button>
      </span>
    </div>

    <!-- choose file Modal -->
    <b-modal
      id="chooseFileModal"
      hide-footer
      :title="$t('general.advancedSearch')"
      size="xl"
      @shown="() => {
        $refs.fileChooseComponent.focusFirstElement()
      }"
    >
      <FileChoose
        ref="fileChooseComponent"
        :displayForAccountingProp="displayForAccountingProp"
        @chosen="onFileChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- choose from Ids Modal -->
    <b-modal
      id="fileChooseFromIdsModal"
      size="md"
      hide-header
      hide-footer
      @shown="() => {
        if (fileOptions && fileOptions.length > 0) {
          $refs.chooseFromIdsComponent.focusFirstElement()
        }
      }"
    >
      <FileChooseFromIds
        v-if="fileOptions && fileOptions.length > 0"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="fileOptions"
        @chosen="$event !== null ? validateAndSelectFile($event) : hideChooseFromIdsModal()"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import filesServices from "@/services/API/filesServices";
// modals
import FileChoose from "@/views/Office/Files/FileChoose";
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
// helpers
import error from "@shared/services/UI/error";

export default {
  components: { FileChoose, FileChooseFromIds },
  mixins: [error],
  props: {
    laboratoryIdProp: {
      type: Number,
      default: null
    },
    currentFileIdProp: {
      type: Number,
      default: null
    },
    fileProp: {
      type: Object,
      default: function() {
        return null;
      },
      deep: true
    },
    labelProp: {
      type: String,
      default: ""
    },
    placeholderProp: {
      type: String,
      default: ""
    },
    // validation and error
    validationTypeProp: {
      type: String,
      default: ""
    },
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    disabledProp: {
      type: Boolean
    },
    displayForAccountingProp: {
      type: Boolean
    }
  },
  data() {
    return {
      searchHasChanged: false,
      accessNumberError: "",
      accessNumber: "",
      file: null,
      fileId: null,
      fileOptions: []
    };
  },
  watch: {
    fileProp: function(val) {
      this.file = val;
      this.fileId = val ? val.id : null;
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    focusFirstElement() {
      if (this.file !== null) {
        this.$refs.clearButton.focus();
      } else {
        this.$refs.searchField.focus();
      }
    },

    clearFileValue() {
      this.accessNumber = "";
      this.searchHasChanged = false;
      this.accessNumberError = "";
      this.$emit("onUpdateParent", null);
    },
    async searchAccessNumber() {
      this.accessNumberError = "";
      const res = await filesServices.getAllByAccessNumber(this.accessNumber);
      if (res.data && res.data.length > 0) {
        if (res.data.length === 1) {
          this.validateAndSelectFile(res.data[0]);
        } else {
          this.fileOptions = res.data;
          this.$bvModal.show("fileChooseFromIdsModal");
        }
      } else {
        this.accessNumberError = "notFoundInLaboratory";
      }
    },
    async onFileChosen(event) {
      if (event !== null) {
        const resFile = await commonServices.get("files", event.id);
        if (resFile.data && resFile.data.id) {
          this.validateAndSelectFile(resFile.data);
        } else {
          this.hideChooseFromIdsModal();
        }
      } else {
        this.hideChooseFromIdsModal();
      }
    },
    validateAndSelectFile(file) {
      this.hideChooseFromIdsModal(false);
      this.$bvModal.hide("chooseFileModal");
      if (file) {
        // laboratory validation
        if (this.laboratoryIdProp && file.laboratoryId !== this.laboratoryIdProp) {
          this.accessNumberError = "notFoundInLaboratory";
          return false;
        }
        if (this.validationTypeProp === "careSheet" || this.validationTypeProp === "feeNote") {
          // filingStatus validation
          if (file.filingStatus.name !== "validatedForAccounting") {
            this.accessNumberError = "notValidatedBySecretary";
            return false;
          }
          // medicalStatus validation
          if (file.medicalStatus.name !== "validatedMedically") {
            this.accessNumberError = "notValidatedMedically";
            return false;
          }
          // cancelled validation
          if (file.filingStatus.name === "cancelled" || file.medicalStatus.name === "cancelled" || file.accountingStatus.name === "cancelled") {
            this.accessNumberError = "cancelledFile";
            return false;
          }
          // accountingStatus validation
          if (!this.currentFileIdProp || this.currentFileIdProp !== file.id) {
            if (file.accountingStatus.name === "invoiced" || file.accountingStatus.name === "paid") {
              this.accessNumberError = "alreadyInvoiced";
              return false;
            }
          }
        }
        // invoicedOrganisation validation
        if (this.validationTypeProp === "careSheet") {
          if (file.invoicedOrganisation === null || file.invoicedOrganisation.organisationType.name !== "healthCare") {
            this.accessNumberError = "invoicingTargetNotHealthcare";
            return false;
          }
        }
        if (this.validationTypeProp === "feeNote") {
          if (file.invoicedOrganisation !== null) {
            this.accessNumberError = "invoicingTargetNotPatient";
            return false;
          }
        }
        // success
        this.accessNumber = "";
        this.accessNumberError = "";
        this.$emit("onUpdateParent", file);
      } else {
        this.accessNumberError = "notFoundInLaboratory";
      }
    },
    hideChooseFromIdsModal(clearAccessNumber = true) {
      this.$bvModal.hide("fileChooseFromIdsModal");
      this.fileOptions = [];
      if (clearAccessNumber) this.accessNumber = "";
      this.accessNumberError = "";
    }
  }
};
</script>

<style scoped src="./InputSearchFile.scss" lang="scss"></style>

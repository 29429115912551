<template>
  <div class="kt-choose-sample">
    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="sampleFields"
      :items="samples"
      :busy="loading"
      select-mode="single"
      selectable
      @row-selected="onSampleSelected"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Code -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterCode"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.tab.exact="(e) => {
                if (filterCode === '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterCode !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCode')"
              @keydown.tab.exact="(e) => {
                if (filterCode !== '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Name -->
          <b-th class="kt-table__th-search">
            <b-form-input
              ref="nameInput"
              v-model="filterName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
            ></b-form-input>
            <b-button
              v-show="filterName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <div
      v-show="!samplesCount && !loading && isSearchActive"
      class="text-center mt-3"
    >
      <h4>
        {{ $t("sampleChoose.notFound") }}
      </h4>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    laboratoryIdProp: {
      type: [Number, null],
      default: null
    },
    sectorIdProp: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {
      // general
      loading: false,
      samplesCount: 0,
      // table fields
      sampleFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "code",
          sortable: false,
          label: this.$t("samples.code")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "name",
          sortable: false,
          label: this.$t("samples.name")
        }
      ],
      // table filters
      filterName: "",
      filterCode: "",
      // table items
      samples: []
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterName ||
        this.filterCode
      );
    }
  },
  methods: {
    focusFirstElement() {
      this.$refs.nameInput.$el.focus();
    },
    focusFirstLine() {
      if (this.$refs.table.$children[1].$children.length) {
        this.$refs.table.$children[1].$children[0].$el.focus();
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchAndUpdateTable();
    },
    // update the table
    async searchAndUpdateTable() {
      try {
        // if no filter
        if (this.isSearchActive === false) {
          this.samples = [];
          return false;
        }

        // get the table items and update
        this.loading = true;

        const filters = {
          laboratoryId: this.laboratoryIdProp,
          sectorId: this.sectorIdProp,
          name: this.filterName,
          code: this.filterCode
        };
        const res = await commonServices.getAll(
          "samples",
          filters
        );
        this.samples = res.data;
        this.samplesCount = res.data.length;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchAndUpdateTable();
      }, 450);
    },
    // on select
    async onSampleSelected(rows) {
      if (rows && rows.length === 1) {
        this.loading = true;
        const res = await commonServices.get("samples", rows[0].id);
        this.$emit("chosen", res.data || null);
        this.loading = false;
      }
    }
  }
};
</script>

<template>
  <div
    class="kt-file-edit"
    :class="{'pb-4': !moduleModeProp}"
  >
    <!-- page header -->
    <div
      class="kt-page-header mb-3"
      :class="!moduleModeProp ? 'mt-4 pt-1' : ''"
    >
      <!-- title -->
      <div v-show="!moduleModeProp">
        <!-- back button -->
        <b-button
          size="sm"
          class="kt-page-header__back-btn btn-icon mr-3"
          variant="light"
          @click="onGoBack"
        >
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
        <h2 class="kt-page-header__title h4 mb-0">
          {{ pageTitleStringFormatted }}
        </h2>
        <!-- view button -->
        <b-button
          v-if="editMode"
          size="sm"
          class="kt-page-header__action"
          :variant="$systemSettings.theme"
          @click="navigate('office/file/view', {fileIdProp: fileId})"
        >
          <b-icon icon="eye"></b-icon>
          {{ $t("general.view") }}
        </b-button>
      </div>
      <!-- subtitle -->
      <div class="kt-page-header__subtitle">
        <!-- accessNumber & isUrgent -->
        <div>
          <!-- accessNumber -->
          <span v-show="accessNumber">{{ $t("files.subtitleAccessNumber", {accessNumber: accessNumber}) }}</span>
          <!-- complementary icon -->
          <span
            v-show="parentFileId"
            class="kt-complementary-icon"
          >
            <b-icon icon="back"></b-icon>
          </span>
          <!-- isUrgent switch -->
          <span class="kt-badge kt-badge--red ml-1">
            <b-form-checkbox
              ref="isUrgentInput"
              v-model="isUrgent"
              switch
              class="kt-badge__switcher"
              @change="onIsUrgentChange"
            ></b-form-checkbox>
            {{ $t("files.urgentBadgeText") }}
          </span>
          <b-spinner
            v-show="isUrgentLoading"
            class="ml-1"
            :class="'kt-spinner--' + $systemSettings.theme"
            small
          ></b-spinner>
          <b-icon
            v-show="isUrgentSuccessIconDisplay"
            class="ml-1"
            icon="check-circle-fill"
            variant="success"
          ></b-icon>
        </div>
        <!-- creationDate -->
        <div v-show="editMode">
          {{ $t("fileView.created", {date: (creationDate ? $d(new Date(creationDate), "date") : "-")} ) }}
        </div>
      </div>
      <!-- page header - bottom -->
      <div class="kt-page-header__bottom mt-1">
        <!-- status history -->
        <span
          v-if="filingStatus || medicalStatus || accountingStatus"
          style="white-space: nowrap;"
        >
          <StatusBadge
            class="mr-1"
            typeProp="filingStatus"
            :statusNameProp="filingStatus"
            isDisplayedStatusTitleProp
          />
          <StatusBadge
            class="mr-1"
            typeProp="medicalStatus"
            :statusNameProp="medicalStatus"
            isDisplayedStatusTitleProp
          />
          <StatusBadge
            typeProp="accountingStatus"
            :statusNameProp="accountingStatus"
            isDisplayedStatusTitleProp
          />
        </span>
        <!-- history button -->
        <b-button
          v-show="filingStatus || medicalStatus || accountingStatus"
          v-b-modal="'fileStatusesHistoryModal-' + fileId"
          variant="outline-secondary"
          size="sm"
          class="ml-2"
        >
          {{ $t('files.historyButton') }}
        </b-button>
        <!-- archives button -->
        <b-button
          v-show="editMode"
          v-b-modal="'archivesModal-' + _uid"
          variant="outline-secondary"
          size="sm"
          :class="editMode ? 'ml-2' : ''"
        >
          {{ $t('files.archives') }}
          <span
            class="kt-count-bubble"
            :class="archives.length ? 'kt-count-bubble--danger' : ''"
          ><span class="kt-count-bubble__number">{{ archives.length }}</span></span>
          <b-icon
            v-show="hasMissingArchives"
            icon="exclamation-triangle-fill"
            style="color: rgba(199, 51, 51, 0.9) !important; margin-left: 4px; font-size: 16px;"
          />
        </b-button>
        <!-- comments button -->
        <b-button
          v-b-modal="'fileCommentsModal-' + fileId"
          variant="outline-secondary"
          size="sm"
          :class="!editMode && parentFileId ? '' : 'ml-2'"
        >
          {{ $t('files.commentsButton') }}
          <span
            class="kt-count-bubble"
            :class="commentsCount ? 'kt-count-bubble--danger' : ''"
          ><span class="kt-count-bubble__number">{{ commentsCount }}</span></span>
        </b-button>
        <!-- group of files button -->
        <b-button
          v-show="editMode"
          v-b-modal="'complementaryFilesModal-' + fileId"
          variant="outline-secondary"
          size="sm"
          class="ml-2"
        >
          {{ $t('files.groupOfFilesButton') }}
          <span
            class="kt-count-bubble"
            :class="groupOfFiles && groupOfFiles.length > 1 ? 'kt-count-bubble--danger' : ''"
          ><span class="kt-count-bubble__number">{{ groupOfFiles ? groupOfFiles.length : 0 }}</span></span>
        </b-button>
        <!-- option drop-down -->
        <div
          v-show="(medicalStatus === 'validatedMedically' || isReadyMedicalReport) && (isEnabled(['roleSpecific', 'office']) || isEnabled(['roleSpecific', 'diagnosis']))"
          class="kt-dropdown-btn kt-dropdown-btn--no-arrow ml-2"
        >
          <b-button
            variant="outline-secondary"
            size="sm"
            class="btn-icon kt-dropdown-btn__switcher-btn"
          >
            <b-icon icon="three-dots-vertical"></b-icon>
          </b-button>
          <ul class="kt-dropdown-btn__list">
            <li class="kt-dropdown-btn__shadow"></li>

            <!-- download medical report -->
            <li
              v-show="(medicalStatus === 'validatedMedically' || isReadyMedicalReport)"
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button"
                @click="downloadMedicalReport"
              >
                <b-icon
                  icon="download"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("files.downloadMedicalReport") }}
              </button>
            </li>
          </ul>
        </div>
        <b-spinner
          v-show="isDownloading"
          class="ml-1"
          :class="'kt-spinner--' + $systemSettings.theme"
          small
        ></b-spinner>
      </div>
      <!-- validated warnings -->
      <div
        v-show="(filingStatus === 'validatedForDiagnosis' || filingStatus === 'validatedForAccounting')
          && filingStatus !== 'cancelled' && medicalStatus !== 'cancelled' && accountingStatus !== 'cancelled'"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="exclamation-triangle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{
          isInvoiced ? $t("fileEdit.validatedWarningInvoiced") :
          medicalStatus === 'validatedMedically' ? $t("fileEdit.validatedWarningMedically") :
          filingStatus === 'validatedForDiagnosis' ? $t("fileEdit.validatedWarningDiagnosis") :
          filingStatus === 'validatedForAccounting' ? $t("fileEdit.validatedWarningAccounting") :
          "" }}</span>
        <!-- options drop-down -->
        <div
          v-if="(filingStatus === 'validatedForAccounting' && medicalStatus !== 'validatedMedically' && !isInvoiced)
            || (medicalStatus !== 'validatedMedically' && !isInvoiced)"
          class="kt-dropdown-btn kt-dropdown-btn--danger kt-dropdown-btn--no-arrow ml-2"
        >
          <b-button
            variant="outline-secondary"
            size="sm"
            class="btn-icon kt-dropdown-btn__switcher-btn"
            pill
          >
            <b-icon icon="three-dots-vertical"></b-icon>
          </b-button>
          <ul
            class="kt-dropdown-btn__list"
          >
            <li class="kt-dropdown-btn__shadow"></li>

            <!-- invalidate accounting only -->
            <li
              v-show="filingStatus === 'validatedForAccounting' && medicalStatus !== 'validatedMedically' && !isInvoiced"
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button"
                @click="invalidate('validatedForDiagnosis')"
              >
                <b-icon
                  icon="arrow-counterclockwise"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("fileEdit.invalidateIntoDiagnosisButton") }}
              </button>
            </li>
            <!-- invalidate all -->
            <li
              v-show="medicalStatus !== 'validatedMedically' && !isInvoiced"
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button"
                @click="invalidate('created')"
              >
                <b-icon
                  icon="arrow-counterclockwise"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("fileEdit.invalidateButton") }}
              </button>
            </li>
            <!-- cancel file
            <li
              class="kt-dropdown-btn__item"
            >
              <button
                class="kt-dropdown-btn__button text-danger"
                @click="cancelFile"
              >
                <b-icon
                  icon="trash"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("fileEdit.cancelFileButton") }}
              </button>
            </li> -->

            <!-- <li class="kt-dropdown-btn__divider"></li> -->
          </ul>
        </div>
      </div>

      <!-- cancelled warnings -->
      <div
        v-show="filingStatus === 'cancelled' || medicalStatus === 'cancelled' || accountingStatus === 'cancelled'"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="exclamation-triangle-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text font-weight-semi-bold">{{ $t("fileEdit.warningFileCancelled") }}</span>
        <!-- dropdown
        <div class="kt-dropdown-btn kt-dropdown-btn--danger kt-dropdown-btn--no-arrow ml-2">
          <b-button
            variant="outline-secondary"
            size="sm"
            class="btn-icon kt-dropdown-btn__switcher-btn"
            pill
          >
            <b-icon icon="three-dots-vertical"></b-icon>
          </b-button>
          <ul
            class="kt-dropdown-btn__list"
          >
            <li class="kt-dropdown-btn__shadow"></li>

            uncancel file
            <li class="kt-dropdown-btn__item">
              <button
                class="kt-dropdown-btn__button"
                @click="uncancelFile"
              >
                <b-icon
                  icon="arrow-counterclockwise"
                  class="kt-dropdown-btn__icon"
                ></b-icon>
                {{ $t("fileEdit.uncancelFileButton") }}
              </button>
            </li>
          </ul>
        </div> -->
      </div>

      <!-- message sent warnings -->
      <div
        v-show="sentMessageWarningText !== ''"
        class="kt-validated-warning kt-badge kt-badge--red kt-badge--lg mt-1"
      >
        <b-icon
          icon="chat-left-fill"
          class="kt-badge__icon"
        ></b-icon>
        <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ sentMessageWarningText }}</span>
          <br />{{ lastSentMessage ? (lastSentMessage.email || "") + (lastSentMessage.phone && lastSentMessage.email ? ", " : "") + (importFormatPhone(lastSentMessage.phone) || "") : "" }}
          <br /><span class="font-weight-semi-bold">{{ $t("files.notifications.errorInRequestInvalidFields") }}</span>
          <br />{{ originalRequestErrorLocalised }}</span>
      </div>
    </div>

    <!-- divider -->
    <div
      v-show="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- PARENT FILE  -->
    <!-- parent view (button) -->
    <b-button
      v-show="parentFileId && !isDiagnosisUser"
      ref="viewParentFileButton"
      v-b-modal="'parentFileViewModal-' + fileId"
      :variant="$systemSettings.theme"
      class="mb-2"
    >
      {{ $t('files.parentFileViewButton') }}
    </b-button>
    <!-- parent validation errors -->
    <div
      v-show="parentFileId && parentFileErrors.length"
      class="kt-input-error-box pb-3 mb-2"
    >
      <p class="mb-0">
        {{ $t("fileEdit.validations.parentValidationErrors") }}
      </p>
      <ul class="mb-0">
        <li
          v-for="error in parentFileErrors"
          :key="error"
        >
          {{ error }}
        </li>
      </ul>
    </div>

    <!-- FILE VIEW (for read only) -->
    <div
      v-if="isActiveAccountingMode || isActiveReadOnly"
      class="kt-view-page"
    >
      <div class="kt-view-page__body">
        <!-- Diagnosis information -->
        <h5
          v-show="isActiveAccountingMode"
          style="margin-bottom: 10px;"
        >
          {{ $t("files.accountingModeDiagnosisInfoTitle") }}
        </h5>

        <!-- SECTION - laboratory - sector - pathologist -->
        <div class="kt-view-page__section">
          <!-- laboratory -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.laboratory") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ laboratoryName }}
              </p>
            </b-col>
          </b-row>
          <!-- sector -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.sector") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ sectorLocalisedName }}
              </p>
            </b-col>
          </b-row>
          <!-- pathologist -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.pathologist") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ pathologist ? (pathologist.user.firstName + " " + pathologist.user.lastName + " (" + pathologist.code + ")" + (pathologist.user.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")")) : $t("general.empty") }}
              </p>
            </b-col>
          </b-row>
        </div>

        <!-- divider -->
        <div
          v-show="isActiveReadOnly || !isActiveAccountingMode"
          class="w-100 border-top mt-1 mb-3"
        ></div>

        <!-- SECTION - patient -->
        <div
          v-show="isActiveReadOnly || !isActiveAccountingMode"
          class="kt-view-page__section"
        >
          <!-- patient -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.patient") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <div
                v-if="patientId"
                class="kt-frame mb-3"
              >
                <PatientView
                  :patientIdProp="patientId"
                  :referenceCreationDateProp="referenceCreationDate"
                  moduleModeProp
                  @alert="(variant, strings) => $emit('alert', variant, strings)"
                />
              </div>
              <!-- forceHealthCare -->
              <div v-show="forceHealthCare">
                <!-- checkbox-display-only -->
                <div
                  class="kt-checkbox-display-only-cont"
                  style="margin-bottom: 1px;"
                >
                  <span
                    class="kt-checkbox-display-only"
                    :class="('kt-checkbox-display-only--' + $systemSettings.theme) + ' kt-checkbox-display-only--checked'"
                  >
                    <span class="kt-checkbox-display-only__cross"></span>
                  </span>
                  <span class="kt-checkbox-display-only__text">{{ $t("files.forceHealthCare") }}</span>
                </div>
                <!-- forceHealthCareOptions -->
                <div class="mb-3">
                  <span class="font-weight-semi-bold">
                    {{ $t('files.forceHealthCareType') }}
                  </span>
                  <span>
                    {{ $t('files.forceHealthCareTypes.' + forceHealthCareOptions.type) }}
                  </span>
                  <span
                    v-show="forceHealthCareOptions.type === 'accident'"
                    class="font-weight-semi-bold ml-2"
                  >
                    {{ $t('files.forceHealthCareReference') }}
                  </span>
                  <span v-show="forceHealthCareOptions.type === 'accident'">
                    {{ forceHealthCareOptions.reference || $t("general.emptyWithHyphen") }}
                  </span>
                  <span
                    v-show="forceHealthCareOptions.type === 'accident' || forceHealthCareOptions.type === 'pregnancy'"
                    class="font-weight-semi-bold ml-2"
                  >
                    {{ $t('files.forceHealthCareDate') }}
                  </span>
                  <span v-show="forceHealthCareOptions.type === 'accident' || forceHealthCareOptions.type === 'pregnancy'">
                    {{ forceHealthCareOptions.date ? $d(new Date(forceHealthCareOptions.date), "date") : $t("general.emptyWithHyphen") }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- divider -->
        <div
          v-show="isActiveReadOnly || !isActiveAccountingMode"
          class="w-100 border-top mt-1 mb-3"
        ></div>

        <!-- SECTION - organisation - file-contacts -->
        <div
          v-show="isActiveReadOnly || !isActiveAccountingMode"
          class="kt-view-page__section"
        >
          <!-- prescribingOrganisation -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.prescribingOrganisation") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <div
                v-show="prescribingOrganisation"
                class="kt-item-list"
              >
                <div class="kt-item-list__item-wrapper">
                  <div class="kt-item-list__item">
                    <!-- name -->
                    <div class="kt-item-list__item-title">
                      {{ prescribingOrganisation ? prescribingOrganisation.name + (prescribingOrganisation.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") : $t("general.empty") }}
                    </div>
                    <!-- code -->
                    <div class="kt-item-list__item-text">
                      {{ prescribingOrganisation ? prescribingOrganisation.code : $t("general.empty") }}
                    </div>
                    <!-- invoice patient -->
                    <div class="mb-2 mt-3">
                      <p class="mb-0 font-weight-semi-bold">
                        {{ $t("files.invoicePatient") }}
                      </p>
                      <p class="mb-0">
                        {{ forceInvoicePatient !== null ? (forceInvoicePatient ? $t('organisations.invoicePatientTrue') : $t('organisations.invoicePatientFalse')) : $t("general.empty") }}
                      </p>
                    </div>
                    <!-- hospitalisationNumber -->
                    <div>
                      <p class="mb-0 font-weight-semi-bold">
                        {{ $t("files.hospitalisationNumber") }}
                      </p>
                      <p class="mb-0">
                        {{ hospitalisationNumber }}
                      </p>
                    </div>
                    <!-- IPP -->
                    <div v-show="ipp">
                      <p
                        class="mb-0 mt-2 font-weight-semi-bold"
                        style="letter-spacing: 0.5px;"
                      >
                        {{ $t("files.ipp") }}
                      </p>
                      <p class="mb-0">
                        {{ ipp }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- prescriber -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.prescriber") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <!-- prescriber -->
              <div
                v-show="prescriber"
                class="kt-item-list"
              >
                <div class="kt-item-list__item-wrapper">
                  <div class="kt-item-list__item">
                    <!-- name -->
                    <div
                      class="kt-item-list__item-title"
                    >
                      {{ (prescriber ? prescriber.firstName + " " + prescriber.lastName : "") +
                        (prescriber && prescriber.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") }}
                    </div>
                    <!-- code -->
                    <div class="kt-item-list__item-text">
                      {{ prescriber ? prescriber.code : "" }}
                    </div>
                    <!-- contactInformation -->
                    <div
                      v-show="contactInformation"
                      class="mb-2 mt-3"
                    >
                      <p class="mb-0 font-weight-semi-bold">
                        {{ contactInformation ? contactInformation.name : $t("general.empty") }}
                      </p>
                      <p class="mb-0">
                        {{ contactInformation ? formatAddress(contactInformation.address1, contactInformation.address2, contactInformation.zip, contactInformation.city, contactInformation.country) : $t("general.empty") }}
                      </p>
                    </div>
                    <!-- is deleted contactInformation -->
                    <div
                      v-show="contactInformation && contactInformation.isActive === false"
                      class="kt-item-list__item-text text-danger mb-2 mt-1"
                    >
                      {{ $t("contacts.notifications.deletedOrganisationContactInformation") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- fileContacts -->
          <b-row v-show="fileContacts.length">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.otherRecipients") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <!-- contacts list -->
              <div
                v-if="fileContacts.length"
                class="kt-item-list"
              >
                <div
                  v-for="fileContactItem in fileContacts"
                  :key="fileContactItem.key"
                  class="kt-item-list__item-wrapper"
                >
                  <div class="kt-item-list__item">
                    <!-- name -->
                    <div
                      class="kt-item-list__item-title"
                    >
                      {{ fileContactItem.contact.firstName + " " + fileContactItem.contact.lastName + (fileContactItem.contact.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") }}
                    </div>
                    <!-- code -->
                    <div class="kt-item-list__item-text">
                      {{ fileContactItem.contact.code }}
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- divider -->
        <div
          v-show="isActiveReadOnly || !isActiveAccountingMode"
          class="w-100 border-top mt-1 mb-3"
        ></div>

        <!-- SECTION - invoicedOrganisation -->
        <div
          v-show="isActiveReadOnly || !isActiveAccountingMode"
          class="kt-view-page__section"
        >
          <!-- invoicedOrganisation -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t("files.invoicedOrganisation") }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ invoicedOrganisationName }}
              </p>
            </b-col>
          </b-row>
        </div>

        <!-- divider -->
        <div
          class="w-100 border-top mt-2 mb-3"
        ></div>

        <!-- SECTION - sample -->
        <div
          class="kt-view-page__section"
        >
          <!-- additionalFeeGroup -->
          <b-row v-show="isActiveReadOnly">
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t('files.additionalFeeGroup') }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              {{ additionalFeeGroupReadOnlyName }}
            </b-col>
          </b-row>
          <!-- containerGroups -->
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h3 class="h6">
                {{ $t('files.containerGroups') }}
              </h3>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <ContainerGroups
                :viewModeProp="true"
                :hideLabelProp="true"
                :containerGroupsProp="containerGroups"
                @alert="(variant, strings) => $emit('alert', variant, strings)"
              />
            </b-col>
          </b-row>
        </div>

        <!-- divider between view / edit -->
        <div
          v-show="isActiveAccountingMode && !isDiagnosisUser"
          class="w-100 border-top mt-1 mb-4"
        ></div>
        <!-- Accounting information -->
        <h5 v-show="isActiveAccountingMode && !isDiagnosisUser">
          {{ $t("files.accountingModeTitle") }}
        </h5>
        <p v-show="isActiveAccountingMode && parentFileId && !isDiagnosisUser">
          {{ $t("files.accountingModeComplementarySubTitleStart") }}
          <b-link
            :variant="$systemSettings.theme"
            @click="navigate('office/file/edit', {editModeProp: true, fileIdProp: parentFileId})"
          >
            {{ $t("files.initialFileLink") }}
          </b-link>
          {{ $t("files.accountingModeComplementarySubTitleEnd") }}
        </p>
      </div>
    </div>

    <!-- FILE FORM -->
    <b-form
      v-show="!isActiveReadOnly"
      @submit.prevent="onValidateFile"
    >
      <!-- laboratory -->
      <label v-show="laboratoryIsDisplayed && !isActiveAccountingMode && !parentFileId">
        <span>{{ $t('files.laboratory') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.laboratoryId"
          :valueProp="laboratoryId"
          @unsaveValue="$userSettings.savedBehaviors.office.file.laboratoryId = null"
          @saveValue="$userSettings.savedBehaviors.office.file.laboratoryId = $event"
          @importValue="laboratoryId = $event"
        />
      </label>
      <SmartSelectInput
        v-if="!isActiveReadOnly && $systemSettings.laboratories.length && !isActiveAccountingMode && !parentFileId"
        labelProp=""
        :optionsProp="$systemSettings.laboratories"
        valueFieldProp="id"
        textFieldProp="name"

        :valueProp="laboratoryId"
        :stateProp="laboratoryIdState"
        :invalidFeedbackProp="laboratoryIdInvalidFeedback"

        :disabledProp="false"
        :showDisabledItemsProp="false"
        :showInactiveItemsProp="false"
        :displayUniqueProp="false"
        :selectFirstOnloadProp="$userSettings.savedBehaviors.office.file.laboratoryId === null"
        :initialValueProp="originalData !== null ? originalData.laboratoryId : null"
        @updateIsDisplayed="laboratoryIsDisplayed = $event"
        @input="laboratoryId = $event"
      />

      <!-- sectors -->
      <label v-show="sectorIsDisplayed && !isActiveAccountingMode && !parentFileId">
        <span>{{ $t('files.sector') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.sectorId"
          :valueProp="sectorId"
          @unsaveValue="$userSettings.savedBehaviors.office.file.sectorId = null"
          @saveValue="$userSettings.savedBehaviors.office.file.sectorId = $event"
          @importValue="onSectorChange($event)"
        />
      </label>
      <SmartSelectInput
        v-if="!isActiveReadOnly && sectorsOptions.length && !isActiveAccountingMode && !parentFileId"
        labelProp=""
        :optionsProp="sectorsOptions"
        valueFieldProp="id"
        textFieldProp="localisedName"

        :valueProp="sectorId"
        :stateProp="sectorIdState"
        :invalidFeedbackProp="sectorIdInvalidFeedback"

        :disabledProp="false"
        :showDisabledItemsProp="false"
        :showInactiveItemsProp="false"
        :displayUniqueProp="false"
        :selectFirstOnloadProp="$userSettings.savedBehaviors.office.file.sectorId === null"
        :initialValueProp="originalData !== null ? originalData.sectorId : null"
        @updateIsDisplayed="sectorIsDisplayed = $event"
        @input="onSectorChange"
      />

      <!-- divider -->
      <div
        v-show="displayedFirstDivider"
        class="w-100 border-top mt-1 mb-2"
      ></div>

      <!-- patient -->
      <b-form-group
        v-show="!parentFileId"
        :label="$t('files.patient')"
        :invalid-feedback="patientInvalidFeedback"
        :state="patientState"
      >
        <!-- patient validation -->
        <div
          v-show="patientFieldsRequiredErrors.length || phoneAndEmailOrAddressRequiredError || partialAddressError || patientNameEditionError"
          class="kt-input-error-box"
        >
          <div
            v-show="patientNameEditionError"
            class="mb-2"
          >
            {{ $t('fileEdit.validations.patientErrors.patientNameEdition') }}
            <br>{{ $t('fileEdit.validations.patientErrors.patientNameEditionSub') }}
            <strong><PersonName
              :sexNameProp="originalData ? originalData.patient.sex.name : ''"
              :firstNameProp="originalData ? originalData.patient.firstName : ''"
              :lastNameProp="originalData ? originalData.patient.lastName : ''"
            />{{ originalData ? ", " + $d(new Date(originalData.patient.birthdate), "date") : $t("general.emptyString") }}</strong>
          </div>
          <div
            v-show="patientFieldsRequiredErrors.length"
            class="mb-2"
          >
            <div>{{ $t('fileEdit.validations.patientErrors.fieldsRequiredTitle') }}</div>
            <ul class="mb-0">
              <li
                v-for="patientField in patientFieldsRequiredErrors"
                :key="patientField"
              >
                {{ $t('patients.' + patientField) }}
              </li>
            </ul>
          </div>
          <div
            v-show="phoneAndEmailOrAddressRequiredError"
            class="mb-2"
          >
            {{ $t('fileEdit.validations.patientErrors.phoneAndEmailOrAddressRequired') }}
          </div>
          <div
            v-show="partialAddressError"
            class="mb-2"
          >
            {{ $t('fileEdit.validations.patientErrors.partialAddress') }}
          </div>
        </div>
        <!-- patient -->
        <div
          v-if="patientId !== null"
          class="kt-frame"
        >
          <!-- view -->
          <PatientView
            :patientIdProp="patientId"
            :referenceCreationDateProp="referenceCreationDate"
            moduleModeProp
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
          <!-- actions -->
          <div class="kt-frame__actions-container">
            <!-- edit -->
            <b-button
              ref="patientEditButton"
              class="kt-frame__btn"
              :variant="'outline-' + $systemSettings.theme"
              size="sm"
              squared
              @click="onEditPatient"
            >
              {{ $t('general.edit') }}
            </b-button>
            <!-- delete -->
            <b-button
              v-show="filingStatus !== 'validatedForDiagnosis'"
              class="kt-frame__btn kt-frame__btn--delete btn-icon"
              variant="light"
              size="sm"
              squared
              @click="onDeletePatient"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>
        </div>

        <!-- add patient button -->
        <b-button
          v-show="patientId === null"
          ref="patientChooseButton"
          :variant="'outline-' + $systemSettings.theme"
          @click="onChoosePatient"
        >
          {{ $t("fileEdit.choose") }}
        </b-button>
      </b-form-group>

      <!-- forceHealthCare -->
      <b-row v-show="!parentFileId">
        <b-col
          cols="12"
          md="7"
        >
          <b-form-group
            :invalid-feedback="forceHealthCareInvalidFeedback"
            :state="forceHealthCareState"
            class="mb-2"
          >
            <b-form-checkbox
              v-model="forceHealthCare"
              class=""
              @change="() => {
                forceHealthCareValidation = true;
                updateInvoicedOrganisation();
              }"
            >
              {{ $t("files.forceHealthCare") }}
            </b-form-checkbox>
          </b-form-group>
          <!-- forceHealthCareOptions -->
          <b-row
            v-if="forceHealthCareOptions"
            v-show="forceHealthCare"
          >
            <!-- type -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('files.forceHealthCareType')"
              >
                <b-form-select
                  v-model="forceHealthCareOptions.type"
                  :options="[
                    {name: 'screeningCampaign', localisedName: $t('files.forceHealthCareTypes.screeningCampaign')},
                    {name: 'pregnancy', localisedName: $t('files.forceHealthCareTypes.pregnancy')},
                    {name: 'accident', localisedName: $t('files.forceHealthCareTypes.accident')}
                  ]"
                  value-field="name"
                  text-field="localisedName"
                />
              </b-form-group>
            </b-col>
            <!-- reference -->
            <b-col
              v-show="forceHealthCareOptions.type === 'accident'"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('files.forceHealthCareReference')"
              >
                <b-form-input
                  v-model="forceHealthCareOptions.reference"
                  maxlength="9"
                  trim
                />
              </b-form-group>
            </b-col>
            <!-- date -->
            <b-col
              v-show="forceHealthCareOptions.type === 'accident' || forceHealthCareOptions.type === 'pregnancy'"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('files.forceHealthCareDate')"
              >
                <b-form-input
                  v-model="forceHealthCareOptions.date"
                  type="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- divider -->
      <div
        v-show="!parentFileId"
        class="w-100 border-top mt-1 mb-2"
      ></div>

      <!-- prescribingOrganisation -->
      <label v-show="!parentFileId">
        <span>{{ $t('files.prescribingOrganisation') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.prescribingOrganisation"
          :valueProp="prescribingOrganisation"
          @unsaveValue="$userSettings.savedBehaviors.office.file.prescribingOrganisation = null"
          @saveValue="$userSettings.savedBehaviors.office.file.prescribingOrganisation = $event"
          @importValue="onOrganisationChosen($event)"
        />
      </label>
      <b-form-group
        v-show="!parentFileId"
        :style="prescribingOrganisation ? 'margin-bottom: 6px;' : ''"
        :invalid-feedback="prescribingOrganisationInvalidFeedback"
        :state="prescribingOrganisationState"
      >
        <!-- prescribingOrganisation list -->
        <div class="kt-item-list">
          <div
            v-if="prescribingOrganisation"
            class="kt-item-list__item-wrapper"
          >
            <div class="kt-item-list__item">
              <!-- actions -->
              <div class="kt-item-list__item-actions">
                <!-- delete organisation Button -->
                <b-button
                  class="btn-icon kt-item-list__item-delete"
                  size="sm"
                  variant="light"
                  squared
                  @click="onDeletePrescribingOrganisation"
                >
                  <b-icon icon="x"></b-icon>
                </b-button>
              </div>

              <!-- name -->
              <div class="kt-item-list__item-title">
                {{ prescribingOrganisation ? prescribingOrganisation.name + (prescribingOrganisation.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") : "" }}
              </div>
              <!-- code -->
              <div class="kt-item-list__item-text">
                {{ prescribingOrganisation.code || '' }}
              </div>
              <!-- forceInvoicePatient -->
              <b-form-group
                class="mb-2 mt-3"
                :label="$t('files.invoicePatient')"
                :invalid-feedback="forceInvoicePatientInvalidFeedback"
                :state="forceInvoicePatientState"
              >
                <b-form-radio-group
                  ref="forceInvoicePatientRadio"
                  v-model="forceInvoicePatient"
                  :options="[
                    {value: true, text: $t('organisations.invoicePatientTrue')},
                    {value: false, text: $t('organisations.invoicePatientFalse')}
                  ]"
                  :state="forceInvoicePatientState"
                  @input="updateInvoicedOrganisation"
                />
              </b-form-group>
              <!-- hospitalisation number -->
              <b-form-group
                class="mb-2"
                :label="$t('files.hospitalisationNumber')"
                :invalid-feedback="hospitalisationNumberInvalidFeedback"
                :state="hospitalisationNumberState"
              >
                <b-form-input
                  v-model="hospitalisationNumber"
                  trim
                  maxlength="50"
                  :state="hospitalisationNumberState"
                  @blur="hospitalisationNumberValidation = true"
                ></b-form-input>
              </b-form-group>
              <!-- ipp -->
              <b-form-group
                class="mb-2"
                :label="$t('files.ipp')"
                :invalid-feedback="ippInvalidFeedback"
                :state="ippState"
              >
                <b-form-input
                  v-model="ipp"
                  trim
                  maxlength="50"
                  :state="ippState"
                  @blur="ippValidation = true"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <!-- choose organisation Button -->
        <b-button
          v-show="prescribingOrganisation === null"
          ref="chooseOrganisationButton"
          v-b-modal="'chooseOrganisationModal'"
          :variant="'outline-' + $systemSettings.theme"
        >
          {{ $t("fileEdit.choose") }}
        </b-button>
      </b-form-group>

      <!-- prescriber -->
      <label v-show="!parentFileId">
        <span>{{ $t('files.prescriber') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.prescriber"
          :valueProp="prescriber"
          @unsaveValue="$userSettings.savedBehaviors.office.file.prescriber = null"
          @saveValue="$userSettings.savedBehaviors.office.file.prescriber = $event"
          @importValue="($event) => {
            if ($event) onPrescriberChosen($event);

          }"
        />
      </label>
      <b-form-group
        v-show="!parentFileId"
        :invalid-feedback="prescriberInvalidFeedback"
        :state="prescriberState"
      >
        <!-- prescriber -->
        <div class="kt-item-list">
          <div
            v-show="prescriber"
            class="kt-item-list__item-wrapper"
          >
            <div class="kt-item-list__item mb-0">
              <!-- actions -->
              <div class="kt-item-list__item-actions">
                <!-- delete button -->
                <b-button
                  v-if="isEnabled(['menu', 'office', 'files', 'fileContacts', 'delete'])"
                  ref="prescriberDeleteButton"
                  class="btn-icon kt-item-list__item-delete"
                  size="sm"
                  variant="light"
                  squared
                  @click="onDeletePrescriber"
                >
                  <b-icon icon="x"></b-icon>
                </b-button>
              </div>

              <!-- name -->
              <div class="kt-item-list__item-title">
                {{ (prescriber ? prescriber.firstName + " " + prescriber.lastName : "") +
                  (prescriber && prescriber.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") }}
              </div>
              <!-- code -->
              <div class="kt-item-list__item-text">
                {{ prescriber ? prescriber.code : "" }}
              </div>
              <!-- contactInformationId -->
              <b-form-group
                class="mb-0 mt-3"
                :label="$t('files.contactInformation')"
              >
                <!-- empty -->
                <b-button
                  v-show="!contactInformationId"
                  v-b-modal="'chooseContactInformationModal' + _uid"
                  :variant="'outline-' + $systemSettings.theme"
                >
                  {{ $t("fileEdit.choose") }}
                </b-button>
                <!-- select contactInformation -->
                <b-button
                  v-show="contactInformationId"
                  v-b-modal="'chooseContactInformationModal' + _uid"
                  variant="outline-secondary"
                  class="kt-contactInformation-btn"
                >
                  <span class="d-block h6 mb-1">
                    {{ (contactInformation ? contactInformation.name : "") + (contactInformation && contactInformation.isActive !== true ? ' (' + $t("general.deletedLabel") + ')' : '') }}
                  </span>
                  <span class="d-block">
                    {{ contactInformation ? formatAddress(contactInformation.address1, contactInformation.address2, contactInformation.zip, contactInformation.city, contactInformation.country) : "" }}
                  </span>
                  <span class="d-block">
                    {{ contactInformation ? contactInformation.phone + ' - ' + (contactInformation.email || "NC") : "" }}
                  </span>
                </b-button>
              </b-form-group>
              <!-- is deleted information -->
              <p
                v-show="contactInformation && contactInformation.isActive === false"
                class="text-danger mb-0 mt-1"
                style="font-size: 80%;"
              >
                {{ $t("contacts.notifications.deletedOrganisationContactInformation") }}
              </p>
              <!-- invalid Organisation Error Message
              <p
                v-show="prescriber.validOrganisation === false"
                class="text-danger mb-0 mt-3"
                style="font-size: 80%;"
              >
                {{ $t("fileEdit.validations.prescriberDoesntBelongToOrganisation") }}
              </p> -->
            </div>
          </div>
        </div>
        <!-- add a prescriber -->
        <b-button
          v-show="!prescriber"
          ref="choosePrescriberButton"
          v-b-modal="'choosePrescriberModal' + _uid"
          :variant="'outline-' + $systemSettings.theme"
        >
          {{ $t("fileEdit.choose") }}
        </b-button>
        <!-- Warning : Prescriber doesn't belong to Organisation -->
        <div v-show="!isValidPrescriberBelongsToOrganisation">
          <div class="kt-badge kt-badge--yellow mt-2">
            <b-icon
              icon="info-circle"
              class="kt-badge__icon"
            ></b-icon>
            <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("fileEdit.validations.prescriberDoesntBelongToOrganisation") }}</span></span>
          </div>
        </div>
      </b-form-group>

      <!-- file-contacts -->
      <label v-show="!parentFileId">
        <span>{{ $t('files.otherRecipients') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.fileContacts"
          :valueProp="fileContacts"
          @unsaveValue="$userSettings.savedBehaviors.office.file.fileContacts = null"
          @saveValue="$userSettings.savedBehaviors.office.file.fileContacts = $event"
          @importValue="($event) => {
            if ($event && $event.length > 0) {
              for (let i = 0; i < $event.length; i++) {
                onFileContactChosen($event[i].contact)
              }
            }
          }"
        />
      </label>
      <b-form-group
        v-show="!parentFileId"
        :invalid-feedback="fileContactsInvalidFeedback"
        :state="fileContactsState"
      >
        <!-- file-contacts list -->
        <div class="kt-item-list">
          <div
            v-for="fileContactItem in fileContacts"
            :key="fileContactItem.contactId"
            class="kt-item-list__item-wrapper"
          >
            <div class="kt-item-list__item">
              <!-- actions -->
              <div class="kt-item-list__item-actions">
                <!-- delete button -->
                <b-button
                  v-if="isEnabled(['menu', 'office', 'files', 'fileContacts', 'delete'])"
                  class="btn-icon kt-item-list__item-delete"
                  size="sm"
                  variant="light"
                  squared
                  @click="onDeleteFileContact(fileContactItem.contactId)"
                >
                  <b-icon icon="x"></b-icon>
                </b-button>
              </div>

              <!-- name -->
              <div class="kt-item-list__item-title">
                {{ fileContactItem.contact.firstName + " " + fileContactItem.contact.lastName + (fileContactItem.contact.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") }}
              </div>
              <!-- code -->
              <div class="kt-item-list__item-text">
                {{ fileContactItem.contact.code }}
              </div>
            </div>
          </div>
        </div>
        <!-- add a file contact -->
        <b-button
          v-show="isEnabled(['menu', 'office', 'files', 'fileContacts', 'add'])"
          ref="addContactButton"
          v-b-modal="'chooseContactModal' + _uid"
          size="sm"
          :variant="'outline-' + $systemSettings.theme"
          pill
        >
          <b-icon icon="plus"></b-icon>
          {{ $t("fileEdit.addFileContact") }}
        </b-button>
      </b-form-group>

      <!-- divider -->
      <div
        v-show="!parentFileId"
        class="w-100 border-top mt-1 mb-2"
      ></div>

      <!-- invoicedOrganisation -->
      <b-form-group
        v-show="!parentFileId"
        :label="$t('files.invoicedOrganisation')"
        :invalid-feedback="invoicedOrganisationInvalidFeedback"
        :state="invoicedOrganisationState"
      >
        <b-form-input
          :value="invoicedOrganisationName"
          disabled
          :state="invoicedOrganisationState"
        ></b-form-input>
      </b-form-group>

      <!-- divider -->
      <div
        v-show="!isActiveAccountingMode && !isDiagnosisUser"
        class="w-100 border-top mt-1 mb-2"
      ></div>

      <!-- containerGroups - list -->
      <label v-show="!isActiveAccountingMode">
        <span>{{ $t('files.containerGroups') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.containerGroups"
          :valueProp="containerGroups"
          @unsaveValue="$userSettings.savedBehaviors.office.file.containerGroups = null"
          @saveValue="($event) => {
            const containerGroups = JSON.parse(JSON.stringify($event));
            for (let i = 0; i < containerGroups.length; i++) {
              if (containerGroups[i].tempId) delete containerGroups[i].tempId;
            }
            $userSettings.savedBehaviors.office.file.containerGroups = containerGroups;
          }"
          @importValue="importSavedcontainerGroups($event)"
        />
      </label>
      <ContainerGroups
        v-show="!isActiveAccountingMode"
        ref="containerGroups"
        :containerGroupsProp="containerGroups"
        :isValidationActiveProp="containerGroupsValidation"
        :containerGroupsRequiredProp="containerGroupsRequired"

        :fileCreationDateProp="creationDate"
        :laboratoryIdProp="laboratoryId"
        :sectorIdProp="sectorId"
        :fileEditModeProp="editMode"

        @containerGroupsUpdated="onContainerGroupsUpdated($event)"
        @updateIsValid="containerGroupsState = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />

      <!-- additionalFeeGroup -->
      <label
        v-if="!isActiveReadOnly && !parentFileId && additionalFeeGroupsOptions.length"
      >
        <span>{{ $t('files.additionalFeeGroup') }}</span>
        <Pin
          v-if="!editMode && !parentFileIdProp"
          :savedValueProp="$userSettings.savedBehaviors.office.file.additionalFeeGroupId"
          :valueProp="additionalFeeGroupId"
          @unsaveValue="$userSettings.savedBehaviors.office.file.additionalFeeGroupId = null"
          @saveValue="$userSettings.savedBehaviors.office.file.additionalFeeGroupId = $event"
          @importValue="($event) => {
            const filteredOptions = additionalFeeGroupsOptions.filter((v) => v.id === $event);
            additionalFeeGroupId = filteredOptions.length === 1 ? filteredOptions[0].id : null;
          }"
        />
      </label>
      <SmartSelectInput
        v-if="!isActiveReadOnly && !parentFileId && additionalFeeGroupsOptions.length"
        labelProp=""
        :optionsProp="additionalFeeGroupsOptions"
        valueFieldProp="id"
        textFieldProp="name"

        :valueProp="additionalFeeGroupId"
        :stateProp="additionalFeeGroupState"
        :invalidFeedbackProp="additionalFeeGroupInvalidFeedback"

        :disabledProp="false"
        :showDisabledItemsProp="true"
        :showInactiveItemsProp="false"
        :displayUniqueProp="true"
        :selectFirstOnloadProp="$userSettings.savedBehaviors.office.file.additionalFeeGroupId === null"
        :initialValueProp="originalData !== null && originalData.additionalFeeGroupId ? originalData.additionalFeeGroupId : null"
        @input="($event) => {
          const filteredOptions = additionalFeeGroupsOptions.filter((v) => v.id === $event);
          additionalFeeGroupId = filteredOptions.length === 1 ? filteredOptions[0].id : null;
        }"
      />

      <!-- pathologist -->
      <b-row v-if="laboratoryId !== null && !isActiveAccountingMode && !parentFileId">
        <b-col
          cols="12"
          md="4"
        >
          <label>
            <span>{{ $t('files.pathologist') }}</span>
            <Pin
              v-if="!editMode && !parentFileIdProp"
              :savedValueProp="$userSettings.savedBehaviors.office.file.pathologistId"
              :valueProp="pathologistId"
              @unsaveValue="$userSettings.savedBehaviors.office.file.pathologistId = null"
              @saveValue="$userSettings.savedBehaviors.office.file.pathologistId = $event"
              @importValue="onPathologistUpdated($event)"
            />
          </label>
          <InputSearch
            searchedTableProp="pathologists"
            searchedFieldProp="user.lastName"
            :searchedLaboratoriesProp="laboratoryId ? [laboratoryId] : []"
            :returnFieldsArrayProp="['id', 'user.lastName', 'user.firstName', 'user.isActive', 'code']"
            suggestionsKeyProp="id"
            :valueProp="pathologist"
            :displayedValueProp="suggestionFormatPathologists(pathologist)"
            :suggestionFormatProp="suggestionFormatPathologists"

            :stateProp="pathologistState"
            :invalidFeedbackProp="pathologistInvalidFeedback"

            @onUpdateParent="onPathologistUpdated($event ? $event.id : null)"
            @onActivateValidation="pathologistValidation = true"
            @onDisableValidation="pathologistValidation = false;"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          ></InputSearch>
        </b-col>
      </b-row>

      <!-- divider -->
      <div class="w-100 border-top mt-3 mb-3"></div>

      <!-- requestErrors -->
      <div
        v-show="!parentFileId && filingStatus !== 'validatedForDiagnosis' && filingStatus !== 'validatedForAccounting'"
        class="mb-1"
      >
        <button
          type="button"
          class="kt-checkbox-display-only-cont"
          @click.prevent="onClickRequestErrors"
          @keydown.space.prevent="onClickRequestErrors"
          @keydown.enter.prevent="onValidateFile"
        >
          <span
            class="kt-checkbox-display-only"
            :class="'kt-checkbox-display-only--' + $systemSettings.theme + (requestErrors.length ? ' kt-checkbox-display-only--checked' : '')"
          ></span>
          <span class="kt-checkbox-display-only__text">{{ $t("fileEdit.fileRequestErrorsCheckboxMessage") }}</span>
        </button>
        <!-- list -->
        <ul
          v-show="requestErrors.length"
          style="font-size: 14px; padding-left: 34px;"
        >
          <li
            v-for="requestErrorId in requestErrors"
            :key="requestErrorId"
          >
            {{ getRequestErrorLocalisedName(requestErrorId) }}
          </li>
        </ul>
        <!-- requesterrors message -->
        <p
          v-show="willErrorMessageBeSent"
          class="mt-1 mb-0"
          style="font-size: 14px"
        >
          {{ willErrorMessageBeSent === "patient" ? $t("fileEdit.requestErrorActionDescriptionPatient") : $t("fileEdit.requestErrorActionDescriptionPrescriber") }}
        </p>
      </div>

      <!-- save file button -->
      <b-button
        v-if="filingStatus !== 'validatedForAccounting' && (!isDiagnosisUser || editMode || parentFileId === null)"
        class="my-2 mr-2"
        :variant="requestErrors.length ? $systemSettings.theme : 'outline-secondary'"
        @click.prevent="onSaveFile"
      >
        {{ saveButtonStringFormatted }}
      </b-button>
      <!-- validate file button (for diagnosis or accounting) -->
      <b-button
        v-if="filingStatus !== 'validatedForAccounting' && !requestErrors.length"
        class="my-2 mr-2"
        :variant="$systemSettings.theme"
        type="submit"
      >
        {{ validateButtonStringFormatted }}
      </b-button>
    </b-form>

    <!-- choose Patient Modal -->
    <b-modal
      ref="choosePatientModal"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
      @hidden="() => {
        $nextTick(() => {
          if (patient === null) {
            $refs.patientChooseButton.focus();
          } else {
            $refs.patientEditButton.focus();
          }
        });
      }"
    >
      <template #modal-title>
        <div class="d-flex">
          <span>{{ $t('patientChoose.pageTitle') }}</span>
          <!-- add patient button -->
          <b-button
            ref="addPatientButton"
            class="ml-3"
            :variant="$systemSettings.theme"
            :disabled="!patientIsSearched"
            @click="onAddPatient"
            @keydown.ctrl.down.exact.prevent="() => {
              $refs.patientChooseComponent.focusFirstElement()
            }"
          >
            <b-icon icon="plus"></b-icon>
            {{ $t("patientChoose.createNewPatient") }}
          </b-button>
        </div>
      </template>
      <div @keydown.ctrl.up.exact.prevent="focusAddPatientButton">
        <PatientChoose
          ref="patientChooseComponent"
          :initialFilterValuesProp="patientInitialFilterValues"
          @searched="patientIsSearched = true;"
          @chosen="onPatientChosen($event ? $event.id : null)"
          @openAddItem="onAddPatient"
          @updateInitialFilterValues="patientInitialFilterValues = $event"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </div>
    </b-modal>
    <!-- edit Patient Modal -->
    <b-modal
      ref="editPatientModal"
      hide-footer
      :title="patientId ? $t('patientEdit.pageTitle') : $t('patientNew.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientEditComponent.focusFirstElement();
      }"
      @hidden="() => {
        $nextTick(() => {
          if (patient === null) {
            $refs.patientChooseButton.focus();
          } else {
            $refs.patientEditButton.focus();
          }
        });
      }"
    >
      <PatientEdit
        ref="patientEditComponent"
        :patientIdProp="patientId"
        :editModeProp="Boolean(patientId)"
        :moduleModeProp="true"
        :initialValuesProp="patientInitialFilterValues"
        @onPatientCreated="onPatientChosen($event)"
        @onPatientEdited="onPatientChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- choose Organisation Modal -->
    <b-modal
      id="chooseOrganisationModal"
      hide-footer
      :title="$t('organisationChoose.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.organisationChooseComponent.focusFirstElement()
      }"
    >
      <OrganisationChoose
        ref="organisationChooseComponent"
        :optionsProp="{
          filterIsPrescribing: true
        }"
        @chosen="onOrganisationChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- choose Prescriber Modal -->
    <b-modal
      :id="'choosePrescriberModal' + _uid"
      hide-footer
      :title="$t('fileContacts.choosePrescriberModalTitle')"
      size="xl"
      @shown="() => {
        $refs.prescriberChooseComponent.focusFirstElement()
      }"
    >
      <ContactChoose
        ref="prescriberChooseComponent"
        :organisationIdProp="prescribingOrganisationId"
        @chosen="onPrescriberChosen"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- choose Contact Modal -->
    <b-modal
      :id="'chooseContactModal' + _uid"
      hide-footer
      :title="$t('fileContacts.chooseContactModalTitle')"
      size="xl"
      @shown="() => {
        $refs.contactChooseComponent.focusFirstElement()
      }"
    >
      <ContactChoose
        ref="contactChooseComponent"
        @chosen="onFileContactChosen"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>

    <!-- choose ContactInformation Modal -->
    <b-modal
      :id="'chooseContactInformationModal' + _uid"
      hide-footer
      :title="$t('fileEdit.contactInformationModalTitle')"
      size="md"
      @shown="() => {
        if ($refs.contactInformationOptions[contactInformationPosition || 0]) {
          $refs.contactInformationOptions[contactInformationPosition || 0].focus();
        }
      }"
    >
      <div
        v-for="(contactInformationOption, index) in contactInformationOptions"
        :key="contactInformationOption.id"
      >
        <div
          v-if="index !== 0"
          class="border-top my-2"
        ></div>
        <b-button
          v-show="contactInformationId"
          ref="contactInformationOptions"
          class="kt-contactInformation-btn"
          variant="outline-secondary"
          @click="contactInformationId = contactInformationOption.id; $bvModal.hide('chooseContactInformationModal' + _uid);"
        >
          <span class="d-block h6 mb-1">
            {{ contactInformationOption.name + (contactInformationOption.isActive !== true ? ' (' + $t("general.deletedLabel") + ')' : '') }}
          </span>
          <span class="d-block">
            {{ formatAddress(contactInformationOption.address1, contactInformationOption.address2, contactInformationOption.zip, contactInformationOption.city, contactInformationOption.country) }}
          </span>
          <span class="d-block">
            {{ contactInformationOption.phone + ' - ' + contactInformationOption.email }}
          </span>
        </b-button>
      </div>
    </b-modal>

    <!-- requestErrors Modal  -->
    <b-modal
      id="requestErrorsModal"
      hide-footer
      :title="$t('fileEdit.chooseRequestErrorsModalTitle')"
      @shown="() => {
        $refs.requestErrorsOptionsInput.$children[0].focus();
      }"
    >
      <b-form @submit.prevent="onFinishedRequestErrors">
        <b-form-checkbox-group
          ref="requestErrorsOptionsInput"
          v-model="requestErrors"
          :options="requestErrorsOptions"
          value-field="id"
          text-field="localisedName"
          stacked
        ></b-form-checkbox-group>
        <!-- save -->
        <b-button
          class="mt-3"
          :variant="$systemSettings.theme"
          type="submit"
        >
          {{ $t('general.done') }}
        </b-button>
      </b-form>
    </b-modal>
    <!-- saveBeforeComplementary Modal  -->
    <b-modal
      id="saveBeforeComplementaryModal"
      hide-footer
      :title="$t('fileEdit.saveBeforeComplementaryModalTitle')"
      @shown="() => {
        $refs.saveBeforeComplementaryButton.focus();
      }"
    >
      <!-- save -->
      <b-button
        ref="saveBeforeComplementaryButton"
        class="mt-3"
        variant="outline-secondary"
        @click.prevent="saveBeforeComplementaryModalAction(false)"
      >
        {{ $t("fileEdit.submitTextSave") }}
      </b-button>
      <!-- validate -->
      <b-button
        class="mt-3 ml-2"
        :variant="$systemSettings.theme"
        @click.prevent="saveBeforeComplementaryModalAction(true)"
      >
        {{ validateButtonStringFormatted }}
      </b-button>
    </b-modal>

    <!-- parent fileView Modal  -->
    <b-modal
      :id="'parentFileViewModal-' + fileId"
      size="xl"
      hide-footer
      :title="$t('files.parentFileViewModalTitle')"
    >
      <template #modal-header>
        <div>
          <h5
            :id="'parentFileViewModal-' + fileId + '___BV_modal_title_'"
            class="modal-title kt-page-header__title h4"
          >
            {{ $t('files.parentFileViewModalTitle') }}
          </h5>
          <!-- edit parent file -->
          <b-button
            v-if="isEnabled(['menu', 'office', 'files', 'edit'])"
            size="sm"
            :variant="$systemSettings.theme"
            class="ml-3"
            @click="navigate('office/file/edit', {fileIdProp: parentFileId})"
          >
            <b-icon icon="pen"></b-icon>
            {{ $t("general.edit") }}
          </b-button>
        </div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('parentFileViewModal-' + fileId)"
        >
          {{ $t('general.closeX') }}
        </button>
      </template>
      <FileView
        v-if="parentFileId"
        :fileIdProp="parentFileId"
        moduleModeProp
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- fileStatusesHistory Modal  -->
    <b-modal
      :id="'fileStatusesHistoryModal-' + fileId"
      size="xl"
      hide-footer
      :title="$t('files.fileStatusesHistoryModalTitle')"
    >
      <FileStatusesHistory
        v-if="displayFileStatusesHistory"
        :fileStatusesHistoryProp="fileStatusesHistory"
        :sentMessagesProp="sentMessages"
        :fileModificationsProp="fileModifications"
        hideHeaderProp
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- archives Modal  -->
    <b-modal
      :id="'archivesModal-' + _uid"
      size="m"
      hide-header
      hide-footer
    >
      <FileArchives
        :archivesProp="archives"
        :hasMissingArchivesProp="hasMissingArchives"
        :readyProp="!archivesIsLoading"
        @close="$bvModal.hide('archivesModal-' + _uid)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- fileComments Modal  -->
    <b-modal
      :id="'fileCommentsModal-' + fileId"
      size="xl"
      hide-footer
      :title="$t('files.fileCommentsModalTitle')"
      @shown="() => {
        $refs.fileCommentsComponent.focusFirstElement()
      }"
    >
      <FileComments
        ref="fileCommentsComponent"
        :commentsBeforeCreationProp="commentsBeforeCreation"
        :fileIdProp="fileId"
        @onExportCommentsBeforeCreation="commentsBeforeCreation = $event"
        @updateCommentsCount="($event) => {
          debug($event);
          commentsCount = $event;
          debug(commentsCount);
        }"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- groupOfFiles Modal -->
    <b-modal
      :id="'complementaryFilesModal-' + fileId"
      size="m"
      hide-header
      hide-footer
      @shown="() => {
        if (groupOfFiles && groupOfFiles.length > 1) {
          $refs.chooseFromIdsComponent.focusFirstElement();
        } else if ($refs.addComplementaryButton && isEnabled(['roleSpecific', 'cytotechnician'])) {
          $refs.addComplementaryButton.focus();
        }
      }"
    >
      <!-- no complementary -->
      <div v-show="!groupOfFiles || groupOfFiles.length < 2">
        <!-- modal header -->
        <header
          class="modal-header"
          style="margin: -1rem -1rem 0 -1rem;"
        >
          <div>
            <!-- title -->
            <h5
              class="modal-title kt-page-header__title h4 mb-0"
              style="font-size: 22px; line-height: 24px;"
            >
              {{ $t("fileChooseFromIds.pageTitle") }}
            </h5>
          </div>
          <!-- close -->
          <button
            ref="closeButton"
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('complementaryFilesModal-' + fileId)"
          >
            {{ $t('general.closeX') }}
          </button>
        </header>
        <!-- modal content -->
        <p class="text-dark text-15 mb-1 mt-3">
          {{ $t("files.noComplementaryFile") }}
        </p>
      </div>
      <FileChooseFromIds
        v-if="groupOfFiles && groupOfFiles.length > 1"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="groupOfFiles"
        :currentFileIdProp="fileId"
        @chosen="($event) => {
          if ($event !== null && $event.id !== fileId) {
            navigate('office/file/edit', {fileIdProp: $event.id})
          } else {
            $bvModal.hide('complementaryFilesModal-' + fileId)
          }
        }"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
      <!-- new complementary file -->
      <b-button
        v-if="isEnabled(['roleSpecific', 'cytotechnician'])"
        v-show="requestErrors.length === 0 && !parentFileId"
        ref="addComplementaryButton"
        class="mb-2 mt-3"
        :variant="$systemSettings.theme"
        type="button"
        @click="addComplementaryFile"
      >
        {{ $t('fileEdit.addComplementaryFile') }}
      </b-button>
    </b-modal>
    <!-- file alreadyCreated Modal  -->
    <b-modal
      :id="'alreadyCreatedModal-' + _uid"
      size="sm"
      hide-footer
      hide-header
      @shown="() => {
        $refs.alreadyCreatedButton.focus()
      }"
    >
      <div class="text-center py-1">
        <h4
          style="font-size: 18px;
          letter-spacing: -0.03px;
          margin-bottom: 12px;"
        >
          <b-icon
            icon="exclamation-triangle"
            style="margin-right: 6px; color: #a8001b !important;"
          ></b-icon>
          {{ $t('fileEdit.notifications.alreadyCreatedTitle') }}
        </h4>
        <b-button
          ref="alreadyCreatedButton"
          :variant="$systemSettings.theme"
          size="sm"
          @click="navigate('office/file/view', {fileIdProp: fileId})"
        >
          <b-icon icon="eye"></b-icon>
          {{ $t('fileEdit.notifications.alreadyCreatedButton') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
import StatusBadge from "@shared/views/Helpers/StatusBadge";
import PatientView from "@/views/Office/Patients/PatientView";
import PatientEdit from "@/views/Office/Patients/PatientEdit";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import ContainerGroups from "@/views/Data/ContainerGroups";
import PersonName from "@shared/views/Helpers/PersonName";
import FileStatusesHistory from "@/views/Office/Files/FileStatusesHistory";
import FileArchives from "@shared/views/Office/Files/FileArchives";
import FileComments from "@/views/Office/Files/FileComments";
import SmartSelectInput from "@shared/views/Helpers/SmartSelectInput";
import FileView from "@/views/Office/Files/FileView";
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
import Pin from "@shared/views/Helpers/Pin";
// modals
import ContactChoose from "@/views/Office/Contacts/ContactChoose";
import OrganisationChoose from "@/views/Data/Organisations/OrganisationChoose";
// services
import filesServices from "@/services/API/filesServices";
import commonServices from "@shared/services/API/commonServices";
import cytologyReportsServices from "@/services/API/cytologyReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import address from "@shared/services/UI/address";
import phoneNumber from "@shared/services/UI/phoneNumber";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: {
    InputSearch,
    StatusBadge,
    ContactChoose,
    OrganisationChoose,
    ContainerGroups,
    PatientEdit,
    PatientView,
    PatientChoose,
    PersonName,
    FileStatusesHistory,
    FileArchives,
    FileComments,
    FileChooseFromIds,
    SmartSelectInput,
    FileView,
    Pin
  },
  mixins: [userRights, error, date, address, phoneNumber],
  props: {
    accessNumberProp: {
      type: [Number, String],
      default: null
    },
    fileIdProp: {
      type: Number,
      default: null
    },
    parentFileIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    },
    moduleModeProp: {
      type: Boolean
    },
    cancelRouteProp: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // general
      debugMode: false,
      editMode: this.editModeProp,
      fileId: this.fileIdProp,
      parentFileId: this.parentFileIdProp,
      referenceCreationDate: "",
      originalData: null,
      isDownloading: false,
      // form variables
      commentsBeforeCreation: [],

      complementaryFiles: [],
      groupOfFiles: [],

      isUrgent: false,
      laboratoryId: null,
      sectorId: null,

      patient: null,
      patientId: null,
      forceHealthCare: false,
      forceHealthCareOptions: {
        type: "screeningCampaign",
        reference: "",
        date: ""
      },
      ipp: "",
      fileStatusesHistory: null,
      sentMessages: [],
      fileModifications: [],

      prescribingOrganisationId: null,
      prescribingOrganisation: null,
      forceInvoicePatient: null,
      hospitalisationNumber: "",

      prescriber: null,
      prescriberHasDownloaded: false,
      fileContacts: [],
      contactInformationId: null,

      invoicedOrganisationId: null,
      invoicedOrganisation: null,

      pathologistId: null,
      pathologist: null,

      additionalFeeGroupId: null,
      containerGroups: [],

      requestErrors: [],

      // file variables only for display
      accessNumber: this.accessNumberProp,
      creationDate: "",
      archives: [],
      archivesIsLoading: false,
      hasMissingArchives: false,
      commentsCount: 0,
      filingStatus: "",
      medicalStatus: "",
      accountingStatus: "",
      isUrgentLoading: false,
      isUrgentSuccessIconDisplay: false,
      displayFileStatusesHistory: true,
      patientInvoicedOrganisation: null,
      pathologistName: "",
      laboratoryIsDisplayed: false,
      sectorIsDisplayed: false,
      // validation variables
      areContainerGroupsEdited: false,
      areContactsEdited: false,
      areRequestErrorsEdited: false,
      isActiveDiagnosisValidation: false,
      isActiveAccountingValidation: false,
      // patient
      patientIsSearched: false,
      patientInitialFilterValues: {},
      // patient validation errors
      patientFieldsRequiredErrors: [],
      phoneAndEmailOrAddressRequiredError: false,
      partialAddressError: false,
      patientNameEditionError: false,
      // is required active
      laboratoryIdRequired: true,
      sectorIdRequired: true,
      patientRequired: true,
      pathologistRequired: false,
      prescribingOrganisationRequired: false,
      forceInvoicePatientRequired: false,
      hospitalisationNumberRequired: false,
      prescriberRequired: false,
      fileContactsRequired: false,
      invoicedOrganisationRequired: false,
      containerGroupsRequired: false,
      additionalFeeGroupRequired: false,
      forceHealthCareRequired: false,
      ippRequired: false,
      // is validation active
      laboratoryIdValidation: false,
      sectorIdValidation: false,
      patientValidation: false,
      forceHealthCareValidation: false,
      ippValidation: false,
      prescribingOrganisationValidation: false,
      forceInvoicePatientValidation: false,
      hospitalisationNumberValidation: false,
      prescriberValidation: false,
      fileContactsValidation: false,
      invoicedOrganisationValidation: false,
      containerGroupsValidation: false,
      additionalFeeGroupValidation: false,
      pathologistValidation: false,
      // child auto validation
      containerGroupsState: false,
      // options arrays
      sectorsOptions: [],
      additionalFeeGroupsOptions: []
    };
  },
  computed: {
    // form validation
    laboratoryIdState: function() {
      // is validation active
      if (!this.laboratoryIdValidation) return null;
      // required
      if (this.laboratoryIdRequired) {
        return this.laboratoryId !== null ? null : false;
      }
      // else
      return null;
    },
    laboratoryIdInvalidFeedback: function() {
      return this.laboratoryIdState === false ? this.$t("validationRules.required") : "";
    },
    sectorIdState: function() {
      // is validation active
      if (!this.sectorIdValidation) return null;
      // required
      if (this.sectorIdRequired) {
        return this.sectorId ? null : false;
      }
      // else
      return null;
    },
    sectorIdInvalidFeedback: function() {
      return this.sectorIdState === false ? this.$t("validationRules.required") : "";
    },
    prescribingOrganisationState: function() {
      // is validation active
      if (!this.prescribingOrganisationValidation) return null;
      // required
      if (this.prescribingOrganisationRequired) {
        return this.prescribingOrganisation ? null : false;
      }
      // else
      return null;
    },
    prescribingOrganisationInvalidFeedback: function() {
      return this.prescribingOrganisationState === false ? this.$t("validationRules.required") : "";
    },
    forceInvoicePatientState: function() {
      // is validation active
      if (!this.forceInvoicePatientValidation) return null;
      // required (when an organisation is selected)
      if (this.forceInvoicePatientRequired && this.prescribingOrganisation !== null) {
        return this.forceInvoicePatient !== null ? null : false;
      }
      // else
      return null;
    },
    forceInvoicePatientInvalidFeedback: function() {
      return this.forceInvoicePatientState === false ? this.$t("validationRules.required") : "";
    },
    hospitalisationNumberState: function() {
      // is validation active
      if (!this.hospitalisationNumberValidation) return null;
      // required (when an organisation is selected)
      if (this.hospitalisationNumberRequired && this.prescribingOrganisation !== null) {
        return this.hospitalisationNumber.length > 0 ? null : false;
      }
      // else
      return null;
    },
    hospitalisationNumberInvalidFeedback: function() {
      return this.hospitalisationNumberState === false ? this.$t("validationRules.required") : "";
    },
    invoicedOrganisationState: function() {
      // is validation active
      if (!this.invoicedOrganisationValidation) return null;
      // required
      if (this.invoicedOrganisationRequired) {
        return this.invoicedOrganisation ? null : false;
      }
      // else
      return null;
    },
    invoicedOrganisationInvalidFeedback: function() {
      return this.invoicedOrganisationState === false ? this.$t("validationRules.required") : "";
    },
    // file contacts helpers
    isValidPrescriberBelongsToOrganisation: function() {
      if (!this.prescriber || !this.prescribingOrganisationId) {
        return true;
      }

      if (!this.prescriber.contactOrganisations) {
        // prescriber has no organisation
        return false;
      } else {
        // search in prescriber's organisations
        for (const contactOrganisation of this.prescriber.contactOrganisations) {
          if (contactOrganisation.organisationId === this.prescribingOrganisationId) {
            return true;
          }
        }
      }
      return false;
    },
    contactInformationPosition: function() {
      let found = null;
      for (let i = 0; i < this.contactInformationOptions.length; i++) {
        if (this.contactInformationOptions[i].id === this.contactInformationId) {
          found = i;
          break;
        }
      }
      return found;
    },
    prescriberState: function() {
      // is validation active
      if (!this.prescriberValidation) return null;

      // required
      if (this.prescriberRequired && !this.prescriber) {
        return false;
      }

      // contactInformation is required
      if (this.prescriberRequired && !this.contactInformationId) {
        return false;
      }

      // check contactInformation included in prescriber
      if (this.prescriberRequired && this.contactInformationId && this.contactInformationPosition === null) {
        return false;
      }

      // else
      return null;
    },
    prescriberInvalidFeedback: function() {
      // no error
      if (this.prescriberState === null) return "";

      // required
      if (this.prescriberRequired && !this.prescriber) {
        return this.$t("validationRules.required");
      }
      // contactInformation is required
      if (this.prescriberRequired && !this.contactInformationId) {
        return this.$t("fileEdit.validations.contactInformationRequired");
      }
      // check contactInformation included in prescriber
      return this.$t("fileEdit.validations.contactInformationRequired");
    },
    fileContactsState: function() {
      // else
      return null;
    },
    fileContactsInvalidFeedback: function() {
      // no error
      return "";
    },

    forceHealthCareState: function() {
      // is validation active
      if (!this.forceHealthCareValidation) return null;
      // check for patient coverage
      if (!this.forceHealthCare) {
        return null;
      } else {
        if (!this.patient || !this.patient.invoicedOrganisationId) {
          return false;
        }
        return this.patient.insuranceType.name !== "healthCare" &&
          !this.validateDateBeforeDate(this.referenceCreationDate, this.patient.insuranceEndDate)
          ? false : null;
      }
    },
    forceHealthCareInvalidFeedback: function() {
      return this.forceHealthCareState === false ? this.$t("fileEdit.validations.patientRequiresAnHealthCare") : "";
    },
    ippState: function() {
      return null;
    },
    ippInvalidFeedback: function() {
      return "";
    },
    patientState: function() {
      // clear validation
      this.clearPatientFieldsValidation();

      // is validation active
      if (!this.patientValidation) {
        return null;
      }
      // not required
      if (!this.patientRequired) {
        return null;
      }

      // no patient
      if (!this.patientId) {
        return false;
      }

      // patient fields validation
      if (!this.updatePatientFieldsValidation()) return false;

      // else
      return null;
    },
    patientInvalidFeedback: function() {
      // no error
      if (this.patientState === null) {
        return "";
      }

      // required
      if (!this.patientId) {
        return this.$t("validationRules.required");
      }

      // patient error box
      return "";
    },
    additionalFeeGroupState: function() {
      return null;
    },
    additionalFeeGroupInvalidFeedback: function() {
      return "";
    },
    pathologistState: function() {
      // is validation active
      if (!this.pathologistValidation) return null;
      // required
      if (this.pathologistRequired && (!this.pathologist || !this.pathologist.id || !this.pathologistId)) {
        return false;
      }
      // sectorId
      if (this.pathologistWrongSectorError) {
        return false;
      }
      // additionalFeeGroup
      if (this.pathologistWrongAdditionalFeeGroup) {
        return false;
      }
      // laboratoryId
      if (this.pathologist && this.laboratoryId) {
        if (this.pathologist.user.userLaboratories.filter((v) => v.laboratoryId === this.laboratoryId).length === 0) {
          return false;
        }
      }
      // else
      return null;
    },
    pathologistInvalidFeedback: function() {
      // no error
      if (this.pathologistState === null) {
        return "";
      }
      // required
      if (!this.pathologist || !this.pathologist.id || !this.pathologistId) {
        return this.$t("validationRules.required");
      }
      // sectorId
      if (this.pathologistWrongSectorError) {
        return this.$t("fileEdit.validations.pathologistWrongSector");
      }
      // additionalFeeGroup
      if (this.pathologistWrongAdditionalFeeGroup) {
        if (this.pathologist.replacedPathologist) {
          return this.$t("fileEdit.validations.replacedPathologistWrongAdditionalFee");
        } else {
          return this.$t("fileEdit.validations.pathologistWrongAdditionalFee");
        }
      }
      // laboratoryId
      return this.$t("fileEdit.validations.pathologistWrongLaboratory");
    },
    pathologistWrongSectorError: function() {
      // no pathologist
      if (!this.pathologist || !this.pathologistId) {
        return false;
      }
      // sectorId
      if (this.sectorId) {
        if (this.pathologist.pathologistSectors.filter((v) => v.sectorId === this.sectorId).length === 0) {
          return true;
        }
      }
      // no error
      return false;
    },
    pathologistWrongAdditionalFeeGroup: function() {
      // no pathologist yet or no additionalFeeGroup
      if (!this.pathologist || !this.pathologistId || !this.additionalFeeGroupId) {
        return false;
      }
      const pathologist = this.pathologist.replacedPathologist || this.pathologist;
      // additionalFeeGroup
      return (
        pathologist.pathologistAdditionalFeeGroups.filter((v) => v.additionalFeeGroupId === this.additionalFeeGroupId).length === 0
      );
    },

    // parent file errors
    parentFileErrors: function() {
      const errors = [];
      if (this.parentFileId) {
        if (this.laboratoryIdState === false) errors.push(this.$t("files.laboratory"));
        if (this.sectorIdState === false) errors.push(this.$t("files.sector"));
        if (this.patientState === false) errors.push(this.$t("files.patient"));
        if (this.forceHealthCareState === false) errors.push(this.$t("files.forceHealthCare"));
        if (this.ippState === false) errors.push(this.$t("files.ipp"));
        if (this.prescribingOrganisationState === false) errors.push(this.$t("files.prescribingOrganisation"));
        if (this.forceInvoicePatientState === false) errors.push(this.$t("files.invoicePatient"));
        if (this.hospitalisationNumberState === false) errors.push(this.$t("files.hospitalisationNumber"));
        if (this.prescriberState === false) errors.push(this.$t("files.prescriber"));
        if (this.fileContactsState === false) errors.push(this.$t("files.otherRecipients"));
        if (this.invoicedOrganisationState === false) errors.push(this.$t("files.invoicedOrganisation"));
        if (this.additionalFeeGroupState === false) errors.push(this.$t("files.additionalFeeGroup"));
        if (this.pathologistState === false) errors.push(this.$t("files.pathologist"));
      }
      return errors;
    },
    requestErrorsOptions: function() {
      return this.$systemSettings.requestErrors;
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      if (this.parentFileId) {
        return this.editMode ? this.$t("fileEdit.pageTitleComplementary") : this.$t("fileNew.pageTitleComplementary");
      } else {
        return this.editMode ? this.$t("fileEdit.pageTitle") : this.$t("fileNew.pageTitle");
      }
    },
    saveButtonStringFormatted: function() {
      if (!this.willErrorMessageBeSent) {
        // no message
        return this.$t("fileEdit.submitTextSave");
      } else {
        // has message
        if (this.willErrorMessageBeSent === "prescriber") {
          return this.$t("fileEdit.submitTextSaveErrorInRequestPrescriber");
        } else {
          return this.$t("fileEdit.submitTextSaveErrorInRequestPatient");
        }
      }
    },
    validateButtonStringFormatted: function() {
      let buttonText = "";
      if (this.isDiagnosisUser) {
        if (this.editMode) {
          // edition
          buttonText = this.$t("fileEdit.submitTextValidate");
        } else {
          // creation
          if (this.parentFileId === null) {
            // initial
            buttonText = this.$t("fileNew.submitTextValidate");
          } else {
            // complementary
            buttonText = this.$t("fileEdit.submitTextCreateComplementaryFile");
          }
        }
      } else if (this.filingStatus === "validatedForDiagnosis") {
        // validate for accounting
        buttonText = this.$t("fileEdit.submitTextValidateForAccounting");
      } else {
        if (this.editMode) {
          // edition
          buttonText = this.$t("fileEdit.submitTextValidate");
        } else {
          // creation
          buttonText = this.$t("fileNew.submitTextValidate");
        }
      }
      return buttonText;
    },
    laboratoryName: function() {
      let laboratoryName = "";
      if (this.laboratoryId && this.$systemSettings.laboratories) {
        for (const laboratory of this.$systemSettings.laboratories) {
          if (laboratory.id === this.laboratoryId) {
            laboratoryName = laboratory.formattedName;
            break;
          }
        }
      }
      return laboratoryName;
    },
    sectorLocalisedName: function() {
      let sectorLocalisedName = "";
      if (this.sectorId && this.sectorsOptions) {
        for (const sector of this.sectorsOptions) {
          if (sector.id === this.sectorId) {
            sectorLocalisedName = sector.localisedName + (!sector.isActive ? " (" + this.$t("general.deletedLabel") + ")" : "");
            break;
          }
        }
      }
      return sectorLocalisedName;
    },
    invoicedOrganisationName: function() {
      let name = "";
      if (this.invoicedOrganisation === null) {
        if (this.patient) {
          name += this.$t("files.patient") + " : " + this.patient.firstName + (this.patient.firstName && this.patient.lastName ? " " : "") + this.patient.lastName;
        }
      } else {
        name = this.invoicedOrganisation.organisationType.name === "healthCare" ? this.$t("files.healthCare") : this.$t("files.organisation");
        name += " : " + this.invoicedOrganisation.name;
        if (this.invoicedOrganisation.isActive === false) {
          name += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return name;
    },
    additionalFeeGroupReadOnlyName: function() {
      if (!this.isActiveReadOnly) {
        return "";
      }
      for (const option of this.additionalFeeGroupsOptions) {
        if (option.id === this.additionalFeeGroupId) {
          const additionalFeeGroup = option;
          return additionalFeeGroup.name + (additionalFeeGroup.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
        }
      }
      return this.$t("additionalFeeGroups.defaultGroupName");
    },
    // sentMessage and errorInRequest
    prescriberEmail: function() {
      let email = "";
      if (this.contactInformation && this.contactInformation.email) {
        email = this.contactInformation.email;
      }
      return email;
    },
    lastSentMessage: function() {
      if (this.sentMessages && this.sentMessages.length) {
        return {
          email: this.sentMessages[this.sentMessages.length - 1].content.email || "",
          phone: this.sentMessages[this.sentMessages.length - 1].content.phone || "",
          recipient: this.sentMessages[this.sentMessages.length - 1].content.recipient,
          timestamp: this.sentMessages[this.sentMessages.length - 1].timestamp
        };
      } else {
        return null;
      }
    },
    sentMessageWarningText: function() {
      let output = "";
      if (this.filingStatus === "errorInRequest" && this.lastSentMessage) {
        if (this.lastSentMessage.recipient === "prescriber") {
          output = this.$t("files.notifications.sentMessageWarningPrescriber");
        } else if (this.lastSentMessage.recipient === "patient") {
          output = this.$t("files.notifications.sentMessageWarningPatient");
        }
      }
      return output;
    },
    originalRequestErrorLocalised: function() {
      if (this.originalData === null || !this.originalData.fileRequestErrors || !this.originalData.fileRequestErrors.length) {
        return this.$t("general.emptyWithHyphen");
      }

      const output = this.originalData.fileRequestErrors.map((v) => {
        return this.getRequestErrorLocalisedName(v.requestErrorId);
      });
      return output.join(", ");
    },
    // error message
    prescriberContactEdited: function() {
      if (!this.requestErrors || this.requestErrors.length === 0) {
        return false;
      }

      if (this.lastSentMessage && this.lastSentMessage.recipient === "prescriber") {
        // lastSentMessage to the prescriber
        return this.prescriberEmail && this.lastSentMessage.email !== this.prescriberEmail;
      } else {
        // no message or lastSentMessage to the patient
        return this.prescriberEmail;
      }
    },
    patientContactEdited: function() {
      if (!this.requestErrors || this.requestErrors.length === 0) {
        return false;
      }

      if (this.lastSentMessage && this.lastSentMessage.recipient === "patient") {
        // lastSentMessage to the patient
        return this.lastSentMessage.phone !== this.patient.phone || this.lastSentMessage.email !== this.patient.email;
      } else {
        // no message or lastSentMessage to the prescriber
        return this.patient && (this.patient.phone !== "" || this.patient.email !== "");
      }
    },
    willErrorMessageBeSent: function() {
      if (!this.requestErrors.length) { // no error
        return null;
      }

      if (!this.originalData) { // new file
        if (
          this.prescriber !== null && this.prescriberEmail
        ) {
          return "prescriber";
        } else if (
          this.patient && (this.patient.email !== "" || this.patient.phone !== "")
        ) {
          return "patient";
        } else {
          return null;
        }
      } else { // file edition
        if (this.originalData.filingStatus.name === "errorInRequest") { // error was already registered
          if (!this.lastSentMessage) { // no message was sent
            if (
              this.prescriber !== null && this.prescriberEmail
            ) {
              return "prescriber";
            } else if (
              this.patient && (this.patient.email !== "" || this.patient.phone !== "")
            ) {
              return "patient";
            } else {
              return null;
            }
          } else if (this.lastSentMessage.recipient === "prescriber") { // message to prescriber was sent
            if (this.prescriber === null) { // prescriber was deleted
              if (
                this.patient && (this.patient.email !== "" || this.patient.phone !== "")
              ) {
                return "patient";
              } else {
                return null;
              }
            } else {
              if (this.prescriberEmail) {
                if (this.prescriberContactEdited) { // prescriber available and edited
                  return "prescriber";
                } else { // prescriber has not changed
                  return null;
                }
              } else {
                if (this.patient.email !== "" || this.patient.phone !== "") { // patient available
                  return "patient";
                } else {
                  return null;
                }
              }
            }
          } else { // message to patient was sent
            if (this.prescriber !== null && this.prescriberEmail) { // now we have an available prescriber
              return "prescriber";
            } else if (this.patientContactEdited && (this.patient.email !== "" || this.patient.phone !== "")) { // patient was edited and still available
              return "patient";
            } else {
              return null;
            }
          }
        } else { // edition with added error
          if (
            this.prescriber !== null && this.prescriberEmail
          ) {
            return "prescriber";
          } else if (
            this.patient && (this.patient.email !== "" || this.patient.phone !== "")
          ) {
            return "patient";
          } else {
            return null;
          }
        }
      }
    },
    // helpers variables
    isFileEdited: function() {
      // new files : always true
      if (!this.editMode || !this.originalData) {
        return true;
      }

      const additionalFeeGroupIdInitialValue = this.originalData && this.originalData.additionalFeeGroupId ? this.originalData.additionalFeeGroupId : null;
      if (this.parentFileId) {
        // fileContacts
        let areFileContactsEdited = false;
        if (this.fileContacts.length !== this.originalData.fileContacts.length) {
          areFileContactsEdited = true;
        } else {
          for (const fileContact of this.fileContacts) {
            for (const originalFileContact of this.originalData.fileContacts) {
              if (
                fileContact.contact.id !== originalFileContact.contact.id ||
                fileContact.isPrescriber !== originalFileContact.isPrescriber
              ) {
                areFileContactsEdited = true;
                break;
              }
            }
            if (areFileContactsEdited) break;
          }
        }
        return (
          this.isUrgent !== this.originalData.isUrgent ||
          this.laboratoryId !== this.originalData.laboratoryId ||
          this.sectorId !== this.originalData.sectorId ||

          this.prescribingOrganisationId !== this.originalData.prescribingOrganisationId ||
          this.forceInvoicePatient !== this.originalData.forceInvoicePatient ||
          this.hospitalisationNumber !== this.originalData.hospitalisationNumber ||
          areFileContactsEdited ||
          this.contactInformationId !== this.originalData.contactInformationId ||

          this.patientId !== this.originalData.patientId ||
          this.isforceHealthCareEdited ||
          this.ipp !== this.originalData.ipp ||

          this.invoicedOrganisationId !== this.originalData.invoicedOrganisationId ||

          this.areContainerGroupsEdited ||
          this.pathologistId !== this.originalData.pathologistId ||
          this.additionalFeeGroupId !== additionalFeeGroupIdInitialValue
        );
      } else {
        return (
          this.isUrgent !== this.originalData.isUrgent ||
          this.laboratoryId !== this.originalData.laboratoryId ||
          this.sectorId !== this.originalData.sectorId ||

          this.prescribingOrganisationId !== this.originalData.prescribingOrganisationId ||
          this.forceInvoicePatient !== this.originalData.forceInvoicePatient ||
          this.hospitalisationNumber !== this.originalData.hospitalisationNumber ||
          this.areContactsEdited ||
          this.contactInformationId !== this.originalData.contactInformationId ||

          this.patientId !== this.originalData.patientId ||
          this.isforceHealthCareEdited ||
          this.ipp !== this.originalData.ipp ||
          this.patientContactEdited ||
          this.prescriberContactEdited ||

          this.invoicedOrganisationId !== this.originalData.invoicedOrganisationId ||

          this.areContainerGroupsEdited ||
          this.pathologistId !== this.originalData.pathologistId ||
          this.additionalFeeGroupId !== additionalFeeGroupIdInitialValue ||

          this.areRequestErrorsEdited
        );
      }
    },
    isforceHealthCareEdited: function() {
      if (!this.editMode || !this.originalData) return true;

      if (!this.forceHealthCare || !this.originalData.forceHealthCare) {
        return Boolean(this.forceHealthCare || this.originalData.forceHealthCare);
      } else {
        return this.forceHealthCareOptions.type !== this.originalData.forceHealthCare.type ||
          this.forceHealthCareOptions.reference !== (this.originalData.forceHealthCare.reference || "") ||
          this.forceHealthCareOptions.date !== (this.originalData.forceHealthCare.date || "");
      }
    },
    isActiveAccountingMode: function() {
      return this.filingStatus === "validatedForDiagnosis";
    },
    isActiveReadOnly: function() {
      return this.filingStatus === "validatedForAccounting" || this.isInvoiced || this.filingStatus === "cancelled" || this.medicalStatus === "cancelled" || this.accountingStatus === "cancelled";
    },
    isInvoiced: function() {
      if (this.editMode) {
        return this.accountingStatus === "invoiced" || (this.originalData && this.originalData.fileInvoices.length);
      } else {
        return this.accountingStatus === "invoiced";
      }
    },
    isReadyMedicalReport: function() {
      if (this.groupOfFiles.length === 0) {
        return false;
      }
      const notValidated = this.groupOfFiles.find((file) => {
        if (!file.parentFileId) {
          return file.medicalStatus.name !== "validatedMedically";
        } else {
          return file.medicalStatus.name !== "validatedMedically" && file.medicalStatus.name !== "cancelled";
        }
      });
      return !notValidated;
    },
    // contactInformation
    contactInformation: function() {
      let output = null;
      if (this.prescriber && this.contactInformationId && this.contactInformationOptions.length) {
        for (const contactInformation of this.contactInformationOptions) {
          if (contactInformation.id === this.contactInformationId) {
            output = contactInformation;
          }
        }
      }
      return output;
    },
    contactInformationOptions: function() {
      const options = [];
      let contactInformation;
      if (this.prescriber) {
        if (this.prescriber.contactOrganisations) {
          for (const contactOrganisation of this.prescriber.contactOrganisations) {
            contactInformation = contactOrganisation.contactInformation;
            contactInformation.organisationName = contactOrganisation.organisation.name;
            contactInformation.name = contactOrganisation.organisation.name + " - " + contactOrganisation.contactInformation.name;
            options.push(contactInformation);
          }
        }
        if (this.prescriber.contactContactInformations) {
          for (const contactContactInformation of this.prescriber.contactContactInformations) {
            options.push(contactContactInformation.contactInformation);
          }
        }
      }
      return options;
    },
    // pathologist
    isDiagnosisUser: function() {
      return this.isRole("pathologist") || this.isRole("cytotechnician");
    },
    displayedFirstDivider: function() {
      if (this.isActiveAccountingMode || this.isActiveReadOnly || this.parentFileId) {
        return false;
      }
      return this.sectorIsDisplayed || this.laboratoryIsDisplayed;
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import options
    this.importSectorsOptions();
    // await this.importRequestErrorsOptions();
    this.importAdditionalFeeGroupsOptions();

    if (this.editMode) {
      // import data
      await this.importFileData();
      if (!this.isActiveReadOnly) {
        await this.updateInvoicedOrganisation();
      }
      this.getArchives();
    } else {
      // import from parent
      if (this.parentFileIdProp) {
        await this.importFileData(false, true);
      }
      // set default variables
      this.creationDate = this.getIsoDate();
      this.referenceCreationDate = this.creationDate;
    }

    // autofocus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (!this.moduleModeProp) {
        this.$refs.isUrgentInput.focus();
      } else if (this.$refs.viewParentFileButton) {
        this.$refs.viewParentFileButton.focus();
      }
      // if (this.isActiveReadOnly) {
      //   // read only mode
      // } else if (this.parentFileId) {
      //   // coomplementary file
      //   if (!this.isActiveAccountingMode) {
      //     // edit mode : containers
      //   } else {
      //     // read only mode
      //   }
      // } else {
      //   // edit mode
      //   if (!this.isActiveAccountingMode && this.$systemSettings.laboratories.length && this.$systemSettings.laboratories.length > 1) {
      //     // laboratories
      //   } else if (!this.isActiveAccountingMode && this.sectorsOptions.length > 1) {
      //     // sectors
      //   } else {
      //     if (this.patientId === null) {
      //       // patient add button
      //     } else {
      //       // patient edit button
      //     }
      //   }
      // }
    },
    focusAddPatientButton() {
      if (this.$refs.addPatientButton && this.patientIsSearched) {
        this.$refs.addPatientButton.focus();
      }
    },

    // ----- COMPLEMENTARY FILE ----- //
    async addComplementaryFile() {
      try {
        if (this.requestErrors && this.requestErrors.length) {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.cantAddComplementaryWithCurrentErrorTitle")
          });
        } else if (this.isFileEdited) {
          this.$bvModal.show("saveBeforeComplementaryModal");
        } else {
          this.navigate("office/file/new", { parentFileIdProp: this.fileIdProp, accessNumberProp: this.accessNumber });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async saveBeforeComplementaryModalAction(forValidation = false) {
      try {
        this.$bvModal.hide("saveBeforeComplementaryModal");

        let res = false;
        if (forValidation) {
          res = await this.onValidateFile(false);
        } else {
          res = await this.validateForm(false, false) !== false && await this.sendSaveFile(false) !== false;
        }

        if (res) {
          this.navigate("office/file/new", { parentFileIdProp: this.fileIdProp, accessNumberProp: this.accessNumber });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // ----- REQUEST ERRORS ----- //
    onClickRequestErrors() {
      this.areRequestErrorsEdited = true;

      if (this.requestErrors.length) {
        this.requestErrors = [];
      } else {
        this.$bvModal.show("requestErrorsModal");
      }
    },
    onFinishedRequestErrors() {
      this.areRequestErrorsEdited = true;

      this.$bvModal.hide("requestErrorsModal");
    },
    getRequestErrorLocalisedName(requestErrorId) {
      const requestError = this.requestErrorsOptions.find(v => v.id === requestErrorId);
      return requestError ? requestError.localisedName : this.$t("general.emptyWithHyphen");
    },

    // ----- GENERAL FUNCTIONS ----- //
    importSectorsOptions() {
      const sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      this.sectorsOptions = sectorsOptions.map((sector) => {
        sector.isActive = sector.isSubscribed;
        sector.disabled = !sector.isSubscribed;
        return sector;
      });
    },
    importAdditionalFeeGroupsOptions() {
      const additionalFeeGroupsOptions = JSON.parse(JSON.stringify(this.$systemSettings.additionalFeeGroups));
      additionalFeeGroupsOptions.unshift({ id: null, rank: 0, name: this.$t("additionalFeeGroups.defaultGroupName") });
      this.additionalFeeGroupsOptions = additionalFeeGroupsOptions;
    },
    async getArchives() {
      try {
        this.archivesIsLoading = true;
        const filters = [
          {
            documentType: "admission",
            accessNumber: this.accessNumber,
            patientId: null
          },
          {
            documentType: "medicalReport",
            accessNumber: this.accessNumber,
            patientId: null
          }
        ];
        const resArchives = await commonServices.getAll("archives", filters);
        const archives = [];
        this.hasMissingArchives = false;
        for (const documentTypeObj of resArchives.data) {
          for (const interfaceObj of documentTypeObj.results) {
            for (const document of interfaceObj.result) {
              if (document.error && document.error === "unreachable") {
                this.hasMissingArchives = true;
              } else {
                archives.push({
                  documentType: documentTypeObj.documentType,
                  interface: interfaceObj.interface,
                  localName: interfaceObj.localName,
                  ...document
                });
              }
            }
          }
        }
        this.archives = archives;
        this.archivesIsLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async importFileData(refresh = false, createFromParent = false) {
      try {
        if (refresh) {
          // force update
          this.areContainerGroupsEdited = false;
          this.areContactsEdited = false;
          this.displayFileStatusesHistory = false;
          this.$nextTick(() => {
            this.displayFileStatusesHistory = true;
          });
        }

        // get file
        let res;
        if (createFromParent) {
          res = await commonServices.get("files", this.parentFileId);
          this.originalData = null;
        } else {
          res = await commonServices.get("files", this.fileId);
          this.originalData = JSON.parse(JSON.stringify(res.data));
        }

        // statuses
        if (createFromParent) {
          this.fileStatusesHistory = null;
          this.sentMessages = [];
          this.fileModifications = [];
          this.filingStatus = "";
          this.medicalStatus = "";
          this.accountingStatus = "";
        } else {
          this.fileStatusesHistory = res.data.fileStatusesHistory;
          this.sentMessages = res.data.sentMessages || [];
          this.fileModifications = res.data.fileModifications || [];
          this.filingStatus = res.data.filingStatus.name;
          this.medicalStatus = res.data.medicalStatus.name;
          this.accountingStatus = res.data.accountingStatus.name;
        }
        // import data
        this.accessNumber = res.data.accessNumber;
        this.isUrgent = res.data.isUrgent;
        if (createFromParent) {
          this.creationDate = this.getIsoDate();
        } else {
          this.creationDate = res.data.creationDate;
        }
        this.laboratoryId = res.data.laboratoryId;
        this.sectorId = res.data.sectorId;
        if (createFromParent) {
          this.commentsCount = 0;
        } else {
          this.commentsCount = res.data.fileCommentCount || 0;
        }
        // complementaryFiles & groupOfFiles & referenceCreationDate
        if (createFromParent) {
          this.parentFileId = res.data.id;
          this.complementaryFiles = [];
          // groupOfFiles
          let groupOfFiles = [];
          // res.data file is the parent
          if (res.data.childrenFiles && res.data.childrenFiles.length) {
            groupOfFiles = res.data.childrenFiles.map((item) => {
              item.parentFileId = res.data.id;
              return item;
            });
          }
          groupOfFiles.unshift({
            id: res.data.fileId,
            parentFileId: null,
            accessNumber: res.data.accessNumber,
            creationDate: res.data.creationDate,
            filingStatus: res.data.filingStatus || null,
            medicalStatus: res.data.medicalStatus,
            accountingStatus: res.data.accountingStatus || null
          });
          this.groupOfFiles = groupOfFiles;
          this.referenceCreationDate = res.data.creationDate;
        } else {
          this.parentFileId = res.data.parentFileId;
          this.complementaryFiles = res.data.childrenFiles;
          // groupOfFiles
          let groupOfFiles = [];
          if (this.parentFileId) {
            // current file is a complementary
            const resLinkedFiles = await filesServices.getLinkedFiles(this.parentFileId);
            if (resLinkedFiles.data) groupOfFiles = resLinkedFiles.data;
            groupOfFiles.unshift(res.data.parentFile);
            this.referenceCreationDate = res.data.parentFile.creationDate;
          } else {
            // current file is the parent
            if (this.complementaryFiles && this.complementaryFiles.length) {
              groupOfFiles = this.complementaryFiles.map((item) => {
                item.parentFileId = this.fileId;
                return item;
              });
            }
            groupOfFiles.unshift({
              id: this.fileId,
              accessNumber: res.data.accessNumber,
              creationDate: res.data.creationDate,
              filingStatus: res.data.filingStatus || null,
              medicalStatus: res.data.medicalStatus,
              accountingStatus: res.data.accountingStatus || null
            });
            this.referenceCreationDate = res.data.creationDate;
          }
          this.groupOfFiles = groupOfFiles;
        }

        // fileRequestErrors
        if (createFromParent) {
          this.requestErrors = [];
        } else {
          this.requestErrors = res.data.fileRequestErrors ? res.data.fileRequestErrors.map(v => v.requestErrorId) : [];
        }
        // pathologist
        this.pathologistId = res.data.pathologistId;
        this.pathologist = res.data.pathologist;
        // prescribingOrganisation
        this.prescribingOrganisationId = res.data.prescribingOrganisationId;
        this.prescribingOrganisation = res.data.prescribingOrganisation;
        this.forceInvoicePatient = res.data.forceInvoicePatient;
        this.hospitalisationNumber = res.data.hospitalisationNumber;
        // fileContacts
        const fileContactsPrescriber = res.data.fileContacts ? res.data.fileContacts.filter(v => v.isPrescriber) : [];
        this.prescriber = fileContactsPrescriber[0] ? fileContactsPrescriber[0].contact : null;
        this.prescriberHasDownloaded = fileContactsPrescriber[0] ? fileContactsPrescriber[0].downloaded : false;
        this.fileContacts = res.data.fileContacts ? res.data.fileContacts.filter(v => !v.isPrescriber) : [];
        this.contactInformationId = res.data.contactInformationId;
        if (this.filingStatus === "created") {
          this.handleDefaultContactInformationSelection();
        }
        // containerGroups
        let containerGroups = JSON.parse(JSON.stringify(res.data.containerGroups));
        containerGroups = containerGroups.map((item) => {
          item.tempId = item.id;
          if (createFromParent) item.containerGroupTasks = [];
          return item;
        });
        this.containerGroups = containerGroups;
        this.additionalFeeGroupId = res.data.additionalFeeGroupId || null;
        // patient
        this.patientId = res.data.patientId;
        this.patient = null;
        this.patient = res.data.patient;
        // forceHealthCare
        this.forceHealthCareOptions = {
          type: res.data.forceHealthCare ? (res.data.forceHealthCare.type || "screeningCampaign") : "screeningCampaign",
          reference: res.data.forceHealthCare ? (res.data.forceHealthCare.reference || "") : "",
          date: res.data.forceHealthCare ? (res.data.forceHealthCare.date || "") : ""
        };
        this.forceHealthCare = Boolean(res.data.forceHealthCare);
        this.ipp = res.data.ipp;
        // invoicedOrganisation
        this.invoicedOrganisationId = res.data.invoicedOrganisationId;
        this.invoicedOrganisation = res.data.invoicedOrganisation || null;

        // already validatedForDiagnosis
        if (this.filingStatus === "validatedForDiagnosis") {
          this.isActiveAccountingValidation = true;
        }

        // for complementary files, check if the parent file has changed
        if (!createFromParent && this.parentFileId) {
          await this.refreshDataFromParent();
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async refreshDataFromParent() {
      try {
        // get parent
        const res = await commonServices.get("files", this.parentFileId);

        // import data
        this.laboratoryId = res.data.laboratoryId;
        this.sectorId = res.data.sectorId;
        // additionalFeeGroup
        this.additionalFeeGroupId = res.data.additionalFeeGroupId || null;
        // pathologist
        this.pathologistId = res.data.pathologistId;
        this.pathologist = res.data.pathologist;
        // prescribingOrganisation
        this.prescribingOrganisationId = res.data.prescribingOrganisationId;
        this.prescribingOrganisation = res.data.prescribingOrganisation;
        this.forceInvoicePatient = res.data.forceInvoicePatient;
        this.hospitalisationNumber = res.data.hospitalisationNumber;
        // fileContacts
        const fileContactsPrescriber = res.data.fileContacts ? res.data.fileContacts.filter(v => v.isPrescriber) : [];
        this.prescriber = fileContactsPrescriber[0] ? fileContactsPrescriber[0].contact : null;
        this.prescriberHasDownloaded = fileContactsPrescriber[0] ? fileContactsPrescriber[0].downloaded : false;
        this.fileContacts = res.data.fileContacts ? res.data.fileContacts.filter(v => !v.isPrescriber) : [];
        this.contactInformationId = res.data.contactInformationId;
        if (this.filingStatus === "created") {
          this.handleDefaultContactInformationSelection();
        }
        // patient
        this.patientId = res.data.patientId;
        this.patient = null;
        this.patient = res.data.patient;
        // forceHealthCare
        this.forceHealthCareOptions = {
          type: res.data.forceHealthCare ? (res.data.forceHealthCare.type || "screeningCampaign") : "screeningCampaign",
          reference: res.data.forceHealthCare ? (res.data.forceHealthCare.reference || "") : "",
          date: res.data.forceHealthCare ? (res.data.forceHealthCare.date || "") : ""
        };
        this.forceHealthCare = Boolean(res.data.forceHealthCare);
        this.ipp = res.data.ipp;
        // invoicedOrganisation
        this.invoicedOrganisationId = res.data.invoicedOrganisationId;
        this.invoicedOrganisation = res.data.invoicedOrganisation || null;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // go back button
    onGoBack() {
      if (this.cancelRouteProp) {
        navigate(this.cancelRouteProp.name, this.cancelRouteProp.props);
      } else {
        if (this.isDiagnosisUser) {
          navigate("diagnosis/reportEntry");
        } else {
          navigate("office/fileEntry");
        }
      }
    },

    // ----- FIELDS FUNCTIONS ----- //
    // Sector : when changed, update forceInvoicePatient and invoicedOrganisation
    async onSectorChange(sectorId) {
      if (this.sectorId !== sectorId) {
        this.sectorId = sectorId;
        this.filterValidContainerGroups();

        await this.$nextTick();
        this.updateForceInvoicePatient();
        this.updateInvoicedOrganisation();
      }
    },
    // InvoicedOrganisation : on file change, update invoicedOrganisation
    async updateInvoicedOrganisation() {
      try {
        if (this.patient === null) {
          this.invoicedOrganisation = null;
          this.invoicedOrganisationId = null;
        } else {
          const res = await filesServices.getInvoicedOrganisation({
            referenceCreationDate: this.referenceCreationDate,
            forceHealthCare: this.forceHealthCare,
            patientId: (this.patient ? this.patient.id : null),
            filePrescribingOrganisationId: (this.prescribingOrganisation ? this.prescribingOrganisation.id : null),
            fileForceInvoicePatient: this.forceInvoicePatient
          });
          if (res.data.invoicedOrganisation) {
            this.invoicedOrganisation = res.data.invoicedOrganisation;
            this.invoicedOrganisationId = res.data.invoicedOrganisation.id;
          } else {
            this.invoicedOrganisation = null;
            this.invoicedOrganisationId = null;
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // PRESCRIBING-ORGANISATION : Modal
    onOrganisationChosen(event) {
      // update organisation
      this.prescribingOrganisation = event;
      this.prescribingOrganisationId = this.prescribingOrganisation ? this.prescribingOrganisation.id : null;
      // update forceInvoicePatient
      this.updateForceInvoicePatient();
      // update invoiceOrganisation
      this.updateInvoicedOrganisation();
      // activate validation
      this.prescribingOrganisationValidation = true;
      this.areContactsEdited = true;
      // close modal
      this.$bvModal.hide("chooseOrganisationModal");

      this.$nextTick(() => {
        if (this.prescribingOrganisation === null) {
          this.$refs.chooseOrganisationButton.focus();
        } else {
          if (this.forceInvoicePatient !== null) {
            for (const child of this.$refs.forceInvoicePatientRadio.$children) {
              if (child.value === this.forceInvoicePatient) {
                child.focus();
                break;
              }
            }
          } else {
            this.$refs.forceInvoicePatientRadio.$children[0].focus();
          }
        }
      });
    },
    onDeletePrescribingOrganisation() {
      // update organisation
      this.prescribingOrganisation = null;
      this.prescribingOrganisationId = null;
      // update forceInvoicePatient
      this.forceInvoicePatient = null;
      // update invoiceOrganisation
      this.updateInvoicedOrganisation();
      // activate validation
      this.prescribingOrganisationValidation = true;
      this.areContactsEdited = true;

      this.$nextTick(() => {
        this.$refs.chooseOrganisationButton.focus();
      });
    },
    // PRESCRIBING-ORGANISATION : invoicePatient
    getOrganisationInvoicePatient() {
      let organisationInvoicePatient = null;
      // if the sector is chosen and the organisation is setup
      if (this.sectorId && this.prescribingOrganisation && this.prescribingOrganisation.organisationSectors) {
        // get the value of invoicePatient corresponding to the current sector
        for (const organisationSector of this.prescribingOrganisation.organisationSectors) {
          if (organisationSector.sectorId === this.sectorId) {
            organisationInvoicePatient = organisationSector.invoicePatient;
            break;
          }
        }
      }
      return organisationInvoicePatient;
    },
    updateForceInvoicePatient() {
      this.forceInvoicePatient = this.getOrganisationInvoicePatient();
    },

    // PATIENT : Modal
    onChoosePatient() {
      this.$refs.choosePatientModal.show();
      this.$refs.editPatientModal.hide();
    },
    onAddPatient() {
      this.$refs.choosePatientModal.hide();
      this.$refs.editPatientModal.show();
    },
    onEditPatient() {
      this.$refs.choosePatientModal.hide();
      this.$refs.editPatientModal.show();
    },
    async onPatientChosen(patientId) {
      try {
        let patient = null;
        if (patientId) {
          const resServices = await commonServices.get("patients", patientId);
          patient = resServices.data || null;
        }
        this.patient = patient;

        this.patientInvoicedOrganisation = (patient ? (patient.invoicedOrganisation || null) : null);
        await this.updateInvoicedOrganisation();

        this.patientId = null;
        if (patient) {
          this.$nextTick(() => {
            this.patientId = patient.id;
          });
        }

        this.updatePatientFieldsValidation();

        this.$refs.choosePatientModal.hide();
        this.$refs.editPatientModal.hide();
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onDeletePatient() {
      await this.onPatientChosen(null);
    },
    // PATIENT : fields validation
    clearPatientFieldsValidation() {
      this.patientFieldsRequiredErrors = [];
      this.phoneAndEmailOrAddressRequiredError = false;
      this.partialAddressError = false;
      this.patientNameEditionError = false;
    },
    updatePatientFieldsValidation() {
      // setup patient requirements
      const patientBaseDataRequired = this.isActiveDiagnosisValidation || this.isActiveAccountingValidation;
      let patientContactInformationRequired = false;
      let patientRegNumberRequired = false;
      if (this.isActiveAccountingValidation) {
        if (this.invoicedOrganisation !== null && this.invoicedOrganisation.organisationType.name === "healthCare") {
          // healthcare
          patientRegNumberRequired = true;
        } else if (this.invoicedOrganisation === null) {
          // no organisation for this file OR the organisation is invoicing the patient
          patientContactInformationRequired = true;
        } else if (this.invoicedOrganisation !== null) {
          // organisation invoicing itself : no further validation
        }
      }

      // clear errors
      this.clearPatientFieldsValidation();
      // check patient info errors
      if (patientBaseDataRequired) {
        if (!this.patient.sex) this.patientFieldsRequiredErrors.push("sex");
        if (!this.patient.firstName) this.patientFieldsRequiredErrors.push("firstName");
        if (!this.patient.lastName) this.patientFieldsRequiredErrors.push("lastName");
        if (!this.patient.birthdate) this.patientFieldsRequiredErrors.push("birthdateFull");
      }
      if (patientContactInformationRequired) {
        const addressPartiallyFilled = !!this.patient.address1 || !!this.patient.address2 || !!this.patient.zip || !!this.patient.city;
        const isAddressEntered = !!this.patient.address1 && !!this.patient.zip && !!this.patient.city && !!this.patient.country;
        const areEmailPhoneEntered = !!this.patient.email && !!this.patient.phone;

        // phone and email or address required
        if (!isAddressEntered && !areEmailPhoneEntered) {
          this.phoneAndEmailOrAddressRequiredError = true;
        }
        // address partially filed
        if (addressPartiallyFilled && !isAddressEntered) {
          this.partialAddressError = true;
        }
      }
      if (patientRegNumberRequired) {
        if (!this.patient.regNumber && !this.patient.insureeRegNumber) this.patientFieldsRequiredErrors.push("regNumber");
      }

      // If validated for diagnosis, check if the patient was edited
      if (this.filingStatus === "validatedForDiagnosis") {
        if (
          this.originalData.patient.birthdate !== this.patient.birthdate ||
          this.originalData.patient.firstName !== this.patient.firstName ||
          this.originalData.patient.lastName !== this.patient.lastName ||
          this.originalData.patient.sex.id !== this.patient.sex.id
        ) {
          this.patientNameEditionError = true;
        }
      }

      // return true : validation success, false : validation failure
      return !(this.patientFieldsRequiredErrors.length || this.phoneAndEmailOrAddressRequiredError === true || this.partialAddressError === true || this.patientNameEditionError === true);
    },

    // PRESCRIBER
    async onPrescriberChosen(targetContact) {
      try {
        const res = await commonServices.get("contacts", targetContact.id);
        const contact = res.data.id ? res.data : null;

        if (contact) {
          this.prescriber = contact;
          // update selected default contactInformation
          this.handleDefaultContactInformationSelection();
          // activate validation
          this.prescriberValidation = true;
          this.areContactsEdited = true;
        }

        this.$bvModal.hide("choosePrescriberModal" + this._uid);
        await this.$nextTick();
        this.$refs.prescriberDeleteButton.focus();
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onDeletePrescriber() {
      // deletion
      this.prescriber = null;
      this.contactInformationId = null;

      // activate validation
      this.prescriberValidation = true;
      this.areContactsEdited = true;

      this.$refs.choosePrescriberButton.focus();
    },
    // contactInformation
    handleDefaultContactInformationSelection() {
      // reset if no prescriber
      if (!this.prescriber) {
        this.contactInformationId = null;
      } else {
        const defaultContactInformation = this.getDefaultContactInformation();
        if (!this.contactInformationId) {
          // contactInformationId not set : set default
          this.contactInformationId = defaultContactInformation ? defaultContactInformation.id : null;
        } else {
          // contactInformationId is set : reset if invalid contactInformation
          if (this.contactInformationPosition === null) {
            this.contactInformationId = defaultContactInformation ? defaultContactInformation.id : null;
          }
        }
      }
    },
    getDefaultContactInformation() {
      let output = null;
      if (this.prescriber.contactOrganisations) {
        for (const contactOrganisation of this.prescriber.contactOrganisations) {
          if (contactOrganisation.isDefault) {
            output = contactOrganisation.contactInformation;
            break;
          }
        }
      }
      if (!output && this.prescriber.contactContactInformations) {
        for (const contactContactInformation of this.prescriber.contactContactInformations) {
          if (contactContactInformation.isDefault) {
            output = contactContactInformation.contactInformation;
            break;
          }
        }
      }
      return output;
    },
    // FILE-CONTACTS
    async onFileContactChosen(contact) {
      try {
        if (contact) {
          // search if the contact is already added
          const position = this.fileContacts.reduce((pos, value, index) => {
            if (pos !== null) {
              return pos;
            } else {
              return value.contactId === contact.id ? index : null;
            }
          }, null);

          if (position !== null) {
            // if the contact exists : update the contact
            this.fileContacts[position] = {
              fileId: this.fileId,
              contactId: contact.id,
              contact: contact
            };
          } else {
            // if the contact is new : add the contact
            this.fileContacts.push({
              fileId: this.fileId,
              contactId: contact.id,
              contact: contact
            });
          }
          // activate validation
          this.fileContactsValidation = true;
          this.areContactsEdited = true;
        }

        this.$bvModal.hide("chooseContactModal" + this._uid);
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onDeleteFileContact(targetContactId) {
      // search for the deleted contact
      for (let i = this.fileContacts.length - 1; i >= 0; i--) {
        if (this.fileContacts[i].contactId === targetContactId) {
          // deletion
          this.fileContacts.splice(i, 1);

          // activate validation
          this.fileContactsValidation = true;
          this.areContactsEdited = true;

          this.$refs.addContactButton.focus();

          break;
        }
      }
    },

    // ----- CONTAINER GROUPS ----- //
    onContainerGroupsUpdated($event) {
      this.containerGroups = $event;
      this.updateContainerGroupsEdited();
    },
    updateContainerGroupsEdited() {
      let edited = false;
      if (!this.originalData) {
        edited = true;
      } else if (this.originalData.containerGroups.length === 0 && (!this.containerGroups || this.containerGroups.length === 0)) {
        // stay false
      } else if (this.originalData.containerGroups.length !== this.containerGroups.length) {
        edited = true;
      } else {
        for (let i = 0; i < this.originalData.containerGroups.length; i++) {
          if (!this.checkIdenticalContainerGroups(this.originalData.containerGroups[i], this.containerGroups[i])) {
            edited = true;
            break;
          }
        }
      }
      this.areContainerGroupsEdited = edited;
    },
    checkIdenticalContainerGroups(group1, group2) {
      return (
        group1.collectionDate !== group2.collectionDate &&
        this.checkIdenticalContainerGroupTasks(group1.containerGroupTasks, group2.containerGroupTasks) &&
        this.checkIdenticalContainers(group1.containers, group2.containers)
      );
    },
    checkIdenticalContainerGroupTasks(groupTask1, groupTask2) {
      if (groupTask1.length !== groupTask2.length) return false;
      for (let i = 0; i < groupTask1.length; i++) {
        if (groupTask1[i].taskId !== groupTask2[i].taskId) return false;
      }
      return true;
    },
    checkIdenticalContainers(container1, container2) {
      if (container1.length !== container2.length) return false;
      for (let i = 0; i < container1.length; i++) {
        if (container1[i].sampleId !== container2[i].sampleId || container1[i].containerTypeId !== container2[i].containerTypeId) return false;
      }
      return true;
    },
    async importSavedcontainerGroups(savedGroups) {
      if (!savedGroups) return false;

      await this.$nextTick();
      savedGroups.map((group) => {
        group.collectionDate = this.getIsoDate();
      });
      this.containerGroups = savedGroups;
      this.filterValidContainerGroups();
      this.updateContainerGroupsEdited();
    },
    filterValidContainerGroups() {
      let containerGroups = JSON.parse(JSON.stringify(this.containerGroups));

      if (this.sectorId) {
        // if cytology and multiple containergroups, only allow one containerGroup
        if (this.sectorId === 1 && containerGroups.length > 0) {
          containerGroups = [containerGroups[0]];
        }
        // remove invalid containers / tasks
        for (const containerGroup of containerGroups) {
          for (let j = containerGroup.containers.length - 1; j >= 0; j--) {
            if (containerGroup.containers[j].sample) {
              const filteredSampleSectors = containerGroup.containers[j].sample.sampleSectors.filter((v) => v.sectorId === this.sectorId);
              if (filteredSampleSectors.length === 0) {
                containerGroup.containers.splice(j, 1);
              }
              if (
                containerGroup.containers[j].containerType.sectorId !== this.sectorId ||
                containerGroup.containers[j].containerType.laboratoryId !== this.laboratoryId
              ) {
                containerGroup.containers[j].containerType = null;
                containerGroup.containers[j].containerTypeId = null;
              }
            }
          }
          for (let j = containerGroup.containerGroupTasks.length - 1; j >= 0; j--) {
            if (containerGroup.containerGroupTasks[j].task.sectorId !== this.sectorId) {
              containerGroup.containerGroupTasks.splice(j, 1);
            }
          }
        }
      }
      this.containerGroups = containerGroups;
    },

    // ----- PATHOLOGIST ----- //
    suggestionFormatPathologists(suggestion) {
      if (!suggestion) return "";
      let output = suggestion.user.firstName + " " + suggestion.user.lastName + " (" + suggestion.code + ")";
      if (suggestion.user.isActive !== undefined && suggestion.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },
    async onPathologistUpdated(pathologistId) {
      if (!pathologistId) {
        this.pathologistId = null;
        this.pathologist = null;
        this.pathologistValidation = true;
        return false;
      }

      const res = await commonServices.get("pathologists", pathologistId);
      this.pathologistId = res.data.id;
      this.pathologist = res.data;
      this.pathologistValidation = true;
    },

    // ----- SAVE FUNCTIONS ----- //
    resetFormValidation() {
      this.isActiveDiagnosisValidation = false;
      this.isActiveAccountingValidation = false;

      this.laboratoryIdValidation = false;
      this.sectorIdValidation = false;
      this.prescribingOrganisationValidation = false;
      this.forceInvoicePatientValidation = false;
      this.hospitalisationNumberValidation = false;
      this.invoicedOrganisationValidation = false;
      this.prescriberValidation = false;
      this.fileContactsValidation = false;
      this.pathologistValidation = false;
      this.patientValidation = false;
      this.forceHealthCareValidation = false;
      this.ippValidation = false;
      this.containerGroupsValidation = false;
      this.additionalFeeGroupValidation = false;
    },
    async validateForm(diagnosisValidation = false, accountingValidation = false) {
      // set up validation variables for computed reative rules (cf. patient)
      this.isActiveDiagnosisValidation = diagnosisValidation || accountingValidation;
      this.isActiveAccountingValidation = accountingValidation;

      // required activation
      this.laboratoryIdRequired = true;
      this.sectorIdRequired = true;
      this.prescribingOrganisationRequired = false;
      this.forceInvoicePatientRequired = accountingValidation;
      this.hospitalisationNumberRequired = accountingValidation;
      this.invoicedOrganisationRequired = false;
      this.prescriberRequired = diagnosisValidation || accountingValidation;
      this.fileContactsRequired = diagnosisValidation || accountingValidation;
      this.pathologistRequired = diagnosisValidation || accountingValidation;
      this.patientRequired = true;
      this.forceHealthCareRequired = false;
      this.ippRequired = false;
      this.containerGroupsRequired = diagnosisValidation || accountingValidation;
      this.additionalFeeGroupRequired = diagnosisValidation || accountingValidation;
      if (this.debugMode) console.log("111 VALIDATION changed required", diagnosisValidation || accountingValidation);

      // validation activation
      this.laboratoryIdValidation = true;
      this.sectorIdValidation = true;
      this.prescribingOrganisationValidation = true;
      this.forceInvoicePatientValidation = true;
      this.hospitalisationNumberValidation = true;
      this.invoicedOrganisationValidation = true;
      this.prescriberValidation = true;
      this.fileContactsValidation = true;
      this.pathologistValidation = true;
      this.patientValidation = false; // force refresh patient validation
      this.patientValidation = true;
      this.forceHealthCareValidation = true;
      this.ippValidation = true;
      this.containerGroupsValidation = false; // force refresh containerGroups validation
      this.containerGroupsValidation = true;
      this.additionalFeeGroupValidation = true;

      await this.$nextTick();
      if (this.debugMode) console.log("111 VALIDATION containerGroupsState", this.containerGroupsState);
      return !(
        this.laboratoryIdState === false ||
        this.sectorIdState === false ||
        this.prescribingOrganisationState === false ||
        this.forceInvoicePatientState === false ||
        this.hospitalisationNumberState === false ||
        this.prescriberState === false ||
        this.fileContactsState === false ||
        this.pathologistState === false ||
        this.patientState === false ||
        this.forceHealthCareState === false ||
        this.ippState === false ||
        this.containerGroupsState === false ||
        this.additionalFeeGroupState === false
      );
    },
    async onSaveFile(displaySaveSuccess = true) {
      try {
        // validate form fields
        if (await this.validateForm(false, false) === false) return false;

        const res = await this.sendSaveFile(displaySaveSuccess);
        if (res) {
          if (this.moduleModeProp) {
            this.$emit("fileCreated", res.data.id);
          } else if (this.$route.name !== "office/file/edit") {
            this.navigate("office/file/edit", { fileIdProp: this.fileId });
          }
        }
        return res;
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async saveBeforeCreationComments(fileId) {
      try {
        if (this.editMode === true || !this.commentsBeforeCreation || this.commentsBeforeCreation.length === 0) {
          return true;
        }

        // save comments
        let res;
        for (const comment of this.commentsBeforeCreation) {
          res = await commonServices.post(
            "fileComments",
            {
              fileId: fileId,
              userId: this.$userSettings.user.id,
              content: comment.content
            }
          );
          // comments error
          if (!res.data.id) {
            this.$emit("alert", "danger", {
              title: this.$t("fileComments.notifications.saveCommentsErrorTitle"),
              message: this.$t("fileComments.notifications.saveCommentsErrorText")
            });
            return false;
          }
        }

        return true;
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async sendSaveFile(displaySuccess = true) {
      try {
        // saving with no change (file already created) = success
        if (!this.isFileEdited) {
          if (displaySuccess) {
            this.$emit("alert", "success", {
              title: this.$t("fileEdit.notifications.savedTitle"),
              message: this.$t("fileEdit.notifications.savedText")
            });
          }
          return true;
        }

        // setup form
        const filteredFileContacts = this.fileContacts.map((fileContact) => {
          return {
            contactId: fileContact.contactId,
            isPrescriber: false,
            downloaded: fileContact.downloaded || false
          };
        });
        if (this.prescriber) {
          filteredFileContacts.push({
            contactId: this.prescriber.id,
            isPrescriber: true,
            downloaded: this.prescriberHasDownloaded
          });
        }
        const filteredContainerGroups = this.containerGroups.map(({ collectionDate, containers, containerGroupTasks }) => {
          return {
            collectionDate: collectionDate || null,
            containers: containers.map(({ sampleId, containerTypeId }) => {
              return {
                sampleId,
                containerTypeId
              };
            }).filter((container) => container.sampleId !== null),
            containerGroupTasks: containerGroupTasks.map(({ number, taskId }) => {
              return {
                number,
                taskId
              };
            })
          };
        });
        const filteredFileRequestErrors = this.requestErrors.map((requestErrorId) => {
          return { requestErrorId: requestErrorId };
        });
        // forceHealthCare
        let forceHealthCareFormatted = null;
        if (this.forceHealthCare) {
          if (this.forceHealthCareOptions.type === "screeningCampaign") {
            forceHealthCareFormatted = {
              type: this.forceHealthCareOptions.type
            };
          } else if (this.forceHealthCareOptions.type === "accident") {
            forceHealthCareFormatted = {
              type: this.forceHealthCareOptions.type,
              reference: this.forceHealthCareOptions.reference,
              date: this.forceHealthCareOptions.date
            };
          } else if (this.forceHealthCareOptions.type === "pregnancy") {
            forceHealthCareFormatted = {
              type: this.forceHealthCareOptions.type,
              date: this.forceHealthCareOptions.date
            };
          }
        }
        const form = {
          parentFileId: this.parentFileId,
          referenceCreationDate: this.referenceCreationDate,
          accessNumber: this.accessNumber,
          isUrgent: this.isUrgent,
          laboratoryId: this.laboratoryId,
          sectorId: this.sectorId,

          patientId: this.patientId,
          forceHealthCare: forceHealthCareFormatted,
          ipp: this.ipp,
          sendToPatient: this.willErrorMessageBeSent === "patient",
          sendToPrescriber: this.willErrorMessageBeSent === "prescriber",

          prescribingOrganisationId: this.prescribingOrganisationId,
          forceInvoicePatient: this.forceInvoicePatient,
          hospitalisationNumber: this.prescribingOrganisationId ? this.hospitalisationNumber : "",

          fileContacts: filteredFileContacts,
          contactInformationId: this.contactInformationId,
          containerGroups: filteredContainerGroups,
          additionalFeeGroupId: this.additionalFeeGroupId,

          pathologistId: this.pathologistId,

          fileRequestErrors: filteredFileRequestErrors
        };

        // send file
        if (this.editMode) {
          // edit file
          const res = await commonServices.put("files", form, this.fileId);
          if (res.data === true) {
            // success
            if (displaySuccess) {
              this.$emit("alert", "success", {
                title: this.$t("fileEdit.notifications.savedTitle"),
                message: this.$t("fileEdit.notifications.savedText")
              });
            }
            // update component
            await this.importFileData(true);
            this.containerGroupsValidation = false;
            return true;
          } else {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("fileEdit.notifications.editionErrorTitle"),
              message: this.$t("fileEdit.notifications.editionErrorText")
            });
            return false;
          }
        } else {
          // new file
          const res = await commonServices.post("files", form);
          if (res.data.id) {
            // save comments
            const commentsSaved = await this.saveBeforeCreationComments(res.data.id);
            if (!commentsSaved) {
              return false;
            }
            // success
            if (displaySuccess) {
              this.$emit("alert", "success", {
                title: this.$t("fileEdit.notifications.savedTitle"),
                message: this.$t("fileEdit.notifications.savedText")
              });
            }
            // update component
            this.fileId = res.data.id;
            this.editMode = true;
            this.containerGroupsValidation = false;
            await this.importFileData(true);
            return true;
          } else if (res.data.error && res.data.error === "alreadyCreated") {
            // alreadyCreated
            this.fileId = res.data.args.fileId;
            this.$bvModal.show("alreadyCreatedModal-" + this._uid);
            return false;
          } else if (res.data.error && res.data.error === "accessNumberNotMatchRegex") {
            // accessNumberNotMatchRegex
            this.$emit("alert", "danger", {
              title: this.$t("fileEdit.notifications.accessNumberNotMatchRegexTitle"),
              message: this.$t("fileEdit.notifications.accessNumberNotMatchRegexText")
            });
            return false;
          } else {
            // error
            this.$emit("alert", "danger", {
              title: this.$t("fileEdit.notifications.additionErrorTitle"),
              message: this.$t("fileEdit.notifications.additionErrorText")
            });
            return false;
          }
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async onValidateFile(redirectAfterwards = true) {
      try {
        // validate form fields
        let canValidate = "";
        if (await this.validateForm(true, true)) {
          // can validate for Accounting
          if (this.debugMode) console.log("can validate for Accounting");
          canValidate = "accounting";
        } else if (this.filingStatus !== "validatedForDiagnosis" && await this.validateForm(true, false)) {
          // can validate for Diagnosis
          if (this.debugMode) console.log("can validate for Diagnosis");
          canValidate = "diagnosis";
        } else {
          // can't validate
          if (this.debugMode) console.log("can not be Validated");
          await this.validateForm(true, !this.isDiagnosisUser);
          return false;
        }

        // save file first if necessary
        if (this.isFileEdited || this.filingStatus === "") {
          if (this.debugMode) console.log("need to be saved first");
          // check if we can save
          if (await this.validateForm(false, false)) {
            if (this.debugMode) console.log("can save : fields validation passed");
            // save
            if (!await this.sendSaveFile(false)) {
              if (this.debugMode) console.log("saving failed : backend feedback is displayed");
              // error
              await this.validateForm(true, !this.isDiagnosisUser);
              return false;
            }
            if (this.debugMode) console.log("success save");
          } else {
            if (this.debugMode) console.log("can not be saved : fields validation failed");
            // can't save
            await this.validateForm(true, !this.isDiagnosisUser);
            return false;
          }
        }

        // validate file
        if (canValidate === "accounting") {
          // validate for Accounting
          if (this.debugMode) console.log("send validate for Accounting");
          return await this.sendValidateForAccountingFile(redirectAfterwards);
        } else if (canValidate === "diagnosis") {
          // validate for Diagnosis
          if (this.isDiagnosisUser) {
            // for Diagnosis users
            if (this.debugMode) console.log("send validate for Diagnosis");
            return await this.sendValidateForDiagnosisFile(false);
          } else {
            // others
            if (this.debugMode) console.log("open validateForDiagnosis Popup");
            return await this.validateForDiagnosisPopup(redirectAfterwards);
          }
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async validateForDiagnosisPopup() {
      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("fileEdit.validateForDiagnosisPopup.text"), {
        title: this.$t("fileEdit.validateForDiagnosisPopup.title"),
        okVariant: this.$systemSettings.theme,
        okTitle: this.$t("fileEdit.validateForDiagnosisPopup.ok"),
        cancelTitle: this.$t("fileEdit.validateForDiagnosisPopup.cancel"),
        centered: true
      });
      if (result) {
        this.sendValidateForDiagnosisFile();
      } else {
        await this.validateForm(true, true);
      }
    },
    async sendValidateForDiagnosisFile(redirectAfterwards = true) {
      try {
        const res = await filesServices.validateForDiagnosis(this.fileId);
        if (res.data === true) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("fileEdit.notifications.validatedForDiagnosisTitle"),
            message: this.$t("fileEdit.notifications.validatedForDiagnosisText")
          });
          if (this.moduleModeProp) {
            this.$emit("fileValidated", this.fileId);
          } else {
            if (redirectAfterwards) {
              if (this.isDiagnosisUser) {
                navigate("diagnosis/reportEntry");
              } else {
                navigate("office/fileEntry");
              }
            }
          }
          return true;
        } else if (res.data.error === "parentFileNotValidatedForDiagnosis") {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.parentFileNotValidatedForDiagnosisTitle"),
            message: this.$t("fileEdit.notifications.parentFileNotValidatedForDiagnosisText")
          });
          return false;
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.validationForDiagnosisErrorTitle"),
            message: this.$t("fileEdit.notifications.validationForDiagnosisErrorText")
          });
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async sendValidateForAccountingFile(redirectAfterwards = true) {
      try {
        const res = await filesServices.validateForAccounting(this.fileId);
        if (res.data === true) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("fileEdit.notifications.validatedTitle"),
            message: this.$t("fileEdit.notifications.validatedText")
          });
          if (this.moduleModeProp) {
            this.$emit("fileValidated", this.fileId);
          } else {
            if (redirectAfterwards) {
              if (this.isDiagnosisUser) {
                navigate("diagnosis/reportEntry");
              } else {
                navigate("office/fileEntry");
              }
            }
          }
          return true;
        } else if (res.data.error === "parentFileNotValidatedForAccounting") {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.parentFileNotValidatedForAccountingTitle"),
            message: this.$t("fileEdit.notifications.parentFileNotValidatedForAccountingText")
          });
          return false;
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.validationErrorTitle"),
            message: this.$t("fileEdit.notifications.validationErrorText")
          });
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async invalidate(targetStatus) {
      try {
        // check if complementaries are validated medically
        if (targetStatus === "created") {
          if (this.complementaryFiles && this.complementaryFiles.length) {
            let validatedChildrenCount = 0;
            for (const complementaryFile of this.complementaryFiles) {
              if (complementaryFile.medicalStatus.name === "validatedMedically") {
                validatedChildrenCount++;
              }
            }
            if (validatedChildrenCount) {
              this.$emit("alert", "danger", {
                title: this.$tc("fileEdit.notifications.childValidatedMedicallyErrorTitle", validatedChildrenCount, { count: validatedChildrenCount }),
                message: this.$tc("fileEdit.notifications.childValidatedMedicallyErrorText", validatedChildrenCount, { count: validatedChildrenCount })
              });
              return false;
            }
          }
        }

        // send invalidate
        const res = await filesServices.invalidate(this.fileId, targetStatus);
        if (res.data === true) {
          // success
          if (targetStatus === "validatedForDiagnosis") {
            this.$emit("alert", "success", {
              title: this.$t("fileEdit.notifications.invalidatedIntoDiagnosisTitle"),
              message: this.$t("fileEdit.notifications.invalidatedIntoDiagnosisText")
            });
          } else {
            this.$emit("alert", "success", {
              title: this.$t("fileEdit.notifications.invalidatedTitle"),
              message: this.$t("fileEdit.notifications.invalidatedText")
            });
          }
          this.resetFormValidation();
          await this.importFileData(true);
          await this.updateInvoicedOrganisation();
          return true;
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.invalidationErrorTitle"),
            message: this.$t("fileEdit.notifications.invalidationErrorText")
          });
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    async cancelFile() {
      try {
        const res = await filesServices.cancel(this.fileId);
        if (res.data === true) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("fileEdit.notifications.cancelledTitle"),
            message: this.$t("fileEdit.notifications.cancelledText")
          });
          this.resetFormValidation();
          this.importFileData(true);
          return true;
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("fileEdit.notifications.cancelationErrorTitle"),
            message: this.$t("fileEdit.notifications.cancelationErrorText")
          });
          return false;
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    // async uncancelFile() {
    //   try {
    //     const res = await filesServices.uncancel(this.fileId);
    //     if (res.data === true) {
    //       // success
    //       this.$emit("alert", "success", {
    //         title: this.$t("fileEdit.notifications.uncancelledTitle"),
    //         message: this.$t("fileEdit.notifications.uncancelledText")
    //       });
    //       this.resetFormValidation();
    //       this.importFileData(true);
    //       return true;
    //     } else {
    //       // error
    //       this.$emit("alert", "danger", {
    //         title: this.$t("fileEdit.notifications.uncancelationErrorTitle"),
    //         message: this.$t("fileEdit.notifications.uncancelationErrorText")
    //       });
    //       return false;
    //     }
    //   } catch (err) {
    //     this.handleErrors(err);
    //     return false;
    //   }
    // },
    async onIsUrgentChange(isUrgent) {
      try {
        if (this.editMode) {
          this.isUrgentLoading = true;
          this.isUrgentSuccessIconDisplay = false;

          const res = await filesServices.setIsUrgent(this.fileId, isUrgent);
          if (res.data === true) {
            // success
            this.isUrgentLoading = false;
            this.isUrgentSuccessIconDisplay = true;
            setTimeout(() => { this.isUrgentSuccessIconDisplay = false; }, 1500);
          } else {
            // error
            this.isUrgentLoading = false;
            this.$nextTick(() => {
              this.isUrgent = !isUrgent;
            });
            this.$emit("alert", "danger", {
              title: this.$t("fileEdit.notifications.isUrgentErrorTitle"),
              message: this.$t("fileEdit.notifications.isUrgentErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
        return false;
      }
    },
    // MedicalReport
    async downloadMedicalReport() {
      try {
        // all files must be validated
        if (!this.isReadyMedicalReport) {
          this.$emit("alert", "warning", {
            title: this.$t("files.notifications.validateAllFilesBeforeDownloadTitle"),
            message: this.$t("files.notifications.validateAllFilesBeforeDownloadText")
          });
          return false;
        }

        this.isDownloading = true;

        const res = await cytologyReportsServices.getAllByFileId(this.parentFileId || this.fileId);
        const reports = res.data.cytologyReports || [];

        for (const report of reports) {
          if (report.isSuperseded === false) {
            const resFile = await commonServices.export("cytologyReports", report.id, "pdf");
            if (resFile.data.type === "application/json") {
              this.$emit("alert", "danger", {
                title: this.$t("general.downloadFileErrorTitle"),
                message: this.$t("general.downloadFileErrorText")
              });
            } else {
              fileSaver.saveAs(resFile.data, this.accessNumber + ".pdf");
            }
            this.isDownloading = false;
            return true;
          }
        }

        // no report found
        this.$emit("alert", "danger", {
          title: this.$t("files.notifications.medicalReportNotFound")
        });
        this.isDownloading = false;
      } catch (err) {
        this.isDownloading = false;
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./FileEdit.scss" lang="scss"></style>

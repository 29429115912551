export default {
  props: {
    // identify if used as a small module or as a full page
    moduleModeProp: {
      type: Boolean
    },
    // for smaller margins between sections
    smallMarginsProp: {
      type: [Boolean, String],
      default: null
    }
  },
  data() {
    return {

    };
  },
  computed: {
    // set up the small-margins variable
    sectionMarginsClass: function() {
      if (this.moduleModeProp) {
        // if not set, default true for moduleMode
        if (this.smallMarginsProp === null) {
          return "kt-view-page__section--smallMargins";
        } else {
          return this.smallMarginsProp ? "kt-view-page__section--smallMargins" : "";
        }
      } else {
        // if not set, default false for full page
        if (this.smallMarginsProp) {
          return "kt-view-page__section--smallMargins";
        } else {
          return "";
        }
      }
    }
  },
  watch: {
    // get the displayedFieldsProp on load/change and set up the variables
    displayedFieldsProp: {
      handler(value) {
        this.displayedFieldInit(value);
      },
      immediate: true
    }
  },
  methods: {
    displayedFieldInit(displayedFields) {
      for (var key in displayedFields) {
        if (typeof displayedFields[key] === "object") {
          // object value
          this[displayedFields[key].name + "Display"] = true;
        } else if (typeof displayedFields[key] === "string") {
          // string value
          this[displayedFields[key] + "Display"] = true;
        }
      }
    }
  }
};

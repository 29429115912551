export default {
  methods: {
    // format phone to 0605040302 for field display
    importFormatPhone(phone) {
      var formattedPhone = "";
      if (/^\+33\d{9}$/.test(phone)) {
        formattedPhone = "0" + phone.substring(3);
      } else if (/^0\d{9}$/.test(phone)) {
        formattedPhone = phone;
      } else if (/^\d{9}$/.test(phone)) {
        formattedPhone = "0" + phone;
      }
      return formattedPhone;
    },
    // format phone to +33 for sending the file
    exportFormatPhone(phone) {
      var formattedPhone = "";
      if (/^0\d{9}$/.test(phone)) {
        formattedPhone = "+33" + phone.substring(1);
      } else if (/^\d{9}$/.test(phone)) {
        formattedPhone = "+33" + phone;
      } else if (/^\+33\d{9}$/.test(phone)) {
        formattedPhone = phone;
      }
      return formattedPhone;
    }
  }
};

<template>
  <div class="kt-paymentReport-paymentLines-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ $t("payments.paymentReportsPaymentLinesEdit.pageTitle") }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-3 pb-1"></div>

    <!-- report and group headers -->
    <div class="kt-view-page__body pb-1">
      <!-- divided inforation -->
      <div class="kt-divided-information">
        <div class="kt-divided-information__wrapper">
          <!-- paymentReport info -->
          <div class="kt-divided-information__section">
            <div class="kt-divided-information__section-content font-weight-semi-bold">
              {{ $t("payments.paymentReportsPaymentLinesEdit.paymentReportSectionTitle") }}
            </div>
            <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
              <span class="mr-3">{{ organisation ? organisation.name + " (" + organisation.code + ")" + (organisation.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : "" }}</span>{{ reference }}
            </div>
          </div>
          <!-- paid -->
          <div class="kt-divided-information__section kt-divided-information__section--amount border-left">
            <div class="kt-divided-information__section-title">
              {{ $t('payments.paymentReports.paid') }}
            </div>
            <div
              class="kt-divided-information__section-content kt-divided-information__color-secondary"
              :class="{'text-danger': !paymentReportPaid}"
            >
              {{ $n((paymentReportPaid || 0), "currency") }}
            </div>
          </div>
          <!-- reconciled -->
          <div class="kt-divided-information__section kt-divided-information__section--progress border-left">
            <div class="kt-divided-information__section-title">
              {{ $t('payments.paymentReports.reconciled') }}
            </div>
            <!-- <div class="kt-divided-information__section-title">
              {{ $t('payments.paymentReports.remaining') }}
            </div> -->
            <!-- reconciled progress bar -->
            <ProgressBar
              :valueProp="paymentReportReconciled"
              :totalProp="paymentReportPaid"
            />
          </div>
          <!-- actions -->
          <div class="kt-divided-information__section kt-divided-information__section--actions border-left">
            <!-- option drop-down -->
            <div
              v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toPdf']) ||
                isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toXlsx'])"
              class="kt-dropdown-btn kt-dropdown-btn--no-arrow"
            >
              <!-- variant="kt-dropdown-btn--success" -->
              <b-button
                variant="outline-secondary"
                size="sm"
                class="btn-icon kt-dropdown-btn__switcher-btn"
                pill
              >
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
              <ul
                class="kt-dropdown-btn__list"
              >
                <li class="kt-dropdown-btn__shadow"></li>

                <!-- export paymentReport PDF -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toPdf'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onExportPaymentReport('pdf')"
                  >
                    <b-icon
                      icon="download"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReports.downloadPaymentReportPdf") }}
                  </button>
                </li>
                <!-- export paymentReport XLS -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'toXlsx'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onExportPaymentReport('xlsx')"
                  >
                    <b-icon
                      icon="table"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReports.downloadPaymentReportXlsx") }}
                  </button>
                </li>

                <!-- <li class="kt-dropdown-btn__divider"></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- divider -->
      <div class="w-100 mt-2"></div>

      <!-- divided inforation -->
      <div class="kt-divided-information">
        <div class="kt-divided-information__wrapper">
          <!-- paymentGroup info -->
          <div class="kt-divided-information__section">
            <div class="kt-divided-information__section-content font-weight-semi-bold">
              {{ $t("payments.paymentReportsPaymentLinesEdit.paymentGroupSectionTitle") }}
            </div>
            <div class="kt-divided-information__section-content kt-divided-information__color-secondary">
              {{ pathologist ? pathologist.user.firstName + " " + pathologist.user.lastName + " (" + pathologist.code + ")" + (pathologist.user.isActive === false ? " (" + $t("general.deletedLabel") + ")" : "") : "" }}
            </div>
          </div>
          <!-- paid -->
          <div class="kt-divided-information__section kt-divided-information__section--amount border-left">
            <div class="kt-divided-information__section-title">
              {{ $t('payments.paymentReports.paid') }}
            </div>
            <div
              class="kt-divided-information__section-content kt-divided-information__color-secondary"
              :class="{'text-danger': !paid}"
            >
              {{ $n((paid || 0), "currency") }}
            </div>
          </div>
          <!-- reconciled -->
          <div class="kt-divided-information__section kt-divided-information__section--progress border-left">
            <div class="kt-divided-information__section-title">
              {{ $t('payments.paymentReports.reconciled') }}
            </div>
            <!-- <div class="kt-divided-information__section-title">
              {{ $t('payments.paymentReports.remaining') }}
            </div> -->
            <!-- reconciled progress bar -->
            <ProgressBar
              :valueProp="reconciled"
              :totalProp="paid"
            />
          </div>
          <!-- actions -->
          <div class="kt-divided-information__section kt-divided-information__section--actions border-left">
            <!-- option drop-down -->
            <div
              v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'deleteGroup']) ||
                isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'GroupToPdf']) ||
                isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'GroupToXlsx'])"
              class="kt-dropdown-btn kt-dropdown-btn--no-arrow"
            >
              <!-- variant="kt-dropdown-btn--success" -->
              <b-button
                variant="outline-secondary"
                size="sm"
                class="btn-icon kt-dropdown-btn__switcher-btn"
                pill
              >
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
              <ul class="kt-dropdown-btn__list">
                <li class="kt-dropdown-btn__shadow"></li>

                <!-- export paymentGroup PDF -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'groupToPdf'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onExportPaymentGroup('pdf')"
                  >
                    <b-icon
                      icon="download"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReportsPaymentGroupsEdit.downloadPaymentGroupPdf") }}
                  </button>
                </li>
                <!-- export paymentGroup XLSX -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'groupToXlsx'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button"
                    @click="onExportPaymentGroup('xlsx')"
                  >
                    <b-icon
                      icon="table"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReportsPaymentGroupsEdit.downloadPaymentGroupXlsx") }}
                  </button>
                </li>
                <!-- delete paymentGroup -->
                <li
                  v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'deleteGroup'])"
                  class="kt-dropdown-btn__item"
                >
                  <button
                    class="kt-dropdown-btn__button text-danger"
                    @click="onDeletePaymentGroup"
                  >
                    <b-icon
                      icon="trash"
                      class="kt-dropdown-btn__icon"
                    ></b-icon>
                    {{ $t("payments.paymentReportsPaymentGroupsEdit.deletePaymentGroup") }}
                  </button>
                </li>

                <!-- <li class="kt-dropdown-btn__divider"></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-3 mb-3"></div>

    <!-- table titles -->
    <b-row class="mb-1">
      <!-- paymentLines title -->
      <b-col
        cols="6"
        class="mb-3"
      >
        <h2
          class="kt-page-header__title h4"
          style="margin-bottom: 2px !important;"
        >
          {{ $t("payments.paymentReportsPaymentLinesEdit.tableTitle") }}
        </h2>
        <!-- switch to view button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'viewLines'])"
          ref="viewPaymentsButton"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === null ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode(null)"
        >
          <b-icon icon="list"></b-icon>
          {{ $t("payments.paymentReportsPaymentLinesEdit.viewPaymentLineButton") }}
          <span class="kt-count-bubble"><span class="kt-count-bubble__number">{{ filePaymentCount }}</span></span>
        </b-button>
        <!-- switch to add button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'addLine'])"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === 'add' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode('add')"
        >
          <b-icon icon="plus"></b-icon>
          {{ $t("payments.paymentReportsPaymentLinesEdit.addPaymentLineButton") }}
        </b-button>
      </b-col>
      <!-- unknownPaymentLines title -->
      <b-col
        cols="6"
        class="mb-3"
      >
        <h2
          class="kt-page-header__title h4"
          style="margin-bottom: 2px !important;"
        >
          {{ $t("payments.paymentReportsPaymentLinesEdit.unknownTableTitle") }}
        </h2>
        <!-- switch to view button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'viewLines'])"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === 'unknown' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="changeCurrentActiveMode('unknown')"
        >
          <b-icon icon="list"></b-icon>
          {{ $t("payments.paymentReportsPaymentLinesEdit.viewUnknownPaymentLineButton") }}
          <span class="kt-count-bubble"><span class="kt-count-bubble__number">{{ unknownPaymentCount }}</span></span>
        </b-button>
        <!-- switch to add button -->
        <b-button
          v-show="isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'addLine'])"
          class="ml-3"
          size="sm"
          :variant="currentActiveMode === 'unknownAdd' ? $systemSettings.theme : 'outline-' + $systemSettings.theme"
          @click="onUnknownLineAdd"
        >
          <b-icon icon="plus"></b-icon>
          {{ $t("payments.paymentReportsPaymentLinesEdit.unknownAddPaymentLineButton") }}
        </b-button>
      </b-col>
    </b-row>

    <!-- lines table -->
    <b-table
      v-show="currentActiveMode !== 'unknownAdd' && editedUnknownId === null"
      ref="linesTable"
      class="kt-table"
      tbody-tr-class="kt-paymentLine"
      style="margin-bottom: 2px !important;"
      responsive
      :fields="currentActiveMode === 'unknown' ? unknownFields : tableFields"
      :items="lines"
      :busy="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact="focusFirstLine"
        >
          <!-- filter HospitalisationNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="searchHospitalisationNumber"
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter LastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter FirstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter AccessNumber -->
          <b-th
            v-show="currentActiveMode === null || currentActiveMode === 'add'"
            class="kt-table__th-search"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
              @keydown.tab.exact="(e) => {
                if (currentActiveMode === null) {
                  e.preventDefault();
                  lastSearchTabKey();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- CollectionDates / Paid -->
          <b-th class="kt-table__th-search">
          </b-th>
          <!-- Acts / filter Comment -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-show="currentActiveMode === 'unknown'"
              v-model="filterComment"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @input="onFilterChange"
              @keydown.enter.exact="submitSearchInput"
              @keydown.tab.exact.prevent="lastSearchTabKey"
            ></b-form-input>
            <b-button
              v-show="filterComment !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterComment')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            :style="{width: (currentActiveMode === 'add' ? '110px' : (currentActiveMode === null ? '39px' : (currentActiveMode === 'unknown' ? '85px' : '')))}"
            @keydown.tab.exact="(e) => {
              if (currentActiveMode === 'add') {
                e.preventDefault();
                lastSearchTabKey();
              }
            }"
          >
            <div
              v-b-tooltip.hover
              :title="$t('payments.healthCarePaymentLinesEdit.filterPaidFiles')"
            >
              <b-form-checkbox
                v-show="currentActiveMode === 'add'"
                v-model="filterPaidFiles"
                switch
                @input="onPageInput"
              >
                {{ $t("payments.healthCarePaymentLinesEdit.filterPaidFilesShort") }}
              </b-form-checkbox>
            </div>
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          <b-link
            :variant="$systemSettings.theme"
            class=""
            :href="data.item.fileId ? '/office/file/view/' + data.item.fileId : '#'"
            @click.prevent="navigate('office/file/view', {
              fileIdProp: data.item.fileId,
              cancelRouteProp: {name: $route.name, props: $route.params}
            })"
          >
            {{ data.item.file.accessNumber }}
            <!-- complementary -->
            <span
              v-show="data.item.file.parentFileId"
              style="font-size: 14px; margin: 0 0 1px 3px; color: rgb(59, 72, 83);"
            >
              <b-icon icon="back"></b-icon>
            </span>
          </b-link>
        </div>
      </template>

      <!-- fileInvoices -->
      <template v-slot:cell(fileInvoices)="data">
        <div class="kt-paymentLine-actsTable">
          <div
            v-for="(fileInvoice, fileInvoiceIndex) in data.item.fileInvoices"
            :key="fileInvoice.id"
          >
            <div>
              <!-- transformed badge -->
              <div
                v-show="fileInvoice.fileInvoiceTransformations && fileInvoice.fileInvoiceTransformations.length"
                class="kt-badge kt-badge--yellow"
              >
                <b-icon
                  icon="arrow-left-right"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fullyTransformed') }}</span></span>
              </div>
              <!-- from transformation badge -->
              <div
                v-show="fileInvoice.targetFileInvoice_fileInvoiceTransformation"
                class="kt-badge kt-badge--blue"
              >
                <b-icon
                  icon="arrow-left-right"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t('careSheetView.fromTransformation') }}</span></span>
              </div>
            </div>
            <!-- invoiceActs table -->
            <div
              v-if="(currentActiveMode === null || currentActiveMode === 'add') && fileInvoice.invoiceActs && fileInvoice.invoiceActs.length"
              class="kt-flex-table"
            >
              <div
                class="kt-flex-table__wrapper"
                role="table"
                :aria-label="$t('payments.paymentReportsPaymentLinesEdit.filePayments')"
              >
                <!-- header line -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--header"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="columnheader"
                  >
                    {{ $t('payments.paymentReportsPaymentLinesEdit.actCode') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.paymentReportsPaymentLinesEdit.invoiced') }}
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row text-success"
                    role="columnheader"
                  >
                    {{ $t('payments.paymentReportsPaymentLinesEdit.previouslyPaid') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="columnheader"
                  >
                    {{ $t('payments.paymentReportsPaymentLinesEdit.paid') }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'text-danger': fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0)}"
                    role="columnheader"
                  >
                    {{ $t('payments.paymentReportsPaymentLinesEdit.remaining') }}
                  </div>
                </div>
                <!-- content lines -->
                <div
                  v-for="(invoiceAct, index) in fileInvoice.invoiceActs"
                  :key="invoiceAct.id + '_' + data.item.paid"
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    {{ invoiceAct.act.code }}
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    {{ $n(invoiceAct.invoiced || 0, "currency") }}
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row text-success"
                    role="cell"
                  >
                    {{ invoiceAct.previouslyPaid ? $n(invoiceAct.previouslyPaid, "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                  <div
                    class="kt-flex-table__row py-0"
                    role="cell"
                  >
                    <!-- @onActivateValidation="paidValidation = $event" -->
                    <InputPrice
                      :ref="'paidInput_' + data.index + '_' + index"
                      class="kt-paymentLine-actPriceInput"
                      labelProp=""
                      :priceProp="invoiceAct.paymentAct.paid"
                      :stateProp="invoiceAct.paymentAct.paid ? null : false"
                      invalidFeedbackProp=""
                      @submit="submitPaymentAct(data.index, fileInvoiceIndex, index, $event)"
                      @change="editPaymentAct(data.index, fileInvoiceIndex, index, $event)"
                    ></InputPrice>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'text-danger': (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid) !== 0}"
                    role="cell"
                  >
                    {{ (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid) !== 0 ? $n((invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), "currency") : $t("general.emptyWithHyphen") }}
                  </div>
                </div>
                <!-- Last line (Totals) -->
                <div
                  class="kt-flex-table__line kt-flex-table__line--regular"
                  role="rowgroup"
                >
                  <div
                    class="kt-flex-table__row first"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $t('actsTable.total') }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.invoiced, 0), "currency") }}</span>
                  </div>
                  <div
                    v-show="fileInvoice.previouslyPaid"
                    class="kt-flex-table__row text-success"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.previouslyPaid, "currency") }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + invoiceAct.paymentAct.paid, 0), "currency") }}</span>
                  </div>
                  <div
                    class="kt-flex-table__row"
                    :class="{'text-danger': fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0) !== 0}"
                    role="cell"
                  >
                    <span class="font-weight-semi-bold">{{ fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0) !== 0 ? $n(fileInvoice.invoiceActs.reduce((total, invoiceAct) => total + (invoiceAct.invoiced - invoiceAct.previouslyPaid - invoiceAct.paymentAct.paid), 0), "currency") : $t("general.emptyWithHyphen") }}</span>
                  </div>
                </div>
              </div>
              <!-- save line button -->
              <b-button
                v-if="fileInvoiceIndex === (data.item.fileInvoices.length - 1)"
                :ref="'saveFilePaymentButton_' + data.item.id"
                class="d-none"
                :variant="$systemSettings.theme"
                size="sm"
                @click="saveLine(data.index)"
              >
                {{ $t("payments.paymentReportsPaymentLinesEdit.saveLineButton") }}
              </b-button>
            </div>
          </div>
        </div>
      </template>

      <!-- actions -->
      <template v-slot:cell(actionButtons)="data">
        <div :style="currentActiveMode === 'unknown' ? 'width: 85px;' : 'width: 39px;'">
          <!-- edit -->
          <b-button
            v-show="currentActiveMode === 'unknown' && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'editLine'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="mr-1"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onUnknownLineEdit(data.item.id)"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete -->
          <b-button
            v-show="(currentActiveMode === null || currentActiveMode === 'unknown') && isEnabled(['menu', 'accounting', 'payments', 'paymentReports', 'deleteLine'])"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            :class="{'ml-1': currentActiveMode === 'unknown'}"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="currentActiveMode === 'unknown' ? onDeleteUnknownPayment(data.item.id) : onDeleteFilePayment(data.item.id)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
          <!-- add -->
          <b-button
            v-show="currentActiveMode === 'add'"
            style="white-space: nowrap;"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onPaymentLineAdd(data.index)"
          >
            <b-icon icon="plus"></b-icon>
            {{ $t("general.add") }}
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h5
      v-show="showNoResult"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h5>

    <!-- pagination -->
    <div v-show="(currentActiveMode === null || currentActiveMode === 'unknown') && editedUnknownId === null">
      <div class="d-flex justify-content-around">
        <b-navbar>
          <b-navbar-nav>
            <b-pagination
              v-show="linesCount"
              v-model="page"
              class="mx-2 mt-3 mb-3"
              :class="'kt-pagination--' + $systemSettings.theme"
              :total-rows="linesCount"
              per-page="50"
              @input="onPageInput"
            ></b-pagination>
          </b-navbar-nav>
        </b-navbar>
      </div>
    </div>

    <!-- maxReached -->
    <div
      v-show="currentActiveMode === 'add' && maxReached && !loading"
      class="text-center mt-3"
    >
      <b-button
        variant="outline-secondary"
        :disabled="loadingExtendSearch"
        @click="extendSearch"
      >
        <b-icon icon="chevron-down"></b-icon>
        <span>&nbsp;{{ $t('general.extendResults') }}</span>
      </b-button>
      <b-spinner
        v-show="loadingExtendSearch"
        class="ml-1"
        :class="'kt-spinner--' + $systemSettings.theme"
        small
      ></b-spinner>
    </div>

    <!-- divider -->
    <div
      v-show="currentActiveMode === 'unknownAdd' || editedUnknownId !== null"
      class="w-100 border-top mt-1 mb-4"
    ></div>
    <!-- unknownPaymentEdit component title -->
    <div v-show="currentActiveMode === 'unknownAdd' || editedUnknownId !== null">
      <h5 class="kt-paymentLine-edit__subtitle mb-3">
        {{ editedUnknownId !== null ? $t("payments.paymentReportsPaymentLinesEdit.unknownTableTitleEdit") : $t("payments.paymentReportsPaymentLinesEdit.unknownTableTitleAdd") }}
      </h5>
    </div>
    <!-- unknownPayment Add -->
    <UnknownPaymentEdit
      v-if="currentActiveMode === 'unknownAdd' || editedUnknownId !== null"
      :paymentReportIdProp="paymentReportIdProp"
      :paymentGroupIdProp="paymentGroupIdProp"
      :initialValuesProp="initialValuesUnknownPayment"
      :unknownPaymentIdProp="editedUnknownId"
      :editModeProp="!!editedUnknownId"
      moduleModeProp
      @unknownPaymentSaved="onUnknownPaymentSaved"
      @alert="(variant, strings) => $emit('alert', variant, strings)"
    />

    <!-- editPaymentGroup Modal -->
    <b-modal
      id="editPaymentGroupModal"
      hide-footer
      :title="$t('payments.paymentGroupEdit.pageTitle')"
      size="md"
    >
      <PaymentGroupEdit
        :paymentReportIdProp="paymentReportId"
        :unvavailablePathologistIdsProp="unvavailablePathologistIds"
        :paymentGroupIdProp="paymentGroupId"
        moduleModeProp
        :editModeProp="true"
        @paymentGroupEdited="onPaymentGroupEdited"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import PaymentGroupEdit from "@/views/Accounting/Payments/PaymentGroups/PaymentGroupEdit";
import InputPrice from "@shared/views/Helpers/InputPrice";
import ProgressBar from "@shared/views/Helpers/ProgressBar";
import UnknownPaymentEdit from "@/views/Accounting/Payments/UnknownPayments/UnknownPaymentEdit";
// services
import commonServices from "@shared/services/API/commonServices";
import paymentGroupsServices from "@/services/API/paymentGroupsServices";
// helpers
import userRights from "@/services/UI/userRights";
import pagination from "@shared/services/UI/pagination";
import error from "@shared/services/UI/error";
import price from "@shared/services/UI/price";
// others
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { PaymentGroupEdit, InputPrice, ProgressBar, UnknownPaymentEdit },
  mixins: [userRights, pagination, error, price],
  props: {
    paymentReportIdProp: {
      type: Number,
      default: null
    },
    paymentGroupIdProp: {
      type: Number,
      default: null
    },
    accessNumberProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // general
      paymentReportId: this.paymentReportIdProp,
      paymentReportData: null,
      paymentGroupId: this.paymentGroupIdProp,
      paymentGroupData: null,
      page: 1,
      loading: true,
      currentActiveMode: null, // null | "add" | "unknown"
      maxReached: false,
      loadingExtendSearch: false,
      lastSearchFilters: null,
      lastSearchId: null,

      // paymentReport
      filterPaidFiles: true,
      reference: "",
      laboratory: null,
      organisation: null,
      organisationId: null,
      orderDate: "",
      paymentDate: "",
      bankId: null,
      bank: null,
      paymentReportPaid: 0,
      paymentReportReconciled: 0,

      // paymentGroup
      pathologist: null,
      filePaymentCount: 0,
      unknownPaymentCount: 0,
      paid: 0,
      reconciled: 0,
      unvavailablePathologistIds: [],

      // filePayments
      lines: [],
      linesCount: 0,
      tableFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.patient.lastName",
          sortable: true,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.patient.firstName",
          sortable: true,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "accessNumber",
          sortable: true,
          label: this.$t("payments.paymentReportsPaymentLinesEdit.accessNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "file.collectionDates",
          sortable: false,
          label: this.$t("files.collectionDateShort"),
          formatter: (value, _key, _item) => {
            return value ? value.join(", ") : "";
          }
        },
        {
          tdClass: (_value, _key, item) => {
            const previouslyPaidTotal = item.fileInvoices.reduce((total, fileInvoice) => { return total + fileInvoice.invoiceActs.previouslyPaid; }, 0);
            return "kt-table__td kt-paymentLine-acts kt-paymentLine-acts--" + (previouslyPaidTotal ? "lg" : "md");
          },
          thClass: "kt-table__th kt-table__th--sm",
          key: "fileInvoices",
          sortable: false,
          label: this.$t("payments.paymentReportsPaymentLinesEdit.acts")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      unknownFields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "lastName",
          sortable: true,
          label: this.$t("files.lastName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "firstName",
          sortable: true,
          label: this.$t("files.firstName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "paid",
          sortable: true,
          label: this.$t("payments.unknownPayments.paid"),
          formatter: (value, _key, _item) => {
            return this.$n(value || 0, "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "comment",
          sortable: true,
          label: this.$t("payments.unknownPayments.comment")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th kt-table__th--sm",
          key: "actionButtons",
          sortable: false,
          label: ""
        }
      ],
      filterHospitalisationNumber: "",
      filterFirstName: "",
      filterLastName: "",
      filterAccessNumber: this.accessNumberProp,
      filterComment: "",

      // unknown
      editedUnknownId: null
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterHospitalisationNumber !== "" ||
        this.filterFirstName !== "" ||
        this.filterLastName !== "" ||
        ((this.currentActiveMode === null || this.currentActiveMode === "add") && this.filterAccessNumber !== "") ||
        ((this.currentActiveMode === "unknown") && this.filterComment !== "")
      );
    },
    showNoResult: function() {
      if (!this.linesCount || !this.loading) return false;
      if (this.currentActiveMode === null || this.currentActiveMode === "unknown") return true;
      if (this.currentActiveMode === "add") return !this.isSearchActive;
      return false;
    },
    initialValuesUnknownPayment: function() {
      return {
        hospitalisationNumber: this.filterHospitalisationNumber,
        lastName: this.filterFirstName,
        firstName: this.filterLastName,
        comment: this.filterComment
      };
    }
    // formatted variables
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import data
    await this.onPageInput();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    lastSearchTabKey() {
      if (this.$refs.paidInput_0_0 && this.$refs.paidInput_0_0[0]) {
        this.$refs.paidInput_0_0[0].$el.firstChild.elements[0].focus();
      }
    },
    focusFirstElement() {
      if (this.$refs.viewPaymentsButton) {
        this.$refs.viewPaymentsButton.focus();
      }
    },
    focusFirstFilter() {
      this.$refs.searchHospitalisationNumber.$el.focus();
    },
    focusFirstLine() {
      if (this.currentActiveMode === null || this.currentActiveMode === "add") {
        if (this.$refs.paidInput_0_0 && this.$refs.paidInput_0_0[0]) {
          this.$refs.paidInput_0_0[0].$el.firstChild.elements[0].focus();
        }
      } else if (this.currentActiveMode === "unknown") {
        if (this.$refs.editLineButton_0) {
          this.$refs.editLineButton_0.focus();
        }
      }
    },

    // paymentReport functions
    async onExportPaymentReport(type) {
      try {
        const resFile = await commonServices.export("paymentReports", this.paymentReportId, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, this.reference + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // PaymentGroups functions
    async onEditPaymentGroup() {
      // get paymentReport
      const resServices = await commonServices.get("paymentReports", this.paymentReportId);
      const paymentReport = resServices.data;
      // update unvavailablePathologistIds
      this.unvavailablePathologistIds = paymentReport.paymentGroups.map((paymentGroup) => {
        return paymentGroup.pathologist.id;
      });
      // show modal
      this.$bvModal.show("editPaymentGroupModal");
    },
    async onPaymentGroupEdited() {
      this.$bvModal.hide("editPaymentGroupModal");
      await this.onPageInput();
    },
    async onDeletePaymentGroup() {
      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.paymentGroupDelete.text"), {
        title: this.$t("payments.paymentGroupDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("payments.paymentGroupDelete.ok"),
        cancelTitle: this.$t("payments.paymentGroupDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("paymentGroups", this.paymentGroupId);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("payments.paymentGroupDelete.notifications.deletedTitle"),
            message: this.$t("payments.paymentGroupDelete.notifications.deletedText")
          });
          this.onGoBack();
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("payments.paymentGroupDelete.notifications.deletedErrorTitle"),
            message: this.$t("payments.paymentGroupDelete.notifications.deletedErrorText")
          });
        }
      }
    },
    async onExportPaymentGroup(type) {
      try {
        const resFile = await commonServices.export("paymentGroups", this.paymentGroupId, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, this.paymentGroupId + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // table functions
    updatePaymentReportFromData() {
      this.reference = this.paymentReportData.reference;
      this.laboratory = this.paymentReportData.laboratory;
      this.organisation = this.paymentReportData.organisation;
      this.organisationId = this.paymentReportData.organisationId;
      this.orderDate = this.paymentReportData.orderDate;
      this.paymentDate = this.paymentReportData.paymentDate;
      this.bank = this.paymentReportData.bank;
      this.bankId = this.paymentReportData.bankId;
      this.paymentReportPaid = this.importFormatPrice(this.paymentReportData.paid);
      this.paymentReportReconciled = this.importFormatPrice(this.paymentReportData.reconciled);
    },
    updatePaymentGroupFromData() {
      this.pathologist = this.paymentGroupData.pathologist;
      this.filePaymentCount = this.paymentGroupData.filePaymentCount;
      this.unknownPaymentCount = this.paymentGroupData.unknownPaymentCount;
      this.paid = this.importFormatPrice(this.paymentGroupData.paid);
      this.reconciled = this.importFormatPrice(this.paymentGroupData.reconciled);
    },
    // clear filter
    async onClearFilter(fieldName) {
      this[fieldName] = "";
      await this.onPageInput();
    },
    async onClearAllFilters() {
      this.filterHospitalisationNumber = "";
      this.filterFirstName = "";
      this.filterLastName = "";
      this.filterAccessNumber = "";
      this.filterComment = "";
      await this.onPageInput();
    },
    async changeCurrentActiveMode(target) {
      this.maxReached = false;
      this.filterPaidFiles = true;
      this.editedUnknownId = null;
      this.currentActiveMode = target;
      await this.onPageInput();
      this.$nextTick(() => {
        this.focusFirstFilter();
      });
    },
    // search update (with a delay)
    onFilterChange() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.onPageInput();
      }, 450);
    },
    async onPageInput() {
      try {
        this.loading = true;

        let res = [];
        if (this.currentActiveMode === null) {
          // VIEW/SEARCH MODE
          res = await commonServices.showAll(
            "filePayments",
            this.page,
            {
              accessNumber: this.filterAccessNumber,
              hospitalisationNumber: this.filterHospitalisationNumber,
              firstName: this.filterFirstName,
              lastName: this.filterLastName,
              maidenName: "", //filterMaidenName,
              paymentGroupId: this.paymentGroupId
            },
            {
              by: this.sortBy,
              desc: this.sortDesc
            }
          );
          this.linesCount = res.data.count;
          this.lines = this.formatFilePayments(res.data.rows);
          // update report and group
          this.paymentGroupData = res.data.paymentGroup;
          this.paymentReportData = res.data.paymentGroup.paymentReport;
          this.updatePaymentReportFromData();
          this.updatePaymentGroupFromData();
        } else if (this.currentActiveMode === "add") {
          // ADD LIGNE MODE
          if (this.isSearchActive === false) {
            // if no filter
            this.lines = [];
            this.linesCount = 0;
          } else {
            this.lastSearchFilters = {
              filterPaidFiles: this.filterPaidFiles, // filter files with "paid" accounting status
              accessNumber: this.filterAccessNumber,
              hospitalisationNumber: this.filterHospitalisationNumber,
              firstName: this.filterFirstName,
              lastName: this.filterLastName,
              //filterMaidenName,
              organisationId: this.organisationId,
              paymentGroupId: this.paymentGroupId
            };
            res = await paymentGroupsServices.getAllFileInvoices(this.lastSearchFilters);
            this.lines = this.formatFileInvoices(res.data.rows);
            if (res.data && res.data.rows.length) {
              this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
            }
            this.linesCount = res.data.rows.length;
            this.maxReached = res.data.maxReached;
          }
        } else if (this.currentActiveMode === "unknown") {
          // VIEW MODE UNKNOWN
          const filters = {
            paymentGroupId: this.paymentGroupId,
            hospitalisationNumber: this.filterHospitalisationNumber,
            firstName: this.filterFirstName,
            lastName: this.filterLastName,
            comment: this.filterComment
          };
          res = await commonServices.showAll(
            "unknownPayments",
            this.page,
            filters,
            {
              by: this.sortBy,
              desc: this.sortDesc
            }
          );
          this.linesCount = res.data.count;
          this.lines = res.data.rows;
          // update report and group
          this.paymentGroupData = res.data.paymentGroup;
          this.paymentReportData = res.data.paymentGroup.paymentReport;
          this.updatePaymentReportFromData();
          this.updatePaymentGroupFromData();
          // lines
          for (const line of this.lines) {
            line.paid = this.importFormatPrice(line.paid);
          }
        } else {
          this.lines = [];
          this.linesCount = 0;
        }

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async extendSearch() {
      try {
        if (this.currentActiveMode === "add") {
          this.loadingExtendFiles = true;

          this.lastSearchFilters.minId = this.lastSearchId;

          // search and Update
          const res = await paymentGroupsServices.getAllFileInvoices(this.lastSearchFilters);
          if (res.data && res.data.rows.length) {
            this.maxReached = res.data.maxReached;
            this.lastSearchId = res.data.rows[res.data.rows.length - 1].id;
            this.lines.push(...this.formatFileInvoices(res.data.rows));
          } else {
            this.maxReached = false;
          }

          this.loadingExtendFiles = false;
        }
      } catch (err) {
        this.loadingExtendFiles = false;
        this.handleErrors(err);
      }
    },
    // FORMAT LINES (fileInvoices)
    formatFileInvoices(fileInvoices) {
      return fileInvoices.map((line) => {
        delete line.careSheetId;
        delete line.feeNoteId;
        delete line.invoiceReportId;

        line.invoiced = this.importFormatPrice(line.invoiced);
        line.paid = 0;
        line.previouslyPaid = 0;

        // invoiceActs
        for (const invoiceAct of line.invoiceActs) {
          delete invoiceAct.fileInvoiceId;
          // invoiced
          invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
          // previouslyPaid
          invoiceAct.previouslyPaid = 0;
          // paid
          for (const paymentAct of invoiceAct.paymentActs) {
            paymentAct.paid = this.importFormatPrice(paymentAct.paid);
            invoiceAct.previouslyPaid += paymentAct.paid;
            line.previouslyPaid += paymentAct.paid;
          }
          // create current paymentAct
          invoiceAct.paymentAct = {
            invoiceActId: invoiceAct.id,
            paid: invoiceAct.invoiced - invoiceAct.previouslyPaid
          };
        }
        // reassign fileInvoices
        line.fileInvoices = [{
          targetFileInvoice_fileInvoiceTransformation: line.targetFileInvoice_fileInvoiceTransformation,
          fileInvoiceTransformations: line.fileInvoiceTransformations,
          invoiced: line.invoiced,
          paid: line.paid,
          previouslyPaid: line.previouslyPaid,
          invoiceActs: line.invoiceActs
        }];
        delete line.targetFileInvoice_fileInvoiceTransformation;
        delete line.fileInvoiceTransformations;
        delete line.invoiceActs;
        // setup collectionDates
        line.file.collectionDates = line.file.containerGroups.map(({ collectionDate }) => {
          return collectionDate ? this.$d(new Date(collectionDate), "date") : "";
        });
        delete line.file.containerGroups;
        return line;
      });
    },
    // FORMAT LINES (filePayments)
    formatFilePayments(filePayments) {
      if (!filePayments.length) return [];

      return filePayments.map((line) => {
        delete line.paymentGroupId;
        delete line.patientPaymentId;
        delete line.healthCarePaymentId;
        // format prices
        line.paid = this.importFormatPrice(line.paid);
        const invoicedTotal = line.file.fileInvoices.reduce((total, item) => { return total + item.invoiced; }, 0);
        line.invoiced = this.importFormatPrice(invoicedTotal);
        line.previouslyPaid = 0;
        line.fileInvoices = line.file.fileInvoices;
        delete line.file.fileInvoices;

        // fileInvoices
        for (const fileInvoice of line.fileInvoices) {
          fileInvoice.previouslyPaid = 0;
          for (const invoiceAct of fileInvoice.invoiceActs) {
            // format prices
            invoiceAct.invoiced = this.importFormatPrice(invoiceAct.invoiced);
            for (const paymentAct of invoiceAct.paymentActs) {
              paymentAct.paid = this.importFormatPrice(paymentAct.paid);
            }
            // search if existing current paymentAct
            let currentPaymentActPosition = null;
            for (let l = 0; l < line.paymentActs.length; l++) {
              if (invoiceAct.id === line.paymentActs[l].invoiceActId) {
                currentPaymentActPosition = l;
                break;
              }
            }
            invoiceAct.previouslyPaid = 0;
            // for each paymentActs (old and current)
            for (let l = invoiceAct.paymentActs.length - 1; l >= 0; l--) {
              if (currentPaymentActPosition !== null && invoiceAct.paymentActs[l].id === line.paymentActs[currentPaymentActPosition].id) {
                // if paymentAct is current : save separatly
                invoiceAct.paymentAct = invoiceAct.paymentActs[l];
                invoiceAct.paymentActs.splice(l, 1);
              } else {
                // else : add to previouslyPaid
                invoiceAct.previouslyPaid += invoiceAct.paymentActs[l].paid;
                fileInvoice.previouslyPaid += invoiceAct.paymentActs[l].paid;
                line.previouslyPaid += invoiceAct.paymentActs[l].paid;
              }
            }
            // if no current payment created for the invoiceAct, add a default paymentAct
            if (!invoiceAct.paymentAct) {
              invoiceAct.paymentAct = {
                filePaymentId: line.id,
                id: null,
                invoiceActId: invoiceAct.id,
                paid: 0
              };
            }
          }
        }
        delete line.paymentActs;
        // setup collectionDates
        line.file.collectionDates = line.file.containerGroups.map(({ collectionDate }) => {
          return collectionDate ? this.$d(new Date(collectionDate), "date") : "";
        });
        delete line.file.containerGroups;
        return line;
      });
    },

    async updateHeader() {
      try {
        const res = await commonServices.get("paymentGroups", this.paymentGroupId);
        this.paymentGroupData = res.data;
        this.paymentReportData = res.data.paymentReport;
        this.updatePaymentReportFromData();
        this.updatePaymentGroupFromData();
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // known line save/edit
    async editPaymentAct(linePosition, fileInvoicePosition, invoiceActPosition, priceValue = null) {
      // update invoiceActs paid
      this.lines[linePosition].fileInvoices[fileInvoicePosition].invoiceActs[invoiceActPosition].paymentAct.paid = priceValue;
      // update total paid
      let paidTotal = 0;
      for (const fileInvoice of this.lines[linePosition].fileInvoices) {
        for (const invoiceAct of fileInvoice.invoiceActs) {
          paidTotal += invoiceAct.paymentAct.paid;
        }
      }
      this.lines[linePosition].paid = paidTotal;
      // only for view mode
      if (this.currentActiveMode === null) {
        this.$refs["saveFilePaymentButton_" + this.lines[linePosition].id][0].classList.remove("d-none");
      }
      await this.$refs.linesTable.refresh();
    },
    async submitPaymentAct(linePosition, fileInvoicePosition, invoiceActPosition, priceValue = null) {
      await this.editPaymentAct(linePosition, fileInvoicePosition, invoiceActPosition, priceValue);
      await this.saveLine(linePosition);
    },
    async submitSearchInput() {
      if (this.currentActiveMode === "add" && this.lines.length === 1) {
        await this.saveLine(0);
      }
    },
    async saveLine(linePosition) {
      await this.$nextTick();

      if (this.currentActiveMode === null) {
        // VIEW MODE (EDIT PAYMENT LINE)
        await this.onPaymentLineEdit(linePosition);
      } else {
        // ADD MODE (ADD PAYMENT LINE)
        await this.onPaymentLineAdd(linePosition);
      }
    },
    async onPaymentLineEdit(linePosition) {
      try {
        // update total paid
        let paidTotal = 0;
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paidTotal += invoiceAct.paymentAct.paid;
          }
        }
        this.lines[linePosition].paid = paidTotal;
        // paid amount must be not null
        if (this.lines[linePosition].paid === 0) {
          return false;
        }

        // setup paymentActs
        const paymentActs = [];
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paymentActs.push({
              id: invoiceAct.paymentAct.id,
              filePaymentId: invoiceAct.paymentAct.filePaymentId,
              invoiceActId: invoiceAct.id,
              paid: this.exportFormatPrice(invoiceAct.paymentAct.paid)
            });
          }
        }

        // setup form
        const form = {
          paymentGroupId: this.paymentGroupId,
          fileId: this.lines[linePosition].fileId,
          paid: this.exportFormatPrice(this.lines[linePosition].paid),
          paymentActs: paymentActs,
          isActive: true
        };
        const resEdition = await commonServices.put("filePayments", form, this.lines[linePosition].id);
        if (resEdition.data === true) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.editedTitle"),
            message: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.editedText")
          });
          this.focusFirstFilter();
          // hide button
          this.$refs["saveFilePaymentButton_" + this.lines[linePosition].id][0].classList.add("d-none");
          // update table
          await this.onPageInput();
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.editionErrorTitle"),
            message: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.editionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async onPaymentLineAdd(linePosition) {
      try {
        // update total paid
        let paidTotal = 0;
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paidTotal += invoiceAct.paymentAct.paid;
          }
        }
        this.lines[linePosition].paid = paidTotal;
        // paid amount must be not null
        if (this.lines[linePosition].paid === 0) {
          return false;
        }

        // setup paymentActs
        const paymentActs = [];
        for (const fileInvoice of this.lines[linePosition].fileInvoices) {
          for (const invoiceAct of fileInvoice.invoiceActs) {
            paymentActs.push({
              invoiceActId: invoiceAct.id,
              paid: this.exportFormatPrice(invoiceAct.paymentAct.paid)
            });
          }
        }

        // setup form
        const form = {
          paymentGroupId: this.paymentGroupId,
          fileId: this.lines[linePosition].fileId,
          paid: this.exportFormatPrice(this.lines[linePosition].paid),
          paymentActs: paymentActs,
          isActive: true
        };
        // add filePayment
        const resAddition = await commonServices.post("filePayments", form);
        if (resAddition.data.id) {
          // success
          this.$emit("alert", "success", {
            title: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.addedTitle"),
            message: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.addedText")
          });
          await this.updateHeader();
          if ((this.filterAccessNumber !== "" || this.filterHospitalisationNumber !== "") && this.lines.length > 1) {
            await this.onPageInput();
            this.focusFirstLine();
          } else {
            await this.onClearAllFilters();
            this.focusFirstFilter();
          }
        } else {
          // error
          this.$emit("alert", "danger", {
            title: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.additionErrorTitle"),
            message: this.$t("payments.paymentReportsPaymentLinesEdit.notifications.additionErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // delete filePayment
    async onDeleteFilePayment(filePaymentId) {
      try {
        if (filePaymentId) {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.paymentLineDelete.text"), {
            title: this.$t("payments.paymentLineDelete.title"),
            okVariant: "danger",
            okTitle: this.$t("payments.paymentLineDelete.ok"),
            cancelTitle: this.$t("payments.paymentLineDelete.cancel"),
            centered: true
          });
          if (result) {
            // delete
            this.loading = true;
            const res = await commonServices.delete("filePayments", filePaymentId);
            if (res.data === true) {
              this.$emit("alert", "success", {
                title: this.$t("payments.paymentLineDelete.notifications.deletedTitle"),
                message: this.$t("payments.paymentLineDelete.notifications.deletedText")
              });
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("payments.paymentLineDelete.notifications.deletedErrorTitle"),
                message: this.$t("payments.paymentLineDelete.notifications.deletedErrorText")
              });
            }
            await this.onPageInput();
            this.loading = false;
            this.focusFirstFilter();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // delete unknownPayment
    async onDeleteUnknownPayment(unknownPaymentId) {
      try {
        if (unknownPaymentId) {
          // confirmation pop up
          const result = await this.$bvModal.msgBoxConfirm(this.$t("payments.unknownPaymentDelete.text"), {
            title: this.$t("payments.unknownPaymentDelete.title"),
            okVariant: "danger",
            okTitle: this.$t("payments.unknownPaymentDelete.ok"),
            cancelTitle: this.$t("payments.unknownPaymentDelete.cancel"),
            centered: true
          });
          if (result) {
            // delete
            this.loading = true;
            const res = await commonServices.delete("unknownPayments", unknownPaymentId);
            if (res.data === true) {
              this.$emit("alert", "success", {
                title: this.$t("payments.unknownPaymentDelete.notifications.deletedTitle"),
                message: this.$t("payments.unknownPaymentDelete.notifications.deletedText")
              });
            } else {
              this.$emit("alert", "danger", {
                title: this.$t("payments.unknownPaymentDelete.notifications.deletedErrorTitle"),
                message: this.$t("payments.unknownPaymentDelete.notifications.deletedErrorText")
              });
            }
            await this.onPageInput();
            this.loading = false;
            this.focusFirstFilter();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // unknown line
    async onUnknownLineAdd() {
      if (this.currentActiveMode !== "unknownAdd") {
        await this.changeCurrentActiveMode("unknownAdd");
      }
    },
    async onUnknownLineEdit(unknownId) {
      this.editedUnknownId = unknownId;
      this.$bvModal.show("editUnknownPaymentModal");
    },
    async onUnknownPaymentSaved() {
      this.currentActiveMode = "unknown";
      await this.onClearAllFilters();
      this.$bvModal.hide("editUnknownPaymentModal");
      if (this.editedUnknownId) {
        this.editedUnknownId = null;
      }
      this.$nextTick(() => {
        this.focusFirstFilter();
      });
    },

    // navigate back to the group
    onGoBack() {
      navigate("accounting/payments/paymentReport/paymentGroupsEdit", { paymentReportIdProp: this.paymentReportIdProp });
    }
  }
};
</script>

<style src="./PaymentReportPaymentLinesEdit-global.scss" lang="scss"></style>

<template>
  <div class="kt-task-histology-content">
    <!-- content form -->
    <b-row>
    </b-row>
  </div>
</template>

<script>
// helpers
import error from "@shared/services/UI/error";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  mixins: [error, viewComponents],
  props: {
    contentProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      // form variables
    };
  }
  // watch: {
  //   contentProp: {
  //     handler(val) {
  //       if (val !== null) {
  //         // import
  //       } else {
  //         // null
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // }
};
</script>

<template>
  <div class="kt-reports">
    <!-- payments navbar -->
    <b-nav
      pills
      class="kt-sub-nav mt-2"
      :class="'kt-sub-nav--' + $systemSettings.theme"
    >
      <!-- balanceReport -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'balanceReport'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports' || $route.name === 'accounting/reports/balanceReport'"
        @click="navigate('accounting/reports/balanceReport', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="exclamation-triangle"></b-icon> -->
        {{ $t("reports.menu.balanceReport") }}
      </b-nav-item>
      <!-- filesNotInvoiced -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'filesNotInvoiced'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/filesNotInvoiced'"
        @click="navigate('accounting/reports/filesNotInvoiced', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="file-diff"></b-icon> -->
        {{ $t("reports.menu.filesNotInvoiced") }}
      </b-nav-item>
      <!-- sales -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'sales'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/sales'"
        @click="navigate('accounting/reports/sales', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="list-ol"></b-icon> -->
        {{ $t("reports.menu.sales") }}
      </b-nav-item>
      <!-- fileInvoicesNotPaid -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'fileInvoicesNotPaid'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/fileInvoicesNotPaid'"
        @click="navigate('accounting/reports/fileInvoicesNotPaid', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="alarm"></b-icon> -->
        {{ $t("reports.menu.fileInvoicesNotPaid") }}
      </b-nav-item>
      <!-- allPaymentsReport -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'allPaymentsReport'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/allPaymentsReport'"
        @click="navigate('accounting/reports/allPaymentsReport', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="exclamation-triangle"></b-icon> -->
        {{ $t("reports.menu.allPaymentsReport") }}
      </b-nav-item>
      <!-- incorrectPayments -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'incorrectPayments'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/incorrectPayments'"
        @click="navigate('accounting/reports/incorrectPayments', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="exclamation-triangle"></b-icon> -->
        {{ $t("reports.menu.incorrectPayments") }}
      </b-nav-item>
      <!-- receivedPayments -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'receivedPayments'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/receivedPayments'"
        @click="navigate('accounting/reports/receivedPayments', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="exclamation-triangle"></b-icon> -->
        {{ $t("reports.menu.receivedPayments") }}
      </b-nav-item>
      <!-- compensationsReport -->
      <b-nav-item
        v-show="isEnabled(['menu', 'accounting', 'reports', 'general', 'compensationsReport'])"
        class="kt-sub-nav__link"
        :active="$route.name === 'accounting/reports/compensationsReport'"
        @click="navigate('accounting/reports/compensationsReport', null, getQueryFromUrl)"
      >
        <!-- <b-icon icon="exclamation-triangle"></b-icon> -->
        {{ $t("reports.menu.compensationsReport") }}
      </b-nav-item>
    </b-nav>

    <!-- router -->
    <div class="mt-2 mt-lg-3">
      <keep-alive>
        <router-view
          :key="$route.fullPath"
          @alert="(variant, strings) => $emit('alert', variant, strings)"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
// helpers
import userRights from "@/services/UI/userRights";
import { navigate, getQueryFromUrl } from "@/services/UI/vueRouterServices";

export default {
  mixins: [userRights],
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;
    this.getQueryFromUrl = getQueryFromUrl;
  }
};
</script>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  async getDocument(interfaceName, documentType, id, size) {
    return axiosServices.axios.get("/archives/getDocument", {
      params: {
        interfaceName: interfaceName,
        documentType: documentType,
        id: id,
        size: size
      },
      responseType: "blob"
    });
  }
};

<template>
  <div class="kt-register-success">
    <!-- back to login -->
    <div class="kt-login__subtitle">
      <b-link
        ref="backButton"
        class="kt-link kt-link--gray"
        @click="$emit('goToLogin')"
      >
        <b-icon icon="chevron-left"></b-icon>
        {{ $t("session.registerSuccess.backButton") }}
      </b-link>
    </div>

    <!-- illustration -->
    <div class="text-center">
      <img
        :src="'/img/Session/RegisterSuccess/email-link-' + $systemSettings.theme + '.png'"
        alt="email-confirmation"
      />
    </div>

    <!-- title -->
    <h2
      class="kt-login__action-title h5 mt-4 mb-3"
    >
      {{ $t('session.registerSuccess.welcome', {name: (registerReturnProp.firstName + ' ' + registerReturnProp.lastName)}) }}
    </h2>
    <div
      class="kt-login__subtitle"
    >
      {{ $t("session.registerSuccess.emailConfirmation", {email: registerReturnProp.email}) }}
    </div>
    <b-link
      :class="'kt-link kt-link--' + $systemSettings.theme"
      @click="onSendValidationAgain"
    >
      {{ $t("session.registerSuccess.resendEmail") }}
    </b-link>
  </div>
</template>

<script>
import userRequestsServices from "@shared/services/API/userRequestsServices";
import error from "@shared/services/UI/error";

export default {
  mixins: [error],
  props: {
    registerReturnProp: {
      type: Object,
      deep: true,
      default: function() {
        return { firstName: "", lastName: "", email: "" };
      }
    }
  },
  mounted() {
    // auto focus
    this.$refs.backButton.focus();
  },
  methods: {
    async onSendValidationAgain() {
      try {
        const res = await userRequestsServices.resendConfirmationEmail(this.registerReturnProp.email);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("session.registerSuccess.notifications.onResendConfirmationEmailSuccessTitle"),
            message: this.$t("session.registerSuccess.notifications.onResendConfirmationEmailSuccessText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("session.registerSuccess.notifications.onResendConfirmationEmailErrorTitle"),
            message: this.$t("session.registerSuccess.notifications.onResendConfirmationEmailErrorText")
          });
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<template>
  <div
    class="kt-inputDateRange"
    :class="{
      'kt-inputDateRange--full-width': fullWidthProp,
      'border': state === false,
      'border-danger': state === false
    }"
  >
    <b-dropdown
      ref="dropdownInput"
      variant="outline"
      :disabled="disabledProp"
      @blur="$emit('blur', $event)"
    >
      <template #button-content>
        <span class="kt-inputDateRange__text-value">{{ textValue }}</span>
      </template>
      <b-dropdown-item @click="updateValue(getIsoDate(0, 0, 0), getIsoDate(0, 0, 0))">
        {{ $t("inputDateRange.today") }}
      </b-dropdown-item>
      <b-dropdown-item @click="updateValue(getIsoDate(-7, 0, 0), getIsoDate(0, 0, 0))">
        {{ $t("inputDateRange.week") }}
      </b-dropdown-item>
      <b-dropdown-item @click="updateValue(getIsoDate(0, -1, 0), getIsoDate(0, 0, 0))">
        {{ $t("inputDateRange.month") }}
      </b-dropdown-item>
      <b-dropdown-item @click="updateValue(getIsoDate(0, -3, 0), getIsoDate(0, 0, 0))">
        {{ $t("inputDateRange.threeMonths") }}
      </b-dropdown-item>
      <b-dropdown-item
        @click="updateValue(customizedStart, customizedEnd)"
      >
        {{ $t("inputDateRange.customized") }}
      </b-dropdown-item>
      <b-dropdown-text class="pt-0 kt-inputDateRange__custom-option">
        <b-form-input
          v-model="customizedStart"
          class="kt-inputDateRange__input kt-inputDateRange__input--left hide-date-picker"
          type="date"
          autocomplete="off"
          @click.stop
          @keydown.enter.exact="onSubmit"
          @input="updateValue($event, customizedEnd)"
        />
        <b-form-input
          v-model="customizedEnd"
          class="kt-inputDateRange__input kt-inputDateRange__input--right hide-date-picker"
          type="date"
          autocomplete="off"
          @click.stop
          @keydown.enter.exact="onSubmit"
          @input="updateValue(customizedStart, $event)"
        />
      </b-dropdown-text>
      <b-dropdown-text
        v-show="invalidFeedback"
        class="pt-0 text-13 text-danger"
      >
        {{ invalidFeedback }}
      </b-dropdown-text>
      <!-- <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-header></b-dropdown-header>
      <b-dropdown-form></b-dropdown-form> -->
    </b-dropdown>
  </div>
</template>

<script>
// helpers
import date from "@shared/services/UI/date";

export default {
  mixins: [date],
  props: {
    valueProp: {
      type: Object,
      default: function() {
        return { start: this.getIsoDate(0, -3, 0), end: this.getIsoDate(0, 0, 0) };
      }
    },
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    },
    authorizesGapInMonthsProp: {
      type: Number,
      default: 0
    },
    disabledProp: {
      type: Boolean
    },
    fullWidthProp: {
      type: Boolean
    }
  },
  data() {
    return {
      value: { start: this.getIsoDate(0, -3, 0), end: this.getIsoDate(0, 0, 0) },
      textValue: this.$t("inputDateRange.month"),
      customizedStart: this.getIsoDate(0, -6, 0),
      customizedEnd: this.getIsoDate(0, 0, 0)
    };
  },
  computed: {
    options: function() {
      const todayIsoDate = this.getIsoDate(0, 0, 0);
      return [
        {
          value: { start: todayIsoDate, end: todayIsoDate },
          text: this.$t("inputDateRange.today")
        },
        {
          value: { start: this.getIsoDate(-7, 0, 0), end: todayIsoDate },
          text: this.$t("inputDateRange.week")
        },
        {
          value: { start: this.getIsoDate(0, -1, 0), end: todayIsoDate },
          text: this.$t("inputDateRange.month")
        },
        {
          value: { start: this.getIsoDate(0, -3, 0), end: todayIsoDate },
          text: this.$t("inputDateRange.threeMonths")
        }
      ];
    },
    isValidGapBetweenStartEnd: function() {
      if (!this.authorizesGapInMonthsProp) return true;
      if (!this.value || !this.value.start || !this.value.end) return false;
      return this.validateDateBeforeDate(this.getIsoDate(-1, -this.authorizesGapInMonthsProp, 0, this.value.end), this.value.start);
    },
    state: function() {
      // required
      if (this.authorizesGapInMonthsProp && (!this.value || !this.value.end || !this.value.start)) return false;
      // max gap
      if (!this.isValidGapBetweenStartEnd) return false;
      // parent validation
      if (this.stateProp === false) return false;
      return null;
    },
    invalidFeedback: function() {
      if (this.startDateStateDisplay === null) return "";
      // required
      if (this.authorizesGapInMonthsProp && (!this.value || !this.value.end || !this.value.start)) return this.$t("validationRules.required");
      // max gap
      if (!this.isValidGapBetweenStartEnd) return this.$tc("reports.reportsSearchForm.validations.oneMonthMaxGap", this.authorizesGapInMonthsProp, { authorizesGapInMonths: this.authorizesGapInMonthsProp });
      // parent validation
      return this.invalidFeedbackProp;
    }
  },
  watch: {
    valueProp: {
      handler: function(val) {
        this.value = val;
        if (!val) {
          this.updateValue(this.getIsoDate(0, -3, 0), this.getIsoDate(0, 0, 0));
        } else {
          const foundOption = this.options.find(v => v.value.start === val.start && v.value.end === val.end);
          if (foundOption) {
            this.textValue = foundOption.text;
          } else {
            this.customizedStart = val.start;
            this.customizedEnd = val.end;
            const customizedEndString = this.customizedEnd ? this.$d(new Date(this.customizedEnd), "date") : "-";
            const customizedStartString = this.customizedStart ? this.$d(new Date(this.customizedStart), "date") : "-";
            this.textValue = customizedStartString + " | " + customizedEndString;
          }
        }
      },
      immediate: true,
      deep: true
    },
    state: {
      handler: function(val) {
        this.$emit("isValid", val !== false);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    focusFirstElement() {
      if (this.$refs.dropdownInput) {
        this.$refs.dropdownInput.hide(true);
      }
    },
    updateValue(start, end) {
      this.$emit("change", { start: start, end: end });
    },
    onSubmit() {
      this.focusFirstElement();
      this.$emit("submit", true);
    }
  }
};
</script>

<style src="./InputDateRange.scss" lang="scss"></style>

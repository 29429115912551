<template>
  <div class="kt-analysis-types d-inline-block">
    <button
      class="kt-analysis-types__button"
      :class="{disabled: !value.includes('cytology')}"
      @click="onClickAnalysisType('cytology')"
    >
      <span
        class="kt-analysis-types__color"
        :style="'background-color: ' + analysisColorsProp.cytology"
      ></span>
      <span class="kt-analysis-types__text">{{ $t("statistics.analysisTypes.cytology") }}</span>
    </button>
    <button
      class="kt-analysis-types__button"
      :class="{disabled: !value.includes('cotesting')}"
      @click="onClickAnalysisType('cotesting')"
    >
      <span
        class="kt-analysis-types__color"
        :style="'background-color: ' + analysisColorsProp.cotesting"
      ></span>
      <span class="kt-analysis-types__text">{{ $t("statistics.analysisTypes.cotesting") }}</span>
    </button>
    <button
      class="kt-analysis-types__button"
      :class="{disabled: !value.includes('hpv')}"
      @click="onClickAnalysisType('hpv')"
    >
      <span
        class="kt-analysis-types__color"
        :style="'background-color: ' + analysisColorsProp.hpv"
      ></span>
      <span class="kt-analysis-types__text">{{ $t("statistics.analysisTypes.hpv") }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: function() {
        return [];
      }
    },
    analysisColorsProp: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    onClickAnalysisType(type) {
      let output = this.value;
      if (this.value.includes(type)) {
        output = output.filter((v) => v !== type);
      } else {
        output.push(type);
      }
      this.$emit("input", output);
    }
  }
};
</script>

<style src="./AnalysisTypesSelect-global.scss" lang="scss"></style>

import { render, staticRenderFns } from "./ContactOrganisations.vue?vue&type=template&id=f9f0b8b0&"
import script from "./ContactOrganisations.vue?vue&type=script&lang=js&"
export * from "./ContactOrganisations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="kt-patients mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("patients.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'office', 'patients', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('office/patient/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("patients.addPatientButton") }}
      </b-button>
    </div>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      responsive
      :fields="fields"
      :items="patients"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter birthdate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              ref="birthdateFilter"
              v-model="filterBirthdate"
              class="kt-table__th-search-input"
              autocomplete="off"
              type="date"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter firstName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterFirstName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterFirstName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterFirstName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter lastName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterLastName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterLastName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterLastName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter maidenName -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterMaidenName"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterMaidenName !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterMaidenName')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter regNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputRegistrationNumber
              class="kt-table__th-search-regNumber"
              :regNumberProp="filterRegNumber"
              @onUpdateParent="filterRegNumber = $event.regNumber"
              @submit="onPageInput"
              @input="searchHasChanged = true;"
            ></InputRegistrationNumber>
            <b-button
              v-show="filterRegNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterRegNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter zip -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterZip"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterZip !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterZip')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter address1 -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterAddress1"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterAddress1 !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAddress1')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter city -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterCity"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
              @keydown.tab.exact="(e) => {
                if (filterCity === '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            ></b-form-input>
            <b-button
              v-show="filterCity !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterCity')"
              @keydown.tab.exact="(e) => {
                if (filterCity !== '') {
                  e.preventDefault();
                  focusFirstLine();
                }
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>

          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 85px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
          </b-th>
        </b-tr>
      </template>

      <!-- regNumber -->
      <template v-slot:cell(regNumber)="data">
        <div v-show="data.item.regNumber">
          <span style="white-space: nowrap;">{{ regNumberFormatter(data.item.regNumber) }}</span>
        </div>
        <div v-show="data.item.insureeRegNumber">
          <span style="white-space: nowrap;">{{ regNumberFormatter(data.item.insureeRegNumber) }}</span>
          <span>{{ " " + $t("patients.insureeTag") }}</span>
        </div>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(patientButtons)="data">
        <div style="min-width: 85px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'office', 'patients', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/patient/view', {patientIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="isEnabled(['menu', 'office', 'patients', 'edit'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/patient/edit', {patientIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
        </div>
      </template>
      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="(!patients || !patients.length) && !loading && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import InputRegistrationNumber from "@shared/views/Helpers/InputRegistrationNumber";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import regNumber from "@shared/services/UI/regNumber";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputRegistrationNumber },
  mixins: [userRights, error, regNumber, saveParamsInQuery],
  props: {
  },
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "birthdate",
          sortable: true,
          label: this.$t("patients.birthdate"),
          formatter: (value, _key, _item) => {
            return (value ? this.$d(new Date(value), "date") : "");
          }
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "firstName",
          sortable: true,
          label: this.$t("patients.firstName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "lastName",
          sortable: true,
          label: this.$t("patients.lastName")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "maidenName",
          sortable: true,
          label: this.$t("patients.maidenName")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "regNumber",
          sortable: true,
          label: this.$t("patients.regNumber"),
          formatter: (_value, _key, item) => {
            return item.regNumber + item.insureeRegNumber;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "zip",
          sortable: true,
          label: this.$t("patients.zip")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "address1",
          sortable: true,
          label: this.$t("patients.address1")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "city",
          sortable: true,
          label: this.$t("patients.city")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "patientButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterBirthdate: "",
      filterFirstName: "",
      filterLastName: "",
      filterMaidenName: "",
      filterRegNumber: "",
      filterZip: "",
      filterAddress1: "",
      filterCity: "",
      // table items
      patients: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterBirthdate: "string",
        filterFirstName: "string",
        filterLastName: "string",
        filterMaidenName: "string",
        filterRegNumber: "string",
        filterZip: "string",
        filterAddress1: "string",
        filterCity: "string"
      }
    };
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import data
    await this.onPageInput();

    // auto-focus
    this.$refs.addLineButton.focus();
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.editLineButton_0) {
        this.$refs.editLineButton_0.focus();
      }
    },
    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // searchAndUpdateTable
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (this.filterBirthdate !== "") {
          const filterBirthdate = new Date(this.filterBirthdate).getFullYear();
          if (filterBirthdate < 1900 || filterBirthdate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          birthdate: this.filterBirthdate,
          firstName: this.filterFirstName,
          lastName: this.filterLastName,
          maidenName: this.filterMaidenName,
          regNumber: this.filterRegNumber,
          zip: this.filterZip,
          address1: this.filterAddress1,
          city: this.filterCity
        };

        const res = await commonServices.getAll("patients", filters);
        this.patients = res.data.rows;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.patients.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.birthdateFilter.focus();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>


<template>
  <div>
    <!-- file details -->
    <div class="kt-file-details">
      <div class="kt-file-details__center">
        <!-- access number -->
        <div class="kt-file-details__item">
          <div class="kt-file-details__title">
            {{ $t("files.accessNumber") }}
          </div>
          <div
            class="kt-file-details__value"
            :class="isUrgent ? 'kt-file-details__value--red' : ''"
          >
            {{ accessNumber }}
            <span
              v-if="isUrgent"
              style="font-size: 14px; margin: 0 0 1px 3px;"
            >
              <b-icon
                icon="flag-fill"
                variant="danger"
              ></b-icon>
            </span>
            <span
              v-if="parentFileId"
              class="kt-complementary-icon"
              :class="{'kt-complementary-icon--ml-lg': !!parentFileId}"
            >
              <b-icon icon="back"></b-icon>
            </span>
          </div>
        </div>
        <!-- fullName -->
        <div class="kt-file-details__item">
          <div class="kt-file-details__title">
            {{ $t("patients.fullName") }}
          </div>
          <div class="kt-file-details__value">
            <PersonName
              :sexNameProp="sexName"
              :firstNameProp="firstName"
              :lastNameProp="lastName"
              :maidenNameProp="maidenName"
            />
          </div>
        </div>
        <!-- birthdate -->
        <div class="kt-file-details__item">
          <div class="kt-file-details__title">
            {{ $t("patients.birthdateFull") }}
          </div>
          <div class="kt-file-details__value">
            {{ birthdateAge }}
          </div>
        </div>
        <!-- creationDate -->
        <div class="kt-file-details__item">
          <div class="kt-file-details__title">
            {{ $t("files.creationDate") }}
          </div>
          <div class="kt-file-details__value">
            {{ creationDate ? $d(new Date(creationDate), "date") : $t("general.emptyWithHyphen") }}
          </div>
        </div>
        <!-- previousPatientFilesLength -->
        <div class="kt-file-details__item">
          <div class="kt-file-details__title">
            {{ $t("medicalReports.file.previousFileNumber") }}
          </div>
          <div class="kt-file-details__value">
            <span
              v-show="previousPatientFiles.length === 0"
              class="kt-file-details__pill"
            >{{ previousPatientFiles.length }}</span>
            <a
              v-show="previousPatientFiles.length > 0"
              v-b-modal="'previousPatientFilesModal-' + _uid"
              class="kt-file-details__pill"
            >
              {{ previousPatientFiles.length }}
              <b-icon
                v-show="hasMissingPreviousFiles"
                icon="exclamation-triangle-fill"
                style="color: rgba(199, 51, 51, 0.9) !important;"
              />
            </a>
          </div>
        </div>
        <!-- prescriber -->
        <div class="kt-file-details__item">
          <div class="kt-file-details__title">
            {{ $t("files.prescriber") }}
          </div>
          <div class="kt-file-details__value">
            {{ prescriberName }}
          </div>
        </div>
        <!-- HPV -->
        <div
          v-show="isHpv"
          class="kt-file-details__item"
        >
          <div class="kt-file-details__title">
            {{ $t("medicalReports.file.hpvResult") }}
          </div>
          <div class="kt-file-details__value">
            <div v-show="hpvResultsProp.length === 0">
              <span class="kt-file-details__pill">{{ hpvResultName }}</span>
            </div>
            <a
              v-show="hpvResultsProp.length > 0"
              v-b-modal="'hpvResultsModal-' + _uid"
              class="kt-file-details__pill"
            >
              {{ hpvResultName }}
              <span
                class="kt-count-bubble kt-count-bubble--danger align-middle"
                :class="hpvResultsProp.length > 1 ? 'd-inline-block' : 'd-none'"
              ><span class="kt-count-bubble__number">{{ hpvResultsProp.length }}</span></span>
            </a>
          </div>
        </div>
      </div>
      <!-- file action icons -->
      <div class="kt-file-details__right">
        <div class="kt-file-details__icon-container first">
          <!-- view file -->
          <b-button
            v-b-modal="'viewFileModal-' + _uid"
            class="kt-file-details__icon btn-icon first"
            variant="light"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- complementaries -->
          <b-button
            v-b-modal="'groupOfFilesModal-' + _uid"
            class="kt-file-details__icon btn-icon"
            variant="light"
          >
            <b-icon icon="folder"></b-icon><!--
            --><span
              v-show="groupOfFiles && groupOfFiles.length > 1"
              class="kt-count-bubble kt-count-bubble--corner kt-count-bubble--danger"
            ><span class="kt-count-bubble__number">{{ groupOfFiles ? groupOfFiles.length : 0 }}</span></span>
          </b-button>
        </div>
        <div class="kt-file-details__icon-container">
          <!-- archives -->
          <b-button
            v-b-modal="'archivesModal-' + _uid"
            class="kt-file-details__icon btn-icon first"
            variant="light"
          >
            <b-icon icon="paperclip"></b-icon><!--
            --><span
              v-show="!archivesIsLoading && archives.length && !hasMissingArchives"
              class="kt-count-bubble kt-count-bubble--corner kt-count-bubble--danger"
            ><span class="kt-count-bubble__number">{{ archives.length }}</span></span><!--
            --><span
              v-show="hasMissingArchives"
              class="kt-count-bubble kt-count-bubble--corner kt-count-bubble--danger"
            ><b-icon
              icon="exclamation-triangle-fill"
              style="color: rgba(199, 51, 51, 0.9) !important; margin-left: -18px; font-size: 16px;"
            /></span>
          </b-button>
          <!-- comments -->
          <b-button
            v-b-modal="'fileCommentsModal-' + _uid"
            class="kt-file-details__icon btn-icon"
            variant="light"
          >
            <b-icon icon="chat-left-dots"></b-icon><!--
            --><span
              v-show="commentsCount"
              class="kt-count-bubble kt-count-bubble--corner kt-count-bubble--danger"
            ><span class="kt-count-bubble__number">{{ commentsCount }}</span></span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Modal - view file -->
    <b-modal
      :id="'viewFileModal-' + _uid"
      hide-header
      hide-footer
      size="xl"
      :title="parentFileId ? $t('fileView.pageTitleComplementary') : $t('fileView.pageTitle')"
    >
      <!-- modal header -->
      <header
        class="modal-header"
        style="margin: -1rem -1rem 1rem;"
      >
        <div>
          <!-- title -->
          <h5
            class="modal-title kt-page-header__title h4 mb-0"
            style="font-size: 22px; line-height: 24px;"
          >
            <span>{{ parentFileId ? $t('fileView.pageTitleComplementary') : $t('fileView.pageTitle') }}</span>
            <!-- edit button -->
            <b-button
              v-if="isEnabled(['menu', 'office', 'files', 'edit'])"
              size="sm"
              class="kt-page-header__action"
              :variant="$systemSettings.theme"
              @click="navigate('office/file/edit', {fileIdProp: fileId})"
            >
              <b-icon icon="pen"></b-icon>
              {{ $t("fileView.editFile") }}
            </b-button>
          </h5>
        </div>
        <!-- close -->
        <button
          ref="closeButton"
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('viewFileModal-' + _uid)"
        >
          {{ $t('general.closeX') }}
        </button>
      </header>

      <FileView
        :fileIdProp="fileId"
        :moduleModeProp="true"
        @onOpenAddComplementaryFileModule="addComplementaryFile"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- archives Modal  -->
    <b-modal
      :id="'archivesModal-' + _uid"
      size="m"
      hide-header
      hide-footer
    >
      <FileArchives
        :archivesProp="archives"
        :readyProp="!archivesIsLoading"
        :hasMissingArchivesProp="hasMissingArchives"
        @close="$bvModal.hide('archivesModal-' + _uid)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- comments Modal  -->
    <b-modal
      :id="'fileCommentsModal-' + _uid"
      size="xl"
      hide-footer
      :title="$t('files.fileCommentsModalTitle')"
      @shown="() => {
        $refs.fileCommentsComponent.focusFirstElement()
      }"
    >
      <FileComments
        ref="fileCommentsComponent"
        :fileIdProp="fileId"
        @updateCommentsCount="commentsCount = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- group of files Modal -->
    <b-modal
      :id="'groupOfFilesModal-' + _uid"
      size="m"
      hide-header
      hide-footer
      @shown="() => {
        if (groupOfFiles && groupOfFiles.length > 1) {
          $refs.chooseFromIdsComponent.focusFirstElement();
        } else if ($refs.addComplementaryButton) {
          $refs.addComplementaryButton.focus();
        }
      }"
    >
      <!-- no complementary -->
      <p
        v-show="!groupOfFiles || groupOfFiles.length < 2"
        class="text-dark text-15 mb-1 mt-2"
      >
        {{ $t("files.noComplementaryFile") }}
      </p>
      <FileChooseFromIds
        v-if="groupOfFiles && groupOfFiles.length > 1"
        ref="chooseFromIdsComponent"
        :fileOptionsProp="groupOfFiles"
        :currentFileIdProp="fileId"
        displayDeleteFileProp
        @chosen="navigateToMedicalReportByFile($event)"
        @fileDeleted="onFileDeleted($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
      <!-- new complementary file -->
      <b-button
        v-if="isEnabled(['roleSpecific', 'cytotechnician'])"
        v-show="!parentFileId"
        ref="addComplementaryButton"
        class="mb-2 mt-3"
        :variant="$systemSettings.theme"
        type="button"
        @click="addComplementaryFile"
      >
        {{ $t('fileEdit.addComplementaryFile') }}
      </b-button>
    </b-modal>
    <!-- Modal - add complementary file -->
    <b-modal
      :id="'addComplementaryModal-' + _uid"
      hide-footer
      size="lg"
      :title="$t('fileNew.pageTitleComplementary')"
      @shown="() => {
        $refs.fileEditComponent.focusFirstElement();
      }"
    >
      <FileEdit
        ref="fileEditComponent"
        :parentFileIdProp="parentFileId || fileId"
        :accessNumberProp="accessNumber"
        :editModeProp="false"
        :moduleModeProp="true"
        @fileValidated="updateGroupOfFiles"
        @fileCreated="updateGroupOfFiles"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- previousPatientFiles Modal -->
    <b-modal
      :id="'previousPatientFilesModal-' + _uid"
      size="m"
      hide-header
      hide-footer
      @shown="() => {
        if (previousPatientFiles && previousPatientFiles.length > 0) {
          $refs.chooseFromPreviousFilesComponent.focusFirstElement();
        }
      }"
    >
      <!-- no previous file -->
      <p
        v-show="!previousPatientFiles || previousPatientFiles.length === 0"
        class="text-dark text-15 mb-1 mt-2"
      >
        {{ $t("query.noResult") }}
      </p>
      <FileChooseFromPreviousFiles
        v-if="previousPatientFiles && previousPatientFiles.length"
        ref="chooseFromPreviousFilesComponent"
        :fileOptionsProp="previousPatientFiles"
        :hasMissingPreviousFilesProp="hasMissingPreviousFiles"
        @chosen="navigateToMedicalReportByFile($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
    <!-- hpvResults Modal -->
    <b-modal
      :id="'hpvResultsModal-' + _uid"
      size="m"
      hide-header
      hide-footer
    >
      <HpvResultsView
        v-if="hpvResultsProp && hpvResultsProp.length"
        :hpvResultsProp="hpvResultsProp"
        @close="$bvModal.hide('hpvResultsModal-' + _uid)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import PersonName from "@shared/views/Helpers/PersonName";
import FileEdit from "@/views/Office/Files/FileEdit";
import FileView from "@/views/Office/Files/FileView";
import FileArchives from "@shared/views/Office/Files/FileArchives";
import FileComments from "@/views/Office/Files/FileComments";
import FileChooseFromIds from "@shared/views/Office/Files/FileChooseFromIds";
import FileChooseFromPreviousFiles from "@shared/views/Office/Files/FileChooseFromPreviousFiles";
import HpvResultsView from "@shared/views/Office/Files/HpvResultsView";
// services
import filesServices from "@/services/API/filesServices";
import commonServices from "@shared/services/API/commonServices";
import archivesServices from "@/services/API/archivesServices";
// helpers
import userRights from "@/services/UI/userRights";
import date from "@shared/services/UI/date";
import error from "@shared/services/UI/error";
import cytologyReportHelpers from "@/services/UI/cytologyReportHelpers";
import { navigate } from "@/services/UI/vueRouterServices";
// others
import fileSaver from "file-saver";

export default {
  components: {
    PersonName,
    FileEdit,
    FileView,
    FileArchives,
    FileComments,
    FileChooseFromIds,
    FileChooseFromPreviousFiles,
    HpvResultsView
  },
  mixins: [userRights, date, error, cytologyReportHelpers],
  props: {
    fileDataProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    hpvResultsProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    openAddComplementaryProp: {
      type: Boolean
    }
  },
  data() {
    return {
      fileId: this.fileIdProp,

      laboratoryId: null,
      accessNumber: "",
      parentFileId: null,
      isUrgent: false,
      sexName: "",
      lastName: "",
      maidenName: "",
      firstName: "",
      birthdateAge: "",
      age: 0,
      creationDate: "",
      previousPatientFiles: [],
      prescriber: null,
      prescriberName: "",

      isHpv: false,
      isCytology: false,

      archives: [],
      archivesIsLoading: false,
      hasMissingArchives: false,
      hasMissingPreviousFiles: false,
      complementaryFiles: [],
      groupOfFiles: [],
      commentsCount: 0,
      fileStatusesHistory: null,
      sentMessages: [],
      fileModifications: []
    };
  },
  computed: {
    // format variables
    hpvResultName: function() {
      return this.getLocalisedHpvResult(this.hpvResultsProp.length ? this.hpvResultsProp[this.hpvResultsProp.length - 1].content : "");
    }
  },
  watch: {
    // update File data on Prop change
    fileDataProp: {
      handler: async function(file) {
        if (file && file.accessNumber && file.accessNumber !== this.accessNumber) {
          this.getArchives(file);
        }
        if (file && file.id && file.id !== this.fileId) {
          await this.updateFileInfo(file);
        }
      },
      deep: true,
      immediate: false
    },
    // parent triggers addComplementaryFile()
    openAddComplementaryProp: function(value) {
      if (value) {
        this.addComplementaryFile();
        this.$emit("openedAddComplementary", true);
      }
    },
    // update complementary count
    complementaryFiles: function(value) {
      if (value) {
        this.$emit("updateComplementaryCount", value ? value.length : 0);
      }
    }
  },
  mounted() {
    // pseudo-mixins
    this.navigate = navigate;
  },
  methods: {
    async getArchives(file) {
      try {
        this.archivesIsLoading = true;
        const filters = [
          {
            documentType: "admission",
            accessNumber: file.accessNumber,
            patientId: null
          },
          {
            documentType: "medicalReport",
            accessNumber: file.accessNumber,
            patientId: null
          }
        ];
        const resArchives = await commonServices.getAll("archives", filters);
        const archives = [];
        this.hasMissingArchives = false;
        for (const documentTypeObj of resArchives.data) {
          for (const interfaceObj of documentTypeObj.results) {
            for (const document of interfaceObj.result) {
              if (document.error && document.error === "unreachable") {
                this.hasMissingArchives = true;
              } else {
                archives.push({
                  documentType: documentTypeObj.documentType,
                  interface: interfaceObj.interface,
                  localName: interfaceObj.localName,
                  ...document
                });
              }
            }
          }
        }
        this.archives = archives;
        this.archivesIsLoading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async getPreviousPatientFiles(patientId) {
      try {
        // previous Patient files
        const resPreviousPatientFiles = await filesServices.getAllByPatientId(patientId);
        const previousPatientFiles = resPreviousPatientFiles.data.rows.filter((value) => value.accessNumber !== this.accessNumber);
        let previousInitials = previousPatientFiles.filter((value) => !value.parentFileId);
        previousInitials = previousInitials.map((initial) => {
          initial.complementariesCount = 0;
          return initial;
        });
        const previousComplementaries = previousPatientFiles.filter((value) => Boolean(value.parentFileId));
        for (const previousComplementary of previousComplementaries) {
          for (const previousInitial of previousInitials) {
            if (previousComplementary.accessNumber === previousInitial.accessNumber) {
              previousInitial.complementariesCount++;
              break;
            }
          }
        }
        // previous Archives
        const filters = [
          {
            documentType: "medicalReport",
            accessNumber: null,
            patientId: patientId
          }
        ];
        const resArchives = await commonServices.getAll("archives", filters);
        const archives = [];
        this.hasMissingPreviousFiles = false;
        for (const documentTypeObj of resArchives.data) {
          for (const interfaceObj of documentTypeObj.results) {
            for (const document of interfaceObj.result) {
              if (document.error && document.error === "unreachable") {
                this.hasMissingPreviousFiles = true;
              } else {
                archives.push({
                  isArchive: true,
                  documentType: documentTypeObj.documentType,
                  interface: interfaceObj.interface,
                  localName: interfaceObj.localName,
                  ...document
                });
              }
            }
          }
        }
        this.previousPatientFiles = [...previousInitials, ...archives];
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async updateFileInfo(file) {
      try {
        this.fileId = file.id;
        // groupOfFiles
        this.parentFileId = file.parentFileId;
        this.complementaryFiles = file.complementaryFiles ? JSON.parse(JSON.stringify(file.complementaryFiles)) : [];
        this.groupOfFiles = file.groupOfFiles || [];

        this.accessNumber = file.accessNumber;
        this.laboratoryId = file.laboratory.id;
        this.isUrgent = file.isUrgent;
        this.sexName = file.patient.sex.name;
        this.firstName = file.patient.firstName;
        this.lastName = file.patient.lastName;
        this.maidenName = file.patient.maidenName;
        this.age = this.getAgeYearFromBirthdate(file.patient.birthdate);
        this.birthdateAge = this.getFormattedBirthdateAge(file.patient.birthdate);
        this.creationDate = file.creationDate;

        this.fileStatusesHistory = file.fileStatusesHistory;
        this.sentMessages = file.sentMessages || [];
        this.fileModifications = file.fileModifications || [];
        this.commentsCount = file.fileCommentCount || 0;
        this.getPreviousPatientFiles(file.patientId);

        // prescriber
        const prescribers = file.fileContacts.filter((value) => value.isPrescriber);
        if (prescribers.length > 0) {
          this.prescriber = prescribers[0].contact;
          this.prescriberName = this.prescriber.firstName + " " + this.prescriber.lastName;
          if (this.prescriber.isActive === false) this.prescriberName += " (" + this.$t("general.deletedLabel") + ")";
        } else {
          this.prescriber = null;
          this.prescriberName = "";
        }
        // screening, smear, layering, hpv, isHpv/isCytology
        if (file.sector.name === "cytology" && file.containerGroups.length === 1 && file.containerGroups[0].containerGroupTasks.length === 1) {
          this.screening = file.containerGroups[0].containerGroupTasks[0].task.content.screening;
          this.smear = file.containerGroups[0].containerGroupTasks[0].task.content.smear;
          this.layering = file.containerGroups[0].containerGroupTasks[0].task.content.layering;
          this.isHpv = file.containerGroups[0].containerGroupTasks[0].task.content.isHpv;
          this.isCytology = file.containerGroups[0].containerGroupTasks[0].task.content.isCytology;
        } else {
          this.isHpv = false;
          this.isCytology = false;
          // radio-button values
          this.screening = "";
          this.smear = "";
          this.layering = "";
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    addComplementaryFile() {
      this.$bvModal.hide("viewFileModal-" + this._uid);
      this.$bvModal.hide("groupOfFilesModal-" + this._uid);
      this.$bvModal.show("addComplementaryModal-" + this._uid);
    },
    async updateGroupOfFiles(_changedFileId) {
      this.$bvModal.hide("addComplementaryModal-" + this._uid);
      const res = await commonServices.get("files", (this.parentFileId || this.fileId));
      const parentFile = res.data;
      // complementaryFiles
      let complementaryFiles = [];
      if (typeof parentFile.childrenFiles !== "undefined") {
        complementaryFiles = parentFile.childrenFiles.map((v) => {
          v.parentFileId = parentFile.id;
          return v;
        });
      }
      this.complementaryFiles = JSON.parse(JSON.stringify(complementaryFiles));
      // groupOfFiles
      const groupOfFiles = complementaryFiles;
      groupOfFiles.unshift({
        id: parentFile.id,
        parentFileId: null,
        accessNumber: parentFile.accessNumber,
        creationDate: parentFile.creationDate,
        filingStatus: parentFile.filingStatus || null,
        medicalStatus: parentFile.medicalStatus,
        accountingStatus: parentFile.accountingStatus || null
      });
      this.groupOfFiles = JSON.parse(JSON.stringify(groupOfFiles));
      this.$emit("updateGroupOfFiles", { groupOfFiles: groupOfFiles, complementaryFiles: complementaryFiles });
    },
    async onFileDeleted(deletedFile) {
      if (deletedFile.id === this.fileId) {
        // refresh the entire report
        this.$bvModal.hide("groupOfFilesModal-" + this._uid);
        this.$emit("refreshCancelledFile", true);
      } else {
        // refresh only the groupOfFiles
        await this.updateGroupOfFiles();
      }
    },
    navigateToMedicalReportByFile(event) {
      // validate : chosen not null
      if (event === null) {
        this.$bvModal.hide("groupOfFilesModal-" + this._uid);
        this.$bvModal.hide("previousPatientFilesModal-" + this._uid);
        return false;
      }
      // isArchive
      if (event.isArchive) {
        this.getFile(event);
        return false;
      }
      // validate : same file
      if (this.fileId === event.id) {
        this.$bvModal.hide("groupOfFilesModal-" + this._uid);
        return false;
      }
      // validate : file not validated by secretary
      if (!event.filingStatus || (event.filingStatus.name !== "validatedForDiagnosis" && event.filingStatus.name !== "validatedForAccounting")) {
        this.$emit("alert", "danger", {
          title: this.$t("reportEntry.notifications.fileNotValidatedTitle"),
          message: this.$t("reportEntry.notifications.fileNotValidatedText")
        });
        return false;
      }

      if (event.sector) {
        navigate("diagnosis/" + event.sector.name + "ReportEdit", {
          fileIdProp: event.id,
          cancelRouteProp: this.cancelRouteProp
        });
      } else {
        navigate("diagnosis/cytologyReportEdit", {
          fileIdProp: event.id,
          cancelRouteProp: this.cancelRouteProp
        });
      }
      return true;
    },
    async getFile(archive) {
      try {
        const resFile = await archivesServices.getDocument(archive.interface, archive.documentType, archive.id, archive.size);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, (archive.name || (this.$t("archivesDocumentTypes." + archive.documentType) + "-" + archive.id)) + ".pdf");
        }
        this.$bvModal.hide("previousPatientFilesModal-" + this._uid);
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<style scoped src="./ReportFileDetails.scss" lang="scss"></style>

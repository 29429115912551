<template>
  <div class="kt-filesReport mb-5">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <div class="d-flex justify-content-between">
        <div>
          <h2 class="h4 mb-0 kt-page-header__title">
            {{ $t("filesReports.pageTitle") }}
          </h2>
        </div>
        <!-- right side actions -->
        <div>
          <!-- open advancedSearch -->
          <b-button
            v-b-modal="'FileAdvancedSearchModal' + _uid"
            size="sm"
            class="kt-page-header__action mb-n1"
            variant="light"
          >
            <b-icon icon="filter"></b-icon>
            {{ $t('files.advancedSearchTitle') }}
            <span
              v-show="advandedFiltersCount"
              class="kt-count-bubble kt-count-bubble--danger"
            ><span
              class="kt-count-bubble__number"
              style="margin-left: 2px;"
            >{{ advandedFiltersCount }}</span></span>
          </b-button>
          <!-- clear filters -->
          <b-button
            v-show="isSearchActive"
            size="sm"
            class="kt-page-header__action mb-n1"
            variant="light"
            @click="clearAllFilters"
          >
            <b-icon icon="x"></b-icon>
            {{ $t("general.clearFilters") }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- result (totals) -->
    <div
      v-show="isDisplayedResult"
      class="kt-item-list kt-item-list--full-width kt-item-list--shadow mb-4"
    >
      <div class="kt-item-list__item-wrapper">
        <div
          class="kt-item-list__item mb-0"
          style="padding: 20px;"
        >
          <div class="d-flex justify-content-around align-items-center text-center">
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.totalFiles") }}</span>
              <br /><span>{{ totalFiles }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.totalComplementariesShort") }}</span>
              <br /><span>{{ totalComplementaries }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.totalCytology") }}</span>
              <br /><span>{{ totalCytology }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.totalHpv") }}</span>
              <br /><span>{{ totalHpv }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.totalCotesting") }}</span>
              <br /><span>{{ totalCotesting }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.positive") }}</span>
              <br /><span>{{ positive }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.negative") }}</span>
              <br /><span>{{ negative }}</span>
            </h4>
            <h4 class="kt-reports__total-title border-right">
              <span class="kt-reports__total-text">{{ $t("filesReports.indeterminate") }}</span>
              <br /><span>{{ indeterminate }}</span>
            </h4>
            <div class="ml-3">
              <b-button
                :variant="$systemSettings.theme"
                :disabled="loading || searchHasChanged"
                @click="onExport"
              >
                {{ $t("general.downloadXlsx") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- advanced filters -->
    <b-modal
      :id="'FileAdvancedSearchModal' + _uid"
      hide-footer
      :title="$t('files.advancedSearchTitle')"
      size="md"
    >
      <div class="kt-advanced-search">
        <b-row v-show="showLaboratories">
          <!-- LaboratoryId -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.laboratory') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-select
              v-model="filterLaboratoryId"
              class="mb-3"
              :options="laboratoriesOptions.filter(v => v.isActive)"
              value-field="id"
              text-field="formattedName"
              @change="searchHasChanged = true;"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row v-show="sectorsOptions.length > 2">
          <!-- sectorId -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.sector') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-select
              v-model="filterSectorId"
              class="mb-3"
              :options="sectorsOptions"
              value-field="id"
              text-field="localisedName"
              @change="searchHasChanged = true;"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <!-- prescribingOrganisation -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.prescribingOrganisation') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="mb-3"
          >
            <!-- prescribingOrganisation list -->
            <div class="kt-item-list">
              <div
                v-if="filterPrescribingOrganisation !== null"
                class="kt-item-list__item-wrapper"
              >
                <div class="kt-item-list__item mb-0">
                  <div class="kt-item-list__item-title">
                    <!-- name -->
                    {{ filterPrescribingOrganisation.name || '' }}
                  </div>
                  <div class="kt-item-list__item-text">
                    <!-- code -->
                    {{ filterPrescribingOrganisation.code }}
                  </div>

                  <!-- actions -->
                  <div class="kt-item-list__item-actions">
                    <!-- delete organisation Button -->
                    <b-button
                      class="btn-icon kt-item-list__item-delete"
                      size="sm"
                      variant="light"
                      squared
                      @click="filterPrescribingOrganisation = null"
                    >
                      <b-icon icon="x"></b-icon>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- choose organisation Button -->
            <b-button
              v-show="filterPrescribingOrganisation === null"
              v-b-modal="'chooseOrganisationModal'"
              :variant="'outline-' + $systemSettings.theme"
            >
              {{ $t("fileEdit.choose") }}
            </b-button>
          </b-col>

          <!-- fileContact -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchFileContact') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="mb-3"
          >
            <!-- fileContact list -->
            <div class="kt-item-list">
              <div
                v-if="filterFileContact !== null"
                class="kt-item-list__item-wrapper"
              >
                <div class="kt-item-list__item mb-0">
                  <div class="kt-item-list__item-title">
                    <!-- name -->
                    {{ filterFileContact.firstName + (filterFileContact.firstName && filterFileContact.lastName ? " " : "") + filterFileContact.lastName }}
                  </div>
                  <div class="kt-item-list__item-text">
                    <!-- code -->
                    {{ filterFileContact.code }}
                  </div>

                  <!-- actions -->
                  <div class="kt-item-list__item-actions">
                    <!-- delete fileContact Button -->
                    <b-button
                      class="btn-icon kt-item-list__item-delete"
                      size="sm"
                      variant="light"
                      squared
                      @click="filterFileContact = null"
                    >
                      <b-icon icon="x"></b-icon>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- choose fileContact Button -->
            <b-button
              v-show="filterFileContact === null"
              v-b-modal="'chooseFileContactModal'"
              disabled
              :variant="'outline-' + $systemSettings.theme"
            >
              {{ $t("fileEdit.choose") }}
            </b-button>
          </b-col>
        </b-row>

        <!-- divider -->
        <div
          class="border-top"
          style="margin-bottom: 20px;"
        ></div>

        <!-- patient -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <h3 class="kt-advanced-search__subtitle">
              {{ $t('files.patient') }}
            </h3>
          </b-col>
          <b-col
            v-show="filterPatient"
            cols="12"
            md="8"
          >
            <!-- filterPatient info -->
            <div class="kt-item-list">
              <div class="kt-item-list__item-wrapper">
                <div class="kt-item-list__item mb-3">
                  <!-- actions -->
                  <div class="kt-item-list__item-actions">
                    <!-- delete filterPatient -->
                    <b-button
                      class="btn-icon kt-item-list__item-delete"
                      size="sm"
                      variant="light"
                      squared
                      @click="filterPatient = null"
                    >
                      <b-icon icon="x"></b-icon>
                    </b-button>
                  </div>

                  <div class="kt-item-list__item-title">
                    <!-- name -->
                    <PersonName
                      :sexNameProp="filterPatient ? filterPatient.sex.name : ''"
                      :firstNameProp="filterPatient ? filterPatient.firstName : ''"
                      :lastNameProp="filterPatient ? filterPatient.lastName : ''"
                      :maidenNameProp="filterPatient ? filterPatient.maidenName : ''"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-show="!filterPatient">
          <!-- PatientSexId -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchSex') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="mb-3"
          >
            <b-form-checkbox
              v-for="sex in sexOptions"
              :key="sex.id"
              v-model="filterPatientSexId"
              :value="sex.id"
              class="d-inline-block mr-3"
              @click.prevent="() => {
                filterPatientSexId = filterPatientSexId === sex.id ? null : sex.id;
              }"
              @change="searchHasChanged = true"
            >
              {{ sex.localisedName }}
            </b-form-checkbox>
          </b-col>
          <!-- PatientBirthdate -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchBirthdate') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="mb-3"
          >
            <b-form-input
              v-model="filterPatientBirthdate"
              autocomplete="off"
              type="date"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="$bvModal.hide('FileAdvancedSearchModal' + _uid)"
            ></b-form-input>
          </b-col>
          <!-- PatientFirstName -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchFisrtName') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="mb-3"
          >
            <b-form-input
              v-model="filterPatientFirstName"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="$bvModal.hide('FileAdvancedSearchModal' + _uid)"
            ></b-form-input>
            <SearchTypeSelect v-model="filterTypePatientFirstName" />
          </b-col>
          <!-- PatientLastName -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchLastName') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="mb-3"
          >
            <b-form-input
              v-model="filterPatientLastName"
              trim
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="$bvModal.hide('FileAdvancedSearchModal' + _uid)"
            ></b-form-input>
            <SearchTypeSelect v-model="filterTypePatientLastName" />
          </b-col>
        </b-row>

        <!-- divider -->
        <div
          class="border-top"
          style="margin-bottom: 20px;"
        ></div>
        <!-- result -->
        <h3 class="kt-advanced-search__subtitle">
          {{ $t('files.results') }}
        </h3>
        <b-row>
          <!-- CytologyResult -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchCytologyResult') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-select
              v-model="filterCytologyResult"
              class="mb-3"
              :options="[
                {text: $tc('general.allMasculine', 2), value: null},
                {text: $t('files.resultOptions.negative'), value: 'negative'},
                {text: $t('files.resultOptions.positive'), value: 'positive'},
                {text: $t('files.resultOptions.indeterminate'), value: 'indeterminate'}
              ]"
              @change="searchHasChanged = true;"
            ></b-form-select>
          </b-col>
          <!-- HpvResult -->
          <b-col
            cols="12"
            md="4"
          >
            <label class="kt-advanced-search__label">{{ $t('files.advancedSearchHpvResult') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-select
              v-model="filterHpvResult"
              class="mb-3"
              :options="[
                {text: $tc('general.allMasculine', 2), value: null},
                {text: $t('files.resultOptions.negative'), value: 'negative'},
                {text: $t('files.resultOptions.positive'), value: 'positive'},
                {text: $t('files.resultOptions.indeterminate'), value: 'indeterminate'}
              ]"
              @change="searchHasChanged = true;"
            ></b-form-select>
          </b-col>
        </b-row>

        <!-- divider -->
        <div class="w-100 border-top mb-3"></div>

        <!-- filter footer -->
        <div class="text-right pt-1">
          <!-- close -->
          <b-button
            :variant="$systemSettings.theme"
            @click="closeAndSearch"
          >
            {{ $t('files.advancedSearchCloseButton') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- choose statuses Modal -->
    <b-modal
      :id="'statusesModal' + _uid"
      hide-footer
      :title="$t('files.statusesSearchTitle')"
      size="lg"
    >
      <div class="kt-statuses-filters">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <!-- filing -->
            <h3 class="kt-statuses-filters__subtitle">
              {{ $t("fileStatus.badgeTitles.filingStatus") }}
            </h3>
            <button
              class="kt-statuses-filters__option"
              :class="!filterFilingStatusIds.length ? 'kt-statuses-filters__option--checked' : ''"
              @click="filterFilingStatusIds = []; searchHasChanged = true;"
            >
              <div class="kt-checkbox-display-only-cont p-0">
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (!filterFilingStatusIds.length ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text">{{ $tc("general.allMasculine", 2) }}</span>
              </div>
            </button>
            <button
              v-for="filingStatus in filingStatusOptions"
              :key="filingStatus.id"
              class="kt-statuses-filters__option"
              :class="filterFilingStatusIds.includes(filingStatus.id) ? 'kt-statuses-filters__option--checked' : ''"
              @click="updateStatusOption(filterFilingStatusIds, filingStatus.id)"
            >
              <div class="kt-checkbox-display-only-cont p-0">
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (filterFilingStatusIds.includes(filingStatus.id) ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text font-weight-normal">{{ filingStatus.localisedName }}</span>
              </div>
            </button>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <!-- divider -->
            <div class="w-100 border-top my-3 d-lg-none"></div>
            <!-- medical -->
            <h3 class="kt-statuses-filters__subtitle">
              {{ $t("fileStatus.badgeTitles.medicalStatus") }}
            </h3>
            <button
              class="kt-statuses-filters__option"
              :class="!filterMedicalStatusIds.length ? 'kt-statuses-filters__option--checked' : ''"
              @click="filterMedicalStatusIds = []; searchHasChanged = true;"
            >
              <div class="kt-checkbox-display-only-cont p-0">
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (!filterMedicalStatusIds.length ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text">{{ $tc("general.allMasculine", 2) }}</span>
              </div>
            </button>
            <button
              v-for="medicalStatus in medicalStatusOptions"
              :key="medicalStatus.id"
              class="kt-statuses-filters__option"
              :class="filterMedicalStatusIds.includes(medicalStatus.id) ? 'kt-statuses-filters__option--checked' : ''"
              @click="updateStatusOption(filterMedicalStatusIds, medicalStatus.id)"
            >
              <div class="kt-checkbox-display-only-cont p-0">
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (filterMedicalStatusIds.includes(medicalStatus.id) ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text font-weight-normal">{{ medicalStatus.localisedName }}</span>
              </div>
            </button>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <!-- divider -->
            <div class="w-100 border-top my-3 d-lg-none"></div>
            <!-- accounting -->
            <h3 class="kt-statuses-filters__subtitle">
              {{ $t("fileStatus.badgeTitles.accountingStatus") }}
            </h3>
            <button
              class="kt-statuses-filters__option"
              :class="!filterAccountingStatusIds.length ? 'kt-statuses-filters__option--checked' : ''"
              @click="filterAccountingStatusIds = []; searchHasChanged = true;"
            >
              <div class="kt-checkbox-display-only-cont p-0">
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (!filterAccountingStatusIds.length ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text">{{ $tc("general.allMasculine", 2) }}</span>
              </div>
            </button>
            <button
              v-for="accountingStatus in accountingStatusOptions"
              :key="accountingStatus.id"
              class="kt-statuses-filters__option"
              :class="filterAccountingStatusIds.includes(accountingStatus.id) ? 'kt-statuses-filters__option--checked' : ''"
              @click="updateStatusOption(filterAccountingStatusIds, accountingStatus.id)"
            >
              <div class="kt-checkbox-display-only-cont p-0">
                <span
                  class="kt-checkbox-display-only"
                  :class="('kt-checkbox-display-only--' + $systemSettings.theme) + (filterAccountingStatusIds.includes(accountingStatus.id) ? ' kt-checkbox-display-only--checked' : '')"
                >
                  <span class="kt-checkbox-display-only__cross"></span>
                </span>
                <span class="kt-checkbox-display-only__text font-weight-normal">{{ accountingStatus.localisedName }}</span>
              </div>
            </button>
          </b-col>
        </b-row>

        <!-- divider -->
        <div class="w-100 border-top my-3"></div>

        <!-- filter footer -->
        <div class="text-right pt-1">
          <!-- close -->
          <b-button
            :variant="$systemSettings.theme"
            @click="$bvModal.hide('statusesModal' + _uid)"
          >
            {{ $t('general.search') }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- choose fileContactModal Modal -->
    <b-modal
      id="chooseFileContactModal"
      hide-footer
      :title="$t('contactChoose.pageTitle')"
      size="xl"
    >
      <ContactChoose
        :organisationIdProp="filterPrescribingOrganisation ? filterPrescribingOrganisation.id : null"
        @chosen="onFileContactChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>

    <!-- choose Organisation Modal -->
    <b-modal
      id="chooseOrganisationModal"
      hide-footer
      :title="$t('organisationChoose.pageTitle')"
      size="xl"
    >
      <OrganisationChoose
        :optionsProp="{
          filterIsPrescribing: true
        }"
        @chosen="onOrganisationChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      :fields="formattedFields"
      :items="files"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter creationDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputDateRange
              ref="filterRangeFilter"
              :valueProp="filterRange"
              class="kt-table__th-search-input"
              fullWidthProp
              :authorizesGapInMonthsProp="3"
              @keydown.enter.exact="onPageInput"
              @change="filterRange = $event; searchHasChanged = true;"
              @submit="onPageInput"
              @isValid="isValidFilterRange = $event"
            />
          </b-th>
          <!-- filter accessNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputAccessNumber
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              :maxlengthProp="50"
              trimProp
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
              @submit="onPageInput"
            />
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter hospitalisationNumber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterHospitalisationNumber"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterHospitalisationNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterHospitalisationNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter patient -->
          <b-th
            class="kt-table__th-search"
            style="min-width: 120px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-button
              ref="filterPatientChoose"
              v-b-modal="'patientChooseModal-' + _uid"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :style="filterPatient === null ? 'padding-left: 34px;' : ''"
              :variant="'outline-' + $systemSettings.theme"
            >
              <!-- search icon -->
              <b-icon
                v-show="filterPatient === null"
                class="kt-table__th-search-input__search-icon"
                icon="search"
              ></b-icon>
              <!-- value -->
              <span class="kt-table__th-search-input__text-overflow">{{ filterPatient ? filterPatient.firstName + ' ' + filterPatient.lastName : '' }}</span>
            </b-button>
            <b-button
              v-show="filterPatient"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterPatient = null;
                searchHasChanged = true;
                $refs.filterPatientChoose.focus();
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter prescriber -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="contacts"
              searchedFieldProp="lastName"
              :returnFieldsArrayProp="['id', 'code', 'lastName', 'firstName', 'isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterPrescriber"
              :displayedValueProp="suggestionFormatContact(filterPrescriber)"
              :suggestionFormatProp="suggestionFormatContact"
              noWrapProp

              @onUpdateParent="onPrescriberChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filterPathologist -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="pathologists"
              searchedFieldProp="user.lastName"
              :returnFieldsArrayProp="['id', 'code', 'user.lastName', 'user.firstName', 'user.email', 'user.isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterPathologist"
              :displayedValueProp="suggestionFormatPathologist(filterPathologist)"
              :suggestionFormatProp="suggestionFormatPathologist"
              noWrapProp

              @onUpdateParent="onPathologistChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- statuses -->
          <b-th class="kt-table__th-search">
            <b-button
              v-b-modal="'statusesModal' + _uid"
              class="kt-table__th-search-input kt-table__th-search-input--choose"
              :variant="'outline-' + $systemSettings.theme"
            >
              <span class="kt-statuses-filters-input">
                <span
                  class="kt-statuses-filters-input__element"
                  :class="{'kt-statuses-filters-input__element--show': filterFilingStatusIds.length && filterFilingStatusIds.length !== filingStatusOptions.length}"
                >
                  <span
                    v-for="(filingStatusId, index) in filterFilingStatusIds"
                    :key="filingStatusId"
                  >{{ (index > 0 ? ", " : "") + getStatusNameById(filingStatusId, "filing") }}</span>
                </span>
                <span
                  class="kt-statuses-filters-input__element"
                  :class="{'kt-statuses-filters-input__element--show': filterMedicalStatusIds.length && filterMedicalStatusIds.length !== medicalStatusOptions.length}"
                >
                  <span
                    v-for="(medicalStatusId, index) in filterMedicalStatusIds"
                    :key="medicalStatusId"
                  >{{ (index > 0 ? ", " : "") + getStatusNameById(medicalStatusId, "medical") }}</span>
                </span>
                <span
                  class="kt-statuses-filters-input__element"
                  :class="{'kt-statuses-filters-input__element--show': filterAccountingStatusIds.length && filterAccountingStatusIds.length !== accountingStatusOptions.length}"
                >
                  <span
                    v-for="(accountingStatusId, index) in filterAccountingStatusIds"
                    :key="accountingStatusId"
                  >{{ (index > 0 ? ", " : "") + getStatusNameById(accountingStatusId, "accounting") }}</span>
                </span>
              </span>
            </b-button>
            <b-button
              v-show="(filterFilingStatusIds.length && filterFilingStatusIds.length !== filingStatusOptions.length) ||
                (filterMedicalStatusIds.length && filterMedicalStatusIds.length !== medicalStatusOptions.length) ||
                (filterAccountingStatusIds.length && filterAccountingStatusIds.length !== accountingStatusOptions.length)"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="() => {
                filterFilingStatusIds = [];
                filterMedicalStatusIds = [];
                filterAccountingStatusIds = [];
                searchHasChanged = true;
              }"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 85px;"
          >
            <!-- search Button  -->
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              style="white-space: nowrap;"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : access number -->
      <template v-slot:cell(accessNumber)="data">
        <div style="display: flex; align-items: center;">
          {{ data.item.accessNumber }}
          <!-- is urgent -->
          <span
            v-show="data.item.isUrgent"
            style="font-size: 14px; margin: 0 0 1px 3px;"
          >
            <b-icon
              icon="flag-fill"
              variant="danger"
            ></b-icon>
          </span>
          <!-- complementary -->
          <span
            v-show="data.item.parentFileId"
            class="kt-complementary-icon"
            :class="{'kt-complementary-icon--ml-lg': !!data.item.isUrgent}"
          >
            <b-icon icon="back"></b-icon>
          </span>
        </div>
      </template>

      <!-- cell template : patient -->
      <template v-slot:cell(patient)="data">
        <!-- patient name -->
        <PersonName
          class="kt-break-word"
          :sexNameProp="data.value.sex.name || ''"
          :firstNameProp="data.value.firstName || ''"
          :lastNameProp="data.value.lastName || ''"
          :maidenNameProp="data.value.maidenName || ''"
        />
      </template>

      <!-- cell template : status badges -->
      <template v-slot:cell(status)="data">
        <div style="white-space: nowrap;">
          <StatusBadge
            typeProp="filingStatus"
            :statusNameProp="data.item.filingStatus.name || ''"
            isHiddenEmptyProp
          />
          <StatusBadge
            class="ml-1"
            typeProp="medicalStatus"
            :statusNameProp="data.item.medicalStatus.name || ''"
            isHiddenEmptyProp
          />
          <StatusBadge
            class="ml-1"
            typeProp="accountingStatus"
            :statusNameProp="data.item.accountingStatus.name || ''"
            isHiddenEmptyProp
          />
        </div>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(fileButtons)="data">
        <div style="width: 85px;">
          <!-- view -->
          <b-button
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="() => {
              navigate('office/file/view', {
                fileIdProp: data.item['id'],
                cancelRouteProp: {name: $route.name, props: $route.params}
              });
            }"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- edit -->
          <b-button
            v-show="isEnabled(['menu', 'office', 'files', 'edit'])"
            :ref="data.index === 0 ? 'editLineButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('office/file/edit', {
              fileIdProp: data.item['id'],
              accessNumberProp: data.item['accessNumber'],
              cancelRouteProp: {name: 'office/files'}
            })"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="isDisplayedResult && files.length === 0 && !loading"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>

    <!-- choose Patient Modal -->
    <b-modal
      :id="'patientChooseModal-' + _uid"
      hide-footer
      :title="$t('patientChoose.pageTitle')"
      size="xxl"
      @shown="() => {
        $refs.patientChooseComponent.focusFirstElement()
      }"
    >
      <PatientChoose
        ref="patientChooseComponent"
        :initialFilterValuesProp="patientInitialFilterValues"
        @chosen="onPatientChosen($event)"
        @updateInitialFilterValues="patientInitialFilterValues = $event"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// components
import StatusBadge from "@shared/views/Helpers/StatusBadge";
import PersonName from "@shared/views/Helpers/PersonName";
import PatientChoose from "@shared/views/Office/Patients/PatientChoose";
import InputSearch from "@shared/views/Helpers/InputSearch";
import InputAccessNumber from "@shared/views/Helpers/InputAccessNumber";
import InputDateRange from "@shared/views/Helpers/InputDateRange";
import OrganisationChoose from "@/views/Data/Organisations/OrganisationChoose";
import ContactChoose from "@/views/Office/Contacts/ContactChoose";
import SearchTypeSelect from "@shared/views/Helpers/SearchTypeSelect";
// services
import commonServices from "@shared/services/API/commonServices";
import filesReportsServices from "@/services/API/filesReportsServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
import fileSaver from "file-saver";

export default {
  components: { StatusBadge, PersonName, PatientChoose, InputSearch, InputAccessNumber, InputDateRange, OrganisationChoose, ContactChoose, SearchTypeSelect },
  mixins: [userRights, error, saveParamsInQuery],
  props: {},
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: true,
      lastSearchFilters: null,
      maxReached: false,
      isValidFilterRange: true,
      isDisplayedResult: false,
      totalFiles: 0,
      totalComplementaries: 0,
      totalCytology: 0,
      totalHpv: 0,
      totalCotesting: 0,
      positive: 0,
      negative: 0,
      indeterminate: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "creationDate",
          sortable: true,
          label: this.$t("files.creationDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("files.accessNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th text-nowrap",
          key: "hospitalisationNumber",
          sortable: true,
          label: this.$t("files.hospitalisationNumberShort")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "patient",
          sortable: true,
          label: this.$t("files.patient")
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "fileContacts",
          sortable: true,
          label: this.$t("files.prescriber"),
          formatter: (_value, _key, item) => {
            let output = "";
            if (item.fileContacts) {
              const filteredFileContacts = item.fileContacts.filter(v => v.isPrescriber);
              if (filteredFileContacts[0]) {
                output = filteredFileContacts[0].contact.firstName + " " + filteredFileContacts[0].contact.lastName;
              }
            }
            return output;
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td kt-break-word",
          thClass: "kt-table__th",
          key: "pathologist",
          sortable: true,
          label: this.$t("files.pathologist"),
          formatter: (value, _key, _item) => {
            return value ? this.suggestionFormatPathologist(value) : "";
          },
          sortByFormatted: true
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "status",
          sortable: false,
          label: this.$t("files.filingStatus")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "fileButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterRange: null,
      filterAccessNumber: "",
      filterHospitalisationNumber: "",
      filterPatient: null,
      filterPrescriber: null,
      filterPathologist: null,
      filterFilingStatusIds: [],
      filterAccountingStatusIds: [],
      filterMedicalStatusIds: [],
      filterLaboratoryId: null,
      filterSectorId: null,
      filterCytologyResult: null,
      filterHpvResult: null,
      filterPrescribingOrganisation: null,
      filterFileContact: null,
      filterPatientSexId: null,
      filterPatientBirthdate: "",
      filterPatientFirstName: "",
      filterPatientLastName: "",
      filterTypePatientLastName: "exact",
      filterTypePatientFirstName: "exact",
      patientInitialFilterValues: {},
      notReadyForDiagnosisFileId: null,
      // table items
      files: [],
      // options
      laboratoriesOptions: [],
      sectorsOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterRange: { start: "string", end: "string" },
        filterAccessNumber: "string",
        filterHospitalisationNumber: "string",
        filterPatient: {
          id: "number",
          firstName: "string",
          lastName: "string",
          maidenName: "string",
          sex: { name: "string" }
        },
        filterPrescriber: {
          id: "number",
          firstName: "string",
          lastName: "string",
          code: "string"
        },
        filterPathologist: {
          id: "number",
          user: {
            firstName: "string",
            lastName: "string"
          }
        },
        filterFilingStatusIds: "arrayOfNumbers",
        filterMedicalStatusIds: "arrayOfNumbers",
        filterAccountingStatusIds: "arrayOfNumbers",
        filterLaboratoryId: "number",
        filterSectorId: "number",
        filterCytologyResult: "string",
        filterHpvResult: "string",
        filterPrescribingOrganisation: {
          id: "number",
          name: "string",
          code: "string",
          organisationType: {
            name: "string"
          },
          isActive: "boolean"
        },
        filterFileContact: {
          id: "number",
          code: "string",
          firstName: "string",
          lastName: "string",
          isActive: "boolean"
        },
        filterPatientSexId: "number",
        filterPatientBirthdate: "string",
        filterPatientLastName: "string",
        filterPatientFirstName: "string",
        filterTypePatientLastName: "string",
        filterTypePatientFirstName: "string"
      }
    };
  },
  computed: {
    advandedFiltersCount: function() {
      let count = 0;
      if (this.filterLaboratoryId) count++;
      if (this.filterSectorId) count++;
      if (this.filterCytologyResult) count++;
      if (this.filterHpvResult) count++;
      if (this.filterPrescribingOrganisation !== null) count++;
      if (this.filterFileContact !== null) count++;
      if (!this.filterPatient) {
        if (this.filterPatientSexId) count++;
        if (this.filterPatientBirthdate) count++;
        if (this.filterPatientFirstName) count++;
        if (this.filterPatientLastName) count++;
      }
      return count;
    },
    isSearchActive: function() {
      return (
        this.filterRange ||
        this.filterAccessNumber ||
        this.filterHospitalisationNumber ||
        this.filterPatient ||
        this.filterPrescriber ||
        this.filterPathologist ||
        (this.filterFilingStatusIds.length && this.filterFilingStatusIds.length !== this.filingStatusOptions.length) ||
        (this.filterMedicalStatusIds.length && this.filterMedicalStatusIds.length !== this.medicalStatusOptions.length) ||
        (this.filterAccountingStatusIds.length && this.filterAccountingStatusIds.length !== this.accountingStatusOptions.length) ||
        this.filterLaboratoryId ||
        this.filterSectorId ||
        this.filterCytologyResult ||
        this.filterHpvResult ||
        this.filterPrescribingOrganisation ||
        this.filterFileContact ||
        this.filterPatientSexId ||
        this.filterPatientBirthdate ||
        this.filterPatientFirstName ||
        this.filterPatientLastName
      );
    },
    showLaboratories: function() {
      const availableLaboratories = this.laboratoriesOptions.filter(v => v.isActive);
      return availableLaboratories.length >= 2;
    },
    formattedFields: function() {
      return this.showLaboratories ? this.fields : this.fields.filter(v => v.key !== "laboratory");
    },
    filingStatusOptions: function() {
      return this.$systemSettings.filingStatuses;
    },
    accountingStatusOptions: function() {
      return this.$systemSettings.accountingStatuses;
    },
    medicalStatusOptions: function() {
      return this.$systemSettings.medicalStatuses;
    },
    sexOptions: function() {
      return this.$systemSettings.sexes;
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import options
      this.importLaboratoriesOptions();
      this.importSectorsOptions();

      // auto-focus
      this.$refs.filterRangeFilter.focusFirstElement();
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.editLineButton_0) {
        this.$refs.editLineButton_0.focus();
      }
    },

    getStatusNameById(id, type) {
      for (const option of this[type + "StatusOptions"]) {
        if (option.id === id) {
          return option.localisedName;
        }
      }
      return "?";
    },
    updateStatusOption(idsArray, idValue) {
      const index = idsArray.indexOf(idValue);
      if (index > -1) {
        idsArray.splice(index, 1);
      } else {
        idsArray.push(idValue);
      }
      this.searchHasChanged = true;
    },

    // import data
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, isActive: true, formattedName: this.$tc("general.allMasculine", 2) });
      this.laboratoriesOptions = laboratories;
    },
    importSectorsOptions() {
      const sectorsOptions = JSON.parse(JSON.stringify(this.$systemSettings.sectors));
      sectorsOptions.unshift({ id: null, isSubscribed: true, name: "all", localisedName: this.$tc("general.allMasculine", 2) });
      this.sectorsOptions = sectorsOptions.map((sector) => {
        sector.isActive = sector.isSubscribed;
        sector.disabled = !sector.isSubscribed;
        return sector;
      });
    },

    // patient
    onPatientChosen(patient) {
      this.filterPatient = patient ? { id: patient.id, firstName: patient.firstName, lastName: patient.lastName, maidenName: patient.maidenName, sex: { name: patient.sex.name } } : null;
      this.searchHasChanged = true;
      this.$bvModal.hide("patientChooseModal-" + this._uid);
    },
    // contact
    suggestionFormatContact(contact) {
      if (!contact) return "";
      let output = contact.firstName + " " + contact.lastName;
      if (contact.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },
    onPrescriberChosen(contact) {
      this.filterPrescriber = contact ? { id: contact.id, firstName: contact.firstName, lastName: contact.lastName, code: contact.code } : null;
      this.searchHasChanged = true;
    },
    // pathologist
    suggestionFormatPathologist(pathologist) {
      if (!pathologist) return "";
      let output = pathologist.user.firstName + " " + pathologist.user.lastName;
      if (pathologist.user.isActive === false) {
        output += " (" + this.$t("general.deletedLabel") + ")";
      }
      return output;
    },
    onPathologistChosen(pathologist) {
      this.filterPathologist = pathologist ? { id: pathologist.id, user: { firstName: pathologist.user.firstName, lastName: pathologist.user.lastName } } : null;
      this.searchHasChanged = true;
    },

    // clear filters
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    clearAllFilters() {
      this.isValidFilterRange = true;
      this.filterRange = null;
      this.filterAccessNumber = "";
      this.filterHospitalisationNumber = "";
      this.filterPatient = null;
      this.filterPrescriber = null;
      this.filterPathologist = null;
      this.filterFilingStatusIds = [];
      this.filterMedicalStatusIds = [];
      this.filterAccountingStatusIds = [];
      if (this.advandedFiltersCount) {
        this.filterLaboratoryId = null;
        this.filterSectorId = null;
        this.filterCytologyResult = null;
        this.filterHpvResult = null;
        this.filterPrescribingOrganisation = null;
        this.filterFileContact = null;
        this.filterPatientSexId = null;
        this.filterPatientBirthdate = "";
        this.filterPatientFirstName = "";
        this.filterPatientLastName = "";
        this.filterTypePatientLastName = "exact";
        this.filterTypePatientFirstName = "exact";
      }
      this.searchHasChanged = true;
    },
    closeAndSearch() {
      this.$bvModal.hide("FileAdvancedSearchModal" + this._uid);
      this.onPageInput();
    },

    // PrescribingOrganisation : MODAL
    onOrganisationChosen(event) {
      this.filterPrescribingOrganisation = {
        id: event.id,
        name: event.name,
        code: event.code,
        organisationType: {
          name: event.organisationType.name
        },
        isActive: event.isActive
      };
      this.searchHasChanged = true;
      // close modal
      this.$bvModal.hide("chooseOrganisationModal");
    },
    // FileContact : MODAL
    onFileContactChosen(event) {
      this.filterFileContact = {
        id: event.id,
        firstName: event.firstName,
        lastName: event.lastName,
        code: event.code,
        isActive: event.isActive
      };
      this.searchHasChanged = true;
      // close modal
      this.$bvModal.hide("chooseFileContactModal");
    },

    // update table
    async validateForm() {
      if (!this.isValidFilterRange) {
        return false;
      }
      if (this.filterRange) {
        const filterStartDate = new Date(this.filterRange.start).getFullYear();
        if (filterStartDate < 1900 || filterStartDate > 2300) {
          return false;
        }
      }
      return true;
    },
    getSearchFilters() {
      return {
        isReport: true,
        creationDateStart: this.filterRange && this.filterRange.start ? this.filterRange.start : null,
        creationDateEnd: this.filterRange && this.filterRange.end ? this.filterRange.end : null,
        accessNumber: this.filterAccessNumber,
        hospitalisationNumber: this.filterHospitalisationNumber,
        patientId: this.filterPatient ? this.filterPatient.id : null,
        prescriberId: this.filterPrescriber ? this.filterPrescriber.id : null,
        pathologistId: this.filterPathologist ? this.filterPathologist.id : null,

        laboratoryId: this.filterLaboratoryId,
        sectorId: this.filterSectorId,
        cytologyResult: this.filterCytologyResult,
        hpvResult: this.filterHpvResult,
        prescribingOrganisationId: this.filterPrescribingOrganisation ? this.filterPrescribingOrganisation.id : null,
        fileContactId: this.filterFileContact ? this.filterFileContact.id : null,
        patientSexId: this.filterPatientSexId,
        patientBirthdate: this.filterPatientBirthdate,
        patientFirstName: this.filterPatientFirstName,
        patientLastName: this.filterPatientLastName,
        filterTypePatientLastName: this.filterTypePatientLastName,
        filterTypePatientFirstName: this.filterTypePatientFirstName,
        filingStatusIds: (this.filterFilingStatusIds.length !== this.filingStatusOptions.length ? this.filterFilingStatusIds : []),
        medicalStatusIds: (this.filterMedicalStatusIds.length !== this.medicalStatusOptions.length ? this.filterMedicalStatusIds : []),
        accountingStatusIds: (this.filterAccountingStatusIds.length !== this.accountingStatusOptions.length ? this.filterAccountingStatusIds : [])
      };
    },
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (await this.validateForm() === false) return false;

        this.loading = true;
        this.searchHasChanged = false;

        this.lastSearchFilters = this.getSearchFilters();

        // search and Update
        const res = await commonServices.getAll("files", this.lastSearchFilters);
        this.files = res.data.rows;
        this.totalFiles = res.data.totalFiles;
        this.totalComplementaries = res.data.totalComplementaries;
        this.totalCytology = res.data.totalCytology;
        this.totalHpv = res.data.totalHpv;
        this.totalCotesting = res.data.totalCotesting;
        this.positive = res.data.positive;
        this.negative = res.data.negative;
        this.indeterminate = res.data.indeterminate;
        this.maxReached = res.data.maxReached;
        this.isDisplayedResult = true;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.files.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.filterRangeFilter.focusFirstElement();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
      this.loading = false;
    },

    async onExport() {
      try {
        // validation
        if (await this.validateForm() === false) return false;

        this.loading = true;
        const filters = this.getSearchFilters();
        const resFile = await filesReportsServices.generate(filters, "xlsx");
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          let date = "";
          if (this.filterRange) {
            date = (this.filterRange.start ? "_" + this.filterRange.start : "") + "_" + (this.filterRange.end ? "_" + this.filterRange.end : "");
          }
          await fileSaver.saveAs(resFile.data, this.$t("filesReports.fileName") + date + ".xlsx");
        }
      } catch (err) {
        this.handleErrors(err);
      }
      this.loading = false;
    }

  }
};
</script>

<style src="@/views/Office/Files/AdvancedSearch.scss" lang="scss"></style>
<style src="@/views/Office/Files/StatusFilters.scss" lang="scss"></style>

<template>
  <div class="kt-histologyShortCode-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- histologyShortCode form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('histologyShortCodes.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              ref="codeInput"
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('histologyShortCodes.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              v-model="name"
              trim
              maxlength="256"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- content -->
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('histologyShortCodes.content')"
            :invalid-feedback="contentInvalidFeedback"
            :state="contentState"
          >
            <quillEditor
              ref="quillEditor"
              v-model="content"
              :options="{
                placeholder: $t('histologyShortCodes.contentPlaceholder'),
                modules: {
                  toolbar: [
                    [{'header': [1, 2, 3, false]}],
                    ['bold', 'italic', 'underline'],
                    [{'color': []}, {'background': []}],
                    [{'align': [false, 'right', 'center']}],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'script': 'super'}],
                    ['blockquote']
                  ]
                },
                formats: ['background', 'bold', 'color', 'italic', 'size', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'formula'],
                readOnly: true,
                theme: 'snow'
              }"
            />
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// modules
import "quill/dist/quill.snow.css";
import "@/views/Diagnosis/HistologyReports/quill.scss";
import { quillEditor } from "vue-quill-editor";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: {
    quillEditor
  },
  mixins: [userRights, error],
  props: {
    histologyShortCodeIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      histologyShortCodeId: this.histologyShortCodeIdProp,
      histologyShortCodeData: null,
      // form variables
      code: "",
      name: "",
      content: "",
      // is validation active
      codeValidation: false,
      nameValidation: false,
      contentValidation: false
    };
  },
  computed: {
    // form validation
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    contentState: function() {
      if (!this.contentValidation) return null;
      return this.content && this.content.length > 0 ? null : false;
    },
    contentInvalidFeedback: function() {
      return this.contentState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("histologyShortCodeEdit.pageTitle") : this.$t("histologyShortCodeNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("histologyShortCodeEdit.submitText") : this.$t("histologyShortCodeNew.submitText");
    }
  },
  async mounted() {
    // validate ID
    //if (this.editModeProp) {
    //  if (this.histologyShortCodeId === null) {
    //    this.$emit("alert", "danger", {
    //      title: this.$t("histologyShortCode.notifications.invalidIdTitle"),
    //      message: this.$t("histologyShortCode.notifications.invalidIdText")
    //    });
    //    navigate("diagnosis/histologyShortCodes");
    //    return;
    //  }
    //}

    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.codeInput.focus();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get histologyShortCode
          const resServices = await commonServices.get("histologyShortCodes", this.histologyShortCodeId);
          this.histologyShortCodeData = resServices.data;
          // import data
          this.code = resServices.data.code;
          this.name = resServices.data.name;
          this.content = resServices.data.content;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("diagnosis/histologyShortCodes");
    },

    // submit functions
    validateForm() {
      this.codeValidation = true;
      this.nameValidation = true;
      this.contentValidation = true;

      return !(
        this.codeState === false ||
        this.nameState === false ||
        this.contentState === false
      );
    },
    async saveObject() {
      try {
        const form = {
          code: this.code,
          name: this.name,
          content: this.content
        };
        if (this.editMode) {
          const res = await commonServices.put("histologyShortCodes", form, this.histologyShortCodeId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("histologyShortCodeEdit.notifications.editedTitle"),
              message: this.$t("histologyShortCodeEdit.notifications.editedText")
            });
            navigate("diagnosis/histologyShortCodes");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("histologyShortCodeEdit.notifications.editionErrorTitle"),
              message: this.$t("histologyShortCodeEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("histologyShortCodes", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("histologyShortCodeNew.notifications.addedTitle"),
              message: this.$t("histologyShortCodeNew.notifications.addedText")
            });
            navigate("diagnosis/histologyShortCodes");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("histologyShortCodeNew.notifications.additionErrorTitle"),
              message: this.$t("histologyShortCodeNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveObject();
    }
  }
};
</script>

<template>
  <div class="kt-awaitingHpvResults mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("awaitingHpvResults.pageTitle") }}
      </h2>
      <!-- uploadResults button  -->
      <b-button
        v-show="isEnabled(['menu', 'diagnosis', 'awaitingHpvResults', 'upload'])"
        ref="uploadResultsButton"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('diagnosis/awaitingHpvResults/upload')"
      >
        <b-icon icon="file-earmark-arrow-up"></b-icon>
        {{ $t("awaitingHpvResults.uploadAwaitingHpvResultButton") }}
      </b-button>
      <!-- addResult button  -->
      <b-button
        v-show="isEnabled(['menu', 'diagnosis', 'awaitingHpvResults', 'add'])"
        size="sm"
        class="pr-3 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('diagnosis/awaitingHpvResult/new')"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("awaitingHpvResults.addAwaitingHpvResultButton") }}
      </b-button>
      <!-- unrecognizedHpvResults  -->
      <b-button
        v-show="unrecognizedHpvResultsCount > 0"
        size="sm"
        class="pr-3 kt-page-header__action text-danger"
        variant="outline-secondary"
        @click="navigate('diagnosis/unrecognizedHpvResults')"
      >
        <span class="font-weight-semi-bold">{{ unrecognizedHpvResultsCount + (unrecognizedHpvResultsCount === 50 ? '+' : '') + ' ' + $t("awaitingHpvResults.unrecognizedHpvResultButton") }}</span>
      </b-button>

      <!-- filter LaboratoryId -->
      <div v-if="laboratoriesOptions.length > 2">
        <div class="d-inline-block">
          <b-form-select
            v-model="filterLaboratoryId"
            class="mt-3"
            size="sm"
            :options="laboratoriesOptions"
            value-field="id"
            text-field="formattedName"
            @keydown.enter.exact="onPageInput"
            @input="searchHasChanged = true;"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- table -->
    <b-table
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="awaitingHpvResults"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filter Timestamp -->
          <b-th
            class="kt-table__th-search"
            style="width: 200px;"
          >
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            ></b-form-input>
          </b-th>
          <!-- filter InstrumentId -->
          <b-th class="kt-table__th-search">
            <InputSearch
              class="kt-table__th-search-autocomplete"
              searchedTableProp="instruments"
              searchedFieldProp="networkName"
              :searchedLaboratoriesProp="null"
              :returnFieldsArrayProp="['id', 'networkName', 'model', 'brand', 'serialNumber', 'isActive']"
              suggestionsKeyProp="id"

              :placeholderProp="$t('awaitingHpvUpload.instrumentPlaceholder')"
              :valueProp="filterInstrument"
              :displayedValueProp="filterInstrument ? filterInstrument.networkName + ' ' + filterInstrument.model + (filterInstrument.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="suggestionFormatInstruments"
              noWrapProp

              @onUpdateParent="onInstrumentChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            ></InputSearch>
          </b-th>
          <!-- filter AccessNumber -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterAccessNumber"
              class="kt-table__th-search-input"
              trim
              maxlength="50"
              @input="searchHasChanged = true;"
              @keydown.enter.exact="onPageInput"
            />
            <b-button
              v-show="filterAccessNumber !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterAccessNumber')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Content -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            :style="{width: searchHasChanged ? '151px' : '38px'}"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="onPageInput"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(awaitingHpvResultButtons)="data">
        <div style="width: 85px;">
          <!-- edit -->
          <b-button
            v-show="isEnabled(['menu', 'diagnosis', 'awaitingHpvResults', 'edit'])"
            :ref="data.item['id'] + '_edit'"
            :key="data.item['id'] + '_edit'"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('diagnosis/awaitingHpvResult/edit', {awaitingHpvResultIdProp: data.item['id']})"
          >
            <b-icon icon="pen"></b-icon>
          </b-button>
          <!-- delete  -->
          <b-button
            v-show="isEnabled(['menu', 'diagnosis', 'awaitingHpvResults', 'delete'])"
            :ref="data.index === 0 ? 'deleteLineButton_' + data.index : null"
            class="my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onDeleteAwaitingHpvResult(data.item)"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!awaitingHpvResultsCount && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { InputSearch },
  mixins: [userRights, error, date, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      awaitingHpvResultsCount: 0,
      unrecognizedHpvResultsCount: 0,
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "timestamp",
          sortable: true,
          label: this.$t("awaitingHpvResults.timestamp"),
          formatter: (value, _key, _item) => {
            return value ? this.getFormattedDateTimeFromTimestamp(value) : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "instrument",
          sortable: true,
          label: this.$t("awaitingHpvResults.instrument"),
          formatter: (value, _key, _item) => {
            return value.networkName + (value.isActive === true ? "" : " (" + this.$t("general.deletedLabel") + ")");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "accessNumber",
          sortable: true,
          label: this.$t("awaitingHpvResults.accessNumber")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "content",
          sortable: true,
          label: this.$t("awaitingHpvResults.content")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "awaitingHpvResultButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterDate: "",
      filterLaboratoryId: null,
      filterInstrument: null,
      filterAccessNumber: "",
      // table items
      awaitingHpvResults: [],
      // options
      laboratoriesOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        filterDate: "string",
        filterLaboratoryId: "number",
        filterInstrument: { id: "number", networkName: "string", model: "string" },
        filterAccessNumber: "string"
      }
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterDate ||
        this.filterLaboratoryId ||
        this.filterInstrument ||
        this.filterAccessNumber
      );
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import laboratories
      this.importLaboratoriesOptions();

      // refresh table
      await this.onPageInput();
      await this.countUnrecognizedHpvResults();

      // auto-focus
      if (this.$refs.uploadResultsButton) {
        this.$refs.uploadResultsButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.deleteLineButton_0) {
        this.$refs.deleteLineButton_0.focus();
      }
    },

    // import laboratories
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, formattedName: this.$tc("fileInvoiceTransformations.allLaboratories") });
      this.laboratoriesOptions = laboratories;
    },
    // import countUnrecognizedHpvResults count
    async countUnrecognizedHpvResults() {
      try {
        const resUnrecognized = await commonServices.getAll("unrecognizedHpvResults", {});
        this.unrecognizedHpvResultsCount = resUnrecognized.data.rows.length;
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // instrument
    onInstrumentChosen(instrument) {
      this.filterInstrument = instrument ? { id: instrument.id, networkName: instrument.networkName, model: instrument.model } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // search and update the table
    async onPageInput() {
      try {
        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          date: this.filterDate,
          laboratoryId: this.filterLaboratoryId,
          instrumentId: this.filterInstrument ? this.filterInstrument.id : null,
          accessNumber: this.filterAccessNumber
        };
        const res = await commonServices.getAll("hpvResults", filters);
        this.awaitingHpvResults = res.data.rows;
        this.awaitingHpvResultsCount = res.data.rows.length;
        this.maxReached = res.data.maxReached;

        this.loading = false;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // format instrument suggestion
    suggestionFormatInstruments(suggestion) {
      let output = "";
      if (suggestion) {
        output = suggestion.networkName + " " + suggestion.model + " " + suggestion.brand + " (" + suggestion.serialNumber + ")";
        if (suggestion.isActive === false) {
          output += " (" + this.$t("general.deletedLabel") + ")";
        }
      }
      return output;
    },
    // delete
    async onDeleteAwaitingHpvResult(row) {
      if (typeof row === "undefined") return false;

      // confirmation pop up
      const result = await this.$bvModal.msgBoxConfirm(this.$t("awaitingHpvResultDelete.text"), {
        title: this.$t("awaitingHpvResultDelete.title"),
        okVariant: "danger",
        okTitle: this.$t("awaitingHpvResultDelete.ok"),
        cancelTitle: this.$t("awaitingHpvResultDelete.cancel"),
        centered: true
      });
      // delete
      if (result) {
        const res = await commonServices.delete("hpvResults", row.id);
        if (res.data === true) {
          this.$emit("alert", "success", {
            title: this.$t("awaitingHpvResultDelete.notifications.deletedTitle"),
            message: this.$t("awaitingHpvResultDelete.notifications.deletedText")
          });
        } else {
          this.$emit("alert", "danger", {
            title: this.$t("awaitingHpvResultDelete.notifications.deletedErrorTitle"),
            message: this.$t("awaitingHpvResultDelete.notifications.deletedErrorText")
          });
        }
        this.onPageInput();
      }
    }
  }
};
</script>

<template>
  <div
    class="kt-view-page kt-containerGroupShortCode-view"
    :class="moduleModeProp ? '' : 'pb-4'"
  >
    <!-- page header -->
    <div
      v-if="!moduleModeProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        ref="backButton"
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("containerGroupShortCodeView.pageTitle") }}
      </h2>
      <!-- edit button -->
      <b-button
        v-if="isEnabled(['menu', 'diagnosis', 'containerGroupShortCodes', 'edit'])"
        size="sm"
        class="kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('diagnosis/containerGroupShortCode/edit', {containerGroupShortCodeIdProp: containerGroupShortCodeId})"
      >
        <b-icon icon="pen"></b-icon>
        {{ $t("containerGroupShortCodeView.editButton") }}
      </b-button>
      <!-- subtitle -->
      <p class="kt-page-header__subtitle mb-0">
      </p>
    </div>

    <!-- divider -->
    <div
      v-if="!moduleModeProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- page body -->
    <div class="kt-view-page__body">
      <!-- SECTION -->
      <div
        v-if="codeDisplay || nameDisplay || contentDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <b-row v-if="codeDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerGroupShortCodes.code") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ code }}
            </p>
          </b-col>
        </b-row>
        <b-row v-if="nameDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerGroupShortCodes.name") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <p class="mb-0">
              {{ name }}
            </p>
          </b-col>
        </b-row>
        <b-row v-if="contentDisplay">
          <b-col
            cols="12"
            md="3"
          >
            <h3 class="h6">
              {{ $t("containerGroupShortCodes.content") }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import viewComponents from "@shared/services/UI/viewComponents";

export default {
  components: {},
  mixins: [userRights, error, viewComponents],
  props: {
    containerGroupShortCodeIdProp: {
      type: Number,
      default: null
    },
    moduleModeProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "code",
          "name",
          "content"
        ];
      }
    }
  },
  data() {
    return {
      // general
      containerGroupShortCodeId: this.containerGroupShortCodeIdProp,
      containerGroupShortCodeData: null,
      // base data
      code: "",
      name: "",
      content: "",
      // extended data
      // dispayFields
      codeDisplay: false,
      nameDisplay: false,
      contentDisplay: false
    };
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // validate ID
      //if (this.containerGroupShortCodeId === null) {
      //  this.$emit("alert", "danger", {
      //    title: this.$t("containerGroupShortCode.notifications.invalidIdTitle"),
      //    message: this.$t("containerGroupShortCode.notifications.invalidIdText")
      //  });
      //  if (!this.moduleModeProp) navigate("diagnosis/containerGroupShortCodes");
      //  return false;
      //}

      // get containerGroupShortCode
      const resServices = await commonServices.get("containerGroupShortCodes", this.containerGroupShortCodeId);
      this.containerGroupShortCodeData = resServices.data;
      // assign the data
      this.code = resServices.data.code;
      this.name = resServices.data.name;
      this.content = resServices.data.content;

      // auto focus
      if (!this.moduleModeProp) {
        this.$refs.backButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    onGoBack() {
      navigate("diagnosis/containerGroupShortCodes");
    }
  }
};
</script>

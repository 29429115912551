<template>
  <div class="kt-containers">
    <!-- containers - list -->
    <div class="kt-item-list">
      <div
        v-for="(containerItem, index) in containers"
        :key="containerItem.id"
        class="kt-item-list__item-wrapper"
        style="width: 100%"
      >
        <div
          class="kt-item-list__item"
          :style="{width: '100%', marginBottom: (containers && index === (containers.length - 1) ? '0' : '')}"
        >
          <!-- actions -->
          <div class="kt-item-list__item-actions">
            <!-- delete button -->
            <b-button
              v-if="!viewModeProp"
              v-show="isEnabled(['menu', 'office', 'files', 'containers', 'delete'])"
              class="btn-icon kt-item-list__item-delete"
              size="sm"
              variant="light"
              squared
              @click="onDeleteContainer(containerItem.id)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </div>

          <!-- containerType edit -->
          <b-row v-if="!viewModeProp && displayContainerTypeProp">
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('containers.containerType')"
              >
                <b-form-select
                  v-model="containerItem.containerTypeId"
                  class="kt-table__th-search-input"
                  :options="filteredContainerTypesOptions"
                  value-field="id"
                  text-field="name"
                  :disabled="viewModeProp"
                  @input="updateContainerType(index, $event)"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- containerType view -->
          <b-row v-if="viewModeProp && displayContainerTypeProp">
            <b-col
              cols="12"
              md="3"
            >
              <label class="mb-1">{{ $t("containers.containerType") }}</label>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ containerItem.containerType ? containerItem.containerType.name : $t("general.emptyWithHyphen") }}
              </p>
            </b-col>
          </b-row>

          <!-- sample edit -->
          <b-form-group
            v-show="!viewModeProp"
            :label="$t('containers.sample')"
            class="mb-0"
          >
            <p class="mb-0">
              {{ containerItem.sample ? containerItem.sample.code + " - " + containerItem.sample.name : "" }}
            </p>
            <!-- delete sample -->
            <b-link
              v-show="containerItem.sampleId !== null"
              :ref="'deleteSampleButton' + index"
              :class="'kt-link kt-link--' + $systemSettings.theme"
              @click="onRemoveSample(containerItem.id, index)"
            >
              {{ $t("containers.removeSample") }}
            </b-link>
            <!-- add a sample -->
            <b-button
              v-show="containerItem.sampleId === null"
              :ref="'addSampleButton' + index"
              size="sm"
              :variant="'outline-' + $systemSettings.theme"
              pill
              @click="onOpenChooseSample(index)"
            >
              <b-icon icon="plus"></b-icon>
              {{ $t("containers.addSampleButton") }}
            </b-button>
          </b-form-group>
          <!-- sample view -->
          <b-row v-show="viewModeProp">
            <b-col
              cols="12"
              md="3"
            >
              <label class="mb-1">{{ $t("containers.sample") }}</label>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <p class="mb-0">
                {{ containerItem.sample ? containerItem.sample.code + " - " + containerItem.sample.name : $t("general.emptyWithHyphen") }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <!-- add a container -->
    <b-button
      v-if="!viewModeProp && isEnabled(['menu', 'office', 'files', 'containers', 'add'])"
      v-show="sectorIdProp !== 1 || containersProp.length === 0"
      ref="containerAddButton"
      :style="{'margin-top': containers.length ? '12px' : ''}"
      size="sm"
      :variant="'outline-' + $systemSettings.theme"
      pill
      @click="onAddContainer"
    >
      <b-icon icon="plus"></b-icon>
      {{ $t("containers.addButton") }}
    </b-button>

    <!-- Sample Choose Modal -->
    <b-modal
      v-if="!viewModeProp"
      :id="'chooseSampleModal' + _uid"
      :title="$t('sampleChoose.pageTitle')"
      size="xl"
      hide-footer
      @shown="() => {
        $refs.sampleChooseComponent.focusFirstElement();
      }"
      @hidden="sampleChooseHidden"
    >
      <SampleChoose
        ref="sampleChooseComponent"
        :laboratoryIdProp="laboratoryIdProp"
        :sectorIdProp="sectorIdProp"
        @chosen="onSampleChosen($event)"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </div>
</template>

<script>
// services
import commonServices from "@shared/services/API/commonServices";
// components
import SampleChoose from "@/views/Data/Samples/SampleChoose";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    SampleChoose
  },
  mixins: [userRights, error],
  props: {
    containersProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    fileCreationDateProp: {
      type: String,
      default: ""
    },
    laboratoryIdProp: {
      type: [Number, null],
      default: null
    },
    sectorIdProp: {
      type: [Number, null],
      default: null
    },
    displayContainerTypeProp: {
      type: Boolean
    },
    // true when the component is not editable
    viewModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // base list
      containers: [],
      currentContainerIndex: null,
      // options
      containerTypesOptions: []
    };
  },
  computed: {
    filteredContainerTypesOptions: function() {
      if (this.containerTypesOptions.length > 0) {
        return this.containerTypesOptions.filter(v => (!this.sectorIdProp || v.sectorId === this.sectorIdProp) && (!this.laboratoryIdProp || v.laboratoryId === this.laboratoryIdProp));
      } else {
        return [];
      }
    }
  },
  watch: {
    // update value
    containersProp: {
      handler(val) {
        if (val && val.length > 0) {
          this.containers = JSON.parse(JSON.stringify(val));
          // reset the items id
          for (var i in this.containers) {
            this.containers[i].id = Number(uniqueId());
          }
        } else {
          this.containers = [];
        }
      },
      deep: true,
      immediate: true
    },
    // update containerType on laboratory/sector change
    sectorIdProp: {
      handler(val) {
        if (!this.viewModeProp && this.displayContainerTypeProp) {
          for (const container of this.containers) {
            if (container.containerType && container.containerType.sectorId !== val) {
              container.containerType = null;
              container.containerTypeId = null;
            }
          }
        }
      },
      immediate: true
    },
    laboratoryIdProp: {
      handler(val) {
        if (!this.viewModeProp && this.displayContainerTypeProp) {
          for (const container of this.containers) {
            if (container.containerType && container.containerType.laboratoryId !== val) {
              container.containerType = null;
              container.containerTypeId = null;
            }
          }
        }
      },
      immediate: true
    }
  },
  async mounted() {
    // auto add an empty containergroup on load
    if (this.viewModeProp === false && (!this.containersProp || !this.containersProp.length)) {
      this.onAddContainer();
    }

    if (!this.viewModeProp && this.displayContainerTypeProp) {
      await this.importContainerTypes();
    }
  },
  methods: {
    async importContainerTypes() {
      try {
        const res = await commonServices.getAll("containerTypes", null);
        this.containerTypesOptions = res.data;
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // Helpers
    getContainerDisplayedPosition(id) {
      for (let i = 0; i < this.containers.length; i++) {
        if (this.containers[i].id === id) return i;
      }
      return null;
    },
    // Actions
    onAddContainer() {
      const id = Number(uniqueId());
      this.containers.push({
        id: id,
        sampleId: null,
        sample: null,
        containerTypeId: this.filteredContainerTypesOptions.length ? this.filteredContainerTypesOptions[0].id : null,
        containerType: this.filteredContainerTypesOptions.length ? this.filteredContainerTypesOptions[0] : null
      });
      // send to parent
      this.sendToparent();
      // focus
      this.$nextTick(() => {
        if (this.containers.length) {
          this.$refs["addSampleButton" + (this.containers.length - 1)][0].focus();
        }
      });
    },
    onDeleteContainer(targetId) {
      // get the position
      const position = this.getContainerDisplayedPosition(targetId);
      if (position !== null) {
        this.containers.splice(position, 1);

        // send to parent
        this.sendToparent();
      }
      // auto-focus
      this.$nextTick(() => {
        this.$refs.containerAddButton.focus();
      });
    },

    // ContainerType
    updateContainerType(containerIndex, $event) {
      for (const containerType of this.filteredContainerTypesOptions) {
        if (containerType.id === $event) {
          this.containers[containerIndex].containerType = containerType;
          this.containers[containerIndex].containerTypeId = $event;
        }
      }
      // send to parent
      this.sendToparent();
    },

    // Sample
    onOpenChooseSample(containerIndex) {
      this.currentContainerIndex = containerIndex;
      this.$bvModal.show("chooseSampleModal" + this._uid);
    },
    onSampleChosen(targetSample) {
      // validate
      if (this.currentContainerIndex === null || !targetSample) return false;

      this.containers[this.currentContainerIndex].sampleId = targetSample.id;
      this.containers[this.currentContainerIndex].sample = targetSample;

      this.$bvModal.hide("chooseSampleModal" + this._uid);

      // send to parent
      this.$nextTick(() => {
        this.sendToparent();
      });
    },
    sampleChooseHidden() {
      this.$nextTick(() => {
        if (this.currentContainerIndex !== null) {
          if (this.containers[this.currentContainerIndex].sampleId) {
            this.$refs["deleteSampleButton" + this.currentContainerIndex][0].focus();
          } else {
            this.$refs["addSampleButton" + this.currentContainerIndex][0].focus();
          }
        }
        this.currentContainerIndex = null;
      });
    },
    onRemoveSample(containerId, index) {
      // get container
      const position = this.getContainerDisplayedPosition(containerId);

      if (position !== null) {
        this.containers[position].sampleId = null;
        this.containers[position].sample = null;

        // send to parent
        this.sendToparent();

        // auto-focus
        this.$nextTick(() => {
          this.$refs["addSampleButton" + index][0].focus();
        });
      }
    },
    // on update / finished
    sendToparent() {
      this.$emit("containersUpdate", this.containers);
    }
  }
};
</script>

<template>
  <div class="kt-containerGroupShortCode-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- containerGroupShortCode form -->
    <b-form @submit.prevent="submitSave">
      <b-row>
        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('containerGroupShortCodes.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              ref="codeInput"
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('containerGroupShortCodes.name')"
            :invalid-feedback="nameInvalidFeedback"
            :state="nameState"
          >
            <b-form-input
              v-model="name"
              trim
              maxlength="256"
              :state="nameState"
              @blur="nameValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- containerGroup -->
        <b-col cols="12">
          <b-form-group
            :label="$t('containerGroupShortCodes.containerGroup')"
            :state="containerGroupState"
            :invalid-feedback="containerGroupInvalidFeedback"
            class="kt-containerGroups"
          >
            <!-- containerGroups - list -->
            <div
              v-show="containerGroup"
              class="kt-item-list"
              style="margin-bottom: -10px;"
            >
              <div
                class="kt-item-list__item-wrapper"
                style="width: 100%;"
              >
                <div
                  class="kt-item-list__item"
                  style="width: 100%"
                >
                  <ContainerGroup
                    ref="containerGroupComponent"

                    :containerGroupProp="containerGroup"
                    :containerGroupRequiredProp="true"
                    :isValidationActiveProp="containerGroupValidation"

                    fileCreationDateProp=""
                    :laboratoryIdProp="null"
                    :sectorIdProp="null"
                    :fileEditModeProp="editMode"
                    :viewModeProp="false"
                    :displayCollectionDateProp="false"
                    :displayContainerTypeProp="false"

                    @containerGroupUpdated="containerGroup = $event"
                    @containerGroupDeleted="containerGroup = {
                      tempId: null,
                      id: null,
                      containers: [],
                      containerGroupTasks: []
                    }"
                    @updateIsValid="isValidContainerGroup = $event"
                    @alert="(variant, strings) => $emit('alert', variant, strings)"
                  />
                </div>
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import ContainerGroup from "@/views/Data/ContainerGroup";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: { ContainerGroup },
  mixins: [userRights, error],
  props: {
    containerGroupShortCodeIdProp: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      containerGroupShortCodeId: this.containerGroupShortCodeIdProp,
      containerGroupShortCodeData: null,
      // form variables
      code: "",
      name: "",
      containerGroup: {
        tempId: null,
        id: null,
        containers: [],
        containerGroupTasks: []
      },
      isValidContainerGroup: false,
      // is validation active
      codeValidation: false,
      nameValidation: false,
      containerGroupValidation: false
    };
  },
  computed: {
    // form validation
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    nameState: function() {
      if (!this.nameValidation) return null;
      return this.name && this.name.length > 0 ? null : false;
    },
    nameInvalidFeedback: function() {
      return this.nameState === false ? this.$t("validationRules.required") : "";
    },
    containerGroupState: function() {
      if (!this.containerGroupValidation) return null;
      return this.containerGroup !== null ? null : false;
    },
    containerGroupInvalidFeedback: function() {
      return this.containerGroupState === false ? this.$t("validationRules.required") : "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("containerGroupShortCodeEdit.pageTitle") : this.$t("containerGroupShortCodeNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("containerGroupShortCodeEdit.submitText") : this.$t("containerGroupShortCodeNew.submitText");
    }
  },
  async mounted() {
    // validate ID
    //if (this.editModeProp) {
    //  if (this.containerGroupShortCodeId === null) {
    //    this.$emit("alert", "danger", {
    //      title: this.$t("containerGroupShortCode.notifications.invalidIdTitle"),
    //      message: this.$t("containerGroupShortCode.notifications.invalidIdText")
    //    });
    //    navigate("diagnosis/containerGroupShortCodes");
    //    return;
    //  }
    //}

    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.codeInput.focus();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get containerGroupShortCode
          const resServices = await commonServices.get("containerGroupShortCodes", this.containerGroupShortCodeId);
          this.containerGroupShortCodeData = resServices.data;
          // import data
          this.code = resServices.data.code;
          this.name = resServices.data.name;
          this.containerGroup = resServices.data.containerGroup;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("diagnosis/containerGroupShortCodes");
    },

    // submit functions
    validateForm() {
      this.codeValidation = true;
      this.nameValidation = true;
      this.containerGroupValidation = true;

      return !(
        this.codeState === false ||
        this.nameState === false ||
        this.containerGroupState === false
      );
    },
    async saveObject() {
      try {
        const form = {
          code: this.code,
          name: this.name,
          containerGroup: this.containerGroup
        };
        if (this.editMode) {
          const res = await commonServices.put("containerGroupShortCodes", form, this.containerGroupShortCodeId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("containerGroupShortCodeEdit.notifications.editedTitle"),
              message: this.$t("containerGroupShortCodeEdit.notifications.editedText")
            });
            navigate("diagnosis/containerGroupShortCodes");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("containerGroupShortCodeEdit.notifications.editionErrorTitle"),
              message: this.$t("containerGroupShortCodeEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("containerGroupShortCodes", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("containerGroupShortCodeNew.notifications.addedTitle"),
              message: this.$t("containerGroupShortCodeNew.notifications.addedText")
            });
            navigate("diagnosis/containerGroupShortCodes");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("containerGroupShortCodeNew.notifications.additionErrorTitle"),
              message: this.$t("containerGroupShortCodeNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    async submitSave() {
      if (this.validateForm() !== false) await this.saveObject();
    }
  }
};
</script>

<template>
  <div class="kt-invoiceReports mb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("invoiceReports.pageTitle") }}
      </h2>
      <!-- add button -->
      <b-button
        v-show="isEnabled(['menu', 'accounting', 'invoiceReports', 'add'])"
        ref="addLineButton"
        size="sm"
        class="pr-3 mr-1 kt-page-header__action"
        :variant="$systemSettings.theme"
        @click="navigate('accounting/invoices/invoiceReport/new', {organisationTypeNameProp: organisationTypeName})"
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("invoiceReports.addInvoiceReportButton") }}
      </b-button>

      <!-- filter LaboratoryId -->
      <div v-if="laboratoriesOptions.length > 2">
        <div class="d-inline-block">
          <b-form-select
            v-model="filterLaboratoryId"
            class="mt-3"
            size="sm"
            :options="laboratoriesOptions"
            value-field="id"
            text-field="formattedName"
            @keydown.enter.exact="onPageInput"
            @input="searchHasChanged = true;"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- switcher organisation type -->
    <b-button-group
      class="kt-btn-group mb-3"
    >
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'clinics'])"
        class="kt-btn-group__btn"
        :class="(organisationTypeName === 'clinics') ? 'kt-btn-group__btn--active' : ''"
        variant="light"
        @click="changeOrganisationType('clinics')"
      >
        <b-icon icon="house"></b-icon>
        {{ $t("accountingSectionOrganisationTypes.clinics") }}
      </b-button>
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'healthCenters'])"
        class="kt-btn-group__btn"
        :class="(organisationTypeName === 'healthCenters') ? 'kt-btn-group__btn--active' : ''"
        variant="light"
        @click="changeOrganisationType('healthCenters')"
      >
        <b-icon icon="people"></b-icon>
        {{ $t("accountingSectionOrganisationTypes.healthCenters") }}
      </b-button>
      <b-button
        v-if="isEnabled(['menu', 'accounting', 'invoiceReports', 'hospitals'])"
        class="kt-btn-group__btn"
        :class="(organisationTypeName === 'hospitals') ? 'kt-btn-group__btn--active' : ''"
        variant="light"
        @click="changeOrganisationType('hospitals')"
      >
        <b-icon icon="building"></b-icon>
        {{ $t("accountingSectionOrganisationTypes.hospitals") }}
      </b-button>
    </b-button-group>

    <!-- table -->
    <b-table
      ref="table"
      class="m-0 kt-table"
      hover
      :fields="fields"
      :items="invoiceReports"
      :busy="loading"
    >
      <!-- search header -->
      <template v-slot:thead-top>
        <b-tr
          class="kt-table__tr-search"
          @keydown.ctrl.down.exact.prevent="focusFirstLine"
        >
          <!-- filterOrganisation -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <InputSearch
              ref="organisationFilter"
              class="kt-table__th-search-autocomplete"
              searchedTableProp="organisations"
              searchedFieldProp="name"
              :organisationTypeNamesProp="['clinics', 'healthCenters', 'hospitals']"
              :returnFieldsArrayProp="['id', 'code', 'name', 'isActive']"
              suggestionsKeyProp="id"

              :valueProp="filterOrganisation"
              :displayedValueProp="filterOrganisation ? filterOrganisation.name + ' (' + filterOrganisation.code + ')' + (filterOrganisation.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : ''"
              :suggestionFormatProp="(suggestion) => {
                return suggestion ? suggestion.name + ' (' + suggestion.code + ')' + (suggestion.isActive === false ? ' (' + $t('general.deletedLabel') + ')' : '') : '';
              }"
              noWrapProp

              @onUpdateParent="onOrganisationChosen"
              @keydown.enter.exact="onPageInput"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-th>
          <!-- filter EndDate -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterEndDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter InvoiceDate -->
          <b-th class="kt-table__th-search">
            <span class="kt-table__th-search-exact">{{ $t("general.exact") }}</span>
            <b-form-input
              v-model="filterInvoiceDate"
              class="kt-table__th-search-input"
              type="date"
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
          </b-th>
          <!-- filter Reference -->
          <b-th class="kt-table__th-search">
            <b-form-input
              v-model="filterReference"
              class="kt-table__th-search-input"
              trim
              autocomplete="off"
              @keydown.enter.exact="onPageInput"
              @input="searchHasChanged = true;"
            ></b-form-input>
            <b-button
              v-show="filterReference !== ''"
              class="kt-table__clear-btn"
              squared
              size="sm"
              @click="onClearFilter('filterReference')"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-th>
          <!-- filter Invoiced -->
          <b-th class="kt-table__th-search"></b-th>
          <!-- actions -->
          <b-th
            class="kt-table__th-search"
            style="width: 151px;"
          >
            <b-button
              v-show="searchHasChanged"
              variant="light"
              class="kt-table__th-search-btn"
              @click="onPageInput(true)"
            >
              <b-icon icon="search"></b-icon>
              <span>&nbsp;{{ $t('files.searchButton') }}</span>
            </b-button>
            <!-- fix for focus jump -->
            <input
              style="border: transparent !important; width: 0; padding: 0;"
              @focus="focusFirstLine"
            />
          </b-th>
        </b-tr>
      </template>

      <!-- cell template : action buttons -->
      <template v-slot:cell(invoiceReportButtons)="data">
        <div style="width: 131px;">
          <!-- view -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'invoiceReports', 'view'])"
            :ref="data.index === 0 ? 'viewLineButton_' + data.index : null"
            class="mr-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="navigate('accounting/invoices/invoiceReport/view', {invoiceReportIdProp: data.item['id']})"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>
          <!-- export pdf  -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'invoiceReports', 'toPdf'])"
            :ref="data.index === 0 ? 'exportPdfButton_' + data.index : null"
            class="mx-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onExport(data.item, 'pdf')"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
          <!-- export xlsx  -->
          <b-button
            v-show="isEnabled(['menu', 'accounting', 'invoiceReports', 'toXlsx'])"
            :ref="data.index === 0 ? 'exportXlsxButton_' + data.index : null"
            class="ml-1 my-0"
            size="sm"
            :variant="$systemSettings.theme"
            pill
            @click="onExport(data.item, 'xlsx')"
          >
            <b-icon icon="table"></b-icon>
          </b-button>
        </div>
      </template>

      <!-- on loading -->
      <template v-slot:table-busy>
        <div :class="'text-center text-' + $systemSettings.theme">
          <b-spinner
            class="align-middle"
            :class="'kt-spinner--' + $systemSettings.theme"
          ></b-spinner>
          <span class="kt-spinner-label">{{ $t("query.loading") }}</span>
        </div>
      </template>
    </b-table>

    <!-- no result -->
    <h4
      v-show="!invoiceReportsCount && !loading && isSearchActive && !searchHasChanged"
      class="text-center mt-3"
    >
      {{ $t("query.noResult") }}
    </h4>

    <!-- maxReached -->
    <div
      v-show="maxReached && !loading"
      class="text-center mt-3"
    >
      <span class="kt-badge kt-badge--light-gray kt-badge--lg">
        <span class="kt-badge__text">{{ $t("query.maxReached") }}</span>
      </span>
    </div>
  </div>
</template>

<script>
// components
import InputSearch from "@shared/views/Helpers/InputSearch";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import invoicesHelpers from "@shared/services/UI/invoicesHelpers";
import saveParamsInQuery from "@shared/services/UI/saveParamsInQuery";
import { navigate } from "@/services/UI/vueRouterServices";
// others
import fileSaver from "file-saver";

export default {
  components: { InputSearch },
  mixins: [userRights, error, invoicesHelpers, saveParamsInQuery],
  data() {
    return {
      // general
      loading: false,
      searchHasChanged: false,
      maxReached: false,
      invoiceReportsCount: 0,
      // organisationType
      organisationTypeName: "clinics",
      // table fields
      fields: [
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "organisation",
          sortable: true,
          label: this.$t("invoiceReports.organisationName"),
          formatter: (value, _key, _item) => {
            return value.name + " (" + value.code + ")" + (value.isActive === false ? " (" + this.$t("general.deletedLabel") + ")" : "");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "endDate",
          sortable: true,
          label: this.$t("invoiceReports.endDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiceDate",
          sortable: true,
          label: this.$t("invoiceReports.invoiceDate"),
          formatter: (value, _key, _item) => {
            return value ? this.$d(new Date(value), "date") : "";
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "reference",
          sortable: true,
          label: this.$t("invoiceReports.reference")
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiced",
          sortable: false,
          label: this.$t("invoiceReports.invoiced"),
          formatter: (value, _key, _item) => {
            return this.$n(value || 0, "currency");
          }
        },
        {
          tdClass: "kt-table__td",
          thClass: "kt-table__th",
          key: "invoiceReportButtons",
          sortable: false,
          label: ""
        }
      ],
      // table filters
      filterLaboratoryId: null,
      filterOrganisation: null,
      filterEndDate: "",
      filterInvoiceDate: "",
      filterReference: "",
      // table items
      invoiceReports: [],
      // options
      laboratoriesOptions: [],
      // saveParamsConfig
      saveParamsConfig: {
        organisationTypeName: "string",
        filterLaboratoryId: "number",
        filterOrganisation: { id: "number", code: "string", name: "string" },
        filterEndDate: "string",
        filterInvoiceDate: "string",
        filterReference: "string"
      }
    };
  },
  computed: {
    isSearchActive: function() {
      return !!(
        this.filterLaboratoryId ||
        this.filterOrganisation ||
        this.filterEndDate ||
        this.filterInvoiceDate ||
        this.filterReference
      );
    }
  },
  async mounted() {
    try {
      // pseudo-mixins
      this.navigate = navigate;

      // import laboratories
      this.importLaboratoriesOptions();

      // refresh table
      await this.onPageInput();

      // auto-focus
      if (this.$refs.addLineButton) {
        this.$refs.addLineButton.focus();
      }
    } catch (err) {
      this.handleErrors(err);
    }
  },
  methods: {
    focusFirstLine() {
      if (this.$refs.viewLineButton_0) {
        this.$refs.viewLineButton_0.focus();
      } else if (this.$refs.exportPdfButton_0) {
        this.$refs.exportPdfButton_0.focus();
      } else if (this.$refs.exportXlsxButton_0) {
        this.$refs.exportXlsxButton_0.focus();
      }
    },

    // import laboratories
    importLaboratoriesOptions() {
      // select
      const laboratories = JSON.parse(JSON.stringify(this.$systemSettings.availableLaboratories));
      laboratories.unshift({ id: null, formattedName: this.$tc("fileInvoiceTransformations.allLaboratories") });
      this.laboratoriesOptions = laboratories;
    },

    // organisation
    onOrganisationChosen(organisation) {
      this.filterOrganisation = organisation ? { id: organisation.id, code: organisation.code, name: organisation.name } : null;
      this.searchHasChanged = true;
    },

    // clear one filter
    onClearFilter(fieldName) {
      this[fieldName] = "";
      this.searchHasChanged = true;
    },
    // searchAndUpdateTable
    async onPageInput(autoFocusAfterSearch = false) {
      try {
        if (typeof autoFocusAfterSearch !== "boolean") {
          autoFocusAfterSearch = false;
        }

        // validation dates
        if (this.filterEndDate !== "") {
          const filterEndDate = new Date(this.filterEndDate).getFullYear();
          if (filterEndDate < 1900 || filterEndDate > 2300) {
            return false;
          }
        }
        if (this.filterInvoiceDate !== "") {
          const filterInvoiceDate = new Date(this.filterInvoiceDate).getFullYear();
          if (filterInvoiceDate < 1900 || filterInvoiceDate > 2300) {
            return false;
          }
        }

        this.loading = true;
        this.searchHasChanged = false;

        const filters = {
          laboratoryId: this.filterLaboratoryId,
          organisationType: this.organisationTypeName,
          organisationId: this.filterOrganisation ? this.filterOrganisation.id : null,
          endDate: this.filterEndDate,
          invoiceDate: this.filterInvoiceDate,
          reference: this.filterReference
        };
        const res = await commonServices.getAll("invoiceReports", filters);
        this.invoiceReports = this.importFormatInvoicesList(res.data.rows, "invoiceReport");
        this.invoiceReportsCount = res.data.rows.length;
        this.maxReached = res.data.maxReached;

        this.loading = false;

        // auto-focus
        if (autoFocusAfterSearch) {
          if (this.invoiceReports.length) {
            this.$nextTick(() => {
              this.focusFirstLine();
            });
          } else {
            this.$refs.organisationFilter.focusFirstElement();
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    changeOrganisationType(organisationTypeName) {
      this.organisationTypeName = organisationTypeName;
      this.onPageInput();
    },

    // export
    async onExport(row, type) {
      try {
        const resFile = await commonServices.export("invoiceReports", row.id, type);
        if (resFile.data.type === "application/json") {
          this.$emit("alert", "danger", {
            title: this.$t("general.downloadFileErrorTitle"),
            message: this.$t("general.downloadFileErrorText")
          });
        } else {
          fileSaver.saveAs(resFile.data, row.reference + "." + type);
        }
      } catch (err) {
        this.handleErrors(err);
      }
    }
  }
};
</script>

<template>
  <div class="kt-view-page kt-file-statuses-history pb-4">
    <!-- component header -->
    <div
      v-if="!hideHeaderProp"
      class="kt-page-header mt-4 pt-1 mb-3"
    >
      <!-- back button -->
      <b-button
        v-show="backButtonRouteNameProp"
        size="sm"
        class="kt-page-header__back-btn btn-icon mr-3"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="h4 mb-0 kt-page-header__title">
        {{ $t("fileStatusesHistory.pageTitle") }}
      </h2>
      <!-- subtitle -->
      <div class="kt-page-header__subtitle">
        <!-- status labels -->
        <span
          v-show="filingStatus || medicalStatus || accountingStatus"
          style="padding-top: 4px; display: block; white-space: nowrap;"
        >
          <StatusBadge
            class="mr-1"
            typeProp="filingStatus"
            :statusNameProp="filingStatus"
            isDisplayedStatusTitleProp
          />
          <StatusBadge
            class="mr-1"
            typeProp="medicalStatus"
            :statusNameProp="medicalStatus"
            isDisplayedStatusTitleProp
          />
          <StatusBadge
            typeProp="accountingStatus"
            :statusNameProp="accountingStatus"
            isDisplayedStatusTitleProp
          />
        </span>
      </div>
    </div>

    <!-- divider -->
    <div
      v-if="!hideHeaderProp"
      class="w-100 border-top mt-1 mb-4"
    ></div>

    <!-- component body -->
    <div class="kt-view-page__body">
      <!-- SECTION filing -->
      <div
        v-if="filingDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h5>
          {{ $t("fileStatusesHistory.sectionTitleFiling") }}
        </h5>
        <b-row
          v-for="(statusItem, index) in filingStatusesFormatted"
          :key="index"
          class="kt-file-statuses-history__item"
        >
          <b-col
            cols="12"
            md="4"
          >
            <p class="mb-0">
              {{ statusItem.user ? statusItem.user.firstName + ' ' + statusItem.user.lastName + (statusItem.user.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") : '' }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <p class="mb-0">
              {{ getFormattedDateTimeFromTimestamp(statusItem.timestamp) }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- filing status -->
            <span v-if="statusItem.filingStatus"><StatusBadge
              class="mt-n2"
              typeProp="filingStatus"
              :statusNameProp="statusItem.filingStatus.name"
            /></span>
            <!-- sent message -->
            <span
              v-if="statusItem.content"
              class="kt-badge kt-badge--red"
            >
              <strong>{{ $t('fileStatusesHistory.errorMessageStatus', {recipient: $t('fileStatusesHistory.' + statusItem.content.recipient)}) }}</strong>
              <span v-if="statusItem.content.email"><br />{{ statusItem.content.email }}</span>
              <span v-if="statusItem.content.phone"><br />{{ importFormatPhone(statusItem.content.phone) }}</span>
            </span>
            <!-- modification status -->
            <span v-if="statusItem.modificationStatus">{{ $t('fileStatusesHistory.modifiedFile') }}</span>
          </b-col>
        </b-row>
      </div>

      <div class="w-100 border-top mt-1 mb-3"></div>

      <!-- SECTION medical -->
      <div
        v-if="medicalDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h5>
          {{ $t("fileStatusesHistory.sectionTitleMedical") }}
        </h5>
        <b-row
          v-for="statusItem in medicalStatusesHistory"
          :key="statusItem.id"
          class="kt-file-statuses-history__item"
        >
          <b-col
            cols="12"
            md="4"
          >
            <p class="mb-0">
              {{ statusItem.user ? statusItem.user.firstName + ' ' + statusItem.user.lastName + (statusItem.user.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") : '' }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <p class="mb-0">
              {{ getFormattedDateTimeFromTimestamp(statusItem.timestamp) }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <StatusBadge
              class="mt-n2"
              typeProp="medicalStatus"
              :statusNameProp="statusItem.medicalStatus.name"
            />
          </b-col>
        </b-row>
      </div>

      <div class="w-100 border-top mt-1 mb-3"></div>

      <!-- SECTION accounting -->
      <div
        v-if="accountingDisplay"
        class="kt-view-page__section"
        :class="sectionMarginsClass"
      >
        <h5>
          {{ $t("fileStatusesHistory.sectionTitleAccounting") }}
        </h5>
        <b-row
          v-for="statusItem in accountingStatusesHistory"
          :key="statusItem.id"
          class="kt-file-statuses-history__item"
        >
          <b-col
            cols="12"
            md="4"
          >
            <p class="mb-0">
              {{ statusItem.user ? statusItem.user.firstName + ' ' + statusItem.user.lastName + (statusItem.user.isActive === true ? "" : " (" + $t("general.deletedLabel") + ")") : '' }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <p class="mb-0">
              {{ getFormattedDateTimeFromTimestamp(statusItem.timestamp) }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <StatusBadge
              class="mt-n2"
              typeProp="accountingStatus"
              :statusNameProp="statusItem.accountingStatus.name"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// components
import StatusBadge from "@shared/views/Helpers/StatusBadge";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import viewComponents from "@shared/services/UI/viewComponents";
import date from "@shared/services/UI/date";
import phoneNumber from "@shared/services/UI/phoneNumber";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: {
    StatusBadge
  },
  mixins: [userRights, error, viewComponents, date, phoneNumber],
  props: {
    backButtonRouteNameProp: {
      type: String,
      default: ""
    },
    fileStatusesHistoryProp: {
      type: Object,
      default: function() {
        return null;
      }
    },
    fileModificationsProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    sentMessagesProp: {
      type: Array,
      default: function() {
        return [];
      }
    },
    hideHeaderProp: {
      type: Boolean
    },
    displayedFieldsProp: {
      type: Array,
      default: function() {
        return [
          "filing",
          "accounting",
          "medical"
        ];
      }
    }
  },
  data() {
    return {
      // dispayFields
      filingDisplay: false,
      accountingDisplay: false,
      medicalDisplay: false
    };
  },
  computed: {
    fileModificationsFormatted: function() {
      if (this.fileModificationsProp.length === 0) {
        return [];
      } else {
        return this.fileModificationsProp.map((modification) => {
          const formatted = modification;
          formatted.modificationStatus = true;
          return formatted;
        });
      }
    },
    filingStatusesFormatted: function() {
      const arr = this.filingStatusesHistory.concat(this.sentMessagesProp, this.fileModificationsFormatted);

      if (arr.length > 0) {
        arr.sort((a, b) => {
          const aDate = new Date(a.timestamp);
          const bDate = new Date(b.timestamp);
          if (aDate > bDate) {
            return 1;
          } else {
            return (bDate > aDate) ? -1 : 0;
          }
        });
      }

      return arr;
    },
    // current statuses name
    filingStatus: function() {
      return this.fileStatusesHistoryProp && this.fileStatusesHistoryProp.filing && this.fileStatusesHistoryProp.filing.length > 0 ? this.fileStatusesHistoryProp.filing[0].filingStatus.name : "";
    },
    accountingStatus: function() {
      return this.fileStatusesHistoryProp && this.fileStatusesHistoryProp.accounting && this.fileStatusesHistoryProp.accounting.length > 0 ? this.fileStatusesHistoryProp.accounting[0].accountingStatus.name : "";
    },
    medicalStatus: function() {
      return this.fileStatusesHistoryProp && this.fileStatusesHistoryProp.medical && this.fileStatusesHistoryProp.medical.length > 0 ? this.fileStatusesHistoryProp.medical[0].medicalStatus.name : "";
    },
    // statuses history in chronological order
    filingStatusesHistory: function() {
      if (this.fileStatusesHistoryProp && this.fileStatusesHistoryProp.filing && this.fileStatusesHistoryProp.filing.length > 0) {
        const arr = JSON.parse(JSON.stringify(this.fileStatusesHistoryProp.filing));
        return arr.reverse();
      } else {
        return [];
      }
    },
    accountingStatusesHistory: function() {
      if (this.fileStatusesHistoryProp && this.fileStatusesHistoryProp.accounting && this.fileStatusesHistoryProp.accounting.length > 0) {
        const arr = JSON.parse(JSON.stringify(this.fileStatusesHistoryProp.accounting));
        return arr.reverse();
      } else {
        return [];
      }
    },
    medicalStatusesHistory: function() {
      if (this.fileStatusesHistoryProp && this.fileStatusesHistoryProp.medical && this.fileStatusesHistoryProp.medical.length > 0) {
        const arr = JSON.parse(JSON.stringify(this.fileStatusesHistoryProp.medical));
        return arr.reverse();
      } else {
        return [];
      }
    }
  },
  mounted() {
    // Place holder
  },
  methods: {
    onGoBack() {
      navigate(this.backButtonRouteNameProp);
    }
  }
};
</script>

<style scoped src="./FileStatusesHistory.scss" lang="scss"></style>

<template>
  <div
    class="kt-input-regNumber"
    :class="'kt-input-regNumber--' + $systemSettings.theme"
  >
    <b-form-group
      class="mb-0"
      :label="labelProp"
      :invalid-feedback="invalidFeedbackProp"
      :state="stateProp"
    >
      <b-form-input
        ref="regNumberField"
        v-model="regNumberFormatted"
        class="kt-input-regNumber__input"
        :state="stateProp"
        @blur="$emit('onActivateValidation', true)"
        @input="() => {
          onRegNumberInput();
          $emit('input', true);
        }"
        @keydown.enter.exact="$emit('submit', true)"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
// helpers
import regNumber from "@shared/services/UI/regNumber";

export default {
  mixins: [regNumber],
  props: {
    labelProp: {
      type: String,
      default: ""
    },
    regNumberProp: {
      type: String,
      default: ""
    },
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    invalidFeedbackProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      regNumber: "",
      regNumberShort: "",
      regNumberKey: "",
      regNumberFormatted: "",
      // debug
      debugMode: false
    };
  },
  watch: {
    regNumberProp: {
      handler: function(value) {
        if (this.regNumber !== value) {
          if (this.debugMode) console.log("watch ///////////// value " + value);
          this.regNumberFormatted = this.regNumberFormatter(value);
          this.onRegNumberInput();
        }
      },
      immediate: true
    }
  },
  methods: {
    onRegNumberInput() {
      // format: X XX XX XX XXX XXX XX
      // format: 1 23 45 67 890 123 45
      // format: 012345678901234567890

      if (this.debugMode) console.log("1 ///////////// value " + this.regNumberFormatted);
      if (this.debugMode) console.log("1 ///////////// caret " + this.$refs.regNumberField.selectionStart);
      let formatted = this.regNumberFormatted
        .replace(/a/g, "A")
        .replace(/b/g, "B")
        .replace(/[^0-9AB\s]+/g, "");
      let letterPosition = null;
      for (var i = formatted.length - 1; i >= 0; i--) {
        if (formatted.charAt(i) === "A" || formatted.charAt(i) === "B") {
          if (letterPosition) {
            formatted = formatted.slice(0, letterPosition) + formatted.slice(letterPosition + 1, formatted.length);
          } else {
            letterPosition = i;
          }
        }
      }
      let short = "";
      let key = "";
      const caretStart = this.$refs.regNumberField ? this.$refs.regNumberField.selectionStart : 0;
      const caretEnd = this.$refs.regNumberField ? this.$refs.regNumberField.selectionEnd : 0;

      // when editing right before the key, delete the key
      let caretCut = formatted;
      if (
        caretStart === caretEnd &&
        (caretStart === 17 || caretStart === 16)
      ) {
        caretCut = caretCut.slice(0, caretStart);
      }
      if (this.debugMode) console.log("1 ///////////// caretCut " + caretCut);

      // trimming the number
      const trimmed = caretCut.replace(/\s/g, "");
      short = trimmed.slice(0, 13);

      if (this.debugMode) console.log("1 ///////////// caret " + caretStart);
      if (this.debugMode) console.log("/////////////");

      if (this.debugMode) console.log("1 ///////////// trimmed " + trimmed);
      if (this.debugMode) console.log("1 ///////////// short " + short);

      // format the trimmed number
      formatted = trimmed.slice(0, 1);
      if (trimmed.length > 1) {
        formatted = formatted.slice(0, 1) + " " + trimmed.slice(1, trimmed.length);
      }
      if (trimmed.length > 3) {
        formatted = formatted.slice(0, 4) + " " + trimmed.slice(3, trimmed.length);
      }
      if (trimmed.length > 5) {
        formatted = formatted.slice(0, 7) + " " + trimmed.slice(5, trimmed.length);
      }
      if (trimmed.length > 7) {
        formatted = formatted.slice(0, 10) + " " + trimmed.slice(7, trimmed.length);
      }
      if (trimmed.length > 10) {
        formatted = formatted.slice(0, 14) + " " + trimmed.slice(10, 13);
      }
      if (this.debugMode) console.log("2 ///////////// FORMATTED BEFORE KEY " + formatted);

      // update the key
      if (short && short.length === 13) {
        const base = short.replace(/2A/g, "19").replace(/2B/g, "18");
        if (base.match(/^\d+$/g)) {
          key = 97 - (base % 97);
          key = ("00" + key).slice(-2);
        } else {
          key = "##";
        }
      }
      this.regNumber = short + key;
      this.regNumberShort = short;

      if (this.debugMode) console.log("3 ///////////// regNumberShort " + this.regNumberShort);
      if (this.debugMode) console.log("3 ///////////// key " + key);
      if (this.debugMode) console.log("3 ///////////// regNumber " + this.regNumber);
      if (this.debugMode) console.log("3 ///////////// regNumberFormatted " + this.regNumberFormatted);
      if (this.debugMode) console.log("/");

      this.$emit("onUpdateParent", { regNumber: this.regNumber });

      if (this.debugMode) console.log("///////////// before caret " + caretStart);
      if (this.debugMode) console.log("/////////////");
      if (this.debugMode) console.log("///////////// regNumberShort.length " + this.regNumberShort.length);
      this.$nextTick(() => {
        // update the value
        this.regNumberFormatted = formatted + (key ? " " : "") + key;

        // update the carret
        if (this.regNumberShort.length >= 13 && this.$refs.regNumberField) {
          this.$nextTick(() => {
            this.$refs.regNumberField.selectionStart = 18;
            this.$refs.regNumberField.selectionEnd = 18;
          });
        } else {
          this.$nextTick(() => {
            const addition = (caretStart === 2 || caretStart === 5 || caretStart === 8 || caretStart === 11 || caretStart === 15 || caretStart === 19) ? 1 : 0;
            this.$refs.regNumberField.selectionStart = caretStart + addition;
            this.$refs.regNumberField.selectionEnd = caretEnd + addition;
          });
        }
      });
      if (this.debugMode) console.log("///////////// after caret " + this.$refs.regNumberField.selectionStart);
      if (this.debugMode) console.log("/////////////");
      if (this.debugMode) console.log("//////////////////////////////////////////////////////////////");
      if (this.debugMode) console.log("/");
    }
  }
};
</script>

<template>
  <b-form-input
    ref="textInput"
    :value="value"
    :maxlength="maxlengthProp"
    :state="stateProp"
    :disabled="disabledProp"
    :trim="trimProp"
    autocomplete="off"
    @input="($event) => {
      $emit('input', $event);
      $emit('updateValidation', false);
    }"
    @change="($event) => {
      formatted = formatValue($event);
      $emit('input', formatted);
      $emit('updateValidation', true);
    }"
    @keydown.enter.exact.prevent
    @keyup.enter.exact="() => {
      formatted = formatValue(value);
      $emit('input', formatted);
      $emit('updateValidation', true);
      $emit('submit', true)
    }"
    @blur="() => {
      $emit('updateValidation', true);
    }"
  ></b-form-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    stateProp: {
      type: [Boolean, null],
      default: null
    },
    disabledProp: {
      type: Boolean
    },
    maxlengthProp: {
      type: Number,
      default: 50
    },
    trimProp: {
      type: Boolean
    }
  },
  // computed: {
  //   displayValue: {
  //     get: function() {
  //       return this.value;
  //     },
  //     set: function(modifiedValue) {
  //       return modifiedValue;
  //     }
  //   }
  // },
  methods: {
    focusFirstElement() {
      if (this.$refs.textInput) {
        this.$refs.textInput.focus();
      }
    },
    formatValue(value = "") {
      if (value === "") {
        return "";
      } else {
        for (const laboratory of this.$systemSettings.laboratories) {
          if (value.match(laboratory.slideNumberRegex && laboratory.slideToAccessNumberRegex)) {
            return value.replace(new RegExp(laboratory.slideToAccessNumberRegex), "");
          }
        }
        return value;
      }
    }
  }
};
</script>

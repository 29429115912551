<template>
  <b-form-group
    :invalid-feedback="taskActsInvalidFeedback"
    :state="taskActsStateDisplay"
  >
    <div class="kt-taskActs">
      <!-- title -->
      <h3
        v-show="!hideLabelProp"
        class="h6"
      >
        {{ $t("taskActs.title") }}
      </h3>

      <!-- acts - list -->
      <div class="kt-item-list">
        <div
          v-for="taskActItem in taskActs"
          :key="taskActItem.tempId"
          class="kt-item-list__item-wrapper"
          style="width: 100%"
        >
          <!-- TASK ACT -->
          <TaskAct
            :taskActProp="taskActItem"
            :validationActiveProp="validationActiveProp"
            :viewModeProp="viewModeProp"
            :additionalFeeGroupsOptionsProp="additionalFeeGroupsOptions"
            @deleteTaskAct="onDeleteTaskAct(taskActItem.tempId)"
            @updateTaskAct="onUpdateTaskAct($event)"
            @updateIsValid="($event) => onIsValidUpdated(taskActItem.tempId, $event)"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </div>
      </div>

      <!-- add act button -->
      <b-button
        v-if="!viewModeProp && isEnabled(['menu', 'data', 'tasks', 'taskActs', 'add'])"
        v-b-modal="'chooseModal' + _uid"
        size="sm"
        :variant="'outline-' + $systemSettings.theme"
        pill
      >
        <b-icon icon="plus"></b-icon>
        {{ $t("taskActs.addButton") }}
      </b-button>
    </div>

    <!-- Act Choose Modal -->
    <b-modal
      v-if="!viewModeProp"
      :id="'chooseModal' + _uid"
      hide-footer
      :title="$t('actChoose.pageTitle')"
      size="xl"
      @shown="() => {
        $refs.actChooseComponent.focusFirstElement()
      }"
    >
      <ActChoose
        ref="actChooseComponent"
        @chosen="onActChosen"
        @alert="(variant, strings) => $emit('alert', variant, strings)"
      />
    </b-modal>
  </b-form-group>
</template>

<script>
// components
import TaskAct from "@/views/Data/TaskAct";
import ActChoose from "@/views/Data/Acts/ActChoose";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import date from "@shared/services/UI/date";
import uniqueId from "lodash.uniqueid";

export default {
  components: {
    TaskAct,
    ActChoose
  },
  mixins: [userRights, error, date],
  props: {
    taskActsProp: {
      type: Array,
      default: function() {
        return [];
      },
      deep: true
    },
    hideLabelProp: {
      type: Boolean
    },
    viewModeProp: {
      type: Boolean
    },
    validationActiveProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // base list
      taskActs: [],
      // validation
      taskActsIsValidArray: [],
      // options arrays
      additionalFeeGroupsOptions: []
    };
  },
  computed: {
    taskActsState: function() {
      if (!this.validationActiveProp || this.viewModeProp) {
        // validation not active
        return null;
      } else if (this.taskActs.length <= 0) {
        // no taskAct selected
        return false;
      } else {
        // each taskAct fields validation
        let state = null;
        for (const taskAct of this.taskActs) {
          if (!this.taskActsIsValidArray.includes(taskAct.tempId)) {
            state = false;
            break;
          }
        }
        return state;
      }
    },
    taskActsStateDisplay: function() {
      if (!this.validationActiveProp) return null;
      return this.taskActsState;
    },
    taskActsInvalidFeedback: function() {
      // no error
      if (this.taskActsStateDisplay === null) return "";
      if (this.taskActs.length <= 0) {
        // no taskAct selected
        return this.$t("validationRules.required");
      } else {
        // error already displayed on each taskAct
        return "";
      }
    }
  },
  watch: {
    // import taskActs and setup tempId
    taskActsProp: {
      handler(val) {
        if (val && val.length > 0) {
          let taskActs = JSON.parse(JSON.stringify(val));
          taskActs = taskActs.map((taskAct) => {
            if (!taskAct.tempId) {
              taskAct.tempId = taskAct.id || "new-" + uniqueId();
            }
            return taskAct;
          });
          this.taskActs = taskActs;
        } else {
          this.taskActs = [];
        }
      },
      immediate: true
    },
    // activate validation by prop
    validationActiveProp: {
      handler() {
        this.sendIsValid();
      }
    }
  },
  async mounted() {
    // import additionalFeeGroupsOptions
    if (!this.viewModeProp) this.importAdditionalFeeGroupsOptions();
  },
  methods: {
    importAdditionalFeeGroupsOptions() {
      const additionalFeeGroupsOptions = JSON.parse(JSON.stringify(this.$systemSettings.additionalFeeGroups));
      additionalFeeGroupsOptions.filter((v) => v.isActive !== false);
      this.additionalFeeGroupsOptions = additionalFeeGroupsOptions;
    },

    // validation
    onIsValidUpdated(tempId, isValid) {
      // search position
      const position = this.taskActsIsValidArray.reduce((pos, currentId, index) => {
        if (pos !== null) {
          return pos;
        } else {
          return currentId === tempId ? index : null;
        }
      }, null);
      // edit
      if (isValid !== false) {
        if (position === null) this.taskActsIsValidArray.push(tempId);
      } else {
        if (position !== null) this.taskActsIsValidArray.splice(position, 1);
      }
      this.sendIsValid();
    },
    sendIsValid() {
      this.$emit("updateIsValid", this.taskActsState);
    },

    // Helpers
    getTaskActDisplayedPosition(tempId) {
      return this.taskActs.reduce((pos, value, index) => {
        if (pos !== null) {
          return pos;
        } else {
          return value.tempId === tempId ? index : null;
        }
      }, null);
    },
    // Actions
    onActChosen(targetAct) {
      // validate
      if (!targetAct) return false;

      // add in displayed array
      this.taskActs.push({
        id: null,
        tempId: "new-" + uniqueId(),
        actId: targetAct.id,
        startValidityDate: this.getIsoDate(),
        endValidityDate: ""
      });

      // close modal
      this.$bvModal.hide("chooseModal" + this._uid);

      // send to parent
      this.sendToparent();
    },
    onUpdateTaskAct(taskAct) {
      // find the act to update
      const position = this.getTaskActDisplayedPosition(taskAct.tempId);

      if (position !== null) {
        // update task
        this.taskActs[position] = taskAct;

        this.sendToparent();
      }
    },
    onDeleteTaskAct(taskActTempId) {
      // delete in displayed array
      const displayedPosition = this.getTaskActDisplayedPosition(taskActTempId);
      this.taskActs.splice(displayedPosition, 1);

      // send to parent
      this.sendToparent();
    },
    // on update / finished
    sendToparent() {
      this.$emit("taskActsUpdate", this.taskActs);
      this.sendIsValid();
    }
  }
};
</script>

import axiosServices from "@shared/services/API/axiosServices";

export default {
  axiosServices,
  getAll(filter) {
    return axiosServices.axios.get("/dashboardRecords/getAll", { params: { filter: filter } });
  },
  getOngoingLive(filter) {
    return axiosServices.axios.get("/dashboardRecords/getOngoingLive", { params: { filter: filter } });
  }
};

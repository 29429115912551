<template>
  <div class="kt-act-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- act form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('acts.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              ref="codeInput"
              v-model="code"
              trim
              maxlength="7"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- description -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('acts.description')"
            :invalid-feedback="descriptionInvalidFeedback"
            :state="descriptionState"
          >
            <b-form-input
              v-model="description"
              trim
              maxlength="256"
              :state="descriptionState"
              @blur="descriptionValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- price -->
        <b-col
          cols="12"
          md="4"
        >
          <InputPrice
            :labelProp="$t('acts.price')"
            :priceProp="price"
            :stateProp="priceState"
            :invalidFeedbackProp="priceInvalidFeedback"
            @onUpdateParent="price = $event"
            @onActivateValidation="priceValidation = $event"
            @submit="onSubmit"
          ></InputPrice>
        </b-col>
        <!-- startValidityDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('acts.startValidityDate')"
            :invalid-feedback="startValidityDateInvalidFeedback"
            :state="startValidityDateState"
          >
            <b-form-input
              v-model="startValidityDate"
              type="date"
              :state="startValidityDateState"
              @blur="startValidityDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!-- endValidityDate -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('acts.endValidityDate')"
            :invalid-feedback="endValidityDateInvalidFeedback"
            :state="endValidityDateState"
          >
            <b-form-input
              v-model="endValidityDate"
              type="date"
              :state="endValidityDateState"
              @blur="endValidityDateValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputPrice from "@shared/views/Helpers/InputPrice";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import { navigate } from "@/services/UI/vueRouterServices";
import price from "@shared/services/UI/price";

export default {
  components: { InputPrice },
  mixins: [userRights, error, price],
  props: {
    actIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      actId: this.actIdProp,
      actData: null,
      // form variables
      code: "",
      description: "",
      price: 0,
      startValidityDate: "",
      endValidityDate: "",
      // is validation active
      codeValidation: false,
      descriptionValidation: false,
      priceValidation: false,
      startValidityDateValidation: false,
      endValidityDateValidation: false
    };
  },
  computed: {
    // form validation
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    descriptionState: function() {
      if (!this.descriptionValidation) return null;
      return this.description && this.description.length > 0 ? null : false;
    },
    descriptionInvalidFeedback: function() {
      return this.descriptionState === false ? this.$t("validationRules.required") : "";
    },
    priceState: function() {
      if (!this.priceValidation) return null;
      if (!this.price) return false;
      return typeof this.price === "number" || /^\d+\.\d{2}$/g.test(this.paid) ? null : false;
    },
    priceInvalidFeedback: function() {
      if (this.priceState === null) return "";
      if (!this.price) return this.$t("validationRules.required");
      return this.$t("validationRules.invalidPrice");
    },
    startValidityDateState: function() {
      if (!this.startValidityDateValidation) return null;
      return this.startValidityDate && this.startValidityDate.length > 0 ? null : false;
    },
    startValidityDateInvalidFeedback: function() {
      return this.startValidityDateState === false ? this.$t("validationRules.required") : "";
    },
    endValidityDateState: function() {
      return null;
    },
    endValidityDateInvalidFeedback: function() {
      return "";
    },

    // formatted variables
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("actEdit.pageTitle") : this.$t("actNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("actEdit.submitText") : this.$t("actNew.submitText");
    }
  },
  async mounted() {
    // pseudo-mixins
    this.navigate = navigate;

    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      this.$refs.codeInput.focus();
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get act
          const resServices = await commonServices.get("acts", this.actId);
          this.actData = resServices.data;
          // import data
          this.code = resServices.data.code;
          this.description = resServices.data.description;
          this.price = this.importFormatPrice(resServices.data.price);
          this.startValidityDate = resServices.data.startValidityDate;
          this.endValidityDate = resServices.data.endValidityDate;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    // navigate to the list page
    onGoBack() {
      navigate("data/acts");
    },
    // submit
    onValidateAct() {
      this.codeValidation = true;
      this.descriptionValidation = true;
      this.priceValidation = true;
      this.startValidityDateValidation = true;
      this.endValidityDateValidation = true;

      return !(
        this.codeState === false ||
        this.descriptionState === false ||
        this.priceState === false ||
        this.startValidityDateState === false ||
        this.endValidityDateState === false
      );
    },
    async onSaveAct() {
      try {
        const form = {
          code: this.code,
          description: this.description,
          startValidityDate: this.startValidityDate || null,
          endValidityDate: this.endValidityDate || null,
          price: this.exportFormatPrice(this.price)
        };
        if (this.editMode) {
          const res = await commonServices.put("acts", form, this.actId);
          if (res.data === true) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("actEdit.notifications.editedTitle"),
              message: this.$t("actEdit.notifications.editedText")
            });
            navigate("data/acts");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("actEdit.notifications.editionErrorTitle"),
              message: this.$t("actEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          const res = await commonServices.post("acts", form);
          if (res.data.id) {
            // success message
            this.$emit("alert", "success", {
              title: this.$t("actNew.notifications.addedTitle"),
              message: this.$t("actNew.notifications.addedText")
            });
            navigate("data/acts");
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("actNew.notifications.additionErrorTitle"),
              message: this.$t("actNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    onSubmit() {
      if (this.onValidateAct() !== false) this.onSaveAct();
    }
  }
};
</script>
